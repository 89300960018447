import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IClaimInquiryFormState, IClaimInquiryFormActionProps, actionCreators } from '@store/ClaimInquiryForm';
import { Input, TextArea, Button } from '@optum-uicl/ui-core/dist';
import { DialogWrapper, OKCancelButtons, DialogInstruction, CenteredContent, ContentColumnWrapper } from '@common/DialogWrapper';
import { DialogLegend, DialogFieldset, DialogLabel } from '@common/DialogStyles';
import { SelectComponent } from '../../common/SelectComponent';
import { UpDownArrowButtonsRow  } from '@common/UpDownArrowButtonsRow';
import { handleChange, pageLeave } from '@commonResources/userModified';
import { RadioButtonComponent } from '@common/RadioButton';


export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
#upright_lable,
#upright_lable_ub
    {
        writing-mode: vertical-lr;
        text-orientation: upright;
    }
#first_four_hcfa_wrapper,
#first_three_ub_wrapper
    {
        margin-bottom: 5px
    }
`;
export const CustomWrapper = styled.div`
    margin-right: 10px;
    margin-bottom: 5px;
`;

export const RowWrapperRight = styled.div`
    display: flex;
    width: 250px    
`;

export const RowWrapperLeft = styled.div`
    display: flex;
    width: 340px    
`;

export const ContentRowWrapperTop = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    padding-bottom:5px;
`;

export const ContentRowWrapper = styled.div`
    display: inline-flex;
    flex-direction: row;
    
`;

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    title: string,
    ncsIsEnabled: boolean,
}

interface IComponentState {

}

export const DEFAULT_STATE:  IComponentState = {};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IClaimInquiryFormProps = IMergeCrudComponentProps<IClaimInquiryFormState, IClaimInquiryFormActionProps, IOwnProps>;

export class ClaimInquiryForm extends React.Component<IClaimInquiryFormProps, IComponentState> {

    
    protected updatingPage: boolean;

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        title: "California CIF Maintenance",
        ncsIsEnabled: false,
    };

    constructor(props: IClaimInquiryFormProps) {
        super(props);
        this.updatingPage = false;
        this.state = DEFAULT_STATE;
    }

    public componentWillMount() {
    }

    public componentDidMount() {

        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.props.history.push('/LandingPage');
        }

        this.props.action.crud.get({ crudId: CrudTypes.mctiCreateCIF });
        //this.props.action.ui.loadPatientBox({ masterCrud: this.props.dataStore.crud, uiData: { value: '' } });
    }

    public componentDidUpdate(prevProps: any, prevState: any) {

        if (!this.props.dataStore.ui.isIntialDataLoaded &&
            this.props.dataStore.crud.data) {
            this.props.action.ui.loadPatientBox({ masterCrud: this.props.dataStore.crud, uiData: { value: '' } });
        }

        if (this.props.dataStore.crud.dataStatus === 'SUCCESS' && this.updatingPage)
            this.props.history.push('/LandingPage');

    }

    public componentWillUnmount() {
        
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public validateForm(): boolean {

        this.props.action.ui.updateCrudData({ masterCrud: this.props.dataStore.crud, uiData: { value: '' } });

        return true;
    }

    public onOKSubmitClaimInquiryForm(e: React.ChangeEvent<HTMLButtonElement>) {
        if (!this.validateForm())
            return;

        this.props.action.crud.update(  this.props.dataStore.crud );

        this.onCancelClaimInquiryForm('');

        }

    public onCancelClaimInquiryForm(e: any) {
        
        this.onLeavingPage("/LandingPage");
    }
    
    public onLeavingPage(e: any) {
        this.props.history.push("/LandingPage");
    }

    public onSelectPatientName(e: any) {

        this.props.action.ui.setPatientRecordIndex(e.target.value);
    }

    public updateRadioHCFA(val: any) {
  
        this.props.action.ui.updateRadioHCFA({ masterCrud: this.props.dataStore.crud, uiData: { value: val } });
    }
    public updateRadioUB(val: any) {

        this.props.action.ui.updateRadioUB({ masterCrud: this.props.dataStore.crud, uiData: { value: val } });
    }

    public onMoveRecordUp(e: React.ChangeEvent<HTMLButtonElement>) {

        if (this.props.dataStore.ui.patientNameRecordPointer != "1") {
            this.props.action.ui.movePatientRecord("UP");
        } 
    }

    public onMoveRecordDown(e: React.ChangeEvent<HTMLButtonElement>) {

        if (this.props.dataStore.ui.patientNameRecordPointer != "3") {
            this.props.action.ui.movePatientRecord("DOWN");
        }
    }

    /* -----------------------------------  */
    /* -----  DATA HELP METHODS ----------  */
    /* -----------------------------------  */

    public getData(ob: any, field: string): string {
        if (!this.dataLoaded() || !ob)
            return '';

        return ob[field] ?? '';
    }

    public dataLoaded(): boolean {
        if (this.props.dataStore.ui &&
            this.props.dataStore.ui.claimInquiryForm)
            return true;

        return false;
    }

    public render() {
        var instruction = <React.Fragment>Use this page to set the CIF data that will automatically display when a user creates a CIF. * 1500 claims do not use medical record numbers.</React.Fragment>;
        var buttons = <OKCancelButtons disableOK={!this.props.canEdit} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOKSubmitClaimInquiryForm(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancelClaimInquiryForm(e)} />;
        var labelStyles: React.CSSProperties = { marginRight: '10px' };
        let okText = "Yes";
        let cancelText = "No";

        return (
            <DialogWrapper title={this.props.title} instruction={instruction} helpUrl='/Support/Help/HELP_Maint_CACIF.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}> 
                <ContentWrapper id={"content_wrapper_claiminquiryform"} style={{ width: '700px' }}>
                    <ContentColumnWrapper>
                        <ContentRowWrapperTop>
                                <DialogFieldset style={{ width: '350px', height: '100px' }}>
                                    <DialogLegend>Patient's Name (7)</DialogLegend>
                                    <ContentRowWrapper id={"patient_name_box"}>
                                        <SelectComponent 
                                            size={3}
                                            width='250px'
                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectPatientName(e)}
                                            optionFields={{
                                                value: "value",
                                                text: "label"
                                                }}
                                            records={this.props.dataStore.ui.patientRecords}
                                            key={this.props.dataStore.ui.keyValue}
                                            selectedValue={this.props.dataStore.ui.patientNameRecordPointer}
                                        />
                                        <UpDownArrowButtonsRow
                                            toolTipDown=' Move Down'
                                            toolTipUp=' Move Up'
                                            onUpBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                this.onMoveRecordUp(e)
                                            }}
                                            onDownBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                this.onMoveRecordDown(e)
                                            }}
                                        />
                                    </ContentRowWrapper>
                                </DialogFieldset>
                            <RowWrapperLeft>
                                <DialogFieldset >
                                    <DialogLegend>Service Lines For Outpatient Claims</DialogLegend>
                                    <table><tbody><tr>
                                        <ContentRowWrapper>
                                            <td style={{ backgroundColor: '#cbcccd', marginTop: '7px', height: '100px' }}>
                                                <label style={{ paddingTop: '10px' }} id='upright_lable'  >1500</label>
                                                <br/>
                                            </td>
                                            <td>
                                                <ContentColumnWrapper style={{ marginTop: '20px' }}>
                                                    <RadioButtonComponent
                                                        width='100%'
                                                        id="first_four_hcfa"
                                                        label="First 4 service lines"
                                                        checked={this.getData(this.props.dataStore.ui.claimInquiryForm.ClaimInquiryFormMaintenanceInfo.ServiceLines, '@HCFA') === '1'}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateRadioHCFA('1')}
                                                    />
                                                    <RadioButtonComponent
                                                        width='100%'
                                                        id="first_four_hcfa"
                                                        label="Last 4 service lines"
                                                        checked={this.getData(this.props.dataStore.ui.claimInquiryForm.ClaimInquiryFormMaintenanceInfo.ServiceLines, '@HCFA') === '2'}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateRadioHCFA('2')}
                                                    />
                                                    <RadioButtonComponent
                                                        width='100%'
                                                        id="first_four_hcfa"
                                                        label="Select 4 service lines"
                                                        checked={this.getData(this.props.dataStore.ui.claimInquiryForm.ClaimInquiryFormMaintenanceInfo.ServiceLines, '@HCFA') === '3'}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateRadioHCFA('3')}
                                                    />

                                                </ContentColumnWrapper>
                                            </td>
                                        </ContentRowWrapper>
                                            
                                        <ContentRowWrapper>
                                            <td style={{ backgroundColor: '#cbcccd', marginTop: '7px', height: '100px' }}>
                                                <label id='upright_lable_ub' style={{ paddingTop: '25px' }}>UB</label>
                                            </td>
                                            <td>
                                                <ContentColumnWrapper style={{ marginTop: '20px' }}>
                                                    <RadioButtonComponent
                                                        width='100%'
                                                        id="first_three_ub"
                                                            label="First 3 service lines + 001"
                                                            checked={this.getData(this.props.dataStore.ui.claimInquiryForm.ClaimInquiryFormMaintenanceInfo.ServiceLines, '@UB92') === '1'}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateRadioUB('1')}
                                                    />
                                                
                                                    <RadioButtonComponent
                                                        width='100%'
                                                        id="first_three_ub"
                                                        label="Last 3 service lines + 001"
                                                        checked={this.getData(this.props.dataStore.ui.claimInquiryForm.ClaimInquiryFormMaintenanceInfo.ServiceLines, '@UB92') === '2'}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateRadioUB('2')}
                                                    />
                                                
                                                    <RadioButtonComponent
                                                        width='100%'
                                                        id="first_three_ub"
                                                        label="Select 3 service lines + 001"
                                                        checked={this.getData(this.props.dataStore.ui.claimInquiryForm.ClaimInquiryFormMaintenanceInfo.ServiceLines, '@UB92') === '3'}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateRadioUB('3')}
                                                    />
                                                </ContentColumnWrapper>
                                            </td>
                                        </ContentRowWrapper>
                                    </tr></tbody></table>
                                </DialogFieldset >
                            </RowWrapperLeft>
                        </ContentRowWrapperTop>
                    </ContentColumnWrapper>
                    <CustomWrapper>
                        <DialogFieldset >
                            <DialogLegend>Remarks</DialogLegend>
                            <TextArea
                                className='text-area'
                                domID="cif_remarks"
                                initialValue={ this.getData(this.props.dataStore.ui.claimInquiryForm.ClaimInquiryFormMaintenanceInfo.Remarks, '@Value')}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => this.props.action.ui.updateRemarks(e.target.value)}
                            />
                        </DialogFieldset>
                    </CustomWrapper>
                </ContentWrapper>
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IClaimInquiryFormState, IClaimInquiryFormActionProps, IOwnProps, IClaimInquiryFormProps, ApplicationState>(
    createCrudMapStateToProps('claimInquiryForm'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ClaimInquiryForm);

export default withRouter(connectedHoc);
