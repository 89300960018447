import * as React from 'react';
import * as jQuery from 'jquery';
import { Panel, MenuItem, Badge } from 'react-bootstrap';
//import {SingleSelectGroup} from '@optum-uicl/ui-core/dist';
import { IQuickLinkSubMenu } from './RAPQuickLinks';
import { RouteComponentProps, withRouter} from 'react-router-dom';
import { UrlFromApi } from '../common/UrlFromApi';

window.jQuery = jQuery; window.$ = jQuery;

type QuickLinkPanelProps = IProps & RouteComponentProps<{}>;

interface IProps {
    isExpanded: boolean;
    type: string;
    qlId: string;
    qlName: string;
    onLinkClick: (url: UrlFromApi) => void;
    link?: string;
    count?: string;
    content?: IQuickLinkSubMenu[];
    componentProps?: {
        UserProperties: [{
            id: string,
            state: string;
        }];
    };
    saveMethod?: () => void;
}

interface IState {
    expanded: boolean,
    panelClass: string;
}

interface IDivStyle extends React.CSSProperties {
    fontFamily: string,
    fontSize: string,
    fontWeight: number,
    color: string,
    cursor: string,
    textDecoration: string,
    position: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed',
    left: string,
    top: string,
    letterSpacing: string,
}

export default class QuickLinksPanel extends React.Component<QuickLinkPanelProps, IState>
{
    constructor(props: QuickLinkPanelProps) {
        super(props);
        this.state =
            {
                expanded: false,
                panelClass: ''
            };
        this.headerClicked = this.headerClicked.bind(this);
    }

    componentDidMount() {
        $('span.doNotScroll').click(function (event:any) {
            event.preventDefault();
        });
        this.setState(
            {
                expanded: this.props.isExpanded
            });

        if (!this.props.isExpanded)
            this.setState({ panelClass: "qlPanel" });
        else
            this.setState({ panelClass: "qlPanelExpanded" });
    }

    getPanelHeader() {
        //var divStyle = evalDivStyle(this);
        var divStyle: IDivStyle = {
            fontFamily: '',
            fontSize: '',
            fontWeight: 12,
            color: '',
            cursor: '',
            textDecoration: '',
            position: 'static',
            left: '',
            top: '',
            letterSpacing: ''
        };
        divStyle.fontFamily = "optum-sans-regular";
        divStyle.fontSize = "12px";
        divStyle.fontWeight = 600;
        divStyle.color = "#6D6F70";
        divStyle.cursor = "pointer";
        divStyle.textDecoration = "none";
        divStyle.position = "relative";
        divStyle.left = '3px';
        divStyle.top = '1px';
        divStyle.letterSpacing = '0.5px';
        var caretStyle = this.state.expanded ? 'qlcaret down doNotScroll' : 'qlcaret right doNotScroll';
        var header = <span key="header" className='doNotScroll' style={divStyle}>{this.props.qlName}</span>;
        if (this.props.type === "PanelMenu") {
            divStyle.fontWeight = 600;
            header = <span key="header" className='doNotScroll panel-link' style={divStyle}>{this.props.qlName}</span>;
        }
        if (this.props.type === "PanelLink") {
            caretStyle = 'qlcaret doNotScroll';
            header = <span key="header" className="doNotScroll" style={divStyle}>{this.props.qlName} <Badge>{this.props.count}</Badge></span>;
        }
        var arrow = <span key="arrow" className={caretStyle}></span>;

        return [arrow, header];
    }

    buildLinksForPanel() {
        var linksJson = this.props.content;
        if (linksJson)
        {
            var keyCount = 0;
            var links = linksJson.map((linkItem) =>
            {
                keyCount++;
                if (linkItem.Type)
                {
                    switch (linkItem.Type)
                    {
                        case "Item":
                            if (linkItem.Link) {
                                return <MenuItem key={keyCount} eventKey={linkItem.Link} href="#" onClick={() => this.props.onLinkClick(linkItem.Url)}>{linkItem.Name}</MenuItem>;
                            }
                            
                    }
                }
            }, this);
            return links;
        }
    }

    /*
        * LCR-0403
        * the next few lines need to be uncommented to work on the assurance project
        */
    render() {
        //return <SingleSelectGroup className={this.state.panelClass} items=
        return <Panel className={this.state.panelClass} expanded={this.state.expanded} onClick={this.headerClicked} onToggle={() => {}}>
            <Panel.Heading>{this.getPanelHeader()}</Panel.Heading>
            <Panel.Body collapsible>{this.buildLinksForPanel()}</Panel.Body>
        </Panel>;
    }
    

    headerClicked() {
        if (this.props.type === "PanelMenu") {
            if (!this.state.expanded)
                this.setState({ panelClass: "qlPanelExpanded" });
            else
                this.setState({ panelClass: "qlPanel" });

            this.setState({ expanded: !this.state.expanded });
            if (this.props.componentProps) {
                var componentProps = this.props.componentProps.UserProperties;
                for (var i = 0; i < componentProps.length; i++) {
                    if (componentProps[i].id === this.props.qlId) {
                        var state = !this.state.expanded;
                        componentProps[i].state = (state === true ? "Expanded" : "Collapsed");
                        break;
                    }
                }
                if (this.props.saveMethod) {
                    this.props.saveMethod();
                }
            }
        }
        if (this.props.type === "PanelLink" && this.props.link) {
            if (this.props.qlName === "Messages") {
                this.props.history.push('/Home/Messages');
            } else {
                eval(this.props.link);
            }
        }
    }
}
