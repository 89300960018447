import { actionCreators, IActionCreators, IActionCreatorKeys, ApplicationState, ApplicationStateKeys, KnownStates } from '@store/index';
import { Reducer, ActionCreator, Dispatch, bindActionCreators, ActionCreatorsMapObject } from 'redux';
import { MapStateToPropsParam, MapDispatchToPropsParam, MapDispatchToPropsFunction } from 'react-redux';
import { ICrudComponentActions, ICrudComponentActionProps, createCrudMapDispatchToProps } from '../../scripts/util/CrudComponentHelpers';
import { IApiComponentActions, IApiComponentActionProps, createApiMapDispatchToProps } from '@scripts/util/ApiDataHelpers';

// This function supports a single named store for any number of component instances.
//export function mapStateToProps<TOwnProps extends any>(stateField: ApplicationStateKeys) {
//    return (state: ApplicationState, ownProps: TOwnProps): ApplicationState[typeof stateField] => {
//        return state[stateField];
//    }
//}

// This function supports a single named store for any number of component instances.
export function mapStateToProps<TOwnProps extends any, StateField extends ApplicationStateKeys>(stateField: StateField) {
    return (state: ApplicationState, ownProps: TOwnProps): ApplicationState[StateField] => {
        return state[stateField];
    }
}

export interface IStateFieldComponentProps<StateField extends ApplicationStateKeys> {
    stateField: StateField;
}

// This function supports a store per instance.
export function mapInstancedStateToProps<TOwnProps extends IStateFieldComponentProps<StateField>, StateField extends ApplicationStateKeys>() {
    return (state: ApplicationState, ownProps: TOwnProps): ApplicationState[StateField] => {
        return state[ownProps.stateField];
    }
}

/*
export type IMergeComponentProps<S extends IComponentState<any, any>, A extends IApiComponentActionProps<any>, O extends {}> = O & {
    dataStore: S;
    action: IActionCreators;
};


export function mergeApiComponentProps<S extends IApiComponentState<any, any>, A extends IApiComponentActionProps<any>, O extends {}>(state: S, action: A, ownProps: O): IMergeApiComponentProps<S, A, O> {
    return Object.assign({}, ownProps, {
        dataStore: state,
        action: action
    });
}
*/

//export type IApplicationStateMap<K extends ApplicationStateKeys> = {
//    [key in K]: ApplicationState[K];
//};
export type IApplicationStateMap<K extends ApplicationStateKeys> = Pick<ApplicationState, K>;
/*
export function mapStatesToProps<TOwnProps extends any, StateField extends ApplicationStateKeys>(stateFields: (ApplicationStateKeys)[]) {
    return (state: ApplicationState, ownProps: TOwnProps): IApplicationStateMap<StateField> => {
        var result: IApplicationStateMap<StateField> | object = {};
        stateFields.forEach((stateKey: ApplicationStateKeys) => {
            result = { ...result, [stateKey]: state[stateKey] };
        });
        return result as IApplicationStateMap<StateField>;
    };
}
*/

export function mapStatesToProps<TOwnProps extends any, StateField extends ApplicationStateKeys>(stateFields: (ApplicationStateKeys)[]) {
    return (state: ApplicationState, ownProps: TOwnProps): Partial<ApplicationState> => {
        var result: Partial<ApplicationState> | object = {};
        stateFields.forEach((stateKey: ApplicationStateKeys) => {
            result = { ...result, [stateKey]: state[stateKey] };
        });
        return result;
    };
}

export function isCrudComponentAction(actionCreator: IActionCreatorTypes): actionCreator is ICrudComponentActions<any> {
    return ((actionCreator as ICrudComponentActions<any>).crud !== undefined) && ((actionCreator as ICrudComponentActions<any>).ui !== undefined);
}

export function isApiComponentAction(actionCreator: IActionCreatorTypes): actionCreator is IApiComponentActions<any> {
    return ((actionCreator as IApiComponentActions<any>).api !== undefined) && ((actionCreator as ICrudComponentActions<any>).ui !== undefined);
}

//export type IActionCreatorMap<K extends IActionCreatorKeys = IActionCreatorKeys> = {
//    [key in K]: IActionCreators[K];
//};
export type IActionCreatorMap<K extends IActionCreatorKeys = IActionCreatorKeys> = Pick<IActionCreators, K>;

//export type IActionCreatorMap<K extends IActionCreatorKeys> = {
//    [key in K]: IActionCreators[K];
//};
//export type IActionCreatorPropMap<K extends IActionCreatorKeys> = {
////    [key in K]: IActionCreatorPropTypes<T>;
//    [key in K]: IActionCreatorPropTypes<IActionCreators[K]>;
//};

//export type IActionCreatorsActionProps = IActionCreatorMap<ActionCreatorsMapObject<Pick<IActionCreators, Partial<IActionCreatorKeys>>> | ICrudComponentActionProps<any> | IApiComponentActionProps<any>>;
export type IActionCreatorTypes = ActionCreatorsMapObject<any> | ICrudComponentActions<any> | IApiComponentActions<any>;
//export type IActionCreatorPropTypes = ActionCreatorsMapObject<any> | ICrudComponentActionProps<any> | IApiComponentActionProps<any>;
////export type IActionCreatorsActionProps = Partial<IActionCreatorMap<IActionCreatorKeys>>;
//export type IActionCreatorsActionProps = Partial<IActionCreatorPropMap<IActionCreatorPropTypes>>;

//export type IActionCreatorPropTypes<T extends any> = ActionCreatorsMapObject<T> | ICrudComponentActionProps<T> | IApiComponentActionProps<T>;
//export type IActionCreatorsActionProps = Partial<IActionCreatorMap<IActionCreatorKeys>>;
//export type IActionCreatorsActionProps = Partial<IActionCreatorPropMap<IActionCreatorKeys, IActionCreatorPropTypes<any>>>;
//export type IActionCreatorsActionProps = Partial<IActionCreatorPropMap<IActionCreatorKeys>>;

/*
export type IActionCreatorPropMap<K extends IActionCreatorKeys> = {
//    [key in K]: IActionCreatorPropTypes<T>;
    [key in K]: IActionCreatorPropTypes<IActionCreators[K]>;
};

export type IActionCreatorPropTypes<T extends any> = ActionCreatorsMapObject<T> | ICrudComponentActionProps<T> | IApiComponentActionProps<T>;
//export type IActionCreatorsActionProps = Partial<IActionCreatorMap<IActionCreatorKeys>>;
//export type IActionCreatorsActionProps = Partial<IActionCreatorPropMap<IActionCreatorKeys, IActionCreatorPropTypes<any>>>;
export type IActionCreatorsActionProps = Partial<IActionCreatorPropMap<IActionCreatorKeys>>;

export function createMapDispatchToProps<TOwnProps>(actionCreatorKeys: (IActionCreatorKeys)[]): MapDispatchToPropsFunction<IActionCreatorsActionProps, TOwnProps> {
    return (dispatch: Dispatch, ownProps: TOwnProps): IActionCreatorsActionProps => {
        //var result: ActionCreatorsMapObject<IActionCreatorsActionProps> | ICrudComponentActionProps<any> = {};
        var result: IActionCreatorsActionProps | object = {};
        actionCreatorKeys.forEach((actionCreatorKey: IActionCreatorKeys) => {
            var actionCreator = actionCreators[actionCreatorKey];
            if (isCrudComponentAction(actionCreator)) {
                //var crudBindingFunction = createCrudMapDispatchToProps<TOwnProps>(actionCreator);
                //if (typeof crudBindingFunction === 'function') {
                //    result = { ...result, [actionCreatorKey]: crudBindingFunction(dispatch, ownProps) }
                //
                //}
                //var crudBindCrudActionCreators = (typeof crudBindingFunction === 'function') ? crudBindingFunction : () => null;
                //result = { ...result, [actionCreatorKey]: crudBindCrudActionCreators(dispatch, ownProps) }
                //var crudBindingFunction: MapDispatchToPropsFunction<ICrudComponentActionProps<any>, TOwnProps> = createCrudMapDispatchToProps<TOwnProps>(actionCreator);
                //ICrudComponentActions<CommonUI.ActionCreators & U>
                //result = { ...result, [actionCreatorKey]: createCrudMapDispatchToProps<TOwnProps>(actionCreator)(dispatch, ownProps) }
                result = { ...result, [actionCreatorKey]: createCrudMapDispatchToProps(actionCreator)(dispatch, ownProps) };

            }
            else if (isApiComponentAction(actionCreator)) {
                result = { ...result, [actionCreatorKey]: createApiMapDispatchToProps(actionCreator)(dispatch, ownProps) };
            }
            else {
                result = { ...result, [actionCreatorKey]: bindActionCreators(actionCreator as any, dispatch) }
            }
        });
        return result as IActionCreatorsActionProps;
    }
}
*/

export function createMapDispatchToProps<A extends (IActionCreatorKeys)[], TOwnProps>(actionCreatorKeys: A): MapDispatchToPropsFunction<IActionCreatorMap, TOwnProps> {
    return (dispatch: Dispatch, ownProps: TOwnProps): IActionCreatorMap => {
        var result: IActionCreatorMap | object = {};
        actionCreatorKeys.forEach((actionCreatorKey: IActionCreatorKeys) => {
            var actionCreator = actionCreators[actionCreatorKey];
            if (isCrudComponentAction(actionCreator)) {
                result = { ...result, [actionCreatorKey]: createCrudMapDispatchToProps(actionCreator)(dispatch, ownProps) };
            }
            else if (isApiComponentAction(actionCreator)) {
                result = { ...result, [actionCreatorKey]: createApiMapDispatchToProps(actionCreator)(dispatch, ownProps) };
            }
            else {
                result = { ...result, [actionCreatorKey]: bindActionCreators(actionCreator as any, dispatch) }
            }
        });
        return result as IActionCreatorMap;
    }
}

export function mapPartialDispatchToProps<A extends Partial<IActionCreators>, TOwnProps>(actionCreatorsToMap: A): MapDispatchToPropsFunction<A, TOwnProps> {
    return (dispatch: Dispatch, ownProps: TOwnProps): A => {
        var result: A | object = {};
        for (const actionCreatorKey in actionCreators) {
            const actionCreator = actionCreators[actionCreatorKey as IActionCreatorKeys];
            if (isCrudComponentAction(actionCreator)) {
                result = { ...result, [actionCreatorKey]: createCrudMapDispatchToProps(actionCreator)(dispatch, ownProps) };

            }
            else if (isApiComponentAction(actionCreator)) {
                result = { ...result, [actionCreatorKey]: createApiMapDispatchToProps(actionCreator)(dispatch, ownProps) };
            }
            else {
                result = { ...result, [actionCreatorKey]: bindActionCreators(actionCreator as any, dispatch) }
            }
        };
        return result as A;
    }
}

export function mapDispatchToProps<A extends IActionCreatorTypes, TOwnProps>(actionCreatorToMap: A): MapDispatchToPropsFunction<A, TOwnProps> {
    return (dispatch: Dispatch, ownProps: TOwnProps): A => {
        var result: A | object = {};
            if (isCrudComponentAction(actionCreatorToMap)) {
                result = createCrudMapDispatchToProps(actionCreatorToMap)(dispatch, ownProps);

            }
            else if (isApiComponentAction(actionCreatorToMap)) {
                result = createApiMapDispatchToProps(actionCreatorToMap)(dispatch, ownProps);
            }
            else {
                result = bindActionCreators(actionCreatorToMap as any, dispatch);
            }
        return result as A;
    }
}

export type IMergeComponentActionProps<U extends any> = ActionCreatorsMapObject<U>

export type IMergeComponentProps<S, A, O> = O & {
    dataStore: S;
    action: A;
};

export function mergeComponentProps<S, A, O>(state: S, action: A, ownProps: O): IMergeComponentProps<S, A, O> {
    return Object.assign({}, ownProps, {
        dataStore: state,
        action: action
    });
}
