import * as React from 'react';
import { ContentRowWrapper, } from '@common/DialogWrapper';
import styled from 'styled-components';
import { DialogLegend, DialogFieldset } from '@common/DialogStyles';
import { SelectComponent } from '../../common/SelectComponent';
import { Input } from '@optum-uicl/ui-core/dist';


//-----------------
// PAGE NOTES
// This component collects all the physician 
//-----------------
//STYLES - Put custom style elements up here.
// e.g.
//const Spacer = styled.div`
//    height: 0.25rem;
//
const RowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    padding-top:5px;
`;

//-----------------
// OBJECT DEFINITIONS - Put any interface or small class definitions here.

//-----------------
// STATE AND PROPS - Define the local component state and any component props here.
interface IComponentState {
}

interface IComponentProps {
    remitLobs: ISelectOption[],
    selectedLob?: string,
    filterFunction: Function,
    selectFunction: Function,
}

//-----------------
// DEFAULTS - Define any default object values (including the state) here.
export const DEFAULT_STATE: IComponentState = {
};


export class RemitLobs extends React.Component<IComponentProps, IComponentState>
{

    constructor(props: IComponentProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public render() {
        const records: ISelectOption[] = this.props?.remitLobs;
        return (
            <ContentRowWrapper>
                <DialogFieldset width="34.969rem" style={{marginBottom: "1.25rem"}}>
                    <DialogLegend>Remit LOBs</DialogLegend>
                    <RowWrapper>
                        <SelectComponent
                            title='add-a-new-payer'
                            size={6}
                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.selectFunction(e?.target?.value ?? '')}
                            selectedValue={this.props.selectedLob}
                            optionFields={{
                                value: "value",
                                text: "label",
                            }}
                            width="100%"
                            height="15.188rem"
                            disabled={false}
                            records={records} />
                    </RowWrapper>
                    <RowWrapper style={{ marginTop: "0.5rem" }}>
                        <Input
                            autoComplete="off"
                            domID="remit-filter-input"
                            className="text-input"
                            label="Filter This List"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.filterFunction(e?.currentTarget?.value ?? '')}
                        />
                    </RowWrapper>
                </DialogFieldset>
            </ContentRowWrapper>
        );
    }
}