import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper } from '@common/DialogWrapper';
import { Input} from '@optum-uicl/ui-core/dist';
import { ARMGrid } from '@common/UICLWrappers/ARMGrid';
import { Colors } from '@commonResources/colorVariables';
import styled from 'styled-components';
import _ from 'lodash';
import { ARMHeaderCell } from '../../common/UICLWrappers/ARMHeaderCell';
import { ISortIndicator, SortDataType, SortHelper } from '@scripts/util/SortHelper';
import { handleChange } from '@commonResources/userModified';
import { TextStyles } from "@optum-uicl/ui-core/dist";

const numberOfSortableHeaders = 1
let sortableHeaderIds: any[] = []
for (let i = 0; i < numberOfSortableHeaders; i++) {
    sortableHeaderIds = [...sortableHeaderIds, "" + _.uniqueId()]
}

const gridID = "UpdateSuppressionModalGrid-id"

const defualtEditDataArray: editData[] = []

const defualtClientData: clientData = {
    "@Active": "N",
    "@Batch": "N",
    "@Bridge": "N",
    "@CFI": "N",
    "@Direct": "N",
    "@DisplayName": "",
    "@EligEnabled": "N",
    "@ID": "",
    "@Manual": "N",
    "@Name": "",
    "@PayerID": "",
    "@RTECPID": "",
    Edits: { Edit: defualtEditDataArray },
};

const UpdateSuppressionModalContainerDiv = styled.div`
    width: 600px;
    height: 365px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: ${Colors.optumNeutral100};
    text-transform: uppercase;
    ${TextStyles.xSmall};  
`;

interface IComponentProps {
    closeModal: () => void;
    maintenanceInfo: any;
    payerId: string;
    rtecpId: string;
    id: any;
    fieldEdits: any;
    onOK: (updates: any, fieldUpdates: any, payerId: string, rtecpId: string) => void;
};

interface editData {
    "@ID": string;
    "@EditId": string;
    "@TypeBill": string;
    "@Suprressed": string;
};

interface clientData {
    "@Active": string;
    "@Batch": string;
    "@Bridge": string;
    "@CFI": string;
    "@Direct": string;
    "@DisplayName": string;
    "@EligEnabled": string;
    "@ID": string;
    "@Manual": string;
    "@Name": string;
    "@PayerID": string;
    "@RTECPID": string;
    Edits?: any;
    Fields?: any;
};

interface IRowProps {
    record: {
        "@ID": string;
        "@EditId": string;
        "@TypeBill": string;
        "@Suprressed": string;
        "@EditName": string;
    }
};

interface IRecord extends Sortable {
    "@ID": string;
    "@EditId": string;
    "@TypeBill": string;
    "@Suprressed": string;
    "@EditName": string;
}

interface IComponentState {
    sortIndicator: ISortIndicator;
    clientData: clientData;
    records: IRecord[];
    cursorPos: number;
    inputScrollPos: number;
    fieldUpdates: any
};

export const DEFAULT_STATE: IComponentState = {
    sortIndicator: {
        sortColumn: '@EditName',
        sortDirection: 'up',
        sortDataType: SortDataType.String
    },
    clientData: defualtClientData,
    records: [],
    cursorPos: -1,
    inputScrollPos: 0,
    fieldUpdates: {}
};

export default class UpdateSuppressionModal extends React.Component<IComponentProps, IComponentState> {
    sortableHeaderRefs: { [index: string]: any };
    inputRefs: { [index: string]: any };

    constructor(props: IComponentProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.sortableHeaderRefs = [];
        this.inputRefs = [];
    };

    public handleSorting = (sortIndicator: ISortIndicator, refKey: string = "") => {
        this.setState({
            sortIndicator,
            records: SortHelper.Sort(this.state.records, sortIndicator)
        }, () => refKey !== "" && this.sortableHeaderRefs[refKey].focus());
    };

    public getCustomHeader = () => {
        const {
            sortIndicator,
        } = this.state;

        const header = (headerProps: any) => {
            return (<thead>
                <tr>
                    <ARMHeaderCell
                        id={sortableHeaderIds[0]}
                        key={sortableHeaderIds[0]}
                        setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[0]] = input}
                        refKey={sortableHeaderIds[0]}
                        style={{ whiteSpace: "nowrap", overflow: "hidden", maxWidth: "250px", minWidth: "250px" }}
                        dataName="@EditName"
                        text="EDIT NAME"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        hasCorrectedTabIndex={true}
                    />
                    <ARMHeaderCell
                        style={{ whiteSpace: "nowrap", overflow: "hidden", maxWidth: "190px", minWidth: "190px" }}
                        dataName="@TypeBill"
                        text="Type Of Bill"
                        hasCorrectedTabIndex={true}
                    />
                    <ARMHeaderCell
                        style={{ whiteSpace: "nowrap", overflow: "hidden", maxWidth: "190px", minWidth: "190px" }}
                        dataName="@Suprressed"
                        text="Suppress"
                        hasCorrectedTabIndex={true}
                    />
                </tr>
            </thead>)
        }
        return header;
    };

    componentDidMount() {
        const { rtecpId, payerId, maintenanceInfo } = this.props
        const Payer = maintenanceInfo.ClientPayers.Payer.find((e: any) => e["@PayerID"] === payerId && e["@RTECPID"] === rtecpId)

        const profile = maintenanceInfo.EligibilityPayerProfiles.PayerProfile.find((e: any) => e["@RTECPID"] === Payer["@RTECPID"])
        const profileEdit = this.forceToArray(profile.Edits.Edit)

        const client: clientData = typeof Payer === 'object' ? { ...Payer } : defualtClientData
        const clientEdit = this.forceToArray(client.Edits.Edit);

        const records: IRecord[] = profileEdit.map(profileInfo => {
            const clientInfo = clientEdit.find((d: editData) => profileInfo["@EditId"] === d["@EditId"])
            const rowInfo = clientInfo ? clientInfo : this.genClientData(profileInfo["@EditId"], "#" + _.uniqueId())
            return { ...profileInfo, ...rowInfo }
        });

        this.setState({
            clientData: {
                ...client,
                Edits: {
                    ...client.Edits,
                    Edit: clientEdit
                }
            },
            records: SortHelper.Sort(records, this.state.sortIndicator)
        })
    }

    forceToArray = (input: any) => {
        if (Array.isArray(input)) {
            return input
        } else if (typeof input === 'object') {
            return [input]
        } else {
            return []
        }
    }

    public inputOnFocus(e: React.FocusEvent<HTMLInputElement>) {
        const {
            cursorPos,
            inputScrollPos
        } = this.state;

        e.target.setSelectionRange(cursorPos, cursorPos);
        e.target.scrollLeft = inputScrollPos;
    }

    public onChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const { clientData, records } = this.state
        const { Edit } = clientData.Edits
        const index: number = Array.isArray(Edit) ? Edit.findIndex((d: editData) => d["@EditId"] === id) : -1;
        const recordIndex = records.findIndex(record => record['@EditId'] === id);
        const textInputId = event.currentTarget.id;
        let editArray = Edit;

        if (index > -1) {

            const update = { ...Edit[index], "@TypeBill": event.target.value };
            editArray = [
                ...Edit.slice(0, index),
                update,
                ...Edit.slice(index + 1),
            ]

            this.setState((prevState, prevProps) => {
                const editId = update["@EditId"];
                const updatedState = {
                    ...prevState,
                    fieldUpdates: {
                        ...prevState.fieldUpdates,
                        [editId]: update
                    }
                };

                return updatedState
            })

        } else {
            const record = records.find(record => record['@EditId'] === id);

            if (record) {

                const update = {
                    "@ID": record["@ID"] ? record["@ID"] : "#" + _.uniqueId(),
                    "@EditId": record["@EditId"],
                    "@TypeBill": event.target.value,
                    "@Suprressed": record["@Suprressed"] ? record["@Suprressed"] : "N"
                }
                editArray = [
                    ...Edit,
                    update
                ]
                this.setState((prevState, prevProps) => {
                    const editId = update["@EditId"];
                    const updatedState = {
                        ...prevState,
                        fieldUpdates: {
                            ...prevState.fieldUpdates,
                            [editId]: update
                        }
                    }
                    console.log("updatedState", updatedState)
                    return updatedState
                })
            } else {
                console.log("ERROR: RECORD NOT FOUND!")
            }
        }

        this.setState({
            clientData: {
                ...clientData,
                Edits: {
                    Edit: editArray
                }
            },
            records: [
                ...records.slice(0, recordIndex),
                {
                    ...records[recordIndex],
                    "@TypeBill": event.target.value
                },
                ...records.slice(recordIndex + 1),
            ],
            cursorPos: event.target.selectionStart === null ? -1 : event.target.selectionStart,
            inputScrollPos: event.target.scrollLeft
        }, () => {
            this.inputRefs[textInputId].focus();
        })
    }

    public onCheck = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const { clientData, records } = this.state
        const { Edit } = clientData.Edits;
        const index: number = Array.isArray(Edit) ? Edit.findIndex((d: editData) => d["@EditId"] === id) : -1;
        const recordIndex = records.findIndex(record => record['@EditId'] === id);
        const checkboxId = event.currentTarget.id;
        let editArray = Edit;
        if (index > -1) {
            const update = {
                ...Edit[index],
                "@Suprressed": (Edit[index]["@Suprressed"] === "Y" ? "N" : "Y")
            };
            editArray = [
                ...Edit.slice(0, index),
                update,
                ...Edit.slice(index + 1),
            ];

            this.setState((prevState, prevProps) => {

                const editId = update["@EditId"];
                const updatedState = {
                    ...prevState,
                    fieldUpdates: {
                        ...prevState.fieldUpdates,
                        [editId]: update
                    }
                };

                return updatedState
            })

        } else {
            const record = records[recordIndex]
            if (record) {
                const update = {
                    "@ID": record["@ID"] ? record["@ID"] : "#" + _.uniqueId(),
                    "@EditId": record["@EditId"],
                    "@TypeBill": record["@TypeBill"] ? record["@TypeBill"] : "",
                    "@Suprressed": "Y"
                };

                editArray = [
                    ...Edit,
                    update
                ];
                
                this.setState((prevState, prevProps) => {
                    const editId = update["@EditId"];
                    const updatedState = {
                        ...prevState,
                        fieldUpdates: {
                            ...prevState.fieldUpdates,
                            [editId]: update
                        }
                    };
                    return updatedState
                });
            } else {
                console.log("ERROR: RECORD NOT FOUND!")
            }
        }

        this.setState({
            clientData: {
                ...clientData,
                Edits: {
                    Edit: editArray
                }
            },
            records: [
                ...records.slice(0, recordIndex),
                { ...records[recordIndex], "@Suprressed": (records[recordIndex]["@Suprressed"] === "Y" ? "N" : "Y") },
                ...records.slice(recordIndex + 1),
            ]
        }, () => {
            this.inputRefs[checkboxId].focus()
        });
    }

    public genClientData = (editId: string, id: string, typeBill: string = "", suprressed: string = "N") => {
        return {
            "@ID": id,
            "@EditId": editId,
            "@TypeBill": typeBill,
            "@Suprressed": suprressed
        };
    }

    public getCustomRow = () => {
        const row = ({ record }: IRowProps) => {
            const recordId = record["@EditId"];
            return (
                <tr id={recordId} key={recordId} style={{ margin: "0px", marginRight: "-10px" }}>
                    {
                        <>
                            <td style={{ height: "21px", width: "30%", margin: "0px", padding: "0px", paddingTop: "6px" }}>
                                {record["@EditName"]}
                            </td>
                            <td style={{ height: "21px", width: "60%", margin: "0px", paddingRight: "10px", paddingLeft: "10px", paddingTop: "2px", paddingBottom: "2px" }}>
                                <input
                                    id={"suppress_input_" + recordId}
                                    style={{ width: "100%" }}
                                    maxLength={100}
                                    ref={(input: any) => this.inputRefs[`suppress_input_${recordId}`] = input}
                                    type="text"
                                    value={record["@TypeBill"]}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChange(e, recordId)}
                                    onFocus={e => { this.inputOnFocus(e); }}
                                />
                            </td>
                            <td style={{ height: "21px", width: "10%", margin: "0px", padding: "0px", paddingLeft: "5px", paddingTop: "3px", paddingBottom: "2px" }}>
                                <input
                                    id={"suppress_checkbox_" + recordId}
                                    ref={(input: any) => this.inputRefs[`suppress_checkbox_${recordId}`] = input}
                                    type="checkbox"
                                    checked={record["@Suprressed"] === "Y"}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onCheck(e, recordId)}
                                />
                            </td>
                        </>
                    }
                </tr>
            );
        }

        return row
    }

    public handleSubmit = (e: React.ChangeEvent<HTMLButtonElement>) => {
        handleChange();
        const { onOK, rtecpId, payerId, maintenanceInfo, id, fieldEdits } = this.props
        const { Edit } = this.state.clientData.Edits
        const payerIndex = maintenanceInfo.ClientPayers.Payer.findIndex((e: any) => e["@PayerID"] === payerId && e["@RTECPID"] === rtecpId)
        const fieldUpdates = {
            ...fieldEdits,
            [id]: {
                ...fieldEdits[id],
                ...this.state.fieldUpdates
            }
        };

        const maintenanceInfoUpdate = {
            ...maintenanceInfo,
            ClientPayers: {
                ...maintenanceInfo.ClientPayers,
                Payer: [
                    ...maintenanceInfo.ClientPayers.Payer.slice(0, payerIndex),
                    {
                        ...maintenanceInfo.ClientPayers.Payer[payerIndex],
                        Edits: {
                            Edit: Edit
                        }
                    },
                    ...maintenanceInfo.ClientPayers.Payer.slice(payerIndex + 1),
                ]
            }
        };

        onOK(maintenanceInfoUpdate, fieldUpdates,payerId,rtecpId)
    };

    public onCancel = (e: React.ChangeEvent<HTMLButtonElement>) => {
        this.props.closeModal();
    };

    render() {
        const { clientData, records } = this.state
        const buttons = <OKCancelButtons
            onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.handleSubmit(e)}
            onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)}
        />;

        const header = this.getCustomHeader();
        const row = this.getCustomRow();

        return (
            <DialogWrapper title="Update Eligibility Payer Edit Suppression"
                helpUrl={"/Support/Help/HELP_Maint_EligibilityData.htm"}
                buttons={buttons}
                marginTop={`0px`}
                width={`600px`}
                bodySharpBorder={true}
                bodyBorderColor={Colors.white}
                >
                <UpdateSuppressionModalContainerDiv>
                    <Input domID="UpdateSuppressionModalInput-id" label="Payer"
                        initialValue={clientData["@DisplayName"]}
                        disabled />
                    <div style={{ height: "300px" }}>
                        <ARMGrid
                            key={gridID}
                            domID={gridID}
                            maxHeight={"300px"}
                            isFixedHeader
                            headerComponent={header}
                            columns={new Set([
                                { dataName: "@EditName", text: "Edit Name", sortable: true, isSorted: 0 },
                                { dataName: "@TypeBill", text: "Type Of Bill", sortable: false, isSorted: 0 },
                                { dataName: "@Suprressed", text: "Suppress", sortable: false, isSorted: 0 },
                            ])}
                            rowComponent={row}
                            records={records}
                            scrollToTop={false}
                            selectionKey={"@ID"}
                        />
                    </div>
                </UpdateSuppressionModalContainerDiv>
            </DialogWrapper>
        );
    }
}
