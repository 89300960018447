import { Reducer } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { IApiActionData } from "@scripts/util/ApiDataHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ISubmitClaimsUIState {
    isBusy: boolean;
    changed: boolean;
}

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const actionCreators: any = {
};

export const defaultState: ISubmitClaimsUIState = {
    isBusy: false,
    changed: false
};


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ISubmitClaimsUIState, KnownActions> = (state: ISubmitClaimsUIState = defaultState, action: KnownActions) => {

    return state;
}
