import * as React from 'react';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper, ContentColumnWrapper, DialogButton } from '@common/DialogWrapper';
import { DialogLegend, DialogFieldset } from '@common/DialogStyles';
import { Input } from '@optum-uicl/ui-core/dist';
import { RowWrapper, PhysicianDetailsRightWrapper } from './PhysicianStyles'
import { PhysicianInfo, DEFAULT_PHYSICIAN_INFO, FlWaFields, IlNyFields } from '@store/ui/PhysicianMaintenanceUI';
import { FlWaProperty, IlNyProperty } from './PhysicianMaintenance';

//-----------------
// STATE AND PROPS - Define the local component state and any component props here.
interface IComponentState {
}

interface IComponentProps {
    flWaFields: FlWaFields,
    ilNyFields: IlNyFields,
    updateFlWa: (id: FlWaProperty, val: string) => void,
    updateIlNy: (id: IlNyProperty, val: string) => void,
    resetFields: () => void,
}

//-----------------
// DEFAULTS - Define any default object values (including the state) here.
export const DEFAULT_STATE: IComponentState = {
};

//-----------------
// COMPONENT CLASS - Define the component class here.
export class PhysicianForDataInputs extends React.Component<IComponentProps, IComponentState> {
    // TODO - Create component prop defaults.
    //static defaultProps: IComponentProps = {

    //};

    constructor(props: IComponentProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }


    // COMPONENT METHODS - Define the component methods here

    //TODO - Componetize the input fields and their styles into a single React component
    //Use the existing pieces in the master PM component as a starting point.
    public render() {
        return (
            <React.Fragment>
                <PhysicianDetailsRightWrapper>
                    <ContentRowWrapper>
                        <ContentColumnWrapper>
                            <DialogFieldset>
                                <DialogLegend>For FL, WA</DialogLegend>
                                <ContentRowWrapper>
                                    <RowWrapper>
                                        <Input
                                            autoComplete="off"
                                            domID="groupno"
                                            className="text-input"
                                            label="Group No:"
                                            size="small"
                                            maxLength={5}
                                            initialValue={this.props.flWaFields.groupNumber}
                                            onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => 
                                                this.props.updateFlWa('groupNumber', e.target.value)
                                            }
                                        />
                                    </RowWrapper>
                                </ContentRowWrapper>
                                <ContentRowWrapper>
                                    <RowWrapper>
                                        <Input
                                            autoComplete="off"
                                            domID="groupsuffix"
                                            className="text-input"
                                            label="Group Suffix:"
                                            size="small"
                                            maxLength={1}
                                            initialValue={this.props.flWaFields.groupSuffix}
                                            onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                                this.props.updateFlWa('groupSuffix', e.target.value)
                                            }
                                        />
                                    </RowWrapper>
                                </ContentRowWrapper>
                                <ContentRowWrapper>
                                    <RowWrapper>
                                        <Input
                                            autoComplete="off"
                                            domID="doctorno"
                                            className="text-input"
                                            label="Doctor No:"
                                            size="small"
                                            maxLength={5}
                                            initialValue={this.props.flWaFields.doctorNumber}
                                            onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                                this.props.updateFlWa('doctorNumber', e.target.value)
                                            }
                                        />
                                    </RowWrapper>
                                </ContentRowWrapper>
                                <ContentRowWrapper>
                                    <RowWrapper>
                                        <Input
                                            autoComplete="off"
                                            domID="locCode"
                                            className="text-input"
                                            label="Location Code:"
                                            size="small"
                                            maxLength={1}
                                            initialValue={this.props.flWaFields.locationCode}
                                            onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                                this.props.updateFlWa('locationCode', e.target.value)
                                            }
                                        />
                                    </RowWrapper>
                                </ContentRowWrapper>
                            </DialogFieldset>
                        </ContentColumnWrapper>
                        <ContentColumnWrapper>
                            <DialogFieldset>
                                <DialogLegend>For IL, NY, PA, SC, TX</DialogLegend>
                                <ContentRowWrapper>
                                    <RowWrapper>
                                        <Input
                                            autoComplete="off"
                                            domID="billing-dept"
                                            className="text-input"
                                            label="Billing Dept:"
                                            size="small"
                                            maxLength={4}
                                            initialValue={this.props.ilNyFields.billingDept}
                                            onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                                this.props.updateIlNy('billingDept', e.target.value)
                                            }
                                        />
                                    </RowWrapper>
                                </ContentRowWrapper>
                                <ContentRowWrapper>
                                    <RowWrapper>
                                        <Input
                                            autoComplete="off"
                                            domID="acct-no"
                                            className="text-input"
                                            label="Physician Acct No:"
                                            size="small"
                                            maxLength={5}
                                            initialValue={this.props.ilNyFields.physicianAccountNumber}
                                            onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                                this.props.updateIlNy('physicianAccountNumber', e.target.value)
                                            }
                                        />
                                    </RowWrapper>
                                </ContentRowWrapper>
                                <ContentRowWrapper>
                                    <RowWrapper>
                                        <Input
                                            autoComplete="off"
                                            domID="id-code"
                                            className="text-input"
                                            label="ID/LOC Code:"
                                            size="small"
                                            maxLength={8}
                                            initialValue={this.props.ilNyFields.idLocCode}
                                            onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                                this.props.updateIlNy('idLocCode', e.target.value)
                                            }
                                        />
                                    </RowWrapper>
                                </ContentRowWrapper>
                                <ContentRowWrapper>
                                    <RowWrapper>
                                        <Input
                                            autoComplete="off"
                                            domID="dept-flag"
                                            className="text-input"
                                            label="Dept Flag:"
                                            size="small"
                                            maxLength={1}
                                            initialValue={this.props.ilNyFields.deptFlag}
                                            onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                                this.props.updateIlNy('deptFlag', e.target.value)
                                            }
                                        />
                                    </RowWrapper>
                                </ContentRowWrapper>
                            </DialogFieldset>
                        </ContentColumnWrapper>
                    </ContentRowWrapper>
                </PhysicianDetailsRightWrapper>
            </React.Fragment>
        )
    }
}