import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, batch } from 'react-redux';
import styled from 'styled-components';

import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray, VerifyParentAndArray, enforceArray } from '@scripts/util/ValidationHelpers';
import { getRawToken } from '@scripts/session/SecurityToken';

// store includes
import { ApplicationState } from '@store/index';
import { ICookies_Config } from '@store/ConfigData';
import { emptyFilter } from '@store/api/RSRTFilterStore';
import { IRSRTFilterState, IRSRTFilterActions, IRSRTFilterActionProps, actionCreators/*, validationCallback */ } from '@store/RSRTFilter';

// uicl
import { Input, Button, SelectDropdown } from '@optum-uicl/ui-core/dist';

// commonResources
import { SecurityBits } from '@commonResources/SecurityFunctionList';
import { URLs } from '@commonDevResources/constants';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { deepCopyFunction, isMissingEndQuote, IsValidType } from '@commonResources/validations';

// common
import { RadioButtonComponent } from '@common/RadioButton';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { FormTypeList } from '@common/FilterRelated/FormTypeList';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { DialogButtonRefine, DialogButtonCancel, DialogButton, DialogButtonSubmit } from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { CheckBoxComponent, CheckBoxLabel } from '@common/CheckBox';
import { ARMDatePicker } from '@common/DateOrTimeRelated/ARMDatePicker';

import { ARMNarrowInput } from '../../common/UICLWrappers/ARMNarrowInput';
import { array } from 'prop-types';
import { SelectDropdownValue } from '@optum-uicl/ui-core/dist/Atoms/SelectDropdown/types';
import { Checkbox } from '@optum-uicl/ui-core/dist';

interface IComponentState {
    disableBtn: boolean;
    showReleaseClaimModal: boolean;
    releaseClaimModalAnswer: boolean;
};


export const DEFAULT_STATE: IComponentState = {
    disableBtn: false,
    showReleaseClaimModal: false,
    releaseClaimModalAnswer: false
};


export interface IRSRTFilterComponentProps {
    canEdit: boolean,  // Permission
    canView: boolean,  // SecurityClearance
    canCreate: boolean, // FN_CREATE_FILTERS - enables Save button
    title: string,
    ncsIsEnabled: boolean,
    type?: string;  // be careful with this, the word "type" is used too much in filter, this value should be "Claim|Report|Remit"
    process: string;
    parameter?: any;
    apiType: string;
    executeJS?: any;
    execNode?: string;
    execAction?: string;
    execProcess?: string;
    execAttribs?: string;
    extraInstruction?: string;
    claimStateRestrictions?: string[];  // formerly XSL Transform ClaimState restrictions - BEWARE if you are working on "delete" you need to build this list based off security bit checks                                         
    claimStatusExclusions?: string[];    //  formerly XSL Transform ClaimStatus restriction - BEWARE if you are working on ("manual", "submit", "statereport") 
    testMode?: boolean;
    instruction?: string; // only send if overriding (viewX12claim)
    helpUrl?: string;  // only send if overriding (viewx12claim)
};

type IRSRTFilterOwnProps = IRSRTFilterComponentProps & RouteComponentProps<{}>;
type IRSRTFilterProps = IMergeApiComponentProps<IRSRTFilterState, IRSRTFilterActionProps, IRSRTFilterOwnProps>;
/////////////////////////////////////////////////////////////////////////////////////////////

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const ContentRowWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    padding-bottom:5px;

    #rsrt_accepted_wrapper, #rsrt_warning_wrapper, #rsrt_rejected_wrapper {
        label {
            padding-left: 5px;
        }
        
    }
    span[id*='rsrt_']{
        width:100px;
        flex: 1 1 auto;
    }
    
    .selectDropdown__control {
            box-shadow: rgba(155, 161, 169, 0.25) 0px 2px 2px 0px inset;
            border-radius:3px;
            margin-bottom:5px !important;        
        }
    .selectDropdown__container,.selectDropdown__control {
            margin-bottom:5px !important;
        }
    
`;

export const InputRowWrapper = styled.div`
    display: flex;  
    span { width: 100%; }
    #rsrt_controlNum, #fin-class, #cli-specific-1, #cli-specific-2, #cli-specific-3 {
        justify-content: space-between;
        margin-right: 5px;
        .narrow_input { width: 305px; }
    }
    input {
        box-shadow: rgba(155, 161, 169, 0.25) 0px 2px 2px 0px inset;
    }
    
`;

export const ContentColumnWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding:3px;
`;

export const ContentRowWrapperDate = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin-bottom: 5px;
    padding-top: 5px;
    #rsrt_datefield {
        width: 200px;
        height: 32px !important;
    }
    #rsrt_datefrom, #rsrt_dateto {
        width: 85px;
    }
    .SingleDatePickerInput {
        width: 150px;
        margin-left: 5px;
        height:36px !important;
    }
    .selectDropdown__control .css-jlyzsf-control{
        40px
    }
    .eKcwdS.small .selectDropdown__control{
        height:32px;
    }
    
    .DayPickerNavigation_button img:focus {
        box-shadow: rgb(12, 85, 184) 0px 0px 0px 2px;

    }
    .SingleDatePickerInput .SingleDatePickerInput_calendarIcon:focus {
        box-shadow: rgb(12, 85, 184) 0px 0px 0px 2px;
    }
    .SingleDatePickerInput .SingleDatePickerInput_calendarIcon svg {
    vertical-align: middle;
    padding: 2px;
    }
    .SingleDatePickerInput .SingleDatePickerInput_clearDate:focus {
        box-shadow: rgb(12, 85, 184) 0px 0px 0px 2px;
    }
    .SingleDatePickerInput .DateInput .DateInput_input{
        outline-color: rgb(12, 85, 184)
        }
    .CalendarDay:focus:not(.CalendarDay__blocked_calendar){
        box-shadow: rgb(12, 85, 184) 0px 0px 0px 2px;
    }
 
`;

export const FilterColumn3 = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 110px;
    padding-right:10px;
`;

export const FilterColumn2 = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 110px;
    padding:5px;
    .formtypewrapper {
        width: fit-content; 
    }
    .rsrtfilter_selectformtype_select {
        // height: 60px; 
        width: 140px;
    }
`;

export const BatchDropdownWrapper = styled.div`
    margin: 0px 5px;
`;

const DATE_DISCHARGE_ID: string = "3";

export class RSRTFilter extends React.Component<IRSRTFilterProps, IComponentState> {
    static defaultProps: IRSRTFilterComponentProps = {
        canEdit: false,   // this is your old Permission
        canView: false,
        canCreate: false,
        ncsIsEnabled: false,
        process: "",
        parameter: {},  //  todo  view usage on spinoff
        apiType: "RSRTFilter",
        title: "Claim Filter",
        type: "Claim",
        executeJS: undefined,
        instruction: "Turn on options and enter information until you have created the most precise set of requirements. Click Refine to check the group of claims before you continue. Click View Claims to grab the claims without checking them.",
        extraInstruction: "",
        helpUrl: "/Support/Help/HELP_RRTFilter.htm",
        //claimStateRestrictions: ['', '1', '3', '11'],
        claimStatusExclusions: [],  // should be passed as ['R'], for "manual", "submit","statereport"
        testMode: false,

    };
    process_lowercase: string;
    oneDay: Number;
    today: string;
    minDate: string;
    maxDate: string;
    eligibilityActive: boolean | undefined;
    directEntryActive: boolean | undefined;

    checkRua: boolean;
    clientId: string;
    tabType: string;
    overrideUrl: string;  // helper for refine

    constructor(props: IRSRTFilterProps) {
        super(props);
        this.state = DEFAULT_STATE;

        this.clientId = sessionStorage.getItem("Client")?.split(' ')[0] ?? "";
        this.process_lowercase = (this.props.process)?.toLowerCase();
        this.eligibilityActive = undefined;
        this.directEntryActive = undefined;
        this.checkRua = false;
        this.tabType = '';
        this.overrideUrl = '';

        // initializing dates for later usage
        this.oneDay = 1000 * 60 * 60 * 24;
        var myDate = new Date();
        this.today = this.getDateInMMDDYYYYFormat(myDate);

        this.maxDate = this.dateAdd(myDate, 'y', 2);
        this.minDate = this.dateAdd(myDate, 'y', -1);
    }

    public componentDidUpdate(prevProps: any, prevState: any) {

        const postAction = this.props.dataStore.ui.postAction;
        if (postAction !== '') {
            this.setPostAction("");
            if (postAction == "SubmitFilter") {
                this.postAction_SubmitFilter();
            } else if (postAction == "RefineFilter") {

                this.postAction_RefineFilter();
            }
        }
    }
     
    public componentWillUnmount() {
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public componentDidMount() {

        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.goToHomePage();
        }

        this.onLoad();  // logic from the onload
        this.props.action.api.loadData(URLs.api + '/api/data/GetRRTFilterFormData', this.validationCallback.bind(this), this.errorCallback.bind(this));
    }

    public async submitFilter() {
        // make the call
        if (this.props.execNode !== "" && this.props.execAction !== "") {
            this.checkRua = true;
            if (await this.validateFilter()) {
                this.setPostAction("SubmitFilter");
            }
        }
    }

    public postAction_SubmitFilter() {
        this.setBusy(true);
        console.log(`postAction_SubmitFilter -> ${this.props.execNode}`)
        
        switch (this.props.execNode) {
            case "MultiClaim":
            case "PaperClaim":
                this.executeMultiClaimProcess();
                break;
            //case "ClaimChange":
            //    this.executeClaimChangeProcess();
            //    break;
            case "Report":
                this.executeClaimReportProcess();
                break;
            case "ChangeClaimState":
            case "Export":
                alert('todo implement');
                break;
            default:
                console.error('ClaimFilter: unknown execNode found ' + this.props.execNode);
                break;
        }
    }

    public async executeClaimReportProcess() {

        var xmlData: APIXMLCB_XmlCallBackModel = {};

        // validations on inputs before making call - i do not know that any of these are required
        if (!this.props.execAction || (this.props.execAction && this.props.execAction.length == 0))
            return;

        this.setBusy(true);

        var jsonData: any = {};
        jsonData.Report = {}; // execNode
        jsonData.Report['@Action'] = this.props.execAction;
        jsonData.Report['@Type'] = "Claim";  // hardcoded in filter.asp
        jsonData.Report['@Process'] = (this.props.execProcess && this.props.execProcess.length > 0) ? this.props.execProcess : this.props.process;

        if (this.props.parameter && this.props.parameter?.ReportRequests) {
            jsonData.Report.ReportRequests = this.props.parameter?.ReportRequests;
        }

        jsonData.Report = Object.assign(jsonData.Report, JSON.parse(await this.getFilterData()));

        //  debug only
        console.log(' ** executeClaimReportProcess clicked **');
        console.log(jsonData.Report);

        xmlData.Action = this.props.execAction ? this.props.execAction : "";
        xmlData.ViewOrigin = '/Claims/filter.asp';  // see note in type def
        xmlData.FilterJsonData = JSON.stringify(jsonData);

        await fetch(URLs.api + '/api/data/PostMultiClaim',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
                if (response.status === 200) {
                    this.goToHomePage();
                    return;
                } else {
                    throw new Error('RSRTFilter: Failed to create the report job ' + response.status + ' ' + response.statusText);
                }
            })
            .catch(error => {
                console.error('RSRTFilter: ' + error);
                this.errorCallback("Failed to create the report job.");
            })
            .finally(() => {
                this.setBusy(false);
            });

    }

    public onLoad() {

        if (this.process_lowercase === "changeclaimstatesdrnotsent" || this.process_lowercase === "delete/rrt" ||
            this.process_lowercase === "validate/rrt" || this.process_lowercase === "rsrtcustom") {
            this.setClaimState('13');
            this.props.action.ui.disableSentToState(true);
        } else if (this.process_lowercase === "changeclaimstatesdrsent") {
            this.setClaimState('15');
            this.props.action.ui.disableSentToState(true);
            this.props.action.ui.disableNotSentToState(true);
        } else if (this.process_lowercase === "viewedit/rrt") {
            this.setClaimState('13');
        }
        if ( this.process_lowercase === "changeclaimstatesdrsent") {
            this.disableFieldsonRTTProfileSelected(true);
        }
        if (this.process_lowercase === "changeclaimstatesdrnotsent" || this.process_lowercase === "changeclaimstatesdrsent") {
            return;
        }

        this.props.action.ui.setProcessName(this.process_lowercase);

        this.loadFilterCriteria(0);
                return
    }

    public errorCallback(dataStatus: string): boolean {
        this.setBusy(false);
        const str = 'An error occurred retrieving the  call for RSRTFilter. Error: ' + dataStatus;
        console.error(str);
        return true;
    }

    public validationCallback_LoadFilter: ValidationCallback<APIRSRT_FilterWrapper> = (data: any): data is APIRSRT_FilterWrapper => {

        let testObj: APIRSRT_FilterWrapper = data as APIRSRT_FilterWrapper;
        var currentData;
        let validShape: boolean = ValidateJSONSchema(testObj, "APIRSRT_FilterWrapper");
        if (validShape) {
            /* extra validation togo here */
        }

        return validShape;
    }

    public isClientUser() {
        let userId = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "isClientUser");
        return (userId && userId.value === 'True');
    }

    public dateAdd(date: any, type: string, amount: number) {
        try {
            var y = date.getFullYear(),
                m = date.getMonth(),
                d = date.getDate();
            if (type === 'y') {
                y += amount;
            };
            if (type === 'm') {
                m += amount;
            };
            if (type === 'd') {
                d += amount;
            };
            var xx = new Date(y, m, d);
            return this.getDateInMMDDYYYYFormat(xx);
        }
        catch (e) {
            console.error('Unable to properly format date, dateAdd');
        }
        return "00/00/0000";
    }

    public getDateInMMDDYYYYFormat(input: any): string {
        try {
            var mm = input.getMonth() + 1;
            var dd = input.getDate();
            return (mm < 10 ? '0' + mm : mm) + '/' + (dd < 10 ? '0' + dd : dd) + '/' + input.getFullYear();
        }
        catch (e) {
            console.error('Unable to properly format date');
        }
        return "00/00/0000";
    }

    public validationCallback(data: any): boolean {
        this.setBusy(false);
        return true;
    }

    public setBusy(val: boolean) {
        this.props.action.ui.setBusy({ api: this.props.dataStore.api.data, uiData: { value: val } });
    }

    public applyFocus(fld: string) {
        // FYI - can't get Input (ui-core) to handle ref properly (ref.current always null), going at the dom
        var x = document.getElementById(fld);
        if (x) x.focus();
    }

    // NOTE this did not work as expected because subsequent re-renders were losing the focus.  If this is going to be
    // required functionality will need to keep resetting on a lifecycle event (componentdidupdate?)
    public setFocus(fld: string) {
        this.props.action.ui.setFocusField(fld);
        this.applyFocus(fld);
    }

    public validateDateField(data: any, focusFldPrefix: string): boolean {
        var id = this.getData(data, "@ID");
        var fromVal = this.getData(data, "@From");
        var toVal = this.getData(data, "@To");

        if (id == "" || id == "0") {
            if (fromVal != "" || toVal != "") {
                //this.onAlert("Please select a date field.");
                this.setFocus(focusFldPrefix);
                return false;
            }
        }

        return true;
    }

    ///////////////////////////////////////////////////////////////////////////////////
    //   date field reference
    //   DateField and DateField2 are DateField @From and @To with domId 'claimfilter_<quickstart|adv|pay|elig>_datefieldfrom', with an @ID field
    ///////////////////////////////////////////////////////////////////////////////////

    public validateDateField_Check2(data: any, focusFld: string): boolean {
        var fromVal = this.getData(data, "@From");
        var toVal = this.getData(data, "@To");

        if (fromVal == "00/00/0000" || fromVal == "0/0/0000" || fromVal == "00/0/0000" || fromVal == "0/00/0000" || (fromVal.length > 0 && fromVal.length < 8)) {
            if (toVal !== "00/00/0000" && toVal !== "0/0/0000" && toVal !== "00/0/0000" && toVal !== "0/00/0000" || (toVal.length > 0 && toVal.length < 8)) {
                this.onAlert("Please check date validity.");
                this.setFocus(focusFld);
                return false;
            }
        }

        try {
            var fromDate = new Date(fromVal);
            var toDate = new Date(toVal);

            if ((fromDate && fromDate.valueOf() > 0 && toDate && toDate.valueOf() > 0 && fromDate > toDate)) {
                this.onAlert("Please check date validity.");

                //this.setFocus(focusFld);
                //this.setFocus('rsrt_datefrom')

                return false;
            }
        }
        catch (e) {

        }

        return true;
    }

    public getDateFromControl(dateVal: string, focusFld: string) {
        var formattedDate = new Date(dateVal);
        if (dateVal != "") {
            if (formattedDate.valueOf() != 0) {
                var today = new Date();
                var oneDay = 1000 * 60 * 60 * 24;
                var yearOffset = dateVal.lastIndexOf("/");
                var monthOffset = dateVal.indexOf("/");
                var dayOffset = dateVal.indexOf("/", monthOffset + 1);

                if (yearOffset == -1 || monthOffset == -1 || dayOffset == -1) {
                    //this.onAlert("Please enter a valid date.");
                    this.setFocus(focusFld);
                    return undefined;
                }

                // need to build an actual date
                if (dateVal.substr(yearOffset + 1) == "0000") {
                    var month = dateVal.substr(0, monthOffset);
                    var day = dateVal.substr(monthOffset + 1, dayOffset - monthOffset - 1);
                    var timeDiff = Math.abs(today.getTime() - Math.abs((+month) * 30 * oneDay + (+day) * oneDay));

                    // reset the value of FromDate to the actual date
                    formattedDate = new Date(timeDiff);
                }
            }
        }
        return formattedDate;
    }

    public async validateFilter(): Promise<boolean>      {

        if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.AccountNumber, "@Value"), "alphanumeric", 'rsrt_controlNum'))
            return false;

        var fieldRegex = "DO_NOT_UPDATE_3";
        var bNotSentToState: boolean = (this.props.dataStore.ui.filter.Filter.ClaimState['@ID'] === '13')
        fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.AccountNumber, "@Value"), "alphanumeric", bNotSentToState, /[^\w '\.\-/@,":]/g);
        if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
            this.updateAccountNumber(fieldRegex);
        }
        if (this.getData(this.props.dataStore.ui.filter.Filter.ClaimState, '@ID') === '15') {
            if ((this.getData(this.props.dataStore.ui.filter.Filter.DateField, '@ID') === '0' || this.getData(this.props.dataStore.ui.filter.Filter.DateField, '@ID') === '') &&
                (this.getData(this.props.dataStore.ui.filter.Filter.SentToStateBatch, '@ID') === '0' || this.getData(this.props.dataStore.ui.filter.Filter.SentToStateBatch, '@ID') === '') &&
                this.getData(this.props.dataStore.ui.filter.Filter.AccountNumber, '@Value').length < 5) {
                var currentDateField: any = this.props.dataStore.ui.filter.Filter?.DateField;
                if (currentDateField) {
                    if (currentDateField['@From'] === '') currentDateField['@From'] = "00/00/0000";
                    if (currentDateField['@To'] === '') currentDateField['@To'] = "00/00/0000";
                } else {
                    currentDateField = { '@ID': '', '@From': '00/00/0000', '@To': '00/00/0000' };
                }
                this.updateDateField(currentDateField);
                this.onAlert("Please select a date field.");
                this.setFocus('rsrt_datefield');
                return false;
            }
        }

        if (this.getData(this.props.dataStore.ui.filter.Filter.DateField, '@ID') === '0' || this.getData(this.props.dataStore.ui.filter.Filter.DateField, '@ID') === '') {
            var currentDateField: any = this.props.dataStore.ui.filter.Filter?.DateField;
            if (currentDateField['@From'] != '' || currentDateField['@To'] != '') {
                this.onAlert("Please select a date field.");
                this.setFocus('rsrt_datefield');
                return false;
            }
        } else {
            var currentDateField: any = this.props.dataStore.ui.filter.Filter?.DateField;
            if (currentDateField) {
                if (currentDateField['@From'] === '') {
                    if (currentDateField['@To'] === '') {
                        if (this.getData(this.props.dataStore.ui.filter.Filter.AccountNumber, '@Value').length < 5) {
                            this.onAlert("Please enter a date.");
                            this.setFocus('rsrt_datefield');
                            return false;
                        }
                    } else {
                        currentDateField['@From'] = currentDateField['@To'];
                    }
                } else {
                    if (currentDateField['@To'] === '') {
                        currentDateField['@To'] = currentDateField['@From'];
                    }
                }
            }
        }

        this.updateDateField(currentDateField);
        if (!this.validateDateField_Check2(currentDateField, "rsrt_datefield")) {
            return false;
        }

        /*looks like only "rsrtcustom" process actually runs validateFilter */
        if (this.process_lowercase === "rsrtcustom") {
            return await this.checkRua_validateFilter();
        } else {
            return true;
        }
    }

    async  getRRTClientProfile(profileId : number) {
        // read the profile
        const xmlData: APIXMLCB_XmlCallBackModel = {};
        xmlData.Type = "Claim";
        xmlData.ViewOrigin = '/Claims/rsrtfilter.asp';  // see note in type def
        xmlData.FilterId = profileId;

        const response = await fetch(URLs.api + '/api/data/getRRTClientProfile',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            });

        if (!response.ok) {
            console.error(`RSRTFilter: ${response.status}` );
            this.errorCallback("Unable to process filter.");
        } 

        const data = await response.json();
        return data;
    };
 
    public async getFilterData(): Promise<string> {

        var data: any = this.props.dataStore.ui.filter;
        var bHasRRTProfileSelected: boolean = false;
        var rrtData: any = {};
        var profileId: number = -1;

        if (data.Filter.RRTProfileID &&
            (this.getData(data.Filter.RRTProfileID, '@ID') != "" && this.getData(data.Filter.RRTProfileID, '@ID') != "0")) {
            //When YES RRT Filter is Selected
            bHasRRTProfileSelected = true;
            var rrtProfileId = this.getData(data.Filter.RRTProfileID, '@ID');
            profileId = parseInt(rrtProfileId, 10);
        }

        if (!bHasRRTProfileSelected || isNaN(profileId)) {
            return this.getFilterData_post(null /* rrtData */, bHasRRTProfileSelected);
        } else {
            if (profileId < 1) {
                return this.getFilterData_post(null /* rrtData */, bHasRRTProfileSelected);
            } else {
                const data: any = await this.getRRTClientProfile(profileId);
                return this.getFilterData_post(JSON.parse(data), bHasRRTProfileSelected);
            }
        }
    }

    public async checkRua_validateFilter() {
            /*looks like only rsrtcustom process runs validateFilter. Please test*/
            var xmlData: APIXMLCB_XmlCallBackModel = {};
            xmlData.Type = "Claim";
            xmlData.ViewOrigin = '/Claims/rsrtfilter.asp';  // see note in type def
            xmlData.FilterJsonData = await this.getFilterData();
            xmlData.TestMode = this.props.testMode;

            fetch(URLs.api + '/api/data/ValidateFilter',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `${getRawToken()}`
                    },
                    body: JSON.stringify(xmlData),
                })
                .then(async (response) => {

                    // get json response here
                    let data: any = await response.json();

                    if (response.status === 306) {
                        //let cleanData = data.message.replace(/:"/g, "-");
                        this.onAlert(data.message);  // error is in message
                        return false;
                    } else if (response.status === 200) {
                        //   this.onRefineSuccess(data, url);  // data is just in data
                        return true;
                    } else {
                        throw new Error(`RRTFilter: unable to run filter ${response.status}. ${response.statusText}: ${(data && data.message ? data.message : data)}`);
                    }
                })
                .catch(error => {
                    console.error('RRTFilter: ' + error);
                    this.errorCallback("Unable to process filter.");
                })
                .finally(() => {

                });
        //}
        return true;
    }

    public getFilterData_post(rrtData: any, bHasRRTProfileSelected : boolean): string {

        var data: any = deepCopyFunction({ ...this.props.dataStore.ui.filter });

        if (bHasRRTProfileSelected) {

            ///////////////////////////////
            // CLEAN UP RRT PROFILE FILTER FIELDS
            ///////////////////////////////
            data.Filter['@SubType'] = rrtData.Filter['@SubType'];

            if (rrtData.Filter.ServiceType) {
                if (this.getData(rrtData.Filter.ServiceType, '@Inpatient').length == 0
                    && this.getData(rrtData.Filter.ServiceType, '@Outpatient').length == 0
                    && this.getData(rrtData.Filter.ServiceType, '@All').length == 0) {
                    delete rrtData.Filter['ServiceType'];
                } else {
                    if (this.getData(rrtData.Filter.ServiceType, '@Inpatient').length == 0)
                        delete rrtData.Filter.ServiceType['@Inpatient'];
                    if (this.getData(rrtData.Filter.ServiceType, '@Outpatient').length == 0)
                        delete rrtData.Filter.ServiceType['@Outpatient'];
                    if (this.getData(rrtData.Filter.ServiceType, '@All').length == 0)
                        delete rrtData.Filter.ServiceType['@All'];
                }
            }
            //now we check again and insert into our regular filter if still viable
            if (rrtData.Filter.ServiceType) {
                data.Filter['ServiceType'] = rrtData.Filter.ServiceType;
            }

            if (rrtData.Filter.BillingOption) {
                if (this.getData(rrtData.Filter.BillingOption, '@Primary').length == 0
                    && this.getData(rrtData.Filter.BillingOption, '@Rebill').length == 0
                    && this.getData(rrtData.Filter.BillingOption, '@Secondary').length == 0
                    && this.getData(rrtData.Filter.BillingOption, '@Tertiary').length == 0
                    && this.getData(rrtData.Filter.BillingOption, '@All').length == 0) {
                    delete rrtData.Filter['BillingOption'];
                } else {
                    if (this.getData(rrtData.Filter.BillingOption, '@Primary').length == 0)
                        delete rrtData.Filter.BillingOption['@Primary'];
                    if (this.getData(rrtData.Filter.BillingOption, '@Rebill').length == 0)
                        delete rrtData.Filter.BillingOption['@Rebill'];
                    if (this.getData(rrtData.Filter.BillingOption, '@Secondary').length == 0)
                        delete rrtData.Filter.BillingOption['@Secondary'];
                    if (this.getData(rrtData.Filter.BillingOption, '@Tertiary').length == 0)
                        delete rrtData.Filter.BillingOption['@Tertiary'];
                    if (this.getData(rrtData.Filter.BillingOption, '@All').length == 0)
                        delete rrtData.Filter.BillingOption['@All'];
                }
            }
            if (rrtData.Filter.BillingOption) {
                data.Filter['BillingOption'] = rrtData.Filter.BillingOption;
            }

            if (rrtData.Filter.TypeOfBill) {
                if (this.getData(rrtData.Filter.TypeOfBill, '@Value').length == 0) {
                    delete rrtData.Filter['TypeOfBill'];
                } else {
                    data.Filter['TypeOfBill'] = rrtData.Filter.TypeOfBill;
                }
            }

            //UserField 2-8: { '@ID': '', '@Value': ''},
            if (rrtData.Filter.UserField) {
                if (this.getData(rrtData.Filter.UserField, '@Value').length == 0) {
                    delete rrtData.Filter['UserField'];
                } else {
                    data.Filter['UserField'] = rrtData.Filter.UserField;
                }
            }
            if (rrtData.Filter.UserField2) {
                if (this.getData(rrtData.Filter.UserField2, '@Value').length == 0) {
                    delete rrtData.Filter['UserField2'];
                } else {
                    data.Filter['UserField2'] = rrtData.Filter.UserField2;
                }
            }
            if (rrtData.Filter.UserField3) {
                if (this.getData(rrtData.Filter.UserField3, '@Value').length == 0) {
                    delete rrtData.Filter['UserField3'];
                } else {
                    data.Filter['UserField3'] = rrtData.Filter.UserField3;
                }
            }
            if (rrtData.Filter.UserField4) {
                if (this.getData(rrtData.Filter.UserField4, '@Value').length == 0) {
                    delete rrtData.Filter['UserField4'];
                } else {
                    data.Filter['UserField4'] = rrtData.Filter.UserField4;
                }
            }
            if (rrtData.Filter.UserField5) {
                if (this.getData(rrtData.Filter.UserField5, '@Value').length == 0) {
                    delete rrtData.Filter['UserField5'];
                } else {
                    data.Filter['UserField5'] = rrtData.Filter.UserField5;
                }
            }

            //DisplayOrder - only used when no rrt profile filter chosen
            if (data.Filter.DisplayOrder) {
                delete data.Filter['DisplayOrder'];
            }
        } else {
            delete data.Filter['RRTProfileID'];
        }

        ///////////////////////////////
        // CLEAN UP RSRT FILTER FIELDS
        ///////////////////////////////
        delete data.Filter['@Name'];

        //ClaimState: { '@ID': '' },  // NOTE I think this should always have a value
        if (data.Filter.ClaimState) {
            if (this.getData(data.Filter.ClaimState, '@ID').length == 0)
                delete data.Filter['ClaimState'];
        }

        // SentToStateBatch: { '@ID': '' },
        if (data.Filter.SentToStateBatch) {
            // delete data.Filter.SentToStateBatch['@Name'];
            if (this.getData(data.Filter.SentToStateBatch, '@ID').length == 0 || this.getData(data.Filter.SentToStateBatch, '@ID') == "0")
                delete data.Filter['SentToStateBatch'];
        }

        // AccountNumber: { '@Value': '' },
        if (data.Filter.AccountNumber) {
            if (this.getData(data.Filter.AccountNumber, '@Value').length == 0)
                delete data.Filter['AccountNumber'];
        }
        //SubmittedFile: { '@ID': '' },
        if (data.Filter.SubmittedFile) {
            if (this.getData(data.Filter.SubmittedFile, '@ID').length == 0 || this.getData(data.Filter.SubmittedFile, '@ID') == "0")
                delete data.Filter['SubmittedFile'];
        }

        ///////////////////////////////
        // CLEAN UP COMMON FIELDS TO BOTH FILTERS
        ///////////////////////////////

        //DateField: { '@ID': '', '@From': '', '@To': '' },
        if (data.Filter.DateField) {
            if (this.getData(data.Filter.DateField, '@ID').length == 0 || this.getData(data.Filter.DateField, '@ID') == "0") {
                delete data.Filter['DateField'];
                if (bHasRRTProfileSelected && rrtData.Filter.DateField) {
                    if (this.getData(rrtData.Filter.DateField, '@ID').length >= 0 && this.getData(rrtData.Filter.DateField, '@ID') != "0") {
                        data.Filter['DateField'] = rrtData.Filter['DateField']
                    }
                }
            }
        }

        //UserField6: { '@ID': '', '@Value': ''},
        if (data.Filter.UserField6) {
            if (this.getData(data.Filter.UserField6, '@Value').length == 0) {
                delete data.Filter['UserField6'];
                if (bHasRRTProfileSelected && rrtData.Filter.UserField6) {
                    if (this.getData(rrtData.Filter.UserField6, '@Value').length > 0 && this.getData(rrtData.Filter.UserField6, '@Value') != "") {
                        data.Filter['UserField6'] = rrtData.Filter['UserField6']
                    }
                }
            }
        }

        //UserField7: { '@ID': '', '@Value': ''},
        if (data.Filter.UserField7) {
            if (this.getData(data.Filter.UserField7, '@Value').length == 0) {
                delete data.Filter['UserField7'];
                if (bHasRRTProfileSelected && rrtData.Filter.UserField7) {
                    if (this.getData(rrtData.Filter.UserField7, '@Value').length > 0 && this.getData(rrtData.Filter.UserField7, '@Value') != "") {
                        data.Filter['UserField7'] = rrtData.Filter['UserField7']
                    }
                }
            }
        }

        //UserField8: { '@ID': '', '@Value': ''},
        if (data.Filter.UserField8) {
            if (this.getData(data.Filter.UserField8, '@Value').length == 0) {
                delete data.Filter['UserField8'];
                if (bHasRRTProfileSelected && rrtData.Filter.UserField8) {
                    if (this.getData(rrtData.Filter.UserField8, '@Value').length > 0 && this.getData(rrtData.Filter.UserField8, '@Value') != "") {
                        data.Filter['UserField8'] = rrtData.Filter['UserField8']
                    }
                }
            }
        }

        //Financial Class: { '@Value': '' },
        if (data.Filter.FinancialClass) {
            if (this.getData(data.Filter.FinancialClass, '@Value').length == 0) {
                delete data.Filter['FinancialClass'];
                if (bHasRRTProfileSelected && rrtData.Filter.FinancialClass) {
                    if (this.getData(rrtData.Filter.FinancialClass, '@Value').length > 0 && this.getData(rrtData.Filter.FinancialClass, '@Value') != "") {
                        data.Filter['FinancialClass'] = rrtData.Filter['FinancialClass']
                    }
                }
            }
        }

        //FormTypes: { FormType: [] }
        if (data.Filter.FormTypes) {
            if (data.Filter.FormTypes.FormType) {
                if (data.Filter.FormTypes.FormType['@ID'] === '0' || (data.Filter.FormTypes.FormType['@ID']) === '' || (rrtData && data.Filter.FormTypes.FormType['@ID'] === undefined)) {
                    delete data.Filter.FormTypes['FormType'];
                    delete data.Filter['FormTypes'];
                    if (bHasRRTProfileSelected && rrtData.Filter.FormTypes) {
                        if (rrtData.Filter.FormTypes.FormType && !data.Filter.FormTypes) {
                            //let FormType: APIRSRT_FilterSimpleID = [];
                            data.Filter['FormTypes'] = { FormType: [] };
                            if (data.Filter.FormTypes.FormType) { 
                                data.Filter.FormTypes.FormType = [rrtData.Filter.FormTypes['FormType']];
                    }
                }
            }
        }
            }
        }

        // TODO Clarify re ClaimStatus: {'@Accepted': '', '@Warning': '', '@Rejected': '', '@All': '' },
        if (data.Filter.ClaimStatus) {
            if ((this.getData(data.Filter.ClaimStatus, '@Accepted').length == 0
                && this.getData(data.Filter.ClaimStatus, '@Warning').length == 0
                && this.getData(data.Filter.ClaimStatus, '@All').length == 0
                && this.getData(data.Filter.ClaimStatus, '@Rejected').length == 0))
                delete data.Filter['ClaimStatus'];
            else {
                if (this.getData(data.Filter.ClaimStatus, '@Accepted').length == 0)
                    delete data.Filter.ClaimStatus['@Accepted'];
                if (this.getData(data.Filter.ClaimStatus, '@Warning').length == 0)
                    delete data.Filter.ClaimStatus['@Warning'];
                if (this.getData(data.Filter.ClaimStatus, '@All').length == 0)
                    delete data.Filter.ClaimStatus['@All'];
                if (this.getData(data.Filter.ClaimStatus, '@Rejected').length == 0)
                    delete data.Filter.ClaimStatus['@Rejected'];
            }
        }

        return JSON.stringify(data);
    }

    public removeRRTFromFilterData(data: APIRSRT_FilterWrapper){

        let bHasRRTProfileSelected: boolean = false; 
        const profileIdInFilter = "RRTProfileID" in data.Filter;
        const profileIdNotNull = this.getData(data.Filter, '@RRTProfileID') !== "" || this.getData(data.Filter.RRTProfileID, '@ID') !== "0";

        if (data.Filter && profileIdInFilter && profileIdNotNull) {
            // When YES RRT Profile is Selected
            const filterData = this.props.dataStore.ui.filter;
            const rrtProfileId = this.getData(data.Filter, '@RRTProfileID') != "" ? this.getData(data.Filter, '@RRTProfileID') :
                (this.getData(data.Filter.RRTProfileID, '@ID').length != 0 || this.getData(data.Filter.RRTProfileID, '@ID') != "") ? this.getData(data.Filter.RRTProfileID, '@ID') :
                    '';
            filterData.Filter['RRTProfileID'] = { '@ID': rrtProfileId }

            bHasRRTProfileSelected = true;
        } 

        return bHasRRTProfileSelected; 
    }

    public isFieldValid(data: string, dataType: string, focusField: string) {
        var eqMsg = isMissingEndQuote(data);
        if (eqMsg) {
            this.onAlert(eqMsg);
            if (focusField) this.setFocus(focusField);
            return false;
        }

        var typeMsg = IsValidType(data, dataType);
        if (typeMsg) {
            this.onAlert(typeMsg);
            if (focusField) this.setFocus(focusField);
            return false;
        }
        return true;
    }

    applyRegex(data: string, type: string, applyAddlRegex: boolean, rExp: RegExp): string {
        var newData = data;
        if (type == "alpha") {
            newData = newData.replace(/[^\w '\-@,"!:]/g, "");
        } else if (type == "numeric") {
            newData = newData.replace(/[^\d\.\-,!:]/g, "");
        } else {
            newData = newData.replace(/[^\w '\.\-/@,"!:#*]/g, "");
        }

        if (applyAddlRegex)
            newData = newData.replace(rExp, "");
        if (newData != data)
            return newData;

        return "DO_NOT_UPDATE_3";
    }

    ////////////////////////////
    ////  ACTION CALLS
    ////////////////////////////

    public setDataRefreshed() {
        this.props.action.ui.setDataRefreshed();
    }
    public clearDataRefreshed() {
        this.props.action.ui.clearDataRefreshed();
    }

    public onDateChange(e: any, fromField: boolean) {
        var data: string = e ?? '';


        var currentData: any = this.props.dataStore.ui.filter.Filter?.DateField;
        if (!currentData)
            currentData = { '@ID': '', '@From': '', '@To': '' };

        if (fromField && currentData['@From'] === data) return;
        if (!fromField && currentData['@To'] === data) return;


        if (fromField)
            currentData['@From'] = data;
        else
            currentData['@To'] = data;

        this.updateDateField(currentData);

        if (!fromField) {
            this.validateDateField_Check2(data, "rsrt_datefield");
    }
    }

    // onclick of the date type dropdown - not the date itself
    public onDateFieldChange(e: any) {
        var data: string = (e && e.value ? e.value : '');


        var currentData: any = this.props.dataStore.ui.filter.Filter?.DateField;
        if (!currentData)
            currentData = { '@ID': '', '@From': '', '@To': '' };

        if (currentData['@ID'] === data) {
            return;
        } else {
            if (this.props.dataStore.ui.focusField == "rsrt_datefield") {
                this.setFocus('');
            }
        }

        if (data === '') {
            currentData = { '@ID': '', '@From': '', '@To': '' };
        }

        currentData['@ID'] = data;

        this.updateDateField(currentData);

    }

    public updateDateField(data: any) {
        // this causes a bug 
        // this.validateDateField_Check2(data, "rsrt_datefield");

        this.props.action.ui.updateDateField(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: data,
                }
            });
    }

    public onSentToStateBatch(e: any | undefined, id: string = '') {
        var result = { '@ID': id.length > 0 ? id : (e && e.value ? e.value : '0'), '@Name': (e && e.label ? e.label : '') };
        this.updateSentToStateBatch(result);
    }

    public updateSentToStateBatch(e: any) {
       
        this.props.action.ui.selectSentToState(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    selected: e,
                }
            });
    }

    public updateAccountNumber(val: string) {

        if (val.length < 5 && this.props.dataStore.ui.filter.Filter.ClaimState['@ID'] === '15' &&
            (this.props.dataStore.ui.filter.Filter.DateField['@From'] === '' || this.props.dataStore.ui.filter.Filter.DateField['@To'] === '')) {
            this.onDateChange("00/00/0000", true);
            this.onDateChange("00/00/0000", false);
        }

        this.props.action.ui.updateAccountNumber({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });

        this.claimStateCheck(val.trim());
    }

    public onSubmittedFileChange(e: any | undefined) {
        var result = { '@ID': (e && e.value ? e.value : '0') };
        this.updateSubmittedFile(result);
    }

    public updateSubmittedFile(e: any) {
         this.props.action.ui.selectSubmittedFile(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    selected: e,
                }
            });
    }

    public onSelectFormTypes(e: React.ChangeEvent<HTMLSelectElement>) {

        var result = this.getSelectedItemsArray(e);
        this.selectFormTypes(result);
    }

    public selectFormTypes(val: any) {

        this.props.action.ui.selectFormTypes(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    selected: val,
                }
            });
    }

    public onSelectClaimStatus(e: any) {
        this.props.action.ui.selectClaimStatus(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: e,
                }
            });
    }

    ////////////////////////////
    ////  GENERAL METHODS
    ////////////////////////////
    public getSelectedItemsArray(e: React.ChangeEvent<HTMLSelectElement>) {
        var data: string[] = this.getSelectedOptions(e);

        let result: APIRSRT_FilterSimpleID[] = [];

        data.forEach(ob => {
            if (ob.length > 0) {
                var item: APIRSRT_FilterSimpleID = { '@ID': ob };
                result.push(item);
            }
        });

        return result;
    }

    public getSelectedItem(e: React.ChangeEvent<HTMLSelectElement>): APIRSRT_FilterSimpleID {
        var data: string[] = this.getSelectedOptions(e);

        var item: APIRSRT_FilterSimpleID = { '@ID': data[0] };

        return item;
    }

    public getSelectedOptions(e: React.ChangeEvent<HTMLSelectElement>) {
        if (!e || !e.target || !e.target.options || e.target.options.length === 0) return [];
        let result = [];

        for (let i = 0; i < e.target.options.length; i++) {
            if (e.target.options[i].selected)
                result.push(e.target.options[i].value);
        }
        return result;
    }

    public goToHomePage() {
        this.props.history.push("/LandingPage");
    }

    createPostDataFromProps(): any {
        return {
            filterId: '', //this.props.dataStore.ui.filterId,
            filterName: '', //this.props.dataStore.ui.filterName,
            title: this.props.title,
            process: this.props.process,
            execProcess: this.props.execProcess,
            execNode: this.props.execNode,
            execAction: this.props.execAction,
            execAttribs: this.props.execAttribs,
            parameter: this.props.parameter ? this.props.parameter : {},
            filterType: '',//this.props.dataStore.ui.filter.Filter["@Type"],
            restrictionsExist: "",
            testMode: this.props.testMode,
        };
    }

    public async postAction_RefineFilter() {

        var xmlData: APIXMLCB_XmlCallBackModel = {};
        xmlData.Process = this.props.process;  // to test view edit use : "View/Edit"; 
        xmlData.Type = "Claim";
        xmlData.ViewOrigin = '/Claims/rsrtfilter.asp';  // see note in type def
        xmlData.FilterJsonData = await this.getFilterData();
        xmlData.TestMode = this.props.testMode

        // debug only - REMOVE ME

        // console.log(' ** postAction_RefineFilter clicked **');
        // console.log(xmlData.FilterJsonData);
         
        this.setBusy(true);
        fetch(URLs.api + '/api/data/SetWorkingGroup',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            })
            .then(async (response) => {
                // get json response here
                let data: any = await response.json();

                if (response.status === 306) {
                    //let cleanData = data.message.replace(/:"/g, "-");
                    this.onAlert(data.message);  // error is in message
                    return;
                } else if (response.status === 200) {
                    this.onRefineSuccess(data, this.overrideUrl);  // data is just in data
                    return;
                } else {
                    throw new Error(`RRTFilter: unable to run filter ${response.status}. ${response.statusText}:  ${(data && data.message ? data.message : data)}`);
                }
            })
            .catch(error => {
                console.error('RRTFilter: ' + error);
                this.errorCallback("Unable to process filter.");
            })
            .finally(() => {
                this.setBusy(false);
            });

    }

    public onRefineSuccess(data: string, urlOverride: string) {
        let strResponse = data;
        let bContinue = true;
        //  console.log('onRefineSuccess: ' + data);  

        let postData = this.createPostDataFromProps();

        if (strResponse.substr(0, 4) === "RUA=") {
            postData.restrictionsExist = 'N';
            if (strResponse.substr(4, 1) === "Y") {
                postData.restrictionsExist = 'Y';
            }
            strResponse = strResponse.substr(6);
        }

        // in this event, we check the claim count and stop the processing (restting) if the count is 0 (zero)
        if (strResponse.substr(0, 7) === "CLMCNT=") {
            let nClaimCount = Number(strResponse.substr(7, strResponse.length - 8));
            if (nClaimCount <= 0) {
                bContinue = false;
            }

            strResponse = "";
        }

        if (bContinue) {
            if (strResponse === "") {
                if (postData.filterId === "0") {
                    postData.filterName = '';
                }
                
                // document.UserFilter.action = Url;
                var url = "/Claims/WorkingGroupDisplay";
                if (urlOverride == "CFI")  // don't inject url
                    url = "/CFI/Default";

                this.overrideUrl = '';
                this.props.history.push(url, postData);
            }
        }
        else {
            this.onAlert("No claims meet your filter criteria. Return to the filter and change your criteria.");
        }
    }

    // maps to onOk - this is Refine or Submit for some process
    // handles "Submit|Refine~But_Refine~Permission~RunFilter~'WorkingGroupDisplay.asp'"
    // RunFilter/MakeRequest
    public async onRefine(/*e: React.ChangeEvent<HTMLButtonElement>, */ url: string = '') {
        if (!await this.validateFilter())
            return;
        else {
            this.overrideUrl = url;
            this.setPostAction("RefineFilter");
        }
    }

    // NOTE:  If your page uses ExecAttribs you need to code for them (remove your alert when you code it)
    public async executeMultiClaimProcess() {
        const xmlData: APIXMLCB_XmlCallBackModel = {};

        // validations on inputs before making call - i do not know that any of these are required
        if (!this.props.execAction || (this.props.execAction && this.props.execAction.length == 0))
            return;

        var jsonData: any = {};
        jsonData.MultiClaim = {}; // execNode
        jsonData.MultiClaim['@Action'] = this.props.execAction;
        jsonData.MultiClaim['@Type'] = "Claim";  // hardcoded in filter.asp
        jsonData.MultiClaim['@Process'] = (this.props.execProcess && this.props.execProcess.length > 0) ? this.props.execProcess : this.props.process;
        if (this.props.execAttribs && this.props.execAttribs.length > 0 && this.props.execAction !== "SpinOffClaim")
            jsonData.MultiClaim['@ParamString'] = this.props.execAttribs;
        else if (this.props.execAction === "SpinOffClaim") {
            jsonData.MultiClaim['@Param'] = this.props.execAttribs;
        }

        if (this.process_lowercase == 'dep' ||
            this.process_lowercase == 'depoutsource') {
            if (this.props.parameter && this.props.parameter?.ReportRequests) {
                jsonData.MultiClaim.ReportRequests = this.props.parameter?.ReportRequests;
            }
        }

        jsonData.MultiClaim = Object.assign(jsonData.MultiClaim, JSON.parse(await this.getFilterData()));

        xmlData.Action = this.props.execAction ? this.props.execAction : "";
        xmlData.Process = this.props.execProcess ? this.props.execProcess : "";
        xmlData.ViewOrigin = '/Claims/filter.asp';  // see note in type def
        xmlData.FilterJsonData = JSON.stringify(jsonData);

        // console.log('** executeMultiClaimProcess clicked **');
        //console.log(JSON.stringify(jsonData.MultiClaim, null, 2));
        //console.log(jsonData.MultiClaim.Filter);
        //console.log(xmlData);

        this.setBusy(true);
        await fetch(URLs.api + '/api/data/PostMultiClaim',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
                if (response.status === 200) {
                    this.goToHomePage();
                    return;
                } else {
                    throw new Error('ClaimFilter: unable to run filter ' + response.status + ' ' + response.statusText);
                }
            })
            .catch(error => {
                console.error('ClaimFilter: ' + error);
                this.errorCallback("Unable to run filter");
            })
            .finally(() => {
                // this.setBusy(false);
            });
    }

    ////////////////////////////
    ////  RENDER HELPER METHODS
    ////////////////////////////
    public dataLoaded(): boolean {
        if (this.props.dataStore.ui.filter &&
            this.props.dataStore.ui.filter.Filter)
            return true;

        return false;
    }

    public getLookupList(lookupList: APIRSRT_SimpleList[] | null) {
        if (!this.apiDataAvailable() || !lookupList || lookupList.length === 0)
            return [];

        let result: any[] = [];

        result = this.getLookupListIntialItem(lookupList, null);

        return result;
    }

    public getLookupListIntialItem(lookupList: APIRSRT_SimpleList[] | null, initialItem: string[] | null) {
        if (!this.apiDataAvailable() || !lookupList)
            return [];

        let result: any[] = [];

        if (initialItem) {
            result.push({ label: initialItem[0], value: initialItem[1] })
        }

        lookupList.forEach(ob => {
            if (ob['Name'])
                result.push({ label: ob['Name'], value: ob['ID'] });
        });

        return result;
    }

    public getLookupList_SelectedItem3(lookupList: any[] | null, lookupID: string, objItem: any, fieldToFind: string | undefined, emptyItem?: any): any {

        var result = emptyItem ?? { value: '', label: '' };
        var idToFind = '';

        if (!this.apiDataAvailable() || !lookupList || lookupList.length === 0 || objItem == undefined || fieldToFind === undefined)
            return result;

        if (objItem != null && objItem[fieldToFind] != null)
            idToFind = objItem[fieldToFind];

        if (idToFind == undefined)
            return result;

        let item = lookupList.find(
            ob => ob[lookupID] === idToFind);

        if (item) {
            result.label = item['Name'];
            result.value = item['ID'];
        } else if (emptyItem) {
            result = emptyItem;
        }
        return result;
    }

    public getLookupList_SelectedItem2(lookupList: any[] | null, objItem: any, fieldToFind: string | undefined, emptyItem?: any): any {

        return this.getLookupList_SelectedItem3(lookupList, 'ID', objItem, fieldToFind, emptyItem);
    }

    public getFormTypes() {
        let addItem: APIRSRT_SimpleList[] = [
            {
                'ID': '',
                'Name': '-All Form Types-'
            }
        ];

        let data = this.props.dataStore.api.data?.ListPopulation?.FormTypeList
            ? addItem.concat(this.props.dataStore.api.data.ListPopulation.FormTypeList) : addItem;

        return data;
    }

    public getSelectedFormTypes(): string[] {
        let items: string[] = [];

        if (this.dataLoaded() &&
            this.props.dataStore.ui.filter.Filter.FormTypes &&
            this.props.dataStore.ui.filter.Filter.FormTypes.FormType) {
            this.props.dataStore.ui.filter.Filter.FormTypes.FormType.forEach(ob => {
                if (ob['@ID']?.length > 0) {
                    items.push(ob['@ID']);
                }
            });
        }

        if (items.length == 0) { items.push('') }
        return items;
    }
    public apiDataAvailable(): boolean {
        if (this.props.dataStore.api.data &&
            this.props.dataStore.api.data.ListPopulation)
            return true;
        return false;
    }

    public onRefineClick(refineCallback: any) {

        refineCallback();
    }

    public onViewClaimsClick(refineCallback: any) {

        refineCallback('CFI');
    }

    public onSubmitClick(submitCallback: any) {
        this.setState({ disableBtn: true });

        submitCallback();
    }

    // trying to eliminate... this.props.dataStore.ui.filter?.Filter?.DateField['@To']
    public getData(ob: any, field: string): string {
        if (!this.dataLoaded() || !ob)
            return '';

        return ob[field] ?? '';
    }

    public async setWorkingGroup(refineProcess: boolean) {
        // console.log(' ** setWorkingGroup called **');
        var xmlData: APIXMLCB_XmlCallBackModel = {};
        xmlData.Process = this.props.process;  // to test view edit use : "View/Edit"; 
        xmlData.Type = "Claim";
        xmlData.ViewOrigin = 'rsrtfilter.asp';  // see note in type def
        xmlData.FilterJsonData = await this.getFilterData();
        xmlData.Convert = this.process_lowercase == "view" ? "True" : "False";
        this.setBusy(true);
  
        fetch(URLs.api + '/api/data/SetWorkingGroup',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            })
            .then(async (response) => {

                // get json response here
                let data: any = await response.json();

                if (response.status === 306) {
                    //let cleanData = data.message.replace(/:"/g, "-");
                    this.onAlert(data.message);  // error is in message
                    this.setBusy(false);
                    // this.setState({ redirecting: false });
                    return;
                } else if (response.status === 200) {
                    if (refineProcess) {
                        this.onRefineSuccess(data, this.overrideUrl);  // data is just in data
                    } else {
                        // this.onRunValidateSuccess(data);
                    }

                    return;
                } else {
                    throw new Error(`RRTFilter: unable to run filter ${response.status}. ${response.statusText}: ${(data && data.message ? data.message : data)}`);
                }
            })
            .catch(error => {
                console.error('RemitFilter: ' + error);
                this.setBusy(false);
            })
            .finally(() => {
                if (this.process_lowercase !== 'match') {
                    this.setBusy(false);
                }
            });

    }

    public onCancel() {
        this.props.history.push("/LandingPage");
    }

    public getButtons() {
        // Button are based on process
        if (this.process_lowercase === "rsrtcustom" || this.process_lowercase === "validate/rrt" || this.process_lowercase === "viewedit/rrt") {
            // ASPInfo.Add "ButtonInfo", Array("Refine~But_Refine~Permission~RunFilter~'WorkingGroupDisplay.asp'", "Submit~But_Continue~Permission~ExecuteProcess~", "Cancel~But_Cancel~~HandleCancelButton~")
            return <React.Fragment>
                <DialogButtonRefine disable={false} domID='refine-claims-btn' name='Refine'
                    onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onRefineClick(this.onRefine.bind(this))} />

                {(this.process_lowercase === "validate/rrt" || this.process_lowercase === "rsrtcustom") ? (
                    <DialogButtonSubmit disable={false} domID='view-claims-btn' name={ "Continue" }
                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) =>  this.onSubmitClick(this.submitFilter.bind(this))} />
                ) : (
                    <DialogButtonSubmit disable={false} domID='validate-claims-btn' name={"View Claims" }
                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onViewClaimsClick(this.onRefine.bind(this))} />
                )}
 
                <DialogButtonCancel disable={false} domID='cancel-btn' onClick={() => { this.onCancel() }} name='Cancel' />
            </React.Fragment>
        } else if (this.process_lowercase === "delete/rrt" || this.process_lowercase === "changeclaimstatesdrnotsent" ||
            this.process_lowercase === "changeclaimstatesdrsent") {
            //ASPInfo.Add "ButtonInfo", Array("Refine~But_Refine~Permission~RunFilter~'WorkingGroupDisplay.asp'", "Cancel~But_Cancel~~HandleCancelButton~")
            return <React.Fragment>
                <DialogButtonRefine disable={false} domID='refine-claims-btn' onClick={() => this.onRefineClick(this.onRefine.bind(this))} name='Refine' />
                <DialogButtonCancel disable={false} domID='cancel-btn' onClick={() => { this.onCancel() }} name='Cancel' />
            </React.Fragment>
        } else {
            return
            <React.Fragment>
                <DialogButtonCancel disable={false} domID='cancel-btn' onClick={() => { this.onCancel() }} name='Cancel' />
            </React.Fragment>
        }
    }

    public onClearAlert(e: any) {
        this.onAlert("");
    }

    public onAlert(msg: string) {
        this.props.action.ui.sendRSRTFilterAlert(msg);
    }

    /// setClaimState- 
    public claimStateCheck(account : string) {

        if (account.length >= 5) {

            if (this.props.dataStore.ui.filter.Filter.ClaimState['@ID'] === '15' &&
            this.props.dataStore.ui.filter.Filter.DateField['@ID'] === DATE_DISCHARGE_ID &&
            (this.props.dataStore.ui.filter.Filter.DateField['@From'] === '00/00/0000' || this.props.dataStore.ui.filter.Filter.DateField['@To'] === '00/00/0000')) {
            var dateField = { '@ID': '', '@From': '', '@To': '' };
            this.updateDateField(dateField);
            }
             
        } else {
            
            if (this.props.dataStore.ui.filter.Filter.ClaimState['@ID'] === '15') {
                var dateField = { '@ID': DATE_DISCHARGE_ID, '@From': '00/00/0000', '@To': '00/00/0000' };
                this.updateDateField(dateField);
            }
            
        }
    }

    public onSubmitForm() {
        // NOTE EVERY ONE SHOULD TEST THIS FOR THEIR  PROCESS TYPE
        this.loadFilterCriteria(0); //filter id number
    }

    public async loadFilterCriteria(filterId: number, successCallback: any = undefined) {
        var manageBusy = !this.props.dataStore.ui.isBusy;

        if (manageBusy)
            this.setBusy(true);

        var xmlData: APIXMLCB_XmlCallBackModel = {};

        xmlData.FilterId = 0 //filterId; // this would be 0
        xmlData.TestMode = this.props.testMode;
        xmlData.Type = "Claim";  

        await fetch(URLs.api + '/api/data/getFilter',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error(response.statusText);
                }
            }).then((data: any /*APIRSRT_FilterWrapper*/) => {
                //console.log('Filter back from query');
                //console.log(data);
                //console.log('Filter on hand');
                //console.log(this.props.dataStore.ui.filter.Filter);
                if (data) {
                    this.onLoadFilter(data);
                }
            }).then(() => {
                if (successCallback != undefined)
                    successCallback();
            })
            .catch(error => {
                console.error('RSRTFilter: ' + error);
                this.errorCallback('Unable to load Claim State');
            })
            .finally(() => {

            });

    }

    public sentToState() {

        if (this.process_lowercase === "changeclaimstatesdrnotsent" || this.process_lowercase === "changeclaimstatesdrsent") {
            return
        }
        if ((this.props.dataStore.ui.filter.Filter.DateField['@ID'] === '0' || this.props.dataStore.ui.filter.Filter.DateField['@ID'] === '') &&
            (this.props.dataStore.ui.filter.Filter.SentToStateBatch['@ID'] === '0' || this.props.dataStore.ui.filter.Filter.SentToStateBatch['@ID'] === '') &&
            this.props.dataStore.ui.filter.Filter.AccountNumber['@Value'].length < 5) {
//            && this.props.dataStore.ui.bLoading === false) {
            //need the id for discharge date
            var currentData = { '@ID': DATE_DISCHARGE_ID, '@From': '00/00/0000', '@To': '00/00/0000' };
            this.props.action.ui.updateDateField(
                {
                    api: this.props.dataStore.api.data,
                    uiData: { data: currentData }
                });
        }

        if ((this.process_lowercase === "delete/rrt" || this.process_lowercase === "validate/rrt" || this.process_lowercase === "viewedit/rrt")) {
        
        }
        this.props.action.ui.disableProfile(true);
        this.updateProfile('', '0');
    }

    public notSentToState() {

        this.props.action.ui.disableProfile(false);
        var currentData = { '@ID': '0', '@From': '', '@To': '' };
        this.updateDateField(currentData);

    }

    public setClaimState(val: any) {

        this.props.action.ui.setClaimState({ api: this.props.dataStore.api.data, uiData: { value: val } });;
        if (val === '13') this.notSentToState();
        if (val === '15') this.sentToState();
    }

    public updateProfile(e: any | undefined, id: string = '') {
        let result = { '@ID': id.length > 0 ? id : (e && e.value ? e.value : '0') };
        let disable = false;

        if (result && (result['@ID'] !== "0" && result['@ID'] !== "")) {
            disable = true;
        } 

        if (result && (result['@ID'] === "-1")) {
            result['@ID'] = '';
        }

        this.updateRRTProfileID(result);
        this.disableFieldsonRTTProfileSelected(disable);
        
    }

    public updateRRTProfileID(e: any) {

        this.props.action.ui.updateProfile(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    selected: e,
                }
            });
    }

    public disableFieldsonRTTProfileSelected(val: boolean) {
        this.props.action.ui.disableFormTypes(val);

        if (!val) {
            const data = this.props.dataStore.ui.filter.Filter.FormTypes.FormType ? this.props.dataStore.ui.filter.Filter.FormTypes.FormType : []
            this.selectFormTypes(data);
        }

        if (this.process_lowercase !== "changeclaimstatesdrnotsent" && this.process_lowercase !== "delete/rrt" &&
            this.process_lowercase !== "validate/rrt") {
            this.props.action.ui.disableSentToState(val);
        }
    }

    public onLoadFilter(data: APIRSRT_FilterWrapper) {

        /* 
         * When the Filter Profile comes back after it had been combined with RRT it will not display correctly 
         * we need to removed the RRT Filter Profile from the Filter Profile
         * */

        const hasRRTClientProfileFilter = this.removeRRTFromFilterData(data);

            if (this.validationCallback_LoadFilter(data)) {
                this.props.action.ui.loadFilter(
                    {
                        api: this.props.dataStore.api.data,
                        uiData: { data: data, index: undefined }
                    });
            } else {
                console.error('onLoadFilter cannot verify shape of filter to load.');
                this.onAlert('Unable to load filter.');
        }
         
        this.disableFieldsonRTTProfileSelected(hasRRTClientProfileFilter)
    }

    public updateFinancialClass(val: string) {
        this.props.action.ui.updateFinancialClass({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }
    public updateUserField6(val: string) {
        this.props.action.ui.updateUserField6({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }
    public updateUserField7(val: string) {
        this.props.action.ui.updateUserField7({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }
    public updateUserField8(val: string) {
        this.props.action.ui.updateUserField8({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }
    public setPostAction(data: string) {
        this.props.action.ui.setPostAction(data);
    }
    public setFilterIntialized(data: boolean) {
        this.props.action.ui.setPostAction(data);
    }

    public onLoadClaimState(): boolean {

        if (this.process_lowercase === "changeclaimstatesdrnotsent" || this.process_lowercase === "delete/rrt" ||
            this.process_lowercase === "validate/rrt") {
            this.setClaimState("13"); 
            this.props.action.ui.disableSentToState(true);
            this.props.action.ui.disableProfile(false);
        } else if (this.process_lowercase === "changeclaimstatesdrsent") {
            this.setClaimState("15");
            this.props.action.ui.disableSentToState(true);
            this.props.action.ui.disableNotSentToState(true);
        }
        if (this.process_lowercase === "changeclaimstatesdrnotsent" || this.process_lowercase === "changeclaimstatesdrsent") {
            return false;
        }
        this.claimStateCheck("");
        

        return false;
    }

    public render() {
        var instruction = <React.Fragment>{this.props.instruction + " " + this.props.extraInstruction}</React.Fragment>;

        var fld = this.props.dataStore.ui.filter.Filter?.DateField;
        var disableClaimState = false; // this.onLoadClaimState();
        var titleString = "Claim Filter >> " + this.props.title;
        var formTypesSelected = this.getSelectedFormTypes()
        var formTypesData = this.getFormTypes();

        return (
            <DialogWrapper
                title={titleString}
                instruction={instruction}
                helpUrl={this.props.helpUrl}
                buttons={this.getButtons()}
                isBusy={false}
            >
                <ContentRowWrapper style={{ width: '1150px' }}>
                    <ContentColumnWrapper style={{ width: '330px' }}>
                        <DialogFieldset>
                            <DialogLegend>Field</DialogLegend>
                            <InputRowWrapper>
                                <ARMNarrowInput
                                    domID="rsrt_controlNum"
                                    id='control-number'
                                    className="text-input"
                                    hasError={this.props.dataStore.ui.focusField == "rsrt_controlNum"}
                                    label='Control Number :'
                                    initialValue={this.getData(this.props.dataStore.ui.filter.Filter.AccountNumber, "@Value")}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateAccountNumber(e.target.value)}
                                />
                            </InputRowWrapper>
                            <ContentRowWrapperDate>
                                <SelectDropdown
                                    domID="rsrt_datefield"
                                    className={"select_datefield_dropdown"}
                                    hasError={this.props.dataStore.ui.focusField == "rsrt_datefield"}
                                    isClearable={false}
                                    isSearchable={true}
                                    onChange={(option: SelectDropdownValue) => this.onDateFieldChange(option)}
                                    options={this.getLookupListIntialItem(this.props.dataStore.api.data?.ListPopulation?.DateFieldList, ["- Select Date Field -", ""])}
                                    initialValue={this.getLookupList_SelectedItem2(this.props.dataStore.api.data?.ListPopulation?.DateFieldList,
                                        fld, '@ID', { label: "- Select Date Field -", value: '' })}
                                />
                                <ARMDatePicker
                                    domID="rsrt_datefrom"
                                    className="sel_datef"
                                    hasError={this.props.dataStore.ui.focusField == "rsrt_datefrom"}
                                    onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onDateChange(e, true)}
                                    allowTime={false}
                                    minDate={this.minDate}
                                    maxDate={this.maxDate}
                                    assocTo={this.getData(fld, '@To')}
                                    initialDate={this.getData(fld, '@From')}
                                />
                                <ARMDatePicker
                                    domID="rsrt_dateto"
                                    className="sel_datet"
                                    hasError={this.props.dataStore.ui.focusField == "rsrt_dateto"}
                                    onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onDateChange(e, false)}
                                    allowTime={disableClaimState}
                                    minDate={this.minDate}
                                    maxDate={this.maxDate}
                                    assocFrom={this.getData(fld, '@From')}
                                    initialDate={this.getData(fld, '@To')}
                                />

                            </ContentRowWrapperDate>

                            <InputRowWrapper>
                                <ARMNarrowInput
                                    domID="fin-class"
                                    id='fin-class'
                                    className="text-input"
                                    label='Financial Class:'
                                    initialValue={this.getData(this.props.dataStore.ui.filter.Filter.FinancialClass, "@Value")}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateFinancialClass(e.target.value)}
                                />
                            </InputRowWrapper>
                            <InputRowWrapper>
                                <ARMNarrowInput
                                    domID="cli-specific-1"
                                    id='cli-specific-1'
                                    className="text-input"
                                    label='Client Specific 1:'
                                    initialValue={this.getData(this.props.dataStore.ui.filter.Filter.UserField6, "@Value")}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateUserField6(e.target.value)}
                                />
                            </InputRowWrapper>
                            <InputRowWrapper>
                                <ARMNarrowInput
                                    domID="cli-specific-2"
                                    id='cli-specific-2'
                                    className="text-input"
                                    label='Client Specific 2:'
                                    initialValue={this.getData(this.props.dataStore.ui.filter.Filter.UserField7, "@Value")}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateUserField7(e.target.value)}
                                />
                            </InputRowWrapper>
                            <InputRowWrapper>
                                <ARMNarrowInput
                                    domID="cli-specific-3"
                                    id='cli-specific-3'
                                    className="text-input"
                                    label='Client Specific 3:'
                                    initialValue={this.getData(this.props.dataStore.ui.filter.Filter.UserField8, "@Value")}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateUserField8(e.target.value)}
                                />
                            </InputRowWrapper>
                        </DialogFieldset>

                        <DialogFieldset>
                            <DialogLegend>Profile</DialogLegend>
                            <SelectDropdown
                                domID="rsrtfilter_profile"
                                className="dropdown"
                                disabled={this.props.dataStore.ui.profileDisable}
                                isClearable={false}
                                isSearchable={true}
                                //width="250px"
                                //index={this.props.dataStore.ui.renderProfileHelper}
                                onChange={(option: SelectDropdownValue) => this.updateProfile(option)}
                                options={this.getLookupListIntialItem(this.props.dataStore.api.data?.ListPopulation?.RRTClientProfileList, ["- Select Profile -", ""])}
                                initialValue={this.getLookupList_SelectedItem2(this.props.dataStore.api.data?.ListPopulation?.RRTClientProfileList,
                                    this.props.dataStore.ui.filter.Filter?.RRTProfileID, '@ID', { label: "- Select Profile -", value: '' })}
                            />
                        </DialogFieldset>
                    </ContentColumnWrapper>
                    <FilterColumn2>
                        <FormTypeList
                            title='rsrtfilter_selectformtype'
                            forms={formTypesData}
                            selectedValues={this.props.dataStore.ui.clearForms === 0 ? formTypesSelected : []}
                            disabled={this.props.dataStore.ui.formTypeDisable}
                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectFormTypes(e)}
                            key={this.props.dataStore.ui.clearForms}
                        />
                        <BatchDropdownWrapper>
                        <SelectDropdown
                            domID="rsrtfilter_senttostatebatch"
                            className="dropdown"
                            label="SENT TO STATE BATCH:"
                            disabled={this.process_lowercase === "direct"}
                            isClearable={false}
                            isSearchable={true}
                            //width="150px"
                            onChange={(option: SelectDropdownValue) => this.onSentToStateBatch(option)}
                            options={this.getLookupListIntialItem(this.props.dataStore.api.data?.ListPopulation?.SentToStateBatchList, ["- Select Sent to State Batch -", ""])}
                                initialValue={this.getLookupList_SelectedItem3(this.props.dataStore.api.data?.ListPopulation?.SentToStateBatchList, 'Name',
                                    this.props.dataStore.ui.filter.Filter.SentToStateBatch, '@Name', { label: "- Select Sent to State Batch -", value: '' })}
                        />
                        <SelectDropdown
                            domID='rsrtfilter_submittedfile'
                            className="dropdown"
                            label="SUBMITTED FILE:"
                            isClearable={false}
                            isSearchable={true}
                            disabled={false}
                            hasError={false}
                            onChange={(option: SelectDropdownValue) => this.onSubmittedFileChange(option)}
                            options={this.getLookupListIntialItem(this.props.dataStore.api.data?.ListPopulation?.SubmittedFileList, ["- Select Submitted File -", ""])}
                            initialValue={this.getLookupList_SelectedItem2(this.props.dataStore.api.data?.ListPopulation?.SubmittedFileList,
                                this.props.dataStore.ui.filter.Filter?.SubmittedFile, "@ID", { label: "- Select Submitted File -", value: '' })}
                        />
                        </BatchDropdownWrapper> 
                    </FilterColumn2>
                    <FilterColumn3>
                        <DialogFieldset>
                            <DialogLegend>Claim State</DialogLegend>
                            <RadioButtonComponent
                                width='100%'
                                id="not-sent-state-radio"
                                label="Not Sent to State"
                                checked={this.getData(this.props.dataStore.ui.filter.Filter.ClaimState,'@ID') === '13'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setClaimState('13')}
                                disabled={this.props.dataStore.ui.notSentToStateDisable}
                            />
                            <RadioButtonComponent
                                width='100%'
                                id="sent-state-radio"
                                label="Sent to State"
                                checked={this.getData(this.props.dataStore.ui.filter.Filter.ClaimState,'@ID') === '15'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setClaimState('15')}
                                disabled={this.props.dataStore.ui.sentToStateDisable}
                            />
                        </DialogFieldset>
                        <DialogFieldset>
                            <DialogLegend>Claim Status</DialogLegend>
                            <ContentRowWrapper>
                                <Checkbox
                                    domID='rsrt_accepted'
                                    label='Accepted'
                                    checked={this.props.dataStore.ui.filter.Filter.ClaimStatus && (this.getData(this.props.dataStore.ui.filter.Filter.ClaimStatus,"@Accepted") === 'A' ||
                                        this.getData(this.props.dataStore.ui.filter.Filter.ClaimStatus,"@All") === 'true')}
                                    onChange={(e: React.SyntheticEvent) => this.onSelectClaimStatus('Accepted')}
                                />
                                <Checkbox
                                    domID='rsrt_warning'
                                    label='Warning'
                                    checked={this.props.dataStore.ui.filter.Filter.ClaimStatus && (this.getData(this.props.dataStore.ui.filter.Filter.ClaimStatus,"@Warning") === 'W' ||
                                        this.getData(this.props.dataStore.ui.filter.Filter.ClaimStatus,"@All") === 'true')}
                                    onChange={(e: React.SyntheticEvent) => this.onSelectClaimStatus('Warning')}
                                />
                            </ContentRowWrapper>
                            <ContentRowWrapper>
                                <Checkbox
                                    domID='rsrt_rejected'
                                    label='Rejected'
                                    checked={this.props.dataStore.ui.filter.Filter.ClaimStatus && (this.getData(this.props.dataStore.ui.filter.Filter.ClaimStatus,"@Rejected") === 'R' ||
                                        this.getData(this.props.dataStore.ui.filter.Filter.ClaimStatus,"@All") === 'true')}
                                    onChange={(e: React.SyntheticEvent) => this.onSelectClaimStatus('Rejected')}
                                />
                            </ContentRowWrapper>
                        </DialogFieldset>
                    </FilterColumn3>
                </ContentRowWrapper>
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.rsrtAlert && this.props.dataStore.ui.rsrtAlert.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearAlert(e)}
                    message={this.props.dataStore.ui.rsrtAlert}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClearAlert(e)}
                />
            </DialogWrapper>);

    }

}

var connectedHoc = connect<IRSRTFilterState, IRSRTFilterActionProps, IRSRTFilterOwnProps, IRSRTFilterProps, ApplicationState>(
    createApiMapStateToProps('rsrtFilter'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(RSRTFilter);

export default withRouter(connectedHoc);