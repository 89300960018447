import { Reducer, Action } from 'redux';
import { createAction, createDataAction, ActionTypes, createApiBodyAction } from '@scripts/util/ActionHelpers';
import { IApiActionData } from "@scripts/util/ApiDataHelpers"
import { deepCopyFunction } from '../../commonResources/validations';
import { URLs } from '@commonDevResources/constants';
import { debounce } from 'lodash';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IRemitFilterUIState {
    filter: APIRMTF_FilterWrapper;  // the visual data of filter

    filterName: string;  // this is used to change the title when a filter is loaded/saved
    filterId: number;

    dataRefreshed: boolean;  // to handle loadFilter needing setFilter called...   
    loaded: boolean; // has a filter been loaded replaces the following check in old asp : xmlFilter.documentElement != null && xmlFilter.documentElement.nodeName != 'Filter'
    isBusy: boolean;

    // filter list support
    isFilterListOpen: boolean;

    // ncs support on save filter
    saveFilterNcsAlert?: string;

    // for alert messages
    filterAlert?: string;
    remitFilterErrorHandler?: string;

    clearFocus: boolean;  // if an action is processed, clear the focus/error field
    postAction: string;
    loadedFilterErrorMessage: any;
}


const emptyFilter: APIRMTF_FilterWrapper = {
    Filter: {
        '@ID': '0',
        '@Name': '',
        '@Type': 'Remit',

        FiscalPeriod: {
            '@From': '',
            '@To': ''
        },
        ImportDate: {
            '@From': '00/01/0000',
            '@To': '00/00/0000'
        },
        PmtDate: {
            '@From': '',
            '@To': ''
        },
        SecBill: {
            '@From': '',
            '@To': ''
        },
        ServiceFrom: {
            '@From': '',
            '@To': ''
        },
        ServiceThru: {
            '@From': '',
            '@To': ''
        },
        CheckNumber: { '@Value': '' },
        FileName: { '@Value': '' },
        APCCode: { '@Value': '' },
        ContractualAdj: { '@Value': '' },
        PatientControlNumber: { '@Value': '' },
        LastName: { '@Value': '' },
        PatientICNNumber: { '@Value': '' },
        PaymentAmt: { '@Value': '' },
        CheckAmt: { '@Value': '' },
        RemitProviderNPI: { '@Value': '' },
        RemitFederalTaxId: { '@Value': '' },
        RemarkCode: { '@ID': '', '@Value': '' },
        Status: { '@Value': '' },
        AmtBilled: { '@Value': '' },
        RemitTypeOfBill: { '@Value': '' },
        AdjustmentCode: { '@ID': '', '@Value': '' },
        GroupCode: { '@ID': '', '@Value': '' },

        RemitState: {
            '@Matched': '',
            '@Unmatched': '',
        },
        DisplayOrder: { '@ID': '1' },
        RemitStatus: {
            '@Paid': '',
            '@Denied': '',
            '@Pending': '',
            '@Other': '',
            '@All': '',

        },
        ServiceType: {
            '@Inpatient': '',
            '@Outpatient': '',
            '@All': '',
        },
        Source: {
            '@Aii': '',
            '@RA': '',
            '@All': '',
        },
        Candidates_N: { '@NameControl': '' },
        Candidates_S: { '@Secondary': '' },
        Payers: { Payer: [] },
        LOBs: { LOB: [] },
        RemitLOBs: { RemitLOB: [] },
        Providers: { Provider: [] },
        ProviderNPIs: { ProviderNPI: [] },
        Facilities: { Facility: [] }
    }

};

const defaultState: IRemitFilterUIState = {
    filter: deepCopyFunction({ ...emptyFilter }),
    filterName: '',
    filterId: 0,

    dataRefreshed: false,
    loaded: false,
    isBusy: false,

    filterAlert: '',
    remitFilterErrorHandler: '',

    isFilterListOpen: false,
    saveFilterNcsAlert: '',
    clearFocus: false,
    postAction: '',
    loadedFilterErrorMessage: null
};

export interface IRemitFilterUIData {
    index: string;
    value: boolean;
};
interface IRemitFilterUIFieldData {
    data: any;
    index: number;
}

interface IRemitFilterUISimpleID {
    selected: APICF_FilterSimpleID;
}

interface IRemitFilterUIFieldUpdate {
    value: string;
}

interface IRemitFilterUIArrayUpdate {
    value: any;
}

interface IRemitFilterUIIdValue {
    id: number;
    value: string;
}

export const defaultFilter: IRemitFilterUIData = {
    index: '',
    value: false,
};


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface IRF_LoadFilter extends IApiActionData<APIRMTF_ListPopulationSavedFilterContainer, IRemitFilterUIFieldData> { }
export interface IRF_FieldData extends IApiActionData<APIRMTF_ListPopulationContainer, IRemitFilterUIData> { }
export interface IRF_SelectSimpleId extends IApiActionData<APIRMTF_ListPopulationContainer, IRemitFilterUISimpleID> { }
export interface IRF_FieldValue extends IApiActionData<APIRMTF_ListPopulationContainer, IRemitFilterUIFieldUpdate> { }
export interface IRF_IdValue extends IApiActionData<APIRMTF_ListPopulationSavedFilterContainer, IRemitFilterUIIdValue> { }
export interface IRF_ArrayData extends IApiActionData<APIRMTF_ListPopulationContainer, IRemitFilterUIArrayUpdate> { }

export const actionCreators = {
    loadFilter: (filterInfo: IRF_LoadFilter) => createDataAction('RF_LOAD_FILTER', filterInfo),
    setActiveTab: (tabInfo: IRF_LoadFilter) => createDataAction('RF_SET_ACTIVE_TAB', tabInfo),
    setLoaded: (loaded: IRF_FieldData) => createDataAction('RF_SET_LOADED', loaded),

    selectDisplayOrder: (selectDisplayOrder: IRF_SelectSimpleId) => createDataAction('RF_SELECT_DISPLAYORDER', selectDisplayOrder),

    updateFiscalPeriod: (data: IRF_LoadFilter) => createDataAction('RF_UPDATE_FISCALPERIOD', data),
    updateImportDate: (data: IRF_LoadFilter) => createDataAction('RF_UPDATE_IMPORT_DATE', data),
    updatePmtDate: (data: IRF_LoadFilter) => createDataAction('RF_UPDATE_PMT_DATE', data),
    updateSecBill: (data: IRF_LoadFilter) => createDataAction('RF_UPDATE_SEC_BILL', data),
    updateServiceFrom: (data: IRF_LoadFilter) => createDataAction('RF_UPDATE_SERVICE_FROM', data),
    updateServiceThru: (data: IRF_LoadFilter) => createDataAction('RF_UPDATE_SERVICE_THRU', data),
    updateCheckNumber: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_CHECKNUMBER', data),
    updateFileName: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_FILENAME', data),

    updateApcCode: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_APCCODE', data),
    updateContractualAdj: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_CONTRACTUALADJ', data),
    updatePatientControlNumber: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_PATIENTCONTROLNUMBER', data),
    updateLastName: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_LASTNAME', data),
    updatePatientICNNumber: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_PATIENTICNNUMBER', data),
    updatePaymentAmt: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_PAYMENTAMT', data),
    updateCheckAmt: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_CHECKAMT', data),
    updateRemitProviderNPI: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_REMITPROVIDERNPI', data),
    updateRemitFederalTaxId: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_REMITFEDERALTAXID', data),
    updateRemarkCodeId: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_REMARKCODEID', data),
    updateRemarkCode: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_REMARKCODE', data),
    updateStatus: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_STATUS', data),
    updateAmtBilled: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_AMTBILLED', data),
    updateRemitTypeOfBill: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_REMITTYPEOFBILL', data),
    updateAdjustmentCodeId: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_ADJUSTMENTCODEID', data),
    updateAdjustmentCode: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_ADJUSTMENTCODE', data),
    updateGroupCodeId: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_GROUPCODEID', data),
    updateGroupCode: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_GROUPCODE', data),

    updateRemitState: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_REMITSTATE', data),
    updateRemitStatus: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_REMITSTATUS', data),
    updateServiceType: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_SERVICETYPE', data),
    updateSource: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_SOURCE', data),
    updateCandidatesN: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_CANDIDATES_N', data),
    updateCandidatesS: (data: IRF_FieldValue) => createDataAction('RF_UPDATE_CANDIDATES_S', data),
    updateSelectedPayers: (data: IRF_ArrayData) => createDataAction('RF_UPDATE_SELECTED_PAYERS', data),
    updateSelectedRemitLOBs: (data: IRF_ArrayData) => createDataAction('RF_UPDATE_SELECTED_REMITLOBS', data),
    updateSelectedLOBs: (data: IRF_ArrayData) => createDataAction('RF_UPDATE_SELECTED_LOBS', data),
    updateSelectedProviders: (data: IRF_ArrayData) => createDataAction('RF_UPDATE_SELECTED_PROVIDERS', data),
    updateSelectedProviderNPIs: (data: IRF_ArrayData) => createDataAction('RF_UPDATE_SELECTED_PROVIDERNPIS', data),
    updateSelectedFacilities: (data: IRF_ArrayData) => createDataAction('RF_UPDATE_SELECTED_FACILITIES', data),


    ruaValidateFilter: (data: string) => createApiBodyAction('RF_RUAVALIDATEFILTER',
        `${URLs.api}/api/data/ValidateFilter`, undefined, "POST", data),

    postMultiClaim: (data: string) => createApiBodyAction('RF_POSTMULTICLAIM',
        `${URLs.api}/api/data/PostMultiClaim`, undefined, "POST", data),

    compareRemit835Count: (data: number) => createApiBodyAction('RF_COMPAREREMIT835COUNT',
        `${URLs.api}/api/data/remits/Remit835Count`, data, "GET", undefined),

    compareRemitFileCount: () => createApiBodyAction('RF_COMPAREREMITFILECOUNT',
        `${URLs.api}/api/data/remits/FileCountFromFilter`, '', "GET", undefined),

    putRemitFilterList: () => createApiBodyAction('RF_PUTREMITFILTERLIST',
        `${URLs.api}/api/data/remits/1/5000`, '', "PUT", undefined),

    sendFilterAlert: (fieldInfo: string) => createDataAction('RF_SEND_ALERT', fieldInfo),
    displayErrorMessage: (fieldInfo: IRF_FieldValue) => createDataAction('RF_ERROR_HANDLER_CONFIRM', fieldInfo),
    setBusy: (fieldInfo: IRF_FieldData) => createDataAction('RF_SETBUSY', fieldInfo),
    setDataRefreshed: (val: boolean) => createDataAction('RF_SET_DATA_REFRESHED', val),

    // filter list support
    selectFilterSelection: (filterInfo: IRF_IdValue) => createDataAction('RF_SELECT_FILTER', filterInfo),
    reloadFilterList: (filterInfo: IRF_LoadFilter) => createDataAction('RF_RELOAD_FILTERLIST', filterInfo),
    openFilterList: () => createAction('RF_OPEN_FILTERLIST'),
    closeFilterList: () => createAction('RF_CLOSE_FILTERLIST'),

    resetClearFocus: () => createAction('RF_CLEARFOCUS'),
    setPostAction: (val: string) => createDataAction('RF_SETPOSTACTION', val),
    sendSaveFilterNCSAlert: (fieldInfo: IRF_FieldValue) => createDataAction('RF_NCS_ALERT', fieldInfo),
    clearLoadedFilterErrorMessage: (fieldInfo: IRF_FieldData) => createDataAction('RF_CLEAR_LOADED_FILTER_ERROR', fieldInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

function getData(ob: any, field: string): string {
    if (!ob)
        return '';

    return ob[field] ?? '';

}

function isSingleEntry(val: string) {
    return (val.length >= 5 && val.indexOf("-") == -1 && val.indexOf('"') == -1) ||
        (val.length >= 7 && val.charAt(0) == '"' && val.charAt(val.length - 1) == '"');
}
function isImportOrPmtDatesDirty(state: any) {
    var importDateFrom = getData(state.filter?.Filter?.ImportDate, '@From');
    var importDateTo = getData(state.filter?.Filter?.ImportDate, '@To');
    var pmtDateFrom = getData(state.filter?.Filter?.PmtDate, '@From');
    var pmtDateTo = getData(state.filter?.Filter?.PmtDate, '@To');
    if (importDateFrom != "00/01/0000" || importDateTo != "00/00/0000" ||
        pmtDateFrom != "" || pmtDateTo != "")
        return true;
    return false;
}
function isImportAndPmtDatesEmpty(state: any) {
    var importDateFrom = getData(state.filter?.Filter?.ImportDate, '@From');
    var importDateTo = getData(state.filter?.Filter?.ImportDate, '@To');
    var pmtDateFrom = getData(state.filter?.Filter?.PmtDate, '@From');
    var pmtDateTo = getData(state.filter?.Filter?.PmtDate, '@To');
    if (importDateFrom == "" && importDateTo == "" && pmtDateFrom == "" && pmtDateTo == "")
        return true;
    return false;
}

function getRemitState(state: any, e: any) {

    var Matched, Unmatched, All;

    if (!state.filter.Filter.RemitState) {
        Matched = Unmatched = All = '';
    } else {
        Matched = getData(state.filter.Filter.RemitState, "@Matched");
        Unmatched = getData(state.filter.Filter.RemitState, "@Unmatched");
        All = getData(state.filter.Filter.RemitState, "@All");
    }
    switch (e) {
        case 'Matched':
            if (All === "true") {
                Matched = '';
                Unmatched = 'U';
                All = '';
            } else {
                if (Matched === '') {
                    Matched = 'M'
                } else Matched = '';
            }
            break;
        case 'Unmatched':
            if (All === "true") {
                Matched = 'M';
                Unmatched = '';
                All = '';
            } else {
                if (Unmatched === '') {
                    Unmatched = 'U'
                } else Unmatched = '';
            }
            break;

    }

    if (Matched === "M" && Unmatched === "U") {
        Matched = Unmatched = "";
        All = "true"
    }

    return {
        '@Matched': Matched, '@Unmatched': Unmatched, '@All': All
    }

}

function getRemitStatus(state: any, e: any) {

    var Paid, Denied, Pending, Other, All;

    if (!state.filter.Filter.RemitStatus) {
        Paid = Denied = Pending = Other = All = '';
    } else {
        Paid = getData(state.filter.Filter.RemitStatus, "@Paid");
        Denied = getData(state.filter.Filter.RemitStatus, "@Denied");
        Pending = getData(state.filter.Filter.RemitStatus, "@Pending");
        Other = getData(state.filter.Filter.RemitStatus, "@Other");
        All = getData(state.filter.Filter.RemitStatus, "@All");
    }
    switch (e) {
        case 'Paid':
            if (All === "true") {
                Paid = '';
                Denied = 'D';
                Pending = 'W'
                Other = 'O'
                All = '';
            } else {
                if (Paid === '') {
                    Paid = 'P'
                } else Paid = '';
            }
            break;
        case 'Denied':
            if (All === "true") {
                Paid = 'P';
                Denied = '';
                Pending = 'W'
                Other = 'O'
                All = '';
            } else {
                if (Denied === '') {
                    Denied = 'D'
                } else Denied = '';
            }
            break;
        case 'Pending':
            if (All === "true") {
                Paid = 'P';
                Denied = 'D';
                Pending = ''
                Other = 'O'
                All = '';
            } else {
                if (Pending === '') {
                    Pending = 'W'
                } else Pending = '';
            }
            break;
        case 'Other':
            if (All === "true") {
                Paid = 'P';
                Denied = 'D';
                Pending = 'W'
                Other = ''
                All = '';
            } else {
                if (Other === '') {
                    Other = 'O'
                } else Other = '';
            }
            break;

    }
    if (Paid === "P" && Denied === "D" && Pending === "W" && Other === "O") {
        Paid = Denied = Pending = Other = "";
        All = "true"
    }

    return {
        '@Paid': Paid, '@Denied': Denied, '@Pending': Pending, '@Other': Other, '@All': All
    }

}

function getServiceType(state: any, e: any) {

    var Inpatient, Outpatient, All;

    if (!state.filter.Filter.ServiceType) {
        Inpatient = Outpatient = All = '';
    } else {
        Inpatient = getData(state.filter.Filter.ServiceType, "@Inpatient");
        Outpatient = getData(state.filter.Filter.ServiceType, "@Outpatient");
        All = getData(state.filter.Filter.ServiceType, "@All");
    }
    switch (e) {
        case 'Inpatient':
            if (All === "true") {
                Inpatient = '';
                Outpatient = 'O';
                All = '';
            } else {
                if (Inpatient === '') {
                    Inpatient = 'I'
                } else Inpatient = '';
            }
            break;
        case 'Outpatient':
            if (All === "true") {
                Inpatient = 'I';
                Outpatient = '';
                All = '';
            } else {
                if (Outpatient === '') {
                    Outpatient = 'O'
                } else Outpatient = '';
            }
            break;

    }

    if (Inpatient === "I" && Outpatient === "O") {
        Inpatient = Outpatient = "";
        All = "true"
    }

    return {
        '@Inpatient': Inpatient, '@Outpatient': Outpatient, '@All': All
    }

}

function getSource(state: any, e: any) {

    var Aii, RA, All;

    if (!state.filter.Filter.Source) {
        Aii = RA = All = '';
    } else {
        Aii = getData(state.filter.Filter.Source, "@Aii");
        RA = getData(state.filter.Filter.Source, "@RA");
        All = getData(state.filter.Filter.Source, "@All");
    }
    switch (e) {
        case 'Aii':
            if (All === "true") {
                Aii = '';
                RA = 'R';
                All = '';
            } else {
                if (Aii === '') {
                    Aii = 'A'
                } else Aii = '';
            }
            break;
        case 'RA':
            if (All === "true") {
                Aii = 'A';
                RA = '';
                All = '';
            } else {
                if (RA === '') {
                    RA = 'R'
                } else RA = '';
            }
            break;

    }

    if (Aii === "A" && RA === "R") {
        Aii = RA = "";
        All = "true"
    }

    return {
        '@Aii': Aii, '@RA': RA, '@All': All
    }

}

function getCheckboxData(state: any, e: any) {

    var NameControl, Secondary, process;

    if (!state.filter.Filter.Candidates_N) {
        NameControl = '';
    } else {
        NameControl = getData(state.filter.Filter.Candidates_N, "@NameControl");
    }
    if (!state.filter.Filter.Candidates_S) {
        Secondary = '';
    } else {
        Secondary = getData(state.filter.Filter.Candidates_S, "@Secondary");
    }

    if (NameControl === 'Y') {
        NameControl = '';
    } else {
        NameControl = 'Y';
    }

    if (Secondary === 'Y') {
        Secondary = '';
    } else {
        Secondary = 'Y';
    }


    if (process === "match") {
        /*FixCandidates*/
        if (e === 'CandidatesN' && !(NameControl === 'Y')) Secondary = '';
        if (e === 'CandidatesS' && (Secondary === 'Y')) NameControl = 'Y';
    }


    if (e === 'CandidatesN') {
        return {
            '@NameControl': NameControl
        }
    } else return {
        '@Secondary': Secondary
    };
}
// ----------------
function removeBadFilterData(action: any, filter: any) {
    // this used to be called xmlFormFill
    let errors: any[] = [];
    const listPopulation = action.data.api?.ListPopulation;
    const watchList = ["RemitLOBList", "FacilityList", "PayerList"]

    for (let key in listPopulation) {
        if (watchList.includes(key)) {

            const obj = deepCopyFunction(listPopulation)[key]
            let availableFilters = obj?.map((data: any) => data.ID)
            let list: any;

            switch (key) {
                case "RemitLOBList": // this is the UI label -> LOB 
                    list = filter.Filter?.LOBs?.LOB;
                    if (list) {
                        filter.Filter.LOBs.LOB = list.filter((data: any, index: number) => {
                            if (!availableFilters.includes(data["@ID"])) {
                                errors.push({ name: 'LOB', value: data["@ID"] })
                            } else {
                                return data
                            }
                        })
                    }
                    break;
                case "FacilityList":
                    list = filter.Filter?.Facilities?.Facility;
                    if (list) {
                        filter.Filter.Facilities.Facility = list.filter((data: any, index: number) => {
                            if (!availableFilters.includes(data["@ID"])) {
                                errors.push({ name: 'Facility', value: data["@ID"] })
                            } else {
                                return data
                            }
                        })
                    }
                    break;
                case "PayerList":
                    list = filter.Filter?.Payers?.Payer;
                    if (list) {
                        filter.Filter.Payers.Payer = list.filter((data: any, index: number) => {
                            if (!availableFilters.includes(data["@ID"])) {
                                errors.push({ name: 'Payer', value: data["@ID"] })
                            } else {
                                return data
                            }
                        })
                    }
                    break;
                default:
                    break;
            }
        }

    }

    let message = null;

    if (errors.length) {
        let warningMessage = "";
        errors.forEach((data: any, index: number) => warningMessage += `${!index ? "(" : ""}${data.name}=${data.value}${errors.length - 1 !== index ? ", " : ")"}`)
        message = `The saved filter contains options that are no longer available ${warningMessage}. Unavailable options have not been loaded. Check your saved filter options.`
    }

    return { filter, message }
}

// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IRemitFilterUIState, KnownActions> = (state: IRemitFilterUIState | undefined, action: KnownActions) => {

    if (state != undefined) {

        var apiResetTest: any = action.type;
        if (apiResetTest == 'API_RESET') {
            return {
                ...state,
                postAction: ''
            };
        }




        switch (action.type) {
            case 'RF_CLEARFOCUS':
            case 'RF_SEND_ALERT':
            case 'RF_ERROR_HANDLER_CONFIRM':
            case 'RF_SETBUSY':
            case 'RF_NCS_ALERT':
                break;
            default:
                state.clearFocus = true;
        }

        switch (action.type) {

            case 'RF_RUAVALIDATEFILTER':
                {
                    switch (action.status.status) {
                        case 'REQUEST':
                            return state;
                            break;
                        case 'SUCCESS':
                        case 'FAIL':
                            //  console.log(action.status.responseCode);
                            if (action.status.responseCode === 306) {

                                // todo need to figure out where the data is for a 306, assuming action.responseData, but since this is not my process, leaving it to the first to implement it
                                return {
                                    ...state,
                                    filterAlert: action.responseData && action.responseData.message ? action.responseData.message : "Unable to process filter. "
                                }
                            } else if (action.status.responseCode !== 200) {
                                return {
                                    ...state,
                                    remitFilterErrorHandler: "Unable to process filter."
                                }
                            } else {
                                return {
                                    ...state,
                                    postAction: "SubmitFilter"
                                }
                            }


                            break;

                    }
                    break;
                }
                break;


            case 'RF_POSTMULTICLAIM':
                {
                    switch (action.status.status) {
                        case 'REQUEST':
                            return state;
                            break;
                        case 'SUCCESS':
                        case 'FAIL':
                            //    console.log(action.status.responseCode);
                            if (action.status.responseCode !== 200) {
                                if (action.responseData && action.responseData.message)
                                    console.log(action.responseData.message);
                                return {
                                    ...state,
                                    remitFilterErrorHandler: "Unable to process filter."
                                }
                            } else {
                                return {
                                    ...state,
                                    postAction: "GoHome"
                                }
                            }

                            break;
                    }
                    break;
                }
                break;



            case 'RF_COMPAREREMIT835COUNT':
                {
                
                    switch (action.status.status) {
                        case 'REQUEST':
                            return state;
                            break;
                        case 'SUCCESS':
                        case 'FAIL':
                             
                            if (action.status.responseCode !== 200) {
                                if (action.responseData && action.responseData.message)
                                    console.log(action.responseData.message);
                                return {
                                    ...state,
                                    remitFilterErrorHandler: "Unable to process filter."
                                }
                            } else {
                              
                                var responseCount = Number(action.responseData);

                                if (isNaN(responseCount))
                                    responseCount = 0;

                                if (responseCount <= 20 && responseCount != 0) {
                                    return {
                                        ...state,
                                        postAction: "JobRequest"
                                    }
                                } else if (responseCount == 0) {
                                    return {
                                        ...state,
                                        filterAlert: "The Remits selected pre-date the functionality of this feature, please refine your filter."
                                    }
                                } else {
                                    return {
                                        ...state,
                                        filterAlert: "Too many results returned, please narrow your search criteria."
                                    }
                                }
                            }
                            break;

                    }
                    break;
                }
                break;


            case 'RF_COMPAREREMITFILECOUNT':
                {
                    switch (action.status.status) {
                        case 'REQUEST':
                            return state;
                            break;
                        case 'SUCCESS':
                        case 'FAIL':
                            console.log(action.status.responseCode);
                            if (action.status.responseCode !== 200) {
                                if (action.responseData && action.responseData.message)
                                    console.log(action.responseData.message);
                                return {
                                    ...state,
                                    remitFilterErrorHandler: "Unable to process filter."
                                }
                            } else {
                                var responseCount = Number(action.responseData);
                                 if (isNaN(responseCount))
                                    responseCount = 0;
                                if (responseCount == 1) {
                                    return {
                                        ...state,
                                        postAction: "viewx12remitRedirect"
                                    }
                                } else if (responseCount == 0) {
                                    return {
                                        ...state,
                                        filterAlert: "The search did not return any results, please modify your filter criteria."
                                    }

                                } else {
                                    return {
                                        ...state,
                                        filterAlert: "Too many results returned, please narrow your search criteria or use refine to select a single file."
                                    }
                                }
                            }

                            break;
                    }
                    break;
                }
                break;

                
            case 'RF_PUTREMITFILTERLIST':
                {
                    switch (action.status.status) {
                        case 'REQUEST':
                            return state;
                            break;
                        case 'SUCCESS':
                        case 'FAIL':
                            //    console.log(action.status.responseCode);
                            if (action.status.responseCode !== 200) {
                                if (action.responseData && action.responseData.message)
                                    console.log(action.responseData.message);
                                return {
                                    ...state,
                                    remitFilterErrorHandler: "An error occurred setting the current remit list on the server."
                                }
                            } else {
                                return {
                                    ...state,
                                    postAction: "GoHome"
                                }
                            }

                            break;
                    }
                    break;
                }
                break;









            case 'RF_SELECT_DISPLAYORDER':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            DisplayOrder: action.data.uiData.selected
                        }
                    }
                }
                break;

            case 'RF_LOAD_FILTER':
                const oldFilter = action.data.uiData.data !== undefined ? deepCopyFunction(action.data.uiData.data) : deepCopyFunction({ ...emptyFilter })  // copy of the object
                const { message, filter } = removeBadFilterData(action, oldFilter)

                return {
                    ...state,
                    filter,
                    loaded: true,
                    isBusy: false,
                    dataRefreshed: true,
                    filterAlert: '',
                    remitFilterErrorHandler: '',
                    loadedFilterErrorMessage: message
                }
                break;

            case 'RF_CLEAR_LOADED_FILTER_ERROR':
                return {
                    ...state,
                    loadedFilterErrorMessage: action.data.uiData.value,
                }
                break;

            case 'RF_UPDATE_IMPORT_DATE':
                var importDate = state.filter.Filter.ImportDate || {};
                if (action.data.uiData.data.from == true)
                    importDate['@From'] = action.data.uiData.data.date;
                else
                    importDate['@To'] = action.data.uiData.data.date;

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            ImportDate: importDate
                        }
                    }
                }
                break;

            case 'RF_UPDATE_PMT_DATE':
                var pmtDate = state.filter.Filter.PmtDate || {};
                if (action.data.uiData.data.from == true)
                    pmtDate['@From'] = action.data.uiData.data.date;
                else
                    pmtDate['@To'] = action.data.uiData.data.date;

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            PmtDate: pmtDate
                        }
                    }
                }
                break;

            case 'RF_UPDATE_SEC_BILL':
                var secBill = state.filter.Filter.SecBill || {};
                if (action.data.uiData.data.from == true)
                    secBill['@From'] = action.data.uiData.data.date;
                else
                    secBill['@To'] = action.data.uiData.data.date;

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            SecBill: secBill
                        }
                    }
                }
                break;

            case 'RF_UPDATE_SERVICE_FROM':
                var serviceFrom = state.filter.Filter.ServiceFrom || {};
                if (action.data.uiData.data.from == true)
                    serviceFrom['@From'] = action.data.uiData.data.date;
                else
                    serviceFrom['@To'] = action.data.uiData.data.date;

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            ServiceFrom: serviceFrom
                        }
                    }
                }
                break;

            case 'RF_UPDATE_SERVICE_THRU':
                var serviceThru = state.filter.Filter.ServiceThru || {};
                if (action.data.uiData.data.from == true)
                    serviceThru['@From'] = action.data.uiData.data.date;
                else
                    serviceThru['@To'] = action.data.uiData.data.date;

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            ServiceThru: serviceThru
                        }
                    }
                }
                break;

            case 'RF_UPDATE_FISCALPERIOD':
                var fisPeriod = state.filter.Filter.FiscalPeriod || {};
                var data = action.data.uiData?.data?.date ?? "";
                if (data.length == 4 && data.indexOf('/') == -1) {
                    data = data.slice(0, 2) + "/" + data.slice(2);
                }

                if (action.data.uiData.data.from == true)
                    fisPeriod['@From'] = data;
                else
                    fisPeriod['@To'] = data;

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            FiscalPeriod: fisPeriod
                        }
                    }
                }
                break;

            case 'RF_UPDATE_APCCODE':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            APCCode: { '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;

            case 'RF_UPDATE_CONTRACTUALADJ':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            ContractualAdj: { '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;

            case 'RF_UPDATE_LASTNAME':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            LastName: { '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;

            case 'RF_UPDATE_PATIENTICNNUMBER':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            PatientICNNumber: { '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;

            case 'RF_UPDATE_PAYMENTAMT':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            PaymentAmt: { '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;
            case 'RF_UPDATE_CHECKAMT':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            CheckAmt: { '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;

            case 'RF_UPDATE_REMITPROVIDERNPI':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            RemitProviderNPI: { '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;
            case 'RF_UPDATE_REMITFEDERALTAXID':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            RemitFederalTaxId: { '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;

            case 'RF_UPDATE_REMARKCODEID':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            RemarkCode: {
                                ...state.filter.Filter.RemarkCode,
                                '@ID': action.data.uiData.value
                            },
                        }
                    }
                }
                break;

            case 'RF_UPDATE_REMARKCODE':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            RemarkCode: {
                                ...state.filter.Filter.RemarkCode,
                                '@Value': action.data.uiData.value
                            },
                        }
                    }
                }
                break;


            case 'RF_UPDATE_STATUS':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            Status: { '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;

            case 'RF_UPDATE_AMTBILLED':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            AmtBilled: { '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;

            case 'RF_UPDATE_REMITTYPEOFBILL':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            RemitTypeOfBill: { '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;


            case 'RF_UPDATE_ADJUSTMENTCODEID':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            AdjustmentCode: {
                                ...state.filter.Filter.AdjustmentCode,
                                '@ID': action.data.uiData.value
                            },
                        }
                    }
                }
                break;

            case 'RF_UPDATE_ADJUSTMENTCODE':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            AdjustmentCode: {
                                ...state.filter.Filter.AdjustmentCode,
                                '@Value': action.data.uiData.value
                            },
                        }
                    }
                }
                break;



            case 'RF_UPDATE_GROUPCODEID':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            GroupCode: {
                                ...state.filter.Filter.GroupCode,
                                '@ID': action.data.uiData.value
                            },
                        }
                    }
                }
                break;

            case 'RF_UPDATE_GROUPCODE':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            GroupCode: {
                                ...state.filter.Filter.GroupCode,
                                '@Value': action.data.uiData.value
                            },
                        }
                    }
                }
                break;

            case 'RF_UPDATE_CHECKNUMBER':
                var isSingle = isSingleEntry(action.data.uiData.value);
                var areDatesDirty = isImportOrPmtDatesDirty(state);
                var areDatesEmpty = isImportAndPmtDatesEmpty(state);
                var bClearDate = false;
                var bDefaultDate = false;

                if (areDatesDirty == false && isSingle == true) {
                    bClearDate = true;
                } else if (areDatesEmpty == true && isSingle == false) {
                    bDefaultDate = true;
                }

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            CheckNumber: { '@Value': action.data.uiData.value },
                            ...(bClearDate === true && {
                                ImportDate: { '@From': '', '@To': '' }
                            }),
                            ...(bDefaultDate === true && {
                                ImportDate: { '@From': '00/01/0000', '@To': '00/00/0000' }
                            }),
                        }
                    }
                }
                break;

            case 'RF_UPDATE_FILENAME':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            FileName: { '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;

            case 'RF_UPDATE_PATIENTCONTROLNUMBER':
                var isSingle = isSingleEntry(action.data.uiData.value);
                var areDatesDirty = isImportOrPmtDatesDirty(state);
                var areDatesEmpty = isImportAndPmtDatesEmpty(state);
                var bClearDate = false;
                var bDefaultDate = false;

                if (areDatesDirty == false && isSingle == true) {
                    bClearDate = true;
                } else if (areDatesEmpty == true && isSingle == false) {
                    bDefaultDate = true;
                }

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            PatientControlNumber: { '@Value': action.data.uiData.value },
                            ...(bClearDate === true && {
                                ImportDate: { '@From': '', '@To': '' }
                            }),
                            ...(bDefaultDate === true && {
                                ImportDate: { '@From': '00/01/0000', '@To': '00/00/0000' }
                            }),
                        }
                    }
                }
                break;


            case 'RF_UPDATE_REMITSTATE':
                var csData = getRemitState(state, action.data.uiData.value);
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            RemitState: csData
                        }
                    }
                }
                break;

            case 'RF_UPDATE_REMITSTATUS':
                var cstatusData = getRemitStatus(state, action.data.uiData.value);
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            RemitStatus: cstatusData
                        }
                    }
                }
                break;

            case 'RF_UPDATE_SERVICETYPE':
                var cserviceData = getServiceType(state, action.data.uiData.value);
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            ServiceType: cserviceData
                        }
                    }
                }
                break;

            case 'RF_UPDATE_SOURCE':
                var csourceData = getSource(state, action.data.uiData.value);
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            Source: csourceData
                        }
                    }
                }
                break;

            case 'RF_UPDATE_CANDIDATES_N':
                var checkboxData = getCheckboxData(state, action.data.uiData.value);
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            Candidates_N: checkboxData
                        }
                    }
                }
                break;

            case 'RF_UPDATE_CANDIDATES_S':
                var checkboxData = getCheckboxData(state, action.data.uiData.value);
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            Candidates_S: checkboxData
                        }
                    }
                }
                break;

            case 'RF_UPDATE_SELECTED_PAYERS':

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            Payers: { Payer: action.data.uiData.value, }
                        }
                    }
                }
                break;

            case 'RF_UPDATE_SELECTED_REMITLOBS':

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            LOBs: { LOB: action.data.uiData.value, }
                        }
                    }
                }
                break;

            case 'RF_UPDATE_SELECTED_LOBS':

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            RemitLOBs: { RemitLOB: action.data.uiData.value, }
                        }
                    }
                }
                break;

            case 'RF_UPDATE_SELECTED_PROVIDERS':

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            Providers: { Provider: action.data.uiData.value, }
                        }
                    }
                }
                break;

            case 'RF_UPDATE_SELECTED_PROVIDERNPIS':

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            ProviderNPIs: { ProviderNPI: action.data.uiData.value, }
                        }
                    }
                }
                break;

            case 'RF_UPDATE_SELECTED_FACILITIES':

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            Facilities: { Facility: action.data.uiData.value, }
                        }
                    }
                }
                break;

            case 'RF_SET_ACTIVE_TAB':

                return {
                    ...state,
                    activeTab: action.data.uiData.data,

                }

                break;


            case 'RF_SELECT_FILTER':
                return {
                    ...state,
                    filterName: action.data.uiData.value,
                    filterId: action.data.uiData.id,
                    focusField: '',
                }
                break;



            case 'RF_RELOAD_FILTERLIST':
                {
                    var newFilter: any = {};
                    newFilter['@ID'] = "0";
                    newFilter['@Name'] = "";
                    newFilter['@Favorite'] = "9999";
                    newFilter['@FilterType'] = "R";
                    newFilter['@FilterAssigned'] = "0";
                    newFilter['@Candidate'] = "";
                    newFilter['@MatchType'] = "";

                    if (action.data.api && action.data.api.ListPopulation && action.data.uiData.data) {
                        var lp = JSON.parse(action.data.uiData.data);
                        var filterId = lp.ListPopulation.FilterList['@Current'];

                        //this.props.dataStore.api?.data2?.ListPopulation?.FilterList?.Filter?.length 
                        newFilter['@ID'] = filterId;

                        if (filterId != "0" && lp.ListPopulation.FilterList.Filter) {
                            for (var i = 0; i < lp.ListPopulation.FilterList.Filter.length; i++) {
                                if (lp.ListPopulation.FilterList.Filter[i]['@ID'] === filterId) {
                                    newFilter['@Name'] = lp.ListPopulation.FilterList.Filter[i]['@Name'];
                                    newFilter['@MatchType'] = lp.ListPopulation.FilterList.Filter[i]['@MatchType'];

                                    if (!action.data.api.ListPopulation.FilterList.Filter.find((obj: any) => obj['@Name'] === newFilter['@Name']))
                                        action.data.api.ListPopulation.FilterList.Filter.push(newFilter);
                                    break;
                                }
                            }
                        }
                    }

                    return {
                        ...state,
                        filterId: Number(newFilter['@ID']),
                        filterName: newFilter['@Name'],
                        filter: {
                            ...state.filter,
                            Filter: {
                                ...state.filter.Filter,
                                '@ID': newFilter['@ID'],
                                '@Name': newFilter['@Name'],
                            }
                        }
                    }
                    break;

                }

            case 'RF_OPEN_FILTERLIST':
                {
                    return {
                        ...state,
                        isFilterListOpen: true,
                        filterName: '',
                        filterId: 0,
                    }
                    break;
                }
            case 'RF_CLOSE_FILTERLIST':
                {
                    return {
                        ...state,
                        isFilterListOpen: false,
                    }
                    break;
                }
            case 'RF_NCS_ALERT':
                return {
                    ...state,
                    saveFilterNcsAlert: action.data.uiData.value,
                }
                break;

            case 'RF_SET_DATA_REFRESHED':
                return {
                    ...state,
                    dataRefreshed: action.data
                }
                break;

            case 'RF_SEND_ALERT':
                return {
                    ...state,
                    filterAlert: action.data,
                }
                break;
            case 'RF_ERROR_HANDLER_CONFIRM':
                return {
                    ...state,
                    remitFilterErrorHandler: action.data.uiData.value,
                }
                break;

            case 'RF_SET_LOADED':
                return {
                    ...state,
                    loaded: action.data.uiData.value
                }
                break;

            case 'RF_CLEARFOCUS':
                return {
                    ...state,
                    clearFocus: false
                }
                break;

            case 'RF_SETPOSTACTION':
                return {
                    ...state,
                    postAction: action.data
                }
                break;

            case 'RF_SETBUSY':
                return {
                    ...state,
                    isBusy: action.data.uiData.value,
                }
                break;



            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                return state;  // having a weird issue with postAction in state, not really clearing on exit as it should
        }
    }

    return state || defaultState;
}

