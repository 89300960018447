import * as React from "react";
import { DialogFieldset, DialogLegend } from "../../../common/DialogStyles";
import { CrudPermissions } from "../CrudPermissions";
import styled from 'styled-components';
import { DefaultFacilityGenerator } from "../DefaultFacilityGenerator";
import { GenericValidator } from "../Validators/GenericValidator";
import { RegExValidator } from "../Validators/RegExValidator";
import { IsAlphaNumeric, IsNumeric } from "@commonResources/validations";
import { Facility, List, MCFacilityMaintenanceInfo } from "../../../../store/api/FacilityMasterType";

export const ComponentWrapper = styled.div`
    .fieldLabel{
        font-weight: bold;
        text-align: right;
        white-space: nowrap;
    }

    .facilityName{
        width: 300px;
    }
`;

interface IComponentProps {
    maintenanceInfo: MCFacilityMaintenanceInfo | undefined,
    facility: Facility,
    crudPermissions: CrudPermissions,
    facilityUpdateRoutine: Function,
    fieldFocus: string,
    isFacilityInfoSubmit: boolean,
    validationAggregationRoutine: Function,
    validationAggregation: Map<string, boolean>,
    cursorPos: number,
}

interface IComponentState {
    facility: Facility,
    isFacilityInfoSubmit: boolean,
}

export const DEFAULT_STATE: IComponentState = {
    facility: DefaultFacilityGenerator.generateDefaultFacility(),
    isFacilityInfoSubmit: false,
}

export class PayToAddress extends React.Component<IComponentProps, IComponentState> {

    constructor(props: IComponentProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    getStatesDropdown() {
        const {
            maintenanceInfo,
            facility,
            fieldFocus,
            facilityUpdateRoutine
        } = this.props;

        const selectedStateId = facility["@PayToStateID"]

        // prepend a default option to the start of states
        let states: List[] = [{ "@ID": "0", "@Name": "- Select State -" }].concat(
            // filter out redundant blank record with id = 0
            maintenanceInfo?.StateList?.State.filter(x => x["@ID"] != '0') || []);

        return (
            <select
                id='PayToStateID'
                autoFocus={fieldFocus == 'PayToStateID'}
                style={{ height: 26 }}
                onChange={x => {
                    facilityUpdateRoutine("@PayToStateID", x.currentTarget.value, 'PayToStateID')
                }}
                onBlur={x => {
                    facilityUpdateRoutine("@PayToStateID", x.currentTarget.value, 'PayToStateID')
                }}
                value={selectedStateId}
            >
                { states && states.map(x => {
                    return <option key={x["@ID"]} value={x["@ID"]}>{x["@Name"]}</option>
                })}
            </select>
        );
    }

    isSameAddressTrigger() {
        const {
            facility,
            isFacilityInfoSubmit,
        } = this.props;

        const hasSomeAddress =
            (facility["@Address"].length > 0 && facility["@PayToAddress"].length > 0) ||
            // (facility["@Address2"].length > 0 && facility["@PayToAddress2"].length > 0) ||
            (facility["@City"].length > 0 && facility["@PayToCity"].length > 0) ||
            (facility["@StateID"].length > 0 && facility["@PayToStateID"].length > 0) ||
            (facility["@ZipCode"].length > 0 && facility["@PayToZipCode"].length > 0);

        const isSameAddress =
            facility["@Address"].toUpperCase() == facility["@PayToAddress"].toUpperCase() &&
            facility["@Address2"].toUpperCase() == facility["@PayToAddress2"].toUpperCase() &&
            facility["@City"].toUpperCase() == facility["@PayToCity"].toUpperCase() &&
            facility["@StateID"].toUpperCase() == facility["@PayToStateID"].toUpperCase() &&
            facility["@ZipCode"].toUpperCase() == facility["@PayToZipCode"].toUpperCase();

        return (isFacilityInfoSubmit && hasSomeAddress && isSameAddress);
    }

    inputOnChange(x: React.ChangeEvent<HTMLInputElement>) {
        const {
            facilityUpdateRoutine,
        } = this.props;

        const inputId = x.currentTarget.id;
        const fieldId = '@' + inputId;
        const inputValue = x.currentTarget.value;
        const cursorPos = x.target.selectionStart;

        facilityUpdateRoutine(fieldId, inputValue, inputId, cursorPos)
    }

    inputOnBlur(x: React.FocusEvent<HTMLInputElement>) {
        const {
            facilityUpdateRoutine,
        } = this.props;

        const inputId = x.currentTarget.id;
        const fieldId = '@' + inputId;

        // remove leading and trailing spaces from the input value per legacy
        let inputValue = x.currentTarget.value.trim();
        x.currentTarget.value = inputValue;

        // cursor pos of 0 selects all text in the input
        facilityUpdateRoutine(fieldId, inputValue, inputId, 0);
    }

    render() {
        const {
            facility,
            facilityUpdateRoutine,
            fieldFocus,
            isFacilityInfoSubmit,
            cursorPos,
            validationAggregation,
            validationAggregationRoutine,
        } = this.props;

        const ddlStates = this.getStatesDropdown();

        const payToNameRequiredTrigger =
            isFacilityInfoSubmit &&
            (
                facility["@PayToName"].length == 0 &&
                (facility["@PayToAddress"].length > 0 ||
                    facility["@PayToAddress2"].length > 0 ||
                    facility["@PayToCity"].length > 0 ||
                    (facility["@PayToStateID"].length > 0 && facility["@PayToStateID"] != '0') ||
                    facility["@PayToZipCode"].length > 0)
            );

        const isSameAddressTrigger = this.isSameAddressTrigger();

        return (
            <ComponentWrapper>
                <DialogFieldset>
                    <DialogLegend>Pay-To Address</DialogLegend>
                    <table>
                        <tbody>

                            <GenericValidator
                                fieldId="PayToAddress"
                                fieldName="PayToAddress"
                                trigger={
                                    isSameAddressTrigger
                                }
                                message={'Pay-To Address must be different than Billing Provider Address'}
                                style={{ width: 300 }}
                                validationAggregation={validationAggregation}
                                validationAggregationRoutine={validationAggregationRoutine}
                            />

                            {/* Facility Name */}
                            <tr>
                                <td className="fieldLabel">Facility Name:</td>
                                <td style={{ width: 5 }}></td>
                                <td>
                                    <input
                                        id='PayToName'
                                        autoFocus={fieldFocus == 'PayToName'}
                                        type="text"
                                        value={facility["@PayToName"]}
                                        onBlur={x => {
                                            this.inputOnBlur(x);
                                        }}
                                        onChange={x => {
                                            this.inputOnChange(x); 
                                        }}
                                        maxLength={60}
                                        style={{ width: 300 }}
                                    />
                                </td>
                            </tr>

                            <GenericValidator
                                fieldId="PayToName.Required"
                                fieldName="PayToName"
                                message="Pay-To Facility Name is required"
                                trigger={
                                    payToNameRequiredTrigger
                                }
                                style={{}}
                                validationAggregation={validationAggregation}
                                validationAggregationRoutine={validationAggregationRoutine}
                            />

                            {/* Address */}
                            <tr>
                                <td className="fieldLabel">Address:</td>
                                <td style={{ width: 5 }}></td>
                                <td>
                                    <input
                                        id='PayToAddress'
                                        autoFocus={fieldFocus == 'PayToAddress'}
                                        type="text"
                                        value={facility["@PayToAddress"]}
                                        onChange={x => {
                                            this.inputOnChange(x);
                                        }}
                                        onBlur={x => {
                                            this.inputOnBlur(x);
                                        }}
                                        maxLength={55}
                                        style={{ width: 300 }}
                                    />
                                </td>
                            </tr>

                            {/* if form has "Pay-To Name" then "Pay-To Address" is required */}
                            <GenericValidator
                                fieldId={'PayToAddress.Conditional'}
                                fieldName={'Pay To Address'}
                                trigger={
                                    isFacilityInfoSubmit &&
                                    (facility["@PayToName"].length > 0) &&
                                    (facility["@PayToAddress"].length == 0)
                                }
                                message={'Pay-To Address is required when Pay-To Name is present'}
                                style={{ width: 300 }}
                                validationAggregation={validationAggregation}
                                validationAggregationRoutine={validationAggregationRoutine}
                            />

                            {/* Address 2 */}
                            <tr>
                                <td className="fieldLabel">Address 2:</td>
                                <td style={{ width: 5 }}></td>
                                <td>
                                    <input
                                        id='PayToAddress2'
                                        type="text"
                                        maxLength={55}
                                        style={{ width: 300 }}
                                        value={facility["@PayToAddress2"]}
                                        autoFocus={fieldFocus == 'PayToAddress2'}
                                        onChange={x => {
                                            this.inputOnChange(x);
                                        }}
                                        onBlur={x => {
                                            this.inputOnBlur(x);
                                        }}
                                    />
                                </td>
                            </tr>

                            {/* City */}
                            <tr>
                                <td className="fieldLabel">City:</td>
                                <td style={{ width: 5 }}></td>
                                <td>
                                    <input
                                        id='PayToCity'
                                        autoFocus={fieldFocus == 'PayToCity'}
                                        type="text"
                                        value={facility["@PayToCity"]}
                                        onChange={x => {
                                            this.inputOnChange(x);
                                        }}
                                        onBlur={x => {
                                            this.inputOnBlur(x);
                                        }}
                                        maxLength={30}
                                        style={{ width: 300 }}
                                    />
                                </td>
                            </tr>

                            <RegExValidator
                                fieldId={'txtPayToCity.AlphaNumeric'}
                                fieldName={'Pay-To City'}
                                message={'Pay-To City must consist of valid characters'}
                                trigger={
                                    isFacilityInfoSubmit &&
                                    !IsAlphaNumeric(facility["@PayToCity"])
                                }
                                style={{ width: 300 }}
                                validationAggregation={validationAggregation}
                                validationAggregationRoutine={validationAggregationRoutine}
                            />

                            {/* if form has "Pay-To Name" then "Pay-To City" is required */}
                            <GenericValidator
                                fieldId={'PayToCity.Conditional'}
                                fieldName={'Pay To City'}
                                trigger={
                                    isFacilityInfoSubmit &&
                                    (facility["@PayToName"].length > 0) &&
                                    (facility["@PayToCity"].length == 0)
                                }
                                message={'Pay-To City is required when Pay-To Name is present'}
                                style={{ width: 300 }}
                                validationAggregation={validationAggregation}
                                validationAggregationRoutine={validationAggregationRoutine}
                            />

                            {/* State */}
                            <tr>
                                <td className="fieldLabel">State:</td>
                                <td style={{ width: 5 }}></td>
                                <td>{ddlStates}</td>
                            </tr>

                            {/* if form has "Pay-To Name" then "Pay-To State" is required */}
                            <GenericValidator
                                fieldId={'PayToState'}
                                fieldName={'Pay To State'}
                                trigger={
                                    isFacilityInfoSubmit &&
                                    (facility["@PayToName"].length > 0) &&
                                    (facility["@PayToStateID"] == '' || facility["@PayToStateID"] == '0')
                                }
                                message={'Pay-To State is required when Pay-To Name is present'}
                                style={{ width: 300 }}
                                validationAggregation={validationAggregation}
                                validationAggregationRoutine={validationAggregationRoutine}
                            />

                            {/* ZIP */}
                            <tr>
                                <td className="fieldLabel">ZIP:</td>
                                <td style={{ width: 5 }}></td>
                                <td>
                                    <input
                                        id='PayToZipCode'
                                        autoFocus={fieldFocus == 'PayToZipCode'}
                                        type="text"
                                        value={facility["@PayToZipCode"]}
                                        onChange={x => {
                                            this.inputOnChange(x);
                                        }}
                                        onBlur={x => {
                                            this.inputOnBlur(x);
                                        }}
                                        maxLength={9}
                                        style={{ width: 150 }}
                                    />
                                </td>
                            </tr>

                            {/* if form has "Pay-To Name" then "Pay-To ZIP" is required */}
                            <GenericValidator
                                fieldId={'PayToZIP'}
                                fieldName={'Pay To ZIP'}
                                trigger={
                                    isFacilityInfoSubmit &&
                                    (facility["@PayToName"].length > 0) &&
                                    (facility["@PayToZipCode"].length == 0)
                                }
                                message={'Pay-To ZIP is required when Pay-To Name is present'}
                                style={{ width: 300 }}
                                validationAggregation={validationAggregation}
                                validationAggregationRoutine={validationAggregationRoutine}
                            />

                            <RegExValidator
                                fieldId={'txtPayToZipCode.RegEx.Numeric'}
                                fieldName={'Pay-To ZIP Code'}
                                message={'Pay-To ZIP must be numeric'}
                                trigger={
                                    isFacilityInfoSubmit &&
                                    !IsNumeric(facility["@PayToZipCode"])
                                }
                                style={{ width: 300 }}
                                validationAggregation={validationAggregation}
                                validationAggregationRoutine={validationAggregationRoutine}
                            />

                            <RegExValidator
                                fieldId={'txtPayToZipCode.RegEx.Length'}
                                fieldName={'Pay To Zip Code'}
                                trigger={
                                    isFacilityInfoSubmit &&
                                    facility["@PayToZipCode"].length > 0 &&
                                    IsNumeric(facility["@PayToZipCode"]) &&
                                    ![5, 9].includes(facility["@PayToZipCode"].length)
                                }
                                message={'Pay-To ZIP must consist of either 5 numbers or 9 numbers'}
                                style={{ width: 300 }}
                                validationAggregation={validationAggregation}
                                validationAggregationRoutine={validationAggregationRoutine}
                            />

                            {/* Copy to Claim */}
                            <tr>
                                <td></td>
                                <td></td>
                                <td className="fieldLabel" style={{ textAlign: 'left' }}>
                                    <input
                                        id='PayToCopyAddressToClaim'
                                        autoFocus={fieldFocus == 'PayToCopyAddressToClaim'}
                                        type="checkbox"
                                        checked={facility["@PayToCopyAddressToClaim"] == 'Y'}
                                        onChange={x => {
                                            facilityUpdateRoutine(
                                                "@PayToCopyAddressToClaim",
                                                x.currentTarget.checked ? 'Y' : 'N',
                                                'PayToCopyAddressToClaim',
                                                0)
                                        }}
                                        onBlur={x => {
                                            facilityUpdateRoutine(
                                                "@PayToCopyAddressToClaim",
                                                x.currentTarget.checked ? 'Y' : 'N',
                                                'PayToCopyAddressToClaim',
                                                0)
                                        }}
                                    />
                                    Copy to Claim
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </DialogFieldset>
            </ComponentWrapper>
        );
    }
}
