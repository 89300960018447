import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '../../commonResources/typography';
import { CheckBoxComponent } from '../common/CheckBox';
import { Button } from '@optum-uicl/ui-core/dist';
import { noop } from 'lodash';
import { Colors } from '@commonResources/colorVariables';
import { Checkbox } from '@optum-uicl/ui-core/dist';


export interface IOptionComponentProps {
    title?: string;
    value: any;
    text: any;
    deleted?: string;
    selected?: string;
    disabled?: string;
    highlighted: string;
};

//TODO: should this be here, since we have the IOption... above?
export interface ISelectableItem {
    id: string;
    description: string,
    isSelected: boolean,
}


interface ICheckboxGroupComponentProps extends React.HTMLProps<HTMLDivElement> {
    width?: string;
    height?: string;
    title?: string;
    onCheck?: (id:string) => void;
    optionFields: IOptionComponentProps;
    records?: any[];
    setAllOrNone: (isSelected: boolean) => void;
    selectAllIsSelected: boolean;
    domId?: string;
}
export const SelectButton = styled.button`
display: flex;
border-radius: 999px;
height: 22px;
padding: 4px 4px;
border: 1px solid #ffffff;
justify-content: center;
align-items: center;
font-size: 11px;
color: #002677;
background-color: #ffffff;
font-size: 14px;
font-family: Optum Sans;
font-weight: 400;
letter-spacing: 0px;
line-height: 150%;
text-decoration: underline;


&:hover {
background-color: #f2f7ff;
color: #002677;
border: 1px solid #e9f1ff;
cursor:pointer;

}
`;

export const SelectGroupWrapper = styled.div<ICheckboxGroupComponentProps | any>`
    display: flex;
    flex: 1 1 auto;
    ${(props: ICheckboxGroupComponentProps) => props.width ? `width: ${props.width}` : `width:300px`};
    ${(props: ICheckboxGroupComponentProps) => props.width ? `max-width: ${props.width}` : `max-width:300px`};
    ${(props: ICheckboxGroupComponentProps) => props.height ? `height: ${props.height}` : `height:200px`};
    ${(props: ICheckboxGroupComponentProps) => props.height ? `min-height: ${props.height}` : ''};
    flex-direction: column;
    font-family: ${Typography.ARMFontFamily};
    .san-header {
        display: flex;
        flex-direction: row;
        flex: 0 1 auto;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        padding: .3em 0;
    }
    .san-item-container { 
        border: 2px solid #ccc;
        width: 100%;
        max-width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        background: white;
        flex: 1 0 1px;
        padding: 3px 0px 3px 6px;
    }
    .group-title {
        color: ${Colors.grey100};
        font-weight: 600;
        font-size: 15px; 
        margin: 0;
    }
    &.is-disabled {
        cursor: not-allowed;
        .san-item-container, button {
            pointer-events: none;
        }
    }
    div[id^="codewrapper-"] {
        margin-bottom:4px;
    }
    div[id^="codewrapper-"] div{
        align-self: flex-start !important;
        margin-top: 1px;
    }
`;

export const ItemWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    alignItems: center,
    height: '20px',
    &:hover {
        background-color: lightgrey;
    }
    &.san-highlighted-row {
        background: #ADD8E6;
    }
    label.arm_checkbox_label.arm_checkbox_label.arm_checkbox_label{
        font-weight: normal;
        white-space: nowrap;
        text-overflow: hidden;
    }   
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;   
`

export const CheckBoxGroupLabel = styled.label`
    padding-left:3px;
    padding-top: 2px;
    font-size: 10pt;
    ${Typography.ARMFontFamily};
    ${Typography.bold};
`;

export class SelectAllOrNoneComponent extends React.Component<ICheckboxGroupComponentProps>
{

    constructor(props: ICheckboxGroupComponentProps) {
        super(props);
    }

    static defaultProps: ICheckboxGroupComponentProps = {
        records: [],
        setAllOrNone: noop,
        optionFields: {
            value: "id",
            text: "description",
            selected: "isSelected",
            disabled: 'isDisabled',
            title: 'description',
            highlighted: 'isHighlighted',
        },
        selectAllIsSelected: true,
    };

    public setAllOrNone() {
        this.props.setAllOrNone(this.props.selectAllIsSelected);
    }

    public setCheckedState(id: string) {
        this.props.onCheck && this.props.onCheck(id); 
    }


    public renderStringTemplate(item: any) {
        const { optionFields: { value, text } } = this.props;
        return item[text];
    }

    public renderItems = () => {
        const { optionFields: { value, text, disabled = '', selected = '', highlighted='', title } } = this.props;
        return this.props.records?.map((item: any, index) => {
            const itemTitle = item[title || text];
            const className = item[highlighted] ? 'san-highlighted-row' : '';
            return (
                <ItemWrapper
                    key={index}
                    id={`codewrapper-${index}`}
                    title={itemTitle}
                    className={className}
                >
                    <Checkbox
                        domID={index.toString()}
                        label={this.renderStringTemplate(item)}
                        checked={item[selected || 'isSelected']}
                        disabled={item[disabled || 'isDisabled']}                        
                        onChange={() => this.setCheckedState(item[value])}
                    />
                </ItemWrapper>
            )
        })
    }

    render() {
        return (
            <SelectGroupWrapper className={this.props.className} width={this.props?.width} height={this.props?.height}>
                <div className='san-header'>
                    <p className='group-title'> {this.props.title}: </p>                   
                    <SelectButton                       
                        name={!this.props.selectAllIsSelected ? "Select All" : 'Select None'}
                        style={{ textAlign: 'center' }}
                        type="button"
                        disabled={this.props.className === 'is-disabled'}
                        onClick={() => {
                            this.props.setAllOrNone(!this.props.selectAllIsSelected);
                        }}
                    >{!this.props.selectAllIsSelected ? "Select All" : 'Select None'}</SelectButton>
                </div>
                <div className='san-item-container'>
                    {this.renderItems()}
                </div>
            </SelectGroupWrapper>
        )
    }
}



