import * as React from 'react';
import styled from 'styled-components';
import { RouteComponentProps, Prompt, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindAll } from 'lodash';
import moment from 'moment';
import { Location } from 'history';
import { SelectDropdown, Radio, Button } from '@optum-uicl/ui-core/dist';
import { ARMDatePicker } from '@common/DateOrTimeRelated/ARMDatePicker';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { OKCancelButtons, DialogWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { ModalConfirmation, leaveMessage } from '@common/ModalConfirmation';
import { URLs } from '@commonDevResources/constants';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { IFormModalState, defaultFormModalState } from '../../../store/ui/BaseCrudUI';
import { ApplicationState } from '@store/index';
import { IReportOptionsUIState, IReportOptionsActionProps, actionCreators, validationCallback } from '@store/ReportOptions';
import { BoldSelectWrapper } from '../../common/component-styles';
import { getRawToken } from '@scripts/session/SecurityToken';
import { IModalConfirmationProps } from '@common/ModalConfirmation';

const ButtonContainer = styled.div`
    text-align: center;
    margin-top: 5px;
`
const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

const DateWrapper = styled.div`
    margin: 0 15px;
    .col2_sep {
        margin-left: 61px;
        label {
            margin-top: 8px;
        }
    }
    .col2_sep, .col3_sep {
        flex-direction: row;

        label {
            margin-top: 9px;
        }
    }
`

const armDateFormat = 'MM/DD/YYYY';

export interface IComponentState extends IFormModalState {
    isPageBusy: boolean;
    cancelLeave: boolean;
    todayMoment: moment.Moment;
    fromDate: string;
    fileOptions: any;
    filterRegenerateData: any;
    isModalOpen: boolean;
    minFromDate: string;
    minToDate: string;
    maxFromDate: string;
    maxToDate: string;
    modalMessage: string;
    record?: string;
    today: string;
    thruDate: string;
    saveOnNavigate: boolean;
    modalProps: Partial<IModalConfirmationProps>;
};

const DEFAULT_STATE: IComponentState = {
    isPageBusy: true,
    cancelLeave: false,
    todayMoment: moment(),
    today: moment().format(armDateFormat),
    fileOptions: [],
    filterRegenerateData: [{
        "Files": [{
            "Filename": "",
            "Record": ""
        }]
    }],
    fromDate: moment().subtract(8, 'days').format(armDateFormat),
    isModalOpen: false,
    thruDate: moment().format(armDateFormat),
    minFromDate: moment().subtract(89, 'days').format(armDateFormat),
    minToDate: moment().subtract(8, 'days').format(armDateFormat),
    maxFromDate: moment().add(1, 'days').format(armDateFormat),
    maxToDate: moment().add(1, 'days').format(armDateFormat),
    modalMessage: "",
    navigationConfirmed: false,
    record: "",
    saveOnNavigate: false,
    modalProps: {
        okText: "OK",
        cancelText: "Cancel",
        alertMode: false,
    },
};

interface IComponentProps {
};

type IOwnProps = IComponentProps & RouteComponentProps<{}, {}, any>;
type IReportOptionsProps = IMergeCrudComponentProps<IReportOptionsUIState, IReportOptionsActionProps, IOwnProps>;

interface ISelectionOption {
    label: string;
    value: string;
}

export class FilterRegenerate extends React.PureComponent<IReportOptionsProps, IComponentState> {
    // define only once, instead of on every render
    private instructions = (
        <div style={{ textAlign: "center" }}>
            Select a date range and click Load to see the files generated during that time frame.
            <br />
            <br />
            <i>
                The initial list is the list of files generated in the last
                {(this.props.location.state as any).parameter.ReportRequests.ReportRequest[0]['@ID'] === '2501' ||
                  (this.props.location.state as any).parameter.ReportRequests.ReportRequest[0]['@ID'] === '2502' ?
                  ' 30 ' :
                  ' 7 '
                }
                days.
            </i>
        </div>
    )

    public componentDidMount() {
        const reportRequest = (this.props.location.state as any).parameter.ReportRequests.ReportRequest[0]['@ID']
        this.getFilterRegenerate(reportRequest)
        this.getCalendarDate(reportRequest)
    }

    public getCalendarDate = (reportRequest: string) => {
        if (reportRequest === '2501' || reportRequest === '2502') {
            this.setState({
                fromDate: moment().subtract(1, 'month').format(armDateFormat)
            })
        }
    }

    public getFilterRegenerate = (reportRequest: string, fromDateParam: string = this.state.minToDate, thruDateParam: string = this.state.thruDate) => {
        const splitToDate = thruDateParam.split('/')
        const concatToDate = splitToDate[0] + splitToDate[1] + splitToDate[2]
        const splitFromDate = fromDateParam.split('/')
        const concatFromDate = splitFromDate[0] + splitFromDate[1] + splitFromDate[2]
        let reportReqApi = ''

        switch (reportRequest) {
            case '1860R':
                reportReqApi = 'GetCENFFileList'
                break;
            case '1864R':
                reportReqApi = 'GetCreditDetailCENFFileList'
                break;
            case '1865R':
                reportReqApi = 'GetBackFeedFileList'
                break;
            case '2501':
                reportReqApi = 'GetRegulatoryReportingFileList'
                break;
            case '2502':
                reportReqApi = 'GetRegulatoryReportingFileList'
                break;
            default:
                return;
        }

        fetch(URLs.api + `/api/data/${reportReqApi}/${concatFromDate}/${concatToDate}`)
            .then(async (response) => {
                let results: any = await response.json();
                let jsonData: string = JSON.stringify(results);
                let data = JSON.parse(jsonData);
                if (data?.Files) {
                    let dataFileOptions = data.Files.map((item: any) => {
                        return {
                            label: item.Filename,
                            value: reportRequest === '2501' ? item.JobId : item.Record
                        }
                    });
                    this.setState({
                        filterRegenerateData: data,
                        fileOptions: dataFileOptions,
                        record: data.Files.length > 0 ? (reportRequest == '2501' ? data.Files[0].JobId : data.Files[0].Record) : ""
                    });
                }
            })
    }

    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public async executeRRTRegenerateProcess() {
         //data/rrtRegenerate/{historyId}
        if (!this.state.record  || this.state.record.length < 1) return;
        await fetch(URLs.api + `/api/data/rrtRegenerate/${this.state.record}`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                }
            }).then(response => {
                if (response.status === 200) {
                    //this.goToHomePage();
                    return;
                } else {
                    throw new Error('executeRRTRegenerateProcess: Failed to create the report job ' + response.status + ' ' + response.statusText);
                }
            })
            .catch(error => {
                console.error('executeRRTRegenerateProcess: ' + error);
            })

    }

    public async executeClaimReportProcess() {
        if (!this.state.record || this.state.record.length < 1) return;
        var xmlData: APIXMLCB_XmlCallBackModel = {};

        var jsonData: any = {};
        jsonData.Report = {}; // execNode
        jsonData.Report['@Action'] = 'GetReport';

        var parameterValue = this.props.history.location.state?.parameter ??
        {
            'ReportRequests': {
                '@Type': '11',
                'ReportRequest': []
            }
        };
        if (parameterValue.ReportRequests.ReportRequest[0]['@ID'] && parameterValue.ReportRequests.ReportRequest[0]['@ID'] !== '2502') {
            parameterValue.ReportRequests.ReportRequest[0]['@RegenRowPtr'] = this.state.record;
            parameterValue.ReportRequests.ReportRequest[0]['@ID'] = parameterValue.ReportRequests.ReportRequest[0]['@ID'].substring(0, 4);
            jsonData.Report = parameterValue

            xmlData.Action = 'GetReport';
            xmlData.FilterJsonData = JSON.stringify(jsonData);

            await fetch(URLs.api + '/api/data/PostMultiClaim',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `${getRawToken()}`
                    },
                    body: JSON.stringify(xmlData),
                }).then(response => {
                    if (response.status === 200) {
                        //this.goToHomePage();
                        return;
                    } else {
                        throw new Error('ClaimFilter: Failed to create the report job ' + response.status + ' ' + response.statusText);
                    }
                })
                .catch(error => {
                    console.error('ClaimFilter: ' + error);
                })
        }
    }

    public onDialogOk = () => {
        if ((this.props.location.state as any).parameter.ReportRequests.ReportRequest[0]['@ID'] === '2502') {
            this.executeRRTRegenerateProcess()
        } else {
            this.executeClaimReportProcess()
        }
        this.props.history.push("/LandingPage");
    }

    public onDialogCancel = (e: React.ChangeEvent<HTMLButtonElement>) => {
        // This will trigger the prompt to open the modal
        this.props.history.push('/LandingPage');
    }

    public onModalOk = () => {
        this.setState({ navigationConfirmed: true, saveOnNavigate: false }, () => {
            this.props.history.push(this.state.navDestination?.pathname || '/LandingPage');
        });
    }

    public onModalClose = () => {
        this.setState({ navigationConfirmed: false, saveOnNavigate: false }, () => {
            this.props.action.confirm.closeConfirm();
        });
    }

    public onFormatChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editDateFormat({
            masterCrud: this.props.dataStore.crud.data,
            uiData: e.target.value,
        });
    }

    public onDelimiterChange(e: ISelectionOption) {
        this.props.action.ui.editDelimiter({
            masterCrud: this.props.dataStore.crud.data,
            uiData: e.value,
        });
    }
    public onQualifierChange(e: ISelectionOption) {
        this.props.action.ui.editQualifier({
            masterCrud: this.props.dataStore.crud.data,
            uiData: e.value,
        });
    }

    public loadFiles() {
        const { fromDate, thruDate, today } = this.state
        let momentFromDate = moment(fromDate)
        let momentThruDate = moment(thruDate)
        const momentToday = moment(today)
        const limitDate = momentThruDate.subtract(90, 'days').format(armDateFormat)
        const reportRequest = (this.props.location.state as any).parameter.ReportRequests.ReportRequest[0]['@ID']

        this.setState({
            fileOptions: {
                label: "",
                value: ""
            }
        })

        if (momentFromDate.isAfter(limitDate) && momentFromDate.isBefore(momentToday) || fromDate === thruDate) {
            this.getFilterRegenerate(reportRequest, fromDate, thruDate)
        } else {
            this.handleModal()
        }
    }

    public handleModal = () => {
        this.setState({
            isModalOpen: true,
            modalMessage: 'Please select a valid, 90-day, date range to load.'
        })
    }

    public displayModal = () => {
        this.setState({
            isModalOpen: true,
            modalMessage: 'Please select a valid, 90-day, date range to load.'
        })

        if (this.state.modalMessage === 'undefined') {
            this.setState({
                isModalOpen: false
            })
        }
    }

    isBusy = () => {
        return (this.props.dataStore.crud.dataStatus === 'REQUEST');
    }

    private okCancelButtons = <OKCancelButtons onClickOK={this.onDialogOk} onClickCancel={this.onDialogCancel} />;

    constructor(props: IReportOptionsProps) {
        super(props);
        this.state = DEFAULT_STATE;
        //bindAll(this, ['onFormatChange', 'onDelimiterChange', 'onQualifierChange', 'showRouteChangeConfirm']);
    }

    public render() {
        return (<DialogWrapper title='Report Regenerate'
            instruction={this.instructions}
            helpUrl='/Support/Help/HELP_Reports_Regenerate.htm'
            buttons={this.okCancelButtons}
            isBusy={this.isBusy()}
        >
            <ModalConfirmation
                isOpen={this.state.isModalOpen}
                alertMode={true}
                onModalToggle={() => {
                    this.setState({
                        isModalOpen: false
                    })
                }}
                formattedMessage={`${this.state.modalMessage}`}
            />
            <ContentColumnWrapper style={{ width: 600 }}>
                <ContentWrapper>
                    <DateWrapper>
                        <ARMDatePicker
                            className="col2_sep"
                            domID="regenerate_importfrom"
                            label="Date:"
                            onInputChange={(event: string) => {
                                this.setState({
                                    fromDate: event
                                })
                            }}
                            allowTime={false}
                            minDate={this.state.minFromDate}
                            maxDate={this.state.maxFromDate}
                            initialDate={`${this.state.fromDate}`}
                            disableHowLong={true}
                        />
                    </DateWrapper>
                    <DateWrapper>
                        <ARMDatePicker
                            className="col3_sep"
                            domID="regenerate_importto"
                            label="Thru"
                            onInputChange={(event: string) => {
                                this.setState({
                                    thruDate: event
                                })
                            }}
                            allowTime={false}
                            minDate={this.state.fromDate}
                            maxDate={this.state.maxToDate}
                            initialDate={`${this.state.today}`}
                            disableHowLong={true}
                        />
                    </DateWrapper>
                </ContentWrapper>
                <DialogFieldset>
                    <DialogLegend>Previously Sent Files</DialogLegend>
                    <BoldSelectWrapper>
                        <SelectDropdown
                            domID="ro-field-delimiter"
                            className="dropdown"
                            label="Files:"
                            isClearable={false}
                            isSearchable={false}
                            size="small"
                            onChange={(event) => {
                                this.setState({
                                    record: event.value
                                })
                            }}
                            options={this.state.fileOptions}
                            initialValue={this.state.fileOptions.length > 0 ? this.state.fileOptions[0] : {label: "", value: ""}}
                        />
                        <ButtonContainer>
                            <Button
                                domID="remitfilter_sfloadbutton"
                                name="LOAD"
                                buttonType="standard"
                                size="small"
                                type="button"
                                onClick={(e: React.SyntheticEvent) => this.loadFiles()}
                            />
                        </ButtonContainer>
                    </BoldSelectWrapper>
                </DialogFieldset>
            </ContentColumnWrapper>
            <ModalConfirmation
                isOpen={this.props.dataStore.confirm.isOpen}
                onModalToggle={this.onModalClose}
                formattedMessage={leaveMessage}
                onConfirm={this.onModalOk}
            />
        </DialogWrapper>);
    }
}

const connectedHoc = connect<IReportOptionsUIState,
    IReportOptionsActionProps,
    IOwnProps,
    IReportOptionsProps,
    ApplicationState>(
        createCrudMapStateToProps('reportOptions'),
        createCrudMapDispatchToProps(actionCreators),
        mergeCrudComponentProps
    )(FilterRegenerate);

export default withRouter(connectedHoc);
