import * as React from 'react';
import { ARMGrid } from '../../common/UICLWrappers/ARMGrid';
import { GridConfig, IRowProps } from './GridConfig';
import { ISortIndicator, SortDataType } from '../../../scripts/util/SortHelper';
import { ISampleTreeviewData } from './ISampleTreeviewData';
import styled from 'styled-components';
import { ARMHeaderCell } from '../../common/UICLWrappers/ARMHeaderCell';
import plusSymbol from './icons/plus.gif';
import minusSymbol from './icons/minus.gif';
import checkMark from './icons/cvs_checkout.gif';
import checkFileIn from './icons/cvs_checkin.gif';
import undoCheckFileOut from './icons/cvs_undo.gif';
import register from './icons/cvs_register.gif';
import downloadImage from './icons/cvs_download.gif';
import deleteImage from './icons/cvs_delete.gif';
import undelete from './icons/cvs_undelete.gif';
import i from './icons/i.gif';
import l_middle from './icons/l-middle.gif';
import l_end from './icons/l-end.gif';
import { isEmpty, isNull, uniqueId } from 'lodash';
import { FileDownloader } from "./FileDownloader";
import { URLs } from '@commonDevResources/constants';
import { getRawToken } from "@scripts/session/SecurityToken";
import { ModalConfirmation } from '../../common/ModalConfirmation';

const numberOfSortableHeaders = 3
let sortableHeaderIds: any[] = []
for (let i = 0; i < numberOfSortableHeaders; i++) {
    sortableHeaderIds = [...sortableHeaderIds, ""+ uniqueId()]
}

export const GridWrapper = styled.div`

    /* STANDARD OUTER GRID STYLE CONFIGURATION */

    div{
        display:inline;
    }
    background-color:white;
    width: 900px;
    height: 400px;
    border: black 1px solid;
    overflow-y: scroll;

    #sample-treeview-grid{

        /* STANDARD INNER GRID STYLE CONFIGURATION */

        thead{position:sticky; z-index:20; top:0px}

        background-color:white;
        .empty-row{
            background-color:white !important;
        }
        overflow-y:scroll;
        overflow-x:scroll;

        table tbody tr > td{
            height:32px !important;
        }

        /* ROW HOVER CONFIGURATION */

        tr:hover{
            background-color:#edf9ff;
            cursor:pointer;
        }

        /* OTHER CONFIGURATION */

        .child-header {
            background-color: #0F0F59;
            pointer-events: none;
            th > div {
                white-space: nowrap;
                color: #FFF
                float: left;
                img {
                    float: left;
                }
            }
        }

        .child-row {
            cursor: default;
            img {
                float: left;
            }
        }

        .cell-style {
            height: 32px;
            display: flex;
            align-items: center;
            white-space: nowrap;
        }

        /* primary grid row headers */

        .FileName {
            width: 355px;
        }

        .Modified{
            width: 245px;
        }

        .Size {
            width: 125px;
        }

        .Owner {
            width: 125px;
        }

        .primary-header-count {
            width: 100px;
        }

        .primary-header-checkedOut {
            width: 100px;
        }

        .child-row-amount {
  
        }

        .CheckedOut {
            width: 114px;
        }

        /* primary grid rows */

        .overflow-filename-style {
            overflow: hidden;
            inline-size: 288px;
            margin-right: -10px;
        }

        .primary-row-file-name {
            width: 155px;
        }
        
        .overflow-import-date-style {
            overflow: hidden;
            inline-size: 130px;
            margin-right: -10px;
        }

        .primary-row-import-date {
            width: 345px;
        }
        
        .overflow-payment-source-style {
            overflow: hidden;
   
            margin-right: -10px;
        }

        .primary-row-payment-source {
            width: 88px;
        }
        
        .overflow-count-style {
            overflow: hidden;
            inline-size: 122px;
            margin-right: -10px;
            margin-left: 50px;
        }

        .primary-row-count {
            width: 100px;
           inline-size: 90px;
        }
        
        .overflow-checkedOut-style {
            overflow: hidden;
            inline-size: 90px;
            margin-right: -10px;
        }

        .primary-row-checkedOut {
            width: 100px;
            inline-size:62px;
        }

        .primary-row-remove {
            width: 114px;
        }

        /* child grid row headers */

        .child-header-payment-date {
            white-space: nowrap;
            width: 170px;
        }

        .child-header-payment-no {
            width: 152px;
        }

        .child-header-provider-no {
            width: 450px;
        }

        .child-header-tax-id {
            width: 90px;
        }

        .child-header-secondary {
            width: 115px;
        }

        .child-header-matched {
            width: 100px;
        }

        .child-header-unmatched {
            width: 115px;
        }

        .child-header-checkedOut {
            width: 100px;
        }

        .child-header-remove {
            width: 50px;
            white-space: nowrap;
        }

        /* child grid rows */
        .child-row-payment-date{
            white-space: nowrap;
            width: 170px;
        }
        
        .overflow-payment-no-style {
            overflow: hidden;
            inline-size: 138px;
            margin-right: -10px;
        }

        .child-row-payment-no {
            width: 120px;
        }
        
        .overflow-provider-no-style {
            overflow: hidden;
           /* inline-size: 100px;*/
            margin-right: -10px;
        }

        .child-row-provider-no {
            width: 464px;
        }
        
        .overflow-tax-id-style {
            overflow: hidden;
            inline-size: 70px;
            margin-right: -10px;
        }

        .child-row-tax-id {
            width: 90px;
        }
        
        .overflow-secondary-style {
            overflow: hidden;
            inline-size: 100px;
            margin-right: -10px;
        }

        .child-row-secondary {
            width: 115px;
        }
        
        .overflow-matched-style {
            overflow: hidden;
            inline-size: 90px;
            margin-right: -10px;
        }

        .child-row-matched {
            width: 100px;
        }
        
        .overflow-unmatched-style {
            overflow: hidden;
            inline-size: 100px;
            margin-right: -10px;
        }

        .child-row-unmatched {
            width: 115px;
        }
        
        .overflow-child-checkedOut-style {
            overflow: hidden;
            inline-size: 90px;
            margin-right: -10px;
        }

        .child-row-checkedOut {
            width: 100px;
        }

        .child-row-remove{
            width: 50px;
            white-space: nowrap;
        }
    }
`;

interface searchResultsObj {
    "tOwner": string;
    "parentData": any[];
};

interface ResultsGridState {
    treeviewData: ISampleTreeviewData | undefined;
    expandClicked: boolean;
    expandedParentIds: string[];
    inputValue: string;
    sortIndicator: ISortIndicator;
    topOwner: string;
}

interface paginatedSearchResult {
    childData: any[];
    parentData: any[];
}


interface IChildRecord {
    RevisionNumber: string,
    Note: string,
    RevisionDate: string,
}

interface IComponentState {
    selectedIndex: number;
    cancelLeave: boolean;
    isBusy: boolean,
    topOwner:string,
    reasonInputError: boolean;
    reasonInputText: string;
    isHoveringOverBack: boolean;
    isHoveringOverFoward: boolean;
    searchResults: searchResultsObj;
    paginatedSearchResults: paginatedSearchResult[];
    pageIndex: number;
    dateUUID: string;
    resultsUUID: string;
    treeviewData: ISampleTreeviewData | undefined;
    expandClicked: boolean;
    expandedParentIds: string[];
    inputValue: string;
    sortIndicator: ISortIndicator;
    validationModalMessage: string;
    isValidationModalOpen: boolean;
    fileToCheckin: string;
    recordToScrollTo: string;
    showDeleteQuestion: boolean;
    showNCSDeleteQuestion: boolean;
    showUnDeleteQuestion: boolean;
    showNCSUnDeleteQuestion: boolean;
    actionRecord?: IRowProps["record"];
};

export const DEFAULT_RESULTS_GRID_STATE: ResultsGridState = {
    treeviewData: undefined,
    expandClicked: false,
    topOwner: "",
    expandedParentIds: [],
    inputValue: '',
    sortIndicator: {
        sortColumn: 'Modified',
        sortDirection: 'up',
        sortDataType: SortDataType.Date
    },
}

export const DEFAULT_STATE: IComponentState = {
    selectedIndex: 0,
    cancelLeave: false,
    isBusy: false,
    topOwner: "",
    reasonInputError: false,
    reasonInputText: "",
    isHoveringOverBack: false,
    isHoveringOverFoward: false,
    validationModalMessage: "",
    isValidationModalOpen: false,
    searchResults: {
        "tOwner": "",
        "parentData": []
    },
    paginatedSearchResults: [{
        childData: [],
        parentData: [],
    }],
    pageIndex: 0,
    dateUUID: uniqueId(),
    resultsUUID: "" + Date.now(),
    treeviewData: undefined,
    expandClicked: false,
    expandedParentIds: [],
    inputValue: '',
    sortIndicator: {
        sortColumn: 'Modified',
        sortDirection: 'up',
        sortDataType: SortDataType.Date
    },
    fileToCheckin: "",
    recordToScrollTo: "",
    showDeleteQuestion: false,
    showNCSDeleteQuestion: false,
    showUnDeleteQuestion: false,
    showNCSUnDeleteQuestion: false,
};

export class UseTabGrid extends React.Component<any, IComponentState> {
    cheackboxRefs: { [index: string]: any };
    sortableHeaderRefs: { [index: string]: any };
    rowRefs: { [index: string]: any };
    scrollPosition: any;

    constructor(props: any) {
        super(props);
        this.state = {
            ...DEFAULT_STATE,
            treeviewData: props.treeviewData
        };
        this.handleSorting = this.handleSorting.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.onParentRemoveClicked = this.onParentRemoveClicked.bind(this);
        this.cheackboxRefs = [];
        this.sortableHeaderRefs = [];
        this.rowRefs = [];
     
    }

    handleSorting(sortIndicator: ISortIndicator, refKey: string = "") {
        const {
            treeviewData
        } = this.props.resultsGridState;

        if (!treeviewData) return;

        let sortedTreeviewData = this.props.onSort(sortIndicator);

        this.props.setResultsGridState({
            treeviewData: sortedTreeviewData[this.props.resultsGridState.pageIndex],
            paginatedSearchResults: sortedTreeviewData,
            sortIndicator,
            expandedParentIds: [],
        }, () =>refKey !== "" && this.sortableHeaderRefs[refKey].focus());
    }

    onRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        const {
            expandedParentIds
        } = this.props.resultsGridState;

        let newExpandedParentIds = expandedParentIds;
        const clickedId = event.currentTarget.id;

        if (newExpandedParentIds.includes(clickedId)) {
            newExpandedParentIds = newExpandedParentIds.filter((x: any) => x != clickedId)
        } else {
            newExpandedParentIds.push(clickedId);
        }

        this.props.setResultsGridState({ expandedParentIds: newExpandedParentIds });
    }

    getCustomHeader() {
        const {
            sortIndicator,
        } = this.props.resultsGridState;

        const header = () => <thead><tr style={{ display: 'block' }}>
            {
                <>
                    <ARMHeaderCell
                        id={sortableHeaderIds[0]}
                        key={sortableHeaderIds[0]}
                        setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[0]] = input}
                        refKey={sortableHeaderIds[0]}
                        style={{ whiteSpace: "nowrap", width: "400px" }}
                        dataName="FileName"
                        text="Filename"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        hasCorrectedTabIndex={true}
                    />
                    <ARMHeaderCell
                        id={sortableHeaderIds[1]}
                        key={sortableHeaderIds[1]}
                        setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[1]] = input}
                        refKey={sortableHeaderIds[1]}
                        dataName="Modified"
                        text="Modified"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.Date}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        hasCorrectedTabIndex={true}
                    />
                    <ARMHeaderCell
                        id={sortableHeaderIds[2]}
                        key={sortableHeaderIds[2]}
                        setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[2]] = input}
                        refKey={sortableHeaderIds[2]}
                        style={{ whiteSpace: "nowrap", width: "400px" }}
                        dataName="Size"
                        text="Size"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.Number}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        hasCorrectedTabIndex={true}
                    />
                    <ARMHeaderCell
                        id={sortableHeaderIds[3]}
                        key={sortableHeaderIds[3]}
                        setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[3]] = input}
                        refKey={sortableHeaderIds[3]}
                        style={{ whiteSpace: "nowrap", width: "400px" }}
                        dataName="Owner"
                        text="Owner"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        hasCorrectedTabIndex={true}
                    />
                    <ARMHeaderCell
                        id={sortableHeaderIds[4]}
                        key={sortableHeaderIds[4]}
                        setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[4]] = input}
                        refKey={sortableHeaderIds[4]}
                        style={{ whiteSpace: "nowrap", width: "400px" }}
                        cellType="text"
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        hasCorrectedTabIndex={true}
                        dataName="CheckedOut"
                        text="Action"
                        sortable={false}
                    />
                </>
            }
        </tr></thead>
        return header;
    }

    onParentRemoveClicked(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        e.preventDefault();
    }

    user = (): string => {

        let sessionUser = sessionStorage?.getItem("Username");
        let userName: string = isNull(sessionUser) ? "" : sessionUser;
        if (userName?.toUpperCase().startsWith('NDC\\')) {
            userName = userName.substring(4, userName.length);
        }

        return userName;
    }

    public doApiCall = async (action: any, filename: any) => {

        let API_CALL = '';
        const CHECKOUT_FILES_API_CALL = 'api/data/BridgeFileCheckout';
        const API_ACTION = 'api/data/BridgeFileAction';
        const UNDO_CHECKOUT_COMMAND = 'UndoCheckOut';
        const REGISTER_COMMAND = 'Register';
        const DELETE_COMMAND = 'Delete';
        const UNDELETE_COMMAND = "Undelete";
        let body: any;

        const {
            showNCSDeleteQuestion,
            showNCSUnDeleteQuestion,
        } = this.state;

        switch (action) {
            case 'CHECKOUT':
                API_CALL = CHECKOUT_FILES_API_CALL + '?directive=' + this.props.directive;
                body = JSON.stringify(filename);
                break;
            case 'UNDOCHECKOUT':
                API_CALL = API_ACTION;
                body = JSON.stringify({ BridgeFileName: filename, Directive: this.props.directive, Command: UNDO_CHECKOUT_COMMAND });
                break;
            case 'REGISTER':
                API_CALL = API_ACTION;
                body = JSON.stringify({ BridgeFileName: filename, Directive: this.props.directive, Command: REGISTER_COMMAND });
                break;
            case 'DELETE':
            case 'UNDOCHECKOUT':
                API_CALL = API_ACTION;
                if (showNCSDeleteQuestion)  {
                    body = JSON.stringify({ BridgeFileName: filename, Directive: this.props.directive, NCSAction: DELETE_COMMAND, Command: DELETE_COMMAND });
                } else {
                    body = JSON.stringify({ BridgeFileName: filename, Directive: this.props.directive, Command: DELETE_COMMAND });
                }
                break;
            case 'UNDELETE':
                API_CALL = API_ACTION;
                if (showNCSUnDeleteQuestion) {
                    body = JSON.stringify({ BridgeFileName: filename, Directive: this.props.directive, NCSAction: UNDELETE_COMMAND, Note: "", DeletedBy: this.user(), Command: UNDELETE_COMMAND });
                } else {
                    body = JSON.stringify({ BridgeFileName: filename, Directive: this.props.directive, Note: "", DeletedBy: this.user(), Command: UNDELETE_COMMAND });
                }
                break;
            default:
                console.log(`Sorry, no action found for ${action}.`);
        }

        this.props.setBusyLoader(true);
      

        await fetch(URLs.api + '/' + API_CALL,
            {
                method: 'POST',
                      headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                          'Authorization': `${getRawToken()}`
                      },
                body: body

            }).then(response => {
                if (response.status == 500) {
                    this.setState({
                        isValidationModalOpen: true,
                        validationModalMessage: "API has server error.",
                    })

                    this.props.setBusyLoader(false);

                } else
                    return response.json();
            }).then((data) => {

                if (data == "") {

                    //if successful refetch data;

                    this.setState({ isBusy: false });

                    this.props.onGetBridgeFiles();

                } else if (data != "") {

                    this.setState({
                        isValidationModalOpen: true,
                        validationModalMessage: data+"",
                    });

                    this.props.setBusyLoader(false);

                }
                else {
                    this.setState({
                        isValidationModalOpen: true,
                        validationModalMessage: "Something went wrong.",
                    });
                    this.props.setBusyLoader(false);
                };

            }).catch(error => {
                console.log('error:', error + '');
            });
    }

    showDeleteAction = (record: IRowProps['record']) => {
        this.setState({ showDeleteQuestion: true, actionRecord: record });
    }

    handleDeleteActionConfirmation = (confirm: boolean) => {
        const {
            actionRecord,
        } = this.state;

        this.setState({ showDeleteQuestion: confirm });

        if (confirm) {
            if (this.props.ncsIsEnabled) {
                this.showNCSDeleteAction(confirm && this.props.ncsIsEnabled);
            } else {
                this.props.onHandleScroll(actionRecord?.FileSequence.toString())
                this.doApiCall("DELETE", actionRecord?.FileName);
            }
        }
    }

    showNCSDeleteAction = (confirm: boolean) => {
        this.setState({ showNCSDeleteQuestion: confirm });
    }

    handleNCSDeleteActionConfirmation = (confirm: boolean) => {
        const {
            actionRecord,
        } = this.state;

        this.setState({ showNCSDeleteQuestion: confirm });

        if (confirm) {
            this.props.onHandleScroll(actionRecord?.FileSequence.toString())
            this.doApiCall("DELETE", actionRecord?.FileName);
        } else {
            this.setState({ showDeleteQuestion: false, showNCSDeleteQuestion: false }, () => {
                this.props.onHandleScroll(actionRecord?.FileSequence.toString())
                this.doApiCall("DELETE", actionRecord?.FileName);
            });
        }
    }

    showUnDeleteAction = (record: IRowProps['record']) => {
        this.setState({ showUnDeleteQuestion: true, actionRecord: record});
    }

    handleUnDeleteActionConfirmation = (confirm: boolean) => {
        const {
            actionRecord,
        } = this.state;

        this.setState({ showUnDeleteQuestion: confirm });

        if (confirm) {
            if (this.props.ncsIsEnabled) {
                this.showNCSUnDeleteAction(confirm && this.props.ncsIsEnabled);
            } else {
                this.props.onHandleScroll(actionRecord?.FileSequence.toString())
                this.doApiCall("UNDELETE", actionRecord?.FileName);
            }
        }
    }

    showNCSUnDeleteAction = (confirm: boolean) => {
        this.setState({ showNCSUnDeleteQuestion: confirm });
    }

    handleNCSUnDeleteActionConfirmation = (confirm: boolean) => {
        const {
            actionRecord,
        } = this.state;

        this.setState({ showNCSUnDeleteQuestion: confirm });

        if (confirm) {
            this.props.onHandleScroll(actionRecord?.FileSequence.toString())
            this.doApiCall("UNDELETE", actionRecord?.FileName);
        } else {
            this.setState({ showUnDeleteQuestion: false, showNCSUnDeleteQuestion: false }, () => {
                this.props.onHandleScroll(actionRecord?.FileSequence.toString())
                this.doApiCall("UNDELETE", actionRecord?.FileName);
            });
        }
    }

    getCustomRow() {


        const row = ({ record }: IRowProps) => {
            const {
                expandedParentIds,
            } = this.props.resultsGridState;

            const uID = record.Size + "" + record.Owner + "" + record.FileName + "" + record.Modified + ""
            
            return (
             
                <>
                    <tr id={record.FileSequence.toString()} ref={el => this.rowRefs[record.FileSequence.toString()] = el} key={record.FileSequence} role="button" onClick={(x) => { this.onRowClick(x); this.props.onClearScrollTo() }} style={{ display: 'block' }}>
                        {
                            
                            <>
                                <td className="primary-row-file-name">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style overflow-filename-style">
                                          {/*  the way to check for unregistered files is to see if there are any child records per ASP code---records.Revisions.length*/}

                                            {expandedParentIds.includes(record.FileSequence.toString()) && (record.Revisions.length > 0) &&
                                                <img src={minusSymbol} />
                                            }
                                            {!expandedParentIds.includes(record.FileSequence.toString()) && (record.Revisions.length > 0) &&
                                                <img src={plusSymbol} />
                                            }
                                            <a style={{ cursor: "pointer",  textDecoration:'underline'  }}
                                                href={void (0)}
                                                onClick={async (x) => {
                                                     x.stopPropagation();
                                                    await new FileDownloader().downloadFile(`${this.props.directive}/${record.FileName}`);

                                                }} > {record.FileName} </a>
                                    
                                        </div>
                                    </div>
                                </td>
                                <td className="primary-row-import-date">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style overflow-import-date-style">
                                            {record.Modified}
                                        </div>
                                    </div>
                                </td>
                                <td className="primary-row-payment-source">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style overflow-payment-source-style">
                                            {record.Size ? `${record.Size} KB` : ' '}
                                        </div>
                                    </div>
                                </td>
                                <td className="primary-row-count">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style overflow-count-style">
                                            {record.Owner}
                                        </div>
                                    </div>
                                </td>
                                <td className="primary-row-checkedOut">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style overflow-checkedOut-style">
                                            {(record.Revisions.length > 0) ? (
                                                (!isEmpty(record.Owner)) ? (
                                                    (this.props.lockBy?.toUpperCase() == this.user()?.toUpperCase() || (isEmpty(this.props.lockBy) && (record.Owner?.toUpperCase() == this.user().toUpperCase()))) ? (
                                                        <React.Fragment>
                                                            < img alt="Check In" title="Check In"
                                                                onClick={(x) => {
                                                                    // prevent row click event
                                                                    x.stopPropagation();
                                                                    this.props.onClearScrollTo();
                                                                    this.props.onGetFilename(record.FileName);
                                                                }}
                                                                src={checkFileIn} /> &nbsp;
                                                            < img alt="Undo Check Out" title="Undo Check Out"
                                                                onClick={(x) => {
                                                                    // prevent row click event
                                                                    x.stopPropagation();
                                                                    this.doApiCall("UNDOCHECKOUT", record.FileName);
                                                                }}
                                                                src={undoCheckFileOut} />
                                                        </React.Fragment>
                                                    ) : (<React.Fragment> </React.Fragment>)
                                                ) : (
                                                    (isEmpty(record.DeletedBy)) ? (
                                                            (this.props.lockBy?.toUpperCase() == this.user().toUpperCase() || isEmpty(this.props.lockBy?.toUpperCase()) ) ? (
                                                                <React.Fragment>
                                                                < img alt="Check Out" title="Check Out"
                                                                    onClick={async (x) => {
                                                                        x.stopPropagation();
                                                                        await new FileDownloader().downloadFile(`${this.props.directive}/${record.FileName}`);
                                                                        this.props.onHandleScroll(record.FileSequence.toString())
                                                                        this.doApiCall("CHECKOUT", record.FileName);
                                                                    }}
                                                                    src={checkMark} />&nbsp;
                                                                < img alt="Delete" title="Delete"
                                                                            onClick={(x) => {
                                                                                x.stopPropagation();
                                                                                this.showDeleteAction(record);
                                                                    }}
                                                                    src={deleteImage} />
                                                                </React.Fragment>
                                                            ) : (<React.Fragment></React.Fragment>)
                                                        ) : (
                                                                (this.props.lockBy?.toUpperCase() == this.user().toUpperCase() || isEmpty(this.props.lockBy?.toUpperCase())) ? (
                                                                    <React.Fragment>
                                                            < img alt = "Undelete" title = "Undelete"
                                                                onClick = { (x) => {
                                                                    // prevent row click event
                                                                    x.stopPropagation();
                                                                    this.showUnDeleteAction(record);
                                                           
                                                                }}
                                                                src={undelete} />
                                                                    </React.Fragment>
                                                                ) : (<React.Fragment></React.Fragment>)
                                                    )
                                                )
                                            ) : (
                                                    (this.props.lockBy?.toUpperCase() == this.user().toUpperCase() || isEmpty(this.props.lockBy?.toUpperCase())) ? (
                                                    <React.Fragment>
                                                    < img alt="Register" title="Register"
                                                        onClick={(x) => {
                                                            // prevent row click event
                                                            x.stopPropagation();
                                                            this.props.onHandleScroll(record.FileSequence.toString())
                                                            this.doApiCall("REGISTER", record.FileName);
                                                        }}
                                                        src={register} />&nbsp;
                                                        < img alt="Delete" title="Delete"
                                                            onClick={(x) => {
                                                                this.showDeleteAction(record);
                                                                x.stopPropagation();
                                                        }}
                                                        src={deleteImage} />
                                                    </React.Fragment>
                                                ) : (<React.Fragment></React.Fragment>)
                                            )}
                                        </div>
                                    </div>
                                </td>
                            </>
                        }
                    </tr>
                    {expandedParentIds.includes(record.FileSequence.toString()) &&
                        <>
                            <tr className="child-header" style={{ display: 'block'}}>
                                <th className="child-header-payment-date">
                                <React.Fragment>
                                        <img src={i} />
                                    <button tabIndex={-1}>
                                        <td style={{ color: '#FFF' }}>
                                                Version
                                            </td>
                                        </button>
                                </React.Fragment>
                            </th>
                            <th className="child-header-payment-no" >
                                <React.Fragment>
                                    <button tabIndex={-1}>
                                        <td style={{ color: '#FFF' }}>
                                           Checked In
                                            </td>
                                    </button>
                                </React.Fragment>
                            </th>
                            <th className="child-header-provider-no"  >
                                <React.Fragment>
                                    <button tabIndex={-1}>
                                        <td style={{ color: '#FFF' }}>
                                           Note 
                                            </td>
                                    </button>
                                </React.Fragment>
                            </th>
                            <th className="child-header-checkedOut" >
                                <React.Fragment>
                                    <button tabIndex={-1}>
                                        <td style={{ color: '#FFF' }}>
                                            Action
                                            </td>
                                    </button>
                                </React.Fragment>
                            </th>
                        </tr>
                        {
                            record.Revisions && record.Revisions.map((childRecord: IChildRecord, i) => {
                                return (
                                    <tr className="child-row" style={{ display: 'block' }}>
                                        <td className="child-row-payment-date">
                                            <div className="cell-component-wrapper cell-style">
                                                <div className="no-margin cell-style overflow-child-amount-style">
                                                    {i < (record.Revisions.length-1) &&
                                                        < img src={l_end} />
                                                    }
                                                    {i == (record.Revisions.length - 1) &&
                                                        < img src={l_middle} />
                                                    }
                                                    {childRecord.RevisionNumber}                                            
                                                </div>
                                            </div>
                                        </td>
                                        <td className="child-row-payment-no">
                                            <div className="cell-component-wrapper cell-style">
                                                <div className="no-margin cell-style overflow-payment-no-style">
                                                    {childRecord.RevisionDate}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="child-row-provider-no">
                                            <div className="cell-component-wrapper cell-style">
                                                <div className="no-margin cell-style overflow-provider-no-style">
                                                    {childRecord.Note}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="child-row-amount">
                                            <div className="cell-component-wrapper cell-style">
                                                <div className="no-margin cell-style overflow-child-amount-style">
                                                    {(record.Status == "Up-to-date") &&
                                                        <> < img role="button"
                                                        src={downloadImage}
                                                        onClick={async () => {
                                                            await new FileDownloader().downloadFile(`${this.props.directive}/${childRecord.RevisionNumber}=${record.FileName}`);
                                                        }}
                                                        alt="Download"
                                                        title="Download" /> </>
                                                    }
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        </> 
                    }
                </>
            );
        }

        return row;
    }

    render() {

        const {
            treeviewData
        } = this.props.resultsGridState;

        const {
            showDeleteQuestion,
            showNCSDeleteQuestion,
            showUnDeleteQuestion,
            showNCSUnDeleteQuestion,
            actionRecord,
        } = this.state;

        var popupStyles: React.CSSProperties = { alignContent: 'center', justifyContent: 'center', textAlign: 'center', color: 'red' };

        var confirmMessageStyle: React.CSSProperties = { alignContent: 'center', justifyContent: 'center', textAlign: 'center', color: 'black' };

        const data = treeviewData?.parentData
        const columns = GridConfig.getColumnsConfig();
        const row = this.getCustomRow();
        const header = this.getCustomHeader();

        return (
            <>
                <GridWrapper>
                { treeviewData?.parentData &&
                    <ARMGrid
                        domID='sample-treeview-grid'
                        maxHeight={'350px'}
                        isFixedHeader={true}
                        columns={columns}
                        headerComponent={header}
                        rowComponent={row}
                        records={data}
                        emptyGridMessage=' '
                        selectionKey={'FileName'} />
                }
                </GridWrapper>
                {showDeleteQuestion && actionRecord?.FileName && (
                   <ModalConfirmation
                        isOpen={true}
                        alertMode={false}
                        okText='Yes'
                        cancelText='No'
                        showDefaultClose={false}
                        formattedMessage= { (
                            <div style={popupStyles}>
                                <p style={confirmMessageStyle}>Are you sure you want to delete {actionRecord?.FileName}?</p>
                            </div>)
                        }
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.showDeleteAction(actionRecord)}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.handleDeleteActionConfirmation(true)}
                        onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.handleDeleteActionConfirmation(false)}
                    />)
                }
                {showNCSDeleteQuestion && actionRecord?.FileName && this.props.ncsIsEnabled && (
                    <ModalConfirmation
                        isOpen={true}
                        alertMode={false}
                        okText='Yes'
                        cancelText='No'
                        showDefaultClose={false}
                        formattedMessage={(
                            <div style={popupStyles}>
                                <p>Warning: This action cannot be undone.</p>
                                <p>Before selecting  Yes:</p>
                                <p>Comfirm that files on other sites are not currently locked by another user. </p>
                                <p>Confirm that this file is not currently checked out on another site.</p>
                                <br />
                                <p>Confirm that this file is appropriate to share across sites.</p>
                                <p>Only select Yes if none of the enterprise sites use the file.</p>
                                <p>Do you want to remove any file with the same name from all enterprise sites?</p>
                            </div>)
                        }
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.showNCSDeleteAction(showNCSDeleteQuestion)}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.handleNCSDeleteActionConfirmation(true)}
                        onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.handleNCSDeleteActionConfirmation(false)}
                    />)
                }
                {showUnDeleteQuestion && actionRecord?.FileName && (
                    <ModalConfirmation
                        isOpen={true}
                        alertMode={false}
                        okText='Yes'
                        cancelText='No'
                        showDefaultClose={false}
                        formattedMessage={ (
                            <div style={popupStyles}>
                                <p style={confirmMessageStyle}>Are you sure you want to restore the file {actionRecord?.FileName}?</p>
                            </div>)}
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.showUnDeleteAction(actionRecord)}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.handleUnDeleteActionConfirmation(true)}
                        onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.handleUnDeleteActionConfirmation(false)}
                    />)
                }
                {showNCSUnDeleteQuestion && actionRecord?.FileName && this.props.ncsIsEnabled && (
                    <ModalConfirmation
                        isOpen={true}
                        alertMode={false}
                        okText='Yes'
                        cancelText='No'
                        showDefaultClose={false}
                        formattedMessage={(
                            <div style={popupStyles}>
                                <p>Warning: This action cannot be undone.</p>
                                <p>Before selecting  Yes:</p>
                                <p>Comfirm that files on other sites are not currently locked by another user. </p>
                                <p>Confirm that this file is not currently checked out on another site.</p>
                                <br />
                                <p>Confirm that this file is appropriate to share across sites.</p>
                                <p>Only select Yes if none of the enterprise sites use the file.</p>
                                <p style={confirmMessageStyle}>Do you want to restore the deleted file for all enterprise sites?</p>
                            </div>)
                        }
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.showNCSUnDeleteAction(showNCSUnDeleteQuestion)}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.handleNCSUnDeleteActionConfirmation(true)}
                        onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.handleNCSUnDeleteActionConfirmation(false)}
                    />)
                }
            </>
        )
    }
}
