import * as React from 'react';
import { bindAll, intersectionWith, map, noop, sortBy } from 'lodash';
import styled from 'styled-components';
import { SelectComponent } from '@common/SelectComponent';
import { ContentRowFloatLeft } from '@common/DialogWrapper';

export interface IUserListProps {
    users: APICF_SimpleListUser[];
    selectedUserIds: string[];
    onUserSelectionChange: Function;
    userActions: APICF_SimpleList[];
    selectedUserActions: string[];
    onUserActionChange: Function;
    hideActions:Boolean;
    singleSelect: Boolean;
}

export interface IUserListState {

}

export const defaultUserActions = [
    {'ID': '5', 'Name': 'Create'},
    {'ID': '3', 'Name': 'Delete'},
    {'ID': '2', 'Name': 'Modify'},
    {'ID': '4', 'Name': 'Undelete'},
    {'ID': '1', 'Name': 'View'},
];

const UserListRow = styled(ContentRowFloatLeft)`
    padding-top: 10px;
    column-gap: 12px;
    
    div: first-child {
        flex: 0;
    }
    .dropdown {
        label: {
            width: 200px;
        }
    }
`;

export default class UserList extends React.PureComponent<IUserListProps, IUserListState> {
    static defaultProps = {
        users: [],
        selectedUserIds: ['-1'],
        onUserSelectionChange: noop,
        userActions: defaultUserActions,
        onUserActionChange: noop,
        selectedUserActions: [],
        hideActions: false,
        singleSelect: false,
    };

    constructor(props: IUserListProps) {
        super(props);
        bindAll(this, ['renderUserSelect', 'renderUserActionSelect', 'onUserSelectChange', 'onActionSelect']);
    }

    private onUserSelectChange(e: React.ChangeEvent<HTMLSelectElement>) {
        // convert from HTMLCollection to array of values
        const selectedOptions = map(e.target.selectedOptions, 'value');
        const selectedUsers = intersectionWith(this.props.users,
            selectedOptions,
            (user, value) => user.ID === value);
        this.props.onUserSelectionChange(selectedUsers);
    }

    private onActionSelect(e: React.ChangeEvent<HTMLSelectElement>) {
        const selectedOptions = map(e.target.selectedOptions, 'value');
        const selectedActions = intersectionWith(selectedOptions, 
            this.props.userActions,
            (value, action) => {
            //console.log({ action, optValue: option });
            return action.ID === value;
        });
        this.props.onUserActionChange(selectedActions);
    }

    private renderUserSelect() {
        const options = { value: 'ID', text: 'Name' };
        const { selectedUserIds, users, singleSelect } = this.props;
        const alphaUsers = sortBy(users, 'Name');
        let selection = selectedUserIds;
        if (!selection.length) selection = ['-1'];
        const records = [
            {
                ID: '-1',
                Name: '- Select User -',
                LoginName: '',
            },
            ...alphaUsers
        ];
        return (<SelectComponent
            autoScrollToSelection={!singleSelect}
            className="dropdown"
            label="User:"
            multiple={(!singleSelect).toString()}
            onSelect={this.onUserSelectChange}
            optionFields={options}
            records={records}
            selectedMultiValue={selection}
            size={singleSelect? 1: 12}
            title="select-user"/>
        );
    }

    private renderUserActionSelect() {
        const options = { value: 'ID', text: 'Name' };
        const size = Math.min(this.props.userActions.length, 5);
        return (
            <SelectComponent 
                className="dropdown"
                label="Action:"
                multiple="true"
                onSelect={this.onActionSelect}
                optionFields={options}
                records={this.props.userActions}
                selectedMultiValue={this.props.selectedUserActions}
                size={size}
            />
        );
    }

    public render() {
        return (
            <UserListRow>
                {this.renderUserSelect()}
                {!this.props.hideActions && this.renderUserActionSelect()}
            </UserListRow>
        );
    }
};