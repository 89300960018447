import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';
import { IClaimFilterState, IClaimFilterActionProps, actionCreators} from '@store/ClaimFilter';
import { ClaimFilter, IClaimFilterComponentProps } from './ClaimFilter';

export interface ISpinOffFilterComponentState {};

export const DEFAULT_STATE: ISpinOffFilterComponentState = {};

type IOwnProps = IClaimFilterComponentProps & RouteComponentProps<{}>;

type IClaimFilterProps = IMergeApiComponentProps<IClaimFilterState, IClaimFilterActionProps, IOwnProps>; 

export class SpinOffFilter extends React.Component<IClaimFilterProps, ISpinOffFilterComponentState>
{

    static defaultProps: IClaimFilterComponentProps = {
        canEdit: false,
        canView: false,
        canCreate: false,
        ncsIsEnabled: false,
        process: "spinoff",
        execNode: "MultiClaim",
        execAction: "SpinOffClaim",
        execProcess:"SpinOff",
        parameter: "some dummy",
        apiType: "ClaimFilter",
        title: "Spin Off",
        executeJS: undefined,
        extraInstruction: "Click Continue to spin off the claims without viewing the claim list.",
        execAttribs: ""
    };

    constructor(props: IClaimFilterProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.props.history.push('/LandingPage');
        }
    }

    public componentWillUnmount() {
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public onOk = (baseThis: any, param1: any) : boolean => {
        return true;
    }

    public render() {
        var parameterValue = (this.props.history.location.state as any).parameter;
        let value = [];
        if (parameterValue !== undefined) {
            let delim = '\t';
            value = parameterValue.split(delim);
            console.log("Values", value);
        }


        return (
            <div>
                <ClaimFilter
                    {...this.props} parameter={parameterValue} execAttribs={value[1]} title={"Spin Off >> " + value[2] + " To " + value[3]}
                    executeJS ={this.onOk}
                />
            </div>

        );
    }
};

var connectedHoc = connect<IClaimFilterState, IClaimFilterActionProps, IOwnProps, IClaimFilterProps, ApplicationState>(
    createApiMapStateToProps('claimFilter'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(SpinOffFilter);

export default withRouter(connectedHoc);
