import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IClaimTotalsState, IClaimTotalsActions, IClaimTotalsActionProps, actionCreators } from '@store/ClaimTotals';
import styled, { createGlobalStyle } from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import _, { isUndefined } from 'lodash';
import { Button } from '@optum-uicl/ui-core/dist';
import { DialogButton, DialogWrapper, DialogButtonOK, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { ARMNarrowDropdown } from '../../common/UICLWrappers/ARMNarrowDropdown';
import { ARMLabel } from '../../Transactions/Logon/styles/ARMLoginForm';
import { URLs } from '@commonDevResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';
import { ICookies_Config } from '../../../store/ConfigData';
import { SelectDropdown } from '@optum-uicl/ui-core/dist';
import { SelectDropdownValue } from '@optum-uicl/ui-core/dist/Atoms/SelectDropdown/types';


const IMG = styled.img`
    margin-left: 5px;
    cursor: pointer;
    margin-right: -7px;
`;

const RefreshButtonWrapper = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 20px;
    padding-top: 20px;
    padding-left:20px;

    button{
        color:#0C55B8;

        &:hover{
        color:#133D97;
        }
        &:active{
        color:#00184D;
        }
    }
`;
const DropdownWrapper = styled.div` 
    .selectDropdown__container, .selectDropdown__control
        {
            width:400px;
        }
    
`;
const ContentRowWrappertest=styled.div`
    display: flex;
    margin-bottom: 5px;
    flex-direction: row;
`;

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    title: string
};

interface IComponentState {
    activeModal: JSX.Element | undefined;
    loader: boolean;
    clientList: IDropDownItem[];
}

export const DEFAULT_STATE: IComponentState = {
    activeModal: undefined,
    loader: false,
    clientList: [{ value: '', label: '', id: '' }],
};

interface IDropDownItem extends SelectDropdownValue {
    label: string,
    value: string,
    id: string
}

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IClaimTotalsProps = IMergeCrudComponentProps<IClaimTotalsState, IClaimTotalsActionProps, IOwnProps>;

export class ClaimTotals extends React.Component<IClaimTotalsProps, IComponentState> {

    protected dataForSwitchClients: any = [];
    protected dataForClaimTotals: any;

    static addScheduleItemCount: number = 0;
    protected reportRestrictionsData: any = [];
    public groupList: [] = [];
    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        title: "Claim Counts"
    };

    constructor(props: IClaimTotalsProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }
        else {
            this.loadClientsFromServer();
        }
    }

    loadClientsFromServer() {
        var clientUrl = URLs.api + '/api/data/securityinfo/securityInfoUserClientAndLinkedClients';
        var xhr = new XMLHttpRequest();
        this.setState({ loader: true })
        xhr.onreadystatechange = () => {
            if (xhr.readyState == xhr.DONE) {
                if (xhr.status == 200) {
                    var responseData = xhr.responseText;
                    let isClientUser = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "isClientUser")?.value !== "False";
                    let dataClientValues = JSON.parse(responseData);
                    let CID = dataClientValues.filter((itemData: any) => itemData.name === sessionStorage.getItem("Client"));
                    if (!isClientUser) {
                        dataClientValues = dataClientValues.filter((itemData: any) => itemData.isLinkedClient === false);
                    }
                    this.ClientList(dataClientValues);

                    if (CID !== undefined && CID !== null && CID.length > 0) {
                        this.props.action.ui.selectClient({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                value: CID[0].clientId,
                                id: CID[0].id
                            }
                        });
                    }
                    if (CID.length > 0) {
                        this.loadClaimTotals(CID[0].clientId, CID[0].id);
                    }
                    else {
                        this.loadClaimTotals(0, 0);
                    }
                    this.setState({ loader: false })
                } else {
                    console.warn(xhr.status + " response handling " + clientUrl);
                    this.setState({ loader: false })
                }
            }
        };
        xhr.open('get', clientUrl, false);
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send();
    }

    public async loadClaimTotals(cliendId: any, id: any): Promise<string> {
        var xmlCallbackModel: APIXMLCB_XmlCallBackModel = {};
        xmlCallbackModel.ClientID = cliendId;
        xmlCallbackModel.ClientAlias = id;
        let claimTotalModel: ClaimTotalsModel;
        let result: string = '';
        await fetch(URLs.api + '/api/GetClaimCounts',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlCallbackModel),
            }).then(async response => {
                if (response.status === 200) {
                    const data: any = await response.json();
                    claimTotalModel = data;
                    this.props.action.ui.updateDetail({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            ClaimTotalsModel: data
                        }
                    });
                } else {
                    throw new Error(response.statusText);
                }
            })
            .catch(error => {
                result = '';
                this.props.action.ui.updateDetail({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        ClaimTotalsModel: null
                    }
                });
            })
            .finally(() => {
                return result;
            });
        return result;
    }

    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public onOkClaimTotals(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push("/LandingPage");
    }

    public ClientList = (clients: any) => {
        let clientDetails: IDropDownItem[] = [{ value: '', label: '- Select Client -', id: '' }];
        if (clients !== undefined || clients !== null || clients?.length > 0) {
            clients?.map((cli: any) => {
                clientDetails.push({ value: cli.clientId, label: cli.name, id: cli.id });
            });
        }
        this.setState({ clientList: clientDetails });
    }

    public onSelectChangeClient(e: any) {
        if (e !== undefined) {
            if (e.value !== "") {
                let id = this.state.clientList.filter(f => f.value == e.value)

                this.loadClaimTotals(e.value, id[0].id);
                this.props.action.ui.selectClient({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: e.value
                    }
                });
            } else {
                this.props.action.ui.selectClient({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: ""
                    }
                });
            }
        }
    }

    public onRefresh(e: React.SyntheticEvent) {
        if (e !== undefined) {
            let filteredClient = this.state.clientList.filter(f => f.value == this.props.dataStore.ui.clientId);
            if (filteredClient !== undefined && filteredClient !== null && filteredClient.length > 0) {
                this.loadClaimTotals(this.props.dataStore.ui.clientId, filteredClient[0].id);
            }
        }
    }

    public render() {
        var buttons = <DialogButtonOK onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOkClaimTotals(e)} />;
        const bottomInstructions = <React.Fragment>To display a client's current claim counts, select the client from the list.</React.Fragment>;

        return (
            <React.Fragment>
                <DialogWrapper title="Claim Counts" instruction={bottomInstructions} width="650px" helpUrl='/Support/Help/HELP_Claims_ClaimCounts.htm' buttons={buttons} isBusy={this.state.loader}>
                    <ContentColumnWrapper>
                        <ContentRowWrapper style={{ marginLeft: "24px", width: "532px" }}>
                            {/* <ARMNarrowDropdown
                                title={"Client"}
                                label={"Client:"}
                                className="dropdown"
                                isLabel={true}
                                width="400px"
                                min-width="150px"
                                multiple="false"
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    this.onSelectChangeClient(e);
                                }}
                                records={this.state.clientList}
                                optionFields={{
                                    value: "value",
                                    text: "label"
                                }}
                                selectedValue={this.props.dataStore.ui.clientId}
                            /> */}
                            <DropdownWrapper>
                             <SelectDropdown
                                domID='Client'
                                label='CLIENT:'
                                isClearable={false}
                                isSearchable={false}
                                onChange={(e: SelectDropdownValue) => this.onSelectChangeClient(e)}
                                options={this.state.clientList}
                                initialValue={{label: this.state.clientList.filter(f => f.value == this.props.dataStore.ui.clientId)[0]?.label, value: this.props.dataStore.ui.clientId}} 
                                />
                            </DropdownWrapper> 
                                
                            
                                <React.Fragment>
                                    <RefreshButtonWrapper>
                                    <span>
                                    <IMG src='/shared/images/Icons/RefreshArrow.png' style={{ marginRight: "5px"} }
                                            onClick={(e: React.MouseEvent<HTMLElement>) => this.onRefresh(e)}
                                        />
                                    </span>
                                    <Button
                                        domID="Refresh-Link"
                                        name="Refresh"
                                        style={{ textDecoration: "underline" }}
                                        buttonType="unstyled"
                                        size="control"
                                        type="button"
                                        onClick={(e: React.SyntheticEvent) => this.onRefresh(e)}
                                    >
                                    </Button>
                                    </RefreshButtonWrapper>
                                </React.Fragment>
                            
                        </ContentRowWrapper>
                        <ContentRowWrapper style={{ marginTop: "15px" }}>
                            <ContentColumnWrapper>
                                <ContentRowWrapper >
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px" }}>Unreleased:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px" }}>{this.props.dataStore.ui.unreleased}</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px" }}>Test Unreleased:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px" }}>{this.props.dataStore.ui.testUnreleased}</ARMLabel>
                                    </ContentColumnWrapper>
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <div></div>
                            </ContentColumnWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <ContentColumnWrapper>
                                <ContentRowWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px" }}>Releasing:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px" }}>{this.props.dataStore.ui.releasing}</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px" }}>Test Released:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px" }}>{this.props.dataStore.ui.testReleased}</ARMLabel>
                                    </ContentColumnWrapper>
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <div></div>
                            </ContentColumnWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <ContentColumnWrapper>
                                <ContentRowWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px" }}>Released:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px" }}>{this.props.dataStore.ui.released}</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px" }}>Test Releasing:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px" }}>{this.props.dataStore.ui.testReleasing}</ARMLabel>
                                    </ContentColumnWrapper>
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <div></div>
                            </ContentColumnWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <ContentColumnWrapper>
                                <ContentRowWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px" }}>Held:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px" }}>{this.props.dataStore.ui.held}</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px" }}>Test Deleted:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px" }}>{this.props.dataStore.ui.testDeleteCount}</ARMLabel>
                                    </ContentColumnWrapper>
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <div></div>
                            </ContentColumnWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <ContentColumnWrapper>
                                <ContentRowWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px" }}>Bridging:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px" }}>{this.props.dataStore.ui.bridging}</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px", color: this.props.dataStore.ui.rrtEnabled === "1" ? "#37474F" : this.props.dataStore.ui.rrtEnabled === "" ? "#37474F" : "grey" }}>RRT Unsent:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px", color: this.props.dataStore.ui.rrtEnabled === "1" ? "#37474F" : this.props.dataStore.ui.rrtEnabled === "" ? "#37474F" : "grey" }}>{this.props.dataStore.ui.rrtUnsent}</ARMLabel>
                                    </ContentColumnWrapper>
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <div></div>
                            </ContentColumnWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <ContentColumnWrapper>
                                <ContentRowWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px" }}>Deleted:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px" }}>{this.props.dataStore.ui.deleteCount}</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px", color: this.props.dataStore.ui.rrtEnabled === "1" ? "#37474F" : this.props.dataStore.ui.rrtEnabled === "" ? "#37474F" : "grey" }}>RRT Sending:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px", color: this.props.dataStore.ui.rrtEnabled === "1" ? "#37474F" : this.props.dataStore.ui.rrtEnabled === "" ? "#37474F" : "grey" }}>{this.props.dataStore.ui.rrtSending}</ARMLabel>
                                    </ContentColumnWrapper>
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <div></div>
                            </ContentColumnWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <ContentColumnWrapper>
                                <ContentRowWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px" }}>Direct Entry Processing:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px" }}>{this.props.dataStore.ui.de}</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px", color: this.props.dataStore.ui.rrtEnabled === "1" ? "#37474F" : this.props.dataStore.ui.rrtEnabled === "" ? "#37474F" : "grey" }}>RRT Sent:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px", color: this.props.dataStore.ui.rrtEnabled === "1" ? "#37474F" : this.props.dataStore.ui.rrtEnabled === "" ? "#37474F" : "grey" }}>{this.props.dataStore.ui.rrtSent}</ARMLabel>
                                    </ContentColumnWrapper>
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <div></div>
                            </ContentColumnWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <ContentColumnWrapper>
                                <ContentRowWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px" }}>Outsource Marked:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px" }}>{this.props.dataStore.ui.outSourceMarked}</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px", color: this.props.dataStore.ui.rrtEnabled === "1" ? "#37474F" : this.props.dataStore.ui.rrtEnabled === "" ? "#37474F" : "grey" }}>RRT Deleted:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px", color: this.props.dataStore.ui.rrtEnabled === "1" ? "#37474F" : this.props.dataStore.ui.rrtEnabled === "" ? "#37474F" : "grey" }}>{this.props.dataStore.ui.rrtDeleted}</ARMLabel>
                                    </ContentColumnWrapper>
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <div></div>
                            </ContentColumnWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <ContentColumnWrapper>
                                <ContentRowWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px" }}>Outsource Files Created:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px" }}>{this.props.dataStore.ui.outSourceFilesCreated}</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper style={{ textAlign: "right", width: "200px" }}>

                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper style={{ textAlign: "right", width: "75px" }}>

                                    </ContentColumnWrapper>
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <div></div>
                            </ContentColumnWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <ContentColumnWrapper>
                                <ContentRowWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px" }}>Outsource Sending:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px" }}>{this.props.dataStore.ui.outSourceSending}</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper style={{ textAlign: "right", width: "200px" }}>

                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper style={{ textAlign: "right", width: "75px" }}>

                                    </ContentColumnWrapper>
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <div></div>
                            </ContentColumnWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <ContentColumnWrapper>
                                <ContentRowWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px" }}>Outsourced:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px" }}>{this.props.dataStore.ui.outSourced}</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper style={{ textAlign: "right", width: "200px" }}>

                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper style={{ textAlign: "right", width: "75px" }}>

                                    </ContentColumnWrapper>
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <div></div>
                            </ContentColumnWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <ContentColumnWrapper>
                                <ContentRowWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px", color: this.props.dataStore.ui.rrtEnabled === "1" ? "grey" : "#37474F" }}>State Reporting Only:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px", color: this.props.dataStore.ui.rrtEnabled === "1" ? "grey" : "#37474F" }} >{this.props.dataStore.ui.stateReportingOnly}</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper style={{ textAlign: "right", width: "200px" }}>

                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper style={{ textAlign: "right", width: "75px" }}>

                                    </ContentColumnWrapper>
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <div></div>
                            </ContentColumnWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <ContentColumnWrapper>
                                <ContentRowWrappertest>

                                    <ContentColumnWrapper style={{ textAlign: "right", width: "200px", marginLeft:"15px" }}>
                                        <div style={{ display: "block", height: "1px", border: "0", borderTop: "1px solid grey", margin: "1em 0", padding: "0",gap:'0px !important'}}></div>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper style={{ textAlign: "right", width: "75px" }}>
                                        <div style={{ display: "block", height: "1px", border: "0", borderTop: "1px solid grey", margin: "1em 0", padding: "0",gap:'0px !important' }}></div>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper style={{ textAlign: "right", width: "200px" }}>
                                        <div style={{ display: "block", height: "1px", border: "0", borderTop: "1px solid grey", margin: "1em 0", padding: "0",gap:'0px !important' }}></div>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper style={{ textAlign: "right", width: "75px" }}>
                                        <div style={{ display: "block", height: "1px", border: "0", borderTop: "1px solid grey", margin: "1em 0", padding: "0",gap:'0px !important' }}></div>
                                    </ContentColumnWrapper>
                                </ContentRowWrappertest>
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <div></div>
                            </ContentColumnWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <ContentColumnWrapper>
                                <ContentRowWrapper>
                                    <ContentColumnWrapper style={{ textAlign: "right", width: "200px" }}>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper style={{ textAlign: "right", width: "75px" }}>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "200px" }}>Total Claims:</ARMLabel>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ARMLabel style={{ textAlign: "right", width: "75px" }} >{this.props.dataStore.ui.totalClaims}</ARMLabel>
                                    </ContentColumnWrapper>
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <div></div>
                            </ContentColumnWrapper>
                        </ContentRowWrapper>
                    </ContentColumnWrapper>


                </DialogWrapper>
            </React.Fragment>
        );
    }
};

//todo: update states reducers
var connectedHoc = connect<IClaimTotalsState, IClaimTotalsActionProps, IOwnProps, IClaimTotalsProps, ApplicationState>(
    createCrudMapStateToProps('claimTotals'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ClaimTotals);

export default withRouter(connectedHoc);
