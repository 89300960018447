import * as React from 'react';
import styled from 'styled-components';
import {  getSecurityToken } from '@scripts/session/SecurityToken';
import { LoadingIndicator } from '@optum-uicl/ui-core/dist';
import { LandingPageComponent, IComponentProps } from './LandingPageComponent';
import { AssuranceComponentTop } from './AssuranceComponentTop';
import { ARMComponentError } from './ARMComponentError';
import { URLs } from '@commonDevResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';

const adDwellTimeInMilliseconds: number = 5000;

interface IAdSpaceState {
    data: any;
    errorCode: any;
    clientID: string;
    siloId: string;
    token: string | null;
    selectedAd: number;
}

export interface IAdSpaceProps extends IComponentProps {//, RouteComponentProps<{}> {
    AdHost: string | null;
    AdUrl: string | null;
}

const AdSpaceContainer = styled.div`
id:"${(props: IComponentProps) => props.Name}";
order:"${(props: IComponentProps) => props.Order}";
border = "1px solid #e1e2e6";
  fontFamily = "Tahoma"; 
  fontSize = "13px"; 
  color = "#d84040";
  backgroundColor = "#FDF6E3";
className = "${(props: IComponentProps) => props.Name + " flex-item " + props.Name + props.Order}";
`;

export const AdDotContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    width: 100%;
    margin-right: 10px;
`;

const normalDotStyle: React.CSSProperties = {
    border: '2px solid #efefef'
};

const activeDotStyle: React.CSSProperties = {
    border: '2px solid #e47979'
};


export default class AdSpace extends React.Component<IAdSpaceProps, IAdSpaceState>{
    static defaultProps: IComponentProps = {
    };
    
    constructor(props: IAdSpaceProps) {
        super(props);
        this.state = {
            data:null,
            errorCode:null,
            clientID: "0",
            siloId: "0",
            token: sessionStorage.getItem('SecurityInfo'),
            selectedAd: 1
        };        
    }

    GetClientDetailsFromServer() {
        var xhr = new XMLHttpRequest();
        //this = typeof (AdSpace);
        xhr.open('get', URLs.api + "/api/data/securityinfo/clientIdAndSiloId", true);
        xhr.onload = () => {
            var data = JSON.parse(xhr.responseText);
            this.setState({
                clientID: data.ClientId,
                siloId: data.SiloId
            })
            this.getAdsFromStore(this.state.clientID, this.state.siloId);
        };
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send();
    }

    componentDidMount() {
        this.GetClientDetailsFromServer();
        this.getAdsFromStore("", "");
    }

    componentWillUnmount() {
        this.stopAdCycle();
    }

    getAdHost() {
        //return 'http://localhost:7028';
        var jsonToken = getSecurityToken();
        //return this.props.AdHost || sessionStorage.getItem('PlatformUrl');
        return this.props.AdHost || jsonToken.PlatformUrl;
    }

    getAdUrl(clientId:string, siloId:string) {
        var adHost = this.getAdHost();
        var adUrl = this.props.AdUrl || '/api/Ad?clientId=' + clientId + '&siloId=' + siloId;
        return adHost + adUrl;
    }

    private readonly noTimer = -1;
    private currentTimerId = this.noTimer;

    stopAdCycle() {
        if (this.currentTimerId !== undefined) {
            clearTimeout(this.currentTimerId);
            this.currentTimerId = this.noTimer;
        }
    }
    
    getNewSelectedAdIndex() {
        if (isNaN(this.state.selectedAd)) {
            return this.state.selectedAd;
        }
        if (this.state.data.length < 2) {
            return this.state.selectedAd;       // not enough ads to rotate
        }
        return (this.state.selectedAd % this.state.data.length) + 1;
    }

    cycleAd() {
        this.stopAdCycle();     // stop any existing cycle
        const newSelectedAd = this.getNewSelectedAdIndex();
        if (!isNaN(newSelectedAd)) {
            this.setState({ selectedAd: newSelectedAd });
 //           console.log(`Showing ad #${newSelectedAd} of ${this.state.data.length} for ${adDwellTimeInMilliseconds/1000} seconds.`);
        }
        this.currentTimerId = window.setTimeout(this.cycleAd.bind(this), adDwellTimeInMilliseconds);
    }

    getAdsFromStore(clientId:string,siloId:string) {
        var adUrl = this.getAdUrl(clientId, siloId);
        console.log("Loading from " + adUrl);
        var xhr = new XMLHttpRequest();
        
        xhr.onreadystatechange = () => {
            if (xhr.readyState == xhr.DONE) {
                if (xhr.status != 200) {
                    console.warn(xhr.status + " response handling " + adUrl);
                    this.setState({ errorCode: xhr.status });
                }
                else {
                    try {
                        console.log("Ad data loaded");
                        var responseData = xhr.responseText;
                        console.log("Ad data loaded: " + responseData);
                        var adData = JSON.parse(responseData);
                        console.log("Ad data loaded: " + adData);
                        this.setState({ data: adData }, this.cycleAd);
                    }
                    catch (e){
                        console.log("Bad Ad data loaded: " + e);
                    }
                }
            }
        };

        xhr.open('get', adUrl, true);
        xhr.send();
        //var adData = [{ "Id": "Ad_02", "Name": "RelayLearn", "Type": "Item", "Image": { "Url": "/Images/Ads/KnowledgeCenter.png", "UsePlatformUrl": 1, "Width": 242, "Height": 265 }, "Link": "https://relayhealth.learn.com/learn", "IncludedSilos": "", "ExcludedSilos": "HKY04,THK04,QHK04", "IncludedCids": "", "ExcludedCids": "7261,7262,7263,7264,7265,7266,7267,7268,7269" }, { "Id": "Ad_03", "Name": "SupportChat", "Type": "Item", "Image": { "Url": "/Images/Ads/SupportChat.png", "UsePlatformUrl": 1, "Width": 242, "Height": 265 }, "Link": "http://relayhealth.custhelp.com/app/chat/chat_launch/", "IncludedSilos": "", "ExcludedSilos": "HKY04,THK04,QHK04", "IncludedCids": "", "ExcludedCids": "7261,7262,7263,7264,7265,7266,7267,7268,7269" }, { "Id": "Ad_11", "Name": "Community", "Type": "Item", "Image": { "Url": "/Images/Ads/CommunitiesBannerAdWhite.png", "UsePlatformUrl": 1, "Width": 242, "Height": 265 }, "Link": "https://community.changehealthcare.com", "IncludedSilos": "", "ExcludedSilos": "HKY04,THK04,QHK04", "IncludedCids": "", "ExcludedCids": "" }];
        //this.setState({ data: adData });
    }

    isItemDefined(input: any) {
        if (typeof input === 'undefined' || input === null) {
            return false;
        }
        return true;
    }

    getAdItems(data:any) {
        var adItems = null;
        if (this.isItemDefined(data)) {
      //      console.log("getAdData: " + data);
            var adCount = 0;
            var adItems = data.map((adItem: any) => {
                adCount++;
        //        console.log("processing " + adItem.Name);
                if (adItem.Type !== null && adItem.Type !== undefined) {
                    switch (adItem.Type) {
                        case "Item":
                            var imageUrl = adItem.Image.Url;
                            if (adItem.Image.UsePlatformUrl === 1) {
                                imageUrl = this.getAdHost() + adItem.Image.Url;
                            }
                            var adClassName = "RAPAdSpaceItem";
                            if (adCount !== this.state.selectedAd)
                                adClassName = "RAPAdSpaceItemHide";
                            return( 
                               
                                <div className={adClassName}>
                                    <a href={adItem.Link} target="_blank">
                                        <img src={imageUrl} width={adItem.Image.Width} height={adItem.Image.Height} />
                                    </a>
                                </div>);
                    }
                }
            });
        }
        return adItems;
    }

    getRenderDots(data:any) {
        var adItems = null;
        if (this.isItemDefined(data)) {
   //         console.log("getAdData: " + data);
            var adCount = 0;
            var adItems = data.map((adItem: any) => {
                adCount++;
     //           console.log("processing " + adItem.Name);
                if (adItem.Type !== null && adItem.Type !== undefined) {
                    switch (adItem.Type) {
                        case "Item":
                            //                        var imageUrl = adItem.Image.Url;
                            //                        if (adItem.Image.UsePlatformUrl === 1) {
                            //                            imageUrl = this.getAdHost() + adItem.Image.Url;
                            //                        }
                            var ct = adCount;
                            let dotStyle = normalDotStyle;
                            if (adCount === this.state.selectedAd) {
                                dotStyle = activeDotStyle;
                            }
                            //                        var adClassName = "dot";
                            //                        if (adCount !== 1)
                            //                            adClassName = "dot";
                            return (<span className="adDot" style={dotStyle} onClick={(e: any) => this.clickDot(ct)}></span>);
                    }
                }
            });
        }
        return adItems;
    }

    clickDot(dotVal: number) {
        this.setState({selectedAd: dotVal});
    }


    getClassName() {
        return this.props.Name + " flex-componentcontainer";
    }

    renderLoading() {
        return (<LandingPageComponent ComponentClass={this.getClassName()} {...this.props}>
            <AssuranceComponentTop helpCtrl="false" />
            <div className="RAPAdSpaceLoader">
                <div className="RAPAdSpaceItem">
                    <LoadingIndicator domID="test-id" className="test-indicator" length="32px" />
                </div>
            </div>
        </LandingPageComponent>);
    }

    renderAds() {

        return (<LandingPageComponent ComponentClass={this.getClassName()} {...this.props}>
            <AssuranceComponentTop helpCtrl="false" />
            {this.getAdItems(this.state.data)} 
            <AdDotContainer>
                {this.getRenderDots(this.state.data)}
            </AdDotContainer>
        </LandingPageComponent>);
    }

    renderError() {
        return (<LandingPageComponent ComponentClass={this.getClassName()} {...this.props}>
            <AssuranceComponentTop helpCtrl="false" />
            <div className="RAPAdSpaceLoader">
                <ARMComponentError />
            </div>
        </LandingPageComponent>);
    }

    render() {
        if (this.state.data != null) {
            return this.renderAds();
        }
        else if (this.state.errorCode) {
            return this.renderError();
        }
        else {
            return this.renderLoading();
        }
    }
}
