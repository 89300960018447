import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';

import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import { IsFieldFilled, IsNumeric } from '@commonResources/validations';

import styled from 'styled-components';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IBridgeMaintenanceState, IBridgeMaintenanceActions, IBridgeMaintenanceActionProps, actionCreators, validationCallback } from '@store/BridgeMaintenance';
import { defaultState as defaultUIState } from '@store/ui/BridgeMaintenanceUI';
import { ICookies_Config } from '@store/ConfigData';

import { Input, TextArea, Button, SelectDropdown } from '@optum-uicl/ui-core/dist';

import { DialogWrapper, OKCancelButtons, DialogInstruction, CenteredContent, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { DialogLegend, DialogFieldset, DialogLabel } from '@common/DialogStyles';
import { CheckBoxComponent } from '@common/CheckBox';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { SelectComponent } from '../../common/SelectComponent';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';


const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

`;

const BridgeMaintenanceLeftContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 0px 5px;
    width: 435px;

    #bridges-fieldset {
            padding-bottom: 9px !important;
        }
`;

const BridgeMaintenanceContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

    min-height: 180px;
    width: 680px; 
    padding: 10px 10px 0px 10px;

    div.text-input input[type='text']{
        height: 28px;
    }


    fieldset {
        padding-bottom: 5px;
        margin-left: 0px;
        margin-right: 0px;
        margin-top:2px;
    }

    
    #bridge-key {
        width: 160px;
        text-transform: uppercase;
        padding-right: 10px;
        margin-right: 6px;
    }
    #bridge-name {
        width: 365px;
        text-transform: uppercase;
        padding-right: 10px;
        margin-right: 6px;
    }
    #bridge-claimtype{
        width: 160px;
    }
    #bridge-filetype{
        width: 195px;
       
        margin-right: 5px;
    }   
    #bridge-pagingroutine{
        width: 600px;
    }

    #bridge-conversionprocess{
        width: 488px;
    }


    #bridge-fileheaderterminator,
    #bridge-claimheaderterminator,
    #bridge-claimbodyterminator,
    #bridge-lineformatterminator
    {
        width: 120px;
    }
    #bridge-fileheadercount,
    #bridge-claimheadercount,
    #bridge-claimbodycount,
    #bridge-lineformatcount
    {
        margin-right: 10px;
        width: 50px;
    }
    #bridge-fileheadertype,
    #bridge-claimheadertype,
    #bridge-claimbodytype,
    #bridge-lineformattype{
        width: 450px;
        margin-right: 10px;
    }

    #bridge-maxpages{
        width: 160px;
        margin-right: 6px;
    }

#bridge-filetype-line,
#bridge-maxpages-line {
    margin-top: 0px;
}

    #bridge-v5fieldset2 {
        xxxwidth: 100%;       
    }
    #bridge-desfieldset,
    #xxxbridge-rrtfieldset {      
        width: 325px;
        
    }

    #bridge-rrtfieldset_wrapper {      
       margin-left: 10px !important; 
       height:100%;
    }
    #bridge-desfieldset{
        margin-right: 10px;
        height:100%;
        margin-top:0px;
    }

    #bridge-desfiletype{
        width: 170px;
    }

    #bridge-v5nsfrecordidlength,
    #bridge-v5nsfrecordlength,
    #bridge-v5nsfheaderendrecord{
        width: 73px;
        margin-right: 60px;
    }


    #bridge-sdrselfpay_wrapper,
    #bridge-sdrstateclaims_wrapper{
        height: 40px;
        margin: 1px 5px  1px 5px !important;
    }

    #bridge-sdrselfpay,
    #bridge-sdrstateclaims{
        margin-top:10px;
    }

#bridge-desfieldset_wrapper{
    margin-right: 20px;
    height:100%;
}

`;


const JustifyV5 = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;


interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    canSecCheck1: boolean,  // delete
    title: string,
};

interface IComponentState {
    cancelLeave: boolean
}

interface IDropdownItem {
    label: string,
    value: string,
}

export const DEFAULT_STATE: IComponentState = {cancelLeave: false };

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IBridgeMaintenanceProps = IMergeCrudComponentProps<IBridgeMaintenanceState, IBridgeMaintenanceActionProps, IOwnProps>;



export class BridgeMaintenance extends React.Component<IBridgeMaintenanceProps, IComponentState> {
    static addItemCount: number = 0;

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        canSecCheck1 : false,
        title: "Bridge Maintenance",
    };

    constructor(props: IBridgeMaintenanceProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }


    public componentDidMount() {
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.props.history.push('/LandingPage');
        }
        pageLeave();
        //     console.log('can edit ' + this.props.canEdit + ' can view ' + this.props.canView);
        this.props.action.configData.getConfigData({ cookies: [], config: [{ name: "EnableTenetFeatures" }] });
        this.props.action.crud.get({ crudId: CrudTypes.mctiBridge }, validationCallback);
 
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    /* -----------------------------------  */
    /* --------  ACTION METHODS ----------  */
    /* -----------------------------------  */


    public onSelectBridgeMaintenance(e: React.ChangeEvent<HTMLSelectElement>) {

        this.props.action.ui.selectBridgeMaintenance(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
//                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }

    public updateBridge_BridgeKey(val: string) {
        if (val && val.length > 0)
            val = val.toUpperCase();
        this.props.action.ui.updateBridgeKey({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }

    public updateBridge_BridgeName(val: string) {
        if (val && val.length > 0)
            val = val.toUpperCase();
        this.props.action.ui.updateBridgeName({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_ClaimType(val: string) {
        this.props.action.ui.updateBridgeClaimType({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_FileType(val: string) {
        this.props.action.ui.updateBridgeFileType({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_PagingRoutine(val: string) {
        this.props.action.ui.updateBridgePagingRoutine({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_MaxPages(val: string) {
        this.props.action.ui.updateBridgeMaxPages({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_ConversionProcess(val: string) {
        if (val && val.length > 0)
            val = val.toUpperCase();
        this.props.action.ui.updateBridgeConversionProcess({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_FileHeaderTypeID(val: string) {
        this.props.action.ui.updateBridgeFileHeaderTypeID({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_FileHeaderCount(val: string) {
        this.props.action.ui.updateBridgeFileHeaderCount({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_FileHeaderTerminator(val: string) {
        this.props.action.ui.updateBridgeFileHeaderTerminator({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_ClaimHeaderTypeID(val: string) {
        this.props.action.ui.updateBridgeClaimHeaderTypeID({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_ClaimHeaderCount(val: string) {
        this.props.action.ui.updateBridgeClaimHeaderCount({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_ClaimHeaderTerminator(val: string) {
        this.props.action.ui.updateBridgeClaimHeaderTerminator({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_ClaimBodyTypeID(val: string) {
        this.props.action.ui.updateBridgeClaimBodyTypeID({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_ClaimBodyCount(val: string) {
        this.props.action.ui.updateBridgeClaimBodyCount({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_ClaimBodyTerminator(val: string) {
        this.props.action.ui.updateBridgeClaimBodyTerminator({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_LineFormatTypeID(val: string) {
        this.props.action.ui.updateBridgeLineFormatTypeID({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_LineFormatCount(val: string) {
        this.props.action.ui.updateBridgeLineFormatCount({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_LineFormatTerminator(val: string) {
        this.props.action.ui.updateBridgeLineFormatTerminator({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_V5NSFRecordIdLength(val: string) {
        this.props.action.ui.updateBridgeV5NSFRecordIdLength({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_V5NSFRecordLength(val: string) {
        this.props.action.ui.updateBridgeV5NSFRecordLength({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_V5NSFHeaderEndRecord(val: string) {
        this.props.action.ui.updateBridgeV5NSFHeaderEndRecord({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }

    public updateBridge_DESFileTypeID(val: string) {
        this.props.action.ui.updateBridgeDESFileTypeID({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateBridge_SDRSelfPay(val: boolean) {
        let sdr = this.props.dataStore.ui.selectedItem['@SDRType'];
        let newVal = '0';
        if (val) {
                newVal = '1';
        } else {
                newVal = '0';
        }
        this.props.action.ui.updateBridgeSDRType({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: newVal
            }
        });
    }
    public updateBridge_SDRStateClaims(val: boolean) {
        let sdr = this.props.dataStore.ui.selectedItem['@SDRType'];
        let newVal = '0';
        if (val) {
                newVal = '2';
        } else {
            newVal = '0';
        }
        this.props.action.ui.updateBridgeSDRType({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: newVal
            }
        });
    }

    public sendError(id: string, msg: string) {
        if (!id || (id && id.length === 0) || !msg || (msg && msg.length === 0))
            return;

        this.props.action.ui.errorBridgeField({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { id: id, value: msg }
        });
    }



/* -----------------------------------  */
    /* ------  BUTTON RELATED METHODS ----  */
    /* -----------------------------------  */
    public crudLoaded(): boolean {
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.BridgeMaintenanceInfo &&
            this.props.dataStore.crud.data.BridgeMaintenanceInfo.Bridges &&
            this.props.dataStore.crud.data.BridgeMaintenanceInfo.Bridges.Bridge)
            return true;

        return false;
    }

    public validateEntry(): boolean {
        if (!this.crudLoaded())
            return false;

        let requiredFieldErrorFound = false;

        if (!IsFieldFilled(this.props.dataStore.ui.selectedItem['@BridgeKey'])) {
            this.sendError('BridgeKey', "Bridge Key must not be blank.");
            requiredFieldErrorFound = true;
        }
        
        if (!IsFieldFilled(this.props.dataStore.ui.selectedItem['@MaxPages'])) {
            this.sendError('MaxPages', "Max Pages must not be blank.");
            requiredFieldErrorFound = true;
        }

        if (!IsFieldFilled(this.props.dataStore.ui.selectedItem['@Name'])) {
            this.sendError('Name', "Bridge Name must not be blank.");
            requiredFieldErrorFound = true;
        }

        if (requiredFieldErrorFound)
            return false;

        const selectedItemID = this.props.dataStore.ui.selectedItem['@ID'];
        const selectedItemKey = this.props.dataStore.ui.selectedItem['@BridgeKey'];

        const conflict = this.props.dataStore.crud.data!.BridgeMaintenanceInfo.Bridges.Bridge
            .find(obj => obj['@ID'] !== selectedItemID && obj['@BridgeKey'] === selectedItemKey);

        if (conflict !== undefined) {
            this.sendError('BridgeKey', "The Bridge Key you have entered already exists.Please type in a different Bridge Key.");
            return false;
        }

        let numericFieldErrorFound = false;
        
        if (!IsNumeric(this.props.dataStore.ui.selectedItem['@MaxPages'])) {
            this.sendError('MaxPages', "The value you typed in must consist of only numbers.");
            numericFieldErrorFound = true;
        }

        if (!IsNumeric(this.props.dataStore.ui.selectedItem['@FileHeaderCount'])) {
            this.sendError('FileHeaderCount', "The value you typed in must consist of only numbers.");
            numericFieldErrorFound = true;
        }

        if (!IsNumeric(this.props.dataStore.ui.selectedItem['@ClaimHeaderCount'])) {
            this.sendError('ClaimHeaderCount', "The value you typed in must consist of only numbers.");
            numericFieldErrorFound = true;
        }

        if (!IsNumeric(this.props.dataStore.ui.selectedItem['@ClaimBodyCount'])) {
            this.sendError('ClaimBodyCount', "The value you typed in must consist of only numbers.");
            numericFieldErrorFound = true;
        }

        if (!IsNumeric(this.props.dataStore.ui.selectedItem['@LineFormatCount'])) {
            this.sendError('LineFormatCount', "The value you typed in must consist of only numbers.");
            numericFieldErrorFound = true;
        }

        if (!IsNumeric(this.props.dataStore.ui.selectedItem['@V5NSFRecordIdLength'])) {
            this.sendError('V5NSFRecordIdLength', "The value you typed in must consist of only numbers.");
            numericFieldErrorFound = true;
        }

        if (!IsNumeric(this.props.dataStore.ui.selectedItem['@V5NSFRecordLength'])) {
            this.sendError('V5NSFRecordLength', "The value you typed in must consist of only numbers.");
            numericFieldErrorFound = true;
        }
        if (numericFieldErrorFound)
            return false;


        if (this.allowTenetFlag() === "1") {
            if (this.props.dataStore.crud.data &&
                this.props.dataStore.crud.data.BridgeMaintenanceInfo &&
                this.props.dataStore.crud.data.BridgeMaintenanceInfo.DESFileTypeList &&
                this.props.dataStore.crud.data.BridgeMaintenanceInfo.DESFileTypeList.DESFileType &&
                this.props.dataStore.crud.data.BridgeMaintenanceInfo.DESFileTypeList.DESFileType.length > 0)
            {
                if (this.props.dataStore.ui.selectedItem['@DESFileTypeID'] === "0") {
                    this.sendError('DESFileTypeID', "Please select a DES submission file type.");
                    return false;
                }
            }
        }



        return true;

    }

    public onClickModifyButton(e: React.SyntheticEvent) {
        if (!this.crudLoaded())
            return;


        if (!this.validateEntry())
            return;


        const selectedItemID = this.props.dataStore.ui.selectedItem['@ID'];

        if (selectedItemID !== "") {
            let temp = this.props.dataStore.crud.data!.BridgeMaintenanceInfo.Bridges.Bridge.find(
                obj => obj['@ID'] === selectedItemID);
            if (!temp) return;
           
            this.props.action.ui.updateBridge({ masterCrud: this.props.dataStore.crud.data, uiData: { id: selectedItemID } });
        } else {
            this.props.action.ui.addBridge({ masterCrud: this.props.dataStore.crud.data, uiData: { id: "#" + BridgeMaintenance.addItemCount++ } });
        }
    }

    
    public onClearRemoveConfirm(e: any) {
        this.onConfirmRemove('');
    }
    public onClickRemoveButton(e: React.SyntheticEvent) {
        if (this.props.dataStore.ui.selectedItem['@ID'] === "")
            return;

        const msg = "Do you want to remove '" + this.props.dataStore.ui.selectedItem['@Name'] + "'? This action cannot be undone.";
        this.onConfirmRemove(msg);
    }
    public onConfirmRemove(msg: string) {
       

        this.props.action.ui.errorBridgeRemoveConfirm({ masterCrud: this.props.dataStore.crud.data, uiData: { value: msg } });
    }

    public onRemoveButton(e: any) {

        if (!this.crudLoaded()) return;

        const selectedItemID = this.props.dataStore.ui.selectedItem['@ID'];

        if (selectedItemID !== "") {
            handleChange()
            let temp = this.props.dataStore.crud.data!.BridgeMaintenanceInfo.Bridges.Bridge.find(
                obj => obj['@ID'] === selectedItemID);
            if (!temp) return;

            this.props.action.ui.removeBridge({ masterCrud: this.props.dataStore.crud.data, uiData: { id: selectedItemID }});
        }

    }


    public validateForm(): boolean {


        return true;
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {

        if (!this.validateForm())
            return;

   //     console.log(this.props.dataStore.crud.data);
        
        this.props.action.crud.updateWithDelta(this.props.dataStore.crud);

        const location = {
            pathname: '/LandingPage',
            state: { fromDashboard: true }
        }

        this.props.history.push('/LandingPage');
    }


    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }
    public onClearAlert(e: any) {
        this.onAlert("");
    }
    public onAlert(msg: string) {
        this.props.action.ui.sendBridgeMaintenanceAlert({ masterCrud: this.props.dataStore.crud.data, uiData: { value: msg } });
    }

    /* -----------------------------------  */
    /* -----  DATA HELP METHODS ----------  */
    /* -----------------------------------  */

    public getGenericLookupList_SelectedItem(lookupList: MCB_GenericLookupList[] | null, idToFind: string | undefined): IDropdownItem {
        if (!this.crudLoaded() || !this.props.dataStore.ui.selectedItem  ||
            idToFind === undefined || !lookupList || lookupList.length === 0
        ) return { value: '', label: '' };

        let item = lookupList.find(
            ob => ob['@ID'] === idToFind);
        let result = { value: '', label: '' };
        if (item) {
            result.label = item['@Name'];
            result.value = item['@ID'];
        } else {
            result.label = '- Select File Type -';
            result.value = '0';
        }

        return result;
    }
    
    public getDESFileTypeLookupList_ArrayFiltered(lookupList: MCB_DESFileType[] | null) {
        if (!this.crudLoaded() || !this.props.dataStore.ui.selectedItem ||
            !lookupList || lookupList.length === 0)
            return [];

        let result: any[] = [];
        result.push({ label: '- Select File Type -', value: '0' });

        var filterField = this.getSelectedClaimType();
        if (!filterField) filterField = "1001";

        lookupList.forEach(ob => {
            if (ob['@Name'] && ob['@ClaimTypeID'] === filterField)
                result.push({ label: ob['@Name'], value: ob['@ID'] });
        });
        return result;
    }

    public getPagingRoutineLookupList_ArrayFiltered(lookupList: MCB_PagingRoutineType[] | null) {
        if (!this.crudLoaded() || !this.props.dataStore.ui.selectedItem ||
            !lookupList || lookupList.length === 0)
            return [];

        let result: any[] = [];

        var filterField = this.getSelectedClaimType();
        if (!filterField) filterField = "1001";

        lookupList.forEach(ob => {
            if (ob['@Name'] && ob['@ClaimTypeId'] === filterField)
                result.push({ label: ob['@Name'], value: ob['@ID'] });
        });
        return result;
    }

    public getGenericLookupList_Array(lookupList: MCB_GenericLookupList[] | null ) {
        if (!this.crudLoaded() || !this.props.dataStore.ui.selectedItem ||
            !lookupList || lookupList.length === 0)
            return [];

        let result: any[] = [];

        lookupList.forEach(ob => {
            if (ob['@Name'])
                result.push({ label: ob['@Name'], value: ob['@ID'] });
        });
        return result;
    }

    public getSelectedClaimType() : string | undefined{
        var temp = "1001";
        if (this.props.dataStore.ui && this.props.dataStore.ui.selectedItem)
            return this.props.dataStore.ui.selectedItem["@ClaimTypeID"];
        return temp;
    }


    public getBridgesForLeftSideDisplay() {
        let addItem: MCB_Bridge[] = [
            {
                '@ID': '',
                '@Name': '- ADD A NEW BRIDGE -',
                '@BridgeKey': '',

            }
        ];

        let data = this.props.dataStore.crud.data
            ? addItem.concat(this.props.dataStore.crud.data.BridgeMaintenanceInfo.Bridges.Bridge) : addItem;

        return data;
    }

    public allowTenetFlag() :string {
        let allowTenetObj = this.props.dataStore.configData.container.config?.find((o: ICookies_Config) => o.name === "EnableTenetFeatures");
        return (allowTenetObj && allowTenetObj.value ? allowTenetObj.value : "");
    }

    // returning 0 for normal, disable for disable it, error for error it
    public stateOfSDRCheckbox(type: string): string {
        if (this.crudLoaded() && this.props.dataStore.crud.data!.BridgeMaintenanceInfo.SDR &&
            this.props.dataStore.ui && this.props.dataStore.ui.selectedItem)
        {
            let clientSDR = this.props.dataStore.crud.data!.BridgeMaintenanceInfo.SDR['@SDRType'];

            let val = this.props.dataStore.ui.selectedItem['@SDRType'];
            if ((type === "SelfPay" && clientSDR !== "1" && clientSDR !== "3") ||
                (type === "StateClaims" && clientSDR !== "2" && clientSDR !== "3"))
            {   
                if (type === "SelfPay") {
                    if (val === "1" || val === "3")
                        return "error"; // checked but not allowed to be
                    else
                        return "disable";  // not checked, and can't be...so disable it
                } else if (type === "StateClaims") {
                    if (val === "2" || val === "3")
                        return "error"; // checked but not allowed to be
                    else
                        return "disable";  // not checked, and can't be...so disable it
                }

            } 
        }

        return "0";
    }

   

    /* -----------------------------------  */
    /* -------------  RENDER -------------  */
    /* -----------------------------------  */
    public render() {
        var instruction = <React.Fragment>* indicates a required field.  <span className='instructionRed'>Warning!</span> Changes to bridge information can cause claim submission to fail. <span className='instructionRed'>DO NOT</span> change this information without assistance from a Support technician.</React.Fragment>;
        let instructionTop = <React.Fragment><span className='instructionRed'>Warning!</span> Changes to bridge information can cause claim submission to fail. <span className='instructionRed'>DO NOT</span> change this information without assistance from a Support technician.</React.Fragment>;

        var buttons = <OKCancelButtons disableOK={!this.props.dataStore.ui.changed || !this.props.canEdit} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        let data = this.getBridgesForLeftSideDisplay();
        let allowTenet =  this.allowTenetFlag();

        let rrtClientLevel = (this.crudLoaded() ? this.props.dataStore.crud.data!.BridgeMaintenanceInfo.SDR['@SDRType'] : '');
        let showRRT = (this.crudLoaded() && this.props.dataStore.crud.data!.BridgeMaintenanceInfo.SDR['@SDRType'] !== '0' ||
            (this.props.dataStore.ui && this.props.dataStore.ui.selectedItem &&
            (this.props.dataStore.ui.selectedItem['@SDRType'] === "1" || this.props.dataStore.ui.selectedItem['@SDRType'] === "2" || this.props.dataStore.ui.selectedItem['@SDRType'] === "3")));
        let stateRRTSelfPay = this.stateOfSDRCheckbox("SelfPay");
        let stateRRTStateClaims = this.stateOfSDRCheckbox("StateClaims");
        return (
            <DialogWrapper title={this.props.title} marginTop="28px" instruction={instruction} helpUrl='/Support/Help/HELP_Maint_Bridge.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper id={"content_wrapper_BridgeMaintenance"}>
                    {// <CenteredContent><DialogInstruction>{instructionTop}</DialogInstruction></CenteredContent> 
                    }
                    <ContentRowWrapper>
                        <BridgeMaintenanceLeftContent>
                            <DialogFieldset id='bridges-fieldset'>
                                <DialogLegend>Bridges</DialogLegend>
                                <SelectComponent
                                    title='bridges'
                                    size={(allowTenet === '1' ? 30 : 25)}
                                    width='400px'
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectBridgeMaintenance(e)}
                                    optionFields={{
                                        value: "@ID",
                                        text: "@Name"
                                    }}
                                    records={data}
                                    selectedValue={this.props.dataStore.ui.selectedBridgeMaintenance.id}
                                ></SelectComponent>
                            </DialogFieldset>
                        </BridgeMaintenanceLeftContent>
                        <BridgeMaintenanceContent>
                            <ContentRowWrapper>
                                <Input
                                    domID="bridge-key"
                                    className="text-input"
                                    label="*Bridge Key:"
                                    maxLength={6}
                                    initialValue={this.props.dataStore.ui.selectedItem['@BridgeKey']}

                                    hasError={!!this.props.dataStore.ui.bridgeKeyError}
                                    errorMessage={this.props.dataStore.ui.bridgeKeyError}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateBridge_BridgeKey(e.target.value)} 
                                />
                                <Input
                                    domID="bridge-name"
                                    className="text-input"
                                    label="*Bridge Name:"
                                    maxLength={40}
                                    size='small'
                                    initialValue={this.props.dataStore.ui.selectedItem['@Name']}

                                    hasError={!!this.props.dataStore.ui.bridgeNameError}
                                    errorMessage={this.props.dataStore.ui.bridgeNameError}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateBridge_BridgeName(e.target.value)} 
                                />
                                <SelectDropdown
                                    domID="bridge-claimtype"
                                    className="dropdown"
                                    label="*Claim Type:"
                                    // disabled={false}
                                    menuWidth={110}

                                    
                                    isClearable={false}
                                    isSearchable={true}
                                    size='small'

                                    onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateBridge_ClaimType(e.value)}

                                    options={this.getGenericLookupList_Array((!this.props.dataStore.crud.data ||
                                        !this.props.dataStore.crud.data.BridgeMaintenanceInfo
                                        ? null : this.props.dataStore.crud.data.BridgeMaintenanceInfo.ClaimTypeList.ClaimType))}

                                    initialValue={this.getGenericLookupList_SelectedItem(
                                        (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.BridgeMaintenanceInfo
                                            ? null : this.props.dataStore.crud.data.BridgeMaintenanceInfo.ClaimTypeList.ClaimType),
                                        this.props.dataStore.ui.selectedItem["@ClaimTypeID"])}
                                />
                            </ContentRowWrapper>
                            <ContentRowWrapper id='bridge-filetype-line'>
                                
                                <SelectDropdown
                                    domID="bridge-filetype"
                                    className="dropdown"
                                    label="*File Type:"
                                    // disabled={false}
                                    menuWidth={159}



                                    isClearable={false}
                                    isSearchable={true}
                                    size='small'

                                    onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateBridge_FileType(e.value)}

                                    options={this.getGenericLookupList_Array((!this.props.dataStore.crud.data ||
                                        !this.props.dataStore.crud.data.BridgeMaintenanceInfo
                                        ? null : this.props.dataStore.crud.data.BridgeMaintenanceInfo.FileTypeList.FileType))}

                                    initialValue={this.getGenericLookupList_SelectedItem(
                                        (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.BridgeMaintenanceInfo
                                            ? null : this.props.dataStore.crud.data.BridgeMaintenanceInfo.FileTypeList.FileType),
                                        this.props.dataStore.ui.selectedItem["@FileTypeID"])}
                                />
                                <SelectDropdown
                                    domID="bridge-pagingroutine"
                                    className="dropdown"
                                    label="*Paging Routine:"
                                    // disabled={false}
                                    menuWidth={490}



                                    isClearable={false}
                                    isSearchable={true}
                                    size='small'

                                    onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateBridge_PagingRoutine(e.value)}



                                    options={this.getPagingRoutineLookupList_ArrayFiltered((!this.props.dataStore.crud.data ||
                                                !this.props.dataStore.crud.data.BridgeMaintenanceInfo
                                        ? null : this.props.dataStore.crud.data.BridgeMaintenanceInfo.PagingRoutineTypeList.PagingRoutineType))}

                                    initialValue={this.getGenericLookupList_SelectedItem(
                                        (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.BridgeMaintenanceInfo
                                            ? null : this.props.dataStore.crud.data.BridgeMaintenanceInfo.PagingRoutineTypeList.PagingRoutineType),
                                        this.props.dataStore.ui.selectedItem["@PagingRoutineTypeID"])}
                                    />
                                
                            </ContentRowWrapper>
                            <ContentRowWrapper id='bridge-maxpages-line'>
                                
                                    <Input
                                        domID="bridge-maxpages"
                                        className="text-input"
                                        label="*Maximum Pages:"
                                        maxLength={4}

                                        initialValue={this.props.dataStore.ui.selectedItem['@MaxPages']}
                                        hasError={!!this.props.dataStore.ui.maxPagesError}
                                        errorMessage={this.props.dataStore.ui.maxPagesError}

                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateBridge_MaxPages(e.target.value)} 
                                    />
                                    <Input
                                        domID="bridge-conversionprocess"
                                        className="text-input"
                                        label="Conversion Process:"
                                        maxLength={40} 

                                        initialValue={this.props.dataStore.ui.selectedItem['@ConversionProcess']}
         
                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateBridge_ConversionProcess(e.target.value)} 
                                    />
                                
                            </ContentRowWrapper>
                            <DialogFieldset>
                                <DialogLegend>File Header</DialogLegend>
                                <ContentRowWrapper>
                                <SelectDropdown
                                    domID="bridge-fileheadertype"
                                    className="dropdown"
                                    label="Type:"
                                    // disabled={false}
                                    menuWidth={450}


                                    isClearable={false}
                                    isSearchable={true}
                                    size='small'

                                    onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateBridge_FileHeaderTypeID(e.value)}


                                    options={this.getGenericLookupList_Array((!this.props.dataStore.crud.data ||
                                        !this.props.dataStore.crud.data.BridgeMaintenanceInfo
                                        ? null : this.props.dataStore.crud.data.BridgeMaintenanceInfo.FileHeaderTypeList.FileHeaderType))}

                                    initialValue={this.getGenericLookupList_SelectedItem(
                                        (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.BridgeMaintenanceInfo
                                            ? null : this.props.dataStore.crud.data.BridgeMaintenanceInfo.FileHeaderTypeList.FileHeaderType),
                                        this.props.dataStore.ui.selectedItem["@FileHeaderTypeID"])}
                                />    
                            {/*<ContentRowWrapper>*/}
                                    <Input
                                        domID="bridge-fileheadercount"
                                        className="text-input"
                                        label="Count:"
                                        maxLength={4} 

                                        initialValue={this.props.dataStore.ui.selectedItem['@FileHeaderCount']}
                                        hasError={!!this.props.dataStore.ui.fileHeaderCountError}
                                        errorMessage={this.props.dataStore.ui.fileHeaderCountError}

                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateBridge_FileHeaderCount(e.target.value)} 
                                    />
                                    <Input
                                        domID="bridge-fileheaderterminator"
                                        className="text-input"
                                        label="Terminator:"
                                        maxLength={24} 

                                        initialValue={this.props.dataStore.ui.selectedItem['@FileHeaderTerminator']}


                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateBridge_FileHeaderTerminator(e.target.value)} 
                                    />
                                </ContentRowWrapper>
                            </DialogFieldset>
                            <DialogFieldset>
                                <DialogLegend>Claim Header</DialogLegend>
                                <ContentRowWrapper>
                                <SelectDropdown
                                    domID="bridge-claimheadertype"
                                    className="dropdown"
                                    label="Type:"
                                    // disabled={false}
                                    menuWidth={450}


                                    isClearable={false}
                                    isSearchable={true}
                                    size='small'

                                    onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateBridge_ClaimHeaderTypeID(e.value)}

                                    options={this.getGenericLookupList_Array((!this.props.dataStore.crud.data ||
                                        !this.props.dataStore.crud.data.BridgeMaintenanceInfo
                                        ? null : this.props.dataStore.crud.data.BridgeMaintenanceInfo.ClaimHeaderTypeList.ClaimHeaderType))}

                                    initialValue={this.getGenericLookupList_SelectedItem(
                                        (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.BridgeMaintenanceInfo
                                            ? null : this.props.dataStore.crud.data.BridgeMaintenanceInfo.ClaimHeaderTypeList.ClaimHeaderType),
                                        this.props.dataStore.ui.selectedItem["@ClaimHeaderTypeID"])}
                                />
                                {/*<ContentRowWrapper>*/}
                                    <Input
                                        domID="bridge-claimheadercount"
                                        className="text-input"
                                        label="Count:"
                                        maxLength={4} 

                                        initialValue={this.props.dataStore.ui.selectedItem['@ClaimHeaderCount']}
                                        hasError={!!this.props.dataStore.ui.claimHeaderCountError}
                                        errorMessage={this.props.dataStore.ui.claimHeaderCountError}
                                        
                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateBridge_ClaimHeaderCount(e.target.value)} 
                                    />
                                    <Input
                                        domID="bridge-claimheaderterminator"
                                        className="text-input"
                                        label="Terminator:"
                                        maxLength={24} 

                                        initialValue={this.props.dataStore.ui.selectedItem['@ClaimHeaderTerminator']}
    
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateBridge_ClaimHeaderTerminator(e.target.value)} 
                                    />
                                </ContentRowWrapper>
                            </DialogFieldset>
                            <DialogFieldset>
                                <DialogLegend>Claim Body</DialogLegend>
                                <ContentRowWrapper>
                                <SelectDropdown
                                    domID="bridge-claimbodytype"
                                    className="dropdown"
                                    label="Type:"
                                    // disabled={false}
                                    menuWidth={450}


                                    isClearable={false}
                                    isSearchable={true}
                                    size='small'

                                    onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateBridge_ClaimBodyTypeID(e.value)}

                                    options={this.getGenericLookupList_Array((!this.props.dataStore.crud.data ||
                                        !this.props.dataStore.crud.data.BridgeMaintenanceInfo
                                        ? null : this.props.dataStore.crud.data.BridgeMaintenanceInfo.ClaimBodyTypeList.ClaimBodyType))}

                                    initialValue={this.getGenericLookupList_SelectedItem(
                                        (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.BridgeMaintenanceInfo
                                            ? null : this.props.dataStore.crud.data.BridgeMaintenanceInfo.ClaimBodyTypeList.ClaimBodyType),
                                        this.props.dataStore.ui.selectedItem["@ClaimBodyTypeID"])}
                                />
                            {/*<ContentRowWrapper>*/}
                                    <Input
                                        domID="bridge-claimbodycount"
                                        className="text-input"
                                        label="Count:"
                                        maxLength={4} 

                                        initialValue={this.props.dataStore.ui.selectedItem['@ClaimBodyCount']}
                                        hasError={!!this.props.dataStore.ui.claimBodyCountError}
                                        errorMessage={this.props.dataStore.ui.claimBodyCountError}
                                        
                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateBridge_ClaimBodyCount(e.target.value)} 
                                    />
                                    <Input
                                        domID="bridge-claimbodyterminator"
                                        className="text-input"
                                        label="Terminator:"
                                        maxLength={24} 

                                        initialValue={this.props.dataStore.ui.selectedItem['@ClaimBodyTerminator']}

                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateBridge_ClaimBodyTerminator(e.target.value)} 
                                    />
                                </ContentRowWrapper>
                            </DialogFieldset>
                            <DialogFieldset>
                                <DialogLegend>Line Format</DialogLegend>
                                <ContentRowWrapper>
                                <SelectDropdown
                                    domID="bridge-lineformattype"
                                    className="dropdown"
                                    label="Type:"
                                    // disabled={false}
                                    menuWidth={450}


                                    isClearable={false}
                                    isSearchable={true}
                                    size='small'

                                    onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateBridge_LineFormatTypeID(e.value)}

                                    options={this.getGenericLookupList_Array((!this.props.dataStore.crud.data ||
                                        !this.props.dataStore.crud.data.BridgeMaintenanceInfo
                                        ? null : this.props.dataStore.crud.data.BridgeMaintenanceInfo.LineFormatTypeList.LineFormatType))}

                                    initialValue={this.getGenericLookupList_SelectedItem(
                                        (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.BridgeMaintenanceInfo
                                            ? null : this.props.dataStore.crud.data.BridgeMaintenanceInfo.LineFormatTypeList.LineFormatType),
                                        this.props.dataStore.ui.selectedItem["@LineFormatTypeID"])}
                                />
                                {/*<ContentRowWrapper>*/}
                                    <Input
                                        domID="bridge-lineformatcount"
                                        className="text-input"
                                        label="Count:"
                                        maxLength={4} 

                                        initialValue={this.props.dataStore.ui.selectedItem['@LineFormatCount']}
                                        hasError={!!this.props.dataStore.ui.lineFormatCountError}
                                        errorMessage={this.props.dataStore.ui.lineFormatCountError}
                                        
                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateBridge_LineFormatCount(e.target.value)} 
                                    />
                                    <Input
                                        domID="bridge-lineformatterminator"
                                        className="text-input"
                                        label="Terminator:"
                                        maxLength={24} 

                                        initialValue={this.props.dataStore.ui.selectedItem['@LineFormatTerminator']}


                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateBridge_LineFormatTerminator(e.target.value)} 
                                    />
                                </ContentRowWrapper>
                            </DialogFieldset>

                        <ContentRowWrapper>
                                <DialogFieldset id='bridge-v5fieldset2'
                                    width={showRRT ? '400px' : '100%'}
                                >
                                <DialogLegend>V5 / NSF</DialogLegend>
                       
                                    <JustifyV5>
                                    <Input
                                        domID="bridge-v5nsfrecordidlength"
                                        className="text-input"
                                        label="Record ID Length:"
                                        maxLength={3}

                                        initialValue={this.props.dataStore.ui.selectedItem['@V5NSFRecordIdLength']}
                                        hasError={!!this.props.dataStore.ui.v5NSFRecordIdLengthError}
                                        errorMessage={this.props.dataStore.ui.v5NSFRecordIdLengthError}

                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateBridge_V5NSFRecordIdLength(e.target.value)} 


                                    />
                                    <Input
                                        domID="bridge-v5nsfrecordlength"
                                        className="text-input"
                                        label="Record Length:"
                                        maxLength={4}

                                        initialValue={this.props.dataStore.ui.selectedItem['@V5NSFRecordLength']}
                                        hasError={!!this.props.dataStore.ui.v5NSFRecordLengthError}
                                        errorMessage={this.props.dataStore.ui.v5NSFRecordLengthError}

                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateBridge_V5NSFRecordLength(e.target.value)} 
                                    />
                
                                    <Input
                                        domID="bridge-v5nsfheaderendrecord"
                                        className="text-input"
                                        label="Header End Record:"
                                        maxLength={4}

                                        initialValue={this.props.dataStore.ui.selectedItem['@V5NSFHeaderEndRecord']}

                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateBridge_V5NSFHeaderEndRecord(e.target.value)} 
                                            />
                                    </JustifyV5>

                                   

                          
                            </DialogFieldset>
                            {showRRT &&
                                <ContentRowWrapper id='bridge-rrtfieldset_wrapper'>

                                    <DialogFieldset id='bridge-rrtfieldset' min-width='250px' width='100%'>
                                        <DialogLegend>RRT Options</DialogLegend>
                                        <ContentRowWrapper>
                                            <CheckBoxComponent
                                                id="bridge-sdrselfpay"

                                                labelAbove='SELF PAY:'
                                                checked={(this.props.dataStore.ui.selectedItem['@SDRType'] === "3" ||
                                                    this.props.dataStore.ui.selectedItem['@SDRType'] === "1")}

                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateBridge_SDRSelfPay(e.target.checked)}
                                                disabled={!this.props.canEdit || stateRRTSelfPay === "disable"}
                                                hasError={stateRRTSelfPay === "error" }
                                            />
                                            <CheckBoxComponent
                                                id="bridge-sdrstateclaims"
                                                labelAbove='STATE CLAIMS:'
                                                checked={(this.props.dataStore.ui.selectedItem['@SDRType'] === "3" ||
                                                    this.props.dataStore.ui.selectedItem['@SDRType'] === "2")}

                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateBridge_SDRStateClaims(e.target.checked)}
                                                disabled={!this.props.canEdit || stateRRTStateClaims === "disable"}
                                                hasError={stateRRTStateClaims === "error"}
                                            />
                                        </ContentRowWrapper>
                                    </DialogFieldset>

                                </ContentRowWrapper>
                            }

                        </ContentRowWrapper>

                        <ContentRowWrapper id='bridge-conditionalfieldset_wrapper' >
                            {allowTenet === '1' && 
                            <ContentRowWrapper id='bridge-desfieldset_wrapper' >
                                <ContentColumnWrapper>
                                        <DialogFieldset id='bridge-desfieldset' width='318px'>
                                        <DialogLegend>DES</DialogLegend>
                                        <SelectDropdown
                                            domID="bridge-desfiletype"
                                            className="dropdown"
                                            label="*Submission:"
                                            disabled={!this.props.canEdit || allowTenet !== '1'}

                                            hasError={(this.props.dataStore.ui.desFileTypeIdError && this.props.dataStore.ui.desFileTypeIdError !== '') ? true : false}
                                            errorMessage={this.props.dataStore.ui.desFileTypeIdError}

                                            isClearable={false}
                                            isSearchable={true}
                                            size='small'
                                            menuWidth={170}
                                            onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateBridge_DESFileTypeID(e.value)}
            
                                            options={this.getDESFileTypeLookupList_ArrayFiltered((!this.props.dataStore.crud.data ||
                                                !this.props.dataStore.crud.data.BridgeMaintenanceInfo
                                                ? null : this.props.dataStore.crud.data.BridgeMaintenanceInfo.DESFileTypeList.DESFileType))}




                                            initialValue={this.getGenericLookupList_SelectedItem(
                                                (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.BridgeMaintenanceInfo
                                                    ? null : this.props.dataStore.crud.data.BridgeMaintenanceInfo.DESFileTypeList.DESFileType),
                                                this.props.dataStore.ui.selectedItem["@DESFileTypeID"])}
                                        />
                                    </DialogFieldset>
                                    </ContentColumnWrapper>
                               </ContentRowWrapper>
                               }
                              
                        </ContentRowWrapper>

                        <SelectButtons>
                            <Button
                                    domID="addButton"
                                    name={this.props.dataStore.ui.selectedItem['@ID'] !== "" ? "Update" : "Add"}
                                    buttonType="emphasized"
                                    size="control"
                                    type="button"
                                    disabled={!this.props.canEdit}

                                    onClick={(e: React.SyntheticEvent) => {
                                        handleChange()
                                        this.onClickModifyButton(e)
                                    }}
                                />
                            <Button
                                    domID="removeButton"
                                    name="Remove"
                                    buttonType="standard"
                                    size="control"
                                    type="button"
                                    disabled={!this.props.canSecCheck1 || this.props.dataStore.ui.selectedItem['@ID'] === ""}

                                    onClick={(e: React.SyntheticEvent) => {
                                        this.onClickRemoveButton(e)
                                    }}
                                />
                        </SelectButtons>


                        </BridgeMaintenanceContent>
                    </ContentRowWrapper>
                </ContentWrapper>
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.bridgeMaintenanceAlert && this.props.dataStore.ui.bridgeMaintenanceAlert.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearAlert(e)}
                    message={this.props.dataStore.ui.bridgeMaintenanceAlert}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClearAlert(e)}
                />
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.bridgeRemoveConfirm && this.props.dataStore.ui.bridgeRemoveConfirm.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearRemoveConfirm(e)}
                    message={this.props.dataStore.ui.bridgeRemoveConfirm}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onRemoveButton(e)}
                />
            </DialogWrapper>
        );
    }
};
    
var connectedHoc = connect<IBridgeMaintenanceState, IBridgeMaintenanceActionProps, IOwnProps, IBridgeMaintenanceProps, ApplicationState>(
    createCrudMapStateToProps('bridgeMaintenance'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(BridgeMaintenance);

export default withRouter(connectedHoc);
