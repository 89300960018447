import * as React from "react";
import { ARMGrid } from "@common/UICLWrappers/ARMGrid";

import { Colors } from "@commonResources/colorVariables";
import styled from "styled-components";
import _, { upperCase } from "lodash";
import { Clear, TextStyles } from '@optum-uicl/ui-core/dist';

import Formatter from "@commonResources/Formatter";


const textHeight = 13;

const tableEdgePaddingForHeaderAndData = 10;

interface IStyledComponentProps {
  styleArry?: string[];
}

const ListContainerDiv = styled.div<IStyledComponentProps>`
  color: ${Colors.black};
  display: flex;
  flex-direction: column;
  align-items: ${(props: any) => getContent(props.searchInputAlignment)};
  justify-content: center;
  .TableListData {
    ${TextStyles.xSmall};
  };
  /*remove empty header created by UICL*/
  * > th:last-child {
    display: none;
  };
  ${(props) => (props.styleArry ? props.styleArry.join("") : "")}

`;

const getContent = (searchInputAlignment: any) => {
  switch (searchInputAlignment) {
    case "left":
      return "flex-start";
    case "center":
      return "center";
    case "right":
      return "flex-end";
    default:
      return "flex-start";
  }
};

const SearchInputLabel = styled.label`
  color: ${Colors.grey100};
  text-transform:uppercase;
  ${TextStyles.xSmallBold};  
  margin-bottom: 5px;
`;

const SearchInput = styled.input`
  color: ${Colors.grey100};
  ${TextStyles.small};
  padding: 9px 8px 8px 10px;
  box-sizing: border-box;
  border: 1px solid #9ba1a9;
  border-radius: 3px;
  box-shadow: inset 0 2px 2px 0 rgb(155 161 169 / 25%);
  width: ${(props) => props.width || "200px"}
  &::placeholder {
    color: ${Colors.grey50};
  }
  &:hover {
    border: 1px solid #757e8a;
  }
  &:focus {
    outline: 0;
    border: 1px solid #2f3237;
  }
`;

const ClearSearchIconWapper = styled.div`
  svg {
    position: absolute;
    right: 8px;
    bottom: 7px;
    cursor: pointer;
    opacity: 0.75;
  }
  svg:hover {
    opacity: 1;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-bottom: 5px;
`;

interface IComponentProps {
  maxHeight?: string;
  columns: any[];
  records: any[];
  domID?: string;
  width?: number;
  supportSelection?: boolean;
  label?: string;
  placeholder?: string;
  searchInputAlignment?: "left" | "center" | "right";
  searchInputWidth?: string;
}

interface IComponentState {
  styleArry: string[];
  sortOrder: string;
  searchKey: string;
}

export default class FilterableTable extends React.Component<
  IComponentProps,
  IComponentState
> {
  inputRefs: { [index: string]: any };

  constructor(props: IComponentProps) {
    super(props);

    const sortableColumns = this.props.columns.filter((c) => {
      return c.sortable;
    });
    this.state = {
      styleArry: [],
      sortOrder: "",
      searchKey: "",
    };
    this.inputRefs = [];
  }

  public getCustomRow = () => {
    const row = ({ record }: any) => {
      const recordId = this.props.domID + "" + record.id;

      return (
        <tr id={recordId} key={recordId}>
          {this.getCustomDataArry(record)}
        </tr>
      );
    };

    return row;
  };

  public getCustomDataArry = (record: any) => {
    const { columns, domID, records, width } = this.props;
    const widthOffset = 14 / records.length;
    return columns.map((c: any) => {
      const recordDataId = domID + "" + record.id + c.dataName;

      let tableDisplayData: any = "";
      if (record[c.dataName] && record[c.dataName].text) {
        tableDisplayData = record[c.dataName].text;
        if (record[c.dataName] && record[c.dataName].formatter) {
          tableDisplayData = Formatter(
            record[c.dataName].formatter,
            tableDisplayData,
            { height: 15, width: 15 }
          );
        }
      }

      return (
        <td
          id={recordDataId}
          key={recordDataId}
          className={"TableListData"}
          style={{
            maxWidth: width
              ? undefined
              : c.width > widthOffset
              ? c.width - widthOffset
              : c.width,
            minWidth: width
              ? undefined
              : c.width > widthOffset
              ? c.width - widthOffset
              : c.width,
            height: textHeight,
            whiteSpace:
              record[c.dataName] && record[c.dataName].isWrap
                ? "none"
                : "nowrap",
            textAlign:
              record[c.dataName] && record[c.dataName].isWrap ? "left" : undefined,
            overflow: "hidden",
            verticalAlign: "middle",
            padding: "4px 0px 4px 4px",
          }}
        >
          {tableDisplayData}
        </td>
      );
    });
  };

  getRightAlignTextStyleFromProps = (props: any) => {
    return `
    *>th:nth-child(${props.nthElement}){
        padding: 0;
        span{
            text-align: right;
            padding: 0;
        }
        button{
            float: right;
            padding: 0;
        }
    }
    *>tr{
        td:nth-child(${props.nthElement}){
            text-align: right;
        }
    }
    `;
  };

  getColumnStyleFromProps = (index: any, columns: any) => {
    const tableWidth = this.props.width;
    const standardDataPadding = 2;
    const width =
      columns.length && tableWidth
        ? tableWidth / columns.length
        : columns[index].width ?? "";
    const paddingRight =
      index === columns.length - 1
        ? tableEdgePaddingForHeaderAndData
        : standardDataPadding;
    const paddingLeft =
      index === 0 ? tableEdgePaddingForHeaderAndData : standardDataPadding;

    const tableChildStyle = `:nth-child(${index + 1}){
                padding: ${standardDataPadding}px !important;
                padding-left: ${paddingLeft}px !important;
                padding-right: ${paddingRight}px !important;
                min-width: ${width}px !important;
                max-width: ${width}px !important;
            }`;

    return `*>th${tableChildStyle}*>td${tableChildStyle}`;
  };

  sortingComparator = (
    sortDataName: string,
    sortDirectionString: string,
    records: any[]
  ) => {
    const columnSortDataType = this.props.columns.find(
      (c) => c.dataName === sortDataName
    )?.SortDataType;
    const sortIndicator = {
      sortColumn: sortDataName,
      sortDirection: sortDirectionString === "SORT_ASCENDING" ? "down" : "up",
      sortDataType: columnSortDataType ?? "String",
    };

    let sortedRecords: any[] = records;

    // sort by string
    if (sortIndicator.sortDataType === "String") {
      if (sortIndicator.sortDirection == "up") {
        sortedRecords = records.sort((a, b) => {
          const x = a[sortIndicator.sortColumn]?.text ?? "";
          const y = b[sortIndicator.sortColumn]?.text ?? "";
          return x.toString().localeCompare(y.toString());
        });
      } else {
        sortedRecords = records.sort((a, b) => {
          const x = a[sortIndicator.sortColumn]?.text ?? "";
          const y = b[sortIndicator.sortColumn]?.text ?? "";
          return y.toString().localeCompare(x.toString());
        });
      }

      // sort by number
    } else if (sortIndicator.sortDataType === "Number") {
      if (sortIndicator.sortDirection == "up") {
        sortedRecords = records.sort((a, b) => {
          const x = (a[sortIndicator.sortColumn]?.text ?? "").replace(
            /[^0-9.]/g,
            ""
          );
          const y = (b[sortIndicator.sortColumn]?.text ?? "").replace(
            /[^0-9.]/g,
            ""
          );
          return parseFloat(x.toString()) - parseFloat(y.toString());
        });
      } else {
        sortedRecords = records.sort((a, b) => {
          const x = (a[sortIndicator.sortColumn]?.text ?? "").replace(
            /[^0-9.]/g,
            ""
          );
          const y = (b[sortIndicator.sortColumn]?.text ?? "").replace(
            /[^0-9.]/g,
            ""
          );
          return parseFloat(y.toString()) - parseFloat(x.toString());
        });
      }

      // sort by date
    } else if (sortIndicator.sortDataType === "Date") {
      const defaultDateString = "01/01/0001";
      if (sortIndicator.sortDirection == "up") {
        sortedRecords = records.sort((a, b) => {
          const x = a[sortIndicator.sortColumn]?.text ?? defaultDateString;
          const y = b[sortIndicator.sortColumn]?.text ?? defaultDateString;
          return (
            new Date((x || defaultDateString).toString()).getTime() -
            new Date((y || defaultDateString).toString()).getTime()
          );
        });
      } else {
        sortedRecords = records.sort((a, b) => {
          const x = a[sortIndicator.sortColumn]?.text ?? defaultDateString;
          const y = b[sortIndicator.sortColumn]?.text ?? defaultDateString;
          return (
            new Date((y || defaultDateString).toString()).getTime() -
            new Date((x || defaultDateString).toString()).getTime()
          );
        });
      }
    }

    return sortedRecords;
  };

  public handleChange = (e: any) => {
    const textInputId = e.currentTarget.id;
    this.setState({ searchKey: e.target.value }, () => {
      this.inputRefs[textInputId].focus();
    });
  };

  public handleSearchButtonClick = (textInputId: string) => {
    this.setState({ searchKey: "" }, () => {
      this.inputRefs[textInputId].focus();
    });
  };

  public filterRecords = () => {
    const unsearchedRecords = this.props.records;
    const searchKey = this.state.searchKey;
    if (searchKey.trim() === "") {
      return unsearchedRecords;
    } else {
      const searchedRecords = unsearchedRecords.reduce(
        (currArry, currentValue) => {
          let result = currArry;
          if (JSON.stringify(currentValue).includes(searchKey)) {
            result = [...result, currentValue];
          }
          return result;
        },
        []
      );
      return searchedRecords;
    }
  };

  render() {
    const {
      maxHeight,
      columns,
      supportSelection,
      placeholder,
      label,
      searchInputAlignment,
      searchInputWidth,
    } = this.props;
    const { searchKey } = this.state;
    const records = this.filterRecords();
    const listWidth = this.props.width;
    const domID =
      (this.props.domID ?? "") +
      Date.now() +
      "" +
      Math.random() +
      "" +
      _.uniqueId();
    const sortableColumns = columns.filter((c) => {
      return c.sortable;
    });

    let styleArry: string[] = ["", ""];
    if (!this.state.styleArry.length && columns.length) {
      columns.map((column, index) => {
        styleArry = [
          ...styleArry,
          this.getColumnStyleFromProps(index, columns),
        ];
        if (column.rightAlignText) {
          styleArry = [
            ...styleArry,
            this.getRightAlignTextStyleFromProps({ nthElement: index + 1 }),
          ];
        }
      });
      this.setState({ styleArry });
    }

    const reconstructedListWidth: number | undefined = columns.length
      ? columns.reduce((result, value) => {
          if (value.width && result !== undefined) {
            return result + value.width;
          } else {
            return undefined;
          }
        }, 0)
      : undefined;

    const componentContent = (
      <ListContainerDiv
        key={domID + "-ListContainerDiv"}
        styleArry={this.state.styleArry}
        //@ts-ignore
        searchInputAlignment={searchInputAlignment}
      >
        <SearchWrapper>
          {label && <SearchInputLabel>{label}</SearchInputLabel>}
          <SearchInput
            width={searchInputWidth}
            id="Filterable-Search-Input"
            ref={(input: any) =>
              (this.inputRefs[`Filterable-Search-Input`] = input)
            }
            type="text"
            placeholder={placeholder || "Enter your search term"}
            {...(label ? { label } : {})}
            value={searchKey}
            disabled={false}
            onChange={(e: any) => this.handleChange(e)}
          />
          <ClearSearchIconWapper
            onClick={() =>
              this.handleSearchButtonClick("Filterable-Search-Input")
            }
          >
            <Clear size={'medium'} />
          </ClearSearchIconWapper>
        </SearchWrapper>
        <ARMGrid
          //supportSelection={supportSelection}
          columns={new Set(columns)}
          isFixedHeader
          maxHeight={maxHeight ? maxHeight : "100%"}
          domID={domID + "-ARMGrid"}
          key={domID + "-ARMGrid"}
          selectionKey={
            sortableColumns.length ? sortableColumns[0].dataName : ""
          }
          initialSortingKey={this.state.sortOrder}
          rowComponent={this.getCustomRow()}
          //onSelectAll = { ()=>console.log("Hello World! onSelectAll")}
          records={records}
          onSortGridColumn={(
            e: React.MouseEvent<HTMLTableHeaderCellElement>,
            sortData: {
              sortingKey: string /*todo there are other props we may need*/;
            }
          ) => {
            this.setState({ sortOrder: sortData.sortingKey });
          }}
          sortingComparator={
            sortableColumns.length ? this.sortingComparator : () => records
          }
        />
      </ListContainerDiv>
    );
    return componentContent;
  }
}
