import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "@store/index";

import {
    IMergeApiComponentProps,
    createApiMapStateToProps,
    createApiMapDispatchToProps,
    mergeApiComponentProps,
    resetApiComponentState,
} from "@scripts/util/ApiDataHelpers";

import {
    IRemitFilterState,
    IRemitFilterActionProps,
    actionCreators,
} from "@store/RemitFilter";

import { RemitFilter, IRemitFilterComponentProps } from "./RemitFilter";

export interface IViewRemitFilterComponentState {
    [key: string]: any
}
export const DEFAULT_STATE: IViewRemitFilterComponentState = {};

interface IOwnProps extends RouteComponentProps <{}, {}, IViewRemitFilterComponentState> {
    canEdit: boolean, 
    canView: boolean, 
    canCreate: boolean,
    ncsIsEnabled: boolean,
    process: string,
    title: string,
    instruction: string,
    execProcess: string,
    execNode: string,
    execAction: string,
};
type IRemitFilterProps = IMergeApiComponentProps<
    IRemitFilterState,
    IRemitFilterActionProps,
    IOwnProps 
>;

export class RemitPageFactory extends React.Component<
    IRemitFilterProps,
    IViewRemitFilterComponentState
    > {
    static defaultProps: any = {
        // FROM: remit/filter.asp
        canEdit: false, // permission
        canView: false, // security clearance
        canCreate: false, // save button -
        ncsIsEnabled: false,
        process: "", // Process
        title: "", // RemitTitle
        instruction: "", // from Instruction
        execProcess: "", // ExecProcess
        execNode: "", //  ExecNode
        execAction: "", // ExecAction
    };

    constructor(props: IRemitFilterProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            const alertMessage =
                "User did not have rights to " + this.props.title || "view this page.";
            alert(alertMessage);
            this.props.history.push("/LandingPage");
        }
    }

    public componentWillUnmount() {
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public generateFilterProps = (props: any) => {
        const clone = { ...RemitPageFactory.defaultProps, ...props };
        
        switch (props.location.pathname) {
            case "/Remits/Filter/retrieve835":
                clone.title = "Retrieve 835";
                clone.process = "retrieve835";
                clone.instruction =
                    "Use the Advanced Filter to enter criteria until you have created the most precise set of requirements. Click Refine to check the group of remits before you continue. Click Continue to retrieve remits without viewing the remit list.";
                break;
            case "/Remits/Filter/View":
                clone.title = "View Remits";
                clone.process = "view";
                break;
            case "/Remits/Filter/match":
            case "/Remits/Filter/Match":
                clone.title = "Match Remits";
                clone.process = "match";
                clone.instruction =
                    "Use the Quick Start tab to choose an existing filter or enter a quick filter, use the Advanced tab to set up a new filter. Then click Refine to see a list of remits in the working group before using them. Save is active only from the Advanced tab.";
                break;
            case "/Remits/Filter/postingfile":
                clone.title = "Create Posting File";
                clone.process = "postingfile";
                clone.instruction =
                    "Use the Advanced Filter to enter criteria until you have created the most precise set of requirements. Click Refine to check the group of remits before you continue. Click Continue to retrieve remits without viewing the remit list.";
                break;
            case "/Remits/Filter/postingfilebypayer":
                clone.title = "Create Posting File";
                clone.process = "postingfilebypayer";
                clone.instruction =
                    "Use the Advanced Filter to enter criteria until you have created the most precise set of requirements. Click Refine to check the group of remits before you continue. Click Continue to retrieve remits without viewing the remit list.";
                break;
            case "/Remits/Filter/postingfilebypayment":
                clone.title = "Create Posting File";
                clone.process = "postingfilebypayment";
                clone.instruction =
                    "Use the Advanced Filter to enter criteria until you have created the most precise set of requirements. Click Refine to check the group of remits before you continue. Click Continue to retrieve remits without viewing the remit list.";
                break;
            case "/Remits/Filter/viewx12remit":
                clone.title = "X12 Viewer";
                clone.process = "viewx12remit";
                clone.instruction =
                    "Use the Advanced Filter to enter criteria to locate the Remit file you wish to View. Only one remit file can be selected and viewed. Click Refine to view list of filter results, or click Continue to view file.";
                break;
            case "/Remits/Filter/CreateReport":
                clone.parameter = this.props.history.location.state?.parameter ?? {
                    ReportRequests: {
                        "@Type": "",
                        ReportRequest: [],
                    },
                };

                clone.title = "Remit Report";
                clone.process = this.props.history.location.state?.process ?? "remit";
                clone.execProcess =
                    this.props.history.location.state?.process ?? "remit";
                clone.execAction = this.props.history.location.state?.execAction;
                clone.execNode =
                    this.props.history.location.state?.execNode ?? "Report";
                break;
            default:
                break;
        }

        return clone;
    };

    public render() {
        const props = this.generateFilterProps(this.props);

        return (
            <div>
                <RemitFilter {...props} />
            </div>
        );
    }
}

const connectedHoc = connect<
    IRemitFilterState,
    IRemitFilterActionProps,
    IOwnProps,
    IRemitFilterProps,
    ApplicationState
>(
    createApiMapStateToProps("remitFilter"),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(RemitPageFactory);

export default withRouter(connectedHoc);
