import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AssurancePlaceholder, { IAssurancePlaceholderProps } from './AssurancePlaceholder';
import AdSpace, { IAdSpaceProps } from './AdSpace';
import MyWorkProductivity from './MyWorkProductivity';
import './css/LandingPage.css';
import { ARMComponentLoading } from './ARMComponentLoading';
import { IComponentProps } from './LandingPageComponent';
import RAPTitle from './RAPTitle';
import AssuranceHeader from './AssuranceHeader';
import { LandingPageContainerLegacy, LandingPageContainer, LandingPageTop, LandingPageBody, LandingPageComponentsLegacy, LandingPageComponents, LandingPageBottom } from './styles/LandingPageContainer';
import RAPQuickLinks from './RAPQuickLinks';
import ARMTopSection from './ARMTopSection';
import ARMClaimCreationState from './ARMClaimCreationState';
import UnReleasedClaimAge from './UnReleasedClaimAge';
import ARMMessageComponent from './ARMMessageComponent';
import MyClaimsComponent from './MyClaimsComponent';
import ARMQuickSearch from './ARMQuickSearch';
import { URLs } from '@commonDevResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';


//var testit = require('../../scripts/test');

interface ILoadComponentProps {
    url?: string;
}

interface IState {
    data?: ILandingPageData | ILegacyHomePageData;
}

interface IDivStyle {
    order: number;
    font: string;
};

type IComponent = IComponentProps & IAssurancePlaceholderProps & IAdSpaceProps;// & RouteComponentProps<{}>;

//these functions will have to be refactored or eliminated alltogether
const isItemDefined = (props: IProps) => { return props.url };       //LCR-02
const evalTarget = () => console.log('!evalTarget');          //LCR-02

type IProps = ILoadComponentProps & RouteComponentProps<{}>;
interface ILandingPageData {
    LandingPageTop: [],
    LandingPageBody: [],
    LandingPageComponents: []
}

interface ILegacyHomePageData {
    LegacyHomePage: IComponent[];
}

export default class LoadComponents extends React.Component<IProps, IState>
{
    static defaultProps: ILoadComponentProps = {
        url: URLs.api + "/api/data/GetUIComponents?ComponentGroupId=1&UserRole=0",
    };

    public componentCount: number = 0;
    public IconsView: boolean = false;

    constructor(props: IProps) {
        super(props);
        this.state =
        {
            data: undefined
        };
        this.componentCount = 0;
        this.IconsView = false;
    };

    loadComponentsFromServer(): any {


        const myState: IState = this.state;

        //if (!isItemDefined(this.props.url) || !isItemDefined(this.props.url)) {
        if (!isItemDefined(this.props)) {
            return null;
        }

        if (this.props.url) {
            var _this = this;
            var xhr = new XMLHttpRequest();
            xhr.open('get', this.props.url, true);
            xhr.setRequestHeader('Authorization', getRawToken());
            xhr.onload = function () {
                var data = JSON.parse(xhr.responseText);
                myState.data = data;
                _this.setState({
                    data: myState.data
                });
            };
            xhr.send();
        }
        return null;
    }

    componentDidMount() {
        this.loadComponentsFromServer();
    }

    createLandingPageElement(component: IComponent) {
        var element = (window as any)[component.Name];
        // LCR-0329 Added the RAPTtile to the switch statement to load the title component
        // LCR-0418 COMMENTED LINE 128 TO COMPILE
        // LCR-0418 COMMENTED LINE 132 TO COMPILE
        switch (component.Name) {
            case "RAPAdSpace":
                //        console.log(component.Name + ' should have been generated without switch');
                this.componentCount++;
                return (<AdSpace key={this.componentCount} {...component} />);
            case "RAPTitle":
                //      console.log(component.Name + ' should have been made the other way - LCR-0329');
                this.componentCount++;
                return (<RAPTitle key={this.componentCount} {...component} />);
            case "RAPTopSection":
                //       console.log(component.Name + ' should have been generated without switch');
                this.componentCount++;
                return (<ARMTopSection key={this.componentCount} {...component} {...this.props} />);
            case "AssuranceHeader":
                //        console.log(component.Name + ' should have been made the other way - LCR-0329');
                this.componentCount++;
                return (<AssuranceHeader key={this.componentCount} {...component} />);
            case "RAPWorkProductivity":
                //        console.log(component.Name + ' should have been generated without switch');
                this.componentCount++;
                return (<MyWorkProductivity key={this.componentCount} {...component} />);
            case "RAPUnreleasedClaimAge":
                //        console.log(component.Name + ' should have been generated without switch');
                this.componentCount++;
                return (<UnReleasedClaimAge key={this.componentCount} {...this.props} {...component} />);
            case "RAPQuickLinks":
                //       console.log(component.Name + ' should have been generated without switch');
                this.componentCount++;
                return (<RAPQuickLinks displayAsIcons={false} key={this.componentCount} {...component} {...this.props} />);
            case "RAPClaimCreationState":
                //        console.log(component.Name + ' should have been generated without switch');
                this.componentCount++;
                return (<ARMClaimCreationState key={this.componentCount} {...component} {...this.props} />);
            case "RAPMyClaims":
                //       console.log(component.Name + ' should have been generated without switch');
                this.componentCount++;
                return (<MyClaimsComponent key={this.componentCount} {...component}  {...this.props} />);
        }
        if (element !== undefined) {

            //        console.log(component.Name + ' is a valid element');
            this.componentCount++;
            return (React.createElement(element, { ...{ key: this.componentCount }, ...component }));
        }
        else {
            this.componentCount++;
            return (<AssurancePlaceholder key={this.componentCount} {...component} />);
        }
    }

    createLegacyHomepageElement(component: IComponent) {
        var element = (window as any)[component.Name];
        //     console.log("should have element");

        // LCR-0329 Added the RAPTtile to the switch statement to load the title component
        // LCR-0418 COMMENTED LINE 128 TO COMPILE
        // LCR-0418 COMMENTED LINE 132 TO COMPILE
        switch (component.Name) {
            case "RAPQuickLinks":
                this.componentCount++;
                return (<RAPQuickLinks displayAsIcons={true} key={this.componentCount} {...component} {...this.props} />);
            case "MessageAlerts":
                this.componentCount++;
                //return (<AssurancePlaceholder key={this.componentCount} {...component} />);
                return (<ARMMessageComponent key={this.componentCount} {...component} />);
            case "ARMQuickSearch":
                this.componentCount++;
                return (<ARMQuickSearch renderPanel={true} key={this.componentCount} {...component} {...this.props} />);
            case "ARMMyClaims":
                this.componentCount++;
                //return (<AssurancePlaceholder key={this.componentCount} {...component} />);
                return (<MyClaimsComponent key={this.componentCount} {...component} />);
            //return (<MyClaims key={this.componentCount} {...component} />);            
        }
        if (element !== undefined) {
            this.componentCount++;
            return (React.createElement(element, { ...{ key: this.componentCount }, ...component }));
        }
        else {
            this.componentCount++;
            return (<AssurancePlaceholder key={this.componentCount} {...component} />);
        }
    }

    isDataLegacyHomePage(data: any): data is ILegacyHomePageData {
        return (data as ILegacyHomePageData).LegacyHomePage !== undefined;
    }

    render() {
        if (this.state.data != undefined) {
            if (this.isDataLegacyHomePage(this.state.data)) {
                return this.renderLegacyHomepage();
            }
            else {
                return this.renderLayout();
            }
        }
        return (<LandingPageContainer><ARMComponentLoading /></LandingPageContainer>);
    }

    renderLegacyHomepage() {
        if (this.state.data != undefined) {

            var Order = 0;
            this.componentCount = 0;
            const myProps: IProps = this.props;
            //if  ( !isItemDefined(this.props)) //|| ( this.state.data.LandingPageComponents.length === 0 )
            //{ 
            //     // window.location("/Home/UserHomeLegacy.asp");
            //     return null;
            //} 

            var items = (this.state.data as ILegacyHomePageData);

            var legacyPageComponents = items.LegacyHomePage.map((component: IComponent) => {
                return this.createLegacyHomepageElement(component);
            });

            const divStyle: IDivStyle = {
                order: Order + 1,
                font: 'none'
            };
            return (<LandingPageContainerLegacy>
                {/* <LandingPageTop></LandingPageTop> 
                <LandingPageBody>*/}
                <LandingPageComponentsLegacy>{legacyPageComponents}</LandingPageComponentsLegacy>
                {/*   </LandingPageBody>
                <LandingPageBottom /> */}
            </LandingPageContainerLegacy>);

        }
        else {
            const divStyle: IDivStyle = {
                order: 1,
                font: 'none'
            };
            return (<LandingPageContainer><ARMComponentLoading /></LandingPageContainer>);
        }
    }

    renderLayout() {
        //if ( isItemDefined( this.props/*, this.state.data*/ ))
        if (this.state.data != undefined) {
            var Order = 0;
            //if ( !isItemDefined(this.props)) //|| ( this.state.data.LandingPageComponents.length === 0 )
            //{
            //    // window.location("/Home/UserHomeLegacy.asp");
            //    return null;
            //}

            var items = (this.state.data as ILandingPageData);

            this.componentCount = 0;
            const myProps: IProps = this.props;
            var landingPageTop = items.LandingPageTop.map((component: IComponent) => {
                return this.createLandingPageElement(component);
            });

            var landingPageBody = items.LandingPageBody.map((component: IComponent) => {
                return this.createLandingPageElement(component);
            });

            var landingPageComponents = items.LandingPageComponents.map((component: IComponent) => {
                return this.createLandingPageElement(component);
            });

            const divStyle: IDivStyle = {
                order: Order + 1,
                font: 'none'
            };
            return (<LandingPageContainer>
                <LandingPageTop>{landingPageTop}</LandingPageTop>
                <LandingPageBody>{landingPageBody}
                    <LandingPageComponents>{landingPageComponents}</LandingPageComponents>
                </LandingPageBody>
                <LandingPageBottom />
            </LandingPageContainer>);

        }
        else {
            const divStyle: IDivStyle = {
                order: 1,
                font: 'none'
            };
            return (<LandingPageContainer><ARMComponentLoading /></LandingPageContainer>);
        }
        //return (<div id="chartdiv" style={{ height: '400px', width: '300px' }}></div>);
    }
};
