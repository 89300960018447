import * as React from 'react';
import styled from 'styled-components';

import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { SelectComponent } from '../../common/SelectComponent';


export interface ILobListProps {
    title: string;                                          // title of buttons
    forms: APICF_SimpleList[] | any[] | undefined;
    selectedValues: string[] | undefined;
    width?: string;                                         // width for your screen
    height?: string;
    className?: string;                                     // classname if needed
    disabled?: boolean;
    optionFields?: any;
    onSelect?: React.ChangeEventHandler<HTMLSelectElement>;  // action taken 
};

export interface ILobListState {
    disabled: boolean;
};

/* was 
 * .{this.props.title + '_select} option {
            padding: 0px 2px 0px 2px !important;
            min-height: 1.2em;
            display: block;
            margin: 0 !important;
        }
* but no way that was working (caused error in jest test)
 */
const LobWrapper = styled.div`
 option:hover,
    option:checked,
    option:active,
    option:focus {
    background: #CDDDFE;
    position: relative;
    --webkit-appearance: none;
    }
    select:focus option:checked{
        background-color: #E9F1FF !important;
        color: #002677;  
    }
    select#claimfilter_loblist_select option:checked{
        background-color: #E9F1FF !important;
        color: #002677; 
    }

    select:focus > option:checked:before {
    color: #002677;
    position: absolute;
    content: attr(data-content);
    }
`;



export class LobList extends React.Component<ILobListProps, ILobListState>
{
    static defaultProps: ILobListProps = {
        title: "LobList",
        forms: undefined,
        selectedValues: undefined,
        width: '170px',
        height: '130px',
        className: 'loblist',
        optionFields: {
            value: "ID",
            text: "Name"
        },
        disabled: false
    };

    constructor(props: ILobListProps) {
        super(props);
    }


    render() {


        return (
            <LobWrapper className='lobwrapper'>
                <DialogFieldset id={this.props.title + '_fieldset'} className={this.props.className}>
                    <DialogLegend>LOB</DialogLegend>
                    <SelectComponent
                        title={this.props.title + '_select'}
                        className={this.props.title + '_select'}
                        width={this.props.width ? this.props.width : '170px'}
                        height={this.props.height ? this.props.height : '125px'}
                        multiple='true'
                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.onSelect ? this.props.onSelect(e) : () => { console.log(this.props.title + ' onSelect missing') }}
                        optionFields={this.props.optionFields}
                        records={this.props.forms}
                        disabled={this.props.disabled }
                        selectedMultiValue={this.props.selectedValues}>
                    </SelectComponent>
                </DialogFieldset>
            </LobWrapper>


        );
    }
};