import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';

import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { SelectComponent } from '@common/SelectComponent';
import { ArrowButtons } from '@common/ArrowButtons';
import { UpDownArrowButtons } from '@common/UpDownArrowButtons';
import { UpDownArrowButtonsRow } from '@common/UpDownArrowButtonsRow';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';

import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IFavoriteFilterState, IFavoriteFilterActionProps, actionCreators, validationCallback } from '@store/FavoriteFilter';
import { defaultState as defaultUIState, FilterType, ISelectFilterUserData, ISelectFilterGroupData, defaultFilterGroup, defaultFilterUser, defaultPutFavorites } from '@store/ui/FavoriteFilterUI';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '@common/AssuranceMenu';
import {ICookies_Config} from "@store/ConfigData";
import { TextStyles } from "@optum-uicl/ui-core/dist";

export const SelectList = styled.div`
`;

export const ContentRowWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const ContentColumnWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    #HomePageList-Id {
        margin-left: 42px;
        padding: 5px 5px 0px 5px;
    }

    #HomePageArrow-Buttons {
        margin-top:1px;
        margin-left: 100px;
    }

    #HomePageSEQArrow-Buttons {
        margin-top: 35px;
        margin-left:-7px;
    }

     #savedFilter-id {
        margin-left: 10px;
    }

    #FavArrow-Buttons {
      margin-top: 50px;  
    }
`;

export const ContentArrowColumnWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 34px
`;

export const SelectActions = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
`;

export const FilterErrorMessage = styled.span`
  width: 300px;
  display: block;
  color: ${Colors.digitalRed130};
  ${TextStyles.xSmall};
`;

interface IComponentProps {
    homePageMaxFilters: number,
};

interface IComponentState {
    cancelLeave: boolean
}

export const DEFAULT_STATE: IComponentState = {cancelLeave: false};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IFavoriteFilterProps = IMergeCrudComponentProps<IFavoriteFilterState, IFavoriteFilterActionProps, IOwnProps>;

class FilterInfo {
    id!: string;
    name!: string;
}

export class FavoriteFilter extends React.Component<IFavoriteFilterProps, IComponentState> {

    private initFavoriteFilter: MCFavoriteFilter = {
        '@ID': '',
        '@Name': '',
    };

    private initHomePageFilter: MCHomePageFilter = {
        "@ID": '',
        "@Name": '',
        "@Sequence": ''
    }

    private initSavedFilter: MCSavedFilter = {
        '@ID': '',
        '@Name': '',
    };

    static defaultProps: IComponentProps = {
        homePageMaxFilters: 5,
    };

    constructor(props: IFavoriteFilterProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    private setInitialLoad() {
        this.initFilterUsers();
        this.initFilterGroups();
    }
    
    public componentDidMount() {
        pageLeave();
        this.props.action.crud.get({ crudId: CrudTypes.mctiAssignFavoriteFilterMaster }, validationCallback);
        this.props.action.configData.getConfigData({ cookies: [], config: [{ name: "HomePageFavMaxFilters" }] });
        this.setInitialLoad();
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }
   
    public isSelectorHasFavorites(selector: any): boolean {
        let selectorHasFavorites: boolean = false;
        if (selector) {
            if (selector.Favorites && selector.Favorites.Favorite && selector.Favorites.Favorite.length > 0) {
                selectorHasFavorites = true;
            }
        }
        return selectorHasFavorites;
    }

    public isSelectorHasHomePages(selector: any): boolean {
        let selectorHasHomePages: boolean = false;
        if (selector) {
            if (selector.HomePages && selector.HomePages.HomePage && selector.HomePages.HomePage.length > 0) {
                selectorHasHomePages = true;
            }
        }
        return selectorHasHomePages;
    }

    public selectorFavorites(selector: any): MCFavoriteFilter[] {
        let favoriteFilters: MCFavoriteFilter[] = [];
        if (this.isSelectorHasFavorites(selector)) {
            return selector.Favorites.Favorite;
        }
        return favoriteFilters;
    }

    public selectorHomePages(selector: any): MCHomePageFilter[] {
        let homePageFilters: MCHomePageFilter[] = [];
        if (this.isSelectorHasHomePages(selector)) {
            return selector.HomePages.HomePage;
        }
        return homePageFilters;
    }

    public deletedFavoriteFilters(oselector: any, dselector: any): MCFavoriteFilter[] {
        let favoriteFilters: MCFavoriteFilter[] = [];

        let oselectorHasFavorites: boolean = this.isSelectorHasFavorites(oselector);
        let dselectorHasFavorites: boolean = this.isSelectorHasFavorites(dselector);

        if (oselectorHasFavorites || dselectorHasFavorites) {

            let oFavFilters: MCFavoriteFilter[] = this.selectorFavorites(oselector);
            let dFavFilters: MCFavoriteFilter[] = this.selectorFavorites(dselector);

            // original has more filters than delta filters remove delta filters from original
            // return deleted original filters 
            favoriteFilters = this.deletedFilters(oFavFilters, dFavFilters); 
        }
        return favoriteFilters;
    }
    
    public objectHasChange(original: any, delta: any): boolean {
        if (original && delta && original["@ID"] === delta["@ID"] && original["@Name"] === delta["@Name"]) {
            if (original.Favorites || delta.Favorites) {
                if ((original.Favorites.Favorite && original.Favorites.Favorite.length) ||
                    (delta.Favorites.Favorite && delta.Favorites.Favorite.length)) {

                    let orgFavorites: MCFavoriteFilter[] = original.Favorites.Favorite as MCFavoriteFilter[];
                    let deltaFavorites: MCFavoriteFilter[] = delta.Favorites.Favorite as MCFavoriteFilter[];

                    let orgFavoritesLength = (!orgFavorites) ? 0 : ((orgFavorites.length > 0) ? orgFavorites.length : 0);
                    let deltaFavoritesLength = (!deltaFavorites) ? 0 : ((deltaFavorites.length > 0) ? deltaFavorites.length : 0);

                    if (orgFavoritesLength != deltaFavoritesLength) {
                        return true;
                    } else {
                        if (orgFavoritesLength !== 0 && deltaFavoritesLength !== 0) {

                            let favDifference = orgFavorites.filter(oflt => delta.Favorites.Favorite.indexOf(oflt) > 0);

                            if (favDifference && favDifference.length > 0) {
                                return true;
                            }
                        }
                    }
                }
            }

            if (original.HomePages || delta.HomePages) {
                if ((original.HomePages.HomePage && original.HomePages.HomePage.length) ||
                    (delta.HomePages.HomePage && delta.HomePages.HomePage.length)) {

                    let orgHomePages: MCHomePageFilter[] = original.HomePages.HomePage as MCHomePageFilter[];
                    let deltaHomePages: MCHomePageFilter[] = delta.HomePages.HomePage as MCHomePageFilter[];

                    let orgHomePagesLength = (!orgHomePages) ? 0 : ((orgHomePages.length > 0) ? orgHomePages.length : 0);
                    let deltaHomePagesLength = (!deltaHomePages) ? 0 : ((deltaHomePages.length > 0) ? deltaHomePages.length : 0);

                    if (orgHomePagesLength != deltaHomePagesLength) {
                        return true;
                    } else {
                        if (orgHomePagesLength !== 0 && deltaHomePagesLength !== 0) {

                            let pgeDifference = orgHomePages.filter(opge => delta.HomePages.HomePage.indexOf(opge) > 0);

                            if (pgeDifference && pgeDifference.length > 0) {
                                return true;
                            }

                            if (this.isHomePageSequenceChanged(orgHomePages, deltaHomePages)) {
                                return true;
                            }
                        }
                    }

                    
                }                    
            }
        }
        return false;
    }

    // Newly Added Favorite Filters
    public buildAddedFavoirteFilters(original: any, delta: any): MCFavoriteFilter[] {
        let diffFavoriteFilters: MCFavoriteFilter[] = [this.initFavoriteFilter];
        if (this.isSelectorHasFavorites(original) || this.isSelectorHasFavorites(delta)) {

            let originalFavoirtes = this.selectorFavorites(original);
            let deltaFavorites = this.selectorFavorites(delta);

            diffFavoriteFilters = this.addedFilters(originalFavoirtes, deltaFavorites);
        }
        //console.log(diffFavoriteFilters,"buildAddedFavoirteFilters")
        return diffFavoriteFilters;
    }

    // Returns deletedFilters = originalFilters - deltaFilters
    public deletedFilters(originalFilters: any[], deltaFilters: any[]): any[] {
        let filteredFavorites: any[] = [{ '@ID': '', "@Name": ''}];
        if ((originalFilters && originalFilters.length > 0) || (deltaFilters && deltaFilters.length > 0)) {
            for (let oIndex = 0; oIndex < originalFilters.length; oIndex++) {
                let filterIndex: number = deltaFilters.findIndex(fflt => fflt["@ID"] === originalFilters[oIndex]["@ID"]);
                if (filterIndex === -1) {
                    filteredFavorites.push(originalFilters[oIndex]);
                }
            }
        }
        filteredFavorites = filteredFavorites.filter(flt => flt["@ID"] !== "");

        return filteredFavorites;
    }

    // Returns addedFilters = deltaFilters - originalFilters
    public addedFilters(originalFilters: any[], deltaFilters: any[]): any[] {
        let newFilters: any[] = [{ '@ID': '', '@Name': '' }];

        if ((originalFilters && originalFilters.length > 0) || (deltaFilters && deltaFilters.length > 0)) {
            for (let dIndex = 0; dIndex < deltaFilters.length; dIndex++) {
                let filterIndex: number = originalFilters.findIndex(oflt => oflt["@ID"] === deltaFilters[dIndex]["@ID"]);
                if (filterIndex === -1) {
                    newFilters.push(deltaFilters[dIndex]);
                }
            }
        }

        newFilters = newFilters.filter(flt => flt["@ID"] !== "");

        return newFilters;
    }
    
    // Deleted Favorite Filters
    public buildDeletedFavoirteFilters(original: any, delta: any): MCFavoriteFilter[] {
        let diffFavoriteFilters: MCFavoriteFilter[] = [this.initFavoriteFilter];
        if (this.isSelectorHasFavorites(original) || this.isSelectorHasFavorites(delta)) {

            let originalFavoirtes = this.selectorFavorites(original);
            let deltaFavorites = this.selectorFavorites(delta);

            diffFavoriteFilters = this.deletedFilters(originalFavoirtes, deltaFavorites);
        }

        diffFavoriteFilters = diffFavoriteFilters.filter(flt => flt["@ID"] !== "");

        return diffFavoriteFilters;
    }

    // All Favorite Filters
    public buildAllFavoirteFilters(original: any, delta: any): MCFavoriteFilter[] {
        let diffFavoriteFilters: MCFavoriteFilter[] = [this.initFavoriteFilter];

        if (this.isSelectorHasFavorites(original) || this.isSelectorHasFavorites(delta)) {
            diffFavoriteFilters = diffFavoriteFilters.concat(this.buildAddedFavoirteFilters(original, delta));
            diffFavoriteFilters = diffFavoriteFilters.concat(this.buildDeletedFavoirteFilters(original, delta));
        }

        diffFavoriteFilters = diffFavoriteFilters.filter(flt => flt["@ID"] !== "");

        return diffFavoriteFilters;
    }

    // Newly Added Home Page Filters
    public buildAddedHomePages(original: any, delta: any): MCHomePageFilter[] {
        let diffHomePages: MCHomePageFilter[] = [this.initHomePageFilter];
        if (this.isSelectorHasHomePages(original) || this.isSelectorHasHomePages(delta)) {

            let originalPages = this.selectorHomePages(original);
            let deltaPages = this.selectorHomePages(delta);

            diffHomePages = this.addedFilters(originalPages, deltaPages);
        }

        diffHomePages = diffHomePages.filter(pge => pge["@ID"] !== "");

        return diffHomePages;
    }

    // Deleted Home Pages
    public buildDeletedHomePages(original: any, delta: any): MCHomePageFilter[] {
        let diffHomePages: MCHomePageFilter[] = [this.initHomePageFilter];
        if (this.isSelectorHasHomePages(original) || this.isSelectorHasHomePages(delta)) {

            let originalPages = this.selectorHomePages(original);
            let deltaPages = this.selectorHomePages(delta);

            diffHomePages = this.deletedFilters(originalPages, deltaPages);
        }

        diffHomePages = diffHomePages.filter(pge => pge["@ID"] !== "");

        return diffHomePages;
    }

    // All Home Pages
    public buildAllHomePages(original: any, delta: any): MCHomePageFilter[] {
        let diffHomePages: MCHomePageFilter[] = [this.initHomePageFilter];

        if (this.isSelectorHasHomePages(original) || this.isSelectorHasHomePages(delta)) {
            diffHomePages = diffHomePages.concat(this.buildAddedHomePages(original, delta));
            diffHomePages = diffHomePages.concat(this.buildDeletedHomePages(original, delta));
        }

        diffHomePages = diffHomePages.filter(pge => pge["@ID"] !== "" && pge["@Name"] !== "");

        return diffHomePages;
    }

    // check for sequence update
    public isHomePageSequenceChanged(oHomePages: MCHomePageFilter[], dHomePages: MCHomePageFilter[]): boolean {
        let isSequenceChanged: boolean = false;

        if (dHomePages && dHomePages.length > 0 && oHomePages && oHomePages.length > 0) {
            for (let index = 0; index < dHomePages.length; index++) {
                let dPage: MCHomePageFilter = dHomePages[index];
                if (dPage) {
                    let oPageIndex: number = oHomePages.findIndex(pge => pge["@ID"] === dPage["@ID"]);
                    if (oPageIndex !== -1) {
                        let oPage: MCHomePageFilter = oHomePages[oPageIndex];
                        if (oPage && oPage["@ID"] === dPage["@ID"]) {
                            if (oPage["@Sequence"] !== dPage["@Sequence"]) {
                                isSequenceChanged = true;
                                break;
                            }
                        }
                    }
                }
            }
        }

        return isSequenceChanged;
    }

    private insertPutARMFilter(filter: MCPutFavoriteFilter) {
        if (filter && filter["@AssignID"] !== "" && filter["@FilterID"] !== "") {
            
            if (this.props.dataStore.ui.putFavorites.AssignFavorites.Favorites.Favorite) {
                if (this.props.dataStore.ui.putFavorites.AssignFavorites.Favorites.Favorite.length > 0) {
                    let filterIndex = this.props.dataStore.ui.putFavorites.AssignFavorites.Favorites.Favorite.findIndex(flt => (flt["@AssignID"] === filter["@AssignID"] && flt["@FilterID"] === filter["@FilterID"]));
                    if (filterIndex === -1) {
                        this.props.dataStore.ui.putFavorites.AssignFavorites.Favorites.Favorite.push(filter);
                    } else {
                        if (this.props.dataStore.ui.putFavorites.AssignFavorites.Favorites.Favorite[filterIndex]["@Delete"] !== filter["@Delete"]) {
                            this.props.dataStore.ui.putFavorites.AssignFavorites.Favorites.Favorite.push(filter);
                        }
                    }
                } else {
                    this.props.dataStore.ui.putFavorites.AssignFavorites.Favorites.Favorite.push(filter);
                }
            }
        }
    }
    
    public buildPutARMFilter(original: MCARMFilter, delta: MCARMFilter) {
        if (this.props.dataStore.ui) {
            if (!this.props.dataStore.ui.putFavorites) {
                this.props.dataStore.ui.putFavorites = {
                    AssignFavorites: {
                        Favorites: {
                            Favorite: [
                                {
                                    "@AssignType": '',
                                    "@AssignID": '',
                                    '@FilterID': '',
                                    "@FilterName": '',
                                    "@Delete": '',
                                    "@Sequence": ''
                                }]
                        }
                    }
                };
            } else {
                this.props.dataStore.ui.putFavorites.AssignFavorites.Favorites.Favorite.slice();
            }
        }

        if (original && original.AssignFavorites && original.AssignFavorites.FilterList &&
            original.AssignFavorites.Users && original.AssignFavorites.Groups &&
            delta.AssignFavorites.FilterList && delta.AssignFavorites.Users && delta.AssignFavorites.Groups) {

            // Working on Users
            let originalUserList = original.AssignFavorites.Users.User;
            let deltaUserList = delta.AssignFavorites.Users.User;

            if (originalUserList && deltaUserList && originalUserList.length === deltaUserList.length) {
                for (let oIndex = 0; oIndex < originalUserList.length; oIndex++) {
                    let ouser: MCFilterUser = originalUserList[oIndex];
                    let dUserIndex: number = deltaUserList.findIndex(dusr => dusr["@ID"] === ouser["@ID"]);
                    if (dUserIndex !== -1) {
                        let duser = deltaUserList[dUserIndex];
                        if (duser) {
                            if (this.objectHasChange(ouser, duser)) {
                                // Handle Newly Added Favorite Filters
                                var newFavoriteFilters: MCFavoriteFilter[] = this.buildAddedFavoirteFilters(ouser, duser);
                                if (newFavoriteFilters && newFavoriteFilters.length > 0) {
                                    for (let afIndex = 0; afIndex < newFavoriteFilters.length; afIndex++) {
                                        let savedFilterObject: MCSavedFilter = this.getFilterOjbectByID(newFavoriteFilters[afIndex]["@ID"]);
                                        let filter: MCPutFavoriteFilter = {
                                            "@AssignType": 's',
                                            "@AssignID": duser["@ID"],
                                            '@FilterID': savedFilterObject["@ID"],
                                            "@FilterName": savedFilterObject["@Name"],
                                            "@Delete": '',
                                            "@Sequence": '0'
                                        };

                                        this.insertPutARMFilter(filter);
                                    }
                                }

                                // Handle Deleted Favoirte Filters
                                let deletedFavoriteFilters: MCFavoriteFilter[] = this.buildDeletedFavoirteFilters(ouser, duser);
                                if (deletedFavoriteFilters && deletedFavoriteFilters.length > 0) {
                                    for (let dfIndex = 0; dfIndex < deletedFavoriteFilters.length; dfIndex++) {
                                        let savedFilterObject: MCSavedFilter = this.getFilterOjbectByID(deletedFavoriteFilters[dfIndex]["@ID"]);
                                        let filter: MCPutFavoriteFilter = {
                                            "@AssignType": 's',
                                            "@AssignID": duser["@ID"],
                                            '@FilterID': savedFilterObject["@ID"],
                                            "@FilterName": savedFilterObject["@Name"],
                                            "@Delete": 'true',
                                            "@Sequence": '0'
                                        };

                                        this.insertPutARMFilter(filter);
                                    }
                                }

                                // Handle Newly Added Home Page Filters
                                let newHomePageFilters: MCHomePageFilter[] = this.buildAddedHomePages(ouser, duser);
                                if (newHomePageFilters && newHomePageFilters.length > 0) {
                                    for (let ahIndex = 0; ahIndex < newHomePageFilters.length; ahIndex++) {
                                        let savedFilterObject: MCSavedFilter = this.getFilterOjbectByID(newHomePageFilters[ahIndex]["@ID"]);
                                        let filter: MCPutFavoriteFilter = {
                                            "@AssignType": 's',
                                            "@AssignID": duser["@ID"],
                                            '@FilterID': savedFilterObject["@ID"],
                                            "@FilterName": savedFilterObject["@Name"],
                                            "@Delete": '',
                                            "@Sequence": newHomePageFilters[ahIndex]["@Sequence"] ? newHomePageFilters[ahIndex]["@Sequence"] : (ahIndex + 1).toString()
                                        };

                                        this.insertPutARMFilter(filter);
                                    }
                                }

                                // Handle Deleted Home Page Filters
                                let deletedHomePageFilters: MCHomePageFilter[] = this.buildDeletedHomePages(ouser, duser);
                                if (deletedHomePageFilters && deletedHomePageFilters.length > 0) {
                                    for (let dhIndex = 0; dhIndex < deletedHomePageFilters.length; dhIndex++) {
                                        let savedFilterObject: MCSavedFilter = this.getFilterOjbectByID(deletedHomePageFilters[dhIndex]["@ID"]);
                                        // mark for deletion only if it was completely removed from home and favs
                                        let tagAsDel = newFavoriteFilters.find(x => x["@ID"] === savedFilterObject["@ID"]) ? '' : 'true';
                                        let filter: MCPutFavoriteFilter = {
                                            "@AssignType": 's',
                                            "@AssignID": duser["@ID"],
                                            '@FilterID': savedFilterObject["@ID"],
                                            "@FilterName": savedFilterObject["@Name"],
                                            "@Delete": tagAsDel,
                                            "@Sequence": "0",
                                        };

                                        this.insertPutARMFilter(filter);
                                    }
                                }


                                // Handle Sequence update
                                let allHomePages: MCHomePageFilter[] = this.selectorHomePages(duser);
                                if (allHomePages && allHomePages.length > 0) {
                                    for (let sIndex = 0; sIndex < allHomePages.length; sIndex++) {
                                        let savedFilterObject: MCSavedFilter = this.getFilterOjbectByID(allHomePages[sIndex]["@ID"]);
                                        let filter: MCPutFavoriteFilter = {
                                            "@AssignType": 's',
                                            "@AssignID": duser["@ID"],
                                            '@FilterID': savedFilterObject["@ID"],
                                            "@FilterName": savedFilterObject["@Name"],
                                            "@Delete": "",
                                            "@Sequence": (sIndex + 1).toString(),
                                        };

                                        this.insertPutARMFilter(filter);
                                    };
                                }
                            }
                        }
                    }
                }
            }

            // Working on Groups
            let originalGroupList = original.AssignFavorites.Groups.Group;
            let deltaGroupList = delta.AssignFavorites.Groups.Group;

            if (originalGroupList && deltaGroupList && originalGroupList.length === deltaGroupList.length) {
                for (let oIndex = 0; oIndex < originalGroupList.length; oIndex++) {
                    let ogroup = originalGroupList[oIndex];
                    let dGroupIndex: number = deltaGroupList.findIndex(dgrp => dgrp["@ID"] === ogroup["@ID"]);
                    if (dGroupIndex !== -1) {
                        let dgroup = deltaGroupList[dGroupIndex];
                        if (dgroup) {
                            if (this.objectHasChange(ogroup, dgroup)) {
                                // Handle Newly Added Favorite Filters
                                var newFavoriteFilters: MCFavoriteFilter[] = this.buildAddedFavoirteFilters(ogroup, dgroup);
                                if (newFavoriteFilters && newFavoriteFilters.length > 0) {
                                    for (let afIndex = 0; afIndex < newFavoriteFilters.length; afIndex++) {
                                        let filter: MCPutFavoriteFilter = {
                                            "@AssignType": 'g',
                                            "@AssignID": dgroup["@ID"],
                                            '@FilterID': newFavoriteFilters[afIndex]["@ID"],
                                            "@FilterName": newFavoriteFilters[afIndex]["@Name"],
                                            "@Delete": '',
                                            "@Sequence": "0"
                                        };

                                        this.insertPutARMFilter(filter);
                                    };
                                }

                                // Handle Deleted Favoirte Filters
                                let deletedFavoriteFilters: MCFavoriteFilter[] = this.deletedFavoriteFilters(ogroup, dgroup);
                                if (deletedFavoriteFilters && deletedFavoriteFilters.length > 0) {
                                    for (let dfIndex = 0; dfIndex < deletedFavoriteFilters.length; dfIndex++) {
                                        let filter: MCPutFavoriteFilter = {
                                            "@AssignType": 'g',
                                            "@AssignID": dgroup["@ID"],
                                            '@FilterID': deletedFavoriteFilters[dfIndex]["@ID"],
                                            "@FilterName": deletedFavoriteFilters[dfIndex]["@Name"],
                                            "@Delete": 'true',
                                            "@Sequence": "0"
                                        };

                                        this.insertPutARMFilter(filter);
                                    };
                                }

                                // Handle Newly Added Home Page Filters
                                let newHomePageFilters: MCHomePageFilter[] = this.buildAddedHomePages(ogroup, dgroup);
                                if (newHomePageFilters && newHomePageFilters.length > 0) {
                                    for (let ahIndex = 0; ahIndex < newHomePageFilters.length; ahIndex++) {
                                        let filter: MCPutFavoriteFilter = {
                                            "@AssignType": 'g',
                                            "@AssignID": dgroup["@ID"],
                                            '@FilterID': newHomePageFilters[ahIndex]["@ID"],
                                            "@FilterName": newHomePageFilters[ahIndex]["@Name"],
                                            "@Delete": '',
                                            "@Sequence": (ahIndex + 1).toString()
                                        };

                                        this.insertPutARMFilter(filter);
                                    }
                                }

                                // Handle Deleted Home Page Filters
                                let deletedHomePageFilters: MCHomePageFilter[] = this.buildDeletedHomePages(ogroup, dgroup);
                                if (deletedHomePageFilters && deletedHomePageFilters.length > 0) {
                                    for (let dhIndex = 0; dhIndex < deletedHomePageFilters.length; dhIndex++) {
                                        // mark for deletion only if it was completely removed from home and favs
                                        let tagAsDel = newFavoriteFilters.find(x => x["@ID"] === deletedHomePageFilters[dhIndex]["@ID"]) ? '' : 'true';
                                        let filter: MCPutFavoriteFilter = {
                                            "@AssignType": 'g',
                                            "@AssignID": dgroup["@ID"],
                                            '@FilterID': deletedHomePageFilters[dhIndex]["@ID"],
                                            "@FilterName": deletedHomePageFilters[dhIndex]["@Name"],
                                            "@Delete": tagAsDel,
                                            "@Sequence": "0"
                                        };

                                        this.insertPutARMFilter(filter);
                                    };
                                }

                                // Handle Sequence update
                                let allHomePages: MCHomePageFilter[] = this.selectorHomePages(dgroup);
                                if (allHomePages && allHomePages.length > 0) {
                                    for (let sIndex = 0; sIndex < allHomePages.length; sIndex++) {
                                        let savedFilterObject: MCSavedFilter = this.getFilterOjbectByID(allHomePages[sIndex]["@ID"]);
                                        let filter: MCPutFavoriteFilter = {
                                            "@AssignType": 'g',
                                            "@AssignID": dgroup["@ID"],
                                            '@FilterID': savedFilterObject["@ID"],
                                            "@FilterName": savedFilterObject["@Name"],
                                            "@Delete": "",
                                            "@Sequence": (sIndex + 1).toString(),
                                        };

                                        this.insertPutARMFilter(filter);
                                    };
                                }
                            }
                        }
                    }
                }
            }
        }

        // Clean Empty Objects
        if (this.props.dataStore.ui.putFavorites) {
            if (this.props.dataStore.ui.putFavorites.AssignFavorites) {
                if (this.props.dataStore.ui.putFavorites.AssignFavorites.Favorites) {
                    if (this.props.dataStore.ui.putFavorites.AssignFavorites.Favorites.Favorite &&
                        this.props.dataStore.ui.putFavorites.AssignFavorites.Favorites.Favorite.length > 0) {
                        this.props.dataStore.ui.putFavorites.AssignFavorites.Favorites.Favorite =
                            this.props.dataStore.ui.putFavorites.AssignFavorites.Favorites.Favorite.filter(flt => flt["@AssignID"] !== "" || flt["@FilterID"] !== "");
                    }
                }
            }
        }
    }

    private buildPutXML(filter: MCPutAssignedFavorites) {
        //console.log(JSON.stringify(filter));
        let crudXML = "<?xml version=\"1.0\"?><AssignFavorites>";
        if (filter) {
            if (filter.AssignFavorites) {
                if (filter.AssignFavorites.Favorites) {
                    if (filter.AssignFavorites.Favorites.Favorite) {
                        if (filter.AssignFavorites.Favorites.Favorite.length > 0) {
                            crudXML += "<Favorites>";
                            filter.AssignFavorites.Favorites.Favorite.forEach(function (flt) {
                                crudXML += "<Favorite AssignType=\"" + flt["@AssignType"] + "\" AssignID=\"" +
                                    flt["@AssignID"] + "\" FilterID=\"" + flt["@FilterID"] + "\" FilterName=\"" +
                                    flt["@FilterName"] + "\" ";

                                if (flt["@Delete"] && flt["@Delete"] !== "") {
                                    crudXML += "Delete=\"" + flt["@Delete"] + "\" ";
                                }

                                crudXML += "Sequence=\"" + flt["@Sequence"] + "\"/>";
                            });
                            crudXML += "</Favorites>";
                        }
                    }
                }
            }
        }
        crudXML += "</AssignFavorites>";

        return crudXML;
    }
   
    public onOK() {
        
        this.props.dataStore.ui.putFavorites = {
            AssignFavorites: {
                Favorites: {
                    Favorite: [
                        {
                            "@AssignType": '',
                            "@AssignID": '',
                            '@FilterID': '',
                            "@FilterName": '',
                            "@Delete": '',
                            "@Sequence": ''
                        }]
                }
            }
        };;
        this.buildPutARMFilter(this.props.dataStore.crud.originalData!, this.props.dataStore.crud.data!);
        //console.log(this.buildPutXML(this.props.dataStore.ui.putFavorites));
        this.props.action.crud.update({crudId: CrudTypes.mctiAssignFavoriteFilterMaster, data: this.props.dataStore.ui.putFavorites });
        this.props.history.push('/LandingPage');
    }

    public onCancel() {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    private initFilterUsers() {
        this.props.action.ui.selectFilterUser({
            index: -1,
            id: '',
            name: '',
            Favorites: { Favorite: [] },
            HomePages: { HomePage: [] },
        });
    }

    private initFilterGroups() {
        this.props.action.ui.selectFilterGroup({
            index: -1,
            id: '',
            name: '',
            Favorites: { Favorite: [] },
            HomePages: { HomePage: [] },
        });
    }

    private isUserExists(user: ISelectFilterUserData):boolean {
        let exists = false;
        if (user &&
            this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.AssignFavorites.Users.User) {

            let index: number = this.props.dataStore.crud.data.AssignFavorites.Users.User.findIndex(uObj => uObj["@ID"] === user.id);

            exists = (index != -1);
        }
        return exists;
    }

    private isGroupExists(group: ISelectFilterGroupData): boolean {
        let exists = false;
        if (group &&
            this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.AssignFavorites.Groups.Group) {

            let index: number = this.props.dataStore.crud.data.AssignFavorites.Groups.Group.findIndex(gObj => gObj["@Name"] === group.text);

            exists = (index != -1);
        }
        return exists;
    }

    public onSelectFilter(e: React.ChangeEvent<HTMLSelectElement>, type: FilterType) {
        switch (type) {
            case FilterType.UserFilter:
                this.props.action.ui.selectFilterUser({
                    masterCrud: this.props.dataStore.crud.data,
                    filterType: type,
                    selectedFilterUser: this.props.dataStore.ui.selectedFilterUser,
                    uiData: {
                        index: e.target.options.selectedIndex,
                        id: e.target.value,
                        text: e.target.options[e.target.options.selectedIndex].textContent
                    },
                });
                break;
            case FilterType.GroupFilter:
                this.props.action.ui.selectFilterGroup({
                    masterCrud: this.props.dataStore.crud.data,
                    filterType: type,
                    selectedFilterGroup: this.props.dataStore.ui.selectedFilterGroup,
                    uiData: {
                        index: e.target.options.selectedIndex,
                        id: e.target.value,
                        text: e.target.options[e.target.options.selectedIndex].textContent,
                    },
                });
                break;
        }
    }
   
    public onSelectAllSavedFilter(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectAllSavedFilters({
            masterCrud: this.props.dataStore.crud.data,
            filterType: this.props.dataStore.ui.filterType,
            uiData: {
                selected: this.getSelectedOptions(e),
            },
        });
    }

    public getSelectedOptions(e: React.ChangeEvent<HTMLSelectElement>) {
        if (!e.target.options || e.target.options.length === 0) return [];
        let result = [];

        for (let i = 0; i < e.target.options.length; i++) {
            if (e.target.options[i].selected)
                result.push(e.target.options[i].value);
        }
        return result;
    }

    public onSelectAllFavoriteFilter(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectAllFavoriteFilters({
            masterCrud: this.props.dataStore.crud.data,
            filterType: this.props.dataStore.ui.filterType,
            uiData: {
                selected: this.getSelectedOptions(e),
            },
        });
    }
    
    public onSelectAllHomePageFilter(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectAllHomePageFilters({
            masterCrud: this.props.dataStore.crud.data,
            filterType: this.props.dataStore.ui.filterType,
            uiData: {
                selected: this.getSelectedOptions(e),
            },
        });
    }
    
    public getFilterOjbectByID(id: string): MCSavedFilter {

        let result: MCSavedFilter = this.initSavedFilter;

        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.AssignFavorites &&
            this.props.dataStore.crud.data.AssignFavorites.FilterList &&
            this.props.dataStore.crud.data.AssignFavorites.FilterList.Filter) {

            let filters = this.props.dataStore.crud.data.AssignFavorites.FilterList.Filter;

            if (id && filters) {

                const filterObject = filters.find(filterObj => filterObj["@ID"] === id);

                if (filterObject)
                    result = filterObject;
            }
        }
        return result;
    }

    public isFilterExistAsFavorite(filterId: string): boolean {
        let result: boolean = false;
        if (filterId && this.props.dataStore.ui.selectedAllFavoriteFilters && this.props.dataStore.ui.selectedAllFavoriteFilters.length > 0) {
            const filterFound = this.props.dataStore.ui.selectedAllFavoriteFilters.find(fObj => fObj === filterId);
            if (filterFound) {
                result = true;
            }
        } 
        return result;
    }

    public isFilterExistAsHomePage(filterId: string): boolean {
        let result: boolean = false;
        if (filterId && this.props.dataStore.ui.selectedAllHomePageFilters && this.props.dataStore.ui.selectedAllHomePageFilters.length > 0) {
            const filterFound = this.props.dataStore.ui.selectedAllHomePageFilters.find(fObj => fObj === filterId);
            if (filterFound) {
                result = true;
            }
        }
        return result;
    }

    public isFilterExistAsSavedFilter(filter: string): boolean {
        let result: boolean = false;
        if (filter && this.props.dataStore.ui.selectedAllSavedFilters && this.props.dataStore.ui.selectedAllSavedFilters.length > 0) {
            let filterIndex: number = this.props.dataStore.ui.selectedAllSavedFilters.findIndex(fObj => fObj === filter);
            if (filterIndex !== -1) {
                let savedFilter = this.props.dataStore.ui.selectedAllSavedFilters[filterIndex];
                if (savedFilter && savedFilter !== "") {
                    result = true;
                }
            }
        }
        return result;
    }

    public getSavedFilterList(): MCSavedFilter[] {
        let result: MCSavedFilter[] = [this.initSavedFilter];
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.AssignFavorites &&
            this.props.dataStore.crud.data.AssignFavorites.FilterList &&
            this.props.dataStore.crud.data.AssignFavorites.FilterList.Filter && 
            this.props.dataStore.crud.data.AssignFavorites.FilterList.Filter.length > 0) {
            this.props.dataStore.crud.data.AssignFavorites.FilterList.Filter.forEach(function (sfilter) {
                result.push(sfilter);
            });
        }
        result = result.filter(rst => rst["@ID"] !== "");
        return result;
    }

    public getAllFavoriteFilters(): MCFavoriteFilter[] {
        let result: MCFavoriteFilter[] = [];
        if (this.props.dataStore.ui.selectedFilterUser) {
            switch (this.props.dataStore.ui.filterType) {
                case FilterType.UserFilter:
                    if (this.props.dataStore.ui.selectedFilterUser) {
                        if (this.props.dataStore.ui.selectedAllFavoriteFilters &&
                            this.props.dataStore.ui.selectedAllFavoriteFilters.length > 0) {
                            for (let idx = 0; idx < this.props.dataStore.ui.selectedAllFavoriteFilters.length; idx++) {
                                let savedFilterObject: MCSavedFilter = this.getFilterOjbectByID(this.props.dataStore.ui.selectedAllFavoriteFilters[idx]);

                                if (savedFilterObject) {
                                    let favoriteFilter: MCFavoriteFilter = {
                                        '@ID': savedFilterObject["@ID"],
                                        '@Name': savedFilterObject["@Name"]
                                    }

                                    if (favoriteFilter && favoriteFilter["@ID"] !== "") {
                                        result.push(favoriteFilter);
                                    }
                                }
                            }
                        } 
                    }
                    break;
                case FilterType.GroupFilter:
                    if (this.props.dataStore.ui.selectedFilterGroup) {
                        if (this.props.dataStore.ui.selectedAllFavoriteFilters &&
                            this.props.dataStore.ui.selectedAllFavoriteFilters.length > 0) {
                            for (let idx = 0; idx < this.props.dataStore.ui.selectedAllFavoriteFilters.length; idx++) {
                                let savedFilterObject: MCSavedFilter = this.getFilterOjbectByID(this.props.dataStore.ui.selectedAllFavoriteFilters[idx]);

                                if (savedFilterObject) {
                                    let favoriteFilter: MCFavoriteFilter = {
                                        '@ID': savedFilterObject["@ID"],
                                        '@Name': savedFilterObject["@Name"],
                                    }

                                    if (favoriteFilter && favoriteFilter["@ID"] !== "") {
                                        result.push(favoriteFilter);
                                    }
                                }
                            }
                        }
                    }
                    break;
            }
        }
        return result;
    }

    public getAllHomePageFilters(): MCHomePageFilter[] {
        let result: MCHomePageFilter[] = [];
        if (this.props.dataStore.ui.selectedFilterUser) {
            switch (this.props.dataStore.ui.filterType) {
                case FilterType.UserFilter:
                    if (this.props.dataStore.ui.selectedFilterUser) {
                        if (this.props.dataStore.ui.selectedAllHomePageFilters &&
                            this.props.dataStore.ui.selectedAllHomePageFilters.length > 0) {
                            for (let idx = 0; idx < this.props.dataStore.ui.selectedAllHomePageFilters.length; idx++) {
                                let savedFilterObject: MCSavedFilter = this.getFilterOjbectByID(this.props.dataStore.ui.selectedAllHomePageFilters[idx]);

                                if (savedFilterObject) {
                                    let pageFilter: MCHomePageFilter = {
                                        '@ID': savedFilterObject["@ID"],
                                        '@Name': savedFilterObject["@Name"],
                                        '@Sequence': (idx + 1).toString(),
                                    }

                                    if (pageFilter && pageFilter["@ID"] !== "") {
                                        result.push(pageFilter);
                                    }
                                }
                            }
                        }
                    }
                    break;
                case FilterType.GroupFilter:
                    if (this.props.dataStore.ui.selectedFilterGroup) {
                        if (this.props.dataStore.ui.selectedAllHomePageFilters &&
                            this.props.dataStore.ui.selectedAllHomePageFilters.length > 0) {
                            for (let idx = 0; idx < this.props.dataStore.ui.selectedAllHomePageFilters.length; idx++) {
                                let savedFilterObject: MCSavedFilter = this.getFilterOjbectByID(this.props.dataStore.ui.selectedAllHomePageFilters[idx]);

                                if (savedFilterObject) {
                                    let pageFilter: MCHomePageFilter = {
                                        '@ID': savedFilterObject["@ID"],
                                        '@Name': savedFilterObject["@Name"],
                                        '@Sequence': (idx + 1).toString(),
                                    }

                                    if (pageFilter && pageFilter["@ID"] !== "") {
                                        result.push(pageFilter);
                                    }
                                }
                            }
                        }
                    }
                    break;
            }
        }
        return result;
    }

    public getUpdatedSavedFilter(favorites: MCFavoriteFilter[], homepages: MCHomePageFilter[]): MCSavedFilter[] {
        let filters: MCSavedFilter[] = [];

        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.AssignFavorites &&
            this.props.dataStore.crud.data.AssignFavorites.FilterList &&
            this.props.dataStore.crud.data.AssignFavorites.FilterList.Filter) {

             filters = this.props.dataStore.crud.data.AssignFavorites.FilterList.Filter;

            if (favorites && favorites.length > 0) {
                favorites.forEach(function (fObj) {
                    filters = filters.filter(sfObj => sfObj["@ID"] !== fObj["@ID"]);
                });
            }
            if (homepages && homepages.length > 0) {
                homepages.forEach(function (hObj) {
                    filters = filters.filter(sfObj => sfObj["@ID"] !== hObj["@ID"]);
                });
            }

            return filters;
        }

        return filters;
    }

    public getUpdatedFavoriteFilter(homePages: MCHomePageFilter[], favorites: MCFavoriteFilter[]): MCFavoriteFilter[] {
        if (homePages && homePages.length > 0) {
            homePages.forEach(function (homeObj) {
                favorites = favorites.filter(ffObj => ffObj["@ID"] !== homeObj["@ID"]);
            });
        }
        return favorites;
    }

    private getSelectorFavorites(selectorID: string | undefined): MCFavoriteFilter[] {
        let result: MCFavoriteFilter[] = [];

        if (selectorID !== "") {
            if (this.props.dataStore.crud.data &&
                this.props.dataStore.crud.data.AssignFavorites) {
                if (this.props.dataStore.ui.filterType === FilterType.UserFilter) {
                    if (this.props.dataStore.crud.data.AssignFavorites.Users &&
                        this.props.dataStore.crud.data.AssignFavorites.Users.User &&
                        this.props.dataStore.crud.data.AssignFavorites.Users.User.length > 0) {

                        let userIndex = this.props.dataStore.crud.data.AssignFavorites.Users.User.findIndex(usr => usr["@ID"] === selectorID);

                        if (userIndex !== -1) {
                            let userFilters = this.props.dataStore.crud.data.AssignFavorites.Users.User[userIndex].Favorites.Favorite;
                            if (userFilters && userFilters.length > 0) {
                                for (let idx = 0; idx < userFilters.length; idx++) {
                                    let savedFilter = this.getFilterOjbectByID(userFilters[idx]["@ID"]);
                                    if (savedFilter) {
                                        let favFilter: MCFavoriteFilter = {
                                            "@ID": savedFilter["@ID"],
                                            "@Name": savedFilter["@Name"]
                                        };
                                        result.push(favFilter);
                                    }
                                }
                            }
                        }
                    }
                } else if (this.props.dataStore.ui.filterType === FilterType.GroupFilter) {
                    if (this.props.dataStore.crud.data.AssignFavorites.Groups &&
                        this.props.dataStore.crud.data.AssignFavorites.Groups.Group &&
                        this.props.dataStore.crud.data.AssignFavorites.Groups.Group.length > 0) {

                        let groupIndex = this.props.dataStore.crud.data.AssignFavorites.Groups.Group.findIndex(grp => grp["@ID"] === selectorID);

                        if (groupIndex !== -1) {
                            let groupFilters = this.props.dataStore.crud.data.AssignFavorites.Groups.Group[groupIndex].Favorites.Favorite;
                            if (groupFilters && groupFilters.length > 0) {
                                for (let idx = 0; idx < groupFilters.length; idx++) {
                                    let savedFilter = this.getFilterOjbectByID(groupFilters[idx]["@ID"]);
                                    if (savedFilter) {
                                        let favFilter: MCFavoriteFilter = {
                                            "@ID": savedFilter["@ID"],
                                            "@Name": savedFilter["@Name"]
                                        };
                                        result.push(favFilter);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return result;
    }

    private getSelectorHomePages(selectorID: string | undefined): MCHomePageFilter[] {

        let result: MCHomePageFilter[] | MCHomePageFilter = [];

        if (selectorID !== "") {
            if (this.props.dataStore.crud.data &&
                this.props.dataStore.crud.data.AssignFavorites) {
                if (this.props.dataStore.ui.filterType === FilterType.UserFilter) {
                    if (this.props.dataStore.crud.data.AssignFavorites.Users &&
                        this.props.dataStore.crud.data.AssignFavorites.Users.User &&
                        this.props.dataStore.crud.data.AssignFavorites.Users.User.length > 0) {

                        let userIndex = this.props.dataStore.crud.data.AssignFavorites.Users.User.findIndex(usr => usr["@ID"] === selectorID);

                        if (userIndex !== -1) {
                            let userPages: any = this.props.dataStore.crud.data.AssignFavorites.Users.User[userIndex].HomePages.HomePage;
                            if (userPages && userPages.length > 0) {
                                for (let idx = 0; idx < userPages.length; idx++) {
                                    let savedFilter: MCSavedFilter = this.getFilterOjbectByID(userPages[idx]["@ID"]);
                                    if (savedFilter) {
                                        let pageFilter: MCHomePageFilter = {
                                            "@ID": savedFilter["@ID"],
                                            "@Name": savedFilter["@Name"],
                                            "@Sequence": (idx + 1).toString()
                                        };
                                        result.push(pageFilter);
                                    }
                                }
                            } 
                        }
                    }
                } else if (this.props.dataStore.ui.filterType === FilterType.GroupFilter) {
                    if (this.props.dataStore.crud.data.AssignFavorites.Groups &&
                        this.props.dataStore.crud.data.AssignFavorites.Groups.Group &&
                        this.props.dataStore.crud.data.AssignFavorites.Groups.Group.length > 0) {

                        let groupIndex = this.props.dataStore.crud.data.AssignFavorites.Groups.Group.findIndex(grp => grp["@ID"] === selectorID);

                        if (groupIndex !== -1) {
                            let groupPages: any = this.props.dataStore.crud.data.AssignFavorites.Groups.Group[groupIndex].HomePages.HomePage;
                            if (groupPages && groupPages.length > 0) {
                                for (let idx = 0; idx < groupPages.length; idx++) {
                                    let savedFilter: MCSavedFilter = this.getFilterOjbectByID(groupPages[idx]["@ID"]);
                                    if (savedFilter) {
                                        let pageFilter: MCHomePageFilter = {
                                            "@ID": savedFilter["@ID"],
                                            "@Name": savedFilter["@Name"],
                                            "@Sequence": (idx + 1).toString()
                                        };
                                        result.push(pageFilter);
                                    }
                                }
                            } 
                        }
                    }
                }
            }
        }

        return result;
    }

    public getSelectedUserFavorites(): MCFavoriteFilter[] {

        let result: MCFavoriteFilter[] = [];

        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.AssignFavorites) return result;
        
        if (this.isUserExists(this.props.dataStore.ui.selectedFilterUser)) {
            if (this.props.dataStore.ui.selectedFilterUser) {

                if (this.props.dataStore.ui.selectedFilterUser.Favorites &&
                    this.props.dataStore.ui.selectedFilterUser.Favorites.Favorite) {

                    let userFilters = this.getSelectorFavorites(this.props.dataStore.ui.selectedFilterUser.id);

                    if (userFilters && userFilters.length > 0) {
                        userFilters.forEach(uf => result.push(uf));
                    }
                } else {
                    if (this.props.dataStore.crud.data.AssignFavorites.Users &&
                        this.props.dataStore.crud.data.AssignFavorites.Users.User &&
                        this.props.dataStore.crud.data.AssignFavorites.Users.User.length > 0) {
                        let userIndex = this.props.dataStore.crud.data.AssignFavorites.Users.User.findIndex(usr => usr["@ID"] === this.props.dataStore.ui.selectedFilterUser.id);
                        if (userIndex !== -1) {
                            let userFilters = this.getSelectorFavorites(this.props.dataStore.crud.data.AssignFavorites.Users.User[userIndex]["@ID"]);
                            if (userFilters && userFilters.length > 0) {
                                userFilters.forEach(uf => result.push(uf));
                            }
                        }
                    }
                }

                if (result && result.length > 0) {
                    for (let idx = 0; idx < result.length; idx++) {
                        if (this.isFilterExistAsFavorite(result[idx]["@ID"]) || this.isFilterExistAsHomePage(result[idx]["@ID"])) {
                            this.props.dataStore.ui.selectedAllSavedFilters = this.props.dataStore.ui.selectedAllSavedFilters.filter(fObj => fObj !== result[idx]["@ID"])
                        } else {
                            if (this.props.dataStore.crud.data &&
                                this.props.dataStore.crud.data.AssignFavorites &&
                                this.props.dataStore.crud.data.AssignFavorites.FilterList &&
                                this.props.dataStore.crud.data.AssignFavorites.FilterList.Filter) {

                                this.props.dataStore.crud.data.AssignFavorites.FilterList.Filter = this.getSavedFilterList();
                            }
                        }
                    }
                }
            }
        } else {
            if (this.props.dataStore.ui.selectedFilterUser.id === "") {
                let value: string = "";
                if (this.props.dataStore.crud.data.AssignFavorites.Users.User.length) {
                    value = this.props.dataStore.crud.data.AssignFavorites.Users.User[0]["@ID"];
                }
                this.props.dataStore.ui.selectedFilterUser.id = value;
                this.getSelectorFavorites(value).forEach(flt => result.push(flt));
            }
        }

        return result;
    }
   
    public getSelectedGroupFavorites(): MCFavoriteFilter[] {

        let result: MCFavoriteFilter[] = [];

        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.AssignFavorites) return result;
       
        if (this.isGroupExists(this.props.dataStore.ui.selectedFilterGroup)) {
            if (this.props.dataStore.ui.selectedFilterGroup) {
                if (this.props.dataStore.ui.selectedFilterGroup.Favorites &&
                    this.props.dataStore.ui.selectedFilterGroup.Favorites.Favorite) {

                    let groupFilters = this.getSelectorFavorites(this.props.dataStore.ui.selectedFilterGroup.id);

                    if (groupFilters && groupFilters.length > 0) {
                        groupFilters.forEach(gf => result.push(gf));
                    }
                } else {
                    if (this.props.dataStore.crud.data.AssignFavorites.Groups &&
                        this.props.dataStore.crud.data.AssignFavorites.Groups.Group &&
                        this.props.dataStore.crud.data.AssignFavorites.Groups.Group.length > 0) {
                        let groupIndex = this.props.dataStore.crud.data.AssignFavorites.Groups.Group.findIndex(grp => grp["@Name"] === this.props.dataStore.ui.selectedFilterGroup.text);
                        if (groupIndex !== -1) {
                            let groupFilters = this.getSelectorFavorites(this.props.dataStore.crud.data.AssignFavorites.Groups.Group[groupIndex]["@ID"]);
                            if (groupFilters && groupFilters.length > 0) {
                                groupFilters.forEach(gf => result.push(gf));
                            }
                        }
                    }
                }

                if (result && result.length > 0) {
                    for (let idx = 0; idx < result.length; idx++) {
                        if (this.isFilterExistAsFavorite(result[idx]["@ID"]) || this.isFilterExistAsHomePage(result[idx]["@ID"])) {
                            this.props.dataStore.ui.selectedAllSavedFilters = this.props.dataStore.ui.selectedAllSavedFilters.filter(fObj => fObj !== result[idx]["@ID"])
                        } else {
                            if (this.props.dataStore.crud.data &&
                                this.props.dataStore.crud.data.AssignFavorites &&
                                this.props.dataStore.crud.data.AssignFavorites.FilterList &&
                                this.props.dataStore.crud.data.AssignFavorites.FilterList.Filter) {

                                this.props.dataStore.crud.data.AssignFavorites.FilterList.Filter = this.getSavedFilterList();
                            }
                        }
                    }
                }
            }
        } else {
            if (this.props.dataStore.ui.selectedFilterGroup.id === "") {
                let value: string = this.props.dataStore.crud.data.AssignFavorites.Groups.Group[0]["@ID"];
                this.props.dataStore.ui.selectedFilterUser.id = value;
                this.getSelectorFavorites(value).forEach(flt => result.push(flt));
            }
        }

        return result;
    }

    public getSelectedUserHomePages(): MCHomePageFilter[] {

        let result: MCHomePageFilter[] = [];

        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.AssignFavorites) return result;
        
        if (this.isUserExists(this.props.dataStore.ui.selectedFilterUser)) {
            if (this.props.dataStore.ui.selectedFilterUser) {

                if (this.props.dataStore.ui.selectedFilterUser.HomePages &&
                    this.props.dataStore.ui.selectedFilterUser.HomePages.HomePage) {

                    let userPages = this.getSelectorHomePages(this.props.dataStore.ui.selectedFilterUser.id);

                    if (userPages && userPages.length > 0) {
                        userPages.forEach(up => result.push(up));
                    }
                } else {
                    if (this.props.dataStore.crud.data.AssignFavorites.Users &&
                        this.props.dataStore.crud.data.AssignFavorites.Users.User &&
                        this.props.dataStore.crud.data.AssignFavorites.Users.User.length > 0) {
                        let userIndex = this.props.dataStore.crud.data.AssignFavorites.Users.User.findIndex(usr => usr["@ID"] === this.props.dataStore.ui.selectedFilterUser.id);
                        if (userIndex !== -1) {
                            let userPages = this.getSelectorHomePages(this.props.dataStore.crud.data.AssignFavorites.Users.User[userIndex]["@ID"]);
                            if (userPages && userPages.length > 0) {
                                userPages.forEach(up => result.push(up));
                            }
                        }
                    }
                }

                if (result && result.length > 0) {
                    for (let idx = 0; idx < result.length; idx++) {
                        if (this.isFilterExistAsFavorite(result[idx]["@ID"])) {
                            this.props.dataStore.ui.selectedAllSavedFilters = this.props.dataStore.ui.selectedAllSavedFilters.filter(fObj => fObj !== result[idx]["@ID"])
                        } else {
                            if (this.props.dataStore.crud.data &&
                                this.props.dataStore.crud.data.AssignFavorites &&
                                this.props.dataStore.crud.data.AssignFavorites.FilterList &&
                                this.props.dataStore.crud.data.AssignFavorites.FilterList.Filter) {

                                this.props.dataStore.crud.data.AssignFavorites.FilterList.Filter = this.getSavedFilterList();
                            }
                        }
                    }
                }
            }
        } else {
                if (this.props.dataStore.crud.data.AssignFavorites.Users.User) {
                    let value: string = "";
                    if (this.props.dataStore.ui.selectedFilterUser.id === "" && this.props.dataStore.crud.data.AssignFavorites.Users.User.length) {
                        value = this.props.dataStore.crud.data.AssignFavorites.Users.User[0]["@ID"];
                    }
                    this.props.dataStore.ui.selectedFilterUser.id = value;
                    this.getSelectorHomePages(value).forEach(pge => result.push(pge));
                }
        }
        return result;
    }

    public getSelectedGroupHomePages(): MCHomePageFilter[] {

        let result: MCHomePageFilter[] = [];

        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.AssignFavorites) return result;
        
        if (this.props.dataStore.ui.selectedFilterGroup) {

            if (this.isGroupExists(this.props.dataStore.ui.selectedFilterGroup)) {

                if (this.props.dataStore.ui.selectedFilterGroup.HomePages &&
                    this.props.dataStore.ui.selectedFilterGroup.HomePages.HomePage) {

                    let groupPages = this.getSelectorHomePages(this.props.dataStore.ui.selectedFilterGroup.id);

                    if (groupPages && groupPages.length > 0) {
                        groupPages.forEach(gp => result.push(gp));
                    }
                } else {
                    if (this.props.dataStore.crud.data.AssignFavorites.Groups &&
                        this.props.dataStore.crud.data.AssignFavorites.Groups.Group &&
                        this.props.dataStore.crud.data.AssignFavorites.Groups.Group.length > 0) {
                        let groupIndex = this.props.dataStore.crud.data.AssignFavorites.Groups.Group.findIndex(grp => grp["@Name"] === this.props.dataStore.ui.selectedFilterGroup.text);
                        if (groupIndex !== -1) {
                            let groupPages = this.getSelectorHomePages(this.props.dataStore.crud.data.AssignFavorites.Groups.Group[groupIndex]["@ID"]);
                            if (groupPages && groupPages.length > 0) {
                                groupPages.forEach(gp => result.push(gp));
                            }
                        }
                    }
                }

                if (result && result.length > 0) {
                    for (let idx = 0; idx < result.length; idx++) {
                        if (this.isFilterExistAsFavorite(result[idx]["@ID"])) {
                            this.props.dataStore.ui.selectedAllSavedFilters = this.props.dataStore.ui.selectedAllSavedFilters.filter(fObj => fObj !== result[idx]["@ID"])
                        } else {
                            if (this.props.dataStore.crud.data &&
                                this.props.dataStore.crud.data.AssignFavorites &&
                                this.props.dataStore.crud.data.AssignFavorites.FilterList &&
                                this.props.dataStore.crud.data.AssignFavorites.FilterList.Filter) {

                                this.props.dataStore.crud.data.AssignFavorites.FilterList.Filter = this.getSavedFilterList();
                            }
                        }
                    }
                }

            } else {
                if (this.props.dataStore.ui.selectedFilterGroup.id === "") {
                    let value: string = this.props.dataStore.crud.data.AssignFavorites.Groups.Group[0]["@ID"];
                    this.props.dataStore.ui.selectedFilterGroup.id = value;
                    this.getSelectorHomePages(value).forEach(pge => result.push(pge));
                }
            }
        }

        return result;
    }

    public isUserORGroupSelected(): boolean {
        let result: boolean = false;
        result = (( this.props.dataStore.ui.selectedFilterUser &&
                    this.props.dataStore.ui.selectedFilterUser.id !== "" &&
                    this.props.dataStore.ui.filterType === FilterType.UserFilter) ||
                (this.props.dataStore.ui.selectedFilterGroup &&
                this.props.dataStore.ui.selectedFilterGroup.id !== "" &&
                this.props.dataStore.ui.filterType === FilterType.GroupFilter));
        return result;
    }

    public onRemoveFavorites(e: React.ChangeEvent<HTMLButtonElement>) {

        if (this.isUserORGroupSelected() &&
            this.props.dataStore.ui.selectedAllFavoriteFilters &&
            this.props.dataStore.ui.selectedAllFavoriteFilters.length > 0) {
            this.props.action.ui.removeFavorites({ masterCrud: this.props.dataStore.crud.data, uiData: true });
        }
    }

    public onAddFavorites(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.isUserORGroupSelected() &&
            this.props.dataStore.ui.selectedAllSavedFilters &&
            this.props.dataStore.ui.selectedAllSavedFilters.length > 0) {
            this.props.action.ui.addFavorites({ masterCrud: this.props.dataStore.crud.data, uiData: true });
        }
    }

    public onRemoveHomePages(e: React.ChangeEvent<HTMLButtonElement>) {
        
        if (this.isUserORGroupSelected() &&
            this.props.dataStore.ui.selectedAllHomePageFilters &&
            this.props.dataStore.ui.selectedAllHomePageFilters.length > 0) {
            this.props.action.ui.removeHomePages({ masterCrud: this.props.dataStore.crud.data, uiData: true });
        }
    }

    public getMaxHomeFilters() {
        let configuredMaxFilters = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "HomePageFavMaxFilters")

        if (configuredMaxFilters && configuredMaxFilters.value > 0)
            return configuredMaxFilters.value;

        return this.props.homePageMaxFilters;
    }

    public onAddHomePages(e: React.ChangeEvent<HTMLButtonElement>) {
        
        if (this.isUserORGroupSelected() &&
            this.props.dataStore.ui.selectedAllFavoriteFilters &&
            this.props.dataStore.ui.selectedAllFavoriteFilters.length > 0) {
            let maxAllowedHomePages = this.getMaxHomeFilters();
            let selectedFavFilterCount = this.props.dataStore.ui.selectedAllFavoriteFilters.length;
            let filterType: FilterType = this.props.dataStore.ui.filterType;
            let existingHomePageCount = 0;
            if (filterType) {
                switch (filterType) {
                    case FilterType.UserFilter:
                        {
                            let selectedUserIndex: number =
                                this.props.dataStore.crud.data!.AssignFavorites.Users.User.findIndex(usr => usr["@ID"] === this.props.dataStore.ui.selectedFilterUser.id);

                            if (selectedUserIndex !== -1) {
                                let selectedUser: MCFilterUser = this.props.dataStore.crud.data!.AssignFavorites.Users.User[selectedUserIndex];
                                if (selectedUser && selectedUser.HomePages && selectedUser.HomePages.HomePage && selectedUser.HomePages.HomePage.length > 0) {
                                    existingHomePageCount = selectedUser.HomePages.HomePage.length;
                                }
                            }
                        }
                        break;
                    case FilterType.GroupFilter:
                        {
                            let selectedGroupIndex: number =
                                this.props.dataStore.crud.data!.AssignFavorites.Groups.Group.findIndex(grp => grp["@ID"] === this.props.dataStore.ui.selectedFilterGroup.id);

                            if (selectedGroupIndex !== -1) {
                                let selectedGroup: MCFilterGroup = this.props.dataStore.crud.data!.AssignFavorites.Groups.Group[selectedGroupIndex];
                                if (selectedGroup && selectedGroup.HomePages && selectedGroup.HomePages.HomePage && selectedGroup.HomePages.HomePage.length > 0) {
                                    existingHomePageCount = selectedGroup.HomePages.HomePage.length;
                                }
                            }
                        }
                        break;
                }
            }
            
            if (selectedFavFilterCount + existingHomePageCount > maxAllowedHomePages) {
                this.props.action.ui.addFilterErrorMsg({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: { value: "Only " + maxAllowedHomePages + " home page filters allowed." }
                });
                return;
            }

            this.props.action.ui.addHomePages({ masterCrud: this.props.dataStore.crud.data, uiData: true });
        }
	}

    public onMoveHomePagesUp(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.moveHomePagesUp({ masterCrud: this.props.dataStore.crud.data, uiData: true });
    }
    public onMoveHomePagesDown(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.moveHomePagesDown({ masterCrud: this.props.dataStore.crud.data, uiData: true });
    }
    
    public getAllHomePages(): MCHomePageFilter[] {
        let result: MCHomePageFilter[] = [];
        let filterType: FilterType = this.props.dataStore.ui.filterType;
        if (filterType) {
            switch (filterType) {
                case FilterType.UserFilter:
                    {
                        if (this.props.dataStore.ui.selectedFilterUser &&
                            this.props.dataStore.ui.selectedFilterUser.HomePages &&
                            this.props.dataStore.ui.selectedFilterUser.HomePages.HomePage.length > 0) {
                            this.props.dataStore.ui.selectedFilterUser.HomePages.HomePage.forEach(function (homeObj) {
                                if (homeObj && homeObj["@ID"] && homeObj["@ID"] !== "") {
                                    let pageFilter: MCHomePageFilter = {
                                        "@ID": homeObj["@ID"],
                                        "@Name": homeObj["@Name"],
                                        "@Sequence": homeObj["@Sequence"]
                                    }
                                    result.push(pageFilter);
                                }
                            });
                        }
                    }
                    break;
                case FilterType.GroupFilter:
                    {
                        if (this.props.dataStore.ui.selectedFilterGroup &&
                            this.props.dataStore.ui.selectedFilterGroup.HomePages &&
                            this.props.dataStore.ui.selectedFilterGroup.HomePages.HomePage.length > 0) {
                            this.props.dataStore.ui.selectedFilterGroup.HomePages.HomePage.forEach(function (homeObj) {
                                if (homeObj && homeObj["@ID"] && homeObj["@ID"] !== "") {
                                    let pageFilter: MCHomePageFilter = {
                                        "@ID": homeObj["@ID"],
                                        "@Name": homeObj["@Name"],
                                        "@Sequence": homeObj["@Sequence"]
                                    }
                                    result.push(pageFilter);
                                }
                            });
                        }
                    }
                    break;
            }
        }
        return result;
    }

    public getAllFavorites(): MCFavoriteFilter[] {
        let result: MCFavoriteFilter[] = [];
        if (this.props.dataStore.ui.filterType) {
            switch (this.props.dataStore.ui.filterType) {
                case FilterType.UserFilter:
                    {
                        if (this.props.dataStore.ui.selectedFilterUser &&
                            this.props.dataStore.ui.selectedFilterUser.Favorites &&
                            this.props.dataStore.ui.selectedFilterUser.Favorites.Favorite.length > 0) {
                            this.props.dataStore.ui.selectedFilterUser.Favorites.Favorite.forEach(function (filterObj) {
                                if (filterObj && filterObj["@ID"] && filterObj["@ID"] !== "") {
                                    let favFilter: MCFavoriteFilter = {
                                        "@ID": filterObj["@ID"],
                                        "@Name": filterObj["@Name"]
                                    };
                                    result.push(favFilter);
                                }
                            });
                        }
                    }
                    break;
                case FilterType.GroupFilter:
                    {
                        if (this.props.dataStore.ui.selectedFilterGroup &&
                            this.props.dataStore.ui.selectedFilterGroup.Favorites &&
                            this.props.dataStore.ui.selectedFilterGroup.Favorites.Favorite.length > 0) {
                            this.props.dataStore.ui.selectedFilterGroup.Favorites.Favorite.forEach(function (filterObj) {
                                if (filterObj && filterObj["@ID"] && filterObj["@ID"] !== "") {
                                    let favFilter: MCFavoriteFilter = {
                                        "@ID": filterObj["@ID"],
                                        "@Name": filterObj["@Name"]
                                    };
                                    result.push(favFilter);
                                }
                            });
                        }
                    }
                    break;
            }
        }
        return result;
    }

    private initializUsersList(): MCFilterUser[] {
        // Default Users List
        let users: MCFilterUser[] = [];

        // initailize Users List
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.AssignFavorites &&
            this.props.dataStore.crud.data.AssignFavorites.Users &&
            this.props.dataStore.crud.data.AssignFavorites.Users.User) {

            users = this.props.dataStore.crud.data.AssignFavorites.Users.User;
        }

        return users;
    }

    private initializeGroupsList(): MCFilterGroup[] {
        // Default Groups List
        let groups: MCFilterGroup[] = [];

        // initailzie Groups List
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.AssignFavorites &&
            this.props.dataStore.crud.data.AssignFavorites.Groups &&
            this.props.dataStore.crud.data.AssignFavorites.Groups.Group) {

            groups = this.props.dataStore.crud.data.AssignFavorites.Groups.Group;
        }

        return groups;
    }

    private initalizeSavedFilterList(): MCSavedFilter[] {
        // Default Saved Filter List
        let filters: MCSavedFilter[] = [];

        // initailize Saved Filter List
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.AssignFavorites &&
            this.props.dataStore.crud.data.AssignFavorites.FilterList &&
            this.props.dataStore.crud.data.AssignFavorites.FilterList.Filter) {

            filters = this.props.dataStore.crud.data.AssignFavorites.FilterList.Filter;
        }

        return filters;
    }
   
    public render() {
        var helpButtons = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK()} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel()} />;

        // initailize Users List
        let users: MCFilterUser[] = this.initializUsersList();

        // initailzie Groups List
        let groups: MCFilterGroup[] = this.initializeGroupsList();

        // initailize Saved Filter List
        let filters: MCSavedFilter[] = [];

        let favFilters: MCFavoriteFilter[] = [];
        let homFilters: MCHomePageFilter[] = [];
        
        
        if (this.props.dataStore.ui.filterType != 0) {
            
            switch (this.props.dataStore.ui.filterType) {
                case FilterType.UserFilter:
                    {
                        favFilters = this.getSelectedUserFavorites();
                        homFilters = this.getSelectedUserHomePages();
                    }
                    break;
                case FilterType.GroupFilter:
                    {
                        favFilters = this.getSelectedGroupFavorites();
                        homFilters = this.getSelectedGroupHomePages();
                    }
                    break;
            }
            
            if (favFilters && favFilters.length === 0) {
                favFilters = this.getAllFavoriteFilters();
            }

            if (homFilters && homFilters.length === 0) {
                homFilters = this.getAllHomePageFilters();
            }

            filters = this.getUpdatedSavedFilter(favFilters, homFilters);
            if(favFilters && favFilters.length > 0) {
                favFilters = this.getUpdatedFavoriteFilter(homFilters, favFilters);
            }
        }

        return (
            <DialogWrapper title="Assign Favorite Filter" width='900px' helpUrl='/Support/Help/HELP_Maint_FavoriteFilterAssignment.htm' buttons={helpButtons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentColumnWrapper>
                    <DialogFieldset width="99%">
                        <ContentRowWrapper>
                            <SelectList>
                                <ContentColumnWrapper>
                                    <DialogLegend>Users:</DialogLegend>
                                    <SelectComponent
                                        key={this.props.dataStore.ui.clearUser}
                                        title='FilterUsers'
                                        size={11}
                                        width='200px'
                                        height='232px'
                                        style={{"marginBottom": "10px"}}
                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectFilter(e, FilterType.UserFilter)}
                                        optionFields={{
                                            value: '@ID',
                                            text: '@Name'
                                        }}
                                        records={users}
                                        selectedValue={this.props.dataStore.ui.selectedFilterUser.id}
/>
                                    <DialogLegend>Groups:</DialogLegend>
                                    <SelectComponent
                                        key={this.props.dataStore.ui.clearGroup}
                                        title='FilterGroups'
                                        size={11}
                                        width='200px'
                                        height='256px'
                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectFilter(e, FilterType.GroupFilter)}
                                        optionFields={{
                                            value: "@ID",
                                            text: "@Name",
                                        }}
                                        records={groups}
                                        selectedValue={this.props.dataStore.ui.selectedFilterGroup.id}
                                    />
                                </ContentColumnWrapper>
                            </SelectList>
                            <SelectList>
                                <ContentRowWrapper>
                                    <ContentColumnWrapper>
                                        <div id='savedFilter-id'>
                                            <DialogLegend>Saved Filters:</DialogLegend>
                                            <SelectComponent
                                                title='SavedFilter'
                                                size={23}
                                                width='310px'
                                                height='521px'
                                                multiple='true'
                                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectAllSavedFilter(e)}
                                                optionFields={{
                                                    value: "@ID",
                                                    text: "@Name"
                                                }}
                                                records={filters}
                                                selectedMultiValue={this.props.dataStore.ui.selectedAllSavedFilters}
                                            />
                                        </div>
                                    </ContentColumnWrapper>
                                </ContentRowWrapper>
                            </SelectList>
                            <ContentColumnWrapper>
                                <ContentRowWrapper>
                                        <div id="FavArrow-Buttons">
                                        <ArrowButtons
                                            toolTipLeft=' Remove Filter from your Favorite Filters '
                                            toolTipRight=' Assign Filter as a Favorite Filter'
                                            onLeftBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                handleChange();
                                                this.onRemoveFavorites(e)
                                            }}
                                            onRightBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                handleChange();
                                                this.onAddFavorites(e)
                                            }}
                                        />
                                        </div>
                                        <SelectList>
                                            <ContentColumnWrapper>
                                                <DialogLegend>Favorite Filters:</DialogLegend>
                                            <SelectComponent
                                                title='FavoriteFilter'
                                                size={14}
                                                width='310px'
                                                height='270px'
                                                multiple='true'
                                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectAllFavoriteFilter(e)}
                                                optionFields={{
                                                    value: "@ID",
                                                    text: "@Name"
                                                }}
                                                records={favFilters}
                                                selectedMultiValue={this.props.dataStore.ui.selectedAllFavoriteFilters}
                                            />
                                            <div id="HomePageArrow-Buttons">
                                            <UpDownArrowButtonsRow
                                                    toolTipDown=' Assign Filter as a Home Page Filter'
                                                    toolTipUp=' Remove Filter from your Home Page Filters'
                                                    onUpBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                        handleChange()
                                                        this.onRemoveHomePages(e)
                                                    }}
                                                    onDownBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                        handleChange()
                                                        this.onAddHomePages(e)
                                                    }}
                                            />
                                            </div>
                                            </ContentColumnWrapper>
                                        </SelectList>
                                </ContentRowWrapper>                                
                                <ContentRowWrapper>
                                    <div id='HomePageList-Id'>
                                    <SelectList>
                                        <ContentColumnWrapper>
                                            <DialogLegend>Home Page Filters:</DialogLegend>
                                                <SelectComponent
                                                    title='HomePageFilter'
                                                    size={5}
                                                    width='310px'
                                                    height="180px"
                                                    multiple='true'
                                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectAllHomePageFilter(e)}
                                                    optionFields={{
                                                        value: "@ID",
                                                        text: "@Name"
                                                    }}
                                                    records={homFilters}
                                                    selectedMultiValue={this.props.dataStore.ui.selectedAllHomePageFilters}
                                                />
                                                {(!!this.props.dataStore.ui.filterErrorMsg && this.props.dataStore.ui.filterErrorMsg.length > 0) &&
                                                    <FilterErrorMessage>{this.props.dataStore.ui.filterErrorMsg}</FilterErrorMessage>
                                                }
                                        </ContentColumnWrapper>
                                    </SelectList>
                                    </div>
                                    <div id="HomePageSEQArrow-Buttons">
                                    <UpDownArrowButtons
                                            toolTipDown=' Move Down'
                                            toolTipUp=' Move Up'
                                            onUpBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                handleChange()
                                                this.onMoveHomePagesUp(e)
                                            }}
                                            onDownBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                handleChange()
                                                this.onMoveHomePagesDown(e)
                                            }}
                                    />
                                    </div>
                                    </ContentRowWrapper>
                            </ContentColumnWrapper>
                        </ContentRowWrapper>                           
                    </DialogFieldset>
                </ContentColumnWrapper>
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IFavoriteFilterState, IFavoriteFilterActionProps, IOwnProps, IFavoriteFilterProps, ApplicationState>(
    createCrudMapStateToProps('favoriteFilter'),
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(FavoriteFilter);

export default withRouter(connectedHoc);