import * as React from 'react';
import { IComponentProps } from './LandingPageComponent';
import { Button } from '@optum-uicl/ui-core/dist';
import { IQuickLinkSubMenu } from './RAPQuickLinks';
import { IImageButtonImage, ImageButton } from '../common/ImageButton';
import { UrlFromApi } from '../common/UrlFromApi';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import styled from 'styled-components';
import { Typography } from '../../commonResources/typography';
import { Colors } from '../../commonResources/colorVariables';
//import RAPQuickLinksIcon from '../../content/images/LandingPage/Icons/Icon_RAPQuickLinksOff.png';
//import MessageAlertsIcon from '../../content/images/LandingPage/Icons/Icon_MessageAlertsOff.png';
//import ARMQuickSearchIcon from '../../content/images/LandingPage/Icons/Icon_ARMQuickSearchOff.png';
//import ARMMyClaimsIcon from '../../content/images/LandingPage/Icons/Icon_ARMMyClaimsOff.png';
//import { PageHeader } from 'react-bootstrap';
import ARMLandingPageIconClaimsOff from '../../content/images/LandingPage/Icons/Icon_ClaimsOff.png';
import ARMLandingPageIconRemitsOff from '../../content/images/LandingPage/Icons/Icon_RemitsOff.png';
import ARMLandingPageIconReportsOff from '../../content/images/LandingPage/Icons/Icon_ReportsOff.png';
import ARMLandingPageIconDashboardsOff from '../../content/images/LandingPage/Icons/Icon_DashboardsOff.png';
import ARMLandingPageIconMaintenanceOff from '../../content/images/LandingPage/Icons/Icon_MaintenanceOff.png';
import ARMLandingPageIconSupportOff from '../../content/images/LandingPage/Icons/Icon_SupportOff.png';

import ARMLandingPageIconClaimsUp from '../../content/images/LandingPage/Icons/Icon_ClaimsUp.png';
import ARMLandingPageIconRemitsUp from '../../content/images/LandingPage/Icons/Icon_RemitsUp.png';
import ARMLandingPageIconReportsUp from '../../content/images/LandingPage/Icons/Icon_ReportsUp.png';
import ARMLandingPageIconDashboardsUp from '../../content/images/LandingPage/Icons/Icon_DashboardsUp.png';
import ARMLandingPageIconMaintenanceUp from '../../content/images/LandingPage/Icons/Icon_MaintenanceUp.png';
import ARMLandingPageIconSupportUp from '../../content/images/LandingPage/Icons/Icon_SupportUp.png';

import ARMLandingPageIconClaimsOver from '../../content/images/LandingPage/Icons/Icon_ClaimsOver.png';
import ARMLandingPageIconRemitsOver from '../../content/images/LandingPage/Icons/Icon_RemitsOver.png';
import ARMLandingPageIconReportsOver from '../../content/images/LandingPage/Icons/Icon_ReportsOver.png';
import ARMLandingPageIconDashboardsOver from '../../content/images/LandingPage/Icons/Icon_DashboardsOver.png';
import ARMLandingPageIconMaintenanceOver from '../../content/images/LandingPage/Icons/Icon_MaintenanceOver.png';
import ARMLandingPageIconSupportOver from '../../content/images/LandingPage/Icons/Icon_SupportOver.png';

import ARMLandingPageIconClaimsDown from '../../content/images/LandingPage/Icons/Icon_ClaimsDown.png';
import ARMLandingPageIconRemitsDown from '../../content/images/LandingPage/Icons/Icon_RemitsDown.png';
import ARMLandingPageIconReportsDown from '../../content/images/LandingPage/Icons/Icon_ReportsDown.png';
import ARMLandingPageIconDashboardsDown from '../../content/images/LandingPage/Icons/Icon_DashboardsDown.png';
import ARMLandingPageIconMaintenanceDown from '../../content/images/LandingPage/Icons/Icon_MaintenanceDown.png';
import ARMLandingPageIconSupportDown from '../../content/images/LandingPage/Icons/Icon_SupportDown.png';

export type TIconImageTypes = "Claims" | "Remits" | "Reports" | "Dashboards" | "Maintenance" | "Support";

type TIconImageIndex<T extends TIconImageTypes> = { [key in T]: IImageButtonImage };

let ARMLandingPageIcon: TIconImageIndex<TIconImageTypes> =
{
    Claims: {
        Off: ARMLandingPageIconClaimsOff,
        Up: ARMLandingPageIconClaimsUp,
        Over: ARMLandingPageIconClaimsOver,
        Down: ARMLandingPageIconClaimsDown,
    },
    Remits: {
        Off: ARMLandingPageIconRemitsOff,
        Up: ARMLandingPageIconRemitsUp,
        Over: ARMLandingPageIconRemitsOver,
        Down: ARMLandingPageIconRemitsDown,
    },
    Reports: {
        Off: ARMLandingPageIconReportsOff,
        Up: ARMLandingPageIconReportsUp,
        Over: ARMLandingPageIconReportsOver,
        Down: ARMLandingPageIconReportsDown,
    },
    Dashboards: {
        Off: ARMLandingPageIconDashboardsOff,
        Up: ARMLandingPageIconDashboardsUp,
        Over: ARMLandingPageIconDashboardsOver,
        Down: ARMLandingPageIconDashboardsDown,
    },
    Maintenance: {
        Off: ARMLandingPageIconMaintenanceOff,
        Up: ARMLandingPageIconMaintenanceUp,
        Over: ARMLandingPageIconMaintenanceOver,
        Down: ARMLandingPageIconMaintenanceDown,
    },
    Support: {
        Off: ARMLandingPageIconSupportOff,
        Up: ARMLandingPageIconSupportUp,
        Over: ARMLandingPageIconSupportOver,
        Down: ARMLandingPageIconSupportDown,
    },
};

interface IPanelCellProps {
    Order?: number;
}

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

interface IComponentState {

}

export const PanelCell = styled.div`
    display: flex;
    flex-direction: column;
    
    ${(props: IPanelCellProps) => props.Order && `order: ${props.Order}`};
    ${ Typography.defaultLineHeight};

    .link-button {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    }
    .qlIconImage {
        padding: 0px 1em;
        padding-top: 5px;
        padding-left: 0px;
        justify-content: left !important;
    }
    img {
        height: 60px
    }

    .align-top {
        vertical-align: top;
    }
`;

export const PanelLinks = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 5px;
    margin-bottom: 10px;
    .link-button {
        color: #0C55B8;
        text-decoration:underline;
        justify-content: left !important;
        width: 100%;
        padding: 2px 0px 2px 0px;
        &:hover {
                text-decoration-color: #133D97;
            }
        }

    .align-top {
        vertical-align: top;
    }
`;

export const PanelTitle = styled.div`
    ${ Typography.defaultLineHeight};
    ${ Typography.bold};
    font-size: 14px;
    color: #000000;
    padding-top:0.5em;
    padding-left: 5px;
`;

export interface IQuickLinksIconPanelProps extends IComponentProps {
    IconImagePath: TIconImageTypes;
    IconAltText: string;
    Title: string;
    Links: IQuickLinkSubMenu[];
    onLinkClick: (url: UrlFromApi) => void;
    Order?: number;
}

interface IARMQuickLinksIconPanelState {
    iconImage: any;
    disabled: boolean;
}

type IQuickLinkConnectedProps = IQuickLinksIconPanelProps & RouteComponentProps<{}>;

class QuickLinksIconPanel extends React.Component<IQuickLinkConnectedProps, IARMQuickLinksIconPanelState>{

    constructor(props: IQuickLinkConnectedProps) {
        super(props);
        this.state = {
            iconImage: null,
            disabled: false
        }
    }
    
    render() {
            let listItems = null;
            if (this.props.Links) {
                //Generate buttons that link to the appropriate locations with the passed click handler instead of trying to eval the Link's text.
                listItems = this.props.Links.map((link) =>
                    <Button
                        domID={link.Id}
                        type="button"
                        size="control"
                        buttonType="unstyled"
                        className="link-button"
                        onClick={() => this.props.onLinkClick(link.Url)}
                        disabled={this.state.disabled}>
                        <span>{link.Name}</span>
                    </Button>);
            }
            return (
                <PanelCell Order={this.props.Order}>
                    <ImageButton
                       Title = {this.props.Title}
                        className="qlIconImage"
                        image={ARMLandingPageIcon[this.props.IconImagePath]}
                        disabled={this.state.disabled}
                        altText={this.props.IconAltText}
                        onClick={() => this.props.onLinkClick(this.props.Links[0].Url)} />
                    <PanelTitle>{this.props.Title}</PanelTitle>
                    <PanelLinks>
                        {listItems}
                    </PanelLinks>
                </PanelCell>
            );
    };
}

export default withRouter(QuickLinksIconPanel);