import { ActionTypes, createAction, createDataAction, createApiAction, createApiBodyAction } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import { Reducer } from 'redux';
import { URLs } from '@commonDevResources/constants';
import { uniqueId } from 'lodash';

// -----------------
// STATE - This defines the type of data maintained in the Redux store. DownloadFilesUI
export interface IDownloadFilesUIState {
    selectedTabIndex: number;
    deIDEncryptEnabled: string;
    sendFromDate: string;
    sendToDate: string;
    sendFileName: string;
    sendFileType: string;
    isDeleteSuccess: string;
    isReportDeleteSuccess: string;
    zipFileName: string;
    zipReportFileName: string;
    isEncryptDownload: boolean;
    ReportDownloadFiles: downloadFiles | undefined;
    //Support Download
    SupportDownloadFiles: DownloadFiles | undefined;
    zipDownloadFilePath: string;
}

export const defaultState: IDownloadFilesUIState = {
    selectedTabIndex: 0,
    deIDEncryptEnabled: "",
    sendFromDate: "",
    sendToDate: "",
    sendFileName: "",
    sendFileType: "",
    isDeleteSuccess: "",
    isReportDeleteSuccess:"",
    zipFileName: "",
    zipReportFileName: "",
    ReportDownloadFiles: undefined,
    isEncryptDownload: false,
    // Support Download
    SupportDownloadFiles: undefined,
    zipDownloadFilePath:"",
}

export interface IDownloadFilesUIData {
    fromDate: string;
    toDate: string;
    fileName: string;
    fileType: string;
    encryptDownload: boolean;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISearchValue extends ICrudActionData<DownloadFilesSearch, IDownloadFilesUIData> { }

export const actionCreators = {
    selectTabIndex: (tabIndex: number) => createDataAction('SELECT_DOWNLOAD_TAB_INDEX', tabIndex),
    getDeIDEncryptEnabled: () => createApiAction('GET_DEID_ENCRYPT_ENABLED', `${URLs.api}/api/data/securityInfo/getDeIDEncryptEnabled`),
    selectedSearchReportFromDate: (data: ISearchValue) => createDataAction('SELECTED_SEARCH_REPORT_FROMDATE', data),
    selectedSearchReportToDate: (data: ISearchValue) => createDataAction('SELECTED_SEARCH_REPORT_TODATE', data),
    selectedSearchReportFileName: (data: ISearchValue) => createDataAction('SELECTED_SEARCH_REPORT_FILENAME', data),
    selectedSearchReportFileType: (data: ISearchValue) => createDataAction('SELECTED_SEARCH_REPORT_FILETYPE', data),
    //Report DownloadFiles
    getReportDownloadFiles: (data: IDownloadFilesUIData) => createApiBodyAction('GET_REPORT_DOWNLOADFILES', `${URLs.api}/api/data/downloadFilesSearch`, undefined, "POST", JSON.stringify(data)),
    encryptReportDownloadFiles: (data: any) => createApiBodyAction('ENCRYPT_REPORT_DOWNLOADFILES', `${URLs.api}/api/data/encryptdownload`, undefined, "POST", JSON.stringify(data)),
    DeleteDownloadFiles: (data: any) => createApiBodyAction('DELETE_REPORT_DOWNLOADFILES', `${URLs.api}/api/data/downloadFilesDelete`, undefined, "PUT", JSON.stringify(data)),
    downloadReportDownloadFiles: (data: any) => createApiBodyAction('DOWNLOAD_REPORT_DOWNLOADFILES', `${URLs.api}/api/data/downloadFilesZip`, undefined, "PUT", JSON.stringify(data)),
   
    //Support Download
    getSupportDownloadFiles: (data: any) => createApiBodyAction('GET_SUPPORT_DOWNLOADFILES', `${URLs.api}/api/data/GetFoldersByMaskOnly`, undefined, "POST", JSON.stringify(data)),
    deleteSupportDownloadFiles: (data: any) => createApiBodyAction('DELETE_SUPPORT_DOWNLOADFILES', `${URLs.api}/api/data/DeleteSupportDownloadFiles`, undefined, "POST", JSON.stringify(data)),
    encryptSupportDownloadFiles: (data: any) => createApiBodyAction('ENCRYPT_SUPPORT_DOWNLOADFILES', `${URLs.api}/api/data/EncryptSupportDownloadFiles`, undefined, "POST", JSON.stringify(data)),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IDownloadFilesUIState, KnownActions> = (state: IDownloadFilesUIState | undefined, action: KnownActions) => {
    if (state !== undefined) {
        switch (action.type) {

            case 'SELECT_DOWNLOAD_TAB_INDEX':
                return {
                    ...state,
                    selectedTabIndex: action.data,
                }
                break;

            //Load DeIDEncryptEnabled
            case 'GET_DEID_ENCRYPT_ENABLED':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                            break;

                        case "SUCCESS":
                            {
                                const responseData = action?.responseData as string;
                                if (responseData) {
                                    return {
                                        ...state,
                                        deIDEncryptEnabled: JSON.parse(responseData),
                                    }
                                } else {
                                    console.log('DeIDEncryptEnabled - No data in request..');
                                }
                                break;
                            }
                        case "FAIL":
                            {
                                console.log(action, 'Failed to retrieve DeIDEncryptEnabled data.');
                                break;
                            }
                    }
                    break;
                }

            //Search Report DownloadFiles Results
            case 'GET_REPORT_DOWNLOADFILES':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                            break;

                        case "SUCCESS":
                            {
                                const responseData = action?.responseData;

                                if (responseData) {
                                    console.log('responseCommands', responseData)
                                    return {
                                        ...state,
                                        ReportDownloadFiles: responseData
                                    }
                                } else {
                                    console.log('where\'s ma data???');
                                }
                                break;
                            }
                        case "FAIL":
                            {
                                console.log(action, 'Failed to retrieve DeIDEncryptEnabled data.');
                                break;
                            }
                    }
                    break;
                }
            case 'ENCRYPT_REPORT_DOWNLOADFILES':
                switch (action.status.status) {
                    case "VALIDATE":
                    case "REQUEST":
                        return {
                            ...state,
                        }
                    case "SUCCESS":
                        let isEncryptSuccess = action.responseData.resultId;
                        console.log('zipReportFileName', isEncryptSuccess);
                        return {
                            ...state,
                            zipReportFileName: isEncryptSuccess
                        }
                    case "FAIL":
                        console.log("Error: failed to encrypt report download files");
                        return {
                            ...state,
                            zipReportFileName: action.responseData,
                        }
                    default:
                        return state;
                }
            case 'DOWNLOAD_REPORT_DOWNLOADFILES':
                switch (action.status.status) {
                    case "VALIDATE":
                    case "REQUEST":
                        return {
                            ...state,
                        }
                    case "SUCCESS":
                        var filePath = action.responseData.resultId;
                        console.log('filePath', filePath)
                        console.log('zipDownloadFilePath-filePath', filePath)
                        
                        return {
                            ...state,
                            zipDownloadFilePath: filePath
                        }
                        
                    case "FAIL":
                        console.log("Error: failed to download report download file path");
                        return {
                            ...state,
                            zipDownloadFilePath: action.responseData,
                        }
                    default:
                        return state;

                }
            case 'DELETE_REPORT_DOWNLOADFILES':
                switch (action.status.status) {
                    case "VALIDATE":
                    case "REQUEST":
                        return {
                            ...state,
                        }
                    case "SUCCESS":
                        let isDeleteSuccess = action.responseData.resultId;
                        return {
                            ...state,
                            isReportDeleteSuccess: isDeleteSuccess
                        }
                    case "FAIL":
                        console.log("Error: failed to delete report download files");
                        return {
                            ...state,
                            isReportDeleteSuccess: action.responseData,
                        }
                    default:
                        return state;
                }

            case 'SELECTED_SEARCH_REPORT_FROMDATE':

                return {
                    ...state,
                    sendFromDate: action.data.uiData.fromDate,
                };
                break;

            case 'SELECTED_SEARCH_REPORT_TODATE':
                return {
                    ...state,
                    sendToDate: action.data.uiData.toDate,
                }
                break;
            case 'SELECTED_SEARCH_REPORT_FILENAME':

                return {
                    ...state,
                    sendFileName: action.data.uiData.fileName,
                };
                break;

            case 'SELECTED_SEARCH_REPORT_FILETYPE':
                return {
                    ...state,
                    sendFileType: action.data.uiData.fileType,
                }
                break;

            //Support Download Files
            //Search Report DownloadFiles Results
            case 'GET_SUPPORT_DOWNLOADFILES':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                            break;

                        case "SUCCESS":
                            {
                                const responseData = action?.responseData;

                                if (responseData) {
                                    console.log('support download', responseData)
                                    return {
                                        ...state,
                                        SupportDownloadFiles: responseData
                                    }
                                } else {
                                    console.log('where\'s ma data???');
                                }
                                break;
                            }
                        case "FAIL":
                            {
                                console.log(action, 'Failed to retrieve DeIDEncryptEnabled data.');
                                break;
                            }
                    }
                    break;
                }

            case 'DELETE_SUPPORT_DOWNLOADFILES':
                switch (action.status.status) {
                    case "VALIDATE":
                    case "REQUEST":
                        return {
                            ...state,
                        }
                    case "SUCCESS":
                        let isDeleteSuccess = action.responseData;
                        return {
                            ...state,
                            isDeleteSuccess: isDeleteSuccess
                        }
                    case "FAIL":
                        console.log("Error: failed to delete support download files");
                        return {
                            ...state,
                            isDeleteSuccess: action.responseData,
                        }
                    default:
                        return state;
                }

            case 'ENCRYPT_SUPPORT_DOWNLOADFILES':
                switch (action.status.status) {
                    case "VALIDATE":
                    case "REQUEST":
                        return {
                            ...state,
                        }
                    case "SUCCESS":
                        let isEncryptSuccess = action.responseData;
                        return {
                            ...state,
                            zipFileName: isEncryptSuccess
                        }
                    case "FAIL":
                        console.log("Error: failed to encrypt support download files");
                        return {
                            ...state,
                            zipFileName: action.responseData,
                        }
                    default:
                        return state;
                }

            default:
                return state;
        }
    }
    return state || defaultState;
}