import * as React from 'react';
import styled from 'styled-components';
import { DialogWrapper } from '../common/DialogWrapper';
import MeterComponent from '../../commonResources/commonGraphing/meterComponent/MeterComponent';
import { Input, Checkbox } from '@optum-uicl/ui-core/dist';
import _ from 'lodash';

const diameterdomID = ""+_.uniqueId();
const rangedomID = "" +_.uniqueId();
const valuedomID = "" +_.uniqueId();
const headerdomID = "" +_.uniqueId();
const footerdomID = "" + _.uniqueId();
const safedomID = "" + _.uniqueId();
const warningdomID = "" + _.uniqueId();
const dangerdomID = "" + _.uniqueId();

interface IComponentProps {
};

interface IIndexable {
    [key: string]: any;
}

interface IComponentState {
    diameter: number;
    range: number;
    value: number;
    header: string;
    safe: number;
    warning: number;
    danger: number;
    footerPrefix: string;
    low: number;
    high: number;
    isBoundariesDisabled: boolean;
} 

export default class MeterComponentDemo extends React.Component<IComponentProps, IComponentState | IIndexable> {
    constructor(props: IComponentProps) {
        super(props);
        const range = 1;
        const value = Math.ceil(Math.random() * range);
        const low = 0.33333;
        const high = 0.66666;

        this.state = {
            diameter: 350,
            range,
            value,
            low,
            high,
            header: "Unreleased Dollars ",
            footerPrefix: "Total: $",
            isBoundariesDisabled: false,
        }
    }
    
    strOnChange = (e: { target: { name: string, value: string } }) => {
        
        this.setState({ [e.target.name]: "" + e.target.value })
    }

    numOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        this.setState({ [name]: +value });
    }

    render() {
        const {
            low,
            high,
            diameter,
            value,
            header,
            footerPrefix,
            isBoundariesDisabled
        } = this.state

        // RegEx is to format numbers with comma separator
        let newFooter = footerPrefix + value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        const margin = 10;

        return (
            <DialogWrapper title="Meter Component Demo" >
                <div style={{ display: "flex", flexDirection: "column", justifyContent:"center", alignItems:"center" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ margin }}>
                            <Input domID={diameterdomID} type="number" label={"diameter:"} name={"diameter"} value={this.state.diameter} onChange={this.numOnChange} />
                        </div>
                    
                        <div style={{ margin }}>
                            <Input domID={valuedomID} type="number" label={"value:"} name={"value"} value={value} onChange={this.numOnChange} />
                        </div>
                        <div style={{ margin }}>
                            <Input domID={headerdomID} type="text" label={"header:"} name={"header"} value={header} onChange={this.strOnChange} />
                        </div>
                        <div style={{ margin }}>
                            <Input domID={footerdomID} type="text" label={"footer prefix:"} name={"footerPrefix"} value={footerPrefix} onChange={this.strOnChange} />
                        </div>
                       
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyItems: "center" }}>
                        <h5 style={{ marginTop: "20px" }}>Boundaries:</h5>
                        <div style={{ margin }}>
                            <Input domID={safedomID} type="number" label={"low:"} name={"low"} value={low} onChange={this.numOnChange}  />
                        </div>
                        <div style={{ margin }}>
                            <Input domID={warningdomID} type="number" label={"high:"} name={"high"} value={high} onChange={this.numOnChange} />
                        </div>
                    </div>

                    <div>
                        <Checkbox  label={"Disable Boundaries"} onChange={() => this.setState({ isBoundariesDisabled: !this.state.isBoundariesDisabled })} />
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin }}>
                        <MeterComponent header={header} footer={newFooter} diameter={diameter} value={value} low={low} high={high} isBoundariesDisabled={isBoundariesDisabled} />
                    </div>
                </div>
            </DialogWrapper>
        );
    };
};
