import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '@commonResources/colorVariables';
import { Typography } from '@commonResources/typography';
import { ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';

export interface IRadioButtonComponentProps {
    id: string;
    name?: string;
    checked: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    label?: string;
    labelBefore?: string;
    labelAbove?: string;
    width?: string;
    disabled: boolean;
    onDoubleClick?: any;
    className?: string;
    maxContent: string | undefined; 
};

export interface IRadioButtonComponentState {
    disabled: boolean;
};

export interface IRadioButtonWrapperProps {
    widthValue?: string;
    className?: string;
    onDoubleClick?: any;
};

interface IRadioButtonLabelProps {
    id?: string;
    disabled?: boolean;
    maxContent?: string;
};
//---------------------------------------------------------------

export const RadioButtonWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;

    flex-direction: row;
    height: 20px;
    xxmargin: 0 auto;
    min-width: 20px; 
 

    ${(props: IRadioButtonWrapperProps) => props.widthValue && `width: ${props.widthValue}`};
`;

export const RadioButtonLabel = styled.label<IRadioButtonLabelProps>`
    padding-left:3px;
    padding-top: 2px;
    font-size: 14px;
    ${Typography.ARMFontFamily};
    ${Typography.bold};
    ${(p) => p.maxContent && `width: ${p.maxContent}`};
    ${(p) => p.disabled ? `color: rgb(146, 148, 150) !important; opacity: 0.9` : `color: ${Colors.grey100}`};
    font-weight: 400;
`;
    


    

export const RadioButtonLabelBefore = styled.label`
    padding-left:3px;
    padding-top: 2px;
    
    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.ARMFontFamily};
    ${Typography.bold};
    
`;


const RadioButtonLabelAbove = styled.label`
    padding-left: 2px;
    padding-top: 2px;

    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.ARMFontFamily};
    ${Typography.bold};
`;

export const RadioButtonInput = styled.input`
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 15px;
    width: 15px;
    padding-right: 5px;
	border: 1;
    border-radius: 50px;
    outline: none;
    accent-color: rgb(12, 85, 184);
    :before {
         content: "";
         position: absolute;
         border-radius: 14px;
         top: 0;
         left: 0;
         background-color: white;
		}
    :checked:after {
         content: "";
         border-radius: 5px;
         border-width: 0 2px 2px 0;
         -webkit-transform: rotate(45deg);
         -ms-transform: rotate(45deg);
         transform: rotate(45deg);
         position: absolute;
         top: 2px;
         left: 5px;
	}
`;


export class RadioButtonComponent extends React.Component<IRadioButtonComponentProps, IRadioButtonComponentState>
{    
    static defaultProps: IRadioButtonComponentProps = {
        id: "",
        checked: false,
        width: "98px",
        disabled: false,
        maxContent: undefined
    };

    constructor(props: IRadioButtonComponentProps) {
        super(props);
        this.state = {
            disabled: this.props.disabled || false,
        }
    }


    renderAbove() {
        return (
            <RadioButtonWrapper
                widthValue={this.props.width}
                className={this.props.className ?? ""}
                id={this.props.id + "_wrapper"}
                onDoubleClick={(e: any) => { if (!this.props.disabled && this.props.onDoubleClick) { this.props.onDoubleClick() } }}
            >
                <ContentColumnWrapper>
                    <RadioButtonLabelAbove
                        id={this.props.id + "_label"}
                        htmlFor={(this.props.name && this.props.name.length > 0) ? this.props.id : ''}
                    >
                        {this.props.labelAbove}
                    </RadioButtonLabelAbove>
                    <RadioButtonInput
                        id={this.props.id}
                        name={this.props.name}
                        className={this.props.className ?? ""}
                        type="radio"
                        checked={this.props.checked}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (this.props.onChange) this.props.onChange(e) }}
                        disabled={this.props.disabled}
                    />
                </ContentColumnWrapper>
            </RadioButtonWrapper>
        );

    }

    renderBefore() {
        return (
            <RadioButtonWrapper
                widthValue={this.props.width}
                className={this.props.className ?? ""}
                id={this.props.id + "_wrapper"}
                onDoubleClick={(e: any) => { if (!this.props.disabled && this.props.onDoubleClick) { this.props.onDoubleClick() } }}
            >
                <RadioButtonLabelBefore
                    id={this.props.id + "_label"}
                    htmlFor={(this.props.name && this.props.name?.length > 0) ? this.props.id : ''}
                >
                    {this.props.labelBefore}
                </RadioButtonLabelBefore>
                <RadioButtonInput
                    id={this.props.id}
                    name={this.props.name}
                    className={this.props.className ?? ""}
                    type="radio"
                    checked={this.props.checked}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (this.props.onChange) this.props.onChange(e) }}
                    disabled={this.props.disabled}
                />

            </RadioButtonWrapper>
        );

    }
    renderOriginal() {
        return (
            <RadioButtonWrapper
                widthValue={this.props.width}
                
                id={this.props.id + "_wrapper"}
                onDoubleClick={(e: any) => { if (!this.props.disabled && this.props.onDoubleClick) { this.props.onDoubleClick() } }}
            >
                <RadioButtonInput
                    id={this.props.id}
                    name={this.props.name}
                    className={this.props.className ?? ""}
                    type="radio"
                    checked={this.props.checked}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (this.props.onChange) this.props.onChange(e) }}
                    disabled={this.props.disabled}
                />
                <RadioButtonLabel
                    maxContent={this.props.maxContent}
                    id={this.props.id + "_label"}
                    htmlFor={(this.props.name && this.props.name?.length > 0 )? this.props.id : ''}
                    disabled={this.props.disabled}
                >
                    {this.props.label}
                </RadioButtonLabel>
            </RadioButtonWrapper>
        );

    }

    render() {
        if (this.props.labelAbove && this.props.labelAbove.length > 0) {
            return this.renderAbove();
        } else if (this.props.labelBefore && this.props.labelBefore.length > 0) {
            return this.renderBefore();
        } else {
            return this.renderOriginal();
        }
    }
};