import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons, ContentColumnWrapper, ContentRowWrapper } from '@common/DialogWrapper';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps } from '@scripts/util/CrudComponentHelpers';
import { connect } from 'react-redux';
import { SelectDropdown, Button, Modal } from '@optum-uicl/ui-core/dist';
import { ApplicationState } from '@store/index';
import { IJobQueueState, IJobQueueActionProps, actionCreators } from '@store/JobQueue';
import { URLs } from '@commonDevResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';
import { DialogFieldset } from '@common/DialogStyles';
import { ARMGrid } from '@common/UICLWrappers/ARMGrid';
import { ARMHeaderCell } from '@common/UICLWrappers/ARMHeaderCell';
import { pageLeave } from '@commonResources/userModified';
import { AssuranceMenu } from '@common/AssuranceMenu';
import { ARMDatePicker } from '@common/DateOrTimeRelated/ARMDatePicker';
import { FooterInstructions } from '@common/FooterInstructions';
import { JobType, JobState, Server, Client, JobQueueData, Command, IInsertJobRequest, ILoadJobQueueRequest, IRowProps } from './JobQueueEntities';
import { ICookies_Config, IConfigData_Cookie } from '@store/ConfigData';
import { Colors } from '@commonResources/colorVariables';
import { Typography } from '@commonResources/typography';
import { JobDetailsDiv } from '../JobDetails/JobDetailsDiv';
import { createGlobalStyle } from 'styled-components';
import { ARMRowCell } from '@common/UICLWrappers/ARMRowCell';
import { SortHelper, ISortIndicator, SortDataType, SortOrder } from "@scripts/util/SortHelper"
import { isEmpty } from 'lodash';

const ModalWrapper = createGlobalStyle<{ width: string, height: string }>`
    #job-details-modal .active-content {
        width: 85%;
        padding: 1rem 1.5rem;
        transform-style: preserve-3d;
        transition: transform 300ms;
        margin: 5em auto;
        max-width: 85%;
        height: auto;
        overflow-y: auto;

    }

     #job-details-modal .active-content > div:nth-of-type(3) { 
        width: 100% !important; 
    }
`;

const IMG = styled.img`
    vertical-align: bottom;
    margin-left:0px;
    padding-right: 2px !important;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin-bottom: 10px;
    #jobQueue-id {
        overflow: 'auto',
        padding: '1px',
    }

    .JobType {
        vertical-align: inherit;
        width:233px;
    }
    .State {
        vertical-align: inherit;
        width:117px;
    }
    .CID {
        vertical-align: inherit;
        width:117px;
    }
    .Server {
        vertical-align: inherit;
        width:117px;
    }

    .UserName, .QueuedDate, .StartDateTime, .EndDateTime, .CommandList {
        vertical-align: inherit;
    }
    
    .selectDropdown {
        vertical-align: inherit;
    }

    select {
        font-size: 12px;
        font-weight: 600;
    }
    
    .link {
        ${ Typography.defaultLineHeight};
        ${ Typography.medium};
        color: ${ Colors.brightBlue130};
        padding: 0px;
        text-decoration: underline;
        &: hover {
            text-decoration-color: ${ Colors.babyBlue};
            {cursor: pointer;}
        }
    }
    
    .queued {
        margin-bottom: 2px;   
    }

    .CommandList {
        z-index: 20;
        width: 120px;
    }
    
    #command-dropdown {    
        width:150px;
    }

    #refresh-job {
        width:45px;
        height:20px;
    }

    button {
        font-weight: 400;
        text-transform: capitalize;
    }
    button > .caret-span {
        fill-opacity: 0;
    }
    button:hover > .caret-span {
        fill-opacity: 1;
    }
`;

interface IComponentProps {
    // Job Queue Security Bits
    canView: boolean;   // SecurityBits.FN_VIEW_JOBQUEUE
    canEdit: boolean;   // SecurityBits.FN_EDIT_JOBQUEUE
};

interface IComponentState {
    cancelLeave: boolean;
    isOpen: boolean;

    isNDCUser: boolean;
    selectedJobType: string;
    selectedJobState: string;
    selectedClient: string;
    selectedServer: string;

    allJobTypes: JobType[];
    allJobStates: JobState[];
    allClients: Client[];
    allServers: Server[];
    allJobs: JobQueueData[];
    filteredJobs: JobQueueData[];

    serverTime: string;
    loadJobQueueAPIData: any;
    sortIndicator: {
        sortColumn: string,
        sortDirection: string
    };

    jobIndex: number;
    totalJobs: number;
    pageIndex: number;
    pageTotal: number;
    
    selectedFromDate: string;
    selectedToDate: string;
    displayModal: boolean;
    dirty: boolean;
    isRefresh: boolean;

    jobAPIDetailInfo: string;
    commandSelected: boolean;
    
    selectedJobId: string | undefined;
    modalWidth: string;
    modalHeight: string;
    isLoading: boolean;
}

interface HeaderProps { columns: {} }

interface IDropDownItem {
    label: string,
    value: string,
    commandData: Command[]
}

interface ICookies_Container {
    cookies?: IConfigData_Cookie[];
    config?: ICookies_Config[];
}

export const DEFAULT_STATE: IComponentState = {
    cancelLeave: false,
    isOpen: false,
    isNDCUser: false,
    selectedJobType: '- Job Type -',
    selectedJobState: '- State -',
    selectedClient: '- CID -',
    selectedServer: '- Server -',
    loadJobQueueAPIData: '',
    sortIndicator: {
        sortColumn: '',
        sortDirection: '',
    },
    jobIndex: 1,
    totalJobs: 0,
    pageIndex: 1,
    pageTotal: 1,
    allJobTypes: [],
    allJobStates: [],
    allClients: [],
    allServers: [],
    allJobs: [],
    filteredJobs: [],
    serverTime: '',
    dirty: false,
    isRefresh: false,
    
    selectedFromDate: '',
    selectedToDate: '',
    displayModal: false,
    jobAPIDetailInfo: '',
    commandSelected: false,
    selectedJobId: undefined,
    modalWidth: '200px',
    modalHeight: '200px',
    isLoading: false
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IJobQueueProps = IMergeCrudComponentProps<IJobQueueState, IJobQueueActionProps, IOwnProps>;

export interface IJobQueueComponentProps {
};

export interface IJobQueueContentProps {
};

export class JobQueue extends React.Component<IJobQueueProps, IComponentState> {
    protected selectedJobs: Array<IInsertJobRequest> = [];
    readonly PAGE_SIZE = 100;

    static defaultProps: IComponentProps = {
        // Job Queue Security Bits
        canView: false,                     // SecurityBits.FN_VIEW_JOBQUEUE
        canEdit: false,                     // SecurityBits.FN_EDIT_JOBQUEUE
    };

    minDate: string;
    maxDate: string;
    clientAlias: string;

    constructor(props: IJobQueueProps) {
        super(props);
        this.state = DEFAULT_STATE;
       
        this.minDate = this.dateAdd(new Date(), 'm', -1);
        this.maxDate = this.getDateInMMDDYYYYHHMMSSFormat(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));

        this.clientAlias = sessionStorage.getItem("ClientAlias") ?? "";
        
        
        this.handleSort = this.handleSort.bind(this);
        this.initialize = this.initialize.bind(this);
    }

    public componentDidMount() {
        if (this.props.canView) {
           
            this.configData();
            this.handleJobDetailsRequest();
        }
        pageLeave();
    }

    handleJobDetailsRequest() {
        this.setState({ isLoading: true });
        const pathname = this.props.location.pathname;
        const lastPathPart = pathname.split('/').slice(-1)[0];
        const requestedJobId = Number(lastPathPart);
        if (Number(requestedJobId)) {
            this.displayJobDetailsModal(requestedJobId.toString());
        }
        this.setState({ isLoading: false });
    }

    public componentWillUnmount() {
        pageLeave();
    }

    public getDateInMMDDYYYYHHMMSSFormat(input: any): string {
        try {
            var month = input.getMonth() + 1;
            var day = input.getDate();
            var hour = input.getHours();
            var year = input.getFullYear();
            var minute = input.getMinutes();
            var seconds = input.getSeconds();

            return (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year + ' ' + hour + ':' + minute + ':' + seconds; 
        }
        catch (e) {
            console.error('Unable to properly format date');
        }
        return "00/00/0000 00:00:00";
    }

    public getDateInMMDDYYYYFormat(input: any): string {
        try {
            var month = input.getMonth() + 1;
            var day = input.getDate();
            var year = input.getFullYear();

            return (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year;
        }
        catch (e) {
            console.error('Unable to properly format date');
        }
        return "00/00/0000";
    }

    public dateAdd(date: any, type: string, amount: number) {
        try {
            var y = date.getFullYear(),
                m = date.getMonth(),
                d = date.getDate();
            if (type === 'y') {
                y += amount;
            };
            if (type === 'm') {
                m += amount;
                if (m <= 0) {
                    m += 12;
                    y--;
                }
                else if (m > 12) {
                    m -= 12;
                    y++;
                }
            };
            if (type === 'd') {
                d += amount;
            };
            var xx = new Date(y, m, d);
            return this.getDateInMMDDYYYYFormat(xx);
        }
        catch (e) {
            console.error('Unable to properly format date, dateAdd');
        }
        return "00/00/0000";
    }

    configData = async () => {
        var url = URLs.api + '/api/data/securityInfo/getCookies';

        var data: ICookies_Container = {
            cookies: [],
            config: [{
                name: "isClientUser",
                value: '',
           }]
        };

        await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: JSON.stringify(data),
        })
        .then(async response => {
            if (response.status === 200) {
                const data: any = await response.json();
                let isNDCUser: boolean = (data.config[0].name === "isClientUser" && data.config[0].value === "False") ? true : false;
                this.setState({ isNDCUser: isNDCUser, selectedClient: this.clientAlias }, () => {
                    this.LoadJobQueue(true);
                });
            }
        })
        .catch(error => {
            console.error(`promise rejected: URL:${url} with Error: ${error}`);
        });
    }

    jobIndex = (pageIndex: number): number => {
        const { pageTotal } = this.state;
        let jobIndex: number = 1;
        if (pageIndex <= pageTotal) {
            jobIndex = this.PAGE_SIZE * (pageIndex - 1) + 1;
        }
        return jobIndex;
    }

    Today = (): string => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        return mm + '/' + dd + '/' + yyyy;
    }

    LoadJobQueue = async (firstTime:boolean = false) => {
        const { isNDCUser, selectedToDate, selectedFromDate, jobIndex, selectedJobType, selectedJobState, selectedClient, selectedServer } = this.state;

        
        let selectedJobTypeID: string = this.selectedJobTypeID(selectedJobType);
        let selectedJobStateID: string = this.selectedJobStateID(selectedJobState);
        let selectedClientID: string = firstTime ? "-1":this.selectedClientID(selectedClient);

        let loadJobQueueRequest: ILoadJobQueueRequest = {
            StartRecord: jobIndex.toString(),
            JobsPerPage: '100',
            JobTypeId: selectedJobTypeID,
            JobStateId: selectedJobStateID,
            ClientId: selectedClientID,
            OrderBy:'0',
            OrderType: '1',
            QueueFrom: selectedFromDate === '' ? this.Today() : selectedFromDate,
            QueueTo: selectedToDate === '' ? this.Today() : selectedToDate,
        };

        if (isNDCUser) {
            let selectedServerID: string = this.selectedServerID(selectedServer);
            loadJobQueueRequest.AppServerId = selectedServerID;
        }

        var url = URLs.api + '/api/data/LoadJobQueueList';
        let requestString = JSON.stringify(loadJobQueueRequest);
        await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: requestString,
        })
        .then(async response => {
            if (response.status === 200) {
                const data: any = await response.json();
                let responseData: string = JSON.stringify(data);
                this.setState({ loadJobQueueAPIData: JSON.parse(responseData) }, () => {
                    this.initialize();
                });
            }
        })
        .catch(error => {
            console.error(`promise rejected: URL:${url} with Error: ${error}`);
        });
    }

    InsertJobs = async () => {
        if (this.selectedJobs && this.selectedJobs.length > 0) {
            var url = URLs.api + '/api/data/InsertJobQueue';
            let requestJSON: string = JSON.stringify(this.selectedJobs);
            await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: requestJSON,
            })
            .then(async response => {
                if (response.status === 200) {
                    console.log(`Insert Job Request ${requestJSON} Processed Sucessfully`)
                }
            })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
            });
            this.setState({ commandSelected: false }, () => {
                this.onClickRefresh();
                this.selectedJobs = [];
            });
        } else {
            console.warn(`Insert Job Command Request is Empty.`);
        }
    }

    JobDetails = async (jobid: string) => {
        var url = URLs.api + '/api/data/JobDetails?jobid=' + jobid;
        await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        })
        .then(async response => {
            if (response.status === 200) {
                 const data: any = await response.json();
                let responseData: string = JSON.stringify(data);
                this.setState({ jobAPIDetailInfo: responseData }, () => {
                    console.log(`JobDetails: ${url} with Response: ${responseData}`);
                });
            }
        })
        .catch(error => {
            console.error(`promise rejected: URL:${url} with Error: ${error}`);
        });
    }

    CommandOptions = (commandsData: Command[]): IDropDownItem[] => {
        const commands: IDropDownItem[] = [{ label: '', value: '', commandData: commandsData }];
        if (commandsData && commandsData.length > 0) {
            for (let idx = 0; idx < commandsData.length; idx++) {
                const command: IDropDownItem = { label: commandsData[idx].Name, value: commandsData[idx].ID, commandData: commandsData };
                commands.push(command);
            }
        }
        return commands;
    }
    
    SelectedCommandOption = (e: any, id: string): IDropDownItem => {
        let command: IDropDownItem = { label: '', value: '', commandData: e.commandData };
        if (e.commandData && e.commanData.length > 0) {
            for (let idx = 0; idx < e.commandData.length; idx++) {
                const foundJob = this.selectedJobs.find(job => job.ID === id);
                if (foundJob) {
                    const foundCommand = e.commandData.find((cmd: Command) => cmd.ID == e.value && cmd.Name == e.label);
                    if (foundCommand) {
                        command = { label: foundCommand.Name, value: foundCommand.ID, commandData: e.commandData };
                    }
                }
            }
        }
        return command;
    }

    onCommandChange(job: JobQueueData, e: any) {
        let commandLabel: string = e.label ?? '';
        let commandValue: string = e.value ?? '';
        const jobID = job.ID;
        const appServerId: string = job.AppServerId;

        let commandRequest: IInsertJobRequest = {
            ID: jobID,
            Command: commandValue,
            Server: appServerId,
        }

        const foundJob = this.selectedJobs.some(job => job.ID === commandRequest.ID);
        if (!foundJob) {
            if (!isEmpty(commandValue)) {
                this.selectedJobs.push(commandRequest);
            }
        } else {
            const sameCommand = this.selectedJobs.some(job => job.ID === commandRequest.ID && job.Command === commandRequest.Command);
            if (!sameCommand) {
                let jobIndex = this.selectedJobs.findIndex(job => job.ID === commandRequest.ID);
                if (jobIndex !== -1) {
                    this.selectedJobs.splice(jobIndex, 1);
                }
                if (!isEmpty(commandValue)) {
                    this.selectedJobs.push(commandRequest);
                }
            }
        }
        
    }

    initialize = () => {
        const { isNDCUser, loadJobQueueAPIData, selectedFromDate, selectedToDate, dirty } = this.state;

        let jobTypes: JobType[] = new Array();
        let jobStates: JobState[] = new Array();
        let clients: Client[] = new Array();

        if (!dirty) {
            jobTypes.push(new JobType('0', '- Job Type -'));
            jobStates.push(new JobState('0', '- State -'));
            if (isNDCUser) {
                clients.push(new Client('0', '- CID -'));
            }
        }

        let allJobs: JobQueueData[] = new Array();
               
        let serverTime: string = new Date().toLocaleString();
        let totalJobs: number = 1;

        if (loadJobQueueAPIData) {

            serverTime = loadJobQueueAPIData.ServerTime;
            
            if (!dirty) {
                // Set All Job Types
                if (loadJobQueueAPIData.JobTypeList && loadJobQueueAPIData.JobTypeList.JobTypeList && loadJobQueueAPIData.JobTypeList.JobTypeList.length > 0) {
                    for (let idx = 0; idx < loadJobQueueAPIData.JobTypeList.JobTypeList.length; idx++) {
                        jobTypes.push(loadJobQueueAPIData.JobTypeList.JobTypeList[idx]);
                    }
                }

                this.setState({ allJobTypes: jobTypes });

                // Set All Job States
                if (loadJobQueueAPIData.JobStateList && loadJobQueueAPIData.JobStateList.JobStateList && loadJobQueueAPIData.JobStateList.JobStateList.length > 0) {
                    for (let idx = 0; idx < loadJobQueueAPIData.JobStateList.JobStateList.length; idx++) {
                        jobStates.push(loadJobQueueAPIData.JobStateList.JobStateList[idx]);
                    }
                }

                this.setState({ allJobStates: jobStates });

                if (isNDCUser) {

                    let servers: Server[] = new Array();
                    servers.push(new Server('0', '- Server -'));

                    // Set All Application Servers
                    if (loadJobQueueAPIData.AppServerList && loadJobQueueAPIData.AppServerList.AppServerList && loadJobQueueAPIData.AppServerList.AppServerList.length > 0) {
                        for (let idx = 0; idx < loadJobQueueAPIData.AppServerList.AppServerList.length; idx++) {
                            servers.push(loadJobQueueAPIData.AppServerList.AppServerList[idx]);
                        }
                    }

                    this.setState({ allServers: servers });
                }
            
                // Set All Clients
                if (loadJobQueueAPIData.ClientList && loadJobQueueAPIData.ClientList.ClientList && loadJobQueueAPIData.ClientList.ClientList.length > 0) {
                    for (let idx = 0; idx < loadJobQueueAPIData.ClientList.ClientList.length; idx++) {
                        clients.push(loadJobQueueAPIData.ClientList.ClientList[idx]);
                    }
                }

                this.setState({ allClients: clients });
            }

            // Set All Jobs
            if (loadJobQueueAPIData.JobList) {

                totalJobs = +(loadJobQueueAPIData.JobList.Total);

                if (loadJobQueueAPIData.JobList.JobList && loadJobQueueAPIData.JobList.JobList.length > 0) {
                    loadJobQueueAPIData.JobList.JobList.map((job: JobQueueData) => {
                        let parentJob: JobQueueData = job;
                        parentJob.Name = job.JobName;
                        parentJob.JobName = <React.Fragment>
                            <span id={parentJob.ID} className="link" onClick={() => this.displayJobDetailsModal(parentJob.ID)}>
                                <b>{parentJob.JobName}</b>
                            </span>
                        </React.Fragment>

                        parentJob.StateValue = JSON.parse(JSON.stringify(parentJob.StateName));
                        if (parentJob.StateValue === "Queued" && parentJob.Enabled === "0") {
                            parentJob.StateName = <React.Fragment>{parentJob.StateName}&nbsp;<IMG src='/Shared/Images/Icons/smallIcon_Padlock3.gif' className="queued" alt="Disabled" title="Disabled" /></React.Fragment>
                        }

                        if (parentJob.StateId === "4" && +(parentJob.ProgressTotal) !== 0) { // StateId = 4 - Running
                            let jobCompletionPercentage = +(parentJob.ProgressCurrent) / +(parentJob.ProgressTotal) * 100;
                            parentJob.StateName = <React.Fragment>{parentJob.StateName}&nbsp;({jobCompletionPercentage.toFixed()}%)</React.Fragment>
                        }

                        let jobCommands: Command[] = parentJob.CommandList;
                        if (jobCommands && jobCommands.length > 0) {
                            // Handle Parent Job CommandList
                            parentJob.CommandList = <SelectDropdown
                                domID="command-dropdown"
                                className="dropdown"
                                menuWidth={150}
                                size='small'
                                isClearable={false}
                                isSearchable={false}
                                options={this.CommandOptions(jobCommands)}
                                onChange={(e: any) => this.onCommandChange(parentJob, e)}
                                initialValue={this.SelectedCommandOption(jobCommands, parentJob.ID)}
                                disabled={!this.props.canEdit}
                            />
                        }
                        else {
                            parentJob.CommandList = ""
                            this.setState({ commandSelected: false });
                        }

                        allJobs.push(parentJob);

                        // Handle Child Jobs
                        if (parentJob?.ChildJobs) {
                            parentJob.ChildJobs.map((cjob: JobQueueData) => {
                                let childJob: JobQueueData = cjob;
                                childJob.Name = cjob.JobName
                                childJob.JobName = <React.Fragment><span><IMG src='/shared/images/treeview/l.gif' /></span >
                                    <span id={childJob.ID} className="link" onClick={() => this.displayJobDetailsModal(childJob.ID)}><b>{childJob.JobName}</b></span></React.Fragment>

                                childJob.StateValue = JSON.parse(JSON.stringify(childJob.StateName));

                                if (childJob.StateValue === "Queued" && childJob.Enabled === "0") {
                                    childJob.StateName = <React.Fragment>{childJob.StateName}&nbsp;<IMG src='/Shared/Images/Icons/smallIcon_Padlock3.gif' className="queued" alt="Disabled" title="Disabled"/></React.Fragment>
                                }

                                if (childJob.StateId === "4" && +(childJob.ProgressTotal) !== 0) { // StateId = 4 - Running
                                    let jobCompletionPercentage = +(childJob.ProgressCurrent) / +(childJob.ProgressTotal) * 100;
                                    childJob.StateName = <React.Fragment>{childJob.StateName}&nbsp;({jobCompletionPercentage.toFixed()}%)</React.Fragment>
                                }

                                let childJobCommands: Command[] = childJob.CommandList;
                                if (childJobCommands && childJobCommands.length > 0) {
                                    // Handle Child Job CommandList
                                    childJob.CommandList = <SelectDropdown
                                        domID="command-dropdown"
                                        className="dropdown"
                                        menuWidth={150}
                                        size='small'
                                        isClearable={false}
                                        isSearchable={false}
                                        options={this.CommandOptions(childJobCommands)}
                                        onChange={(e: any) => this.onCommandChange(childJob, e)}
                                        initialValue={this.SelectedCommandOption(childJobCommands, childJob.ID)}
                                        disabled={!this.props.canEdit}
                                    />
                                }
                                else {
                                    childJob.CommandList = ''
                                    this.setState({ commandSelected: false });
                                }

                                allJobs.push(childJob)

                                // Handle GrandChild Jobs
                                if (childJob?.ChildJobs) {
                                    childJob.ChildJobs.map((gcjob: JobQueueData) => {
                                        let grandChildJob: JobQueueData = gcjob;
                                        grandChildJob.Name = gcjob.JobName;
                                        grandChildJob.JobName = <React.Fragment><span>&nbsp;&nbsp;&nbsp;&nbsp;<IMG src='/shared/images/treeview/l.gif' /></span >
                                            <span id={grandChildJob.ID} className="link" onClick={() => this.displayJobDetailsModal(grandChildJob.ID)}><b>{grandChildJob.JobName}</b></span></React.Fragment>

                                        grandChildJob.StateValue = JSON.parse(JSON.stringify(grandChildJob.StateName));

                                        if (grandChildJob.StateValue === "Queued" && grandChildJob.Enabled === "0") {
                                            grandChildJob.StateName = <React.Fragment>{grandChildJob.StateName}&nbsp;<IMG src='/Shared/Images/Icons/smallIcon_Padlock3.gif' className="queued" alt="Disabled" title="Disabled"/></React.Fragment>
                                        }

                                        if (grandChildJob.StateId === "4" && +(grandChildJob.ProgressTotal) !== 0) { // StateId = 4 - Running
                                            let jobCompletionPercentage = +(grandChildJob.ProgressCurrent) / +(grandChildJob.ProgressTotal) * 100;
                                            grandChildJob.StateName = <React.Fragment>{grandChildJob.StateName}&nbsp;({jobCompletionPercentage.toFixed()}%)</React.Fragment>
                                        }

                                        let grandChildJobCommands: Command[] = grandChildJob.CommandList;
                                        if (grandChildJobCommands && grandChildJobCommands.length > 0) {
                                            // Handle Grand Child Job CommandList
                                            grandChildJob.CommandList = <SelectDropdown
                                                domID="command-dropdown"
                                                className="dropdown"
                                                menuWidth={150}
                                                size='small'
                                                isClearable={false}
                                                isSearchable={false}
                                                options={this.CommandOptions(grandChildJobCommands)}
                                                onChange={(e: any) => this.onCommandChange(grandChildJob, e)}
                                                initialValue={this.SelectedCommandOption(grandChildJobCommands, grandChildJob.ID)}
                                                disabled={!this.props.canEdit}
                                            />
                                        }
                                        else {
                                            grandChildJob.CommandList = ''
                                            this.setState({ commandSelected: false });
                                        }

                                        allJobs.push(grandChildJob);
                                    })
                                }                              
                            })
                        }
                    })
                }
            }
        }
        
        this.setState({
            serverTime: serverTime,
            allJobs: allJobs,
            filteredJobs: allJobs,
            totalJobs: totalJobs,
            selectedFromDate: selectedFromDate === '' ? this.Today() : selectedFromDate,
            selectedToDate: selectedToDate === '' ? this.Today() : selectedToDate,
        }, () => {
            this.setState({
                pageTotal: Math.ceil(totalJobs / this.PAGE_SIZE) == 0 ? 1 : Math.ceil(totalJobs / this.PAGE_SIZE),
                })
        });
    }

    selectedJobTypeID(selectedJobType: string): string {
        const { allJobTypes } = this.state;
        let selectedJobTypeID: string = '';
        if (selectedJobType && selectedJobType.length > 0 && selectedJobType !== '- Job Type -') {
            let index: number = allJobTypes.findIndex(jobType => jobType.Name === selectedJobType);
            if (index != -1) {
                selectedJobTypeID = allJobTypes[index].ID;
            }
        }
        return selectedJobTypeID;
    }

    selectedJobStateID(selectedJobState: string): string {
        const { allJobStates } = this.state;
        let selectedJobStateID: string = '';
        if (selectedJobState && selectedJobState.length > 0 && selectedJobState !== '- State -') {
            let index: number = allJobStates.findIndex(jobState => jobState.Name === selectedJobState);
            if (index != -1) {
                selectedJobStateID = allJobStates[index].ID;
            }
        }
        return selectedJobStateID;
    }

    selectedClientID(selectedClient: string): string {
        const { allClients } = this.state;
        let selectedClientID: string = '';
        if (selectedClient && selectedClient.length > 0 && selectedClient !== '- CID -') {
            let index: number = allClients.findIndex(client => client.Name === selectedClient);
            if (index != -1) {
                selectedClientID = allClients[index].ID;
            }
        }
        return selectedClientID;
    }

    selectedServerID(selectedServer: string): string {
        const { allServers } = this.state;
        let selectedServerID: string = '';
        if (selectedServer && selectedServer.length > 0 && selectedServer !== '- Server -') {
            let index: number = allServers.findIndex(server => server.Name === selectedServer);
            if (index != -1) {
                selectedServerID = allServers[index].ID;
            }
        }
        return selectedServerID.toString();
    }

    onChangeJobType(selectedJobType: string = '- Job Type -'): JobQueueData[] {
        this.setState({ selectedJobType: selectedJobType, jobIndex: 1, dirty: true }, () => this.LoadJobQueue());
        return this.state.filteredJobs;     
    }

    OnChangeState(selectedState: string = '- State -'): JobQueueData[] {
        this.setState({ selectedJobState: selectedState, jobIndex: 1, dirty: true }, () => this.LoadJobQueue());
        return this.state.filteredJobs;
    }

    onChangeCID(selectedClient: string = '- CID -'): JobQueueData[] {
        this.setState({ selectedClient: selectedClient, jobIndex: 1, dirty: true }, () => this.LoadJobQueue());
        return this.state.filteredJobs;
    }

    onChangeServer(selectedServer: string = '- Server -'): JobQueueData[] {
        this.setState({ selectedServer: selectedServer, jobIndex: 1, dirty: true }, () => this.LoadJobQueue());
        return this.state.filteredJobs;
    }
    
    public onOKJobQueue(e: React.ChangeEvent<HTMLButtonElement>) {
        this.InsertJobs();
        this.LoadJobQueue();
        this.onClickRefresh();
    }

    public onCancelJobQueue(e: React.ChangeEvent<HTMLButtonElement>) {
        this.setState({ cancelLeave: true })
        this.props.history.push("/LandingPage");
    }

    public onClickConfirm(e: React.MouseEvent<HTMLButtonElement>) {
        this.props.dataStore.confirm.confirmCallback();
    }

    onToggleConfirmation(e: React.MouseEvent<HTMLElement>) {
        this.props.action.confirm.closeConfirm();
    }

    getJobQueueGridColumnHeaders() {
        const { isNDCUser } = this.state;

        let headers = new Set();

        const jobTypesElement = {
            dataName: "JobName",
            text: '- Job Type -',
            sortable: true,
            cellType: "custom",
            isSorted: 0,
        };

        const statesElement = {
            dataName: "StateName",
            text: "- State -",
            sortable: false,
            cellType: "text",
            isSorted: 1,
        };

        const clientsElement = {
            dataName: "ClientAlias",
            text: "- CID -",
            sortable: false,
            cellType: "text",
            isSorted: 1,
        };

        headers.add(jobTypesElement);
        headers.add(statesElement);
        headers.add(clientsElement);

        const serversElement = {
            dataName: "AppServerName",
            text: "- Server -",
            sortable: false,
            cellType: "text",
            isSorted: 1,
        };

        if (isNDCUser) {
            headers.add(serversElement);
        }

        const usersElement = {
            dataName: "UserName",
            text: "User Name",
            sortable: false,
            cellType: "text",
            isSorted: 0,
        };

        headers.add(usersElement);

        const queuedDatesElement = {
            dataName: "QueuedDate",
            text: "Queued Time",
            sortable: true,
            cellType: "text",
            isSorted: 0,
        };

        headers.add(queuedDatesElement);

        const startDateTimeElement = {
            dataName: "StartDateTime",
            text: "Start Time",
            sortable: true,
            cellType: "text",
            isSorted: 0,
        };

        headers.add(startDateTimeElement);

        const endDateTimeElement = {
            dataName: "EndDateTime",
            text: "End Time",
            sortable: true,
            cellType: "text",
            isSorted: 0,
        };

        headers.add(endDateTimeElement);

        const commandListElement = {
            dataName: "CommandList",
            text: "Command",
            sortable: false,
            cellType: "text",
            isSorted: 0,
        };

        if (isNDCUser || this.props.canEdit) {
            headers.add(commandListElement);
        }

        return headers;
    }

    public previousPage(e: React.ChangeEvent<HTMLButtonElement>) {
        const {
            pageTotal,
            pageIndex,
        } = this.state;

        if (pageTotal >= pageIndex) {
            this.setState({
                pageIndex: pageIndex - 1,
                jobIndex: this.jobIndex(pageIndex - 1),
            }, () => this.LoadJobQueue());
            e.target.value = pageIndex.toString();
        }
    }

    public nextPage(e: React.ChangeEvent<HTMLButtonElement>) {
        const {
            pageIndex,
            pageTotal
        } = this.state;

        if (pageIndex < pageTotal) {
            this.setState({
                pageIndex: pageIndex + 1,
                jobIndex: this.jobIndex(pageIndex + 1),
            }, () => this.LoadJobQueue());
            e.target.value = pageIndex.toString();
        }
    }

    public handleSort(sortIndicator: ISortIndicator) {
        const { filteredJobs } = this.state;

        let sortedRecords: JobQueueData[] = [];

        switch (sortIndicator.sortColumn) {
            case 'JobType':
                {
                    sortedRecords = sortIndicator.sortDirection == 'up' ?
                        filteredJobs.sort((a, b) => (a.ID.localeCompare(b.ID))) :
                        filteredJobs.sort((a, b) => (b.ID.localeCompare(a.ID)));
                }
                break;
            case 'StateName':
                {
                    sortedRecords = sortIndicator.sortDirection == 'up' ?
                        filteredJobs.sort((a, b) => a.StateValue.localeCompare(b.StateValue)) :
                        filteredJobs.sort((a, b) => b.StateValue.localeCompare(a.StateValue));
                }
                break;
            case 'ClientAlias':
                {
                    sortedRecords = sortIndicator.sortDirection == 'up' ?
                        filteredJobs.sort((a, b) => a.ClientAlias.localeCompare(b.ClientAlias)) :
                        filteredJobs.sort((a, b) => b.ClientAlias.localeCompare(a.ClientAlias));
                }
                break;
            case 'AppServerName':
                {
                    sortedRecords = sortIndicator.sortDirection == 'up' ?
                        filteredJobs.sort((a, b) => a.AppServerName.localeCompare(b.AppServerName)) :
                        filteredJobs.sort((a, b) => b.AppServerName.localeCompare(a.AppServerName));
                }
                break;
            case 'QueuedDate': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredJobs.sort((a, b) => a.QueuedDate.localeCompare(b.QueuedDate)) :
                    filteredJobs.sort((a, b) => b.QueuedDate.localeCompare(a.QueuedDate));
            }
            break;
            case 'StartDateTime': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredJobs.sort((a, b) => (a.StartDateTime).localeCompare(b.StartDateTime)) :
                    filteredJobs.sort((a, b) => (b.StartDateTime).localeCompare(a.StartDateTime));
            }
            break;
            case 'EndDateTime': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredJobs.sort((a, b) => a.EndDateTime.localeCompare(b.EndDateTime)) :
                    filteredJobs.sort((a, b) => b.EndDateTime.localeCompare(a.EndDateTime));
            }
            break;
        }

        this.setState({ sortIndicator, filteredJobs: sortedRecords });
    }

    public onDateChange(e: string, fromField: boolean) {
        var data: string = e ?? '';
       
        if (fromField && this.state.selectedFromDate === data) return;
        if (!fromField && this.state.selectedToDate === data) return;

        if (fromField === true) {
            this.setState({ selectedFromDate: data, dirty: true });
        }

        if (fromField === false) {
            this.setState({ selectedToDate: data, dirty: true });
        }
    }

    public displayJobDetailsModal(jobId: string) {
        this.setState({
            displayModal: true,
            selectedJobId: jobId
        });
    }

    public onClickRefresh() {
        this.setState({
            isRefresh: true,
            jobIndex: 1,
            pageIndex: 1,
            pageTotal: 1,
        },
        () => this.LoadJobQueue());
    }

    onModalSizeChange(modalWidth: string, modalHeight: string) {

        console.log("change modal width and height",modalWidth,
        modalHeight )
        this.setState({
            modalWidth,
            modalHeight
        })
    }

    getJobQueueCustomRow() {
        const { isNDCUser } = this.state

        const row = ({ record }: IRowProps) =>
            (record.StateName === 'Failed') ?
                <tr style={{ backgroundColor: '#f4c5c5', fontSize: '13px' }}>
                    <ARMRowCell value={record.JobName} style={{ backgroundColor: '#f4c5c5' }} />
                    <ARMRowCell value={record.StateName} style={{ backgroundColor: '#f4c5c5' }} />
                    <ARMRowCell value={record.ClientAlias} style={{ backgroundColor: '#f4c5c5' }} />
                    {
                        isNDCUser ? 
                            <ARMRowCell value={record.AppServerName} style={{ backgroundColor: '#f4c5c5' }} />
                        : ""                           
                    }
                    <ARMRowCell value={record.UserName} style={{ backgroundColor: '#f4c5c5' }} />
                    <ARMRowCell value={record.QueuedDate} style={{ backgroundColor: '#f4c5c5', width: '91px' }} />
                    <ARMRowCell value={record.StartDateTime} style={{ backgroundColor: '#f4c5c5', width: '91px' }} />
                    <ARMRowCell value={record.EndDateTime} style={{ backgroundColor: '#f4c5c5', width: '91px' }} />
                    <ARMRowCell value={record.CommandList} style={{ backgroundColor: '#f4c5c5' }} />
                </tr >
                :
                <tr style={{ backgroundColor: 'none', fontSize: '13px' }}>
                    <ARMRowCell value={record.JobName} style={{ backgroundColor: 'none' }} />
                    <ARMRowCell value={record.StateName} style={{ backgroundColor: 'none' }} />
                    <ARMRowCell value={record.ClientAlias} style={{ backgroundColor: 'none' }} />
                    {
                        isNDCUser ?
                            <ARMRowCell value={record.AppServerName} style={{ backgroundColor: 'none' }} />
                        : ""
                    }
                    <ARMRowCell value={record.UserName} style={{ backgroundColor: 'none' }} />
                    <ARMRowCell value={record.QueuedDate} style={{ backgroundColor: 'none', width: '91px' }} />
                    <ARMRowCell value={record.StartDateTime} style={{ backgroundColor: 'none', width: '91px'}} />
                    <ARMRowCell value={record.EndDateTime} style={{ backgroundColor: 'none', width: '91px' }} />
                    <ARMRowCell value={record.CommandList} style={{ backgroundColor: 'none' }} />
                </tr>
        return row;
    }

    public render() {
        var instruction = <React.Fragment>To sort, click on the column heads.</React.Fragment>;
        var buttons = <OKCancelButtons disableOK={ !this.props.canEdit }
            onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOKJobQueue(e)}
            onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancelJobQueue(e)} />;

        // Job Type
        const jobTypes: JobType[] = this.state.allJobTypes;
        const jobTypeOptions = jobTypes.map(job => <option key={job.ID + Math.random().toString(5).substr(2, 5)} value={job.Name} selected={this.state.selectedJobType === job.Name}>{job.Name}</option>);
        const jobTypeFilters =
            <select onChange={jobType => this.setState(
                {
                    selectedJobType: jobType.target.value,
                    filteredJobs: this.onChangeJobType(jobType.target.value),
                    pageIndex: 1,
                    pageTotal: Math.ceil(this.state.allJobs.length / this.PAGE_SIZE) == 0 ? 1 : Math.ceil(this.state.allJobs.length / this.PAGE_SIZE),
                })}>
                {jobTypeOptions}
            </select>;
        
        // State
        const stateData: JobState[] = this.state.allJobStates;
        const stateOptions = stateData.map(state => <option key={state.ID + Math.random().toString(5).substr(2, 5)} value={state.Name} selected={this.state.selectedJobState === state.Name}>{state.Name}</option>);
        const stateFilters =
            <select onChange={state => this.setState(
                {
                    selectedJobState: state.target.value,
                    filteredJobs: this.OnChangeState(state.target.value),
                    pageIndex: 1,
                    pageTotal: Math.ceil(this.state.allJobs.length / this.PAGE_SIZE) == 0 ? 1 : Math.ceil(this.state.allJobs.length / this.PAGE_SIZE),
                })}>
                {stateOptions}
            </select>;

        // Client
        const clientIDData: Client[] = this.state.allClients;
        const clientIDOptions = clientIDData.map(cid => <option key={cid.ID + Math.random().toString(5).substr(2, 5)} value={cid.Name} selected={this.state.selectedClient === cid.Name}>{cid.Name}</option>);
        const clientIDFilters =
            <select onChange={cid => this.setState(
                {
                    selectedClient: cid.target.value,
                    filteredJobs: this.onChangeCID(cid.target.value),
                    pageIndex: 1,
                    pageTotal: Math.ceil(this.state.allJobs.length / this.PAGE_SIZE) == 0 ? 1 : Math.ceil(this.state.allJobs.length / this.PAGE_SIZE),
                })}>
                {clientIDOptions}
            </select>;

       
        // Server
        const serverData: Server[] = this.state.allServers;
        const serverOptions = serverData.map(server => <option key={server.ID + Math.random().toString(5).substr(2, 5)} value={server.Name} selected={this.state.selectedServer === server.Name}>{server.Name}</option>);
        const serverFilters =
            <select onChange={server => this.setState(
                {
                    selectedServer: server.target.value,
                    filteredJobs: this.onChangeServer(server.target.value),
                    pageIndex: 1,
                    pageTotal: Math.ceil(this.state.allJobs.length / this.PAGE_SIZE) == 0 ? 1 : Math.ceil(this.state.allJobs.length / this.PAGE_SIZE),
                })}>
                {serverOptions}
            </select>;
       
        const header = ({ columns }: HeaderProps) => <thead><tr>
            {
                <React.Fragment>
                    <ARMHeaderCell
                        dataName="JobType"
                        text="-Job Type-"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                        select={jobTypeFilters}
                    />
                    <ARMHeaderCell
                        dataName="StateName"
                        text="- State -"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                        select={stateFilters}
                    />
                    <ARMHeaderCell
                        dataName="ClientAlias"
                        text="- CID -"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                        select={clientIDFilters}
                        disable={this.state.allClients.length === 1}
                    />
                    {this.state.isNDCUser &&
                        <ARMHeaderCell
                            dataName="AppServerName"
                            text="- Server -"
                            cellType="text"
                            sortable={true}
                            isSorted={1}
                            sortHandler={this.handleSort}
                            sortIndicator={this.state.sortIndicator}
                            select={serverFilters}
                        />
                    }
                    <ARMHeaderCell
                        dataName="UserName"
                        text="User Name"
                        cellType="text"
                        sortable={false}
                        isSorted={0}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="QueuedDate"
                        text="Queued Time"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="StartDateTime"
                        text="Start Time"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="EndDateTime"
                        text="End Time"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    {(this.state.isNDCUser || this.props.canEdit) &&
                        <ARMHeaderCell
                            dataName="CommandList"
                            text="Command"
                            cellType="text"
                            sortable={false}
                            isSorted={1}
                            sortHandler={this.handleSort}
                            sortIndicator={this.state.sortIndicator}
                        />
                    }
                </React.Fragment>
            }
        </tr></thead>

        let footerMsg: string = `Current Server Time: ${this.state.serverTime}`;

        const {
            displayModal,
            selectedJobId,
            modalWidth,
            modalHeight,
            filteredJobs,
        } = this.state;

        const row = this.getJobQueueCustomRow();
        return (
            <DialogWrapper title="Job Queue" instruction={instruction} isBusy={this.state.isLoading} helpUrl='/Support/Help/HELP_ViewingJobQueues.htm' buttons={buttons} >
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper>
                    <DialogFieldset style={{ height: '100%', marginBottom: '5px' }}>
                        <ARMGrid
                            dom-ID="jobQueue-id"
                            isFixedHeader={true}
                            maxHeight='492px'
                            columns={this.getJobQueueGridColumnHeaders()}
                            headerComponent={header}
                            rowComponent={row}
                            records={filteredJobs}
                            selectionKey='empty'
                        />
                        <ContentColumnWrapper>
                            <div style={{ paddingTop: "10px", paddingLeft: "10px" }}>
                                <ContentRowWrapper>
                                    <ARMDatePicker
                                        domID="jobqueue_queueFrom"
                                        className="cal_style"
                                        onInputChange={(e: any) => this.onDateChange(e, true)}
                                        allowTime={true}
                                        minDate={this.minDate}
                                        maxDate={this.maxDate}
                                        assocTo={this.state.selectedToDate}
                                        initialDate={this.getDateInMMDDYYYYFormat(new Date())}
                                        disableHowLong={true}
                                    />
                                    <div style={{ paddingTop: "5px", paddingLeft: "5px", paddingRight: "5px" }}><span><b>Thru</b></span></div>
                                        <ARMDatePicker
                                            domID="jobQueue_queueTo"
                                            className="cal_style"
                                            onInputChange={(e: any) => this.onDateChange(e, false)}
                                            allowTime={true}
                                            minDate={this.minDate}
                                            maxDate={this.maxDate}
                                            assocFrom={this.state.selectedFromDate}
                                            initialDate={this.getDateInMMDDYYYYFormat(new Date())}
                                            disableHowLong={true}
                                        />
                                    </ContentRowWrapper>
                                </div>
                            <ContentColumnWrapper style={{ paddingLeft: '10px' }}>
                                <ContentRowWrapper>
                                    <React.Fragment>
                                        <span>
                                            <IMG src='/shared/images/Icons/RefreshArrow.png' onClick={() => this.onClickRefresh()}/>
                                        </span>
                                        <div style={{ paddingTop: "2px" }}>
                                            <Button 
                                                domID="refresh-job"
                                                name="Refresh"
                                                buttonType="diminished"
                                                size="small"
                                                onClick={(e: React.SyntheticEvent) => this.onClickRefresh() }>
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                    <FooterInstructions
                                        footerMsgs={[footerMsg]}
                                        pageCount={this.state.pageIndex}
                                        pageTotal={this.state.pageTotal}
                                        onLeftBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.previousPage(e)}
                                        onRightBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.nextPage(e)}
                                    />
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                        </ContentColumnWrapper>
                    </DialogFieldset>
                   
                    {displayModal && selectedJobId &&
                        <>
                            <ModalWrapper width={modalWidth} height={modalHeight} /> 
                            <Modal
                                    domID="job-details-modal"
                                    isOpen={true}
                                    showDefaultClose={false}
                                    onClickOut={()=> this.setState({ displayModal: false })}
                            >
                            <JobDetailsDiv
                                isOpen={true}
                                showDefaultClose={false}
                                onClose={() => {
                                    this.setState({ displayModal: false })
                                }}
                                jobId={selectedJobId}
                                onSizeChange={this.onModalSizeChange.bind(this)}
                            />
                            </Modal>
                        </>
                    }
                    
                </ContentWrapper>
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IJobQueueState, IJobQueueActionProps, IOwnProps, IJobQueueProps, ApplicationState>(
    createCrudMapStateToProps('jobQueue'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(JobQueue);

export default withRouter(connectedHoc);
