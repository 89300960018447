import * as React from 'react';
import styled from 'styled-components';

import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { SelectComponent } from '../../common/SelectComponent';


export interface IFormTypeListProps {
    title: string;                                          // title of buttons
    forms: APICF_SimpleList[] | any[] | undefined;
    selectedValues: string[] | undefined;
    width?: string;                                         // width for your screen
    height?: string;
    className?: string;                                     // classname if needed
    disabled?: boolean;
    optionFields?: any;
    onSelect?: React.ChangeEventHandler<HTMLSelectElement>;  // action taken 
};

export interface IFormTypeListState {
    disabled: boolean;
};

/* was
 * 
    .{this.props.title + '_select} option {
            padding: 0px 2px 0px 2px !important;
            min-height: 1.2em;
            display: block;
            margin: 0 !important;
        }
* but no way it was working (caused error in jest test)
 */
const FormTypeWrapper = styled.div`
`;



export class FormTypeList extends React.Component<IFormTypeListProps, IFormTypeListState>
{
    static defaultProps: IFormTypeListProps = {
        title: "FormTypesList",
        forms: undefined,
        selectedValues: undefined,
        width: '150px',
        height: '130px',
        className: 'formtypeslist',
        optionFields: {
            value: "ID",
            text: "Name"
        },
        disabled: false
    };

    constructor(props: IFormTypeListProps) {
        super(props);
    }


    render() {


        return (
            <FormTypeWrapper className='formtypewrapper' style={{ flex: "1 1" }}>
            <DialogFieldset id={this.props.title + '_fieldset'} className={this.props.className} >
                <DialogLegend>Form Type</DialogLegend>
                <SelectComponent
                    title={this.props.title + '_select'}
                    className={this.props.title + '_select'}
                    width={this.props.width ? this.props.width : '150px'}
                    height={this.props.height ? this.props.height : '125px'}
                    multiple='true'
                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.onSelect ? this.props.onSelect(e) : () => {console.log(this.props.title + ' onSelect missing')}}
                    optionFields={this.props.optionFields}
                    records={this.props.forms}
                        disabled={this.props.disabled}
                    selectedMultiValue={this.props.selectedValues}>
                </SelectComponent>
            </DialogFieldset>
        </FormTypeWrapper>


        );
    }
};