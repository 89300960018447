import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Grid, Button } from "@optum-uicl/ui-core/dist";
import styled from "styled-components";
import { ContentRowWrapper } from "@common/DialogWrapper";
import {
  DialogLegend,
  DialogFieldset,
  DialogLegend3,
} from "@common/DialogStyles";
import { RadioButtonComponent } from "@common/RadioButton";
import { SelectComponent } from "@common/SelectComponent";
import {
  IMNPayerSelectionData,
  IMNPayerSelectionLocalState,
  IPayerSelectionUpdateRequest,
} from "@store/ui/ClaimOptionsUI";
import FilterableList from "@commonResources/FilterableList/FilterableList";

import { Colors } from "@commonResources/colorVariables";

import fiData from "./fiData.json";

import { colSpan2 } from "@components/LandingPage/ARMMessageListComponent";

import { connect } from "react-redux";
import { ApplicationState } from "@store/index";
import {  mergeComponentProps} from '@scripts/util/ReduxHelpers';

import {
  ActionCreators,
  actionCreators,
  IClaimOptionsFIState,
  IClaimOptionsFIProps,
} from "@store/ui/ClaimOptionsUI";
import { TextStyles } from "@optum-uicl/ui-core/dist";
import { ColumnType } from "@optum-uicl/ui-core/dist/Organisms/Grid/types";

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-right: 10px;

  #id-mn-payer-fieldset {
    padding-left: 10px;
    padding-right: 10px;
  }

  #id-mn-payer-form-type-fieldset {
    margin-left: 0px;
  }

  #id-mn-payer-fi-data-content-fieldset {
    margin-right: 0px;
  }

  #id-mn-payer-payer-or-alias {
    padding-left: 40px;
    padding-right: 20px;
  }

  #lobPayerSelect .option-class,
  #FISelect .option-class {
    ${TextStyles.xSmall};
  }
  select{
    font-family:'Optum Sans';
  }
`;

const FiListWrapper = styled.div`
  margin-top: 25px;
  select:focus {
    outline: none !important;
    border: 1px solid rgb(115, 125, 133) !important;
  }
  input:focus {
    outline: none !important;
    border: 1px solid rgb(115, 125, 133) !important;
  }
`;

const ReadOnlyPayerInfoBox = styled.textarea`
  width: 993px;
  height: 100px;
  resize: none;
  padding: 4px 10px;
  color: ${Colors.grey100};
  ${TextStyles.xSmallBold};
  text-transform:uppercase;
  line-height: 15px;
`;

const GridWrapperMNPayerSelectionNotes = styled.div`
  border: black 1px solid;
  background-color: white;
`;

const GridWrapper = styled.div`
  #grid-wrapper-id-mn-payer-selection-options-grid {
    height: 170px;
  }

  .empty-row {
    background-color: white;
  }

  thead tr th {
    height: 20px;
  }

  table tbody tr > td {
    height: auto;
  }

  table tbody tr td div {
    min-height: 15px;
    ${TextStyles.xSmall};
  }

  .LOB {
    width: 150px;
  }

  .PayerOrAlias {
    width: 345px;
  }

  .FIDataContent {
    width: 405px;
  }

  .Form {
    min-width: 30px;
    max-width: 30px;
  }
`;

const SelectButtons = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: center;
  padding-top: 6px;

  button {
    margin: 4px;
    width: 85px;
  }
`;
export const CustomWrapper = styled.div`
    #undefined-ListComponentSelect:focus{
      outline:none !important;
      border:1px solid rgb(115, 125, 133) !important;
    }
    #Filterable-Search-Input:focus{
      outline:none !important;
      border:1px solid rgb(115, 125, 133) !important; 
    }
    
`;

export interface IComponentProps {
  canView: boolean;
  canEdit: boolean;
  canDelete: boolean;

  displayAlertMessage(msg: string): void;
  getFiData(): void;
  localState?: IMNPayerSelectionLocalState;
  onLocalStateChange(request: IMNPayerSelectionLocalState): void;

  payerSelectionOptions: IMNPayerSelectionData;
  updateMNPayerSelectionOptions(request: IPayerSelectionUpdateRequest): void;
}

interface UIListSelectedPayerLOB {
  value: string;
  text: string;
}

interface MCPALIAS_LOCAL_LOB {
  sop: string;
  patientType: string;
  emast: string;
  payerId: string;
  nhPid: string;
  neiCid: string;
  naiCid: string;
  note: string;
  rteCpid: string;
  cpid: string;
}

interface IComponentState {
  selectedMNID: string; // selected row id
  resetID: string;
  // Form Type
  selectedFormType: string;

  // LOB, Payer of Alias
  selectedPayerOption: string;
  payerList: MCClaimOptionsIDName[];
  selectedLOBs: string[];
  selectedPayers: string[];
  selectedAliases: string[];

  // FI Data Content
  fiList: MCClaimOptionsIDName[];
  selectedFI: string;
  selections: any[];
  fiData: any[];
  payerInformation: string;
}

interface IDictionary {
  [index: string]: string;
}

interface FiData {
  parentId: string;
  sop: string;
  patientType: string;
  emast: string;
  payerId: string;
  cpid: string;
  nhPid?: string;
  neiCid?: string;
  naiCid?: string;
  rteCpid?: string;
  note?: string;
  [key: string]: string | undefined;
}

const sampleSelectedRecordsArr: FiData[][] = [
  [
    {
      parentId: "1336",
      sop: "F",
      patientType: "A",
      emast: "PE_O007",
      payerId: "AHIPA",
      cpid: "7273",
      nhPid: "88987",
    },
    {
      parentId: "1336",
      sop: "G",
      patientType: "I",
      emast: "HE9O007",
      payerId: "AHIPA",
      cpid: "2596",
      neiCid: "5478",
    },
  ],
  [
    {
      parentId: "1338",
      sop: "H",
      patientType: "A",
      emast: "PE_T007",
      payerId: "24585",
      cpid: "7461",
      naiCid: "6745",
    },
    {
      parentId: "1338",
      sop: "F",
      patientType: "O",
      emast: "HE9T007",
      payerId: "24585",
      cpid: "9632",
      rteCpid: "8876",
    },
  ],
  [
    {
      parentId: "1343",
      sop: "I",
      patientType: "A",
      emast: "PE_O007",
      payerId: "ACIPA",
      cpid: "6141",
    },
  ],
];

export default class MedNecPayerSelectionOptions extends React.Component<
  IComponentProps,
  IComponentState
> {
  constructor(props: IComponentProps) {
    super(props);

    this.state = {
      resetID: "",
      selectedMNID: props.localState?.selectedMNID
        ? props.localState.selectedMNID
        : "",
      selectedFormType: props.localState?.selectedFormType
        ? props.localState.selectedFormType
        : "",
      selectedPayerOption: props.localState?.selectedPayerOption
        ? props.localState.selectedPayerOption
        : "",
      selectedLOBs: props.localState?.selectedLOBs
        ? props.localState.selectedLOBs
        : [],
      selectedPayers: props.localState?.selectedPayers
        ? props.localState.selectedPayers
        : [],
      selectedAliases: props.localState?.selectedAliases
        ? props.localState.selectedAliases
        : [],
      selectedFI: props.localState?.selectedFI
        ? props.localState.selectedFI
        : "",

      payerList:
        props.localState?.selectedPayerOption &&
        props.localState?.selectedFormType
          ? this.getLOBPayerAliasList(
              props.localState.selectedPayerOption,
              props.localState.selectedFormType,
              props.localState?.selectedMNID
                ? props.localState.selectedMNID
                : ""
            )
          : [],
      fiList: props.localState?.selectedFormType
        ? this.getFIList(props.localState?.selectedFormType)
        : [],
      fiData: [],
      selections: [],
      payerInformation: "",
    };
  }

  public componentDidMount() {
    //@ts-ignore
    this.props.getFiData();
  }

  public componentWillUnmount() {}

  public setPayerInformation() {
    const selectedItem = this.state.selectedFI; // string id of selected record
    let stringID:string | null = null;
    let records = null;

    records = this.props.payerSelectionOptions.FIDetailList.reduce(
      (arr: any, val: any) => {
        if (selectedItem && val) {
          if (selectedItem === val.parentId) {
            arr = [...arr, val];
          }
          return arr;
        }
      },
      []
    );

    if (records && records.length) {
      stringID = this.parsePayerLOBInfo([records]);
      if (stringID !== null || stringID !== "") {
        if (stringID !== this.state.payerInformation) {
          this.setState({ payerInformation: stringID });
        }
      }
    }
  }

  /**
   * Business logic pulled from legacy code; used to display formatted text to user.  Formatted text
   * is the concatenated output of LOB: PatientType (claim type); SOP; Emast; a substring (single character)
   * of Emast; and any and all IDs that have values; and Notes.  (Not sure if the specific order of the IDs
   * is required, but maintaining it from legacy just in case.)  And as usual, need "software intervention"
   * for TypeScript...
   * @param listPayerLOB
   */
  public parsePayerLOBInfo = (listPayerLOB: FiData[][]): string => {
    let strObj: Object = {};
    let tmpArr: any[] = [];
    const stringOrder = [
      "emast",
      "sop",
      "emastSub",
      "patientType",
      "idEquals",
      "nhpidEquals",
      "neiCidEquals",
      "naiCidEquals",
      "rteCpidEquals",
      "cpidEquals",
      "note",
    ];

    listPayerLOB.map((arr: FiData[]) => {
      // Each arr is an array of keys
      arr.forEach((dataObj: FiData) => {
        // Each dataObj is an array of keys
        Object.keys(dataObj).map((keyStr: string) => {
          let ele = dataObj[keyStr];
          // EMAST
          if (keyStr === "emast") {
            let char = ele && ele.substring(0, 1);
            char = char && char.toUpperCase();
            switch (char) {
              case "H":
                strObj = { ...strObj, emast: "HOSP" };
                //formattedText += "HOSP";
                break;
              case "P":
                //formattedText += "PHYS";
                strObj = { ...strObj, emast: "PHYS" };
                break;
              default:
                break;
            }
            // EMAST Substring
            const emastSub = (ele && ele.substring(3)) || "";
            strObj = { ...strObj, emastSub: emastSub };
            //formattedText += char + " ";
          }

          // SOP
          if (keyStr === "sop") {
            switch (ele) {
              case "C":
                strObj = { ...strObj, sop: "MEDICARE" };
                //formattedText += "MEDICARE ";
                break;
              case "D":
                strObj = { ...strObj, sop: "MEDICAID" };
                //formattedText += "MEDICAID ";
                break;
              case "F":
                strObj = { ...strObj, sop: "COMMERCIAL" };
                //formattedText += "COMMERCIAL ";
                break;
              case "G":
                strObj = { ...strObj, sop: "BC/BS" };
                //formattedText += "BC/BS ";
                break;
              case "H":
                strObj = { ...strObj, sop: "CHAMPUS" };
                //formattedText += "CHAMPUS ";
                break;
              case "I":
                strObj = { ...strObj, sop: "OTHER" };
                //formattedText += "OTHER ";
                break;
              default:
                break;
            }
          }

          // PATIENT TYPE
          if (keyStr === "patientType") {
            let patientType = ele && ele.toUpperCase();

            switch (patientType) {
              case "I":
                strObj = { ...strObj, patientType: "INPAT" };
                //formattedText += "INPAT ";
                break;
              case "O":
                strObj = { ...strObj, patientType: "OUTPAT" };
                //formattedText += "OUTPAT ";
                break;
              case "A": //do nothing (fallthrough intentional)
              default:
                break;
            }
          }

          if (dataObj["payerId"] && dataObj["payerId"].length) {
            let idEquals = "ID=" + dataObj["payerId"];
            //formattedText += idEquals;
            strObj = { ...strObj, idEquals };
          }

          if (dataObj["nhPid"] && dataObj["nhPid"].length) {
            let nhpidEquals = "NHPID=" + dataObj["nhPid"];
            //formattedText += nhpidEquals;
            strObj = { ...strObj, nhpidEquals };
          }

          if (dataObj["neiCid"] && dataObj["neiCid"].length) {
            let neiCidEquals = "NEIC=" + dataObj["neiCid"];
            //formattedText += neiCidEquals;
            strObj = { ...strObj, neiCidEquals };
          }

          if (dataObj["naiCid"] && dataObj["naiCid"].length) {
            let naiCidEquals = "NAIC=" + dataObj["naiCid"];
            //formattedText += naiCidEquals;
            strObj = { ...strObj, naiCidEquals };
          }

          if (dataObj["rteCpid"] && dataObj["rteCpid"].length) {
            let rteCpidEquals = "RTECPID=" + dataObj["rteCpid"];
            //formattedText += rteCpidEquals;
            strObj = { ...strObj, rteCpidEquals };
          }
          if (dataObj["cpid"] && dataObj["cpid"].length) {
            let cpidEquals = "CPID=" + dataObj["cpid"];
            //formattedText += cpidEquals;
            strObj = { ...strObj, cpidEquals };
          }
          if (dataObj["note"] && dataObj["note"].length) {
            let note = dataObj["note"];
            //formattedText += note;
            strObj = { ...strObj, note };
          }
        }); // End Map

        //formattedText += "\n";
        tmpArr.push(strObj);

        strObj = {};
      }); // End for Each'
    }); // End Map

    const sortOrder: any = stringOrder.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});

    let finalStr = tmpArr.map((strObj: FiData) => {
      // Get array of keys as strings
      let keysArr = Object.keys(strObj);
      // Sort them according to our string order
      keysArr = keysArr.reduce((arr: any, cur: any) => {
        let index = sortOrder[cur];
        arr[index] = cur;
        return arr;
      }, []);

      // Filter out all empty elements
      keysArr = keysArr.filter((el) => {
        return el != null;
      });

      let items = keysArr
        //@ts-ignore
        .sort((a, b) => a.emast - b.emast)
        .map((strKey) => {
          return strObj[strKey];
        });

      return items.join(" ");
    });

    return finalStr.join("\n");
  };

  MNPayerSelectionGridColumns = () => {
    let headers = new Set();

    const FormElement = {
      dataName: "LOB",
      text: "LOB",
      sortable: false,
      cellType: "custom" ,
      isSorted: 0,
    };

    headers.add(FormElement);

    const PayerElement = {
      dataName: "PayerOrAlias",
      text: "Payer/Alias",
      sortable: false,
      cellType: "custom",
      isSorted: 0,
    };

    headers.add(PayerElement);

    const FieldElement = {
      dataName: "FIDataContent",
      text: "FI Data Content",
      sortable: false,
      cellType: "custom",
      isSorted: 0,
    };

    headers.add(FieldElement);

    const EditNameElement = {
      dataName: "Form",
      text: "Form",
      sortable: false,
      cellType: "custom",
      isSorted: 0,
    };

    headers.add(EditNameElement);

    return headers;
  };

  private getSelectedPayers(option: string, editData: any): string[] {
    switch (option) {
      case "PAYER": {
        const values = editData.Payers.Payer.map((payer: any) => payer["@ID"]);
        return values;
      }
      default: {
        return [];
      }
    }
  }

  private getSelectedLOBs(option: string, editData: any): string[] {
    switch (option) {
      case "LOB": {
        const values = editData.LOBs.LOB.map((lob: any) => lob["@ID"]);
        return values;
      }
      default: {
        return [];
      }
    }
  }

  private getSelectedAliases(option: string, editData: any): string[] {
    switch (option) {
      case "ALIAS": {
        const values = editData.Aliases.Alias.map((alias: any) => alias["@ID"]);
        return values;
      }
      default: {
        return [];
      }
    }
  }

  private isAlreadyAddedLOB(
    lobId: string,
    selectedMNId: string,
    formType: string
  ): boolean {
    let mnEditFilteredList = this.props.payerSelectionOptions.MNEdits.filter(
      (mnedit) =>
        mnedit["@ID"] != selectedMNId &&
        (formType == "P"
          ? mnedit["@Professional"] == "Y"
          : mnedit["@Institutional"] == "Y")
    );

    let bLobFound = false;
    mnEditFilteredList.forEach((mnEdit: any) => {
      if (!bLobFound) {
        if (Array.isArray(mnEdit.LOBs.LOB)) {
          let lobFound = mnEdit.LOBs.LOB.find(
            (lob: any) => lob["@ID"] == lobId
          );
          if (lobFound) bLobFound = true;
        }
      }
    });
    return bLobFound;
  }

  private isAlreadyAddedPayer(
    payerId: string,
    selectedMNId: string,
    formType: string
  ): boolean {
    let mnEditFilteredList = this.props.payerSelectionOptions.MNEdits.filter(
      (mnedit) =>
        mnedit["@ID"] != selectedMNId &&
        (formType == "P"
          ? mnedit["@Professional"] == "Y"
          : mnedit["@Institutional"] == "Y")
    );

    let bPayerFound = false;
    mnEditFilteredList.forEach((mnEdit: any) => {
      if (!bPayerFound) {
        if (Array.isArray(mnEdit.Payers.Payer)) {
          let payerFound = mnEdit.Payers.Payer.find(
            (payer: any) => payer["@ID"] == payerId
          );
          if (payerFound) bPayerFound = true;
        }
      }
    });
    return bPayerFound;
  }

  private isAlreadyAddedAlias(
    aliasId: string,
    selectedMNId: string,
    formType: string
  ): boolean {
    let mnEditFilteredList = this.props.payerSelectionOptions.MNEdits.filter(
      (mnedit) =>
        mnedit["@ID"] != selectedMNId &&
        (formType == "P"
          ? mnedit["@Professional"] == "Y"
          : mnedit["@Institutional"] == "Y")
    );

    let bAliasFound = false;
    mnEditFilteredList.forEach((mnEdit: any) => {
      if (!bAliasFound) {
        if (Array.isArray(mnEdit.Aliases.Alias)) {
          let aliasFound = mnEdit.Aliases.Alias.find(
            (alias: any) => alias["@ID"] == aliasId
          );
          if (aliasFound) bAliasFound = true;
        }
      }
    });
    return bAliasFound;
  }

  private getLOBPayerAliasList(
    option: string,
    formType: string,
    selectedMNId: string
  ) {
    switch (option) {
      case "LOB": {
        // Return all the LOB that have not yet been added to a setting for the formType, unless it is the currently selected setting.
        let lobList = this.props.payerSelectionOptions.LOBList.filter(
          (lob) => lob["@Name"].toUpperCase() != "MEDICARE"
        );
        let filteredLobList = lobList.filter(
          (lob) => !this.isAlreadyAddedLOB(lob["@ID"], selectedMNId, formType)
        );
        return filteredLobList;
      }
      case "PAYER": {
        // Return all the payers that have not yet been added to a setting for the formType, unless it is the currently selected setting.
        let payerList = this.props.payerSelectionOptions.PayerList.filter(
          (payer) => payer["@Type"] != (formType == "P" ? "H" : "P")
        );
        let filteredPayerList = payerList.filter(
          (payer) =>
            !this.isAlreadyAddedPayer(payer["@ID"], selectedMNId, formType)
        );
        return filteredPayerList;
      }
      case "ALIAS": {
        // Return all the aliases that have not yet been added to a setting for the formType, unless it is the currently selected setting.
        let aliasList = this.props.payerSelectionOptions.AliasList.filter(
          (alias) => alias["@Type"] != (formType == "P" ? "H" : "P")
        );
        let filteredAliasList = aliasList.filter(
          (alias) =>
            !this.isAlreadyAddedAlias(alias["@ID"], selectedMNId, formType)
        );
        return filteredAliasList;
      }
    }
    return [];
  }

  private checkForSuppressedOptions(editData: any) {
    if (editData["@Professional"] == "Y") {
      if (this.props.payerSelectionOptions.SuppressMNBP) {
        // I think that in the IE page, the message would show when they changed the suppress in the main tab
        // and the MN edit custom settings rows would not be dynamically disabled, so we should not need these messages here.
        return true;
      }
    } else {
      if (this.props.payerSelectionOptions.SuppressMNAI) {
        return true;
      }
    }
    return false;
  }

  deselectAllRows() {
    // clear selected row highlights from ui
    var mnPayerSelectionGrid = document.getElementById(
      "id-mn-payer-selection-options-grid"
    );
    if (mnPayerSelectionGrid != null) {
      var selectedRows = mnPayerSelectionGrid.querySelectorAll(".selected");
      for (var i = 0; i < selectedRows.length; i++) {
        selectedRows[i].classList.remove("selected");
      }
    }
  }

  highlightSelectedRow(target: any) {
    if (target != null) {
      this.deselectAllRows();
      // set selected class to grid row
      target.className = "selected";
    }
  }

  public onSelectMNRow(e: any, record: any) {
    if (record && record.ID) {
      let editData = this.props.payerSelectionOptions.MNEdits.find(
        (edit) => edit["@ID"] == record.ID
      );
      if (editData) {
        if (this.checkForSuppressedOptions(editData)) return;

        this.highlightSelectedRow(e.currentTarget);

        let ID = editData["@ID"];

        let lobPayerAliastOption =
          editData?.LOBs?.LOB != null && editData.LOBs.LOB.length > 0
            ? "LOB"
            : editData?.Payers?.Payer != null &&
              editData.Payers.Payer.length > 0
            ? "PAYER"
            : "ALIAS";

        let newSelectedData = {
          selectedMNID: ID,
          selectedFormType: editData["@Professional"] == "Y" ? "P" : "H",
          selectedPayerOption: lobPayerAliastOption,

          selectedLOBs: this.getSelectedLOBs(lobPayerAliastOption, editData),
          selectedPayers: this.getSelectedPayers(
            lobPayerAliastOption,
            editData
          ),
          selectedAliases: this.getSelectedAliases(
            lobPayerAliastOption,
            editData
          ),

          selectedFI: editData["@FIDataSetID"],
          resetID: this.getResetCode(),
        };

        this.props.onLocalStateChange({
          ...this.props.localState,
          ...newSelectedData,
        });

        this.setState(
          {
            ...newSelectedData,
            fiList: this.getFIList(
              editData["@Professional"] == "Y" ? "P" : "H"
            ),
            payerList: this.getLOBPayerAliasList(
              lobPayerAliastOption,
              editData["@Professional"] == "Y" ? "P" : "H",
              ID
            ),
          },
          () => {
            this.setPayerInformation();
          }
        );
      }
    }
  }

  public onFormTypeChange(newFormType: string) {
    if (this.state.selectedFormType == newFormType || newFormType == "") return;

    let fiList = this.getFIList(newFormType);
    let payerOption = this.state.selectedPayerOption;
    if (payerOption == "") payerOption = "LOB";

    let newSelectedData = {
      selectedFormType: newFormType,
      selectedPayerOption: payerOption,
      selectedLOBs: [],
      selectedPayers: [],
      selectedAliases: [],
      selectedFI: this.state.selectedFI,
    };

    if (
      newFormType != this.state.selectedFormType &&
      this.state.selectedFormType !== ""
    ) {
      //console.log("Clearing selectedFI...");
      // @ts-ignore
      newSelectedData["selectedFI"] = [];
    }

    //@ts-ignore
    this.props.onLocalStateChange({
      ...this.props.localState,
      ...newSelectedData,
    });
    //@ts-ignore
    this.setState({
      ...newSelectedData,
      payerList: this.getLOBPayerAliasList(
        payerOption,
        newFormType,
        this.state.selectedMNID
      ),
      fiList: fiList,
    });
  }

  public onChangePayerOption(value: string) {
    if (this.state.selectedPayerOption == value || value == "") return;

    let newSelectedData = {
      selectedPayerOption: value,
    };

    this.props.onLocalStateChange({
      ...this.props.localState,
      ...newSelectedData,
    });

    this.setState({
      ...newSelectedData,
      payerList: this.getLOBPayerAliasList(
        value,
        this.state.selectedFormType,
        this.state.selectedMNID
      ),
    });
  }

  private validateFields() {
    if (this.state.selectedFormType == "") {
      this.props.displayAlertMessage("Please select a Form Type.");
      return false;
    }

    if (
      this.state.selectedPayerOption == "LOB" &&
      this.state.selectedLOBs.length == 0
    ) {
      this.props.displayAlertMessage("Please select a LOB.");
      return false;
    }

    if (
      this.state.selectedPayerOption == "PAYER" &&
      this.state.selectedPayers.length == 0
    ) {
      this.props.displayAlertMessage("Please select a Payer.");
      return false;
    }

    if (
      this.state.selectedPayerOption == "ALIAS" &&
      this.state.selectedAliases.length == 0
    ) {
      this.props.displayAlertMessage("Please select an Alias.");
      return false;
    }

    if (this.state.selectedFI == "") {
      this.props.displayAlertMessage(
        "Please select an FI Data Content provider."
      );
      return false;
    }

    return true;
  }

  public onSelectPayer(e: React.ChangeEvent<HTMLSelectElement>) {
    if (!e.target.options || e.target.options.length === 0) return [];

    let result = [];

    for (let i = 0; i < e.target.options.length; i++) {
      if (e.target.options[i].selected) result.push(e.target.options[i].value);
    }
    switch (this.state.selectedPayerOption) {
      case "LOB":
        this.props.onLocalStateChange({
          ...this.props.localState,
          selectedLOBs: result,
        });
        this.setState({ selectedLOBs: result });
        break;
      case "PAYER":
        this.props.onLocalStateChange({
          ...this.props.localState,
          selectedPayers: result,
        });
        this.setState({ selectedPayers: result });
        break;
      case "ALIAS":
        this.props.onLocalStateChange({
          ...this.props.localState,
          selectedAliases: result,
        });
        this.setState({ selectedAliases: result });
        break;
      default:
        break;
    }
  }

  public onSelectFI(e: React.ChangeEvent<HTMLSelectElement>) {
    this.props.onLocalStateChange({
      ...this.props.localState,
      selectedFI: e.target.value,
    });
    this.setState({ selectedFI: e.target.value });
  }

  public getResetCode(): string {
    return (Math.random() + "" + Date.now()).split(".")[1];
  }

  public onClickAddUpdateButton(e: React.SyntheticEvent) {
    if (!this.validateFields()) return false;

    let action: "ADD" | "UPDATE" | "REMOVE" = "UPDATE";
    if (this.state.selectedMNID == "") action = "ADD";

    let payerOption = this.state.selectedPayerOption;
    let lobs: MCClaimOptionsSelectedLOBs = { LOB: [] };
    let payers: MCClaimOptionsSelectedPayers = { Payer: [] };
    let aliases: MCClaimOptionsSelectedAliases = { Alias: [] };

    switch (payerOption) {
      case "LOB":
        lobs.LOB = this.state.selectedLOBs.map((lob) => {
          let newElt: MCClaimOptionsID = { "@ID": lob };
          return newElt;
        });
        break;
      case "PAYER":
        payers.Payer = this.state.selectedPayers.map((payer) => {
          let newElt: MCClaimOptionsID = { "@ID": payer };
          return newElt;
        });
        break;
      case "ALIAS":
        aliases.Alias = this.state.selectedAliases.map((alias) => {
          let newElt: MCClaimOptionsID = { "@ID": alias };
          return newElt;
        });
        break;
      default:
        break;
    }

    let data: MCClaimOptionsMNEdit = {
      "@ID": this.state.selectedMNID,
      "@FIDataSetID": this.state.selectedFI,
      "@Professional": this.state.selectedFormType == "P" ? "Y" : "N",
      "@Institutional": this.state.selectedFormType == "H" ? "Y" : "N",
      LOBs: lobs,
      Payers: payers,
      Aliases: aliases,
    };

    let request: IPayerSelectionUpdateRequest = { Action: action, Value: data };
    this.props.updateMNPayerSelectionOptions(request);

    this.clearEntries();
  }

  private clearEntries() {
    this.deselectAllRows();

    let newSelectedData = {
      selectedMNID: "",
      selectedFormType: "",
      selectedPayerOption: "",
      selectedLOBs: [],
      selectedPayers: [],
      selectedAliases: [],
      selectedFI: [], //this.getFirstFIListItem(this.state.fiList),
    };

    //@ts-ignore
    this.props.onLocalStateChange({
      ...this.props.localState,
      ...newSelectedData,
    });

    //@ts-ignore
    this.setState({
      ...newSelectedData,
      payerList: [],
      payerInformation: "",
      resetID: this.getResetCode(),
    });
  }

  public resetSelectedFIEntries() {
    //this.deselectAllRows();

    let newSelectedData = {
      // selectedMNID: "",
      // selectedFormType: "",
      // selectedPayerOption: "",
      // selectedLOBs: [],
      // selectedPayers: [],
      // selectedAliases: [],
      selectedFI: [], //this.getFirstFIListItem(this.state.fiList),
    };

    //@ts-ignore
    this.props.onLocalStateChange({
      ...this.props.localState,
      ...newSelectedData,
    });

    //@ts-ignore
    this.setState({
      ...newSelectedData,
      // payerList: [],
      // payerInformation: "",
      // resetID: this.getResetCode(),
    });
  }

  public onClickClearButton() {
    this.clearEntries();
  }

  public onClickRemoveButton(e: React.SyntheticEvent) {
    this.deselectAllRows();

    let request: IPayerSelectionUpdateRequest = {
      Action: "REMOVE",
      Value: this.state.selectedMNID,
    };
    this.props.updateMNPayerSelectionOptions(request);

    let newSelectedData = {
      selectedMNID: "",
      selectedFormType: "",
      selectedPayerOption: "",
      selectedLOBs: [],
      selectedPayers: [],
      selectedAliases: [],
      selectedFI: this.getFirstFIListItem(this.state.fiList),
    };

    this.props.onLocalStateChange({
      ...this.props.localState,
      ...newSelectedData,
    });

    this.setState({
      ...newSelectedData,
      payerList: [],
    });
  }

  private getFIList(formType: string) {
    return this.props.payerSelectionOptions.FIList.filter((fi) =>
      formType == "P" ? fi["@Type"] != "H" : fi["@Type"] != "P"
    );
  }

  private getFirstFIListItem(fiList: any[]) {
    if (fiList && fiList.length > 0) {
      return fiList[0]["@ID"];
    }
    return "";
  }

  private getDataName(dataId: string, dataArray: any[], notFoundText: string) {
    if (dataArray && dataArray.length > 0) {
      let lobObject = dataArray.find((data) => data["@ID"] == dataId);
      if (lobObject) return lobObject["@Name"];
    }
    return notFoundText;
  }

  private getFIName(fiDataSetId: string) {
    if (
      this.props.payerSelectionOptions.FIList &&
      this.props.payerSelectionOptions.FIList.length > 0
    ) {
      let lobObject = this.props.payerSelectionOptions.FIList.find(
        (fi) => fi["@ID"] == fiDataSetId
      );
      if (lobObject) return lobObject["@Name"];
    }
    return "Unknown FI " + fiDataSetId;
  }

  private styles = {
    disabledStyle: {
      color: "lightgrey",
    },
  };

  private renderRowLOBData(lobs: any[], id: string, disabled: boolean) {
    if (lobs && lobs.length > 0) {
      return lobs.map((lob, index) => {
        let key = "mn-ps-lob-" + id + "-" + index;
        let notFoundText = "Unknown LOB " + lob["@ID"];
        if (disabled)
          return (
            <span style={this.styles.disabledStyle} key={key}>
              {this.getDataName(
                lob["@ID"],
                this.props.payerSelectionOptions.LOBList,
                notFoundText
              )}
              <br />
            </span>
          );
        return (
          <span key={key}>
            {this.getDataName(
              lob["@ID"],
              this.props.payerSelectionOptions.LOBList,
              notFoundText
            )}
            <br />
          </span>
        );
      });
    } else {
      let key = "mn-ps-lob-" + id;
      return <span key={key}></span>;
    }
  }
  private renderRowPayerOrAlias(
    payers: any[],
    aliases: any[],
    id: string,
    disabled: boolean
  ) {
    if (payers && payers.length > 0) {
      return payers.map((payer, index) => {
        let key = "mn-ps-payer-" + id + "-" + index;
        let notFoundText = "Unknown Payer " + payer["@ID"];
        if (disabled)
          return (
            <span style={this.styles.disabledStyle} key={key}>
              {" "}
              {this.getDataName(
                payer["@ID"],
                this.props.payerSelectionOptions.PayerList,
                notFoundText
              )}{" "}
              <br />
            </span>
          );
        return (
          <span key={key}>
            {this.getDataName(
              payer["@ID"],
              this.props.payerSelectionOptions.PayerList,
              notFoundText
            )}
            <br />
          </span>
        );
      });
    } else if (aliases && aliases.length > 0) {
      if (aliases && aliases.length > 0) {
        return aliases.map((alias, index) => {
          let key = "mn-ps-alias-" + id + "-" + index;
          let notFoundText = "Unknown Alias " + alias["@ID"];
          if (disabled)
            return (
              <span style={this.styles.disabledStyle} key={key}>
                {this.getDataName(
                  alias["@ID"],
                  this.props.payerSelectionOptions.AliasList,
                  notFoundText
                )}
                <br />
              </span>
            );
          return (
            <span key={key}>
              {this.getDataName(
                alias["@ID"],
                this.props.payerSelectionOptions.AliasList,
                notFoundText
              )}
              <br />
            </span>
          );
        });
      }
    } else {
      let key = "mn-ps-payeroralias-" + id;
      return <span key={key}></span>;
    }
  }

  private renderRowFIDataContent(
    FIDataSetID: string,
    id: string,
    disabled: boolean
  ) {
    if (disabled)
      return (
        <span style={this.styles.disabledStyle} key={"mn-ps-fidata-" + id}>
          {this.getFIName(FIDataSetID)}
        </span>
      );
    return (
      <span key={"mn-ps-fidata-" + id}>{this.getFIName(FIDataSetID)}</span>
    );
  }

  private renderRowForm(professional: string, id: string, disabled: boolean) {
    if (disabled)
      return (
        <span style={this.styles.disabledStyle} key={"mn-ps-form-" + id}>
          {professional == "Y" ? "PRO" : "INST"}
        </span>
      );
    return (
      <span key={"mn-ps-form-" + id}>
        {professional == "Y" ? "PRO" : "INST"}
      </span>
    );
  }

  private GetGridData(): any[] {
    let gridData: any[] = [];

    if (
      this.props.payerSelectionOptions &&
      this.props.payerSelectionOptions.MNEdits
    ) {
      this.props.payerSelectionOptions.MNEdits.forEach((mnEdit) => {
        let disabled =
          (this.props.payerSelectionOptions.SuppressMNBP &&
            mnEdit["@Professional"] == "Y") ||
          (this.props.payerSelectionOptions.SuppressMNAI &&
            mnEdit["@Professional"] != "Y");

        let rowData = {
          ID: mnEdit["@ID"],
          LOB: this.renderRowLOBData(mnEdit.LOBs.LOB, mnEdit["@ID"], disabled),
          PayerOrAlias: this.renderRowPayerOrAlias(
            mnEdit.Payers.Payer,
            mnEdit.Aliases.Alias,
            mnEdit["@ID"],
            disabled
          ),
          FIDataContent: this.renderRowFIDataContent(
            mnEdit["@FIDataSetID"],
            mnEdit["@ID"],
            disabled
          ),
          Form: this.renderRowForm(
            mnEdit["@Professional"],
            mnEdit["@ID"],
            disabled
          ),
        };
        gridData.push(rowData);
      });
    }
    return gridData;
  }

  MNDisabledRowIDs = () => {
    let rows = new Set<string>();

    if (
      this.props.payerSelectionOptions &&
      this.props.payerSelectionOptions.MNEdits
    ) {
      if (
        this.props.payerSelectionOptions.SuppressMNBP ||
        this.props.payerSelectionOptions.SuppressMNAI
      )
        this.props.payerSelectionOptions.MNEdits.forEach((mnEdit) => {
          if (
            (mnEdit["@Professional"] == "Y" &&
              this.props.payerSelectionOptions.SuppressMNBP) ||
            (mnEdit["@Professional"] != "Y" &&
              this.props.payerSelectionOptions.SuppressMNAI)
          ) {
            rows.add(mnEdit["@ID"]);
          }
        });
    }
    return rows;
  };

  public setPayerSelections = (records: any) => {
    let mappedrecords = records.map((record: any) => record.value);

    switch (this.state.selectedPayerOption) {
      case "LOB":
        this.setState({ selectedLOBs: mappedrecords });
        break;
      case "PAYER":
        this.setState({ selectedPayers: mappedrecords });
        break;
      case "ALIAS":
        this.setState({ selectedAliases: mappedrecords });
        break;
      default:
        break;
    }
  };

  public getPayerSelections = () => {
    switch (this.state.selectedPayerOption) {
      case "LOB":
        return this.state.selectedLOBs;
      case "PAYER":
        return this.state.selectedPayers;
      case "ALIAS":
        return this.state.selectedAliases;
      default:
        return [];
    }
  };

  public setFiSelections = (records: any) => {
    const recordArr: any = []; // Stores the array of arrays for fi

    records &&
      records.map((record: any) => {
        let myArr: any = [];
        this.props.payerSelectionOptions.FIDetailList.reduce(
          (arr: any, val: any) => {
            if (record.id === val?.parentId) {
              myArr.push(val);
              return myArr;
            }
          },
          []
        );
        return recordArr.push(myArr);
      });
    const recordStrings = this.parsePayerLOBInfo(recordArr);

    if (recordArr.length) {
      this.setState({
        payerInformation: recordStrings,
        selectedFI: records[0].value,
      });
    }
  };

  public render() {
    //console.log("The payerlist is now:", this.state.payerList);

    // Debug table to determine whether "ADD" is disabled (all need to be false for buttom to be enabled)
    /*
    console.table({
      "Can View": !this.props.canView,
      "Can Edit": !this.props.canEdit,
      "Is FI selected": !this.state.selectedFI.length,
      "Form Type is selected": this.state.selectedFormType == "",
    });
    */

    if (!this.props.canView) return <div></div>;

    let MNPayerSelectionData: any[] = this.GetGridData();

    return (
      <ContentWrapper id={"content_wrapper_PayerSelectionClaimsOptions"}>
        <DialogFieldset id="id-mn-payer-fieldset">
          <DialogLegend3 helpUrl="/Support/Help/HELP_Maint_ClaimOptions_MNTab.htm">
            MN Edit Custom Settings
          </DialogLegend3>
          <GridWrapperMNPayerSelectionNotes>
            <GridWrapper>
              <Grid
                domID="id-mn-payer-selection-options-grid"
                isFixedHeader={true}
                maxHeight="170px"
                columns={this.MNPayerSelectionGridColumns() as Set<ColumnType>}
                records={MNPayerSelectionData}
                initialDisabledItemIds={this.MNDisabledRowIDs() as Set<string | number>} // this is not working though.... at least with onClickTHrough...
                onClickThrough={(e: any, record: any) => {
                  this.onSelectMNRow(e, record);
                }}
                selectionKey="ID"
              />
            </GridWrapper>
          </GridWrapperMNPayerSelectionNotes>
          <div>
            <ContentRowWrapper>
              <DialogFieldset id="id-mn-payer-form-type-fieldset">
                <DialogLegend>Form Type</DialogLegend>
                <RadioButtonComponent
                  id="id-mn-payer-prof-radio"
                  label="Professional"
                  width="120px"
                  disabled={
                    this.props.payerSelectionOptions.SuppressMNBP ||
                    !this.props.canEdit
                  }
                  checked={this.state.selectedFormType == "P"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.onFormTypeChange("P");
                  }}
                />
                <RadioButtonComponent
                  id="id-mn-payer-inst-radio"
                  label="Institutional"
                  width="120px"
                  disabled={
                    this.props.payerSelectionOptions.SuppressMNAI ||
                    !this.props.canEdit
                  }
                  checked={this.state.selectedFormType == "H"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.onFormTypeChange("H");
                  }}
                />
              </DialogFieldset>
              <DialogFieldset id="id-mn-payer-lob-or-payer-fieldset">
                <DialogLegend>LOB or Payer</DialogLegend>
                <ContentRowWrapper id="id-mn-payer-payer-or-alias">
                  <RadioButtonComponent
                    id="id-mn-payer-lob-radio"
                    label="LOB"
                    width="100px"
                    disabled={
                      this.state.selectedFormType == "" || !this.props.canEdit
                    }
                    checked={this.state.selectedPayerOption == "LOB"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.onChangePayerOption("LOB")
                    }
                  />
                  <RadioButtonComponent
                    id="id-mn-payer-payer-radio"
                    label="Payer"
                    width="100px"
                    disabled={
                      this.state.selectedFormType == "" || !this.props.canEdit
                    }
                    checked={this.state.selectedPayerOption == "PAYER"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.onChangePayerOption("PAYER")
                    }
                  />
                  <RadioButtonComponent
                    id="id-mn-payer-alias-radio"
                    label="Alias"
                    width="100px"
                    disabled={
                      this.state.selectedFormType == "" || !this.props.canEdit
                    }
                    checked={this.state.selectedPayerOption == "ALIAS"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.onChangePayerOption("ALIAS")
                    }
                  />
                </ContentRowWrapper>
                <CustomWrapper>
                <FilterableList
                  records={this.state.payerList}
                  setSelections={this.setPayerSelections}
                  width={400}
                  height={125}
                  key={
                    this.state.selectedFormType +
                    "" +
                    this.state.selectedPayerOption +
                    this.state.resetID
                  } //required to force displayed data to update if records change outside of the FilterableList
                  placeholder="Filter"
                  optionFields={{
                    id: "@ID",
                    value: "@ID",
                    text: "@Name",
                  }}
                  disabled={
                    this.state.selectedFormType == "" || !this.props.canEdit
                  }
                  value={this.getPayerSelections()}
                  multiSelect
                />
                </CustomWrapper>
              </DialogFieldset>

              <DialogFieldset id="id-mn-payer-fi-data-content-fieldset">
                <DialogLegend>FI Data Content Selection</DialogLegend>
                <FiListWrapper>
                  <FilterableList
                    domID={
                      JSON.stringify(this.state.fiList) + this.state.resetID
                    }
                    records={this.state.fiList}
                    setSelections={this.setFiSelections}
                    width={400}
                    height={125}
                    key={JSON.stringify(this.state.fiList) + this.state.resetID} //required to force displayed data to update if records change outside of the FilterableList
                    placeholder="Filter"
                    optionFields={{
                      id: "@ID",
                      value: "@ID",
                      text: "@Name",
                    }}
                    // TODO: single select list expects a scalar value, not an array
                    value={
                      !!(this.state.selectedFI + "")
                        ? [this.state.selectedFI + ""]
                        : undefined
                    }
                    onHandleSearchInputChange={() =>
                      this.resetSelectedFIEntries()
                    }
                  />
                </FiListWrapper>
              </DialogFieldset>
            </ContentRowWrapper>
            <ContentRowWrapper>
              <SelectButtons>
                <Button
                  domID="id-mn-add-button"
                  name={
                    this.state.selectedMNID == "" || !this.props.canEdit
                      ? "Add"
                      : "Update"
                  }
                  buttonType="emphasized"
                  size="control"
                  type="button"
                  disabled={
                    !this.props.canView ||
                    !this.props.canEdit ||
                    !this.state.selectedFI.length ||
                    this.state.selectedFormType == ""
                  }
                  onClick={(e: React.SyntheticEvent) => {
                    this.onClickAddUpdateButton(e);
                  }}
                />
                <Button
                  domID="id-mn-clear-button"
                  name="Clear"
                  buttonType="standard"
                  size="control"
                  type="button"
                  onClick={(e: React.SyntheticEvent) => {
                    this.onClickClearButton(/*e*/);
                  }}
                />
                <Button
                  domID="id-mn-remove-button"
                  name="Remove"
                  buttonType="standard"
                  size="control"
                  type="button"
                  disabled={
                    !this.props.canView ||
                    !this.props.canDelete ||
                    !this.props.canEdit ||
                    this.state.selectedMNID == ""
                  }
                  onClick={(e: React.SyntheticEvent) => {
                    this.onClickRemoveButton(e);
                  }}
                />
              </SelectButtons>
            </ContentRowWrapper>
          </div>
          <DialogFieldset style={{marginLeft:'0px'}}>
            <DialogLegend>Payer Information</DialogLegend>
            <ReadOnlyPayerInfoBox
              id="ReadOnlyPayerInfoBox"
              name="ReadOnlyPayerInfoBox"
              value={this.state.payerInformation}
              key={this.state.payerInformation + this.state.resetID}
              readOnly
            />
          </DialogFieldset>
        </DialogFieldset>
      </ContentWrapper>
    );
  }
}



