import * as React from 'react';
import * as wind from '../../commonResources/window';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { LandingPageComponent, IComponentProps } from './LandingPageComponent';
import { ARMComponentLoading } from './ARMComponentLoading';
import { ARMComponentError } from './ARMComponentError';
import { ARMComponentMessage } from './ARMComponentMessage';
import { connect } from 'react-redux';
import { actionCreators /*, validationCallback */, IViewProductivityActionProps, IViewProductivityState } from '@store/ViewProductivity';
import AssuranceDateControl from './AssuranceDateControl';
import { push } from 'connected-react-router'
import { Colors } from '../../commonResources/colorVariables';
import styled from 'styled-components';
import { Typography } from '@commonResources/typography';
import { saveComponentState } from '@scripts/util/LandingPageHelpers'
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { getRawToken } from '@scripts/session/SecurityToken';

//TODO: need to verify - commented as dont find any references of imported module
//import { VerticalBarTheme } from '@optum-uicl/ui-core/dist';
import {
    VictoryChart,
    VictoryBar,
    VictoryAxis,
    VictoryTheme,
    VictoryTooltip,
    VictoryLabel
} from 'victory';


import { URLs } from '@commonDevResources/constants';
import { ActionTypes } from '../../scripts/util/ActionHelpers';

interface IWorkProductivityState {
    data: dataRef;
    userCanView: boolean;
    errorCode: any | null;
    optionsData: any[];
    dateRange: string;
    dateRangeValue: string;
    dataAvailable: boolean;
}

type dataRef = {
    Data: [];
    Ticks: [];
    UserId: Number;
    FromDate: Date;
    ThruDate: Date;
};

interface IWorkProductivityProps extends IComponentProps {
    dateRange?: string;
    id?: string;
    updateResultObject: any;
    action: any;
}

interface IChartData {
    x: string,
    y: number,
    index: number,
    label: string,
};

const WorkProductivityContainer = styled.div`
    height: 308px;
    width: 100%;
    ${Typography.ARMFontFamily};
`;

const WorkProductivityDetail = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    display: block;
    margin-left: auto;
    margin-right: auto;

    ${Typography.ARMFontFamily}; 
`;

const WorkProductivityComponentHeader = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;

    ${Typography.ARMFontFamily};
   
    #dateControlWP_armDateCtrl * {
        ${Typography.small}; 
        ${Typography.smallLineHeight};
    };

    .selectDropdown__container {
        margin-top: -3px;
    };

    .selectDropdown__control {
        width: 192px !important;
    }
`;

type IProps = IWorkProductivityProps & RouteComponentProps<{}>;

class WorkProductivity extends React.Component<IProps, IWorkProductivityState>{
    static addItemCount: number = 0;
    static defaultProps: IComponentProps = {};
    currentUserRole = 0;
    constructor(props: IProps) {
        super(props);
        this.state = {
            data: { Data: [], Ticks: [], UserId: 0, FromDate: new Date(), ThruDate: new Date() },
            userCanView: true,
            errorCode: null,
            optionsData: [{ name: "Last 30 days", value: "4" }, { name: "Month to Date", value: "3" }, { name: "Week to Date", value: "2" }, { name: "Yesterday", value: "1" }, { name: "Today", value: "0" }, { name: "Custom Date Range", value: "7" }],
            dateRange: '',
            dateRangeValue: '',
            dataAvailable: false,
        };
    }

    componentDidMount() {
        this.checkUserRights();

    }

    checkUserRights() {
        var xhr = new XMLHttpRequest();
        xhr.open('get', URLs.api + "/api/data/userrights/userPermission/753", true); // FN_VIEW_MYPRODUCTIVITY = 753
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.onload = () => {
            //            console.log("userPermission: [" + xhr.responseText + "]");
            var data = xhr.responseText !== "" ? JSON.parse(xhr.responseText) : null;
            this.setState({ userCanView: (data ? data.userPermission : false) },
                () => { setTimeout(() => this.loadComponent(), 20); });
        };
        xhr.send();
    }
    loadComponent() {
        if (this.state.userCanView || this.currentUserRole == 2) {
            console.log("ComponentId : " + this.props.id);
            console.log("dateRange Prop : " + this.props.dateRange);

            if (this.props.dateRange == undefined || (this.props.dateRange == '7' && (sessionStorage.getItem(this.props.Name + "Start") == null || sessionStorage.getItem(this.props.Name + "End") == null))) {
                this.setState({
                    dateRange: '0',
                    dateRangeValue: "Today"
                }, () => { this.loadChartData() });
            }
            else {
                var dateRangeValue = "";
                var dateRange = '0';

                //var valueDateRange = sessionStorage.getItem(this.props.Name + "Start").replace(/-/g, "/") + "-" + sessionStorage.getItem(this.props.Name + "End").replace(/-/g, "/");
                this.state.optionsData.map((option: any) => {
                    if (option.value == this.props.dateRange) {
                        dateRangeValue = option.name;
                        dateRange = option.value;
                        if (this.props.dateRange == '7') {
                            if (sessionStorage.getItem(this.props.Name + "Start") != null && sessionStorage.getItem(this.props.Name + "End") != null) {
                                dateRangeValue = "";
                            }
                            else {
                                dateRange = '0';
                                dateRangeValue = "Today";
                            }
                        }

                    }
                });

                this.setState({
                    dateRange: dateRange,
                    dateRangeValue: dateRangeValue
                }, () => { this.loadChartData() });
            }
        } else {
            console.log("User doesn't have the rights to View My Productivity");
        }
    }
    loadChartData() {
        //clientStatsService.startTracking("Work Productivity|LoadChartData");
        var gridUrl = URLs.api + '/api/data/GetProductivityData/63/' + this.state.dateRange;

        var name = 'dateControlWP';
        if (this.state.dateRange == '7' && sessionStorage.getItem(name + "Start") != null && sessionStorage.getItem(name + "End") != null)
            gridUrl = gridUrl + "/" + sessionStorage.getItem(name + "Start") + "/" + sessionStorage.getItem(name + "End");


        console.log("RAPWorkProductivity-> Loading from " + gridUrl);
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState == xhr.DONE) {
                if (xhr.status == 200) {
                    //clientStatsService.stopTracking("Work Productivity|LoadChartData");
                    var responseData = xhr.responseText;
                    var gridData = JSON.parse(responseData);
                    this.setState({ data: gridData }); // , () => { this.buildChart() });
                }
                else {
                    console.warn(xhr.status + " response handling " + gridUrl);
                    this.setState({ errorCode: xhr.status });
                }
            }
        };
        xhr.open('get', gridUrl, true);
        xhr.send();
    }

    buildTicks(): number[] {

        let result: number[] = [];
        if (this.state.data.Ticks) {
            this.state.data.Ticks.forEach((ob) => {
                result.push(ob['Value']);
            });
        }
        return result;
    }

    buildChart() {
        console.log('WorkProductivity, buildChart()-> start');

        var dataAvailable = false;
        let count = 0;
        let chartData: IChartData[] = [];
        let sLabel = '';
        if (this.state.data.Data) {
            this.state.data.Data.forEach((ob) => {
                sLabel = "Workflow State: " + ob['Description'] + "   \n Count: " + ob['Count'];
                chartData.push({ x: ob['Description'], y: ob['Count'], index: count++, label: sLabel });
                if (ob['Count'] > 0)
                    dataAvailable = true;
            });
        }

        console.log('buildChart()->end');
        return { data: chartData, dataAvailable: dataAvailable };

    }

    redirectLink() {
        //        if (!this.state.data)
        //            return;
        // direct page to legacy unreleased dashboard. Client-Side Stat to log the clicks.
        //clientStatsService.startTracking("Work Productivity|RedirectToDashboard");
        //clientStatsService.stopTracking("Work Productivity|RedirectToDashboard");

        let url: string = '/Dashboard/63/' +
            this.state.data.UserId +
            '/' +
            this.state.data.FromDate +
            '/' +
            this.state.data.ThruDate +
            '/productivity_bar'; //  .aspx?requestId=' + wind.requestIdFactory();
        this.props.action.ui.updateResultObject({
            masterCrud: this.state.data
        })
        this.props.history.push({ pathname: '/Dashboard/productivityBar', state: 'work_productivity' });
    }

    saveCurrentState() {
        //     console.log('saving props wih current state');
        var temp = JSON.parse(JSON.stringify(this.props));
        var saveThis = { ...temp, dateRange: this.state.dateRange };
        //      console.log(JSON.stringify(saveThis));
        saveComponentState(this.props.id, JSON.stringify(saveThis));
    }

    onDateChange(event: any, selectedValue: string) {
        console.log('RAWorkProductivity-> onDateChange');
        this.setState(
            {
                dateRange: event.value,
                dateRangeValue: selectedValue,

            },
            () => {
                this.loadChartData();
                this.saveCurrentState();
            });
    }


    renderGraph() {
        WorkProductivity.addItemCount = 0;

        let chartData: IChartData[] = [];
        let gridData = { data: chartData, dataAvailable: false };
        if (this.state.data && this.state.data.UserId !== 0)
            gridData = this.buildChart();

        let chartLabel = "Work Productivity: " + this.state.data.FromDate + " - " + this.state.data.ThruDate;
        return (
            <LandingPageComponent ComponentClass={this.props.Name} {...this.props}>
                <WorkProductivityComponentHeader id="header" className="componentHeader">
                    <AssuranceDateControl minDays={30} Name="dateControlWP" helpCtrl="true" helpLink="USER_PRODUCTIVITY.HTM" dateCtrl="true" ctrlLabel=" User Productivity: " data={this.state.optionsData} onDateChange={this.onDateChange.bind(this)} dateRange={this.state.dateRange} dateRangeValue={this.state.dateRangeValue} />

                </WorkProductivityComponentHeader>
                {!this.state.data || this.state.data.UserId === 0 &&
                    // loading stuff
                    <div className="RAPWorkProductivityLoader">
                        <div className="RAPAdSpaceItem">
                            <ARMComponentLoading />
                        </div>
                    </div>
                }
                {this.state.data && this.state.data.UserId !== 0 &&
                    <WorkProductivityContainer id="wpContainer" >
                        <WorkProductivityDetail id="workProductivityDetail" onClick={() => this.redirectLink()} >
                            <VictoryChart domainPadding={{ x: [36, 20] }} width={550} >
                                <VictoryLabel text={chartLabel} x={11} y={20} textAnchor="start" style={{ fontSize: '12px', fill: 'black', fontFamily: 'inherit', fontWeight: 600 }} />
                                <VictoryAxis
                                    dependentAxis
                                    crossAxis={true}
                                    style={{
                                        axis: { stroke: "transparent" },
                                        grid: { stroke: "grey" }
                                    }}
                                    tickValues={this.buildTicks()}
                                    tickLabelComponent={<VictoryLabel dy={-2} dx={-1} style={{ fontSize: '12px', fill: 'black', fontFamily: 'inherit', }} />}
                                />

                                <VictoryAxis
                                    tickLabelComponent={<VictoryLabel style={{ fontSize: '11px', fill: 'black', fontFamily: 'inherit', }} />}

                                />

                                {!gridData.dataAvailable &&
                                    <VictoryLabel text="No data available" x={225} y={180} textAnchor="middle" style={{ fontSize: '16px', fill: 'black', fontFamily: 'inherit', }} />
                                }
                                <VictoryBar
                                    animate={true}
                                    data={gridData.data}
                                    labelComponent={<VictoryTooltip style={{ fontSize: 12, fill: 'black', fontFamily: 'inherit', }} />}
                                    cornerRadius={5}
                                    style={{
                                        data: {
                                            fill: Colors.optumDarkTeal,
                                            width: 25,

                                        }
                                    }}
                                />
                                <VictoryLabel text="Biller Task(s)" x={45} y={286} textAnchor="start" style={{ fontSize: '12px', fill: 'black', fontFamily: 'inherit', fontWeight: 600 }}
                                />
                            </VictoryChart>

                        </WorkProductivityDetail>
                    </WorkProductivityContainer>
                }
            </LandingPageComponent>);
    }
    renderError() {
        return (<LandingPageComponent ComponentClass={this.props.Name} {...this.props}>
            <div className="componentHeader" />
            <div className="RAPWorkProductivityLoader">
                <ARMComponentError />
            </div>
        </LandingPageComponent>);
    }
    renderNoRights() {
        var noRightsStyle: React.CSSProperties = {
            position: 'inherit',
            textAlign: 'center',
            height: '100%',
            width: '100%'
        };
        return (
            <LandingPageComponent ComponentClass={this.props.Name} {...this.props}>
                <div className="componentHeader" />
                <div className="RAPUnreleasedClaimAgeLoader">
                    <div style={noRightsStyle} className="noDataColor"><ARMComponentMessage message="User Does Not Have Rights To View My Productivity" /></div>
                </div>
            </LandingPageComponent>
        );


    }
    render() {
        console.log(this.state, 'STATE in my work')
        /*    if (this.state.data && this.state.data.UserId !== 0) {
                return this.renderGraph();
            } else */
        if (this.state.errorCode) {
            return this.renderError();
        } else if (!this.state.userCanView && this.currentUserRole != 2) {
            return this.renderNoRights();
        } else {
            return this.renderGraph();
        }

    }
    renderComponentMessage(message: string) {
        return (
            <div className="componentLoading">
                <div className="componentLoadingTop" />
                <div className="componentLoadingContent disabled">
                    <img src="Content/images/messageIcon.png" />
                </div>
                <div className="componentMessageText">{message}</div>
                <div className="componentLoadingBottom" />
            </div>
        );
    }

    renderComponentError() {
        return (
            <div className="componentError">
                <div className="componentErrorContent">
                    <ARMComponentError />
                </div>
            </div>
        );
    }
}

var connectedHoc = connect<IViewProductivityState, IViewProductivityActionProps, any, any, any>(
    createCrudMapStateToProps('ViewProductivity'),             // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(WorkProductivity);

export default withRouter(connectedHoc);
