import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, SelectDropdown, Checkbox } from '@optum-uicl/ui-core/dist';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled, { createGlobalStyle } from 'styled-components';

import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend } from "@common/DialogStyles";

import { SelectComponent } from '@common/SelectComponent';
import { CheckBoxComponent } from '@common/CheckBox';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';

import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IPaperClaimsPrintingMaintenanceState , IPaperClaimsPrintingMaintenanceActionProps, actionCreators } from '@store/PaperClaimsPrintingMaintenance';

import {URLs} from "@commonResources/constants";
import {getRawToken} from "@scripts/session/SecurityToken";
import { SelectDropdownValue } from '@optum-uicl/ui-core/dist/Atoms/SelectDropdown/types';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    width: 635px;
    flex-wrap:wrap;

    [id^="PCPM_select_"] label {
        text-transform:uppercase;
        padding-top: 10px;
  }
`;

export const PrintOptionsWrapper = styled.div`
    padding-left:45px;
    padding-top: 5px;
    .previewButton{
        margin-top:15px;
    }
`;

export const PaperOptions = styled.div`
    width: 618px;
    padding: 5px 5px 10px 10px;
    display :block;
`;

export const FunctionSection = styled.div`
    display: flex;
    flex: 1 1 auto;
    margin-bottom: 5px;
`;

export const CheckBoxWrapper = styled.div`
    float: left;
    width: 250px;
    padding-left: 10px;
    .arm_checkbox_label {
        padding-left:0px;
    }
`;

export const CheckBoxRightWrapper = styled.div`
    float: right;
    width: 250px;
    margin-right:10px;
    .arm_checkbox_label {
        padding-left:0px;
    }
`;

export const SelectList = styled.div`
    padding: 5px 5px 5px 10px;
`;

export const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: left;

    button {       
        text-align: center;
        text-transform: uppercase;
    }
`

const GlobalStyles = createGlobalStyle` 
       [id^="PCPM_select_"] > div:first-of-type 
       {
        width: 320px !important;
} 
[id^="select-dropdown-"] #Rejected, [id^="select-dropdown-"] #Accepted 
{ 
    padding: 8px 24px 8px 10px !important;
    }
    `;

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    canSecCheck1: boolean,
    canSecCheck2: boolean,

    title: string,
};

interface IComponentState {
}

export const DEFAULT_STATE: IComponentState = {};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IPaperClaimsPrintingMaintenanceProps = IMergeCrudComponentProps<IPaperClaimsPrintingMaintenanceState, IPaperClaimsPrintingMaintenanceActionProps, IOwnProps>;




export class PaperClaimsPrintingMaintenance extends React.Component<IPaperClaimsPrintingMaintenanceProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        canSecCheck2: false,
        canSecCheck1 : false,
        title: "Print Maintenance",
    };

    constructor(props: IPaperClaimsPrintingMaintenanceProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentWillMount() {
        //this.props.action.ui.reset(13);
        this.props.action.crud.get({ crudId: CrudTypes.mctiPaperClaimsConfig });
        // this.setInitialLoad();
    }

    public componentDidMount() {
       
    }

    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        //if (!this.validateForm())
        //    return;

        let PrintOptionList = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.PrintOptionList;
        let DateOption = this.props.dataStore.ui.DateOptions.DateOption;
        let PutPaperClaimsPrintingMaintenanceInfo:any = {
            PaperClaimsPrintingMaintenanceInfo: {
            PrintOptionList: {
            }
        }
        };
       let putPrintOptions = PrintOptionList.PrintOption.map((element:any) => {
            let obj:any = {'@ID':element['@ID'], '@FontFaceID':element['@FontFaceID'], '@FontSizeID':element['@FontSizeID'], '@PrintTypeID':element['@PrintTypeID']}
            obj['@bitBatchClaimPrompt'] = DateOption['@BatchClaimPrompt'];
            obj['@bitSingleClaimPrompt'] = DateOption['@SingleClaimPrompt'];
            obj['@CMSPrintDate'] = DateOption['@CMSPrintDate'] || '';
            obj['@CMSClsPrintDate'] = DateOption['@CMSClsPrintDate'] || '';
            obj['@CMSViewDate'] = DateOption['@CMSViewDate'] || '';
            return obj;
        });
        console.log(this.props.dataStore.crud.data);
        PutPaperClaimsPrintingMaintenanceInfo.PaperClaimsPrintingMaintenanceInfo.PrintOptionList.PrintOption = putPrintOptions;
        
        this.props.action.crud.update({crudId: CrudTypes.mctiPaperClaimsConfig, data: PutPaperClaimsPrintingMaintenanceInfo });
        // this.props.action.ui.resetDefaultState();


        this.props.history.push('/LandingPage');
    }

    public onSelectClaimType(e: React.ChangeEvent<HTMLSelectElement>) {
        let selectedPrintOption = this.props.dataStore.ui.printOptionList.PrintOption.find(
            (ob: any) => ob['@ClaimTypeID'] === e.target.value);
        this.props.action.ui.selectPrintOptions({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                printOption: selectedPrintOption
            }
        });
    }

    public updatePrintType(e: SelectDropdownValue) {
        let selectedPrintOptionIndex = this.props.dataStore.ui.printOptionList.PrintOption.findIndex(
            (ob: any) => ob['@ID'] === this.props.dataStore.ui.selectedPrintOption['@ID']);
        let printOptionList = this.props.dataStore.ui.printOptionList;
        printOptionList.PrintOption[selectedPrintOptionIndex]['@PrintTypeID'] = e.value;
        this.props.action.ui.updatePrintType({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                printOptionList: printOptionList
            }
        });
    }

    public updateFontSize(option: SelectDropdownValue) {
        let selectedPrintOptionIndex = this.props.dataStore.ui.printOptionList.PrintOption.findIndex(
            (ob:any) => ob['@ID'] ===  this.props.dataStore.ui.selectedPrintOption['@ID']);
        let printOptionList = this.props.dataStore.ui.printOptionList;
        printOptionList.PrintOption[selectedPrintOptionIndex]['@FontSizeID'] = option.value;
        this.props.action.ui.updatePrintType({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                printOptionList: printOptionList
            }
        });
    }

    public updateFontFace(e: SelectDropdownValue) {
        let selectedPrintOptionIndex = this.props.dataStore.ui.printOptionList.PrintOption.findIndex(
            (ob:any) => ob['@ID'] ===  this.props.dataStore.ui.selectedPrintOption['@ID']);
        let printOptionList = this.props.dataStore.ui.printOptionList;
        printOptionList.PrintOption[selectedPrintOptionIndex]['@FontFaceID'] = e.value;
        this.props.action.ui.updatePrintType({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                printOptionList: printOptionList
            }
        });
    }

    public getSelectedFormType() {
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.ui.selectPrintOption || !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList.ClaimType
        ) return { value: '', label: '' };

        let item = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList.ClaimType.find(
            (ob: any) => ob['@ID'] === this.props.dataStore.ui.selectPrintOption["@StateID"]);
        let result = { value: '', label: '' };
        if (item) {
            let label = item['@Name'];
            if (item['@ID'] == "0") {
                label = "Select State";
            }
            result.label = label;
            result.value = item['@ID'];
        } else {
            result.value = "Select State";
            result.label = '- Select State -';
        }

        return result;
    }

    public getFormTypesList() {

        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList.ClaimType
        ) return [];

        let result: any[] = [];

        if (!this.props.dataStore.ui.initialValuesLoaded) {
            this.setInitialPrintOption();
        }



        this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList.ClaimType.forEach((ob:any) => {
            if (ob['@Name'] && ob['@ID'] !== "0") {
                if ( result.findIndex((item:any) => item.id === ob['@ID']) === -1)
                    result.push({ name: ob['@Name'], id: ob['@ID'] });
            }
        });

       
       

        return result;

    }

    public setInitialPrintOption() {
        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.PrintOptionList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.PrintOptionList.PrintOption
        ) return ;

        this.updatePrintOption(this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.PrintOptionList.PrintOption[0])
        
    }

    public updatePrintOption(printOption: any) {
        this.props.action.ui.updatePrintOptions({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
               printOption
            }
        });
    }

    public getPrintTypeList(){
        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FormTypeList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FormTypeList.FormType
        ) return [];

        let result: any[] = [];

        this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FormTypeList.FormType.forEach((ob:any) => {
            const ClaimTypeID = this.props.dataStore.ui.selectedPrintOption["@ClaimTypeID"]
            const redFormOnlyArray = ["3004"]
            const grayFormOnlyArray = ["3010", "3011", "3012", "3013", "3014"]

            const isRedFormOnly = redFormOnlyArray.includes(ClaimTypeID)
            const isGrayFormOnly = grayFormOnlyArray.includes(ClaimTypeID)
            const isAllForms = !isRedFormOnly && !isGrayFormOnly

            const isRedForm = ob['@Name'] === "Red Form"
            const isGrayForm = ob['@Name'] === "Gray Form"

            if ((isRedFormOnly && isRedForm) || (isGrayFormOnly && isGrayForm) || isAllForms) {
                result.push({ label: ob['@Name'], value: ob['@ID'] });
            }
        });
        return result;
    }

    public getFontFaceList(){
        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontFaceList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontFaceList.FontFace
        ) return [];

        let result: any[] = [];

        this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontFaceList.FontFace.forEach((ob:any) => {
            if (ob['@Name'] && ob['@ID'] !== "0")
                result.push({ label: ob['@Name'], value: ob['@ID'] });
        });
        return result;
    }

    public getFontSizeList(){
        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontSizeList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontSizeList.FontSize
        ) return [];

        let result: any[] = [];

        this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontSizeList.FontSize.forEach((ob:any) => {
            if (ob['@Name'] && ob['@ID'] !== "0")
                result.push({ label: ob['@Name'], value: ob['@ID'] });
        });
        return result;
    }

    public getInitialPrintTypeActionSelectedItem() : SelectDropdownValue {
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.ui.selectedPrintOption ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FormTypeList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FormTypeList.FormType) {
            return { value: '', label: '', id: '' };
        }

        let result: SelectDropdownValue = { value: '', label: '', id: '' }; 
        let selectedPrintType = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FormTypeList.FormType.find(
            (ob:any) => ob['@ID'] ===  this.props.dataStore.ui.selectedPrintOption["@PrintTypeID"]);
        let formType = this.props.dataStore.ui.selectedPrintOption["@ClaimTypeID"];
        
        var dentalForm = (formType == '3010' || formType == '3011' || formType == '3012' || formType == '3013'|| formType == '3014' );
        var ca251 = (formType == '3004')

        if(dentalForm){
            result.value = '1';
        } else if(ca251){
            result.value = '2';
        } else if (selectedPrintType) {
           
            result.value = selectedPrintType['@ID'];
        } else {
            result.value = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FormTypeList.FormType[0]['@ID'];
        }
        result.id = result.value;
        result.label = this.getPrintTypeList()?.find((ob: any) => ob.value === result.value)?.label;
        return result;
    }

    public getInitialFontFaceActionSelectedItem(): SelectDropdownValue {
        if (!this.props.dataStore.crud.data || !this.props.dataStore.ui.selectedPrintOption ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontFaceList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontFaceList.FontFace)
            return { value: '', label: '' };


        let result: SelectDropdownValue = { value: '', label: '' };

        let selectedFontFace = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontFaceList.FontFace.find(
            (ob:any) => ob['@ID'] ===  this.props.dataStore.ui.selectedPrintOption["@FontFaceID"]);
        if (selectedFontFace) {
           
            result.value = selectedFontFace['@ID'];
        } else {
            result.value = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontFaceList.FontFace[0]['@ID'];
        }
        result.id = result.value;
        result.label = this.getFontFaceList()?.find((ob: any) => ob.value === result.value)?.label;
        return result;
    }

    public getInitialFontSizeActionSelectedItem(): SelectDropdownValue {
        if (!this.props.dataStore.crud.data || !this.props.dataStore.ui.selectedPrintOption ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontSizeList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontSizeList.FontSize)
            return { value: '', label: '' };


        let result: SelectDropdownValue = { value: '', label: '' };

        let selectedFontSize = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontSizeList.FontSize.find(
            (ob:any) => ob['@ID'] ===  this.props.dataStore.ui.selectedPrintOption["@FontSizeID"]);
        if (selectedFontSize) {
            result.value = selectedFontSize['@ID'];
           
        }else{
            result.value = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontSizeList.FontSize[0]['@ID'];
        }
        result.id = result.value;
        result.label = this.getFontSizeList()?.find((ob: any) => ob.value === result.value)?.label;
        return result;
    }


    public getInitialClaimTypeActionSelectedItem() {
        if (!this.props.dataStore.crud.data || !this.props.dataStore.ui.selectedPrintOption ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList.ClaimType)
            return '';

        let result = '';

        let selectedClaimType = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList.ClaimType.find(
            (ob:any) => ob['@ID'] ===  this.props.dataStore.ui.selectedPrintOption["@ClaimTypeID"]);
        if (selectedClaimType) {
          
            result = selectedClaimType['@ID'];
           
        }else{
          
            result = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList.ClaimType[0]['@ID'];
        }
        return result;
    }

    public onToggleBatchClaimReport(checked?: boolean) {
        let DateOptions =  this.props.dataStore.ui.DateOptions;
        DateOptions.DateOption['@BatchClaimPrompt'] = checked ? '1' : '0';
        this.props.action.ui.onToggleBatchClaimReport({ masterCrud: this.props.dataStore.crud.data, uiData: { DateOptions: DateOptions } });
    }

    public onToggleSingleClaimReport(checked?: boolean) {
        let DateOptions =  this.props.dataStore.ui.DateOptions;
        DateOptions.DateOption['@SingleClaimPrompt'] = checked ? '1' : '0';
        this.props.action.ui.onToggleSingleClaimReport({ masterCrud: this.props.dataStore.crud.data, uiData: { DateOptions: DateOptions } });
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
            this.props.history.push('/LandingPage');
    }

    public onPreviewClick(){

        let selectedFontFace = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontFaceList.FontFace.find(
            (ob:any) => ob['@ID'] ===  this.props.dataStore.ui.selectedPrintOption["@FontFaceID"]);

            let selectedFontSize = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontSizeList.FontSize.find(
                (ob:any) => ob['@ID'] ===  this.props.dataStore.ui.selectedPrintOption["@FontSizeID"]);

        const ClaimTypeID = this.props.dataStore.ui.selectedPrintOption["@ClaimTypeID"]
        const redFormOnlyArray = ["3004"]
        const grayFormOnlyArray = ["3010", "3011", "3012", "3013", "3014"]

        const isRedFormOnly = redFormOnlyArray.includes(ClaimTypeID)
        const isGrayFormOnly = grayFormOnlyArray.includes(ClaimTypeID)

        const PrintTypeID = parseInt(this.props.dataStore.ui.selectedPrintOption['@PrintTypeID'])

        let PrintID

        if (isGrayFormOnly) {
            PrintID = 1
        } else if (isRedFormOnly) {
            PrintID = 2
        } else {
            PrintID = PrintTypeID
        }
        
        var params:any = {
                Form: parseInt(ClaimTypeID),
                Print: PrintID,
                Font: selectedFontFace['@Name'],
                Size: parseInt(selectedFontSize['@Name']),
                Adjust:Number(selectedFontSize['@FontAdjust'])
        }
        if (params.Form == 1001)
            params.Form = 3001;
        if (params.Form == 1011)
            params.Form = 2011;
        let query = Object.keys(params)
             .map((k:any) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');
        let mypath = ''; 
        fetch(URLs.api + `/api/data/checkDataAlignmentWithParams`,
                {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(params),
            }).then(async (response) => {
                if (response.status === 200) {
                     return response.json();
                } else {
                    throw new Error(response.statusText);
                }
            }).then((data: any) => {
                mypath = data.PrintResponses[0].Path;
                console.log("PaperClaimsPrinting - CheckDataAlignment - path=" + mypath);
                window.document.open("/shared/Open%3D" + mypath, 'FormAlignment', 'width=1273,height=1250');
            })
            .catch(error => {
                console.log("PaperClaimsPrinting - CheckDataAlignment - Error");
                alert('Preview Request Failed. Please try again. If the issue persists, please contact Support.');
                //sessionStorage.setItem('SystemErrorNotification', 'Check Data Alignment request failed. Please try again. If the issue persists, please contact Support.');
            })
            .finally(() => {
                this.setState({ funcCallBusy: false });
                //this.props.history.push('/ui/LandingPage');
            });
    }

    public isFontDisabled() {
        let formType = this.props.dataStore.ui && this.props.dataStore.ui.selectedPrintOption && this.props.dataStore.ui.selectedPrintOption["@ClaimTypeID"];
        var dentalForm = (formType == '3010' || formType == '3011' || formType == '3012' || formType == '3013' || formType == '3014');
        var ca251 = (formType == '3004')
        return !dentalForm && !ca251 && this.props.dataStore.ui && this.props.dataStore.ui.selectedPrintOption && (this.props.dataStore.ui.selectedPrintOption['@PrintTypeID'] === '3' || this.props.dataStore.ui.selectedPrintOption['@PrintTypeID'] === '4')

    }



public render() {
    var instruction = <React.Fragment>Use this page to set up a printing option for each type of claim form you print. Select the claim form, then select the print option and click OK.</React.Fragment>;
    var buttons = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e) } />;
        let formTypesList = this.getFormTypesList();
        let printTypeList = this.getPrintTypeList();
        let fontFaceList = this.getFontFaceList();
        let fontSizeList = this.getFontSizeList();

        return (
            <DialogWrapper title="Print Options" instruction={instruction} buttons={buttons}  helpUrl='/Support/Help/HELP_Maint_PaperClaims.htm' >
                <ContentWrapper>
                <GlobalStyles />
                <FunctionSection>
                        <SelectList>
                            <DialogFieldset width='200px' style={{ padding: '10px' }}>
                            <DialogLegend>Form Types</DialogLegend>
                            <SelectComponent
                                title='Print Options'
                                size={10}
                                    width='200px'
                                    height='100%'
                                optionFields={{
                                    value: "id",
                                    text: "name"
                                }}
                                records={formTypesList}
                                selectedValue={this.getInitialClaimTypeActionSelectedItem()}
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectClaimType(e)}
                            >
                            </SelectComponent>
                        </DialogFieldset>
                    </SelectList>
                    <PrintOptionsWrapper>
                    <SelectDropdown
                                domID="PCPM_select_PrintType"
                                label="Print Type:"
                                options={printTypeList}
                                className="PCPMSelect-class"
                                size="large"
                                isClearable={false}
                                isSearchable={false}
                                initialValue={this.getInitialPrintTypeActionSelectedItem()}
                                onChange={(e: SelectDropdownValue) => this.updatePrintType(e)}
                                errorMessage=""
                                    />
                    <SelectDropdown
                                domID="PCPM_select_FontFace"
                                label="Font Face:"
                                options={fontFaceList}
                                className="PCPMSelect-class"
                                size="large"
                                isClearable={false}
                                isSearchable={false}
                                initialValue={this.getInitialFontFaceActionSelectedItem()}
                                onChange={(e: SelectDropdownValue) => this.updateFontFace(e)}
                                errorMessage=""
                                disabled = {this.isFontDisabled()}  />
                    <SelectDropdown
                                domID="PCPM_select_FontSize"
                                label="Font Size:"
                                options={fontSizeList}
                                className="PCPMSelect-class"
                                initialValue={this.getInitialFontSizeActionSelectedItem()}
                                size="large"
                                isClearable={false}
                                isSearchable={false}
                                errorMessage=""
                                onChange={(option: SelectDropdownValue) => this.updateFontSize(option)}
                                disabled = {this.isFontDisabled()}
                            />
                     <SelectButtons>
                        <Button
                            domID="PaperClaimPrintingMaintance_PreviewButton"
                            name="Preview"
                            buttonType="standard"
                            size="small"
                            type="button"
                            className="previewButton"
                            disabled = {this.isFontDisabled() || this.props.dataStore.ui.selectedPrintOption["@ClaimTypeID"] === '1000'}
                            onClick={() => this.onPreviewClick()}                                                       
                        />
                     </SelectButtons>
                     </PrintOptionsWrapper>
                     </FunctionSection>
                    
                     <PaperOptions>
                        <DialogFieldset style={{ padding: '10px' }}>
                            <DialogLegend>Prompt For paper options</DialogLegend>
                            <CheckBoxWrapper>
                                <Checkbox
                                    domID='batchClaim'
                                    label='Batch Claim Printing'
                                    checked={this.props.dataStore.ui.batchClaimReport === '1' ? true : false}
                                    onChange={(e: React.SyntheticEvent, checked?: boolean) => this.onToggleBatchClaimReport(checked)}
                                />
                                </CheckBoxWrapper>
                                <CheckBoxRightWrapper>
                                <Checkbox
                                    domID='singleClaim'
                                    label='Single Claim Printing'
                                    checked={this.props.dataStore.ui.singleClaimReport === '1' ? true : false}
                                    onChange={(e: React.SyntheticEvent, checked?: boolean) => this.onToggleSingleClaimReport(checked)}
                                />
                                </CheckBoxRightWrapper>
                     </DialogFieldset>
                     </PaperOptions>
                     </ContentWrapper>
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IPaperClaimsPrintingMaintenanceState, IPaperClaimsPrintingMaintenanceActionProps, IOwnProps, IPaperClaimsPrintingMaintenanceProps, ApplicationState>(
    createCrudMapStateToProps('PaperClaimsPrintingMaintenance'),             // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(PaperClaimsPrintingMaintenance);

export default withRouter(connectedHoc);
