import * as React from 'react';
import styled from 'styled-components';
import { IImageButtonImage, ImageButton } from '@common/ImageButton';

import ArrowLeftUp from '@content/images/Buttons/But_ArrowLeft_creamUp.png';
import ArrowLeftDown from '@content/images/Buttons/But_ArrowLeft_creamDown.png';
import ArrowLeftFocus from '@content/images/Buttons/But_ArrowLeft_creamFocus.png';
import ArrowLeftOff from '@content/images/Buttons/But_ArrowLeft_creamOff.png';
import ArrowLeftOver from '@content/images/Buttons/But_ArrowLeft_creamOver.png';

import ArrowRightFocus from '@content/images/Buttons/But_ArrowRight_creamFocus.png';
import ArrowRightOff from '@content/images/Buttons/But_ArrowRight_creamOff.png';
import ArrowRightOver from '@content/images/Buttons/But_ArrowRight_creamOver.png';
import ArrowRightUp from '@content/images/Buttons/But_ArrowRight_creamUp.png';
import ArrowRightDown from '@content/images/Buttons/But_ArrowRight_creamDown.png';

export interface IArrowButtonsProps {
    title: string;                                          // title of buttons
    width?: string;                                         // width for your screen
    paddingTop?: string;                                    // moving it down if needed
    className?: string;                                     // classname if needed
    toolTipLeft?: string;                                   // tooltip?
    toolTipRight?: string;                                  // tooltip?
    disabled?: boolean;
    disabledToRightButton?: boolean,
    disabledToLeftButton?: boolean,
    onClick?: React.ChangeEventHandler<HTMLButtonElement>;  // action taken (see FieldLevelSecurity.TSX for usage)
    onLeftBtnClick?: React.ChangeEventHandler<HTMLButtonElement>; 
    onRightBtnClick?: React.ChangeEventHandler<HTMLButtonElement>; 
    margin?: string;
    gap?: string;
};

export interface IArrowButtonsState {
    disabled: boolean;
};

export interface IArrowButtonsWrapperProps {
    widthValue?: string;
    paddingTopValue?: string;
    marginValue?: string;
    gapValue?: string;
};

export const ArrowButtonsWrapper = styled.div`
    display: flex;
    flex: 0;
    flex-direction: column;

    min-width: 35px;
    min-height: 40px;
    ${(props: IArrowButtonsWrapperProps) => props.widthValue && `width: ${props.widthValue}`};
    ${(props: IArrowButtonsWrapperProps) => props.marginValue && `margin: ${props.marginValue}`};
    ${(props: IArrowButtonsWrapperProps) => props.gapValue && `gap: ${props.gapValue}`};
    ${(props: IArrowButtonsWrapperProps) => props.paddingTopValue && `padding-top: ${props.paddingTopValue}`};
`;

export class ArrowButtons extends React.Component<IArrowButtonsProps, IArrowButtonsState>
{
    static defaultProps: IArrowButtonsProps = {
        title: "ArrowButtons",
        width: '45px',
        paddingTop: '65px',
        className: 'arrow-button',
        toolTipLeft: 'Arrow Left',
        toolTipRight: 'Arrow Right',
        disabled: false,
    };

    static arrowLeftIcon: IImageButtonImage = {
        //Focus: ArrowLeftFocus,
        Off: ArrowLeftOff,
        Over: ArrowLeftOver,
        Up: ArrowLeftUp,
        Down: ArrowLeftDown,
    }

    static arrowRightIcon: IImageButtonImage = {
        //Focus: ArrowRightFocus,
        Off: ArrowRightOff,
        Over: ArrowRightOver,
        Up: ArrowRightUp,
        Down: ArrowRightDown,
    }

    constructor(props: IArrowButtonsProps) {
        super(props);
        //this.state = {
        //    disabled: this.props.disabled || false,
        //}
    }

    public getArrowRightIcon() {
        let disabled = this.props.disabled;
        if (this.props.disabledToRightButton) { disabled = this.props.disabledToRightButton; }
        return (
            <ImageButton
                Title='rightArrow'
                tooltip={this.props.toolTipRight}
                altText={this.props.toolTipRight}
                image={ArrowButtons.arrowRightIcon}
                className="arrow-button"
                disabled={disabled}
                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                    if (this.props.onClick !== undefined) {
                        this.props.onClick(e);
                    } else {
                        if (this.props.onRightBtnClick !== undefined) {
                            this.props.onRightBtnClick(e);
                        }
                    }
                }} 
            />
        );
    }

    public getArrowLeftIcon() {
        let disabled = this.props.disabled;
        if (this.props.disabledToLeftButton) { disabled = this.props.disabledToLeftButton; }
        return (
            <ImageButton
                Title='leftArrow'
                tooltip={this.props.toolTipLeft}
                altText={this.props.toolTipLeft}
                image={ArrowButtons.arrowLeftIcon}
                className="arrow-button"
                disabled={disabled}
                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                    if (this.props.onClick !== undefined) {
                        this.props.onClick(e);
                    } else {
                        if (this.props.onLeftBtnClick !== undefined) {
                            this.props.onLeftBtnClick(e);
                        }
                    }
                }}
            />
        );
    }

    render() {
        var arrowLeftIcon = this.getArrowLeftIcon();
        var arrowRightIcon = this.getArrowRightIcon();

        return (
            <ArrowButtonsWrapper
                id={this.props.title}
                widthValue={this.props.width}
                marginValue={this.props.margin}
                paddingTopValue={this.props.paddingTop}
                className={this.props.className}
                gapValue={this.props.gap}
            >
                {arrowRightIcon}
                {arrowLeftIcon}
            </ArrowButtonsWrapper>
        );
    }
};