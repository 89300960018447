import { Reducer } from "redux";
import {
  createDataAction,
  ActionTypes,
  createApiAction,
  createApiBodyAction,
  IApiAction,
  createApiFileUploadAction,
  ValidationCallback,
} from "@scripts/util/ActionHelpers";
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import { DESFileUploadType } from "@scripts/@types/MasterCrud/DESFileUpload";
import { URLs } from "@commonDevResources/constants";

export interface ISubmitDESFilesUploadUIState {
  fileTypes: any;
  fileTypeTop: any;
  fileTypeBottom: any;
  fileUploadTop: string;
  fileUploadTopName: string;
  fileUploadBottom: string;
  fileUploadBottomName: string;
}

export interface IDESFileUpload {
    value: string;
    name: string;
}

interface IDESFileType {
  value: any;
}

interface IDESUploadDESFiles {
  value: any;
}

export interface IDESFileUploadData
  extends ICrudActionData<DESFileUploadType, IDESFileUpload> {}
export interface IDESFileTypeData
  extends ICrudActionData<DESFileUploadType, IDESFileType> {}
export interface IDESUploadDESFilesData
  extends ICrudActionData<DESFileUploadType, IDESUploadDESFiles> {}

export const actionCreators = {
  getFileTypeData: (callback?: ValidationCallback<any>) =>
    createApiAction(
      "GET_DES_FILE_TYPE_DATA",
      URLs.api + "/api/data/GetDESFileTypes",
      undefined,
      callback
    ),
  selectFileUploadTop: (selectInfoTop: IDESFileUploadData) =>
    createDataAction("CHANGE_FILE_UPLOAD_TOP", selectInfoTop),
  selectFileUploadBottom: (selectInfoBottom: IDESFileUploadData) =>
    createDataAction("CHANGE_FILE_UPLOAD_BOTTOM", selectInfoBottom),
  selectFileTypeTop: (fileTypeTop: IDESFileTypeData) =>
    createDataAction("CHANGE_FILE_TYPE_TOP", fileTypeTop),
  selectFileTypeBottom: (fileTypeBottom: IDESFileTypeData) =>
    createDataAction("CHANGE_FILE_TYPE_BOTTOM", fileTypeBottom),
  uploadDESFiles: (
    fileTypes: any,
    files: IDESUploadDESFilesData,
    callback?: ValidationCallback<any>
  ) =>
    createApiFileUploadAction(
      "DES_UPLOAD",
      `${URLs.api}/api/data/UploadDESFiles?fileTypes=${fileTypes}`,
      files,
      {},
      callback
    ),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>["type"];

export const defaultState: ISubmitDESFilesUploadUIState = {
  fileTypes: null,
  fileTypeTop: null,
  fileTypeBottom: null,
  fileUploadTop: "",
  fileUploadBottom: "",
  fileUploadTopName: "",
  fileUploadBottomName: "",
};

export const reducer: Reducer<ISubmitDESFilesUploadUIState, KnownActions> = (
  state: ISubmitDESFilesUploadUIState | undefined,
  action: KnownActions
) => {
  let newState = {};
  if (state != undefined) {
    switch (action.type) {
      case "CHANGE_FILE_TYPE_TOP": {
        return {
          ...state,
          fileTypeTop: action.data.uiData.value,
        };
        break;
      }

      case "CHANGE_FILE_TYPE_BOTTOM": {
        return {
          ...state,
          fileTypeBottom: action.data.uiData.value,
        };
        break;
      }
      case "CHANGE_FILE_UPLOAD_TOP": {
        return {
          ...state,
          fileUploadTop: action.data.uiData.value,
          fileUploadTopName: action.data.uiData.name,
        };
        break;
      }
      case "CHANGE_FILE_UPLOAD_BOTTOM": {
        return {
          ...state,
          fileUploadBottom: action.data.uiData.value,
          fileUploadBottomName: action.data.uiData.name,
        };
        break;
      }
      case "GET_DES_FILE_TYPE_DATA": {
        switch (action.status.status) {
          case "REQUEST":
            break;

          case "SUCCESS": {
            const responseData: any = action?.responseData;
            if (responseData) {
              return { ...state, fileTypes: responseData.DESFileTypes };
            } else {
              console.log("where's ma data???");
            }  
            break;
          }
          case "FAIL": {
            console.log(action, "Failed to retrieve DESFileTypes");
            break;
          }
        }
        break;
      }
      case "DES_UPLOAD": {
        switch (action.status.status) {
          case "REQUEST":
            break;

          case "SUCCESS": {
                const responseData: any = action?.responseData;

                console.log(responseData)

            if (responseData === "success") {
              return { ...state };
            } else {
              console.log("where's ma data???");
            }
            break;
          }
          case "FAIL": {
            console.log(action, "Failed to upload DES Files...");
            break;
          }
        }
        break;
      }
      default:
        return state;
    }
  }

  return state || defaultState;
};
