import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';
import { IClaimFilterState, IClaimFilterActionProps, actionCreators } from '@store/ClaimFilter';
import { ClaimFilter, IClaimFilterComponentProps } from './ClaimFilter';

export interface ICreateReportFilterComponentState { };

export const DEFAULT_STATE: ICreateReportFilterComponentState = {};

type IOwnProps = IClaimFilterComponentProps & RouteComponentProps<{}>;

type IClaimFilterProps = IMergeApiComponentProps<IClaimFilterState, IClaimFilterActionProps, IOwnProps>;
interface LocationState {
    parameter: any;
    process: string;
    title: any;
    execAction: any;
    execNode: any;
    extraInstruction: any;
    claimStateRestrictions: any;
}
export class CreateReportFilter extends React.Component<IClaimFilterProps, ICreateReportFilterComponentState>
{
     

    static defaultProps: IClaimFilterComponentProps = {
        canEdit: false,
        canView: false,
        canCreate: false,
        ncsIsEnabled: false,
        process: "unreleased",
        execNode: "Report",
        execAction: "GetReport",
        execProcess: "Unreleased",
        parameter: "some dummy",
        apiType: "ClaimFilter",
        title: " Claim Report",  // //  ClaimTitle = Request.Form("Process") & " Claim Report"
        executeJS: undefined,
        extraInstruction: "Click Continue to create the report without viewing the claim list.",
    };

/*  this class is meant to handle the following process
 *  
    Case "unreleased", "released", "deleted", "custom"
 	Case "compliance", "direct"
	Case "analysis"
 */  



    constructor(props: IClaimFilterProps) {
        super(props);
        //debugger;
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.props.history.push('/LandingPage');
        }

    }

    public componentWillUnmount() {
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public render() {
        var parameterValue = (this.props.history.location.state as LocationState)?.parameter ??
        {
            'ReportRequests': {
                '@Type': '',
                'ReportRequest' : []
            }
        };

        /*
            NOTE(s):
            - you have to get the props passed in from the state since we are doing a history.push
         */
        //debugger;

        //debugger;
        var processValue: string = (this.props.history.location.state as LocationState)?.process ?? "Unreleased";
        let title: any = (this.props.history.location.state as LocationState)?.title;
        let actionOf: any = (this.props.history.location.state as LocationState)?.execAction;
        let nodeOf: any = (this.props.history.location.state as LocationState)?.execNode;
        let instructOf: any = (this.props.history.location.state as LocationState)?.extraInstruction;
        let csr: any = (this.props.history.location.state as LocationState)?.claimStateRestrictions;

        return (
            <div>
                <ClaimFilter
                    {...this.props}
                    title={title}
                    parameter={parameterValue}
                    execProcess={processValue}
                    process={processValue}
                    execAction={actionOf}
                    execNode={nodeOf}
                    extraInstruction={instructOf}
                    claimStateRestrictions={csr?.length>0 ? csr : undefined}   
                />
            </div>

        );
    }
};

var connectedHoc = connect<IClaimFilterState, IClaimFilterActionProps, IOwnProps, IClaimFilterProps, ApplicationState>(
    createApiMapStateToProps('claimFilter'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(CreateReportFilter);

export default withRouter(connectedHoc);
