import * as React from 'react'; 
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { Input, Button } from '@optum-uicl/ui-core/dist';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { SelectComponent } from '../../common/SelectComponent';
import { ArrowButtons } from '@common/ArrowButtons';
import {
    IMergeCrudComponentProps,
    createCrudMapStateToProps,
    createCrudMapDispatchToProps,
    mergeCrudComponentProps,
    resetCrudComponentState
} from '../../../scripts/util/CrudComponentHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IEligibilityProfileState, IEligibilityProfileActionProps, actionCreators, validationCallback } from '@store/EligibilityProfile';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';
import { AvailableSelectedHelper } from './AvailableSelectedHelper';
import { SelectOption } from './Shared';
import { IRecordProp } from './EnabledPayersGrid/GridConfig';
import { EnabledPayersGrid } from './EnabledPayersGrid/EnabledPayersGrid';
import { get, has, isArray, isEmpty, isUndefined } from 'lodash';
import { ModalComponent } from '@common/ModalComponent';
import UpdateSuppressionModal from './UpdateSuppressionModal'

import UpdateFieldModal from './UpdateRequestFieldsModal';
import AdditionalPayers from './AdditionalPayers'
import { CheckBoxComponent } from "@common/CheckBox";
import Typography from "@commonResources/typography";
import Colors from "@commonResources/colorVariables";
import { ModalConfirmation } from '@common/ModalConfirmation';
import { ICookies_Config } from '@store/ConfigData';
import {
    MCEligibilityPayerMaintenanceInfo, MCSEligibilityPayerInfo,
    MCClientPayerInfo, ClientPayersPayer, EligibilityPayersPayer
} from '../../../scripts/@types/MasterCrud/EligibilityProfile';

export enum IsChecked {
    N = "N",
    Y = "Y",
}


// this should be in a util folder and shared throughout the app
const forceToArray = (input: any) => {
    if (Array.isArray(input)) {
        return input
    } else if (typeof input === 'object') {
        return [input]
    } else {
        return []
    }
}

export const ButtonToolTip = styled.div`
    overflow: hidden;
    white-space: nowrap;
`;

export const ArrowButtonsAlignmentAdjustment = styled.div`
    position: relative;
    margin-left: 9px;
`;

export const ContentWrapper = styled.div`
`;

export const ARMLabel = styled.div`
    ${Typography.ARMFontFamily};
    ${Typography.bold};
    font-size: 13.33px;
    padding-top: 3px;
    padding-left: 5px;
`;

export const ErrTxt = styled.div`
    ${Typography.ARMFontFamily};
    ${Typography.medium};
    color: ${Colors.digitalRed130};
    font-size: 11px;
    padding-top: 5px;
    padding-left: 5px;
    display: inline;
`;

export const HourInputWrapper = styled.div`
    width: 45px;
    margin-bottom: 0px !important;
    height: 20px;
`;

export const HoursLabelWrapper = styled.div`
    font-size: 12px;
    margin-top: 3.25px;
    margin-right: 5px;
    margin-right: 25px;
`;

export const HoursCheckBoxWrapper = styled.div`
    width: 170px;
    height: 20px;
`;

export const Exclude90CheckBoxWrapper = styled.div`
    width: 215px;
`;

export const HoldClaimsCheckBoxWrapper = styled.div`
    text-align: left;
    margin-bottom: 5px;
`;

interface IComponentProps {
    canEdit: boolean, // SecurityBits.FN_EDIT_PAYERELIGIBILITY
    canView: boolean, // SecurityBits.FN_VIEW_PAYERELIGIBILITY
}

interface IComponentState {
    isBusy: boolean;
    enrollmentAlert: boolean;
    availablePayers: SelectOption[];
    availablePayersFilter: string;
    enabledPayers: ClientPayersPayer[];
    selectedAvailablePayerIds: string[];
    additionalPayers: EligibilityPayersPayer[];
    filteredPayers: EligibilityPayersPayer[];
    maintenanceInfo: MCEligibilityPayerMaintenanceInfo | undefined;
    searchText: string;
    activeModal: JSX.Element | undefined;
    customModalWidth: string;
    hasReleaseHoursError: boolean;
    recordToScrollTo: string[];
    fieldEdits: any;
    requestFieldEdits: any;
    showMDEActiveAlert: boolean;
    showMDEBridgeAlert: boolean;
    confirmActivateBridge: boolean;
    selectedEnabledPayerRecord: any;
    atleastOneEligibilityAlert: boolean;
    cancelLeave: boolean;
}

export const DEFAULT_STATE: IComponentState = {
    isBusy: true,
    enrollmentAlert: false,
    availablePayers: [],
    additionalPayers: [],
    filteredPayers: [],
    availablePayersFilter: '',
    selectedAvailablePayerIds: [],
    enabledPayers: [],
    maintenanceInfo: undefined,
    searchText: '',
    activeModal: undefined,
    customModalWidth: "",
    hasReleaseHoursError: false,
    recordToScrollTo: [],
    requestFieldEdits: {},
    fieldEdits: {},
    showMDEActiveAlert: false,
    showMDEBridgeAlert: false,
    confirmActivateBridge: false,
    selectedEnabledPayerRecord: undefined,
    atleastOneEligibilityAlert: false,
    cancelLeave: false,
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IEligibilityProfileProps = IMergeCrudComponentProps<IEligibilityProfileState, IEligibilityProfileActionProps, IOwnProps>;

export class EligibilityProfile extends React.Component<IEligibilityProfileProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canView: false,
        canEdit: false,
    };

    constructor(props: IEligibilityProfileProps) {
        super(props);

        this.state = DEFAULT_STATE;

        this.handleSorting = this.handleSorting.bind(this);
        this.onEnabledPayerClick = this.onEnabledPayerClick.bind(this);
        this.onRequestFieldUpdate = this.onRequestFieldUpdate.bind(this)
        this.onEditFieldUpdate = this.onEditFieldUpdate.bind(this)
        this.addAdditionalPayers = this.addAdditionalPayers.bind(this)
    }

    handleSorting() { }

    isMedicarePayer = (recpid: string): boolean => {
        let isMedicare: boolean = false;
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.EligibilityPayerMaintenanceInfo) {

            let profile = this.props.dataStore.crud.data.EligibilityPayerMaintenanceInfo.EligibilityPayerProfiles?.PayerProfile;

            if (profile && isArray(profile)) {

                let profileIndex: number = profile.findIndex(profile => profile['@RTECPID'] === recpid);

                if (profileIndex !== -1) {
                    isMedicare = profile[profileIndex]['@EnrollmentRequired'] === 'Y';
                }
            }
        }
        return isMedicare;
    }

    availablePayers = (): SelectOption[] => {

        let selectedOptions: SelectOption[] = [];

        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.EligibilityPayerMaintenanceInfo) {

            let payers = this.props.dataStore.crud.data.EligibilityPayerMaintenanceInfo.ClientPayers?.Payer;
            
                payers?.forEach(payer => {
                    if(payer['@EligEnabled'] === 'N') {
                        let displayName = `${payer['@DisplayName']}`;
                        let medicareDisplayNameIndex: number = displayName.indexOf('*');
                        let displayNameHasRETCPIDIndex: number = displayName.indexOf('(');

                        if (this.isMedicarePayer(payer['@RTECPID']) && medicareDisplayNameIndex === -1) {
                            displayName = `${payer['@DisplayName']} (${payer['@RTECPID']})*`;
                        } else if (displayNameHasRETCPIDIndex === -1) {
                            displayName = `${payer['@DisplayName']} (${payer['@RTECPID']})`;
                        }

                        let selectedOption: SelectOption = {
                            value: displayName,
                            text: displayName
                        };
                        selectedOptions.push(selectedOption);
                    }
                });
        }
        return selectedOptions.sort((a, b) => a.value.localeCompare(b.value));
    }

    getClientPayer(payerId: string, rtecpId: string): ClientPayersPayer[] {
        let clientPayer: ClientPayersPayer[] = [];
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.EligibilityPayerMaintenanceInfo) {

            const payers: ClientPayersPayer[] = this.props.dataStore.crud.data.EligibilityPayerMaintenanceInfo.ClientPayers?.Payer || [];

            const clientPayerFound = payers.find(payer => payer['@PayerID'] === payerId && payer['@RTECPID'] === rtecpId);
            if(clientPayerFound)
            {
                clientPayer=[clientPayerFound];
            }
        }

        return clientPayer;
    }

    getEnabledPayers(): ClientPayersPayer[] {

        let eligibileClientPayers: ClientPayersPayer[] = [];
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.EligibilityPayerMaintenanceInfo) {

            let payers: ClientPayersPayer[] = this.props.dataStore.crud.data.EligibilityPayerMaintenanceInfo.ClientPayers?.Payer || [];

                eligibileClientPayers = payers.filter(payer => payer['@EligEnabled'] === "Y");

                eligibileClientPayers.forEach(ePayer => {
                    let displayName = `${ePayer['@DisplayName']}`;
                    let medicareDisplayNameIndex: number = displayName.indexOf('*');
                    let displayNameHasRETCPIDIndex: number = displayName.indexOf('(');

                    if (this.isMedicarePayer(ePayer['@RTECPID']) && medicareDisplayNameIndex === -1) {
                        displayName = `${ePayer['@DisplayName']} (${ePayer['@RTECPID']})*`;
                    } else if (displayNameHasRETCPIDIndex === -1) {
                        displayName = `${ePayer['@DisplayName']} (${ePayer['@RTECPID']})`;
                    }

                    if (medicareDisplayNameIndex === -1) {
                        ePayer["@DisplayName"] = displayName;
                    } else if (displayNameHasRETCPIDIndex === -1) {
                        ePayer["@DisplayName"] = displayName;
                    }
                });
        }
        
        return eligibileClientPayers.sort((a, b) => a['@DisplayName'].localeCompare(b['@DisplayName']));
    }

    async componentDidMount() {
        const {
            action
        } = this.props;

        pageLeave();

        this.props.action.configData.getConfigData({
            cookies: [{ name: "DirectEntry" }],
            config: []
        });

        const getCrudData = async () => {
            this.setState({ isBusy: true });

            await action.crud.get({ crudId: CrudTypes.mctiEligibilityPayerProfile }, validationCallback);

            const maintenanceInfo = this.props.dataStore.crud.data?.EligibilityPayerMaintenanceInfo;
            if (maintenanceInfo) {
                this.props.action.ui.getMaintenanceInfo({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: maintenanceInfo
                });


                const ash = new AvailableSelectedHelper();
                // get enabled payers
                let enabledPayersUI: ClientPayersPayer[] = [];
                 
                // get latest available payers (user input filter is one driver for this)
                enabledPayersUI = this.getEnabledPayers();

                // get additional payers
                const additionalPayers: EligibilityPayersPayer[] = ash.getAdditionalPayers(maintenanceInfo, this.props.dataStore.ui.availableSelectedMap.availablePayers);
         

                this.props.action.ui.selectedClientPayersPayer({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: enabledPayersUI
                });

                this.setState({
                    additionalPayers,
                    isBusy: false,
                });
            }
        }
        getCrudData();
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public directEntryFlag(): string {
        let directEntryObj = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "DirectEntry");
        return (directEntryObj && directEntryObj.value ? directEntryObj.value : "");
    }

    // maintains the current available/selected map in state---updated--eigyan

    onEnabledPayerClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, record : any) {        
        // event.id is NOT unique, PayerID + RTECPID is
        const selectedEnabledPayer = {
            '@RTECPID': record['@RTECPID'],
            '@PayerID': record['@PayerID'],
            '@Name': record['@Name'],
            '@DisplayName': record['@DisplayName'],
            '@ID': record['@ID']
        }

        let availableSelectedMapOld = this.props.dataStore.ui.availableSelectedMap;
        availableSelectedMapOld.enabledPayers.selectedIds = [selectedEnabledPayer];

        this.props.action.ui.availableSelectedMaps({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                selectedMap: availableSelectedMapOld
            }
        });
    };
    
    //TODO I don't think we want a scroll handler, if we hightlight it it's on the user to find it
    handleScroll = (e: any) => {
        const recordToScrollTo = this.props.dataStore.ui.availableSelectedMap.enabledPayers.selectedIds[0];
        
        if(recordToScrollTo){
            const id = recordToScrollTo['@PayerID'].toString() + recordToScrollTo['@RTECPID'];
            document.getElementById(id)?.scrollIntoView();
        }
    }

    // Persistence Helper Methods - Start
    clientPayersInBoth = (oPayers: ClientPayersPayer[], dPayers: ClientPayersPayer[], compareFunction: any) => {
        return dPayers.filter((dPayer: ClientPayersPayer) =>
            oPayers.some((oPayer: ClientPayersPayer) => compareFunction(oPayer, dPayer)));
    }

    isRequestFieldModified(oPayer: any, dPayer: any) {
        const rtecpId = dPayer["@RTECPID"];
        const payerId = dPayer["@PayerID"];
        const key = `${payerId}_${rtecpId}`;
        const hasRequestFieldEdits = has(this.state.requestFieldEdits, [key]);
        return hasRequestFieldEdits
    }

    isEditFieldModified(oPayer: any, dPayer: any) {
        const rtecpId = dPayer["@RTECPID"];
        const payerId = dPayer["@PayerID"];
        const key = `${payerId}_${rtecpId}`;
        const hasRequestFieldEdits = has(this.state.fieldEdits, [key]);
        return hasRequestFieldEdits
    }

    editsInBoth = (oEdits: any, dEdits: any, compareFunction: any) => {
        return dEdits.filter((dEdit: any) =>
            oEdits.some((oEdit: any) => compareFunction(oEdit, dEdit)));
    }

    isModifiedClientPayer = (oPayer: ClientPayersPayer, dPayer: ClientPayersPayer) => {
        return dPayer.added === true || (oPayer["@ID"] === dPayer["@ID"] && oPayer["@RTECPID"] === dPayer["@RTECPID"] &&
            (oPayer['@Bridge'] !== dPayer['@Bridge'] || oPayer['@Batch'] !== dPayer['@Batch'] || oPayer['@CFI'] !== dPayer['@CFI'] ||
            oPayer['@Manual'] !== dPayer['@Manual'] || oPayer['@Direct'] !== dPayer['@Direct'] || oPayer['@Active'] !== dPayer['@Active'] || 
                this.isRequestFieldModified(oPayer, dPayer) || this.isEditFieldModified(oPayer, dPayer)));
    }

    getModifiedClientPayers = (oPayers: ClientPayersPayer[], dPayers: ClientPayersPayer[]): ClientPayersPayer[] => {
        let modifiedClientPayers: ClientPayersPayer[];
        if (oPayers.length > 0)
            modifiedClientPayers = this.clientPayersInBoth(oPayers, dPayers, this.isModifiedClientPayer);
        else
            modifiedClientPayers = dPayers;
        modifiedClientPayers = modifiedClientPayers.filter(mcp => mcp.added !== false);
        return modifiedClientPayers;
    }
  
    // Persistence Helper Methods - End
    hasInvalidPayers(payers: MCClientPayerInfo[]): boolean {

        let isInvalid: boolean = false;

        if (payers) {

            let Payers2Validate = forceToArray(payers).filter(payer => payer['@Delete'] !== "true");

            if (Payers2Validate) {

                let inValidPayers = Payers2Validate.filter(payer => payer['@Bridge'] === 'N' && payer['@Batch'] === 'N' &&
                    payer['@CFI'] === 'N' && payer['@Manual'] === 'N');


                isInvalid = inValidPayers && inValidPayers.length > 0 ? true : false;

                this.setState({ atleastOneEligibilityAlert: isInvalid });
            }

        }

        return isInvalid;
    }
    

    onClickOK(e: React.ChangeEvent<HTMLButtonElement>) {
        const {
            requestFieldEdits,
            fieldEdits,
        } = this.state;
        
        const hasReleaseHoursError = this.props.dataStore.ui.releaseHoursError;

        const {
            originalData,
            data
        } = this.props.dataStore.crud;

        if (!hasReleaseHoursError) {
            console.log('onClickOK() -> start!', this.props.dataStore.crud.data?.EligibilityPayerMaintenanceInfo);
            console.log('requestFieldEdits', requestFieldEdits)
        }

        let updateEligibilityPayers: MCSEligibilityPayerInfo = {
            EligibilityPayerMaintenanceInfo: {
                ClientPayers: { Payer: [] }
            }
        };

        if (this.props.dataStore.crud && originalData && data) {
            // initialize Attributes
            if (originalData.EligibilityPayerMaintenanceInfo['@ReleaseWithPending271'] !== data.EligibilityPayerMaintenanceInfo['@ReleaseWithPending271']) {
                updateEligibilityPayers.EligibilityPayerMaintenanceInfo['@ReleaseWithPending271'] = data.EligibilityPayerMaintenanceInfo['@ReleaseWithPending271']
            }

            if (originalData.EligibilityPayerMaintenanceInfo['@AutoRelease271Hours'] !== data.EligibilityPayerMaintenanceInfo['@AutoRelease271Hours']) {
                updateEligibilityPayers.EligibilityPayerMaintenanceInfo['@AutoRelease271Hours'] = data.EligibilityPayerMaintenanceInfo['@AutoRelease271Hours']
            }

            if (originalData.EligibilityPayerMaintenanceInfo['@AutoReleasePending271'] !== data.EligibilityPayerMaintenanceInfo['@AutoReleasePending271']) {
                updateEligibilityPayers.EligibilityPayerMaintenanceInfo['@AutoReleasePending271'] = data.EligibilityPayerMaintenanceInfo['@AutoReleasePending271']
            }

            if (originalData.EligibilityPayerMaintenanceInfo['@AutoListConversionFlag'] !== data.EligibilityPayerMaintenanceInfo['@AutoListConversionFlag']) {
                updateEligibilityPayers.EligibilityPayerMaintenanceInfo['@AutoListConversionFlag'] = data.EligibilityPayerMaintenanceInfo['@AutoListConversionFlag']
            }

            if (originalData.EligibilityPayerMaintenanceInfo['@EligHospExclude90Day'] !== data.EligibilityPayerMaintenanceInfo['@EligHospExclude90Day']) {
                updateEligibilityPayers.EligibilityPayerMaintenanceInfo['@EligHospExclude90Day'] = data.EligibilityPayerMaintenanceInfo['@EligHospExclude90Day']
            }

            let oCP: ClientPayersPayer[] = originalData.EligibilityPayerMaintenanceInfo.ClientPayers?.Payer || [];
            let dCP: ClientPayersPayer[] = data.EligibilityPayerMaintenanceInfo.ClientPayers?.Payer || [];

            // Handle Modified Enabled Client Payers
            let modifiedPayers = this.getModifiedClientPayers(oCP, dCP);

            if (modifiedPayers?.length > 0) {

                modifiedPayers.forEach(mp => {
                    let modifiedPayer: MCClientPayerInfo = {
                        '@ID': isUndefined(mp['@ID']) ? '#' : mp['@ID'],
                        '@PayerID': mp['@PayerID'],
                        "@Bridge": mp['@Bridge'],
                        "@Batch": mp['@Batch'],
                        "@CFI": mp['@CFI'],
                        "@Manual": mp['@Manual'],
                        "@Direct": mp['@Direct'],
                        "@Active": mp['@Active'],
                        "@RTECPID": mp['@RTECPID'],
                        '@Name': '',
                        '@DisplayName': ''
                    };

                    const payerId = mp["@PayerID"];
                    const rtecpId = mp["@RTECPID"];


                    const key = `${payerId}_${rtecpId}`;
                    modifiedPayer["Fields"] = mp.Fields;


                    const hasEdits = has(fieldEdits, [key]);
                    if (hasEdits) {
                        const edits = Object.values(fieldEdits[key]).map((edit: any) => {
                            if (edit["@ID"] !== undefined) {
                                return {
                                    "@ID": edit["@ID"],
                                    "@EditId": edit["@EditId"],
                                    "@Suprressed": edit["@Suprressed"],
                                    "@TypeBill": edit["@TypeBill"]
                                }
                            }
                            else {
                                return {
                                    "@ID": '#',
                                    "@EditId": edit["@EditId"],
                                    "@Suprressed": edit["@Suprressed"],
                                    "@TypeBill": edit["@TypeBill"]
                                }
                            }
                        });

                        modifiedPayer["Edits"] = {
                            Edit: edits
                        }
                    }

                    updateEligibilityPayers.EligibilityPayerMaintenanceInfo.ClientPayers.Payer.push(modifiedPayer);
                });
            }

            // AutoListConversion Flag
            if (modifiedPayers) {

                let autoListConversionPayer = modifiedPayers.find(mp => this.isMedicarePayer(mp['@RTECPID']) &&
                    mp['@EligEnabled'] === 'Y' && mp['@Active'] === 'Y' && mp['@Bridge'] === 'Y');

                updateEligibilityPayers.EligibilityPayerMaintenanceInfo['@AutoListConversionFlag'] = autoListConversionPayer ? 'Y' : 'N';
            }

            // Handle deleted Enabled Client Payers
            let deletedPayers = dCP.filter(cp => cp['@EligEnabled'] === 'N');

            if (deletedPayers?.length > 0) {
                deletedPayers.forEach(dp => {
                    let deletedPayer: MCClientPayerInfo = {
                        '@ID': dp['@ID'],
                        "@Delete": 'true',
                        '@Name': '',
                        '@DisplayName': ''
                    };

                    if (!isEmpty(deletedPayer['@ID'])) {
                        updateEligibilityPayers.EligibilityPayerMaintenanceInfo.ClientPayers.Payer.push(deletedPayer);
                    }
                });
            }

            let payersToSave = updateEligibilityPayers.EligibilityPayerMaintenanceInfo.ClientPayers.Payer;

            if (!this.hasInvalidPayers(payersToSave)) {
                this.props.action.crud.update({ crudId: CrudTypes.mctiEligibilityPayerProfile, data: updateEligibilityPayers });
                this.props.history.push("/LandingPage");
            }           
        }
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")

        let oCP: ClientPayersPayer[] = this.props.dataStore.crud.originalData?.EligibilityPayerMaintenanceInfo.ClientPayers?.Payer || [];
        let dCP: ClientPayersPayer[] = this.props.dataStore.crud.data?.EligibilityPayerMaintenanceInfo.ClientPayers?.Payer || [];

        let hasModified: boolean = oCP && dCP && oCP.length !== dCP.length ? true : false;

        if (!hasModified) {
            let modifiedPayers = this.getModifiedClientPayers(oCP, dCP);
            hasModified = modifiedPayers && modifiedPayers.length > 0 ? true : false;
        }

        if (userModified === 'true' || hasModified) {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    addAdditionalPayers(addedPayers: any) {

        let showEnrollmentAlert: boolean = false;

        if (isArray(addedPayers)) {
            addedPayers.forEach(sPayer => {
                if (this.isEnrollmentRequired(sPayer['@RTECPID'])) {
                    showEnrollmentAlert = true;
                }
            });
        }

        this.props.action.ui.addPayers({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                selectedPayers: addedPayers,
            }
        });

        this.setState({ activeModal: undefined, enrollmentAlert: showEnrollmentAlert })
    }

    onClickSearchForPayers() {
        const {
            searchText
        } = this.state;


        let filteredPayers = this.props.dataStore.crud.data?.EligibilityPayerMaintenanceInfo.EligibilityPayers?.Payer;
        const clientPayers = this.props.dataStore.crud.data?.EligibilityPayerMaintenanceInfo.ClientPayers?.Payer;

        if (clientPayers && filteredPayers) {
            // remove it if it's in the client node list already
            clientPayers.forEach(cPayer => {
                filteredPayers = filteredPayers?.filter(fPayer => (cPayer["@RTECPID"] !== fPayer["@RTECPID"] ||
                    cPayer["@PayerID"] !== fPayer["@ID"]));
            });
        }

        // Filter payers according to search term
        if (!isEmpty(searchText)) {

            if (filteredPayers && isArray(filteredPayers)) {
                filteredPayers = filteredPayers.filter(payer => {
                    const result = (payer["@RTECPID"]?.includes(searchText.toUpperCase())) ||
                        (payer["@DisplayName"].includes(searchText.toUpperCase()));
                    return result;
                });
            }

            this.setState({
                activeModal: <AdditionalPayers
                    closeModal={() => this.setState({ activeModal: undefined })}
                    data={filteredPayers}
                    searchText={searchText}
                    onOK={(addedPayers: any) => this.addAdditionalPayers(addedPayers)}
                    maintenanceInfo={this.props.dataStore.crud.data?.EligibilityPayerMaintenanceInfo}
                    //@ts-ignore
                    sendClientPayers={this.showAlert.bind(this)}
                />
            });
        } else {
            this.setState({
                activeModal: <AdditionalPayers
                    closeModal={() => this.setState({ activeModal: undefined })}
                    data={filteredPayers}
                    searchText={''}
                    onOK={this.addAdditionalPayers}
                    maintenanceInfo={this.props.dataStore.crud.data?.EligibilityPayerMaintenanceInfo}
                />
            });
        }
    }

    showAlert(items: any) {
        //@ts-ignore
        const isMedicare = items.find(element => element.enrollmentNotificaionFlag == true)
        if (isMedicare !== undefined) {
            //@ts-ignore
            this.handleApplyModal()
        }
    }

    selectedPayerInfo = (selectedPayers: string[]): EligibilityPayersPayer[] => {

        let selectedEligibilityPayers: EligibilityPayersPayer[] = [];

        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.EligibilityPayerMaintenanceInfo) {

            let payers: EligibilityPayersPayer[] = this.props.dataStore.crud.data.EligibilityPayerMaintenanceInfo.EligibilityPayers?.Payer || [];

            if (selectedPayers && isArray(selectedPayers)) {
                selectedPayers.forEach(spayer => {
                    let payerName: string = "";
                    let payerRtecpId: string = "";
                    let rtecpIDOpIndex: number = spayer.indexOf('(');
                    let rtecpIdCpIndex: number = spayer.indexOf(')');
                    if (rtecpIDOpIndex !== -1 && rtecpIdCpIndex !== -1) {
                        payerName = spayer.substring(0, rtecpIDOpIndex).trim();
                        payerRtecpId = spayer.substring(rtecpIDOpIndex + 1, rtecpIdCpIndex);
                    }
                        let selectedPayer = payers.find(ePayer => ePayer['@Name'] === payerName && ePayer['@RTECPID'] === payerRtecpId);

                        if (selectedPayer) {
                            let displayName = `${selectedPayer['@DisplayName']}`;

                            let medicareDisplayNameIndex: number = displayName.indexOf('*');
                            let displayNameHasRETCPIDIndex: number = displayName.indexOf('(');

                            if (this.isMedicarePayer(selectedPayer['@RTECPID'] as string) && medicareDisplayNameIndex === -1) {
                                displayName = `${selectedPayer['@DisplayName']} (${selectedPayer['@RTECPID']})*`;
                            } else if (displayNameHasRETCPIDIndex === -1) {
                                displayName = `${selectedPayer['@DisplayName']} (${selectedPayer['@RTECPID']})`;
                            }

                            let selectedPayerInfo: EligibilityPayersPayer = {
                                '@ID': selectedPayer['@ID'],
                                '@RTECPID': selectedPayer['@RTECPID'],
                                '@Name': displayName,
                                '@DisplayName': displayName
                            };
                            selectedEligibilityPayers.push(selectedPayerInfo);
                        }
                });
            }
        }

        return selectedEligibilityPayers;

    }

    selectedPayers = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (!e.target.options || e.target.options.length === 0) return [];
        let result = [];

        for (let i = 0; i < e.target.options.length; i++) {
            if (e.target.options[i].selected) {
                result.push(e.target.options[i].text);
            }
        }

        return this.selectedPayerInfo(result);
    }

    onSelectAvailablePayer(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectedPayers({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                selectedPayers: this.selectedPayers(e),
            }
        });
    }

    // filter the dataset for the listbox
    filterAvailablePayers(filterText: string) {
        this.props.action.ui.filterPayers({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: filterText,
            }
        });
    }

    isEnrollmentRequired(rtecpId: string): boolean {
        let enrollmentRequired: boolean = false;

        if (!isEmpty(rtecpId)) {
            if (this.props.dataStore.crud.originalData) {
                let profile = this.props.dataStore.crud.originalData.EligibilityPayerMaintenanceInfo.EligibilityPayerProfiles?.PayerProfile;

                if (profile && isArray(profile)) {

                    let profileIndex: number = profile.findIndex(profile => profile['@RTECPID'] === rtecpId);

                    if (profileIndex !== -1) {
                        enrollmentRequired = profile[profileIndex]['@EnrollmentRequired'] === 'Y';
                    }
                }
            }
        }
        return enrollmentRequired;
    }
    
    onAddEnabledPayers(e: React.ChangeEvent<HTMLButtonElement>): void {
        handleChange();

        if (this.props.dataStore.ui.selectedPayers.selectedPayers) {
            let selectedPayerNames: EligibilityPayersPayer[] = [];

            let showEnrollmentAlert: boolean = false;

            if (isArray(this.props.dataStore.ui.selectedPayers.selectedPayers)) {
                this.props.dataStore.ui.selectedPayers.selectedPayers.forEach(sPayer => {
                    selectedPayerNames.push(sPayer);
                    if (this.isEnrollmentRequired(sPayer['@RTECPID'])) {
                        showEnrollmentAlert = true;
                    }
                });
            }

            this.props.action.ui.addPayers({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    selectedPayers: selectedPayerNames,
                }
            });

            this.setState({ enrollmentAlert: showEnrollmentAlert });
        }
    }

    onRemoveEnabledPayers(e: React.ChangeEvent<HTMLButtonElement>): void {
        handleChange();

        let selectedClientPayer = this.props.dataStore.ui.availableSelectedMap.enabledPayers.selectedIds[0];

        if (selectedClientPayer) {
            if (selectedClientPayer["@ID"] !== "#") {
                this.props.action.ui.removePayers({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: selectedClientPayer
                });
            }
        }
    }

    handleApplyModal() {
        this.setState({
            enrollmentAlert: !this.state.enrollmentAlert
        });
    }
    
    hasAutoListConversionRun() : boolean {
        return this.props.dataStore.ui.maintenanceInfoDetail["@AutoListConversionFlag"] != '0'
    }

    handleMDEActiveAlert(show: boolean) {
        if(!this.hasAutoListConversionRun())
            this.setState({ showMDEActiveAlert: show });
    }

    handleAtleastOneEligibilityAlert(show: boolean) {
        this.setState({ atleastOneEligibilityAlert: !show });
    }
    
    handleMDEActiveConfirmation(confirm: boolean) {
        this.setState({ confirmActivateBridge: confirm });

        if (confirm && this.props.dataStore.ui.lastUpdatedCheckboxParent)
            this.props.action.ui.toggleMDEBatchFlag({
                masterCrud: this.props.dataStore.crud.data,
                uiData: confirm
            });
    }

    handleMDEBridgeAlert(show: boolean) {
        if(!this.hasAutoListConversionRun())
            this.setState({ showMDEBridgeAlert: show });
    }

    toggleEnum(enumVal: IsChecked): IsChecked {
        return enumVal === IsChecked.Y ? IsChecked.N : IsChecked.Y
    }

    onChangeHoldClaims(): void {
        handleChange();
        this.props.action.ui.changeHoldClaimsCheckbox({
            masterCrud: this.props.dataStore.crud.data
        });
    }

    onChangeReleaseClaims(): void {
        handleChange();
        this.props.action.ui.changeAvailableForReleaseCheckbox({
            masterCrud: this.props.dataStore.crud.data
        });
    }

    onChangeExclude90Day(): void {
        handleChange();
        this.props.action.ui.changeExclude90DayCheckbox({
            masterCrud: this.props.dataStore.crud.data
        });
    }

    updateEnabledPayerRecord(record: IRecordProp): void {
        const {
            recordToScrollTo,
            confirmActivateBridge,
        } = this.state;

        let recordToUpdate: ClientPayersPayer | undefined;

        recordToScrollTo.push(record['@PayerID'].toString());

        if (recordToScrollTo.length > 1) {
            recordToScrollTo.shift();
        }

        if (this.props.dataStore.ui.maintenanceInfoDetail) {
            recordToUpdate = this.props.dataStore.ui.maintenanceInfoDetail.ClientPayers?.Payer.find((e: any) => e["@PayerID"] === record["@PayerID"] && e["@RTECPID"] === record["@RTECPID"])
        }

        if (recordToUpdate) {

            let mdeBridgePreviousValue: boolean = recordToUpdate["@Bridge"] === "Y" ? true : false;

            if (recordToUpdate["@Active"].toString() !== record['@Active']) {
                recordToUpdate["@Active"] = this.toggleEnum(recordToUpdate["@Active"]);
            }

            if (recordToUpdate["@Bridge"].toString() !== record['@Bridge']) {
                recordToUpdate["@Bridge"] = this.toggleEnum(recordToUpdate["@Bridge"]);
            }

            if (this.isMedicarePayer(recordToUpdate?.['@RTECPID'])) {
                if (recordToUpdate["@Active"] === 'Y') {
                    if (recordToUpdate["@Bridge"] === 'Y') {
                        if (!confirmActivateBridge) {
                            this.handleMDEBridgeAlert(!confirmActivateBridge);
                        }
                    } else {
                        if (!mdeBridgePreviousValue)
                            this.handleMDEActiveAlert(true);
                    }
                }
            }

            if (recordToUpdate["@Batch"].toString() !== record['@Batch']) {
                this.handleMDEBridgeAlert(false);
                recordToUpdate["@Batch"] = this.toggleEnum(recordToUpdate["@Batch"]);
            }

            if (this.isMedicarePayer(recordToUpdate?.['@RTECPID'])) {
                if (recordToUpdate["@Active"] === 'Y') {
                    if (recordToUpdate["@Bridge"] === 'Y') {
                        if (confirmActivateBridge) {
                            this.handleMDEBridgeAlert(confirmActivateBridge);
                        }
                    }
                }
            }

            if (recordToUpdate["@CFI"].toString() !== record['@CFI']) {
                this.handleMDEBridgeAlert(false);
                recordToUpdate["@CFI"] = this.toggleEnum(recordToUpdate["@CFI"]);
            }

            if (recordToUpdate["@Manual"].toString() !== record['@Manual']) {
                this.handleMDEBridgeAlert(false);
                recordToUpdate["@Manual"] = this.toggleEnum(recordToUpdate["@Manual"]);
            }

            if (recordToUpdate["@Direct"].toString() !== record['@Direct']) {
                this.handleMDEBridgeAlert(false);
                recordToUpdate["@Direct"] = this.toggleEnum(recordToUpdate["@Direct"]);
            }
        }

        this.props.action.ui.checkboxInSelectedRow({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                updatedRecord: record,
            }
        });

        const selectedParent = {
            '@RTECPID': record['@RTECPID'],
            '@PayerID': record['@PayerID'],
            '@DisplayName': record['@DisplayName'],
        }

        this.props.action.ui.setCheckboxClickParent({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                parent: selectedParent,
            }
        });
    }

    onChangeHours(hourValue: string): void {
        this.props.action.ui.checkReleaseHours(
            {
               masterCrud: this.props.dataStore.crud.data,
               uiData:{
                   value: hourValue
               } 
            });
    }

    onRequestFieldUpdate(requestFieldEdits: any, payerId: string, rtecpId: string): void {

        const ogPayerData = this.props.dataStore.ui.maintenanceInfoDetail.ClientPayers?.Payer.find((payer) => payer["@PayerID"] === payerId && payer["@RTECPID"] === rtecpId);
        const key = `${payerId}_${rtecpId}`;
        let ogRequestFields = get(ogPayerData, ['Fields', 'Field'], []);
        ogRequestFields = forceToArray(ogRequestFields)
        const requestFields = Object.values(requestFieldEdits[key]).map((field: any) => {
            const fieldIsPresent = ogRequestFields.filter((o: any) => o["@Type"] === field["@Type"]);
            if (fieldIsPresent && fieldIsPresent.length > 0) {
                const ogField = fieldIsPresent[0]
                if (field["@Required"] === "N" && field["@Send"] === "N" && field["@DoNotSend"] === "N") {
                    return {
                        "@Delete": "true",
                        "@ID": ogField["@ID"]
                    }
                }
                if (fieldIsPresent.length > 0) {
                    fieldIsPresent.map((item) => {
                        return {
                            "@Delete": "true",
                            "@ID": item["@ID"]
                        }
                    })
                }

                return {
                    "@Type": field["@Type"],
                    "@Required": field["@Required"],
                    "@Send": field["@Send"],
                    "@ID": "#"
                }
            }

            return {
                "@Type": field["@Type"],
                "@Required": field["@Required"],
                "@Send": field["@Send"],
                "@ID": "#"
            }
        });

        ogRequestFields.map((item: any) => {
            let requestFieldsAlreadyexists = requestFields.find((data) => {
                return data["@Type"] === item["@Type"];
            })
            if (requestFieldsAlreadyexists == undefined)
                requestFields.push(item);
            else if (item["@ID"] !== "#") {
                item["@Delete"] = "true";
                requestFields.push(item);
            }
        })

        this.props.action.ui.requestFieldEdits({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                requestFieldEdit: requestFields,
                payerID: payerId,
                rtecpId: rtecpId,


            }
        });
        this.setState({ requestFieldEdits, activeModal: undefined, customModalWidth: "" })
    }

    onEditFieldUpdate(maintenanceInfo: any, fieldEdits: any, payerId: string | number, rtecpId: string) {
        const ogPayerData = this.props.dataStore.ui.maintenanceInfoDetail.ClientPayers?.Payer.find((payer) => payer["@PayerID"] === payerId && payer["@RTECPID"] === rtecpId);
        const newPayerData = maintenanceInfo.ClientPayers.Payer.find((payer: ClientPayersPayer) => payer["@PayerID"] === payerId && payer["@RTECPID"] === rtecpId);
        if (ogPayerData) {
            ogPayerData.Edits = newPayerData.Edits;

            const dataUpdate = this.props.dataStore.ui.maintenanceInfoDetail.ClientPayers?.Payer;
            const index = this.props.dataStore.ui.maintenanceInfoDetail.ClientPayers?.Payer.findIndex((payer) => payer["@PayerID"] === payerId && payer["@RTECPID"] === rtecpId);
            
            if(index !== undefined){
                dataUpdate?.splice(index, 1, ogPayerData);
            }
            
            maintenanceInfo.ClientPayers.Payer = dataUpdate;
        }

        this.props.action.ui.fieldEdits({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                maintenanceInfo: maintenanceInfo,
                fieldEdit: fieldEdits
            }
        });
        this.setState((prevState, prevProps) => {
            return {
                ...prevState,
                fieldEdits,
                activeModal: undefined,
            }
        });
    }

    render() {
        const {
            isBusy,
            atleastOneEligibilityAlert,
            enrollmentAlert,
            showMDEActiveAlert,
            showMDEBridgeAlert,
        } = this.state;

        let enabledPayersUI: ClientPayersPayer[] = [];
        if (this.props.dataStore.crud.data?.EligibilityPayerMaintenanceInfo) {
            enabledPayersUI = this.getEnabledPayers();
        }

        const instruction = <React.Fragment>Use this page to enable payers for use with eligibility checks.</React.Fragment>;

        var buttons = <OKCancelButtons
            disableOK={!this.props.canView || !this.props.canEdit || this.props.dataStore.ui.releaseHoursError}
            onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickOK(e)}
            onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;

        let holdClaimsWithPendingRequests = false;
        let automaticallyReleaseHeldClaims = false;
        let autoReleaseAfterHoursValue = this.props.dataStore.crud.data?.EligibilityPayerMaintenanceInfo?.["@AutoRelease271Hours"];
        let autoReleaseAfterHoursDisabled = false;
        let automaticallyReleaseHeldClaimsDisabled = false;
        let availablePayers = this.availablePayers();
        let exclude90dayChecked = false;
        if(this.props.dataStore.ui.filterValue){
            availablePayers = availablePayers.filter(payer => payer.text.startsWith(this.props.dataStore.ui.filterValue.toUpperCase()));
        }

        if (this.props.dataStore.crud.data?.EligibilityPayerMaintenanceInfo) {
            if (this.props.dataStore.crud.data?.EligibilityPayerMaintenanceInfo["@AutoReleasePending271"] === 'Y')
                automaticallyReleaseHeldClaims = true;
            else {
                automaticallyReleaseHeldClaims = false;
                autoReleaseAfterHoursDisabled = true;
            }

            if (this.props.dataStore.crud.data?.EligibilityPayerMaintenanceInfo["@ReleaseWithPending271"] === 'Y') {
                holdClaimsWithPendingRequests = false;
                automaticallyReleaseHeldClaimsDisabled = true;
                autoReleaseAfterHoursDisabled = true;
            }
            else {
                holdClaimsWithPendingRequests = true;
                automaticallyReleaseHeldClaimsDisabled = false;
                autoReleaseAfterHoursDisabled = !automaticallyReleaseHeldClaims;
            }

            exclude90dayChecked = (this.props.dataStore.crud.data?.EligibilityPayerMaintenanceInfo["@EligHospExclude90Day"] === 'Y');
        }
        let directEntry = this.directEntryFlag();

        return (
            <React.Fragment>
                <DialogWrapper title="Eligibility Payer Profile Maintenance" instruction={instruction}
                    helpUrl='/Support/Help/Help_Maint_EligibilityProfile.htm' buttons={buttons} isBusy={isBusy}>
                    {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })}  />}
                    <ContentWrapper>
                        <ContentRowWrapper>
                            <ContentColumnWrapper>
                                <HoldClaimsCheckBoxWrapper>
                                    <CheckBoxComponent
                                        id='eligProfile_holdClaims'
                                        label='Hold Claims with pending requests'
                                        width='380px'
                                        checked={holdClaimsWithPendingRequests}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHoldClaims()}
                                    />
                                </HoldClaimsCheckBoxWrapper>
                                <DialogFieldset>
                                    <DialogLegend>Available Payers</DialogLegend>
                                    <Input
                                        type='textbox'
                                        key='available-payer-filter'
                                        size='small'
                                        maxLength={30}
                                        onChange={x => this.filterAvailablePayers(x.target.value)}
                                        style={{ marginBottom: 9, width: '370px' }}
                                    />
                                    <SelectComponent
                                        title='availablePayers'
                                        size={10}
                                        width='370px'
                                        height='350px'
                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectAvailablePayer(e)}
                                        optionFields={{
                                            value: "value",
                                            text: "text"
                                        }}
                                        records={availablePayers}
                                        multiple={'true'}
                                    >
                                    </SelectComponent>
                                </DialogFieldset>
                            </ContentColumnWrapper>
                            <ArrowButtonsAlignmentAdjustment>
                                <ArrowButtons
                                    paddingTop='175px'
                                    width='1px'
                                    margin='0 0 0 0'
                                    toolTipLeft=' Remove Payer(s) from Enabled Payers'
                                    toolTipRight=' Add Payer(s) to Enabled Payers'
                                    onRightBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => { this.onAddEnabledPayers(e) }}
                                    onLeftBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => { this.onRemoveEnabledPayers(e) }}
                                    disabled={!this.props.canEdit}
                                />
                            </ArrowButtonsAlignmentAdjustment>
                            <ContentColumnWrapper
                                style={{paddingRight: '10px'}}>
                                <ContentRowWrapper>
                                    <HoursCheckBoxWrapper>
                                        <CheckBoxComponent
                                            id='eligProfile_releaseAvalable'
                                            label='Available for release in'
                                            width='180'
                                            checked={automaticallyReleaseHeldClaims}
                                            disabled={automaticallyReleaseHeldClaimsDisabled}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeReleaseClaims()}
                                        />
                                    </HoursCheckBoxWrapper>
                                    <HourInputWrapper>
                                        <Input
                                            domID='eligProfile_Hours'
                                            size='small'
                                            maxLength={3}
                                            hasError={this.props.dataStore.ui.releaseHoursError}
                                            disabled={autoReleaseAfterHoursDisabled}
                                            initialValue={autoReleaseAfterHoursValue}
                                            style={{ marginBottom: '0px' }}
                                            onChange={(e: React.ChangeEvent<HTMLButtonElement | HTMLInputElement>) => this.onChangeHours(e.target.value)}
                                        />
                                    </HourInputWrapper>
                                    <HoursLabelWrapper>
                                        Hours
                                        {
                                            this.props.dataStore.ui.releaseHoursError ? <React.Fragment><ErrTxt> Only digits are allowed. </ErrTxt></React.Fragment> : <React.Fragment>&nbsp;</React.Fragment>
                                        }
                                    </HoursLabelWrapper>
                                    <Exclude90CheckBoxWrapper>
                                        <CheckBoxComponent
                                                id='eligProfile_exclude90'
                                                label='ELGHOSP Exclude 90 day'
                                                width='225'
                                                checked={exclude90dayChecked}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeExclude90Day()}
                                            />
                                    </Exclude90CheckBoxWrapper>
                                </ContentRowWrapper>
                                <DialogFieldset>
                                    <DialogLegend>Enabled Payers</DialogLegend>
                                    {
                                        enabledPayersUI && this.props.dataStore.crud.data?.EligibilityPayerMaintenanceInfo &&
                                        <EnabledPayersGrid
                                            maintenanceInfo={this.props.dataStore.crud.data.EligibilityPayerMaintenanceInfo}
                                            enabledPayers={enabledPayersUI}
                                            availableSelectedMap={this.props.dataStore.ui.availableSelectedMap.enabledPayers}
                                            onRowClick={this.onEnabledPayerClick}
                                            directEntry={directEntry}
                                            canView={this.props.canView}
                                            canEdit={this.props.canEdit}
                                            onFieldClick={(maintenanceInfo: any, payerId: any, rtecpId: string) => {
                                                this.setState({
                                                    customModalWidth: "1000px",
                                                    activeModal:
                                                        <UpdateFieldModal
                                                            id={`${payerId}_${rtecpId}`}
                                                            maintenanceInfo={maintenanceInfo}
                                                            payerId={payerId}
                                                            rtecpId={rtecpId}
                                                            onOK={this.onRequestFieldUpdate}
                                                            closeModal={() => this.setState({ activeModal: undefined, customModalWidth: "" })}
                                                            fieldEdits={this.state.requestFieldEdits}
                                                        />
                                                });
                                            }}
                                            onEditClick={(maintenanceInfo: any, payerId: number, rtecpId: string) => {
                                                this.setState({
                                                    activeModal:
                                                        <UpdateSuppressionModal
                                                            id={`${payerId}_${rtecpId}`}
                                                            maintenanceInfo={this.props.dataStore.ui.maintenanceInfoDetail}
                                                            payerId={String(payerId)}
                                                            rtecpId={rtecpId}
                                                            onOK={this.onEditFieldUpdate}
                                                            fieldEdits={this.state.fieldEdits}
                                                            closeModal={() => this.setState({ activeModal: undefined })}
                                                        />
                                                });
                                            }}
                                            onCheckboxClick={(record: IRecordProp) => { this.updateEnabledPayerRecord(record); }}
                                        />
                                    }
                                </DialogFieldset>
                            </ContentColumnWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <DialogFieldset style={{ width: '100%' }}>
                                <DialogLegend>Search for Additional Payers</DialogLegend>
                                {/* Have to add a div wrapper around the UICL input to force inline style displays. */}
                                <div style={{display: 'inline-block'}}>
                                    <Input
                                        domID='eligProfile_AddPayerSearch'
                                        size='small'
                                        style={{ width: 403 }}
                                        onChange={x => {
                                            this.setState({
                                                searchText: x.target.value
                                            })
                                        }}
                                    />
                                </div>
                                {/* spacer */}
                                <div style={{ display: 'inline-block', width: 4 }}></div>
                                <div style={{ display: 'inline-block', position: 'relative', top: -1, verticalAlign: 'middle' }}>
                                    <ButtonToolTip key={"payer-search-button-ButtonToolTip"} title={'SearchAllPayers'}>
                                    <Button
                                        domID="payer-search-button"
                                        name={"Search"}
                                        buttonType="standard"
                                        size="small"
                                        type="button"
                                        onClick={() => { this.onClickSearchForPayers(); }}
                                        />
                                    </ButtonToolTip>
                                </div>
                            </DialogFieldset>
                        </ContentRowWrapper>
                    </ContentWrapper>
                </DialogWrapper>
                <ModalComponent
                    component={this.state.activeModal}
                    isOpen={this.state.activeModal !== undefined}
                    // Need to set the modal toggle since closeOnClickOut is now default true.
                    onModalToggle={() => this.setState({activeModal: undefined})}
                    showDefaultClose={false}
                    width={this.state.customModalWidth}
                />
                <ModalConfirmation
                    title={"Payers's enrollment"}
                    isOpen={enrollmentAlert}
                    alertMode={true}
                    message={''}
                    formattedMessage={"One or more payers enabled requires enrollment - verify that enrollment is complete."}
                    okText={"Yes"}
                    cancelText={"No"}
                    showDefaultClose={false}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.handleApplyModal() }}
                />
                <ModalConfirmation
                    title={"Payers's enrollment"}
                    isOpen={atleastOneEligibilityAlert}
                    alertMode={true}
                    message={''}
                    formattedMessage={"All Enabled Payers must have at least one eligibility option selected."}
                    okText={"Yes"}
                    cancelText={"No"}
                    showDefaultClose={false}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.handleAtleastOneEligibilityAlert(atleastOneEligibilityAlert) }}
                />
                <ModalConfirmation
                    isOpen={showMDEActiveAlert}
                    formattedMessage={(
                        <div>
                            <p>You are activating a Medicare Direct Entry line of business to use the Assurance Eligibility Edits.</p>
                            <p>Do you want eligibility checks to automatically run during claim bridging?</p>
                            <p>If so, click the Yes button to enable or preserve the Bridge checkbox in this profile and to have the system update your related existing auto modules and auto lists as needed.</p>
                        </div>)
                    }
                    okText={"Yes"}
                    cancelText={"No"}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.handleMDEActiveAlert(!showMDEActiveAlert)}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.handleMDEActiveConfirmation(true)}
                    onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.handleMDEActiveConfirmation(false)}
                />
                <ModalConfirmation
                    alertMode={true}
                    isOpen={showMDEBridgeAlert}
                    formattedMessage={(
                        <div>
                            <p>You are activating the setting to automatically enable Eligibility Edits during claim bridging for your Medicare Direct Entry claims.</p>
                            <p>Click the OK button to continue and have the system update your related existing auto modules and auto lists as needed.</p>
                        </div>)
                    }
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.handleMDEBridgeAlert(!showMDEBridgeAlert) }}
                />
            </React.Fragment>
        )
    }
}

var connectedHoc = connect<IEligibilityProfileState, IEligibilityProfileActionProps, IOwnProps, IComponentProps, ApplicationState>(
    createCrudMapStateToProps('eligibilityProfile'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(EligibilityProfile);

export default withRouter(connectedHoc);


