import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '@commonResources/typography';
import { SelectGroupComponent } from '@common/SelectGroupComponent';
import { Input, Modal, Button, Section } from '@optum-uicl/ui-core/dist';
import { CenteredContent, DialogWrapper } from '@common/DialogWrapper';
import { ModalConfirmation } from '../ModalConfirmation';

const StyledModal = styled(Modal)`
    min-block-size: -webkit-fill-available;
    height: fit-content;
`

const ModalWrapperSec = styled.div`
{data()=> }

`;
const ModalFilterListBody = styled.div`

    .header {
        justify-content: center;
        margin-bottom: 30px;
    };
    justify-content: center;
    #filterlist-filtername{
        width: 360px;
    }

    .xqjtA {
        font-family: optum-sans-regular;
        font-size: 20px;
        font-weight: 400;
        color: rgb(55, 71, 79);
    }

`;


const ModalFilterListInnerContent = styled.div`
    display: inline-block;
    margin: 0 auto;

`;


const ModalFilterListDivide = styled.div`
    ${Typography.ARMFontFamily};
    ${Typography.normal};
    ${Typography.default};
    margin: 20px;
    text-align: center;

`;

const ModalFilterListMessageWrapper = styled.div`
    ${Typography.ARMFontFamily};
    ${Typography.normal};
    ${Typography.default};
    padding-bottom: 20px;
    text-align: left;
    width: 360px;
    .bBTdyP {
        text-transform: uppercase !important;
        margin-bottom: 2px
        font-size: 11px !important;
        line-height: 1.4em !important;
        letter-spacing: 0.5px !important;
      }
    
`;

const ButtonRow = styled.div`
    display: flex;
    flex: 1 1 auto;
    gap: 10px;
    flex-direction: row;
    justify-content: center;
    padding-top: 6px;
    margin-bottom: 15px;
    
    button {
        color: #002677;
        background-color: #FFFFFF;
        border-color: #002677;
        width: 90px
    }
    button:hover{
        color: #001D5B;
        background-color: #F4F4F4;
        border-color: #001D5B;
    }

    button:active, button:focus{
        color: #00184D;
        background-color: #E5E5E6;
        border-color: #00184D;
    }

    

`;
ButtonRow.displayName = "ModalFilterListButtonRow";




export const ModalFilterListSelectList = styled.div`
    padding: 5px;
`;

export interface IModalFilterListOption {
    'value': string;
    'label': string;
    'fav'?: boolean;
};

export interface IModalFilterListGroup {
    'label': string;
    'items': IModalFilterListOption[];
};

export interface IModalFilterListFilters {
    filterList: IModalFilterListGroup[];
}


export interface IModalFilterListSelection {
    name: string;
    id?: number;
}


export interface IModalFilterListProps {
    isOpen: boolean;
    showDefaultClose: boolean;
    onModalToggle: React.MouseEventHandler<HTMLElement>;
    title?: string;
    filters: IModalFilterListFilters;
    onConfirm?: any;
    onDeny?: React.MouseEventHandler<HTMLButtonElement>;
    selectedValue?: string;
};

interface IModalFilterListComponentState {
    visible: boolean;
    filterName: string;
    filterNameValue: string;
    filterId: string;
    confirmMsg: string;
}

const modalfilterList_defaultState: IModalFilterListComponentState = {
    visible: true,
    filterName: '',
    filterId: '',
    filterNameValue: '',
    confirmMsg: '',
};

export class ModalFilterList extends React.Component<IModalFilterListProps, IModalFilterListComponentState> {

    static defaultProps: IModalFilterListProps = {
        isOpen: true,
        showDefaultClose: false,
        filters: { filterList: [] },
        onModalToggle: (e: React.MouseEvent<HTMLElement>) => false,
        title: 'Save Filter',
        selectedValue: '',
    };


    constructor(props: IModalFilterListProps) {
        super(props);

        this.state = modalfilterList_defaultState;
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.filters.filterList !== prevProps.filters.filterList) {
            for (var i = 0; i < this.props.filters.filterList.length; i++) {
                const found = this.props.filters.filterList[i]?.items.find(element => element.value === this.props.selectedValue);
                if (found) {
                    this.setState({ filterName: found.label, filterId: found.value });
                }
            }
        }
    }

    closeModal(e: React.SyntheticEvent) {
        this.setState({
            visible: false,
            filterName: '',
            filterId: '',
            filterNameValue: '',
            confirmMsg: '',
        });


        if (this.props.onModalToggle)
            this.props.onModalToggle(e as React.MouseEvent<HTMLButtonElement>);
    }

    onSelectFilter(e: React.ChangeEvent<HTMLSelectElement>) {
        var selectedFilterID: string = e.target.value;
        var selectedFilterIndex: number = e.target.options.selectedIndex;
        if (e.target.options && e.target.options.length > 0 &&
            e.target.options[e.target.options.selectedIndex] !== null &&
            e.target.options[e.target.options.selectedIndex].text &&
            e.target.options.selectedIndex !== -1) {
            this.setState({ filterName: e.target.options[e.target.options.selectedIndex].text, filterId: selectedFilterID });
        }
    }

    getData(): any {
        if (this.state.filterNameValue.length > 0) {
            if (this.props.filters.filterList && this.props.filters.filterList.length > 0) {
                // does the name exist in the list
                var filterNameValueLower = this.state.filterNameValue.toLowerCase();
                for (var i = 0; i < this.props.filters.filterList.length; i++) {
                    const found = this.props.filters.filterList[i]?.items.find(element => element.label.toLowerCase() === filterNameValueLower);

                    if (found) {
                        this.setState({ confirmMsg: "Do you want to update the existing saved filter named '" + this.state.filterNameValue + "' ?" });
                        return undefined;

                    }
                }
            }

            return { name: this.state.filterNameValue, 'id': '' };
        }
        else if (this.state.filterName.length > 0) {
            return { name: this.state.filterName, 'id': this.state.filterId }
        } else {
            alert("Please type a new name to create a filter or select an existing saved filter to update it.");
        }

        return undefined;
    }

    OnOkClicked(e: React.SyntheticEvent) {

        {
            this.props.onModalToggle(e as React.MouseEvent<HTMLButtonElement>);
            if (this.props.onConfirm) {
                var data = this.getData();
                if (data == undefined)  // issues with their selection
                    return;
                this.props.onConfirm(data);
            }
            this.setState(modalfilterList_defaultState);
        }
    }

    public onClearConfirmMsg(e: any) {
        this.setState({ confirmMsg: '' });
    }

    public onConfirmMsg(e: any) {        
        this.setState({ confirmMsg: '' });

        this.props.onConfirm({ name: this.state.filterNameValue, 'id': '' });
        this.setState(modalfilterList_defaultState);

    }

    public isExecuted: boolean = false;

    render() {
        // var filterRecords = this.clearSelected(this.props.filters.filterList); 

        return (
            <ModalWrapperSec title={this.props.title} >
            <StyledModal domID="filterlist-modal" isOpen={this.props.isOpen} showDefaultClose={this.props.showDefaultClose} onModalToggle={(e: React.SyntheticEvent) => this.closeModal(e)}>
                <ModalFilterListBody>
                    <Section
                        domID="filterlist-title"
                        title={this.props.title ? this.props.title : ModalFilterList.defaultProps.title} />
                    <CenteredContent>
                        <ModalFilterListInnerContent>
                            <ModalFilterListMessageWrapper  >
                                <Input
                                    domID="filterlist-filtername"
                                    label="New Filter Name:"
                                    maxLength={50}
                                    initialValue={''}
                                    value={this.state.filterNameValue}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ filterNameValue: e.target.value })}
                                />
                                <ModalFilterListDivide> or </ModalFilterListDivide>
                                <ModalFilterListSelectList>
                                    <SelectGroupComponent
                                        size={20}
                                        width='350px'
                                        onSelect={(e: React.SyntheticEvent) => this.onSelectFilter(e as React.ChangeEvent<HTMLSelectElement>)}
                                        fontSize={Typography.mediumLarge}
                                        optionGroups={{
                                            label: "value",
                                            items: [{
                                                value: "value",
                                                text: "text",
                                            }]
                                        }}
                                        records={this.props.filters.filterList}
                                        selectedValue={this.state.filterId}
                                    >
                                    </SelectGroupComponent>
                                </ModalFilterListSelectList>
                            </ModalFilterListMessageWrapper>
                            <ButtonRow >
                                <Button
                                    name="OK"
                                    buttonType="emphasized"
                                    onClick={(e: React.SyntheticEvent) => this.OnOkClicked(e)}
                                    size="small"
                                    domID="filterlist-confirm-button" />
                                <Button
                                    name="CANCEL"
                                    buttonType="standard"
                                    size="small"
                                    onClick={(e: React.SyntheticEvent) => {
                                        this.props.onModalToggle(e as React.MouseEvent<HTMLButtonElement>);
                                        this.closeModal(e)
                                        if (this.props.onDeny) {
                                            this.props.onDeny(e as React.MouseEvent<HTMLButtonElement>);
                                        }
                                    }
                                    }
                                    domID="filterlist-cancel-button" />
                            </ButtonRow>
                        </ModalFilterListInnerContent>
                    </CenteredContent>
                </ModalFilterListBody>                

                </StyledModal>
                <ModalConfirmation
                    isOpen={!!this.state.confirmMsg && this.state.confirmMsg.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearConfirmMsg(e)}
                    message={this.state.confirmMsg}
                    okText={"Yes"}
                    cancelText={"No"}
                    showDefaultClose={false}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onConfirmMsg(e)}
                />
            </ModalWrapperSec>
        );
    }
}