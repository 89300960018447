import * as React from 'react';
import { RouteComponentProps, Prompt, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindAll, orderBy, without } from 'lodash';
import { Location } from 'history';
import { Button, Tabs, Grid, Warning } from '@optum-uicl/ui-core/dist';
import moment from 'moment';
import styled from 'styled-components';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { OKCancelButtons, DialogWrapper, ContentColumnWrapper, ContentRowWrapper, FlexBoxModifier } from '@common/DialogWrapper';
import { ModalConfirmation, leaveMessage } from '@common/ModalConfirmation';
import CrudTypes from '@commonResources/CrudTypes';
import {Colors} from '@commonResources/colorVariables';
import {
    IMergeCrudComponentProps,
    createCrudMapStateToProps,
    createCrudMapDispatchToProps,
    mergeCrudComponentProps,
    resetCrudComponentState
} from '@scripts/util/CrudComponentHelpers';
import { IFormModalState, defaultFormModalState } from '@store/ui/BaseCrudUI';
import { ApplicationState } from '@store/index';
import {
    IManageClaimAssignmentsErrorRulesUIState,
    IManageClaimAssignmentsErrorRulesActionProps,
    actionCreators,
    validationCallback
} from '@store/ManageClaimAssignmentsErrorRules';
import { getSelectedRule, IMCAERS_FieldValue, IManageClaimAssignmentsErrorRulesData, IMCAERManageRulesTabState, IMCAERSearchSummary, IMCAERSearchSummaryDetail, IMCAERS_DepartmentRuleValue, IMCAERS_Rule, IMCAERS_User, IModifyManageClaimAssignmentsErrorRules, MCASearchRequest, IRuleSummaryBase } from '@store/ui/ManageClaimAssignmentsErrorRulesUI';
import { ArmTabContainer, SizedContainer } from '@common/component-styles';
import ArmRadioButtonGroup from '@common/UICLWrappers/ARMRadioButtonGroup';
import { SelectComponent } from '@common/SelectComponent';
import ARMDateRange from '@common/DateOrTimeRelated/ARMDateRange';
import { ARMNarrowInput } from '@common/UICLWrappers/ARMNarrowInput';
import MultiSelectForSimpleValues from '@common/FilterRelated/MultiSelectForSimpleValues';
import { eRulesAssignmentModifyButtonType, IRulesAssignDepartmentInfo, IRulesAssignUserInfo, RuleAssignmentComponent } from '@common/RuleAssignmentComponent';
import { IARMUserData } from '@common/ARMUserComponent';
import { IARMDeptData } from '@common/ARMDepartmentComponent';
import { handleChange, pageLeave } from '@commonResources/userModified';
import { convertPossibleARMDateStringToDateString } from '@scripts/util/ValidationHelpers';
import { ColumnType, GridProps, RowType } from '@optum-uicl/ui-core/dist/Organisms/Grid/types';

interface IComponentProps {
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IManageClaimAssignmentsErrorRulesProps = IMergeCrudComponentProps<IManageClaimAssignmentsErrorRulesUIState,
    IManageClaimAssignmentsErrorRulesActionProps,
    IOwnProps>;

interface IGridSummary extends IMCAERSearchSummary {
    client1: string;
    client2: string;
    client3: string;
    ruleExistsIcon: React.ElementType;
}
type SummarySortFields = keyof IMCAERSearchSummary | keyof IRuleSummaryBase | 'intTotalErrorCount';

interface IGridDetail extends IMCAERSearchSummaryDetail {
    message: { text: string };
}

interface ITitleGridCellData {
    text: string | undefined,
    title?: string | undefined,
};

interface IGridManageRules {
    id: string,
    assignedTo: ITitleGridCellData,
    assignedToSort: string,
    categoryName: ITitleGridCellData,
    categoryNameSort: string,
    editName: string,
    expires: string,
    expiresSort: number,
    fieldName: string,
    isSelected: boolean,
    otherCriteria: ITitleGridCellData,
    otherCriteriaSort: string,
    payerLob: ITitleGridCellData,
    payerLobSort: string,
};
type ManageRulesSortFields = keyof IGridManageRules;


interface ITabItem {
    label: string;
    domID: string;
}

type DetailSortFields = keyof IMCAERSearchSummaryDetail | 'intTotalErrorCount';

const SearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 900px;
    margin: 0 auto;
    padding: 10px;
    gap: 10px;
    .select-time-period.select-time-period.select-time-period.select-time-period {
        flex: 0 1 0;
        padding: 10px 0;
    }
    .search-select>select {
        width: 100%;
        overflow-x: hidden;
    }
`;


const RulesGrid = styled(Grid)`
    background: #FFF;
    min-width: 950px;
    tr>*:first-child {padding-left: 10px !important}
    tr.selected {
        background: #ffd352;
    }
    th {
        min-width: 80px;
    }
    //tr:not(.empty-row)>*:last-child {
    //    display: none; /* placeholder for settings icon we're not using */
    //}
    .empty-row {
        height: 80px;
        td {
            vertical-align: top !important;
            padding-top: .5em;
        }
    }
`;

// headerWidth not working, do this instead
const DetailsWrapper = styled.div`
    max-width: 930px;
    th.editName {
        max-width: 100px;
        width: 100px;
    }
    th.totalErrorCount {
        max-width: 80px
        width: 80px;
    }
    th.message, td.message {
        min-width: 700px;
        width: 700px;
        max-width: 700px;
    }
    table tbody tr:not(.empty-row) > td div {
        white-space: nowrap ;
        overflow-x:hidden;
        text-overflow: clip;
        vertical-align: middle;
    }
`;

const HCPCSContainer = styled.div`
    display: flex;
    margin-left: 32px;
`

const ManageRulesGridWrapper = styled.div`
    max-width: 750px;
    th.payerLob, td.payerLob {
        max-width: 100px !important;
        width: 100px;
    }
    th.fieldName, td.fieldName {
        max-width: 90px !important;
        width: 90px;
    }
    th.categoryName, td.categoryName {
        max-width: 150px !important;
        width: 150px;
    }
    th.editName, td.editName {
        max-width: 100px !important;
        width: 100px;
    }
    th.assignedTo, td.assignedTo {
        max-width: 120px !important;
        width: 120px;
    }
    th.expires, td.expires {
        max-width: 80px !important;
        width: 80px;
    }
    th.otherCriteria, td.otherCriteria {
        min-width: 100px;
    }
    table tbody tr > td {
        white-space: nowrap ;
        overflow-x:hidden;
        text-overflow: hidden;
    }
`;
const AssignmentContainer = styled(RuleAssignmentComponent)`
    width: fit-content;
    margin: 0 auto;   
`;

const SummaryWrapper = styled.div`
    max-width: 930px;
    th {
        max-width: 150px; /*defaults*/
        width: 100px;
        vertical-align: middle;
    }
    th.ruleExistsIcon.ruleExistsIcon.ruleExistsIcon.ruleExistsIcon, td.ruleExistsIcon.ruleExistsIcon.ruleExistsIcon.ruleExistsIcon {
        max-width: 35px;
        width: 35px;
        min-width: 0;
    }
    td.ruleExistsIcon {
        padding-left: 5px !important;
        padding-right: 5px !important;
        svg {
            height: 25px;
            width: 25px;
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .4));
        }
    }
    th.ruleExistsIcon.ruleExistsIcon.ruleExistsIcon.ruleExistsIcon.ruleExistsIcon.ruleExistsIcon button {
        padding: 0;
        vertical-align: middle;
        svg {
            position: relative;
        }
    }
    th.hcpcsMax, th.hcpcsMin, th.payerLob, td.payerLob {
        max-width: 140px;
        width: 115px;
        min-width: 110px;
    }
    th.client1, th.client2, th.client3 {
        min-width: 90px;
    }
    th.facilityName, td.facilityName {
        min-width: 130px;
        max-width: 180px;
    }
    th.categoryName, td.categoryName {
        max-width: 220px;
        width: auto;
        min-width: 120px;
    }
    td.ruleExistsIcon svg path {
        fill: ${Colors.digitalRed150} !important;
    }
    table tbody tr:not(.empty-row) > td {
        white-space: nowrap ;
        overflow-x:hidden;
        text-overflow: clip;
        vertical-align: middle;
    }
     table tbody tr td.ruleExistsIcon div[class^=IconCell__IconWrapper-sc-]{
         padding-top:0px;
     }
`;

// TODO: extract for reuse
const TitleGridCell = ({ text, title }: {text:string, title?:string}) => <div title={title??text}>{text}</div>;

const MAX_RETURN_ROWS = "500";

export class ManageClaimAssignmentsErrorRules extends React.PureComponent<IManageClaimAssignmentsErrorRulesProps,
    IFormModalState> {
    // define only once, instead of on every render
    private instructions = <React.Fragment><div>Search for claim errors that have been encountered, and assign future claims with those errors to one or more users or departments.</div><i>(For more information, click the help button.)</i></React.Fragment>;

    constructor(props: IManageClaimAssignmentsErrorRulesProps) {
        super(props);
        this.state = defaultFormModalState;
        bindAll(this, [
            'onTimePeriodChange', 'onHcpcsMinChange', 'onHcpcsMaxChange', 'onClientField1Change', 'onClientField2Change', 'onClientField3Change', 'onErrorTextChange', 'doMCAERSearch',
            'renderManageErrorRules', 'renderSearchResults', 'renderMCAERSearch', 'renderSelectedTab',
            'selectMCAERTab', 'resetWarnings',
            // we expect to remove these once redux actions for these exist
            'onSelectCategories', 'onSelectLobs', 'onSelectPayers', 'onSelectFacilities', 'onSelectFields',
            //
            'onRulesRowSelected',
            // results tab
            'onResultsSummarySelect', 'onResultsDetailSelect', 'onResultsAssignmentSelectUsers', 'onResultsAssignmentDepartments', 'onResultsAssignmentsAlphaSplit',
            'onSummaryGridSort', 'onSummaryDetailSort', 'onManageRuleSort',
        ]);
    }

    public componentDidMount() {
        pageLeave();
        this.props.action.crud.get({ crudId: CrudTypes.mctiClaimAssignmentError },
            (result: MCManageClaimAssignmentError) => {
                if (validationCallback(result)) {
                    this.props.action.ui.initalizeOptions({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: result,
                    });
                    return true;
                }
            }
        );
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    // for some reason manual bind and bindAll did not work with these functions, so using alternate syntax
    public onDialogOk = () => {
        this.setState({ navigationConfirmed: true, saveOnNavigate: true },
            () => {
                if (this.props.dataStore.ui.isDirty) {
                    this.props.action.ui.saveRuleChanges(CrudTypes.mctiClaimAssignmentError, this.props.dataStore.ui.rules);
                }
                // TODO: may need to validate no errors before navigation
                // See Physician Maintenance for ex
                this.props.history.push("/LandingPage");
            });
    }

    public onDialogCancel = () => {
        this.setState({ saveOnNavigate: false},
            () => {
                this.props.action.confirm.openConfirm({ message: '', confirmCallback: this.onModalOk, cancelCallback: this.onModalClose});
            }
        );
    }

    public onModalOk = () => {
        this.setState({ navigationConfirmed: true, saveOnNavigate: false },
            () => {
                this.props.history.push(this.state.navDestination?.pathname || '/LandingPage');
            });
    }

    public onModalClose = () => {
        this.setState({ navigationConfirmed: false, saveOnNavigate: false },
            () => {
                this.props.action.confirm.closeConfirm();
            });
    }

    //methods for UI gestures here
    /* --------------------------- common --------------------------- */
    selectMCAERTab(event: React.SyntheticEvent<Element, Event>, index: {selectedTabIndex:number}) {
        this.props.action.ui.setSelectedTab(index.selectedTabIndex);
    }
    /* --------------------------- search --------------------------- */
    onTimePeriodChange(event: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectTimePeriod(event.target.value);
    }
    onHcpcsMinChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editHcpcsMin(event.target.value);
    }
    onHcpcsMaxChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editHcpcsMax(event.target.value);
    }
    onClientField1Change(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editClientSpecific(0, event.target.value);
    }
    onClientField2Change(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editClientSpecific(1, event.target.value);
    }
    onClientField3Change(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editClientSpecific(2, event.target.value);
    }
    onErrorTextChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editErrorText(event.target.value);
    }
    //TODO: these can probably just go straight to the actions, but the actions don't exist yet.
    onSelectLobs(lobIds: string[]) {
        this.props.action.ui.selectLobs(lobIds);
    }
    onSelectPayers(payerIds: string[]) {
        this.props.action.ui.selectPayers(payerIds);
    }
    onSelectFacilities(facilityIds: string[]) {
        this.props.action.ui.selectFacilities(facilityIds);
    }
    onSelectFields(fieldids: string[]) {
        this.props.action.ui.selectFields(fieldids);
    }
    onSelectCategories(categoryIds: string[]) {
        this.props.action.ui.selectCategories(categoryIds);
    }
    // debuke says: add the tests for this before committing!
   
    onManageRulesUpdate() {
        // TODO: add test
        handleChange();
        this.props.action.ui.updateManageRules();
    }
    onManageRulesRemove() {
        // TODO: add test
        handleChange();
        this.props.action.ui.removeManageRules();
    }
    onManageRulesSelectUsers(users: IRulesAssignUserInfo[]) {
        this.props.action.ui.selectUsersManageRules(users.map(u => { return u.userId; }));
    }
    onManageRulesAlphaSplitChanged(item: IRulesAssignUserInfo, newValue: string) {
        this.props.action.ui.userAlphaSplitUpdate(item.userId, newValue );
    }
    onManageRulesDepartments(dept: IRulesAssignDepartmentInfo) {
        this.props.action.ui.selectDepartmentsManageRules(dept.deptId);
    }
    onManageRulesExpires(item?: string) {
        this.props.action.ui.expiresManageRules(item);
    }
    onRulesRowSelected(selectedRuleId: string | undefined) {
        this.props.action.ui.selectManageRules(selectedRuleId);
    }
    /* -------------------------------------------------------------------------------------------------- */
    /*          SEARCH                                                                                    */
    /* -------------------------------------------------------------------------------------------------- */

    private validateSearchParams(): boolean {
        const { searchFieldValues } = this.props.dataStore.ui;

        if (searchFieldValues.timePeriod == "none") { 
            if (searchFieldValues.dateFrom == "" || searchFieldValues.dateTo == "") {
                this.props.action.ui.setWarningType("dateRangeRequired");
                return false;
            }
            const fromErrorDate = new Date(searchFieldValues.dateFrom);
            const fromErrorDay = new Date(fromErrorDate.getFullYear(), fromErrorDate.getMonth(), fromErrorDate.getDate());
            const thruErrorDate = new Date(searchFieldValues.dateTo);
            const thruErrorDay = new Date(thruErrorDate.getFullYear(), thruErrorDate.getMonth(), thruErrorDate.getDate());
            const todaysDate = new Date();

            if (fromErrorDay> thruErrorDay) {
                this.props.action.ui.setWarningType("fromDatePriorToThruDateRequired");
                return false;
            }
            if (thruErrorDay >= todaysDate) { 
                this.props.action.ui.setWarningType("searchDatePriorToTodayRequired");
               return false;
            }
        }

        return true;
    }

    /*  Note the format sent by this request does NOT match what ManageClainAssignmentErrorRules sends
     *  because there is a translation layer in XmlCallback.ASP that creates Name/Value pairs
     *  where the Name adds the Hungarian notation prefixes to the atrribute name sent by MCAER.asp
     *  for use by the stored procedures and the value is the value of the attribute.
     *  Ex LOBList="'1', '2'" in XML from ASP becomes <Name="chvLOBList" Value="'1', '2'">.
     */
    private createSearchRequest(): MCASearchRequest | null {
        const { searchFieldValues } = this.props.dataStore.ui;
        // FormType and MaxRows
        const searchRequest: MCASearchRequest =
        {
            Param: [
                {
                    Name: "@intFormType",
                    Value: searchFieldValues.form == "UB" ? "1011" : "1001",
                    Type: 'int',
                },
            ]
        };

        // DateRange, FromDate, ThruDate
        if (searchFieldValues.timePeriod != "none") {
            searchRequest.Param = searchRequest.Param.concat
                (
                    [
                        { Name: "@intMaxDays", Value: searchFieldValues.timePeriod, Type: 'int' },
                    ]);
        }
        else {

            if (searchFieldValues.dateFrom != "") {
                searchRequest.Param = searchRequest.Param.concat
                    (
                        [
                            { Name: "@chvFromDate", Value: convertPossibleARMDateStringToDateString(searchFieldValues.dateFrom) },
                        ]);
            }

            if (searchFieldValues.dateTo != "") {
                searchRequest.Param = searchRequest.Param.concat
                    (
                        [
                            { Name: "@chvThruDate", Value: convertPossibleARMDateStringToDateString(searchFieldValues.dateTo) },
                        ]);
            }
        }

        // LOB or Payer
        if (searchFieldValues.payerType) {
            searchRequest.Param = searchRequest.Param.concat
                (
                    [
                        { Name: "@intPayerList", Value: searchFieldValues.payerType == "payer" ? "1" : "2", Type: 'int' },
                    ]);
        }

        // LOB list
        let searchString = "";
        // TODO switch to map and join instead of all this manual concatenation
        // consider moving to ManageClaimAssignmentsErrorRulesUI.ts
        if (searchFieldValues.payerType == "lob") {
            // remove default (-1) value, add single quotes and delimiters
            searchString = without(searchFieldValues.selectedLobs, '-1').map(value => `'${value}'`).join(', ');
            if (searchString.length > 0) {
                searchRequest.Param = searchRequest.Param.concat
                    (
                        [
                            { Name: "@chvLOBList", Value: searchString },
                        ]);
            }
        }   

        // Payer list
        else {
            searchString = without(searchFieldValues.selectedPayers, '-1').map(value => `'${value}'`).join(', ');

            if (searchString.length > 7500) {
                this.props.action.ui.setWarningType("fewerPayersRequired");
                return null;
            }
            if (searchString.length > 0) {
                searchRequest.Param = searchRequest.Param.concat
                    (
                        [
                            { Name: "@chvPayerList", Value: searchString },
                        ]);
            }
        }

        // Facility list
        searchString = "";
        if (searchFieldValues.selectedFacilities.length > 0) {
            searchString = without(searchFieldValues.selectedFacilities, '-1').map(facilityId => `'${facilityId}'`).join(', ');
            if (searchString.length > 7500) {
                this.props.action.ui.setWarningType("fewerFacilitiesRequired");
               return null;
            }
            if (searchString.length > 0) {
                searchRequest.Param = searchRequest.Param.concat
                    (
                        [
                            { Name: "@chvFacilityList", Value: searchString },
                        ]);
            }
        }

        // Field list
        searchString = "";
        if (searchFieldValues.selectedFields.length > 0) {
            searchString = without(searchFieldValues.selectedFields, '-1').map(fieldId => `'${fieldId}'`).join(', ');
            if (searchString.length > 7500) {
                this.props.action.ui.setWarningType("fewerFieldsRequired");
                return null;
            }
            if (searchString.length > 0) {
                searchRequest.Param = searchRequest.Param.concat
                    (
                        [
                            { Name: "@chvFieldList", Value: searchString },
                        ]);
            }
        }

        // CategoryList
        searchString = "";
        if (searchFieldValues.selectedCategories.length > 0) {
            searchString = without(searchFieldValues.selectedCategories, '-1').map(categoryId => `'${categoryId}'`).join(', ');
            if (searchString.length > 7500) {
                this.props.action.ui.setWarningType("fewerFieldsRequired");
                return null;
            }
            if (searchString.length > 0) {
                searchRequest.Param = searchRequest.Param.concat
                    (
                        [
                            { Name: "@chvCategoryList", Value: searchString },
                        ]);
            }
        }

        // Search string
        if (searchFieldValues.errorText != "") {
            searchRequest.Param = searchRequest.Param.concat
                (
                    [
                        { Name: "@chvSearchString", Value: searchFieldValues.errorText },
                    ]);
        }

        // ClientSpec1, ClientSpec2, ClientSpec3
        if (searchFieldValues.clientFields.length > 0 && !!searchFieldValues.clientFields[0]) {
            searchRequest.Param = searchRequest.Param.concat
                (
                    [
                        { Name: "@chvClientSpec1", Value: searchFieldValues.clientFields[0] },
                    ]);
        }
        if (searchFieldValues.clientFields.length > 1 && !!searchFieldValues.clientFields[1]) {
            searchRequest.Param = searchRequest.Param.concat
                (
                    [
                        { Name: "@chvClientSpec2", Value: searchFieldValues.clientFields[1] },
                    ]);
        }
        if (searchFieldValues.clientFields.length > 2 && !!searchFieldValues.clientFields[2]) {
            searchRequest.Param = searchRequest.Param.concat
                (
                    [
                        { Name: "@chvClientSpec3", Value: searchFieldValues.clientFields[2] },
                    ]);
        }

        // HCPCSMin, HCPCSMax
        if (searchFieldValues.hcpcsMin) {
            searchRequest.Param = searchRequest.Param.concat
                (
                    [
                        { Name: "@chvHCPCSMin", Value: searchFieldValues.hcpcsMin },
                    ]);
        }

        if (searchFieldValues.hcpcsMax) {
            searchRequest.Param = searchRequest.Param.concat
                (
                    [
                        { Name: "@chvHCPCSMax", Value: searchFieldValues.hcpcsMax },
                    ]);
        }
        searchRequest.Param = [...searchRequest.Param, 
                {
                    Name: "@intMaxRows",
                    Value: MAX_RETURN_ROWS.toString(),
                    Type: 'int',
                }
            ]
        //console.log(searchRequest);
        return searchRequest;
    }

    doMCAERSearch(event: React.SyntheticEvent) {
        var bValid = this.validateSearchParams();
        if (!bValid) {
            console.warn('doMCAERSearch NO Valid Params');
            return;
        }

        const searchRequest: MCASearchRequest | null = this.createSearchRequest();
        if (searchRequest == null)
            return; // TODO: do we need to do anything?

        // TODO: do we need some loading indicator?
        //If so, set busy in the request phase of the crud call
        this.props.action.ui.searchMCAErrorRules(searchRequest);
    }
    /* --------------------------- results --------------------------- */
    onResultsSummarySelect(e: React.SyntheticEvent<Element, Event>, record: IMCAERSearchSummary, index?: number) {
        const mouseEvent = e as React.MouseEvent<HTMLTableRowElement>;
        this.props.action.ui.selectSummaryResultsAssignment(record.refId);
    }
    onResultsDetailSelect(e: React.SyntheticEvent<Element, Event>, record: IMCAERSearchSummary, index?: number) {
        const mouseEvent = e as React.MouseEvent<HTMLTableRowElement>;
        this.props.action.ui.selectDetailResultsAssignment(record.refId);
    }
    onResultsAssignmentSelectUsers(users: IARMUserData[]) {
        this.props.action.ui.selectUsersResultsAssignment(users.map(user => user.userId));
    }
    onResultsAssignmentDepartments(department: IARMDeptData) {
        this.props.action.ui.departmentsResultsAssignment(department.deptId);
    }
    onResultsAssignmentsAlphaSplit(user: IARMUserData, value: string) {
        this.props.action.ui.setAlphaSplitResultsAssignment(user.userId, value);
    }
    onSummaryGridSort(e: React.MouseEvent<HTMLTableHeaderCellElement>, sortData: {sortingKey: string /*todo there are other props we may need*/}) {
        this.props.action.ui.setSummaryGridSortOrder(sortData.sortingKey);
    }
    onSummaryDetailSort(e: React.MouseEvent<HTMLTableHeaderCellElement>, sortData: {sortingKey: string}) {
        this.props.action.ui.setDetailGridSortOrder(sortData.sortingKey);
    }
    /* --------------------------- manage --------------------------- */
    onManageRuleSort(e: React.MouseEvent<HTMLTableHeaderCellElement>, sortData: { sortingKey: string }) {
        this.props.action.ui.setManageRuleGridSortOrder(sortData.sortingKey);
    }

/* --------------------------- alerts --------------------------- */
    getAlertOpen() {
        //return false;
        return !!this.props.dataStore.ui.warningType;
    }

    getAlertMessage() {
        //let mystr: string = '';
        //switch (mystr) {
        switch (this.props.dataStore.ui.warningType) {
            case "noUnassignedErrors": {
                return "There are no Unassigned Errors that match your criteria. Please modify and search again.";
            }
            case "dateRangeRequired": {
                return "Time Period or Date Range for errors is required.";
            }
            case "fromDatePriorToThruDateRequired": {
               return "Date Range from date must be prior to through date.";
            }
            case "searchDatePriorToTodayRequired": {
                return "Search Date cannot be greater than today.";
            }
            case "maxRowsExceeded": {
                return "There are too many results to display, please refine your search criteria and try again.";
            }
            case "fewerPayersRequired": {
                return "Too many Payers selected, unable to perform search, please select fewer Payers and try again.";
            }
            case "fewerFacilitiesRequired": {
                return "Too many Facilities selected, unable to perform search, please select fewer Facilities and try again.";
            }
            case "fewerFieldsRequired": {
                return "Too many Fields selected, unable to perform search, please select fewer Fields and try again.";
            }
            case 'rulesNotAdded': {
                return 'There were Summary Results selected that already have a rule defined and therefore another rule was not created.\n\nAlter the assignment on the Manage Rules tab for Summary Results that have an exclamation mark.';
            }
            default:
                break;
        }
        return "";
    }

    resetWarnings() {
        this.props.action.ui.resetWarnings();
    }
    private okCancelButtons = <OKCancelButtons onClickOK={this.onDialogOk} onClickCancel={this.onDialogCancel}/>;


    // https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
    showRouteChangeConfirm(nextLocation: Location) {
        console.log('navigating');
        this.setState({ saveOnNavigate: false, navDestination: nextLocation },
            () => {
                this.props.action.confirm.openConfirm({ message: '' });
            }
        );
        //suppresses native prompt modal
        return false;
    }

    private tabList: ITabItem[] = [{label: 'Search', domID: 'mca-err-search-tab'}, {label: 'Results', domID: 'mca-err-results-tab'}, {label: 'Manage Rules', domID: 'mca-err-manage-rules-tab'}];
    private armDateFormat = 'MM/DD/YYYY';
    renderSelectedTab(tabIndex: number) {
        const {searchListPopulation, searchFieldValues, searchResults, rules, manageRuleState} = this.props.dataStore.ui;
        switch (tabIndex) {
            case 1: return this.renderSearchResults(searchResults, searchFieldValues);
            case 2: return this.renderManageErrorRules(rules, manageRuleState);
            default: return this.renderMCAERSearch(searchListPopulation, searchFieldValues);
        }
    }

    renderMCAERSearch(listPopulation: IManageClaimAssignmentsErrorRulesData['searchListPopulation'], fieldValues: IManageClaimAssignmentsErrorRulesData['searchFieldValues']) {
        const minDate = moment().add(-1, 'year').format(this.armDateFormat);
        const maxDate = moment().add(1, 'day').format(this.armDateFormat); // the old code did something odd but the legacy UI still won't let us select future dates...

        let payerListValues: APICF_SimpleList[] = listPopulation.lobList;
        let defaultPayerListValue = '- All LOBs -';
        let selectedPayerIds = fieldValues.selectedLobs;
        let listValueChangeHandler = this.onSelectLobs;
        if (fieldValues.payerType === 'payer') {
            payerListValues=listPopulation.payerList;
            defaultPayerListValue = '- All Payers -';
            selectedPayerIds=fieldValues.selectedPayers;
            listValueChangeHandler = this.onSelectPayers;
        }
        const fieldProperty = fieldValues.form === 'UB' ? 'FormUB' : 'Form1500';

        let filterFieldList: IMCAERS_FieldValue[] = [];
        if (listPopulation.fieldList && listPopulation.fieldList.length > 0) {
            if (fieldValues.selectedCategories.length == 0 || (fieldValues.selectedCategories.length == 1 && fieldValues.selectedCategories[0] == '-1')) {
                filterFieldList = listPopulation.fieldList.filter(option => (option[fieldProperty] === 'Y'));
                //console.log("field count: " + filterFieldList.length);
            }
            else if (fieldValues.selectedCategories.length > 0) {
                filterFieldList = listPopulation.fieldList.filter(option => (option[fieldProperty] === 'Y' && fieldValues.selectedCategories.includes(option['ErrorCategoryID'])));
                //console.log("filtered field count: " + filterFieldList.length);
            }
        }

        const fieldOptions = filterFieldList;
        const {toggleFormType, togglePayerType} = this.props.action.ui;
        return (
            <SearchContainer id='mcaer-search-tab-content' >
                <FlexBoxModifier as={ContentRowWrapper} flexGap='97px' justifyContent='center'>
                    <FlexBoxModifier as={FlexBoxModifier} flexGap='20px'>
                        <ArmRadioButtonGroup id='mcaer-toggle-form-type-group'
                            label='Form:'
                            items={[{ value: 'UB', labelAfter: 'UB', name: 'UB' }, { value: '1500', labelAfter: '1500', name: '1500' }]}
                            initialValue={fieldValues.form}
                            onGroupChange={toggleFormType}
                        />
                        <SelectComponent className='select-time-period'
                            label='Time Period:'                           
                            style={{ width: '170px', height:'30px',borderRadius:'5px' }}
                            title='mcaer-select-time-period'
                            onSelect={this.onTimePeriodChange}
                            records={
                                [
                                    { value: '0', text: 'Today' },
                                    { value: '10', text: 'Last 10 Days' },
                                    { value: '30', text: 'Last 30 Days' },
                                    { value: '60', text: 'Last 60 Days' },
                                    { value: '90', text: 'Last 90 Days' },
                                    { value: 'none', text: 'Custom Date Range' },
                                ]
                            }
                            size={1}
                            selectedValue={fieldValues.timePeriod}
                        />
                        <ARMDateRange 
                            allowTime={false}
                            domIdPrefix="report-filter-pmt-date-"
                            initialFrom={fieldValues.dateFrom}
                            initialTo={fieldValues.dateTo}
                            labelFrom="Date Range:"
                            labelTo="to"
                            minDate={minDate}
                            maxDate={maxDate}
                            onFromChange={this.props.action.ui.editDateFrom}
                            onToChange={this.props.action.ui.editDateTo}
                            showDefaultButtonBar={false}
                            disabled={fieldValues.timePeriod !== 'none'}
                        />
                    </FlexBoxModifier>
                    <ContentColumnWrapper>
                        <ARMNarrowInput 
                            domID='mcaer-client-0'
                            label='Client Specific 1:'
                            initialValue={fieldValues.clientFields.length ? fieldValues.clientFields[0] : ''}
                            onBlur={this.onClientField1Change}
                            maxLength={30}
                            type='search'
                            width='305px'
                        />
                        <ARMNarrowInput 
                            domID='mcaer-client-1'
                            label='Client Specific 2:'
                            initialValue={fieldValues.clientFields.length>1 ? fieldValues.clientFields[1] : ''}
                            onBlur={this.onClientField2Change}
                            maxLength={25}
                            type='search'
                            width='305px'
                        />
                        <ARMNarrowInput 
                            domID='mcaer-client-2'
                            label='Client Specific 3:'
                            initialValue={fieldValues.clientFields.length>2 ? fieldValues.clientFields[2] : ''}
                            onBlur={this.onClientField3Change}
                            maxLength={16}
                            type='search'
                            width='305px'
                        />
                        <HCPCSContainer>
                            <SizedContainer pixelWidth='240'>
                                <ARMNarrowInput 
                                    domID='mcaer-hcpcs_min'
                                    label='HCPCS&nbsp;Code:'
                                    initialValue={fieldValues.hcpcsMin}
                                    onBlur={this.onHcpcsMinChange}
                                    maxLength={10}
                                    type='search'
                                    width='130px'
                                />
                            </SizedContainer>
                            <SizedContainer pixelWidth='100'>
                                <ARMNarrowInput 
                                    domID='mcaer-hcpcs_max'
                                    label='to'
                                    initialValue={fieldValues.hcpcsMax}
                                    onBlur={this.onHcpcsMaxChange}
                                    maxLength={10}
                                    type='search'
                                    width='130px'
                                />
                            </SizedContainer>
                        </HCPCSContainer>
                    </ContentColumnWrapper>
                </FlexBoxModifier>

                <ARMNarrowInput 
                    domID='mcaer-error-contains-text' 
                    label='Error Text Contains:' 
                    initialValue={fieldValues.errorText} 
                    onBlur={this.onErrorTextChange} 
                    maxLength={256} width='730px'
                    type='search'
                />

                <ContentRowWrapper>
                    <DialogFieldset widthValue='50%'>
                        <DialogLegend>
                            <ArmRadioButtonGroup id='mcaer-toggle-payer-type-group'
                                items={[{ value: 'lob', labelAfter: 'LOB', name: 'LOB' }, { value: 'payer', labelAfter: 'Payer', name: 'Payer' }]}
                                initialValue={fieldValues.payerType}
                                onGroupChange={togglePayerType}
                            />
                        </DialogLegend>
                        <MultiSelectForSimpleValues 
                            title='mcaer-payer-select'
                            className='search-select'
                            label=''
                            tooltip={fieldValues.payerType === 'payer'}
                            optionList={payerListValues}
                            defaultOption={defaultPayerListValue}
                            selectedIds={selectedPayerIds}
                            onChange={listValueChangeHandler}
                        />
                    </DialogFieldset>
                    <DialogFieldset widthValue='50%'>
                        <DialogLegend>Category</DialogLegend>
                        <MultiSelectForSimpleValues 
                            title='mcaer-category-select'
                            className='search-select'
                            label=''
                            tooltip={true}
                            optionList={listPopulation.errorCategoryList}
                            defaultOption='- All Categories -'
                            selectedIds={fieldValues.selectedCategories}
                            onChange={this.onSelectCategories}
                        />
                    </DialogFieldset>
                </ContentRowWrapper>

                <ContentRowWrapper>
                    <DialogFieldset widthValue='50%'>
                        <DialogLegend>Facility</DialogLegend>
                        <MultiSelectForSimpleValues 
                            title='mcaer-facility-select'
                            className='search-select'
                            label=''
                            tooltip={true}
                            optionList={listPopulation.facilityList}
                            defaultOption='- All Facilities -'
                            selectedIds={fieldValues.selectedFacilities}
                            onChange={this.onSelectFacilities}
                        />
                    </DialogFieldset>
                    <DialogFieldset widthValue='50%'>
                        <DialogLegend>Field</DialogLegend>
                        <MultiSelectForSimpleValues 
                            title='mcaer-field-select'
                            className='search-select'
                            label=''
                            optionList={fieldOptions}
                            defaultOption={`- All ${fieldValues.form} Fields -`}
                            selectedIds={fieldValues.selectedFields}
                            onChange={this.onSelectFields}
                        />
                    </DialogFieldset>
                </ContentRowWrapper>

                <FlexBoxModifier as={ContentRowWrapper} justifyContent='center'>
                    <Button domID='mcaer-search-btn' name='Search' buttonType='emphasized' type='button' size="control" onClick={(e: React.SyntheticEvent) => this.doMCAERSearch(e)} />
                </FlexBoxModifier>
            </SearchContainer>
        );
    }

    // Note that we know we're in the MCAER file, so we don't need to add MCAER to the names of things
    renderSearchResults(results: IManageClaimAssignmentsErrorRulesData['searchResults'], fieldValues: IManageClaimAssignmentsErrorRulesData['searchFieldValues']) {
        const rulesUsers = results.userList.filter(user => !user.isDisabled);
        const selectedDepartment = results.departmentList.find(dept => dept.selected) || { deptId: "", name: "", selected: false };
        return(
            <ContentColumnWrapper id='mcae-search-results'>
                <DialogFieldset style={{ width: "99.2%" }}>
                    <DialogLegend>Results</DialogLegend>
                    {this.renderSearchSummaryGrid(results.summaries || [], fieldValues, results.summarySortOrder)}
                </DialogFieldset>
                <DialogFieldset style={{ width:"99.2%" }} >
                    <DialogLegend>Error details for selected result</DialogLegend>
                    {this.renderSummaryDetailsGrid((results.summaries || []).filter(summary => summary.isSelected), results.detailSortOrder)}
                </DialogFieldset>
                <AssignmentContainer
                    className='mcae-rules-assignment'
                    containerTitle='Assignment'
                    allUsers={rulesUsers}
                    allDepartments={results.departmentList}
                    showExpiresOn={true}
                    expiresOnValue=''
                    buttonType={eRulesAssignmentModifyButtonType.Add}
                    enableButtons={results.summaries.some(summary =>  summary.isSelected) && (results.userList.some(user => user.selected) || results.departmentList.some(department =>  department.selected))}
                    onNewCallback={() => {this.props.action.ui.newResultsAssignment(); handleChange();}}
                    onUserAlphaSplitCallback={this.onResultsAssignmentsAlphaSplit}
                    onSelectUsersCallback={this.onResultsAssignmentSelectUsers}
                    onSelectDepartmentsCallback={this.onResultsAssignmentDepartments}
                    onUpdateExpiresOnCallback={this.props.action.ui.expiresResultsAssignment}
                    selectedDepartment={selectedDepartment}
                />
            </ContentColumnWrapper>
        )
    }

    private ruleExistsIcon = {
        icon: Warning,
        text: ''
    }
;
    private ruleDoesNotExistIcon = () => <div />;

    // TODO: typescript type restricting to column data names
    private summaryComparator = (sortDataName: string, sortDirectionString: string, records: IGridSummary[]) => {
        let sortFields: SummarySortFields[] = [];
        switch (sortDataName) {
            case 'ruleExistsIcon' :
                sortFields = ['ruleExists'];
                // this sort order somehow feels more natural for this field
                return orderBy(records, sortFields, [sortDirectionString === 'SORT_DESCENDING' ? 'desc' : 'asc']);
            case 'payerLob': 
                sortFields = ['payerLob'];
                break;
            case 'fieldName': 
                sortFields = ['fieldName', 'payerLob'];
                break;
            case 'initial': 
                sortFields = ['payerLob', 'fieldName'];
                break;
            case 'categoryName': 
                sortFields = ['categoryName', 'payerLob'];
                break;
            case 'intTotalErrorCount': 
                sortFields = ['intTotalErrorCount', 'payerLob'];
                break;
            case 'facilityName': 
            case 'hcpcsMin': 
            case 'hcpcsMax': 
                sortFields = [sortDataName];
                break;
            default:
                break;
        }
        // note grid default behavior is backward, starting with a descending sort, so this fn is backward again
        if (sortFields.length > 0) {
            return orderBy(records, sortFields, sortFields.map(field => sortDirectionString === 'SORT_DESCENDING' ? 'asc' : 'desc'));
        } else {
            // client 1, 2, 3
            const fieldNum = sortDataName.replace('client', '');
            const maybeIdx = parseInt(fieldNum);
            const idx = isNaN(maybeIdx) ? 0 : maybeIdx;
            const sortField = `clientFields[${idx}]`;
            return orderBy(records, [sortField], [sortDirectionString === 'SORT_DESCENDING' ? 'asc' : 'desc']);
        }
    }
    
    renderSearchSummaryGrid(summaries: IMCAERSearchSummary[], fieldValues: IManageClaimAssignmentsErrorRulesData['searchFieldValues'], initialSortingKey: string) {
        const summaryGridColumnArray: ColumnType[] = [
            {dataName: 'ruleExistsIcon', text: '', sortable: true, isSorted: 0, cellType: 'icon'},
            {dataName: 'payerLob', text: 'Payer/LOB', sortable: true, isSorted: 0},
            {dataName: 'fieldName', text: 'Field', sortable: true, isSorted: 0},
            {dataName: 'categoryName', text: 'Category', sortable: true, isSorted: 0},
            {dataName: 'intTotalErrorCount', text: 'Count', sortable: true, isSorted: 0},
            {dataName: 'facilityName', text: 'Facility Name', sortable: true, isSorted: 0},
            {dataName: 'hcpcsMin', text: 'HCPCS Min', sortable: true, isSorted: 0},
            {dataName: 'hcpcsMax', text: 'HCPCS Max', sortable: true, isSorted: 0},
            {dataName: 'client1', text: 'Client 1', sortable: true, isSorted: 0},
            {dataName: 'client2', text: 'Client 2', sortable: true, isSorted: 0},
            {dataName: 'client3', text: 'Client 3', sortable: true, isSorted: 0},
        ];
        const columns: Set<ColumnType> = new Set(summaryGridColumnArray) as Set<ColumnType>;
        const defaultColumnsArray: string[] = summaryGridColumnArray.reduce<string[]>((defColumns, column, index) => {
            if (index < 5) return [...defColumns, column.dataName];
            switch (column.dataName) {
                case 'facilityName':
                    if (without(fieldValues.selectedFacilities, '-1').length>0) return [...defColumns, column.dataName];
                    break;
                case 'hcpcsMin':
                case 'hcpcsMax':
                    if (!!fieldValues[column.dataName]) return [...defColumns, column.dataName];
                    break;
                case 'client1':
                    if (!!fieldValues.clientFields[0]) return [...defColumns, column.dataName];
                    break;
                case 'client2':
                    if (!!fieldValues.clientFields[1]) return [...defColumns, column.dataName];
                    break;
                case 'client3':
                    if (!!fieldValues.clientFields[2]) return [...defColumns, column.dataName];
                    break;
            }
            return defColumns;
        }, []);
        const defaultColumnsDataNames = new Set(
            defaultColumnsArray
        );
        const summariesForGrid = summaries.filter(summary => summary.details?.length || !summary.ruleExists)
            .map((summary:any) => ({
                ...summary,
                ruleExistsIcon: (summary.ruleExists? this.ruleExistsIcon : this.ruleDoesNotExistIcon),
                client1: summary.clientFields?.[0] || '',
                client2: summary.clientFields?.[1] || '',
                client3: summary.clientFields?.[2] || '',
                intTotalErrorCount: parseInt(summary.totalErrorCount ?? ''),
            }));
        // adds "selected" class to selected rows
        const selectedIds = new Set(summariesForGrid.filter(summary =>  summary.isSelected).map(summary => summary.refId).filter(refId => refId !== undefined));
        return (
            <SummaryWrapper>
                <RulesGrid domID='mcaer-search-summary-grid'
                    dataTestId='mcaer-search-summary-grid'
                    columns={columns}
                    defaultColumnsDataNames={defaultColumnsDataNames}
                    records={summariesForGrid}
                    selectionKey='refId'
                    initialSelectedItemIds={selectedIds}
                    onClickThrough={this.onResultsSummarySelect}
                    onSortGridColumn={this.onSummaryGridSort}
                    supportSelection={false}
                    sortingComparator={this.summaryComparator}
                    isConfigurable={false}
                    maxHeight='150px'
                    isFixedHeader
                    initialSortingKey={initialSortingKey}
                />
            </SummaryWrapper>
        );
    }
    
    private detailComparator = (sortDataName: string, sortDirectionString: string, records: IGridDetail[]) => {
        let sortFields: DetailSortFields[] = [];
        switch (sortDataName) {
            case 'message':
                sortFields = ['errorMsg', 'editName'];
                break;
            case 'intTotalErrorCount':
                sortFields = ['intTotalErrorCount', 'editName'];
                break;
            default:
                sortFields = ['editName'];
        }
        return orderBy(records, sortFields, sortFields.map(field => sortDirectionString === 'SORT_DESCENDING' ? 'asc' : 'desc'));
    }
    renderSummaryDetailsGrid(selectedSummaries: IMCAERSearchSummary[], initialSortingKey: string) {
        const columns = new Set<ColumnType>([
            {dataName: 'editName', text: 'Edit', headerWidth: 100, sortable: true, isSorted: 0},
            {dataName: 'message', text: 'Message', cellType: 'custom', customComponent: TitleGridCell, sortable: true, isSorted: 0},
            {dataName: 'intTotalErrorCount', text: 'Count', headerWidth: 80, sortable: true, isSorted: 0},
        ]);
        // custom cell type can't take a plain string, so we map the error message to an object that our custom component then receives
        const details = (selectedSummaries.length === 1) ? selectedSummaries[0].details?.map(detail => ({ ...detail, message: {text: detail.errorMsg}, intTotalErrorCount: parseInt(detail.totalErrorCount)})): [];
        const selectedIds = new Set<string| number>(details?.filter(detail => detail.isSelected).map(detail => detail.refId).filter(refId => refId !== undefined));
        return (
            <DetailsWrapper>
                <RulesGrid domID='mcaer-search-details-grid'
                    dataTestId='mcaer-search-details-grid'
                    columns={columns}
                    records={details || []}
                    selectionKey='refId'
                    initialSelectedItemIds={selectedIds}
                    onClickThrough={this.onResultsDetailSelect}
                    onSortGridColumn={this.onSummaryDetailSort}
                    sortingComparator={this.detailComparator}
                    isConfigurable={false}
                    maxHeight='150px'
                    isFixedHeader
                    initialSortingKey={initialSortingKey}
                    emptyGridMessage={selectedSummaries.length>0 ?'': 'No summary selected'}
            />
            </DetailsWrapper>
        );
    }

    private manageRulesComparator = (sortDataName: string, sortDirectionString: string, records: IGridManageRules[]) => {
        let sortFields: ManageRulesSortFields[] = [];
        switch (sortDataName) {
            case 'assignedTo':
                sortFields = ['assignedToSort', 'payerLobSort'];
                break;
            case 'fieldName':
                sortFields = ['fieldName', 'payerLobSort'];
                break;
            case 'categoryName':
                sortFields = ['categoryNameSort', 'payerLobSort'];
                break;
            case 'otherCriteria':
                sortFields = ['otherCriteriaSort', 'payerLobSort'];
                break;
            case 'expires':
                sortFields = ['expiresSort', 'payerLobSort'];
                break;
            case 'payerLob':
                sortFields = ['payerLobSort'];
                break;
            case 'editName':
                sortFields = [sortDataName];
                break;
            default:
                break;
        }
        // note grid default behavior is backward, starting with a descending sort, so this fn is backward again
        return orderBy(records, sortFields, sortFields.map(() => sortDirectionString === 'SORT_DESCENDING' ? 'asc' : 'desc'));
    }

    renderManageErrorRules(rules: IMCAERS_Rule[], manageRuleState: IMCAERManageRulesTabState) {
        const ruleSelectedDepartment = manageRuleState?.departmentList?.find(dept => dept.selected) || { deptId: "", name: "", selected: false };
        const initialSortingKey = manageRuleState.rulesSortOrder;
        // TODO: note that columns have an align prop that ultimate goes into flex box align-items prop in GridCell and presumably header cell
        const columns = new Set<ColumnType>([
            { dataName: 'payerLob', text: 'Payer/LOB', sortable: true, isSorted: 0, cellType: 'custom', customComponent: TitleGridCell},
            { dataName: 'fieldName', text: 'Field', sortable: true, isSorted: 0 },
            { dataName: 'categoryName', text: 'Category', sortable: true, isSorted: 0, cellType: 'custom', customComponent: TitleGridCell },
            { dataName: 'editName', text: 'Edit', sortable: true, isSorted: 0 },
            { dataName: 'assignedTo', text: 'Assigned To', sortable: true, isSorted: 0, cellType: 'custom', customComponent: TitleGridCell },
            { dataName: 'expires', text: 'Expires', sortable: true, isSorted: 0 },
            { dataName: 'otherCriteria', text: 'Other Criteria', sortable: true, isSorted: 0, cellType: 'custom', customComponent: TitleGridCell},
        ]);
        const unsortedRules = rules.filter(r => !r.isDeleted).map((rule:any) => {
            let criteria: string[] = [];
            if (rule.facilityName) { criteria.push("Facility: " + rule.facilityName); }
            if (rule.clientFields && rule.clientFields[0]) { criteria.push("ClientSpec1: " + rule.clientFields[0]); }
            if (rule.clientFields && rule.clientFields[1]) { criteria.push("ClientSpec2: " + rule.clientFields[1]); }
            if (rule.clientFields && rule.clientFields[2]) { criteria.push("ClientSpec3: " + rule.clientFields[2]); }
            if (rule.errorMsg) { criteria.push("ErrMsg: " + rule.errorMsg); }
            if (rule.hcpcsMin) { criteria.push("HCPCSMin: " + rule.hcpcsMin); }
            if (rule.hcpcsMax) { criteria.push("HCPCSMax: " + rule.hcpcsMax); }
            return {
                ...rule,
                otherCriteria: criteria.join(', '),
            };
        })
        const gridRules: IGridManageRules[] = unsortedRules.map(rule => {       // add tooltips for certain columns
            const id = rule.id;
            const assignedTo: ITitleGridCellData = { text: rule.assignedTo, title: rule.assignedToDetails };
            const assignedToSort = rule.assignedTo?.toLowerCase() ?? '';
            const categoryName: ITitleGridCellData = { text: rule.categoryName };
            const categoryNameSort = rule.categoryName ?? '';
            const editName = rule.editName ?? '';
            const expires = rule.expires ?? '';
            const expiresSort = new Date(rule.expires ?? '01/01/0001').getTime();
            const fieldName = rule.fieldName ?? '';
            const isSelected = rule.isSelected;
            const otherCriteria: ITitleGridCellData = { text: rule.otherCriteria };
            const otherCriteriaSort = rule.otherCriteria ?? '';
            const payerLob: ITitleGridCellData = { text: rule.payerLob ?? '' };
            const payerLobSort = rule.payerLob ?? '';
            return { id, assignedTo, assignedToSort, categoryName, categoryNameSort, editName, expires, expiresSort, fieldName, isSelected, otherCriteria, otherCriteriaSort, payerLob, payerLobSort }
        }) as IGridManageRules[];
        const selectedRuleIds = new Set(gridRules.filter(p => p.isSelected).map(row => row.id));
        return (
            <ContentColumnWrapper>
                <DialogFieldset width="99.2%">
                    <DialogLegend>
                        Rules
                    </DialogLegend>
                    <ManageRulesGridWrapper>
                        <RulesGrid domID='mcaer-manage-rules-grid'
                            dataTestId='mcaer-manage-rules-grid'
                            columns={columns}
                            selectionKey='id'
                            onClickThrough={(event: React.SyntheticEvent<Element, Event>, record: IMCAERS_Rule, index?: number | undefined) => this.onRulesRowSelected(record.id)}
                            onSortGridColumn={this.onManageRuleSort}
                            supportSelection={false}
                            isConfigurable={false}
                            records={gridRules}
                            sortingComparator={this.manageRulesComparator}
                            initialSelectedItemIds={selectedRuleIds}
                            maxHeight='300px'
                            isFixedHeader
                            initialSortingKey={initialSortingKey}
                        />
                    </ManageRulesGridWrapper>
                </DialogFieldset>
                <AssignmentContainer
                    className='mcae-rules-assignment'
                    containerTitle='Assignment'
                    allUsers={manageRuleState.userList}
                    allDepartments={manageRuleState.departmentList}
                    showExpiresOn={true}
                    expiresOnValue={manageRuleState.expires ?? ''}
                    buttonType={eRulesAssignmentModifyButtonType.Update}
                    enableButtons={selectedRuleIds.size > 0}
                    onUpdateCallback={() => this.onManageRulesUpdate()}
                    onRemoveCallback={() => this.onManageRulesRemove()}
                    onSelectUsersCallback={(items: IRulesAssignUserInfo[]) => this.onManageRulesSelectUsers(items)}
                    onUserAlphaSplitCallback={(item: IRulesAssignUserInfo, newValue: string) => this.onManageRulesAlphaSplitChanged(item, newValue)}
                    onSelectDepartmentsCallback={(item: IRulesAssignDepartmentInfo) => this.onManageRulesDepartments(item)}
                    onUpdateExpiresOnCallback={(item?: string) => this.onManageRulesExpires(item)}
                    selectedDepartment={ruleSelectedDepartment}
                />
            </ContentColumnWrapper>
        );
    }

    public render() {
        const { isBusy, isDirty, selectedTab } = this.props.dataStore.ui;
        return (
                <DialogWrapper title='Manage Claim Assignment Error Rules'
                               instruction={this.instructions}
                               helpUrl='/Support/Help/HELP_Maint_ClaimAssignment_Errors.htm'
                               buttons={this.okCancelButtons}
                               isBusy={isBusy}>
                    <ContentColumnWrapper>
                        <SizedContainer as={ArmTabContainer} tabs={this.tabList} pixelWidth='970'>
                       {/* <Tabs tabs={this.tabList} onTabSelect={() => this.selectMCAERTab} initialTab={selectedTab} />*/}
                        <Tabs
                            tabs={this.tabList}
                            onTabSelect={(e: React.SyntheticEvent<Element, Event>, selectedTabIndex: any) => { this.selectMCAERTab(e, selectedTabIndex) }}
                            initialTab={selectedTab}
                        />
                        </SizedContainer>
                        <SizedContainer as={ContentColumnWrapper} minHeightValue='575px' pixelWidth='100%' >
                            {this.renderSelectedTab(selectedTab)}
                        </SizedContainer>
                    </ContentColumnWrapper>
                    <ModalConfirmation
                        isOpen={this.getAlertOpen()}
                        alertMode
                        onModalToggle={this.resetWarnings}
                        formattedMessage={(
                            <div>
                                <p>{this.getAlertMessage()}</p>
                            </div>)
                        }
                        message=""
                        onConfirm={this.resetWarnings}
                    />
                    <ModalConfirmation
                        isOpen={this.props.dataStore.confirm.isOpen}
                        onModalToggle={this.onModalClose}
                        formattedMessage={leaveMessage}
                        okText='Leave'
                        cancelText='Stay'
                        onConfirm={this.onModalOk}/>
                </DialogWrapper>
            );
    }
}

const connectedHoc = connect<IManageClaimAssignmentsErrorRulesUIState,
    IManageClaimAssignmentsErrorRulesActionProps,
    IOwnProps,
    IManageClaimAssignmentsErrorRulesProps,
    ApplicationState>(
    createCrudMapStateToProps('manageClaimAssignmentsErrorRules'),
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ManageClaimAssignmentsErrorRules);

export default withRouter(connectedHoc);