import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '@commonResources/typography';
import { ARMHeaderCell } from '@common/UICLWrappers/ARMHeaderCell';
import { ARMRowCell } from '@common/UICLWrappers/ARMRowCell';
import { SelectComponent } from '@common/SelectComponent';
import { Grid } from '@optum-uicl/ui-core/dist';
import { Colors } from '@commonResources/colorVariables';
import _ from 'lodash';
import { ColumnType, RowType } from '@optum-uicl/ui-core/dist/Organisms/Grid/types';
import { SyntheticEvent } from 'react';

const gridDCGID = 'DropdownChoiceGrid-id'

export interface IDCG_Element {
    ID: string,
    Label: string,
    Name: string,
    Sop: string,
    Threshold: string
    
}

export interface RowProps {
    record: RecordProps
}

export interface RecordProps extends Set<RowType> {
    ID?: string,
    Label?: string,
    Name?: string,
    Sop?: string,
    Threshold?: string
}


interface Extended_IDCG_Element extends IDCG_Element,RowType {};

function isIDCG_Element(record: RowType): record is Extended_IDCG_Element {
    return typeof record.ID === 'string' &&
        typeof record.Label === 'string' &&
        typeof record.Name === 'string' &&
        typeof record.Sop === 'string' &&
        typeof record.Threshold === 'string';
}

export interface IDropdownChoiceGridProps {
    width?: string;
    height?: string;
    records: IDCG_Element[];
    selectedID: string;
    crudData?: any;
    onItemSelected(element: RecordProps | Extended_IDCG_Element): void;
    onChangeThreshold(element: any): void;
    headerLabels: HeaderLabels[];
}

export interface IDropdownChoiceGridState {
}

export interface HeaderLabels {
    label: string; 
}

export const DropdownChoiceGridContainerDiv = styled.div<Partial<IDropdownChoiceGridProps>>`
    display: flex;
    flex: 1 1 auto;
    ${(props: Partial<IDropdownChoiceGridProps>) => props.width ? `width: ${props.width}` : `width:390px`};
    ${(props: Partial<IDropdownChoiceGridProps>) => props.height ? `height: ${props.height}` : `height:200px`};
    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.defaultLineHeight};
    ${Typography.normal};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border: 1px solid #767676 ;
`;

export const DropdownChoiceGridElementDiv = styled.div`
    display: flex;
    width: 100%
    flex: 1 1 auto;
    flex-direction: row;

    .selectedRow {
        background-color: #E9F1FF !important;
        border-top: 1px solid #0C55B8;
        border-bottom: 1px solid #0C55B8;   
    }

    .unselectedRow {
        background-color: lightgrey;
        margin-left: 10px;
    }

    .row-drop-down {
        position: inherit
    }

    .Label {
        padding-left: 10px !important;
    }

    table {
        max-height: 100%;
        table-layout: fixed;
        z-index:0;
        thead {
            border-bottom: 1px solid #E5E5E6;
            tr {
                z-index: 10;
                th:last-child {
                    max-width: 95px;
                    width: 95px;
                }
            }
        }
        tbody {
            tr {
                td {
                    height: 20px !important;
                    select {
                        option {
                            text-align: right;
                        }
                    }
                    >div {
                        width: 100%;
                    }
                }
                td:first-child:not(.select-checkbox) {
                    padding-right: 0;
                    padding-left: 10px;
                    >div {
                        padding-right: 0;
                    }
                }
                td:last-child {
                    select {
                        position: static;
                    }
                }
                
            }
        }
    }

    .dcg-main {
        overflow-x: hidden
    }

    
/* 
    table tbody > tr:hover{  
        background: red;
    }
    table tbody tr:td:div:hover{  
        background: red;
    }
light grey: #f1f1f1 
white: #F2F3F4
*/
`;

export class DropdownChoiceGrid extends React.Component<IDropdownChoiceGridProps, IDropdownChoiceGridState> {
    constructor(props: IDropdownChoiceGridProps) {
        super(props)
        console.log('DropdownChoiceGrid props: ');
        console.dir(props);
    }

    public setThreshold = (e: React.ChangeEvent<HTMLSelectElement>, record: RecordProps) => {
        if (record === null) { return; }
        var selectedElement = record
        selectedElement.Threshold = e.target.value;
        if (this.props?.onChangeThreshold != null) {
            this.props.onChangeThreshold(selectedElement);
        }
        e.preventDefault();
    }

    onRowClick(e: React.MouseEvent<HTMLElement, MouseEvent>, record: RecordProps) {
        if (record === null) { return; }
        //@ts-ignore bad typescript type for EventTarget
        if (e.target.tagName==='SELECT') return;
        if (this.props.selectedID != record.ID) {
            if (this.props?.onItemSelected != null) {
                this.props.onItemSelected(record);
            }
        }
    }

    public getCustomHeader = () => {
        var specifiedWidth = this.props.width;
        if (specifiedWidth)
            specifiedWidth = specifiedWidth.replace('px', '');

        const maxLabelWidth = (Number(specifiedWidth) - 100) + 'px';
        const header = () => <thead>
            <tr>
                <ARMHeaderCell
                    style={{ whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: maxLabelWidth, minWidth: '100px' }}
                    dataName='Label'
                    sortable={false}
                    text={this.props.headerLabels[0].label}
                    cellType='text'
                />
                <ARMHeaderCell
                    style={{ whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '85px', minWidth: '85px' }}
                    dataName='Threshold'
                    sortable={false}
                    text={this.props.headerLabels[1].label}
                />
            </tr>
        </thead>
        return header;
    }


    private rowOptions = Array.from({ length: 30 }, (_, i) => i + 1).map(val => {
        const stringVal = val.toString();
        return { value: stringVal, text: stringVal }
    })

    public getCustomCDGRow() {
        const row = ({ record }: RowProps, rowIndex: number ) => {
            const PayerId = Number(record.ID);
            const rowUUID = 'ID' + PayerId
            let initVal = Number(record.Threshold);
            const rowClassName: string = this.props.selectedID === record.ID ? 'selectedRow' : 'unselectedRow';
            const rowDropdown: string = 'row-drop-down';
            //TODO: it doesn't look like these inline styles are doing anything
            return (<tr id={'row-' + rowUUID} className={rowClassName} key={'row-' + rowUUID} onClick={(e: any) => this.onRowClick(e, record)}>
                {
                    <React.Fragment>
                        <ARMRowCell style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', height: '20px', paddingTop: '3px',  width: '100%', maxWidth: '100%', minWidth: '100px' }}
                            value={record.Label} title={record.Label} />
                        <td align='center'>
                            <SelectComponent
                                width={'50px'}
                                height={'20px'}
                                className={rowDropdown}
                                size={1}
                                selectedValue={initVal.toString()}
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.setThreshold(e, record)}
                                records={this.rowOptions}
                            />
                        </td>
                    </React.Fragment>
                }
            </tr>
            )
        }
        return row;
    }



    public render() {
        const row = this.getCustomCDGRow();
        const header = this.getCustomHeader();
        const columns = new Set([
            { dataName: 'Label', text: 'Payer', cellType: 'text', sortable: true },
            { dataName: 'Threshold', text: 'Threshold', cellType: 'text', sortable: true },
        ])
        var specifiedWidth = this.props.width;
        if (specifiedWidth)
            specifiedWidth = specifiedWidth.replace('px', '');
        const classNameDCG = 'dcg-main';
        return (
            <DropdownChoiceGridContainerDiv height={this.props.height}>
                <DropdownChoiceGridElementDiv style={{width: `${specifiedWidth}`}}>
                    <Grid
                        key={gridDCGID}
                        className={classNameDCG}
                        domID={gridDCGID}
                        // style={{ overflowX: 'hidden' }}  ---already there in LINE-147 UNDER classNameDCG
                        maxHeight={this.props.height}
                        // size={specifiedWidth}   ---added in DropdownChoiceGridElementDiv using width property of CSS
                        columns={columns  as Set<ColumnType>} 
                        isFixedHeader
                        // sortable      ---added in columns in line 262, 263
                        headerComponent={header}
                        // TODO: UICL Breaking Change
                        rowComponent={row}
                        records={this.props.records}
                        // scrollToTop={false} ---Breaking Change (property not available anymore)
                        selectionKey={'ID'}
                        emptyGridMessage=''
                        onClickThrough={(e: SyntheticEvent<Element, Event>, record: RowType) => { 
                            if (isIDCG_Element(record)) {
                                this.props.onItemSelected(record); 
                            } else {
                                console.warn('Record does not corform to IDCG_Element: ', record);
                            }
                        }}
                    /*initialSortingKey='PayerName|SORT_ASCENDING' - This breaks custom sorting and must be removed to enable it*/
                    />
                </DropdownChoiceGridElementDiv>
            </DropdownChoiceGridContainerDiv>
        );
    };

}
