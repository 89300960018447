import * as React from 'react';
import { RouteComponentProps, Prompt, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindAll } from 'lodash';
import styled from 'styled-components';
import { Location } from 'history';
import { Input } from '@optum-uicl/ui-core/dist';
import { SelectDropdown, Button, Tabs } from '@optum-uicl/ui-core/dist';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { OKCancelButtons, DialogWrapper, ContentColumnWrapper, ContentRowWrapper } from '@common/DialogWrapper';
import { ModalConfirmation, leaveMessage } from '@common/ModalConfirmation';
import { SelectComponent } from '@common/SelectComponent';
import { RadioButtonComponent } from '@common/RadioButton';
import { CheckBoxComponent } from '@common/CheckBox';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { IFormModalState, defaultFormModalState } from '../../../store/ui/BaseCrudUI';
import { ApplicationState } from '@store/index';
import { IInternalUserUIState, IInternalUserActionProps, actionCreators, validationCallback } from '@store/InternalUser';
import { SizedContainer, ArmTabContainer } from '@components/common/component-styles';
import { getRawToken } from '@scripts/session/SecurityToken';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';
import CrudTypes from '@commonResources/CrudTypes';

export const ContentContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    font-size: 11px;
    width: 1049px;

    #SelectComponent {
        padding-left:5px !important;

}`

interface IContentWrapperProps {
    height?: string;
}

enum eModifyButtonType {
    Add,
    Update
}

const CheckBoxWrapper = styled.div`
    float: left;
    width: 'fit-content'; 
    marginBottom: 10px;
    marginTop: 10px;

    div .arm_checkbox_label {
        padding-left: 4px;
    }
`;


const ContentWrapper = styled.div<IContentWrapperProps>`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: ${(p) => `${p.height}`};

    #general-type_wrapper {
        padding-right: 20px;
    }
`;

interface ITabItem {
    label: string;
    domID: string;
}


interface IDropDownItem {
    label: string,
    value: string,
}

interface IComponentProps {
    canCreate: boolean;
    canView: boolean;
    canDelete: boolean;
    canEdit: boolean;
};

interface ISelectedUser {
    isDirty: boolean;
    updated?: boolean;
    removed?: boolean;
    added?: boolean;
    userType: 'support' | 'outsource';
    id: string;
    lastName: string;
    firstName: string;
    loginName: string;
    extension: string;
    password: string;
    status: string;
    lastLogonDate: string;
    interactive: string;
    PWExpiring: string;
    failedAttempts: string;
    itemName?: string;
    itemValue?: string;
    confirmedPassword: string;
    email: string;
    displayName: string;
    featurePreview?: string;
}


type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IInternalUserProps = IMergeCrudComponentProps<IInternalUserUIState, IInternalUserActionProps, IOwnProps>;

interface ISelectionOption {
    label: string;
    value: string;
}

export interface IComponentState extends IFormModalState {
    tabIndex: number,
    cancelLeave: boolean,
    canCreate: boolean,
    canView: boolean,
    canEdit: boolean,
    canDelete: boolean
}

export class InternalUser extends React.PureComponent<IInternalUserProps, IComponentState> {
    constructor(props: IInternalUserProps) {
        super(props);
        this.state = { ...defaultFormModalState, tabIndex: 0, cancelLeave: false, canCreate: false, canView: false, canEdit: false, canDelete: false };
        this.props.dataStore.crud.redirectURL = undefined;
        // bindAll(this, ['onFormatChange', 'onDelimiterChange', 'onQualifierChange', 'showRouteChangeConfirm']);
    }
    // define only once, instead of on every render
    private instructions = <React.Fragment> The right to use a feature is controlled by a combination of groups and users. Use this page to add or remove a user and to update information for existing users. * indicates a required field. ** indicates a required field only for new users. </React.Fragment>;
    private tabList: ITabItem[] = [{ label: 'Support', domID: 'id-Support' }, { label: 'Outsource', domID: 'id-Outsoruce' }];

    public componentDidMount() {
        pageLeave();
        this.props.action.ui.initializeIntUserState();
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public removeUnmodifiedUsersFromCrudEbo() {
        let crud = this.props.dataStore.crud;
        let modifiedUserList = this.props.dataStore.ui.internalUsers.filter((item) => {
            return item.updated || item.removed || item.added;
        });
        let modifiedCrudEboUserList: MCInternalUser[] = [];
        if (crud && crud.data) {
            if (this.props.dataStore.ui.internalUsers && this.props.dataStore.ui.internalUsers.length > 0) {
                if (modifiedUserList && modifiedUserList.length > 0) {
                    for (var j = modifiedUserList.length - 1; j >= 0; j--) {
                      if (modifiedUserList[j].userType !== 'support') {
                        let modifiedCrudUserInInternal: any | undefined = this.props.dataStore.ui.internalUsers.find(ele => ele.id === modifiedUserList[j].id);
                        let modifiedCrudUser: MCInternalUser | undefined;
                        if (modifiedCrudUserInInternal) {
                            modifiedCrudUser = {
                                "@ID": modifiedCrudUserInInternal.id,
                                "@FirstName": modifiedCrudUserInInternal.firstName,
                                "@LastName": modifiedCrudUserInInternal.lastName,
                                "@Password": modifiedCrudUserInInternal.password,
                                "@LoginName": modifiedCrudUserInInternal.loginName,
                                "@Extension": modifiedCrudUserInInternal.extension,
                                "@Email": modifiedCrudUserInInternal.email,
                                "@Interactive": modifiedCrudUserInInternal.interactive,
                                "@Status": modifiedCrudUserInInternal.status,
                                "@UserType": modifiedCrudUserInInternal.userType,
                                "@Delete": modifiedCrudUserInInternal.removed ? "true" : "false",
                                "@FeaturePreview": modifiedCrudUserInInternal.featurePreview,
                                "@ItemName": modifiedCrudUserInInternal.itemName,
                                "@ItemValue": modifiedCrudUserInInternal.itemvalue,
                                "@ClaimViewer": "",
                                "@ClientSetId": "",
                                "@UserRoleId": "",
                                "@ConfirmPassword": "",
                            }
                        }
                        if ((modifiedUserList[j].updated || modifiedUserList[j].removed) && modifiedCrudUser) {
                            
                            modifiedCrudUser["@ID"] = modifiedUserList[j].id;
                            modifiedCrudUser["@FirstName"] = modifiedUserList[j].firstName;
                            modifiedCrudUser["@LastName"] = modifiedUserList[j].lastName;
                            modifiedCrudUser["@Password"] = modifiedUserList[j].password;
                            modifiedCrudUser["@LoginName"] = modifiedUserList[j].loginName;
                            modifiedCrudUser["@Extension"] = modifiedUserList[j].extension;
                            modifiedCrudUser["@Email"] = modifiedUserList[j].email;
                            modifiedCrudUser["@Interactive"] = modifiedUserList[j].interactive;
                            modifiedCrudUser["@Status"] = modifiedUserList[j].status;
                            modifiedCrudUser["@UserType"] = modifiedUserList[j].userType;
                            modifiedCrudUser["@FeaturePreview"] = modifiedUserList[j].featurePreview;
                            modifiedCrudUser["@ItemName"] = modifiedUserList[j].itemName;
                            modifiedCrudUser["@ItemValue"] = modifiedUserList[j].itemValue;
                            if (modifiedUserList[j].removed)
                                modifiedCrudUser["@Delete"] = "true";
							//ASRN-4485 Chrome/Edge Internal User Maint - updating existing support user invalidates password 
							if (modifiedUserList[j].password == "")
                                delete (modifiedCrudUser["@Password"]);
                            modifiedCrudEboUserList.push(modifiedCrudUser);
                        }
                        else if (modifiedUserList[j].added) {
                            modifiedCrudUser = {
                                "@ID": "New[" + j + "]",
                                "@FirstName": modifiedUserList[j].firstName,
                                "@LastName": modifiedUserList[j].lastName,
                                "@Password": modifiedUserList[j].password,
                                "@LoginName": modifiedUserList[j].loginName,
                                "@Extension": modifiedUserList[j].extension,
                                "@Email": modifiedUserList[j].email,
                                "@Interactive": modifiedUserList[j].interactive,
                                "@Status": modifiedUserList[j].status,
                                "@ClaimViewer": "",
                                "@ClientSetId": "",
                                "@ItemName": modifiedUserList[j].itemName,
                                "@ItemValue": modifiedUserList[j].itemValue,
                                "@UserRoleId": "",
                                "@UserType": modifiedUserList[j].userType,
                                "@FeaturePreview": modifiedUserList[j].featurePreview,
                                "@ConfirmPassword": "",

                            }
                            modifiedCrudEboUserList.push(modifiedCrudUser);
                        }
					  }
                    }
                }
            }

            return modifiedCrudEboUserList;

        }
    }

    public removeUnmodifiedUsersFromCrudNdc() {
        let crud = this.props.dataStore.crud;
        let modifiedUserList = this.props.dataStore.ui.internalUsers.filter((item) => {
            return item.updated || item.removed || item.added;
        });
        let modifiedCrudNdcUserList: MCInternalUser[] = [];
        if (crud && crud.data) {
            if (this.props.dataStore.ui.internalUsers && this.props.dataStore.ui.internalUsers.length > 0) {
                if (modifiedUserList && modifiedUserList.length > 0) {
                    for (var j = modifiedUserList.length - 1; j >= 0; j--) {
                      if (modifiedUserList[j].userType === 'support') {
                        let modifiedCrudUserInInternal: any | undefined = this.props.dataStore.ui.internalUsers.find(ele => ele.id === modifiedUserList[j].id);
                        let modifiedCrudUser: MCInternalUser | undefined;
                        if (modifiedCrudUserInInternal) {
                            modifiedCrudUser = {
                                "@ID": modifiedCrudUserInInternal.id,
                                "@FirstName": modifiedCrudUserInInternal.firstName,
                                "@LastName": modifiedCrudUserInInternal.lastName,
                                "@Password": modifiedCrudUserInInternal.password,
                                "@LoginName": modifiedCrudUserInInternal.loginName,
                                "@Extension": modifiedCrudUserInInternal.extension,
                                "@Email": modifiedCrudUserInInternal.email,
                                "@Interactive": modifiedCrudUserInInternal.interactive,
                                "@Status": modifiedCrudUserInInternal.status,
                                "@UserType": modifiedCrudUserInInternal.userType,
                                "@Delete": modifiedCrudUserInInternal.removed ? "true" : "false",
                                "@FeaturePreview": modifiedCrudUserInInternal.featurePreview,
                                "@ItemName": modifiedCrudUserInInternal.itemName,
                                "@ItemValue": modifiedCrudUserInInternal.itemvalue,
                                "@ClaimViewer": "",
                                "@ClientSetId": "",
                                "@UserRoleId": "",
                                "@ConfirmPassword": "",
                            }
                        }
                        if ((modifiedUserList[j].updated || modifiedUserList[j].removed) && modifiedCrudUser) {
                            
                            modifiedCrudUser["@ID"] = modifiedUserList[j].id;
                            modifiedCrudUser["@FirstName"] = modifiedUserList[j].firstName;
                            modifiedCrudUser["@LastName"] = modifiedUserList[j].lastName;
                            modifiedCrudUser["@Password"] = modifiedUserList[j].password;
                            modifiedCrudUser["@LoginName"] = modifiedUserList[j].loginName;
                            modifiedCrudUser["@Extension"] = modifiedUserList[j].extension;
                            modifiedCrudUser["@Email"] = modifiedUserList[j].email;
                            modifiedCrudUser["@Interactive"] = modifiedUserList[j].interactive;
                            modifiedCrudUser["@Status"] = modifiedUserList[j].status;
                            modifiedCrudUser["@UserType"] = modifiedUserList[j].userType;
                            modifiedCrudUser["@FeaturePreview"] = modifiedUserList[j].featurePreview;
                            modifiedCrudUser["@ItemName"] = modifiedUserList[j].itemName;
                            modifiedCrudUser["@ItemValue"] = modifiedUserList[j].itemValue;
                            if (modifiedUserList[j].removed)
                                modifiedCrudUser["@Delete"] = "true";
							//ASRN-4485 Chrome/Edge Internal User Maint - updating existing support user invalidates password
							if (modifiedUserList[j].password == "")
                                delete (modifiedCrudUser["@Password"]);
                            modifiedCrudNdcUserList.push(modifiedCrudUser);
                        }
                        else if (modifiedUserList[j].added) {
                            modifiedCrudUser = {
                                "@ID": "New[" + j + "]",
                                "@FirstName": modifiedUserList[j].firstName,
                                "@LastName": modifiedUserList[j].lastName,
                                "@Password": modifiedUserList[j].password,
                                "@LoginName": modifiedUserList[j].loginName,
                                "@Extension": modifiedUserList[j].extension,
                                "@Email": modifiedUserList[j].email,
                                "@Interactive": modifiedUserList[j].interactive,
                                "@Status": modifiedUserList[j].status,
                                "@ClaimViewer": "",
                                "@ClientSetId": "",
                                "@ItemName": modifiedUserList[j].itemName,
                                "@ItemValue": modifiedUserList[j].itemValue,
                                "@UserRoleId": "",
                                "@UserType": modifiedUserList[j].userType,
                                "@FeaturePreview": modifiedUserList[j].featurePreview,
                                "@ConfirmPassword": "",

                            }
                            modifiedCrudNdcUserList.push(modifiedCrudUser);
                        }
					  }
                    }
                }
            }

            return modifiedCrudNdcUserList;

        }
    }

    // for some reason manual bind and bindAll did not work with these functions, so using alternate syntax
    public onDialogOk = () => {
        let modifiedCrudEboUserList: any = undefined; modifiedCrudEboUserList = this.removeUnmodifiedUsersFromCrudEbo();
        let modifiedCrudNdcUserList: any = undefined; modifiedCrudNdcUserList = this.removeUnmodifiedUsersFromCrudNdc();

        let xhr = new XMLHttpRequest();
        xhr.open("POST", "/api/data/ciam/internalUserMaintenanceJson", false);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Authorization", getRawToken());
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status !== 200) {
                    let errorMessage = JSON.parse(JSON.parse(xhr.responseText).message);
                    if (errorMessage) {
                        if (!errorMessage.Successful) {
                            alert("Unable to update user: " + errorMessage.errorMessages[0]);
                        }
                    }
                    console.log(xhr.responseText);
                    return false;
                } else {
                    console.log(xhr.responseText);
                }
            }
        }
        let payload = JSON.stringify(
            {
                UserMaintenance: {
                    NdcUserMaintenanceInfo: {
                        Users: {
                            User: JSON.parse(JSON.stringify(modifiedCrudNdcUserList))
                        }
                    },
                    EBOUserMaintenanceInfo: {
                        Users: {
                            User: JSON.parse(JSON.stringify(modifiedCrudEboUserList))
                        }
                    }
                }
            });
        console.log("PayloadData", payload);
        xhr.send(payload);
        if (modifiedCrudEboUserList.length > 0) {
        this.props.action.crud.update({
            crudId: CrudTypes.mctiOutsourceUserMaster, data: {
                UserMaintenance: {
                    EBOUserMaintenanceInfo: {
                        Users: {
                            User: JSON.parse(JSON.stringify(modifiedCrudEboUserList))
                        }
                    }
                }
            }
        });
                        }
        if (modifiedCrudNdcUserList.length > 0) {
        this.props.action.crud.update({
            crudId: CrudTypes.mctiNdcUserMaster, data: {
                UserMaintenance: {
                        NdcUserMaintenanceInfo: {
                    Users: {
                        User: JSON.parse(JSON.stringify(modifiedCrudNdcUserList))
                    }
                }
            }
            }
        });
                        }
        this.props.history.push('/LandingPage');
    }

    public onDialogCancel = (e: React.ChangeEvent<HTMLButtonElement>) => {
        // This will trigger the prompt to open the modal
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    public onModalOk = () => {
        //this.setState({ navigationConfirmed: true, saveOnNavigate: false }, () => {
        //    this.props.history.push(this.state.navDestination?.pathname || '/LandingPage');
        //});
    }

    public onModalClose = () => {
        //this.setState({ navigationConfirmed: false, saveOnNavigate: false }, () => {
        //    this.props.action.confirm.closeConfirm();
        //});
    }


    isBusy = () => {
        //    return (this.props.dataStore.crud.dataStatus === 'REQUEST');
    }

    private okCancelButtons = <OKCancelButtons onClickOK={this.onDialogOk} onClickCancel={this.onDialogCancel} />;


    showRouteChangeConfirm(nextLocation: Location) {
        this.setState({ saveOnNavigate: false, navDestination: nextLocation },
            () => {
                this.props.action.confirm.openConfirm({ message: '' });
            }
        );
        //suppresses native prompt modal
        return false;
    }

    public renderUserDataInputs(selectedUser: any) {
        const { tabSelectionState } = this.props.dataStore.ui;
        const inputDataArray = [{ key: 'loginName', text: (tabSelectionState === 'support' ? '*Support' : '*Outsource') + ' User Name:', value: selectedUser.loginName, isUpper: false, maxLength: 12, type: "text", errorData: this.props.dataStore.ui.loginName_userError },
        { key: 'firstName', text: '*First Name:', value: selectedUser.firstName, isUpper: true, maxLength: 25, type: "text", errorData: this.props.dataStore.ui.firstName_userError },
        { key: 'lastName', text: '*Last Name:', value: selectedUser.lastName, isUpper: true, maxLength: 35, type: "text", errorData: this.props.dataStore.ui.lastName_userError },
        { key: 'password', text: '**Password', value: selectedUser.password, isUpper: false, maxLength: 64, type: "password", errorData: this.props.dataStore.ui.password_userError },
        { key: 'confirmedPassword', text: '**Confirm Password', value: selectedUser.confirmedPassword, isUpper: false, maxLength: 64, type: "password", errorData: this.props.dataStore.ui.confirmPassword_userError },
            { key: 'extension', text: 'Extension:', value: selectedUser.extension, isUpper: false, maxLength: 5, type: "text" },
            { key: 'email', text: 'Email: ', value: selectedUser.email, isUpper: false, maxLength: 100, type: "text", errorData: this.props.dataStore.ui.email_userError },
        ]
        return (
            <ContentColumnWrapper style={{ width: '400px' }}>
                {inputDataArray.map((inputData, index) => {
                    return (
                        <Input
                            label={inputData.text}
                            domID={inputData.text + index}
                            maxLength={inputData.maxLength}
                            type={inputData.type}
                            initialValue={inputData.value}
                            className="text-input"
                            autoComplete="new-password"
                            hasError={(inputData.errorData && inputData.errorData !== "") ? true : false}
                            errorMessage={inputData.errorData}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.props.action.ui.updateTextInput({
                                    id: selectedUser.id,
                                    attributeName: inputData.key,
                                    text: inputData.isUpper ? e.target.value.toUpperCase() : e.target.value
                                })
                            }
                            }

                        />
                    )
                })}
                <CheckBoxWrapper style={{ marginTop: '5px', marginBottom: '5px' }}>
                    <CheckBoxComponent
                        width='100%'
                        greyText={true}
                        id="interactive-user"
                        labelBefore='INTERACTIVE USER:'
                        lablePadding='0px'
                        checked={selectedUser.interactive === '1' ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            this.props.action.ui.updateInteractiveState({ userId: selectedUser.id, isNew: selectedUser.isNew, interactionStatus: selectedUser.interactive === '1' ? '0' : '1' })
                        }}
                        disabled={false}
                    />
                </CheckBoxWrapper>
                {tabSelectionState === 'support' ?
                    <SelectDropdown domID="select-feature-preview" label="Feature Preview" isClearable={false} onChange={(e: any) => this.updateSelectPreview(e.value)}
                        options={this.getFeaturePreviews()}
                        initialValue={this.getSelectedFeaturePreview(this.props.dataStore.ui.selectedUser.featurePreview)} /> : null}
            </ContentColumnWrapper>
        )
    }

    public updateSelectPreview(feature: string) {

        this.props.action.ui.updateFeaturePreview({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: feature }
        });
    }

    public getFeaturePreviews() {
        let featurePreviews: IDropDownItem[] = [
            {
                label: '- Select Feature -',
                value: ''
            },
            {
                label: 'CFI Legacy Plus',
                value: 'Legacy+',
            },
            {
                label: 'CFI Legacy Only',
                value: 'Legacy',
            }
        ];

        return featurePreviews;
    }

    public getSelectedFeaturePreview(idToFind: string | undefined): IDropDownItem {
        let result: IDropDownItem = { value: '', label: '- Select Feature -' };

        let features: IDropDownItem[] = this.getFeaturePreviews();

        if (!this.props.dataStore.crud.data || !this.props.dataStore.ui.internalUsers ||
            !this.props.dataStore.ui.internalUsers) {
            return result;
        }

        // Assign Default User Role
        result.label = features[0].label;
        result.value = features[0].value;


        let selectedUser = this.props.dataStore.ui.internalUsers.find(usr =>
            usr.id === this.props.dataStore.ui.selectedUser.id);

        if (selectedUser && this.props.dataStore.ui.selectedUser.itemName === selectedUser.itemName &&
            this.props.dataStore.ui.selectedUser.itemName === "CFI_Mode") {

            if (this.props.dataStore.ui.selectedUser.itemValue === "Legacy") {
                result.label = features[2].label;
                result.value = features[2].value;
            } else if (this.props.dataStore.ui.selectedUser.itemValue === "Legacy+") {
                result.label = features[1].label;
                result.value = features[1].value;
            } else {
                result.label = features[0].label;
                result.value = features[0].value;
            }
        } else {
            if (idToFind) {
                let id = features.find(fet => fet.label === idToFind);
                if (id) {
                    result.label = id.label;
                    result.value = id.value;
                }
            }
        }

        return result;
    }

    public renderAccountStatusInputs(selectedUser: any) {
        return (
            <ContentContainer style={{ height: '140px', width: '100%' }}>
                <ContentRowWrapper>
                    <RadioButtonComponent
                        id="enabled-type"
                        label="Enabled"
                        checked={selectedUser.status === '0'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.setInternalUserAccountStatus({ userId: selectedUser.id, status: '0' })}
                        disabled={false}
                    />
                    <RadioButtonComponent
                        id="locked-type"
                        label="Locked Out"
                        checked={selectedUser.status === '1'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.setInternalUserAccountStatus({ userId: selectedUser.id, status: '1' })}
                        disabled={false}
                    />
                    <RadioButtonComponent
                        id="disabled-type"
                        label="Disabled"
                        checked={selectedUser.status === '2'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.setInternalUserAccountStatus({ userId: selectedUser.id, status: '2' })}
                        disabled={false}
                    />
                </ContentRowWrapper>
                <ContentColumnWrapper>
                    <ContentRowWrapper style={{ flex: '1', position: 'relative', flexGrow: 'unset', right: '25px', justifyContent: 'space-around', flexDirection: 'row' }}>
                        <div><p style={{ marginBottom: '0px' }}>Failed attempts: {selectedUser.failedAttempts}</p></div>
                        <div><p style={{ marginBottom: '0px' }}>Password expring In: {selectedUser.PWExpiring}</p></div>
                    </ContentRowWrapper>
                    <ContentRowWrapper style={{ flex: '1', position: 'relative', flexGrow: 'unset', right: '25px', flexDirection: 'row', marginLeft: '45px' }}>
                        <div><p style={{ marginBottom: '0px' }}>Last logged in: {selectedUser.lastLogonDate}</p></div>
                    </ContentRowWrapper>
                    <ContentRowWrapper style={{ flex: '1', position: 'relative', flexGrow: 'unset', right: '25px', flexDirection: 'row', marginLeft: '45px' }}>
                        <div><p style={{ marginBottom: '0px' }}>Filter profile: {selectedUser['@']}</p></div>
                    </ContentRowWrapper>
                    <ContentRowWrapper style={{ flex: '1', position: 'relative', flexGrow: 'unset', right: '25px', flexDirection: 'row', marginLeft: '45px' }}>
                        <div><p style={{ marginBottom: '0px' }}> Report profile: {selectedUser['@']}</p></div>
                    </ContentRowWrapper>
                </ContentColumnWrapper>
            </ContentContainer>
        )
    }

    public onClickConfirmUndeleteUser(user: MCInternalUser) {
        this.props.action.ui.restoreUser({
            masterCrud: this.props.dataStore.crud.data,
            uiData: user
        });
    }

    public isExecuted: boolean = false;

    public getModifyButtonType(): eModifyButtonType {
        return (this.props.dataStore.ui.selectedUser.id == 'default') ? eModifyButtonType.Add : eModifyButtonType.Update;
    }

    public getModifyButtonText(): string {
        return this.getModifyButtonType() == eModifyButtonType.Add ? 'Add' : 'Update';
    }

    public isRemoveButtonDisabled(): boolean {
        return (this.props.dataStore.ui.selectedUser.id == 'default' || !this.props.canDelete);
    }

    public renderContent() {
        const { selectedUser, internalUsers, tabSelectionState } = this.props.dataStore.ui;
        const usersWithDefault = internalUsers.filter(user => user.userType === tabSelectionState);
        let users = [];
        users = !this.props.canCreate ? usersWithDefault.slice(1) : usersWithDefault;
        if (!this.isExecuted) {
            if (users !== undefined && users.length > 1) {
                this.props.action.ui.selectInternalUser(users[0].id)
                this.isExecuted = true;
            }

        }
        let isAddUserEnabled: boolean = this.getModifyButtonType() === eModifyButtonType.Add && !this.props.canCreate ? true : false;
        let isUpdateUserEnabled: boolean = this.getModifyButtonType() === eModifyButtonType.Update && !this.props.canEdit ? true : false;
        return (
            <ContentWrapper id='content-wrapper' style={{ flex: '1', alignItems: 'start', flexDirection: 'column' }}>
                <ContentRowWrapper style={{ marginLeft: '15px', width: '10%', marginTop: '10px' }} id='select-row' >
                    <SelectComponent
                        label='Users'
                        size={20}
                        width='200px'
                        height={this.props.dataStore.ui.tabSelectionState === "outsource" ? '550px' : '602px'}
                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            this.props.action.ui.selectInternalUser(e.target.value)
                        }}
                        optionFields={{
                            value: "id",
                            text: "displayName",
                        }}
                        records={[...users.filter(user => !user.removed)]}
                        selectedValue={selectedUser.id}
                        autoScrollToSelection={true}
                    />
                    <ContentColumnWrapper id='inputs' style={{ flex: '1', position: 'relative', left: '30%' }}>
                        {this.renderUserDataInputs(selectedUser)}
                        <ContentRowWrapper>
                            <DialogFieldset style={{ marginLeft: '0px', width: '400px' }}>
                                <DialogLegend>Account Status</DialogLegend>
                                {this.renderAccountStatusInputs(selectedUser)}
                            </DialogFieldset>
                        </ContentRowWrapper>
                        <ContentRowWrapper style={{ justifyContent: 'center', marginTop: '10px' }}>
                            <div id='add-button-wrapper' style={{ marginRight: '10px' }}>
                                <Button
                                    domID="add-id"
                                    name={selectedUser.id === 'default' ? 'ADD' : 'UPDATE'}
                                    buttonType="standard"
                                    type="button"
                                    onClick={(e: React.SyntheticEvent) => {
                                        handleChange();
                                        if (selectedUser.id === 'default') {
                                            this.props.action.ui.addInternalUser({ userInfo: selectedUser })

                                        } else {
                                            this.props.action.ui.updateInternalUser(selectedUser.id)
                                        }
                                    }
                                    }
                                    disabled={(isAddUserEnabled || isUpdateUserEnabled)}
                                />
                            </div>
                            <Button
                                domID="remove-id"
                                name="REMOVE"
                                size="small"
                                buttonType="standard"
                                type="button"
                                onClick={(e: React.SyntheticEvent) => this.onClickRemoveUser(e)}
                                disabled={this.isRemoveButtonDisabled()}    
                            />
                        </ContentRowWrapper>
                    </ContentColumnWrapper>
                </ContentRowWrapper>
            </ContentWrapper>
        )
    }

    public onClickRemoveUser(e: React.SyntheticEvent) {
        this.props.action.confirm.openConfirm(() => this.onClickConfirmRemoveUser());
    }

    public onClickConfirmRemoveUser() {
        handleChange();
        this.props.action.ui.removeInternalUser({ userId: this.props.dataStore.ui.selectedUser.id })
    }

    public onToggleConfirmation(e: React.MouseEvent<HTMLElement>) {
        this.props.action.confirm.closeConfirm();
    }

    public onRemoveUser(e: React.MouseEvent<HTMLButtonElement>) {
        this.onClickConfirmRemoveUser();
    }

    public render() {
        const { tabIndex } = this.state;
        const { isDirty, tabSelectionState } = this.props.dataStore.ui;
        if (this.props.dataStore.crud.redirectURL !== undefined) {
            return (<Redirect to={{ pathname: this.props.dataStore.crud.redirectURL, state: { from: this.props.location } }} />);
        }
        return (
            <DialogWrapper
                width='650px'

                title='User Maintenance'
                instruction={this.instructions}
                helpUrl='/Support/Help/SUP_Maint_Users.htm'
                buttons={this.okCancelButtons}
                isBusy={this.props.dataStore.ui.isBusy}
            >
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <Prompt when={isDirty && !this.state.navigationConfirmed} message={this.showRouteChangeConfirm} />
                <ContentColumnWrapper id='main-wrapper'>
                    <ContentContainer id='tab-container-wrapper' style={{ width: 'inherit' }}>
                        <SizedContainer as={ArmTabContainer} tabs={this.tabList} percentWidth='100%' >
                            <Tabs tabs={this.tabList}
                                onTabSelect={() => {
                                    this.setState({ tabIndex: this.state.tabIndex === 0 ? 1 : 0 }, () => {
                                        this.props.action.ui.setTabSelectionState(this.state.tabIndex === 0 ? 'support' : 'outsource');
                                        const usersWithDefault = this.props.dataStore.ui.internalUsers.filter(user => user.userType === (this.state.tabIndex === 0 ? 'support' : 'outsource'));
                                        let users: string | any[] | undefined = [];
                                        if (!this.props.canCreate) {
                                            users = usersWithDefault.slice(1);
                                        }
                                        if (users !== undefined && users.length > 1) {
                                            this.props.action.ui.selectInternalUser(users[0].id);
                                        }
                                    })
                                }}
                    
                                initialTab={tabIndex} />
                        </SizedContainer>
                        <ContentColumnWrapper style={{ marginTop: "0px" }}>
                            {this.renderContent()}
                        </ContentColumnWrapper>
                    </ContentContainer>
                </ContentColumnWrapper>
                <ModalConfirmation
                    isOpen={this.props.dataStore.confirm.isOpen}
                    onModalToggle={this.onModalClose}
                    formattedMessage={leaveMessage}
                    onConfirm={this.onModalOk}
                />
                <ModalConfirmation
                    isOpen={this.props.dataStore.confirm.isOpen}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleConfirmation(e)}
                    formattedMessage={(
                        <div>
                            <p>Do you want to remove the user - <b>{this.props.dataStore.ui.selectedUser.displayName}</b>?</p>
                            <p>Deleting the user will remove the user name from the following lists:</p>
                            <p>* Favorite filters (User and Manager assigned)</p>
                            <p>* All Claim Assignments</p>
                            <p>* All Restrict User Access Profiles</p>
                            <p>* All User Preferences</p>
                            <p>This action cannot be undone</p>
                        </div>)
                    }
                    message={this.props.dataStore.confirm.message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onRemoveUser(e)}
                />
            </DialogWrapper>);
    }
}


const connectedHoc = connect<IInternalUserUIState,
    IInternalUserActionProps,
    IOwnProps,
    IInternalUserProps,
    ApplicationState>(
        createCrudMapStateToProps('internalUser'),
        createCrudMapDispatchToProps(actionCreators),
        mergeCrudComponentProps
    )(InternalUser);

export default withRouter(connectedHoc);