import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Input, Button, Tabs, SelectDropdown, Checkbox, TextStyles } from '@optum-uicl/ui-core/dist';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons, ContentColumnWrapper, ContentRowWrapper } from '@common/DialogWrapper';
import { DialogLegend, DialogFieldset, DialogLabel } from '@common/DialogStyles';
import { SelectComponent } from '@common/SelectComponent';
import { ArrowButtons } from '@common/ArrowButtons';
import { UpDownArrowButtons } from '@common/UpDownArrowButtons';
import { UpDownArrowButtonsRow } from '@common/UpDownArrowButtonsRow';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { DaysOfWeekCheckBoxComponent } from '@common/DateOrTimeRelated/DaysOfWeekCheckboxes';
import { CheckBoxComponent } from '@common/CheckBox';
import { TimeScheduleComponent } from '@common/DateOrTimeRelated/TimeSchedule';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IUserPreferencesState, IUserPreferencesActionProps, actionCreators, validationCallback } from '@store/UserPreferences';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { defaultState as defaultUIState } from '@store/ui/UserPreferencesUI';
import { ICookies_Config } from '@store/ConfigData';
import { handleChange, pageLeave } from '@commonResources/userModified';
import { SelectDropdownValue } from '@optum-uicl/ui-core/dist/Atoms/SelectDropdown/types';



export const ContentWrapper = styled.div`
    margin-top: 10px;
    padding: 5px;

    #userPrefs-scheduled-notifications{
        width: 455px;
    }
`;

export const ImmediateActions = styled.div`
    width: 495px;
    height: 30px;
    padding-top: 10px;
    padding-bottom: 35px;
    margin-left: -15px;
`;

export const ScheduleWrapper = styled.div`
    width: 520px;

    label {
        font-size: 11px !important;
    }
    #userPrefs-daysofweek_label{
        padding-top: 22px;
        padding-left:20px;
        font-size: 13.33px !important;
        font-weight: 500;
    }
`;

export const LabelWrapper = styled.div`
    margin-top: -3px;
`;

export const UpDownArrowButtonsWrapper = styled.div`
    padding-left: 150px;
`;

export const UpDownArrowButtonsWrapper2 = styled.div`
    padding-top: 30px;
`;

export const NotificationContentWrapper = styled.div`
    padding-top: 5px;
    padding-left: 10px;
`;

export const MCAWrapper = styled.div`
    width: 411px;

    #unrel-claims-assign-dropdown{
      width: 325px;
    }

    #rel-claims-assign-dropdown{
      width: 325px;
    }

    #user-claims-assign-dropdown{
      width: 325px;
    }
    
`;

export const FavoriteFiltersWrapper = styled.div`
 
`;

export const UserPrefsWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 965px;

    button label {
        text-transform: uppercase;
        max-width: 100%
    }
`;

const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 6px;
    margin-left: 40px;
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

export const UserPrefsErrorMessage = styled.span`
  width: 400px;
  display: block;
  color: ${Colors.digitalRed130};
  ${TextStyles.xSmall};
`;

export const DEFAULT_STATE: IComponentState = {};

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    title: string,
    homePageMaxFilters: number,
    userId: number
};

interface tabArr {
    label: string;
    domID: string;
}

interface IComponentState {
}

interface IDispatchProps {
}

interface IClaimSortTypeItem {
    label: string,
    value: string,
}

enum SortConfigurationTypes {
    Released,
    Unreleased,
    User
}

const scheduledNotificationOptions = [
    { value: '0', label: 'Disable' },
    { value: '1', label: 'Enabled: Send an email at the scheduled time' },
    { value: '2', label: 'Enabled: Only send an email if the assignments have changed' },
];



type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IUserPreferencesProps = IMergeCrudComponentProps<IUserPreferencesState, IUserPreferencesActionProps, IOwnProps>;

export class UserPreferences extends React.Component<IUserPreferencesProps, IComponentState> {
    static addScheduleItemCount: number = 0;

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: true, //todo: no view bit?
        homePageMaxFilters: 3,
        userId: 0,
        title: 'User Preferences'
    };

    constructor(props: IUserPreferencesProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }


    public componentDidUpdate() {

        if (this.props.dataStore.crud.data && !this.props.dataStore.ui.deltaCrudInitialized) {
            this.props.action.ui.setupUserPreferences({ masterCrud: this.props.dataStore.crud.data });
        }
    }

    public componentDidMount() {
        //everyone gets to see this by default
        pageLeave();
        this.props.action.configData.getConfigData({ cookies: [], config: [{ name: "HomePageFavMaxFilters" }, { name: "isClientUser" }] });
        this.props.action.crud.get({ crudId: CrudTypes.mctiUserPreferences }, validationCallback);
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public validEmail(email: string) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        // validate form
        if (this.isClientUser()) {
            let email = this.props.dataStore.crud.data
                ? this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration['@EmailAddress'] : '';

            let immediateFlag =
                this.props.dataStore.crud.data &&
                    this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo
                        .Configuration['@Immediate']
                    ? this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo
                        .Configuration['@Immediate']
                    : 'N';
            let immediateChecked = immediateFlag === 'Y';

            let notifyTypeId = this.props.dataStore.crud.data && this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration['@NotifyTypeId'] ?
                this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration['@NotifyTypeId'] : '0';

            let isNotifyDisabled = notifyTypeId === '0';

            let hasSchedule = this.props.dataStore.crud.data
                ? this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event.length > 0 : false;

            if (!isNotifyDisabled && !hasSchedule) {
                this.props.action.ui.addScheduleErrorMessage({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: "Please add a schedule for Scheduled Notification."
                    }
                });
                if (this.props.dataStore.ui.selectedTabIndex !== 1) {
                    this.props.action.ui.selectTabIndex(1);
                }
                return;
            }

            // if you have an email address entered, it cannot be crap...
            // if you have immediate checked or are enabled, you must have an email address
            if ((!email && (!isNotifyDisabled || immediateChecked)) ||
                (email && email.length > 0 && !this.validEmail(email) /* && !isNotifyDisabled*/)) {
                this.props.action.ui.emailErrorMessage({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: "Please enter a valid email address."
                    }
                });
                if (this.props.dataStore.ui.selectedTabIndex !== 1) {
                    this.props.action.ui.selectTabIndex(1);
                }
                return;
            }
        }


        this.props.dataStore.ui.deltaCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event?.map(item => {
            if (item["@ID"].indexOf("New") > -1)
                item["@ID"] = "#";
        });

        this.props.action.crud.update({ crudId: CrudTypes.mctiUserPreferences, data: this.props.dataStore.ui.deltaCrud });
        this.props.history.push('/LandingPage');

    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        // if page is dirty pop the modal
        if (this.props.dataStore.ui.userPrefsPageDirty) {
            this.props.action.ui.toggleRedirectConfirmModal(true);
        }
        else
            this.toLandingPage();
    }

    public onConfirmCancelRedirect() {
        this.props.action.ui.toggleRedirectConfirmModal(false);
    }

    public toLandingPage() {
        this.props.history.push("/LandingPage");
    }

    public getFavoriteFilters() {
        if (this.props.dataStore.crud.data) {
            return this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter;
        }
    }

    public getHomePageFilters() {
        if (this.props.dataStore.crud.data)
            return this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters.Filter;
    }

    public getSavedFilters() {
        if (this.props.dataStore.crud.data)
            return this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FilterList.Filter;
    }

    public getClaimSortTypeArray() {
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo ||
            !this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.ClaimSortLookupList
        ) return [];

        let result: SelectDropdownValue[] = [];
        this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.ClaimSortLookupList.ClaimSortType.forEach(ob => {
            if (ob['@Name'])
                result.push({ label: ob['@Name'], id: ob['@ID'], value: ob['@ID'] });
        });
        return result;
    }

    public getSortConfigurationUnreleasedItem() {

        if (this.props.dataStore.crud.data) {
            if (this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationUnreleased) { }
            {
                let sortConfigUnrel = this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo
                    .FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationUnreleased;

                if (sortConfigUnrel && sortConfigUnrel['@ID']) {
                    let item = this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo
                        .ClaimSortInfo.ClaimSortLookupList.ClaimSortType.find(ob => ob['@ID'] === sortConfigUnrel!['@ID']);
                    if (item)
                        return item['@ID'];
                }
            }
        }

        //default index
        return '0';
    }
    /*
    interface MCUserPrefsClaimSortType {
    '@ID': string;
    '@Name'?: string;
    '@ClaimSortTypeID'?: string;
}
    */
    public getSortConfigurationValue(config: SortConfigurationTypes): SelectDropdownValue {
        let defaultVal : SelectDropdownValue = { label: '', value: '0', id: '0' };

        if (this.props.dataStore.crud.data) {
            if (this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationUnreleased) { }
            {
                var sortConfigValue: MCUserPrefsClaimSortType = { "@ID": '0', "@ClaimSortTypeID": '0', "@Name": '' };
                switch (config) {
                    case SortConfigurationTypes.Released:
                        sortConfigValue = this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationReleased ?
                            this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationReleased :
                            sortConfigValue;
                        break;
                    case SortConfigurationTypes.Unreleased:
                        sortConfigValue = this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationUnreleased ?
                            this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationUnreleased :
                            sortConfigValue;
                        break;
                    case SortConfigurationTypes.User:
                        sortConfigValue = this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationUser ?
                            this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationUser :
                            sortConfigValue;
                        break;
                    default:
                        return defaultVal;
                }
                var sortId = sortConfigValue['@ClaimSortTypeID'];
                var label = this.getClaimSortTypeArray()?.find((ob: any) => ob.value === sortId)?.label;
                let returnVal: SelectDropdownValue = { id: sortId, value: sortId, label: label }
               
                return returnVal;
            }
        }

        return defaultVal;
    }

    public getScheduledNotificationOption(): SelectDropdownValue {
        var notifyTypeId = '0';
        if (this.props.dataStore.crud.data) {
            if (this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration) { }
            {
                notifyTypeId = this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration["@NotifyTypeId"];
            }
        }
        let returnVal: SelectDropdownValue = { value: notifyTypeId, label: scheduledNotificationOptions.find(ob => ob.value === notifyTypeId)?.label };
        return returnVal;
    }

    public getSchedule(type: string, time: string) {
        let result = { value: '00', label: '00' };
        if (!time || (time && time.length < 5))
            return result;


        if (type == 'hr') {
            const val = time.substring(0, 2);
            result.label = val;
            result.value = val;
        } else {
            const val = time.substring(3, 5);
            result.label = val;
            result.value = val;
        }
        return result;
    }

    public getScheduleName(obj: MCUserPrefsEvent): string {
        let time = '';
        if (obj['@Time'] && obj['@Time'].length > 0)
            time = obj['@Time'].substring(0, 5);

        let days = '';
        if (obj['@Sun'] && obj['@Sun'] === "Y")
            days = days + "Su";
        if (obj['@Mon'] && obj['@Mon'] === "Y")
            days = days + "Mo";
        if (obj['@Tue'] && obj['@Tue'] === "Y")
            days = days + "Tu";
        if (obj['@Wed'] && obj['@Wed'] === "Y")
            days = days + "We";
        if (obj['@Thu'] && obj['@Thu'] === "Y")
            days = days + "Th";
        if (obj['@Fri'] && obj['@Fri'] === "Y")
            days = days + "Fr";
        if (obj['@Sat'] && obj['@Sat'] === "Y")
            days = days + "Sa";

        if (days.length === 0) {
            days = "No Days";
        }
        if (time.length === 0) {
            time = "00:00";
        }

        return time + ' ' + days;
    }

    public getSelectedOptions(e: React.ChangeEvent<HTMLSelectElement>) {
        if (!e.target.options || e.target.options.length === 0) return [];
        let result = [];

        for (let i = 0; i < e.target.options.length; i++) {
            if (e.target.options[i].selected)
                result.push(e.target.options[i].value);
        }
        return result;
    }

    public getScheduleData(): any[] {
        let result: any[] = [
            { value: '', label: "- ADD A NEW TIME -" }
        ];

        console.log(this.props.dataStore.crud.data?.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event)

        if (this.props.dataStore.crud.data) {
            if (this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events) {
                this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event.forEach(obj => {
                    if (obj['@ID']) {
                        const name = this.getScheduleName(obj);

                        result.push({ value: obj['@ID'], label: name });
                    }
                });
            }
        }
        return result;
    }

    public updateSelectedScheduleHr(val: string) {
        this.props.action.ui.updateSelectedScheduleHr({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateSelectedScheduleMin(val: string) {
        this.props.action.ui.updateSelectedScheduleMin({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }

    public onSelectDayOfWeek(e: React.ChangeEvent<HTMLInputElement>, idx: string) {

        this.props.action.ui.selectDayOfWeek(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: idx,
                    value: e.target.checked,
                }
            });

    }

    public onSelectSchedule(e: React.ChangeEvent<HTMLSelectElement>) {
        console.log("value", e.target.value)
        console.log("options", e.target.options)

        console.log('data', e.target.options.selectedIndex
        )

        this.props.action.ui.selectSchedule(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
        console.log("masterCrud", this.props.action.ui.selectSchedule)
    }

    public updateEmailAddress(value: string) {
        if (this.props.dataStore.ui.userPrefsEmailError && this.props.dataStore.ui.userPrefsEmailError.length > 0) {
            if (this.validEmail(value)) {
                this.props.action.ui.emailErrorMessage({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: ''
                    }
                });
            }

        }

        this.props.action.ui.updateEmail(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: value
                }
            });
    }

    public updateImmediate(val: boolean) {
        this.props.action.ui.updateImmediate({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val ? "Y" : "N"
            }
        });
    }

    public onSelectTab(e: React.SyntheticEvent<Element, Event>, selObj: any) {
        this.props.action.ui.selectTabIndex(selObj.selectedTabIndex);
    }

    public onSelectUser(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectUser(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    name: e.target.options[e.target.options.selectedIndex].textContent
                }
            });
    }

    public onSelectGroup(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectGroup(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    name: e.target.options[e.target.options.selectedIndex].textContent
                }
            });
    }

    public onSelectAllSavedFilter(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectAllSavedFilters({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                selected: this.getSelectedOptions(e),
            },
        });
    }


    public onSelectAllFavoriteFilter(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectAllFavoriteFilters({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                selected: this.getSelectedOptions(e),
            },
        });
    }


    public onSelectAllHomePageFilter(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectAllHomePageFilters({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                selected: this.getSelectedOptions(e),
            },
        });
    }


    public onSelectSavedFilter(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectSavedFilter(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    name: e.target.options[e.target.options.selectedIndex].textContent
                }
            });
    }

    public onSelectUserPreferences(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectUserPreferences(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    name: e.target.options[e.target.options.selectedIndex].textContent
                }
            });
    }

    public updateNotificationOption(value: string) {
        this.props.action.ui.updateNotifyOption({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: value
            }
        });
    }

    public updateClaimOption(value: string, option: SortConfigurationTypes) {
        this.props.action.ui.updateClaimOption({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                selection: option,
                value: value
            }
        });
    }

    public onSelectHomePageFilter(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectHomePageFilter(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    name: e.target.options[e.target.options.selectedIndex].textContent
                }
            });
    }

    public onRemoveFavoriteFilterFields(e: React.ChangeEvent<HTMLButtonElement>) {

        this.props.action.ui.removeFavoriteFilterFields(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: true,
            });
    }

    public onAddFavoriteFilterFields(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.addFavoriteFilterFields(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: true,
            });
    }

    public onRemoveHomePageFilterFields(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.removeHomePageFilterFields(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: true,
            });
    }

    public onAddHomePageFilterFields(e: React.ChangeEvent<HTMLButtonElement>) {
        let maxFilters = this.getMaxHomeFilters();
        if (this.props.dataStore.crud.data) {
            var selectedFilters = this.props.dataStore.ui.selectedAllFavoriteFilters.length;
            var currentHomeFilters = this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters.Filter.length;

            if (selectedFilters + currentHomeFilters > maxFilters) {
                this.props.action.ui.addFavFilterErrorMsg({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: { value: "Only " + maxFilters + " home page filters allowed." }
                });
                //this.alertModal("Only " + maxFilters + " home page filters allowed.");
                return;
            }

        }
        this.props.action.ui.addHomePageFilterFields(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: true,
            });
    }

    public onClickModifyScheduleButton(e: React.SyntheticEvent) {
        let item = JSON.parse(JSON.stringify(this.props.dataStore.ui.selectedSchedule));

        //todo scheduled item count for crud??
        if (this.props.dataStore.ui.selectedSchedule['@ID'] !== "") {
            this.props.action.ui.updateUserPreferencesSchedule({ masterCrud: this.props.dataStore.crud.data, uiData: item });
        } else {
            const genrateId = "New" + this.props.dataStore.ui.deltaCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event?.length
            const filter = this.props.dataStore.ui.deltaCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event?.filter(itemvalue =>
                itemvalue?.["@ID"] === genrateId)
            if (filter?.length) {
                if (filter?.length > 0)
                    item['@ID'] = genrateId + 'New'
                else
                    item['@ID'] = genrateId;
            }
            else
                item['@ID'] = genrateId;
            
            this.props.action.ui.addUserPreferencesSchedule({ masterCrud: this.props.dataStore.crud.data, uiData: item });
        }
    }

    public onConfirmRemoveScheduleButton(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.props.dataStore.ui.selectedSchedule['@ID'] !== "") {
            this.props.action.ui.removeUserPrefsSchedule({
                masterCrud: this.props.dataStore.crud.data,
                uiData: { value: this.props.dataStore.ui.selectedSchedule['@ID'] }
            });
        }
    }

    public onClickRemoveScheduleButton(e: React.SyntheticEvent) {
        if (this.props.dataStore.ui.selectedSchedule['@ID'] === "")
            return;

        const name = this.getScheduleName(this.props.dataStore.ui.selectedSchedule);
        const msg = "Do you want to remove '" + name + "' ? This action cannot be undone.";
        this.onConfirmRemoveSchedule(msg);
    }

    public onRemoveScheduleButton() {

        if (this.props.dataStore.ui.selectedSchedule['@ID'] !== "") {
            this.props.action.ui.removeUserPrefsSchedule({
                masterCrud: this.props.dataStore.crud.data,
                uiData: { value: this.props.dataStore.ui.selectedSchedule['@ID'] }
            });
        }
    }

    public alertModal(message: string) {
        this.props.action.ui.addErrorMsg({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: message }
        });
    }

    public onOkFilterErr() {
        this.props.action.ui.removeErrorMsg({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: '' }
        });
    }

    public onConfirmRemoveSchedule(msg: string) {
        this.props.action.ui.errorUserPrefsRemoveScheduleConfirm({ masterCrud: this.props.dataStore.crud.data, uiData: { value: msg } });
    }

    public onClearRemoveScheduleConfirm(e: any) {
        this.onConfirmRemoveSchedule('');
    }

    public onMoveHomePagesUp(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.moveHomePagesUp({ masterCrud: this.props.dataStore.crud.data, uiData: true });
    }
    public onMoveHomePagesDown(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.moveHomePagesDown({ masterCrud: this.props.dataStore.crud.data, uiData: true });
    }

    public getMaxHomeFilters() {
        let configuredMaxFilters = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "HomePageFavMaxFilters")

        if (configuredMaxFilters && configuredMaxFilters.value > 0)
            return configuredMaxFilters.value;

        return this.props.homePageMaxFilters;
    }

    public isClientUser() {
        let userId = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "isClientUser");

        return (userId && userId.value === 'True');

    }

    public render() {
        var helpButtons = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;

        let isClientUser = this.isClientUser();
        let savedFilters = this.getSavedFilters();
        let favoriteFilters = this.getFavoriteFilters();
        let homePageFilters = this.getHomePageFilters();
        let claimSortTypes = this.getClaimSortTypeArray();

        let hasEmailError = this.props.dataStore.ui.userPrefsEmailError !== '';

        let tabList: tabArr[] = [{ label: 'Home Page', domID: 'id-HomePage' }];
        let notifyTab: tabArr = { label: 'Email Notification', domID: 'id-emailNotification' };
        if (isClientUser)
            tabList.push(notifyTab);

        let email = this.props.dataStore.crud.data
            ? this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration['@EmailAddress'] : '';
        let isImmediate = this.props.dataStore.crud.data
            ? this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration['@Immediate'] === 'Y' : false;
        let isScheduleDisabled = this.props.dataStore.crud.data
            ? this.props.dataStore.crud.data.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration['@NotifyTypeId'] === '0' : false;

        return (
            <DialogWrapper title="User Preferences" helpUrl='/Support/Help/HELP_Maint_UserPreferences.htm' buttons={helpButtons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                <UserPrefsWrapper>
                    
                    <Tabs
                        tabs={tabList}
                        onTabSelect={(e: React.SyntheticEvent<Element, Event>, selObj: any) => this.onSelectTab(e, selObj)}
                    >
                    </Tabs>
                    {this.props.dataStore.ui.selectedTabIndex !== 1 ? (
                        <ContentWrapper>
                            <ContentColumnWrapper>
                                <DialogFieldset style={{ padding: "10px", width:"945px" }}>
                                    <DialogLegend>Favorite Filters</DialogLegend>
                                    <FavoriteFiltersWrapper>
                                        <ContentRowWrapper style={{ marginBottom: "0px" }}>
                                            <ContentColumnWrapper>
                                                <DialogLegend>Saved Filters:</DialogLegend>
                                                <SelectComponent
                                                    title='SavedFilter'
                                                    size={21}
                                                    width='400px'
                                                    multiple='true'
                                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectAllSavedFilter(e)}
                                                    optionFields={{
                                                        value: "@ID",
                                                        text: "@Name"
                                                    }}
                                                    records={savedFilters}
                                                    selectedMultiValue={this.props.dataStore.ui.selectedAllSavedFilters}
                                                    style={{height:"420px"}}
                                                >
                                                </SelectComponent>
                                            </ContentColumnWrapper>
                                            <ContentColumnWrapper>
                                                <div>
                                                    <ArrowButtons
                                                        toolTipLeft=' Remove Filter from your Favorite Filters'
                                                        toolTipRight=' Assign Filter as a Favorite Filter'
                                                        disabled={!this.props.canEdit}
                                                        onLeftBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                            handleChange();
                                                            this.onRemoveFavoriteFilterFields(e)
                                                        }}
                                                        onRightBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                            handleChange();
                                                            this.onAddFavoriteFilterFields(e)
                                                        }}
                                                    />
                                                </div>
                                            </ContentColumnWrapper>
                                            <ContentColumnWrapper>
                                                <ContentRowWrapper>
                                                    <ContentColumnWrapper>
                                                        <DialogLegend>Favorite Filters:</DialogLegend>
                                                        <SelectComponent
                                                            title='FavoriteFilters'
                                                            size={10}
                                                            width='400px'
                                                            multiple='true'
                                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectAllFavoriteFilter(e)}
                                                            optionFields={{
                                                                value: "@ID",
                                                                text: "@Name"
                                                            }}
                                                            records={favoriteFilters}
                                                            selectedMultiValue={this.props.dataStore.ui.selectedAllFavoriteFilters}
                                                            style={{ height: "240px" }}
                                                        >
                                                        </SelectComponent>
                                                        {(!!this.props.dataStore.ui.favFilterErrorMsg && this.props.dataStore.ui.favFilterErrorMsg.length > 0) &&
                                                            <UserPrefsErrorMessage>{this.props.dataStore.ui.favFilterErrorMsg}</UserPrefsErrorMessage>
                                                        }
                                                        <UpDownArrowButtonsWrapper>
                                                            <UpDownArrowButtonsRow
                                                                toolTipDown=' Remove Filter(s) from Selected Saved Filter'
                                                                toolTipUp=' Add Field(s) to Favorite Filter'
                                                                disabled={!this.props.canEdit}
                                                                onUpBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                                    handleChange();
                                                                    this.onRemoveHomePageFilterFields(e)
                                                                }}
                                                                onDownBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                                    handleChange();
                                                                    this.onAddHomePageFilterFields(e)
                                                                }}
                                                            />
                                                        </UpDownArrowButtonsWrapper>
                                                    </ContentColumnWrapper>
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <ContentColumnWrapper>
                                                        <DialogLegend>Home Page Filters:</DialogLegend>
                                                        <SelectComponent
                                                            title='HomePageFilter'
                                                            size={6}
                                                            width='400px'
                                                            multiple='true'
                                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectAllHomePageFilter(e)}
                                                            optionFields={{
                                                                value: "@ID",
                                                                text: "@Name"
                                                            }}
                                                            selectedMultiValue={this.props.dataStore.ui.selectedAllHomePageFilters}
                                                            records={homePageFilters}
                                                            style={{ height: "112px" }}
                                                        >
                                                        </SelectComponent>
                                                        {(!!this.props.dataStore.ui.homePageFilterErrorMsg && this.props.dataStore.ui.homePageFilterErrorMsg.length > 0) &&
                                                            <UserPrefsErrorMessage>{this.props.dataStore.ui.homePageFilterErrorMsg}</UserPrefsErrorMessage>
                                                        }
                                                    </ContentColumnWrapper>
                                                    <UpDownArrowButtonsWrapper2>
                                                        <UpDownArrowButtons
                                                            toolTipDown=' Move Down'
                                                            toolTipUp=' Move Up'
                                                            disabled={!this.props.canEdit}
                                                            onUpBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onMoveHomePagesUp(e)}
                                                            onDownBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onMoveHomePagesDown(e)}
                                                        />
                                                    </UpDownArrowButtonsWrapper2>
                                                </ContentRowWrapper>
                                            </ContentColumnWrapper>
                                        </ContentRowWrapper>
                                    </FavoriteFiltersWrapper>
                                </DialogFieldset>
                                {isClientUser &&
                                    <MCAWrapper>
                                        <DialogFieldset style={{ paddingLeft: '10px' }}>
                                            <DialogLegend>Manage Claim Assignments Sort Options</DialogLegend>
                                            <ContentRowWrapper>
                                                <SelectDropdown
                                                    domID="unrel-claims-assign-dropdown"
                                                    className="unrel-claims-dropdown"
                                                    label="Claims assigned to me (unrel):"
                                                    size='small'
                                                    isClearable={false}
                                                    menuWidth={318}
                                                    onChange={(e: any) => { this.updateClaimOption(e.value, SortConfigurationTypes.Unreleased) }}
                                                    options={claimSortTypes}
                                                    initialValue={this.getSortConfigurationValue(SortConfigurationTypes.Unreleased)}
                                                />
                                            </ContentRowWrapper>
                                            <ContentRowWrapper>
                                                <SelectDropdown
                                                    domID="rel-claims-assign-dropdown"
                                                    className="dropdown"
                                                    label="Claims assigned to me (rel):"
                                                    size='small'
                                                    isClearable={false}
                                                    menuWidth={318}
                                                    options={claimSortTypes}
                                                    onChange={(e: any) => this.updateClaimOption(e.value, SortConfigurationTypes.Released)}
                                                    initialValue={this.getSortConfigurationValue(SortConfigurationTypes.Released)}
                                                />
                                            </ContentRowWrapper>
                                            <ContentRowWrapper>
                                                <SelectDropdown
                                                    domID="user-claims-assign-dropdown"
                                                    className="dropdown"
                                                    label="Claims assigned by a user:"
                                                    size='small'
                                                    isClearable={false}
                                                    menuWidth={318}
                                                    options={claimSortTypes}
                                                    onChange={(e: any) => this.updateClaimOption(e.value, SortConfigurationTypes.User)}
                                                    initialValue={this.getSortConfigurationValue(SortConfigurationTypes.User)}
                                                />
                                            </ContentRowWrapper>
                                        </DialogFieldset>
                                    </MCAWrapper>
                                }
                            </ContentColumnWrapper>
                        </ContentWrapper>
                    ) : (
                            <ContentWrapper>
                                <ContentColumnWrapper>
                                    <DialogFieldset style={{ padding: '10px', marginBottom: '5px', width: "945px" }}>
                                        <DialogLegend>Set up workflow email notifications</DialogLegend>
                                        <Input
                                            domID="userPrefs-email"
                                            className="text-input"
                                            label="EMAIL:"
                                            style={{ width:'450px' }}
                                            initialValue={email}
                                            onChange={(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => this.updateEmailAddress(e.target.value)}
                                            hasError={hasEmailError}
                                            errorMessage={this.props.dataStore.ui.userPrefsEmailError}
                                        />
                                        <ImmediateActions>
                                            <ContentRowWrapper>
                                                <CheckBoxComponent
                                                id='userPrefs-immediate'
                                                labelBefore='IMMEDIATE:&nbsp;&nbsp;'
                                                width="10px"
                                                checked={isImmediate}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateImmediate(e.target.checked)}
                                                />
                                                <LabelWrapper>
                                                    <DialogLabel>(Send Email notification for user-to-user-assignments)</DialogLabel>
                                                </LabelWrapper>
                                            </ContentRowWrapper>
                                        </ImmediateActions>
                                        <SelectDropdown
                                            domID="userPrefs-scheduled-notifications"
                                            className="dropdown"
                                            label="SCHEDULED NOTIFICATIONS:"
                                            isClearable={false}
                                            isSearchable={true}
                                            size='medium'
                                            menuWidth={450}
                                            onChange={(e: any) => this.updateNotificationOption(e.value)}
                                            options={scheduledNotificationOptions}
                                            initialValue={this.getScheduledNotificationOption()}
                                        />
                                        <ScheduleWrapper>
                                            <DialogFieldset style={{ marginLeft: "0px", marginTop: "15px" }}>
                                                <DialogLegend>Schedule</DialogLegend>
                                                <ContentRowWrapper style={{ marginLeft: "5px" }}>
                                                    <SelectComponent
                                                        title='userPreferences-schedule'
                                                        size={6}
                                                        width='220px'
                                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectSchedule(e)}
                                                        optionFields={{
                                                            value: "value",
                                                            text: "label"
                                                        }}
                                                        // This is the fix that should be there if we look at the ASP page, but was told not to fix it coz
                                                        // pre-existing in first version of the armui page.
                                                        //disabled={this.getScheduledNotificationOption().value === '0'}
                                                        disabled={false}
                                                        records={this.getScheduleData()}
                                                        selectedValue={this.props.dataStore.ui.selectedSchedule["@ID"]}
                                                    ></SelectComponent>
                                                    <ContentColumnWrapper>
                                                        <ContentRowWrapper>
                                                            <DialogLabel id='userPrefs-daysofweek_label'>Days:</DialogLabel>
                                                            <DaysOfWeekCheckBoxComponent
                                                                id='userPrefs-days'
                                                                checkedSun={this.props.dataStore.ui.selectedSchedule['@Sun'] === 'Y'}
                                                                checkedMon={this.props.dataStore.ui.selectedSchedule['@Mon'] === 'Y'}
                                                                checkedTue={this.props.dataStore.ui.selectedSchedule['@Tue'] === 'Y'}
                                                                checkedWed={this.props.dataStore.ui.selectedSchedule['@Wed'] === 'Y'}
                                                                checkedThu={this.props.dataStore.ui.selectedSchedule['@Thu'] === 'Y'}
                                                                checkedFri={this.props.dataStore.ui.selectedSchedule['@Fri'] === 'Y'}
                                                                checkedSat={this.props.dataStore.ui.selectedSchedule['@Sat'] === 'Y'}
                                                                disabled={false}
                                                                onChangeSun={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Sun')}
                                                                onChangeMon={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Mon')}
                                                                onChangeTue={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Tue')}
                                                                onChangeWed={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Wed')}
                                                                onChangeThu={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Thu')}
                                                                onChangeFri={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Fri')}
                                                                onChangeSat={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Sat')}
                                                            />
                                                        </ContentRowWrapper>
                                                        <TimeScheduleComponent
                                                            id='userPreferences_time'
                                                            onChangeHr={(e: any) => this.updateSelectedScheduleHr(e.value)}
                                                            onChangeMin={(e: any) => this.updateSelectedScheduleMin(e.value)}
                                                            label='AT: '
                                                            width='150px'
                                                            menuWidth='80px'
                                                            disabled={false}
                                                            separator=' : '
                                                            setSelectedValHr={this.getSchedule('hr', this.props.dataStore.ui.selectedSchedule['@Time'])}
                                                            setSelectedValMin={this.getSchedule('min', this.props.dataStore.ui.selectedSchedule['@Time'])}
                                                        ></TimeScheduleComponent>
                                                        <SelectButtons>
                                                            <Button
                                                                domID="userPreferences_schedule_addButton"
                                                                name={this.props.dataStore.ui.selectedSchedule['@ID'] !== "" ? "UPDATE" : "ADD"}
                                                                buttonType="emphasized"
                                                                size="control"
                                                                type="button"
                                                                disabled={!this.props.canEdit || isScheduleDisabled}
                                                                onClick={(e: React.SyntheticEvent) => {
                                                                    handleChange()
                                                                    this.onClickModifyScheduleButton(e)
                                                                }}
                                                            />
                                                            <Button
                                                                domID="userPreferences_schedule_removeButton"
                                                                name="REMOVE"
                                                                buttonType="standard"
                                                                size="control"
                                                                type="button"
                                                                disabled={!this.props.canEdit || this.props.dataStore.ui.selectedSchedule['@ID'] === "" || isScheduleDisabled}
                                                                onClick={(e: React.SyntheticEvent) => {
                                                                handleChange()
                                                                    this.onClickRemoveScheduleButton(e)
                                                                }}
                                                            />
                                                        </SelectButtons>
                                                    </ContentColumnWrapper>
                                                </ContentRowWrapper>
                                                {(!!this.props.dataStore.ui.scheduleErrorMsg && this.props.dataStore.ui.scheduleErrorMsg.length > 0 && this.getScheduledNotificationOption().value !== '0') &&
                                                    <UserPrefsErrorMessage>{this.props.dataStore.ui.scheduleErrorMsg}</UserPrefsErrorMessage>
                                                }
                                            </DialogFieldset>
                                        </ScheduleWrapper>
                                    </DialogFieldset>
                                </ContentColumnWrapper>
                            </ContentWrapper>
                        )}
                    
                    <ModalConfirmation
                        isOpen={!!this.props.dataStore.ui.removeScheduleConfirm && this.props.dataStore.ui.removeScheduleConfirm.length > 0}
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearRemoveScheduleConfirm(e)}
                        message={this.props.dataStore.ui.removeScheduleConfirm}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onRemoveScheduleButton()}
                    />
                    <ModalConfirmation
                        isOpen={!!this.props.dataStore.ui.showConfirmRedirectModal}
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onConfirmCancelRedirect()}
                        formattedMessage={(
                            <div>
                                <b>Are you sure you want to leave this page?</b> <br /> <p> Your changes may NOT be saved.</p>
                            </div>)}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.toLandingPage()}
                    />
                </UserPrefsWrapper>
            </DialogWrapper>
        );
    }
};

function mapStateToProps(state: ApplicationState) {
    return state.userPreferences;
}

var connectedHoc = connect<IUserPreferencesState, IUserPreferencesActionProps, IOwnProps, IUserPreferencesProps, ApplicationState>(
    createCrudMapStateToProps('userPreferences'),
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(UserPreferences);

export default withRouter(connectedHoc);
