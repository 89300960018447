import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';

import { Tabs, Input, Button, SelectDropdown } from '@optum-uicl/ui-core/dist';

import { URLs } from '@commonDevResources/constants';
import { pageLeave } from '@commonResources/userModified';

import {
    Buttons,
    Labels as LabelStyles,
    ArmTabContainer,
    SizedContainer, 
    ITabID
} from '@common/component-styles';
import { DialogFieldset, DialogLegend, HelpIcon } from '@common/DialogStyles';
import { DialogWrapper, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { IModalConfirmationProps, ModalConfirmation } from '@common/ModalConfirmation';
import { ARMDatePicker as DatePicker } from '@common/DateOrTimeRelated/ARMDatePicker';
import { ValidateDateFormat, ValidateDateString, LegacyValidateDate, LegacyValidateDateRange } from '../../../scripts/util/ValidationHelpers';
import { FormatDateAsString } from '../../../scripts/util/FormatHelpers';

import { ApplicationState } from '@store/index';
import { IManualEligibilityCheckState, IManualEligibilityCheckActionProps, actionCreators } from '@store/ManualEligibilityCheck';
import ApiClient, { HttpStatus } from '@commonResources/ApiClient';
import "./ManualEligibilityCheck.css"
import Default from '../../Dashboard/Default/Default';
import BoxShadows from '@commonResources/boxShadows';
import styled from 'styled-components';
import { string } from 'prop-types';
import { SelectDropdownValue } from '@optum-uicl/ui-core/dist/Atoms/SelectDropdown/types';
  
//===========================================================================================
const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    .fullwidth_dropdown {
        width: 100% !important;
    }

    div[class^= "TabsWrapper-sc"]: first-child span[class^= "TabsWrapper"]: first-child {
        margin-left: 0px;
    }

    #tabSubmission, #tabResults {
        padding-left: 20px;
        padding-right: 20px;
        margin-right: 0px;
    }   
`;

export const ManualEligibilityCheckWrapper =  styled.div`
 
    #additional-dialogfieldset{
            padding-bottom: 10px;
        }
    #manualelgcheck-dialogfieldset{
            padding-bottom: 5px;
        }
`;
   
  
const Page = {
    Selectors: {
        idSelectPayer: "selectManualElgOptionsPayers",
        idButtonSubmit: "btnSubmit",
        idButtonClear: "btnClear",
        idButtonPrint: "btnPrint",
        idTabSubmission: "tabSubmission",
        idTabResult: "tabResults",
    },
    Default: {
        selectedPayerOption:
        {
            value: "",
            label: "- Select a Payer -"
        },
        selectProviderTypeOptions: [
            {
                value: "H",
                label: "Institutional"
                
            },
            {
                value: "P",
                label: "Professional"
            }
        ],
        selectGenderOptions: [
            {
                value: "M",
                label: "Male"
            },
            {
                value: "F",
                label: "Female"
            }
        ],
        selectInsuredGenderOption:
        {
            value: "",
            label: "- Select Insured Gender -"
        },
        selectPatientGenderOption:
        {
            value: "",
            label: "- Select Patient Gender -"
        },
        selectPatientRelationshipOptions: [
            {
                value: "",
                label: "- Select Patient Relationship -"
            },
            {
                value: "SELF",
                label: "Self"
            },
            {
                value: "CHILD",
                label: "Child"
            },
            {
                value: "SPOUSE",
                label: "Spouse"
            },
            {
                value: "OTHER",
                label: "Other"
            }
        ],
        initInputState: {
            value: "",
            isDisabled: true,
            rankingText: "",
        }
    }
};
Object.freeze( Page );
Object.freeze( Page.Selectors );
Object.freeze( Page.Default );
Object.seal( Page );

interface IHtmlOnChangeSelectEvent {
    label: string;
    value: string; 
}

interface IInputState {
    value: string;
    isDisabled: boolean;
    rankingText: string;
}
//use prop called "value", same as IInitInputState, otherwise need to jump through a lot of TypeScript hoops
interface ISelectState {
    value: ISelectOption;
    isDisabled: boolean;
    rankingText: string;
}

//===========================================================================================

interface IComponentProps {
    canEdit: boolean;
    canPrint: boolean;
}

// TypeScript really does REALLY suck; this prevents TypeScript error TS7053 when using dynamic state object, e.g. this.state[variableStateName];
// Needed to add line of code "[index: string]: any;" because of TypeScript error TS7053 when trying to use dynamic objects (in selectPayerOption(...) function)
// (See https://stackoverflow.com/questions/56833469/typescript-error-ts7053-element-implicitly-has-an-any-type and 
// https://stackoverflow.com/questions/12710905/how-do-i-dynamically-assign-properties-to-an-object-in-typescript)
// The state names in ALL_CAPS are from legacy, verbatim.  They must be kept exactly the same because those names are also pulled from the database
interface IComponentState {
    [index: string]: any; 
    isPageBusy: boolean;
    selectedTabIndex: number;
    modalProps: IModalConfirmationProps;

    //form inputs
    selectPayerOptions: Array<IManualEligibilityCheckPayer>;
    selectedPayerOption: IManualEligibilityCheckPayer;

    NPI: IInputState;
    BEGINNING_DOS: IInputState;
    ENDING_DOS: IInputState;
    PROVIDER_ID: IInputState;
    selectProviderTypeOptions: Array<ISelectOption>;
    PROVIDER_TYPE: ISelectState;

    INSURED_MEDICAID_ID: IInputState;
    DOB: IInputState;
    FIRST_NAME: IInputState;
    MIDDLE_INITIAL: IInputState;
    LAST_NAME: IInputState;
    selectInsuredGenderOptions: Array<ISelectOption>;
    INSURED_GENDER: ISelectState;
    SS_NUMBER: IInputState;
    GROUP_NUMBER: IInputState;
    selectPatientRelationshipOptions: Array<ISelectOption>;
    PATIENT_RELATIONSHIP: ISelectState;
    PLAN_CODE: IInputState;

    DEPENDENT_FIRST_NAME: IInputState;
    DEPENDENT_MIDDLE_INI: IInputState;
    DEPENDENT_LAST_NAME: IInputState;
    DEPENDENT_DOB: IInputState;
    PAT_SS_NUMBER: IInputState;
    selectPatientGenderOptions: Array<ISelectOption>;
    PATIENT_GENDER: ISelectState;

    SERVICE_TYPE: IInputState;
    STATE_CODE: IInputState;
    CARD_NUMBER: IInputState;

    manualEligibilitySubmitResponse: IManualEligibilityResponse | undefined;
    manualEligibilityCheckResponse: IManualEligibilityCheckResponse | undefined;
}

const DEFAULT_STATE: IComponentState = {
    isPageBusy: true,
    selectedTabIndex: 0,
    modalProps: {
        isOpen: false,
        showDefaultClose: true,
        onModalToggle: (() => {
            return false;
        }),
        message: "",
        formattedMessage: undefined,
        onConfirm: undefined,
        onDeny: undefined,
        onOK: undefined,
        okText: "OK",
        cancelText: "Cancel"
    },

    //form inputs
    selectPayerOptions: [
        {
            ...Page.Default.selectedPayerOption
        }
    ],
    selectedPayerOption: { ...Page.Default.selectedPayerOption },

    NPI: { ...Page.Default.initInputState },
    BEGINNING_DOS: { ...Page.Default.initInputState },
    ENDING_DOS: { ...Page.Default.initInputState },
    PROVIDER_ID: { ...Page.Default.initInputState },
    selectProviderTypeOptions: [ ...Page.Default.selectProviderTypeOptions ],
    PROVIDER_TYPE: {
        value: { ...Page.Default.selectProviderTypeOptions[0] },
        isDisabled: true,
        rankingText: "",
    },

    INSURED_MEDICAID_ID: { ...Page.Default.initInputState },
    DOB: { ...Page.Default.initInputState },
    FIRST_NAME: { ...Page.Default.initInputState },
    MIDDLE_INITIAL: { ...Page.Default.initInputState },
    LAST_NAME: { ...Page.Default.initInputState },
    selectInsuredGenderOptions: [
        { ...Page.Default.selectInsuredGenderOption },
        ...Page.Default.selectGenderOptions
    ],
    INSURED_GENDER: {
        value: { ...Page.Default.selectInsuredGenderOption },
        isDisabled: true,
        rankingText: "",
    },
    SS_NUMBER: { ...Page.Default.initInputState },
    GROUP_NUMBER: { ...Page.Default.initInputState },
    selectPatientRelationshipOptions: [
        ...Page.Default.selectPatientRelationshipOptions
    ],
    PATIENT_RELATIONSHIP: {
        value: { ...Page.Default.selectPatientRelationshipOptions[0] },
        isDisabled: true,
        rankingText: "",
    },
    PLAN_CODE: { ...Page.Default.initInputState },

    DEPENDENT_FIRST_NAME: { ...Page.Default.initInputState },
    DEPENDENT_MIDDLE_INI: { ...Page.Default.initInputState },
    DEPENDENT_LAST_NAME: { ...Page.Default.initInputState },
    DEPENDENT_DOB: { ...Page.Default.initInputState },
    PAT_SS_NUMBER: { ...Page.Default.initInputState },
    selectPatientGenderOptions: [
        { ...Page.Default.selectPatientGenderOption },
        ...Page.Default.selectGenderOptions
    ],
    PATIENT_GENDER: {
        value: { ...Page.Default.selectPatientGenderOption },
        isDisabled: true,
        rankingText: "",
    },

    SERVICE_TYPE: { ...Page.Default.initInputState },
    STATE_CODE: { ...Page.Default.initInputState },
    CARD_NUMBER: { ...Page.Default.initInputState },

    manualEligibilitySubmitResponse: undefined,
    manualEligibilityCheckResponse: undefined,
};
Object.freeze(DEFAULT_STATE);

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IManualEligibilityCheckProps = IMergeApiComponentProps<IManualEligibilityCheckState, IManualEligibilityCheckActionProps, IOwnProps>;


//===========================================================================================

export class ManualEligibilityCheck extends React.Component<IManualEligibilityCheckProps, IComponentState> {

    //private targetLanguage:ISO_LANG = ISO_LANG.EN;
    private readonly text = {
        pageTitle: "Manual Eligibility Check",
        Ok: "OK",
        Cancel: "Cancel",
        Submit: "Submit",
        Clear: "Clear",
        Print: "Print", 

        "form - general legend title": "GENERAL INFORMATION",
        "form - subscriber legend title": "SUBSCRIBER INFORMATION",
        "form - dependent legend title": "DEPENDENT INFORMATION",
        "form - additional legend title": "ADDITIONAL INFORMATION",

        "results - status information": "STATUS INFORMATION",
        "results - insured information": "INSURED INFORMATION",
        "results - patient information": "PATIENT INFORMATION",
        "results - details": "DETAILS",

        footerInstructions: `Enter eligibility information and click the "Submit" button to determine eligibility. Click the "Clear" button to reset form data. <br /> Required fields are marked with an asterisk(*).Ranking is shown next to the field names for those fields with ranking.`,

        "error - SSN": "Please enter a valid SSN or clear this field.",
    };


    private readonly tabs: Array<ITabID> = [
        {
            domID: Page.Selectors.idTabSubmission,
            label: "REQUEST"
        },
        {
            domID: Page.Selectors.idTabResult,
            label: "RESPONSE"
        },
    ];


    //date logic adapted from legacy
    private readonly date = new Date();
    private readonly minDate = new Date(this.date.setDate(this.date.getDate() - 365)); //one year in past
    private readonly maxDate = new Date(this.date.setDate(this.date.getDate() + (3 * 365))); //two years in future

    //-----------------------------------------------------------

    constructor(props: IManualEligibilityCheckProps) {
        super(props);
        this.state = DEFAULT_STATE;

        this.bindEventHandlers();
    }

    public componentDidMount () {
        if (!this.props.canEdit) {
            this.redirectToHome();
        }
        pageLeave();

        this.resetPageState(() => {
            this.refreshData()
                .then((isValid: boolean) => {
                    if (isValid) {
                        console.log(`initial data retrieved for ManualEligibilityCheck is valid`);
                    }
                    else {
                        console.log(`initial data retrieved for ManualEligibilityCheck is INvalid`);
                    }
                })
                .catch((error: string) => {
                    console.error(`Error while refreshing data for ManualEligibilityCheck: ${error}`);
                })
                .finally(() => {
                    //--------------------------------
                    //TO-DO: refactor
                    const firstTab = document.getElementById(this.tabs[0].domID) as HTMLElement;
                    firstTab.click();
                    //--------------------------------

                    //console.log(`finally finished refreshing data for ManualEligibilityCheck`);
                    this.setState({
                        isPageBusy: false,
                    });
                });
        });

    }

    public componentWillUnmount() {
        pageLeave();
        resetApiComponentState(this.props.action, this.props.dataStore);
    }


    //-----------------------------------------------------------
    //supporting, private functions

    /* function to bind common eventhandlers to components "this" reference; declare binding here to add to class/_proto instead of object instance */
    bindEventHandlers () {
        this.handleInputBlur = this.handleInputBlur.bind(this); 
        this.handleInputChangedDOS = this.handleInputChangedDOS.bind(this);
        this.handleInputChangedEOS = this.handleInputChangedEOS.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this); 
    }

    //private checkResponse = () => { alert('Not implemented.'); }

    private closeModal = () => {
        this.setState({
            isPageBusy: false,
            modalProps: { ...DEFAULT_STATE.modalProps }
        });
    }

    /**
     * This was adapted from legacy and should return all the form inputs as a JSON object.  Using Record<string, any> (because TypeScript) 
     * allows a generic JSON object for a placeholder.  As such, properties may be added to or removed from the JSON object without breaking 
     * the return type because TypeScript sucks.
     * */
    private getFormInputAsJson = (): Record<string, any> => { //because TypeScript
        const payerInformation: IManualEligibilityCheckPayer = this.state.selectedPayerOption;
        let formInputAsJson: IManualEligibilityCheckForm = {
            Entry: [
                {
                    "@FieldName": "PayerID",
                    "@Key": "_payerid_",
                    "@Value": `${payerInformation.ID}`
                },
                {
                    "@FieldName": "PayerName",
                    "@Key": "_payerName_",
                    "@Value": `${payerInformation.DisplayName}`
                },
                {
                    "@FieldName": "FIName",
                    "@Key": "_finame_",
                    "@Value": `${payerInformation.FIName}`
                },
                {
                    "@FieldName": "RTECPID",
                    "@Key": "_cpid_",
                    "@Value": `${payerInformation.RTECPID}`
                },
                {
                    "@FieldName": "FormType",
                    "@Key": "_formType_",
                    "@Value": `${this.state.PROVIDER_TYPE.value.value}`
                },
            ]
        };

        const fields = payerInformation.Fields;
        let field: IManualEligibilityCheckFormField;
        let element: HTMLElement | null;
        let legacyFieldName = "";
        let key = "";
        let value = "";
        let patientRelationship: string;
        for (let i = 0, length = fields?.length || 0; i < length; i++) {
            field = fields![i];
            key = field.FieldType;

            element = document.getElementById(`${key}`);
            if (!element) {
                continue; //no element on page, so skip to next iteration
            }

            value = typeof (this.state[key].value) === 'string' ? this.state[key].value : this.state[key].value.value;
            if (key.endsWith('SS_NUMBER')) {
                value = value.replace(/-/g, '');
            }
            //logic from legacy is to exclude anything without a value, so skip if value is empty
            if (value.trim().length === 0) {
                continue;
            }
            legacyFieldName = field.DisplayName; // this.getLegacyFieldName(key);

            formInputAsJson.Entry.push(
                {
                    "@FieldName": legacyFieldName,
                    "@Key": key,
                    "@Value": value
                });
        }

        formInputAsJson.Entry.push(
            {
                "@FieldName": "ServiceType",
                "@Key": "SERVICE_TYPE",
                "@Value": this.state.SERVICE_TYPE.value
            });

        patientRelationship = this.state.PATIENT_RELATIONSHIP.value.value;
        formInputAsJson.Entry.push(
            {
                "@FieldName": "_hasDependant_",
                "@Key": "_hasDependant_",
                "@Value": `${(patientRelationship.length > 0 && patientRelationship.toLowerCase() !== "self")}`
            });

        return formInputAsJson;
    }

    private async getFormPayerOptions(): Promise<any> {
        const endpoint = `${ApiClient.apiBaseURL}/data/Eligibility/GetManualEligibilityFormPayers`;

        let records: Array<IManualEligibilityCheckPayer>;

        return await ApiClient.jsonGetAsync(endpoint)
            .then(async (response: any) => {
                if (response.status === HttpStatus.OK) {
                    let json = await response.json();
                    if (json?.length > 0) {
                        records = Array.isArray(json) ? json : [json];

                        for (let i = 0, length = records.length; i < length; i++) {
                            records[i].value = `${records[i].ID}`;
                            records[i].label = records[i].DisplayName;
                        }
                        records.splice(0, 0, DEFAULT_STATE.selectPayerOptions[0]);
                    }
                    else {
                        records = [DEFAULT_STATE.selectPayerOptions[0]];
                    }

                    return records;
                }
                else {
                    console.error(`Attempt to retrieve Payers failed, status: ${response.status}.`);
                    return [];
                }
            })
            .catch((error) => {
                console.error( `Error while attempting to retrieve Payers failed, error: ${error}.`);
                return [];
            });
    };

    private async getManualEligibilityCheckStatus (transactionId: number): Promise<any> {
        const endpoint = `${ApiClient.apiBaseURL}/data/Eligibility/GetManualEligibilityCheckStatus/${transactionId}`;
        return ApiClient.jsonGetAsync(endpoint)
            .then(async (response: any) => {
                if (response.status === HttpStatus.OK) {
                    const jsonResponse = await response.json();
                    if (jsonResponse) {
                        let statusResponse = jsonResponse as IManualEligibilityStatusResponse;

                        if (statusResponse.ErrorMessage?.length) {
                            this.showModalMessages(`Error: ${statusResponse.ErrorMessage}`);
                            return Promise.reject(statusResponse.ErrorMessage);
                        }
                        if (statusResponse.Status !== "Received") {
                            this.showModalMessages(`Status is: ${statusResponse.Status}`);
                            return Promise.reject(statusResponse.Status);
                        }
                        if (statusResponse.Response?.length === 0) {
                            let message = `Invalid Response received`;
                            this.showModalMessages(message);
                            return Promise.reject(message);
                        }

                        //the following line is from legacy...
                        //var Node = g_xmlResponse.selectSingleNode("/MonitorResponse/EligibilityResponse/Eligibility/Status");
                        //  ... and the following code was reverse-engineered from legacy:
                        //let checkResponse = JSON.parse(statusResponse.Response).MonitorResponse.EligibilityResponse.Eligibility as IManualEligibilityCheckResponse;
                        //  ... however, the following code was adapted to parse data coming from the Test Harness
                        let checkResponse = JSON.parse(statusResponse.Response).Eligibility as IManualEligibilityCheckResponse;
                        this.setState({
                            manualEligibilityCheckResponse: checkResponse
                        });
                        return Promise.resolve(checkResponse); 
                    }

                    //should not get here
                    let message = `Failed to retrieve status, error: ${jsonResponse?.ErrorMessage || 'empty response'}`;
                    console.log(message);
                    return Promise.reject(message); 
                }
                else {
                    const rawData = await response.text();
                    const message = `Attempt to submit Manual Eligibility Check was unsuccessful -- status: ${response.status} : ${rawData}.`;
                    console.error(message);
                    return Promise.reject(message);
                }
            })
            .catch((error) => {
                const message = `Error while attempting to retrieve status, error: ${error}.`;
                console.error(message);
                return Promise.reject(message);
            });
    }

    private printResponse = async (): Promise<any> => {
        const transactionId = this.state.manualEligibilitySubmitResponse?.EligibilityTransactionId || 0;
        if (!this.props.canPrint ) { 
            const message = "insufficient privilege(s)";
            console.error(message);
            return message;
        }
        if (!(transactionId > 0)) { 
            const message = "invalid transaction ID";
            console.error(message);
            return message;
        }

        const endpoint = `${ApiClient.apiBaseURL}/data/Print271ByTrans/${transactionId}`; 
        return  ApiClient.jsonGetAsync(endpoint)
            .then(async (response: any) => {
                if (response.status === HttpStatus.OK) {
                    const jsonString = await response.json();
                    if (jsonString?.length > 0) {
                        let jsonResponse = JSON.parse(jsonString);
                        let printResponse: IPrintResponse = Array.isArray(jsonResponse.PrintResponses) ? 
                            jsonResponse.PrintResponses[0] :
                            jsonResponse.PrintResponses.PrintResponse;

                        if (printResponse["@Error"].trim().length > 0) {
                            console.error(`Error while trying to retrive print information: ${printResponse["@Error"] }`);
                        }
                        else if (printResponse["@Path"].trim().length === 0) {
                            console.error(`Error while trying to retrive print information: invalid path`);
                        }
                        else {
                            //ApiClient.openPDF(printResponse["@Path"]);
                            window.document.open(`/shared/Open=${encodeURIComponent(printResponse["@Path"])}`, 'FormAlignment', 'width=1273,height=1250');
                        }
                    }
                    else {
                        console.error(`No print information available.`);
                    }

                    return; 
                }
                else {
                    //check for error message
                    let message = await response.text();
                    console.error(`Attempt to request print data failed, status: ${response.status}; error message: ${ message || '' }.`);

                    return;
                }
            })
            .catch((error) => {
                console.error(`Error while attempting to request print, error: ${error}.`);
                return; 
            });

        return; 
    }

    private redirectToHome = () => {
        this.resetPageState(() => {
            this.props.history.push("/LandingPage", null);
        });
    }

    private refreshData = (): Promise<any> => {
        this.setState({
            isPageBusy: true,
        });

        return new Promise((resolve, reject) => {
            this.getFormPayerOptions()
                .then((selectPayerOptions: Array<IManualEligibilityCheckPayer>) => {
                    if (selectPayerOptions.length > 0) {
                        this.setState({
                            selectPayerOptions: selectPayerOptions
                        }, () => {
                            resolve(true);
                        })
                    }
                    else {
                        reject(`no data retrieved`);
                    }
                })
                .catch((error: string) => {
                    const message = `error trying to retrieve data for Payer options: ${error}`;
                    console.log(message);
                    reject(message);
                })
                .finally(() => {
                    console.log(`finally refreshData`);
                });
        });
    };

    private resetPageState = (callback?: () => void) => {

        //preserve data if possible so we don't have to make another call to the server
        const newState = this.state.selectPayerOptions.length > 1 ?
            {
                ...DEFAULT_STATE,
                selectPayerOptions: this.state.selectPayerOptions,
                selectedPayerOption: this.state.selectPayerOptions[0]
            }
            : { ...DEFAULT_STATE };

        this.setState(
            {
                ...newState 
            },
            () => {
                if (typeof (callback) === 'function') {
                    callback();
                }
            }
        );
    }

    /**
     * Business Logic to enable/disable controls based on selected payer.
     * @param selectedPayerOption
     */
    private selectPayerOption ( selectedPayerOption: IManualEligibilityCheckPayer ) {
        this.resetPageState();

        if (selectedPayerOption.value === Page.Default.selectedPayerOption.value) {
            this.setState({
                isPageBusy: false,
                selectedPayerOption: selectedPayerOption,
            });
            return;
        }

        //if we are here, then always enable "SERVICE_TYPE" and "PROVIDER_TYPE"
        this.setState({
            selectedPayerOption: selectedPayerOption,
            PROVIDER_TYPE: {
                value: Page.Default.selectProviderTypeOptions[0],
                isDisabled: false,
                rankingText: ""
            },
            SERVICE_TYPE: {
                value: "",
                isDisabled: false,
                rankingText: ""
            }
        });

        let field: IManualEligibilityCheckFormField;
        let formInputId: string;
        let element: HTMLElement | null;
        let elementRank: HTMLElement | null;
        let stateObject: IInputState | ISelectState | undefined; //TypeScript really does REALLY suck
        let initValue: string | ISelectOption; //because TypeScript
        let isDisabled: boolean;
        let requiredValue: string;
        let requiredSymbol: '*' | '';
        let rankingText: string;
        //
        for ( let i = 0, length = selectedPayerOption.Fields?.length ?? 0; i < length; i++ ) {
            field = selectedPayerOption.Fields![i]; //already checked that we have one or more Fields
            formInputId = field.FieldType;

            element = document.getElementById(`${formInputId}`);
            if (!element) {
                continue; //no element on page, so skip to next iteration
            }

            //get default value because all props were reset above (in resetPageState())
            stateObject = this.state[formInputId];
            if (typeof (stateObject) === 'undefined') {
                console.error(`state object "${stateObject}" not found in page state structure, but page element was found!`);
                continue; //skip to next iteration
            }
            initValue = stateObject.value;

            isDisabled = (field.DoNotSend === 'Yes');

            //this could possibly be simplified by removing if-statement because if element_RANK does not exist, then rankingText will not be used
            elementRank = document.getElementById(`${formInputId}_RANK`);
            if (typeof (elementRank) === 'undefined') {
                requiredSymbol = '';
                rankingText = '';
            }
            else {
                requiredValue = field.Required.toLocaleLowerCase();
                requiredSymbol = requiredValue === 'yes' || requiredValue === 'payer' ? '*' : '';
                rankingText = `${requiredSymbol} ${field.Ranking}`.trimLeft();
            }

            // idea for using setState with dynamic key borrowed from https://stackoverflow.com/questions/46771248/react-setstate-with-dynamic-key 
            // and https://stackoverflow.com/questions/29280445/reactjs-setstate-with-a-dynamic-key-name
            this.setState({
                [formInputId]: {
                    value: initValue,
                    isDisabled: isDisabled,
                    rankingText: rankingText,
                }
            });
        }
        //
        this.setState({isPageBusy:false});
    }

    private showModalMessages = (messages: Array<string> | string) => {
        if (messages.length === 0) {
            return;
        }

        let array: Array<string> = !Array.isArray(messages) ? [ messages ] : messages;
        
        if (array.length > 0) {
            let i = 0;
            const formattedMessage = <>{ array.map((message) => <div key={ ++i }>{ message }</div>) }</>; //because React

            let modalProps: IModalConfirmationProps = { ...this.state.modalProps };
            modalProps.isOpen = true;
            modalProps.formattedMessage = formattedMessage;
            modalProps.onDeny = () => this.closeModal();
            modalProps.alertMode = true;
            //
            this.setState({
                modalProps: modalProps
            });
        }
    }

    private async submitManualEligibilityCheckAsync (): Promise<any> {
        if (!this.props.canEdit) {
            const message = "insufficient privilege(s)";
            console.error(message);
            return Promise.reject(message);
        }
        if (!this.validateFormInputs()) { 
            const message = "Validation failed";
            console.error(message);
            return Promise.reject(message);
        }

        const endpoint = `${ApiClient.apiBaseURL}/data/Eligibility/SubmitManualEligibilityCheckAsync`;
        const payload = `{"EligibilityRequestDictionary": ${JSON.stringify(this.getFormInputAsJson())}}`; 

        return ApiClient.jsonPostAsync(endpoint, payload)
            .then(async (response: any) => {
                if (response.status === HttpStatus.OK) {
                    const jsonResponse = await response.json();
                    if (jsonResponse?.Result) {
                        let manualEligibilityResponse = jsonResponse?.Result as IManualEligibilityResponse;

                        if (manualEligibilityResponse.Successful === false ||
                            manualEligibilityResponse.EligibilityTransactionId <= 0 ||
                            manualEligibilityResponse.ErrorMessage.length > 0) {
                            let message = `Failed to initiate request, err: ${manualEligibilityResponse.ErrorMessage}`;
                            console.log(message);
                            return Promise.reject(message);
                        }
                        else {
                            this.setState({
                                manualEligibilitySubmitResponse: manualEligibilityResponse
                            });

                            return Promise.resolve(manualEligibilityResponse); 
                        }
                    }

                    //should not get here
                    let message = `Failed to initiate request, error: ${jsonResponse?.ErrorMessage || 'empty response'}`;
                    console.log(message);
                    return Promise.reject(message);
                }
                else {
                    const rawData = await response.text();
                    const message = `Attempt to submit Manual Eligibility Check was unsuccessful -- status: ${response.status} : ${rawData}.`;
                    console.error(message);
                    return Promise.reject(message);
                }
            })
            .catch((error) => {
                const message = `Error while attempting to submit manual eligibility request, error: ${error}.`;
                console.error(message);
                return Promise.reject(message);
            });
    }

    /**
     * Returns true if valid, or false if invalid
     * @param inputElement
     */
    private validateInputDateOfBirth = (inputElement: HTMLInputElement): boolean => {
        let validation: IValidationResponse;
        const targetValue = inputElement.value;

        //check if is valid date using date library
        if (ValidateDateString(targetValue)) {
            validation = LegacyValidateDate(targetValue);
            if (!validation.isValid) {
                this.showModalMessages(validation!.message as string);
                return false;
            }

            validation = LegacyValidateDateRange(targetValue);
            if (!validation.isValid) {
                this.showModalMessages(validation!.message as string);
                return false;
            }
        }

        return true;
    }

    private validateFormInputs = (): boolean => {
        let isValid = false;

        const payerInformation: IManualEligibilityCheckPayer = this.state.selectedPayerOption;
        const fields = payerInformation.Fields;

        let errorMessages: Array<string> = [];
        let field: IManualEligibilityCheckFormField;
        let element: HTMLElement | null;
        let stateObject: IInputState | ISelectState | undefined; //TypeScript really does REALLY suck
        let requiredValue = "";
        let key = "";
        let value = "";
        let beginDate: Date | string | number = "",
            endDate: Date | string | number = "";  //TypeScript really does REALLY, REALLY suck
        for (let i = 0, length = fields?.length || 0; i < length; i++) {
            field = fields![i];
            key = field.FieldType;

            element = document.getElementById(`${key}`);
            if (!element
                || element.nodeType !== Node.ELEMENT_NODE
                || element.classList.contains('disabled')) {
                continue; //skip to next iteration
            }

            stateObject = this.state[key];
            if (typeof (stateObject) === 'undefined') {
                console.error(`state object "${stateObject}" not found in page state structure, but page element was found!`);
                continue; //skip to next iteration
            }


            value = typeof (this.state[key].value) === 'string' ? this.state[key].value : this.state[key].value.value;

            //required
            requiredValue = field.Required.toLocaleLowerCase();
            if (requiredValue === 'yes' || requiredValue === 'payer') {
                if (value.trim().length === 0) {
                    errorMessages.push(`The '${field.DisplayName}' field is required.`);
                }
            }

            if (value.trim().length === 0) {
                continue;
            }

            //input text formatting
            if (element.tagName === "INPUT") { //
                if ( element.classList.contains('DateInput_input') ) {
                    if (!ValidateDateString(value)) {
                        errorMessages.push(`Please check '${field.DisplayName}' date validity.`);
                    }
                    else if (key === "BEGINNING_DOS") {
                        beginDate = new Date(value);
                    }
                    else if (key === "ENDING_DOS") {
                        endDate = new Date(value);
                    }
                }
                else if (key.endsWith('SS_NUMBER')) {
                    if (this.validateSSN(value) === "invalid format") {
                        errorMessages.push(this.text["error - SSN"]);
                    }
                }
            }
            //else if (element.tagName === "DIV" && element.classList.contains('dropdown')) { }
        }

        //TypeScript really does REALLY, REALLY sucks
        beginDate = typeof beginDate === "string" ? new Date(beginDate) : beginDate
        endDate = typeof endDate === "string" ? new Date(endDate) : endDate
        if (beginDate.valueOf() > 0 && endDate.valueOf() > 0 && beginDate > endDate) {
            errorMessages.push(`Please check DOS range.`);
        }

        if (errorMessages.length > 0) {
            isValid = false;
            this.showModalMessages(errorMessages);
        }
        else {
            isValid = true;
        }

        return isValid;
    }

    private validateSSN = (value: string): "invalid format" | "valid format" | "valid numbers" => {
        if (/^(\d{3}-\d{2}-\d{4})$/.test(value)) {
            return "valid format";
        }
        else if (/^\d{9}$/.test(value)) {
            return "valid numbers";
        }
        else {
            return "invalid format";
        }
    }


    //-----------------------------------------------------------
    //event handlers

    /**
     * generic on input (text) changed (onblur) event handler.
     * purpose: make input text uppercase
     * @param e
     */
    handleInputBlur (e: React.FocusEvent<HTMLInputElement> ) {
        let isValid = true;
        const targetId = e.currentTarget.id;
        const targetValue = e.currentTarget.value.trim();

        if (targetValue.length === 0) {
            return;
        }

        let formattedValue = targetValue.toUpperCase();
        //from legacy, there are input text boxes for user text entry of dates (bad idea), and it has special validation and business rules
        if (targetId.endsWith('DOB')) {
            isValid = (formattedValue.length > 0);
            if (isValid) {
                isValid = this.validateInputDateOfBirth(e.currentTarget as HTMLInputElement);

                if (isValid) {
                    //force ARM-specific date format
                    formattedValue = FormatDateAsString(isValid ? formattedValue : '');
                }
            }
        }
        //from legacy, 
        else if (targetId.endsWith('SS_NUMBER')) {
            let result = this.validateSSN(formattedValue);
            if (result === "invalid format") {
                isValid = false;
                this.showModalMessages(this.text["error - SSN"]);
            }
            else if (result === "valid numbers") {
                formattedValue = [formattedValue.slice(0, 3), formattedValue.slice(3, 5), formattedValue.slice(5)].join('-');
            }
        }

        if (isValid) {
            //idea for using setState with dynamic key borrowed from https://stackoverflow.com/questions/46771248/react-setstate-with-dynamic-key
            //and https://stackoverflow.com/questions/29280445/reactjs-setstate-with-a-dynamic-key-name
            let tmpStateObject = this.state[targetId];
            tmpStateObject.value = formattedValue;
            this.setState({
                [targetId]: tmpStateObject
            });
        }
    }

    handleInputChangedDOS (dateString: string) {
        let validation: IValidationResponse;

        //check if is valid date using date library
        if (dateString.trim().length > 0 && ValidateDateString(dateString)) {
            validation = LegacyValidateDate(dateString);
            if (!validation.isValid) {
              this.showModalMessages(validation!.message as string);
                return;
            }

            validation = LegacyValidateDateRange(dateString);
            if (!validation.isValid) {
                this.showModalMessages(validation!.message as string);
                return;
            }

            let tmpStateObject = this.state.BEGINNING_DOS;
            tmpStateObject.value = dateString as string;
            this.setState({
                BEGINNING_DOS: tmpStateObject
            });
        }
    }

    handleInputChangedEOS (dateString: string) {
        let validation: IValidationResponse;

        //check if is valid date using date library
        if (dateString.trim().length > 0 && ValidateDateString(dateString)) {
            validation = LegacyValidateDate(dateString);
            if (!validation.isValid) {
                this.showModalMessages(validation!.message as string);
                return;
            }

            validation = LegacyValidateDateRange(dateString);
            if (!validation.isValid) {
                this.showModalMessages(validation!.message as string);
                return;
            }

            let tmpStateObject = this.state.ENDING_DOS;
            tmpStateObject.value = dateString as string;
            this.setState({
                ENDING_DOS: tmpStateObject
            });
        }
    }

    /**
     * generic button click event handler.
     * 
     * @param e
     */
    handleOnClick (e: React.MouseEvent<HTMLButtonElement>) {
        //The UICL component "Button" does not pass the expected standard event type with a value for the button clicked
        this.setState({
            isPageBusy: true
        });
        const psuedoValue = e.currentTarget.textContent;
        switch (psuedoValue) {
            case this.text.Submit:
                this.submitManualEligibilityCheckAsync()
                    .then((response: IManualEligibilityResponse) => {
                        if ((response?.EligibilityTransactionId || 0) > 0) {
                            return this.getManualEligibilityCheckStatus(response.EligibilityTransactionId);
                        }
                        let message = `Invalid transaction ID`;
                        console.log(message);
                    })
                    .then(() => {
                        this.setState({
                            selectedTabIndex: 1,
                        }, () => {
                            const secondTab = document.getElementById(this.tabs[1].domID) as HTMLElement;
                            secondTab.click();
                        });
                    })
                    .catch((message: string) => {
                        this.setState({
                            selectedTabIndex: 0,
                        });
                    })
                    .finally(() => {
                        this.setState({
                            isPageBusy: false,
                        });
                    });
                break;
            case this.text.Clear:
                this.resetPageState(() => this.setState({isPageBusy: false}));
                break;
            case this.text.Print:
                this.printResponse()
                    .finally(() => {
                        this.setState({
                            isPageBusy: false,
                        });
                    });
                break;
            default:
                console.error(`Unknown action in 'handleOnClick': "${psuedoValue}"`)
                this.setState({
                    isPageBusy: false
                });
                break;
        }
    }

    //select-option change event handlers
    /**
     * The UICL component "SelectDropdown" does not pass the expected TypeScript React.ChangeEvent<HTMLSelectElement> type.
     * Instead, it returns an ISelectOption object (whatever object type is used for the source options array).
     * @param e
     */
    public onSelectInsuredGenderOption = (e: SelectDropdownValue ) => {
        const targetLabel = e.label ??'';
        const targetValue = e.value ?? '';
        const rankingText = this.state.INSURED_GENDER.rankingText;

        this.setState( {
            INSURED_GENDER: {
                value: {
                    label: targetLabel,
                    value: targetValue
                },
                isDisabled: false,
                rankingText: rankingText
            }
        });
    };

    /**
     * The UICL component "SelectDropdown" does not pass the expected TypeScript React.ChangeEvent<HTMLSelectElement> type.
     * Instead, it returns an ISelectOption object (whatever object type is used for the source options array).
     * @param e
     */
    public onSelectPatientGenderOption = (e: SelectDropdownValue ) => {
        const targetLabel = e.label ??'';
        const targetValue = e.value ?? '';
        const rankingText = this.state.PATIENT_GENDER.rankingText;

        this.setState( {
            PATIENT_GENDER: {
                value: {
                    label: targetLabel,
                    value: targetValue
                },
                isDisabled: false,
                rankingText: rankingText
            }
        });
    };

    /**
     * The UICL component "SelectDropdown" does not pass the expected TypeScript React.ChangeEvent<HTMLSelectElement> type.
     * Instead, it returns an ISelectOption object (whatever object type is used for the source options array).
     * @param e
     */
    public onSelectPatientRelationshipOption = (e: SelectDropdownValue ) => {
        const targetLabel = e.label ??'';
        const targetValue = e.value ?? '';
        const rankingText = this.state.PATIENT_RELATIONSHIP.rankingText;

        this.setState( {
            PATIENT_RELATIONSHIP: {
                value: {
                    label: targetLabel,
                    value: targetValue
                },
                isDisabled: false,
                rankingText: rankingText
            }
        });
    };


    /**
    * The UICL component "SelectDropdown" does not pass the expected TypeScript React.ChangeEvent<HTMLSelectElement> type.
    * Instead, it returns an IManualEligibilityCheckPayer object (whatever object type is used for the source options array).
    * @param e
    */
    public onSelectPayerOption = ( e: IManualEligibilityCheckPayer ) => {
        this.selectPayerOption( e ); 
    };

    /**
     * The UICL component "SelectDropdown" does not pass the expected TypeScript React.ChangeEvent<HTMLSelectElement> type.
     * Instead, it returns an ISelectOption object (whatever object type is used for the source options array).
     * @param e
     */
    public onSelectProviderTypeOption = (e: SelectDropdownValue ) => {
        const targetLabel = e.label ?? ''; 
        const targetValue = e.value ?? '';
        const rankingText = this.state.PROVIDER_TYPE.rankingText;
        
        this.setState( {
            PROVIDER_TYPE: {
                value: {
                    label: targetLabel,
                    value: targetValue
                },
                isDisabled: false,
                rankingText: rankingText
            }
        });
    };

    public onSelectTab = (event: React.SyntheticEvent, index: { selectedTabIndex: number | ((prevState: number) => number); }) => {
        //not trusting the second parameter of the component that raised the event
        let selectedTabId = event.currentTarget.id;
        let selectedIndex = this.tabs.findIndex(t => t.domID === selectedTabId);

        this.setState(
            {
                isPageBusy: true,
                selectedTabIndex: selectedIndex
            },
            () => {
                this.setState({ isPageBusy: false });
            }
        );
    }

    //-----------------------------------------------------------
    //rendering
    renderModal = () => {

        return (
            <ModalConfirmation
                isOpen={this.state.modalProps.isOpen}
                showDefaultClose={true}
                formattedMessage={
                    <div>{this.state.modalProps.formattedMessage || this.state.modalProps.message}</div>
                }
                onModalToggle={this.closeModal}
                onOK={this.state.modalProps.onOK || this.closeModal}
                onConfirm={this.state.modalProps.onConfirm}
                onDeny={this.state.modalProps.onDeny || this.closeModal}
                alertMode={this.state.modalProps.alertMode || false}
                okText={this.state.modalProps.okText || this.text.Ok}
                cancelText={this.state.modalProps.cancelText || this.text.Cancel}
            />
        );
    }

    renderPageButtons = () => {
        let defaultSelected = this.state.selectedPayerOption.value === Page.Default.selectedPayerOption.value;
        let isPrintDisabled = !this.props.canPrint || defaultSelected || !((this.state.manualEligibilitySubmitResponse?.EligibilityTransactionId || 0) > 0);

        return (
            <>
                { this.state.selectedTabIndex === 0 &&
                <>
                    <Button
                        domID={Page.Selectors.idButtonSubmit}
                        name={this.text.Submit}
                        {...Buttons.Props.PageDefault}
                        disabled={ !this.props.canEdit || defaultSelected }
                        onClick={this.handleOnClick}
                        buttonType='emphasized'
                        size='control'
                    />
                    <Button
                        domID={Page.Selectors.idButtonClear}
                        name={this.text.Clear}
                        {...Buttons.Props.PageDefault}
                        disabled={defaultSelected}
                        onClick={this.handleOnClick}
                        buttonType='standard'
                        size='control'
                    />
                </>
                }
                { this.state.selectedTabIndex === 1 &&
                    <Button
                        domID={ Page.Selectors.idButtonPrint }
                        name={ this.text.Print }
                        { ...Buttons.Props.PageDefault }
                        disabled={ isPrintDisabled }
                        onClick={ this.handleOnClick }
                        size='control'
                        buttonType='emphasized'
                        style={{
                            
                          }}
                    />
                }
            </>
        );
    }

    renderTabFormInput = () => {
        const columnStyle = { width: "50%", padding: "1px", margin: "0"};
        const topColumnStyle = { width: "50%", padding: "1px", marginTop: "10px", marginLeft: "5px" };
        const inputLabelStyle = { marginBottom: "5px", fontFamily: 'optum-sans-regular' };
        return (
            <>
            <ManualEligibilityCheckWrapper>
                    <div style={{ ...topColumnStyle }}>
                        <LabelStyles.InputText inline={false} style={inputLabelStyle}>PAYER:</LabelStyles.InputText>
                    <SelectDropdown
                        domID={ Page.Selectors.idSelectPayer }
                        className="dropdown"
                        size='medium'
                        isClearable={ false }
                        isSearchable={ true }
                        onChange={ this.onSelectPayerOption }
                        options={ this.state.selectPayerOptions }
                        initialValue={ this.state.selectedPayerOption }
                        disabled={ false }
                    />
                </div>
                </ManualEligibilityCheckWrapper>
                
                <DialogFieldset id={'manualelgcheck-dialogfieldset'} height='100%' width='950px'>
                    <DialogLegend>{ this.text["form - general legend title"] }</DialogLegend>
                    <ContentRowWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.NPI.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                NPI:
                                <span id="NPI_RANK" className={ this.state.NPI.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >{ this.state.NPI.rankingText }</span>
                            </LabelStyles.InputText>
                            <Input
                                className="margin-padding"
                                domID="NPI"
                                maxLength={ 10 }
                                onBlur={ this.handleInputBlur }
                                initialValue={ this.state.NPI.value }
                                disabled={ this.state.NPI.isDisabled }
                            />
                        </ContentColumnWrapper>
                         <ContentColumnWrapper style={ columnStyle }>
                            <ContentRowWrapper>
                                <ContentColumnWrapper >
                                    <LabelStyles.InputText className={this.state.BEGINNING_DOS.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                        Beginning DOS:
                                        <span id="BEGINNING_DOS_RANK" className={ this.state.BEGINNING_DOS.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                            { this.state.BEGINNING_DOS.rankingText }
                                        </span>
                                    </LabelStyles.InputText>
                                    <DatePicker
                                        domID="BEGINNING_DOS"
                                        className="cal_style"
                                        onInputChange={ this.handleInputChangedDOS }
                                        allowTime={ false }
                                        minDate={ this.minDate.toLocaleDateString() }
                                        maxDate={ this.state.ENDING_DOS.value }
                                        assocTo={ this.state.ENDING_DOS.value }
                                        initialDate={ this.state.BEGINNING_DOS.value }
                                        disableHowLong={ true }
                                        disabled={ this.state.BEGINNING_DOS.isDisabled }
                                    />
                                </ContentColumnWrapper>
                                <ContentColumnWrapper >
                                    <LabelStyles.InputText className={this.state.ENDING_DOS.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                        Ending DOS:
                                        <span id="ENDING_DOS_RANK" className={ this.state.ENDING_DOS.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                            { this.state.ENDING_DOS.rankingText }
                                        </span>
                                    </LabelStyles.InputText>
                                    <DatePicker
                                        domID="ENDING_DOS"
                                        className="cal_style"
                                        onInputChange={ this.handleInputChangedEOS }
                                        allowTime={ false }
                                        minDate={ this.state.BEGINNING_DOS.value }
                                        maxDate={ this.maxDate.toLocaleDateString() }
                                        assocFrom={ this.state.BEGINNING_DOS.value }
                                        initialDate={ this.state.ENDING_DOS.value }
                                        disableHowLong={ true }
                                        disabled={ this.state.ENDING_DOS.isDisabled }
                                    />
                                </ContentColumnWrapper>
                            </ContentRowWrapper>
                        </ContentColumnWrapper>
                    </ContentRowWrapper>
                    <ManualEligibilityCheckWrapper>
                        <ContentRowWrapper>                         
                            <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.PROVIDER_ID.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                Provider ID:
                                <span id="PROVIDER_ID_RANK" className={ this.state.PROVIDER_ID.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.PROVIDER_ID.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <Input
                                className='margin-padding'
                                domID="PROVIDER_ID"
                                maxLength={ 15 }
                                onBlur={ this.handleInputBlur }
                                initialValue={ this.state.PROVIDER_ID.value }
                                disabled={ this.state.PROVIDER_ID.isDisabled }
                            />
                        </ContentColumnWrapper>
                            <ContentColumnWrapper style={{...columnStyle, marginTop: "4px"}}>
                                 <LabelStyles.InputText className={this.state.PROVIDER_TYPE.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                Provider Type:
                            </LabelStyles.InputText>
                                <SelectDropdown
                                domID="PROVIDER_TYPE"
                                className="dropdown fullwidth_dropdown"
                                size='medium'
                                isClearable={ false }
                                isSearchable={ true } 
                                onChange={ this.onSelectProviderTypeOption }
                                options={ this.state.selectProviderTypeOptions }
                                initialValue={ this.state.PROVIDER_TYPE.value }
                                disabled={ this.state.PROVIDER_TYPE.isDisabled }
                                />
                                 </ContentColumnWrapper>
                           
                        </ContentRowWrapper>
                    </ManualEligibilityCheckWrapper>
                </DialogFieldset>
                <ManualEligibilityCheckWrapper>
                    <DialogFieldset width='950px'>
                    <DialogLegend>{ this.text["form - subscriber legend title"] }</DialogLegend>
                    <ContentRowWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.INSURED_MEDICAID_ID.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                Insured ID:
                                <span id="INSURED_MEDICAID_ID_RANK" className={ this.state.INSURED_MEDICAID_ID.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.INSURED_MEDICAID_ID.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <Input
                                className='margin-padding'
                                domID="INSURED_MEDICAID_ID"
                                maxLength={ 16 }
                                onBlur={ this.handleInputBlur }
                                initialValue={ this.state.INSURED_MEDICAID_ID.value }
                                disabled={ this.state.INSURED_MEDICAID_ID.isDisabled }
                            />
                        </ContentColumnWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.DOB.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                DOB:
                                <span id="DOB_RANK" className={ this.state.DOB.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.DOB.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <Input
                                className='margin-padding'
                                domID="DOB"
                                maxLength={ 10 }
                                onBlur={ this.handleInputBlur }
                                initialValue={ this.state.DOB.value }
                                disabled={ this.state.DOB.isDisabled }
                            />
                        </ContentColumnWrapper>
                    </ContentRowWrapper>
                    <ContentRowWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.FIRST_NAME.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                First Name:
                                <span id="FIRST_NAME_RANK" className={ this.state.FIRST_NAME.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.FIRST_NAME.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <Input
                                className='margin-padding'
                                domID="FIRST_NAME"
                                maxLength={ 25 }
                                onBlur={ this.handleInputBlur }
                                initialValue={ this.state.FIRST_NAME.value }
                                disabled={ this.state.FIRST_NAME.isDisabled }
                            />
                        </ContentColumnWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.MIDDLE_INITIAL.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                Middle Initial:
                                <span id="MIDDLE_INITIAL_RANK" className={ this.state.MIDDLE_INITIAL.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.MIDDLE_INITIAL.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <Input
                                className='margin-padding'
                                domID="MIDDLE_INITIAL"
                                maxLength={ 1 }
                                onBlur={ this.handleInputBlur }
                                initialValue={ this.state.MIDDLE_INITIAL.value }
                                disabled={ this.state.MIDDLE_INITIAL.isDisabled }
                            />
                        </ContentColumnWrapper>
                    </ContentRowWrapper>
                    <ContentRowWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.LAST_NAME.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                Last Name:
                                <span id="LAST_NAME_RANK" className={ this.state.LAST_NAME.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.LAST_NAME.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <Input
                                className='margin-padding'
                                domID="LAST_NAME"
                                maxLength={ 35 }
                                onBlur={ this.handleInputBlur }
                                initialValue={ this.state.LAST_NAME.value }
                                disabled={ this.state.LAST_NAME.isDisabled }
                            />
                        </ContentColumnWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.INSURED_GENDER.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                Gender:
                                <span id="INSURED_GENDER_RANK" className={ this.state.INSURED_GENDER.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.INSURED_GENDER.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <SelectDropdown
                                domID="INSURED_GENDER"
                                className="dropdown fullwidth_dropdown"
                                size='medium'
                                isClearable={ false }
                                isSearchable={ true }
                                onChange={ this.onSelectInsuredGenderOption }
                                options={ this.state.selectInsuredGenderOptions }
                                initialValue={ this.state.INSURED_GENDER.value }
                                disabled={ this.state.INSURED_GENDER.isDisabled }
                            />
                        </ContentColumnWrapper>
                    </ContentRowWrapper>
                    <ContentRowWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.SS_NUMBER.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                Social Security #:
                                <span id="SS_NUMBER_RANK" className={ this.state.SS_NUMBER.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.SS_NUMBER.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <Input
                                className='margin-padding'
                                domID="SS_NUMBER"
                                maxLength={ 11 }
                                onBlur={ this.handleInputBlur }
                                initialValue={ this.state.SS_NUMBER.value }
                                disabled={ this.state.SS_NUMBER.isDisabled }
                            />
                        </ContentColumnWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.GROUP_NUMBER.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                Group Number:
                                <span id="GROUP_NUMBER_RANK" className={ this.state.GROUP_NUMBER.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.GROUP_NUMBER.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <Input
                                className='margin-padding'
                                domID="GROUP_NUMBER"
                                maxLength={ 20 }
                                onBlur={ this.handleInputBlur }
                                initialValue={ this.state.GROUP_NUMBER.value }
                                disabled={ this.state.GROUP_NUMBER.isDisabled }
                            />
                        </ContentColumnWrapper>
                    </ContentRowWrapper>
                    <ContentRowWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.PATIENT_RELATIONSHIP.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                Relationship to Insured:
                                <span id="PATIENT_RELATIONSHIP_RANK" className={ this.state.PATIENT_RELATIONSHIP.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.PATIENT_RELATIONSHIP.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <SelectDropdown
                                domID="PATIENT_RELATIONSHIP"
                                className="dropdown fullwidth_dropdown"
                                size='medium'
                                isClearable={ false }
                                isSearchable={ true }
                                onChange={ this.onSelectPatientRelationshipOption }
                                options={ this.state.selectPatientRelationshipOptions }
                                initialValue={ this.state.PATIENT_RELATIONSHIP.value }
                                disabled={ this.state.PATIENT_RELATIONSHIP.isDisabled }
                            />
                        </ContentColumnWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.PLAN_CODE.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                Plan Code:
                                <span id="PLAN_CODE_RANK" className={ this.state.PLAN_CODE.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.PLAN_CODE.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <Input
                                className='margin-padding'
                                domID="PLAN_CODE"
                                maxLength={ 30 }
                                onBlur={ this.handleInputBlur }
                                initialValue={ this.state.PLAN_CODE.value }
                                disabled={ this.state.PLAN_CODE.isDisabled }
                            />
                        </ContentColumnWrapper>
                    </ContentRowWrapper>
                </DialogFieldset>
                </ManualEligibilityCheckWrapper>
                <ManualEligibilityCheckWrapper>
                <DialogFieldset width='950px'>
                    <DialogLegend>{ this.text["form - dependent legend title"] }</DialogLegend>
                    <ContentRowWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.DEPENDENT_FIRST_NAME.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                First Name:
                                <span id="DEPENDENT_FIRST_NAME_RANK" className={ this.state.DEPENDENT_FIRST_NAME.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.DEPENDENT_FIRST_NAME.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <Input
                                className='margin-padding'
                                domID="DEPENDENT_FIRST_NAME"
                                maxLength={ 25 }
                                onBlur={ this.handleInputBlur }
                                initialValue={ this.state.DEPENDENT_FIRST_NAME.value }
                                disabled={ this.state.DEPENDENT_FIRST_NAME.isDisabled }
                            />
                        </ContentColumnWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.DEPENDENT_MIDDLE_INI.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                Middle Initial:
                                <span id="DEPENDENT_MIDDLE_INI_RANK" className={ this.state.DEPENDENT_MIDDLE_INI.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.DEPENDENT_MIDDLE_INI.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <Input
                                className='margin-padding'
                                domID="DEPENDENT_MIDDLE_INI"
                                maxLength={ 1 }
                                onBlur={ this.handleInputBlur }
                                initialValue={ this.state.DEPENDENT_MIDDLE_INI.value }
                                disabled={ this.state.DEPENDENT_MIDDLE_INI.isDisabled }
                            />
                        </ContentColumnWrapper>
                    </ContentRowWrapper>
                    <ContentRowWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.DEPENDENT_LAST_NAME.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                Last Name:
                                <span id="DEPENDENT_LAST_NAME_RANK" className={ this.state.DEPENDENT_LAST_NAME.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.DEPENDENT_LAST_NAME.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <Input
                                className='margin-padding'
                                domID="DEPENDENT_LAST_NAME"
                                maxLength={ 35 }
                                onBlur={ this.handleInputBlur }
                                initialValue={ this.state.DEPENDENT_LAST_NAME.value }
                                disabled={ this.state.DEPENDENT_LAST_NAME.isDisabled }
                            />
                        </ContentColumnWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.DEPENDENT_DOB.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                DOB:
                                <span id="DEPENDENT_DOB_RANK" className={ this.state.DEPENDENT_DOB.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.DEPENDENT_DOB.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <Input
                                className='margin-padding'
                                domID="DEPENDENT_DOB"
                                maxLength={ 10 }
                                onBlur={ this.handleInputBlur }
                                initialValue={ this.state.DEPENDENT_DOB.value }
                                disabled={ this.state.DEPENDENT_DOB.isDisabled }
                            />
                        </ContentColumnWrapper>
                    </ContentRowWrapper>
                    <ContentRowWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.PAT_SS_NUMBER.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                Social Security #:
                                <span id="PAT_SS_NUMBER_RANK" className={ this.state.PAT_SS_NUMBER.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.PAT_SS_NUMBER.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <Input
                                className='margin-padding'
                                domID="PAT_SS_NUMBER"
                                maxLength={ 11 }
                                onBlur={ this.handleInputBlur }
                                initialValue={ this.state.PAT_SS_NUMBER.value }
                                disabled={ this.state.PAT_SS_NUMBER.isDisabled }
                            />
                        </ContentColumnWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.PATIENT_GENDER.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                Gender:
                                <span id="PATIENT_GENDER_RANK" className={ this.state.PATIENT_GENDER.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.PATIENT_GENDER.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <SelectDropdown
                                domID="PATIENT_GENDER"
                                className="dropdown fullwidth_dropdown"
                                size='medium'
                                isClearable={ false }
                                isSearchable={ true }
                                onChange={ this.onSelectPatientGenderOption }
                                options={ this.state.selectPatientGenderOptions }
                                initialValue={ this.state.PATIENT_GENDER.value }
                                disabled={ this.state.PATIENT_GENDER.isDisabled }
                            />
                        </ContentColumnWrapper>
                    </ContentRowWrapper>
                    </DialogFieldset>
                </ManualEligibilityCheckWrapper>
                <ManualEligibilityCheckWrapper>
                    <DialogFieldset id={'additional-dialogfieldset'} width='950px'>
                    <DialogLegend>{ this.text["form - additional legend title"] }</DialogLegend>
                    <ContentRowWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText inline marginBottom="0" className={this.state.SERVICE_TYPE.isDisabled ? 'EligibilityLabelDisabled' : ''} style={{ ...inputLabelStyle, display: "flex", alignItems: "center", gap: "5px" }}>
                                Service Type:
                                    <HelpIcon
                                    title="Additional Information, Service Type "
                                    helpUrl="/Support/Help/HELP_DYN.asp?10"
                                    style={{ marginLeft: "5px" }}
                                />
                            </LabelStyles.InputText>
                            <Input
                                className='margin-padding'
                                domID="SERVICE_TYPE"
                                maxLength={ 35 }
                                onBlur={ this.handleInputBlur }
                                initialValue={ this.state.SERVICE_TYPE.value }
                                disabled={ this.state.SERVICE_TYPE.isDisabled }
                            />
                        </ContentColumnWrapper>
                        <ContentColumnWrapper style={{...columnStyle, marginTop: "-4px" }}>
                            <LabelStyles.InputText className={this.state.STATE_CODE.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false}  style={inputLabelStyle}>
                                State Code:
                                <span id="STATE_CODE_RANK" className={ this.state.STATE_CODE.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.STATE_CODE.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <Input
                                className='margin-padding'
                                domID="STATE_CODE"
                                maxLength={ 2 }
                                onBlur={ this.handleInputBlur }
                                initialValue={ this.state.STATE_CODE.value }
                                disabled={ this.state.STATE_CODE.isDisabled }
                            />
                        </ContentColumnWrapper>
                    </ContentRowWrapper>
                    <ContentRowWrapper>
                        <ContentColumnWrapper style={ columnStyle }>
                            <LabelStyles.InputText className={this.state.CARD_NUMBER.isDisabled ? 'EligibilityLabelDisabled' : ''} inline={false} style={inputLabelStyle}>
                                Card Number:
                                <span id="CARD_NUMBER_RANK" className={ this.state.CARD_NUMBER.isDisabled ? 'EligibilityRankDisabled' : 'EligibilityRank' } >
                                    { this.state.CARD_NUMBER.rankingText }
                                </span>
                            </LabelStyles.InputText>
                            <Input
                                className='margin-padding'
                                domID="CARD_NUMBER"
                                maxLength={ 30 }
                                onBlur={ this.handleInputBlur }
                                initialValue={ this.state.CARD_NUMBER.value }
                                disabled={ this.state.CARD_NUMBER.isDisabled }
                            />
                        </ContentColumnWrapper>
                        <ContentColumnWrapper style={ columnStyle }>&nbsp;</ContentColumnWrapper>
                    </ContentRowWrapper>
                </DialogFieldset>
                </ManualEligibilityCheckWrapper>
                </>
        );
    }

    renderTabResponse = () => {
        const responseDetails = this.renderTabResponseDetails();

        return (
            <>
                {/********************************************************************************************************/}
                {/* status information */}
                <DialogFieldset height='100%' width='950px'>
                    <DialogLegend>{ this.text["results - status information"] }</DialogLegend>
                    <table className="EligibilityResponse">
                        <tbody>
                            <tr>
                                <td className="Field Label small">EB Code:</td>
                                <td className="Field small">{ this.state.manualEligibilityCheckResponse?.Status?.["@BenefitsCode"] }</td>
                                <td className="Field Label">Desc:</td>
                                <td className="Field">{ this.state.manualEligibilityCheckResponse?.Status?.["@BenefitsDesc"] }</td>
                            </tr>
                            <tr>
                                <td className="Field Label small">Reject Code:</td>
                                <td className="Field small">{ this.state.manualEligibilityCheckResponse?.Status?.["@RejectCode"] }</td>
                                <td className="Field Label">Desc:</td>
                                <td className="Field">{ this.state.manualEligibilityCheckResponse?.Status?.["@RejectDesc"] }</td>
                            </tr>
                            <tr>
                                <td className="Field Label small">Action Code:</td>
                                <td className="Field small">{ this.state.manualEligibilityCheckResponse?.Status?.["@ActionCode"] }</td>
                                <td className="Field Label">Desc:</td>
                                <td className="Field">{ this.state.manualEligibilityCheckResponse?.Status?.["@ActionDesc"] }</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="EligibilityResponse">
                        <tbody>
                            <tr>
                                <td className="Field Label small" >Provider ID:</td>
                                <td className="Field large" >{ this.state.manualEligibilityCheckResponse?.Status?.["@ProviderID"] }</td>
                                <td className="Field Label small" >Provider NPI:</td>
                                <td className="Field large" >{ this.state.manualEligibilityCheckResponse?.Status?.["@ProviderNPI"] }</td>
                            </tr>
                            <tr>
                                <td className="Field Label small" align="right">Payer Name:</td>
                                <td className="Field large" >{ this.state.manualEligibilityCheckResponse?.Status?.["@PayerName"] }</td>
                            </tr>
                        </tbody>
                    </table>
                </DialogFieldset>
                
                {/********************************************************************************************************/}
                {/* insured information */}
                <DialogFieldset height='100%' width='950px'>
                    <DialogLegend>{ this.text["results - insured information"] }</DialogLegend>
                    <table className="EligibilityResponse">
                        <tbody>
                            <tr>
                                <td className="Field Label large" >Name (Last, First Middle):</td>
                                <td className="Field x-large" >{ this.state.manualEligibilityCheckResponse?.Response?.["@InsName"] }</td>
                                <td className="Field x-small">Sex:&nbsp;&nbsp;
                                    { this.state.manualEligibilityCheckResponse?.Response?.["@InsSex"] }</td>
                                <td className="Field x-small">DOB:&nbsp;&nbsp;
                                    { FormatDateAsString(this.state.manualEligibilityCheckResponse?.Response?.["@InsDOB"] as string) }</td>
                            </tr>
                            <tr>
                                <td className="Field Label large" >SSN:</td>
                                <td className="Field large" >{ this.state.manualEligibilityCheckResponse?.Response?.["@InsSSN"] || '' }</td>
                                <td className="Field large" colSpan={ 2 } >ID:&nbsp;&nbsp;
                                    { this.state.manualEligibilityCheckResponse?.Response?.["@InsCert"] }</td>
                            </tr>
                            <tr>
                                <td className="Field Label large" >Address 1:</td>
                                <td className="Field x-large" >{ this.state.manualEligibilityCheckResponse?.Response?.["@InsAddress1"] }</td>
                                <td className="Field" colSpan={ 2 } >{/* must use braces for colSpan because TypeScript sucks*/ } Address 2:&nbsp;&nbsp;
                                    { this.state.manualEligibilityCheckResponse?.Response?.["@InsAddress2"] }</td>
                            </tr>
                            <tr>
                                <td className="Field Label large" >City:</td>
                                <td className="Field large" >{ this.state.manualEligibilityCheckResponse?.Response?.["@InsCity"] }</td>
                                <td className="Field x-small" >St:&nbsp;&nbsp;
                                    { this.state.manualEligibilityCheckResponse?.Response?.["@InsState"] }</td>
                                <td className="Field small" >Zip:&nbsp;&nbsp;
                                    { this.state.manualEligibilityCheckResponse?.Response?.["@InsZip"] }</td>
                            </tr>
                            <tr>
                                <td className="Field Label large" >Group No:</td>
                                <td className="Field large" >{ this.state.manualEligibilityCheckResponse?.Response?.["@GroupNo"] }</td>
                                <td className="Field small" colSpan={ 2 } >Plan Code:&nbsp;&nbsp;
                                    { this.state.manualEligibilityCheckResponse?.Response?.["@PlanCode"] }</td>
                            </tr>
                            <tr>
                                <td className="Field Label large" >Policy No:</td>
                                <td className="Field large" >{ this.state.manualEligibilityCheckResponse?.Response?.["@PolicyNo"] }</td>
                                <td className="Field small" colSpan={ 2 } >&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </DialogFieldset>

                {/********************************************************************************************************/}
                {/* patient information */}
                <DialogFieldset height='100%' width='950px'>
                    <DialogLegend>{ this.text["results - patient information"] }</DialogLegend>
                    <table className="EligibilityResponse">
                        <tbody>
                            <tr>
                                <td className="Field Label large" >Name (Last, First Middle):</td>
                                <td className="Field x-large" >{ this.state.manualEligibilityCheckResponse?.Response?.["@PatName"] }</td>
                                <td className="Field x-small" >Sex:&nbsp;&nbsp;
                                    { this.state.manualEligibilityCheckResponse?.Response?.["@PatSex"] }</td>
                                <td className="Field medium" >Rel to Ins:&nbsp;&nbsp;
                                    { this.state.manualEligibilityCheckResponse?.Response?.["@RelationshipToInsured"] }</td>
                                <td className="Field small" >DOB:&nbsp;&nbsp;
                                    { FormatDateAsString(this.state.manualEligibilityCheckResponse?.Response?.["@PatDOB"] as string) }</td>
                            </tr>
                            <tr>
                                <td className="Field Label large" >SSN:</td>
                                <td className="Field large" >{ this.state.manualEligibilityCheckResponse?.Response?.["@PatSSN"] }</td>
                                <td className="Field large" colSpan={ 3 } >ID:&nbsp;&nbsp;
                                    { this.state.manualEligibilityCheckResponse?.Response?.["@PatCert"] }</td>
                            </tr>
                            <tr>
                                <td className="Field Label large" >Address 1:</td>
                                <td className="Field large" >{ this.state.manualEligibilityCheckResponse?.Response?.["@PatAddress1"] }</td>
                                <td className="Field" colSpan={ 3 } >Address 2:&nbsp;&nbsp;
                                    { this.state.manualEligibilityCheckResponse?.Response?.["@PatAddress2"] }</td>
                            </tr>
                            <tr>
                                <td className="Field Label large" >City:</td>
                                <td className="Field large" >{ this.state.manualEligibilityCheckResponse?.Response?.["@PatCity"] }</td>
                                <td className="Field x-small" >St:&nbsp;&nbsp;
                                    { this.state.manualEligibilityCheckResponse?.Response?.["@PatState"] }</td>
                                <td className="Field x-small" colSpan={ 2 } >Zip:&nbsp;&nbsp;
                                    { this.state.manualEligibilityCheckResponse?.Response?.["@PatZip"] }</td>
                            </tr>
                        </tbody>
                    </table>
                </DialogFieldset>

                {/********************************************************************************************************/}
                {/* service date */ }
                <DialogFieldset width='950px'>
                    <table className="EligibilityResponse" >
                        <tbody>
                            <tr>
                                <td className="Field Label large" >Service From:</td>
                                <td className="Field x-large" >{ FormatDateAsString(this.state.manualEligibilityCheckResponse?.Response?.["@ServiceFrom"] as string) }</td>
                                <td className="Field" >Service Thru:&nbsp;&nbsp;</td>
                                <td className="Field x-large" >{ FormatDateAsString(this.state.manualEligibilityCheckResponse?.Response?.["@ServiceThru"] as string) }</td>
                            </tr>
                        </tbody>
                    </table>
                </DialogFieldset>

                {/********************************************************************************************************/}
                {/* details */}
                <DialogFieldset height='100%' width='950px'>
                    <DialogLegend>{ this.text["results - details"] }</DialogLegend>
                    { responseDetails }
                </DialogFieldset>
            </>
        );
    }

    renderTabResponseDetails = () => {
        let responseDetails = this.state.manualEligibilityCheckResponse?.Response?.ResponseDetails?.ResponseDetail;
        let array = (responseDetails && !Array.isArray(responseDetails)) ?
            [responseDetails as IManualEligibilityResponseDetail] :
            responseDetails as Array<IManualEligibilityResponseDetail>;

        return (
            <table className="EligibilityResponse" style={{ width: "100%", backgroundColor:"white" }} cellPadding={3}>
                <thead>
                    <tr>
                        <th style={{ width: "15%" }} >&nbsp;</th>
                        <th style={{ width: "12%" }} >&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>{ array?.length > 0 &&
                    array.map((details: IManualEligibilityResponseDetail) => {
                        return(
                            <>
                            <tr><td colSpan={ 3 } align="center">-------------------- { details["@ServiceTypeDesc"] } Details --------------------</td></tr>
                            <tr><td>Benefit Information</td><td align="right">{ details["@EBCode"] }</td><td>{ details["@EBDesc"] }</td></tr>
                            <tr><td>Coverage Type</td><td align="right">{ details["@Coverage"] }</td><td>{ details["@CoverageDesc"] }</td></tr>
                            <tr><td>Service Type</td><td align="right">{ details["@ServiceType"] }</td><td>{ details["@ServiceTypeDesc"] }</td></tr>
                            <tr><td>Insurance Type</td><td align="right">{ details["@InsuranceType"] }</td><td>{ details["@InsuranceTypeDesc"] }</td></tr>
                            <tr><td>Time Period</td><td align="right">{ details["@TimePeriodCode"] }</td><td>{ details["@TimePeriodDesc"] }</td></tr>
                            <tr><td>Amount</td><td align="right">{ details["@Amount"] && isNaN(+details["@Amount"]) ? `$${details["@Amount"]}` : '' }</td><td>&nbsp;</td></tr>
                            <tr><td>Percent</td><td align="right">{ details["@Percent"] ? Math.round(+details["@Percent"]! * 100) + '%' : '' }</td><td>&nbsp;</td></tr> {/* because TypeScript... */ }
                            <tr><td>Service Quantity Type</td><td align="right">{ details["@QtyQC"] }</td><td>&nbsp;</td></tr>
                            <tr><td>Service Quantity</td><td align="right">{ details["@Quantity"] }</td><td>&nbsp;</td></tr>
                            <tr><td>Eligibility Date From</td><td align="right">{ FormatDateAsString(details["@EligibilityFrom"] || '') }</td><td>&nbsp;</td></tr>
                            <tr><td>Eligibility Date Thru</td><td align="right">{ FormatDateAsString(details["@EligibilityThru"] || '') }</td><td>&nbsp;</td></tr>
                            <tr><td>Benefit Date From</td><td align="right">{ FormatDateAsString(details["@BenefitsFrom"] || '') }</td><td>&nbsp;</td></tr>
                            <tr><td>Benefit Date Thru</td><td align="right">{ FormatDateAsString(details["@BenefitsThru"] || '') }</td><td>&nbsp;</td></tr>
                            <tr><td>Authorization Indicator</td><td align="right">{ details["@AuthIND"] }</td><td>&nbsp;</td></tr>
                            <tr><td>Network Indicator</td><td align="right">{ details["@In-PlanIND"] }</td><td>&nbsp;</td></tr>
                            </>
                        );
                    })
                }</tbody>
            </table>
        );
    }

    public render() {
        const helpUrl: string = `/Support/Help/HELP_Eligibility_ManualCheck.htm`;

        let pageButtons = this.renderPageButtons();
        const modalFragment = this.renderModal();
        //for more tabs, should use an array of JSX.Element with selectedTabIndex
        let currentTab: JSX.Element = this.state.selectedTabIndex === 0 ? this.renderTabFormInput() : this.renderTabResponse();
        let instruction: JSX.Element = <div style={ { textAlign: "center" } } dangerouslySetInnerHTML={ { __html: this.text.footerInstructions } }></div>

        return (
            <DialogWrapper title={ this.text.pageTitle } instruction={ instruction } helpUrl={helpUrl} buttons={pageButtons} isBusy={this.state.isPageBusy}>
                <ContentWrapper style={{ width: '962px' }}>
                    <ArmTabContainer tabs={this.tabs} style={{ width: '100%' }} >
                        <Tabs
                            size="small"
                            tabs={this.tabs}
                            onTabSelect={this.onSelectTab}
                            initialTab={1}                           
                        />   
                    </ArmTabContainer>
                    {currentTab}
                </ContentWrapper>
                { modalFragment }
            </DialogWrapper>
        );
    }
};

const connectedHoc = connect<IManualEligibilityCheckState, IManualEligibilityCheckActionProps, IOwnProps, IManualEligibilityCheckProps, ApplicationState>(
    createApiMapStateToProps('manualEligibilityCheck'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(ManualEligibilityCheck);


export default withRouter(connectedHoc);