/**
 * Purpose: To display the title for the Landing page
 */
import * as React from 'react'
import { LandingPageComponent, IComponentProps } from './LandingPageComponent';
import styled from 'styled-components';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';


const RAPTitleText = styled.div`
    border: none;           
    color:  ${Colors.darkestSecondaryGrey};
    margin-left: .5em;  
    ${Typography.bold};
    ${Typography.mediumLarge};
`;

const RAPTitleDate = styled.div`
    border: none;        
    color: ${Colors.black};
    margin-left: .3em;
    ${Typography.mediumLarge};
`;


interface IProps {
    Name  ?: string,
    Title ?: string,
}

class RAPTitle extends React.Component<IProps>{
    constructor(props:IProps)
    {
        super(props);    
    }

    getDate() {
        var currentDate = new Date();
        var options: Intl.DateTimeFormatOptions = { 
            weekday: 'long', 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        };
        // var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };                
        
        return " - " + currentDate.toLocaleDateString('en-US', options);        
    }

    getClassName() {
        return this.props.Name + " flex-Title-container";
    }

    render() {
        //debugger;
        return (<LandingPageComponent ComponentClass={this.getClassName()} {...this.props}>
                   <RAPTitleText>{this.props.Title} </RAPTitleText>  
                   <RAPTitleDate>{this.getDate()} </RAPTitleDate>         
                </LandingPageComponent>); 
    }
};

export default RAPTitle
