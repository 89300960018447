import { CommonGraph, LegendData, ChartData} from '../../commonResources/commonGraphing/CommonGraph';
import { GraphType, Styles } from '../../commonResources/commonGraphing/CommonGraph';
import React, { useState, createRef } from 'react';
//import { SketchPicker as Sketch } from 'react-color';   // commented out due to dependencies issues.
import { Colors } from '@commonResources/colorVariables';
//import "./GraphingDemoStyles.css";
import styled from 'styled-components';
import { Input, TextStyles, Checkbox, SelectDropdown as Select} from '@optum-uicl/ui-core/dist';
import _ from 'lodash';



interface IStyledComponentProps {
    color: string;
}



interface IColorObject {
    hex: string;
}

// The chart background (Light Blue)
const parentBackgroundColor = "#e5f8ff";

/* Colors for the Line Charts */

const lineColorArray = ["tomato", "purple"];

/* Data Definitions */
let data: ChartData[] = [
    {
        id: 'A001',
        x: 'Huygens, Christian',
        y: 200,
        tooltip:
        { 
            name: "Huygens, Christian",
            totalClaims: "25 for $8,765.67",
            released: "21 for $4,967.34",
            unreleased: "4 for $89,345.75",
        }
    },
    {
        id: 'A002',
        x: 'Volta, Alessandro',
        y: 51,
        tooltip:
        {
            name: "Volta, Alessandro",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'A003',
        x: 'Hooke, Robert',
        y: 82,
        tooltip:
        {
            name: "Hooke, Robert",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'A004',
        x: 'Newton, Isaac',
        y: 300,
        tooltip:
        {
            name: "Newton, Isaac",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'A005',
        x: 'Bohr, Niels',
        y: 100,
        tooltip:
        {
            name: "Bohr, Niels",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'A006',
        x: 'Galilei, Galileo',
        y: 40,
        tooltip:
        {
            name: "Galilei, Galileo",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'A007',
        x: 'Maxwell, James Clerk',
        y: 6,
        tooltip:
        {
            name: "Maxwell, James Clerk",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'A008',
        x: 'Faraday, Michael',
        y: 32,
        tooltip:
        {
            name: "Faraday, Michael",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'A009',
        x: 'Curie, Marie',
        y: 374,
        tooltip:
        {
            name: "Curie, Marie",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'A010',
        x: 'Feynman, Richard',
        y: 200,
        tooltip:
        {
            name: "Feynman, Richard",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'A011',
        x: 'Planck, Max',
        y: 45,
        tooltip:
        {
            name: "Planck, Max",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'A012',
        x: 'Tesla, Nikola',
        y: 384,
        tooltip:
        {
            name: "Tesla, Nikola",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {   
        id: 'A013',
        x: 'Pauli, Wolfgang',
        y: 2,
        tooltip:
        {
            name: "Pauli, Wolfgang",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'A014',
        x: 'Heisenberg, Werner',
        y: 300,
        tooltip:
        {
            name: "Heisenberg, Werner",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'A015',
        x: 'Dirac, Paul',
        y: 100,
        tooltip:
        {
            name: "Dirac, Paul",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'A016',
        x: 'Boltzman, Ludwig',
        y: 40,
        tooltip:
        {
            name: "Boltzman, Ludwig",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'A017',
        x: 'Blaise, Pascal',
        y: 65,
        tooltip:
        {
            name: "Blaise, Pascal",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'A018',
        x: 'Schrödinger, Erwin',
        y: 32,
        tooltip:
        {
            name: "Schrödinger, Erwin",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'A019',
        x: 'Hawkin, Stephen',
        y: 374,
        tooltip:
        {
            name: "Hawkin, Stephen",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'A020',
        x: 'Rutherford, Ernest',
        y: 200,
        tooltip:
        {
            name: "Rutherford, Ernest",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    }
];

//data = [];
/*
data = [
    {
        id: '0', x: "NATARAJAN, BALA", y: 0.000,
    },
    {
        id: '1', x: "TEST", y: 0.0000,
    },
    {
        id: '2', x: "X,X", y: 0.0000
    }
];*/

//data = data.slice(0, 1);
// data can't be undefined, but it can be an empty array
data  = data ?? [];

// Note: Line data contains an array of arrays for multiple line graphs
let lineData: ChartData[][] | undefined = [
    [
    {
        id: 'B001',
        x: 'Huygens, Christian',
        y: 50,
        tooltip:
        {
            name: "Santa Claus",
            totalClaims: "25 for $8,765.67",
            released: "21 for $4,967.34",
            unreleased: "4 for $89,345.75"
        }
    },
    {
        id: 'B002',
        x: 'Volta, Alessandro',
        y: 32,
        tooltip:
        {
            name: "The Grinch!",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'B003',
        x: 'Hooke, Robert',
        y: 45,
        tooltip:
        {
            name: "Hooke, Robert",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'B004',
        x: 'Newton, Isaac',
        y: 280,
        tooltip:
        {
            name: "Newton, Isaac",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'B005',
        x: 'Bohr, Niels',
        y: 70,
        tooltip:
        {
            name: "Bohr, Niels",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'B006',
        x: 'Galilei, Galileo',
        y: 34,
        tooltip:
        {
            name: "Galilei, Galileo",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'B007',
        x: 'Maxwell, James Clerk',
        y: 4,
        tooltip:
        {
            name: "Maxwell, James Clerk",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'B008',
        x: 'Faraday, Michael',
        y: 28,
        tooltip:
        {
            name: "Faraday, Michael",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'B009',
        x: 'Curie, Marie',
        y: 300,
        tooltip:
        {
            name: "Curie, Marie",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'B010',
        x: 'Feynman, Richard',
        y: 100,
        tooltip:
        {
            name: "Feynman, Richard",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'B011',
        x: 'Planck, Max',
        y: 30,
        tooltip:
        {
            name: "Planck, Max",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'B012',
        x: 'Tesla, Nikola',
        y: 256,
        tooltip:
        {
            name: "Tesla, Nikola",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'B013',
        x: 'Pauli, Wolfgang',
        y: 1,
        tooltip:
        {
            name: "Pauli, Wolfgang",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'B014',
        x: 'Heisenberg, Werner',
        y: 200,
        tooltip:
        {
            name: "Heisenberg, Werner",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'B015',
        x: 'Dirac, Paul',
        y: 80,
        tooltip:
        {
            name: "Dirac, Paul",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'B016',
        x: 'Boltzman, Ludwig',
        y: 30,
        tooltip:
        {
            name: "Boltzman, Ludwig",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'B017',
        x: 'Blaise, Pascal',
        y: 45,
        tooltip:
        {
            name: "Blaise, Pascal",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'B018',
        x: 'Schrödinger, Erwin',
        y: 12,
        tooltip:
        {
            name: "Schrödinger, Erwin",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'B019',
        x: 'Hawkin, Stephen',
        y: 300,
        tooltip:
        {
            name: "Hawkin, Stephen",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'B020',
        x: 'Rutherford, Ernest',
        y: 100,
        tooltip:
        {
            name: "Rutherford, Ernest",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    }
    ],
    [
    {
        id: 'C001',
        x: 'Huygens, Christian',
        y: 76,
        tooltip:
        {
            name: "Santa Claus",
            totalClaims: "25 for $8,765.67",
            released: "21 for $4,967.34",
            unreleased: "4 for $89,345.75"
        }
    },
    {
        id: 'C002',
        x: 'Volta, Alessandro',
        y: 89,
        tooltip:
        {
            name: "The Grinch!",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'C003',
        x: 'Hooke, Robert',
        y: 34,
        tooltip:
        {
            name: "Hooke, Robert",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'C004',
        x: 'Newton, Isaac',
        y: 156,
        tooltip:
        {
            name: "Newton, Isaac",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'C005',
        x: 'Bohr, Niels',
        y: 36,
        tooltip:
        {
            name: "Bohr, Niels",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'C006',
        x: 'Galilei, Galileo',
        y: 89,
        tooltip:
        {
            name: "Galilei, Galileo",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'C007',
        x: 'Maxwell, James Clerk',
        y: 92,
        tooltip:
        {
            name: "Maxwell, James Clerk",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'C008',
        x: 'Faraday, Michael',
        y: 56,
        tooltip:
        {
            name: "Faraday, Michael",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'C009',
        x: 'Curie, Marie',
        y: 678,
        tooltip:
        {
            name: "Curie, Marie",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'C010',
        x: 'Feynman, Richard',
        y: 67,
        tooltip:
        {
            name: "Feynman, Richard",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'C011',
        x: 'Planck, Max',
        y: 89,
        tooltip:
        {
            name: "Planck, Max",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'C012',
        x: 'Tesla, Nikola',
        y: 256,
        tooltip:
        {
            name: "Tesla, Nikola",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'C013',
        x: 'Pauli, Wolfgang',
        y: 12,
        tooltip:
        {
            name: "Pauli, Wolfgang",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'C014',
        x: 'Heisenberg, Werner',
        y: 56,
        tooltip:
        {
            name: "Heisenberg, Werner",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'C015',
        x: 'Dirac, Paul',
        y: 87,
        tooltip:
        {
            name: "Dirac, Paul",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'C016',
        x: 'Boltzman, Ludwig',
        y: 23,
        tooltip:
        {
            name: "Boltzman, Ludwig",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'C017',
        x: 'Blaise, Pascal',
        y: 78,
        tooltip:
        {
            name: "Blaise, Pascal",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'C018',
        x: 'Schrödinger, Erwin',
        y: 23,
        tooltip:
        {
            name: "Schrödinger, Erwin",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'C019',
        x: 'Hawkin, Stephen',
        y: 345,
        tooltip:
        {
            name: "Hawkin, Stephen",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        id: 'C020',
        x: 'Rutherford, Ernest',
        y: 23,
        tooltip:
        {
            name: "Rutherford, Ernest",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    }
    ],
];
//lineData = undefined;

/*
lineData =  [
    [{
        id: '0', x: "NATARAJAN, BALA", y: 0.000,
    },
    {
        id: '1', x: "TEST", y: 3,
    },
    {
        id: '2', x: "X,X", y: 0.0000
    }]
];*/


const MainWrapper = styled("div")`
    display: flex;
    margin-top: 21px;
    background-color: ${Colors.white};
    color: #fff;
    padding: 10px;
    ${TextStyles.smallBold};
`;

const UIWrapper = styled("div")`
    width: 200px;
    background-color: #a3b9bf;
`;

const GraphWrapper = styled("div")`
    width: 700px;
`;

const ColorPickerWrapper = styled("div")`
    display: flex;
    position: absolute;
    top: 550px;
    left: 160px;
    z-index: 9999;
`;

const ColorChip = styled("div")`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: ${({ color }: IStyledComponentProps) => color}
    margin: 5px;
    border: 1px #999 solid;
`;

const ColorChipContainer = styled("div")`
    display: flex;
    width: 227px;
`;

const Name = styled("div")`
    padding-bottom: 0.2em; 
    text-transform: uppercase; 
    font-size: 11px;
`;

const Data = styled("div")`
    font-size: 9px;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;


const TooltipLabel = styled("div")`
    font-weight: bold;
    color: ${Colors.brightBlue15}
`;


const Value = styled("div")`
    color: white;
`;

const MyInput = styled(Input)`
   padding: 10px;
   label {
        color: ${Colors.grey100}
    };
`;

const MyCheckbox = styled(Checkbox)`
   padding: 10px;
   label {
    color: ${Colors.grey100}
   };
    fontsize: 11px;
`

const MySelect = styled(Select)`
   padding: 10px;
`


const parent = CommonGraph.defaultStyles.parent;
const chart = CommonGraph.defaultStyles.chart;
const bar = CommonGraph.defaultStyles.bar;




const GraphingDemo = () => {

    const [color, setColor] = useState({ value: 'blue', label: "BLUE" });
    const [shade, setShade] = useState({ value: '', label: "NONE" });
    const [activeColor, setActiveColor] = useState("#000");
    const [activeColorChip, setActiveColorChip] = useState("parent");
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [label, setLabel] = useState('');
    const [chartData, setChartData] = useState(data);
    const [graphType, setGraphType] = useState({ label: "BAR", value: "bar" });
    const [backgroundColor, setBackgroundColor] = useState(parentBackgroundColor);
    const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);


    const legendData = [
        { name: "Count", symbol: { fill: CommonGraph.colors.blue70, type: "square" } } as LegendData,
        { name: "Value 1", symbol: { fill: lineColorArray[0], type: "square" } } as LegendData,
        { name: "Value 2", symbol: { fill: lineColorArray[1], type: "square" } } as LegendData
    ];

    const myStyle: Styles = {
        ...CommonGraph.defaultStyles,
        parent: {
            ...parent,
            background: backgroundColor,
        },
        chart: {
            ...chart,
            background: {
                fill: "#fff",
                stroke: "#eee",
                strokeWidth: 1,
            }
        },
        bar: {
            ...bar,
            data: {
                fill: ({ datum }: { datum: { fill: string } }) => datum.fill,
                fillOpacity: 1,
            }
        }
    }

    const handleColorChange = (option: {value: string, label: string}) => {
        setColor(option);
    }

    const handleShadeChange = (option: { value: string, label: string }) => {
        setShade(option);
    }

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    }

    const handleSubtitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSubtitle(e.target.value);
    }

    const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLabel(e.target.value);
    }

    const handleChangeGraphType = (option: {value: string, label: string}) => {
        setGraphType(option);
    }

    const handleClickColorSwatch = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsColorPickerVisible(!isColorPickerVisible);
        //@ts-ignore
        setActiveColorChip(e.target.getAttribute("data-name"));       
    }
   
    const handleColorPickerChange = (color: IColorObject) => {

        setActiveColor(color.hex);
       
        switch (activeColorChip) {
            case "parent":
                setBackgroundColor(activeColor);
                break;
            case "line1": {
                lineColorArray[0] = activeColor;
                break;
            }
            case "line2": {
                lineColorArray[1] = activeColor;
                break;
            }
        }
        
    }

    const getToolTipJSX = (tooltipObj: any) => {
        const { name, totalClaims, released, unreleased } = tooltipObj
        return (
            <>
                <Name>{name}</Name>
                <Data>
                    <TooltipLabel>Total Claims: </TooltipLabel><Value>{totalClaims}</Value>
                </Data>
                <Data>
                    <TooltipLabel>Released: </TooltipLabel><Value>{released}</Value>
                </Data>
                <Data>
                    <TooltipLabel>Unreleased: </TooltipLabel><Value>{unreleased}</Value>
                </Data>
            </>
        )
    }

    const getTooltipContent = (tooltipData: any) => {
        const { global, bar, point, label } = tooltipData;
        if (bar) {
            return getToolTipJSX(bar);
        } else if (point) {
            return getToolTipJSX(point);
        } else if (label) {
            return getToolTipJSX(label);
        } else if (global) {
            return getToolTipJSX(global);
        } else {
            return getToolTipJSX(tooltipData);
        }
    }
    interface IoptionOnChange {
        label: string;
        value: string;
    }
    return (
        <>
            <MainWrapper>
                <UIWrapper>

                <MySelect
                    domID="graphType"
                    initialValue={graphType}
                    label={"Choose graph type:"}
                    isClearable={false}
                    onChange={(option: { label?: string, value?: string }) => handleChangeGraphType(option as IoptionOnChange)}
                    options={["bar", "line", "pie"].map((type, index) => ({ value: type, label: type.toUpperCase() }))}
                />

                <MySelect
                    domID="colorScheme"
                    initialValue={color}
                    label={"Choose Color Palette:"}
                    onChange={(option: {label?: string, value?: string}) => handleColorChange(option as IoptionOnChange)}
                    options={CommonGraph.colorSchemes.map((color) => ({ value: color, label: color.toUpperCase() }))}
                    isClearable={false}
                />
                   
                <MySelect 
                    domID="colorShade"
                    initialValue={shade}
                    label={"Choose Color Shade:"}
                    onChange={(option: { label?: string, value?: string}) => handleShadeChange(option as IoptionOnChange)}
                    options={CommonGraph.shades.map((shade) => ({ value: shade ? shade.toString() : '', label: shade ? shade.toString() : 'NONE' }))}
                    isClearable={false}
                />
                    
                <MyInput domID="title" label={"Title"} type="text" placeholder="Enter the chart title" value={title} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTitleChange(e)} />
                  
                <MyInput domID="subtitle" label={"Subtitle"} type="text" placeholder="Enter the chart subtitle" value={subtitle} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSubtitleChange(e)} />

                <MyInput domID="label" label={"Label"} type="text" placeholder="Enter the label for the y-axis" value={label} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleLabelChange(e)} />

                {/*
                <MyCheckbox domID="displayLine" labelBefore={"Display line data:"} name="DisplayLineCheckBox" defaultChecked={displayLine} onChange={() => setDisplayLine(!displayLine)} />
                */}
                      
                <ColorChip color={backgroundColor} data-name="parent" title="Parent Background" onClick={(e: React.MouseEvent<HTMLDivElement>) => handleClickColorSwatch(e)} />               
                                
                    {   lineColorArray
                        && lineColorArray.length
                        && (<ColorChipContainer>
                                {lineColorArray.map((color, index) => <ColorChip
                                    color={lineColorArray[index]}
                                    title={`Line ${index + 1} Color`}
                                    key={_.uniqueId() }
                                    onClick={(e: React.MouseEvent<HTMLDivElement>) => handleClickColorSwatch(e)}
                                    data-name={`line${index+1}`}
                                />)}
                        </ColorChipContainer>) 
                    }
     
            </UIWrapper>
                <GraphWrapper onClick={() => { setIsColorPickerVisible(false) }}>
                <CommonGraph
                    disableDataEvents={false}
                    title={title || "Default Title"}
                    subtitle={subtitle || "Default Subtitle"}
                    label={label || "Default Y-Axis"}
                    xLabel={"Bottom label"}
                    data={data}
                    lineData={lineData}
                    legendData={legendData}
                    lineColorArray={lineColorArray}
                    styles={myStyle}
                    colorScheme={`${color.value}${+shade.value}`}
                    graphType={graphType.value as GraphType}
                    tooltipData={{
                        tooltipWidth: 200,
                        tooltipOffsetX: 20,
                        tooltipOffsetY: 30,
                        getContent: getTooltipContent,
                        getGlobalTooltipContent: getTooltipContent,
                        getBarTooltipContent: getTooltipContent,
                        getPointTooltipContent: getTooltipContent,
                        getLabelTooltipContent: getTooltipContent,
                    }}
                    />
            </GraphWrapper>
        </MainWrapper>
            {/*
            isColorPickerVisible &&
                <ColorPickerWrapper>
                    <Sketch
                        disableAlpha={true}
                        color={activeColor}
                        onChange={handleColorPickerChange} />
                </ColorPickerWrapper>
            */}
        </>

    );
}

export default GraphingDemo;
