import * as React from 'react';
import styled, { css }  from 'styled-components';
import { Colors } from '../../../commonResources/colorVariables';
import { Typography } from '../../../commonResources/typography';
import { displayFlex, flexRowWrap, flexRowNoWrap, flexFill, flexRigid, flexContainerWrap, flexContainerNoWrap, ARMExceptionInput, width100 } from './FlexStyles';
import { DropdownButton } from '@optum-uicl/ui-core/dist';


//
// Quick search input elements
//
// These control styling for entering data for patient name and control number.
// There is provision for ARM Exceptions for the landing page view to reduce the size of the elements from the standard UICL versions
// to maintain the existing look and feel.
//

// Define propoerties for manipulating input styles in landing page and legacy home page versions.
interface IQuickSearchInputProps {
    inputWidth?: string;    // Permit overriding the size of the input elements for the legacy home page.
    reducedStyle?: boolean;
     // If true, incorporate exceptions to UICL standard sizes for the landing page.
}

interface ISavedFilterLegacyProps{
    legacy?: boolean;
}

// Define the styles for inclusion in both landing page and legacy home page views.

const searchWithIcon = css`
    .searchWithIcon {
        position: relative;
        display: inline-block;
        margin-right: 5px;
    }

    .searchIcon {
        position: absolute;
        left: 8px;
        top: 25%;
        pointer-events: none;
        z-index: 1;
    }

`

const quickSearchInput = css`
    .quickSearchInput {
        #height: 25px;
        width: ${(props: IQuickSearchInputProps) => props.inputWidth ? props.inputWidth : `185px`};
        border: 1px #737d85;
        border-radius: 6px;
        font-weight: bold;
        margin-right: 5px;
        margin-bottom: 3px;
    };

    input {
        cursor: pointer;
        padding-left: 25px;
        border: 1px solid #7D7F81;
        text-transform: capitalize;
        font-size: 10px !important;
        height: 28px !important;
        box-shadow: rgba(155, 161, 169, 0.25) 0px 2px 2px 0px inset;
    }
`;

// Define styles for spacing the quick search buttons.
const quickSearchButton = css`
    .quickSearchButton {
        margin: 5px;
        margin-bottom: 10px;
    };
    .quickSearchButton:disabled {

    }

    .quickSearchButton:hover{

    }
`;

//
//
// Landing page definitions
//
//

// Parent Wrapper for entire quick search options on the landing page.
export const ARMQuickSearchOptionsBar = styled.div`
    ${flexContainerNoWrap};
    align-items: center;
    justify-content: space-between;
    width: 1050px;
    height: 100%;
    
`;

// Wrapper for quick search entry elements on landing page (input/buttons); used for (flex) positioning and alignment.
export const ARMQuickSearchEntry = styled.div<IQuickSearchInputProps>`
    ${flexContainerNoWrap};
    height: 100%;
    text-align: left;
    align-items: center;
    ${searchWithIcon};
    ${quickSearchInput};
    ${quickSearchButton};
`;

// Default reduced style to true for the landing page.
ARMQuickSearchEntry.defaultProps =
{
    reducedStyle: true
}

// Wrapper for text labels for style in landing page
export const ARMQuickSearchOptionsLabel = styled.div`
    padding-left: 4px;
    padding-right: 4px;
    ${Typography.bold};
    white-space: nowrap;
`;

// Wrapper for filter options (dropdown); used for (flex) positioning and alignment.
export const ARMQuickSearchFilterOptions = styled.div`
    ${displayFlex};
    ${flexFill};
    align-items: center;
    justify-content: flex-end;
`;

// Wrapper for filter dropdown. Used to provide white background (UICL de-emphasised dropdown style uses transparent background)
export const SavedFilterDropdownWrapper = styled.div<ISavedFilterLegacyProps>`
    background-color: ${Colors.white};

    border-radius: 3px;

    ${(props: ISavedFilterLegacyProps) => ((props.legacy === true) ? width100 : ``)};

    #filterOptionsDropdown-dropdown-wrapper{
        ${(props: ISavedFilterLegacyProps) => ((props.legacy === true) ? width100 : ``)};
        ${(props: ISavedFilterLegacyProps) => ((props.legacy === true) ? `justify-content: center` : ``)};
    }
    .dropdown-list {
        max-height: 100px !important;

    }
    .button {
        display: flex;
        justify-content: space-between;
        border-radius: 6px;
        padding-left: 7px;
        padding-right: 7px;
        font-weight: 400;
        font-size: 10px !important;
        color: #4B4D4F;
        ${(props: ISavedFilterLegacyProps) => ((props.legacy === true) ? `width: 90%` : `width: 200px`)};
        border: 1px solid #7D7F81;
        cursor: pointer;
        box-shadow: rgba(155, 161, 169, 0.25) 0px 2px 2px 0px inset;
    }    
`;

//
//
// Panel definitions
//
// The following styled component definitions lay out the quick search panels for the legacy home rendered version.
//
//

// Parent container for legacy home page rendition.
export const ARMQuickSearchOptionsPanel = styled.div`
    ${flexContainerWrap};
    position: relative;
    align-items: flex-start;
    justify-content: space-between;
    min-width: 390px;
    min-height: 236px;
    width: 390px;
    height: 300px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    color: #000;
    border-radius: 8px;
    box-shadow: rgba(17, 80, 244, 0.1) 0px 1px 2px, rgba(131, 144, 175, 0.3) 0px 2px 12px;
`;

// Interface for generic row container with properties to adjust styling and positioning.
interface IQuickSearchPanelRowProps {
    wrap?: boolean;
    justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly" | "left";
    alignItems?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline";
    widthValue?: string;
    paddingTop?: string;
    paddingBottom?: string;
}

// Generic row container with properties to adjust styling and positioning.
export const ARMQuickSearchOptionsPanelRow = styled.div`
    ${(props: IQuickSearchPanelRowProps) => ((props.wrap === true) ? flexContainerWrap : flexContainerNoWrap)};
    ${flexFill};
    align-items: ${(props: IQuickSearchPanelRowProps) => props.alignItems ? props.alignItems : `center`};
    justify-content: ${(props: IQuickSearchPanelRowProps) => props.justifyContent ? props.justifyContent : `space-between`};
    ${(props: IQuickSearchPanelRowProps) => props.widthValue && `width: ${props.widthValue }`};
    ${(props: IQuickSearchPanelRowProps) => props.paddingTop && `padding-top: ${props.paddingTop}`};
    ${(props: IQuickSearchPanelRowProps) => props.paddingBottom && `padding-bottom: ${props.paddingBottom}`};
`;

export const ARMQuickSearchLegacyPanelbuttons = styled.div`
    margin-top: 25px;
    margin-left: 13px;
    margin-right: 20px;
`

// Title wrapper for style and positioning.
export const ARMQuickSearchOptionsPanelTitle = styled.div`
    ${displayFlex};
    ${flexFill};
    line-height: 1.4em;
    color: #4B4D4F;
    font-weight: 600;
    font-size: 14px !important;
    letter-spacing: 0px;
    align-items: center;
    justify-content: left;
    margin-left: 10px;
    font-family: 'Optum Sans';
    ${Typography.bold};
`;

// Help button wrapper for style and positioning.
export const ARMQuickSearchOptionsPanelHelp = styled.div`
    ${displayFlex};
    ${flexRigid};
    align-items: center;
    justify-content: space-between;

    .imageButton
    {
        line-height: unset;
        padding: 0px 0px;
    }
`;

// HR styling for divider lines in legacy home page.
export const ARMQuickSearchOptionsPanelRule = styled.hr`
    width: 35%;
    height: 3px;
    background: #000;
    margin-right: 10px;
    margin-left: 10px;
`;

// Styling for text labels in legacy home page.
export const ARMQuickSearchOptionsPanelLabel = styled.div`
    padding-left: 8px;
    padding-right: 8px;
    ${Typography.bold};
`;

// Container for quick search input elements to control flow and positioning of children.
export const ARMQuickSearchEntryPanel = styled.div`
    ${flexContainerWrap};
    ${flexFill};
    align-items: center;
    justify-content: center;
    .searchWithIconLegacy{
        width: 90%;
        height: 45px;
        .searchIcon{
            position: relative;
            top: 25px;
            z-index: 1;
            left: 6px;
        }
    }

    .quickSearchInput {
        margin-right: 0px !important; 
    }
   ${(props: IQuickSearchInputProps) => ` ${quickSearchInput};`}
`;

// Container for quick search button elements to control flow and positioning of children.
export const ARMQuickSearchEntryPanelButtons = styled.div`
    ${flexContainerWrap};
    ${flexFill};
    align-items: center;
    justify-content: center;
    ${quickSearchButton};
`;
