import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, createAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { OperationView, OperationMeasure, OperationDateRange, OperationFilter } from "@store/OperationalDashboard/DrillDownView"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ISelection<T extends string> {
    label: string;
    value: T;
}

export type ISelectionView = ISelection<OperationView>;
export type ISelectionMeasure = ISelection<OperationMeasure>;
export type ISelectionDateRange = ISelection<OperationDateRange>;

export interface IDashboardFilterState {
    selectedView: ISelectionView;
    selectedMeasure: ISelectionMeasure;
    selectedDateRange: ISelectionDateRange;
    selectedStartDate: string;
    selectedEndDate: string;
    uiLock: string;
}

export interface IDashboardDefaultState {
    selectedTabIndex: Number;
}

export type ClaimTypes = "Total Claims" | "Released Claims" | "Unreleased Claims";

//getCiamUserDetails: (userInfo: ISelectUser) => createApiAction('GET_CIAM_USER_DETAILS', `${URLs.api}/api/data/ciam/getUser/${userInfo.uiData.value}`, userInfo),

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data). 
export const actionCreators = {
    selectTab: (tabIndex: Number) => createDataAction('OPERATIONAL_DASHBOARD_SELECT_TAB', tabIndex),
    resetState: () => createAction('OPERATIONAL_DASHBOARD_RESET_STATE'),
}

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IDashboardDefaultState = {
    selectedTabIndex: 0,
};


// ----------------
// REDUCER - For a given state and actio returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IDashboardDefaultState, KnownActions> = (state: IDashboardDefaultState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {

            case 'OPERATIONAL_DASHBOARD_SELECT_TAB':
                return {
                    ...state,
                    selectedTabIndex: action.data as number,
                }
            case 'OPERATIONAL_DASHBOARD_RESET_STATE':
                return defaultState;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                // const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
