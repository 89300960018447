export class ReportFileInfo {
    private reportID: string = "";
    private clientID: string = "";
    private userID: string = "";
    private userName: string = "";
    private rptFilter: string = "";
    private rptSize: string = "";
    private reportName: string = "";
    private reportType: string = "";
    private allow: number = 0;
    private rptModified: string = "";
    private isViewable: boolean = true;
    private fileName: string = "";
    private utc: string = "";
    private type: string = "";

    constructor() {

    }

    get ReportID(): string {
        return this.reportID;
    }
    set ReportID(value: string) {
        this.reportID = value;
    }

    get ClientID(): string {
        return this.clientID;
    }
    set ClientID(value: string) {
        this.clientID = value;
    }

    get UserID(): string {
        return this.userID;
    }
    set UserID(value: string) {
        this.userID = value;
    }

    get UserName(): string {
        return this.userName;
    }
    set UserName(value: string) {
        this.userName = value;
    }

    get RptFilter(): string {
        return this.rptFilter;
    }
    set RptFilter(value: string) {
        this.rptFilter = value;
    }

    get RptSize(): string {
        return this.rptSize;
    }
    set RptSize(value: string) {
        this.rptSize = value;
    }

    get ReportName(): string {
        return this.reportName;
    }
    set ReportName(value: string) {
        this.reportName = value;
    }

    get ReportType(): string {
        return this.reportType;
    }
    set ReportType(value: string) {
        this.reportType = value;
    }

    get Allow(): number {
        return this.allow;
    }
    set Allow(value: number) {
        this.allow = value;
    }

    get RptModified(): string {
        return this.rptModified;
    }
    set RptModified(value: string) {
        this.rptModified = value;
    }

    get IsViewable(): boolean {
        return this.isViewable;
    }
    set IsViewable(value: boolean) {
        this.isViewable = value;
    }

    get FileName(): string {
        return this.fileName;
    }
    set FileName(value: string) {
        this.fileName = value;
    }

    get UTC(): string {
        return this.utc;
    }
    set UTC(value: string) {
        this.utc = value;
    }

    get Type(): string {
        return this.type;
    }
    set Type(value: string) {
        this.type = value;
    }
}

export class ReportFormInfo {

    private reportID: string = "";
    private name: string = "";
    private allow: number = 0;
    private reportType: string = "";

    constructor() {
    }

    get ReportID(): string {
        return this.reportID;
    }
    set ReportID(value: string) {
        this.reportID = value;
    }
    get Name(): string {
        return this.name;
    }
    set Name(value: string) {
        this.name = value;
    }
    get Allow(): number {
        return this.allow;
    }
    set Allow(value: number) {
        this.allow = value;
    }

    get ReportType(): string {
        return this.reportType;
    }
    set ReportType(value: string) {
        this.reportType = value;
    }
}

export class ViewReportData {
    Created: string = "";
    Name: any;
    Creator: string = "";
    Size: string = "";
    Filters: any;
    Operation: any;
    ReportName: string = "";
    UTC: number = 0;
    Selected?: any = false;
}