import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import * as wind from "@commonResources/window";
import styled from "styled-components";
import refreshIcon from "@content/images/LandingPage/refresharrow.png";
import { Typography } from "@commonResources/typography";
import { Colors } from "@commonResources/colorVariables";
import { LandingPageComponent, IComponentProps } from "./LandingPageComponent";
import {
    ARMLandingPageHeaderWrapper,
    ARMLandingPageHeaderButton,
} from "./ARMLandingPageHeader";
import { URLs } from "@commonDevResources/constants";
import { CheckBoxComponent, ICheckBoxComponentProps } from "@common/CheckBox";
import { ARMComponentMessage } from "./ARMComponentMessage";
import UserFilterForm, { IFilterFormProps } from "@common/UserFilterForm";
import { getRawToken } from "@scripts/session/SecurityToken";

export const ContentContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    font-size: 11px;
    height: ${(props) => (props.style ? props.style.height : "211px")};
    width: 100 %;
    
    .border-top-line td {
        border-bottom: 1px solid black;
    }

    .legacy{
        font-size: 10pt;
        margin: 7px;
    }
    .landingPage{
        font-size: 11px;
    }
    

    .textAlignLeft{
        text-align: left;
    }
    .textAlignRight{
        text-align: right;
    }
    .textAlignCenter{
        text-align: center;
    }

    .legacyTitleStyle{
        font-size: 10pt;
        font-weight: bolder;
        padding-top: 1px;
        padding-left: 5px;
        vertical-align: bottom;
    }

    .legacyCellStyle{
        font-size: 10pt;
        padding-top: 5px;
        font-weight: normal;
        border-collapse: collapse;
    }

    #claimTable tbody tr:nth-child(even)
    {
        background-color: ${Colors.faintestGrey};
    }

    #claimTable tbody tr:nth-child(odd)
    {
        background-color: ${Colors.white};
    }

    }
`;

export const ClaimSummaryContent = styled.div`
    height: 100%;
    width: 100 %;
    }
`;

export const TableOverflowDiv = styled.div`
    }
`;

export const ClaimSummaryContentMsg = styled.div`
    height: 100%;
    width: 100 %;
    padding: 2px;
    }
`;

export const TotalCountContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-size: 10pt;
    margin: 7px;
    width: 100 %;
    color: ${Colors.darkestSecondaryGrey}
    }
`;

export const ClickImage = styled.div`
    cursor: pointer;
    display: flex;
    }
`;

export const ARMLandingPageHeaderCaptionOptions = styled.div`
  ${Typography.ARMFontFamily};

  ${Typography.small};
  ${Typography.defaultLineHeight};
  color: #4B4D4F;
  padding: 10px 5px 10px 1px;
  text-align: right;
  white-space: nowrap;

  .option-checkbox {
    padding: 0px;
    padding-top: 10px;
    padding-right: 10px;
  }

  button#refreshButton {
    color: #4174E0;
    border: none;
    border-radius: 3px !important;
    font-size: 12px !important;
    height: 20px;
    padding: 0px 6px 0px 6px;
    letter-spacing: normal;
  }
  ::placeholder {
    padding-left: 3px;
    color: #737d85;
    font-size: 8px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .myClaimsHeaderInput {
    background-color: ${Colors.darkRed};
    ${Typography.ARMFontFamily};
    ${Typography.bold};
  }

  button#refreshButton:disabled {
    background-color: transparent;
    border-color: transparent;
    color: rgb(63, 96, 127);
}

button#refreshButton:hover{
    background: transparent !important;
    color: #4174E0 !important;
    border-color:#fff !important;
}
`;

const overflowStyle: React.CSSProperties = {
    height: "235px",
    overflowY: "auto",
};
const legacyOverflowStyle: React.CSSProperties = {
    height: "195px",
    overflowY: "auto",
};

const fullwidthStyle: React.CSSProperties = {
    width: "100%",
};
const fullStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
};

const titleStyle: React.CSSProperties = {
    textAlign: "center",
    fontSize: "11px",
    fontWeight: "bolder",
    paddingTop: "5",
};

const titleStyleRight: React.CSSProperties = {
    textAlign: "right",
    fontSize: "11px",
    fontWeight: "bolder",
    paddingTop: "5",
};

const theadStyle: React.CSSProperties = {
    height: "33px",
};

const legacyTheadStyle: React.CSSProperties = {
    height: "24px",
};

const legacyTitleStyle: React.CSSProperties = {
    textAlign: "left",
    fontSize: "10pt",
    fontWeight: "bolder",
    paddingTop: "1",
    paddingLeft: "5px",
    verticalAlign: "bottom",
};

const legacyTitleStyleRight: React.CSSProperties = {
    textAlign: "right",
    fontSize: "10pt",
    fontWeight: "bolder",
    paddingTop: "1",
    paddingLeft: "5px",
    verticalAlign: "bottom",
};

export class MetricsRequest {
    url: string = "";
    requestId: string = "";
    startTime: number = 0;
    filterIndex: number = -1;
}

export class Filter {
    filterId: any;
    filterName: any;
    filterTitle: any;
    rowStyle: any;
    claimCount: any;
    claimValue: any;
    filterAge: any;
    metricsRequest?: MetricsRequest;
    isShown: boolean;
    isHighlighted: boolean;

    constructor() {
        this.isShown = true;
        this.isHighlighted = false;
    }
}

export class HeaderFilter {
    filterInbox: string;
    filterCount: string;
    filterValue: string;
    filterOldest: string;

    constructor() {
        this.filterInbox = "";
        this.filterCount = "";
        this.filterValue = "";
        this.filterOldest = "";
    }
}

interface IMyClaimsComponentProps extends IComponentProps { }

enum eViewType {
    Legacy,
    Biller,
    Supervisor,
}

type MyClaimsComponentProps = IMyClaimsComponentProps & RouteComponentProps<{}>;

interface IMyClaimsComponentState {
    name?: string;
    order?: number;
    id?: number;
    data?: any;
    userCanViewClaims: boolean;
    errorCode: number;
    filteredData: Filter[];
    filterFormState: IFilterFormProps;
    hideEmpty: boolean;
    isBusy: boolean;
    headerFilter: HeaderFilter;
}

const defaultFilterFormState: IFilterFormProps = {
    filterId: "0",
    filterName: "",
    filterType: "Claim",
};

export function FilterTableHeader(props: {
    viewType: eViewType;
    data: HeaderFilter;
    filterCallback: any;
}) {
    const { viewType, data, filterCallback } = props;

    const [filterInbox, setFilterInbox] = React.useState("");
    const [filterCount, setFilterCount] = React.useState("");
    const [filterValue, setFilterValue] = React.useState("");
    const [filterOldest, setFilterOldest] = React.useState("");
    const [stateData, setStateData] = React.useState(data);

    function handleInboxChange(e: React.ChangeEvent<HTMLInputElement>) {
        //   let inboxValue: string = (this?.filterInboxRef?.current?.value !== undefined ? this.filterInboxRef.current.value : "");
        setFilterInbox(e.currentTarget.value);
        // let parentState = { ...data, filterInbox: e.currentTarget.value };
        let parentState = { ...stateData, filterInbox: e.currentTarget.value };
        setStateData(parentState);
        //       console.log(parentState);
        if (filterCallback) filterCallback(parentState);
    }
    function handleCountChange(e: React.ChangeEvent<HTMLInputElement>) {
        setFilterCount(e.currentTarget.value);
        let parentState = { ...stateData, filterCount: e.currentTarget.value };
        setStateData(parentState);
        if (filterCallback) filterCallback(parentState);
    }
    function handleValueChange(e: React.ChangeEvent<HTMLInputElement>) {
        setFilterValue(e.currentTarget.value);
        let parentState = { ...stateData, filterValue: e.currentTarget.value };
        setStateData(parentState);
        if (filterCallback) filterCallback(parentState);
    }
    function handleOldestChange(e: React.ChangeEvent<HTMLInputElement>) {
        setFilterOldest(e.currentTarget.value);
        let parentState = { ...stateData, filterOldest: e.currentTarget.value };
        setStateData(parentState);
        if (filterCallback) filterCallback(parentState);
    }

    if (viewType == eViewType.Legacy) {
        return (
            <thead style={legacyTheadStyle}>
                <tr>
                    <th
                        className="legacyTitleStyle textAlignLeft"
                        style={{ width: "300px" }}
                        title="Inbox"
                    >
                        &nbsp;Inbox
                    </th>
                    <th
                        className="legacyTitleStyle textAlignCenter"
                        style={{ width: "60px" }}
                        title="Claim Count"
                    >
                        &nbsp;Count
                    </th>
                    <th
                        className="legacyTitleStyle textAlignCenter"
                        style={{ width: "165px" }}
                        title="Claim Value"
                    >
                        &nbsp;Value
                    </th>
                    <th
                        className="legacyTitleStyle textAlignCenter"
                        style={{ width: "60px" }}
                        title="Days Since Created"
                    >
                        &nbsp;Oldest
                    </th>
                </tr>
            </thead>
        );
    }

    // NOTE: the extra tbody is to avoid a DOM warning
    return (
        <tbody>
            <tr>
                <td colSpan={4}>
                    <table className="myClaimsHeader" style={{ paddingBottom: '4px', border: '1px solid #cbcccd' }}>
                        <thead style={{ height: "20px" }}>
                            <tr>
                                <th
                                    style={{
                                        fontSize: "12px",
                                        textAlign: "left",
                                        fontWeight: "normal",
                                    }}
                                >
                                    &nbsp;Inbox
                                </th>
                                <th
                                    style={{
                                        fontSize: "12px",
                                        textAlign: "left",
                                        fontWeight: "normal",
                                    }}
                                >
                                    &nbsp;Count
                                </th>
                                <th
                                    style={{
                                        fontSize: "12px",
                                        textAlign: "right",
                                        fontWeight: "normal",
                                    }}
                                >
                                    &nbsp;Value
                                </th>
                                <th
                                    style={{
                                        fontSize: "12px",
                                        textAlign: "right",
                                        fontWeight: "normal",
                                    }}
                                >
                                    &nbsp;Oldest
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td
                                    className="myClaimsHeaderInput"
                                    style={{
                                        width: "40%",
                                    }}
                                >
                                    <input
                                        type="text"
                                        style={{
                                            fontFamily: 'inherit',
                                            fontSize: '8px',
                                            lineHeight: 'inherit',
                                            height: "25px",
                                            borderRadius: '4px',
                                            paddingLeft: "3px",
                                            width: "100%",
                                            maxWidth: "250px"
                                        }}
                                        name="filterInboxHeader"
                                        placeholder="Filter by Inbox"
                                        value={filterInbox}
                                        maxLength={10}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleInboxChange(e)
                                        }
                                    />
                                </td>
                                <td
                                    className="myClaimsHeaderInput"
                                    style={{
                                        width: "10%",
                                    }}
                                >
                                    <input
                                        type="text"
                                        maxLength={6}
                                        style={{
                                            fontFamily: 'inherit',
                                            fontSize: '8px',
                                            lineHeight: 'inherit',
                                            height: "25px",
                                            borderRadius: '4px',
                                            paddingLeft: "3px",
                                            width: 'auto',
                                            maxWidth: "72px"
                                        }}
                                        placeholder="Filter by Count"
                                        value={filterCount}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleCountChange(e)
                                        }
                                    />
                                </td>
                                <td
                                    className="myClaimsHeaderInput"
                                    style={{
                                        width: "30%",
                                        textAlign: "right"
                                    }}
                                >
                                    <input
                                        type="text"
                                        maxLength={6}
                                        style={{
                                            fontFamily: 'inherit',
                                            fontSize: '8px',
                                            lineHeight: 'inherit',
                                            height: "25px",
                                            borderRadius: '4px',
                                            paddingLeft: "3px",
                                            width: "100%",
                                            maxWidth: "225px"
                                        }}
                                        placeholder="Filter by Value"
                                        value={filterValue}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleValueChange(e)
                                        }
                                    />
                                </td>
                                <td
                                    className="myClaimsHeaderInput"
                                    style={{
                                        width: "20%",
                                        textAlign: "right"
                                    }}
                                >
                                    <input
                                        type="text"
                                        maxLength={6}
                                        style={{
                                            fontFamily: 'inherit',
                                            fontSize: '8px',
                                            lineHeight: 'inherit',
                                            height: "25px",
                                            borderRadius: '4px',
                                            paddingLeft: "3px",
                                            width: "100%",
                                            maxWidth: "100px"
                                        }}
                                        placeholder="Filter by Oldest"
                                        value={filterOldest}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleOldestChange(e)
                                        }
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    );

    /*
      return (
          <thead style={theadStyle}><tr>
              <th style={titleStyle} title="Inbox">&nbsp;Inbox</th>
              <th style={titleStyleRight} title="Claim Count">&nbsp;Count</th>
              <th style={titleStyleRight} title="Claim Value">&nbsp;Value</th>
              <th style={titleStyleRight} title="Days Since Created">&nbsp;Oldest</th>
          </tr></thead >
      );
  */
}

export function FilterTableRow(props: {
    data: Filter;
    onClick: any;
    onToggleHighlight: any;
    viewType: eViewType;
    index: any;
}) {
    const { data, onClick, onToggleHighlight, viewType, index } = props;
    const [ishovered, setHovered] = React.useState(false);

    function handleClick() {

        onClick(data);
    }

    function handleToggle() {
        setHovered(true);
        onToggleHighlight(data);
    }
    function handleMouseOutToggle() {
        setHovered(false);
        onToggleHighlight(data);
    }

    if (viewType == eViewType.Legacy) {
        return (
            <tr
                onClick={handleClick}
                onMouseOver={handleToggle}
                onMouseOut={handleMouseOutToggle}
                style={{
                    height: "25px",
                    backgroundColor: (ishovered ? '#CDDDFE' : !data.isHighlighted
                        ? '#fffcc3'
                        : '#fff'),
                    borderCollapse: "separate",
                }}
            >
                <td
                    className="legacyCellStyle textAlignLeft"
                    style={{ width: "300px", fontWeight: '600' }}
                    data-title={data.filterName}
                >
                    {data.filterName}
                </td>
                <td
                    className="legacyCellStyle textAlignRight"
                    style={{ width: "60px" }}
                    data-title="Claim Count"
                >
                    {data.claimCount}
                </td>
                <td
                    className="legacyCellStyle textAlignRight"
                    style={{ width: "165px" }}
                    data-title="Claim Value"
                >
                    {data.claimValue}
                </td>
                <td
                    className="legacyCellStyle textAlignRight"
                    style={{ width: "60px" }}
                    data-title="Days Since Created"
                >
                    {data.filterAge}
                </td>
            </tr>
        );
    }

    return (
        <tr
            onClick={handleClick}
            onMouseOver={handleToggle}
            onMouseOut={handleToggle}
            style={{
                borderSpacing: "0px 0px",
                fontSize: "11px",
                fontWeight: "normal",
                cursor: "pointer",
                height: "25px"
            }}
        >
            <td
                style={{
                    paddingLeft: "5px",
                    width: "40%",
                    fontWeight: 900,
                    fontSize: "14px"
                }}
                title={data.filterName}
                data-title={data.filterName}
            >
                {data.filterName}
            </td>
            <td
                style={{
                    paddingLeft: "5px",
                    width: "10%",
                    fontSize: "14px"
                }}
                title="Claim Count"
                data-title="Claim Count"
            >
                {data.claimCount}
            </td>
            <td
                style={{
                    textAlign: "right",
                    paddingRight: "5px",
                    width: "30%",
                    fontSize: "14px"
                }}
                title="Claim Value"
                data-title="Claim Value"
            >
                {data.claimValue}
            </td>
            <td
                style={{
                    textAlign: "right",
                    paddingRight: "5px",
                    width: "20%",
                    fontSize: "14px"
                }}
                title="Days Since Created"
                data-title="Days Since Created"
            >
                {data.filterAge}
            </td>
        </tr>
    );
}

class MyClaimsComponent extends React.Component<
    MyClaimsComponentProps,
    IMyClaimsComponentState
> {
    viewType: eViewType = eViewType.Legacy; // Legacy, Biller, Supervisor
    isStillMounted: boolean = false;
    isFreshData: boolean = false;
    msgClicked: boolean = false;
    xmlHttpAssignedClaims: any;

    requestId = "";
    displayTitle: string = "My Claims";
    displayWidth: string = "623px";

    constructor(props: MyClaimsComponentProps, context: any) {
        super(props, context);

        this.state = {
            userCanViewClaims: true,
            errorCode: 0,
            filteredData: [],
            filterFormState: { ...defaultFilterFormState },
            hideEmpty: true,
            isBusy: false,
            headerFilter: {
                filterCount: "",
                filterInbox: "",
                filterValue: "",
                filterOldest: "",
            },
        };

        // NOTE this is a cheesy way of determing this, but this is implemented very generic
        // there is a db script defining this UI (even though its unlikely we'd ever be able to utilize it)
        // so to add a new param to pass thru, you would need to change uspr_GetUIComponentGroup (called via RH.API)
        // then change the definition.  I would think we'd be better off not sharing this one component for 3 different views

        if (this.props.Name === "RAPMyClaims") {
            if (this.props.W && this.props.W < 625)
                this.viewType = eViewType.Supervisor;
            else this.viewType = eViewType.Biller;

            // remove the 2 px border width from container on supervisor, biller view only has 1 of the 2 borders
            this.displayWidth =
                (this.props.W
                    ? String(this.props.W - (this.viewType !== eViewType.Biller ? 1 : 2))
                    : "625") + "px";
            this.displayTitle = this.viewType !== eViewType.Biller ? "My Claims" : "";
        }
    }

    mySetState(newState: any, callback: any | undefined = undefined) {
        if (this.isStillMounted) {
            if (callback) {
                this.setState(newState, callback);
            } else {
                this.setState(newState);
            }
        }
    }

    logState() {
        let logMsg: string = "MyClaimsComponent current state:";
        if (this && this.state) {
            logMsg =
                logMsg + " data: " + (this.state.data ? this.state.data : "null");
            logMsg =
                logMsg +
                " userCanViewClaims: " +
                (this.state.userCanViewClaims ? this.state.userCanViewClaims : "null");
            logMsg =
                logMsg +
                " errorCode: " +
                (this.state.errorCode ? this.state.errorCode : "null");
            logMsg =
                logMsg +
                " filteredData: " +
                (this.state.filteredData ? this.state.filteredData.toString() : "null");
            logMsg =
                logMsg +
                " filterFormState: { filterId: " +
                this.state.filterFormState.filterId;
            logMsg =
                logMsg +
                ", filterName: " +
                (this.state.filterFormState.filterName
                    ? this.state.filterFormState.filterName
                    : "null");
            logMsg =
                logMsg + ", filterType: " + this.state.filterFormState.filterType;
            //            logMsg = logMsg + ", process: " + (this.state.filterFormState.process ? this.state.filterFormState.process : "null");
            logMsg =
                logMsg +
                ", parameter: " +
                (this.state.filterFormState.parameter
                    ? this.state.filterFormState.parameter
                    : "null");
            logMsg =
                logMsg +
                ", restrictionsExist: " +
                (this.state.filterFormState.restrictionsExist
                    ? this.state.filterFormState.restrictionsExist
                    : "null");
            logMsg = logMsg + "} ";
            logMsg =
                logMsg +
                " hideEmpty: " +
                (this.state.hideEmpty ? this.state.hideEmpty : "null");
            logMsg =
                logMsg + " isBusy: " + (this.state.isBusy ? this.state.isBusy : "null");
        } else {
            logMsg = logMsg + "undefined";
        }
        //console.log(logMsg);
    }

    sendMessageAlert(msg: string) {
        $.event.trigger({
            type: "messageAlert",
            message: msg,
            time: new Date(),
        });
    }

    createSavedFilterRequest(filterType: string, filterId: string) {
        const xmlRequest = `<Filter Action="HomePageQuickSearch" Type="${filterType}" Process="HomePageQuickSearch" ID="${filterId}"/>`;
        return xmlRequest;
    }

    toggleRowHighlighting(info: Filter) {
        const filteredData = [...this.state.filteredData];
        for (let index = 0; index < filteredData.length; ++index) {
            if (info.filterId === filteredData[index].filterId) {
                const item = { ...filteredData[index] };
                item.isHighlighted = !item.isHighlighted;
                filteredData[index] = item;
                this.mySetState({ filteredData: filteredData });
                break;
            }
        }
    }

    isClaimListPopulated = false;

    prepareClaimList = async (strFilterId: any, requestId: any) => {
        this.isClaimListPopulated = false;

        //set user claim list for assigned claims through the controller action.
        let claimListUrl: string =
            URLs.api +
            "/api/data/SetUserClaimListToAssignedList?ActionId=" +
            strFilterId +
            "&requestId=" +
            requestId;

        await fetch(claimListUrl, {
            method: "GET",
            headers: {
                Accept: "applicaiton/xml",
                "Content-Type": "application/xml",
                Authorization: `${getRawToken()}`
            }
        })
            .then((response) => {
                return response.text();
            })
            .then((responseData) => {
                // successfully set claim list, move on to xmlcallback...
                this.xmlHttpAssignedClaims = null;
                this.isClaimListPopulated = true;
                //console.log(`prepareClaimList: responseData = ${responseData}`);
            })
            .catch((error) => {
                //console.log("promise rejected: " + error);
                this.xmlHttpAssignedClaims = null;
                wind.SetApplicationState(true);
                alert("Error setting assigned claim list.");
            });

        this.claimListPrepared();
    };

    claimListPrepared() {
        if (!this.isClaimListPopulated) {
            //console.log("claim list is not populated");
            return;
        }
        const filterFormState = { ...defaultFilterFormState };
        filterFormState.xmlRequest = this.createSavedFilterRequest(
            filterFormState.filterType,
            filterFormState.filterId
        );
        this.mySetState({ filterFormState: filterFormState });
    }

    filterOutData() {
        const filteredData = [...this.state.filteredData];
        for (let index = 0; index < filteredData.length; ++index) {
            const item = { ...filteredData[index] };
            this.determineShown(item);
            filteredData[index] = item;
        }
        this.mySetState({ filteredData: filteredData });
    }

    // this.mySetState({ filteredData: filteredData });

    filterDataCallback(filterData: HeaderFilter) {
        this.mySetState({ headerFilter: filterData }, () => this.filterOutData());
    }

    summaryFilterGoOnClick(info: Filter) {
        //console.log("MyClaims.tsx - summaryFilterGoOnClick");
        const strFilterId = info.filterId;

        this.msgClicked = true;
        this.requestId = wind.requestIdFactory();

        const filterId = strFilterId;
        const newState = { ...this.state };
        const filterFormState = { ...defaultFilterFormState };
        filterFormState.filterType = "Unfiltered";
        if (strFilterId.substr(0, 1).toUpperCase() === "R") {
            filterFormState.filterType = "Remit";
            filterFormState.filterId = filterId.substr(1);
        } else if (strFilterId.substr(0, 1).toUpperCase() === "C") {
            filterFormState.filterType = "Claim";
            filterFormState.filterId = filterId.substr(1);
        } else {
            this.prepareClaimList(strFilterId, this.requestId);
            return;
        }

        filterFormState.xmlRequest = this.createSavedFilterRequest(
            filterFormState.filterType,
            filterFormState.filterId
        );
        this.mySetState({ filterFormState: filterFormState });
    }

    logShowingInfo(hideEmpty: boolean, claimCount: number, item: Filter) {
        if (!item) {
            console.log("logShowingInfo failed - bad item");
            return;
        }
        const hidingEmpty = hideEmpty ? "true" : "false";
        const showing = item.isShown ? "true" : "false";
        const shownMsg = `raw claimCount = ${item.claimCount}, claimCount = ${claimCount}, hideEmpty = ${hidingEmpty}, isShown = ${showing}`;
        console.log(shownMsg);
    }

    filterOutDataFromHeader(row: Filter) {
        if (this.state.headerFilter) {
            if (this.state.headerFilter.filterInbox.length > 0) {
                if (
                    row.filterName &&
                    row.filterName.length > 0 &&
                    row.filterName
                        .toLowerCase()
                        .indexOf(this.state.headerFilter.filterInbox) == -1
                ) {
                    return true;
                }
            }
            if (this.state.headerFilter.filterCount.length > 0) {
                if (
                    row.claimCount &&
                    row.claimCount.length > 0 &&
                    row.claimCount
                        .toLowerCase()
                        .indexOf(this.state.headerFilter.filterCount) == -1
                ) {
                    return true;
                }
            }
            if (this.state.headerFilter.filterValue.length > 0) {
                if (
                    row.claimValue &&
                    row.claimValue.length > 0 &&
                    row.claimValue
                        .toLowerCase()
                        .indexOf(this.state.headerFilter.filterValue) == -1
                ) {
                    return true;
                }
            }
            if (this.state.headerFilter.filterOldest.length > 0) {
                if (
                    row.filterAge &&
                    row.filterAge.length > 0 &&
                    row.filterAge
                        .toLowerCase()
                        .indexOf(this.state.headerFilter.filterOldest) == -1
                ) {
                    return true;
                }
            }
        }
        return false;
    }

    determineShown(data: Filter) { }

    getFilterMetrics(
        status: number,
        responseText: string,
        modFilterId: any,
        shortNodeName: any,
        filterIndex: any,
        startTime: any,
        requestId: any
    ) {
        //console.log("MyClaims.tsx - GetFilterMetrics : responseText = " + responseText + "  shortNodeName = " + shortNodeName);
        //this.logState();

        let item: Filter;
        item = {
            ...this.state.filteredData[filterIndex],
            claimValue: "Unavailable",
        };

        if (status === 200) {
            try {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(responseText, "text/xml");
                let filterNode: Element | null = null;
                const filterNodes = xmlDoc.getElementsByTagName("Filter");

                if (filterNodes) {
                    filterNode = filterNodes[0];
                }
                if (filterNode != null) {
                    let filterAge = filterNode.getAttribute("FilterAge");
                    if (filterAge === "-1") filterAge = "";

                    if (this.state.filteredData.length > 0) {
                        item = { ...this.state.filteredData[filterIndex] };
                        let ruaMsg = filterNode.getAttribute("ruaMsg");
                        if (ruaMsg != null && ruaMsg.length !== 0) {
                            item.filterTitle = ruaMsg;
                            let style: { [k: string]: any } = {};
                            item.rowStyle = style;
                        }

                        item.claimCount = filterNode.getAttribute("ClaimCount");
                        item.claimValue = filterNode.getAttribute("ClaimValue");
                        item.filterAge = filterAge;

                        this.determineShown(item);
                    }
                }
            } catch (exception) {
                console.log("exception occurred in myclaims component " + exception);
            }
        }

        // update state ... tbd do we need to block?
        let filteredData = [...this.state.filteredData];
        filteredData[filterIndex] = item;

        this.mySetState({ filteredData: filteredData }, () =>
            console.log("The filtered data is: ", filteredData)
        );
    }

    //TEST values to make varied responses
    //testClaimCount: number = 0;
    //testClaimDollars: number = 0;
    //TEST END: values to make varied responses

    filtersAddedToState = async () => {
        if (this && this.state && this.state.filteredData) {
            for (let index = 0; index < this.state.filteredData.length; ++index) {
                const filter = this.state.filteredData[index];
                if (filter && filter.metricsRequest) {
                    const metricRequest = filter.metricsRequest;
                    //TEST: uncomment for local testing
                    //const fakeResponse: string = '<?xml version="1.0" encoding="utf-8"?><FilterSummaryInfo><Filter ID = "{filter.filterId}" ClaimCount = "' + this.testClaimCount++ + '" ClaimValue = "$' + this.testClaimDollars++ + '.00" FilterAge = "0" FilterType = "Claim" ruaMsg = ""/></FilterSummaryInfo>';
                    //setTimeout(() => {
                    //        this.getFilterMetrics(fakeResponse,
                    //            filter.filterId,
                    //            filter.filterName,
                    //            metricRequest.filterIndex,
                    //            metricRequest.startTime,
                    //            metricRequest.requestId);
                    //    },
                    //    20);
                    //continue;
                    //TEST END: uncomment for local testing

                    await fetch(metricRequest.url, {
                        method: "GET",
                        headers: {
                            Accept: "applicaiton/xml",
                            "Content-Type": "application/xml",
                            Authorization: `${getRawToken()}`
                        }
                    })
                        .then(async (response) => {
                            this.getFilterMetrics(
                                response.status,
                                await response.text(),
                                filter.filterId,
                                filter.filterName,
                                metricRequest.filterIndex,
                                metricRequest.startTime,
                                metricRequest.requestId
                            );
                        })
                        .catch((error) => {
                            console.log("promise rejected: " + error);
                        });
                }
            }
            //   this.forceUpdate();
        }
    };

    getClaimsHomePageFavoriteFilterUrl(
        filterId: string | null,
        filterType: string,
        requestId: string
    ) {
        let resultStr: string =
            URLs.api + "/api/data/GetClaimsHomePageFavoriteFilter";
        if (filterId && filterId.length > 0) {
            resultStr +=
                "?requestId=" +
                requestId +
                "&FilterId=" +
                filterId +
                "&FilterType=" +
                filterType;
        } else {
            resultStr += "?requestId=" + requestId;
        }

        return resultStr;
    }

    buildClaimsTable() {
        //console.log("MyClaims.tsx - buildClaimsTable");

        const parser = new DOMParser();
        const xml = parser.parseFromString(this.state.data, "text/xml");

        let claimsData = xml.getElementsByTagName("Filter");
        //console.log("MyClaimsComponent data loaded: " + claimsData);

        let filteredData: Filter[] = [];

        if (claimsData.length > 0) {
            for (
                let summaryIndex = 0;
                summaryIndex < claimsData.length;
                summaryIndex++
            ) {
                let claimNode = claimsData[summaryIndex];

                let nodeName = claimNode.getAttribute("Name");
                if (!nodeName) {
                    continue;
                }
                let shortNodeName =
                    nodeName.length > 30 ? nodeName.substr(0, 30) + "..." : nodeName;

                let filterAbbr = "c";
                if (claimNode.getAttribute("FilterType") === "Remit") {
                    filterAbbr = "r";
                }
                if (claimNode.getAttribute("FilterType") === "Non-Filter") {
                    filterAbbr = "";
                }
                let modFilterId = filterAbbr + claimNode.getAttribute("ID");

                let data = new Filter();
                data.filterId = modFilterId;
                data.filterTitle = nodeName;
                data.filterName = shortNodeName;

                if (claimNode.getAttribute("FilterType") === "Non-Filter") {
                    let filterAge = claimNode.getAttribute("FilterAge");
                    if (filterAge === "-1") filterAge = "";

                    data.claimCount = claimNode.getAttribute("ClaimCount");
                    data.claimValue = claimNode.getAttribute("ClaimValue");
                    data.filterAge = filterAge;
                    this.determineShown(data);

                    filteredData.push(data);
                } else {
                    data.claimCount = "";
                    data.claimValue = "Retrieving...";
                    data.filterAge = "";

                    let requestId = wind.requestIdFactory();
                    let startTime = new Date().getTime();

                    let requestUrl: string = this.getClaimsHomePageFavoriteFilterUrl(
                        claimNode.getAttribute("ID"),
                        filterAbbr,
                        requestId
                    );

                    data.metricsRequest = new MetricsRequest();
                    if (data.metricsRequest !== undefined) {
                        data.metricsRequest.url = requestUrl;
                        data.metricsRequest.filterIndex = filteredData.length;
                        data.metricsRequest.startTime = startTime;
                        data.metricsRequest.requestId = requestId;
                    }

                    //console.log(data.metricsRequest !== undefined ? (data.metricsRequest.url + ',' + data.metricsRequest.filterIndex + ',' + data.metricsRequest.startTime + ',' + data.metricsRequest.requestId) : "metricsRequest was undefined.");

                    filteredData.push(data);
                }
            }

            //console.log("MyClaims length END" + this.state.data.length);
        } else {
            let test = new Filter();
            test.filterId = 0;
            test.filterTitle = "";
            test.filterName = "No Filters Available";
            test.claimCount = "";
            test.claimValue = "";
            test.filterAge = "";
            filteredData.push(test);
        }

        this.mySetState({ filteredData: filteredData }, this.filtersAddedToState);

        //console.log("MyClaims length AFTER" + this.state.data.length);
    }

    loadMyClaims() {
        //TEST: uncomment for local testing
        //this.testClaimCount = 0;
        //this.testClaimDollars = 10;
        //let fakeResponse: string =
        //    '<?xml version="1.0" encoding="utf-8"?><FilterSummaryInfo><FilterSummaries><Filter ID="10" Name="All Released" ClaimCount="0" ClaimValue="$0.00" FilterAge="0" FilterType="Claim" /><Filter ID="11" Name="All Released 1500" ClaimCount="0" ClaimValue="$0.00" FilterAge="0" FilterType="Claim" /><Filter ID="14" Name="Testing One More Filter for Lobos" ClaimCount="0" ClaimValue="$0.00" FilterAge="0" FilterType="Claim" /><Filter ID="7" Name="All Unreleased" ClaimCount="0" ClaimValue="$0.00" FilterAge="0" FilterType="Claim" /><Filter ID="1" Name="Claims assigned to me (unrel)" ClaimCount="0" ClaimValue="$0.00" FilterAge="0" FilterType="Non-Filter" /><Filter ID="2" Name="Claims assigned to me (rel)" ClaimCount="0" ClaimValue="$0.00" FilterAge="0" FilterType="Non-Filter" /><Filter ID="3" Name="Claims assigned by a user" ClaimCount="7" ClaimValue="$425.72" FilterAge="105" FilterType="Non-Filter" /></FilterSummaries></FilterSummaryInfo>';
        //this.mySetState({
        //    userCanViewClaims: true,
        //    data: fakeResponse,
        //    isBusy: false,
        //});
        //this.isFreshData = true;
        //return;
        //TEST END: uncomment for local testing

        //console.log("MyClaims.tsx - loadMyClaims");

        const checkUserRightUrl = `${URLs.api}/api/data/userrights/viewClaims`;

        this.mySetState({
            isBusy: true,
        });

        fetch(checkUserRightUrl, {
            headers: {
                Authorization: `${getRawToken()}`
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error(response.statusText);
                }
            })
            .then((responseData) => {
                this.mySetState({
                    userCanViewClaims: responseData.ViewClaims,
                });
                let requestId = wind.requestIdFactory();
                let loadClaimUrl: string = this.getClaimsHomePageFavoriteFilterUrl(
                    "",
                    "",
                    requestId
                );
                return fetch(loadClaimUrl, {
                    method: "GET",
                    headers: {
                        Accept: "application/xml",
                        "Content-Type": "application/xml",
                        Authorization: `${getRawToken()}`
                    }
                })
                    .then((response) => {
                        if (!this.isStillMounted) return;
                        if (response.status === 200) {
                            return response.text();
                        } else {
                            throw new Error(response.statusText);
                        }
                    })
                    .then((responseData) => {
                        if (!this.isStillMounted) return;
                        this.mySetState({
                            data: responseData,
                            isBusy: false,
                        });
                    })
                    .catch((error) => {
                        this.mySetState({
                            data: "",
                            errorCode: error,
                            isBusy: false,
                        });
                    });
            })
            .catch((error) => {
                this.mySetState({
                    data: "",
                    errorCode: error,
                    isBusy: false,
                });
            });
    }

    public componentDidMount() {
        this.isStillMounted = true;
        this.loadMyClaims();
    }

    componentWillUnmount() {
        this.isStillMounted = false;
    }

    myClaimsRefreshButtonOnClick() {
        this.loadMyClaims();
    }

    onHideEmptyFilterChange(newValue: boolean) {
        this.mySetState(
            {
                hideEmpty: newValue,
            },
            () => this.loadMyClaims()
        );
    }

    getClassName() {
        return (
            this.props.Name +
            " flex-componentcontainer " +
            (this.viewType == eViewType.Biller
                ? " RAPMyClaimsBiller "
                : this.viewType == eViewType.Supervisor
                    ? " RAPMyClaimsSupervisor "
                    : " MyClaimsLegacy ")
        );
    }

    renderBusyView() {
        this.isFreshData = true;
        return (
            <React.Fragment>
                <ClaimSummaryContentMsg
                    className={
                        this.viewType == eViewType.Legacy ? "legacy" : "landingPage"
                    }
                >
                    Retrieving...
                </ClaimSummaryContentMsg>
            </React.Fragment>
        );
    }

    // this.state.filteredData[i].claimCount
    anyColumnsWithClaims() {
        if (
            this.state.filteredData[0] &&
            this.state.filteredData[0].filterName !== "No Filters Available"
        ) {
            for (let i = 0; i < this.state.filteredData.length; i++) {
                if (
                    this.state.filteredData[i].claimValue &&
                    this.state.filteredData[i].claimValue == "Retrieving..."
                )
                    return true; // still loading data
                if (
                    this.state.filteredData[i].claimCount &&
                    this.state.filteredData[i].claimCount.length > 0 &&
                    this.state.filteredData[i].claimCount != "0"
                )
                    return true; //  we found data
            }
        }
        return false;
    }

    renderClaimTableView() {
        if (this.isFreshData) {
            this.isFreshData = false;
            this.buildClaimsTable();
        }

        let hasData: boolean = this.anyColumnsWithClaims();

        return (
            <React.Fragment>
                {true ? (
                    <ClaimSummaryContent>
                        <table className="myClaimsHeader" style={{ width: '100%', borderTop: (this.viewType == eViewType.Legacy ? '1px solid #cbcccd' : ''), borderBottom: (this.viewType == eViewType.Legacy ? '1px solid #cbcccd' : '') }}>
                            <FilterTableHeader
                                viewType={this.viewType}
                                data={this.state.headerFilter}
                                filterCallback={(e: any) => this.filterDataCallback(e)}
                            />
                        </table>
                        <TableOverflowDiv
                            style={
                                this.viewType == eViewType.Legacy
                                    ? legacyOverflowStyle
                                    : overflowStyle
                            }
                        >
                            <table id="claimTable" style={{ ...fullwidthStyle, height: "195px" }}>
                                <tbody style={{ display: 'table', width: '100%' }}>
                                    {this.state.filteredData.map((row, index) => {

                                        row.isShown =
                                            this.state.hideEmpty && row.claimCount <= 0
                                                ? false
                                                : true;
                                        return (
                                            row.isShown && (
                                                <FilterTableRow
                                                    key={row.filterId}
                                                    data={row}
                                                    index={index}
                                                    onClick={this.summaryFilterGoOnClick.bind(this)}
                                                    onToggleHighlight={this.toggleRowHighlighting.bind(
                                                        this
                                                    )}
                                                    viewType={this.viewType}
                                                />
                                            )
                                        );
                                    })}
                                    {this.viewType == eViewType.Legacy && this.totalCountView()}
                                </tbody>
                            </table>
                        </TableOverflowDiv>
                        <UserFilterForm
                            onNoData={this.resetFilterState}
                            filterId={this.state.filterFormState.filterId}
                            filterName={this.state.filterFormState.filterName}
                            filterType={this.state.filterFormState.filterType}
                            xmlRequest={this.state.filterFormState.xmlRequest}
                        />
                    </ClaimSummaryContent>
                ) : (
                    this.renderNoDataView()
                )}
            </React.Fragment>
        );
    }

    resetFilterState = () => {
        this.mySetState({ filterFormState: { ...defaultFilterFormState } });
    };

    hasNoFavorites(data: any) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, "text/xml");
        const filterNodes = xmlDoc.getElementsByTagName("Filter");
        const dataNodes = Array.from(filterNodes).filter(
            (filter: any) => filter.attributes.FilterType.value !== "Non-Filter"
        );
        console.log(
            `There are ${!dataNodes.length ? "no" : ""} favorite filters set`
        );
        return !dataNodes.length;
    }

    hasNoCountsInDefaultRows(data: any) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, "text/xml");
        const filterNodes = xmlDoc.getElementsByTagName("Filter");
        const dataNodes = Array.from(filterNodes).filter(
            (filter: any) => { console.log("filter", filter); return (filter.attributes.FilterType.value === "Non-Filter" && filter.attributes.ClaimCount.value !== "0") }
        );
        console.log(
            `There are ${!dataNodes.length ? "no" : ""} counts in default rows`
        );
        return !dataNodes.length;
    }

    renderAlertBody(msg: string) {
        return (
            <React.Fragment>
                <ARMComponentMessage message={msg} />
            </React.Fragment>
        );
    }

    renderNoDataView() {
        if (this.viewType == eViewType.Legacy) {
            return (
                <React.Fragment>
                    <ClaimSummaryContentMsg className={"legacy"}>
                        No Claim Summaries are available.
                    </ClaimSummaryContentMsg>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <ClaimSummaryContent>
                    <table className="myClaimsHeader" style={fullwidthStyle}>
                        <FilterTableHeader
                            viewType={this.viewType}
                            data={this.state.headerFilter}
                            filterCallback={(e: any) => this.filterDataCallback(e)}
                        />
                    </table>
                    <table style={fullStyle}>
                        <tbody>
                            <tr className="disabled noDataColor">
                                <td>
                                    <ARMComponentMessage message="No Filters Available" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <UserFilterForm
                        onNoData={this.resetFilterState}
                        filterId={this.state.filterFormState.filterId}
                        filterName={this.state.filterFormState.filterName}
                        filterType={this.state.filterFormState.filterType}
                        xmlRequest={this.state.filterFormState.xmlRequest}
                    />
                </ClaimSummaryContent>
            </React.Fragment>
        );
    }

    renderNoPermissionView() {
        return (
            <React.Fragment>
                <ClaimSummaryContentMsg
                    className={
                        this.viewType == eViewType.Legacy ? "legacy" : "landingPage"
                    }
                >
                    User Does Not Have Rights To View Claims
                </ClaimSummaryContentMsg>
            </React.Fragment>
        );
    }

    renderNoAvailableDataView() {
        if (this.viewType == eViewType.Legacy) {
            return (
                <React.Fragment>
                    <ClaimSummaryContentMsg className={"legacy"}>
                        No data available.
                    </ClaimSummaryContentMsg>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <ClaimSummaryContent>
                    <table className="myClaimsHeader" style={fullwidthStyle}>
                        <FilterTableHeader
                            viewType={this.viewType}
                            data={this.state.headerFilter}
                            filterCallback={(e: any) => this.filterDataCallback(e)}
                        />
                    </table>
                    <table style={fullStyle}>
                        <tbody>
                            <tr className="disabled noDataColor">
                                <td>
                                    <ARMComponentMessage message="No data available." />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <UserFilterForm
                        onNoData={this.resetFilterState}
                        filterId={this.state.filterFormState.filterId}
                        filterName={this.state.filterFormState.filterName}
                        filterType={this.state.filterFormState.filterType}
                        xmlRequest={this.state.filterFormState.xmlRequest}
                    />
                </ClaimSummaryContent>
            </React.Fragment>
        );
    }

    renderView() {
        //console.log("MyClaimsComponent : renderView");
        if (this.state.isBusy) {
            return this.renderBusyView();
        } else if (this.state.data) {
            if (this.hasNoFavorites(this.state.data) && this.hasNoCountsInDefaultRows(this.state.data)) {
                if (this.state.hideEmpty) {
                    // no favorites, no default row counts and hide empty checkbox:
                    return this.renderNoDataView();
                } else {
                    // no favorites, no default row counts, show empty:
                    // show the default rows
                    return this.renderClaimTableView();
                }
            } else {
                return this.renderClaimTableView();
            }
        } else if (this.state.errorCode) {
            return this.renderNoDataView();
        } else if (!this.state.userCanViewClaims) {
            return this.renderNoPermissionView();
        }
        return this.renderNoAvailableDataView();
    }

    getCountValue(prev: any, next: any) {
        let result = 0;
        try {
            result = parseInt(prev) + parseInt(next);
        } catch (e) { }
        return !Number.isNaN(result) ? result : 0;
    }

    formatMoneyAsString(val: any) {
        try {
            if (Number.isNaN(val)) {
                return "0.00";
            } else {
                try {
                    return val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
                } catch (e) {
                    console.log(e);
                }
            }
        } catch (e1) {
            console.log(e1);
        }
        return " 0.00";
    }

    totalCountView() {
        return this.state.filteredData[0] &&
            this.state.filteredData[0].filterName !== "No Filters Available" ? (
            <React.Fragment>
                <tr className="border-top-line">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr
                    className="rowHeight totalCount-view"
                    style={{ verticalAlign: "bottom", height: "14px" }}
                >
                    <td
                        className="legacyCellStyle textAlignLeft"
                        style={{ fontWeight: "bolder", width: "300px" }}
                    >
                        &nbsp;&nbsp;Total
                    </td>
                    <td
                        className="legacyCellStyle textAlignRight"
                        style={{ width: "60px" }}
                    >
                        {this.state.filteredData
                            .map((record) => record.claimCount)
                            .reduce((prev, next) => this.getCountValue(prev, next))}
                    </td>
                    <td
                        className="legacyCellStyle textAlignRight"
                        style={{ width: "165px" }}
                    >
                        $
                        {this.formatMoneyAsString(
                            this.state.filteredData
                                .map((record) => record.claimValue)
                                .reduce(
                                    (prev, next) =>
                                        parseFloat(prev) + parseFloat(next.replace(/[$,]/g, "")),
                                    0
                                )
                        )}
                    </td>
                    <td style={{ width: "60px" }} />
                </tr>
            </React.Fragment>
        ) : (
            <tr></tr>
        );
    }

    getDate() {
        var currentDate = new Date();
        var options: Intl.DateTimeFormatOptions = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };
        // var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };                

        return "My Claims - " + currentDate.toLocaleDateString('en-US', options);
    }

    render() {
        //console.log("MyClaimsComponent : render");
        //this.logState();
        //console.log("The data is: ", this.state.data);

        const hideEmptyInput = (
            <ARMLandingPageHeaderCaptionOptions>
                <CheckBoxComponent
                    blackLabel={true}
                    labelType="compact" // not bold, not so much padding
                    label="Hide Empty Filters"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.onHideEmptyFilterChange(e.target.checked)
                    }
                    checked={this.state.hideEmpty}
                ></CheckBoxComponent>
            </ARMLandingPageHeaderCaptionOptions>
        );

        let containerStyle = { height: "100%", width: this.displayWidth };
        let refreshButtonInTitle: JSX.Element | undefined = undefined;
        let totalCountRow: JSX.Element | undefined = (
            <TotalCountContainer>
                <div>Total Count may include the same claim in multiple summaries.</div>
                <ClickImage onClick={this.myClaimsRefreshButtonOnClick.bind(this)}>
                    <img style={{ position: 'relative', width: '21px', height: '16px', top: '1px', paddingRight: '5px' }} src={refreshIcon} />
                    <label style={{ color: '#0C55B8', fontSize: '12px' }}>Refresh</label>
                </ClickImage>
            </TotalCountContainer>
        );

        if (
            this.viewType == eViewType.Biller ||
            this.viewType == eViewType.Supervisor
        ) {
            refreshButtonInTitle = (
                <ARMLandingPageHeaderCaptionOptions>
                    <ARMLandingPageHeaderButton
                        domID="refreshButton"
                        disable={false}
                        name="Refresh"
                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.myClaimsRefreshButtonOnClick()}
                    />
                </ARMLandingPageHeaderCaptionOptions>
            );

            if (this.viewType == eViewType.Supervisor)
                containerStyle = { height: "317px", width: this.displayWidth };
            else
                containerStyle = { height: "309px", width: this.displayWidth };
        }

        return (
            <LandingPageComponent
                ComponentClass={this.getClassName()}
                {...this.props}
            >
                <ARMLandingPageHeaderWrapper
                    checkboxes={hideEmptyInput}
                    helpUrl="/Support/Help/HELP_HomePage_MyClaims.htm"
                    title={this.getDate()}
                    titleButtons={refreshButtonInTitle}
                    width={this.viewType == eViewType.Biller ? "860px !important" :
                        (this.viewType == eViewType.Legacy ? "650px !important" : "575px !important")}
                    height={this.viewType == eViewType.Legacy ? "430px" : ""}
                    isBillerView={this.viewType == eViewType.Biller ? true : false}
                    isLandingPage={this.props.Name === "RAPMyClaims" ? true : false}
                >
                    <ContentContainer style={containerStyle}>
                        {this.renderView()}
                        {this.viewType == eViewType.Legacy && totalCountRow}
                    </ContentContainer>
                </ARMLandingPageHeaderWrapper>
            </LandingPageComponent>
        );
    }
}

export default withRouter(MyClaimsComponent);
