import { Reducer } from 'redux';
import { createDataAction, createApiAction, createApiBodyAction, ActionTypes, TApiStatusTypes, ValidationCallback } from '@scripts/util/ActionHelpers';
import { URLs } from '@commonDevResources/constants';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ICrudObject<T extends any> {
    crudId: number;
    originalData?: T;
    data?: T;
    dataSearch?: T;
    dataSearchOriginal?: T; /* not needed?  what if the do a lot of searches, can't track each one, should be in the component itself */
    dataStatus?: TApiStatusTypes;
    redirectURL?: string;
}

// TODO: What if we have a page with more than one CRUD active at a time?
// We need more than one crud object in the data store.
//export interface IMasterCrudMap extends Map<number, ICrudObject> { };
//
//export interface MasterCrudState extends IMasterCrudMap {
//}
export interface MasterCrudState<T extends any> extends ICrudObject<T> { };

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    get: <T extends any>(crud: ICrudObject<T>, validationCallback?: ValidationCallback<T>) => createApiAction('GET_CRUD', `${URLs.api}/api/crud/${crud.crudId}`, crud, validationCallback),
    search: (crud: ICrudObject<any>, validationCallback?: ValidationCallback<unknown>) => createApiBodyAction('SEARCH_CRUD', `${URLs.api}/api/crud/${crud.crudId}`, crud, 'POST', JSON.stringify(crud.data), validationCallback),
    update: (crud: ICrudObject<any>, validationCallback?: ValidationCallback<unknown>) => createApiBodyAction('UPDATE_CRUD', `${URLs.api}/api/crud/${crud.crudId}`, crud, 'PUT', JSON.stringify(crud.data), validationCallback),
    updateWithNcs: (crud: ICrudObject<any>) => createApiBodyAction('UPDATE_CRUD_NCS', `${URLs.api}/api/crud/updateNcs/${crud.crudId}`, crud, 'PUT', JSON.stringify(crud.data)),
    updateWithDelta: (crud: ICrudObject<any>) => createApiBodyAction('UPDATE_DELTA_CRUD', `${URLs.api}/api/crud/updateDelta/${crud.crudId}`, crud, 'PUT', JSON.stringify({ crud: { original: crud.originalData, data: crud.data } })),
    updateWithDeltaNcs: (crud: ICrudObject<any>) => createApiBodyAction('UPDATE_DELTA_CRUD_NCS', `${URLs.api}/api/crud/updateDeltaNcs/${crud.crudId}`, crud, 'PUT', JSON.stringify({ crud: { original: crud.originalData, data: crud.data } })),
    reset: (crud: ICrudObject<any> ) => createDataAction('RESET_CRUD', crud),
};


export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export function DEFAULT_STATE(crudId: number): MasterCrudState<any> {
    return {
        crudId: crudId,
        data: undefined,
        dataSearch: undefined,
        dataSearchOriginal: undefined,
        originalData: undefined,
        dataStatus: undefined,
    };
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export function processCrudAction(state: MasterCrudState<any>, action: KnownActions): MasterCrudState<any> {
    switch (action.type)
    {
        case 'GET_CRUD':
            switch (action.status.status) {
                case "SUCCESS":
                    return {
                        crudId: action.data.crudId,
                        originalData: JSON.parse(JSON.stringify(action.responseData)),
                        data: action.responseData,
                        dataStatus: action.status.status,
                    }
            }
            break;
        case 'SEARCH_CRUD':
            switch (action.status.status)
            {
                case "SUCCESS":
                /*case "FAIL": ?? */
                    return {
                        crudId: action.data.crudId,
                        dataSearch: action.responseData,
                        dataSearchOriginal: JSON.parse(JSON.stringify(action.responseData)),
                        dataStatus: action.status.status,
                    }
            }
            break;
        case 'UPDATE_CRUD':
        case 'UPDATE_CRUD_NCS':
        case 'UPDATE_DELTA_CRUD':
        case 'UPDATE_DELTA_CRUD_NCS':
            switch (action.status.status)
            {
                case "REQUEST":
                    return {
                        ...state,
                        originalData: undefined,
                        data: undefined,
                        dataStatus: action.status.status,
                    }
                case "SUCCESS":
                case "FAIL":
                    return {
                        ...state,
                        originalData: undefined,
                        data: undefined,
                        dataStatus: action.status.status,
                        redirectURL: action.data.redirectURL,
                    }
                default:
                    const exhaustiveCheck: string = action.status.status;
            }
            break;
    }
    return state;
}

export type CrudReducer = (
    defaultCrudState: MasterCrudState<any>,
    state: MasterCrudState<any> | undefined,
    action: KnownActions
) => MasterCrudState<any>

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const crudReducer: CrudReducer = (defaultCrudState: MasterCrudState<any>, state: MasterCrudState<any> | undefined, action: KnownActions) => {
    if (state != undefined)
    {
        switch (action.type)
        {
            case 'GET_CRUD':
            case 'SEARCH_CRUD':
            case 'UPDATE_CRUD':
            case 'UPDATE_CRUD_NCS':
            case 'UPDATE_DELTA_CRUD':
            case 'UPDATE_DELTA_CRUD_NCS':
                if (action.data.crudId == defaultCrudState.crudId)
                {
                    return processCrudAction(state, action);
                }
                break;
            case 'RESET_CRUD':
                return defaultCrudState;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
        }
    }
    return state || defaultCrudState;
}

export function createReducer(crudId: number): Reducer<MasterCrudState<any>, KnownActions> {
    let defaultState = DEFAULT_STATE(crudId);
    return (state: MasterCrudState<any> | undefined, action: KnownActions) => crudReducer(defaultState, state, action);
}