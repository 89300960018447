import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    createApiMapDispatchToProps,
    createApiMapStateToProps,
    IMergeApiComponentProps,
    mergeApiComponentProps, resetApiComponentState
} from '@scripts/util/ApiDataHelpers';
import { Button, Input, LoadingIndicator } from '@optum-uicl/ui-core/dist';
import styled from 'styled-components';
import { pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '@common/AssuranceMenu';
import { ContentColumnWrapper, ContentRowWrapper, DialogButtonCancel, DialogWrapper } from '@common/DialogWrapper';
import { ApplicationState } from '@store/index';
import { actionCreators, IUserEmailConfirmActionProps, IUserEmailConfirmState } from '@store/UserEmailConfirm';
import { ICookies_Config } from "@store/ConfigData";
import { ModalConfirmation } from "@common/ModalConfirmation";
import { ClearSecuritySession } from "@commonResources/window";

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 700px;

    #UserEmailConfirmText {
        width: 900px;
        height: 100px;
    }

    .Description {
        min-width:500px;
        max-width:500px;
    }
`;

export const InputWrapper = styled.div`
    height: 75px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
`;

export const CodeWrapper = styled.div`
    height: 75px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
`;

export const ButtonWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 5px;
`;

export const TextWrapper = styled.div`
    font-size: 14px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
`;
export const SuccessTextWrapper = styled.div`
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    color: #0f0f59;
    padding-bottom: 10px;
`;

export const StatusWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
`;

export const ContentContainer = styled.div`
    width: 712px;
`;

export const LoadingPad = styled.div`
    margin-top: -5px;
`;



/////////////////////////////////////////////////////////////////////////////////////////////

interface IUserEmailConfirmComponentProps {
    canEdit: boolean,
    canView: boolean,
    apiType: string;
}

interface IComponentState {
    cancelLeave: boolean;
    selectedNoteName: string,
    descriptionText: string
}

export const DEFAULT_STATE: IComponentState = {
    cancelLeave: false,
    selectedNoteName: '',
    descriptionText: ''
};

type IOwnProps = IUserEmailConfirmComponentProps & RouteComponentProps<{}>;
type IUserEmailConfirmProps = IMergeApiComponentProps<IUserEmailConfirmState, IUserEmailConfirmActionProps, IOwnProps>;

/////////////////////////////////////////////////////////////////////////////////////////////

export class UserEmailConfirm extends React.Component<IUserEmailConfirmProps, IComponentState> {

    static defaultProps: IUserEmailConfirmComponentProps = {
        canEdit: false,
        canView: false,
        apiType: "UserEmailConfirm",
    };

    constructor(props: IUserEmailConfirmProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        pageLeave();
        this.props.action.ui.setCodeValid(false);
        this.props.action.ui.setBusy(true);
        this.props.action.configData.getConfigData({
            cookies: [],
            config: [{ name: "Notification_EmailFromAddress" }, { name: "isClientUser" }, { name: "isEBOUser" }, { name: "Rebrand" }]
        });
        this.props.action.ui.getEmailInfo();
    }

    public componentWillUnmount() {
        pageLeave();
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public isClientUser() {
        let userId = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "isClientUser");
        return (userId && userId.value === 'True');
    }

    public onCancel(isInternal: boolean) {
        if (!this.props.dataStore.ui.enforceSSOLogin) {
            if (isInternal)
                this.props.history.push('/LandingPage');

            this.props.action.ui.setModalOpen(true);
            return;
        }
        // just log out if enforce flag is set
        ClearSecuritySession();
        sessionStorage.setItem('ciamPrompt', '1');
        this.props.history.push('/Transactions/Logon');
    }

    public validateEmail(email: string) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    public validateCode(code: string) {
        return String(code)
            .toLowerCase()
            .match(/[0-9]{6}/);
    }

    public sendOrLink(linkEmail: boolean) {

        let email = this.props.dataStore.ui.emailAddress;
        this.props.action.ui.setInvalidEmail('');

        if (this.validateEmail(this.props.dataStore.ui.emailAddress)) {
            if ((this.props.dataStore.ui.emailAddress !== this.props.dataStore.ui.originalEmailAddress)
                || (this.props.dataStore.ui.emailAddress != '' && !this.props.dataStore.ui.confirmedEmail)
                || linkEmail) {
                this.props.action.ui.setButtonBusy(true);
                this.props.action.ui.setStatusMessage("Validating email, please wait..");
                // if they've been confirmed already, link
                if (linkEmail) {
                    this.props.action.ui.emailConfirmLink(encodeURIComponent(this.props.dataStore.ui.emailAddress));
                    return;
                }
                this.props.action.ui.sendConfirmationEmail(encodeURIComponent(email));
            } else {
                this.props.history.push("/LandingPage");
            }
        } else {
            this.props.action.ui.setInvalidEmail('Please enter a valid email address.');
        }
    }

    public async sendCode() {
        let code = this.props.dataStore.ui.code;
        this.props.action.ui.setInvalidCode('');
        if (this.validateCode(code)) {
            this.props.action.ui.setButtonBusy(true);
            let validateMsg = "Validating code";
            if (this.props.dataStore.ui.enforceSSOLogin) {
                validateMsg += " and linking user to SSO";
            }
            validateMsg += ", please wait..";
            this.props.action.ui.setStatusMessage(validateMsg);
            if (this.props.dataStore.ui.enforceSSOLogin && this.isClientUser()) {
                this.props.action.ui.sendCodeAndLink(encodeURIComponent(code), encodeURIComponent(this.props.dataStore.ui.emailAddress));
            }
            else {
                await this.props.action.ui.sendConfirmationCode(encodeURIComponent(code));
                if (this.props.dataStore.ui.codeErrorMessage == '') {
                    this.props.action.ui.setCodeValid(true);
                    setTimeout(() => {
                        this.props.history.push("/LandingPage");
                    }, 4000)
                } else {
                    this.props.action.ui.setInvalidCode('Invalid code.');
                }
            }
        }
        else {
            this.props.action.ui.setInvalidCode('Invalid code.');
        }
    }

    public render() {
        let branding = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "Rebrand")?.value;
        let productName = branding === 'Achieve' ? 'Claims Management' : "Assurance";
        let isClientUser = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "isClientUser")?.value !== "False";
        let isEboUser = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "isEBOUser")?.value !== "False";
        let internalOrEbo = isEboUser || !isClientUser;
        let validationEmailAddress = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "Notification_EmailFromAddress")?.value;
        let instructionText = "Make any corrections necessary and click the 'Send Email' button.";
        let emailHasChanged = this.props.dataStore.ui.emailAddress !== this.props.dataStore.ui.originalEmailAddress;
        let linkedMode = (this.props.dataStore.ui.enforceSSOLogin && this.props.dataStore.ui.confirmedEmail) && !emailHasChanged;

        if (linkedMode)
            instructionText = "If the address is correct, click 'Continue'."

        // flow ends with validating users for a Client that doesnt have Enforce SSO login enabled OR an attempt to link to SSO if it is enabled.  
        let buttons = (!this.props.dataStore.ui.enforceSSOLogin && (this.props.dataStore.ui.successMessage && this.props.dataStore.ui.successMessage != ''))
            || this.props.dataStore.ui.flowComplete ? <React.Fragment><br /><br /></React.Fragment> :
            <DialogButtonCancel onClick={event => this.onCancel(internalOrEbo)} />;

        if (this.props.dataStore.ui.successMessage && !internalOrEbo && (!this.props.dataStore.ui.enforceSSOLogin || this.props.dataStore.ui.flowComplete)) {
            setTimeout(() => {
                this.props.history.push("/LandingPage");
            }, 4000)
        }

        return (
            <DialogWrapper title="Validate Your Email Address" buttons={buttons} isBusy={this.props.dataStore.ui.isBusy}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                {
                    (!this.props.dataStore.ui.enforceSSOLogin && internalOrEbo && !this.props.dataStore.ui.codeValidated) || (!this.props.dataStore.ui.successMessage || (this.props.dataStore.ui.enforceSSOLogin && !this.props.dataStore.ui.flowComplete)) ?
                        <ContentWrapper>
                            <ContentColumnWrapper>
                                <TextWrapper>
                                    To enhance security, {productName} now requires valid email addresses.<br /><br />
                                </TextWrapper>
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <TextWrapper>
                                    <div>Please verify the email address below.<br />{instructionText}<br /><br /></div>
                                </TextWrapper>
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                {
                                    (internalOrEbo && this.props.dataStore.ui.originalEmailAddress == null) &&
                                    <React.Fragment>
                                        <TextWrapper>
                                            <div><b>As an internal user, verifying your email will enable you to login
                                                using SSO.</b></div>
                                            <br />
                                        </TextWrapper>
                                    </React.Fragment>
                                }
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <InputWrapper>
                                    <Input
                                        domID="userEmaiLConfirm-emailAddress"
                                        className="text-input"
                                        label="Email to Validate:"
                                        maxLength={300}
                                        size={'small'}
                                        placeholder={'Enter your email address'}
                                        initialValue={this.props.dataStore.ui.emailAddress}
                                        hasError={this.props.dataStore.ui.emailErrorMessage != ''}
                                        errorMessage={this.props.dataStore.ui.emailErrorMessage}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateEmailValue(e.target.value)}
                                    />
                                </InputWrapper>
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <ButtonWrapper>
                                    {
                                        (!this.props.dataStore.ui.enforceSSOLogin && this.props.dataStore.ui.originalEmailAddress != null) ?
                                            <Button
                                                domID="sendEmailButton"
                                                name={((!this.props.dataStore.ui.enforceSSOLogin) && !emailHasChanged) && (this.props.dataStore.ui.emailAddress != '' && this.props.dataStore.ui.confirmedEmail) ? 'Confirm' : 'Send Email'}
                                                buttonType="emphasized"
                                                size="control"
                                                type="button"
                                                disabled={this.props.dataStore.ui.buttonBusy}
                                                onClick={(e: React.SyntheticEvent) => { this.sendOrLink(linkedMode) }}
                                            /> :
                                            <Button
                                                domID="sendEmailButton"
                                                name={(this.props.dataStore.ui.enforceSSOLogin && this.props.dataStore.ui.confirmedEmail) && !emailHasChanged ? 'Continue' : 'Send Email'}
                                                buttonType="emphasized"
                                                size="control"
                                                type="button"
                                                disabled={this.props.dataStore.ui.buttonBusy}
                                                onClick={(e: React.SyntheticEvent) => { this.sendOrLink(linkedMode) }}
                                            />
                                    }

                                </ButtonWrapper>
                            </ContentColumnWrapper>
                            {
                                (this.props.dataStore.ui.allowConfirmation && !this.props.dataStore.ui.emailErrorMessage) &&
                                <React.Fragment>
                                    <ContentColumnWrapper>
                                        <div><br /></div>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <SuccessTextWrapper>
                                            <div>
                                                Check your email for a validation code from {validationEmailAddress}. <br />
                                                You may need to look in your junk email or spam folder.<br />
                                            </div>
                                        </SuccessTextWrapper>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <CodeWrapper>
                                            <Input
                                                domID="userEmaiLConfirm-validationCode"
                                                className="text-input"
                                                label='Validation Code:'
                                                maxLength={10}
                                                size={'small'}
                                                placeholder={'Please enter a valid 6 digit code.'}
                                                initialValue={this.props.dataStore.ui.code}
                                                hasError={this.props.dataStore.ui.codeErrorMessage != ''}
                                                errorMessage={this.props.dataStore.ui.codeErrorMessage}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateCodeValue(e.target.value)}
                                            />
                                        </CodeWrapper>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <ButtonWrapper>
                                            <Button
                                                domID="sendCodeButton"
                                                name={internalOrEbo ? "Link to SSO" : "Confirm"}
                                                buttonType="emphasized"
                                                size="control"
                                                type="button"
                                                disabled={this.props.dataStore.ui.code.length < 6 || this.props.dataStore.ui.code.length > 6 || this.props.dataStore.ui.buttonBusy}
                                                onClick={(e: React.SyntheticEvent) => { this.sendCode() }}
                                            />
                                        </ButtonWrapper>
                                    </ContentColumnWrapper>
                                </React.Fragment>
                            }
                            <ContentColumnWrapper>
                                <ContentContainer>
                                    <SuccessTextWrapper>
                                        <ContentRowWrapper>
                                            {this.props.dataStore.ui.statusMessage &&
                                                <React.Fragment>
                                                    <StatusWrapper>
                                                        <ContentRowWrapper>
                                                            <LoadingPad>
                                                                <LoadingIndicator
                                                                    className="test-indicator"
                                                                    dataTestId="test-loading"
                                                                    domID="test-id"
                                                                    length="30px"
                                                                />
                                                            </LoadingPad>
                                                            <div dangerouslySetInnerHTML={{ __html: this.props.dataStore.ui.statusMessage }} />
                                                        </ContentRowWrapper>
                                                    </StatusWrapper>
                                                </React.Fragment>
                                            }
                                        </ContentRowWrapper>
                                    </SuccessTextWrapper>
                                </ContentContainer>
                            </ContentColumnWrapper>
                        </ContentWrapper> :
                        //email validated
                        <React.Fragment>
                            <ContentContainer>
                                <SuccessTextWrapper>
                                    <div dangerouslySetInnerHTML={{ __html: this.props.dataStore.ui.successMessage }} />
                                </SuccessTextWrapper>
                            </ContentContainer>
                        </React.Fragment>
                }
                <ModalConfirmation
                    isOpen={this.props.dataStore.ui.modalOpen}
                    formattedMessage={(
                        <div>
                            <p>We could not validate your email address. <br /><br />
                                We will prompt you to update and validate your email address the next time you log in,<br />or you may select Validate Email under the Maintenance menu.
                                <br /><br /> Press OK to leave this page. Press Cancel to go back.</p>
                        </div>)
                    }
                    message={'Email not validated!'}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                        this.props.history.push('/LandingPage');
                    }}
                    onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.props.action.ui.setModalOpen(false)}
                />
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IUserEmailConfirmState, IUserEmailConfirmActionProps, IOwnProps, IUserEmailConfirmProps, ApplicationState>(
    createApiMapStateToProps('userEmailConfirm'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(UserEmailConfirm);


export default withRouter(connectedHoc);