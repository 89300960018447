import * as React from 'react';
import { ARMGrid } from '@common/UICLWrappers/ARMGrid';
import { CheckBoxComponent } from '@common/CheckBox';
import { GridConfig, IHeaderProps, IRowProps } from './GridConfig';
import styled from 'styled-components';
import { ARMHeaderCell } from '@common/UICLWrappers/ARMHeaderCell';
import { SortHelper } from '@scripts/util/SortHelper';
import { ISortIndicator, SortDataType } from '@scripts/util/SortHelper';
import readMessage from '@content/images/LandingPage/Message/OldMessage.gif';
import unreadMessage from '@content/images/LandingPage/Message/NewMessage.gif';
import info from '@content/images/LandingPage/Message/infoUp.gif';
import warning from '@content/images/LandingPage/Message/Warning.gif';
import sevGreen from '@content/images/LandingPage/Message/SeverityLevelsGreen.gif';
import sevOrange from '@content/images/LandingPage/Message/SeverityLevelsOrange.gif';
import sevRed from '@content/images/LandingPage/Message/SeverityLevelsRed.gif';
import { MessagesAPI } from './MessagesAPI';

export const GridWrapper = styled.div`
    /* STANDARD OUTER GRID STYLE CONFIGURATION */

    div{
        display:inline;
    }
    background-color:white;
    width: 1000px;
    height: 200px;
    border: black 1px solid;
    overflow-y: scroll;
    /* overflow-x: hidden; */

    #messages-grid{

        /* STANDARD INNER GRID STYLE CONFIGURATION */

        background-color:white;
        .empty-row{
            background-color:white !important;
        }
        overflow-y:scroll;
        overflow-x:scroll;

        table tbody tr > td{
            height:32px !important;
        }

        /* ROW HOVER CONFIGURATION */

        table tbody tr:hover{
            background-color:#edf9ff !important;
            cursor:pointer !important;
        }

        button{
            display: inline !important;
        }

        /* COLUMN WIDTH CONFIGURATIONS */

        .messageDateTimeFormatted{
            min-width:200px;
            max-width:200px;
        }

        .messageSubject{
            overflow: hidden;
            color:red;
            backgroundColor:yellow;
        }

        .delete{
            width:50px;
            padding:0px;
        }
    }
`;

interface IMessagesGridProps {
    messages: MessageList[],
    onRowClick: Function,
    selectedRowId: string,
    updateCheckedForDeleteMessageIds: Function,
}

interface IComponentState {
    messages: MessageList[],
    sortIndicator: ISortIndicator,
    checkedForDeleteMessageIds: string[]
    //selectedColumnName: string,
    //selectedSortDataType: SortDataType,
}

export const DEFAULT_STATE: IComponentState = {
    messages: [],
    sortIndicator: {
        sortColumn: '',
        sortDirection: '',
        sortDataType: SortDataType.String
    },
    checkedForDeleteMessageIds: []
}

export class MessagesGrid extends React.Component<IMessagesGridProps, IComponentState> {

    constructor(props: IMessagesGridProps) {
        super(props)
        this.state = {
            ...DEFAULT_STATE,
            messages: props.messages
        }
        this.handleSorting = this.handleSorting.bind(this);
    }

    handleSorting(sortIndicator: ISortIndicator) {
        const {
            messages
        } = this.state;

        let sortedMessages = SortHelper.Sort(messages, sortIndicator);

        this.setState({
            sortIndicator,
            messages: sortedMessages
        });
    }

    onRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        const {
            messages
        } = this.state;

        const messageId = event.currentTarget.id;
        const targetMessage = messages.find(x => x.id.toString() == messageId);
        const messageIsRead = targetMessage?.isRead;
        if (targetMessage && !messageIsRead) {
            const markAsRead = async () => {
                await new MessagesAPI().markAsRead([messageId]);
            }
            markAsRead();
            targetMessage.isRead = true;
            this.setState({ messages });
        }

        this.props.onRowClick(messageId);
    }

    getCustomHeader() {
        const {
            sortIndicator,
            //selectedSortDataType
        } = this.state;

        const header = ({ columns }: IHeaderProps) => <thead><tr key={Math.random()}>
            {
                <>
                    <ARMHeaderCell
                        dataName="messageDateTimeFormatted"
                        text="Received"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.Date}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="message"
                        text="Subject"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="delete"
                        text="Delete"
                        cellType="text"
                        sortable={false}
                    />
                </>
            }
        </tr></thead>
        return header;
    }

    getCustomRow() {
        const {
            selectedRowId
        } = this.props;

        const {
            checkedForDeleteMessageIds
        } = this.state;

        const row = ({ record }: IRowProps) => {
            let recordId = record.id.toString();
            //debugger;
            return (
                <tr id={recordId} key={recordId} role="button"
                    onClick={(x) => this.onRowClick(x)}
                    className={recordId === selectedRowId ? 'selected' : ''}>
                    {
                        <>
                            <td style={{ width: 200 }}>
                                <div className="cell-component-wrapper" style={{ width: 200, display: 'flex', alignItems: 'center' }}>
                                    <div className="no-margin" style={{ height: 32, display: 'flex', alignItems: 'center' }}>
                                        {record.isRead &&
                                            <img src={readMessage} />
                                        }
                                        {!record.isRead &&
                                            <img src={unreadMessage} />
                                        }
                                        &nbsp;
                                        {record.messageDateTimeFormatted}
                                    </div>
                                </div>
                            </td>
                            <td style={{ width: 700, whiteSpace: 'nowrap' }}>
                                <div className="cell-component-wrapper" style={{ display: 'flex', alignItems: 'center' }} >
                                    <div className="no-margin" style={{ height: 32, display: 'flex', alignItems: 'center' }}>
                                        {/* display icon before message based on severity */}
                                        {record.messageSeverity === 0 &&
                                            <img src={info} />
                                        }
                                        {record.messageSeverity === 1 &&
                                            <img src={warning} />
                                        }
                                        {record.messageSeverity === 2 &&
                                            <img src={sevGreen} />
                                        }
                                        {record.messageSeverity === 3 &&
                                            <img src={sevOrange} />
                                        }
                                        {record.messageSeverity === 4 &&
                                            <img src={sevRed} />
                                        }
                                        &nbsp;
                                        {record.message}
                                    </div>
                                </div>
                            </td>
                            <td style={{ textAlign: 'left' }}>
                                <div style={{ height: '100%', display: 'flex', alignItems: 'center', paddingLeft: '12px' }}>
                                    <CheckBoxComponent id={recordId}
                                        checked={checkedForDeleteMessageIds.includes(recordId)}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateCheckedForDeleteMessageIds(e)}
                                    />
                                </div>
                            </td>
                        </>
                    }
                </tr>
            );
        }
        return row;
    }

    updateCheckedForDeleteMessageIds(e: React.ChangeEvent<HTMLInputElement>) {
        const {
            checkedForDeleteMessageIds
        } = this.state;

        let latestSet = checkedForDeleteMessageIds;

        // add checkbox value to set if checked
        if (e.target.checked) {
            latestSet.push(e.target.id);
        } else {
            // remove checkbox value from set if unchecked
            latestSet = latestSet.filter(function (value) {
                return value != e.target.id;
            });
        }

        this.setState({
            checkedForDeleteMessageIds: latestSet
        });

        this.props.updateCheckedForDeleteMessageIds(latestSet);
    }

    render() {
        const {
            messages
        } = this.props;

        const columns = GridConfig.getColumnsConfig();
        const row = this.getCustomRow();
        const header = this.getCustomHeader();

        return (
            <GridWrapper>
                {messages &&
                    <ARMGrid
                        domID='messages-grid'
                        maxHeight={'200px'}
                        isFixedHeader={true}
                        columns={columns}
                        headerComponent={header}
                        rowComponent={row}
                        records={messages}
                        emptyGridMessage=' '
                        selectionKey={'id'} />
                }
            </GridWrapper>
        )
    }
}
