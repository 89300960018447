import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Input, TextArea, Button, SelectDropdown } from '@optum-uicl/ui-core/dist';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons, DialogButton, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { DialogLegend, DialogFieldset, DialogLabel } from '@common/DialogStyles';
import { SelectComponent } from '../../common/SelectComponent';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IRemitImportOptionsState, IRemitImportOptionsActions, IRemitImportOptionsActionProps, actionCreators, validationCallback } from '@store/RemitImportOptions';
import { CheckBoxComponent } from '@common/CheckBox';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { ICookies_Config } from '@store/ConfigData';
import AssuranceMenu from '../../common/AssuranceMenu';
import { handleChange, pageLeave } from '@commonResources/userModified';


export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const FirstColumnWrapper = styled.div`
    width:400px !important;
    padding: 5;
`;

export const SecondColumnWrapper = styled.div`
    width: 550px;
    padding: 10px;
    padding-right: 5px;
   
    #remit-lob-dropdown{
      width: 535px;
      padding-bottom:5px;
    }
`;

const FieldSetMargin = styled.div`
fieldset {
    margin-left: 0px;
}
`

export const LOBWrapper = styled.div`
    width: 400px;
`;

export const OverrideWrapper = styled.div`
    width: 400px;
`;

export const LabelWrapper = styled.div`
    display: inline;
    margin-bottom: 0.3rem;
    color: #37474F;
    font-family: 'optum-sans-regular';
    font-size: 11px;
    line-height: 1.4em;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
`;

export const CheckboxWrapper = styled.div`
    width: 100px;
    padding-bottom: 5px;
`;

const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 6px;
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

const SelectWrapper = styled.div`
    padding-bottom: 10px;
`;


interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    canDelete: boolean,
    title: string,
    ncsIsEnabled: boolean,
    ncsSkipPrompt: boolean,
    ncsPromptText: string,
    ncsListType: string,

};

interface IComponentState {
    cancelLeave: boolean,
    isAlertOpen: boolean,
    alertMessage: string
}

export const DEFAULT_STATE: IComponentState = { cancelLeave: false, isAlertOpen: false, alertMessage: "" };

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IRemitImportOptionsProps = IMergeCrudComponentProps<IRemitImportOptionsState, IRemitImportOptionsActionProps, IOwnProps>;

enum eModifyButtonType {
    Add,
    Update
}

export class RemitImportOptions extends React.Component<IRemitImportOptionsProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        canDelete: false,
        title: "Remit Import Options Maintenance",
        ncsIsEnabled: false,
        ncsSkipPrompt: false,
        ncsPromptText: "",
        ncsListType: "",
    };

    constructor(props: IRemitImportOptionsProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }
        pageLeave();
        //this.props.action.configData.getConfigData({ cookies: [{ name: "EnhancedClaimStatusEnabled" }, { name: "DirectEntry" }, { name: "SubmitRemitFileEnabled" }], config: [{ name: "EnableTenetFeatures" }, { name: "SubmitRemitFileEnabled" }] });
        this.props.action.configData.getConfigData({ cookies: [{ name: "SubmitRemitFileEnabled" }], config: [] });

        this.props.action.crud.get({ crudId: CrudTypes.mctiRemitOptionsSetup }, validationCallback);

    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public onSubmitNCS(e: any) {
        this.props.action.crud.updateWithDeltaNcs(this.props.dataStore.crud, false);
        this.props.history.push('/LandingPage');
    }

    public onClearNCS(e: any) {
        this.props.action.ui.sendSubmitNCSAlert({ uiData: { value: '' } });
    }

    public onDenyNCS(e: any) {
        this.props.action.ui.sendSubmitNCSAlert({ uiData: { value: '' } });
        this.props.action.crud.updateWithDelta(this.props.dataStore.crud, false);
        this.props.history.push('/LandingPage');
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.IsAlphaNumeric(this.props.dataStore.ui.overrideISA06["@ISA06Value"])) {
            if (this.props.ncsIsEnabled) {
                this.props.action.ui.sendSubmitNCSAlert({ uiData: { value: this.props.ncsPromptText } });
            } else {
                this.props.action.crud.updateWithDelta(this.props.dataStore.crud, false);
                this.props.history.push('/LandingPage');
            }
        }
        else {
            this.setState({ isAlertOpen: true, alertMessage: "Override field cannot contain special characters" });
        }
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    public onClickModifyButton(e: React.SyntheticEvent) {
        if (this.props.dataStore.crud.data) {
            if (this.IsAlphaNumeric(this.props.dataStore.ui.overrideISA06["@ISA06Value"])) {
                let duplicate = this.props.dataStore.crud.data.RemitImportMaintenanceInfo.RemitImports.RemitImport.find(existingRemit => existingRemit["@ID"] === this.props.dataStore.ui.selectedItem["@ID"]);
                if (duplicate && duplicate["@Delete"] === 'true') {
                    let remitLOB: MCMR_RemitImport = duplicate;
                    this.props.action.confirm.openConfirm("A deleted Remit LOB by that name already exists. Click OK to undelete it.", () => this.onClickUndeletRemitLob(remitLOB));
                }
                else if (duplicate && this.props.dataStore.ui.selectedLOB.index === 0) {
                    this.setState({ isAlertOpen: true, alertMessage: "An entry for that Remit LOB already exists. Please select a different one" });
                }
                else {
                    handleChange();
                    switch (this.getModifyButtonType()) {
                        case eModifyButtonType.Add:
                            this.props.action.ui.addRemitLOB({
                                masterCrud: this.props.dataStore.crud.data,
                                uiData: {
                                    '@ID': this.props.dataStore.ui.selectedItem['@ID'],
                                    '@AlternateMatch': this.props.dataStore.ui.alternateMatch,
                                    '@AutoAccept': this.props.dataStore.ui.selectedAutoAccept['value'],
                                    '@BestMatch': this.props.dataStore.ui.bestMatch,
                                    '@Crossover': this.props.dataStore.ui.crossover,
                                    '@ExcludeLOB': this.props.dataStore.ui.excludeLOB,
                                    '@Delete': 'false'
                                }
                            });
                            break;
                        default:
                            this.props.action.ui.updateRemitLOB({
                                masterCrud: this.props.dataStore.crud.data,
                                uiData: {
                                    '@ID': this.props.dataStore.ui.selectedLOB['value'],
                                    '@AlternateMatch': this.props.dataStore.ui.alternateMatch,
                                    '@AutoAccept': this.props.dataStore.ui.selectedAutoAccept['value'],
                                    '@BestMatch': this.props.dataStore.ui.bestMatch,
                                    '@Crossover': this.props.dataStore.ui.crossover,
                                    '@ExcludeLOB': this.props.dataStore.ui.excludeLOB,
                                    '@Delete': 'false'
                                }
                            });
                    }
                }
            }
            else {
                this.setState({ isAlertOpen: true, alertMessage: "Override field cannot contain special characters" });
            }
        }
    }

    public onClickUndeletRemitLob(remitLob: MCMR_RemitImport) {
        this.props.action.ui.restoreRemitLOB({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                '@ID': remitLob['@ID'],
                '@AlternateMatch': remitLob["@AlternateMatch"],
                '@AutoAccept': remitLob["@AutoAccept"],
                '@BestMatch': remitLob["@BestMatch"],
                '@Crossover': remitLob["@Crossover"],
                '@ExcludeLOB': remitLob["@ExcludeLOB"],
                '@Delete': 'false'
            }
        })
    }

    public onClickRemoveButton(e: React.SyntheticEvent) {
        this.props.action.confirm.openConfirm("Do you want to remove " + this.props.dataStore.ui.selectedLOB.text + "? This action cannot be undone.", () => this.onClickConfirmRemoveRemitLOB());
    }

    public IsAlphaNumeric(strValue: string | undefined) {
        if (!strValue)
            return true;

        var ValidCharactersArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ";
        var nLength = ValidCharactersArray.length;

        for (var i = 0; i < nLength; i++) {
            if (ValidCharactersArray.indexOf(strValue.charAt(i)) < 0) {
                return false;
            }
        }

        return true;
    }

    public onClickConfirmRemoveRemitLOB() {
        handleChange();
        this.props.action.ui.removeRemitLOB({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                updateInfo: {
                    '@ID': this.props.dataStore.ui.selectedLOB.value,
                    '@Name': this.props.dataStore.ui.selectedLOB.text,
                    '@Delete': 'false',
                }
            }
        })
    }

    public onClickConfirm(e: React.MouseEvent<HTMLButtonElement>) {
        if (!this.IsAlphaNumeric(this.props.dataStore.ui.overrideISA06["@ISA06Value"])) {
            this.setState({ isAlertOpen: true, alertMessage: "Override field cannot contain special characters" });
        }
        else
            this.props.dataStore.confirm.confirmCallback();
    }

    public onToggleConfirmation(e: React.MouseEvent<HTMLElement>) {
        this.props.action.confirm.closeConfirm();
    }

    public onCopy(e: React.SyntheticEvent) {
        //"Do you want to copy " + document.Form1.RemitLOB.options[document.Form1.RemitLOB.selectedIndex].text + " Matching Criteria to all of your current payers? This action cannot be undone."
        this.props.action.confirm.openConfirm("Do you want to copy " + this.props.dataStore.ui.selectedLOB.text + "?  Matching Criteria to all of your current payers? This action cannot be undone.", () => this.onClickCopyConfirm());
    }

    public onClickCopyConfirm() {
        handleChange();
        this.props.action.ui.copyMatchingCriteria(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    alternateMatch: this.props.dataStore.ui.alternateMatch,
                    bestMatch: this.props.dataStore.ui.bestMatch,
                    excludeLOB: this.props.dataStore.ui.excludeLOB
                }
            }
        );
    }

    public onSelectRemitLOB(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectRemitLOB(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    value: e.target.value,
                    text: e.target.options[e.target.options.selectedIndex].textContent
                }
            });
    }

    public updateSelectLOBList(e: any) {
        var _id = e.value;
        var _name = e.label;
        if (_id) {
            this.props.action.ui.selectLOBList({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    ['@ID']: _id,
                    ['@Name']: _name
                }
            });
        }
    }

    public updateSelectAutoAccept(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectAutoAccept(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: e.target.value,
                    //text: e.target.options[e.target.options.selectedIndex].textContent
                }
            }
        );
    }

    public onChangeCrossover(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.updateCrossover(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: e.target.checked
                }
            }
        );
    }

    public onChangeExcludeLOB(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.updateExcludeLOB(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: e.target.checked
                }
            }
        );
    }

    public onChangeBestMatch(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.updateBestMatch(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: e.target.checked
                }
            }
        );
    }

    public onChangeAlternateMatch(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.updateAlternateMatch(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: e.target.checked
                }
            }
        );
    }

    public onChangeOverrideISA(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.updateOverrideISA(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    '@ISA06Value': e.target.value
                }
            }
        );
    }

    public getModifyButtonType(): eModifyButtonType {
        switch (this.props.dataStore.ui.selectedLOB.index) {
            case 0:
                return eModifyButtonType.Add;
                break;
            default:
                return eModifyButtonType.Update;
                break;
        }
    }

    public getModifyButtonText() {
        switch (this.getModifyButtonType()) {
            case eModifyButtonType.Add:
                return 'Add';
            default:
                return 'Update';
        }
    }

    public isRemoveButtonDisabled() {
        if (this.props.canDelete)
            return (this.props.dataStore.ui.selectedLOB.index == 0);
        else
            return true;
    }

    public isCopyButtonDisabled() {
        if (this.props.canEdit)
            return (this.props.dataStore.ui.selectedLOB.index == 0);
        else
            return true;
    }

    public getRemitLOBs() {
        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.RemitImportMaintenanceInfo ||
            !this.props.dataStore.crud.data.RemitImportMaintenanceInfo.RemitLobList ||
            !this.props.dataStore.crud.data.RemitImportMaintenanceInfo.RemitLobList.RemitLob
        ) return [{ label: '', value: '' }];

        let result: any[] = [];

        this.props.dataStore.crud.data.RemitImportMaintenanceInfo.RemitLobList.RemitLob.forEach(ob => {
            if (ob['@Name'])
                result.push({ label: ob['@Name'], value: ob['@ID'] });
        });
        return result;
    }

    public getRemitImports() {
        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.RemitImportMaintenanceInfo ||
            !this.props.dataStore.crud.data.RemitImportMaintenanceInfo.RemitImports ||
            !this.props.dataStore.crud.data.RemitImportMaintenanceInfo.RemitImports.RemitImport
        ) return [];
        let remitImports: any = this.props.dataStore.crud.data.RemitImportMaintenanceInfo.RemitImports.RemitImport.filter((item) => {
            return item["@Delete"] !== undefined && item["@Delete"] !== "true";
        });

        for (var i = 0; i < remitImports.length; i++) {
            let remitLOBItem: any = this.props.dataStore.crud.data.RemitImportMaintenanceInfo.RemitLobList.RemitLob.find(ob => ob['@ID'] === remitImports[i]['@ID']);
            if (remitLOBItem !== undefined) {
                remitImports[i]['@Name'] = remitLOBItem['@Name']
            }
        }

        return remitImports;
    }

    public getremitLOBSelectedItem() {
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.ui.selectedItem || !this.props.dataStore.crud.data.RemitImportMaintenanceInfo ||
            !this.props.dataStore.crud.data.RemitImportMaintenanceInfo.RemitLobList ||
            !this.props.dataStore.crud.data.RemitImportMaintenanceInfo.RemitLobList.RemitLob
        ) return { value: '', label: '' };

        let item = this.props.dataStore.crud.data.RemitImportMaintenanceInfo.RemitLobList.RemitLob.find(
            ob => ob['@ID'] === this.props.dataStore.ui.selectedItem["@ID"]);
        let result = { value: '', label: '' };
        if (item) {
            result.label = item['@Name'];
            result.value = item['@ID'];
        } else {
            result.label =
                this.props.dataStore.crud.data.RemitImportMaintenanceInfo.RemitLobList.RemitLob[0]['@Name'];
            result.value =
                this.props.dataStore.crud.data.RemitImportMaintenanceInfo.RemitLobList.RemitLob[0]['@ID'];
            this.props.action.ui.selectLOBList({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    ['@ID']: result.value,
                    ['@Name']: result.label
                }
            });
        }

        return result;
    }

    public render() {
        var autoAcceptValues = [{ value: 'A', label: 'Automatically' }, { value: 'M', label: 'Manually ' }, { value: 'N', label: 'Never' }]
        var helpButtons = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        const instruction = <React.Fragment>Select a payer from Remit LOB, select from the available options, click Add, and then click OK.<br /> <i>(For more information, click the Help button.)</i></React.Fragment>;
        let remitLOBs = this.getRemitLOBs();
        let remitImports = this.getRemitImports();
        var addItem: any[] = [{
            '@ID': '',
            '@Name': '- ADD A NEW REMIT LOB -'
        }]
        let data: any;
        data = this.props.dataStore.crud.data ? addItem.concat(remitImports) : addItem;
        let overrideISA06 = this.props.dataStore.crud.data?.RemitImportMaintenanceInfo.RemitImportOverrideSettings?.RemitImportOverrideSetting['@ISA06Value'].trimEnd();
        let crossover = this.props.dataStore.ui['crossover'] === 'Y' ? true : false;
        let excludeLOB = this.props.dataStore.ui['excludeLOB'] === 'Y' ? true : false;
        let alternateMatch = this.props.dataStore.ui['alternateMatch'] === 'Y' ? true : false;
        let bestMatch = this.props.dataStore.ui['bestMatch'] === 'Y' ? true : false;
        let okText = "Yes";
        let cancelText = "No";
        let submitRemitFileEnabled = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "SubmitRemitFileEnabled");

        return (
            <DialogWrapper title="Remit Import Options Maintenance" width='950px' buttons={helpButtons} instruction={instruction} helpUrl='/Support/Help/HELP_Maint_RemitImport.htm' isBusy={!this.props.dataStore.crud.data}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <FirstColumnWrapper>
                    <ContentColumnWrapper>
                        <ContentRowWrapper>
                            <LOBWrapper>
                                <DialogFieldset>
                                    <DialogLegend>Remit LOBs</DialogLegend>
                                    <SelectComponent
                                        title='remitoptions-lob'
                                        size={6}
                                        width='388px'
                                        height='335px'
                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectRemitLOB(e)}
                                        optionFields={{
                                            value: "@ID",
                                            text: "@Name",
                                            deleted: "@Delete"
                                        }}
                                        disabled={false}
                                        records={data}
                                        selectedValue={this.props.dataStore.ui.selectedLOB.value}
                                    />
                                </DialogFieldset>
                            </LOBWrapper>
                        </ContentRowWrapper>
                        {submitRemitFileEnabled !== undefined && submitRemitFileEnabled.value === "1" &&
                            <ContentRowWrapper>
                                <OverrideWrapper>
                                    <DialogFieldset>
                                        <DialogLegend>Customer submitted (Global)</DialogLegend>
                                        <LabelWrapper>
                                            Override ISA06
                                    </LabelWrapper>
                                        <Input
                                            domID="remitoptions-override"
                                            className="text-input"
                                            size='small'
                                            //width='200px'
                                            initialValue={overrideISA06}
                                            maxLength={15}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeOverrideISA(e)}
                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeOverrideISA(e)}
                                            hasError={false}
                                            errorMessage={''}
                                        />
                                    </DialogFieldset>
                                </OverrideWrapper>
                            </ContentRowWrapper>
                        }
                    </ContentColumnWrapper>
                </FirstColumnWrapper>
                <SecondColumnWrapper>
                    <ContentColumnWrapper>
                        <LabelWrapper>
                            Remit LOB:
                        </LabelWrapper>
                        <ContentRowWrapper>
                            <SelectDropdown
                                domID="remit-lob-dropdown"
                                className="dropdown"
                                //label="Remit LOB:"
                                size='small'
                                menuWidth={535}
                                isClearable={false}
                                onChange={(e: any) => this.updateSelectLOBList(e)}
                                options={remitLOBs}
                                initialValue={this.getremitLOBSelectedItem()}
                                //selectedValue={this.props.dataStore.ui.selectedItem["@ID"]}
                                disabled={this.props.dataStore.ui.selectedLOB.index != 0}
                            />
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <SelectWrapper>
                                <SelectComponent
                                    title='remitoptions-lob'
                                    label='Auto Accept:'
                                    size={6}
                                    width='150px'
                                    height='70px'
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateSelectAutoAccept(e)}
                                    optionFields={{
                                        value: "value",
                                        text: "label"
                                    }}
                                    disabled={false}
                                    records={autoAcceptValues}
                                    selectedValue={this.props.dataStore.ui.selectedAutoAccept.value}
                                />
                            </SelectWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <CheckboxWrapper>
                                <CheckBoxComponent
                                    id='remit-immediate'
                                    label='Create secondary for crossovers (R/A only)'
                                    width="400px"
                                    checked={crossover}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeCrossover(e)}
                                />
                            </CheckboxWrapper>
                        </ContentRowWrapper>
                        <FieldSetMargin>
                        <DialogFieldset>
                            <DialogLegend>Matching Criteria</DialogLegend>
                            <ContentColumnWrapper>
                                <ContentRowWrapper>
                                    <CheckboxWrapper>
                                        <CheckBoxComponent
                                            id='remit-immediate'
                                            label='Use most recent'
                                            width="225px"
                                            checked={bestMatch}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeBestMatch(e)}
                                        />
                                    </CheckboxWrapper>
                                </ContentRowWrapper>
                                <ContentRowWrapper>
                                    <CheckboxWrapper>
                                        <CheckBoxComponent
                                            id='remit-immediate'
                                            label='Use alternate matching'
                                            width="225px"
                                            checked={alternateMatch}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAlternateMatch(e)}
                                        />
                                    </CheckboxWrapper>
                                </ContentRowWrapper>
                                <ContentRowWrapper>
                                    <CheckboxWrapper>
                                        <CheckBoxComponent
                                            id='remit-immediate'
                                            label='Use patient ID matching'
                                            width="225px"
                                            checked={excludeLOB}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeExcludeLOB(e)}
                                        />
                                    </CheckboxWrapper>
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <SelectButtons>
                                    <Button
                                        domID="remit_import_copyButton"
                                        name="Copy"
                                        buttonType="standard"
                                        // width="350px"
                                        // height="70px"
                                        size="control"
                                        type="button"
                                        disabled={this.isCopyButtonDisabled()}
                                        onClick={(e: React.SyntheticEvent) => this.onCopy(e)}
                                    />
                                </SelectButtons>
                            </ContentColumnWrapper>
                        </DialogFieldset>
                        </FieldSetMargin>
                        <SelectButtons>
                            <Button
                                domID="remit_import_addButton"
                                name={this.getModifyButtonText()}
                                buttonType="emphasized"
                                size="control"
                                type="button"
                                disabled={!this.props.canEdit}
                                onClick={(e: React.SyntheticEvent<Element, Event>) => this.onClickModifyButton(e)}
                            />
                            <Button
                                domID="remit_import_removeButton"
                                name="Remove"
                                buttonType="standard"
                                size="control"
                                type="button"
                                onClick={(e: React.SyntheticEvent<Element, Event>) => this.onClickRemoveButton(e)}
                                disabled={this.isRemoveButtonDisabled()}
                            />
                        </SelectButtons>
                    </ContentColumnWrapper>
                </SecondColumnWrapper>
                <ModalConfirmation
                    isOpen={this.props.dataStore.confirm.isOpen}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleConfirmation(e)}
                    message={this.props.dataStore.confirm.message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClickConfirm(e)}
                />
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.submitNCSAlert && this.props.dataStore.ui.submitNCSAlert.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearNCS(e)}
                    message={this.props.dataStore.ui.submitNCSAlert}
                    okText={okText}
                    cancelText={cancelText}
                    showDefaultClose={true}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onSubmitNCS(e)}
                    onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.onDenyNCS(e)}
                />
                <ModalConfirmation
                    isOpen={this.state.isAlertOpen}
                    formattedMessage={(
                        <React.Fragment>
                            <p>{this.state.alertMessage}.</p>
                        </React.Fragment>)
                    }
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.setState({ isAlertOpen: false, alertMessage: "" }) }}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                        this.setState({ isAlertOpen: false, alertMessage: "" })

                    }
                    }
                    onDeny={(e: React.MouseEvent<HTMLButtonElement>) => { this.setState({ isAlertOpen: false }) }}
                    alertMode={true}
                />
            </DialogWrapper>
        );
    }
};



var connectedHoc = connect<IRemitImportOptionsState, IRemitImportOptionsActionProps, IOwnProps, IRemitImportOptionsProps, ApplicationState>(
    createCrudMapStateToProps('remitImportOptions'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(RemitImportOptions);

export default withRouter(connectedHoc);
