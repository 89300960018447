import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { ISecondaryBillingState, ISecondaryBillingActions, ISecondaryBillingActionProps, actionCreators, validationCallback } from '@store/SecondaryBilling';
import { defaultState as defaultUIState } from '@store/ui/SecondaryBillingUI';
import { IsFieldFilled, IsNumeric } from '@commonResources/validations';
import { TabContainer, Input, TextArea, Button, SelectDropdown, Tabs } from '@optum-uicl/ui-core/dist';
import { DialogWrapper, OKCancelButtons, DialogInstruction, CenteredContent, ContentColumnWrapper } from '@common/DialogWrapper';
import { DialogLegend, DialogFieldset, DialogLabel } from '@common/DialogStyles';
import { SelectComponent } from '../../common/SelectComponent';
import { CheckBoxComponent } from '@common/CheckBox';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { ICookies_Config } from '@store/ConfigData';
import { handleChange, pageLeave } from '@commonResources/userModified';
import ITabChangeInfo from "@components/SupportTools/SiteFile/SiteFile";


export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const RowWrapperRight = styled.div`
    display: flex;
    width: 420px    
`;

export const RowWrapperLeft = styled.div`
    display: flex;
    width: 525px;
`;

export const SecondaryBillingContent = styled.div`
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    padding: 5px;
    min-height: 580px;
    width: 1000px;
    padding: 10px;
    font-size: 12pt;

#global-CheckSecPayer_label,
#global-fPayers_label,
#global-holdPayers_label,
#global-swapdisplay_label,
#global-holdAltMatch_label,
#global-allowDupRemit_label,
#options-acceptLocalClaims_label,
#options-checkPrevBill_label,
#options-postCodeCoIns_label,
#options-postCodeDeduct_label,
#options-postCodePatient_label,
#options-postDocContrl_label,
#OKP-checkPrevBill_label,
#OKP-acceptLocalClaims2_label,
#OKP-PostValCoins_label,
#OKP-PostValDeduct_label,
#OKP-PostValPat_label,
#OKP-PostDocCntrl_label,
#OKP-splitClaimLines_label,
#OKP-removeZeroPayServices_label,
    {
        font-size: 12pt;
    }
#global-CheckSecPayer_wrapper,
#global-swapdisplay_wrapper,
#global-allowDupRemit_wrapper,
#options-checkPrevBill_wrapper,
#options-postCodeCoIns_wrapper,
#options-postCodePatient_wrapper,
#options-postDocContrl_wrapper
    {
        padding-left:15px;
    }

#global-selectPayers,
#global-holdCodes,
#options-selectPayers,
#options-HCDEOBClaim,
#OKP-typeOFBill,
#OKP-HCDEOBClaim
    {
        width: 140px;
        padding-left:12px;
    }
#options-selectPayers
{
        width: 140px;
        padding-left:12px;
    }
#options-PostAdjInfo_label,
#options-HCDClaim_label
    {
        margin-left: 5px;
        margin-right: 15px;
        margin-top:5px;
        font-weight: 600;
     }
#OKP-typeOfbill_label,
#OKP-HCDClaim_label,
#OKP-PostAdjInfo_label
    {
        margin-left: 5px;
        margin-right: 15px;
        margin-top:5px;
        font-weight: 600;
    }
#OKP-line_label
    {
        padding-left: 10px;
        font-weight: 600;
     }
#OKP-numberOfLines
    {
        width:50px;
    }
#OKP-splitClaimLines
    {
        padding-right: 10px;
    }
#OKP-removeZeroPayServices_wrapper {
    padding-left: 30px !important;
}
#OKP-PayerList
    {
        width: 450px;
    }
#row5
    {
        padding-top:3px;
        padding-bottom:5px;
    }
#row6Left
    {
        padding-top:3px;
    }
`;

export const SecondaryBillingComercialPayers = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    width: 490px;

    #SBCommercialPayersSelected,
    #SBCommercialPayersManaged,
    #tabs
    { 
	    -webkit-box-shadow: none !important;
	    -moz-box-shadow: none !important;
	    box-shadow: none !important;
    }
    .contentClass
    {   
        #padding-top:10px;
        #padding: 0px;
        margin-left:7px;    
    }
    .tabcss {
        span[class*='TabsWrapper__TabsSwitcherWrapper-'] {
            margin-left: 5px;

            button[class*='buttons__UnstyledButton-']{
                padding: 5px;
    }
        }
        [class*='TabContentWrapper-']{
            padding: 10px 0px;
        }
    }
    .dd100
    { 
        width: 100px;
    }
    fieldset
        { 
            margin: 0px 
        }
`;

export const NonCommercialPayerRow = styled.div`
        padding-left: 9px;
        padding-top: 10px;
        padding-bottom: 5px;
`;

export const SecondaryBillingRightContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

    min-height: 180px;
    width: 420px; 
    padding: 10px;
`;

export const FormRow = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const HidButtons = styled.div`
    visibility: hidden;
`;

export const ContentRowWrapperTop = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    padding-bottom:5px;
`;

export const ContentRowWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const ContentRowWrapperSpaceBetween = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 95%;
`;

export const ContentRowWrapperMediCal = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
 `;


interface IDropdownItem {
    label: string,
    value: string,
}

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    title: string,
    typeOfBill: MCSB_GenericLookupList[],
    ncsIsEnabled: boolean,
    ncsSkipPrompt: boolean,
    ncsPromptText: string,
    ncsListType: string,
}

interface IComponentState {

}

export const DEFAULT_STATE: IComponentState = {};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type ISecondaryBillingProps = IMergeCrudComponentProps<ISecondaryBillingState, ISecondaryBillingActionProps, IOwnProps>;

export class SecondaryBilling extends React.Component<ISecondaryBillingProps, IComponentState> {

    protected medicalLinesChecked: boolean;
    protected removeZeroPayServicesChecked: boolean;

    protected updatingPage: boolean;

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        title: "Secondary Billing Maintenance",
        typeOfBill: [
            {
                '@Name': ' ',
                '@ID': 'D',
            },
            {
                '@Name': 'Inpatient',
                '@ID': 'I',
            },
            {
                '@Name': 'Outpatient',
                '@ID': 'O',
            }
        ],
        ncsIsEnabled: false,
        ncsSkipPrompt: false,
        ncsPromptText: "",
        ncsListType: "",
    };

    constructor(props: ISecondaryBillingProps) {
        super(props);
        this.medicalLinesChecked = false;
        this.removeZeroPayServicesChecked = false;
        this.updatingPage = false;
        this.state = DEFAULT_STATE;
    }

    public componentWillMount() {
    }

    public componentDidMount() {
        pageLeave();
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.props.history.push('/LandingPage');
        }

        this.props.action.crud.get({ crudId: CrudTypes.mctiSecondaryBillingSetup }, validationCallback);

        if (!this.props.dataStore.ui.initialValuesLoaded) {
            this.props.action.ui.loadGlobalValues({
                masterCrud: this.props.dataStore.crud.data, uiData: { id: "", value: "", }
            })
        }

        this.props.action.configData.getConfigData({ cookies: [], config: [{ name: "isClientUser" }] });
    }

    public componentDidUpdate(prevProps: any, prevState: any) {

        if (this.props.dataStore.crud.dataStatus === 'SUCCESS' && this.updatingPage)
            this.props.history.push('/LandingPage');

    }

    public isClientUser() {
        let userId = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "isClientUser");
        return (userId && userId.value === 'True');
    }

    public componentWillUnmount() {
        pageLeave();
        if (this.props.dataStore.ui.changed) {
            this.onCancelWOSave(true);
        }
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public validateForm(): boolean {

        let errorFound = false;

        if (this.props.dataStore.ui.selectedItem["@HoldCode"] === "" && this.props.dataStore.ui.holdCode1Checked === "Y") {
            this.onSendGenericAlert(<React.Fragment>Please select a hold code.</React.Fragment>);
            this.onError("holdcode", "Please enter a holdcode");

            errorFound = true;
        }

        if (this.props.dataStore.ui.selectedItem["@AltMatchHoldCode"] === "" && this.props.dataStore.ui.holdCode2Checked === "Y") {
            this.onSendGenericAlert(<React.Fragment>Please select an Alternate Match hold code.</React.Fragment>);
            this.onError("altholdcode", "Please enter an Alternate Match hold code")
            errorFound = true;
        }

        if (errorFound) {
            return false;
        }

        return true;
    }

    public onError(id: string, val: string) {

        this.props.action.ui.loadErrorValues({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: id,
                value: val,
            }
        });
    }

    public onSubmitNCS(e: any) {
        console.log('onSubmitNCS::SecondaryBilling');
        //this.onAlert("");
        this.props.action.crud.updateWithDeltaNcs(this.props.dataStore.crud);
        this.updatingPage = true;
        //this.props.history.push('/LandingPage');
    }

    public onClearNCS(e: any) {
        console.log('onClearNCS::SecondaryBilling');
        this.props.action.ui.sendSubmitNCSAlert({ masterCrud: this.props.dataStore.crud, uiData: { value: '' } });
    }

    public onDenyNCS(e: any) {
        console.log('onDenyNCS::SecondaryBilling');
        this.props.action.ui.sendSubmitNCSAlert({ masterCrud: this.props.dataStore.crud, uiData: { value: '' } });
        // now insert the crud to save at the current site...
        this.props.action.crud.updateWithDelta(this.props.dataStore.crud);
        this.updatingPage = true;
        //this.props.history.push('/LandingPage');
    }

    public onOKSubmitSecondaryBilling(e: React.ChangeEvent<HTMLButtonElement>) {
        if (!this.validateForm())
            return;

        if (this.props.ncsIsEnabled) {
            this.props.action.ui.sendSubmitNCSAlert({ masterCrud: this.props.dataStore.crud, uiData: { value: this.props.ncsPromptText } });
        } else {
            this.props.action.crud.updateWithDelta(this.props.dataStore.crud);

            const location = {
                pathname: '/LandingPage',
                state: { fromDashboard: true }
            }
            this.updatingPage = true;

        }
    }

    public onClickAddButton(e: React.SyntheticEvent) {

        if (!this.crudLoaded())
            return;

        //medicaid split validation on adding a payer
        if (this.props.dataStore.ui.secondaryBillingItem["@EnableMedicaidSplit"] === "Y") {
            var lineCount = this.props.dataStore.ui.medicaidLineCount;
            if (!IsNumeric(lineCount) || (parseInt(this.props.dataStore.ui.medicaidLineCount) < 1)) {
                this.onSendGenericAlert(<React.Fragment>Split Claim Lines must be a number between 1 and 99.</React.Fragment>);
                return false;
            }

            if (this.props.dataStore.ui.secondaryBillingItem["@PostAdjInfo"] !== "S") {
                this.onSendGenericAlert(<React.Fragment>Post Adjudication Information must be selected as 'Detailed' for Medi-Cal Split Claim Lines.</React.Fragment>);
                this.updatePayerDropDownValues("@PostAdjInfo", "S");
            }
        }

        this.props.action.ui.addUpdatePayer({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: "",
                value: "",
            }
        });

        this.props.action.ui.changedPageDefaults(true);
    }

    public loadSelectedItem(): MCSB_SecondaryBillingList {

        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.SecondaryBillingSetup ||
            !this.props.dataStore.crud.data.SecondaryBillingSetup ||
            !this.props.dataStore.crud.data.SecondaryBillingSetup.SecondaryBillingList)
            return this.props.dataStore.ui.selectedItem;

        var buttonSwitch = document.getElementById("addButton");
        if (buttonSwitch != null) {
            buttonSwitch.style.margin = "10px";
            buttonSwitch.style.width = "80px";
            buttonSwitch.style.textAlign = "center";
        }
        if (!this.props.dataStore.ui.initialValuesLoaded) {
            this.props.action.ui.loadGlobalValues({
                masterCrud: this.props.dataStore.crud.data, uiData: { id: "", value: "", }
            })
        }
        return this.props.dataStore.crud.data.SecondaryBillingSetup.SecondaryBillingList;
    }

    public onPayerRemoveConfirm(e: any) {
        this.onPayerConfirmRemove('');
    }
    public onPayerClickRemoveButton(e: React.SyntheticEvent) {
        if (this.props.dataStore.ui.secondaryBillingItem['@ID'] === "")
            return;

        const msg = "Do you want to remove  '" + this.props.dataStore.ui.secondaryBillingItem['@Name'] + "'? \n\r This action cannot be undone.";
        this.onPayerConfirmRemove(msg);
    }

    public onPayerConfirmRemove(msg: string) {
        this.props.action.ui.errorPayerRemoveConfirm({ masterCrud: this.props.dataStore.crud.data, uiData: { value: msg } });
    }

    public onMediCalRemoveConfirm(e: any) {
        this.onMediCalConfirmRemove('');
    }
    public onClickMediCalRemoveButton(e: any) {

        //this.props.action.ui.mediCalStoreE(e.target.checked)
        this.medicalLinesChecked = e.target.checked;

        // need to change buttons to YES/NO
        const msg = "<div>Are you sure you want to split these payer claims based on Medi-Cal payer requirements?<br><br>" +
            "Click <b>OK</b> to continue. The system sets the <b>Post Adjudication Information</b> value to Detailed.<br>" +
            "Click <b>CANCEL</b> to cancel. The system clears the <b>Medi-Cal Split Claim Lines</b> check box.<div/>";

        if (e.target.checked) {// && this.props.dataStore.ui.secondaryBillingItem["@PostAdjInfo"]!=="S") {
            this.onMediCalConfirmRemove(msg)
        } else {
            this.onToggleMediCalCheckbox(e.target.checked, "@EnableMedicaidSplit")
        }
    }

    public onMediCalConfirmRemove(msg: string) {
        this.props.action.ui.errorMediCalRemoveConfirm({ masterCrud: this.props.dataStore.crud.data, uiData: { value: msg } });
    }

    public onRemoveButton(e: any) {

        this.props.action.ui.removePayer({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: "",
                value: "",
            }
        });
    }

    public onSendGenericAlert(e: any) {
        this.props.action.ui.callAlert(e);
    }

    public onReleaseAlert(e: any) {
        this.props.action.ui.callAlert(undefined);
        return
    }

    public onCancelWOSave(e: any) {
        this.props.action.ui.cancelPageButton(e);
    }

    public onLeavingPage(e: any) {
        this.props.history.push("/LandingPage");
    }

    public onCancelSecondaryBilling(e: any) {
        if (this.props.dataStore.ui.changed) {
            this.onCancelWOSave(true);
        } else {
            this.onLeavingPage("/LandingPage");
        }
    }

    public onClearAlert(e: any) {
        this.onAlert("");
    }

    public onAlert(msg: string) {
        this.props.action.ui.sendSecondaryBillingAlert({ masterCrud: this.props.dataStore.crud.data, uiData: { value: msg } });
    }

    public onToggleCheckbox(e: React.ChangeEvent<HTMLInputElement>, val: string) {

        var checkedValue = "";
        if (e.target.checked) {
            checkedValue = "Y";
        } else {
            checkedValue = "N";
        }
        if (!this.props.dataStore.ui.initialValuesLoaded) {
            this.props.action.ui.loadGlobalValues({
                masterCrud: this.props.dataStore.crud.data, uiData: { id: "", value: "", }
            })
        }

        this.props.action.ui.updateCheckBox({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: val,
                value: checkedValue,
            }
        });

        this.props.action.ui.changedPageDefaults(true);
    }

    public updatePayerDropDownValues(id: string, val: string) {

        this.props.action.ui.updateDropdownList({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: id,
                value: val,
            }
        });

        if (id === '@MedicaidSplitClaimLines') {
            this.updateMedicaidValue(val)
        }

    }

    public updateMedicaidValue(val: string) {
        this.props.action.ui.updateMediciaidValue({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }

    public updateTOBValues(id: string, val: string) {

        this.props.action.ui.updatePayerByTOB({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: val,
                value: val,
            }
        });

        this.props.action.ui.changedPageDefaults(true);
    }

    public onTogglePayerCheckbox(e: React.ChangeEvent<HTMLInputElement>, val: string) {

        var checkedValue = "";
        if (e && e.target.checked) {
            checkedValue = "Y";
        } else {
            checkedValue = "N";
        }

        this.props.action.ui.updateSelectedPayerCheckBox({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: val,
                value: checkedValue,
            }
        });
    }

    public onToggleMediCalCheckbox(e: any, val: string) {

        var checkedValue = "";
        var boxValue = typeof e === "boolean" ? e : false


        if (boxValue) {
            checkedValue = "Y";
            this.updatePayerDropDownValues("@PostAdjInfo", "S");
        } else {
            checkedValue = "N";
            if (typeof e !== "boolean") this.props.action.ui.clearSplitClaimLines("");
            this.onTogglePayerCheckbox(e, "@RemoveZeroPayServices");
        }


        this.props.action.ui.updateSelectedPayerCheckBox({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: val,
                value: checkedValue,
            }
        });
    }

    public onSelectNonPlusCommercialPayer(e: React.ChangeEvent<HTMLSelectElement>, isCommercial: string) {

        this.props.action.ui.updateSelectedPayer({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: e.target.value,
                value: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
            }
        });

        this.props.action.ui.updateAddButton({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: "false",
                value: "UPDATE"
            }
        });

        //is this needed?
        this.props.action.ui.isNonCommercial({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: "",
                value: isCommercial
            }
        });

        //this.props.action.ui.selectTab(0);
        this.props.action.ui.selectTab({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: "0",
                value: "",
            }
        });
    }

    public hideRemoveButton(hide: boolean) {

        var selectLabel = document.getElementById("removeButton");
        if (selectLabel != null) {
            if (hide) {
                selectLabel.style.display = "none";
            }
            else {
                selectLabel.style.display = "flex";
            }
        }
    }

    public crudLoaded(): boolean {
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.SecondaryBillingSetup &&
            this.props.dataStore.crud.data.SecondaryBillingSetup.SecondaryBillingList &&
            this.props.dataStore.crud.data.SecondaryBillingSetup.SecondaryBillingList.SecondaryBilling)
            return true;

        return false;
    }

    /* -----------------------------------  */
    /* -----  DATA HELP METHODS ----------  */
    /* -----------------------------------  */

    public getHoldCodeLookupList(lookupList: MCSB_GenericLookupList[] | null) {
        if (!this.crudLoaded() ||
            !lookupList || lookupList.length === 0)
            return [];

        let result: any[] = [];

        lookupList.forEach(ob => {
            if (ob['@ID'])
                result.push({ label: ob['@ID'], value: ob['@Name'] });
        });
        return result;
    }

    public getGenericLookupList_Array(lookupList: MCSB_GenericLookupList[] | null) {
        if (!this.crudLoaded() ||
            !lookupList || lookupList.length === 0)
            return [];

        let result: any[] = [];

        lookupList.forEach(ob => {
            if (ob['@Name'])
                result.push({ label: ob['@Name'], value: ob['@ID'] });
        });
        return result;
    }

    public getHoldCodeLookupList_SelectedItem(lookupList: MCSB_GenericLookupList[] | null, idToFind: string | undefined): IDropdownItem {
        if (!this.crudLoaded() ||
            idToFind === undefined || !lookupList || lookupList.length === 0
        ) return { value: '', label: '' };

        let item = lookupList.find(
            ob => ob['@ID'] === idToFind);
        let result = { value: ' ', label: ' ' };
        if (item) {
            result.label = item['@ID'];
            result.value = item['@Name'];
        } else {
            result.label = 'Select';
            result.value = '0';
            if (idToFind !== '') {
                /*RASS-26016? Nir senario where the code present in secondary billing is not in hold code list*/
                this.updatePayerDropDownValues("@HoldCode", "")
            }
        }

        return result;
    }

    public getGenericLookupList_SelectedItem(lookupList: MCSB_GenericLookupList[] | null, idToFind: string | undefined): IDropdownItem {
        if (!this.crudLoaded() ||
            idToFind === undefined || !lookupList || lookupList.length === 0
        ) return { value: '', label: '' };

        let item = lookupList.find(
            ob => ob['@ID'] === idToFind);
        let result = { value: ' ', label: ' ' };
        if (item) {
            result.label = item['@Name'];
            result.value = item['@ID'];
        } else {
            result.label = 'Select';
            result.value = '0';
        }

        return result;
    }

    public lookupList_SelectedItem(lookupList: any[], idToFind: string | undefined) {
        if (!this.crudLoaded() ||
            idToFind === undefined || !lookupList || lookupList.length === 0
        ) return false;

        let item = lookupList.find(
            ob => ob['value'] === idToFind);

        if (item) {
            return item;
        } else {
            return { value: ' ', label: ' ' };
        }
    }

    public getRecordsForDisplay(lookupList: MCSB_SecondaryBilling[] | null, filterLobID: string, exclude: boolean) {

        if (!this.crudLoaded() || !lookupList || lookupList.length === 0)
            return [];

        let result: any[] = [];
        let excluded: any[] = [];

        lookupList.forEach(ob => {
            if (ob['@Name'] && (ob['@LobID'] === filterLobID)) { result.push(ob) }
            else { excluded.push(ob) }
        });

        if (exclude) {
            return excluded;
        } else {
            return result;
        }
    }

    getAllPayersforDisplay() {

        let data: any[] = [];
        let random: any = []

        let crudPayers = !this.props.dataStore.crud.data || !this.props.dataStore.crud.data.SecondaryBillingSetup ||
            !this.props.dataStore.crud.data.SecondaryBillingSetup.SecondaryBillingList ? null :
            this.props.dataStore.crud.data.SecondaryBillingSetup.PayerList.Payer

        let selectedPayerList = !this.props.dataStore.crud.data || !this.props.dataStore.crud.data.SecondaryBillingSetup ||
            !this.props.dataStore.crud.data.SecondaryBillingSetup.SecondaryBillingList ? null :
            this.props.dataStore.crud.data.SecondaryBillingSetup.SecondaryBillingList.SecondaryBilling

        if (selectedPayerList)
            selectedPayerList.forEach(ob => {
                if (ob['@Name']) {
                    random.push(ob['@Name'].replace(/\s\([D,O,I]\)/, ""))
                }
            });

        if (crudPayers && crudPayers.length !== 0) {
            crudPayers.forEach(ob => {
                if (ob['@Name'] && random.includes(ob['@Name'])) { data.push({ label: (ob['@Name'] + " [Selected]"), value: ob['@ID'] + ob["@FormType"] }) }
                else { data.push({ label: ob['@Name'], value: ob['@ID'] + ob["@FormType"] }) }

            });
        }
        return data;
    }

    onTabChange(selectInfo: ITabChangeInfo) {
        this.props.action.ui.selectTab({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: selectInfo.tabIndex,
                value: "",
            }
        });

        this.onInitialTab(selectInfo.tabIndex);
    }

    onInitialTab(tabIndex: ((prevState: number) => number) | number) {

        var hide = true;
        if (this.props.dataStore.ui.selectedTabIndex === 1) {
            hide = false;
        }
        this.hideRemoveButton(hide);

        return this.props.dataStore.ui.selectedTabIndex;
    }

    onListSelection(e: any) {

        var property = true;
        var addButtonLabel = "UPDATE";

        if (e && e.label) {
            var userSelection = e.label;
            if (userSelection.indexOf("Selected") > 0) {
                property = false;
            } else {
                addButtonLabel = "ADD"
            }
        }
        this.props.action.ui.updateRemoveButton(property)
        this.props.action.ui.updateAddButton({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: "false",
                value: addButtonLabel
            }
        });

        this.props.action.ui.updateDropdownPayer({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: e.value,
                value: e.label,
            }
        })

        //is this needed ??
        this.props.action.ui.isNonCommercial({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: "",
                value: "false"
            }
        });
    }

    getManagedTab() {
        return (
            <SelectDropdown
                domID="OKP-PayerList"
                className="dropdown"
                label="NAME:"
                isClearable={false}
                isSearchable={true}
                size='small'
                options={this.getAllPayersforDisplay()}
                onChange={(e: any /*React.ChangeEvent<HTMLSelectElement>*/) => this.onListSelection(e)}
                initialValue={this.props.dataStore.ui.allPayersListPointer === "" ? { value: '', label: '' } :
                    this.lookupList_SelectedItem(this.getAllPayersforDisplay(), this.props.dataStore.ui.allPayersListPointer)}
            />
        );
    }

    getSelectedTab() {

        var commercialPayers = this.getRecordsForDisplay(
            (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.SecondaryBillingSetup ||
                !this.props.dataStore.crud.data.SecondaryBillingSetup.SecondaryBillingList ? null :
                this.props.dataStore.crud.data.SecondaryBillingSetup.SecondaryBillingList.SecondaryBilling), "5", false)

        return (
            <SelectComponent
                title='Selected'
                size={6}
                width='450px'
                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectNonPlusCommercialPayer(e, "false")}
                optionFields={{
                    value: "@ID",
                    text: "@Name"
                }}
                records={commercialPayers}
                selectedValue={this.props.dataStore.ui.commercialPayersPointer}
                key={this.props.dataStore.ui.clearCommercial}
            >
            </SelectComponent>
        );
    }

    getTabs() {
        var tabJson = [
            {
                domID: 'SBCommercialPayersSelected',
                label: 'Selected',
                tabContent: this.getSelectedTab()
            },
            {
                domID: 'SBCommercialPayersManaged',
                label: 'Manage',
                tabContent: this.getManagedTab()
            },
        ];
        return tabJson;
    }

    getAddUpdateButtonText() {
        return "ADD"
    }

    public render() {
        var instruction = <React.Fragment>Use this page to set up the requirements that must be met before creating a secondary claim for each of your secondary payers.<br></br><i>For more information, click the ? in the title bar.</i> </React.Fragment>;
        var buttons = <OKCancelButtons disableOK={!this.props.dataStore.ui.changed || !this.props.canEdit} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOKSubmitSecondaryBilling(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancelSecondaryBilling(e)} />;
        var labelStyles: React.CSSProperties = { marginRight: '10px' };
        var nonCommercialPayers = this.getRecordsForDisplay(
            (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.SecondaryBillingSetup ||
                !this.props.dataStore.crud.data.SecondaryBillingSetup.SecondaryBillingList ? null :
                this.props.dataStore.crud.data.SecondaryBillingSetup.SecondaryBillingList.SecondaryBilling), "5", true);
        var selectedItem = this.loadSelectedItem();
        var popupStyles: React.CSSProperties = { alignContent: 'center', justifyContent: 'center' };
        let isNDCUser = !this.isClientUser();
        let revealHCD = this.props.dataStore.ui.selectedItem["@HCDEnabled"] === "1";
        let okText = "Yes";
        let cancelText = "No";
        let pageChangesWereMade = this.props.dataStore.ui.changed;
        if (this.props.dataStore.ui.changed)
            handleChange()

        return (
            <DialogWrapper title={this.props.title} instruction={instruction} helpUrl='/Support/Help/HELP_Maint_SecBill.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                <ContentWrapper id={"content_wrapper_SecondaryBilling"}>
                    <SecondaryBillingContent id='global-fieldset'>
                        <DialogFieldset >
                            <DialogLegend>Global Options</DialogLegend>
                            <ContentRowWrapperTop>
                                <RowWrapperLeft>
                                    <CheckBoxComponent
                                        id='global-CheckSecPayer'
                                        label='Check For Secondary Payer'
                                        checked={selectedItem["@SecondaryPayerCheck"] === "Y"}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onToggleCheckbox(e, "@SecondaryPayerCheck")}
                                    />
                                </RowWrapperLeft>
                                <RowWrapperRight>
                                    <CheckBoxComponent
                                        id='global-holdPayers'
                                        label='Hold Unknown Payers for Review'
                                        checked={!(selectedItem["@HoldCode"] === "") || (selectedItem["@HoldCode"] === "" &&
                                            this.props.dataStore.ui.holdCode1Checked === "Y")}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onToggleCheckbox(e, "@HoldCode")}
                                    />
                                    <SelectDropdown
                                        domID="global-holdCodes"
                                        className="dropdown dd100"
                                        isClearable={false}
                                        isSearchable={true}
                                        disabled={this.props.dataStore.ui.holdCodeList1Disabled === undefined ? !(selectedItem["@HoldCode"] !== "") || (selectedItem["@HoldCode"] === "" &&
                                            this.props.dataStore.ui.holdCode2Checked === "Y") : this.props.dataStore.ui.holdCodeList1Disabled}
                                        size='small'
                                        menuWidth={120}
                                        options={this.getHoldCodeLookupList((!this.props.dataStore.crud.data ||
                                            !this.props.dataStore.crud.data.SecondaryBillingSetup
                                            ? null : this.props.dataStore.crud.data.SecondaryBillingSetup.HoldCodeList.HoldCode))}

                                        initialValue={this.getHoldCodeLookupList_SelectedItem(
                                            (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.SecondaryBillingSetup
                                                ? null : this.props.dataStore.crud.data.SecondaryBillingSetup.HoldCodeList.HoldCode), selectedItem["@HoldCode"])}

                                        onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updatePayerDropDownValues("@HoldCode", e.label)}
                                        hasError={(this.props.dataStore.ui.holdCode1Error && this.props.dataStore.ui.holdCode1Error !== "") ? true : false}
                                        errorMessage={""}//this.props.dataStore.ui.holdCode1Error}
                                    />
                                </RowWrapperRight>
                            </ContentRowWrapperTop>
                            <ContentRowWrapperTop>
                                <RowWrapperLeft>
                                    <CheckBoxComponent
                                        id='global-swapdisplay'
                                        label='Swap Display of Insured/Other Insured (1500)'
                                        checked={selectedItem["@SwapInsuredOther"] === "Y"}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onToggleCheckbox(e, "@SwapInsuredOther")}
                                    />
                                </RowWrapperLeft>
                                <RowWrapperRight>
                                    <CheckBoxComponent
                                        id='global-holdAltMatch'
                                        label='Hold Alternate Match for Review'
                                        checked={!(selectedItem["@AltMatchHoldCode"] === "") || (selectedItem["@AltMatchHoldCode"] === "" &&
                                            this.props.dataStore.ui.holdCode2Checked === "Y")}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onToggleCheckbox(e, "@AltMatchHoldCode")}
                                    />
                                    <SelectDropdown
                                        domID="global-selectPayers"
                                        className="dropdown"
                                        isClearable={false}
                                        isSearchable={true}
                                        disabled={this.props.dataStore.ui.holdCodeList2Disabled === undefined ? !(selectedItem["@AltMatchHoldCode"] !== "") || (selectedItem["@AltMatchHoldCode"] === "" &&
                                            this.props.dataStore.ui.holdCode2Checked === "Y") : this.props.dataStore.ui.holdCodeList2Disabled}
                                        size='small'
                                        menuWidth={120}
                                        options={this.getHoldCodeLookupList((!this.props.dataStore.crud.data ||
                                            !this.props.dataStore.crud.data.SecondaryBillingSetup
                                            ? null : this.props.dataStore.crud.data.SecondaryBillingSetup.HoldCodeList.HoldCode))}

                                        initialValue={this.getHoldCodeLookupList_SelectedItem(
                                            (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.SecondaryBillingSetup
                                                ? null : this.props.dataStore.crud.data.SecondaryBillingSetup.HoldCodeList.HoldCode), selectedItem["@AltMatchHoldCode"])}

                                        onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updatePayerDropDownValues("@AltMatchHoldCode", e.label)}
                                        hasError={(this.props.dataStore.ui.holdCode2Error && this.props.dataStore.ui.holdCode2Error !== "") ? true : false}
                                        errorMessage={""}
                                    />
                                </RowWrapperRight>
                            </ContentRowWrapperTop>
                            {isNDCUser &&
                                <ContentRowWrapperTop id="row3">
                                    <CheckBoxComponent
                                        id='global-allowDupRemit'
                                        label='Allow Duplicate Remit File Import'
                                        checked={selectedItem["@DuplicateFileAllowed"] === "Y"}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onToggleCheckbox(e, "@DuplicateFileAllowed")} />
                                </ContentRowWrapperTop>}
                        </DialogFieldset>
                        <DialogFieldset >
                            <DialogLegend>Options for Unknown Payers</DialogLegend>
                            <ContentRowWrapperTop id="row4">
                                <RowWrapperLeft>
                                    <CheckBoxComponent
                                        id='options-checkPrevBill'
                                        label='Check For Previous Bill'
                                        checked={selectedItem["@PreviousBillCheck"] === "Y"}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onToggleCheckbox(e, "@PreviousBillCheck")} />
                                </RowWrapperLeft>
                                <RowWrapperRight>
                                    <CheckBoxComponent
                                        id='options-acceptLocalClaims'
                                        label='Accept Locally Printed Claims'
                                        disabled={this.props.dataStore.ui.acceptLclPrntClaimsDisabled || !(selectedItem["@HoldCode"] === "") || (selectedItem["@HoldCode"] === "" &&
                                            this.props.dataStore.ui.holdCode1Checked === "Y")}
                                        checked={selectedItem["@AcceptLocalPrint"] === "Y" && selectedItem["@HoldCode"] === ""}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onToggleCheckbox(e, "@AcceptLocalPrint")}
                                    />
                                </RowWrapperRight>
                            </ContentRowWrapperTop>
                            <ContentRowWrapperTop id="row5">
                                <RowWrapperLeft>
                                    <CheckBoxComponent
                                        id='options-postCodeCoIns'
                                        label='Post Value Code For Coinsurance'
                                        checked={selectedItem["@Coinsurance"] === "Y"}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onToggleCheckbox(e, "@Coinsurance")} />
                                </RowWrapperLeft>
                                <RowWrapperRight>
                                    <CheckBoxComponent
                                        id='options-postCodeDeduct'
                                        label='Post Value Code For Deductible'
                                        checked={selectedItem["@Deductible"] === "Y"}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onToggleCheckbox(e, "@Deductible")} />
                                </RowWrapperRight>
                            </ContentRowWrapperTop>
                            <ContentRowWrapperTop id="row6">
                                <RowWrapperLeft id="row6Left">
                                    <CheckBoxComponent
                                        id='options-postCodePatient'
                                        label='Post Value Code For Patient Liability'
                                        checked={selectedItem["@PatLiability"] === "Y"}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onToggleCheckbox(e, "@PatLiability")} />
                                </RowWrapperLeft>
                                <RowWrapperRight>
                                    <ContentRowWrapperSpaceBetween>
                                        <label id='options-PostAdjInfo_label'>Post Adjudication Information:</label>
                                    <SelectDropdown
                                        domID="options-selectPayers"
                                        className="dropdown"

                                        isClearable={false}
                                        isSearchable={true}
                                        size='small'
                                        menuWidth={180}
                                        options={this.getGenericLookupList_Array((!this.props.dataStore.crud.data ||
                                            !this.props.dataStore.crud.data.SecondaryBillingSetup
                                            ? null : this.props.dataStore.crud.data.SecondaryBillingSetup.PostAdjInfoList.PostAdjInfo))}

                                        initialValue={this.getGenericLookupList_SelectedItem(
                                            (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.SecondaryBillingSetup
                                                ? null : this.props.dataStore.crud.data.SecondaryBillingSetup.PostAdjInfoList.PostAdjInfo), selectedItem["@PostAdjInfo"])}

                                        onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updatePayerDropDownValues("@PostAdjInfo*", e.value)}

                                    />
                                    </ContentRowWrapperSpaceBetween>
                                </RowWrapperRight>
                            </ContentRowWrapperTop>
                            <ContentRowWrapperTop >
                                <RowWrapperLeft>
                                    <CheckBoxComponent
                                        id='options-postDocContrl'
                                        label='Post Document Control Number'
                                        checked={selectedItem["@PostDCNYN"] === "Y"}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onToggleCheckbox(e, "@PostDCNYN")} />
                                </RowWrapperLeft>
                                {revealHCD &&
                                    <RowWrapperRight>
                                    <ContentRowWrapperSpaceBetween>
                                        <label id='options-HCDClaim_label'>HCD Claim + EOB type:</label>
                                        <SelectDropdown
                                            domID="options-HCDEOBClaim"
                                            className="dropdown"
                                            isClearable={false}
                                            isSearchable={true}
                                            size='small'
                                            menuWidth={180}
                                            options={this.getGenericLookupList_Array((!this.props.dataStore.crud.data ||
                                                !this.props.dataStore.crud.data.SecondaryBillingSetup
                                                ? null : this.props.dataStore.crud.data.SecondaryBillingSetup.HCDClaimEOBFormTypeList.HCDClaimEOBFormType))}

                                            initialValue={this.getGenericLookupList_SelectedItem(
                                                !this.props.dataStore.crud.data ||
                                                    !this.props.dataStore.crud.data.SecondaryBillingSetup
                                                    ? null : this.props.dataStore.crud.data.SecondaryBillingSetup.HCDClaimEOBFormTypeList.HCDClaimEOBFormType, selectedItem["@HCDClaimEOBFormType"])}

                                            onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updatePayerDropDownValues("@HCDClaimEOBFormType*", e.value)}
                                        />
                                    </ContentRowWrapperSpaceBetween>
                                    </RowWrapperRight>
                                }
                            </ContentRowWrapperTop>
                        </DialogFieldset>
                        <DialogFieldset >
                            <DialogLegend>Options For Known Payers</DialogLegend>
                            <ContentRowWrapper>
                                <ContentColumnWrapper>
                                    <SecondaryBillingComercialPayers>
                                        <DialogFieldset id='commercialPayers' >
                                            <DialogLegend>Commercial Payers</DialogLegend>
                                            <TabContainer
                                                domID='tabs'
                                                size={'300px'}
                                                initialTab={this.onInitialTab(this.props.dataStore.ui.selectedTabIndex)}
                                                onTabChange={(e: React.SyntheticEvent, state: { tabIndex: number | ((prevState: number) => number); }) => this.onTabChange({ tabIndex: state.tabIndex })}
                                                tabs={this.getTabs()}
                                                dataTestId='dataTestId'
                                                contentClass='contentClass'
                                                className='tabcss'
                                                contentPadding={'4px'}
                                            />
                                        </DialogFieldset >
                                        <FormRow>
                                        </FormRow>
                                        <DialogFieldset >
                                            <DialogLegend>Non Commercial Payers</DialogLegend>
                                            <NonCommercialPayerRow>
                                                <SelectComponent
                                                    title='Selected'
                                                    size={6}
                                                    width='450px'
                                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectNonPlusCommercialPayer(e, "true")}
                                                    optionFields={{
                                                        value: "@ID",
                                                        text: "@Name"
                                                    }}
                                                    records={nonCommercialPayers}
                                                    selectedValue={this.props.dataStore.ui.noncommercialPayersPointer}
                                                    key={this.props.dataStore.ui.clearNonCommercial}
                                                >
                                                </SelectComponent>
                                            </NonCommercialPayerRow>
                                        </DialogFieldset >
                                    </SecondaryBillingComercialPayers>
                                </ContentColumnWrapper>
                                <SecondaryBillingRightContent>
                                    <ContentRowWrapper>
                                        <label id='OKP-typeOfbill_label'  >Type Of Bill</label>
                                        <SelectDropdown
                                            domID="OKP-typeOFBill"
                                            className="dropdown"
                                            isClearable={false}
                                            isSearchable={true}
                                            size='small'
                                            menuWidth={180}
                                            disabled={this.props.dataStore.ui.secondaryBillingItem["@FormType"] !== 'I'}
                                            options={this.getGenericLookupList_Array(this.props.typeOfBill)}
                                            initialValue={this.getGenericLookupList_SelectedItem(
                                                this.props.typeOfBill, this.props.dataStore.ui.secondaryBillingItem["@TypeOfBill"])}
                                            onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateTOBValues(e.id, e.value)}
                                        />
                                    </ContentRowWrapper>
                                    <ContentRowWrapper>
                                        <CheckBoxComponent
                                            id='OKP-checkPrevBill'
                                            label='Check For Previous Bill'
                                            checked={this.props.dataStore.ui.secondaryBillingItem["@PreviousBillCheckYN"] === "Y"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onTogglePayerCheckbox(e, "@PreviousBillCheckYN")}
                                        />
                                    </ContentRowWrapper>
                                    <ContentRowWrapper>
                                        <CheckBoxComponent
                                            id='OKP-acceptLocalClaims2'
                                            label='Accept Locally Printed Claims'
                                            checked={this.props.dataStore.ui.secondaryBillingItem["@AcceptLocalPrintYN"] === "Y"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onTogglePayerCheckbox(e, "@AcceptLocalPrintYN")}
                                        />
                                    </ContentRowWrapper>
                                    <ContentRowWrapper>
                                        <CheckBoxComponent
                                            id='OKP-PostValCoins'
                                            label='Post Value Code for Coinsurance'
                                            checked={this.props.dataStore.ui.secondaryBillingItem["@CoinsuranceYN"] === "Y"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onTogglePayerCheckbox(e, "@CoinsuranceYN")}
                                        />
                                    </ContentRowWrapper>
                                    <ContentRowWrapper>
                                        <CheckBoxComponent
                                            id='OKP-PostValDeduct'
                                            label='Post Value Code for Deductible'
                                            checked={this.props.dataStore.ui.secondaryBillingItem["@DeductibleYN"] === "Y"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onTogglePayerCheckbox(e, "@DeductibleYN")}
                                        />
                                    </ContentRowWrapper>
                                    <ContentRowWrapper>
                                        <CheckBoxComponent
                                            id='OKP-PostValPat'
                                            label='Post Value Code for Patient Liability'
                                            checked={this.props.dataStore.ui.secondaryBillingItem["@PatLiabilityYN"] === "Y"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onTogglePayerCheckbox(e, "@PatLiabilityYN")}
                                        />
                                    </ContentRowWrapper>
                                    <ContentRowWrapper>
                                        <CheckBoxComponent
                                            id='OKP-PostDocCntrl'
                                            label='Post Document Control Number'
                                            checked={this.props.dataStore.ui.secondaryBillingItem["@PostDCNYN"] === "Y"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onTogglePayerCheckbox(e, "@PostDCNYN")}
                                        />
                                    </ContentRowWrapper>
                                    <ContentRowWrapper>
                                        <ContentRowWrapperSpaceBetween>
                                            <label id='OKP-PostAdjInfo_label'>Post Adjudication Information:</label>
                                        <SelectDropdown
                                            domID="options-selectPayers"
                                            className="dropdown"

                                            isClearable={false}
                                            isSearchable={true}
                                            size='small'
                                            menuWidth={180}
                                            options={this.getGenericLookupList_Array((!this.props.dataStore.crud.data ||
                                                !this.props.dataStore.crud.data.SecondaryBillingSetup
                                                ? null : this.props.dataStore.crud.data.SecondaryBillingSetup.PostAdjInfoList.PostAdjInfo))}

                                            initialValue={this.getGenericLookupList_SelectedItem(
                                                (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.SecondaryBillingSetup
                                                    ? null : this.props.dataStore.crud.data.SecondaryBillingSetup.PostAdjInfoList.PostAdjInfo),
                                                this.props.dataStore.ui.secondaryBillingItem["@PostAdjInfo"] === "" ? "N" : this.props.dataStore.ui.secondaryBillingItem["@PostAdjInfo"])}

                                            onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updatePayerDropDownValues("@PostAdjInfo", e.value)}
                                        />
                                        </ContentRowWrapperSpaceBetween>
                                    </ContentRowWrapper>
                                    {revealHCD &&
                                        <ContentRowWrapper>
                                        <ContentRowWrapperSpaceBetween>
                                            <label id='OKP-HCDClaim_label'>HCD Claim + EOB type:</label>
                                            <SelectDropdown
                                                domID="OKP-HCDEOBClaim"
                                                className="dropdown"
                                                isClearable={false}
                                                isSearchable={true}
                                                size='small'
                                                menuWidth={180}
                                                options={this.getGenericLookupList_Array((!this.props.dataStore.crud.data ||
                                                    !this.props.dataStore.crud.data.SecondaryBillingSetup
                                                    ? null : this.props.dataStore.crud.data.SecondaryBillingSetup.HCDClaimEOBFormTypeList.HCDClaimEOBFormType))}

                                                initialValue={this.getGenericLookupList_SelectedItem(
                                                    (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.SecondaryBillingSetup
                                                        ? null : this.props.dataStore.crud.data.SecondaryBillingSetup.HCDClaimEOBFormTypeList.HCDClaimEOBFormType), this.props.dataStore.ui.secondaryBillingItem["@HCDClaimEOBFormType"])}

                                                onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updatePayerDropDownValues("@HCDClaimEOBFormType", e.value)}
                                            />
                                        </ContentRowWrapperSpaceBetween>
                                        </ContentRowWrapper>
                                    }
                                    <ContentRowWrapper>
                                        <ContentRowWrapperMediCal>
                                        <CheckBoxComponent
                                            id="OKP-splitClaimLines"
                                            label='Medi-Cal Split Claim Lines'
                                            checked={this.props.dataStore.ui.secondaryBillingItem["@EnableMedicaidSplit"] === "Y"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onClickMediCalRemoveButton(e)}
                                        />
                                        <Input
                                            domID="OKP-numberOfLines"
                                            className="text-input"
                                            maxLength={2}
                                            initialValue={this.props.dataStore.ui.secondaryBillingItem["@MedicaidSplitClaimLines"]}
                                            onChange={(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => this.updatePayerDropDownValues("@MedicaidSplitClaimLines", e.target.value)}
                                        />
                                            <label id='OKP-line_label'>(default = 14)</label>
                                        </ContentRowWrapperMediCal>
                                    </ContentRowWrapper>
                                    <ContentRowWrapper>
                                        <CheckBoxComponent
                                            id="OKP-removeZeroPayServices"
                                            label='Remove Zero Pay Services'
                                            checked={this.props.dataStore.ui.secondaryBillingItem["@RemoveZeroPayServices"] === "Y"}
                                            disabled={this.props.dataStore.ui.secondaryBillingItem["@EnableMedicaidSplit"] === "N"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onTogglePayerCheckbox(e, "@RemoveZeroPayServices")}
                                        />
                                    </ContentRowWrapper>
                                    <SelectButtons>
                                        <Button
                                            domID="addButton"
                                            name={this.props.dataStore.ui.buttonLabel}
                                            size="control"
                                            buttonType="emphasized"
                                            type="button"
                                            disabled={this.props.dataStore.ui.buttonDisabled}
                                            onClick={(e: React.SyntheticEvent) => this.onClickAddButton(e)}
                                            style={labelStyles}
                                        />
                                        <Button
                                            domID="removeButton"
                                            name="REMOVE"
                                            size="control"
                                            buttonType="emphasized"
                                            type="button"
                                            disabled={this.props.dataStore.ui.button2Disable} //{this.isRemoveButtonDisabled()}
                                            onClick={(e: React.SyntheticEvent) => this.onPayerClickRemoveButton(e)}
                                        />
                                    </SelectButtons>
                                </SecondaryBillingRightContent>
                            </ContentRowWrapper>
                        </DialogFieldset>
                    </SecondaryBillingContent>
                </ContentWrapper>
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.SecondaryBillingAlert && this.props.dataStore.ui.SecondaryBillingAlert.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearAlert(e)}
                    message={this.props.dataStore.ui.SecondaryBillingAlert}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClearAlert(e)}
                />
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.payerRemoveConfirm && this.props.dataStore.ui.payerRemoveConfirm.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onPayerRemoveConfirm(e)}
                    formattedMessage={(
                        <div>
                            <p>Do you want to remove  {this.props.dataStore.ui.secondaryBillingItem['@Name']} ? </p>
                            <p>This action cannot be undone.</p>
                        </div>)}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onRemoveButton(e)}
                />
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.medicalRemoveConfirm && this.props.dataStore.ui.medicalRemoveConfirm.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onMediCalRemoveConfirm("medi-cal")}
                    formattedMessage={(
                        <div style={popupStyles}>
                            <p>Are you sure you want to split these payer claims based on Medi-Cal payer requirements?</p>
                            <p>Click <b>Yes</b> to continue. The system sets the <b>Post Adjudication Information</b> value to Detailed.</p>
                            <p>Click <b>No</b> to cancel. The system clears the <b>Medi-Cal Split Claim Lines</b> check box.</p>
                        </div>)}
                    okText='Yes'
                    cancelText='No'
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onToggleMediCalCheckbox(true, "@EnableMedicaidSplit")}
                    onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.onToggleMediCalCheckbox("deny", "@EnableMedicaidSplit")}
                />
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.genericAlertOK}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onReleaseAlert(undefined)}
                    alertMode={true}
                    formattedMessage={this.props.dataStore.ui.genericAlertOK}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onReleaseAlert(undefined)}
                />
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.submitNCSAlert && this.props.dataStore.ui.submitNCSAlert.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearNCS(e)}
                    message={this.props.dataStore.ui.submitNCSAlert}
                    okText={okText}
                    cancelText={cancelText}
                    showDefaultClose={true}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onSubmitNCS(e)}
                    onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.onDenyNCS(e)}
                />
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.cancelWOSave}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onCancelWOSave(false)}
                    formattedMessage={(
                        <div style={popupStyles}>
                            <b>Are you sure you want to leave this page?</b> <br /> <p> Your changes may NOT be saved.</p>
                        </div>)}
                    okText='Leave'
                    cancelText='Stay'

                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onLeavingPage(pageChangesWereMade)}

                    onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.onCancelWOSave(false)}
                />
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<ISecondaryBillingState, ISecondaryBillingActionProps, IOwnProps, ISecondaryBillingProps, ApplicationState>(
    createCrudMapStateToProps('secondaryBilling'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(SecondaryBilling);

export default withRouter(connectedHoc);
