import { Reducer } from 'redux';
import { createDataAction, ActionTypes, createApiAction, createApiBodyAction } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import { URLs } from '@commonDevResources/constants';
import { CrudTypes } from '@commonResources/CrudTypes';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IPasswordField {
    text: string;
    errorFlag: boolean;
    errorMessage?: string;
}

export interface IChangePasswordUIState {
    currentPasswordField: IPasswordField;
    newPasswordField: IPasswordField;
    confirmNewPasswordField: IPasswordField;
    minimumLength: number;
    alphanumericRequired: boolean;
    upperAndLowerCaseRequired: boolean;
    specialCharacterRequired: boolean;
    userType: string;
    validated: boolean;
    submitSucceeded: boolean;
    instructions?: string[];
    warningMessage?: string;
    loginStatusCode?: number;
    isCiamUser: boolean;
    isBusy: boolean;
}

interface ILoginModel {
    Username: string;
    Password: string;
    ClientAlias: string;
    LoginType: string;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

//DEFAULTS
export const defaultPasswordField: IPasswordField = {
    text: "",
    errorFlag: false
};

export const defaultState: IChangePasswordUIState = {
    currentPasswordField: { ...defaultPasswordField },
    newPasswordField: { ...defaultPasswordField },
    confirmNewPasswordField: { ...defaultPasswordField },
    minimumLength: 8,
    alphanumericRequired: true,
    upperAndLowerCaseRequired: true,
    specialCharacterRequired: true,
    userType: "U",
    validated: false,
    submitSucceeded: false,
    isCiamUser: false,
    isBusy: false
};

//ACTION DEFINITIONS
export interface IValidatePasswordFields extends ICrudActionData<MCChangePassword, IChangePasswordUIState> {
}

export interface IGetPasswordRequirements extends ICrudActionData<MCChangePassword, IChangePasswordUIState> {
}

export const actionCreators = {
    //Prefixing all actions with CPA for Change Password Action to tie the action name exactly to Change Password.
    getPasswordRequirements: (requirementInfo: IGetPasswordRequirements) => createApiAction(
        'CPA_GET_PASSWORD_REQUIREMENTS',
        `${URLs.api}/api/data/securityInfo/userType`,
        requirementInfo),
    updateCurrentField: (currentText: string) => createDataAction('CPA_UPDATE_CURRENT_FIELD', currentText),
    updateNewField: (newText: string) => createDataAction('CPA_UPDATE_NEW_FIELD', newText),
    updateConfirmField: (confirmText: string) => createDataAction('CPA_UPDATE_CONFIRM_FIELD', confirmText),
    validatePasswordFields: (validateFieldInfo: IValidatePasswordFields) => createDataAction(
        'CPA_VALIDATE_PASSWORD_FIELDS',
        validateFieldInfo),
    submitNewPassword: (oldPassword: string, newPassword: string) => createApiBodyAction('CPA_SUBMIT_NEW_PASSWORD',
        `${URLs.api}/api/data/securityInfo/changePassword`,
        undefined,
        "POST",
        `{oldPassword: \"${oldPassword}\", newPassword: \"${newPassword}\"}`),
    updateLogin: (loginInfo: ILoginModel) => createApiBodyAction('CPA_UPDATE_LOGIN',
        `${URLs.api}/api/data/securityInfo/login`,
        undefined,
        "POST",
        JSON.stringify(loginInfo)),
    sendResetEmail: () => createApiBodyAction('CIAM_RESET_EMAIL',
        `${URLs.api}/api/data/ciam/resetuser/0`,
        undefined,
        "POST"),
    getCookieInformation: () => createApiBodyAction('COOKIE_INFORMATION',
        `${URLs.api}/api/data/GetCookieInformation`)
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IChangePasswordUIState, KnownActions> =
    (state: IChangePasswordUIState | undefined, action: KnownActions) => {

        function setErrorProperties(field: IPasswordField, message: string, showError: boolean = true) {
            field.errorMessage = message;
            field.errorFlag = showError;
        }

        //Builds the instructions for creating a password. These instructions depend upon the user type and the client's particular password policy.
        //Note that this has been faithfully recreated from the ASP page. We probably should re-evaluate the wording.
        function buildInstructions(targetState: IChangePasswordUIState) {
            const instructions: string[] = [];
            instructions.push(`Passwords must contain at least ${targetState.minimumLength} characters.`);

            if (targetState.alphanumericRequired)
                instructions.push("Passwords must contain at least one letter and one number.");
            
            //If upper/lower case and special characters are required, add the instructions to the list.
            if (targetState.upperAndLowerCaseRequired) {
                instructions.push("Passwords must contain at least one uppercase and lowercase letter.");
            }

            if (targetState.specialCharacterRequired) {
                instructions.push("Passwords must contain at least one special character.");
            }

            return instructions;
        }

        //Validate a proposed password and return all appropriate error phrases in a string array.
        function validatePassword(password: string, state: IChangePasswordUIState) {
            const requirementErrorMessages: string[] = new Array<string>();
            let lengthErrorMessage: string = "";

            if (password.length < state.minimumLength) 
                lengthErrorMessage = `be at least ${state.minimumLength} characters`;

            if (password.length > 64) 
                lengthErrorMessage = "be less than 64 characters";

            if (state.upperAndLowerCaseRequired && (password.search(/[a-z]/) === -1 || password.search(/[A-Z]/) === -1))
                requirementErrorMessages.push("both upper and lower case letters");

            if (state.alphanumericRequired && password.search(/\d/) === -1) 
                requirementErrorMessages.push("at least one number");
            
            if (state.specialCharacterRequired && password.search(/[$\~\`\!@#\$%\^&\*\(\)_\+\-=\|<>\?]/) === -1) 
                requirementErrorMessages.push("at least one special character");

            if (requirementErrorMessages.length === 0 && lengthErrorMessage.length === 0) 
                return "";
            
            let completeErrorMessage = lengthErrorMessage.length === 0
                ? "Your password must have "
                : requirementErrorMessages.length === 0
                    ? `Your password must ${lengthErrorMessage}.`
                    : `Your password must ${lengthErrorMessage} and have `;

            if (requirementErrorMessages.length === 1)
                return `${completeErrorMessage}${requirementErrorMessages[0]}.`;

            const commaIfNeeded = requirementErrorMessages.length > 2 ? "," : "";

            for(let i = 0; i < requirementErrorMessages.length; i++) {
                completeErrorMessage += (i + 1) === requirementErrorMessages.length 
                    ? `and ${requirementErrorMessages[i]}.` 
                    : `${requirementErrorMessages[i]}${commaIfNeeded} `;
            }

            return completeErrorMessage;
        }

        if (state != undefined) {
            const newState: IChangePasswordUIState = {
                ...state,
                currentPasswordField: { ...state.currentPasswordField },
                newPasswordField: { ...state.newPasswordField },
                confirmNewPasswordField: { ...state.confirmNewPasswordField },
            };

            switch (action.type) {
                case "CPA_GET_PASSWORD_REQUIREMENTS":
                    switch (action.status.status) {
                        case "REQUEST":
                            return state;
                        case "SUCCESS":
                        case "FAIL":
                            //TODO- Hook in error handling and error redirect on failed user type lookup.
                            //If we are unable to look up the user's type, assume a normal user.
                            if (action.data.masterCrud) {
                                newState.userType = action.responseData as string;
                                if (action.status.status === "FAIL" ||
                                    (newState.userType !== "E" && newState.userType !== "C" && newState.userType !== "N")) {
                                    newState.userType = "U";
                                }
                                let policy: MCAccountPolicy | undefined;

                                //Tried to pull this into a separate function, but TypeScript throws a fit if it's not bound by the previous if statements.
                                action.data.masterCrud.UserMaintenanceInfo.ClientAccountPolicies.ClientAccountPolicy.forEach(
                                    (pol: MCAccountPolicy) => {
                                        if (pol["@UserType"] === newState.userType)
                                            policy = pol;
                                    });

                                if (policy !== undefined) {
                                    newState.minimumLength = Number(policy["@MinLength"]);
                                    newState.alphanumericRequired = policy["@AlphaNumeric"] === "1";
                                    newState.specialCharacterRequired = policy["@SpecialChar"] === "1";
                                    newState.upperAndLowerCaseRequired = policy["@UpperLowerCase"] === "1";
                                    newState.instructions = buildInstructions(newState);
                                    return newState;
                                }
                            }
                    };
                    break;

                case "CPA_UPDATE_CURRENT_FIELD":
                    newState.currentPasswordField.text = action.data;
                    return newState;

                case "CPA_UPDATE_NEW_FIELD":
                    newState.newPasswordField.text = action.data;
                    return newState;

                case "CPA_UPDATE_CONFIRM_FIELD":
                    newState.confirmNewPasswordField.text = action.data;
                    return newState;

                case "CPA_VALIDATE_PASSWORD_FIELDS":
                    const currentField = newState.currentPasswordField;
                    const newField = newState.newPasswordField;
                    const confirmField = newState.confirmNewPasswordField;
                    const currentPassword = currentField.text;
                    const newPassword = newField.text;
                    const confirmPassword = confirmField.text;


                    //Reset errors on the page so we're not showing old errors.
                    setErrorProperties(currentField, "", false);
                    setErrorProperties(newField, "", false);
                    setErrorProperties(confirmField, "", false);

                    if (!currentPassword) {
                        setErrorProperties(currentField, "Please enter your current password.");
                    }

                    //If the new password field is empty, do not check if any password requirements have been met.
                    if (!newPassword) {
                        setErrorProperties(newField, "Please enter a new password.");
                    }
                    else {
                        //Only check password match if there are no other validate messages.
                        const validateErrorMessage = validatePassword(newPassword, newState);
                        if (validateErrorMessage && validateErrorMessage.length > 0) {
                            setErrorProperties(newField, validateErrorMessage);
                        }
                        else {
                            if (newPassword === currentPassword) {
                                currentField.errorFlag = true;
                                setErrorProperties(newField, "Your new password must be different from your current one.");
                            }
                        }
                    }

                    if (!confirmPassword) {
                        setErrorProperties(confirmField, "Please confirm your new password.");
                    }
                    else {
                        if (newPassword !== confirmPassword) {
                            newField.errorFlag = true;
                            setErrorProperties(confirmField,
                                "You have not confirmed your new password. Please try again.");
                        }
                    }

                    newState.validated = !currentField.errorFlag && !newField.errorFlag && !confirmField.errorFlag;
                    return newState;

                case "CPA_SUBMIT_NEW_PASSWORD":
                    //Make sure we turn off validation so we do not get stuck in a loop. 
                    newState.validated = false;
                    switch (action.status.status) {
                        case "REQUEST":
                            return newState;

                        case "SUCCESS":
                            const errorCode: number = action.responseData;
                            switch (errorCode) {
                                case 0:
                                    newState.submitSucceeded = true;
                                    return newState;

                                case 50520:
                                    setErrorProperties(newState.newPasswordField,
                                        "The new password was previously used. Choose another one.");
                                    return newState;

                                case 50506:
                                    setErrorProperties(newState.currentPasswordField,
                                        "The old password does not match the current password.");
                                    return newState;

                                default:
                                    newState.warningMessage = "Your password could not be changed.";
                                    return newState;
                            }

                        case "FAIL":
                            newState.warningMessage = "Error! Request to change password failed.";
                            newState.validated = false;
                            return newState;
                    };

                case "CPA_UPDATE_LOGIN":
                    newState.loginStatusCode = -1;
                    switch (action.status.status) {
                        case "REQUEST":
                            return newState;

                        case "SUCCESS":
                            newState.loginStatusCode = action.status.responseCode;
                            return newState;

                        case "FAIL":
                            newState.warningMessage = "Your new credentials could not be verified.";
                            newState.loginStatusCode = 500;
                            newState.submitSucceeded = false;
                            return newState;
                    };
                    break;
                case "CIAM_RESET_EMAIL":
                    newState.loginStatusCode = -1;
                    switch (action.status.status) {
                        case "REQUEST":
                            newState.isBusy = true;
                            return newState;

                        case "SUCCESS":
                            newState.loginStatusCode = action.status.responseCode;
                            newState.submitSucceeded = true;
                            newState.isBusy = false;
                            return newState;

                        case "FAIL":
                            let info = JSON.parse(JSON.stringify(action.responseData));
                            if ( info["message"].toLowerCase().includes("user not found")) {
                                newState.warningMessage = "Error! An error occurred while sending the reset password email: " + info["message"];
                            }
                            else {
                                newState.warningMessage = "Error! An error occurred while sending the reset password email. Please try again later.";
                            }
                            newState.loginStatusCode = 500;
                            newState.submitSucceeded = false;
                            newState.isBusy = false;
                            return newState;
                    };
                    break;
                case "COOKIE_INFORMATION":
                    newState.isCiamUser = false;
                    switch (action.status.status) {
                        case "REQUEST":
                            return newState;

                        case "SUCCESS":
                            let info = JSON.parse(action.responseData);
                            newState.isCiamUser = info["IsCiamLogon"] == "true" ? true : false;
                            return newState;

                        case "FAIL":
                            newState.isCiamUser = false;
                            newState.loginStatusCode = 500;
                            newState.submitSucceeded = false;
                            return newState;
                    };
                    break;
                default:
                    // The following line guarantees that every action in the KnownAction union has been covered by a case above
                    return state;
            }
        }

        return defaultState;
    }