import * as React from "react";
import { DialogFieldset, DialogLegend } from "../../../common/DialogStyles";
import { CrudPermissions } from "../CrudPermissions";
import styled from 'styled-components';
import { Button, Input } from '@optum-uicl/ui-core/dist';
import { RequiredFieldValidator } from "../Validators/RequiredFieldValidator";
import { RegExValidator } from "../Validators/RegExValidator";
import { IsAlphaNumeric, IsNumeric } from '@commonResources/validations';
import { Facility, List, MCFacilityMaintenanceInfo } from "../../../../store/api/FacilityMasterType";

export const ComponentWrapper = styled.div`
    .fieldLabel{
        font-weight: bold;
        text-align: right;
        white-space: nowrap;
    }

    input{
        width:300px;
    }

    .facilityName{
        width: 300px;
    }
`;

interface IComponentProps {
    maintenanceInfo: MCFacilityMaintenanceInfo | undefined,
    facility: Facility,
    crudPermissions: CrudPermissions
    copyBillingProviderAddress: Function,
    facilityUpdateRoutine: Function,
    validationAggregationRoutine: Function,
    validationAggregation: Map<string, boolean>,
    fieldFocus: string,
    isFacilityInfoSubmit: boolean,
    cursorPos: number,
};

interface IComponentState {
    facility: Facility,
    selectedState: string,
    selectedCountry: string,
    fieldFocusLocal: string,
}

export class BillingProviderAddress extends React.Component<IComponentProps, IComponentState> {

    inputsTrimmed = false;
    cursor: number | null = 0;

    constructor(props: IComponentProps) {
        super(props);

        const {
            facility,
        } = this.props;

        this.state = {
            facility,
            selectedState: '',
            selectedCountry: '',
            fieldFocusLocal: '',
        }
    }

    getCountriesDropdown(countryId: string) {
        const {
            maintenanceInfo,
            fieldFocus,
            facilityUpdateRoutine,
        } = this.props;

        // prepend a default option to the start of countries
        let countries: List[] = [{ "@ID": "", "@Name": "- Select Country -" }].concat(
            maintenanceInfo?.CountryList?.Country || []);

        return (
            <select
                id='CountryID'
                autoFocus={fieldFocus == 'CountryID'}
                style={{ height: 26 }}
                onChange={x => {
                    facilityUpdateRoutine("@CountryID", x.currentTarget.value, 'CountryID')
                }}
                value={countryId}
            >
                { countries && countries.map(x => {
                    return <option key={x["@ID"]} value={x["@ID"]}>{x["@Name"]}</option>
                })}
            </select>
        );
    }

    getStatesDropdown(stateId: string) {
        const {
            maintenanceInfo,
            fieldFocus,
            facilityUpdateRoutine,
        } = this.props;

        // prepend a default option to the start of states
        let states: List[] = [{ "@ID": "0", "@Name": "- Select State -" }].concat(
            // filter out redundant blank record with id = 0
            maintenanceInfo?.StateList?.State.filter(x => x["@ID"] != '0') || []);

        return (
            <select
                id='StateID'
                autoFocus={fieldFocus == 'StateID'}
                style={{ height: 26 }}
                onChange={x => {
                    facilityUpdateRoutine("@StateID", x.currentTarget.value, 'StateID')
                }}
                value={stateId}
            >
                { states && states.map(x => {
                    // bypass the blank state name that was getting added to the top of the dropdown
                    if (!x["@Name"]) return;
                    return <option key={x["@ID"]} value={x["@ID"]}>{x["@Name"]}</option>
                })}
            </select>
        );
    }

    onClickCopyFacility() {
        const {
            copyBillingProviderAddress
        } = this.props;

        copyBillingProviderAddress();
    }

    logToConsole() {
        console.log('fieldFocus = ' + this.state.fieldFocusLocal);
    }

    inputOnChange(x: React.ChangeEvent<HTMLInputElement>) {
        const {
            facilityUpdateRoutine,
        } = this.props;

        const inputId = x.currentTarget.id;
        const fieldId = '@' + inputId;
        const inputValue = x.currentTarget.value;
        const cursorPos = x.target.selectionStart;

        facilityUpdateRoutine(fieldId, inputValue, inputId, cursorPos)
    }

    inputOnBlur(x: React.FocusEvent<HTMLInputElement>) {
        const {
            facilityUpdateRoutine,
        } = this.props;

        const inputId = x.currentTarget.id;
        const fieldId = '@' + inputId;

        // remove leading and trailing spaces from the input value per legacy
        let inputValue = x.currentTarget.value.trim();
        x.currentTarget.value = inputValue;

        // cursor pos of 0 selects all text in the input
        facilityUpdateRoutine(fieldId, inputValue, inputId, 0);
    }

    render() {
        const {
            validationAggregationRoutine,
            validationAggregation,
            fieldFocus,
            isFacilityInfoSubmit,
            facility,
            crudPermissions
        } = this.props;

        const ddlCountries = this.getCountriesDropdown(facility["@CountryID"]);
        const ddlStates = this.getStatesDropdown(facility["@StateID"]);
        const disableCopy = facility["@ID"] == '0' 
            || !crudPermissions.canEditFacility
            || (crudPermissions.canEditFacility && facility["@PayToName"] !== '')

        const telephoneIsNumericTrigger =
            isFacilityInfoSubmit &&
            facility["@TelephoneNo"].length > 0 &&
            !IsNumeric(facility["@TelephoneNo"])

        const facilityUpdateRoutine = this.props.facilityUpdateRoutine;

        return (
            <>
                {facility &&
                    <ComponentWrapper>
                        <DialogFieldset>
                            <DialogLegend>Billing Provider Address</DialogLegend>
                            <table>
                                <tbody>
                                    {/* Facility Name (Billing Provider) */}
                                    <tr>
                                        <td className='fieldLabel'>* Facility Name:</td>
                                        <td style={{ width: 5 }}></td>
                                        <td>
                                            <input
                                                id='Name'
                                                autoFocus={fieldFocus == 'Name'}
                                                type="text"
                                                value={facility["@Name"]}
                                                onChange={x => {
                                                    this.inputOnChange(x);
                                                }}
                                                onBlur={x => {
                                                    this.inputOnBlur(x);
                                                }}
                                                maxLength={60}
                                            />
                                        </td>
                                    </tr>
                                
                                    <RequiredFieldValidator
                                        fieldId={'txtFacilityName'}
                                        fieldName={'Facility Name'}
                                        triggers={[
                                            isFacilityInfoSubmit && !facility["@Name"].length
                                        ]}
                                        validationAggregation={validationAggregation}
                                        validationAggregationRoutine={validationAggregationRoutine}
                                    />

                                    {/* Address */}
                                    <tr>
                                        <td className='fieldLabel'>* Address:</td>
                                        <td style={{ width: 5 }}></td>
                                        <td>
                                            <input
                                                id='Address'
                                                autoFocus={fieldFocus == 'Address'}
                                                type="text"
                                                value={facility["@Address"]}
                                                onChange={x => {
                                                    this.inputOnChange(x);
                                                }}
                                                onBlur={x => {
                                                    this.inputOnBlur(x);
                                                }}
                                                maxLength={55}
                                            />
                                        </td>
                                    </tr>

                                    <RequiredFieldValidator
                                        fieldId={'txtAddress'}
                                        fieldName={'Address'}
                                        triggers={[
                                            isFacilityInfoSubmit && !facility["@Address"].length
                                        ]}
                                        validationAggregation={validationAggregation}
                                        validationAggregationRoutine={validationAggregationRoutine}
                                    />

                                    {/* Address 2 */}
                                    <tr>
                                        <td className='fieldLabel' >Address 2:</td>
                                        <td style={{ width: 5 }}></td>
                                        <td>
                                        <input
                                            id='Address2'
                                            autoFocus={fieldFocus == 'Address2'}
                                            type="text" 
                                            value={facility["@Address2"]}
                                            onChange={x => {
                                                this.inputOnChange(x);
                                            }}
                                            onBlur={x => {
                                                this.inputOnBlur(x);
                                            }}
                                            maxLength={55}
                                        />
                                        </td>
                                    </tr>

                                    {/* City */}
                                    <tr>
                                        <td className='fieldLabel' >* City:</td>
                                        <td style={{ width: 5 }}></td>
                                        <td>
                                            <input
                                                id='City'
                                                autoFocus={fieldFocus=='City'}
                                                autoComplete={Math.random().toString()}
                                                type="text"
                                                value={facility["@City"]}
                                                onChange={x => {
                                                    this.inputOnChange(x);
                                                }}
                                                onBlur={x => {
                                                    this.inputOnBlur(x);
                                                }}
                                                maxLength={30}
                                            />
                                        </td>
                                    </tr>

                                    <RequiredFieldValidator
                                        fieldId={'txtCity'}
                                        fieldName={'City'}
                                        triggers={[
                                            isFacilityInfoSubmit && !facility["@City"].length
                                        ]}
                                        validationAggregation={validationAggregation}
                                        validationAggregationRoutine={validationAggregationRoutine}
                                    />

                                    <RegExValidator
                                        fieldId={'txtCity.AlphaNumeric'}
                                        fieldName={'City'}
                                        message={'City must consist of valid characters'}
                                        trigger={
                                            isFacilityInfoSubmit &&
                                            !IsAlphaNumeric(facility["@City"])
                                        }
                                        style={{width:300}}
                                        validationAggregation={validationAggregation}
                                        validationAggregationRoutine={validationAggregationRoutine}
                                    />

                                    {/* State */}
                                    <tr>
                                        <td className='fieldLabel' >* State:</td>
                                        <td style={{ width: 5 }}></td>
                                        <td>{ddlStates}</td>
                                    </tr>

                                    <RequiredFieldValidator
                                        fieldId={'ddlState'}
                                        fieldName={'State'}
                                        triggers={[
                                            isFacilityInfoSubmit && (!facility["@StateID"] || facility["@StateID"] == '0'),
                                        ]}
                                        validationAggregation={validationAggregation}
                                        validationAggregationRoutine={validationAggregationRoutine}
                                    />

                                    {/* ZIP */}
                                    <tr>
                                        <td className='fieldLabel' >* ZIP:</td>
                                        <td style={{ width: 5 }}></td>
                                        <td>
                                            <input
                                                id='ZipCode'
                                                autoFocus={fieldFocus=='ZipCode'}
                                                type="text" 
                                                value={facility["@ZipCode"]}
                                                onChange={x => {
                                                    this.inputOnChange(x);
                                                }}
                                                onBlur={x => {
                                                    this.inputOnBlur(x);
                                                }}
                                                maxLength={9}
                                            />
                                        </td>
                                    </tr>

                                    <RequiredFieldValidator
                                        fieldId={'txtZIP'}
                                        fieldName={'ZIP Code'}
                                        triggers={[
                                            isFacilityInfoSubmit &&
                                            !facility['@ZipCode'].length
                                        ]}
                                        validationAggregation={validationAggregation}
                                        validationAggregationRoutine={validationAggregationRoutine}
                                    />

                                    <RegExValidator
                                        fieldId={'txtZIP.RegEx.Numeric'}
                                        fieldName={'ZIP Code'}
                                        trigger={
                                            isFacilityInfoSubmit &&
                                            facility['@ZipCode'].length > 0 &&
                                            !IsNumeric(facility['@ZipCode'])
                                        }
                                        message={'Zip Code must be numeric'}
                                        style={{width:300}}
                                        validationAggregation={validationAggregation}
                                        validationAggregationRoutine={validationAggregationRoutine}
                                    />

                                    <RegExValidator
                                        fieldId={'txtZIP.RegEx.Length'}
                                        fieldName={'ZIP'}
                                        trigger={
                                            isFacilityInfoSubmit &&
                                            facility['@ZipCode'].length > 0 &&
                                            IsNumeric(facility['@ZipCode']) &&
                                            ![5, 9].includes(facility["@ZipCode"].length)
                                        }
                                        message={'ZIP Code must consist of either 5 numbers or 9 numbers'}
                                        style={{ width: 300 }}
                                        validationAggregation={validationAggregation}
                                        validationAggregationRoutine={validationAggregationRoutine}
                                    />

                                    {/* Telephone */}
                                    <tr>
                                        <td className='fieldLabel' >* Telephone:</td>
                                        <td style={{ width: 5 }}></td>
                                        <td>
                                            <input
                                                id='TelephoneNo'
                                                autoFocus={fieldFocus=='TelephoneNo'}
                                                type="text" 
                                                value={facility["@TelephoneNo"]}
                                                onChange={x => {
                                                    this.inputOnChange(x);
                                                }}
                                                onBlur={x => {
                                                    this.inputOnBlur(x);
                                                }}
                                                maxLength={15}
                                            />
                                        </td>
                                    </tr>

                                    {/* 1 - validate input exists */}
                                    <RequiredFieldValidator
                                        fieldId={'txtTelephone'}
                                        fieldName={'Telephone'}
                                        triggers={[
                                            isFacilityInfoSubmit &&
                                            !facility["@TelephoneNo"].length
                                        ]}
                                        validationAggregation={validationAggregation}
                                        validationAggregationRoutine={validationAggregationRoutine}
                                    />

                                    {/* 2 - validate input is numeric */}
                                    <RegExValidator
                                        fieldId={'txtTelephone.RegEx.Numeric'}
                                        fieldName={'Telephone'}
                                        trigger={
                                            telephoneIsNumericTrigger
                                        }
                                        message={'Telephone must be numeric'}
                                        style={{ width: 300 }}
                                        validationAggregation={validationAggregation}
                                        validationAggregationRoutine={validationAggregationRoutine}
                                    />

                                    {/* 3 - validate input is at least 10 numbers */}
                                    <RegExValidator
                                        fieldId={'Telephone.RegEx.Length'}
                                        fieldName={'Telephone'}
                                        trigger={
                                            isFacilityInfoSubmit &&
                                            IsNumeric(facility["@TelephoneNo"]) &&
                                            facility["@TelephoneNo"].length > 0 &&
                                            facility["@TelephoneNo"].length < 10
                                        }
                                        message={'Telephone must consist of at least ten numbers'}
                                        style={{ width: 300 }}
                                        validationAggregation={validationAggregation}
                                        validationAggregationRoutine={validationAggregationRoutine}
                                    />

                                    {/* Fax */}
                                    <tr>
                                        <td className='fieldLabel' >Fax:</td>
                                        <td style={{ width: 5 }}></td>
                                        <td>
                                            <input
                                                id='FaxNo'
                                                autoFocus={fieldFocus=='FaxNo'}
                                                type="text" 
                                                value={facility["@FaxNo"]}
                                                onChange={x => {
                                                    this.inputOnChange(x);
                                                }}
                                                onBlur={x => {
                                                    this.inputOnBlur(x);
                                                }}
                                                maxLength={15}
                                            />
                                        </td>
                                    </tr>

                                    {/* Country */}
                                    <tr>
                                        <td className='fieldLabel'>Country:</td>
                                        <td style={{ width: 5 }}></td>
                                        <td>{ddlCountries}</td>
                                    </tr>

                                    {/* Copy to Claim */}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className="fieldLabel" style={{ textAlign: 'left' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div style={{ display: 'flex', alignContent: 'left'}}>
                                                <input
                                                    id='CopyAddressToClaim'
                                                    key='CopyAddressToClaim'
                                                    type='checkbox'
                                                    autoFocus={fieldFocus == 'CopyAddressToClaim'}
                                                    onKeyDown={x => {
                                                        if (x.key === 'Tab' && !x.shiftKey) {
                                                            x.preventDefault();
                                                            x.stopPropagation();
                                                            facilityUpdateRoutine(
                                                                "@CopyAddressToClaim",
                                                                facility["@CopyAddressToClaim"],
                                                                // tab to PayToName
                                                                'PayToName');
                                                        }
                                                    }}
                                                    onChange={x =>
                                                        facilityUpdateRoutine(
                                                            "@CopyAddressToClaim",
                                                            facility["@CopyAddressToClaim"] == 'Y' ? 'N' : 'Y',
                                                            'CopyAddressToClaim')
                                                    }
                                                    checked={facility["@CopyAddressToClaim"] == 'Y'}
                                                />
                                               <span> Copy to Claim</span>
                                            </div>
                                            <div style={{ display: 'flex', alignContent: 'right' }}>
                                                <Button
                                                    domID="copy-facility-button"
                                                    name={"Copy"}
                                                    buttonType="emphasized"
                                                    size="control"
                                                    style={{height: "30px", width: "110px"}}
                                                    type="button"
                                                    disabled={disableCopy}
                                                    onClick={(e: React.SyntheticEvent) => {
                                                        this.onClickCopyFacility();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </DialogFieldset>
                    </ComponentWrapper>
                }
            </>
        );
    }
}
