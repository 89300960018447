import { Reducer } from 'redux';
import {keyBy, partition, uniqueId} from 'lodash';
import { ICrudActionData } from '@scripts/util/CrudComponentHelpers';
import { IFormUIState, defaultFormState } from '@store/ui/BaseCrudUI';
import { ComplexApiAction, IThunkResult, ComplexAction, ComplexDataAction, IThunkApiAction } from '@scripts/util/ThunkHelpers';
import { createDataAction, ActionTypes, IApiAction, createAction, ValidationCallback } from '@scripts/util/ActionHelpers';
import { MasterCrudState, actionCreators as masterCrudCreators } from '../MasterCrud';
import { IARMUserData } from '@components/common/ARMUserComponent';
import { IARMDeptData } from '@components/common/ARMDepartmentComponent';
import { CrudTypes } from '@commonResources/CrudTypes';
import { searchValidationCallback } from '@store/ManageClaimAssignmentsErrorRules';
import { RowType } from '@optum-uicl/ui-core/dist/Organisms/Grid/types';

export interface MCASearchParameters {
    Name: '@intFormType' | '@intMaxDays' | '@chvFromDate' | '@chvThruDate' |
    '@intPayerList' | '@chvPayerList' | '@chvCategoryList' | '@chvFieldList' |
    '@chvSearchString' | '@chvLOBList' | '@chvFacilityList' |
    '@chvClientSpec1' | '@chvClientSpec2' | '@chvClientSpec3' |
    '@chvHCPCSMin' | '@chvHCPCSMax' | '@intMaxRows',
    Value: string,
    Type?: string
}

export interface MCASearchRequest {
    Param: MCASearchParameters[],
}

// This doesn't match normal JS naming conventions so we can use MultiSelectForSimpleValues for 
// these lists. To maintain consistency, other props on list search items will also have initial cap
export interface IMCAERS_PayerValue extends APICF_SimpleList {
    FIName: string;
}

export interface IMCAERS_FieldValue extends APICF_SimpleList {
    ErrorCategoryID: string;
    FormUB: string;
    Form1500: string;
}

export interface IMCAERS_RuleUser extends IARMUserData {
    alphaSplit: any;
    selected: boolean;
    isDisabled: boolean;
    assignedToName: string;
    id?: string;
    isDirty: boolean;
}

export type IMCAERS_User = Omit<IMCAERS_RuleUser, 'selected' | 'alphaSplit' | 'id' | 'isDirty'>;

export interface IMCAERS_DepartmentRuleValue extends IARMDeptData {
    selected?: boolean;
    priority: string;
    assignedToName: string;
    id?: string;
}
export type IMCAERS_DepartmentValue = Omit<IMCAERS_DepartmentRuleValue, 'selected' | 'id'>;
export interface IRuleSummaryBase {
    fieldId?: string;
    fieldName?: string;
    categoryId?: string;
    categoryName?: string;
    facilityId?: string;
    facilityName?: string;
    lobId?: string;
    payerLob?: string; // payer OR lob name (lob takes precedence per 394-400 of ASP)
    payerName?: string;
    hcpcsMin?: string;
    hcpcsMax?: string;
    clientFields?: string[]; //should contain values of ClientSpec1, ClientSpec2, etc.
    msgLvl?: string; 
// bridge claims only
    isSelected: boolean;
}
export interface CustomRowType {
    caseId?: string;
    providerName?: string;
    patientName?: string;
    serviceFromDate?: string;
    varianceAmount?: number;
}

export interface IMCAERS_Rule extends CustomRowType {
    id?: string;
    editName?: string;
    errorMsg?: string;
    expires?: string;
    assignedTo?: string;
    assignedToDetails?: string;
    departments?: IMCAERS_DepartmentRuleValue[];
    users?: IMCAERS_RuleUser[];
    isDirty?: boolean; //means we need to send in CRUD call
    isDeleted?: boolean; // means we need to send it deleted in CRUD call
    lobId?: any;
    payerName?: string;
    hcpcsMin?: any;
    hcpcsMax?: any;
    categoryId?: string | undefined;
    fieldId?: string | undefined;
    clientFields?: string[];
    isSelected?: boolean;

}

export interface IMCAERSearchSummaryDetail {
    refId: string; 
    editName: string; 
    totalErrorCount: string; 
    errorMsg: string; 
    isSelected?: boolean;
}

export interface IMCAERSearchSummary extends CustomRowType
 {
    refId?: string;
    ruleExists?: boolean;
    totalErrorCount?: string;
    details?: IMCAERSearchSummaryDetail[];
    isSelected?: boolean;
}

interface IMCAEREditRuleBase {
    // these are the ones with all the properties to be assigned
    userList: IMCAERS_RuleUser[];
    departmentList: IMCAERS_DepartmentRuleValue[];
    expires?: string;
}


export interface IMCAERSearchSummaryTabState extends IMCAEREditRuleBase {
    totalErrorCount: string;
    maxRowsExceeded: boolean;
    summaries: IMCAERSearchSummary[];
    summarySortOrder: string;
    detailSortOrder: string;
}

export interface IMCAERManageRulesTabState extends IMCAEREditRuleBase {
    rulesSortOrder: string;
}


// Properties of action creators specific to the ManageClaimAssignmentsErrorRules view
export interface IManageClaimAssignmentsErrorRulesData {
    searchListPopulation: {
        payerList: IMCAERS_PayerValue[];
        fieldList: IMCAERS_FieldValue[];
        errorCategoryList: APICF_SimpleList[];
        lobList: APICF_SimpleList[];
        facilityList: APICF_SimpleList[];
    };
    searchFieldValues: {
        form: 'UB' | '1500';
        payerType: 'lob' | 'payer';
        timePeriod: string;
        dateFrom: string;
        dateTo: string;
        hcpcsMin: string;
        hcpcsMax: string;
        clientFields: string[];
        errorText: string,
        selectedLobs: string[];
        selectedPayers: string[];
        selectedCategories: string[];
        selectedFacilities: string[];
        selectedFields: string[];
    };
    // raw users and departments, used to create
    // specific users and departments based on xml return (rules, search)
    users: IMCAERS_User[];
    departments: IMCAERS_DepartmentValue[];
    searchResults: IMCAERSearchSummaryTabState;
    rules: IMCAERS_Rule[];
    selectedTab: 0|1|2;
    isBusy: boolean;
    manageRuleState: IMCAERManageRulesTabState;
    warningType: string;
}

// UI representation of CRUD data. 
export interface IManageClaimAssignmentsErrorRulesUIState extends IManageClaimAssignmentsErrorRulesData, IFormUIState {};

// merge our custom action data interface with CRUD boilerplate
export interface IModifyManageClaimAssignmentsErrorRules extends ICrudActionData<MCManageClaimAssignmentError, string> {
};

export interface IResetCrudFlag extends ICrudActionData<MCManageClaimAssignmentError, boolean> {
};

export interface IInitializeManageClaimAssignmentsErrorRules extends ICrudActionData<MCManageClaimAssignmentError, MCManageClaimAssignmentError> {};

export const actionCreators = {
    initalizeOptions: (rawApiReturn: IInitializeManageClaimAssignmentsErrorRules) => createDataAction('INIT_MANAGE_CLAIM_ASSIGNMENT_ERROR_RULES',
        rawApiReturn),
    // common actions
    setSelectedTab: (tabIndex: 0|1|2) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_SET_SELECTED_TAB', tabIndex),
    resetDirtyFlag: (manageClaimAssignmentsErrorRulesInfo: IResetCrudFlag) => createDataAction('RESET_MANAGE_CLAIM_ASSIGNMENT_ERROR_RULES_DIRTY', manageClaimAssignmentsErrorRulesInfo),
    saveRuleChanges: (crudId: number, rules: IMCAERS_Rule[], validationFn?: ValidationCallback<any>) => {
        const payload = {
            crudId,
            data: getCrudDelta(rules)
        }
        return ComplexApiAction.fromAction(masterCrudCreators.update(payload, validationFn)).changeType('MANAGE_CLAIM_ASSIGNMENT_SAVE_CHANGES').finish(); //TODO figure out thenRedirect (both using and testing)
    },
    // search tab actions
    toggleFormType: () => createAction('MANAGE_CLAIM_ASSIGNMENT_TOGGLE_FORM_TYPE'),
    togglePayerType: () => createAction('MANAGE_CLAIM_ASSIGNMENT_TOGGLE_PAYER_TYPE'),
    setWarningType: (warningType: '' | 'maxRowsExceeded' | 'dateRangeRequired' | 'fromDatePriorToThruDateRequired' | 'searchDatePriorToTodayRequired' | 'fewerPayersRequired' | 'fewerFacilitiesRequired' | 'fewerFieldsRequired' | 'rulesNotAdded') => createDataAction('MCA_SET_WARNING_TYPE', warningType),
    resetWarnings: () => createAction('MCA_RESET_WARNINGS'),
    searchMCAErrorRules: (request: MCASearchRequest) =>
        ComplexApiAction
            .fromAction(masterCrudCreators.search({ crudId: CrudTypes.mctiClaimAssignmentError, data: JSON.stringify(request) }, searchValidationCallback))
            .changeType('MCA_SEARCH_RESULT_CRUD')
            .finish(),
    selectTimePeriod: (timePeriod: '0' | '10' | '30' | '60' | '90' | 'none') => createDataAction('MANAGE_CLAIM_ASSIGNMENT_SELECT_TIME_PERIOD', timePeriod),
    editClientSpecific: (csIndex: 0|1|2, csValue: string) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_EDIT_CLIENT_SPECIFIC', { csIndex: csIndex, csValue: csValue.toUpperCase() }), 
    editHcpcsMin: (hcpcsMin: string) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_EDIT_HCPCS_MIN', hcpcsMin.toUpperCase()), 
    editHcpcsMax: (hcpcsMax: string) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_EDIT_HCPCS_MAX', hcpcsMax.toUpperCase()), 
    editErrorText: (errorText: string) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_EDIT_ERROR_TEXT', errorText), 
    editDateFrom: (dateFrom: string) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_EDIT_DATE_FROM', dateFrom), 
    editDateTo: (dateTo: string) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_EDIT_DATE_TO', dateTo), 
    selectLobs: (lobs: string[]) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_SELECT_LOBS', lobs),
    selectPayers: (payers: string[]) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_SELECT_PAYERS', payers),
    selectFacilities: (facilities: string[]) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_SELECT_FACILITIES', facilities),
    selectFields: (fields: string[]) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_SELECT_FIELDS', fields),
    selectCategories: (categories: string[]) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_SELECT_CATEGORIES', categories),
    // results tab actions
    selectSummaryResultsAssignment: (summaryAssignment: string) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_RESULTS_SELECT_DESLECT_SUMMARY', summaryAssignment),
    selectDetailResultsAssignment: (detailAssignment: string) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_RESULTS_SELECT_DESLECT_DETAIL', detailAssignment),
    setAlphaSplitResultsAssignment: (userId: string, alphaSplit: string) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_RESULTS_ASSIGNMENT_ALPHA_SPLIT', {userId: userId, value: alphaSplit.toUpperCase()}),
    newResultsAssignment: () => createAction('MANAGE_CLAIM_ASSIGNMENT_RESULTS_ASSIGNMENT_NEW'),
    selectUsersResultsAssignment: (users: string[]) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_RESULTS_ASSIGNMENT_SELECT_USERS', users),
    departmentsResultsAssignment: (departmentId: string) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_RESULTS_ASSIGNMENT_DEPARTMENTS', departmentId),
    expiresResultsAssignment: (expires: string) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_RESULTS_ASSIGNMENT_EXPIRES', expires),
    setSummaryGridSortOrder: (sortData: string) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_RESULTS_SUMMARY_SORT_ORDER', sortData),
    setDetailGridSortOrder: (sortData: string) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_RESULTS_DETAIL_SORT_ORDER', sortData),
    // manage tab actions
    selectManageRules: (selectedRuleId: string) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_MANAGE_RULES_SELECT_DESELECT', selectedRuleId),
    updateManageRules: () => createAction('MANAGE_CLAIM_ASSIGNMENT_MANAGE_RULES_UPDATE'),
    removeManageRules: () => createAction('MANAGE_CLAIM_ASSIGNMENT_MANAGE_RULES_REMOVE'),
    selectUsersManageRules: (users: string[]) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_MANAGE_RULES_SELECT_USERS', users),
    selectDepartmentsManageRules: (departmentId: string) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_MANAGE_RULES_DEPARTMENTS', departmentId),
    expiresManageRules: (rulesExpires: string) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_MANAGE_RULES_EXPIRES', rulesExpires),
    userAlphaSplitUpdate: (userId: string, alphaSplit: string) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_MANAGE_USER_ALPHA_SPLIT_UPDATE', { userId: userId, value: alphaSplit.toUpperCase() }),
    setManageRuleGridSortOrder: (sortData: string) => createDataAction('MANAGE_CLAIM_ASSIGNMENT_MANAGE_RULE_SORT_ORDER', sortData),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
type KnownTypes = ActionTypes<ActionCreators>['type'];

export function getEmptySearchResults(): IMCAERSearchSummaryTabState {
    return {
        totalErrorCount: '',
        maxRowsExceeded: false,
        summaries: [],
        userList: [],
        departmentList: [],
        summarySortOrder: 'initial|SORT_DESCENDING',
        detailSortOrder:  'editName|SORT_DESCENDING',
    };
}

export function getEmptyManageRulesState(): IMCAERManageRulesTabState {
    return {
        userList: [],
        departmentList: [],
        rulesSortOrder: '',
    };
}

export function getDefaultSearchFieldValues() {
    return  {
        form: 'UB',
        payerType: 'lob',
        timePeriod: '0',
        dateFrom: '',
        dateTo: '',
        hcpcsMin: '',
        hcpcsMax: '',
        errorText: '',
        clientFields: [],
        selectedLobs: [],
        selectedPayers: [],
        selectedCategories: [],
        selectedFacilities: [],
        selectedFields: [],
    } as IManageClaimAssignmentsErrorRulesData['searchFieldValues'];
}

export const defaultManageClaimAssignmentsErrorRulesState: IManageClaimAssignmentsErrorRulesUIState = {
    ...defaultFormState,
    searchListPopulation: {
        payerList: [],
        fieldList: [],
        errorCategoryList: [],
        lobList: [],
        facilityList: [],
    },
    searchFieldValues: getDefaultSearchFieldValues(),
    users: [],
    departments: [],
    searchResults: getEmptySearchResults(),
    rules: [],
    manageRuleState: getEmptyManageRulesState(),
    selectedTab: 0,
    isBusy: true,
    warningType: ''
}

export const defaultManageClaimAssignmentRule: IMCAERS_Rule = {
    id: '-1',
    editName: '',
    departments: [],
    users: [],
    isSelected: false,
    isDirty: false, //means we need to send in CRUD call
}

export function getUpdatedUserCrud(userList: IMCAERS_RuleUser[]): MMCAErrorRulesCrudDeltaUser[] {
    return userList.reduce<MMCAErrorRulesCrudDeltaUser[]>((users, user) => {
        if (user.selected) {
            if (!user.id || user.isDirty) return [...users, { '@UserID': user.userId, '@ID': user.id ?? '#', '@AlphaSplit': user.alphaSplit }];
        } else if (!!user.id) {
            return [...users, { '@UserID': user.userId, '@ID': user.id, '@Delete': 'true' }];
        }
        return users;
    }, []);
}

export function getUpdatedDepartmentCrud(departmentList: IMCAERS_DepartmentRuleValue[]): MCMCAErrorRulesCrudDeltaDepartment[] {
    return departmentList.reduce<MCMCAErrorRulesCrudDeltaDepartment[]>((departments, department) => {
        if (department.selected) {
            if (!department.id) {
                return [...departments, { '@DepartmentID': department.deptId, '@ID': '#' }];
            }
        } else if (department.id) {
            return [...departments, { '@DepartmentID': department.deptId, '@ID': department.id, '@Delete': 'true'}];
        }
        return departments;
    }, []);
}
export function getCrudDelta(rules: IMCAERS_Rule[]): MCMCAErrorCrudDelta {
    return {
        MCAErrorMaintenanceInfo: {
            Rules: {
                Rule: rules.reduce<MCMCAErrorRulesCrudDeltaRule[]>((crudRules, rule) => {
                    const User = getUpdatedUserCrud(rule.users ?? []);
                    const Department = getUpdatedDepartmentCrud(rule.departments ?? []);
                    const Users = !!User.length ? {User}: null;
                    const Departments = !!Department.length ? {Department}: null;
                    //  all of these "sub objects" are in here so that if the props are undefined the props don't get added to the api call
                    let bridgeProps = {};
                    if (rule.editName?.toLowerCase() === 'bridge') bridgeProps = {'@MsgLev1': rule.errorMsg};
                    let payerProps = {};
                    if (!!rule.lobId) {
                        payerProps = { "@LOB": rule.lobId };
                    } else {
                        payerProps = {'@Payer': rule.payerName};
                    };

                    const clientSpecificProps: IClientSpecificProps = rule.clientFields ? rule.clientFields.reduce<IClientSpecificProps>((props: IClientSpecificProps, value: string, i: number) => {
                        const propName = `@ClientSpec${i + 1}`;
                        return { ...props, [propName]: value }
                    }, {}) : {};

                    const hcpcsProps = {'@HCPCSMin': rule.hcpcsMin, '@HCPCSMax': rule.hcpcsMax};
                    
                    if (rule.id && rule.id?.search(/new-[0-9+]/) > -1) {
                        const newRule: MCMCAErrorRulesCrudDeltaRule = {
                            "@ID": '#',
                            ...payerProps,
                            "@CategoryID": rule.categoryId,
                            '@FieldID': rule.fieldId,
                            "@Expires": rule.expires,
                            "@EditName": rule.editName,
                            ...bridgeProps,
                            ...clientSpecificProps,
                            ...hcpcsProps,
                            Users,
                            Departments,
                        }
                        return [...crudRules, newRule];
                    } else if (rule.isDeleted) {
                        const deletedRule: MCMCAErrorRulesCrudDeltaRule = {
                            "@ID": rule.id ?? '',
                            "@Delete": 'true',
                            Users: null,
                            Departments: null,
                        }
                        return [...crudRules, deletedRule];
                    } else if (rule.isDirty) {
                        const updatedRule: MCMCAErrorRulesCrudDeltaRule = {
                            "@Expires": rule.expires,
                            "@ID": rule.id ?? '',
                            Users,
                            Departments,
                        }
                        return [...crudRules, updatedRule];
                    }
                    return crudRules;
                }, []),
            },
        }
    };
}

export function mapCrudSimpleValues(options: MCAE_ListItem[]): APICF_SimpleList[] {
    return options.map(option => ({ID: option['@ID'], Name: option['@Name']}));
}

export function mapPayerOptions(options: MCAE_Payer[]): IMCAERS_PayerValue[] {
    // blank payer hard coded in to legacy asp page...
    const blankGenPayer: IMCAERS_PayerValue = { ID: '', Name: 'Blank payer (GEN)', FIName: 'Blank payer (GEN)' }
    var newOptions: IMCAERS_PayerValue[] = [blankGenPayer, ...options.map(option => ({ ID: option['@ID'], Name: option['@Name'], FIName: option['@FIName'] }))];

    return newOptions;
}
export function mapFacilitiesOptions(options: MCAE_ListItem[]): APICF_SimpleList[] {
    return options.map(option => ({ID: option['@ID'], Name: `${option['@Name']} (${option['@ID']})`}));
}

export function mapfieldOptions(options: MCAE_Field[]): IMCAERS_FieldValue[] {
    return options.map(option => ({
        ID: option['@ID'], 
        Name: option['@Name'], 
        ErrorCategoryID: option['@ErrorCategoryID'], 
        FormUB: option['@FormUB'], 
        Form1500: option['@Form1500']
    }));
}

export function mapDepartmentOptions(options: MCAE_Department[]): IMCAERS_DepartmentValue[] {
    var priority: string;
    return options.map(option => ({
        deptId: option['@ID'],
        name: `${option['@Name']} (${(option['@Priority'] !== '') ? option['@Priority'] : 'n/a'})`,
        priority: (option['@Priority'] !== '') ? option['@Priority'] : 'n/a',
        assignedToName: option['@Name'],
    }));
}

export function mapUserOptions(options: MCAE_User[]): IMCAERS_User[] {
    return options.map(option => ({
        userId: option['@ID'],
        assignedToName: option['@Name'],
        user: `${option['@LastName']}, ${option['@FirstName']} (${option['@Name']})`,
        isDisabled: option['@Enabled'] !== '1',
    }));
}

//TODO: extract common props from types used in partial
// note @PayerName exists on Rule, @Payer exists on Summary, appears to be the same thing
export function createRuleSummaryBase(baseXML: Partial<MCAE_Rule> | Partial<MCMCAErrorSearchSummary>, keyedFields: { [key: string]: IMCAERS_FieldValue }, keyedCategories: { [key: string]: APICF_SimpleList }, keyedLOBs: { [key: string]: APICF_SimpleList }, keyedFacilities: {[key: string]: APICF_SimpleList}): IRuleSummaryBase {
    let payerName = '';
    let payerFields = {};
    if ('@PayerName' in baseXML) {
        payerName = baseXML['@PayerName'] || '';
        payerFields = {payerName};
    }
    if ('@Payer' in baseXML) {
        payerName = baseXML['@Payer'] || '';
        payerFields = {payerName};
    }
    payerFields = {...payerFields, payerLob: keyedLOBs[baseXML['@LOB']|| '']?.Name || payerName};
    return {
        ...payerFields,
        lobId: baseXML['@LOB'],
        fieldId: baseXML['@FieldId'] ?? '',
        fieldName: keyedFields[baseXML['@FieldId'] || '']?.Name ?? '',
        categoryId: baseXML['@CategoryId'],
        categoryName: keyedCategories[baseXML['@CategoryId'] || '']?.Name,
        clientFields: [baseXML['@ClientSpec1'] || '', baseXML['@ClientSpec2'] || '', baseXML['@ClientSpec3'] || ''],
        facilityId: baseXML['@FacilityId'],
        facilityName: keyedFacilities[baseXML['@FacilityId'] ?? '']?.Name,
        hcpcsMax: baseXML['@HCPCSMax'],
        hcpcsMin: baseXML['@HCPCSMin'],
        isSelected: false,
    };
}

export function mapRulesOptions(options: MCAE_Rule[], keyedFields: { [key: string]: IMCAERS_FieldValue }, keyedCategories: { [key: string]: APICF_SimpleList }, keyedUsers: { [key: string]: IMCAERS_User }, keyedDepartments: { [key: string]: IMCAERS_DepartmentValue }, keyedLOBs: {[key: string]: APICF_SimpleList}, keyedFacilities: {[key: string]: APICF_SimpleList}): IMCAERS_Rule[] {
    return options.map(option => {
        const departments: IMCAERS_DepartmentRuleValue[] = option.Departments.Department.reduce((outputDepts: IMCAERS_DepartmentRuleValue[], ruleDepartment: MCAE_RuleDepartment) => {
            const listDepartment = keyedDepartments[ruleDepartment['@ID']];
            if (listDepartment) return outputDepts.concat({...listDepartment, selected: true, id: listDepartment.deptId});
            return outputDepts;
        }, []);
        const users: IMCAERS_RuleUser[] = option.Users.User.reduce((outputUsers: IMCAERS_RuleUser[], ruleUser: MCAE_RuleUser) => {
            const listUser = keyedUsers[ruleUser['@ID']];
            if (listUser) return outputUsers.concat({...listUser, selected: true, alphaSplit: ruleUser['@AlphaSplit'], id: listUser.userId, isDirty: false})
            return outputUsers;
        }, []);
        const assignedTo = formatAssignedTo(departments, users);
        const assignedToDetails = formatAssignedToDetailed(departments, users);
        const baseRule = createRuleSummaryBase(option, keyedFields, keyedCategories, keyedLOBs, keyedFacilities);
        return {
            id: option['@ID'],
            ...baseRule,
            editName: option['@EditName'] ?? '',
            errorMsg: option['@ErrMsg'],
            expires: option['@Expires'],
            isDirty: false,
            isSelected: false,
            departments,
            users,
            assignedTo,
            assignedToDetails,
        };
    });
}

export function mapSearchResultsDetailsToUI(details: MCMCAErrorSearchDetail[]): IMCAERSearchSummaryDetail[] {
    return details.map(detailXml => {
        const errorMsg = (detailXml['#cdata-section']).replace(/[\$,]/g, '');
        return {
            refId: detailXml['@RefId'],
            editName: detailXml['@EditName'],
            totalErrorCount: detailXml['@TotalErrorCount'],
            errorMsg,
        };
    });
}

export function getUsersForList(users: IMCAERS_User[]):IMCAERS_RuleUser[] {
    return users.map(user => ({...user, selected: false, alphaSplit: '', isDirty: false}));
}
export function getDeptsForList(departments: IMCAERS_DepartmentValue[]): IMCAERS_DepartmentRuleValue[] {
    return departments.map(department => ({...department, selected: false}));
}
export function mapSearchResultsToUI(crudSearchResults: MCAErrorSearchResultInfo, listPopulation: IManageClaimAssignmentsErrorRulesData['searchListPopulation'], users: IMCAERS_User[], departments: IMCAERS_DepartmentValue[]): IMCAERSearchSummaryTabState {
    const searchSummary = crudSearchResults.MCAResultsMaintenanceInfo.SearchSummary;
    // These need to be defined here to get most benefit from reducing cyclomatic complexit
    const keyedFields = keyBy(listPopulation.fieldList, 'ID');
    const keyedCategories = keyBy(listPopulation.errorCategoryList, 'ID');
    const keyedLobs =  keyBy(listPopulation.lobList, 'ID');
    const keyedFacilities = keyBy(listPopulation.facilityList, 'ID');
    return {
        totalErrorCount: searchSummary['@TotalErrorCount'],
        maxRowsExceeded: (searchSummary['@MaxRowsExceeded'] !== '0'),
        userList: getUsersForList(users),
        departmentList: getDeptsForList(departments),
        summaries: searchSummary.Summary.map(summaryXml => {
            const baseSummary = createRuleSummaryBase(summaryXml, keyedFields, keyedCategories, keyedLobs, keyedFacilities)
            return {
                refId: summaryXml['@RefId'],
                ruleExists: summaryXml['@RuleExists'] === '1',
                totalErrorCount: summaryXml['@TotalErrorCount'],
                ...baseSummary,
                details: mapSearchResultsDetailsToUI(summaryXml.Details.Detail),
            }
        }),
        summarySortOrder: 'initial|SORT_DESCENDING',
        detailSortOrder:  'editName|SORT_DESCENDING',
    };
    
}

export function getSelectedRule(rules: IMCAERS_Rule[]): IMCAERS_Rule {
    var defaultRule = defaultManageClaimAssignmentRule;
    const selectedRules = rules.filter(p => p.isSelected === true);
    return (selectedRules.length === 1) ? selectedRules[0] : defaultRule;
}

export function getSelectedRuleUsers(rule: IMCAERS_Rule, userList: MCAE_Users[]): IMCAERS_User[] {
    return userList.map((item, i) => {
        return Object.assign({}, item, rule.users?.[i])
    })
}

export function formatAssignedTo(departmentList: IMCAERS_DepartmentRuleValue[], userList: IMCAERS_RuleUser[]): string {
    const departmentAssignedTo = departmentList.filter(department => department.selected).map(department => department.assignedToName);
    const userAssignedTo = userList?.filter(user => user.selected).map(user => user.assignedToName + (!!user.alphaSplit ? '*' : ''));
    return [...departmentAssignedTo, ...userAssignedTo].join(', ');
}

export function formatAssignedToDetailed(departmentList: IMCAERS_DepartmentRuleValue[], userList: IMCAERS_RuleUser[]): string {
    const departmentAssignedTo = departmentList.filter(department => department.selected).map(department => department.assignedToName);
    const userAssignedToDetailed = userList?.filter(user => user.selected).map(user => user.assignedToName + (!!user.alphaSplit ? '(' + user.alphaSplit + ')' : ''));
    return [...departmentAssignedTo, ...userAssignedToDetailed].join(', ');
}

export function getRuleStateFromSelection(state: IManageClaimAssignmentsErrorRulesUIState, currentRules: IMCAERS_Rule[]): IMCAERManageRulesTabState {
    const rulesSortOrder = state.manageRuleState.rulesSortOrder;
    const selectedRules = currentRules.filter(r => r.isSelected);
    if (selectedRules.length === 1) {
        const selectedRule = currentRules.find(r => r.isSelected);
        const userIdsOnSelectedRule = selectedRule?.users?.filter(u => u.selected).map(user => user.userId);
        const enabledAndSelectedUsers = getUsersForList(state.users).filter(u => (userIdsOnSelectedRule?.includes(u.userId) || u.isDisabled === false))
        const userList = enabledAndSelectedUsers.map(user => {
            if (userIdsOnSelectedRule?.includes(user.userId)) {
                const selectedUser = selectedRule?.users?.find(u => u.userId === user.userId);
                const alphaSplit = selectedUser?.alphaSplit ?? '';
                const isDisabled = selectedUser?.isDisabled ?? false;
                const isDirty = selectedUser?.isDirty ?? false;
                return { ...user, selected: true, alphaSplit, isDisabled, isDirty }
            } else {
                return { ...user }
            }
        })
        const deptIdOnSelectedRule = selectedRule?.departments?.map(dept => dept.deptId);
        const departmentList = getDeptsForList(state.departments).map(dept => {
            return { ...dept, selected: deptIdOnSelectedRule?.includes(dept.deptId) }
        })
        const expires = selectedRule?.expires;
        return { userList, departmentList, expires, rulesSortOrder }
    }

    // default set of users, departments and expires
    const userList = getUsersForList(state.users).filter(u => u.isDisabled === false);
    return { userList, departmentList: getDeptsForList(state.departments), expires: '', rulesSortOrder };
}

export function mapCrudToUiState(crud: MCClaimAssignmentErrorInfo): IManageClaimAssignmentsErrorRulesUIState {
    const {PayerList, FieldList, ErrorCategoryList, LOBList, FacilityList, UserList, DepartmentList, Rules} = crud;

    const payerList = mapPayerOptions(PayerList.Payer);
    const fieldList = mapfieldOptions(FieldList.Field);
    const errorCategoryList = mapCrudSimpleValues(ErrorCategoryList.ErrorCategory);
    const lobList = mapCrudSimpleValues(LOBList.LOB);
    const facilityList = mapFacilitiesOptions(FacilityList.Facility);
    const users = mapUserOptions(UserList.User);
    const departments = mapDepartmentOptions(DepartmentList.Department);

    /*  We make hashes from the dependent lists to prevent looping through all
     *  the lists when we 'hydrate' the properties that are just referenced by ID
     *  in the rules.
     */
    const rules = mapRulesOptions(Rules.Rule, keyBy(fieldList, 'ID'), keyBy(errorCategoryList, 'ID'), keyBy(users, 'userId'), keyBy(departments, 'deptId'), keyBy(lobList, 'ID'), keyBy(facilityList, 'ID'));

    return {
        searchListPopulation: {
            payerList,
            fieldList,
            errorCategoryList,
            lobList,
            facilityList,
        },
        searchFieldValues: {
            form: 'UB',
            payerType: 'lob',
            timePeriod: '0',
            dateFrom: '',
            dateTo: '',
            hcpcsMin: '',
            hcpcsMax: '',
            errorText: '',
            clientFields: ['', '', ''],
            selectedLobs: [],
            selectedPayers: [],
            selectedCategories: [],
            selectedFacilities: [],
            selectedFields: [],
        },
        users,
        departments,
        searchResults: {...getEmptySearchResults(), userList: getUsersForList(users), departmentList: getDeptsForList(departments)},
        rules,
        selectedTab: 0,
        isBusy: false,
        isDirty: false,
        manageRuleState: { userList: getUsersForList(users).filter(u => u.isDisabled === false), departmentList: getDeptsForList(departments), rulesSortOrder: 'payerLob|SORT_DESCENDING'},
        warningType: ''
    }
}

export const manageClaimAssignmentsErrorRulesUIReducer: Reducer<IManageClaimAssignmentsErrorRulesUIState, KnownActions> =
    (state: IManageClaimAssignmentsErrorRulesUIState | undefined, action: KnownActions) => {
        const isDirty = true;
        let newState = state && { ...state };

        if (state) {
            switch (action.type) {
            // common
            case 'INIT_MANAGE_CLAIM_ASSIGNMENT_ERROR_RULES':
            {
                const crud = action.data.uiData;
                if (crud) return mapCrudToUiState(crud.MCAErrorMaintenanceInfo);
                break;
            }
            case 'RESET_MANAGE_CLAIM_ASSIGNMENT_ERROR_RULES_DIRTY':
                newState = { ...state, isDirty: false };
                break;
            case 'MANAGE_CLAIM_ASSIGNMENT_SET_SELECTED_TAB':
                return {...state, selectedTab: action.data};
            case 'MANAGE_CLAIM_ASSIGNMENT_SAVE_CHANGES': {
                switch (action.status.status) {
                    case 'REQUEST':
                        return {...state, isBusy: true};
                    case 'SUCCESS':
                        return {...state, isBusy: false};
                }
                break;
            }
            // search tab
            case 'MANAGE_CLAIM_ASSIGNMENT_TOGGLE_FORM_TYPE': {
                const {searchFieldValues} = state;
                const form = searchFieldValues.form === 'UB' ? '1500': 'UB';
                return { ...state, searchFieldValues: {...searchFieldValues, form}};
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_TOGGLE_PAYER_TYPE': {
                const {searchFieldValues} = state;
                const payerType = searchFieldValues.payerType === 'lob' ? 'payer': 'lob';
                return { ...state, searchFieldValues: {...searchFieldValues, payerType}};
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_SELECT_TIME_PERIOD': {
                const {searchFieldValues} = state;
                const timePeriod = searchFieldValues.timePeriod = action.data;
                const dateFrom = '';
                const dateTo = '';
                return { ...state, searchFieldValues: {...searchFieldValues, timePeriod, dateFrom, dateTo} };
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_EDIT_CLIENT_SPECIFIC': {
                const { searchFieldValues } = state;
                const csFields = searchFieldValues.clientFields;
                const csResults = { ...state.searchFieldValues, clientFields: csFields.map((fieldValue: any, idx: any) => idx === action.data.csIndex ? action.data.csValue : fieldValue)};
                return {...state, searchFieldValues: csResults};
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_EDIT_HCPCS_MIN': {
                const {searchFieldValues} = state;
                const hcpcsMin = action.data;
                return { ...state, searchFieldValues: {...searchFieldValues, hcpcsMin}};
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_EDIT_HCPCS_MAX': {
                const {searchFieldValues} = state;
                const hcpcsMax = action.data;
                return { ...state, searchFieldValues: {...searchFieldValues, hcpcsMax}};
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_EDIT_ERROR_TEXT': {
                const {searchFieldValues} = state;
                const errorText = action.data;
                return { ...state, searchFieldValues: {...searchFieldValues, errorText}};
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_EDIT_DATE_FROM': {
                const {searchFieldValues} = state;
                const dateFrom = action.data;
                //console.log('dateFrom: ' + dateFrom);
                return { ...state, searchFieldValues: {...searchFieldValues, dateFrom}};
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_EDIT_DATE_TO': {
                const {searchFieldValues} = state;
                const dateTo = action.data;
                //console.log('dateTo: ' + dateTo);
                return { ...state, searchFieldValues: {...searchFieldValues, dateTo}};
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_SELECT_LOBS': {
                const { searchFieldValues } = state;
                const selectedLobs = action.data;
                let allLobIndex: number = selectedLobs?.findIndex((item: string) => item === '-1');
                if (selectedLobs.length > 1 && allLobIndex !== undefined && allLobIndex !== -1) {
                    selectedLobs?.splice(allLobIndex, 1); // remove '- All LOBs -' item...
                    //console.log('removing : ' + newList[0]);
                }

                return { ...state, searchFieldValues: { ...searchFieldValues, selectedLobs } };
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_SELECT_PAYERS': {
                const { searchFieldValues } = state;
                const selectedPayers = action.data;
                let allPayerIndex: number = selectedPayers?.findIndex((item: string) => item === '-1');
                if (selectedPayers.length > 1 && allPayerIndex !== undefined && allPayerIndex !== -1) {
                    selectedPayers?.splice(allPayerIndex, 1); // remove '- All Payers -' item...
                    //console.log('removing : ' + newList[0]);
                }

                return { ...state, searchFieldValues: { ...searchFieldValues, selectedPayers } };
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_SELECT_FACILITIES': {
                const { searchFieldValues } = state;
                const selectedFacilities = action.data;
                let allFacilityIndex: number = selectedFacilities?.findIndex((item: string) => item === '-1');
                if (selectedFacilities.length > 1 && allFacilityIndex !== undefined && allFacilityIndex !== -1) {
                    selectedFacilities?.splice(allFacilityIndex, 1); // remove '- All Facilities -' item...
                    //console.log('removing : ' + newList[0]);
                }

                return { ...state, searchFieldValues: { ...searchFieldValues, selectedFacilities } };
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_SELECT_FIELDS': {
                const { searchFieldValues } = state;
                const selectedFields = action.data;
                let allFieldIndex: number = selectedFields?.findIndex((item: string) => item === '-1');
                if (selectedFields.length > 1 && allFieldIndex !== undefined && allFieldIndex !== -1) {
                    selectedFields?.splice(allFieldIndex, 1); // remove '- All Fields -' item...
                    //console.log('removing : ' + newList[0]);
                }

                return { ...state, searchFieldValues: { ...searchFieldValues, selectedFields } };
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_SELECT_CATEGORIES': {
                const { searchFieldValues } = state;
                const selectedCategories = action.data;
                let allCategoryIndex: number = selectedCategories?.findIndex((item: string) => item === '-1');
                if (selectedCategories.length > 1 && allCategoryIndex !== undefined && allCategoryIndex !== -1) {
                    selectedCategories?.splice(allCategoryIndex, 1); // remove '- All Categories -' item...
                    //console.log('removing : ' + newList[0]);
                }

                return { ...state, searchFieldValues: { ...searchFieldValues, selectedCategories } };
            }
            // results tab
            case 'MANAGE_CLAIM_ASSIGNMENT_RESULTS_SELECT_DESLECT_SUMMARY': {
                const summaries = state.searchResults.summaries.map(summary => {
                    const details = summary.details?.map(detail => ({...detail, isSelected: false}));
                    const isSelected = summary.refId === action.data ? !summary.isSelected: summary.isSelected;
                    return {...summary, details, isSelected};
                }) || [];
                return { ...state, searchResults: {...state.searchResults, summaries}};
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_RESULTS_SELECT_DESLECT_DETAIL': {
                /* Note that this assumes that when there is more (or less) than one selected summary,
                 * there are no rows in the details grid, so the action can't be created in that state
                 */
                let summaries = state.searchResults.summaries || [];
                let selectedSummary = summaries.find(summary => summary.isSelected);
                if (!!selectedSummary) /* for ts */ {
                    selectedSummary = { ...selectedSummary!, details: selectedSummary!.details?.map(detail => detail.refId === action.data ? {...detail, isSelected: !detail.isSelected}: detail)};
                    return { ...state, searchResults: {...state.searchResults, summaries: summaries.map(summary => summary.refId === selectedSummary!.refId ? selectedSummary!: summary)}};

                }
                break;
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_RESULTS_ASSIGNMENT_NEW': {
                const searchResults = state.searchResults;
                const selectedSummaries = searchResults.summaries.filter(summary => summary.isSelected);
                // don't need to consider details in this condition
                if (selectedSummaries.length > 1 || (selectedSummaries.length === 1 && !selectedSummaries[0].details?.some(detail => detail.isSelected))) {
                    const summariesForRules = selectedSummaries.filter(summary => !summary.ruleExists);
                    const newRules: IMCAERS_Rule[] = summariesForRules.map(summary => {
                        const { refId, ruleExists, totalErrorCount, details, ...newRuleBase } = summary;
                        return {
                            ...newRuleBase,
                            id: uniqueId('new-'),
                            editName: '',
                            clientFields: [...((newRuleBase as any).clientFields || [])],
                            isSelected: false,
                            users: searchResults.userList.filter(user => user.selected).map(user => ({ ...user })),
                            departments: searchResults.departmentList.filter(department => department.selected).map(department => ({ ...department })),
                            expires: searchResults.expires,
                            //TODO: assignedToDetails (both if and then branches)
                            assignedTo: formatAssignedTo(searchResults.departmentList, searchResults.userList),
                            assignedToDetails: formatAssignedToDetailed(searchResults.departmentList, searchResults.userList),
                            isDirty: true,
                        }
                    });
                    /*  NOTE that in this case we make a rule from each summary and mark it as ruleExists.
                     *  It KEEPS all its details, so it still shows up in the summary list with an '!'
                     *  This is the behavior that exists in IE. If BA or QE asks for a change here, walk through
                     *  it with them in IE to verify. */
                    const summaries = searchResults.summaries.map(summary => summary.isSelected ? { ...summary, isSelected: false, ruleExists: true } : summary);
                    const warningType = newRules.length < selectedSummaries.length ? 'rulesNotAdded': '';
                    return { ...state, isDirty: summariesForRules.length>0, rules: [...state.rules, ...newRules], searchResults: { ...searchResults, summaries }, warningType }
                } else if (selectedSummaries.length === 1 && selectedSummaries[0].details?.some(detail => detail.isSelected)) {
                    const summary = {...selectedSummaries[0]};
                    const { refId, ruleExists, totalErrorCount, details, ...newRuleBase } = summary;
                    //detailsBySelectedStatus[0] are selected ones, detailsBySelectedStatus[1] are not selected
                    const detailsBySelectedStatus = partition(summary.details, 'isSelected'); 
                    const newRules = detailsBySelectedStatus[0].map(detail => {
                        // TODO: extract this mostly repeated code out to a function
                        return {
                            ...newRuleBase,
                            id: uniqueId('new-'),
                            editName: detail.editName,
                            clientFields: [...((newRuleBase as any).clientFields || [])],
                            isSelected: false,
                            users: searchResults.userList.filter(user => user.selected).map(user => ({ ...user })),
                            departments: searchResults.departmentList.filter(department => department.selected).map(department => ({ ...department })),
                            expires: searchResults.expires,
                            errorMsg: detail.errorMsg,
                            assignedTo: formatAssignedTo(searchResults.departmentList, searchResults.userList),
                            isDirty: true,
                        }
                    });
                    return { ...state, isDirty: true, rules: [...state.rules, ...newRules], 
                        searchResults: { ...searchResults, summaries: searchResults.summaries.map(oldSummary => 
                            oldSummary.refId === summary.refId ? {...summary, details: detailsBySelectedStatus[1], isSelected: false, ruleExists: true} : oldSummary
                        )}
                    };
                }
                break;
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_RESULTS_ASSIGNMENT_SELECT_USERS': {
                const userList = state.searchResults.userList;
                const searchResults = { ...state.searchResults, userList: userList.map(user => ({...user, selected: action.data.includes(user.userId)}))};
                return {...state, searchResults};
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_RESULTS_ASSIGNMENT_DEPARTMENTS': {
                const departmentList = state.searchResults.departmentList;
                const searchResults = { ...state.searchResults, departmentList: departmentList.map(department => ({...department, selected: department.deptId === action.data}))};
                return {...state, searchResults};
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_RESULTS_ASSIGNMENT_ALPHA_SPLIT': {
                const userList = state.searchResults.userList;
                const searchResults = { ...state.searchResults, userList: userList.map(user => (user.userId === action.data.userId ? {...user, alphaSplit: action.data.value}: user))};
                return {...state, searchResults};
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_RESULTS_ASSIGNMENT_EXPIRES': {
                const searchResults = {...state.searchResults, expires: action.data};
                return {...state, searchResults};

            }
            case 'MANAGE_CLAIM_ASSIGNMENT_MANAGE_RULES_SELECT_DESELECT': {
                const rules = state.rules.map(item => item.id === action.data ?
                    { ...item, isSelected: !item.isSelected } : item);
                const manageRuleState = getRuleStateFromSelection(state, rules);
                return { ...state, rules, manageRuleState };
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_RESULTS_SUMMARY_SORT_ORDER': {
                const searchResults = { ...state.searchResults, summarySortOrder: action.data};
                return {...state, searchResults};
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_RESULTS_DETAIL_SORT_ORDER': {
                const searchResults = { ...state.searchResults, detailSortOrder: action.data};
                return {...state, searchResults};
            }
            // manage tab
            case 'MANAGE_CLAIM_ASSIGNMENT_MANAGE_RULES_UPDATE': {
                const selectedRuleIds = state.rules.filter(r => r.isSelected).map(r => r.id);
                const baseUserList = state.manageRuleState.userList;
                const baseDepartments = state.manageRuleState.departmentList;
                const expires = state.manageRuleState.expires;
                const rules = state.rules.map(rule => {
                    if (selectedRuleIds.includes(rule.id)) {
                        // map from the base list since it has all users.  The rule has only those users that are affiliated with 
                        // the rule.  As we build the list, persist the isDirty flag and the id (if present) from the rule's user.  Once
                        // the full list is built, filter it down to only those users that are selected or dirty before adding to rule
                        const existingUsers = rule.users?.map(user => {
                            const matchedNewUser = baseUserList.find(u => u.userId == user.userId);
                            const selected = (!!matchedNewUser && matchedNewUser.selected);
                            const isDirty = user.isDirty || (selected !== user.selected);
                            return { ...user, selected, isDirty };
                        });
                        const existingUserIds = existingUsers?.map(u => u.userId);
                        const newlySelectedUsers = baseUserList.filter(u => u.selected && (!existingUserIds?.includes(u.userId)))
                            .map(u => { return { ...u, isDirty: true } });
                        const users = existingUsers 
                            ? existingUsers.concat(newlySelectedUsers)
                                .sort((a: IMCAERS_RuleUser, b: IMCAERS_RuleUser) => +(a.userId) - +(b.userId) )
                            : [];

                        const selectedDepts = baseDepartments.filter(d => d.selected);
                        const selectedDeptsId = selectedDepts.map(d => d.deptId);
                        const previousDepts: IMCAERS_DepartmentRuleValue[] = rule.departments 
                            ? rule.departments.filter(d => d.id !== undefined)
                                .map(dept => {
                                    const selected = selectedDeptsId.includes(dept.deptId);
                                    return { ...dept, selected }
                                }) 
                            : [];
                        const isConcatNeeded = (previousDepts.length === 0) || ((previousDepts.length === 1) && (!previousDepts[0].selected));
                        const departments = isConcatNeeded ?
                            previousDepts.concat(selectedDepts) :
                            previousDepts;

                        const assignedTo = formatAssignedTo(departments, users);
                        const assignedToDetails = formatAssignedToDetailed(departments, users);
                        return { ...rule, users, departments, assignedTo, assignedToDetails, expires, isDirty: true, isSelected: false }
                    } else {
                        return { ...rule }
                    }
                });
                const manageRuleState = getRuleStateFromSelection(state, rules);
                return { ...state, rules, manageRuleState, isDirty: true};
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_MANAGE_RULES_REMOVE': {
                const selectedRuleIds = state.rules.filter(r => r.isSelected).map(r => r.id);
                const rules = state.rules.map(rule => {
                    return (selectedRuleIds.includes(rule.id) ? { ...rule, isDeleted: true, isSelected: false, isDirty: true } : rule)
                });
                const manageRuleState = getRuleStateFromSelection(state, rules);
                return { ...state, rules, manageRuleState, isDirty: true };
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_MANAGE_RULES_SELECT_USERS': {
                const oldUserList = state.manageRuleState.userList;
                const userList = oldUserList.map(user => {
                    const selected = action.data.includes(user.userId);
                    return { ...user, selected }
                });
                const manageRuleState = { ...state.manageRuleState, userList };
                return { ...state, manageRuleState };
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_MANAGE_RULES_DEPARTMENTS': {
                const departmentList = state.manageRuleState.departmentList.map(department => ({ ...department, selected: department.deptId === action.data }));
                const manageRuleState = { ...state.manageRuleState, departmentList };
                return { ...state, manageRuleState };
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_MANAGE_RULES_EXPIRES': {
                const manageRuleState = { ...state.manageRuleState, expires: action.data };
                return { ...state, manageRuleState };
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_MANAGE_USER_ALPHA_SPLIT_UPDATE': {
                const userList = state.manageRuleState.userList;
                const manageRuleState = { ...state.manageRuleState, userList: userList.map(user => (user.userId === action.data.userId ? { ...user, alphaSplit: action.data.value, isDirty: true } : user)) };
                return { ...state, manageRuleState };
            }
            case 'MANAGE_CLAIM_ASSIGNMENT_MANAGE_RULE_SORT_ORDER': {
                const manageRuleState = { ...state.manageRuleState, rulesSortOrder: action.data };
                return { ...state, manageRuleState };
            }
            case 'MCA_SET_WARNING_TYPE': {
                return { ...state, warningType: action.data };    
            }

            case 'MCA_RESET_WARNINGS': {
                return { ...state, warningType: '' };
            }
            //TODO: move to search tab section to make it easier to find
            case 'MCA_SEARCH_RESULT_CRUD':
                switch (action.status.status) {
                    case 'REQUEST':
                        // TODO: if needing loading indicator, set isBusy to true;
                        break;

                    case 'SUCCESS':
                        let resultInfo: MCAErrorSearchResultInfo = action?.responseData;

                        let warningType = (resultInfo.MCAResultsMaintenanceInfo.SearchSummary['@MaxRowsExceeded'] == '1' ? 'maxRowsExceeded' : '');
                        if (warningType === '') {
                            warningType = (resultInfo.MCAResultsMaintenanceInfo.SearchSummary['@TotalErrorCount'] == '0' ? 'noUnassignedErrors' : '');
                        }
                        //  TODO: if we need loading indicators, set isBusy to false here
                        return { ...state, searchResults: mapSearchResultsToUI(resultInfo, state.searchListPopulation, state.users, state.departments), selectedTab: warningType? 0: 1, warningType};

                    case 'FAIL':
                        console.log(`The response to the Manage Claim Assignments Error Resuls Search returned FAIL`);
                        //  TODO: if we need loading indicators, set isBusy to false here
                        return { ...state, searchResults: {...getEmptySearchResults(), userList: getUsersForList(state.users), departmentList: getDeptsForList(state.departments)}};
                }
                break;

            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
                return state;
            }
        }
        return newState || defaultManageClaimAssignmentsErrorRulesState;
    }
