import * as React from 'react';
import { RouteComponentProps, Prompt, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindAll } from 'lodash';
import { Location } from 'history';
import { SelectDropdown, Radio } from '@optum-uicl/ui-core/dist';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { OKCancelButtons, DialogWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { ModalConfirmation, leaveMessage } from '@common/ModalConfirmation';
import CrudTypes from '../../../commonResources/CrudTypes';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { IFormModalState, defaultFormModalState } from '../../../store/ui/BaseCrudUI';
import { ApplicationState } from '@store/index';
import { IReportOptionsUIState, IReportOptionsActionProps, actionCreators, validationCallback } from '@store/ReportOptions';
import { BoldSelectWrapper } from '../../common/component-styles';
import styled, { createGlobalStyle } from 'styled-components';
import { SelectDropdownValue } from '@optum-uicl/ui-core/dist/Atoms/SelectDropdown/types';

const GlobalStyles = createGlobalStyle` 
        #ro-field-delimiter-select-menu .selectDropdown__menu, #ro-text-qualifier-select-menu .selectDropdown__menu
{ 
         width: 215px !important;
    }
        #reportFieldSpace span {
        margin: 4px 1px;
}
    #ro-field-delimiter, #ro-text-qualifier {
        margin: 6px 0px;
    }
    `;

const DropddownWrapper = styled.div`
        fieldset { 
        margin: 0 !important;
        margin-inline: 0;
  }
`
interface IComponentProps {
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IReportOptionsProps = IMergeCrudComponentProps<IReportOptionsUIState, IReportOptionsActionProps, IOwnProps>;

interface ISelectionOption {
    label: string;
    value: string;
}

export class ReportOptions extends React.PureComponent<IReportOptionsProps, IFormModalState> {
    // define only once, instead of on every render
    private instructions = <React.Fragment>Changing the delimiter or qualifier will force all CSV files to use the selected characters. <i>(See Auto-Module Help for instructions about creating a CSV that uses a non-standard delimiter or qualifier.)</i></React.Fragment>;
    private dateFormats: ISelectionOption[] = [{ label: 'Long (May 1, 2021)', value: 'LONG' }, { label: 'Short (05/01/2021)', value: 'SHORT' }];
    private fieldDelimiters: ISelectionOption[] = [
        { label: 'Default', value: '' },
        { label: '|', value: '124' },
        { label: 'Tab', value: '9' },
        { label: '~', value: '126' },
        { label: '*', value: '42' }, //life, the universe, and everything*
        { label: ',', value: '44' },
    ];
    private textQualifiers: ISelectionOption[] = [
        { label: 'Default', value: '' },
        { label: '"', value: '34' },
        { label: '`', value: '96' },
        { label: 'None', value: '0' },
    ];

    public componentDidMount() {
        this.props.action.crud.get({ crudId: CrudTypes.mctiCSVOption }, (result: MCReportOptions) => {
            if (validationCallback(result)) {
                this.props.action.ui.initalizeOptions({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: result.ReportOptionMaintenanceInfo.ReportOption,
                });
                return true;
            }
        });
    }

    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    // for some reason manual bind and bindAll did not work with these functions, so using alternate syntax
    public onDialogOk = () => {
        this.setState({ navigationConfirmed: true, saveOnNavigate: true }, () => {
            if (this.props.dataStore.ui) {this.props.action.crud.update(this.props.dataStore.crud); }
            this.props.history.push("/LandingPage");
        });
    }

    public onDialogCancel = (e: React.ChangeEvent<HTMLButtonElement>) => {
        // This will trigger the prompt to open the modal
        this.props.history.push('/LandingPage');
    }

    public onModalOk = () => {
        this.setState({navigationConfirmed: true, saveOnNavigate: false}, () =>
        {
            this.props.history.push(this.state.navDestination?.pathname || '/LandingPage');
        });
    }

    public onModalClose = () => {
        this.setState({ navigationConfirmed: false, saveOnNavigate: false }, () => {
            this.props.action.confirm.closeConfirm();
        });
    }

    public onFormatChange(e: string) {
        this.props.action.ui.editDateFormat({
            masterCrud: this.props.dataStore.crud.data,
            uiData: e,
        });
    }

    public onDelimiterChange(e: SelectDropdownValue) {
        this.props.action.ui.editDelimiter({
            masterCrud: this.props.dataStore.crud.data,
            uiData: e.value,
        });
    }
    public onQualifierChange(e: SelectDropdownValue) {
        this.props.action.ui.editQualifier({
            masterCrud: this.props.dataStore.crud.data,
            uiData: e.value,
        });
    }

    isBusy = () => {
        return (this.props.dataStore.crud.dataStatus === 'REQUEST');
    }

    private okCancelButtons = <OKCancelButtons onClickOK={this.onDialogOk} onClickCancel={this.onDialogCancel} />;


    // https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
    showRouteChangeConfirm(nextLocation: Location) {
        this.setState({ saveOnNavigate: false, navDestination: nextLocation},
            () => {
                this.props.action.confirm.openConfirm({ message: ''});
            }
        );
        //suppresses native prompt modal
        return false;
    }

    constructor(props: IReportOptionsProps) {
        super(props);
        this.state = defaultFormModalState;
        bindAll(this, ['onFormatChange', 'onDelimiterChange', 'onQualifierChange', 'showRouteChangeConfirm']);
    }

    public render() {
        const { dateFormat, delimiter, qualifier, isDirty } = this.props.dataStore.ui;
        const selectedDelimiter = this.fieldDelimiters.find(option => option.value === delimiter);
        const selectedQualifier = this.textQualifiers.find(option => option.value === qualifier);
        return (<DialogWrapper title='Report Options'
                                instruction={this.instructions}
                                helpUrl='/Support/Help/HELP_Reports_CSVMaint.htm'
                                buttons={this.okCancelButtons}
                                isBusy={this.isBusy()}
        >
            <Prompt when={isDirty && !this.state.navigationConfirmed} message={this.showRouteChangeConfirm} />
            <GlobalStyles />
            <ContentColumnWrapper>
			 <DropddownWrapper>
                <DialogFieldset>
                    <DialogLegend>Date Formats</DialogLegend>
                    <div id="reportFieldSpace">
                        {
                            this.dateFormats.map(format => {
                                return (
                                    <Radio
                                        checked={dateFormat === format.value}
                                        dataTestId='ro-dateFormat'
                                        key={format.value}
                                        label={format.label}
                                        //name={format.label}
                                        onChange={() => this.onFormatChange(format.value ?? "")}
                                    //value={format.value}
                                    //width='100%'
                                    />
                                );
                            })
                        }
                    </div>
                </DialogFieldset>
				
                <DialogFieldset>
                    <DialogLegend>CSV Options</DialogLegend>
                    <BoldSelectWrapper>
                       
                            <SelectDropdown
                                domID="ro-field-delimiter"
                                className="dropdown"
                                label="Field Delimiter:"
                                isClearable={false}
                                isSearchable={false}
                                size="small"
                                onChange={this.onDelimiterChange}
                                options={this.fieldDelimiters}
                                initialValue={selectedDelimiter}
                                menuWidth="500px"
                            />
                            <SelectDropdown
                                domID="ro-text-qualifier"
                                className="dropdown"
                                label="Text Qualifier:"
                                isClearable={false}
                                isSearchable={false}
                                size="small"
                                onChange={this.onQualifierChange}
                                options={this.textQualifiers}
                                initialValue={selectedQualifier}
                            />
                       
                    </BoldSelectWrapper>
                    </DialogFieldset>
                </DropddownWrapper>
            </ContentColumnWrapper>
            <ModalConfirmation
                isOpen={this.props.dataStore.confirm.isOpen}
                onModalToggle={this.onModalClose}
                formattedMessage={leaveMessage}
                onConfirm={this.onModalOk}
            />
        </DialogWrapper>);
    }
}

const connectedHoc = connect<IReportOptionsUIState,
    IReportOptionsActionProps,
    IOwnProps,
    IReportOptionsProps,
    ApplicationState>(
    createCrudMapStateToProps('reportOptions'),
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ReportOptions);

export default withRouter(connectedHoc);
