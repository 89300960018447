import * as React from "react";
import { DialogFieldset, DialogLegend } from "../../../common/DialogStyles";
import { CrudPermissions } from "../CrudPermissions";
import styled from 'styled-components';
import { General } from './General';
import { BillingProviderAddress } from './BillingProviderAddress';
import { PayToAddress } from './PayToAddress';
import { RequiredFieldValidator } from "../Validators/RequiredFieldValidator";
import { Button, Tooltip } from '@optum-uicl/ui-core/dist';
import { FacilityManager } from "../FacilityManager";
import { Facility, MCFacilityMaintenanceInfo } from "../../../../store/api/FacilityMasterType";

export const ComponentWrapper = styled.div`
    .fieldLabel{
        font-weight: bold;
        text-align: right;
        white-space: nowrap;
    }

    input {
        text-transform: uppercase;
        white-space:pre-wrap;
    }
    fieldset {
        width: inherit !important;
    }
`;

const SelectButtons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40;
    margin:  10px 0px;
    
    button {
        width: 110px;
        text-align: center;
        height: 30px !important;
    }
`;

interface IComponentProps {
    maintenanceInfo: MCFacilityMaintenanceInfo | undefined,
    facility: Facility,
    crudPermissions: CrudPermissions,
    isFacilityInfoSubmit: boolean,
    payerInfoSubmitCount: number,
    facilityAddRoutine: Function,
    facilityUpdateRoutine: Function,
    validationAggregation: Map<string, boolean>;
    validationAggregationRoutine: Function,
    pageIsValid: boolean,
    fieldFocus: string,
    addFacilityMaster: Function,
    updateFacilityMaster: Function,
    removeFacilityMaster: Function,
    copyFacilityMaster: Function,
    cursorPos: number,
    onCopyDupFacSubId: boolean
};

interface IComponentState {
    facility: Facility,
    isFacilityInfoSubmit: boolean
};

export class FacilityInformation extends React.Component<IComponentProps, IComponentState> {

    private billingProviderAddress: React.RefObject<BillingProviderAddress> | undefined;
    cursor: number | null = 0;

    constructor(props: IComponentProps) {
        super(props);

        const {
            facility,
            isFacilityInfoSubmit,
        } = this.props;

        this.state = {
            facility: facility,
            isFacilityInfoSubmit: isFacilityInfoSubmit
        }

        this.copyBillingProviderAddress = this.copyBillingProviderAddress.bind(this);
        this.billingProviderAddress = React.createRef();
    }

    getDescription() {
        const {
            validationAggregation,
            validationAggregationRoutine,
            fieldFocus,
            isFacilityInfoSubmit,
            facility,
        } = this.props;

        return (
            <table style={{ position: 'relative', left: 22 }}>
                <tbody>
                    <tr>
                        <td className="fieldLabel">* Description:</td>
                        <td style={{ width: 5 }}></td>
                        <td>
                            <input type="text"
                                id='Description'
                                autoFocus={fieldFocus == 'Description'}
                                style={{ width: 300, position: 'relative', left: 3 }}
                                value={facility["@Description"]}
                                onChange={x => {
                                    this.inputOnChange(x);
                                }}
                                onBlur={x => {
                                    x.currentTarget.value = x.currentTarget.value.trim();
                                }}
                                maxLength={60}
                            />
                        </td>
                    </tr>

                    <RequiredFieldValidator
                        fieldId={'txtDescription'}
                        fieldName={'Description'}
                        triggers={[
                            isFacilityInfoSubmit &&
                            !facility["@Description"].length
                        ]}
                        style={{ position: 'relative', left: 3 }}
                        validationAggregation={validationAggregation}
                        validationAggregationRoutine={validationAggregationRoutine}
                    />

                </tbody>
            </table>
        );
    }

    onClickAddFacility() {
        const {
            addFacilityMaster
        } = this.props;

        addFacilityMaster();
    }

    onClickUpdateFacility() {
        const {
            updateFacilityMaster,
        } = this.props;

        updateFacilityMaster();
    }

    onClickRemoveFacility() {
        const {
            removeFacilityMaster
        } = this.props;

        removeFacilityMaster();
    }

    onClickCopyFacility() {
        const {
            copyFacilityMaster
        } = this.props;

        copyFacilityMaster();
    }

    copyBillingProviderAddress() {

        const {
            facility
        } = this.state;

        let newFacility = facility;
        newFacility["@PayToName"] = facility["@Name"];
        newFacility["@PayToAddress"] = facility["@Address"];
        newFacility["@PayToAddress2"] = facility["@Address2"];
        newFacility["@PayToCity"] = facility["@City"];
        newFacility["@PayToStateID"] = facility["@StateID"];
        newFacility["@PayToZipCode"] = facility["@ZipCode"];

        this.setState({ facility: newFacility });
    }

    inputOnChange(x: React.ChangeEvent<HTMLInputElement>) {
        const {
            facilityUpdateRoutine,
        } = this.props;

        const inputId = x.currentTarget.id;
        const fieldId = '@' + inputId;
        const inputValue = x.currentTarget.value;
        const cursorPos = x.target.selectionStart;

        facilityUpdateRoutine(fieldId, inputValue, inputId, cursorPos)
    }

    inputOnBlur(x: React.FocusEvent<HTMLInputElement>) {
        const {
            facilityUpdateRoutine,
        } = this.props;

        const inputId = x.currentTarget.id;
        const fieldId = '@' + inputId;

        // remove leading and trailing spaces from the input value per legacy
        let inputValue = x.currentTarget.value.trim();
        x.currentTarget.value = inputValue;

        // cursor pos of 0 selects all text in the input
        facilityUpdateRoutine(fieldId, inputValue, inputId, 0);
    }

    render() {
        const {
            maintenanceInfo,
            crudPermissions,
            pageIsValid,
            fieldFocus,
            facilityUpdateRoutine,
            isFacilityInfoSubmit,
            payerInfoSubmitCount,
            facility,
            onCopyDupFacSubId
        } = this.props;

        /*
        const {
            facility,
            //isFacilityInfoSubmit,
        } = this.state;
        */

        const {
            validationAggregation,
            validationAggregationRoutine,
        } = this.props;

        const description = this.getDescription();
        const disableAdd = facility["@ID"] !== "0" || !crudPermissions.canCreateFacility;
        const disableUpdate = facility["@ID"] === "0" || !crudPermissions.canEditFacility;
        const disableCopy = facility["@ID"] === "0" || !crudPermissions.canCreateFacility;
        const disableRemove = facility["@ID"] === "0" || !crudPermissions.canDeleteFacility;
        const {
            cursorPos
        } = this.props;

        const facilityId = facility["@ID"];
        const bpaKey = 'bpa-' + facilityId;
        const payToKey = 'payTo-' + facilityId;
        const generalKey = 'general-' + facilityId;

        return (
            <ComponentWrapper>
                <DialogFieldset>
                    <DialogLegend>Facility Information</DialogLegend>

                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: '50%' }}>

                            {description}

                            <BillingProviderAddress
                                ref={this.billingProviderAddress}
                                key={bpaKey}
                                maintenanceInfo={maintenanceInfo}
                                facility={facility}
                                crudPermissions={crudPermissions}
                                copyBillingProviderAddress={this.copyBillingProviderAddress}
                                facilityUpdateRoutine={facilityUpdateRoutine}
                                validationAggregationRoutine={validationAggregationRoutine}
                                validationAggregation={validationAggregation}
                                fieldFocus={fieldFocus}
                                isFacilityInfoSubmit={isFacilityInfoSubmit}
                                cursorPos={cursorPos}
                            />

                            <PayToAddress
                                key={payToKey}
                                maintenanceInfo={maintenanceInfo}
                                facility={facility}
                                crudPermissions={crudPermissions}
                                facilityUpdateRoutine={facilityUpdateRoutine}
                                fieldFocus={fieldFocus}
                                validationAggregation={validationAggregation}
                                validationAggregationRoutine={validationAggregationRoutine}
                                isFacilityInfoSubmit={isFacilityInfoSubmit}
                                cursorPos={cursorPos}
                            />
                        </div>
                        <div style={{ flex: '50%' }}>

                            <General
                                key={generalKey}
                                maintenanceInfo={maintenanceInfo}
                                facility={facility}
                                crudPermissions={crudPermissions}
                                validationAggregation={validationAggregation}
                                validationAggregationRoutine={validationAggregationRoutine}
                                facilityUpdateRoutine={facilityUpdateRoutine}
                                fieldFocus={fieldFocus}
                                isFacilityInfoSubmit={isFacilityInfoSubmit}
                                cursorPos={cursorPos}
                                payerInfoSubmitCount={payerInfoSubmitCount}
                                onCopyDupFacSubId={onCopyDupFacSubId}
                            />

                        </div>
                    </div>
                    <SelectButtons>
                        <div style={{
                            display: 'flex', justifyContent: 'space-between', gap: '10px',
                            width: facility["@ID"] == '0' ? 200 : 225
                        }}>
                            {facility["@ID"] == '0' &&
                                <Button
                                    domID="add-facility-button"
                                    name={"Add"}
                                    buttonType="emphasized"
                                    size="control"
                                    type="button"
                                    disabled={disableAdd}
                                    onClick={(e: React.SyntheticEvent) => {
                                        this.onClickAddFacility();
                                    }}
                                />
                            }

                            {facility["@ID"] != '0' &&
                                <Button
                                    domID="update-facility-button"
                                    name={"Update"}
                                    buttonType="emphasized"
                                    size="control"
                                    type="button"
                                    onClick={(e: React.SyntheticEvent) => {
                                        this.onClickUpdateFacility();
                                    }}
                                    disabled={disableUpdate}
                                />
                            }

                            <Button
                                domID="remove-facility-button"
                                name={"Remove"}
                                buttonType="emphasized"
                                size="control"
                                type="button"
                                onClick={(e: React.SyntheticEvent) => {
                                    this.onClickRemoveFacility();
                                }}
                                disabled={disableRemove}
                            />
                            <Tooltip
                                domID="copy-facility-button-tooltip"
                                onClick={() => { }}
                                onKeyPress={() => { }}
                                tooltipContent="Copy the Billing Provider Address to Pay-To Address"
                                tooltipPosition="top-center"
                                tooltipStyle={{
                                    textAlign: 'center'
                                }}
                                tooltipWidth={350}
                            >
                                <Button
                                    domID="copy-facility-button"
                                    name={"Copy"}
                                    buttonType="emphasized"
                                    size="control"
                                    type="button"
                                    onClick={(e: React.SyntheticEvent) => {
                                        this.onClickCopyFacility();
                                    }}
                                    disabled={disableCopy}

                                />
                            </Tooltip>
                            
                        </div>
                    </SelectButtons>
                </DialogFieldset>
            </ComponentWrapper>
        );
    }
}
