import * as React from 'react';
import { RouteComponentProps, Redirect, withRouter } from 'react-router';
import { readCookie } from '@commonResources/window';

interface ILegacyRouteProps {
    redirectNativeUrl?: string;
    allowChrome: boolean;
}

interface ILegacyRouteParams {
    ParentFolder: string;
    ChildFolder: string;
    File: string;
    Process: string;
    Param1: string;
}

interface ILegacyRouteState {
    [key: string]: any
}

type IOwnProps = ILegacyRouteProps & RouteComponentProps<ILegacyRouteParams, {}, ILegacyRouteState>;

const INITIAL_STATE: ILegacyRouteState = {
};

export class LegacyRoute extends React.Component<IOwnProps, ILegacyRouteState> {

    static defaultProps = {
        redirectNativeUrl: '/LandingPage',
        allowChrome: false,
    }


    protected GoToProcess = React.createRef<HTMLFormElement>();

    constructor(props: IOwnProps) {
        super(props);
        this.state = {
            ...INITIAL_STATE,
        };
    }

    componentDidMount() {
        if (this.GoToProcess) {
            this.GoToProcess.current?.submit();
        }
    }

    mapRedirect() {
        // Use convention or dictionary lookup of path?
        let fileExt = '.asp';
        let requestId = '';
        let actionVerb = '';

        if (this.props.match.params.ParentFolder && this.props.match.params.ParentFolder === 'TestMode') {
            fileExt = '.aspx';
        }
        if (this.props.match.params.ParentFolder && this.props.match.params.ParentFolder === 'AgentClient') {
            actionVerb = 'GET';
            fileExt = '';
        }
        // to handle more scenarios accept fileExt on props
        if (this.props.history.location.state) {
            if ((this.props.history.location.state as any).fileExt) {
                fileExt = (this.props.history.location.state as any).fileExt;
                delete this.props.history.location.state.fileExt;

            }
            if ((this.props.history.location.state as any).requestId) {
                requestId = (this.props.history.location.state as any).requestId;
                delete this.props.history.location.state.requestId;

            }
            if ((this.props.history.location.state as any).actionVerb) {
                actionVerb = (this.props.history.location.state as any).actionVerb;
                delete this.props.history.location.state.actionVerb;
            }

            // cleanup so that we don't get these on teh querystring
            /*        var clone = Object.assign({}, { ...this.props.history.location.state});
                    delete clone.fileExt;
                    delete clone.requestId;
                    delete clone.actionVerb;
        */
        }

        if (this.props.match.params.ParentFolder == "Claims" &&
            this.props.match.params.ChildFolder == "CFR" &&
            this.props.match.params.Process == "NewClaim" &&
            this.props.match.params.Param1 &&
            this.props.match.params.Param1.length > 0) {
            // special case #1 of probably many
            return { url: "/Claims/CFR.asp?Function=NewClaim&Form=" + this.props.match.params.Param1, process: 'process', actionVerb: actionVerb };
        }

        let url = '/' + (this.props.match.params.ParentFolder ? this.props.match.params.ParentFolder + '/' : '') + (this.props.match.params.ChildFolder ? this.props.match.params.ChildFolder + '/' : '') + this.props.match.params.File + fileExt;
        // NOTE for handling query string params
        let hasFirstQueryStringParam = false;
        if (this.props.match.params.Param1 && this.props.match.params.Param1.length > 0) {
            hasFirstQueryStringParam = true;
            url += '?' + this.props.match.params.Param1;
        }
        if (requestId && requestId.length > 0) {
            url += (hasFirstQueryStringParam == true ? '&' : '?') + 'requestId = ' + requestId;
        }

        if (this.props.location && this.props.location.hash.length > 0) {
            url = url + this.props.location.hash;
        }

        let process = this.props.match.params.Process;
        return { url: url, process: process, actionVerb: actionVerb };
    }

    getParams() {
        if (this.props.location.state) {
            let keys = Object.keys(this.props.location.state);
            let inputs = [];
            for (let key in keys) {
                let paramName = keys[key];
                let value = this.props.location.state[paramName as keyof typeof this.props.location.state];
                console.log(`mapping ${paramName} to hidden input field - value = ${value}`);
                inputs.push((<input
                    type="hidden"
                    name={paramName}
                    value={value}
                />) as any);
            }
            return inputs;
        }
        return null;
    }

    renderLegacyGoTo() {
        let urlInfo = this.mapRedirect();
        return (
            <form ref={this.GoToProcess} action={urlInfo.url} name="GoToProcess" method={urlInfo.actionVerb && urlInfo.actionVerb.length > 0 ? urlInfo.actionVerb : "post"}>
                <input type="hidden" name="Process" value={urlInfo.process} />
                {this.getParams()}
            </form>
        );
    }

    renderLegacyRedirect() {
        return (<Redirect to={{ pathname: this.props.redirectNativeUrl, state: { from: this.props.location } }} />);
    }

    render() {
        if (this.props.match.path.startsWith('/Legacy/') && this.props.history.action === "POP") {
            this.props.history.goBack();
            return null;
        }

        if (this.props.allowChrome) {
            if (this.props.history.action === "POP") {
                this.props.history.goBack();
                return null;
            }
            return this.renderLegacyGoTo();
        }

        /*
            how this works:
            - you try to get to a page/resource, the developer doesn't want to allow this access.
            - setting the sessionStorage item "SystemErrorNotification" to a message will enable the dialog ON THE TARGET component.
            - the target component that the user is on (or navigating to) can check this message in the lifecycle event "componentDidMount" (see ARMMessaageComponent and ARMQuickSearc).
            - if the message is populated, the target component can take this message and display it in an alert/ModalDialog or whatever the requirement is for that page.
            - be sure to clear this message after using it...
            - note: this doesn't handle redirecing since each case is different.
        */
        sessionStorage.setItem('SystemErrorNotification', 'The page you are trying to access is not currently supported by your browser.');
        return this.renderLegacyRedirect();
    }
}

export default withRouter(LegacyRoute);
