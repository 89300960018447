import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styled from 'styled-components';

import { getRawToken } from '@scripts/session/SecurityToken';
import { ValidationCallback } from '../../../scripts/util/ActionHelpers';
import { createApiMapDispatchToProps, createApiMapStateToProps, IMergeApiComponentProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';
import { enforceArray, ValidateJSONSchema } from '@scripts/util/ValidationHelpers';

// store includes
import { ICookies_Config } from '@store/ConfigData';
import { ApplicationState } from '@store/index';
import { actionCreators /*, validationCallback */, IRemitFilterActionProps, IRemitFilterState } from '@store/RemitFilter';

// uicl
import { Tabs, Button, Input, SelectDropdown } from '@optum-uicl/ui-core/dist';
// uicl 
// commonResources

import * as wind from '@commonResources/window';
import { URLs } from '@commonDevResources/constants';
import { Typography } from '@commonResources/typography';
import { dateAdd, getDateInMMDDYYYYFormat, IsValidType } from '@commonResources/validations';

// common
import { RadioButtonComponent } from '@common/RadioButton';
import { ARMDatePicker } from '@common/DateOrTimeRelated/ARMDatePicker';
import { CheckBoxComponent } from '@common/CheckBox';
import { ARMNarrowInput } from '@common/UICLWrappers/ARMNarrowInput';
import { CustomARMNarrowInput } from '@common/UICLWrappers/CustomARMNarrowInput';
import { SelectComponent } from '@common/SelectComponent';
import { DialogFieldset, DialogLegend, DialogLegend3 } from '@common/DialogStyles';
import { ContentColumnWrapper, DialogButton, DialogWrapper } from '@common/DialogWrapper';
import { IModalFilterListFilters, IModalFilterListOption, IModalFilterListSelection, ModalFilterList } from '@common/FilterRelated/ModalFilterList';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { ARMNarrowDropdown } from '../../common/UICLWrappers/ARMNarrowDropdown';
import { Colors } from "@commonResources/colorVariables";
import FilterableList from "@commonResources/FilterableList/FilterableList";
import { SelectDropdownValue } from '@optum-uicl/ui-core/dist/Atoms/SelectDropdown/types';
import { Checkbox } from '@optum-uicl/ui-core/dist'
/////////////////////////////////////////////////////////////////////////////////////////////
//  CUSTOM STYLES 
/////////////////////////////////////////////////////////////////////////////////////////////
export const ContentRowWrapper = styled.div`
    display: flex;
    flex-direction: row;
   
    select:focus{
        outline: none;
        border: 1px solid rgb(115, 125, 133);
    }
    select option:checked, select option:active{
       background-color: #E9F1FF !important;
       color: #002677 !important; 
       border-top: 1px solid #0C55B8;
       border-bottom: 1px solid #0C55B8;
       border-left:none !important;
       border-right:none !important;
       font-weight:initial !important;
    }
    select option:hover{
        background-color: #CDDDFE !important;
        color: #002677 !important;
        cursor: pointer;
    }
    option:checked, option:active{
       background-color: #E9F1FF !important;
       color: #002677 !important; 
       border-top: 1px solid #0C55B8;
       border-bottom: 1px solid #0C55B8;
       border-left:none !important;
       border-right:none !important;
       font-weight:initial !important;
    }
    .SingleDatePickerInput{
        height:28px !important;
    }
    .SingleDatePickerInput .DateInput .DateInput_input {
        height: 28px !important;
    }
    .narrow_input > input{
        height:24px !important;
    }
    .selectDropdown__control{
        min-height:28px !important;
        height:28px !important;
    }
    .medium .selectDropdown__value-container, .medium .selectDropdown__indicators{
        height: 28px !important;
    }
    #remitfilter_qs_checknumber{
        height:28px !important;
    }
     #remitfilter_providernpi_select{
        height:28px !important;
     }
    #remitfilter_providernpi_select:focus{
        outline:none !important;
     }
    #remitfilter_providerno{
        height:28px !important;
    }
    #remitfilter_providerno:focus{
        outline:none !important;
     }
    #remitfilter_adv_fiscalperiodto{
        height:28px !important;
        width: 170px !important;
        margin-left: 5px !important;
    }
    #remitfilter_adv_fiscalperiodfrom{
        height:28px !important;
        width: 170px !important;
        margin-left:-1px !important;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    .pseudodate input,
    .narrow_input input {
        
        box-shadow: inset 0 2px 2px 0 rgba(155, 161, 169, 0.25);
    }
`;

export const FilterColumn0 = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
    width: 450px;
`;

export const SlimLabel = styled.div`
        display: block; 
        color: #37474F;
        font-size: 11px;
        line-height: 1.4em;
        font-weight: 600;
        -webkit-letter-spacing: 0.5px;
        -moz-letter-spacing: 0.5px;
        -ms-letter-spacing: 0.5px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-right: 10px;
        flex: 1
`;
export const CustomSlimLabel = styled.div`
        display: block; 
        color: #37474F;
        font-size: 11px;
        line-height: 1.4em;
        font-weight: 600;
        -webkit-letter-spacing: 0.5px;
        -moz-letter-spacing: 0.5px;
        -ms-letter-spacing: 0.5px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-right: 10px;
        margin-top: -1px;
        flex: 1
`;
export const FilterColumn1 = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 475px; 
`;

export const FilterColumn2 = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 500px; // maybe to much room
    width: 450px; // a little more room
    // width: 400px; // fits the best
`;

export const FilterColumn3 = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 180px;  
`;

export const RemitFilterContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

    min-height: 680px;
    min-width: 1080px;
    width: 1250px;
    padding: 0px;

    button label{
        text-transform:uppercase;
    }

   * div > .arm_checkbox_label {
        padding-left: 4px;
    }

    #remitfilter_providerno_select,
    #remitfilter_payer_select,
    #remitfilter_remitlob_select,
    #remitfilter_facility_select{
        overflow-x: hidden;
        height: 145px;
    }

#remitfilter_lob_select{
        overflow-x: hidden;
        height: 183px;
    }
    #remitilter_displayorder_adv label {
        display: block; 
        color: rgb(55, 71, 79);
        font-size: 11px;
        line-height: 1.4em;
        font-weight: 600;
        -webkit-letter-spacing: 0.5px;
        -moz-letter-spacing: 0.5px;
        -ms-letter-spacing: 0.5px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-right: 10px;
        flex: 1;
    }
    #remitilter_displayorder_adv
    .selectDropdown__control{
        
        box-shadow:inset 0 2px 2px 0 rgba(155,161,169,0.25);
        }
   }

    #remitfilter-lastremitlist_wrapper,
    #remitfilter-lastfilter_wrapper,
    #remitfilter-quickfilter_wrapper,
    #remitfilter-savedfilter_wrapper{
        width: 350px;
        float:left;
        margin-left: 10px;
        margin-top: 5px;
        
    }

    
    #remitfilter-lastfilter,
    #remitfilter-savedfilter,
    #remitfilter-quickfilter,
    #remitfilter-lastremitlist
    {
        margin-left:1px; 
        height:15px;
        width:15px;
    }

    #remitfilter_sflist {
        margin-bottom: 5px;
    }

    #remitfilter-tab-qs,
    #remitfilter-tab-adv
    {
       padding-top: 1px;
       padding-bottom: 1px;
    }


    #remitfilter-tab-qs > label,
    #remitfilter-tab-adv > label
     {
         margin-bottom: 0px;
         ${Typography.small};
     }

    .col2_sep{
        margin-left: 0px;
    }
    .col3_sep {
        margin-left: 10px;
        margin-right: -5px;
        // padding-top:20px;
    }
    .col3_sep_qs {
        margin-right: 0px;
        margin-top: 20px;
    }

    .remitfilter_qs_checknumber {
        width: 100%;
        margin-bottom:0px !important;

        label {
            
            color: #37474F;
            font-size: 11px !important;
            line-height: 1.4em;
            font-weight: 600;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            
        }
        #remitfilter_qs_checknumber {
            width: 100%;
            box-shadow: inset 0 2px 2px 0 rgba(155, 161, 169, 0.25);
        }
    }
    

    .pseudodate {
        width: 175px;
    }

    .field2nolabel{
        margin-left: 10px;
        margin-right: -5px;
        // padding-top:20px;
    }
    
    .narrowlabel,
    #RemitFilter_AdjustmentCode,
    #RemitFilter_RemarkCode,
    #RemitFilter_GroupCode {   
        margin-left: 0px !important;
    }

    .narrowlabel {
        margin: 2.5px auto !important;
        .select-label-class {
            height: 24px !important;
            border: 1px solid rgb(75, 77, 79) !important;
            box-shadow: inset 0 2px 2px 0 rgba(155, 161, 169, 0.25);
        }
    }
   
`;
export const ButtonWrapper = styled.div`

    #remitfilter_sfloadbutton {
        width:85px;
        height:26px;
        display: inline-block;
        margin-left:190px;  
    }
`;

export const DialogBox = styled.div`
      display: flex;
      #remitfilter-refineclaimbtn, 
      #remitfilter-refinepaybtn { 
        width: fit-content;     
      }
`;

export const ContentRowContainer = styled.div`
     display: flex; 
     align-items: center;
     width: 100%;
     justify-content: center;
     .col2_sep, .col2_sep3 { 
        flex: 2 
      }
`;

export const FilterableListWrapper = styled.div`
  display: flex;
  flex-direction: row;

`;

export const ListContainerDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  

  color: ${Colors.black};
  ${Typography.default};
  ${Typography.normal};
  ${Typography.LetterSpacing};
  ${Typography.fontFamily};

  & select > option {
    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.normal};
    ${Typography.LetterSpacing};
    ${Typography.fontFamily};
    font-size: 13px !important;
  }

  & label {
    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.bold};
    ${Typography.LetterSpacing};
    ${Typography.fontFamily};
    ${Typography.uppercase};
  }

    #remitfilter_providernpi_select {
        width:130px !important;
        border: 1px solid rgb(75, 77, 79) !important;
    }

    #remitfilter_providerno {
        width:130px !important;
        border: 1px solid rgb(75, 77, 79) !important;
    }
    svg{
        position: absolute;
        right: 10px;
        bottom: 5px;
        cursor: pointer;
        opacity: 0.75;
        width: 18px !important;
        height: 18px !important;
    }
`;

const DisplayListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;

  & ul {
    list-style-type: none;
    ${Typography.default};
    ${Typography.normal};
    ${Typography.LetterSpacing};
    ${Typography.fontFamily};
    ${Typography.small};
    color: ${Colors.white};
  }
  & ul > li {
    padding: 5px 10px;
    margin: 2px;
    border-radius: 20px;
    border: 1px solid ${Colors.brightBlue115};
    background: ${Colors.babyBlue};
    text-align: center;
  }
`;

/////////////////////////////////////////////////////////////////////////////////////////////
// INTERFACE
/////////////////////////////////////////////////////////////////////////////////////////////

export interface IRemitFilterComponentProps {
    canEdit: boolean,  // Permission
    canView: boolean,  // SecurityClearance
    canCreate: boolean, // FN_CREATE_FILTERS - enables Save button

    ncsIsEnabled: boolean,

    title: string,  // title
    type?: string;  // be careful with this, the word "type" is used too much in filter, this value should be "Claim|Report|Remit"
    process: string;  // process
    execNode?: string;  //  ExecNode
    execAction?: string;  // ExecAction
    execProcess?: string;  // ExecProcess 
    parameter?: any;

    // NOT HERE:
    // ----------------------------------
    // executeJS - custom code in RemitFilter.tsx/WGD
    // SecurityClearance - in routes.tsx
    // Permission - in routes.tsx

    instruction?: string; // from Instruction
    testMode?: boolean;


};


interface IComponentState {

    activeTab: number;  // qs (0) or adv (1)
    quickStart: number; // which radio button is selected on QS
    focusField: string;
    disableRefineButton: boolean;
    disableBtn: boolean; // this is to avoid double click action

    disableUnmatched: boolean;
    disableMatched: boolean;
    disableClaimCandidates: boolean;
    disableDisplayOrder: boolean;

    filterIdQs: number;
    filterNameQs: string;
    hideQs: boolean;
    redirecting: boolean;
};


export const DEFAULT_STATE: IComponentState = {


    focusField: '',
    disableRefineButton: true,
    activeTab: 0,
    quickStart: 0,
    filterIdQs: -1,
    filterNameQs: '',
    disableBtn: false,
    disableUnmatched: false,
    disableClaimCandidates: true,
    disableMatched: false,
    hideQs: false,
    disableDisplayOrder: false,
    redirecting: false,
};

type IRemitFilterOwnProps = IRemitFilterComponentProps & RouteComponentProps<{}>;

type IRemitFilterProps = IMergeApiComponentProps<IRemitFilterState, IRemitFilterActionProps, IRemitFilterOwnProps>;


/////////////////////////////////////////////////////////////////////////////////////////////
// CLASS CONSTRUCTOR 
/////////////////////////////////////////////////////////////////////////////////////////////
const POSTACTION_SUBMITFILTER: string = "SubmitFilter";
const POSTACTION_REFINEFILTER: string = "RefineFilter";
const POSTACTION_LOADFILTER: string = "LoadFilter";  // this is loadfilter getting called when the initial draw does not show QS (show button instead)
const POSTACTION_LOADFILTER2: string = "LoadFilter2";  // this is switch tabs
const POSTACTION_JOBREQUEST: string = "JobRequest";
const POSTACTION_VIEWX12REMIT: string = "viewx12remitRedirect";
const POSTACTION_GOHOME: string = "GoHome";



export class RemitFilter extends React.Component<IRemitFilterProps, IComponentState> {

    static defaultProps: IRemitFilterComponentProps = {
        canEdit: false,   // this is your old Permission
        canView: false,
        canCreate: false,
        ncsIsEnabled: false,
        process: "view",
        title: "Remit Filter",
        type: "Remit",
        parameter: {},
        instruction: "Use the Quick Start tab to choose an existing filter or enter a quick filter, use the Advanced tab to set up a new filter. Then click Refine to see a list of remits in the working group before you use them or click Continue to use the remits without looking at them. Save is active only from the Advanced tab.",
        testMode: false,
    };

    //locals
    process_lowercase: string;
    today: string;
    minDate: string;
    minDateMedicare: string;
    maxDate: string;

    dataLoaded: boolean;
    hasFavorites: boolean;
    tabType: string;
    matchType: string;

    disableQs0: boolean;
    disableQs1: boolean;
    overrideUrl: string;

    saveFilterName: string;
    saveFilterId?: number | undefined;
    directEntry?: boolean | undefined;
    helpLink: string;   // from HelpInfo -> eg. '/Support/Help/HELP_RMTFilterQuickStart.htm'

    reportAlreadyRequested: boolean;

    constructor(props: IRemitFilterProps) {
        super(props);
        this.state = DEFAULT_STATE;

        this.process_lowercase = (this.props.process)?.toLowerCase();

        var myDate = new Date();
        this.today = getDateInMMDDYYYYFormat(myDate);
        this.maxDate = dateAdd(myDate, 'y', 2);
        this.minDate = dateAdd(myDate, 'y', -1);
        this.minDateMedicare = dateAdd(myDate, 'y', -2);

        this.dataLoaded = false;
        this.hasFavorites = false;
        this.tabType = '';
        this.matchType = '';
        this.disableQs0 = this.disableQs1 = false;
        this.overrideUrl = '';

        this.saveFilterName = '';
        this.saveFilterId = undefined;
        this.directEntry = undefined;
        this.helpLink = "/Support/Help/HELP_RMTFilterQuickStart.htm";

        this.reportAlreadyRequested = false;

        let scrollPos: number = 0;

    }

    public componentDidMount() {
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.goToHomePage();
        }



        // fix the helpLink     
        if (this.process_lowercase == "retrieve835") {
            this.helpLink = '/Support/Help/HELP_RMT_Ranges.htm';
        } else if (this.process_lowercase.indexOf("postingfile") > -1) {
            this.helpLink = '/Support/Help/HELP_RMT_CREATEPOSTINGFILE.htm';
        } else if (this.process_lowercase == "viewx12remit") {
            this.helpLink = '/Support/Help/HELP_FilterX12Remit.htm';
        }

        // set in match process somewhere - was a query string param, eg., MatchRemits
        this.matchType = sessionStorage.getItem("matchType") ?? "";
        window.sessionStorage.removeItem("matchType");

        // set in wgd
        this.tabType = sessionStorage.getItem("tabType") ?? "";
        window.sessionStorage.removeItem("tabType");


        if (this.tabType !== '' || this.matchType == "MatchRemits") {
            this.disableQs0 = true;
            this.onChangeQuickStart(1);
        }

        // BC
        if (this.process_lowercase == "match") {
            this.setState({
                disableMatched: true,
                disableUnmatched: true,
                disableClaimCandidates: false
            });

            sessionStorage.removeItem("dtRefineRemits_state");
            if (window.location.search === "?matchType=MatchRemits") {
                let matchFilterData = localStorage.getItem('filter')
				console.log("item");
                console.log(matchFilterData)
                if (matchFilterData !== undefined && matchFilterData !== null) {
                    let parsedData = JSON.parse(matchFilterData);
                    this.props.action.ui.loadFilter(
                        {
                            api: this.props.dataStore.api.data,
                            uiData: { data: parsedData, index: this.state.activeTab }
                        });
                    localStorage.removeItem("filter");
                }

            }
        }


        if (this.process_lowercase == "match" && this.matchType !== "MatchRemits") {
            this.disableQs0 = this.disableQs1 = true;
            this.onChangeQuickStart(2);
        }

        if (this.process_lowercase === "viewx12remit" || this.process_lowercase === "retrieve835" ||
            this.tabType === "AdvancedRemits" || this.process_lowercase.indexOf("postingfile") > -1) {
            this.setState({ hideQs: true, activeTab: 1, quickStart: 1 });
            this.disableQs0 = this.disableQs1 = true;
            this.setPostAction(POSTACTION_LOADFILTER2);
        }


        if (this.process_lowercase !== "retrieve835" &&
            this.tabType !== "AdvancedRemits" && this.process_lowercase.indexOf("postingfile") == -1) {
            this.setState({ disableRefineButton: false });
        }


        if (this.process_lowercase == "feedantpay") {
            this.setState({ disableUnmatched: true, disableMatched: true });
            this.disableQs0 = this.disableQs1 = true;
            this.onChangeQuickStart(2);
        }


        if (this.oddballProcesses()) {
            this.disableQs0 = this.disableQs1 = true;
            this.onChangeQuickStart(2);
        }

        if (this.process_lowercase == "denial") {
            this.disableQs0 = true;
            this.onChangeQuickStart(1);
        }

        /* jmc todo - when tabtype is advanced (back from refine), will it load the right tab...possibly was in ..
         * If Request.QueryString("tabType") = "Advanced" Then 'Back button from Working Claim List
        %>
        ....
          LoadFilter();  < -- here?
        */


        if (this.tabType === 'Advanced') {
            this.setPostAction(POSTACTION_LOADFILTER2);
        }

        this.props.action.configData.getConfigData({
            cookies: [{ name: "DirectEntry" }],
            config: [
                { name: "isClientUser" },
                { name: "isEBOUser" },
                { name: "RemitPostingFile_MaxCLPs" },
                { name: "Retrieve835_MaxFileCount" },
                { name: "RemitPostingFile_MaxRangeDays" },
            ],
        });

        this.props.action.api.loadData(
            URLs.api + "/api/data/GetRemitFilterFormData",
            this.validationCallback_GetFormData.bind(this),
            this.errorCallback_GetFormData.bind(this)
        );

        this.props.action.api.loadData2(
            URLs.api + "/api/data/GetRemitFilterList",
            "",
            "GET",
            this.validationCallback_GetRemitFilterList.bind(this),
            this.errorCallback_GetRemitFilterList.bind(this)
        );

    }


    public componentWillUnmount() {

        resetApiComponentState(this.props.action, this.props.dataStore);

    }

    public componentDidUpdate(prevProps: any, prevState: any) {
        if (this.process_lowercase !== "match") {

            if (this.getData(this.props.dataStore.ui.filter?.Filter?.Candidates_S, '@Secondary') === 'Y') {
                this.props.action.ui.updateCandidatesS({ api: this.props.dataStore.api.data, uiData: { value: "CandidatesS" } });
            }

            if (this.getData(this.props.dataStore.ui.filter?.Filter?.Candidates_N, '@NameControl') === 'Y') {
                this.props.action.ui.updateCandidatesN({ api: this.props.dataStore.api.data, uiData: { value: "CandidatesN" } });
            }

        }
        var postAction = this.props.dataStore.ui.postAction;
        if (postAction !== '') {

            this.setPostAction("");
            if (postAction == POSTACTION_SUBMITFILTER) {
                this.postAction_SubmitFilter();
            } else if (postAction == POSTACTION_REFINEFILTER) {
                this.postAction_RefineFilter();
            } else if (postAction == POSTACTION_LOADFILTER) {
                this.postAction_LoadFilter();
            } else if (postAction == POSTACTION_LOADFILTER2) {
                this.postAction_LoadFilter2();
            } else if (postAction == POSTACTION_JOBREQUEST) {
                this.jobRequest();
            } else if (postAction == POSTACTION_VIEWX12REMIT) {
                this.viewx12remitRedirect();
            } else if (postAction == POSTACTION_GOHOME) {
                this.goToHomePage();
            }
        }

        this.setFilter();

        if (this.props.dataStore.ui.dataRefreshed == true) {
            //    this.setFilter(); // pulling it up to always run
            this.setDataRefreshed(false);
        }

        if (this.props.dataStore.ui.clearFocus == true) {
            this.setState({ focusField: '' });
            this.resetClearFocus();
        } else {
            if (this.state.focusField !== '')
                this.applyFocus(this.state.focusField);
        }

    }

    public errorCallback_GetRemitFilterList(dataStatus: string): boolean {
        var str = 'An error occurred reading saved filters for ' + this.props.title + ".  Error: " + dataStatus;
        console.error(str);
        this.onAlert(str);

        return true;
    }

    public errorCallback_GetFormData(dataStatus: string): boolean {
        var str = 'An error occurred processing call for ' + this.props.title + ".  Error: " + dataStatus;
        console.error(str);
        this.onAlert(str);

        return true;
    }

    public validationCallback_GetRemitFilterList(data: APIRMTF_ListPopulationSavedFilterContainer): boolean {
        let testObj: APIRMTF_ListPopulationSavedFilterContainer = data as APIRMTF_ListPopulationSavedFilterContainer;
        let validShape: boolean = ValidateJSONSchema(testObj, "APIRMTF_ListPopulationSavedFilterContainer");
        if (validShape) {

            if (!testObj.ListPopulation?.FilterList)
                testObj.ListPopulation.FilterList = { Filter: [] };

            if (testObj.ListPopulation?.FilterList.Filter) {
                testObj.ListPopulation.FilterList.Filter = enforceArray<APIRMTF_SimpleList>(testObj.ListPopulation.FilterList.Filter);
            }
        }

        return validShape;
    }

    public validationCallback_GetFormData(data: APIRMTF_ListPopulationContainer): boolean {
        let testObj: APIRMTF_ListPopulationContainer = data as APIRMTF_ListPopulationContainer;

        let validShape: boolean = ValidateJSONSchema(testObj, "APIRMTF_ListPopulationContainer");
        if (validShape) {

            if (testObj.ListPopulation?.LOBList)
                testObj.ListPopulation.LOBList = enforceArray<APIRMTF_SimpleList>(testObj.ListPopulation.LOBList);

            if (testObj.ListPopulation?.PayerList)
                testObj.ListPopulation.PayerList = enforceArray<APIRMTF_SimpleList>(testObj.ListPopulation.PayerList);

            if (testObj.ListPopulation?.DisplayOrderList)
                testObj.ListPopulation.DisplayOrderList = enforceArray<APIRMTF_SimpleList>(testObj.ListPopulation.DisplayOrderList);

            if (testObj.ListPopulation?.FacilityList)
                testObj.ListPopulation.FacilityList = enforceArray<APIRMTF_SimpleList>(testObj.ListPopulation.FacilityList);

            if (testObj.ListPopulation?.ProviderList)
                testObj.ListPopulation.ProviderList = enforceArray<APIRMTF_SimpleList>(testObj.ListPopulation.ProviderList);

            if (testObj.ListPopulation?.ProviderNPIList)
                testObj.ListPopulation.ProviderNPIList = enforceArray<APIRMTF_SimpleList>(testObj.ListPopulation.ProviderNPIList);

            if (testObj.ListPopulation?.RemitLOBList)
                testObj.ListPopulation.RemitLOBList = enforceArray<APIRMTF_SimpleList>(testObj.ListPopulation.RemitLOBList);
        }

        return validShape;
    }


    public setFilter() {

        switch (this.process_lowercase) {

            case "feedantpay": {
                if (this.getData(this.props.dataStore.ui.filter?.Filter?.RemitState, '@Matched') !== 'M' &&
                    this.getData(this.props.dataStore.ui.filter?.Filter?.RemitState, '@All') !== 'true') {
                    this.onSelectRemitState('Matched');
                }
                break;
            }

            case "match": {
                if (this.getData(this.props.dataStore.ui.filter?.Filter?.RemitState, '@Unmatched') !== 'U' &&
                    this.getData(this.props.dataStore.ui.filter?.Filter?.RemitState, '@All') !== 'true') {
                    this.onSelectRemitState('Unmatched');
                }
                break;
            }

            case "remit": {
                // debuke says: this is causing an infinite loop... needed?
                if (this.doesParameterContainReportRequestValue(['1910', '1911'])) {
                    console.log('Remit Time!');
                    if (this.state.disableDisplayOrder != true) {
                        this.setState({ disableDisplayOrder: true });
                    }
                    //document.AdvancedFilter.DisplayOrder.selectedIndex = 0;
                    //document.AdvancedFilter.DisplayOrder.disabled = true;
                }
                break;
            }

            case "denial": {
                console.log('Denial filter');
                if (this.state.disableDisplayOrder != true) {
                    this.setState({ disableDisplayOrder: true });
                    console.log('disabled display order');
                }
                break;
            }
        }
    }

    // these process fell to else logic in onLoad
    public oddballProcesses() {
        if (this.process_lowercase == "view" ||
            this.process_lowercase == "retrieve835" ||
            this.tabType == "AdvancedRemits" ||
            this.process_lowercase.indexOf("postingfile") > -1 ||
            this.process_lowercase == "viewx12remit" ||
            this.process_lowercase == "match" ||
            this.process_lowercase == "denial" ||
            this.process_lowercase == "feedantpay")
            return false;
        return true;
    }

    public goToHomePage() {
        // BC
        if (this.process_lowercase == "match") {
            localStorage.setItem('filter', JSON.stringify(this.props.dataStore.ui.filter));
            const isSecondary = this.getData(this.props.dataStore.ui.filter?.Filter?.Candidates_S, '@Secondary') === 'Y' ? 'true' : 'false';
            this.props.history.push(`/Remits/MatchRemits/Refine/secondaryFlag=${isSecondary}`);

            this.setBusy(false);
            this.setState({ redirecting: false });
        } else {
            if (this.process_lowercase == "match") {
                wind.goTo('/Remits/MatchRemits.asp?secondaryFlag=false' /* + isSecondary */, "Refine", "", "");
            } else
                this.props.history.push("/LandingPage");
        }

    }



    public isClientUser() {
        let userId = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "isClientUser");
        return (userId && userId.value === 'True');
    }
    public isNDCUser() {
        let userId = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "isClientUser");
        let eboId = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "isEBOUser");
        return ((userId && userId.value !== 'True') && (eboId && eboId.value !== 'True'));
    }

    public getConfigValue(flag: string): string {
        let configObj = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === flag);
        return (configObj && configObj.value ? configObj.value : "");
    }

    public getDirectEntry() {
        if (this.directEntry != undefined)
            return this.directEntry;

        let directEntry = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "DirectEntry");
        if (directEntry && directEntry.value)
            this.directEntry = directEntry.value === "1";

        return (this.directEntry == undefined ? false : this.directEntry);

    }

    public getDateFromControl(dateVal: string) {
        var formattedDate = new Date(dateVal);
        if (dateVal != "") {
            if (formattedDate.valueOf() != 0) {
                var today = new Date();
                var oneDay = 1000 * 60 * 60 * 24;
                var yearOffset = dateVal.lastIndexOf("/");
                var monthOffset = dateVal.indexOf("/");
                var dayOffset = dateVal.indexOf("/", monthOffset + 1);

                if (yearOffset == -1 || monthOffset == -1 || dayOffset == -1) {
                    return undefined;
                }

                // need to build an actual date
                if (dateVal.substr(yearOffset + 1) == "0000") {
                    var month = dateVal.substr(0, monthOffset);
                    var day = dateVal.substr(monthOffset + 1, dayOffset - monthOffset - 1);

                    var timeDiff = Math.abs(today.getTime() - Math.abs((+month) * 30 * oneDay + (+day) * oneDay));

                    // reset the value of FromDate to the actual date
                    formattedDate = new Date(timeDiff);
                }
            }
        }
        return formattedDate;
    }

    public onCancel() {
        this.props.history.push("/LandingPage");
    }
    public onClearAlert(e: any) {
        this.onAlert("");
    }
    public onAlert(msg: string) {
        this.props.action.ui.sendFilterAlert(msg);

    }

    /////////////////////////////////////////////////////////////////////////////////////////////
    // METHODS
    /////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////
    ////  REDUX ACTION WRAPPERS
    ////////////////////////////

    public resetClearFocus() {
        this.props.action.ui.resetClearFocus();
    }

    public onFiscalPeriodChange(e: any, fromField: boolean) {
        var data: string = e ?? '';
        var newData = { date: data, from: fromField };

        this.props.action.ui.updateFiscalPeriod(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newData,
                }
            });
    }

    public onFiscalPeriodBlur(e: any, fromField: boolean) {
        var data: string = e ?? '';
        if (data.length > 0) {
            var newData = data.replace(/[^\/\d]/g, "");
            if (newData != data) {
                var svData = { date: newData, from: fromField };

                this.props.action.ui.updateFiscalPeriod(
                    {
                        api: this.props.dataStore.api.data,
                        uiData: {
                            data: svData,
                        }
                    });

            }

        }

    }

    // datepicker blur is sending unneeded updates which accidentally clears my focus
    public dateFieldChanged(data: string, dataPrev: string): boolean {
        if (data == dataPrev)
            return false;
        return true;
    }

    public onImportDateChange(e: any, fromField: boolean) {
        var data: string = e ?? '';
        if (!this.dateFieldChanged(data, this.getData(this.props.dataStore.ui.filter?.Filter?.ImportDate, fromField ? '@From' : '@To')))
            return;

        var newData = { date: data, from: fromField };
        this.props.action.ui.updateImportDate(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newData,
                }
            });
    }

    public onPmtDateChange(e: any, fromField: boolean) {
        var data: string = e ?? '';
        if (!this.dateFieldChanged(data, this.getData(this.props.dataStore.ui.filter?.Filter?.PmtDate, fromField ? '@From' : '@To')))
            return;

        var newData = { date: data, from: fromField };

        this.props.action.ui.updatePmtDate(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newData,
                }
            });
    }

    public onSecBillChange(e: any, fromField: boolean) {
        var data: string = e ?? '';
        if (!this.dateFieldChanged(data, this.getData(this.props.dataStore.ui.filter?.Filter?.SecBill, fromField ? '@From' : '@To')))
            return;
        var newData = { date: data, from: fromField };

        this.props.action.ui.updateSecBill(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newData,
                }
            });
    }

    public onServiceFromChange(e: any, fromField: boolean) {
        var data: string = e ?? '';
        if (!this.dateFieldChanged(data, this.getData(this.props.dataStore.ui.filter?.Filter?.ServiceFrom, fromField ? '@From' : '@To')))
            return;
        var newData = { date: data, from: fromField };

        this.props.action.ui.updateServiceFrom(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newData,
                }
            });
    }

    public onServiceThruChange(e: any, fromField: boolean) {
        var data: string = e ?? '';
        if (!this.dateFieldChanged(data, this.getData(this.props.dataStore.ui.filter?.Filter?.ServiceThru, fromField ? '@From' : '@To')))
            return;
        var newData = { date: data, from: fromField };

        this.props.action.ui.updateServiceThru(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newData,
                }
            });
    }

    public setDataRefreshed(val: boolean) {
        this.props.action.ui.setDataRefreshed(val);
    }

    public onApcCodeChange(val: string) {
        this.props.action.ui.updateApcCode({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public onContractualAdjChange(val: string) {
        this.props.action.ui.updateContractualAdj({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public onCheckNumberChange(val: string) {
        this.props.action.ui.updateCheckNumber({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public onFileNameChange(val: string) {
        this.props.action.ui.updateFileName({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public onPatientControlNumberChange(val: string) {
        this.props.action.ui.updatePatientControlNumber({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public onLastNameChange(val: string) {
        this.props.action.ui.updateLastName({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public onPatientICNNumberChange(val: string) {
        this.props.action.ui.updatePatientICNNumber({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public onPaymentAmtChange(val: string) {
        this.props.action.ui.updatePaymentAmt({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }
    public onCheckAmtChange(val: string) {
        this.props.action.ui.updateCheckAmt({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }
    public onRemitProviderNPIChange(val: string) {
        this.props.action.ui.updateRemitProviderNPI({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public onRemitFederalTaxIdChange(val: string) {
        this.props.action.ui.updateRemitFederalTaxId({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public onRemarkCodeIdChange(val: string) {
        this.props.action.ui.updateRemarkCodeId({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public onRemarkCodeChange(val: string) {
        this.props.action.ui.updateRemarkCode({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public onStatusChange(val: string) {
        this.props.action.ui.updateStatus({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }
    public onAmtBilledChange(val: string) {
        this.props.action.ui.updateAmtBilled({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }
    public onRemitTypeOfBillChange(val: string) {
        this.props.action.ui.updateRemitTypeOfBill({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public onAdjustmentCodeIdChange(val: string) {
        this.props.action.ui.updateAdjustmentCodeId({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public onAdjustmentCodeChange(val: string) {
        this.props.action.ui.updateAdjustmentCode({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public onGroupCodeIdChange(val: string) {
        this.props.action.ui.updateGroupCodeId({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public onGroupCodeChange(val: string) {
        this.props.action.ui.updateGroupCode({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public onSelectRemitState(val: string) {
        this.props.action.ui.updateRemitState({ api: this.props.dataStore.api.data, uiData: { value: val } });
    }
    public onSelectRemitStatus(val: string) {
        this.props.action.ui.updateRemitStatus({ api: this.props.dataStore.api.data, uiData: { value: val } });
    }
    public onSelectServiceType(val: string) {
        this.props.action.ui.updateServiceType({ api: this.props.dataStore.api.data, uiData: { value: val } });
    }
    public onSelectSource(val: string) {
        this.props.action.ui.updateSource({ api: this.props.dataStore.api.data, uiData: { value: val } });
    }
    public handleSelectCandidates(val: string) {
        if (this.process_lowercase == "match") {
            if (val === 'CandidatesS') {
                if (!(this.getData(this.props.dataStore.ui.filter?.Filter?.Candidates_S, '@Secondary') === 'Y')
                    && !(this.getData(this.props.dataStore.ui.filter?.Filter?.Candidates_N, '@NameControl') === 'Y')) {
                    this.props.action.ui.updateCandidatesN({ api: this.props.dataStore.api.data, uiData: { value: "CandidatesN" } });
                    this.props.action.ui.updateCandidatesS({ api: this.props.dataStore.api.data, uiData: { value: "CandidatesS" } });
                } else {
                    this.props.action.ui.updateCandidatesS({ api: this.props.dataStore.api.data, uiData: { value: val } });
                }
            } else {
                if (this.getData(this.props.dataStore.ui.filter?.Filter?.Candidates_S, '@Secondary') === 'Y'
                    && (this.getData(this.props.dataStore.ui.filter?.Filter?.Candidates_N, '@NameControl') === 'Y')) {
                    this.props.action.ui.updateCandidatesN({ api: this.props.dataStore.api.data, uiData: { value: "CandidatesN" } });
                    this.props.action.ui.updateCandidatesS({ api: this.props.dataStore.api.data, uiData: { value: "CandidatesS" } });
                } else {
                    this.props.action.ui.updateCandidatesN({ api: this.props.dataStore.api.data, uiData: { value: val } });
                }
            }
        } else {
            if (val === 'CandidatesN') {
                this.props.action.ui.updateCandidatesN({ api: this.props.dataStore.api.data, uiData: { value: val } });
            } else {
                this.props.action.ui.updateCandidatesS({ api: this.props.dataStore.api.data, uiData: { value: val } });
            }
        }
    }

    public onSelectPayers(e: React.ChangeEvent<HTMLSelectElement>) {
        var result = this.getSelectedOptions(e);
        if (result.length > 0 && result[0]["@ID"] === "") {
            result.splice(0, 1)
        }

        this.props.action.ui.updateSelectedPayers(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    value: result,
                }
            });
    }

    public onSelectRemitLOBs(e: React.ChangeEvent<HTMLSelectElement>) {
        var result = this.getSelectedOptions(e);
        if (result.length > 0 && result[0]["@ID"] === "") {
            result.splice(0, 1)
        }

        this.props.action.ui.updateSelectedRemitLOBs(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    value: result,
                }
            });
    }

    public onSelectLOBs(e: React.ChangeEvent<HTMLSelectElement>) {
        var result = this.getSelectedOptions(e);
        if (result.length > 0 && result[0]["@ID"] === "") {
            result.splice(0, 1)
        }

        this.props.action.ui.updateSelectedLOBs(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    value: result,
                }
            });
    }

    public onSelectProviders(e: any) {
        if (!e) return [];
        //let result = [];
        if (($("#remitfilter_providerno").val() !== "") || ($("#remitfilter_providerno").val() !== undefined)) {
            e = e.filter((item: any) => {
                return item.id !== "";
            })
        }
        let result: APIRMTF_SimpleIDName[] = [];
        let isAllProviderNoSelected: boolean = false;
        for (let i = 0; i < e.length; i++) {
            if (e[i].id === "") {
                isAllProviderNoSelected = true;
            }
            result.push({ '@ID': e[i].id, '@Name': e[i].text });
        }
        if (isAllProviderNoSelected) {
            result = [];
            result.push({ '@ID': '', '@Name': 'All PROV NOs' });
        }
        this.props.action.ui.updateSelectedProviders(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    value: result,
                }
            });
    }

    public onSelectProviderNPIs(e: any) {
        if (!e) return [];
        //let result = [];
        if (($("#remitfilter_providernpi_select").val() !== "") || ($("#remitfilter_providernpi_select").val() !== undefined)) {
            e = e.filter((item: any) => {
                return item.id !== "";
            })
        }
        let result: APIRMTF_SimpleIDName[] = [];
        let isAllProviderNPISelected: boolean = false;
        for (let i = 0; i < e.length; i++) {
            if (e[i].id === "") {
                isAllProviderNPISelected = true;
            }
            result.push({ '@ID': e[i].id, '@Name': e[i].text });
        }
        if (isAllProviderNPISelected) {
            result = [];
            result.push({ '@ID': '', '@Name': 'All PROV NPIs' });
        }
        this.props.action.ui.updateSelectedProviderNPIs(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    value: result,
                }
            });
    }

    public onSelectFacilities(e: React.ChangeEvent<HTMLSelectElement>) {
        var result = this.getSelectedOptions(e);
        if (result.length > 0 && result[0]["@ID"] === "") {
            result.splice(0, 1)
        }

        this.props.action.ui.updateSelectedFacilities(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    value: result,
                }
            });
    }

    public getSelectedOptions(e: React.ChangeEvent<HTMLSelectElement>) {
        if (!e || !e.target || !e.target.options || e.target.options.length === 0) return [];
        //let result = [];
        let result: APIRMTF_SimpleIDName[] = [];

        for (let i = 0; i < e.target.options.length; i++) {
            if (e.target.options[i].selected)
                result.push({ '@ID': e.target.options[i].value, '@Name': e.target.options[i].text });
        }
        return result;
    }

    ////////////////////////////
    ////  REFINE LOGIC
    ////////////////////////////

    public onRefineClick(refineCallback: any, byPament: boolean = false) {

        if (this.state.activeTab == 0 && (this.state.quickStart == 3 || this.state.quickStart == 1)) {
            // need to load filter before making call
            this.loadFilter(refineCallback);
        } else {
            if (!byPament)
                refineCallback();
            else
                refineCallback("ByPayment");
        }
    }

    public onRefine(/*e: React.ChangeEvent<HTMLButtonElement>, */ url: string = '') {
        if (this.validateFilter()) {
            this.overrideUrl = url;
            this.setPostAction(POSTACTION_REFINEFILTER);
        }

    }
    public postAction_LoadFilter() {
        this.loadFilter();
    }
    public postAction_LoadFilter2() {
        this.loadFilter();
        this.setState({ quickStart: 3 });
    }
    public postAction_RefineFilter() {
        this.setWorkingGroup(true);
    }

    public setWorkingGroup(refineProcess: boolean) {
        //       console.log(' ** setWorkingGroup called **');
        var xmlData: APIXMLCB_XmlCallBackModel = {};
        xmlData.Process = this.props.process;  // to test view edit use : "View/Edit"; 
        xmlData.Type = "Remit";
        xmlData.ViewOrigin = '/Remits/filter.asp';  // see note in type def
        xmlData.FilterJsonData = this.getFilterData();
        xmlData.Convert = this.process_lowercase == "view" ? "True" : "False";
        this.setBusy(true);

        if (this.process_lowercase === 'match') {
            this.setState({ redirecting: true });
        }

        fetch(URLs.api + '/api/data/SetWorkingGroup',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            })
            .then(async (response) => {

                // get json response here
                let data: any = await response.json();

                if (response.status === 306) {
                    //let cleanData = data.message.replace(/:"/g, "-");
                    this.onAlert(data.message);  // error is in message
                    this.setBusy(false);
                    this.setState({ redirecting: false });
                    return;
                } else if (response.status === 200) {
                    if (refineProcess) {
                        this.onRefineSuccess(data, this.overrideUrl);  // data is just in data
                    } else {
                        this.onRunValidateSuccess(data);
                    }

                    return;
                } else {
                    throw new Error(`RemitFilter: unable to run filter ${response.status}. ${response.statusText}:  ${(data && data.message ? data.message : data)}`);
                }
            })
            .catch(error => {
                console.error('RemitFilter: ' + error);
                this.errorCallback_generic("Unable to process filter.");
                this.setBusy(false);
                this.setState({ redirecting: false });
            })
            .finally(() => {
                if (this.process_lowercase !== 'match') {
                    this.setBusy(false);
                }
            });

    }

    public onRefineSuccess(data: string, urlOverride: string) {

        let strResponse = data;
        let bContinue = true;
        //  console.log('onRefineSuccess: ' + data);  
        let nClaimCount = -1;
        let postData = this.createPostDataFromProps();

        // user access restrictions should preface the responseText on a successful
        // filter, currently "RUA=[Y|N];"

        if (strResponse.substr(0, 4) === "RUA=") {
            postData.restrictionsExist = 'N';
            if (strResponse.substr(4, 1) === "Y") {
                postData.restrictionsExist = 'Y';
            }
            strResponse = strResponse.substr(6);
        }

        // in this event, we check the claim count and stop the processing (restting) if the count is 0 (zero)
        if (strResponse.substr(0, 7) === "CLMCNT=") {
            nClaimCount = Number(strResponse.substr(7, strResponse.length - 8));
            if (nClaimCount <= 0) {
                bContinue = false;
            }

            strResponse = "";
        }

        if (this.process_lowercase.indexOf("postingfile") > -1) {

            if (nClaimCount > -1) {
                if (nClaimCount <= 0) {
                    bContinue = false;
                } else {
                    var nMaxCount = Number(this.getConfigValue("RemitPostingFile_MaxCLPs"));
                    if (isNaN(nMaxCount)) nMaxCount = 0;
                    if (nClaimCount > nMaxCount) {
                        this.onAlert("Claim Payment Count cannot exceed " + nMaxCount + " claims.");
                        return;
                    }
                }
            }

        }

        if (bContinue) {
            if (strResponse === "") {
                if (postData.filterId === "0") {
                    postData.filterName = '';
                }
                sessionStorage.setItem('process', this.process_lowercase);
                if (this.process_lowercase == "match") {
                    this.props.action.ui.putRemitFilterList();
                } else {
                    // document.UserFilter.action = Url;
                    var url = "/Remits/WorkingGroupDisplay";
                    if (urlOverride == "CFI")  // don't inject url
                        url = "/CFI/Default";
                    if (urlOverride == "ByPayment")
                        url = "/Remits/WorkingGroupDisplayByPayment";

                    this.overrideUrl = '';
                    this.props.history.push(url, postData);
                }

            }
        }
        else {
            this.onAlert("No remits meet your filter criteria. Return to the filter and change your criteria.");
            this.setBusy(false);
            this.setState({ redirecting: false });

        }
    }

    ////////////////////////////
    ////  END REFINE LOGIC
    ////////////////////////////
    ////////////////////////////
    ////  SUBMIT LOGIC
    ////////////////////////////

    public onRunValidateSuccess(data: any) {
        let strResponse = data;
        let nClaimCount = -1;

        // not sure this will be needed or not...assuming viewx12remit will need it for the redirect
        let postData = this.createPostDataFromProps();

        // user access restrictions should preface the responseText on a successful
        // filter, currently "RUA=[Y|N];"

        if (strResponse.substr(0, 4) === "RUA=") {
            postData.restrictionsExist = 'N';
            if (strResponse.substr(4, 1) === "Y") {
                postData.restrictionsExist = 'Y';
            }
            strResponse = strResponse.substr(6);
        }

        // in this event, we check the claim count and stop the processing (restting) if the count is 0 (zero)
        if (strResponse.substr(0, 7) === "CLMCNT=") {
            nClaimCount = Number(strResponse.substr(7, strResponse.length - 8));

            strResponse = "";
        }

        if (this.process_lowercase == "retrieve835") {

            if (nClaimCount <= 0) {
                this.onAlert("The search did not return any results, please modify your filter criteria.");
                return;
            } else {
                const maxFileCount = Number(this.getConfigValue("Retrieve835_MaxFileCount"));
                this.props.action.ui.compareRemit835Count(isNaN(maxFileCount) ? 20 : maxFileCount);
            }

        } else if (this.process_lowercase == "viewx12remit") {

            this.props.action.ui.compareRemitFileCount();

        } else if (this.process_lowercase.indexOf("postingfile") > -1) {
            if (nClaimCount > -1) {
                if (nClaimCount == 0) {
                    this.onAlert("No remits meet your filter criteria. Return to the filter and change your criteria.");
                    return;
                } else {
                    var nMaxCount = Number(this.getConfigValue("RemitPostingFile_MaxCLPs"));
                    if (isNaN(nMaxCount)) nMaxCount = 0;
                    if (nClaimCount > nMaxCount) {
                        this.onAlert("Claim Payment Count cannot exceed " + nMaxCount + " claims.");
                        return;
                    } else {
                        this.jobRequest();
                    }

                }
            }
        }
    }

    public viewx12remitRedirect() {
        // this.props.history.push("/Administration/AdminPortal/viewx12remit");  
        // goTo('/Administration/AdminPortal.asp', 'viewx12remit'); // Line 647 of Remits/Filter.asp     
        wind.goTo("/ARM.UI/Administration/AdminPortal/viewx12remit", "viewx12remit", "", "");
    }


    public jobRequest() {
        if (this.process_lowercase == 'retrieve835') {
            this.executeFilterProcess();
        }
        if (this.process_lowercase.indexOf('postingfile') > -1) {
            this.executeMultiClaimProcess();
        }
    }
    public async executeFilterProcess() {

        var xmlData: APIXMLCB_XmlCallBackModel = {};
        var actionData = this.props.execAction;

        if (this.process_lowercase.indexOf('retrieve835') > -1) {
            actionData = "Retrieve835";
        }

        var jsonData: any = {};
        jsonData.Filter = {}; // execNode
        jsonData.Filter['@Action'] = actionData;
        jsonData.Filter['@Type'] = "Remit";  // hardcoded in filter.asp
        jsonData.Filter['@Process'] = this.props.process.toLowerCase();

        jsonData.Filter = Object.assign(jsonData.Filter, JSON.parse(this.getFilterData()));


        xmlData.Action = actionData ? actionData : "";
        xmlData.Process = this.props.process;
        xmlData.ViewOrigin = '/Remits/filter.asp';  // see note in type def
        xmlData.FilterJsonData = JSON.stringify(jsonData);

        this.props.action.ui.postMultiClaim(JSON.stringify(xmlData));

    }

    public async executeMultiClaimProcess() {

        var xmlData: APIXMLCB_XmlCallBackModel = {};
        var actionData = this.props.execAction;

        if (this.process_lowercase.indexOf('postingfile') > -1) {
            actionData = "RemitPostingFile";
        }

        var jsonData: any = {};
        jsonData.MultiClaim = {}; // execNode
        jsonData.MultiClaim['@Action'] = actionData;
        jsonData.MultiClaim['@Type'] = "Remit";  // hardcoded in filter.asp
        jsonData.MultiClaim['@Process'] = this.props.process;

        jsonData.MultiClaim = Object.assign(jsonData.MultiClaim, JSON.parse(this.getFilterData()));


        xmlData.Action = actionData ? actionData : "";
        xmlData.Process = this.props.process;
        xmlData.ViewOrigin = '/Remits/filter.asp';  // see note in type def
        xmlData.FilterJsonData = JSON.stringify(jsonData);

        this.props.action.ui.postMultiClaim(JSON.stringify(xmlData));

    }



    public onSubmitClick(submitCallback: any) {
        if (!this.state.disableBtn) {
            this.setState({ disableBtn: true });

            setTimeout(() => {
                if (this.state.activeTab == 0 && (this.state.quickStart == 3 || this.state.quickStart == 1)) {
                    // need to load filter before making call
                    this.loadFilter(submitCallback);
                } else {
                    submitCallback();
                }
            }, 200);

            this.setState({ disableBtn: false });
        }

    }

    public viewx12remits() {
        var xmlData: APIXMLCB_XmlCallBackModel = {};
        xmlData.Process = this.props.process;  // to test view edit use : "View/Edit"; 
        xmlData.Type = "Remit";
        xmlData.ViewOrigin = '/Remits/filter.asp';  // see note in type def
        xmlData.FilterJsonData = this.getFilterData();
        xmlData.TestMode = this.props.testMode;

        // debug only - REMOVE ME
        console.log(' ** refine clicked **');
        console.log(xmlData.FilterJsonData);

        this.setBusy(true);
        fetch(URLs.api + '/api/data/SetWorkingGroup',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            })
            .then(async (response) => {
                // get json response here
                let data: any = await response.json();
                console.log("DataEntered", response.status);
                if (response.status === 306) {
                    //let cleanData = data.message.replace(/:"/g, "-");
                    this.onAlert(data.message);  // error is in message
                    this.setBusy(false);
                    return;
                } else if (response.status === 200) {
                    this.redirectToAdminPortal(); // data is just in data
                    return;
                } else {
                    this.setBusy(false);
                    throw new Error(`ClaimFilter: unable to run filter ${response.status}. ${response.statusText}:  ${(data && data.message ? data.message : data)}`);
                }
            })
            .catch(error => {
                console.error('ClaimFilter: ' + error);
                this.errorCallback("Unable to process filter.");
            })
    }

    public redirectToAdminPortal() {
        fetch(URLs.api + '/api/data/remits/FileCountFromFilter',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                }
            })
            .then(async (response) => {
                // get json response here
                let data: any = await response.json();
                if (data === "1") {
                    if (this.GoToProcess) {
                        this.GoToProcess.current?.submit();
                    }
                }
                else if (data === "0") {
                    this.setBusy(false);
                    this.onAlert("The search did not return any results, please modify your filter criteria");
                    return false;
                }
                else {
                    this.setBusy(false);
                    this.onAlert("Too many results returned, please narrow your search criteria or use refine to select a single file");
                    return false;
                }
            })
            .catch(error => {
                console.error('ClaimFilter: ' + error);
                this.errorCallback("Unable to process filter.");
            })
            .finally(() => {
                //this.setBusy(false);
            });
    }

    protected GoToProcess = React.createRef<HTMLFormElement>();

    public renderLegacyGoTo() {
        return (
            <form ref={this.GoToProcess} action={"/Administration/AdminPortal.asp"} name="GoToProcess" method={"POST"}>
                <input type="hidden" name="Process" value={"viewx12remit"} />
            </form>
        );
    }

    public submitFilter() {
        if (this.process_lowercase === "viewx12remit") {
            this.viewx12remits();
            return;
        }
        if (this.process_lowercase === "view") {
            this.onRefine("CFI");  // ExecuteJS = "RunFilter(""/CFI/Default.asp"", ""True"");"
            return;
        }

        if (this.process_lowercase === "retrieve835" ||
            this.process_lowercase === "postingfile" ||
            this.process_lowercase === "postingfilebypayer" ||
            this.process_lowercase === "postingfilebypayment" ||
            this.process_lowercase === "viewx12remit") {
            if (this.validateFilter()) {
                return this.runFilterValidate();
            } else {
                return;
            }

        }

        // make the call
        if (this.props.execNode !== "" && this.props.execAction !== "") {
            if (this.validateFilter()) {
                return this.checkRua_validateFilter(POSTACTION_SUBMITFILTER);
            } else {
                return;
            }
        }

    }

    // this is a terrible name for a function, but leaving this because it maps to the old remitfilter.asp function
    public runFilterValidate() {
        this.setWorkingGroup(false);
    }

    public postAction_SubmitFilter() {
        console.log(`postAction_SubmitFilter -> ${this.props.execNode}`);

        // prevent timing issue from requesting the report more than once per second
        if (this.reportAlreadyRequested) {
            console.log('Report previously requested');
            return;
        }
        this.reportAlreadyRequested = true;

        // NOTE when implemented  filter-based pages don't assume this function works for you, you need to review the API code for your type (ExecAction)
        switch (this.props.execNode) {
            case "Report":

                this.executeRemitReportProcess();
                break;
            default:
                console.error('RemitFilter: unknown execNode found ' + this.props.execNode);
                break;
        }
    }

    public async executeRemitReportProcess() {

        var xmlData: APIXMLCB_XmlCallBackModel = {};

        // validations on inputs before making call - i do not know that any of these are required
        if (!this.props.execAction || (this.props.execAction && this.props.execAction.length == 0))
            return;

        this.setBusy(true);

        var jsonData: any = {};
        jsonData.Report = {}; // execNode
        jsonData.Report['@Action'] = this.props.execAction;
        jsonData.Report['@Type'] = "Remit";  // hardcoded in filter.asp
        jsonData.Report['@Process'] = (this.props.execProcess && this.props.execProcess.length > 0) ? this.props.execProcess : this.props.process;


        if (this.props.parameter && this.props.parameter?.ReportRequests) {
            jsonData.Report.ReportRequests = this.props.parameter?.ReportRequests;
        }

        jsonData.Report = Object.assign(jsonData.Report, JSON.parse(this.getFilterData()));

        // debug only
        console.log(' ** executeRemitReportProcess clicked **');
        console.log(jsonData.Report);

        xmlData.Action = this.props.execAction ? this.props.execAction : "";
        xmlData.ViewOrigin = '/Remits/filter.asp';  // see note in type def
        xmlData.FilterJsonData = JSON.stringify(jsonData);

        this.props.action.ui.postMultiClaim(JSON.stringify(xmlData));
    }

    public doesParameterContainReportRequestValue(values: string[]): boolean {
        var result = false;
        if (this.props.parameter?.ReportRequests && this.props.parameter.ReportRequests?.ReportRequest &&
            Array.isArray(this.props.parameter.ReportRequests.ReportRequest)) {
            var findResult = this.props.parameter.ReportRequests.ReportRequest.find((obj: any) => {
                return values.indexOf(obj['@ID']) > -1;
            });
            result = findResult !== undefined;

        }
        return result;
    }

    public processRequiresRuaCheck(): boolean {
        switch (this.process_lowercase) {
            case "feedantpay":
            case "remit":
            case "management":
            case "denail":
                return true;
            default:
                return false;
        }
    }


    public checkRua_validateFilter(postAction: string): boolean {

        if (!this.processRequiresRuaCheck()) {
            this.setPostAction(postAction);
        }

        var xmlData: APIXMLCB_XmlCallBackModel = {};
        xmlData.Type = "Remit";
        xmlData.ViewOrigin = '/Remits/filter.asp';  // see note in type def
        xmlData.FilterJsonData = this.getFilterData();

        this.props.action.ui.ruaValidateFilter(JSON.stringify(xmlData));

        return true;
    }

    ////////////////////////////
    ////  END SUBMIT LOGIC
    ////////////////////////////

    public createPostDataFromProps(): any {
        return {
            filterId: this.props.dataStore.ui.filterId,
            filterName: this.props.dataStore.ui.filterName,
            title: this.props.title,
            process: this.props.process,
            execProcess: this.props.execProcess,
            execNode: this.props.execNode,
            execAction: this.props.execAction,
            parameter: this.props.parameter ? this.props.parameter : {},
            filterType: "Remit",
            restrictionsExist: "",

        };
    }


    public setPostAction(val: string) {
        this.props.action.ui.setPostAction(val);
    }

    public onChangeQuickStart(val: number) {
        if (val !== this.state.quickStart) {
            if (val == 0 && this.disableQs0 == true)
                val = 1;
            if (val == 1 && this.disableQs1 == true)
                val = 2;
        }

        this.setState({ quickStart: val });
    }

    public loadFilter(successCallback: any = undefined, userClick: any = undefined) {

        try {
            var filterId: number = 0;
            if (this.state.quickStart === 3) {  // QuickFilter 3 is saved filter
                if (this.state.filterIdQs == -1) {  // filter id in list
                    this.onAlert("Please select a saved filter to load.");
                    return;
                }

                filterId = Number(this.state.filterIdQs);
                if (isNaN(filterId)) {
                    this.onAlert("Please select a saved filter to load.");
                    return;
                }

                this.loadFilterCriteria(filterId, successCallback);

            } else if (this.state.quickStart === 1 || this.state.quickStart === 0) {
                this.loadFilterCriteria(filterId, successCallback);
            }


        } catch (e) {
            const typError = e as Error;
            console.error('unable to load filter ' + typError.message);
            this.onAlert("Unable to load filter. Please select a saved filter to load.");
            return;
        } finally {
            userClick && this.state.disableRefineButton && this.setState({ disableRefineButton: false });
            // this.setState({ disableBtn: false });
        }

    }

    public setBusy(val: boolean) {
        if (val == false && this.state.disableBtn === true)
            this.setState({ disableBtn: false });

        this.props.action.ui.setBusy({ api: this.props.dataStore.api.data, uiData: { value: val } });
    }

    public async loadFilterCriteria(filterId: number, successCallback: any = undefined) {
        var manageBusy = !this.props.dataStore.ui.isBusy;

        if (manageBusy)
            this.setBusy(true);

        this.setLoaded(true);

        // call to set the filterName
        this.setFilterSelection(filterId, (filterId == 0 ? "" : this.state.filterNameQs));

        var xmlData: APIXMLCB_XmlCallBackModel = {};

        xmlData.FilterId = filterId;

        xmlData.Type = "Remit";  // "Claim|Report|Remit"

        await fetch(URLs.api + '/api/data/getFilter',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error(response.statusText);
                }
            }).then((data: APIRMTF_FilterWrapper) => {
                //console.log(data);
                if (data) {
                    this.onLoadFilter(data);
                }
            }).then(() => {
                if (successCallback != undefined)
                    successCallback();
            })
            .catch(error => {
                console.error('RemitFilter: ' + error);
                this.errorCallback_generic('Unable to load filter.');
            })
            .finally(() => {

            });

    }

    public onLoadFilter(data: APIRMTF_FilterWrapper) {
        var tabIndex: number = 1;
        this.setActiveTab(tabIndex);

        if (this.validationCallback_LoadFilter(data)) {
            this.setState({ focusField: '' })
            this.props.action.ui.loadFilter(
                {
                    api: this.props.dataStore.api.data,
                    uiData: { data: data, index: tabIndex }
                });
        } else {
            console.error('onLoadFilter cannot verify filter to load.');
            this.onAlert('Unable to load filter.');
        }

    }

    public validationCallback_LoadFilter: ValidationCallback<APIRMTF_FilterWrapper> = (data: any): data is APIRMTF_FilterWrapper => {
        let testObj: APIRMTF_FilterWrapper = data as APIRMTF_FilterWrapper;

        let validShape: boolean = ValidateJSONSchema(testObj, "APIRMTF_FilterWrapper");
        if (validShape) {
            if (testObj.Filter?.Payers?.Payer)
                testObj.Filter.Payers.Payer = enforceArray<APIRMTF_SimpleIDName>(testObj.Filter.Payers.Payer);

            if (testObj.Filter?.LOBs?.LOB)
                testObj.Filter.LOBs.LOB = enforceArray<APIRMTF_SimpleIDName>(testObj.Filter.LOBs.LOB);

            if (testObj.Filter?.RemitLOBs?.RemitLOB)
                testObj.Filter.RemitLOBs.RemitLOB = enforceArray<APIRMTF_SimpleIDName>(testObj.Filter.RemitLOBs.RemitLOB);

            if (testObj.Filter?.Providers?.Provider)
                testObj.Filter.Providers.Provider = enforceArray<APIRMTF_SimpleIDName>(testObj.Filter.Providers.Provider);

            if (testObj.Filter?.ProviderNPIs?.ProviderNPI)
                testObj.Filter.ProviderNPIs.ProviderNPI = enforceArray<APIRMTF_SimpleIDName>(testObj.Filter.ProviderNPIs.ProviderNPI);

            if (testObj.Filter?.Facilities?.Facility)
                testObj.Filter.Facilities.Facility = enforceArray<APIRMTF_SimpleIDName>(testObj.Filter.Facilities.Facility);

        }

        return validShape;
    }

    ////////////////////////////
    ////  TAB LOGIC
    ////////////////////////////
    public getTabs(): any {
        var tabs = [];

        var qs = { label: 'Quick Start', domID: 'remitfilter-tab-qs' };
        var adv = { label: 'Advanced', domID: 'remitfilter-tab-adv' };

        if (this.state.hideQs == true) {
            tabs.push(adv);
        } else {
            tabs.push(qs);
            tabs.push(adv);
        }

        return tabs;
    }
    public onShowQuickStart() {
        this.setState({ hideQs: false, activeTab: 0 });
    }

    public getInitialTab(): number {
        //uicl tabs don't seem to support disabling tabs or custom id/index, 
        // creating this method in case there needs to be logic in determining the tab
        return this.state.activeTab;
    }

    public onClearRemitFilterErrorHandler() {
        this.props.history.push("/LandingPage");
    }

    public onDisplayRemitFilterError(msg: string) {
        this.props.action.ui.displayErrorMessage({ api: this.props.dataStore.api.data, uiData: { value: msg } });
    }

    public errorCallback_generic(dataStatus: string): boolean {
        var str = 'An error occurred processing call for ' + this.props.title + ".  Error: " + dataStatus
        console.error(str);
        this.onDisplayRemitFilterError(str);

        return true;
    }

    public setFilterSelection(id: number, name: string) {

        this.props.action.ui.selectFilterSelection(
            {
                api: this.props.dataStore.api.data,
                uiData: { id: id, value: name }
            });
    }

    public apiDataAvailable(): boolean {
        if (this.props.dataStore.api.data &&
            this.props.dataStore.api.data.ListPopulation)
            return true;
        return false;
    }

    public getLookupList_SelectedItem(lookupList: APIRMTF_SimpleList[] | null, idToFind: string | undefined, emptyItem?: any): any {
        if (!this.apiDataAvailable() || !lookupList || lookupList.length === 0 || idToFind === undefined)
            return { value: '', label: '' };

        let item = lookupList.find(
            ob => ob['ID'] === idToFind);
        let result = { value: '', label: '' };
        if (item) {
            result.label = item['Name'];
            result.value = item['ID'];
        } else if (emptyItem) {
            result = emptyItem;
        }
        /*        else {
                    result.label = lookupList[0].Name;
                    result.value = lookupList[0].ID;
                }
        */
        return result;
    }


    public getLookupList_SelectedItem_DisplayOrder() {

        if (this.state.disableDisplayOrder == true) {  // direct hardcoded to , and disabled
            return this.getLookupList_SelectedItem(this.props.dataStore.api.data?.ListPopulation?.DisplayOrderList,
                "0");
        }

        return this.getLookupList_SelectedItem(this.props.dataStore.api.data?.ListPopulation?.DisplayOrderList,
            this.getData(this.props.dataStore.ui.filter.Filter.DisplayOrder, "@ID") == "" ? "1" : this.getData(this.props.dataStore.ui.filter.Filter.DisplayOrder, "@ID"));
    }


    public getLookupListIntialItem(lookupList: APIRMTF_SimpleList[] | null, initialItem: string[] | null) {
        if (!this.apiDataAvailable() || !lookupList)
            return [];

        let result: any[] = [];

        if (initialItem) {
            result.push({ label: initialItem[0], value: initialItem[1] })
        }

        lookupList.forEach(ob => {
            if (ob['Name'])
                result.push({ label: ob['Name'], value: ob['ID'] });
        });

        return result;
    }

    public getLookupList(lookupList: APIRMTF_SimpleList[] | null) {
        if (!this.apiDataAvailable() || !lookupList || lookupList.length === 0)
            return [];

        let result: any[] = [];

        result = this.getLookupListIntialItem(lookupList, null);

        return result;
    }

    public onDisplayOrderChange(e: any | undefined, id: string = '') {
        var result = { '@ID': id.length > 0 ? id : (e && e.value ? e.value : '0') };

        this.props.action.ui.selectDisplayOrder(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    selected: result,
                }
            });
    }


    public setLoaded(val: boolean) {
        this.props.action.ui.setLoaded(
            {
                api: this.props.dataStore.api.data,
                uiData: { index: '0', data: val }
            });
    }
    public setActiveTab(index: number) {
        this.setState({ activeTab: index });
        if (index !== 0) {
            this.setState({ filterIdQs: -1, filterNameQs: '' });
        }
    }

    public onTabSelect(index: number) {

        if (index !== 0) {
            this.setPostAction(POSTACTION_LOADFILTER);
        }

        this.setActiveTab(index);
    }

    public errorCallback(dataStatus: string): boolean {
        var str = 'An error occurred processing call for ' + this.props.title + ".  Error: " + dataStatus
        console.error(str);
        this.onDisplayRemitFilterError(str);

        return true;
    }

    public validationCallback(data: APIRMTF_ListPopulationContainer): boolean {
        let dataCopy: APIRMTF_ListPopulationContainer = data as APIRMTF_ListPopulationContainer;
        if (!dataCopy?.ListPopulation?.FilterList?.Filter.filter)  // does the filter function exist
            return true;

        dataCopy.ListPopulation.FilterList.Filter = dataCopy.ListPopulation.FilterList.Filter.filter((filter) => {
            return true;
        });

        return true;
    }

    public onLoadFilterList(data: APIRMTF_ListPopulationContainer) {
        if (this.validationCallback(data)) {

            this.props.action.ui.reloadFilterList({
                api: this.props.dataStore.api.data2,
                uiData: { data }
            });
        } else {
            console.error('onLoadFilter invalid data returned from .');
            this.onAlert('A problem occurred during filter save.');
        }

    }

    public async saveFilterCall(syncNetworkClients: boolean) {

        this.setBusy(true);

        var xmlData: APIXMLCB_XmlCallBackModel = {};

        xmlData.FilterId = this.saveFilterId;
        //xmlData.GroupId = '';  // to be used by report filter...someday
        xmlData.Action = "FilterList"
        xmlData.TestMode = this.props.testMode;
        xmlData.Type = "Remit";  //  (Claim|Report|Remit)
        xmlData.Process = this.props.process;
        xmlData.FilterJsonData = this.getFilterData();
        xmlData.FilterName = this.saveFilterId && this.saveFilterId > 0 ? '' : this.saveFilterName;
        xmlData.SyncNetworkedClients = syncNetworkClients;

        if (syncNetworkClients) {
            xmlData.SyncFilterName = this.saveFilterName;
            xmlData.Name = this.saveFilterName;
        }

        await fetch(URLs.api + '/api/data/filterList',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error(response.statusText);
                }
            }).then((data) => {
                // console.log(data);  //  {returnCode: 0, errorDescription: "", filterId: 0, filterData:"{ListPopulation:{FilterList:{@Current:"0", @FilterType: "S", @FilterAssigned:"0"}}}}

                if (data.returnCode !== 0) {
                    console.error(data.errorDescription + '(' + data.returnCode + ')  on filter ' + xmlData.FilterJsonData);
                    this.errorCallback('Unable to save filter. ' + data.errorDescription);
                } else {
                    this.onLoadFilterList(data.filterData);  //  todo this uses the wrong interface (CF) ...change to a RF one?
                }

            })
            .catch(error => {
                console.error('RemitFilter: ' + error);
                this.errorCallback('Unable to save filter.');
            })
            .finally(() => {
                this.setBusy(false);
            });

    }

    public onConfirmFilterList(filterListSelection: IModalFilterListSelection) {
        this.props.action.ui.closeFilterList();
        this.setFilterSelection(filterListSelection.id ?? 0, filterListSelection.name);
        this.saveFilterName = filterListSelection.name;
        this.saveFilterId = filterListSelection.id ?? 0;

        if (this.props.ncsIsEnabled === true) {
            var ncsQuestion =
                "Do you want to add or update the filter for all enterprise sites? Unique fields like payer list or facility list are ignored.";
            this.props.action.ui.sendSaveFilterNCSAlert({
                api: this.props.dataStore.api.data,
                uiData: { value: ncsQuestion }
            });
        } else {
            this.saveFilterCall(false);
        }

    }

    public onSelectSavedFilter(e: React.ChangeEvent<HTMLSelectElement>) {
        if (e.target.options && e.target.options.length > 0 &&
            e.target.options[e.target.options.selectedIndex] !== null &&
            e.target.options[e.target.options.selectedIndex].text &&
            e.target.options.selectedIndex !== -1) {

            var filterId: number = Number(e.target.value);
            if (!isNaN(filterId))
                this.setState({ filterIdQs: filterId, filterNameQs: e.target.options[e.target.options.selectedIndex].textContent ?? "" })
        }
    }


    // "Save~But_Save~~SaveFilter~"
    public saveFilter() {
        this.setActiveTab(1);
        this.saveFilterName = '';
        this.saveFilterId = undefined;

        if (this.validateFilter()) {
            this.props.action.ui.openFilterList();
        }
    }

    public onClearNCS(e: any) {
        this.props.action.ui.sendSaveFilterNCSAlert({ api: this.props.dataStore.api.data, uiData: { value: '' } });

        if (e.target.title != 'close') { // uicl's default close button title...
            this.saveFilterCall(false);
        }
    }

    public onConfirmNCS(e: any) {
        this.props.action.ui.sendSaveFilterNCSAlert({ api: this.props.dataStore.api.data, uiData: { value: '' } });
        this.saveFilterCall(true);
    }

    public performDialogAction(val: number) {
        this.onChangeQuickStart(val);

        var isRefine = this.process_lowercase === "match";
        if (isRefine) {
            this.onRefineClick(this.onRefine.bind(this))
        } else {
            this.onSubmitClick(this.submitFilter.bind(this))
        }

    }

    public onClearLoadedFilterErrorMessage(e: any) {
        this.props.action.ui.clearLoadedFilterErrorMessage({ uiData: { value: null } });
        this.saveFilterCall(true);
    }




    public onCloseFilterList() {
        ///        var x = this.getRemitFilterList(false); // resetting the selected state   did this do anything?


        this.props.action.ui.closeFilterList();
    }



    public applyFocus(fld: string) {
        // FYI - can't get Input (ui-core) to handle ref properly (ref.current always null), going at the dom
        var x = document.getElementById(fld);
        if (x) x.focus();
    }
    // NOTE this did not work as expected because subsequent re-renders were losing the focus.  If this is going to be
    // required functionality will need to keep resetting on a lifecycle event (componentdidupdate?)
    public setFocus(fld: string) {
        this.setState({ focusField: fld });
        this.applyFocus(fld);
    }

    // grabbed from old code
    public isSingleEntry(val: string) {
        return (val.length >= 5 && val.indexOf("-") == -1 && val.indexOf('"') == -1) ||
            (val.length >= 7 && val.charAt(0) == '"' && val.charAt(val.length - 1) == '"');
    }

    public validateDateRangeLessThanOneYear(fromDate: Date, toDate: Date) {
        if (fromDate == null || toDate == null)
            return false;

        if (fromDate.valueOf() <= 0 || toDate.valueOf() <= 0)
            return false;

        var maxDayRange = 366; // one year
        var oneDay = 1000 * 60 * 60 * 24;
        var dateDiffFrom = Math.abs(fromDate.getTime() - toDate.getTime());
        var dayDiffFrom = Math.round(dateDiffFrom / oneDay);
        if (dayDiffFrom > maxDayRange)
            return false;

        return true;
    }


    public validateDateField(data: any, updateMethod: any, focusFldPrefix: string): any {
        var fromVal = this.getData(data, "@From");
        var toVal = this.getData(data, "@To");
        var isLessThanOneYear = false;

        if (fromVal == "") {
            if (toVal == "") {
                // hmm
            } else {
                data['@From'] = toVal;
                updateMethod(toVal, true);
                fromVal = toVal;
            }
        } else {
            if (toVal == "") {
                data['@To'] = fromVal;
                updateMethod(fromVal, false);
                toVal = fromVal;
            }
        }

        if (fromVal != "") {
            var result = this.getDateFromControl(fromVal);
            if (result == undefined) {
                this.onAlert("Please enter a valid date.");
                this.setFocus(focusFldPrefix + 'from');
                return { result: false, isLessThanOneYear: false };
            } else {
                var day = ('0' + result.getDate()).slice(-2);
                var month = ('0' + (result.getMonth() + 1)).slice(-2);
                var year = result.getFullYear();
                var newDate = month + '/' + day + '/' + year;
                if (fromVal.indexOf("0000") == -1 && fromVal != newDate) {
                    data['@From'] = newDate;
                    updateMethod(newDate, true);
                    fromVal = newDate;
                }
            }
        }

        if (toVal != "") {
            var result = this.getDateFromControl(toVal);
            if (result == undefined) {
                this.onAlert("Please enter a valid date.");
                this.setFocus(focusFldPrefix + 'to');
                return { result: false, isLessThanOneYear: false };
            } else {
                var day = ('0' + result.getDate()).slice(-2);
                var month = ('0' + (result.getMonth() + 1)).slice(-2);
                var year = result.getFullYear();
                var newDate = month + '/' + day + '/' + year;
                if (toVal.indexOf("0000") == -1 && toVal != newDate) {
                    data['@To'] = newDate;
                    updateMethod(newDate, false);
                    toVal = newDate;
                }
            }
        }


        try {
            if (fromVal != "" && toVal != "") {
                var fromDate = this.getDateFromControl(fromVal);
                var toDate = this.getDateFromControl(toVal);

                if (fromDate != undefined && toDate != undefined && fromDate.valueOf() > 0 && toDate.valueOf() > 0 && fromDate > toDate) {
                    this.onAlert("Please check date validity.");
                    this.setFocus(focusFldPrefix + 'to');
                    return { result: false, isLessThanOneYear: false };
                }
                if (fromDate != undefined && toDate != undefined) {
                    isLessThanOneYear = this.validateDateRangeLessThanOneYear(fromDate, toDate);
                }
            }
        }
        catch (e) {

        }

        return { result: true, isLessThanOneYear: isLessThanOneYear };
    }
    public isFieldValid(data: string, dataType: string, focusField: string) {
        var typeMsg = IsValidType(data, dataType);
        if (typeMsg) {
            this.onAlert(typeMsg);
            if (focusField) this.setFocus(focusField);
            return false;
        }
        return true;
    }

    public isFieldValid_applyRegex(data: string, type: string): string {
        var newData = data;
        if (type == "alpha") {
            newData = newData.replace(/[^\w '\-@,"!:]/g, "");
        } else if (type == "numeric") {
            newData = newData.replace(/[^\d\.\-,!:]/g, "");
        } else {
            newData = newData.replace(/[^\w '\.\-/@,"!:#*]/g, "");
        }

        if (newData != data)
            return newData;

        return "DO_NOT_UPDATE_RF";
    }

    public validateFiscalPeriod(data: any, updateMethod: any, focusFldPrefix: string): boolean {
        var fromVal = this.getData(data, "@From");
        var toVal = this.getData(data, "@To");

        if (fromVal != "") {
            if (!fromVal.match(/^[0-9][0-9]\/[0-9][0-9]$/)) {
                this.onAlert("Fiscal Period must be in the format NN/NN.");
                this.setFocus(focusFldPrefix + 'from');
                return false;
            }
        }

        if (toVal != "") {
            if (!toVal.match(/^[0-9][0-9]\/[0-9][0-9]$/)) {
                this.onAlert("Fiscal Period must be in the format NN/NN.");
                this.setFocus(focusFldPrefix + 'to');
                return false;
            }

        }

        if (fromVal == "") {
            if (toVal == "") {
                return true;
            } else {
                data['@From'] = toVal;
                updateMethod(toVal, true);
                fromVal = toVal;
            }
        } else {
            if (toVal == "") {
                data['@To'] = fromVal;
                updateMethod(fromVal, false);
                toVal = fromVal;
            }
        }


        return true;;
    }



    public validateFilter(): boolean {
        switch (this.state.activeTab) {
            // QuickStart
            case 0: {
                switch (this.state.quickStart) {  // == "3" 
                    case 0: // LAST_REMIT_LIST
                        {
                            // this logic moved to getFilterData()
                            break;
                        }

                    case 1: // LAST_FILTER
                        {
                            // this logic moved to getFilterData() 
                            break;
                        }

                    case 2:
                        {
                            // QUICK START
                            var checkNumber = this.getData(this.props.dataStore.ui.filter.Filter?.CheckNumber, "@Value");
                            if (!this.isFieldValid(checkNumber, "alphanumeric", 'remitfilter_qs_checknumber'))
                                return false;

                            var fieldRegex = "DO_NOT_UPDATE_RF";  // old code IsValidType also replaced the value after a regex, doing that here
                            fieldRegex = this.isFieldValid_applyRegex(checkNumber, "alphanumeric");
                            if (fieldRegex !== "DO_NOT_UPDATE_RF") { // data changed
                                checkNumber = fieldRegex;
                                this.onCheckNumberChange(checkNumber);
                            }

                            var importDates = this.props.dataStore.ui.filter.Filter?.ImportDate;
                            var validateImportDate = this.validateDateField(importDates, this.onImportDateChange.bind(this), 'remitfilter_qs_import');
                            if (!validateImportDate.result) {
                                return false;
                            }

                            var pmtDates = this.props.dataStore.ui.filter.Filter?.PmtDate;
                            var validatePmtDate = this.validateDateField(pmtDates, this.onPmtDateChange.bind(this), 'remitfilter_qs_pmt');
                            if (!validatePmtDate.result) {
                                return false;
                            }

                            if (!this.isSingleEntry(checkNumber) && !validateImportDate.isLessThanOneYear && !validatePmtDate.isLessThanOneYear) {
                                this.onAlert("Please enter a check number of at least five characters without dashes, or enter an Import Date range or Payment Date range of less than one year.");
                                this.setFocus('remitfilter_qs_importfrom');
                                return false;
                            }


                            break;
                        }

                }
            }
                break; // exiting quickstart
            case 1:
                {
                    // ADVANCED TAB
                    var apcCode = this.getData(this.props.dataStore.ui.filter.Filter?.APCCode, "@Value");
                    if (!this.isFieldValid(apcCode, "alphanumeric", 'remitfilter_adv_apccode'))
                        return false;

                    var fieldRegex = "DO_NOT_UPDATE_RF";  // old code IsValidType also replaced the value after a regex, doing that here
                    fieldRegex = this.isFieldValid_applyRegex(apcCode, "alphanumeric");
                    if (fieldRegex !== "DO_NOT_UPDATE_RF") { // data changed
                        apcCode = fieldRegex;
                        this.onApcCodeChange(apcCode);
                    }


                    var checkNumber = this.getData(this.props.dataStore.ui.filter.Filter?.CheckNumber, "@Value");
                    if (!this.isFieldValid(checkNumber, "alphanumeric", 'remitfilter_adv_checknumber'))
                        return false;

                    var fieldRegex = "DO_NOT_UPDATE_RF";  // old code IsValidType also replaced the value after a regex, doing that here
                    fieldRegex = this.isFieldValid_applyRegex(checkNumber, "alphanumeric");
                    if (fieldRegex !== "DO_NOT_UPDATE_RF") { // data changed
                        checkNumber = fieldRegex;
                        this.onCheckNumberChange(checkNumber);
                    }



                    let fileName = this.getData(this.props.dataStore.ui.filter.Filter?.FileName, "@Value");
                    if (!this.isFieldValid(fileName, "alphanumeric", 'remitfilter_adv_filename'))
                        return false;

                    var fieldRegex = "DO_NOT_UPDATE_RF";  // old code IsValidType also replaced the value after a regex, doing that here
                    fieldRegex = this.isFieldValid_applyRegex(fileName, "alphanumeric");
                    if (fieldRegex !== "DO_NOT_UPDATE_RF") { // data changed
                        fileName = fieldRegex;
                        this.onFileNameChange(fileName);
                    }

                    if (fileName != "") {
                        if (fileName.length > 4) {
                            const ext = fileName.substring(fileName.length - 4);
                            if (ext != ".835" && ext.toLowerCase() != ".rtf") {
                                this.onAlert("Remit file name must have a valid extension of .835 or .RTF");
                                this.setFocus('remitfilter_adv_filename');
                                return false;
                            }
                        }
                        else {
                            alert("Please enter a valid file name to search");
                            this.onAlert('remitfilter_adv_filename');
                            return false;
                        }
                    }

                    var contractualAdj = this.getData(this.props.dataStore.ui.filter.Filter?.ContractualAdj, "@Value");
                    if (!this.isFieldValid(contractualAdj, "numeric", 'remitfilter_adv_contractualadj'))
                        return false;

                    var fieldRegex = "DO_NOT_UPDATE_RF";  // old code IsValidType also replaced the value after a regex, doing that here
                    fieldRegex = this.isFieldValid_applyRegex(contractualAdj, "numeric");
                    if (fieldRegex !== "DO_NOT_UPDATE_RF") { // data changed
                        contractualAdj = fieldRegex;
                        this.onContractualAdjChange(contractualAdj);
                    }

                    var patientControlNumber = this.getData(this.props.dataStore.ui.filter.Filter?.PatientControlNumber, "@Value");
                    if (!this.isFieldValid(patientControlNumber, "alphanumeric", 'remitfilter_adv_patientcontrolnumber'))
                        return false;

                    var fieldRegex = "DO_NOT_UPDATE_RF";  // old code IsValidType also replaced the value after a regex, doing that here
                    fieldRegex = this.isFieldValid_applyRegex(patientControlNumber, "alphanumeric");
                    if (fieldRegex !== "DO_NOT_UPDATE_RF") { // data changed
                        patientControlNumber = fieldRegex;
                        this.onPatientControlNumberChange(patientControlNumber);
                    }

                    var lastName = this.getData(this.props.dataStore.ui.filter.Filter?.LastName, "@Value");
                    if (!this.isFieldValid(lastName, "alpha", 'remitfilter_adv_lastname'))
                        return false;

                    var fieldRegex = "DO_NOT_UPDATE_RF";  // old code IsValidType also replaced the value after a regex, doing that here
                    fieldRegex = this.isFieldValid_applyRegex(lastName, "alpha");
                    if (fieldRegex !== "DO_NOT_UPDATE_RF") { // data changed
                        lastName = fieldRegex;
                        this.onLastNameChange(lastName);
                    }

                    var paymentAmt = this.getData(this.props.dataStore.ui.filter.Filter?.PaymentAmt, "@Value");
                    if (!this.isFieldValid(paymentAmt, "numeric", 'remitfilter_adv_paymentamt'))
                        return false;

                    var fieldRegex = "DO_NOT_UPDATE_RF";  // old code IsValidType also replaced the value after a regex, doing that here
                    fieldRegex = this.isFieldValid_applyRegex(paymentAmt, "numeric");
                    if (fieldRegex !== "DO_NOT_UPDATE_RF") { // data changed
                        paymentAmt = fieldRegex;
                        this.onPaymentAmtChange(paymentAmt);
                    }

                    var checkAmt = this.getData(this.props.dataStore.ui.filter.Filter?.CheckAmt, "@Value");
                    if (!this.isFieldValid(checkAmt, "numeric", 'remitfilter_adv_checkamt'))
                        return false;

                    var fieldRegex = "DO_NOT_UPDATE_RF";  // old code IsValidType also replaced the value after a regex, doing that here
                    fieldRegex = this.isFieldValid_applyRegex(checkAmt, "numeric");
                    if (fieldRegex !== "DO_NOT_UPDATE_RF") { // data changed
                        checkAmt = fieldRegex;
                        this.onCheckAmtChange(checkAmt);
                    }


                    var statVal = this.getData(this.props.dataStore.ui.filter.Filter?.Status, "@Value");
                    if (!this.isFieldValid(statVal, "alphanumeric", 'remitfilter_adv_status'))
                        return false;

                    var fieldRegex = "DO_NOT_UPDATE_RF";  // old code IsValidType also replaced the value after a regex, doing that here
                    fieldRegex = this.isFieldValid_applyRegex(statVal, "alphanumeric");
                    if (fieldRegex !== "DO_NOT_UPDATE_RF") { // data changed
                        statVal = fieldRegex;
                        this.onStatusChange(statVal);
                    }

                    var amtBilled = this.getData(this.props.dataStore.ui.filter.Filter?.AmtBilled, "@Value");
                    if (!this.isFieldValid(amtBilled, "numeric", 'remitfilter_adv_amtbilled'))
                        return false;

                    var fieldRegex = "DO_NOT_UPDATE_RF";  // old code IsValidType also replaced the value after a regex, doing that here
                    fieldRegex = this.isFieldValid_applyRegex(amtBilled, "numeric");
                    if (fieldRegex !== "DO_NOT_UPDATE_RF") { // data changed
                        amtBilled = fieldRegex;
                        this.onAmtBilledChange(amtBilled);
                    }


                    var remarkCode = this.getData(this.props.dataStore.ui.filter.Filter?.RemarkCode, "@Value");
                    var remarkCodeId = this.getData(this.props.dataStore.ui.filter.Filter?.RemarkCode, "@ID");
                    if (!this.isFieldValid(remarkCode, "alphanumeric", 'remitfilter_adv_remarkcode'))
                        return false;

                    var fieldRegex = "DO_NOT_UPDATE_RF";  // old code IsValidType also replaced the value after a regex, doing that here
                    fieldRegex = this.isFieldValid_applyRegex(remarkCode, "alphanumeric");
                    if (fieldRegex !== "DO_NOT_UPDATE_RF") { // data changed
                        remarkCode = fieldRegex;
                        this.onRemarkCodeChange(remarkCode);
                    }

                    if (remarkCodeId != "") {
                        if (remarkCode == "") {
                            this.onAlert("Please enter a remark code.");
                            this.setFocus('remitfilter_adv_remarkcode');
                            return false;
                        }
                    } else {
                        if (remarkCode != "")
                            this.onRemarkCodeChange("");
                    }

                    var adjustmentCode = this.getData(this.props.dataStore.ui.filter.Filter?.AdjustmentCode, "@Value");
                    var adjustmentCodeId = this.getData(this.props.dataStore.ui.filter.Filter?.AdjustmentCode, "@ID");
                    if (!this.isFieldValid(adjustmentCode, "alphanumeric", 'remitfilter_adv_adjustmentcode'))
                        return false;

                    var fieldRegex = "DO_NOT_UPDATE_RF";  // old code IsValidType also replaced the value after a regex, doing that here
                    fieldRegex = this.isFieldValid_applyRegex(adjustmentCode, "alphanumeric");
                    if (fieldRegex !== "DO_NOT_UPDATE_RF") { // data changed
                        adjustmentCode = fieldRegex;
                        this.onAdjustmentCodeChange(adjustmentCode);
                    }

                    if (adjustmentCodeId != "") {
                        if (adjustmentCode == "") {
                            this.onAlert("Please enter an adjustment code.");
                            this.setFocus('remitfilter_adv_adjustmentcode');
                            return false;
                        }
                    } else {
                        if (adjustmentCode != "")
                            this.onAdjustmentCodeChange("");
                    }


                    var groupCode = this.getData(this.props.dataStore.ui.filter.Filter?.GroupCode, "@Value");
                    var groupCodeId = this.getData(this.props.dataStore.ui.filter.Filter?.GroupCode, "@ID");
                    if (!this.isFieldValid(groupCode, "alphanumeric", 'remitfilter_adv_groupcode'))
                        return false;

                    var fieldRegex = "DO_NOT_UPDATE_RF";  // old code IsValidType also replaced the value after a regex, doing that here
                    fieldRegex = this.isFieldValid_applyRegex(groupCode, "alphanumeric");
                    if (fieldRegex !== "DO_NOT_UPDATE_RF") { // data changed
                        groupCode = fieldRegex;
                        this.onGroupCodeChange(groupCode);
                    }

                    if (groupCodeId != "") {
                        if (groupCode == "") {
                            this.onAlert("Please enter a group code.");
                            this.setFocus('remitfilter_adv_groupcode');
                            return false;
                        }
                    } else {
                        if (groupCode != "")
                            this.onGroupCodeChange("");
                    }

                    var fiscalPer = this.props.dataStore.ui.filter.Filter?.FiscalPeriod;
                    if (!this.validateFiscalPeriod(fiscalPer, this.onFiscalPeriodChange.bind(this), 'remitfilter_adv_fiscalperiod')) {
                        return false;
                    }

                    var importDates = this.props.dataStore.ui.filter.Filter?.ImportDate;
                    var validateImportDate = this.validateDateField(importDates, this.onImportDateChange.bind(this), 'remitfilter_adv_import');
                    if (!validateImportDate.result) {
                        return false;
                    }

                    var pmtDates = this.props.dataStore.ui.filter.Filter?.PmtDate;
                    var validatePmtDate = this.validateDateField(pmtDates, this.onPmtDateChange.bind(this), 'remitfilter_adv_pmt');
                    if (!validatePmtDate.result) {
                        return false;
                    }

                    const secBillDate = this.props.dataStore.ui.filter.Filter?.SecBill;
                    const validateSecBillDate = this.validateDateField(secBillDate, this.onSecBillChange.bind(this), 'remitfilter_adv_secbill_');
                    if (!validateSecBillDate.result) {
                        return false;
                    }

                    const serviceFromDate = this.props.dataStore.ui.filter.Filter?.ServiceFrom;
                    const validateServiceFromDate = this.validateDateField(serviceFromDate, this.onServiceFromChange.bind(this), 'remitfilter_adv_servicefrom_');
                    if (!validateServiceFromDate.result) {
                        return false;
                    }

                    const serviceThruDate = this.props.dataStore.ui.filter.Filter?.ServiceThru;
                    const validateServiceThruDate = this.validateDateField(serviceThruDate, this.onServiceThruChange.bind(this), 'remitfilter_adv_servicethru_');
                    if (!validateServiceThruDate.result) {
                        return false;
                    }

                    if (!this.isSingleEntry(checkNumber) && !this.isSingleEntry(patientControlNumber)
                        && !validateServiceFromDate.isLessThanOneYear
                        && !validateServiceThruDate.isLessThanOneYear
                        && !validateSecBillDate.isLessThanOneYear
                        && !validateImportDate.isLessThanOneYear
                        && !validatePmtDate.isLessThanOneYear) {
                        this.onAlert("Please enter a check number or patient control number of at least five characters without dashes, or enter a date range of one year or less for any date field except Fiscal Period.");
                        this.setFocus('remitfilter_adv_importfrom');
                        return false;
                    }

                    if (this.process_lowercase.indexOf("postingfile") > -1) {
                        var maxRangeDays = Number(this.getConfigValue("RemitPostingFile_MaxRangeDays"));
                        if (isNaN(maxRangeDays))
                            maxRangeDays = 92;

                        if (!this.validateDateField_DoesNotExceed(this.props.dataStore.ui.filter.Filter?.ImportDate,
                            'remitfilter_adv_import', maxRangeDays)) {
                            return false;
                        }

                        if (!this.validateDateField_DoesNotExceed(this.props.dataStore.ui.filter.Filter?.PmtDate,
                            'remitfilter_adv_pmt', maxRangeDays)) {
                            return false;
                        }

                    }

                }
                break;
        }

        return true;
    }

    public validateDateField_DoesNotExceed(data: any, focusFldPrefix: string, maxDateRange: number): boolean {
        var fromVal = this.getData(data, "@From");
        var toVal = this.getData(data, "@To");

        if (fromVal == "" || toVal == "" || (maxDateRange < 0)) {
            return true;
        }

        try {

            var fromDate = this.getDateFromControl(fromVal);
            var toDate = this.getDateFromControl(toVal);

            if (fromDate && fromDate.valueOf() > 0 && !isNaN(fromDate.valueOf()) &&
                toDate && toDate.valueOf() > 0 && !isNaN(toDate.valueOf())) {

                if (maxDateRange >= 0) {
                    var oneDay = 1000 * 60 * 60 * 24;

                    var dateDiff = Math.abs(toDate.getTime() - fromDate.getTime());
                    var dayDiff = Math.round(dateDiff / oneDay);

                    if (dayDiff > maxDateRange) {
                        this.onAlert("Date Range cannot exceed " + maxDateRange + " days.");
                        this.setFocus(focusFldPrefix + 'to');
                        return false;
                    }
                }

            } else {
                this.onAlert("Please check date validity.");
            }
        }
        catch (e) {
            //  does this mean the date is invalid and i need to error it?
        }

        return true;
    }

    // based on how we implemented the store, i'll need to clean up unused elements 
    public getFilterData(): string {

        if (this.state.activeTab === 0 && this.state.quickStart == 0) {
            return "";  // special case from validate filter
        }


        var data: any = this.props.dataStore.ui.filter;

        data.Filter['@Type'] = "Remit";
        data.Filter['@ID'] = this.props.dataStore.ui.filterId ?? "0";


        //DisplayOrder: { '@ID': '1' },
        if (data.Filter.DisplayOrder) {
            if (this.getData(data.Filter.DisplayOrder, '@ID').length == 0 || this.getData(data.Filter.DisplayOrder, '@ID') == "0")
                delete data.Filter['DisplayOrder'];
        }

        if ((data.Filter['@ID'] == "0" || data.Filter['@ID'] == "0"))
            delete data.Filter['@Name'];

        if (!('@ID' in data.Filter) || data.Filter['@ID'] == "")
            data.Filter['@ID'] = "0";

        if (data.Filter.ImportDate) {
            if (this.getData(data.Filter.ImportDate, '@From').length == 0)
                delete data.Filter.ImportDate['@From'];
            if (this.getData(data.Filter.ImportDate, '@To').length == 0)
                delete data.Filter.ImportDate['@To'];

            if (Object.keys(data.Filter.ImportDate).length == 0)
                delete data.Filter['ImportDate'];
        }

        if (data.Filter.PmtDate) {
            if (this.getData(data.Filter.PmtDate, '@From').length == 0)
                delete data.Filter.PmtDate['@From'];
            if (this.getData(data.Filter.PmtDate, '@To').length == 0)
                delete data.Filter.PmtDate['@To'];

            if (Object.keys(data.Filter.PmtDate).length == 0)
                delete data.Filter['PmtDate'];
        }

        if (data.Filter.SecBill) {
            if (this.getData(data.Filter.SecBill, '@From').length == 0)
                delete data.Filter.SecBill['@From'];
            if (this.getData(data.Filter.SecBill, '@To').length == 0)
                delete data.Filter.SecBill['@To'];

            if (Object.keys(data.Filter.SecBill).length == 0)
                delete data.Filter['SecBill'];
        }

        if (data.Filter.ServiceFrom) {
            if (this.getData(data.Filter.ServiceFrom, '@From').length == 0)
                delete data.Filter.ServiceFrom['@From'];
            if (this.getData(data.Filter.ServiceFrom, '@To').length == 0)
                delete data.Filter.ServiceFrom['@To'];

            if (Object.keys(data.Filter.ServiceFrom).length == 0)
                delete data.Filter['ServiceFrom'];
        }

        if (data.Filter.ServiceThru) {
            if (this.getData(data.Filter.ServiceThru, '@From').length == 0)
                delete data.Filter.ServiceThru['@From'];
            if (this.getData(data.Filter.ServiceThru, '@To').length == 0)
                delete data.Filter.ServiceThru['@To'];

            if (Object.keys(data.Filter.ServiceThru).length == 0)
                delete data.Filter['ServiceThru'];
        }


        //CheckNumber: { '@Value': '' },
        if (data.Filter.CheckNumber) {
            if (this.getData(data.Filter.CheckNumber, '@Value').length == 0)
                delete data.Filter['CheckNumber'];
        }

        //FileName: { '@Value': '' },
        if (data.Filter.FileName) {
            if (this.getData(data.Filter.FileName, '@Value').length == 0)
                delete data.Filter['FileName'];
        }

        if (data.Filter.RemitState) {
            if (this.getData(data.Filter.RemitState, '@Matched').length == 0
                && this.getData(data.Filter.RemitState, '@Unmatched').length == 0
                && this.getData(data.Filter.RemitState, '@All').length == 0)
                delete data.Filter['RemitState'];
            else {
                if (this.getData(data.Filter.RemitState, '@Matched').length == 0)
                    delete data.Filter.RemitState['@Matched'];
                if (this.getData(data.Filter.RemitState, '@Unmatched').length == 0)
                    delete data.Filter.RemitState['@Unmatched'];
                if (this.getData(data.Filter.RemitState, '@All').length == 0)
                    delete data.Filter.RemitState['@All'];
            }
        }

        if (data.Filter.RemitStatus) {
            if (this.getData(data.Filter.RemitStatus, '@Paid').length == 0
                && this.getData(data.Filter.RemitStatus, '@Denied').length == 0
                && this.getData(data.Filter.RemitStatus, '@Pending').length == 0
                && this.getData(data.Filter.RemitStatus, '@Other').length == 0
                && this.getData(data.Filter.RemitStatus, '@All').length == 0)
                delete data.Filter['RemitStatus'];
            else {
                if (this.getData(data.Filter.RemitStatus, '@Paid').length == 0)
                    delete data.Filter.RemitStatus['@Paid'];
                if (this.getData(data.Filter.RemitStatus, '@Denied').length == 0)
                    delete data.Filter.RemitStatus['@Denied'];
                if (this.getData(data.Filter.RemitStatus, '@Pending').length == 0)
                    delete data.Filter.RemitStatus['@Pending'];
                if (this.getData(data.Filter.RemitStatus, '@Other').length == 0)
                    delete data.Filter.RemitStatus['@Other'];
                if (this.getData(data.Filter.RemitStatus, '@All').length == 0)
                    delete data.Filter.RemitStatus['@All'];
            }
        }

        if (data.Filter.ServiceType) {
            if (this.getData(data.Filter.ServiceType, '@Inpatient').length == 0
                && this.getData(data.Filter.ServiceType, '@Outpatient').length == 0
                && this.getData(data.Filter.ServiceType, '@All').length == 0)
                delete data.Filter['ServiceType'];
            else {
                if (this.getData(data.Filter.ServiceType, '@Inpatient').length == 0)
                    delete data.Filter.ServiceType['@Inpatient'];
                if (this.getData(data.Filter.ServiceType, '@Outpatient').length == 0)
                    delete data.Filter.ServiceType['@Outpatient'];
                if (this.getData(data.Filter.ServiceType, '@All').length == 0)
                    delete data.Filter.ServiceType['@All'];
            }
        }

        if (data.Filter.Source) {
            if (this.getData(data.Filter.Source, '@Aii').length == 0
                && this.getData(data.Filter.Source, '@All').length == 0
                && this.getData(data.Filter.Source, '@RA').length == 0)
                delete data.Filter['Source'];
            else {
                if (this.getData(data.Filter.Source, '@Aii').length == 0)
                    delete data.Filter.Source['@Aii'];
                if (this.getData(data.Filter.Source, '@RA').length == 0)
                    delete data.Filter.Source['@RA'];
                if (this.getData(data.Filter.Source, '@All').length == 0)
                    delete data.Filter.Source['@All'];
            }
        }

        if (data.Filter.Candidates_N) {
            if (this.getData(data.Filter.Candidates_N, '@NameControl').length == 0)
                delete data.Filter['Candidates_N'];
        }

        if (data.Filter.Candidates_S) {
            if (this.getData(data.Filter.Candidates_S, '@Secondary').length == 0)
                delete data.Filter['Candidates_S'];
        }


        //APCCode: { '@Value': '' },
        if (data.Filter.APCCode) {
            if (this.state.activeTab === 0 || this.getData(data.Filter.APCCode, '@Value').length == 0)
                delete data.Filter['APCCode'];
        }

        //ContractualAdj: { '@Value': '' },
        if (data.Filter.ContractualAdj) {
            if (this.state.activeTab === 0 || this.getData(data.Filter.ContractualAdj, '@Value').length == 0)
                delete data.Filter['ContractualAdj'];
        }

        //PatientControlNumber: { '@Value': '' },
        if (data.Filter.PatientControlNumber) {
            if (this.getData(data.Filter.PatientControlNumber, '@Value').length == 0)
                delete data.Filter['PatientControlNumber'];
        }

        //LastName: { '@Value': '' },
        if (data.Filter.LastName) {
            if (this.getData(data.Filter.LastName, '@Value').length == 0)
                delete data.Filter['LastName'];
        }

        //PatientICNNumber: { '@Value': '' },
        if (data.Filter.PatientICNNumber) {
            if (this.getData(data.Filter.PatientICNNumber, '@Value').length == 0)
                delete data.Filter['PatientICNNumber'];
        }

        //PaymentAmt: { '@Value': '' },
        if (data.Filter.PaymentAmt) {
            if (this.getData(data.Filter.PaymentAmt, '@Value').length == 0)
                delete data.Filter['PaymentAmt'];
        }

        //CheckAmt: { '@Value': '' },
        if (data.Filter.CheckAmt) {
            if (this.getData(data.Filter.CheckAmt, '@Value').length == 0)
                delete data.Filter['CheckAmt'];
        }


        //RemitProviderNPI: { '@Value': '' },
        if (data.Filter.RemitProviderNPI) {
            if (this.getData(data.Filter.RemitProviderNPI, '@Value').length == 0)
                delete data.Filter['RemitProviderNPI'];
        }

        //RemitFederalTaxId: { '@Value': '' },
        if (data.Filter.RemitFederalTaxId) {
            if (this.getData(data.Filter.RemitFederalTaxId, '@Value').length == 0)
                delete data.Filter['RemitFederalTaxId'];
        }

        //RemarkCode: { '@ID': '', '@Value': '' },
        if (data.Filter.RemarkCode) {
            if (this.getData(data.Filter.RemarkCode, '@ID').length == 0 || this.getData(data.Filter.RemarkCode, '@Value').length == 0)
                delete data.Filter['RemarkCode'];
        }

        //Status: { '@Value': '' },
        if (data.Filter.Status) {
            if (this.getData(data.Filter.Status, '@Value').length == 0)
                delete data.Filter['Status'];
        }
        //AmtBilled: { '@Value': '' },
        if (data.Filter.AmtBilled) {
            if (this.getData(data.Filter.AmtBilled, '@Value').length == 0)
                delete data.Filter['AmtBilled'];
        }
        //RemitTypeOfBill: { '@Value': '' },
        if (data.Filter.RemitTypeOfBill) {
            if (this.getData(data.Filter.RemitTypeOfBill, '@Value').length == 0)
                delete data.Filter['RemitTypeOfBill'];
        }

        //AdjustmentCode: { '@ID': '', '@Value': '' },
        if (data.Filter.AdjustmentCode) {
            if (this.getData(data.Filter.AdjustmentCode, '@ID').length == 0 || this.getData(data.Filter.AdjustmentCode, '@Value').length == 0)
                delete data.Filter['AdjustmentCode'];
        }

        //GroupCode: { '@ID': '', '@Value': '' },
        if (data.Filter.GroupCode) {
            if (this.getData(data.Filter.GroupCode, '@ID').length == 0 || this.getData(data.Filter.GroupCode, '@Value').length == 0)
                delete data.Filter['GroupCode'];
        }


        //Payers: { Payer: [] },
        if (data.Filter.Payers) {
            if (data.Filter.Payers.Payer) {
                if (data.Filter.Payers.Payer.length == 0 ||
                    (data.Filter.Payers.Payer.length == 1 && this.getData(data.Filter.Payers.Payer[0], '@ID').length == 0)) {
                    delete data.Filter.Payers['Payer'];
                    delete data.Filter['Payers'];
                } else {
                    data.Filter.Payers.Payer.map(function (item: any) {
                        delete item["@Name"];
                        return item;
                    });
                }
            } else {
                delete data.Filter['Payers'];
            }
        }

        //LOBs: { LOB: [] },
        if (data.Filter.LOBs) {
            if (data.Filter.LOBs.LOB) {
                if (data.Filter.LOBs.LOB.length == 0 ||
                    (data.Filter.LOBs.LOB.length == 1 && this.getData(data.Filter.LOBs.LOB[0], '@ID').length == 0)) {
                    delete data.Filter.LOBs['LOB'];
                    delete data.Filter['LOBs'];
                } else {
                    data.Filter.LOBs.LOB.map(function (item: any) {
                        delete item["@Name"];
                        return item;
                    });
                }
            } else {
                delete data.Filter['LOBs'];
            }
        }

        //RemitLOBs: { RemitLOB: [] },
        if (data.Filter.RemitLOBs) {
            if (data.Filter.RemitLOBs.RemitLOB) {
                if (data.Filter.RemitLOBs.RemitLOB.length == 0 ||
                    (data.Filter.RemitLOBs.RemitLOB.length == 1 && this.getData(data.Filter.RemitLOBs.RemitLOB[0], '@ID').length == 0)) {
                    delete data.Filter.RemitLOBs['RemitLOB'];
                    delete data.Filter['RemitLOBs'];
                } else {
                    data.Filter.RemitLOBs.RemitLOB.map(function (item: any) {
                        delete item["@Name"];
                        return item;
                    });
                }
            } else {
                delete data.Filter['RemitLOBs'];
            }
        }

        //Providers: { Provider: [] },
        if (data.Filter.Providers) {
            if (data.Filter.Providers.Provider) {
                if (data.Filter.Providers.Provider.length == 0 ||
                    (data.Filter.Providers.Provider.length == 1 && this.getData(data.Filter.Providers.Provider[0], '@ID').length == 0)) {
                    delete data.Filter.Providers['Provider'];
                    delete data.Filter['Providers'];
                } else {
                    data.Filter.Providers.Provider.map(function (item: any) {
                        delete item["@Name"];
                        return item;
                    });
                }
            } else {
                delete data.Filter['Providers'];
            }
        }

        //ProviderNPIs: { ProviderNPI: [] },
        if (data.Filter.ProviderNPIs) {
            if (data.Filter.ProviderNPIs.ProviderNPI) {
                if (data.Filter.ProviderNPIs.ProviderNPI.length == 0 ||
                    (data.Filter.ProviderNPIs.ProviderNPI.length == 1 && this.getData(data.Filter.ProviderNPIs.ProviderNPI[0], '@ID').length == 0)) {
                    delete data.Filter.ProviderNPIs['ProviderNPI'];
                    delete data.Filter['ProviderNPIs'];
                } else {
                    data.Filter.ProviderNPIs.ProviderNPI.map(function (item: any) {
                        delete item["@Name"];
                        return item;
                    });
                }
            } else {
                delete data.Filter['ProviderNPIs'];
            }
        }

        // Facilities: { Facility: [] },
        if (data.Filter.Facilities) {
            if (data.Filter.Facilities.Facility) {
                if (data.Filter.Facilities.Facility.length == 0 ||
                    (data.Filter.Facilities.Facility.length == 1 && this.getData(data.Filter.Facilities.Facility[0], '@ID').length == 0)) {
                    delete data.Filter.Facilities['Facility'];
                    delete data.Filter['Facilities'];
                } else {
                    data.Filter.Facilities.Facility.map(function (item: any) {
                        delete item["@Name"];
                        return item;
                    });
                }
            } else {
                delete data.Filter['Facilities'];
            }
        }


        if (data.Filter.FiscalPeriod) {
            if (this.getData(data.Filter.FiscalPeriod, '@From').length == 0)
                delete data.Filter.FiscalPeriod['@From'];
            if (this.getData(data.Filter.FiscalPeriod, '@To').length == 0)
                delete data.Filter.FiscalPeriod['@To'];

            if (this.state.activeTab === 0 || Object.keys(data.Filter.FiscalPeriod).length == 0)
                delete data.Filter['FiscalPeriod'];
        }


        return JSON.stringify(data);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////
    // RENDER HELPERS 
    /////////////////////////////////////////////////////////////////////////////////////////////




    public getRemitFilterList(allowFav: boolean): IModalFilterListFilters {
        let allFilters: IModalFilterListFilters = { filterList: [] };

        let filters = this.props.dataStore.api.data2?.ListPopulation?.FilterList?.Filter;
        if (filters && filters.length > 0) {
            let rmtFilters: IModalFilterListOption[] = this.getRemitFilterSavedListItem(allowFav);

            if (rmtFilters.length > 0) {
                allFilters.filterList.push({ label: "- Select Remit Filter -", items: rmtFilters });
            }
        }

        return allFilters;

    }

    public getSelectedPayerRecords() {

        let records: any[] = [];
        let lookupListItems: any[] = [];

        records.push({ label: "- All Payers -", value: "" });
        lookupListItems = this.getLookupList(this.props.dataStore.api.data?.ListPopulation?.PayerList)

        if (lookupListItems.length > 0) {
            records.push.apply(records, lookupListItems);
        }

        return records;
    }

    public getSelectedRemitLOBRecords() {

        let records: any[] = [];
        let lookupListItems: any[] = [];

        records.push({ label: "- All LOB -", value: "" });
        lookupListItems = this.getLookupList(this.props.dataStore.api.data?.ListPopulation?.RemitLOBList)

        if (lookupListItems.length > 0) {
            records.push.apply(records, lookupListItems);
        }

        return records;
    }

    public getSelectedLOBRecords() {

        let records: any[] = [];
        let lookupListItems: any[] = [];

        records.push({ label: "- All LOBs -", value: "" });
        lookupListItems = this.getLookupList(this.props.dataStore.api.data?.ListPopulation?.LOBList)

        if (lookupListItems.length > 0) {
            records.push.apply(records, lookupListItems);
        }

        return records;
    }

    public getSelectedProviderNoRecords() {

        let records: any[] = [];
        let lookupListItems: any[] = [];

        records.push({ label: "All PROV NOs", value: "" });
        lookupListItems = this.getLookupList(this.props.dataStore.api.data?.ListPopulation?.ProviderList)

        if (lookupListItems.length > 0) {
            records.push.apply(records, lookupListItems);
        }

        return records;
    }

    public getSelectedProviderNPIRecords() {

        let records: any[] = [];
        let lookupListItems: any[] = [];

        records.push({ label: "All PROV NPIs", value: "" });
        lookupListItems = this.getLookupList(this.props.dataStore.api.data?.ListPopulation?.ProviderNPIList)

        if (lookupListItems.length > 0) {
            records.push.apply(records, lookupListItems);
        }
        return records;
    }

    public getSelectedFacilityRecords() {

        let records: any[] = [];
        let lookupListItems: any[] = [];

        records.push({ label: "- All Facilities -", value: "" });
        lookupListItems = this.getLookupList(this.props.dataStore.api.data?.ListPopulation?.FacilityList)

        if (lookupListItems.length > 0) {
            records.push.apply(records, lookupListItems);
        }

        return records;
    }

    public filterDataLoaded(): boolean {
        if (this.props.dataStore.ui.filter &&
            this.props.dataStore.ui.filter.Filter)
            return true;

        return false;
    }

    public getSelectedPayers(): string[] {
        let items: string[] = [];

        if (this.filterDataLoaded() &&
            this.props.dataStore.ui.filter.Filter.Payers &&
            this.props.dataStore.ui.filter.Filter.Payers.Payer) {
            this.props.dataStore.ui.filter.Filter.Payers.Payer.forEach(ob => {
                if (ob['@ID']?.length > 0) {
                    items.push(ob['@ID']);
                }
            });
        }

        if (items.length == 0) { items.push('') }
        return items;
    }

    public getSelectedRemitLOB(): string[] {
        let items: string[] = [];

        if (this.filterDataLoaded() &&
            this.props.dataStore.ui.filter.Filter.LOBs &&
            this.props.dataStore.ui.filter.Filter.LOBs.LOB) {
            this.props.dataStore.ui.filter.Filter.LOBs.LOB.forEach(ob => {
                if (ob['@ID']?.length > 0) {
                    items.push(ob['@ID']);
                }
            });
        }

        if (items.length == 0) { items.push('') }
        return items;
    }

    public getSelectedLOB(): string[] {
        let items: string[] = [];

        if (this.filterDataLoaded() &&
            this.props.dataStore.ui.filter.Filter.RemitLOBs &&
            this.props.dataStore.ui.filter.Filter.RemitLOBs.RemitLOB) {
            this.props.dataStore.ui.filter.Filter.RemitLOBs.RemitLOB.forEach(ob => {
                if (ob['@ID']?.length > 0) {
                    items.push(ob['@ID']);
                }
            });
        }

        if (items.length == 0) { items.push('') }
        return items;
    }

    public getSelectedProviders(): string[] {
        let items: string[] = [];
        if (this.filterDataLoaded() &&
            this.props.dataStore.ui.filter.Filter.Providers &&
            this.props.dataStore.ui.filter.Filter.Providers.Provider) {
            this.props.dataStore.ui.filter.Filter.Providers.Provider.forEach(ob => {
                if (ob['@ID']?.length > 0) {
                    items.push(ob['@ID']);
                }
            });
        }

        if (items.length == 0) { items.push('') }
        return items;
    }

    public getSelectedProviderNPIs(): string[] {
        
        let items: string[] = [];
        if (this.filterDataLoaded() &&
            this.props.dataStore.ui.filter.Filter.ProviderNPIs &&
            this.props.dataStore.ui.filter.Filter.ProviderNPIs.ProviderNPI) {
            this.props.dataStore.ui.filter.Filter.ProviderNPIs.ProviderNPI.forEach(ob => {
                if (ob['@ID']?.length > 0) {
                    items.push(ob['@ID']);
                }
            });
        }

        if (items.length == 0) { items.push('') }
        return items;
    }

    public getSelectedFacilities(): string[] {
        let items: string[] = [];

        if (this.filterDataLoaded() &&
            this.props.dataStore.ui.filter.Filter.Facilities &&
            this.props.dataStore.ui.filter.Filter.Facilities.Facility) {
            this.props.dataStore.ui.filter.Filter.Facilities.Facility.forEach(ob => {
                if (ob['@ID']?.length > 0) {
                    items.push(ob['@ID']);
                }
            });
        }

        if (items.length == 0) { items.push('') }
        return items;
    }


    public validForProcess(listItem: any): boolean {
        var matchType: string = listItem['@MatchType'] ?? "";
        var candidate: string = listItem['@Candidate'] ?? "";
        matchType = matchType.toUpperCase();
        candidate = candidate.toUpperCase();

        if (this.process_lowercase === "match") {
            if (matchType !== 'U')
                return false;
        } else if (this.process_lowercase === "feedantpay") {
            alert('todo test this');
            if (matchType !== 'M')
                return false;
        } else {
            if (candidate === 'C')
                return false;
        }

        return true;
    }

    public getRemitFilterSavedListItem(allowFav: boolean): IModalFilterListOption[] {
        let filterList: IModalFilterListOption[] = [];
        // "<ListPopulation><FilterList>
        // <Filter ID=\"2\" Name=\"RemitFilter1\" FilterType=\"R\" Favorite=\"9999\" FilterAssigned=\"0\" Candidate=\"\" MatchType=\"\" />
        // <Filter ID=\"3\" Name=\"RemitFilter2\" FilterType=\"R\" Favorite=\"9999\" FilterAssigned=\"1\" Candidate=\"\" MatchType=\"\" /><Filter ID=\"4\" Name=\"RemitReport\" FilterType=\"R\" Favorite=\"9999\" FilterAssigned=\"0\" Candidate=\"\" MatchType=\"\" /><Filter ID=\"7\" Name=\"RemitReportWithOption\" FilterType=\"R\" Favorite=\"9999\" FilterAssigned=\"0\" Candidate=\"\" MatchType=\"\" />
        // <Filter ID=\"10\" Name=\"test filter save\" FilterType=\"R\" Favorite=\"9999\" FilterAssigned=\"0\" Candidate=\"\" MatchType=\"\" /><Filter ID=\"11\" Name=\"testststststst\" FilterType=\"R\" Favorite=\"9999\" FilterAssigned=\"0\" Candidate=\"\" MatchType=\"\" />
        // </FilterList ></ListPopulation > "

        if (this.props.dataStore.api?.data2?.ListPopulation?.FilterList?.Filter?.length > 0) {
            let filters = this.props.dataStore.api?.data2?.ListPopulation?.FilterList?.Filter;
            if (filters && filters.length > 0) {

                if (allowFav == true) {
                    filters.forEach((listItem: any) => {
                        let filterInfo: IModalFilterListOption = { 'value': listItem['@ID'], 'label': listItem['@Name'], 'fav': true };

                        if (this.validForProcess(listItem)) {
                            if (listItem['@Favorite'] !== '9999') {
                                this.hasFavorites = true;
                                filterList.push(filterInfo);
                            }
                        }
                    });
                }

                filters.forEach((listItem: any) => {
                    let filterInfo: IModalFilterListOption = { 'value': listItem['@ID'], 'label': listItem['@Name'] };
                    if (this.validForProcess(listItem)) {
                        if (!allowFav || (allowFav && listItem['@Favorite'] === '9999')) {
                            filterList.push(filterInfo);
                        }
                    }
                });
            }
        }

        return filterList;
    }

    public getData(ob: any, field: string): string {
        if (!ob)
            return '';

        return ob[field] ?? '';

    }

    public hasShowButton() {
        switch (this.process_lowercase) {
            case "postingfile":
            case "postingfilebypayer":
            case "postingfilebypayment":
            case "retrieve835":
            case "viewx12remit":
                return true;
        }
        return false;
    }

    public getButtons() {
        // var isSaveEnabled = this.state.activeTab !== 0 && (this.props.canCreate || this.isNDCUser());
        const isSaveEnabled = (this.props.canCreate || this.isNDCUser());

        return (
            <DialogBox>
                {this.hasShowButton() &&
                    <DialogButton
                        domID={"remitfilter-showbtn"}
                        name="Load"
                        disable={false}
                        btnType="standard"
                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { this.onShowQuickStart() }}
                    />
                }

                <DialogButton
                    domID={"remitfilter-savebtn"}
                    name="Save"
                    disable={!isSaveEnabled}
                    btnType="standard"
                    onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { this.saveFilter() }}
                />

                {this.process_lowercase?.startsWith("postingfile") &&
                    <>
                        <DialogButton
                            domID={"remitfilter-refinepaybtn"}
                            name="Refine By Payment"
                            disable={!this.props.canEdit || this.state.disableRefineButton}
                            onClick={(e: any) => this.state.disableBtn === true ? null : this.onRefineClick(this.onRefine.bind(this), true)}
                        />

                        <DialogButton
                            domID={"remitfilter-refineclaimbtn"}
                            name="Refine By Claim"
                            disable={!this.props.canEdit || this.state.disableRefineButton}
                            onClick={(e: any) => this.state.disableBtn === true ? null : this.onRefineClick(this.onRefine.bind(this))}
                        />
                    </>
                }

                {!(this.process_lowercase?.startsWith("postingfile")) &&

                    <DialogButton
                        domID={"remitfilter-refinebtn"}
                        name={this.process_lowercase === "match" ? "Continue" : "Refine"}
                        disable={!this.props.canEdit || this.state.disableRefineButton}
                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onRefineClick(this.onRefine.bind(this))}
                    />
                }

                {this.process_lowercase !== "match" &&
                    <DialogButton
                        domID={"remitfilter-submitbtn"}
                        name="Continue"
                        disable={!this.props.canEdit || this.state.disableBtn === true || this.state.disableRefineButton}
                        onClick={(e: any) => this.state.disableBtn === true ? null : this.onSubmitClick(this.submitFilter.bind(this))}
                    />
                }
                <DialogButton
                    domID={"remitfilter-cancelbtn"}
                    name="Cancel"
                    disable={false}
                    btnType="standard"
                    onClick={(e: any) => this.onCancel()}
                />
            </DialogBox>
        );


    }


    /////////////////////////////////////////////////////////////////////////////////////////////
    // RENDER  
    /////////////////////////////////////////////////////////////////////////////////////////////

    public render() {
        var instruction = <React.Fragment>{this.props.instruction && this.props.instruction.length > 0 ? this.props.instruction : "Use the Quick Start tab to choose an existing filter or enter a quick filter, use the Advanced tab to set up a new filter. Then click Refine to see a list of remits in the working group before you use them or click Continue to use the remits without looking at them. Save is active only from the Advanced tab."}</React.Fragment>;
        var buttons = this.getButtons();

        var savedFilterList = this.getRemitFilterSavedListItem(true);

        // data helpers
        var titleString = "Remit Filter >> " + this.props.title +
            (this.props.dataStore.ui.filterName.length == 0 ? '' : " \"" + this.props.dataStore.ui.filterName + "\"");

        var groupCodeData: any[] = [{ '@ID': '', '@Name': '- Group Code -' }, { '@ID': '1', '@Name': 'Claim' }, { '@ID': '2', '@Name': 'Service Line' }, { '@ID': '3', '@Name': 'Claim & Service Line' }];
        var adjustmentCodeData: any[] = [{ '@ID': '', '@Name': '- Adjustment Code -' }, { '@ID': '1', '@Name': 'Claim' }, { '@ID': '2', '@Name': 'Service Line' }, { '@ID': '3', '@Name': 'Claim & Service Line' }];
        var remarkCodeData: any[] = [{ '@ID': '', '@Name': '- Remark Code -' }, { '@ID': '1', '@Name': 'Remark: Claim' }, { '@ID': '2', '@Name': 'Remark: Service Line' }, { '@ID': '3', '@Name': 'Remark: Claim & SL' }];
        var selectedPayers = this.getSelectedPayers();
        var selectedRemitLOB = this.getSelectedRemitLOB();
        var selectedLOB = this.getSelectedLOB();
        var selectedFacilities = this.getSelectedFacilities();
        const filterColumnOneRowWrapperStyle = {
            // height: '175px',
            // display: 'flex',
            // justifyContent: 'center'
        };

        return (
            <>
                <DialogWrapper title={titleString} instruction={instruction} helpUrl={this.helpLink} buttons={buttons} isBusy={this.props.dataStore.ui.isBusy || this.props.dataStore.api.dataStatus === 'REQUEST' || this.state.redirecting}>
                    <ContentWrapper id={"content_wrapper_filter"}>
                        <RemitFilterContent>
                            <ContentRowWrapper id={"remitfilter-tab-container"} style={{ width: '100%', maxHeight: '30px' }}>
                                <ContentColumnWrapper>
                                    <Tabs
                                        size="small"
                                        tabs={this.getTabs()}
                                        onTabSelect={(e: any, index: any) => { this.onTabSelect(index.selectedTabIndex) }}
                                        initialTab={this.getInitialTab()}
                                    />
                                </ContentColumnWrapper>
                            </ContentRowWrapper>

                            { /****** QUICK START TAB ******/}
                            {this.state.activeTab == 0 && !this.state.hideQs &&

                                <ContentRowWrapper>
                                    <ContentColumnWrapper id={"remitfilter-quickstart-container"} style={{ width: '570px', height: '500px' }}>

                                        <ContentColumnWrapper>
                                            <RadioButtonComponent
                                                id="remitfilter-lastremitlist"
                                                label="Last Remit List"
                                                checked={this.state.quickStart === 0}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeQuickStart(0)}
                                                disabled={this.state.hideQs || this.disableQs0 == true}
                                            // note: because of a js issue in the old code this double click isn't wired up...bug in old code
                                            //   onDoubleClick={() => { this.performDialogAction(0) }} 
                                            />
                                        </ContentColumnWrapper>
                                        <ContentColumnWrapper>
                                            <RadioButtonComponent
                                                id="remitfilter-lastfilter"
                                                label="Last Filter"
                                                checked={this.state.quickStart === 1}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeQuickStart(1)}
                                                disabled={this.state.hideQs || this.disableQs1 == true}
                                            // note: because of a js issue in the old code this double click isn't wired up...bug in old code
                                            // case LAST_FILTER,SAVED_FILTER:  (no support for comma separation, needed fall-thru logic)
                                            //    onDoubleClick={() => { this.performDialogAction(1) }} 
                                            />
                                        </ContentColumnWrapper>

                                        <ContentColumnWrapper>
                                            <RadioButtonComponent
                                                id="remitfilter-quickfilter"
                                                label="Quick Remit Filter:"
                                                checked={this.state.quickStart === 2}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeQuickStart(2)}
                                                disabled={this.state.hideQs}
                                                onDoubleClick={() => { this.performDialogAction(2) }}
                                            />

                                            <ContentRowWrapper>


                                                <ContentRowWrapper style={{ width: '1090px'}} onMouseDown={() => { this.onChangeQuickStart(2) }}>
                                                    <FilterColumn1 id={"remitfilter-quickstart-container_column1"} >
                                                        <ContentRowWrapper>
                                                            <DialogFieldset id={'remitfilter-qs-field-fs'} style={{ marginLeft: '20px', paddingBottom:'0px' }}>
                                                                <DialogLegend3 helpUrl='/Support/Help/HELP_RMT_Ranges.htm'>Field</DialogLegend3>
                                                                <ContentRowWrapper>
                                                                    <ContentColumnWrapper style={{ paddingBottom: '0px' }}>
                                                                        <ContentRowWrapper style={{ paddingTop: '0px',marginBottom:'5px' }}>
                                                                            <ARMDatePicker
                                                                                className="col2_sep"
                                                                                domID="remitfilter_qs_importfrom"
                                                                                label="Import Date:"
                                                                                onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onImportDateChange(e, true)}
                                                                                allowTime={false}
                                                                                hasError={this.state.focusField == "remitfilter_qs_importfrom"}
                                                                                minDate={this.minDate}
                                                                                maxDate={this.maxDate}
                                                                                assocTo={this.getData(this.props.dataStore.ui.filter?.Filter?.ImportDate, '@To')}
                                                                                initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.ImportDate, '@From')}
                                                                            />
                                                                            <ARMDatePicker
                                                                                domID="remitfilter_qs_importto"
                                                                                className="col3_sep col3_sep_qs"
                                                                                onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onImportDateChange(e, false)}
                                                                                allowTime={false}
                                                                                hasError={this.state.focusField == "remitfilter_qs_importto"}
                                                                                minDate={this.minDate}
                                                                                maxDate={this.maxDate}
                                                                                assocFrom={this.getData(this.props.dataStore.ui.filter?.Filter?.ImportDate, '@From')}
                                                                                initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.ImportDate, '@To')}
                                                                            />
                                                                        </ContentRowWrapper>
                                                                        <ContentRowWrapper style={{ paddingTop: '0px',marginBottom:'5px'}}>
                                                                            <ARMDatePicker
                                                                                className="col2_sep"
                                                                                domID="remitfilter_qs_pmtfrom"
                                                                                label="Payment Date:"
                                                                                onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onPmtDateChange(e, true)}
                                                                                allowTime={false}
                                                                                hasError={this.state.focusField == "remitfilter_qs_pmtfrom"}
                                                                                minDate={this.minDate}
                                                                                maxDate={this.maxDate}
                                                                                assocTo={this.getData(this.props.dataStore.ui.filter?.Filter?.PmtDate, '@To')}
                                                                                initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.PmtDate, '@From')}
                                                                            />

                                                                            <ARMDatePicker
                                                                                domID="remitfilter_qs_pmtto"
                                                                                className="col3_sep col3_sep_qs"
                                                                                onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onPmtDateChange(e, false)}
                                                                                allowTime={false}
                                                                                hasError={this.state.focusField == "remitfilter_qs_pmtto"}
                                                                                minDate={this.minDate}
                                                                                maxDate={this.maxDate}
                                                                                assocFrom={this.getData(this.props.dataStore.ui.filter?.Filter?.PmtDate, '@From')}
                                                                                initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.PmtDate, '@To')}
                                                                            />
                                                                        </ContentRowWrapper>
                                                                        <ContentRowWrapper style={{ paddingTop: '0px', width: '100%',paddingBottom:'5px' }}>
                                                                            <Input
                                                                                domID="remitfilter_qs_checknumber"
                                                                                className="remitfilter_qs_checknumber"
                                                                                label="Check Number:"
                                                                                hasError={this.state.focusField == "remitfilter_qs_checknumber"}
                                                                                maxLength={50}
                                                                                initialValue={this.getData(this.props.dataStore.ui.filter.Filter.CheckNumber, "@Value")}
                                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onCheckNumberChange(e.target.value)}
                                                                            />
                                                                        </ContentRowWrapper>

                                                                    </ContentColumnWrapper>
                                                                </ContentRowWrapper>
                                                            </DialogFieldset>
                                                            <ContentColumnWrapper style={{ width: '170px !important', minHeight: '100px' }}>
                                                                <DialogFieldset id={'remitfilter_qs_remitstate'} style={{ paddingBottom: '3px' }} >
                                                                    <DialogLegend>Remit State</DialogLegend>

                                                                    <ContentRowWrapper style={{ marginBottom: '2px' }} >
                                                                        <Checkbox
                                                                            domID='remitfilter_qs_matched'
                                                                            label='Matched'
                                                                            checked={this.getData(this.props.dataStore.ui.filter?.Filter?.RemitState, '@Matched') === 'M' ||
                                                                                this.getData(this.props.dataStore.ui.filter?.Filter?.RemitState, '@All') === 'true'}
                                                                            onChange={(e: React.SyntheticEvent) => this.onSelectRemitState('Matched')}
                                                                            disabled={this.state.disableMatched}
                                                                        />
                                                                    </ContentRowWrapper>
                                                                    <ContentRowWrapper style={{ marginBottom: '2px' }}>
                                                                        <Checkbox
                                                                            domID='remitfilter_qs_unmatched'
                                                                            label='Unmatched'
                                                                            checked={this.getData(this.props.dataStore.ui.filter?.Filter?.RemitState, '@Unmatched') === 'U' ||
                                                                                this.getData(this.props.dataStore.ui.filter?.Filter?.RemitState, '@All') === 'true'}
                                                                            onChange={(e: React.SyntheticEvent) => this.onSelectRemitState('Unmatched')}
                                                                            disabled={this.state.disableUnmatched}
                                                                        />

                                                                    </ContentRowWrapper>
                                                                </DialogFieldset>


                                                            </ContentColumnWrapper>
                                                        </ContentRowWrapper>
                                                    </FilterColumn1>

                                                    <FilterColumn2 />
                                                    <FilterColumn3 />
                                                </ContentRowWrapper>


                                            </ContentRowWrapper>

                                        </ContentColumnWrapper>



                                        <ContentColumnWrapper>
                                            <RadioButtonComponent
                                                id="remitfilter-savedfilter"
                                                label={this.hasFavorites ? "Saved Filter (favorites are shown in blue)" : "Saved Filter"}

                                                checked={this.state.quickStart === 3}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeQuickStart(3)}
                                                disabled={!savedFilterList || (savedFilterList && savedFilterList.length == 0)}
                                                onDoubleClick={() => { this.performDialogAction(3) }}
                                            />
                                        </ContentColumnWrapper>

                                        <ContentRowWrapper style={{ marginLeft: '20px', width: '40%', marginTop: '5px', marginBottom: '20px' }} onMouseDown={() => { this.onChangeQuickStart(3) }}>
                                            <ContentColumnWrapper>
                                                <SelectComponent
                                                    title={'remitfilter_sflist'}
                                                    size={12}
                                                    width='100%'
                                                    disabled={!savedFilterList || (savedFilterList && savedFilterList.length == 0)}
                                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectSavedFilter(e)}
                                                    optionFields={{ value: "value", text: "label", fav: "fav" }}
                                                    records={savedFilterList}
                                                    onDoubleClick={() => { this.performDialogAction(3) }}
                                                >
                                                </SelectComponent>
                                                <ButtonWrapper>
                                                    <Button
                                                    domID="remitfilter_sfloadbutton"
                                                    name={"LOAD"}
                                                    buttonType="standard"
                                                    size="small"
                                                    type="button"
                                                    disabled={!savedFilterList || (savedFilterList && savedFilterList.length == 0)}
                                                    onClick={(e: React.SyntheticEvent) => this.loadFilter(null, true)}
                                                />
                                                </ButtonWrapper>
                                                
                                            </ContentColumnWrapper>
                                        </ContentRowWrapper>
                                        


                                        <ContentColumnWrapper style={{ height: '300px' }}>
                                        </ContentColumnWrapper>

                                    </ContentColumnWrapper>

                                </ContentRowWrapper>
                            }

                            { /****** ADVANCED TAB ******/}
                            {(this.state.activeTab == 1 || this.state.hideQs) &&

                                <ContentRowWrapper style={{ minHeight: "300px"}}>

                                    <FilterColumn1 id={"remitfilter-advanced-container_column1"} style={{ padding: '5px' }}>

                                        <ContentColumnWrapper>
                                            <ContentRowWrapper style={{ marginTop: "-5px"}}>
                                                <DialogFieldset>
                                                    <DialogLegend>Payer</DialogLegend>
                                                    <ContentRowWrapper onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                        <SelectComponent
                                                            title={'remitfilter_payer_select'}
                                                            multiple='true'
                                                            size={6}
                                                            width='342px'
                                                            disabled={false}
                                                            optionFields={{ value: "value", text: "label" }}
                                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectPayers(e)}
                                                            records={this.getSelectedPayerRecords()}
                                                            selectedMultiValue={selectedPayers}
                                                        />
                                                    </ContentRowWrapper>
                                                </DialogFieldset>

                                                <DialogFieldset id={'remitfilter-remitlob'}>
                                                    <DialogLegend>Remit LOB</DialogLegend>
                                                    <ContentRowWrapper onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                        <SelectComponent
                                                            title={'remitfilter_remitlob_select'}
                                                            multiple='true'
                                                            size={6}
                                                            width='130px'
                                                            disabled={false}
                                                            optionFields={{ value: "value", text: "label" }}
                                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectRemitLOBs(e)}
                                                            records={this.getSelectedRemitLOBRecords()}
                                                            selectedMultiValue={selectedRemitLOB}
                                                        />
                                                    </ContentRowWrapper>
                                                </DialogFieldset>
                                            </ContentRowWrapper>

                                            <ContentRowWrapper>
                                                <DialogFieldset>
                                                    <DialogLegend>LOB</DialogLegend>
                                                    <ContentRowWrapper onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                        <SelectComponent
                                                            title={'remitfilter_lob_select'}
                                                            multiple='true'
                                                            size={6}
                                                            width='190px'
                                                            disabled={false}
                                                            optionFields={{ value: "value", text: "label" }}
                                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectLOBs(e)}
                                                            records={this.getSelectedLOBRecords()}
                                                            selectedMultiValue={selectedLOB}
                                                        />
                                                    </ContentRowWrapper>
                                            </DialogFieldset>
                                            <DialogFieldset id={'remitfilter-remitlob'}>
                                                <DialogLegend>Provider No</DialogLegend>
                                                <ContentRowWrapper onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                    <FilterableListWrapper>
                                                        <ListContainerDiv>
                                                            <FilterableList
                                                                key={JSON.stringify(this.getSelectedProviderNoRecords())}
                                                                domID={'remitfilter_providerno'}
                                                                width={130}
                                                                height={150}
                                                                records={this.getSelectedProviderNoRecords()}
                                                                optionFields={{
                                                                    id: "value",
                                                                    value: "label",
                                                                    text: "label",
                                                                }}
                                                                placeholder="Search"
                                                                setSelections={(e: any) => this.onSelectProviders(e)}
                                                                multiSelect
                                                                value={this.getSelectedProviders()}
                                                            />
                                                        </ListContainerDiv>
                                                    </FilterableListWrapper>
                                                </ContentRowWrapper>
                                            </DialogFieldset>
                                                <DialogFieldset id={'remitfilter-remitlob'}>
                                                    <DialogLegend>Provider NPI</DialogLegend>
                                                    <ContentRowWrapper onMouseDown={() => this.setState({ disableRefineButton: false })}>

                                                        <FilterableListWrapper>
                                                            <ListContainerDiv>
                                                                    <FilterableList
                                                                    key={JSON.stringify(this.getSelectedProviderNPIRecords())}
                                                                    domID={'remitfilter_providernpi_select'}
                                                                    width={130}
                                                                    height={150}
                                                                    records={this.getSelectedProviderNPIRecords()}
                                                                    optionFields={{
                                                                        id: "value",
                                                                        value: "label",
                                                                        text: "label",
                                                                    }}
                                                                    setSelections={(e: any) => this.onSelectProviderNPIs(e)}
                                                                    placeholder="Search"
                                                                    value={this.getSelectedProviderNPIs()}
                                                                    multiSelect
                                                                    />
                                                            </ListContainerDiv>
                                                        </FilterableListWrapper>
                                                    </ContentRowWrapper>
                                                </DialogFieldset>
                                           
                                            </ContentRowWrapper>

                                            <ContentRowWrapper>
                                                <DialogFieldset>
                                                    <DialogLegend>Facility</DialogLegend>
                                                    <ContentRowWrapper onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                        <SelectComponent
                                                            title={'remitfilter_facility_select'}
                                                            multiple='true'
                                                            size={6}
                                                            width='495px'
                                                            disabled={false}
                                                            optionFields={{ value: "value", text: "label" }}
                                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectFacilities(e)}
                                                            records={this.getSelectedFacilityRecords()}
                                                        selectedMultiValue={selectedFacilities}

                                                        />
                                                    </ContentRowWrapper>
                                                </DialogFieldset>
                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ ...filterColumnOneRowWrapperStyle, flexDirection: 'column', padding: '5px' }} onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                <SelectDropdown
                                                    domID="remitilter_displayorder_adv"
                                                    className="dropdown"
                                                    label="DISPLAY ORDER:"
                                                    disabled={this.state.disableDisplayOrder == true}
                                                    isClearable={false}
                                                    isSearchable={true}
                                                    //maxlength={90}
                                                    onChange={(option: SelectDropdownValue) => this.onDisplayOrderChange(option)}
                                                    options={this.getLookupList(this.props.dataStore.api.data?.ListPopulation?.DisplayOrderList)}
                                                    initialValue={this.getLookupList_SelectedItem_DisplayOrder()}
                                                />

                                                <CustomSlimLabel>File Name:</CustomSlimLabel>
                                                <ARMNarrowInput
                                                    style={{ margin: '0 0 0-2px', width: '494px' }}
                                                    domID="remitfilter_adv_filename"
                                                    // className="col1_input"
                                                    // label="File Name:"
                                                    // labelWidth="160px"
                                                    hasError={this.state.focusField == "remitfilter_adv_filename"}
                                                    maxLength={50}
                                                    initialValue={this.getData(this.props.dataStore.ui.filter.Filter.FileName, "@Value")}
                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onFileNameChange(e.target.value)}
                                                    onMouseDown={() => this.setState({ disableRefineButton: false })}
                                                />
                                            </ContentRowWrapper>

                                        </ContentColumnWrapper>

                                    </FilterColumn1>

                                    <FilterColumn2 id={"remitfilter-advanced-container_column2"}  style={{marginLeft:'10px'}}>
                                        <DialogFieldset id={'remitfilter-adv-field-fs'} style={{ padding: '0px 15px', paddingLeft: '15px' }} >
                                            <DialogLegend3 helpUrl='/Support/Help/HELP_RMT_Ranges.htm'>Field</DialogLegend3>
                                            <ContentColumnWrapper>

                                                {/*DATE PICKERS*/}

                                                <ContentRowWrapper>
                                                    <ContentRowContainer onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                        <SlimLabel>Fiscal Period:</SlimLabel>
                                                        <Input
                                                            domID="remitfilter_adv_fiscalperiodfrom"
                                                            className="pseudodate"
                                                            hasError={this.state.focusField == "remitfilter_adv_fiscalperiodfrom"}
                                                            maxLength={5}
                                                            placeholder={"YY/MM"}
                                                            initialValue={this.getData(this.props.dataStore.ui.filter.Filter.FiscalPeriod, "@From")}
                                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => this.onFiscalPeriodBlur(e.target.value, true)}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onFiscalPeriodChange(e.target.value, true)}

                                                        />
                                                        <Input
                                                            domID="remitfilter_adv_fiscalperiodto"
                                                            className="pseudodate field2nolabel"
                                                            label=""
                                                            hasError={this.state.focusField == "remitfilter_adv_fiscalperiodto"}
                                                            maxLength={5}
                                                            placeholder={"YY/MM"}
                                                            initialValue={this.getData(this.props.dataStore.ui.filter.Filter.FiscalPeriod, "@To")}
                                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => this.onFiscalPeriodBlur(e.target.value, false)}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onFiscalPeriodChange(e.target.value, false)}
                                                        />
                                                    </ContentRowContainer>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper style={{ paddingTop: '5px' }}>
                                                    <ContentRowContainer onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                        <SlimLabel>Import Date:</SlimLabel>
                                                        <ARMDatePicker
                                                            className="col2_sep"
                                                            domID="remitfilter_adv_importfrom"
                                                            // label="Import Date:"
                                                            onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onImportDateChange(e, true)}
                                                            allowTime={false}
                                                            hasError={this.state.focusField == "remitfilter_adv_importfrom"}
                                                            minDate={this.minDate}
                                                            maxDate={this.maxDate}
                                                            assocTo={this.getData(this.props.dataStore.ui.filter?.Filter?.ImportDate, '@To')}
                                                            initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.ImportDate, '@From')}

                                                        />
                                                        <ARMDatePicker
                                                            domID="remitfilter_adv_importto"
                                                            className="col3_sep"
                                                            onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onImportDateChange(e, false)}
                                                            allowTime={false}
                                                            hasError={this.state.focusField == "remitfilter_adv_importto"}
                                                            minDate={this.minDate}
                                                            maxDate={this.maxDate}
                                                            assocFrom={this.getData(this.props.dataStore.ui.filter?.Filter?.ImportDate, '@From')}
                                                            initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.ImportDate, '@To')}
                                                        />
                                                    </ContentRowContainer>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper style={{ paddingTop: '5px' }}>
                                                    <ContentRowContainer onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                        <SlimLabel>Payment Date:</SlimLabel>
                                                        <ARMDatePicker
                                                            className="col2_sep"
                                                            domID="remitfilter_adv_pmtfrom"
                                                            // label="Payment Date:"
                                                            onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onPmtDateChange(e, true)}
                                                            allowTime={false}
                                                            hasError={this.state.focusField == "remitfilter_adv_pmtfrom"}
                                                            minDate={this.minDate}
                                                            maxDate={this.maxDate}
                                                            assocTo={this.getData(this.props.dataStore.ui.filter?.Filter?.PmtDate, '@To')}
                                                            initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.PmtDate, '@From')}
                                                        />
                                                        <ARMDatePicker
                                                            domID="remitfilter_adv_pmtto"
                                                            className="col3_sep"
                                                            onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onPmtDateChange(e, false)}
                                                            allowTime={false}
                                                            hasError={this.state.focusField == "remitfilter_adv_pmtto"}
                                                            minDate={this.minDate}
                                                            maxDate={this.maxDate}
                                                            assocFrom={this.getData(this.props.dataStore.ui.filter?.Filter?.PmtDate, '@From')}
                                                            initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.PmtDate, '@To')}
                                                        />
                                                    </ContentRowContainer>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper style={{ paddingTop: '5px' }}>
                                                    <ContentRowContainer onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                        <SlimLabel>Secondary Bill:</SlimLabel>
                                                        <ARMDatePicker
                                                            className="col2_sep"
                                                            domID="remitfilter_adv_secbill_from"
                                                            // label="Secondary Bill:"
                                                            onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSecBillChange(e, true)}
                                                            allowTime={false}
                                                            hasError={this.state.focusField == "remitfilter_adv_secbill_from"}
                                                            minDate={this.minDate}
                                                            maxDate={this.maxDate}
                                                            assocTo={this.getData(this.props.dataStore.ui.filter?.Filter?.SecBill, '@To')}
                                                            initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.SecBill, '@From')}
                                                        />
                                                        <ARMDatePicker
                                                            domID="remitfilter_adv_secbill_to"
                                                            className="col3_sep"
                                                            onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSecBillChange(e, false)}
                                                            allowTime={false}
                                                            hasError={this.state.focusField == "remitfilter_adv_secbill_to"}
                                                            minDate={this.minDate}
                                                            maxDate={this.maxDate}
                                                            assocFrom={this.getData(this.props.dataStore.ui.filter?.Filter?.SecBill, '@From')}
                                                            initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.SecBill, '@To')}
                                                        />
                                                    </ContentRowContainer>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper style={{ paddingTop: '5px' }}>
                                                    <ContentRowContainer onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                        <SlimLabel>Service From:</SlimLabel>
                                                        <ARMDatePicker
                                                            className="col2_sep"
                                                            domID="remitfilter_adv_servicefrom_from"
                                                            // label="Service From:"
                                                            onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onServiceFromChange(e, true)}
                                                            allowTime={false}
                                                            hasError={this.state.focusField == "remitfilter_adv_servicefrom_from"}
                                                            minDate={this.minDateMedicare}
                                                            maxDate={this.maxDate}
                                                            assocTo={this.getData(this.props.dataStore.ui.filter?.Filter?.ServiceFrom, '@To')}
                                                            initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.ServiceFrom, '@From')}
                                                        />
                                                        <ARMDatePicker
                                                            domID="remitfilter_adv_servicefrom_to"
                                                            className="col3_sep"
                                                            onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onServiceFromChange(e, false)}
                                                            allowTime={false}
                                                            hasError={this.state.focusField == "remitfilter_adv_servicefrom_to"}
                                                            minDate={this.minDateMedicare}
                                                            maxDate={this.maxDate}
                                                            assocFrom={this.getData(this.props.dataStore.ui.filter?.Filter?.ServiceFrom, '@From')}
                                                            initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.ServiceFrom, '@To')}
                                                        />
                                                    </ContentRowContainer>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper style={{ paddingTop: '5px',marginBottom:'2.5px' }}>
                                                    <ContentRowContainer onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                        <SlimLabel>Service Thru:</SlimLabel>
                                                        <ARMDatePicker
                                                            className="col2_sep"
                                                            domID="remitfilter_adv_servicethru_from"
                                                            onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onServiceThruChange(e, true)}
                                                            allowTime={false}
                                                            hasError={this.state.focusField == "remitfilter_adv_servicethru_from"}
                                                            minDate={this.minDateMedicare}
                                                            maxDate={this.maxDate}
                                                            assocTo={this.getData(this.props.dataStore.ui.filter?.Filter?.ServiceThru, '@To')}
                                                            initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.ServiceThru, '@From')}
                                                        />
                                                        <ARMDatePicker
                                                            domID="remitfilter_adv_servicethru_to"
                                                            className="col3_sep"
                                                            onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onServiceThruChange(e, false)}
                                                            allowTime={false}
                                                            hasError={this.state.focusField == "remitfilter_adv_servicethru_to"}
                                                            minDate={this.minDateMedicare}
                                                            maxDate={this.maxDate}
                                                            assocFrom={this.getData(this.props.dataStore.ui.filter?.Filter?.ServiceThru, '@From')}
                                                            initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.ServiceThru, '@To')}
                                                        />
                                                    </ContentRowContainer>
                                                </ContentRowWrapper>

                                                {/*DATE PICKERS*/}

                                                <ContentRowWrapper style={{ width: 'fit-content' }} onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                    <CustomARMNarrowInput
                                                        domID="remitfilter_adv_apccode"
                                                        className="col1_input"
                                                        label="APC Code:"
                                                        labelWidth="160px"
                                                        hasError={this.state.focusField == "remitfilter_adv_apccode"}
                                                        maxLength={50}
                                                        initialValue={this.getData(this.props.dataStore.ui.filter.Filter.APCCode, "@Value")}
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onApcCodeChange(e.target.value)}
                                                        style={{
                                                            marginLeft: '22px',
                                                        }}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{width: 'fit-content' }} onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                    <CustomARMNarrowInput
                                                        domID="remitfilter_adv_checknumber"
                                                        className="col1_input"
                                                        label="Check Number:"
                                                        labelWidth="160px"
                                                        hasError={this.state.focusField == "remitfilter_adv_checknumber"}
                                                        maxLength={50}
                                                        initialValue={this.getData(this.props.dataStore.ui.filter.Filter.CheckNumber, "@Value")}
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onCheckNumberChange(e.target.value)}
                                                        style={{
                                                            marginLeft: '22px',
                                                        }}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{ width: 'fit-content' }} onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                    <CustomARMNarrowInput
                                                        domID="remitfilter_adv_contractualadj"
                                                        className="col1_input"
                                                        label="Contractual Adj:"
                                                        labelWidth="160px"
                                                        hasError={this.state.focusField == "remitfilter_adv_contractualadj"}
                                                        maxLength={40}
                                                        initialValue={this.getData(this.props.dataStore.ui.filter.Filter.ContractualAdj, "@Value")}
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onContractualAdjChange(e.target.value)}
                                                        style={{
                                                            marginLeft: '22px',
                                                        }}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{ width: 'fit-content' }} onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                    <CustomARMNarrowInput
                                                        domID="remitfilter_adv_patientcontrolnumber"
                                                        className="col1_input"
                                                        label="Control Number:"
                                                        labelWidth="160px"
                                                        hasError={this.state.focusField == "remitfilter_adv_patientcontrolnumber"}
                                                        maxLength={40}
                                                        initialValue={this.getData(this.props.dataStore.ui.filter.Filter.PatientControlNumber, "@Value")}
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onPatientControlNumberChange(e.target.value)}
                                                        style={{
                                                            marginLeft: '22px',
                                                        }}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{  width: 'fit-content' }} onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                    <CustomARMNarrowInput
                                                        domID="remitfilter_adv_lastname"
                                                        className="col1_input"
                                                        label="Last Name:"
                                                        labelWidth="160px"
                                                        hasError={this.state.focusField == "remitfilter_adv_lastname"}
                                                        maxLength={60}
                                                        initialValue={this.getData(this.props.dataStore.ui.filter.Filter.LastName, "@Value")}
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onLastNameChange(e.target.value)}
                                                        style={{
                                                            marginLeft: '22px',
                                                        }}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{  width: 'fit-content' }} onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                    <CustomARMNarrowInput
                                                        domID="remitfilter_adv_patienticnnumber"
                                                        className="col1_input"
                                                        label="Internal Control No:"
                                                        labelWidth="160px"
                                                        hasError={this.state.focusField == "remitfilter_adv_patienticnnumber"}
                                                        maxLength={50}
                                                        initialValue={this.getData(this.props.dataStore.ui.filter.Filter.PatientICNNumber, "@Value")}
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onPatientICNNumberChange(e.target.value)}
                                                        style={{
                                                            marginLeft: '22px',
                                                        }}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{  width: 'fit-content' }} onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                    <CustomARMNarrowInput
                                                        domID="remitfilter_adv_paymentamt"
                                                        className="col1_input"
                                                        label="Payment Amount:"
                                                        labelWidth="160px"
                                                        hasError={this.state.focusField == "remitfilter_adv_paymentamt"}
                                                        maxLength={40}
                                                        initialValue={this.getData(this.props.dataStore.ui.filter.Filter.PaymentAmt, "@Value")}
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onPaymentAmtChange(e.target.value)}
                                                        style={{
                                                            marginLeft: '22px',
                                                        }}
                                                    />
                                                </ContentRowWrapper>

                                                <ContentRowWrapper style={{  width: 'fit-content' }} onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                    <CustomARMNarrowInput
                                                        domID="remitfilter_adv_remitprovidernpi"
                                                        className="col1_input"
                                                        label="Provider NPI:"
                                                        labelWidth="160px"
                                                        hasError={this.state.focusField == "remitfilter_adv_remitprovidernpi"}
                                                        maxLength={40}
                                                        initialValue={this.getData(this.props.dataStore.ui.filter.Filter.RemitProviderNPI, "@Value")}
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onRemitProviderNPIChange(e.target.value)}
                                                        style={{
                                                            marginLeft: '22px',
                                                        }}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{ width: 'fit-content' }} onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                    <CustomARMNarrowInput
                                                        domID="remitfilter_adv_remitfedtaxid"
                                                        className="col1_input"
                                                        label="Federal Tax ID:"
                                                        labelWidth="160px"
                                                        hasError={this.state.focusField == "remitfilter_adv_remitfedtaxid"}
                                                        maxLength={40}
                                                        initialValue={this.getData(this.props.dataStore.ui.filter.Filter.RemitFederalTaxId, "@Value")}
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onRemitFederalTaxIdChange(e.target.value)}
                                                        style={{
                                                            marginLeft: '22px',
                                                        }}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{width: 'fit-content' }} onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                    <ARMNarrowDropdown
                                                        className="narrowlabel"
                                                        title='RemitFilter_RemarkCode'
                                                        size={1}
                                                        width='150px'
                                                        isLabel={true}
                                                        height='18px'
                                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onRemarkCodeIdChange(e.currentTarget.value)}
                                                        optionFields={{
                                                            value: "@ID",
                                                            text: "@Name"
                                                        }}
                                                        records={remarkCodeData}
                                                        selectedValue={this.getData(this.props.dataStore.ui.filter.Filter.RemarkCode, "@ID")}
                                                    >
                                                    </ARMNarrowDropdown>
                                                    <CustomARMNarrowInput
                                                        domID="remitfilter_adv_remarkcode"
                                                        className="col1_input"

                                                        labelWidth="0px"
                                                        hasError={this.state.focusField == "remitfilter_adv_remarkcode"}
                                                        maxLength={50}
                                                        initialValue={this.getData(this.props.dataStore.ui.filter.Filter.RemarkCode, "@Value")}
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onRemarkCodeChange(e.target.value)}
                                                        style={{
                                                            marginLeft: '22px',
                                                        }}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{width: 'fit-content' }} onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                    <CustomARMNarrowInput
                                                        domID="remitfilter_adv_status"
                                                        className="col1_input"
                                                        label="Remit Status:"
                                                        labelWidth="160px"
                                                        hasError={this.state.focusField == "remitfilter_adv_status"}
                                                        maxLength={40}
                                                        initialValue={this.getData(this.props.dataStore.ui.filter.Filter.Status, "@Value")}
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onStatusChange(e.target.value)}
                                                        style={{
                                                            marginLeft: '22px',
                                                        }}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{width: 'fit-content' }} onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                    <CustomARMNarrowInput
                                                        domID="remitfilter_adv_amtbilled"
                                                        className="col1_input"
                                                        label="Total Charges:"
                                                        labelWidth="160px"
                                                        hasError={this.state.focusField == "remitfilter_adv_amtbilled"}
                                                        maxLength={40}
                                                        initialValue={this.getData(this.props.dataStore.ui.filter.Filter.AmtBilled, "@Value")}
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onAmtBilledChange(e.target.value)}
                                                        style={{
                                                            marginLeft: '22px',
                                                        }}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{ width: 'fit-content' }} onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                    <CustomARMNarrowInput
                                                        domID="remitfilter_adv_remittypeofbill"
                                                        className="col1_input"
                                                        label="Type of Bill:"
                                                        labelWidth="160px"
                                                        hasError={this.state.focusField == "remitfilter_adv_remittypeofbill"}
                                                        maxLength={40}
                                                        initialValue={this.getData(this.props.dataStore.ui.filter.Filter.RemitTypeOfBill, "@Value")}
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onRemitTypeOfBillChange(e.target.value)}
                                                        style={{
                                                            marginLeft: '22px',
                                                        }}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{width: 'fit-content' }} onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                    <ARMNarrowDropdown
                                                        className="narrowlabel"
                                                        title='RemitFilter_AdjustmentCode'
                                                        size={1}
                                                        width='150px'
                                                        isLabel={true}
                                                        height='18px'
                                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onAdjustmentCodeIdChange(e.currentTarget.value)}
                                                        optionFields={{
                                                            value: "@ID",
                                                            text: "@Name"
                                                        }}
                                                        records={adjustmentCodeData}
                                                        selectedValue={this.getData(this.props.dataStore.ui.filter.Filter.AdjustmentCode, "@ID")}
                                                    >
                                                    </ARMNarrowDropdown>
                                                    <CustomARMNarrowInput
                                                        domID="remitfilter_adv_adjustmentcode"
                                                        className="col1_input"
                                                        labelWidth="0px"
                                                        hasError={this.state.focusField == "remitfilter_adv_adjustmentcode"}
                                                        maxLength={40}
                                                        initialValue={this.getData(this.props.dataStore.ui.filter.Filter.AdjustmentCode, "@Value")}
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onAdjustmentCodeChange(e.target.value)}
                                                        style={{
                                                            marginLeft: '22px',
                                                        }}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{  width: 'fit-content' }} onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                    <ARMNarrowDropdown
                                                        className="narrowlabel"
                                                        title='RemitFilter_GroupCode'
                                                        size={1}
                                                        width='150px'
                                                        isLabel={true}
                                                        height='18px'
                                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onGroupCodeIdChange(e.currentTarget.value)}
                                                        optionFields={{
                                                            value: "@ID",
                                                            text: "@Name"
                                                        }}
                                                        records={groupCodeData}
                                                        selectedValue={this.getData(this.props.dataStore.ui.filter.Filter.GroupCode, "@ID")}
                                                    >
                                                    </ARMNarrowDropdown>
                                                    <CustomARMNarrowInput
                                                        domID="remitfilter_adv_groupcode"
                                                        className="col1_input"
                                                        labelWidth="0px"
                                                        hasError={this.state.focusField == "remitfilter_adv_groupcode"}
                                                        maxLength={40}
                                                        initialValue={this.getData(this.props.dataStore.ui.filter.Filter.GroupCode, "@Value")}
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onGroupCodeChange(e.target.value)}
                                                        style={{
                                                            marginLeft: '22px',
                                                        }}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{width: 'fit-content', paddingBottom: '5px' }} onMouseDown={() => this.setState({ disableRefineButton: false })}>
                                                    <CustomARMNarrowInput
                                                        domID="remitfilter_adv_checkamt"
                                                        className="col1_input"
                                                        label="Check Amount:"
                                                        labelWidth="160px"
                                                        hasError={this.state.focusField == "remitfilter_adv_checkamt"}
                                                        maxLength={40} 
                                                        initialValue={this.getData(this.props.dataStore.ui.filter.Filter.CheckAmt, "@Value")}
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onCheckAmtChange(e.target.value)}
                                                        style={{
                                                            marginLeft: '22px',
                                                        }}
                                                    />
                                                </ContentRowWrapper>
                                            </ContentColumnWrapper>
                                        </DialogFieldset>

                                    </FilterColumn2>

                                    <FilterColumn3 id={"remitfilter-advanced-container_column3"} style={{ padding: '0 5px',marginLeft:'8px', marginRight:'10px'}} >

                                        <DialogFieldset id={'remitfilter_adv_remitstate'}  >
                                            <DialogLegend>Remit State</DialogLegend>

                                            <ContentRowWrapper style={{ marginBottom: '2px' }}>
                                                <Checkbox
                                                    domID='remitfilter_adv_matched'
                                                    label='Matched'
                                                    checked={this.getData(this.props.dataStore.ui.filter?.Filter?.RemitState, '@Matched') === 'M' ||
                                                        this.getData(this.props.dataStore.ui.filter?.Filter?.RemitState, '@All') === 'true'}
                                                    onChange={(e: React.SyntheticEvent) => this.onSelectRemitState('Matched')}
                                                    disabled={this.state.disableMatched}
                                                />
                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ marginBottom: '2px' }}>
                                                <Checkbox
                                                    domID='remitfilter_adv_unmatched'
                                                    label='Unmatched'
                                                    checked={this.getData(this.props.dataStore.ui.filter?.Filter?.RemitState, '@Unmatched') === 'U' ||
                                                        this.getData(this.props.dataStore.ui.filter?.Filter?.RemitState, '@All') === 'true'}
                                                    onChange={(e: React.SyntheticEvent) => this.onSelectRemitState('Unmatched')}
                                                    disabled={this.state.disableUnmatched}
                                                />

                                            </ContentRowWrapper>
                                        </DialogFieldset>
                                        <DialogFieldset id={'remitfilter_adv_remitstatus'}  >
                                            <DialogLegend>Remit Status</DialogLegend>

                                            <ContentRowWrapper style={{ marginBottom: '2px' }}>
                                                <Checkbox
                                                    domID='remitfilter_adv_paid'
                                                    label='Paid'
                                                    checked={this.getData(this.props.dataStore.ui.filter?.Filter?.RemitStatus, '@Paid') === 'P' ||
                                                        this.getData(this.props.dataStore.ui.filter?.Filter?.RemitStatus, '@All') === 'true'}
                                                    onChange={(e: React.SyntheticEvent) => this.onSelectRemitStatus('Paid')}
                                                />
                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ marginBottom: '2px' }}>
                                                <Checkbox
                                                    domID='remitfilter_adv_denied'
                                                    label='Denied'
                                                    checked={this.getData(this.props.dataStore.ui.filter?.Filter?.RemitStatus, '@Denied') === 'D' ||
                                                        this.getData(this.props.dataStore.ui.filter?.Filter?.RemitStatus, '@All') === 'true'}
                                                    onChange={(e: React.SyntheticEvent) => this.onSelectRemitStatus('Denied')}
                                                />
                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ marginBottom: '2px' }}>
                                                <Checkbox
                                                    domID='remitfilter_adv_pending'
                                                    label='Pending'
                                                    checked={this.getData(this.props.dataStore.ui.filter?.Filter?.RemitStatus, '@Pending') === 'W' ||
                                                        this.getData(this.props.dataStore.ui.filter?.Filter?.RemitStatus, '@All') === 'true'}
                                                    onChange={(e: React.SyntheticEvent) => this.onSelectRemitStatus('Pending')}
                                                />
                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ marginBottom: '2px' }}>
                                                <Checkbox
                                                    domID='remitfilter_adv_other'
                                                    label='Other'
                                                    checked={this.getData(this.props.dataStore.ui.filter?.Filter?.RemitStatus, '@Other') === 'O' ||
                                                        this.getData(this.props.dataStore.ui.filter?.Filter?.RemitStatus, '@All') === 'true'}
                                                    onChange={(e: React.SyntheticEvent) => this.onSelectRemitStatus('Other')}
                                                />
                                            </ContentRowWrapper>
                                        </DialogFieldset>
                                        <DialogFieldset id={'remitfilter_adv_remitservicetype'}  >
                                            <DialogLegend>Service Type</DialogLegend>

                                            <ContentRowWrapper style={{ marginBottom: '2px' }}>
                                                <Checkbox
                                                    domID='remitfilter_adv_inpatient'
                                                    label='Inpatient'
                                                    checked={this.getData(this.props.dataStore.ui.filter?.Filter?.ServiceType, '@Inpatient') === 'I' ||
                                                        this.getData(this.props.dataStore.ui.filter?.Filter?.ServiceType, '@All') === 'true'}
                                                    onChange={(e: React.SyntheticEvent) => this.onSelectServiceType('Inpatient')}
                                                />
                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ marginBottom: '2px' }}>
                                                <Checkbox
                                                    domID='remitfilter_adv_outpatient'
                                                    label='Outpatient'
                                                    checked={this.getData(this.props.dataStore.ui.filter?.Filter?.ServiceType, '@Outpatient') === 'O' ||
                                                        this.getData(this.props.dataStore.ui.filter?.Filter?.ServiceType, '@All') === 'true'}
                                                    onChange={(e: React.SyntheticEvent) => this.onSelectServiceType('Outpatient')}
                                                />
                                            </ContentRowWrapper>
                                        </DialogFieldset>
                                        <DialogFieldset id={'remitfilter_adv_source'}  >
                                            <DialogLegend>Source</DialogLegend>

                                            <ContentRowWrapper style={{ marginBottom: '2px' }}>
                                                <Checkbox
                                                    domID='remitfilter_adv_directentry'
                                                    label={this.getDirectEntry() ? 'Direct Entry' : 'Aii'}
                                                    checked={this.getData(this.props.dataStore.ui.filter?.Filter?.Source, '@Aii') === 'A' ||
                                                        this.getData(this.props.dataStore.ui.filter?.Filter?.Source, '@All') === 'true'}
                                                    onChange={(e: React.SyntheticEvent) => this.onSelectSource('Aii')}
                                                />
                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ marginBottom: '2px' }}>
                                                <Checkbox
                                                    domID='remitfilter_adv_ra'
                                                    label='R/A'
                                                    checked={this.getData(this.props.dataStore.ui.filter?.Filter?.Source, '@RA') === 'R' ||
                                                        this.getData(this.props.dataStore.ui.filter?.Filter?.Source, '@All') === 'true'}
                                                    onChange={(e: React.SyntheticEvent) => this.onSelectSource('RA')}
                                                />
                                            </ContentRowWrapper>
                                        </DialogFieldset>

                                        <DialogFieldset id={'remitfilter_adv_claimcandidates'}  >
                                            <DialogLegend>Claim Candidates</DialogLegend>

                                            <ContentRowWrapper style={{ marginBottom: '2px' }}>
                                                <Checkbox
                                                    domID='remitfilter_adv_lastname'
                                                    label='Last Name/PCN'
                                                    checked={this.getData(this.props.dataStore.ui.filter?.Filter?.Candidates_N, '@NameControl') === 'Y'}
                                                    onChange={(e: React.SyntheticEvent) => this.handleSelectCandidates('CandidatesN')}
                                                    disabled={this.process_lowercase.indexOf("postingfile") > -1 || this.process_lowercase == "retrieve835" || this.state.disableClaimCandidates}
                                                />
                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ marginBottom: '2px' }}>
                                                <Checkbox
                                                    domID='remitfilter_adv_secondary'
                                                    label='Secondary/Tertiary'
                                                    checked={this.getData(this.props.dataStore.ui.filter?.Filter?.Candidates_S, '@Secondary') === 'Y'}
                                                    onChange={(e: React.SyntheticEvent) => this.handleSelectCandidates('CandidatesS')}
                                                    disabled={this.process_lowercase.indexOf("postingfile") > -1 || this.process_lowercase == "retrieve835" || this.state.disableClaimCandidates}
                                                />
                                            </ContentRowWrapper>
                                        </DialogFieldset>
                                    </FilterColumn3>


                                </ContentRowWrapper>
                            }
                        </RemitFilterContent>
                    </ContentWrapper>
                    <ModalFilterList
                        isOpen={!!this.props.dataStore.ui.isFilterListOpen}
                        onModalToggle={() => { this.onCloseFilterList() }}
                        title={"Save Remit Filter"}
                        filters={this.getRemitFilterList(false)}
                        onConfirm={(filterListSelection: IModalFilterListSelection) => this.onConfirmFilterList(filterListSelection)}
                        onDeny={() => this.onCloseFilterList()}

                    />
                    <ModalConfirmation
                        isOpen={!!this.props.dataStore.ui.saveFilterNcsAlert && this.props.dataStore.ui.saveFilterNcsAlert.length > 0}
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearNCS(e)}
                        message={this.props.dataStore.ui.saveFilterNcsAlert}
                        okText={"Yes"}
                        cancelText={"No"}
                        showDefaultClose={true}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onConfirmNCS(e)}
                    />
                    <ModalConfirmation
                        isOpen={!!this.props.dataStore.ui.filterAlert && this.props.dataStore.ui.filterAlert.length > 0}
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearAlert(e)}
                        message={this.props.dataStore.ui.filterAlert}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClearAlert(e)}
                    />
                    <ModalConfirmation
                        isOpen={!!this.props.dataStore.ui.remitFilterErrorHandler && this.props.dataStore.ui.remitFilterErrorHandler.length > 0}
                        onModalToggle={() => this.onClearRemitFilterErrorHandler()}
                        message={this.props.dataStore.ui.remitFilterErrorHandler}
                        onConfirm={() => this.onClearRemitFilterErrorHandler()}
                    />
                    <ModalConfirmation
                        isOpen={!!this.props.dataStore.ui.loadedFilterErrorMessage}
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearAlert(e)}
                        message={this.props.dataStore.ui.loadedFilterErrorMessage}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClearLoadedFilterErrorMessage(e)}
                        onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.onClearLoadedFilterErrorMessage(e)}
                    />
                </DialogWrapper>
                {this.renderLegacyGoTo()}
            </>
        );
    }

};



var connectedHoc = connect<IRemitFilterState, IRemitFilterActionProps, IRemitFilterOwnProps, IRemitFilterProps, ApplicationState>(
    createApiMapStateToProps('remitFilter'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(RemitFilter);

export default withRouter(connectedHoc);