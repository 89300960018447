export const claimsDetailColumn = [
    {
        id: 0,
        dataName: "patientName",
        text: "Patient Name",
        sortable: true,
        isSorted: 0,
        width: 170,
        // sortIndicator: "up",
        // sortDataType: 1,
    },
    {
        id: 1,
        dataName: "controlNo",
        text: "Control #",
        sortable: true,
        isSorted: 0,
        // sortIndicator: "up",
        // sortDataType: 1
    },
    {
        id: 2,
        dataName: "totalCharges",
        text: "Total Charges",
        sortable: true,
        isSorted: 0,
        width: 130,
        // sortIndicator: "up",
        // sortDataType: 2
    },
    {
        id: 3,
        dataName: "typeOfBill",
        text: "TOB",
        sortable: true,
        isSorted: 0,
        width: 75,
        // sortIndicator: "up",
        // sortDataType: 1
    },
    {
        id: 4,
        dataName: "formType",
        text: "Form",
        sortable: true,
        isSorted: 0,
        width: 80,
        // sortIndicator: "up",
        // sortDataType: 1
    },
    {
        id: 5,
        dataName: "payerName",
        text: "Payer Name",
        sortable: true,
        isSorted: 0,
        width: 120,
        // sortIndicator: "up",
        // sortDataType: 1
    },
    {
        id: 6,
        dataName: "assigned",
        text: "Assigned",
        sortable: true,
        isSorted: 0,
        width: 105,
        // sortIndicator: "up",
        // sortDataType: 1
    },
      {
        id: 7,
        dataName: "ViewCFI",
        text: "View In CFI",
        sortable: false,
        isSorted: 0,
        // sortIndicator: "up",
        // sortDataType: 1,
        cellType: 'button',
    },
]