import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Modal, LoadingIndicator } from '@optum-uicl/ui-core/dist';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper } from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { SelectComponent } from '@common/SelectComponent';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { Input } from '@common/UICLWrappers/ARMInput';
import { SelectDropdown } from '@optum-uicl/ui-core/dist';
import { TextArea } from '@common/UICLWrappers/ARMTextArea';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { defaultState as defaultUIState } from '@store/ui/ManageClaimAssigmentsUI';
import { IErrorRuleDepartmentState, IErrorRuleDepartmentActions, IErrorRuleDepartmentActionProps, actionCreators, validationCallback } from '@store/ManageDepartmentAssignments';
import { ArrowButtons } from '@common/ArrowButtons';
import _ from 'lodash';
import UserList from '../../Reports/Filter/UserList';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const EmptyHeader = styled.div``;

const ManageClaimLeftContent = styled.div`
    display: flex;
    flex: 1 1 auto;   
    flex-direction: column;
    padding: 5px;
    width: 340px;
    #department-fieldset {
    width:100%;
    }
`;

const ManageClaimContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    min-height: 180px;
    min-height: 180px;
    width: 725px;
    #department-code,#select-priority{
    width:250px;
    margin-bottom:5px;
    text-transform: uppercase;
}
`;

export const SelectList = styled.div`
    padding: 5px;
`;

export const ArrowButtonsAlignmentAdjustment = styled.div`
    position: relative;
    // left: -8px;
`;

export const SelectActions = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
   input {
        width: 300px;
    }

`;

export const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;

    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

export const DialogColumnFieldset = styled.fieldset`
    border: 1px solid ${Colors.darkGrey};
    padding: 5px;
    margin: 5px;
    display: flex;
     flex: 1 1 auto;
    flex-direction: row;
`;

interface IComponentProps {
};

interface IComponentState {
    cancelLeave: boolean;
    validationModalMessage: string;
    isValidationModalOpen: boolean;
    disableUpdateDetailsButton: boolean;
    editingPriority: boolean;
    alphaSplit: string;
};

export const DEFAULT_STATE: IComponentState = {
    cancelLeave: false,
    validationModalMessage: "",
    isValidationModalOpen: false,
    disableUpdateDetailsButton: true,
    editingPriority: false,
    alphaSplit:""
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IManageDepartmentTypeProps = IMergeCrudComponentProps<IErrorRuleDepartmentState, IErrorRuleDepartmentActionProps, IOwnProps>;

enum eModifyButtonType {
    Add,
    Update
}

export class ManageClaimAssigments extends React.Component<IManageDepartmentTypeProps, IComponentState> {

    static defaultProps: IComponentProps = {
    };

    constructor(props: IManageDepartmentTypeProps) {

        super(props);
        this.state = DEFAULT_STATE;
      
    }

    public componentDidMount() {
        pageLeave();
        this.props.action.crud.get({ crudId: CrudTypes.mctiClaimAssignmentDepartment }, validationCallback);
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }


    public crudLoaded(): boolean {
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.ErrorRuleDepartmentInfo &&
            this.props.dataStore.crud.data.ErrorRuleDepartmentInfo.ErrorRuleDepartments &&
            this.props.dataStore.crud.data.ErrorRuleDepartmentInfo.UserList
        )
            return true;

        return false;
    }


    public onSelectDeptType(e: React.ChangeEvent<HTMLSelectElement>) {

        this.setState({
            disableUpdateDetailsButton: true
        })

        //console.log(e.target.options.selectedIndex)
   
        this.props.action.ui.selectDepartment(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    value: e.target.value,
                    text: e.target.options[e.target.options.selectedIndex].textContent
                }
            });
     
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {

        //return limbo users Enabled=(3) to Enabled=(0)

        let temp = new Array<any>();
        let final = new Array<any>();

      
           
        if (this.props.dataStore.crud.data
            && this.props.dataStore.crud.data.ErrorRuleDepartmentInfo.UserList) {

            let purgatorySweep = (this.props.dataStore.crud.data && this.props.dataStore.crud.data.ErrorRuleDepartmentInfo &&
                this.props.dataStore.crud.data.ErrorRuleDepartmentInfo.UserList);

            temp.push(purgatorySweep)

            final = temp[0]['User'];

            if (final !== undefined) {
                final.forEach(user => {
                    if (user["@Enabled"] == "3") {
                        user["@Enabled"] = "0"
                    }
                })
            }


                 this.props.dataStore.crud.data.ErrorRuleDepartmentInfo.UserList = JSON.parse(JSON.stringify(final))

        }


        //return false;

        this.props.action.crud.updateWithDelta(this.props.dataStore.crud);
        this.props.history.push("/LandingPage");
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    public getModifyButtonType(): eModifyButtonType {
        switch (this.props.dataStore.ui.selectedDepartment.index) {
            case 0:
                return eModifyButtonType.Add;
                break;
            default:
                return eModifyButtonType.Update;
                break;
        }
    }

    public getModifyButtonText() {
        switch (this.getModifyButtonType()) {
            case eModifyButtonType.Add:
                return 'Add';
            default:
                return 'Update';
        }
    }



    public onClickModifyButton(e: React.SyntheticEvent) {


        if (this.props.dataStore.crud.data) {

            let duplicate = this.props.dataStore.crud.data.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment.find(existingNoteType => existingNoteType["@Code"] === this.props.dataStore.ui.departmentCode.toUpperCase());
      
                switch (this.getModifyButtonType()) {
                    case eModifyButtonType.Add:

                        if (this.props.dataStore.ui.departmentCode === "") {
                            this.setState({
                                isValidationModalOpen: true,
                                validationModalMessage: `Department Code must not be blank.`
                            })
                        }
                       else if (duplicate) {

                            this.setState({
                                isValidationModalOpen: true,
                                validationModalMessage: `You must enter unique department code.`
                            })
                        }

                        else {
                               this.props.action.ui.addDept({
                               masterCrud: this.props.dataStore.crud.data,
                                    uiData: {
                                        '@ID': "#" + (this.props.dataStore.crud.data.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment.length + 1),
                                        '@Code': this.props.dataStore.ui.departmentCode.toUpperCase(),
                                        '@Priority': this.props.dataStore.ui.departmentPriority+"",
                                        '@DisplayName': this.props.dataStore.ui.displayName,
                                        'Users' : ""
                                        }
                                    });

                            this.buildPriorityList(this.props.dataStore.ui.departmentPriority);
                        }

                        break;
                    default:

                        //need another check that uses the index of the found duplicate
                      //  console.log('SELECTED_INDEX', this.props.dataStore.ui.selectedDepartment.index);
                   
                        if (this.props.dataStore.ui.departmentCode === "") {
                            this.setState({
                                isValidationModalOpen: true,
                                validationModalMessage: `Department Code must not be blank.`
                            })

                        }

                        else if (duplicate && duplicate?.["@ID"] !== this.props.dataStore.ui.selectedDepartment.value) {

                            this.setState({
                                isValidationModalOpen: true,
                                validationModalMessage: `You must enter unique department code.`
                            })
                        }

                        else {

                            this.props.action.ui.updateDept({
                                masterCrud: this.props.dataStore.crud.data,
                                uiData: {
                                    '@ID': this.props.dataStore.ui.selectedDepartment.value,
                                    '@Code': this.props.dataStore.ui.departmentCode.toUpperCase(),
                                   // '@Priority': this.props.dataStore.ui.departmentPriority,
                                    '@DisplayName': this.props.dataStore.ui.displayName,
                                }
                            });

                        }
                     
                }
            
        }
    }


    onToggleConfirmation(e: React.MouseEvent<HTMLElement>) {
        this.props.action.confirm.closeConfirm();
    }

    public onClickConfirm(e: React.MouseEvent<HTMLButtonElement>) {
        handleChange();
        this.props.dataStore.confirm.confirmCallback();
    }

    public resetDept() {

        this.props.action.ui.resetDept({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {

            }
        });

        this.props.action.ui.selectDepartment(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: 0,
                    value: '',
                    text: ''
                }
            });

    }


    public onClickRemoveButton(e: React.SyntheticEvent) {
        this.props.action.confirm.openConfirm("Do you want to remove " + this.props.dataStore.ui.selectedDepartment.text + "? This action cannot be undone.", () => this.onClickConfirmRemoveDept());
    }

    public onClickConfirmRemoveDept() {
        this.props.action.ui.removeDept({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                'index': this.props.dataStore.ui.selectedDepartment.index,
                'value': this.props.dataStore.ui.selectedDepartment.value,
                'text': this.props.dataStore.ui.selectedDepartment.text
            }
        });


        this.resetDept();
    }



    //Build Priority List For Dropdown
    public buildPriorityList(remove : string="") {

        let selectedPriority = this.props.dataStore.ui.departmentPriority

        let list = (this.props.dataStore.crud.data && this.props.dataStore.crud.data.ErrorRuleDepartmentInfo &&
            this.props.dataStore.crud.data.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment);

        if (!list)
            return [];

        let priorities: any[] = [];
        let diff = new Array<any>();   
        let filtered = new Array<any>();   
        let final = new Array<any>();   
        let options = new Array<any>();   

        list.forEach(dept => {
            priorities.push(dept['@Priority']);
        });

        //populate initial priority list starting form 1
        if (options.length===0) {
            for (let index = 1; index < 100; index++) {

                options.push(index);

            }
        }
        

        diff = _.difference(priorities, options);

        filtered = options.filter(item => !diff.includes(item + ""));

        filtered.forEach(item => {
            final.push({ label: item + "", value: item });
        })

       
       return final;
    }


    public getUsersList(lookupList: any | any, selectedUsers: any | any, listToReturn: string) {

        if (this.crudLoaded()) {
            let orig = new Array<any>();
            let origSelected = new Array<any>();
            let diff = new Array<any>();
            let filtered= new Array<any>();
            let result = new Array<any>();   
            let orphan = new Array<any>();   

            orig = lookupList['User'];
            origSelected = selectedUsers['Users']['User'];

            if (!Array.isArray(orig)) {

              //  console.log('Array', [{ User: lookupList['User'] }]);
                orphan.push({ User: lookupList['User'] })

                return [{ User: orphan}]
            }


           
            switch (listToReturn) {
                case 'this-department':

                    let ids = _.map(lookupList['User'], '@ID');

                   // console.log('origSelected',origSelected)

                    if (origSelected == undefined || origSelected.length===0) {
                        return [];
                    }

                   // console.log('collection', this.props.dataStore.ui.collection);
                   // console.log('collectionRemoval', this.props.dataStore.ui.collectionRemoval);

                    if (origSelected !== undefined) {
                        if (origSelected.length > 0) {
                            origSelected.forEach(origSelected => {
                                orig.forEach(orig => {
                                    if (origSelected['@ID'] === orig['@ID']) {
                                        if (orig['@Enabled'] == "0" || orig['@Enabled'] == "3") {
                                            if (!orig['@Name'].includes("*")) {
                                                orig['@Name'] = "*" + orig['@Name']
                                            }   
                                        }
                                        result.push(orig);
                                    }
                                })
                            })
                        }

                        if (result.length === 0) {
                            orphan.push(origSelected);
                            result = orig.filter(item => item['@ID'] === orphan[0]['@ID']);
                            //add asterisk to orphan user
                            if (result[0]['@Enabled'] == "0" || result[0]['@Enabled'] == "3") {
                                if (!result[0]['@Name'].includes("*")) {
                                    result[0]['@Name'] = "*" + result[0]['@Name']
                                }
                            }
                        }
                        return result;
                    }

                    break;
                case 'all-users':

                    let allUserIds = _.map(lookupList['User'], '@ID');
                    let selectedUserIds = _.map(selectedUsers['Users']['User'], '@ID');


                    if (origSelected !== undefined) {
                        diff = _.difference(allUserIds, selectedUserIds);
                          if (origSelected.length >= 0) {

                              filtered = orig.filter(item => diff.includes(item['@ID']));

                           }

                        if (filtered.length === 0) {

                            orphan.push(origSelected);

                            filtered = orig.filter(item => item['@ID'] != orphan[0]['@ID']);
                        }

                       if (origSelected.length === allUserIds.length) {
                            return []
                       } else {
                           //Remove Disabled from final result
                           filtered.forEach((user, index) => {
      
                               if (user['@Enabled'] === "0") {
                                   filtered.splice(index, 1)
                               }
                           })
                           //Ensures no disabled orphan returns to list.
                           filtered = filtered.filter(item => item['@Enabled'] != "0");
                             return filtered;
                        }

                    } else {
                       // console.log('sent_orig');
                        orig = orig.filter(item => item['@Enabled'] != "0");
                        return orig;
                    } 

                default:

                    return orig;
            }
        }

    }

    public getSelectedOptions(e: React.ChangeEvent<HTMLSelectElement>) {
        if (!e.target.options || e.target.options.length === 0) return [];
        let result = [];

        for (let i = 0; i < e.target.options.length; i++) {
            if (e.target.options[i].selected)
                result.push(e.target.options[i].value);
        }

        return result;
    }


    public onSelectUser(e: React.ChangeEvent<HTMLSelectElement>, allUsers: any | any, selected: any | any) {

        const {
            action
        } = this.props;

        this.setState({
            disableUpdateDetailsButton: false
        })


        let foundUser = _.find(allUsers['User'], { '@ID': e.target.value });
        let item = new Array<any>();
        let final = new Array<any>();
        let foundAlphaSplit = new Array<any>();


        if (!e.target.options || e.target.options.length === 0) return [];
        let result = [];

        for (let i = 0; i < e.target.options.length; i++) {
            if (e.target.options[i].selected)
                result.push(e.target.options[i].value);
        }



        action.ui.selectedUsers(this.getSelectedOptions(e));


        item.push(selected['Users']['User']);

        if (Array.isArray(item[0])) {

            final = _.find(item[0], { '@ID': e.target.value });

        } else {

            final = item[0];
        } 

        foundAlphaSplit.push(final);

        this.props.action.ui.selectedUser({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                '@Name': foundUser['@Name'],
                '@ID': foundUser['@ID'],
                '@AlphaSplit': foundAlphaSplit[0]['@AlphaSplit']
            }
        });


    }


    public addUser(e: React.ChangeEvent<HTMLButtonElement>): void {
        const {
            dataStore,
            action
        } = this.props;

        action.ui.addUsers({
            masterCrud: dataStore.crud.data,
                uiData: {

                    '@ID': dataStore.ui.availableUsers["@ID"],
                    '@Code': dataStore.ui.availableUsers["@Code"].toUpperCase(),
                    '@Priority': dataStore.ui.availableUsers["@Priority"],
                    '@DisplayName': dataStore.ui.availableUsers["@DisplayName"],
                    'Users': dataStore.ui.availableUsers.Users

            }
        });
    }

    public removeUser(e: React.ChangeEvent<HTMLButtonElement>): void {
        const {
            dataStore,
            action
        } = this.props;

        if (dataStore.ui.collectionRemoval.length == 0) {
            this.setState({
                isValidationModalOpen: true,
                validationModalMessage: `Please make a selection from the list.`
            })
        } else {
              // console.log('REMOVE', dataStore.ui.selectedUsers);

                    action.ui.removeUsers({
                        masterCrud: dataStore.crud.data,
                        uiData: {

                            '@ID': dataStore.ui.availableUsers["@ID"],
                            '@Code': dataStore.ui.availableUsers["@Code"].toUpperCase(),
                            '@Priority': dataStore.ui.availableUsers["@Priority"],
                            '@DisplayName': dataStore.ui.availableUsers["@DisplayName"],
                            'Users': dataStore.ui.selectedUsers.Users

                        }
                    });

        }

    }
/*

    public onUpdateDeptCode(deptCode: string) {
       // this.props.action.ui.updateDepartmentCode(deptCode);

        const {
            dataStore,
            action
        } = this.props;

        this.setState({
            disableUpdateDetailsButton: true
        })

      //  dataStore.ui.departmentCode = deptCode;

        action.ui.updateDept({
            masterCrud: dataStore.crud.data,
            uiData: {
                '@ID': dataStore.ui.selectedDepartment.value,
                '@Code': deptCode.toUpperCase(),
                '@Priority': dataStore.ui.departmentPriority,
                '@DisplayName': dataStore.ui.displayName,
            }
        });
    }
*/

    public checkSelectionMade() {

        const {
            dataStore,
        } = this.props;

        if (dataStore.ui.collection.length == 0) {
            this.setState({
                isValidationModalOpen: true,
                validationModalMessage: `Please make a selection from the list.`
            })
        }

    }


    public onUpdateDeptPriority(priority: string) {

       // this.props.action.ui.updateDepartmentPriority(priority);

        const {
            dataStore,
            action
        } = this.props;

        this.setState({
            disableUpdateDetailsButton: true
        })

        dataStore.ui.departmentPriority = priority;

        action.ui.updateDept({
            masterCrud: dataStore.crud.data,
            uiData: {
               // '@ID': dataStore.ui.selectedDepartment.value,
              //  '@Code': dataStore.ui.departmentCode.toUpperCase(),
                '@Priority': priority,
             ///   '@DisplayName': dataStore.ui.displayName,
            }
        });
    }

    public onUpdateAlphaSplit(alphaSplit: any, userId : any) {

        const {
            dataStore,
            action
        } = this.props;


        action.ui.updateAlphaSplit(
            {
                masterCrud: dataStore.crud.data,
                uiData: {
                    '@ID': userId,
                    '@AlphaSplit': alphaSplit
                }
            });
    }


    public onSelectAvailableUsers(e: React.ChangeEvent<HTMLSelectElement>) {
        const {
            dataStore,
            action
        } = this.props;

        this.setState({
            disableUpdateDetailsButton: true
        })

        action.ui.availableUsers(this.getSelectedOptions(e));
        this.collectAlphaSplit("");
        this.props.action.ui.resetAlpha();
  
    }

    public collectAlphaSplit(alphaSplit: string) {

        const {
            dataStore,
        } = this.props;

        this.setState({ alphaSplit: alphaSplit })

        dataStore.ui.selectedUser["@AlphaSplit"] = alphaSplit
    }

    //prepare departments

    public addDisplayName() {
      
        let list = (this.props.dataStore.crud.data && this.props.dataStore.crud.data.ErrorRuleDepartmentInfo &&
            this.props.dataStore.crud.data.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment);

        if (!list)
            return [];

        list.forEach(dept => {
            dept['@DisplayName'] = dept['@Code'] + " - " + (dept['@Priority'] === "0" || dept['@Priority'] === "" ? 'N/A' : dept['@Priority'])
        });

    }

    



    public render() {

        var addItem: ErrorRuleDepartment[] = [{
            '@ID': '',
            '@Code': '- ADD A NEW DEPARTMENT -',
            '@Priority': '- SELECT PRIORITY -',
            '@DisplayName': '- ADD A NEW DEPARTMENT -',
            Users: []
        }]



        const {
            dataStore,
        } = this.props;

        const {
            isValidationModalOpen,
            validationModalMessage,
            disableUpdateDetailsButton
        } = this.state


        //  console.log('DATA_STORE', dataStore);

        var instruction = <>Create and populate the departments used by the Manage Claim Assignment rules..<br /> <i>(For more information, click the help button.)</i></>;
        var buttons = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;

        this.addDisplayName();

        let data: any;
        let allUsers: any;
        let deptList: any;

        data = dataStore.crud.data ? addItem.concat(dataStore.crud.data.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment) : addItem
        allUsers = dataStore.crud.data?.ErrorRuleDepartmentInfo.UserList;

      //  deptList = this.getDepartments()


  
        return (
            <DialogWrapper title="Manage Claim Assignment Department and Priority" instruction={instruction} helpUrl='/Support/Help/HELP_Maint_ClaimAssignment_Departments.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'} >
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentRowWrapper>
                    <ManageClaimLeftContent>
                        <DialogFieldset id='department-fieldset'>
                            <DialogLegend>Departments</DialogLegend>
                            <SelectComponent
                                title='departments'
                                size={10}
                                width='100%'
                                height='517px'
                        
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectDeptType(e)}
                                optionFields={{
                                    value: "@ID",
                                    text: "@DisplayName",
                                }}
                                records={data}
                                selectedValue={this.props.dataStore.ui.selectedDepartment.value}
                             
                            > 
                            </SelectComponent>
                           
                        </DialogFieldset>
                    </ManageClaimLeftContent>
                    <ManageClaimContent>
                        <SelectActions>
                            <Input
                                domID="department-code"
                                label="Department Code:"
                                maxLength={10}
                                //disabled={this.state.disabled}
                                initialValue={this.props.dataStore.ui.selectedDepartment.value === "" ? "" : this.props.dataStore.ui.departmentCode}
                                //onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onUpdateDeptCode(e.target.value)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleChange()
                                    this.props.action.ui.updateDepartmentCode(e.target.value)
                                }}
                                autoComplete="off"

                            />

                            <SelectDropdown
                                domID="select-priority"
                                className="dropdown"
                                label="Priority:"
                                size='small'
                                menuWidth={250}
                                isClearable={false}
                                isSearchable={false}
                                initialValue={{
                                    label: (dataStore.ui.departmentPriority == '' || dataStore.ui.departmentPriority == '0'  ? '- SELECT PRIORITY -' : dataStore.ui.departmentPriority),
                                    value: (dataStore.ui.departmentPriority == '' || dataStore.ui.departmentPriority == '0'? '- SELECT PRIORITY -' : dataStore.ui.departmentPriority
                                    )
                                }}
                                options={this.buildPriorityList()}
                                onChange={(e: any) => {
                                    handleChange()
                                    this.props.action.ui.updateDepartmentPriority(e.value + "")
                                    this.setState({disableUpdateDetailsButton: true})
                                }
                                }
                            />

                            <SelectButtons>
                                <Button
                                    domID="addButton"
                                    name={this.getModifyButtonText()}
                                    buttonType="emphasized"
                                    size="control"
                                    type="button"
                                    onClick={(e: React.SyntheticEvent) => {
                                        handleChange()
                                        this.onClickModifyButton(e)

                                    }}
                                />
                                <Button
                                    domID="removeButton"
                                    name="Remove"
                                    buttonType="standard"
                                    size="control"
                                    type="button"
                                    disabled={dataStore.ui.selectedDepartment.index === 0}
                                    //onClick={(e: React.MouseEvent<HTMLButtonElement>) => {this.onClickRemoveButton(e)}
                                    onClick={(e: React.SyntheticEvent) => {
                                        handleChange()
                                        this.onClickRemoveButton(e)

                                    }}
                                />
                            </SelectButtons>
                        </SelectActions>
                        <DialogColumnFieldset>
                            <DialogLegend>Users</DialogLegend>
                            <ContentRowWrapper>
                        <SelectComponent
                                    label='All Users:'
                                    title='all-users'
                                    size={10}
                                    width='315px'
                                    height='125px'
                                    optionFields={{
                                        value: "@ID",
                                        text: "@Name",
                                    }}
                                    records={this.getUsersList(allUsers, this.props.dataStore.ui.selectedUsers, 'all-users')}
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectAvailableUsers(e)}
                                
                                 multiple='true'
                                ></SelectComponent>
                                <ArrowButtonsAlignmentAdjustment>
                                    <ArrowButtons
                                        paddingTop='50px'
                                        width='1px'
                                        margin='0 0 0 0'
                                        toolTipLeft=' Remove User(s) from Selected Group'
                                        toolTipRight=' Add User(s) to Selected Group'
                                        onLeftBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                            handleChange()
                                            this.removeUser(e)
                                        }
                                        }
                                        onRightBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) =>
                                            
                                        {   this.checkSelectionMade()
                                            this.addUser(e)
                                            handleChange()
                                        }
                                        }
                                        disabled={dataStore.ui.selectedDepartment.index ===0}
                                    ></ArrowButtons>
                                </ArrowButtonsAlignmentAdjustment>
                           <SelectComponent
                                    label='This Department:'
                                    title='this-department'
                                    size={10}
                                    width='333px'
                                    height='125px'
                                    optionFields={{
                                        value: "@ID",
                                        text: "@Name",
                                    }}
                                    records={this.getUsersList(allUsers, dataStore.ui.selectedUsers, 'this-department')}
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectUser(e, allUsers, dataStore.ui.selectedUsers)}
                                    multiple='true'
                                ></SelectComponent>
                            </ContentRowWrapper>
                        </DialogColumnFieldset>
                        <DialogColumnFieldset>
                            <DialogLegend>Selected Department User Details</DialogLegend>
                            <ContentRowWrapper>
                                <SelectActions>
                                    <Input
                                        domID="user-name-selected"
                                        label="Username:"
                                        maxLength={35}
                                        disabled={true}
                                        initialValue={dataStore.ui.selectedUser['@Name']}
                                    />
                                    <Input
                                        domID="alpha-split"
                                        label="Alpha Split:"
                                        value={dataStore.ui.selectedUser['@AlphaSplit']}
                                        maxLength={40}
                                        initialValue={dataStore.ui.selectedUser['@AlphaSplit']}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleChange()
                                            this.collectAlphaSplit(e.target.value)
                                          }
                                        }
                                        autoComplete="off"
                                        style={{
                                            marginBottom: "10px"
                                        }}
                                    />



                                    <SelectButtons>
                                        <Button
                                            domID="addButton"
                                            name={'UPDATE'}
                                            buttonType="emphasized"
                                            size="control"
                                            type="button"
                                            onClick={(e: React.SyntheticEvent) => {
                                                handleChange()
                                                this.onUpdateAlphaSplit(dataStore.ui.selectedUser["@AlphaSplit"], dataStore.ui.selectedUser['@ID'])
                                            }}
                                            disabled={disableUpdateDetailsButton}
                                        />
                                    </SelectButtons>
                                </SelectActions>
                            </ContentRowWrapper>
                            <ModalConfirmation
                                isOpen={this.props.dataStore.confirm.isOpen}
                                onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleConfirmation(e)}
                                message={this.props.dataStore.confirm.message}
                                onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClickConfirm(e)}
                            />
                            <ModalConfirmation
                                title={"Message from webpage"}
                                isOpen={isValidationModalOpen}
                                alertMode={true}
                                onModalToggle={() => this.setState({ isValidationModalOpen: false, validationModalMessage: "" })}
                                formattedMessage={validationModalMessage}
                            />
                        </DialogColumnFieldset>
                    </ManageClaimContent>
                </ContentRowWrapper>
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IErrorRuleDepartmentState, IErrorRuleDepartmentActionProps, IOwnProps, IManageDepartmentTypeProps, ApplicationState>(
    createCrudMapStateToProps('manageDepartment'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ManageClaimAssigments);

export default withRouter(connectedHoc);
