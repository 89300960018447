import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Input, Button, DropdownButton, Help, Search, SelectDropdown } from '@optum-uicl/ui-core/dist';
import helpButtonIcon from '../../content/images/LandingPage/But_HelpUp.png';
import { ARMQuickSearchOptionsBar, ARMQuickSearchEntry, ARMQuickSearchOptionsLabel, ARMQuickSearchFilterOptions, SavedFilterDropdownWrapper, ARMQuickSearchLegacyPanelbuttons } from './styles/QuickSearchStyles';
import { ARMQuickSearchOptionsPanel, ARMQuickSearchOptionsPanelRow, ARMQuickSearchOptionsPanelTitle, ARMQuickSearchOptionsPanelHelp, ARMQuickSearchOptionsPanelRule, ARMQuickSearchOptionsPanelLabel, ARMQuickSearchEntryPanel, ARMQuickSearchEntryPanelButtons } from './styles/QuickSearchStyles';
import { LandingPageComponent } from './LandingPageComponent';
import { URLs } from '@commonDevResources/constants';
import { ModalConfirmation } from '@common/ModalConfirmation';
import * as wnd from '@commonResources/window';
import UserFilterForm, { IFilterFormProps } from '@common/UserFilterForm';
import { getRawToken } from '@scripts/session/SecurityToken';
import { ImageButton } from '../common/ImageButton';

export interface IARMQuickSearchProps {
    renderPanel?: boolean;
    onMessageAlert?: (message: string, type: string | "") => void;
}

interface IARMQuickSearchState {
    patientName: string;
    patientControlNumber: string;
    userCanViewClaims: boolean;
    isMedicare: boolean;
    savedFilterTitle: string;
    filterData?: any;
    filterFormState: IFilterFormProps,
    patientNameError?: string;
    patientControlNumberError?: string;
    errorMessage?: string;
}

const defaultFilterFormState: IFilterFormProps = {
    filterName: '',
    filterId: '0',
    process: 'HomePageFilter',
    parameter: '',
    filterType: 'Claim',
    restrictionsExist: 'N',
};

type IProps = IARMQuickSearchProps & RouteComponentProps<{}>;

export enum eClaimState {
    Unreleased = "1",
    Released = "3",
}

interface ISectionItem {
    label: string,
    id: number
}

interface ISections {
    title: string,
    sectionItems: ISectionItem[]
}

export class ARMQuickSearch extends React.Component<IProps, IARMQuickSearchState> {

    static defaultProps: IARMQuickSearchProps = {
        renderPanel: false,
        onMessageAlert: undefined
    };

    constructor(props: IProps) {
        super(props);
        this.state =
        {
            patientName: '',
            patientControlNumber: '',
            userCanViewClaims: false,
            isMedicare: false,
            savedFilterTitle: "Select Saved Filter",
            filterData: null,
            filterFormState: { ...defaultFilterFormState },
            patientNameError: undefined,
            patientControlNumberError: undefined,
            errorMessage: ''
        }
        this.onBlurPatientName = this.onBlurPatientName.bind(this);
        this.onPatientNameChanged = this.onPatientNameChanged.bind(this);
        this.onBlurPatientControlNumber = this.onBlurPatientControlNumber.bind(this);
        this.onPatientControlNumberChanged = this.onPatientControlNumberChanged.bind(this);
        this.handleUnreleasedClick = this.handleUnreleasedClick.bind(this);
        this.handleReleasedClick = this.handleReleasedClick.bind(this);
        this.handleMedicareClick = this.handleMedicareClick.bind(this);
        this.onSelectFilter = this.onSelectFilter.bind(this);
    }

    componentDidMount() {
        this.checkWarnings();
        let msgExists = sessionStorage.getItem("SystemErrorNotification");
        if (msgExists && msgExists.length > 0) {
            this.handleMessage(msgExists);
            sessionStorage.setItem('SystemErrorNotification', '');
            this.setState({ errorMessage: '' });
        }
        msgExists = sessionStorage.getItem("SystemWarningNotification");
        if (msgExists && msgExists.length > 0) {
            this.handleMessage(msgExists, "Warning");
            sessionStorage.setItem('SystemWarningNotification', '');
            this.setState({ errorMessage: '' });
        }

        this.checkUserRights();
        this.checkParallonSCType();
        this.loadSavedFilters();
    }

    checkWarnings() {
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState === xhr.DONE) {
                if (xhr.status === 200) {
                    var responseData = xhr.responseText;
                    if (responseData) {
                        let responseMsg: any = JSON.parse(responseData)?.find((rpd: { Type: string; }) => rpd.Type === 'Message');
                        if (responseMsg) {
                            sessionStorage.setItem('SystemWarningNotification', responseMsg.Name);
                        }
                    }
                }
            }
        };

        xhr.open('get', URLs.api + '/api/data/GetQuickLinks', false);
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send();
    }

    checkUserRights() {
        var xhr = new XMLHttpRequest();
        xhr.open('get', URLs.api + "/api/data/userrights/viewClaims", true);
        xhr.onload = () => {
            var data = JSON.parse(xhr.responseText);
            this.setState({ userCanViewClaims: data.viewClaims });
        };
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send();
    }

    checkParallonSCType() {
        var xhr = new XMLHttpRequest();
        xhr.open('get', URLs.api + "/api/data/GetParallonSCType", true);
        xhr.onload = () => {
            var data = JSON.parse(xhr.responseText);
            if (data.ParallonSCType) {
                this.setState({ isMedicare: data.ParallonSCType === 1 });
            }
        };
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send();
    }

    loadSavedFilters() {
        //    console.log("loadSavedFilters");
        var xhr = new XMLHttpRequest();
        xhr.open('get', URLs.api + "/api/data/GetFilters", true);
        xhr.onload = () => {
            var data = JSON.parse(xhr.responseText);
            //       console.log("loadSavedFilters setting state");
            this.setState({ filterData: data });
        };
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send();
    }

    onBlurPatientName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ patientName: event.target.value });
        this.checkInputs();
    }

    onPatientNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ patientName: event.target.value });
    }

    onBlurPatientControlNumber(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ patientControlNumber: event.target.value });
        this.checkInputs();
    }

    onPatientControlNumberChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ patientControlNumber: event.target.value });
    }

    handleUnreleasedClick() {
        if (this.checkInputs()) {
            const filterFormState = { ...defaultFilterFormState };
            filterFormState.xmlRequest = this.createFilterClaimRequest(eClaimState.Unreleased, this.state.patientName, this.state.patientControlNumber);
            this.setState({ filterFormState: filterFormState });
        }
    }

    handleReleasedClick() {
        if (this.checkInputs()) {
            const filterFormState = { ...defaultFilterFormState };
            filterFormState.xmlRequest = this.createFilterClaimRequest(eClaimState.Released, this.state.patientName, this.state.patientControlNumber);
            this.setState({ filterFormState: filterFormState });
        }
    }

    handleMedicareClick() {
        if (this.checkInputs()) {
            const filterFormState = { ...defaultFilterFormState };
            filterFormState.xmlRequest = this.createFilterMedicareRequest(this.state.patientName, this.state.patientControlNumber);
            this.setState({ filterFormState: filterFormState });
        }
    }

    handleMessage(message: string, type: string = "") {
        if (this.props.onMessageAlert) {
            this.props.onMessageAlert(message, type);
        } else {
            this.setState({ errorMessage: message });
        }
    }

    searchValueIsInvalid(value: string): boolean {
        return (value != '' && value.length < 3);
    }

    checkInputs() {
        let patName = this.state.patientName;
        let patCtrl = this.state.patientControlNumber;
        let errorMessage = '';
        this.setState({ patientNameError: '' });
        this.setState({ patientControlNumberError: '' });

        if (patName.indexOf(":") >= 0 || patCtrl.indexOf(":") >= 0) {
            errorMessage = 'Range searches are not permitted.';
            if (this.props.onMessageAlert) {
                this.props.onMessageAlert(errorMessage, '');
            } else {
                this.setState({ patientNameError: (patName.indexOf(":") >= 0) ? errorMessage : '' });
                this.setState({ patientControlNumberError: (patCtrl.indexOf(":") >= 0) ? errorMessage : '' });
            }
            return false;
        }
        else if (this.searchValueIsInvalid(patName) || this.searchValueIsInvalid(patCtrl)) {
            errorMessage = 'You must enter at least 3 characters to search by Patient Last Name and/or Control Number.';
            if (this.props.onMessageAlert) {
                this.props.onMessageAlert(errorMessage, '');
            }
            else {
                errorMessage = "You must enter at least 3 characters";
                if (patName.length > 0 && patName.length < 3) {
                    this.setState({ patientNameError: errorMessage });
                } else {
                    this.setState({ patientNameError: '' });
                    this.setState({ patientControlNumberError: (patCtrl.length > 0 && patCtrl.length < 3) ? errorMessage : '' });    
                }
            }
            return false;
        }
        return true;
    }

    createFilterClaimRequest(claimState: eClaimState, patientName?: string, patientControlNumber?: string) {
        var xmlRequest;

        xmlRequest = '<root Action="HomePageQuickSearch" Type="Claim" Process="HomePageQuickSearch"><Filter Type="Claim" ID="0">';

        if (patientControlNumber !== "")
            xmlRequest += '<AccountNumber Value="' + patientControlNumber + '"/>';

        if (patientName !== "")
            xmlRequest += '<PatientName Value="' + patientName + '"/>';

        xmlRequest += '<DisplayOrder ID="1" />';
        xmlRequest += '<ClaimState ID="' + claimState + '"/>';
        xmlRequest += '</Filter>';
        xmlRequest += '</root>';
        return xmlRequest;
    }

    createFilterMedicareRequest(patientName: string, patientControlNumber: string) {
        var xmlRequest = '<Filter Action="HomePageQuickSearchMedicare" AccountNumber="' + patientControlNumber + '" PatientName="' + patientName + '"/>';
        return xmlRequest;
    }

    createSavedFilterRequest(filterType: string, filterId: string) {
        var xmlRequest = '<Filter Action="HomePageQuickSearch" Type="' + filterType + '" Process="HomePageQuickSearch" ID="' + filterId + '"/>';
        return xmlRequest;
    }

    onSelectFilter(event?: React.SyntheticEvent<{}>, selectState?: any) {
        if (selectState) {
            const filterFormState = { ...defaultFilterFormState };
            filterFormState.filterId = selectState.activeItem.filterId;
            filterFormState.filterType = selectState.activeItem.filterType;
            filterFormState.xmlRequest = this.createSavedFilterRequest(filterFormState.filterType, filterFormState.filterId);
            this.setState({
                filterFormState: filterFormState,
                savedFilterTitle: selectState.activeItem.label
            });
        }
    }

    getSavedFiltersList() {
        let sections: ISections[] = [{ title: '- SELECT SAVED FILTER - ', sectionItems: [] }];

        if (this.state.filterData) {
            let filterList = null;
            let favFilters = null;
            let favHomeFilters = null;
            
            this.state.filterData.map((option: any) => {
                if (option.Type === "FilterList") {
                    filterList = this.getSavedFilters(option.Filters, "Other Saved Filters:").filter(fl => fl.id !== -1);
                }
                if (option.Type === "FavoriteFilters") {
                    favFilters = this.getSavedFilters(option.Filters, "Favorites:").filter(fl => fl.id !== -1);
                }
                if (option.Type === "FavoriteHomeFilters") {
                    favHomeFilters = this.getSavedFilters(option.Filters, "Home Page Favorites:").filter(fl => fl.id !== -1);
                }
            });
            if (favHomeFilters) {
                sections.push({ title: "Home Page Favorites:", sectionItems: favHomeFilters });
            }
            if (favFilters) {
                sections.push({ title: "Favorites:", sectionItems: favFilters });
            }
            if (filterList) {
                sections.push({ title: "Other Saved Filters:", sectionItems: filterList });
            }
        }

        return sections;
    }

    getSavedFilters(data: any, group: string): ISectionItem[] {
        let sectionItem: ISectionItem = { label: group, id: -1 };
        if (data) {
            var filters = data.map((item: any) => {
                var filterType = item.Id.substr(0, 1).toUpperCase() === "C" ? "Claim" : "Remit";
                var filterId = item.Id.substr(1);
                return {
                    id: item.Id,
                    label: item.Name,
                    filterType: filterType,
                    filterId: filterId,
                    isSecondary: true
                }
            });
            filters.unshift(sectionItem);
            return filters;
        }
        return [sectionItem];
    }

    onNoResults() {
        this.setState({ savedFilterTitle: "- SELECT SAVED FILTER -", filterFormState: defaultFilterFormState });
    }

    // TODO: Update with new <UserFilterForm>?
    renderUserFilterForm() {
        return (
            <UserFilterForm
                filterId={this.state.filterFormState.filterId}
                filterName={this.state.filterFormState.filterName}
                filterType={this.state.filterFormState.filterType}
                xmlRequest={this.state.filterFormState.xmlRequest}
                onMessage={(message: string) => this.handleMessage(message)}
                onNoData={() => this.onNoResults()}
             />
        );
    }

    renderQuickSearchEntryTitle() {
        return (
            <ARMQuickSearchOptionsLabel>Quick Search:</ARMQuickSearchOptionsLabel>
        );
    }

    renderQuickSearchEntryInput() {
        return (
            <React.Fragment>
                {this.renderQuickSearchEntryPatientNameTextBox(false)}
                {this.renderQuickSearchEntryPatientControlNumberTextBox(false)}
            </React.Fragment>
        );
    }

    renderQuickSearchEntryPatientNameTextBox(viewIsLegacy: boolean) {
        var placeholder = "Search by name";
        return (
            <>
            <div className={viewIsLegacy ? 'searchWithIconLegacy' : 'searchWithIcon'}>
                <Search
                    className='searchIcon'
                    size='small'
                    fillColor='#7D7F81' />
                <Input
                    className="quickSearchInput"
                    domID="patientNameTextBox"
                    placeholder={placeholder}
                    maxLength={255}
                    value={this.state.patientName}
                    disabled={!this.state.userCanViewClaims}
                    onBlur={this.onBlurPatientName}
                    onChange={this.onPatientNameChanged}
                    hasError={(this.state.patientNameError && this.state.patientNameError !== '') ? true : false}
                    errorMessage={this.state.patientNameError}
                />
            </div>
            </>
        );
    }

    renderQuickSearchEntryPatientControlNumberTextBox(viewIsLegacy: boolean) {
        var placeholder = "Search by control no.";
        return (
            <>
                <div className={viewIsLegacy ? 'searchWithIconLegacy' : 'searchWithIcon'}>
                    <Search
                        className='searchIcon'
                        size='small'
                        fillColor='#7D7F81' />
                    <Input
                        className="quickSearchInput"
                        domID="pcnTextBox"
                        placeholder={placeholder}
                        maxLength={255}
                        value={this.state.patientControlNumber}
                        disabled={!this.state.userCanViewClaims}
                        onBlur={this.onBlurPatientControlNumber}
                        onChange={this.onPatientControlNumberChanged}
                        hasError={(this.state.patientControlNumberError && this.state.patientControlNumberError !== '') ? true : false}
                        errorMessage={this.state.patientControlNumberError} />
                </div>  
            </>
            
        );
    }

    renderQuickSearchEntryButtons() {
        return (
            <React.Fragment>
                <Button
                    className="quickSearchButton"
                    disabled={(!this.state.userCanViewClaims) || ((this.state.patientName == '') && (this.state.patientControlNumber == ''))}
                    onClick={this.handleUnreleasedClick}
                    buttonType="emphasized"
                    size="control"
                ><span>{this.state.isMedicare ? 'Unreleased' : 'Unreleased'}</span></Button>
                <Button
                    className="quickSearchButton"
                    disabled={(!this.state.userCanViewClaims) || ((this.state.patientName == '') && (this.state.patientControlNumber == ''))}
                    onClick={this.handleReleasedClick}
                    buttonType="emphasized"
                    size="control"
                ><span>{this.state.isMedicare ? 'Released' : 'Released'}</span></Button>
                {this.state.isMedicare && 
                    <Button
                        className="quickSearchButton"
                        disabled={(!this.state.userCanViewClaims) || ((!this.state.patientName) && (!this.state.patientControlNumber))}
                        onClick={this.handleMedicareClick}
                        buttonType="emphasized"
                        size="control"
                        ><span>Medicare</span></Button>
                }
            </React.Fragment>
        );
    }

    renderQuickSearchEntry() {
        return(
            <ARMQuickSearchEntry >
                {/* {this.renderQuickSearchEntryTitle()} */}
                {this.renderQuickSearchEntryInput()}
                {this.renderQuickSearchEntryButtons()}
            </ARMQuickSearchEntry>
        );
    }

    renderSavedFilters() {
        return (
            <ARMQuickSearchOptionsPanelRow wrap={false} justifyContent="space-between">
                
                <ARMQuickSearchFilterOptions>
                    {this.renderSavedFiltersList(false)}
                </ARMQuickSearchFilterOptions>
            </ARMQuickSearchOptionsPanelRow>
        );
    }

    renderSavedFiltersList(isTrue: boolean) {
        var savedFiltersList = this.getSavedFiltersList();
        if (savedFiltersList) {
            return (
                <SavedFilterDropdownWrapper legacy= {isTrue}>
                    <DropdownButton
                        buttonClass="dropdown-button"
                        listClass="dropdown-list"
                        listID="Dropdown_List"
                        buttonType="deEmphasized"
                        size="control"
                        disabled={!this.state.userCanViewClaims}
                        buttonID="filterOptionsDropdown"
                        orientation="bottom-right"
                        name={this.state.savedFilterTitle}
                        sections={savedFiltersList}
                        onMenuClick={this.onSelectFilter}
                        menuMaxHeight="200px"
                        menuMaxWidth={isTrue ? '375px' : '200px'}
                    />
                </SavedFilterDropdownWrapper>
            );
        }
    }

    bigHelp() {
        wnd.BigHelp('/Support/Help/HELP_HomePage_QuickSearch.htm');
    }

    renderQuickSearchOptionsPanel() {
        return (<LandingPageComponent {...this.props}>
            <ARMQuickSearchOptionsPanel>
                <ARMQuickSearchOptionsPanelRow wrap={false} justifyContent="space-between">
                    <ARMQuickSearchOptionsPanelTitle>Quick Search</ARMQuickSearchOptionsPanelTitle>
                    <ARMQuickSearchOptionsPanelHelp>
                            <ImageButton
                                Title="Quick Search Help"
                                helpIcon={true}
                                topheader={true}
                                className="imageButton"
                                onClick={this.bigHelp} />
                    </ARMQuickSearchOptionsPanelHelp>
                </ARMQuickSearchOptionsPanelRow>
                <ARMQuickSearchOptionsPanelRow wrap={true} justifyContent="space-between" align-items="stretch">
                    <ARMQuickSearchOptionsPanelRow wrap={false} justifyContent="center" widthValue="100%" paddingTop="5px">
                        {this.renderSavedFiltersList(true)}
                    </ARMQuickSearchOptionsPanelRow>
                    <ARMQuickSearchOptionsPanelRow wrap={false} justifyContent="center" paddingTop="5px" style={{position: 'relative', top: '10px'}}>
                        <ARMQuickSearchOptionsPanelRule /><ARMQuickSearchOptionsPanelLabel>Or</ARMQuickSearchOptionsPanelLabel><ARMQuickSearchOptionsPanelRule />
                    </ARMQuickSearchOptionsPanelRow>
                    <ARMQuickSearchOptionsPanelRow wrap={true} justifyContent="center" widthValue="100%" paddingTop="3px">
                        <ARMQuickSearchEntryPanel inputWidth="80%">
                            {this.renderQuickSearchEntryPatientNameTextBox(true)}
                        </ARMQuickSearchEntryPanel>
                    </ARMQuickSearchOptionsPanelRow>
                    <ARMQuickSearchOptionsPanelRow wrap={true} justifyContent="center" widthValue="100%" paddingTop="3px">
                        <ARMQuickSearchEntryPanel inputWidth="80%">
                            {this.renderQuickSearchEntryPatientControlNumberTextBox(true)}
                        </ARMQuickSearchEntryPanel>
                    </ARMQuickSearchOptionsPanelRow>
                    <ARMQuickSearchOptionsPanelRow wrap={false} justifyContent="left" widthValue="100%" paddingTop="3px">
                        <ARMQuickSearchLegacyPanelbuttons>
                            <ARMQuickSearchEntryPanelButtons style={{justifyContent: 'left'}}>
                                {this.renderQuickSearchEntryButtons()}
                            </ARMQuickSearchEntryPanelButtons>
                        </ARMQuickSearchLegacyPanelbuttons>
                    </ARMQuickSearchOptionsPanelRow>
                </ARMQuickSearchOptionsPanelRow>
                <ModalConfirmation
                    isOpen={!!this.state.errorMessage && this.state.errorMessage !== ''}
                    message={this.state.errorMessage}
                    alertMode={true}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setState({ errorMessage: '' })}
                />
                {this.renderUserFilterForm()}
            </ARMQuickSearchOptionsPanel>
            </LandingPageComponent>
        );
    }

    render() {
        if (this.props.renderPanel) {
            return this.renderQuickSearchOptionsPanel();
        }
        return (
            <ARMQuickSearchOptionsBar>
                { this.renderQuickSearchEntry() }
                { this.renderSavedFilters() }
                { this.renderUserFilterForm() }
            </ARMQuickSearchOptionsBar>
        );
    }
}

export default withRouter(ARMQuickSearch);
