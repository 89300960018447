import React from "react";
import styled from 'styled-components';
import { ContentRowWrapper } from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { ARMGrid } from '../../common/UICLWrappers/ARMGrid';
import { Colors } from '@commonResources/colorVariables';

/*
 * INTERFACES
 */

type ICreateCIFScreenProps = {
    data: CifFormData,
    modalHandler: (modalTitle: string, modalMessage: string, modalVisible: boolean) => void,

};

type ICreateCIFScreenState = {
    formData: CifFormDataDetails,
    box3: CifFormDataBoxDetails,
    box4: CifFormDataBoxDetails,
    box5: CifFormDataBoxDetails,
    remarks: string,
    date: string | number,
    serviceLines: CifFormDataServiceLines[],
    lastFocusedElement: { record: number, index: number },
};

interface UiclColumn {
    dataName: string,
    text: string,
    sortable?: boolean,
    cellType?: string,
    isSorted?: number,
    width?: number,
};

interface RowProps {
    record: {
        box6: boolean,
        box6Disabled: boolean,
        box7: string,
        box8: string,
        box9: string,
        box9b: string,
        box10: boolean,
        box11: boolean,
        box12: boolean,
        box13: string,
        box14: string,
        box15: string,
        del: boolean,
        id: number,
        index: number,
    }
};

/*
 * STYLED COMPONENTS
 */

interface SpacedContentRowWrapperProps {
    gappixeldistance: string;
}
const SpacedContentRowWrapper = styled(ContentRowWrapper) <SpacedContentRowWrapperProps>`
    gap: ${(p) => p.gappixeldistance + 'px'};
    margin: 10px;
    align-items: center;
`;

const ProportionalDialogFieldSet = styled(DialogFieldset)`
    flex-grow: ${({ grow }) => grow}
`;

// We override the ARMGrid table styles for better layout for this use case
const CenteredCellGrid = styled(ARMGrid)`


  && table {
    border-spacing: 0;
    background: ${Colors.grey10};
  }

  && thead {
     background: ${Colors.grey10};
  }

  && th {
    background: ${Colors.grey10};
  }

  && td {
    text-align: center;
    vertical-align: middle;
    padding: 6px;
    background: none;
  }
`;

/**
 * 
 * CREATE CIF SCREEN 
 * 
 *  This screen is reached when all the checks pass or when the user decides to
 *  proceed in spite of a failed test by clicking "Yes".
 * 
 *  It displays the form that provides the opportunity to edit the claim before printing.
 * 
 *  Note: we need to manually handle focus on the input fields in the ARMGrid component
 *  due to a quirk of the component that makes it lose focus after each change is fired.
 *  
 *  This is why we use a standard HTML input text field, since the UI-CL one
 *  does not provide a ref attribute.
 *  
 *  We don't use the HTML focus event to set this, because onChange assumes the component already
 *  has focus and setting this within the ComponentUpdate would cause an infinite loop.
 * 
 */

export default class CreateCIFScreen extends React.Component<ICreateCIFScreenProps, ICreateCIFScreenState> {

    private inputRefs: any;
    private cursor1: any;
    private cursor2: any;
    private cursor3: any;
    private cursor4: any;
    private cursor5: any;
    private cursor6: any;
    private cursor7: any; 
    constructor(props: ICreateCIFScreenProps) {

        super(props);

        // We flatten the nested data to isolate scope and make updates more efficient.
        // serviceLines contains the grid records.
        // formData contains the general formData.
        // We keep track of the last focused record in order to refocus after each rerender to allow continuous editing.

        // We Initialize State from  data passed in through props
        this.state = {
            serviceLines: props.data.formData.serviceLines,
            formData: props.data.formData,
            lastFocusedElement: { record: 0, index: 0 },
            box3: props.data.formData.box3,
            box4: props.data.formData.box4,
            box5: props.data.formData.box5,
            remarks: props.data.formData.remarks,
            date: props.data.formData.date,
        };

        // Holds the references to all the input elements to focus
        this.inputRefs = [];
        this.cursor1 = null;
        this.cursor2 = null;
        this.cursor3 = null;
        this.cursor4= null;
        this.cursor5 = null;
        this.cursor6 = null;
        this.cursor7 = null ;
        // Initialize Index in whihch to store general formData
        this.inputRefs[this.state.serviceLines.length + 1] = [];

        // Init inputRefs (We save the 0 index for the form)
        this.state.serviceLines.map((line, index) => {
            this.inputRefs[index] = new Array();
        });
        //console.log("Inited array with:", this.inputRefs)
    }


    public componentDidMount() {
        // Place initial focus here if required.
     }

    // Called after each update - it's wehre we reset focus
    public componentDidUpdate() {

        // We obtain the indeces (row and element) of the last focused element
        let recordIndex = this.state.lastFocusedElement.record;
        let recordElement = this.state.lastFocusedElement.index;

        //console.log(`Resetting focus to: [${recordIndex}, ${recordElement}]`)
        //console.log("====> Component has updated!", this.inputRefs);

        // Reset Focuse
        if (this.inputRefs) {
           this.inputRefs[recordIndex][recordElement].focus();
        }

    }

    public assembleDataObject = () => {

        //console.log("Assembling data object... ");

        // Assemble state into a new data object
        const serviceLines = this.state.serviceLines;
        const remarks = this.state.remarks;
        const date = this.state.date;
        const box3 = this.state.box3;
        const box4 = this.state.box4;
        const box5 = this.state.box5;
        const maxSLChkCount: number = this.state.formData.maxSLChkCount;
        const claimID: string = this.state.formData.claimID;

        const formData: CifFormDataDetails = {
            maxSLChkCount: maxSLChkCount,
            claimID: claimID,
            remarks: remarks,
            date: date,
            box3: box3,
            box4: box4,
            box5: box5,
            serviceLines: serviceLines
        }

        return formData;

    }

    public displayModal = (message?: string) => {

        const modalTitle = "Message from webpage ";
        const modalMessage = message || '';
        const modalVisible = true;

        this.props.modalHandler(modalTitle, modalMessage, modalVisible);
    }

    public validateServiceLines = () => {

        const selectedServiceLines = this.state.serviceLines.filter((s: CifFormDataServiceLines) => s.box6 === true);
        const upperLimit = this.state.formData.maxSLChkCount;
        const selectedCnt = selectedServiceLines.length;

        // Case where the grid loads with more items than are acceptable
        if (selectedCnt > upperLimit) {
            this.displayModal(`You can select only up to ${upperLimit} service lines.`);
            return false;
        }
        // Case where grid has nothing selected
        if (selectedCnt === 0) {
            this.displayModal('You must select at least 1 service line.');
            return false;

        }

        return true;

    }

    public moveCursorToEnd(id: any) {
        var el: any = document.getElementById(id)
        let trimmedEl = el.value.trim();
        el.focus()
        if (el.value.endsWith(' ')) {
            var len = trimmedEl.length;
            if (el.setSelectionRange && el.selectionStart > len) {
                el.focus();
                el.setSelectionRange(len, len);
            } else if (el.createTextRange) {
                var t = el.createTextRange();
                t.collapse(true);
                t.moveEnd('character', len);
                t.moveStart('character', len);
                t.select();

            }
        }
    }

    public trimOnBlur(id: string) {
        let el: any = document.getElementById(id)
        if (el.value.endsWith(' ')) {
            el.value = el.value.slice().trim();
            let newVal = el.value.slice().trim();
            el.setAttribute('value', newVal)
        }
        el.blur()
    }


    public handleServiceRecordChange = (e: React.ChangeEvent<HTMLInputElement>, recordKey: string, recordIndex: number, recordElement: number): void => {

        e.preventDefault();

        //console.log("Change detected:", e.target.value);

        // Handle accordingly for checkbox vs. text input for current record
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        // Map through the serviceLines array to get the one that correponds to trigering Element
        let newState: any = this.state.serviceLines.map((item, index) => {
            if (index === recordIndex) {

                if (recordKey === "box6") {

                    //console.log(this.state.serviceLines);

                    const selectedServiceLines = this.state.serviceLines.filter((s: CifFormDataServiceLines) => s.box6 === true);

                    const upperLimit = this.state.formData.maxSLChkCount;
                    const selectedCnt = selectedServiceLines.length;

                    if (selectedCnt === upperLimit && value === true) {
                        this.displayModal(`You can select only up to ${upperLimit} service lines.`);
                        //console.log("Invalid since would cause overrun", selectedCnt)
                        // this would cause overrun so avoid
                        return item;
                    } else if (selectedCnt === 1 && value === false) {
                        //console.log("Invalid, would result in no selection", selectedCnt)
                        this.displayModal('You must select at least 1 service line.');
                        return item;
                    }
                }

                let sanitizedVal = typeof value === 'string' ? value.trim() : value;
                return { ...item, [recordKey]: sanitizedVal }
            } else {
                return item
            }
        })
        // Set the focus to restore on update
        this.setState({ lastFocusedElement: { record: recordIndex, index: recordElement} })
        // Set state with the mutated state
        this.setState({ serviceLines: newState });

        //console.log("The new state is => ", newState);


    }

    public getGridColumnHeaders = () => {
        const columns: UiclColumn[] = [
            {
                dataName: "box6",
                text: 'SL',
                sortable: false,
                cellType: "text",
                isSorted: 0,

            },
            {
                dataName: 'box7',
                text: 'Patient Name (7)',
                sortable: false,
                cellType: "text",
                isSorted: 0,

            },
            {
                dataName: 'box8',
                text: 'MEDI-CAL ID (8)',
                sortable: false,
                cellType: "text",
                isSorted: 0,

            },
            {
                dataName: 'box9',
                text: 'Claim ctrl no (9)',
                sortable: false,
                cellType: "text",
                isSorted: 0,

            },
            {
                dataName: 'box9b',
                text: '/Line',
                sortable: false,
                cellType: "text",
                isSorted: 0,

            },
            {
                dataName: 'box10',
                text: 'A (10)',
                cellType: "text",
                sortable: false,
                isSorted: 0,

            },
            {
                dataName: 'box11',
                text: 'U (11)',
                cellType: "text",
                sortable: false,
                isSorted: 0,

            },
            {
                dataName: 'box12',
                text: 'O (12)',
                cellType: "text",
                sortable: false,
                isSorted: 0,

            },
            {
                dataName: 'box13',
                text: 'Service Date (13)',
                cellType: "text",
                sortable: false,
                isSorted: 0,

            },
            {
                dataName: 'box14',
                text: 'Procedure Code (14)',
                cellType: "text",
                sortable: false,
                isSorted: 0,

            },
            {
                dataName: 'box15',
                text: 'Amt billed (15)',
                cellType: "text",
                sortable: false,
                isSorted: 0,

            },
            {
                dataName: 'del',
                text: 'Del',
                cellType: "text",
                sortable: false,
                isSorted: 0,

            }
        ];

        return new Set(columns);

    }


    public getCustomRow = () => {
        const row = ({ record }: RowProps) =>

            <tr id={`${record.index}`} key={record.id} style={{ padding: '5px' }}>
                <React.Fragment>

                    <td>
                        <input
                            id={`${record.id}-box6`}
                            type='checkbox'
                            name='box6'
                            checked={!!record.box6}
                            disabled={record.box6Disabled}
                            ref={el => this.inputRefs[record.index][0] = el}
                            onChange={
                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.handleServiceRecordChange(e, "box6", record.index, 0);
                                }
                            }
                        />

                    </td>
                    <td>
                        <input
                            id={`${record.id}-box7`}
                            type='text'
                            size={12}
                           
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.trimOnBlur(`${record.id}-box7`)
                            }}
                            value={record.box7 || ''}
                            ref={el => this.inputRefs[record.index][1] = el}
                            
                            onChange={
                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.handleServiceRecordChange(e, "box7", record.index, 1);


                                }
                            }
                          
                          
                            maxLength={20}
                        />
                    </td>
                    <td>
                        <input
                            id={`${record.id}-box8`}
                            type='text'
                          
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.trimOnBlur(`${record.id}-box8`)
                            }}
                            size={12}
                            value={record.box8 || ''}
                            ref={el => this.inputRefs[record.index][2] = el}
                           

                            onChange={
                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.handleServiceRecordChange(e, "box8", record.index, 2);
                                }
                            }
                         
                            maxLength={20}
                        />
                    </td>

                    <td>
                        <input
                            id={`${record.id}-box9`}
                            type='text'
                            value={record.box9 || ''}
                            size={12}
                           
                            ref={el => this.inputRefs[record.index][3] = el}
                            onChange={
                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                   this.handleServiceRecordChange(e, "box9", record.index, 3);
                                 }
                            }
                            
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.trimOnBlur(`${record.id}-box9`)
                            }}
                            maxLength={20}
                         />
                    </td>
                    <td>
                        <input
                            id={`${record.id}-box9b`}
                            type='text'
                            value={record.box9b || ''}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.trimOnBlur(`${record.id}-box9b`)
                            }}
                            size={2}
                            ref={el => this.inputRefs[record.index][4] = el}
                            onChange={
                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.handleServiceRecordChange(e, "box9b", record.index, 4);
                                 }
                            }
                           
                            maxLength={2}
                        />

                    </td>
                    <td>
                        <input
                            id={`${record.id}-box10`}
                            type='checkbox'
                            checked={!!record.box10}
                            name='box10'
                            ref={el => this.inputRefs[record.index][5] = el}
                            onChange={
                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.handleServiceRecordChange(e, "box10", record.index, 5)
                                }
                            }
                        />
                    </td>
                    <td>
                        <input
                            id={`${record.id}-box11`}
                            type='checkbox'
                            checked={!!record.box11}
                            name='box11'
                            ref={el => this.inputRefs[record.index][6] = el}
                            onChange={
                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.handleServiceRecordChange(e, "box11", record.index, 6)
                                }
                            } />
                    </td>
                    <td>
                        <input
                            id={`${record.id}-box12`}
                            type='checkbox'
                            checked={!!record.box12}
                            name='box12'
                            ref={el => this.inputRefs[record.index][7] = el}
                            onChange={
                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.handleServiceRecordChange(e, "box12", record.index, 7)
                                }
                            } />
                    </td>
                    <td>
                        <input
                            id={`${record.id}-box13`}
                            value={record.box13 || ''}
                            name='box13'
                            type='text'
                            size={6}
                            ref={el => this.inputRefs[record.index][8] = el}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.trimOnBlur(`${record.id}-box13`)
                            }}
                            onChange={
                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                 this.handleServiceRecordChange(e, "box13", record.index, 8);
                                }
                            }
                            
                        />
                    </td>
                    <td>
                        <input
                            id={`${record.id}-box14`}
                            type='text'
                            value={record.box14 || ''}
                            size={6}
                            ref={el => this.inputRefs[record.index][9] = el}
                         
                            onChange={
                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                 this.handleServiceRecordChange(e, "box14", record.index, 9);
                                }
                            }
                           
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.trimOnBlur(`${record.id}-box14`)
                            }}
                            maxLength={29}
                        />
                    </td>
                    <td>
                        <input
                            id={`${record.id}-box15`}
                            type='text'
                            value={record.box15 || ''}
                            size={6}
                            ref={el => this.inputRefs[record.index][10] = el}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.trimOnBlur(`${record.id}-box15`)
                            }}
                            onChange={
                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.handleServiceRecordChange(e, "box15", record.index, 10)
                                }
                            }
                           
                            maxLength={19}
                        />
                    </td>
                    <td>
                        <input
                            id={`${record.id}-del`}
                            type='checkbox'
                            checked={!!record.del}
                            name="del"
                            ref={el => this.inputRefs[record.index][11] = el}
                            onChange={
                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.handleServiceRecordChange(e, "del", record.index, 11)
                                }
                            }
                        />
                    </td>
                </React.Fragment>
            </tr>
        return row;
    }

    render() {

        const { box3, box4, box5, remarks, date } = this.state;

        return (
            <React.Fragment key="Enclosing">
                <ContentRowWrapper>
                    <DialogFieldset>
                        <DialogLegend>Provider Name / Address (3)</DialogLegend>
                        <SpacedContentRowWrapper gappixeldistance="10">
                            <label htmlFor="cif-form-provider-name">NAME:</label>
                            <input
                                id="cif-form-provider-name"
                                ref={el => this.inputRefs[this.state.serviceLines.length + 1][0] = el}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    var box3 = { ...this.state.box3 };
                                    box3.name = e.target.value;
                                    this.setState({ lastFocusedElement: { record: this.state.serviceLines.length + 1, index: 0 } })
                                    this.setState({ box3 })
                                }}
                               
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.trimOnBlur('cif-form-provider-name')
                                }}
                                value={box3.name}
                                maxLength={30}

                            />
                            <label htmlFor="cif-form-provider-city">CITY:</label>
                            <input
                                id="cif-form-provider-city"
                                ref={el => this.inputRefs[this.state.serviceLines.length + 1][1] = el}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    var box3 = { ...this.state.box3 }
                                    box3.city = e.target.value;
                                    this.setState({ lastFocusedElement: { record: this.state.serviceLines.length + 1, index: 1 } })
                                    this.setState({ box3 })
                                }}
                               
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.trimOnBlur('cif-form-provider-city')
                                }}
                                value={box3.city}
                                maxLength={31}
                            />
                        </SpacedContentRowWrapper>
                        <SpacedContentRowWrapper gappixeldistance="10">
                            <label htmlFor="cif-form-provider-city">ADDRESS:</label>
                            <input
                                id="cif-form-provider-address"
                                ref={el => this.inputRefs[this.state.serviceLines.length + 1][2] = el}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    var box3 = { ...this.state.box3 }
                                    box3.address = e.target.value;
                                    this.setState({ lastFocusedElement: { record: this.state.serviceLines.length + 1, index: 2 } })
                                    this.setState({ box3 })
                                }}
                               
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.trimOnBlur('cif-form-provider-address')
                                }}
                                value={box3.address}
                                maxLength={45}
                            />
                            <label htmlFor="cif-form-provider-state">STATE:</label>
                            <input
                                id="cif-form-provider-state"
                                ref={el => this.inputRefs[this.state.serviceLines.length + 1][3] = el}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    var box3 = { ...this.state.box3 }
                                    box3.state = e.target.value;
                                    this.setState({ lastFocusedElement: { record: this.state.serviceLines.length + 1, index: 3 } })
                                    this.setState({ box3 })
                                }}
                                onClick={(e: any) => {
                                    this.moveCursorToEnd('cif-form-provider-state')
                                }}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.trimOnBlur('cif-form-provider-state')
                                }}
                                value={box3.state}
                                size={2}
                                maxLength={2}
                            />
                            <label htmlFor="cif-form-provider-state">ZIP CODE:</label>
                            <input
                                id="cif-form-provider-zipcode"
                                ref={el => this.inputRefs[this.state.serviceLines.length + 1][4] = el}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    var box3 = { ...this.state.box3 }
                                    box3.zipCode = e.target.value;
                                    this.setState({ lastFocusedElement: { record: this.state.serviceLines.length + 1, index: 4 } })
                                    this.setState({ box3 })
                                }}
                               
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.trimOnBlur('cif-form-provider-zipcode')
                                }}
                                value={box3.zipCode}
                                maxLength={9}
                                size={9}

                            />
                        </SpacedContentRowWrapper>

                    </DialogFieldset>
                    <DialogFieldset>
                        <DialogLegend>Provider Number (4)</DialogLegend>
                        <input
                            id="cif-form-provider-number"
                            ref={el => this.inputRefs[this.state.serviceLines.length + 1][5] = el}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                var box4 = { ...this.state.box4 }
                                box4.providerNumber = e.target.value;
                                this.setState({ lastFocusedElement: { record: this.state.serviceLines.length + 1, index: 5 } })
                                this.setState({ box4 })
                            }}
                          
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.trimOnBlur('cif-form-provider-number')
                            }}
                            value={box4.providerNumber}
                            maxLength={15}
                        />
                    </DialogFieldset>
                    <DialogFieldset>
                        <DialogLegend>Claim Type (5)</DialogLegend>
                        <input
                            id="cif-form-claim-type-1"
                            ref={el => this.inputRefs[this.state.serviceLines.length + 1][6] = el}
                            type="radio"
                            checked={box5.claimType === 0}
                            width={"100%"}
                            name="claimType"
                          

                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                var box5 = { ...this.state.box5 };
                                box5.claimType = 0;
                                this.setState({ lastFocusedElement: { record: this.state.serviceLines.length + 1, index: 6 } })
                                this.setState({ box5 })
                            }}
                        />
                        <label htmlFor="cif-form-claim-type-1">Hospital Inpatient (UB)</label>
                        <br />

                        <input
                            id="cif-form-claim-type-2"
                            ref={el => this.inputRefs[this.state.serviceLines.length + 1][7] = el}
                            type="radio"
                            checked={box5.claimType === 1}
                            width={"100%"}
                            name="claimType"
                            
                           
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                var box5 = { ...this.state.box5 };
                                box5.claimType = 1;
                                this.setState({ lastFocusedElement: { record: this.state.serviceLines.length + 1, index: 7 } })
                                this.setState({ box5 })
                            }}
                        />
                        <label htmlFor="cif-form-claim-type-1">Hospital Outpatient (UB)</label>
                        <br />

                        <input
                            id="cif-form-claim-type-3"
                            ref={el => this.inputRefs[this.state.serviceLines.length + 1][8] = el}
                            type="radio"
                            checked={box5.claimType === 2}
                            width={"100%"}
                            name="claimType"
                          
                          
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                var box5 = { ...this.state.box5 };
                                box5.claimType = 2;
                                this.setState({ lastFocusedElement: { record: this.state.serviceLines.length + 1, index: 8 } })
                                this.setState({ box5 });
                            }}
                        />
                        <label htmlFor="cif-form-claim-type-1">Physician / Allied (1500)</label>
                        <br />
                    </DialogFieldset>
                </ContentRowWrapper>
                <ContentRowWrapper>
                    <DialogFieldset width={'100%'}>
                        <DialogLegend>Services Lines</DialogLegend>

                        <CenteredCellGrid
                            domID='serviceLine-list-grid'
                            key="CenteredARMGrid"
                            isFixedHeader={true}
                            maxHeight='400px'
                            emptyGridMessage=' '
                            selectionKey="id"
                            columns={this.getGridColumnHeaders()}
                            height={200}
                            rowComponent={this.getCustomRow()}
                            scrollToTop={false}

                            // We Map Through the records 
                            records={
                                this.state.serviceLines.map((record: any, index: number) => {

                                    return {
                                        id: record.id,
                                        box6: record.box6,
                                        box6Disabled: record.box6Disabled,
                                        box7: record.box7,
                                        box8: record.box8,
                                        box9: record.box9,
                                        box9b: record.box9b,
                                        box10: record.box10,
                                        box11: record.box11,
                                        box12: record.box12,
                                        box13: record.box13,
                                        box14: record.box14,
                                        box15: record.box15,
                                        del: record.del,
                                        index: index
                                    }
                                })
                            }
                        />

                    </DialogFieldset>
                </ContentRowWrapper>
                <ContentRowWrapper >
                    <ProportionalDialogFieldSet grow="5">
                        <DialogLegend>Remarks</DialogLegend>
                        <textarea
                            id="CreateCIFRemarks"
                            name="CreateCIFRemarks"
                            ref={el => this.inputRefs[this.state.serviceLines.length + 1][9] = el}
                            rows={4}
                            cols={80}
                            value={remarks}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                var newRemark = e.target.value;
                                this.setState({ lastFocusedElement: { record: this.state.serviceLines.length + 1, index: 9 } })
                                this.setState({ remarks: newRemark })
                            }}
                        />
                    </ProportionalDialogFieldSet>

                    <ProportionalDialogFieldSet>
                        <DialogLegend>Date</DialogLegend>
                        <input
                            id="CreateCIFdate"
                            type="text"
                            value={date}
                            ref={el => this.inputRefs[this.state.serviceLines.length + 1][10] = el}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                var newDate = e.target.value;
                                this.setState({ lastFocusedElement: { record: this.state.serviceLines.length + 1, index: 10 } })
                                this.setState({ date: newDate });
                            }}
                            maxLength={6}
                        />
                    </ProportionalDialogFieldSet>
                </ContentRowWrapper>
            </React.Fragment>
        );

    }

}