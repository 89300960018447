import * as React from 'react';
import styled from 'styled-components';
import { PreBillEditFilterGridData } from '../../../store/ui/PreBillFilterUI';
import { PrebillEditFilterGridRow } from './GridRow';
import { Caret } from '@optum-uicl/ui-core/dist'


export const TableRowWrapper = styled.tr`
    
    width: 750px;
    background-color: #D3D3D3;
    color: #37474F;
    height: 30px;
    font-size: 12px;
    font-weight: bold;
    #category
    {
       width:240px;
    }
    #prebillPhase
    {
        width:210px;
        padding-top:5px;
    }
  #expand
    {
        width:50px;
    }
    #fieldName,#editName
    {
        width:170px;
    }
    th:Hover
    {
        cursor:pointer;
    }
    .desdending{
        transform: rotate(-180deg) !important;
    }
`;
export const TableHeadCellWrapper = styled.th`
                border-width: 1px;
                padding-left:10px;
                text-align:center; 
`;

export const InnerTableDiv = styled.div`
                display: flex;
                justify-content: center;
                vertical-align: middle;
`;

export const HeaderElement = styled.span`
                flaot:left;
                padding-top:10px;
`;

export const HeaderElement2 = styled.span`
                flaot:left;
                padding-left:3px;
                width:20px;
                height:20px;
`;

interface IComponentState {
    sortingCol: string;
    isAscendingSort: boolean;
}



interface IPrebillEditFilterGridProps {
    rows: PreBillEditFilterGridData[];
    loadPhaseList: any[];
    expandPrebillFilterGrid: (category: string, dvaraName: string, index: number) => void;
    collaspePrebillFilterGrid: (category: string, dvaraName: string, index: number) => void;
    enablePhaseList: (enable: boolean, index: number) => void;
    ManagePrebillPhaseMapping: (row: PreBillEditFilterGridData, id: string, index: number) => void;
    sortGrid: (sortCriteria: any) => void;
    sortedFlag: boolean;
}

export const DEFAULT_STATE: IComponentState = {
    sortingCol: '',
    isAscendingSort: true
};

export class PrebillEditFilterGrid extends React.Component<IPrebillEditFilterGridProps, IComponentState> {

    constructor(props: IPrebillEditFilterGridProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.isExpandableRow.bind(this);
    }

    componentDidMount() {
        const {
        } = this.props;
     }

    isExpandableRow = (categoryId: string, dvarName: string, index: Number): boolean => {
        let list = this.props!.rows;
        
        for (var i = 0; i < list.length; i++) {
            if (i === index)
                continue;
            if (list[i].categoryId === categoryId) {
                if (dvarName === '') {
                    if (list[i].dvarName !== '')
                        return true;
                } else if (list[i].dvarName === dvarName) {
                    if (list[i].editName !== '')
                        return true;
                }
            }
        }
        return false ;
    }

    render() {
        const { rows, loadPhaseList } = this.props;
        
        return (
            <table style={{height:'0%'}}>
                <thead>
                    <TableRowWrapper>
                        <TableHeadCellWrapper id='category' onClick={() => { if (this.props.rows.length > 0) this.sort('Category') }}>
                            <InnerTableDiv>
                                <HeaderElement>  CATEGORY </HeaderElement>
                                <HeaderElement2>
                                    {(this.props.rows.length > 0 && this.props.sortedFlag ?
                                        (this.getSortIndicator('Category') > 0) ?
                                            <Caret
                                                className={this.getSortIndicator('Category') === 2 ? 'desdending' : 'test'}
                                                fillColor="#37474F"
                                                title="title"
                                            /> : ''
                                        :'')
                                    }
                                </HeaderElement2>
                            </InnerTableDiv>
                        </TableHeadCellWrapper>

                        <TableHeadCellWrapper id='fieldName' onClick={() => { if (this.props.rows.length > 0) this.sort('FieldName') }}>
                            <InnerTableDiv>
                                <HeaderElement>  FIELD NAME  </HeaderElement>
                                <HeaderElement2>
                                    {(this.props.rows.length > 0 && this.props.sortedFlag ?
                                        (this.getSortIndicator('FieldName') > 0) ?
                                            <Caret
                                                className={this.getSortIndicator('FieldName') === 2 ? 'desdending' : 'test'}
                                                fillColor="#37474F"
                                                title="title"
                                            /> : ''
                                        :'')
                                    }
                                </HeaderElement2>
                            </InnerTableDiv>
                        </TableHeadCellWrapper>

                        <TableHeadCellWrapper id='editName' onClick={() => { if (this.props.rows.length > 0) this.sort('EditName') }}>
                            <InnerTableDiv>
                                <HeaderElement>  EDIT NAME  </HeaderElement>
                                <HeaderElement2>
                                    {(this.props.rows.length > 0 && this.props.sortedFlag ?
                                        (this.getSortIndicator('EditName') > 0) ?
                                            <Caret
                                                className={this.getSortIndicator('EditName') === 2 ? 'desdending' : 'test'}
                                                fillColor="#37474F"
                                                title="title"
                                    /> : ''
                                    :'')
                            }
                                </HeaderElement2>
                            </InnerTableDiv>

                        </TableHeadCellWrapper>

                        <TableHeadCellWrapper id='prebillPhase'>
                            PRE-BILL PHASE
                        </TableHeadCellWrapper>
                        <TableHeadCellWrapper id='expand'>
                        </TableHeadCellWrapper>
                    </TableRowWrapper>
                </thead>
                <tbody>
                    {rows.map((row: PreBillEditFilterGridData, index: number) => {
                        return <PrebillEditFilterGridRow row={row} index={index} key={index}
                            isExpandable={this.isExpandableRow(row.categoryId, row.dvarName, index)}
                            expandPrebillFilterGrid={this.props.expandPrebillFilterGrid}
                            collaspePrebillFilterGrid={this.props.collaspePrebillFilterGrid}
                            enablePhaseList={this.props.enablePhaseList}
                            ManagePrebillPhaseMapping={this.props.ManagePrebillPhaseMapping}
                            loadPhaseList={loadPhaseList} />
                    })}

                </tbody>
            </table>
        );
    }
    sort(sortCol: string): void {
        
        if (this.state.sortingCol === sortCol) {

            this.props.sortGrid({ sortCriteria: { sortCol: sortCol, isAscendingSort: !this.state.isAscendingSort } });
            this.setState({ isAscendingSort: !this.state.isAscendingSort });
        }
        else {
            this.props.sortGrid({ sortCriteria: { sortCol: sortCol, isAscendingSort: true } });
            this.setState({ isAscendingSort: true, sortingCol: sortCol });
        }

    }
    getSortIndicator(sortingColumn: string): number {
        //  let sortDirection: number = 0;
        if (this.state.sortingCol === sortingColumn)
            if (this.state.isAscendingSort)
                return 1;
            else
                return 2;
        else
            return 0;
    }
}

