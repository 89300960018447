import * as React from 'react';
import styled from 'styled-components';
import _, { isEmpty, isNull, isUndefined } from 'lodash';
import { Tabs, SelectDropdown, Input, DataItem, Button, TextArea, Checkbox } from '@optum-uicl/ui-core/dist';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import AssuranceMenu from '../../common/AssuranceMenu';
import { ARMGrid } from '@common/UICLWrappers/ARMGrid';
import { ARMHeaderCell } from '@common/UICLWrappers/ARMHeaderCell';
import { ISortIndicator } from '@scripts/util/SortHelper';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { actionCreators /*, validationCallback */, IViewProductivityActionProps, IViewProductivityState } from '@store/ViewProductivity';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import CrudTypes from '@commonResources/CrudTypes';
import { CustomCheckBox } from '@common/CustomCheckBox';
import { SelectComponent } from '@common/SelectComponent';
import { ARMDatePicker } from '@common/DateOrTimeRelated/ARMDatePicker';
import { CheckBoxComponent } from '@common/CheckBox';
import { IRowProps } from '@components/Home/GridConfig';
import Counter from '@components/samples/Counter';
import { ModalConfirmation } from '@common/ModalConfirmation';



export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    width: 943px;
    flex-wrap:wrap;
        
    input[type="checkbox"]:checked{
            accent-color: rgb(12, 85, 184) !important;
    }
`;

export const LabelWrapper = styled.div`
    display: inline;
    margin-bottom: 0.3rem;
    color: #37474F;
    font-family: 'optum-sans-regular';
    font-size: 11px;
    line-height: 1.4em;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
`;

export const UserContainer = styled.div`
    display: flex;
    width: 100%;
    height: 300px;
`;

export const FilterSection = styled.div`
    margin-bottom: 5px;
    label{
        font-weight:bold;
    }
    .option-class{
        font-size: 12px;
    }
`;

export const SelectList = styled.div`
    fieldset{
        width:800px;
    }
`;

export const SelectUserList = styled.div`
    height: 250px;
    width: 580px;
    border: 1px solid #767676; 
    display: flex;
    margin-top: 0px;
    .cell-component-wrapper{
        width: 146px;
        overflow: hidden;
        white-space: nowrap;
        div{
            overflow: hidden;
            white-space: nowrap;
            span{
                font-size:13px;
            }
        }     
    }
`;

export const ButtonSection = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 5px;
    width: 580px;
  
    button{
        margin:0px 5px 5px 5px;
    }
`;

export const UserSection = styled.div`
    width:580px;
    margin-right: 10px;
    margin-top :5px;
    margin-bottom:10px;
    border:1px solid #767676;
    table tbody .selectedRow{
        background-color:rgb(233,241,255) !important;
        cursor:pointer !important;
    }
    thead{
            th {
                button {
                    .caret-span {
                        fill-opacity: 0;
                    }
                    font-weight: 400;
                    text-transform: capitalize;
                }
            }
    }
`;

export const UserTaskSection = styled.div`
    
    display :block;
    .option-class{
        font-size: 11px;
    }
    .arm_checkbox_label{
    padding-left: 0px;
    font-size: 13px;
    font-weight: 600;
    color: #37474F;
    }
`;

export const DateRangeSection = styled.div`
    display :block;
    padding-bottom:10px;
    label{
        font-size:10pt;
        font-weight:600;
    }
    span.date-title{
        position:relative;
        top:25px;
        font-weight:bold;
    }
`

export const CheckBoxWrapper = styled.div`
    float: left;
    width: 160px;
    .arm_checkbox_label {
        padding-left:5px;
    }
`;

export const DatePickerWrapper = styled.div`
    margin:5px;
    text-align:end;
    display:block;
    .cal_style{
        display: inline-flex;
    }
    >label{
        font-size: 13px;
        font-weight: 600;
        color: #37474F;
        margin: 0px 6px;
    }
`;
   
interface IComponentProps {
    title: string,
};


interface IComponentState {

    userList:any[];
    groupList: any[];
    selectedUsers:any[];
    masterUserList:any[];
    counter:number;
    displayedUserTask:any;
    selectedUserIds:any[];
    sortIndicator: {
        sortColumn: string,
        sortDirection: string
    };
    selectedGroupId:any;
    message:string;
    isOpen:boolean;
};


export const DEFAULT_STATE: IComponentState = {
    userList:[],
    groupList:[],
    selectedUsers:[],
    masterUserList:[],
    counter:0,
    displayedUserTask:{

    },
    selectedUserIds:[],
    sortIndicator: {
        sortColumn: '',
        sortDirection: '',
    },
    selectedGroupId:1,
    isOpen:false,
    message:''
}

export const SpeedDialRow = styled.tr``;


type IOwnProps = IComponentProps & RouteComponentProps<{}>;


type IViewProductivityProps = IMergeCrudComponentProps<IViewProductivityState, IViewProductivityActionProps, IOwnProps>;


export class ViewProductivity extends React.PureComponent<IViewProductivityProps, IComponentState> {
    protected selectedUsers: any[] = [];
    minDate: string;
    scrollPosition:any;
    windowScrollPosition:any;
    lastSelectedIndex:any;
    listRef: any;
    pageScrollPosition:any;
    lastSelected:any;
    protected displayedUserTasksValues:any ={
        all:false,
        printedClaims:4,
        deletedClaims:32,
        changedClaims:2,
        viewedClaims:16,
        validatedClaims:8,
        createdClaims:1
    }
    protected allDisplayedUserTasksValues:any =[4,32,2,16,8,1];
    

    constructor(props: IViewProductivityProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.minDate = this.dateAdd(new Date(), 'm', -1);
        this.listRef = React.createRef();
        this.lastSelectedIndex = '';
    }

    public getDateInMMDDYYYYFormat(input: any): string {
        try {
            var month = input.getMonth() + 1;
            var day = input.getDate();
            var year = input.getFullYear();

            return (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year;
        }
        catch (e) {
            console.error('Unable to properly format date');
        }
        return "00/00/0000";
    }

    public dateAdd(date: any, type: string, amount: number) {
        try {
            var y = date.getFullYear(),
                m = date.getMonth(),
                d = date.getDate();
            if (type === 'y') {
                y += amount;
            };
            if (type === 'm') {
                m += amount;
            };
            if (type === 'd') {
                d += amount;
            };
            var xx = new Date(y, m, d);
            return this.getDateInMMDDYYYYFormat(xx);
        }
        catch (e) {
            console.error('Unable to properly format date, dateAdd');
        }
        return "00/00/0000";
    }

    public componentWillMount() {
        this.props.action.ui.updateDisplayedUserTask({
            masterCrud: { 
            all:true,
            printedClaims:false,
            deletedClaims:false,
            changedClaims:false,
            viewedClaims:false,
            validatedClaims:false,
            createdClaims:false
        }
        });
        
        this.props.action.ui.updateUserList({
          masterCrud: []  
        })
        this.props.action.ui.updateGroupList({
            masterCrud: []
        });
        this.setState({selectedUsers:[]});
        this.props.action.crud.get({ crudId: CrudTypes.mctiUsersGroup }, (results:any) =>{
            this.getUserGroupList(results);
        });
        let dateRange ={
            startDate :'',
            endDate:''
        }
        this.props.action.ui.updateDateRange({
            masterCrud: dateRange
        })
        this.setState({selectedUserIds: []});
    }

    public componentDidMount() {
        console.log(this.props);
    }

    
      componentDidUpdate(prevProps:any, prevState:any) {
        // If we have a snapshot value, we've just added new items.
        // Adjust scroll so these new items don't push the old ones out of view.
        // (snapshot here is the value returned from getSnapshotBeforeUpdate)
        if(this.lastSelected === 'users' ){
        if(this.scrollPosition){
            const list = this.listRef;
            list.children[0].scrollTop += this.scrollPosition;
            }
            if(document.querySelector("[data-select='yes']")){
                let ele:any = document.querySelector("[data-select='yes']");
                ele.focus();
            }
            this.windowScrollPosition = 0;
        }else if(this.lastSelected === 'date'){
            let elt:any = document.getElementById('view-Productivity');
            if (elt)
                elt.scrollTop += this.windowScrollPosition;
        }
  
            this.setState({ counter: this.state.selectedUserIds.length })
      
       
        if(this.windowScrollPosition){
            
        }
        
      }

    public componentWillUnmount() { 
        //temporaly disable for testing purposes
       /* resetCrudComponentState(this.props.action, this.props.dataStore);*/
    }

    public getReportListGridColumnHeaders() {
        return new Set([
            {
                dataName: "firstName",
                text: 'First Name',
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "lastName",
                text: "Last Name",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "userName",
                text: "User Name",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "apply",
                text: "Apply",
                sortable: false,
                cellType: "custom",
                isSorted: 0,
            }
        ]);
    }

    
    public getSelectedUseGridColumnHeaders() {
        return new Set([
            {
                dataName: "firstName",
                text: 'First Name',
                sortable: false,
                cellType: "text",
                isSorted: 1,
            },
            {
                dataName: "lastName",
                text: "Last Name",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "userName",
                text: "User Name",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            }
        ]);
    }


    public getUserGroupList(results:any) {

        if (!results) 
             return {userList:[], filterUsers:[]};

        let userList: any[] = [];
        let groupList: any[]= [{name:'All Users', id:"1"}];

        if (!this.props.dataStore.ui.initialValuesLoaded) {
            this.setInitialPrintOption();
        
            if(results.UserProductivityDashboardInfo && results.UserProductivityDashboardInfo.UserList && 
                results.UserProductivityDashboardInfo.UserList.User){
         if(Array.isArray(results.UserProductivityDashboardInfo.UserList.User) ){
                    
        results.UserProductivityDashboardInfo.UserList?.User.forEach((ob:any, idx:any) => {
            if (ob['@FirstName'] && ob['@ID'] !== "0"){
            userList.push({ firstName: ob['@FirstName'], lastName: ob['@LastName'], userName:ob['@UserName'], 
            id:ob['@ID'], selectedOption:false
        });
            }
        });
    }else{
        let useObj = results.UserProductivityDashboardInfo.UserList.User;
        userList.push({ firstName: useObj['@FirstName'], lastName: useObj['@LastName'], userName:useObj['@UserName'], 
        id:useObj['@ID'], selectedOption:false
    })
    }
}

    if(results.UserProductivityDashboardInfo && results.UserProductivityDashboardInfo.GroupList && 
        results.UserProductivityDashboardInfo.GroupList.Group){
            if(Array.isArray(results.UserProductivityDashboardInfo.GroupList.Group) ){
        results.UserProductivityDashboardInfo.GroupList?.Group.forEach((ob:any, idx:any) => {
            if (ob['@Name'] && ob['@ID'] !== "0")
            groupList.push({ name:'from   '+ob['@Name']+ '  group', id:ob['@ID'], users:ob['Users']  });
        });
    }else{
        let groupObj = results.UserProductivityDashboardInfo.GroupList.Group;
        groupList.push({ name:'from   '+groupObj['@Name']+ '  group', id:groupObj['@ID'], users:groupObj['Users']  });
    }

    }
        

        userList.forEach((ele:any,idx:any) => {
            ele.apply= <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <input id={ele.id} type="checkbox"
            checked={this.state.selectedUserIds.includes(ele.id)}
                onClick={(x) => {
                    this.updateSelectedUserIds(x, ele, idx);
                }}
            />
        </div>  
        })

        this.props.action.ui.updateMasterUserList({
            masterCrud: userList
        });
        this.props.action.ui.updateUserList({
            masterCrud: userList
        });

        this.props.action.ui.updateGroupList({
            masterCrud: groupList
        });
    }

    }

    public updateSelectedUserIds(event: React.MouseEvent<HTMLInputElement, MouseEvent>, record:any, index:any) {
        const {
            selectedUserIds
        } = this.state;

        let latestSet = selectedUserIds;

        // add checkbox value to set if checked
        if (event.currentTarget.checked ) {
            latestSet.push(event.currentTarget.id);
            record.selected = event.currentTarget.checked;
        } else {
            // remove checkbox value from set if unchecked
            latestSet = latestSet.filter(function (value) {
                return value != event.currentTarget.id;
            });
        }

        this.windowScrollPosition =0;
        this.lastSelected = 'users';
    
        this.setState({ selectedUserIds: latestSet });
        this.lastSelectedIndex = event.currentTarget.id;
        this.setState({ counter: latestSet.length});

        //console.log('CHECKS---latestSet', latestSet.length);
    }

    getCustomRow() {

        const {
            selectedUserIds
        } = this.state;

        const row = ({ record } :any) => {
            let recordId = record.id.toString();
            //debugger;
            return (
                <tr id={recordId} key={recordId}  className={selectedUserIds.includes(recordId) ?`selectedRow active`:''}>
                    {
                        <>
                            <td style={{ width: 146 }}>
                                <div className="cell-component-wrapper" style={{ width: 146, display: 'flex', alignItems: 'center' }}>
                                    <div className="no-margin" style={{ height: 32, display: 'flex', alignItems: 'center',overflow:'hidden', whiteSpace:'nowrap' }}>
                                        
                                    {record.firstName}
                                    </div>
                                </div>
                            </td>
                            <td style={{ width: 146}}>
                                <div className="cell-component-wrapper" style={{ width: 146, display: 'flex', alignItems: 'center' }}>
                                    <div className="no-margin" style={{ height: 32, display: 'flex', alignItems: 'center', overflow:'hidden', whiteSpace:'nowrap' }}>
                                        
                                    {record.lastName}
                                    </div>
                                </div>
                            </td>
                            <td style={{ width: 146 }}>
                                <div className="cell-component-wrapper" style={{ width: 146, display: 'flex', alignItems: 'center' }}>
                                    <div className="no-margin" style={{ height: 32, display: 'flex', alignItems: 'center', overflow:'hidden', whiteSpace:'nowrap' }}>
                                        
                                    {record.userName}
                                    </div>
                                </div>
                            </td>
                            <td style={{ textAlign: 'left' }}>
                                <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                    <input id={recordId} type="checkbox" data-select={this.lastSelectedIndex === record.id?'yes':'no'}
                                    checked={this.state.selectedUserIds.includes(recordId)}
                                        onClick={(x) => {
                                            this.updateSelectedUserIds(x, record, 0);
                                        }}
                                    />
                                </div>
                            </td>
                        </>
                    }
                </tr>
            );
        }
        return row;
    }


    public setInitialPrintOption() {

        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.UserProductivityDashboardInfo 
        ) return ;
        
    }

    public updateInitialData() {
        this.props.action.ui.updateInitialData({
            masterCrud: this.props.dataStore.crud.data
        });
    }


    public OpsSelected(checked:boolean, record:any){
        console.log(checked);
        record.selectedOption = checked;
    }

    public onSelectClaimType(e: React.ChangeEvent<HTMLSelectElement>) {
        this.lastSelected = 'filters';
        let userIds:any[] = [];
        let updatedUserList:any[] = [];

        if(e.target.value !=="1"){

       this.props.dataStore.ui.groupList.map((filterUser:any) => {
           if(filterUser.id === e.target.value){
               if(Array.isArray(filterUser.users.User)){
                userIds =  [...filterUser.users.User];
               } else if(filterUser.users) {
                   userIds = [filterUser.users.User];
               }
            
           }
       });
        userIds.forEach(userId => {
            this.props.dataStore.ui.masterUserList.forEach((user:any,idx:number) => {
               if(userId['@ID'] && userId['@ID'] === user.id){
                updatedUserList.push( { firstName: user.firstName,
                 lastName: user.lastName, userName:user.userName,
                  id:user.id, selected:user.selected, apply:user.apply});
               }
           })
       }); 

    }else{
        updatedUserList = this.props.dataStore.ui.masterUserList;
    }
    this.setState({selectedGroupId: e.target.value});
       this.props.action.ui.updateUserList({
        masterCrud: updatedUserList
    });

       
    }


    public header = () => <thead><tr>
            {
                <React.Fragment>
                    <ARMHeaderCell
                        dataName="firstName"
                        text="First Name"
                        cellType="text"
                        sortable={true}
                        isSorted={0}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="lastName"
                        text="Last Name"
                        cellType="text"
                        sortable={true}
                        isSorted={0}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}

                    />
                    <ARMHeaderCell
                        dataName="userName"
                        text="User Name"
                        cellType="text"
                        sortable={true}
                        isSorted={0}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        text="Apply"
                        cellType="custom"
                    />
                    
                </React.Fragment>
            }
        </tr></thead>
        
        public headerSelectedUser = () => <thead><tr>
        {
            <React.Fragment>
                <ARMHeaderCell
                    dataName="firstName"
                    text="First Name"
                    cellType="text"
                />
                <ARMHeaderCell
                    dataName="lastName"
                    text="Last Name"
                    cellType="text"

                />
                <ARMHeaderCell
                    dataName="userName"
                    text="User Name"
                    cellType="text"
                />
                
            </React.Fragment>
        }
    </tr></thead>

     public onApplyHandler(){
        let selectedUsers = this.state.selectedUsers;
        let selectedUserIds = this.state.selectedUserIds;
        if(selectedUserIds.length >25){
            this.setState({message:`The number of users selected exceeds the maximum of 25 total users by ${selectedUserIds.length - 25}.`,
                            isOpen:true});
            return;
        }
        this.props.dataStore.ui.masterUserList.forEach((user:any)=>{
                if(selectedUserIds.includes(user.id)){
                    selectedUsers.push(user);
                }
        })

        var unique:any = {};
        var distinct:any = [];
        selectedUsers.forEach(function (x:any)  {
        if (!unique[x.id] && selectedUserIds.includes(x.id)) {
        distinct.push(x);
        unique[x.id] = true;
        }
     });

        this.setState({
                selectedUsers:distinct
            })
        
     }

     public onSelectTheseHandler(){
        let selectedUsers = this.state.selectedUsers;
         let selectedUserIds = this.state.selectedUserIds;

       

         if (this.props.dataStore.ui.userList.length > 25) {

            let message = 'Selecting all users in filter exceeds maximum of 25 total users.\n' + 'There are ' + this.state.selectedUserIds.length + ' users currently selected.';
             this.setState({message:message,isOpen:true}); 
             return;
             
         }

         else if ((this.props.dataStore.ui.userList.length + this.state.counter) > 25) {

            let message = 'Selecting all users in filter exceeds maximum of 25 total users.\n' + 'There are ' + this.state.selectedUserIds.length + ' users currently selected.';
             this.setState({message:message,isOpen:true}); 
             return;
             

         }
        this.props.dataStore.ui.userList.forEach((user:any)=>{
                user.selected = true;
                selectedUsers.push(user);
                selectedUserIds.push(user.id);
        })

        

        var unique:any = {};
        var distinct:any = [];
        selectedUsers.forEach(function (x:any)  {
        if (!unique[x.id]) {
        distinct.push(x);
        unique[x.id] = true;
        }
     });

     this.setState({
        selectedUserIds:selectedUserIds
    })
        this.setState({
                selectedUsers:distinct
            })
        
     }

     public onClearHandler(){
        this.setState({selectedUsers:[]})
        this.props.action.ui.updateSelectedUserList({
            masterCrud:[]
         })
         this.setState({selectedUserIds: []});
         this.setState({counter: 0});
     }


     public handleuserTaskChange(e:any, key:any){
        let displayedUserTask = this.props.dataStore.ui.displayedUserTask;
            displayedUserTask[key] = e.target.checked;
            if(key !== 'all'){
                displayedUserTask['all'] = false;
            }else{
                for(let keyObj in displayedUserTask){
                    if(keyObj !== 'all'){
                        displayedUserTask[keyObj] = false;
                    }
                }
                displayedUserTask['all'] = true;
            }

            this.props.action.ui.updateDisplayedUserTask({
                masterCrud:displayedUserTask
            });
     }
    
    public handleDateChange(e: any, key: any) {
        this.windowScrollPosition = document.getElementById('view-Productivity')?.scrollTop;
        this.lastSelected = 'date';
        let dateRange = this.props.dataStore.ui.dateRange;
            dateRange[key] = e;
            this.props.action.ui.updateDateRange({
                masterCrud:dateRange
            })
     }

     public getUsers(){
         let resultSet:any[] =[];
        this.state.selectedUsers.forEach((user)=>{
            resultSet.push(parseInt(user.id));
        })
        return resultSet;

     }

     public getdisplayedTask(){
         let tasks:any = [];
         let displayedUserTask = this.props.dataStore.ui.displayedUserTask;
         if(displayedUserTask['all'] === true){
             tasks = this.allDisplayedUserTasksValues;
         } else { 
        for(const property in displayedUserTask){
            if(displayedUserTask[property])
            tasks.push(this.displayedUserTasksValues[property]);
            }
        }
        return tasks;
    }

    toDate = (date: string): Date => {
        let patten = /^\d{2}\/\d{2}\/\d{4}$/

        if (!isNull(date) && !isUndefined(date) && !isEmpty(date)) {
            return new Date(date.replace(patten, date));
        }

        return new Date();
    }

     public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
         
        console.log('filter');
         let resultObj ={
         filter:{
             users: this.getUsers(),
             tasks: this.getdisplayedTask(),
             fromDate:this.props.dataStore.ui.dateRange.startDate,
             thruDate:this.props.dataStore.ui.dateRange.endDate
             
         }
        }

        console.log(resultObj);

        if(resultObj.filter.fromDate === ''){
            let message = 'Select an Beginning Date';
            this.setState({message:message,isOpen:true});
            return;
        }
        if(resultObj.filter.thruDate === ''){
            let message = 'Select an Ending Date';
            this.setState({message:message,isOpen:true});
            return;
        }
         if(resultObj.filter.users.length === 0){
             let message = 'Select 1 to 25 users.';
             this.setState({message:message,isOpen:true});
             return;
         }
         if(resultObj.filter.tasks.length === 0){
            let message = 'Atleast one task needs to be selected';
            this.setState({message:message,isOpen:true});
            return;
        }
        
         if (this.toDate(resultObj.filter.fromDate) > this.toDate(resultObj.filter.thruDate)) {
            let message = 'Please select valid Beginning date and Ending date';
            this.setState({message:message,isOpen:true});
            return;
        }
        this.props.action.ui.updateResultObject({
            masterCrud:resultObj
         })
        this.setState({selectedUsers: []},() =>{
            this.props.history.push('/Dashboard/productivityBar');
        });
        
     }
     
     public onClearAlert() {
        this.setState({isOpen:false, message:''});
    }
    public onAlert(msg: string) {
        this.props.action.ui.sendBridgeMaintenanceAlert({ masterCrud: this.props.dataStore.crud.data, uiData: { value: msg } });
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push('/LandingPage');
    }

    public handleSort =(sortIndicator: ISortIndicator) => {
        const  userList:any[]  = this.props.dataStore.ui.userList;

        let sortedRecords:any  = [];

        switch (sortIndicator.sortColumn) {
            case 'firstName': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    userList.sort((a, b) => (a.firstName).toString().localeCompare((b.firstName).toString())) :
                    userList.sort((a, b) => (b.firstName).toString().localeCompare((a.firstName).toString()));
            }
                break;
            case 'lastName': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    userList.sort((a, b) => (a.lastName).toString().localeCompare((b.lastName).toString())) :
                    userList.sort((a, b) => (b.lastName).toString().localeCompare((a.lastName).toString()));
            }
                break;
                case 'userName': {
                    sortedRecords = sortIndicator.sortDirection == 'up' ?
                        userList.sort((a, b) => (a.userName).toString().localeCompare((b.userName).toString())) :
                        userList.sort((a, b) => (b.userName).toString().localeCompare((a.userName).toString()));
                }
                break;
        }
        this.props.action.ui.updateSelectedUserList({
            masterCrud:sortedRecords
         })

         this.setState({sortIndicator});
    }

    handleScroll  = (e:any) =>{
        this.scrollPosition = this.listRef.children[0].scrollTop;
    }

    render(){
    
        var buttons = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e) } />;
     
        let row = this.getCustomRow();
        return (
            <DialogWrapper
                domId="view-Productivity"
                buttons={buttons}
                title="Dashboard User Productivity Criteria"
                helpUrl={"/Support/Help/HELP_Dashboard_Productivity.htm"}
            >
                <ContentWrapper>
                    <SelectList>
                        <DialogFieldset style={{paddingLeft:'10px', paddingRight:'10px', paddingBottom:'10px'}}>
                            <DialogLegend>Users</DialogLegend>
                            <UserContainer>
                                <UserSection 
                                    ref={(elem:any) => this.listRef = elem}
                                    onScroll={(e:any) => this.handleScroll(e)} 
                                >
                                    <ARMGrid
                                        dom-ID="view-Productivity-users"
                                        maxHeight='283px'
                                        scrollToTop={false}
                                        columns={this.getReportListGridColumnHeaders()}
                                        headerComponent={this.header}
                                        records={this.props.dataStore.ui.userList}
                                        isFixedHeader={true}
                                        rowComponent={row}
                                    />
                                </UserSection>
                                <FilterSection>
                                    <DialogLegend>Filter users</DialogLegend>
                                    <SelectComponent
                                        title='Filter Users'
                                        size={10}
                                        width='320px'
                                        selectedValue={this.state.selectedGroupId}
                                        className='selectedUserList'
                                        height='273px'
                                        optionFields={{
                                            value: "id",
                                            text: "name"
                                        }}
                                        records={this.props.dataStore.ui.groupList}
                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectClaimType(e)}
                                    >
                                    </SelectComponent>
                                </FilterSection>
                            </UserContainer>
                            <ButtonSection>
                                <Button
                                    domID="view-Productivity-Apply"
                                    name="APPLY"
                                    buttonType="emphasized"
                                    size="control"
                                    type="button"
                                    className="previewButton"
                                    onClick={(e:any) => this.onApplyHandler()}
                                ></Button>
                                <Button
                                    domID="view-Productivity-SelectThese"
                                    name="SELECT THESE"
                                    buttonType="emphasized"
                                    size="control"
                                    type="button"
                                    onClick={(e:any) => this.onSelectTheseHandler()}
                                    className="previewButton"
                                ></Button>
                                <Button
                                    domID="view-Productivity-Clear"
                                    name="CLEAR"
                                    buttonType="standard"
                                    size="control"
                                    type="button"
                                    className="previewButton"
                                    onClick={(e:any)=> this.onClearHandler()}
                                ></Button>
                            </ButtonSection>
                            <DialogLegend>Selected Users</DialogLegend>
                            <SelectUserList>                   
                                <ARMGrid
                                    dom-ID="view-Productivity-selected-user-list"
                                    maxHeight='250px'
                                    columns={this.getSelectedUseGridColumnHeaders()}
                                    headerComponent={this.headerSelectedUser}
                                    records={this.state.selectedUsers}
                                    selectionKey='empty'
                                    isFixedHeader={true}
                                />
                            </SelectUserList>
                        </DialogFieldset>
                    </SelectList>
                    <UserTaskSection>
                        <DialogFieldset style={{marginBottom:'5px', width:'255px'}}>
                            <DialogLegend>Select Displayed User Tasks</DialogLegend>
                            <CheckBoxWrapper>
                                <CheckBoxComponent
                                    id='batchClaim'
                                    label='All'
                                    width='200px'  
                                    checked={this.props.dataStore.ui.displayedUserTask.all}
                                    onChange={(e) => this.handleuserTaskChange(e, 'all')}/>                             
                                <CheckBoxComponent
                                    id='viewedClaims'
                                    label='Viewed Claims'
                                    width='200px' 
                                    checked={this.props.dataStore.ui.displayedUserTask.viewedClaims}
                                    onChange={(e) => this.handleuserTaskChange(e, 'viewedClaims')}/>
                                <CheckBoxComponent
                                    id='validatedClaims'
                                    label='Validated Claims'
                                    width='200px' 
                                    checked={this.props.dataStore.ui.displayedUserTask.validatedClaims}
                                    onChange={(e) => this.handleuserTaskChange(e, 'validatedClaims')}/>
                                <CheckBoxComponent
                                    id='changedClaims'
                                    label='Changed Claims'
                                    width='200px'
                                    checked={this.props.dataStore.ui.displayedUserTask.changedClaims}
                                    onChange={(e) => this.handleuserTaskChange(e, 'changedClaims')}/>
                                <CheckBoxComponent
                                    id='createdClaims'
                                    label='Created Claims'
                                    width='200px' 
                                    checked={this.props.dataStore.ui.displayedUserTask.createdClaims}
                                    onChange={(e) => this.handleuserTaskChange(e, 'createdClaims')}/>
                                <CheckBoxComponent
                                    id='deletedClaims'
                                    label='Deleted Claims'
                                    width='200px' 
                                    checked={this.props.dataStore.ui.displayedUserTask.deletedClaims}
                                    onChange={(e) => this.handleuserTaskChange(e, 'deletedClaims')}/>
                                <CheckBoxComponent
                                    id='printedClaims'
                                    label='Printed Claims'
                                    width='200px' 
                                    checked={this.props.dataStore.ui.displayedUserTask.printedClaims}
                                    onChange={(e) => this.handleuserTaskChange(e, 'printedClaims')}/>
                            </CheckBoxWrapper>
                        </DialogFieldset>
                    </UserTaskSection>
                    <DateRangeSection>
                        <DialogFieldset>
                            <DialogLegend>select displayed Date range</DialogLegend>
                            <DatePickerWrapper>
                                <label>BEGINNING DATE</label> 
                                <ARMDatePicker
                                    domID="view-Productivity-begin-date"
                                    className="cal_style"
                                    allowTime={false}
                                    showDefaultButtonBar={false}
                                    lockKeyboardInput={true}
                                    minDate={this.minDate}
                                    initialDate={this.props.dataStore.ui.dateRange.startDate }
                                    onInputChange={(e:any) => this.handleDateChange(e,'startDate')}
                                />
                            </DatePickerWrapper>
                            <DatePickerWrapper>
                                <label>ENDING DATE</label>
                                <ARMDatePicker
                                    domID="view-Productivity-end-date"
                                    className="cal_style"
                                    allowTime={false}
                                    minDate={this.props.dataStore.ui.dateRange.startDate || this.minDate}
                                    lockKeyboardInput={true}
                                    showDefaultButtonBar={false}
                                    initialDate={this.props.dataStore.ui.dateRange.endDate }
                                    onInputChange={(e:any) => this.handleDateChange(e,'endDate')}
                                />
                            </DatePickerWrapper>
                        </DialogFieldset>
                    </DateRangeSection>
                </ContentWrapper>
                <ModalConfirmation
                    isOpen={this.state.isOpen}
                    alertMode={true}
                    message={this.state.message}
                    onModalToggle={() => this.onClearAlert()}
                />
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IViewProductivityState, IViewProductivityActionProps, IOwnProps, IViewProductivityProps, ApplicationState>(
    createCrudMapStateToProps('ViewProductivity'),             // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ViewProductivity);

export default withRouter(connectedHoc);