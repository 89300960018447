import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Input, TextArea, Button, SelectDropdown, Tabs } from '@optum-uicl/ui-core/dist';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { SelectComponent } from '@common/SelectComponent';
import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';
import { handleChange, pageLeave } from '@commonResources/userModified';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IBulkImportExportState, IBulkImportExportActions, IBulkImportExportActionProps, actionCreators } from '@store/BulkImportExport';
import { CheckBoxComponent } from '@common/CheckBox';
import { URLs } from '@commonDevResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';
import { ICookies_Config } from '@store/ConfigData';
import { ModalConfirmation } from '@common/ModalConfirmation';
import AssuranceMenu from '@common/AssuranceMenu';
import { ARMNarrowDropdown } from '@common/UICLWrappers/ARMNarrowDropdown';
import { ARMNarrowCheck } from '@common/UICLWrappers/ARMNarrowCheckbox';
import { ARMNarrowInput } from '@common/UICLWrappers/ARMNarrowInput';
import { ARMNarrowBrowse } from '@common/ARMNarrowBrowse';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const EmptyHeader = styled.div``;

export const FirstColumnWrapper = styled.div`
    width:400px !important;
    padding: 5;
`;

const TableContent = styled.div`
    height:150px !important;
    overflow: auto;
    background-color: white;
    
`;

export const UpdatingDialogContainer = styled.div`
    width: 900px;
    height: 300px;
    border: 1px solid grey;
`;

export const UpdatingStatusMsg = styled.div`
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${Colors.black};
    ${Typography.headingSmall};
    ${Typography.ARMFontFamily};
`;

export const LabelWrapper = styled.div`
    display: inline;
    margin-bottom: 0.3rem;
    color: #37474F;
    font-family: 'optum-sans-regular';
    font-size: 11px;
    line-height: 1.4em;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-left:10px;
`;

const ManageBulkTransferContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 0px;
    min-height: 180px;
    min-height: 180px;
    #department-code,.select{
    width:1000px;
    min-width:950px;
    margin-bottom:5px;
}
 #tab-im,
 #tab-ex
    {
       box-shadow: 0 0 0 0 black!important;
       padding-top: 1px;
       padding-bottom: 1px;
    }


     #tab-im > label,
 #tab-ex > label,
     {
         margin-bottom: 0px;
         ${Typography.small};
     }

.option-class{
    font-size: 10pt !important;
}
.text-input{
   height: 22px !important;
}
#FacilityMaster_wrapper{
    margin:0px !important;
    width: 100%;
    height: 30px;
   
}
#PhysicianMaster_wrapper {
    margin: 0px !important;
    width: 100%;
    height: 30px;
}
#PhysicianAutoEntry_wrapper, #PayerAlias_wrapper {
    margin: 0px !important;
    width: 100%;
    height: 30px;
}

.narrow_input > input {
    height:32px !important;
    margin-top:-5px;
    width: 233px;
}
#FieldDelimiter {
    min-width:55px !important;
}

`;

interface IComponentProps {
    canEdit: boolean,
    canCreate: boolean,
};

interface ITabs {
    label: string
    domID: string
}

interface IDelimiterValue {
    text: string
    value: string
}
interface IComponentState {
    cancelLeave: boolean,
    activeTab: number,
    activeTabId: string,
    isAlertOpen: boolean,
    alertMessage: string,
    isPhysicianOptionalFilter: boolean,
    upload: any,
    files: any,
    PhysicianFileName: string,
    FacilityFileName: string,
    PhysicianAEFileName: string,
    PayerAliasFileName: string,
    isUpdating: boolean
};

export class AssignmentData {

    UserId: string = "";
    UserName: string = "";
    AlphaSplit: string = "";
}


export const DEFAULT_STATE: IComponentState = {
    cancelLeave: false,
    activeTab: 0, activeTabId: 'tab-im',
    isAlertOpen: false, alertMessage: "",
    isPhysicianOptionalFilter: false,
    upload: [{ label: 'Physician', fileName: '', file: new FormData() }],
    files: new FormData(),
    PhysicianFileName: "",
    FacilityFileName: "",
    PhysicianAEFileName: "",
    PayerAliasFileName: "",
    isUpdating: false
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IBulkImportExportTypeProps = IMergeApiComponentProps<IBulkImportExportState, IBulkImportExportActionProps, IOwnProps>;

export class BulkImportExport extends React.Component<IBulkImportExportTypeProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canEdit: false,
        canCreate: false
    };

    constructor(props: IBulkImportExportTypeProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public async componentDidMount() {
        this.props.action.ui.selectFieldDelimiter({
            apiData: this.props.dataStore.api.data,
            uiData: {
                value: "124"
            }
        });
        pageLeave();
        console.log("Edit", this.props.canEdit);
    }

    public validateImportExport() {
        if (this.state.activeTab === 0) {
            if (this.state.PhysicianFileName === "" && this.state.PhysicianAEFileName === "" && this.state.FacilityFileName === "" && this.state.PayerAliasFileName === "") {
                this.setState({ isAlertOpen: true, alertMessage: "Please select a master file on your computer" });
                return false;
            }
        }
        else if (this.state.activeTab === 1) {
            if (!this.props.dataStore.ui.ExportFacility && !this.props.dataStore.ui.ExportPhysician && !this.props.dataStore.ui.ExportPhysicianAE && !this.props.dataStore.ui.ExportPayerAlias) {
                this.setState({ isAlertOpen: true, alertMessage: "Please select a file type" });
                return false;
            }
        }
        return true;
    }

    saveDetails() {
        this.setState({ isUpdating: true });
        var xmlData: BulkTransferImportExport = {};

        xmlData.ExportFacility = this.props.dataStore.ui.ExportFacility;
        xmlData.ExportPhysician = this.props.dataStore.ui.ExportPhysician;
        xmlData.ExportPhysicianAE = this.props.dataStore.ui.ExportPhysicianAE;
        xmlData.ExportPayerAlias = this.props.dataStore.ui.ExportPayerAlias;
        xmlData.Delimiter = this.props.dataStore.ui.Delimiter;
        xmlData.HeaderRow = this.props.dataStore.ui.HeaderRow;
        xmlData.FirstNameFrom = this.props.dataStore.ui.FirstNameFrom;
        xmlData.FirstNameTo = this.props.dataStore.ui.FirstNameTo;
        xmlData.LastNameFrom = this.props.dataStore.ui.LastNameFrom;
        xmlData.LastNameTo = this.props.dataStore.ui.LastNameTo;
        xmlData.Import = this.state.activeTab === 0 ? 1 : 0;
        xmlData.KeyNoFrom = this.props.dataStore.ui.KeyNoFrom;
        xmlData.KeyNoTo = this.props.dataStore.ui.KeyNoTo;
        xmlData.PhysicianNoFrom = this.props.dataStore.ui.PhysicianNoFrom;
        xmlData.PhysicianNoTo = this.props.dataStore.ui.PhysicianNoTo;
        xmlData.TaxIDFrom = this.props.dataStore.ui.TaxIDFrom;
        xmlData.TaxIDTo = this.props.dataStore.ui.TaxIDTo;
        xmlData.LOBFrom = this.props.dataStore.ui.LOBFrom;
        xmlData.LOBTo = this.props.dataStore.ui.LOBTo;
        xmlData.PhysicianFileName = this.state.PhysicianFileName;
        xmlData.FacilityFileName = this.state.FacilityFileName;
        xmlData.PhysicianAEFileName = this.state.PhysicianAEFileName;
        xmlData.PayerAliasFileName = this.state.PayerAliasFileName;
        fetch(URLs.api + '/api/data/BulkTransferImportExport?bulkTransferImportExport=' + JSON.stringify(xmlData),
            {
                method: 'POST',
                headers: {
                    'Authorization': `${getRawToken()}`
                },
                body: this.state.files,
            })
            .then(async (response) => {
                // get json response here
                let data: any = await response.json();
                console.log("DataValue", data);
                if (data === "success") {
                    this.setState({ isUpdating: false });
                    this.props.history.push('/LandingPage');
                }
                else if (data === "fileerror") {
                    this.setState({ isUpdating: false });
                    this.setState({ isAlertOpen: true, alertMessage: "The file you wish to import must be a delimited file type" });
                }
                else {
                    this.setState({ isUpdating: false });
                    this.setState({ isAlertOpen: true, alertMessage: "Error while Importing/ Exporting the file. Please try again" });
                }
            })
            .catch(error => {
                console.error('WGD: ' + error);
                this.setState({ isUpdating: false });
            });
    }


    public componentWillUnmount() {
        pageLeave();
    }

    public onClickRemoveButton(e: React.MouseEvent<HTMLButtonElement>) {
        handleChange();
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.validateImportExport()) {
            this.saveDetails();
        }
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }


    public getTabs(): any {
        var tabs: ITabs[] = [];
        var im = { label: 'Import', domID: 'tab-im' };
        var ex = { label: 'Export', domID: 'tab-ex' };
        tabs.push(im);
        tabs.push(ex);
        return tabs;
    }

    public setActiveTab(id: string, index: any) {
        this.setState({ activeTabId: id, activeTab: index.selectedTabIndex });

    }

    public onTabSelect(event: any, index: any) {
        if (event !== undefined) {
            this.setActiveTab(event.currentTarget.id, index);
        }
    }

    public getInitialTab(): number {
        return this.state.activeTab;
    }

    public uploadFilePhysician(uploadedfile: any, index: any) {
        handleChange();
        const uploadData = new FormData()
        uploadData.append('file', uploadedfile.target.files)
        const files = this.state.files
        files?.delete(`file[${index}]`)
        files.append(`file[${index}]`, uploadedfile.target.files[0])
        this.setState({ files: files, PhysicianFileName: uploadedfile.target.files[0].name })
    }
    public uploadFileFacility(uploadedfile: any, index: any) {
        handleChange();
        const uploadData = new FormData()
        uploadData.append('file', uploadedfile.target.files)
        const files = this.state.files
        files?.delete(`file[${index}]`)
        files.append(`file[${index}]`, uploadedfile.target.files[0])
        this.setState({ files: files, FacilityFileName: uploadedfile.target.files[0].name })
    }
    public uploadFilePhysicianAE(uploadedfile: any, index: any) {
        handleChange();
        const uploadData = new FormData()
        uploadData.append('file', uploadedfile.target.files)
        const files = this.state.files
        files?.delete(`file[${index}]`)
        files.append(`file[${index}]`, uploadedfile.target.files[0])
        this.setState({ files: files, PhysicianAEFileName: uploadedfile.target.files[0].name })
    }
    public uploadFilePayerAlias(uploadedfile: any, index: any) {
        handleChange();
        const uploadData = new FormData()
        uploadData.append('file', uploadedfile.target.files)
        const files = this.state.files
        files?.delete(`file[${index}]`)
        files.append(`file[${index}]`, uploadedfile.target.files[0])
        this.setState({ files: files, PayerAliasFileName: uploadedfile.target.files[0].name })
    }

    public render() {
        var instruction = <>Click on the tab you need. To Import, browse to the data file and click OK. To Export, check what you want to export and click OK. Use Download Files to download the export file to your local system.</>;
        var buttons = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        let DelimiterValue: IDelimiterValue[] = [];
        DelimiterValue.push({ text: "|", value: "124" });
        DelimiterValue.push({ text: "Tab", value: "9" });
        return (
            <>
                <DialogWrapper title="Bulk Transfer" width='900px' instruction={instruction} helpUrl='/Support/Help/HELP_Maint_BulkImport.htm' buttons={buttons} isBusy={false}>
                    {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                    {!this.state.isUpdating ? (
                        <ManageBulkTransferContent>
                            <ContentRowWrapper>
                                <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                                    <ARMNarrowDropdown
                                        title="FieldDelimiter"
                                        label="Field Delimiter:"
                                        className="dropdown"
                                        isLabel={true}
                                        width="30px"
                                        multiple="false"
                                        records={DelimiterValue}
                                        optionFields={{
                                            value: "value",
                                            text: "text"
                                        }}
                                        onSelect={(e: any) => {
                                            this.props.action.ui.selectFieldDelimiter({
                                                apiData: this.props.dataStore.api.data,
                                                uiData: {
                                                    value: e.target.value
                                                }
                                            });
                                            handleChange();
                                        }}
                                        selectedValue={this.props.dataStore.ui.Delimiter !== undefined ? this.props.dataStore.ui.Delimiter.toString() : "124"}
                                    />
                                </div>
                            </ContentRowWrapper>
                            <ContentRowWrapper>
                                <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                                    <ARMNarrowCheck
                                        id="HeaderRow"
                                        label='Header Row:'
                                        checked={this.props.dataStore.ui.HeaderRow !== undefined ? this.props.dataStore.ui.HeaderRow === 1 ? true : false : false}
                                        onChangeSelect={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.props.action.ui.checkHeaderRow({
                                                apiData: this.props.dataStore.api.data,
                                                uiData: {
                                                    value: e.target.checked
                                                }
                                            });
                                            handleChange();
                                        }

                                        }
                                    />
                                </div>
                            </ContentRowWrapper>
                            <ContentRowWrapper style={{ width: "100%" }}>

                                <ContentColumnWrapper style={{ width: "72%" }}>
                                    <ContentRowWrapper id={"tab-container"} style={{ maxHeight: '30px', marginTop: "5px" }}>
                                        <ContentColumnWrapper>
                                            <Tabs
                                                tabs={this.getTabs()}
                                                onTabSelect={(e: React.SyntheticEvent, index: {
                                                    selectedTabIndex: ((prevState: number) => number) | number;
                                                }) => { this.onTabSelect(e, index) }}
                                                initialTab={this.getInitialTab()}
                                            />
                                        </ContentColumnWrapper>
                                    </ContentRowWrapper>
                                    {this.state.activeTab === 0 && this.state.activeTabId === "tab-im" &&
                                        <>
                                            <ContentRowWrapper style={{ marginTop: "5px", marginLeft: "42px" }}>
                                                <ARMNarrowBrowse labelValue="Facility Master:"
                                                    inputId="facility-upload"
                                                    classNameValue="text-input"
                                                    maxLength={300}
                                                    initialValue={this.state.FacilityFileName}
                                                    disabledInput={true}
                                                    browseId="facilityupload"
                                                    browseInputId="facility"
                                                    onChange={(e: any) => this.uploadFileFacility(e, 1)}
                                                    styleValue={{ marginTop: "20px", marginLeft: "40px" }}
                                                    inputColumnStyleValue={{ marginTop: "10px", marginLeft: "5px" }}
                                                    browseColumnStyleValue={{ marginTop: "5px", marginLeft: "10px" }}
                                                    divBrowseStyle={{ marginTop: '12px' }}
                                                    acceptType=".txt"
                                                    canEdit={this.props.canEdit}
                                                >
                                                </ARMNarrowBrowse>
                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ marginTop: "5px", marginLeft: "40px" }}>
                                                <ARMNarrowBrowse labelValue="Physician Master:"
                                                    inputId="physician-upload"
                                                    classNameValue="text-input"
                                                    maxLength={300}
                                                    initialValue={this.state.PhysicianFileName}
                                                    disabledInput={true}
                                                    browseId="physicianupload"
                                                    browseInputId="physician"
                                                    onChange={(e: any) => this.uploadFilePhysician(e, 2)}
                                                    styleValue={{ marginTop: "20px", marginLeft: "30px" }}
                                                    inputColumnStyleValue={{ marginTop: "10px", marginLeft: "5px" }}
                                                    browseColumnStyleValue={{ marginTop: "5px", marginLeft: "10px" }}
                                                    divBrowseStyle={{ marginTop: '12px' }}
                                                    acceptType=".txt"
                                                    canEdit={this.props.canEdit}
                                                >
                                                </ARMNarrowBrowse>
                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ marginTop: "5px", marginLeft: "40px" }}>
                                                <ARMNarrowBrowse labelValue="Physician Auto Entry:"
                                                    inputId="physicianae-upload"
                                                    classNameValue="text-input"
                                                    maxLength={300}
                                                    initialValue={this.state.PhysicianAEFileName}
                                                    disabledInput={true}
                                                    browseId="physicianaeupload"
                                                    browseInputId="physicianae"
                                                    onChange={(e: any) => this.uploadFilePhysicianAE(e, 3)}
                                                    styleValue={{ marginTop: "20px", marginLeft: "5px" }}
                                                    inputColumnStyleValue={{ marginTop: "10px", marginLeft: "5px" }}
                                                    browseColumnStyleValue={{ marginTop: "5px", marginLeft: "10px" }}
                                                    divBrowseStyle={{ marginTop: '12px' }}
                                                    acceptType=".txt"
                                                    canEdit={this.props.canEdit}
                                                >
                                                </ARMNarrowBrowse>
                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ marginTop: "5px", marginBottom: "10px", marginLeft: "40px" }}>
                                                <ARMNarrowBrowse labelValue="Payer Alias:"
                                                    inputId="payeralias-upload"
                                                    classNameValue="text-input"
                                                    maxLength={300}
                                                    initialValue={this.state.PayerAliasFileName}
                                                    disabledInput={true}
                                                    browseId="payeraliasupload"
                                                    browseInputId="payeralias"
                                                    onChange={(e: any) => this.uploadFilePayerAlias(e, 4)}
                                                    styleValue={{ marginTop: "20px", marginLeft: "69px" }}
                                                    inputColumnStyleValue={{ marginTop: "10px", marginLeft: "5px" }}
                                                    browseColumnStyleValue={{ marginTop: "5px", marginLeft: "10px" }}
                                                    divBrowseStyle={{ marginTop: '12px' }}
                                                    acceptType=".txt"
                                                    canEdit={this.props.canEdit}
                                                >
                                                </ARMNarrowBrowse>
                                            </ContentRowWrapper>
                                        </>
                                    }
                                    {this.state.activeTab === 1 && this.state.activeTabId === "tab-ex" &&
                                        <ContentRowWrapper>
                                            <ContentColumnWrapper style={{ marginTop: "10px", width: "9%" }}>
                                                <div>
                                                    <LabelWrapper>File Type: </LabelWrapper>
                                                </div>
                                            </ContentColumnWrapper>
                                            <ContentColumnWrapper style={{ marginTop: "10px", width: "22%" }}>
                                                <div style={{ height: "120px" }}>
                                                    <CheckBoxComponent
                                                        id='FacilityMaster'
                                                        label='Facility Master'
                                                        checked={this.props.dataStore.ui.ExportFacility}
                                                        disabled={!this.props.canCreate}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            this.props.action.ui.checkExportFacility({
                                                                apiData: this.props.dataStore.api.data,
                                                                uiData: {
                                                                    value: e.target.checked
                                                                }
                                                            });
                                                            handleChange();
                                                        }
                                                        }

                                                    />
                                                    <CheckBoxComponent
                                                        id='PhysicianMaster'
                                                        label='Physician Master'
                                                        checked={this.props.dataStore.ui.ExportPhysician}
                                                        disabled={!this.props.canCreate}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            this.props.action.ui.checkExportPhysician({
                                                                apiData: this.props.dataStore.api.data,
                                                                uiData: {
                                                                    value: e.target.checked
                                                                }
                                                            });
                                                            this.setState({ isPhysicianOptionalFilter: e.target.checked })
                                                            handleChange();
                                                        }}
                                                    />
                                                    <CheckBoxComponent
                                                        id='PhysicianAutoEntry'
                                                        label='Physician Auto Entry'
                                                        checked={this.props.dataStore.ui.ExportPhysicianAE}
                                                        disabled={!this.props.canCreate}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            this.props.action.ui.checkExportPhysicianAutoEntry({
                                                                apiData: this.props.dataStore.api.data,
                                                                uiData: {
                                                                    value: e.target.checked
                                                                }
                                                            });
                                                            handleChange();
                                                        }}
                                                    />
                                                    <CheckBoxComponent
                                                        id='PayerAlias'
                                                        label='Payer Alias'
                                                        checked={this.props.dataStore.ui.ExportPayerAlias}
                                                        disabled={!this.props.canCreate}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            this.props.action.ui.checkExportPayerAlias({
                                                                apiData: this.props.dataStore.api.data,
                                                                uiData: {
                                                                    value: e.target.checked
                                                                }
                                                            });
                                                            handleChange();
                                                        }}
                                                    />
                                                </div>
                                            </ContentColumnWrapper>
                                            <ContentColumnWrapper style={{ paddingRight: "5px", width: "69%" }}>
                                                {this.state.isPhysicianOptionalFilter &&
                                                    <DialogFieldset id='export-optionalFilter'>
                                                        <DialogLegend>Optional filter</DialogLegend>
                                                        <ContentRowWrapper style={{ height: "60px", marginLeft: "25px" }}>
                                                            <ContentColumnWrapper style={{ marginLeft: "15px" }}>
                                                                <ARMNarrowInput
                                                                    id="lastnamefrom"
                                                                    classNameValue="text-input"
                                                                    label="Last Name:"
                                                                    labelAbove
                                                                    maxLength={35}
                                                                    initialValue={this.props.dataStore.ui.LastNameFrom !== undefined ? this.props.dataStore.ui.LastNameFrom : ""}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.props.action.ui.changeLastNameFrom({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                />
                                                            </ContentColumnWrapper>
                                                            <ContentColumnWrapper style={{ marginTop: "25px" }}>
                                                                <ARMNarrowInput
                                                                    id="lastnameto"
                                                                    classNameValue="text-input"
                                                                    label="Thru"
                                                                    maxLength={35}
                                                                    initialValue={this.props.dataStore.ui.LastNameTo !== undefined ? this.props.dataStore.ui.LastNameTo : ""}
                                                                    // hasError={this.state.error && (this.state.selectedItem["@Name"] === '' || this.state.selectedItem["@Name"] === '- ADD A NEW AUTO SUBMIT -') ||
                                                                    //    this.state.errorNameExist && (this.state.Items.filter((item: any) =>
                                                                    //        item['@Name'] === this.state.selectedItem['@Name'] && item['@ID'] !== this.state.selectedItem['@ID']).length > 0)}

                                                                    // errorMessage={this.state.errorNameExist ? 'This Auto Submit already exists. Please type in a different name.' : this.state.error && 'Name must not be blank.'}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.props.action.ui.changeLastNameTo({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
                                                                        this.props.action.ui.changeLastNameTo({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                />
                                                            </ContentColumnWrapper>
                                                        </ContentRowWrapper>
                                                        <ContentRowWrapper style={{ height: "60px", marginLeft: "25px" }}>
                                                            <ContentColumnWrapper style={{ marginLeft: "15px" }}>
                                                                <ARMNarrowInput
                                                                    id="firstnamefrom"
                                                                    classNameValue="text-input"
                                                                    label="First Name:"
                                                                    labelAbove
                                                                    maxLength={25}
                                                                    initialValue={this.props.dataStore.ui.FirstNameFrom !== undefined ? this.props.dataStore.ui.FirstNameFrom : ""}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.props.action.ui.changeFirstNameFrom({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
                                                                        this.props.action.ui.changeFirstNameFrom({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                />

                                                            </ContentColumnWrapper>
                                                            <ContentColumnWrapper style={{ marginTop: "25px" }}>
                                                                <ARMNarrowInput
                                                                    id="firstnameto"
                                                                    classNameValue="text-input"
                                                                    label="Thru"
                                                                    maxLength={25}
                                                                    initialValue={this.props.dataStore.ui.FirstNameTo !== undefined ? this.props.dataStore.ui.FirstNameTo : ""}

                                                                    // hasError={this.state.error && (this.state.selectedItem["@Name"] === '' || this.state.selectedItem["@Name"] === '- ADD A NEW AUTO SUBMIT -') ||
                                                                    //    this.state.errorNameExist && (this.state.Items.filter((item: any) =>
                                                                    //        item['@Name'] === this.state.selectedItem['@Name'] && item['@ID'] !== this.state.selectedItem['@ID']).length > 0)}

                                                                    // errorMessage={this.state.errorNameExist ? 'This Auto Submit already exists. Please type in a different name.' : this.state.error && 'Name must not be blank.'}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.props.action.ui.changeFirstNameTo({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
                                                                        this.props.action.ui.changeFirstNameTo({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                />
                                                            </ContentColumnWrapper>
                                                        </ContentRowWrapper>
                                                        <ContentRowWrapper style={{ height: "60px", marginLeft: "25px" }}>
                                                            <ContentColumnWrapper style={{ marginLeft: "15px" }}>
                                                                <ARMNarrowInput
                                                                    id="taxidfrom"
                                                                    classNameValue="text-input"
                                                                    label="Tax ID:"
                                                                    labelAbove
                                                                    maxLength={15}
                                                                    initialValue={this.props.dataStore.ui.TaxIDFrom !== undefined ? this.props.dataStore.ui.TaxIDFrom : ""}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.props.action.ui.changeTaxIDFrom({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
                                                                        this.props.action.ui.changeTaxIDFrom({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                />
                                                            </ContentColumnWrapper>
                                                            <ContentColumnWrapper style={{ marginTop: "25px" }}>
                                                                <ARMNarrowInput
                                                                    id="taxidto"
                                                                    classNameValue="text-input"
                                                                    label="Thru"
                                                                    maxLength={15}
                                                                    initialValue={this.props.dataStore.ui.TaxIDTo !== undefined ? this.props.dataStore.ui.TaxIDTo : ""}

                                                                    // hasError={this.state.error && (this.state.selectedItem["@Name"] === '' || this.state.selectedItem["@Name"] === '- ADD A NEW AUTO SUBMIT -') ||
                                                                    //    this.state.errorNameExist && (this.state.Items.filter((item: any) =>
                                                                    //        item['@Name'] === this.state.selectedItem['@Name'] && item['@ID'] !== this.state.selectedItem['@ID']).length > 0)}

                                                                    // errorMessage={this.state.errorNameExist ? 'This Auto Submit already exists. Please type in a different name.' : this.state.error && 'Name must not be blank.'}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.props.action.ui.changeTaxIDTo({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
                                                                        this.props.action.ui.changeTaxIDTo({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                />
                                                            </ContentColumnWrapper>
                                                        </ContentRowWrapper>
                                                        <ContentRowWrapper style={{ height: "60px", marginLeft: "25px" }}>
                                                            <ContentColumnWrapper style={{ marginLeft: "15px" }}>
                                                                <ARMNarrowInput
                                                                    id="keynofrom"
                                                                    classNameValue="text-input"
                                                                    label="Key No:"
                                                                    labelAbove
                                                                    maxLength={20}
                                                                    initialValue={this.props.dataStore.ui.KeyNoFrom !== undefined ? this.props.dataStore.ui.KeyNoFrom : ""}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.props.action.ui.changeKeyNoFrom({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
                                                                        this.props.action.ui.changeKeyNoFrom({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                />
                                                            </ContentColumnWrapper>
                                                            <ContentColumnWrapper style={{ marginTop: "25px" }}>
                                                                <ARMNarrowInput
                                                                    id="keynoto"
                                                                    classNameValue="text-input"
                                                                    label="Thru"
                                                                    maxLength={20}
                                                                    initialValue={this.props.dataStore.ui.KeyNoTo !== undefined ? this.props.dataStore.ui.KeyNoTo : ""}

                                                                    // hasError={this.state.error && (this.state.selectedItem["@Name"] === '' || this.state.selectedItem["@Name"] === '- ADD A NEW AUTO SUBMIT -') ||
                                                                    //    this.state.errorNameExist && (this.state.Items.filter((item: any) =>
                                                                    //        item['@Name'] === this.state.selectedItem['@Name'] && item['@ID'] !== this.state.selectedItem['@ID']).length > 0)}

                                                                    // errorMessage={this.state.errorNameExist ? 'This Auto Submit already exists. Please type in a different name.' : this.state.error && 'Name must not be blank.'}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.props.action.ui.changeKeyNoTo({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
                                                                        this.props.action.ui.changeKeyNoTo({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                />
                                                            </ContentColumnWrapper>
                                                        </ContentRowWrapper>
                                                        <ContentRowWrapper style={{ height: "60px", marginLeft: "25px" }}>
                                                            <ContentColumnWrapper style={{ marginLeft: "15px" }}>
                                                                <ARMNarrowInput
                                                                    id="physiciannofrom"
                                                                    classNameValue="text-input"
                                                                    label="Physician No:"
                                                                    labelAbove
                                                                    maxLength={38}
                                                                    initialValue={this.props.dataStore.ui.PhysicianNoFrom !== undefined ? this.props.dataStore.ui.PhysicianNoFrom : ""}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.props.action.ui.changePhysicianNoFrom({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
                                                                        this.props.action.ui.changePhysicianNoFrom({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                />
                                                            </ContentColumnWrapper>
                                                            <ContentColumnWrapper style={{ marginTop: "25px" }}>
                                                                <ARMNarrowInput
                                                                    id="physiciannoto"
                                                                    classNameValue="text-input"
                                                                    label="Thru"
                                                                    maxLength={38}
                                                                    initialValue={this.props.dataStore.ui.PhysicianNoTo !== undefined ? this.props.dataStore.ui.PhysicianNoTo : ""}

                                                                    // hasError={this.state.error && (this.state.selectedItem["@Name"] === '' || this.state.selectedItem["@Name"] === '- ADD A NEW AUTO SUBMIT -') ||
                                                                    //    this.state.errorNameExist && (this.state.Items.filter((item: any) =>
                                                                    //        item['@Name'] === this.state.selectedItem['@Name'] && item['@ID'] !== this.state.selectedItem['@ID']).length > 0)}

                                                                    // errorMessage={this.state.errorNameExist ? 'This Auto Submit already exists. Please type in a different name.' : this.state.error && 'Name must not be blank.'}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.props.action.ui.changePhysicianNoTo({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
                                                                        this.props.action.ui.changePhysicianNoTo({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                />
                                                            </ContentColumnWrapper>
                                                        </ContentRowWrapper>
                                                        <ContentRowWrapper style={{ height: "60px", marginLeft: "25px" }}>
                                                            <ContentColumnWrapper style={{ marginLeft: "15px" }}>
                                                                <ARMNarrowInput
                                                                    id="lobfrom"
                                                                    classNameValue="text-input"
                                                                    label="LOB:"
                                                                    labelAbove
                                                                    maxLength={38}
                                                                    initialValue={this.props.dataStore.ui.LOBFrom !== undefined ? this.props.dataStore.ui.LOBFrom : ""}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.props.action.ui.changeLOBFrom({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
                                                                        this.props.action.ui.changeLOBFrom({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                />
                                                            </ContentColumnWrapper>
                                                            <ContentColumnWrapper style={{ marginTop: "25px" }}>
                                                                <ARMNarrowInput
                                                                    id="lobto"
                                                                    classNameValue="text-input"
                                                                    label="Thru"
                                                                    maxLength={38}
                                                                    initialValue={this.props.dataStore.ui.LOBTo !== undefined ? this.props.dataStore.ui.LOBTo : ""}

                                                                    // hasError={this.state.error && (this.state.selectedItem["@Name"] === '' || this.state.selectedItem["@Name"] === '- ADD A NEW AUTO SUBMIT -') ||
                                                                    //    this.state.errorNameExist && (this.state.Items.filter((item: any) =>
                                                                    //        item['@Name'] === this.state.selectedItem['@Name'] && item['@ID'] !== this.state.selectedItem['@ID']).length > 0)}

                                                                    // errorMessage={this.state.errorNameExist ? 'This Auto Submit already exists. Please type in a different name.' : this.state.error && 'Name must not be blank.'}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.props.action.ui.changeLOBTo({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
                                                                        this.props.action.ui.changeLOBTo({
                                                                            apiData: this.props.dataStore.api.data,
                                                                            uiData: {
                                                                                value: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                />
                                                            </ContentColumnWrapper>
                                                        </ContentRowWrapper>
                                                    </DialogFieldset>
                                                }
                                            </ContentColumnWrapper>
                                        </ContentRowWrapper>
                                    }
                                </ContentColumnWrapper>
                            </ContentRowWrapper>
                        </ManageBulkTransferContent>
                    ) : (
                        <UpdatingDialogContainer>
                            <UpdatingStatusMsg>
                                Updating Bulk Import/ Export changes, please wait..
                            </UpdatingStatusMsg>
                        </UpdatingDialogContainer>
                    )}
                    <ModalConfirmation
                        isOpen={this.state.isAlertOpen}
                        formattedMessage={(
                            <React.Fragment>
                                <p>{this.state.alertMessage}.</p>
                            </React.Fragment>)
                        }
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.setState({ isAlertOpen: false, alertMessage: "" }) }}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                            this.setState({ isAlertOpen: false, alertMessage: "" })

                        }
                        }
                        onDeny={(e: React.MouseEvent<HTMLButtonElement>) => { this.setState({ isAlertOpen: false }) }}
                        alertMode={true}
                    />
                </DialogWrapper>
            </>
        );
    }
};

var connectedHoc = connect<IBulkImportExportState, IBulkImportExportActionProps, IOwnProps, IBulkImportExportTypeProps, ApplicationState>(
    createApiMapStateToProps('bulkimportexport'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(BulkImportExport);

export default withRouter(connectedHoc);
