import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, batch } from 'react-redux';
import styled from 'styled-components';

import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray, VerifyParentAndArray, enforceArray } from '@scripts/util/ValidationHelpers';
import { getRawToken } from '@scripts/session/SecurityToken';

// store includes
import { ApplicationState } from '@store/index';
import { ICookies_Config } from '@store/ConfigData';
import { emptyFilter } from '@store/api/ClaimFilterStore';
import { IClaimFilterState, IClaimFilterActions, IClaimFilterActionProps, actionCreators/*, validationCallback */ } from '@store/ClaimFilter';

// uicl
import { Input, TextArea, Button, SelectDropdown, Tabs, Checkbox } from '@optum-uicl/ui-core/dist';

// commonResources
import { URLs } from '@commonDevResources/constants';
import { Typography } from '@commonResources/typography';
import { deepCopyFunction, isMissingEndQuote, IsValidType } from '@commonResources/validations';

// common
import { RadioButtonComponent } from '@common/RadioButton';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { LobList } from '@common/FilterRelated/LobList';
import { FormTypeList } from '@common/FilterRelated/FormTypeList';
import { ModalFilterList } from '@common/FilterRelated/ModalFilterList';
import { IModalFilterListSelection, IModalFilterListOption, IModalFilterListFilters } from '@common/FilterRelated/ModalFilterList';
import { DialogWrapper, OKCancelButtons, FilterFourButtons, FilterFiveButtons, CenteredContent, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { SelectComponent } from '../../common/SelectComponent';

import { DialogFieldset, DialogLegend, DialogLegend3 } from '@common/DialogStyles';
import { CheckBoxComponent, CheckBoxLabel } from '@common/CheckBox';
import { ARMDatePicker, createMomentDate } from '@common/DateOrTimeRelated/ARMDatePicker';
import { SelectDropdownValue } from '@optum-uicl/ui-core/dist/Atoms/SelectDropdown/types';

interface IComponentState {
    disableBtn: boolean;
    showReleaseClaimModal: boolean;
    releaseClaimModalAnswer: boolean;
    tryForConfigDataAgain?: boolean;
};


export const DEFAULT_STATE: IComponentState = {
    disableBtn: false,
    showReleaseClaimModal: false,
    releaseClaimModalAnswer: false
};


export interface IClaimFilterComponentProps {
    canEdit: boolean,  // Permission
    canView: boolean,  // SecurityClearance
    canCreate: boolean, // FN_CREATE_FILTERS - enables Save button
    title: string,

    ncsIsEnabled: boolean,

    type?: string;  // be careful with this, the word "type" is used too much in filter, this value should be "Claim|Report|Remit"
    process: string;
    parameter?: any;
    apiType: string;
    executeJS?: any;
    execNode?: string;
    execAction?: string;
    execProcess?: string;
    execAttribs?: string;
    extraInstruction?: string;
    claimStateRestrictions?: string[];  // formerly XSL Transform ClaimState restrictions - BEWARE if you are working on "delete" you need to build this list based off security bit checks                                         
    claimStatusExclusions?: string[];    //  formerly XSL Transform ClaimStatus restriction - BEWARE if you are working on ("manual", "submit", "statereport") 

    testMode?: boolean;

    instruction?: string; // only send if overriding (viewX12claim)
    helpUrl?: string;  // only send if overriding (viewx12claim)
    /* startingTab: string;  // PopulateSelectLists("QuickFilter")??? */
    canDeleteUnreleased?: boolean,
    canDeleteReleased?: boolean,
};



type IClaimFilterOwnProps = IClaimFilterComponentProps & RouteComponentProps<{}>;
type IClaimFilterProps = IMergeApiComponentProps<IClaimFilterState, IClaimFilterActionProps, IClaimFilterOwnProps>;

type DisableableOptions = "deleted" | "unreleased" | "released" | "stateonly" | "formtype" | "claimlob" | "rejected" | "displayorder" | "electronic" | "paper" | "clearinghouseprint" | "direct" | "outsourced" | "hcd";


/////////////////////////////////////////////////////////////////////////////////////////////

export const FilterColumn1 = styled.div`

`;

export const FilterColumn2 = styled.div`



    #claimfilter_displayorder_qs,
    #claimfilter_releasebatch_qs,
    #claimfilter_submittedfile_qs,
    #claimfilter_user_qs, 
    #claimfilter_displayorder_adv,
    #claimfilter_releasebatch_adv,
    #claimfilter_submittedfile_adv,
    #claimfilter_user_adv, 
    #claimfilter_displayorder_pay,
    #claimfilter_releasebatch_pay,
    #claimfilter_submittedfile_pay,
    #claimfilter_user_pay, 
    #claimfilter_displayorder_elig, 
    #claimfilter_releasebatch_elig,
    #claimfilter_submittedfile_elig,
    #claimfilter_user_elig {
        width: 100%;
        padding-left:5px;
        padding-right:5px;
    }
    select option:checked, select option:active{
       background-color: #E9F1FF !important;
       color: #002677 !important; 
       border-top: 1px solid #0C55B8;
       border-bottom: 1px solid #0C55B8;
       border-left:none !important;
       border-right:none !important;
       font-weight:bold;
    }
    select option:hover{
        background-color: #CDDDFE !important;
        color: #002677 !important;
        cursor: pointer;
    }
    option:checked, option:active{
       background-color: #E9F1FF !important;
       color: #002677 !important; 
       border-top: 1px solid #0C55B8;
       border-bottom: 1px solid #0C55B8;
       border-left:none !important;
       border-right:none !important;
       font-weight:bold;
    }

    select:-internal-list-box option:checked{
       background-color: #E9F1FF !important;
       color: #002677 !important; 
       border-top: 1px solid #0C55B8;
       border-bottom: 1px solid #0C55B8;
       border-left:none !important;
       border-right:none !important;
       font-weight:bold;
    }
    select#claimfilter_loblist_select option:checked{
       background-color: #E9F1FF !important;
       color: #002677 !important; 
       border-top: 1px solid #0C55B8;
       border-bottom: 1px solid #0C55B8;
       border-left:none !important;
       border-right:none  !important;
       font-weight:bold;
    }  
`;


export const FilterColumn3 = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

   
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    button label{
        text-transform:uppercase;
    }

    #claimfilter-savedfilter-tab-container span[class^='TabsWrapper__'] button {
    flex: 1 1;
    }
    button label {
        letter-spacing: 0.07em;
    }
    #claimfilter-savedfilter-tab-container span[class^='TabsWrapper__'] button label {
        max-width: unset;
        letter-spacing: 0.07em;
    }
`;
// 
export const ClaimFilterContent = styled.div`

fieldset > div > select {
    width: 100% !important;
    
}
fieldset {
    margin: 0px !important;
}
    div[id*="claimfilter-eligibility-container"] {
        
        }
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

    min-height: 500px;

    min-width: 1080px;
    max-width: 1354px;
    width: 1354px;
    padding: 0px;
  #claimfilter-quickstart-container_column1 label,
  .dropdown label,
  #field_fieldset_adv label,
  #field_fieldset_pay label,
  #field_fieldset_elig label{
    text-transform:uppercase;
  }
  #claimfilter-tab-container {
    button label {
        letter-spacing: 0.07em;
    }
    box-shadow:rgb(155, 161, 169) 0px -2px 0px 0px inset;
  }
    .claim_filter_userfield_radio {   
        margin-left:10px; 
        margin-top: 10px;
        height: 18px;
        width: 18px;
        
    }

    * div > .arm_checkbox_label {
        padding-left: 4px;
    }
  
    #claimfilter_errorcatagory_select{
        overflow-x: hidden;
    }

    #claimfilter-lastclaimlist_wrapper,
    #claimfilter-lastfilter_wrapper,
    #claimfilter-quickfilter_wrapper,
    #claimfilter-savedfilter_wrapper{
        width: 350px;
        float:left;
        margin-left: 10px;
        margin-bottom: 3px;
    }

    #claimfilter-claimstatus,
    #claimfilter-lastfilter,
    #claimfilter-savedfilter,
    #claimfilter-quickfilter,
    #claimfilter-lastclaimlist,
    #claimfilter-claimstate_qs_unr,
    #claimfilter-claimstate_qs_rel,
    #claimfilter-claimstate_adv_unr,
    #claimfilter-claimstate_pay_unr,
    #claimfilter-claimstate_elig_unr,
    #claimfilter-claimstate_adv_rel,
    #claimfilter-claimstate_pay_rel,
    #claimfilter-claimstate_elig_rel,
    #claimfilter-claimstate_adv_so,
    #claimfilter-claimstate_pay_so,
    #claimfilter-claimstate_elig_so,
    #claimfilter-claimstate_adv_unr_cb,
    #claimfilter-claimstate_pay_unr_cb,
    #claimfilter-claimstate_elig_unr_cb,
    #claimfilter-claimstate_adv_rel_cb,
    #claimfilter-claimstate_pay_rel_cb,
    #claimfilter-claimstate_elig_rel_cb,
    #claimfilter-claimstate_adv_del_cb,
    #claimfilter-claimstate_pay_del_cb,
    #claimfilter-claimstate_elig_del_cb,
    #claimfilter-claimstate_adv_so_cb,
    #claimfilter-claimstate_pay_so_cb,
    #claimfilter-claimstate_elig_so_cb,
    #claimfilter-claimstate,
    #claimfilter-claimstate_pay,
    #claimfilter-posted_pay,
    #claimfilter-effective_pay,
    #claimfilter-payertype{
        margin-left:1px; 
        height:15px;
        width:15px;
    }
    #claimfilter-quickfilter_wrapper{
        margin-bottom: 5px;
    }
    .col1_userfieldinput,
    .quickstart_col1_userfieldinput{
        width: 260px;
        flex: 1 1 auto;
    }
    .col1_input_full,
    .quickstart_col1_input_full{
        width: 500px;
    }
    .col1_userdropdown,
    .col1_input,
    .quickstart_col1_input{
        width: 260px;
        flex: 1 1 auto;
    }
    .select-component select,
    .col1_userdropdown .selectDropdown__control,
    .col1_dropdown .selectDropdown__control,
    .dropdown .selectDropdown__control,
    .quickstart_col1_dropdown .selectDropdown__control,
    .col1_input input,
    .col1_userfieldinput input,
    .quickstart_col1_input input {
        box-shadow: inset 0 2px 2px 0 rgba(155, 161, 169, 0.25);
    }
    .selectDropdown__value-container,
    .selectDropdown__indicators{
        height: 0px !important;
    }
    .col1_userdropdown .selectDropdown__control,
    .col1_dropdown .selectDropdown__control,
    .dropdown .selectDropdown__control,
    .quickstart_col1_dropdown .selectDropdown__control,
    .col1_input input,
    .col1_userfieldinput input,
    .quickstart_col1_input input{
        max-height: 32px;
    }
    .col1_sep{
        margin-left: 10px;
    }
    .col1_dropdown,
    .quickstart_col1_dropdown {
        width: 180px;
        flex: 1 1 auto;
     }
    .claim1_dropdown,
    .claim2_dropdown2 {
        width: 255px;
       margin-right:10px;
    }
    .col2_sep{
        margin-left: 0px;
    }
    .col3_sep {
        margin-left: 10px;
        padding-top:20px;
    }

    #claimfilter_payer_select {
        margin-top: 10px;
        min-width: 552.6px;
        height: 150px;
    }

    #claimfilter-sftab-adv,
    #claimfilter-sftab-ps,
    #claimfilter-sftab-elig,
    #claimfilter-tab-qs,
    #claimfilter-tab-adv,
    #claimfilter-tab-ps,
    #claimfilter-tab-elig
    {
       padding-top: 1px;
       padding-bottom: 1px;
    }

    #claimfilter-sftab-adv > label,
    #claimfilter-sftab-ps > label,
    #claimfilter-sftab-elig > label,
    #claimfilter-tab-qs > label,
    #claimfilter-tab-adv > label,
    #claimfilter-tab-ps > label,
    #claimfilter-tab-elig > label
     {
         margin-bottom: 0px;
         ${Typography.small};
         
     }

     #claimfilter-sftab-adv{
         
     } 
     
`;

const StyledCheckbox = styled(Checkbox)`
    label{
        width: 70px !important;
    }
`
const STATE_REPORTING_STATE_ID: string = "43";
const DATE_RELEASED_ID: string = "9";


const STATUS_AP_PENDING_ID: string = "60";
const SINCE_RANGE_FROM_ID: number = 30;
const SINCE_RANGE_TO_ID: number = 35;

export class ClaimFilter extends React.Component<IClaimFilterProps, IComponentState> {
    static defaultProps: IClaimFilterComponentProps = {
        canEdit: false,   // this is your old Permission
        canView: false,
        canCreate: false,
        ncsIsEnabled: false,

        process: "",
        parameter: {},  //  todo  view usage on spinoff
        apiType: "ClaimFilter",
        title: "Filter",
        type: "Claim",
        executeJS: undefined,
        instruction: "When you turn on an option, all claims that match will be in your working group. Turn on options and enter information until you have created the most precise set of requirements. Click Save to save this filter for reuse. Click Refine to check the group of claims before you continue. ",
        extraInstruction: "Click View Claims to go to the claims without viewing the claim list.",

        helpUrl: "/Support/Help/HELP_FilterQuickStart.htm",
        claimStateRestrictions: ['', '1', '3', '11'],
        claimStatusExclusions: [],  // should be passed as ['R'], for "manual", "submit","statereport"
        testMode: false,

    };
    process_lowercase: string;
    oneDay: Number;
    today: string;
    minDate: string;
    maxDate: string;
    eligibilityActive: boolean | undefined;
    directEntryActive: boolean | undefined;
    saveFilterName: string;
    saveFilterId?: number | undefined;
    checkRua: boolean;
    bDEPDeletedFlag: boolean;
    clientId: string;
    hasFavorites: boolean;
    isDeletedFilter: boolean;
    tabType: string;
    overrideUrl: string;  // helper for refine

    constructor(props: IClaimFilterProps) {
        super(props);
        this.state = DEFAULT_STATE;

        this.clientId = sessionStorage.getItem("Client")?.split(' ')[0] ?? "";
        this.process_lowercase = (this.props.process)?.toLowerCase();
        this.eligibilityActive = undefined;
        this.directEntryActive = undefined;
        this.checkRua = false;

        this.saveFilterName = '';
        this.saveFilterId = undefined;
        this.tabType = '';
        this.overrideUrl = '';

        // initializing dates for later usage
        this.oneDay = 1000 * 60 * 60 * 24;
        var myDate = new Date();
        this.today = this.getDateInMMDDYYYYFormat(myDate);

        if (this.process_lowercase === "reprocesspayerstatusrules") {
            this.maxDate = this.today;
            this.minDate = this.dateAdd(myDate, 'd', -120);
        } else {
            this.maxDate = this.dateAdd(myDate, 'y', 2);
            this.minDate = this.dateAdd(myDate, 'y', -1);
        }

        this.bDEPDeletedFlag = false;  // for the 2229 report
        this.hasFavorites = false;
        this.isDeletedFilter = false;
    }

    public componentDidUpdate(prevProps: any, prevState: any) {
        if (this.props.dataStore.ui.dataRefreshed == true || (this.state.tryForConfigDataAgain && this.isConfigLoaded())) {
            if (this.state.tryForConfigDataAgain) this.setState({ tryForConfigDataAgain: false });
            this.setFilter();
            this.clearDataRefreshed();
        }
        if (this.props.dataStore.ui.checkClaimState == true) {  // account number changed
            this.checkClaimState();
            this.clearDataRefreshed();
        }

        var postAction = this.props.dataStore.ui.postAction;
        if (postAction !== '') {
            this.setPostAction(""); // why is this here
            if (postAction == "SubmitFilter") {
                this.postAction_SubmitFilter();
            } else if (postAction == "RefineFilter") {
                this.postAction_RefineFilter();
            }
        }

        if (this.props.dataStore.ui.focusField !== '')
            this.applyFocus(this.props.dataStore.ui.focusField);

    }


    public componentWillUnmount() {
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public componentDidMount() {
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.goToHomePage();
        }


        this.tabType = sessionStorage.getItem("tabType") ?? ""; // set in refine
        window.sessionStorage.removeItem("tabType");

        this.props.action.configData.getConfigData({ cookies: [{ name: "SDREnabled" }, { name: "Eligibility" }, { name: "DirectEntry" }], config: [{ name: "isClientUser" }, { name: "HCDOMSId" }, { name: "Attm_Unsolicited_Enabled" }] });

        this.onLoad();  // logic from the onload

        this.props.action.api.loadData(URLs.api + '/api/data/GetClaimFilterFormData', this.validationCallback.bind(this), this.errorCallback.bind(this));

    }

    public goBackFromRefineX12837() {
        this.disableOption("deleted", true);
        this.disableStateDataReporting(["senttostate", "notsettostate"], true);
        this.disableBillingOption(["secondaryautomated", "tertiaryautomated", "rebill"], true);
        this.disableReleaseBatch("releasebatch", true);

    }

    public onLoad() {
        var disableQuickStartBtns = false;

        if (this.process_lowercase === "manual" || this.process_lowercase === "submit" || this.process_lowercase === "statereport") {
            this.onSelectClaimStatus('Warning')
            this.onSelectClaimStatus('Accepted')
        }



        if (this.tabType == "Advanced") {
            this.setDialogButtonState("1");
            this.clickOnClaimState(true, false, false);
            if (this.process_lowercase === "viewx12claim") {
                this.goBackFromRefineX12837();
            }
            this.onSubmitForm();

        } else if (this.process_lowercase === "view/edit" || this.process_lowercase === "canceleligibility") {
            this.setDialogButtonState("0");  // this is the default
        } else if (this.process_lowercase === "viewx12claim") {
            this.setDialogButtonState("0");  // this is the default

            this.setActiveTab(1);
            this.setFilterFor837();
            this.disableStateDataReporting(["senttostate", "notsettostate"], true);
            this.disableBillingOption(["secondaryautomated", "tertiaryautomated", "rebill"], true);
            this.disableReleaseBatch("releasebatch", true);

        } else if (this.process_lowercase === "deslstatus") {
            this.setDialogButtonState("2");

        } else if (this.process_lowercase === "reprocessuserprofiles") {
            //this.onSelectClaimState('Unreleased');
            this.props.action.ui.selectClaimState({ api: this.props.dataStore.api.data, uiData: { value: '', text: this.process_lowercase, index: 0 } });

            disableQuickStartBtns = true;

        } else {
            disableQuickStartBtns = true;
        }


        if (!disableQuickStartBtns && this.getConfigValue("SDREnabled") == "1") {
            alert(' todo implement ');
            // basically read the user filter and see if it is a sdr filter, if so, set disableQuickStartBtns to true
        }
        /*
         * function CheckRRTUserFilter()
        {
            if (document.readyState != "complete")
            {
                // try again later since page must have finished loading
                setTimeout("CheckRRTUserFilter()", 200);
                return;
            }
        
            var XmlDoc = new ActiveXObject("Microsoft.XMLDOM");
            XmlDoc.loadXML(xmlCurrentUserFilter.xml);
            if(XmlDoc.selectSingleNode("/Filter/ClaimState[@ID='13' or @ID='15']"))
            {
                //default and disable
                ClickOn(document.AdvancedFilter.Type[2]);
                ClickOff(document.AdvancedFilter.Type[0]);
                ClickOff(document.AdvancedFilter.Type[1]);
                document.AdvancedFilter.Type[0].disabled = true; // note;                 
                document.AdvancedFilter.Type[1].disabled = true;
this.setDialogButtonState("2");
            }
        }
         */

        this.setDataRefreshed();


        if (disableQuickStartBtns) {
            this.disableDialogButtons(["0", "1"]);
        }


    }


    public doesParameterContainReportRequestValue(values: string[]): boolean {
        var result = false;
        if (this.props.parameter?.ReportRequests && this.props.parameter.ReportRequests?.ReportRequest &&
            Array.isArray(this.props.parameter.ReportRequests.ReportRequest)) {
            var findResult = this.props.parameter.ReportRequests.ReportRequest.find((obj: any) => {
                return values.indexOf(obj['@ID']) > -1;
            });
            result = findResult !== undefined;

        }
        return result;
    }

    // old logic in setFilter, some may not be needed
    public setFilter() {
        switch (this.process_lowercase) {
            case "manual": {
                this.disableOptions(["released", "stateonly", "deleted", "rejected", "direct"], true);
                this.clickOnClaimState(true, false, false);
            }
                break;

            case "reedit":
            case "unreleased":
            case "runbridgeroutines": {
                this.clickOnClaimState(!this.isClaimStateChecked(3) ? true : false, false, false);

                this.disableOptions(["released", "deleted"], true); //disable the checkboxes/radio buttons

                if (this.process_lowercase === "unreleased") {
                    if (this.doesParameterContainReportRequestValue(['1322'])) {
                        this.selectFormType("1011");
                        this.disableOptions(["formtype"], true);
                    }
                    else if (this.doesParameterContainReportRequestValue(['1323'])) {
                        this.selectFormType("1001");
                        this.disableOptions(["formtype"], true);
                    }
                }
            }
                break;

            case "reprocessmcarules":
            case "changeclaimstater":
            case "holdcodeprocessing": {
                this.disableOptions(["released", "stateonly", "deleted"], true);
                this.clickOnClaimState(true, false, false);
            } break;


            case "claimstatusrequest":
            case "rebill":
            case "secondary":
            case "export7m":
            case "changeclaimstateu":
            case "reprocesspayerstatusrules": {
                this.disableOptions(["unreleased", "stateonly", "deleted"], true);
                this.clickOnClaimState(false, true, false);

            } break;

            case "export28":
            case "exportms4": {
                this.disableOptions(["stateonly"], true);
            }
                break;

            case "delete": {
                if (this.props.canDeleteUnreleased)
                    this.disableOptions(["unreleased"], false);
                else
                    this.disableOptions(["unreleased"], true);

                if (this.props.canDeleteReleased)
                    this.disableOptions(["released"], false);
                else
                    this.disableOptions(["released"], true);

                if (this.props.canDeleteReleased && !this.props.canDeleteUnreleased) {
                    this.clickOnClaimState(false, true, false);
                }
            }
                break;

            case "released": {
                this.disableOptions(["unreleased", "deleted"], true);
                this.clickOnClaimState(false, !this.isClaimStateChecked(3) ? true : false, false);

                // only for LMSC report
                if (this.doesParameterContainReportRequestValue(['1320'])) {
                    this.selectFormType("1011");
                    this.disableOptions(["formtype"], true);

                }

            }
                break;

            case "reprinteoboutsource": {
                this.disableOptions(["unreleased", "stateonly", "deleted", "outsourced"], true);
                this.clickOnClaimState(false, true, false);

                if (!this.isClientUser()) {
                    this.setTransmitTypeOption("Outsourced", true);
                }

            }
                break;

            case "spinoff": {
                this.disableOptions(["stateonly", "formtype"], true);
                let delim = '\t';
                let value = this.props.parameter.split(delim);
                if (value !== undefined)
                    this.selectFormType(value[0]);
            }
                break;

            case "deleted":
            case "deleted_dep": {

                if (this.doesParameterContainReportRequestValue(['2350', '2351', '2352', '2353', '2354', '2355'])) {
                    this.clickOnClaimState(true, false, true);
                    this.isDeletedFilter = true;
                    this.disableOptions(["released", "stateonly", "deleted"], true);

                } else if (this.doesParameterContainReportRequestValue(['1403'])) {
                    this.clickOnClaimState(false, true, false);

                    this.disableOptions(["unreleased", "deleted"], true);
                } else if (this.doesParameterContainReportRequestValue(['2229'])) {
                    this.bDEPDeletedFlag = true;
                    this.clickOnClaimState(false, false, true);

                    this.setClaimState("Deleted", true);
                    this.disableOptions(["released"], true);
                } else {
                    this.clickOnClaimState(true, false, true);
                    this.disableOptions(["released"], true);
                }

            }
                break;

            case "dep": {

                this.clickOnClaimState(true, false, false);

            }
                break;

            case "direct": {
                this.disableOptions(["stateonly", "displayorder"], true);
                this.onDisplayOrderChange(undefined);

                if (this.doesParameterContainReportRequestValue(['1841', '1842', '1843', '1844', '1106', '1850'])) {
                    this.clickOnClaimState(false, true, false);
                    this.disableOptions(["unreleased", "deleted"], true);
                }
                else {
                    this.disableOptions(["direct", "electronic", "clearinghouseprint", "paper", "released", "deleted"], true);
                    this.setTransmitTypeOption("Direct", true);
                    this.clickOnClaimState(true, false, false);
                }


            }
                break;

            case "changetransmitd": {
                this.disableOptions(["stateonly", "formtype", "claimlob", "direct"], true);
                this.selectFormType("1011");
                this.onSelectLob("1");
            }
                break;


            case "changetransmits": {
                this.disableOptions(["stateonly", "electronic", "clearinghouseprint", "paper", "direct"], true);
                this.setTransmitTypeOption("Direct", true);
            }
                break;

            case "eligibility":
            case "validate": {
                this.disableOptions(["released", "stateonly", "deleted", "electronic", "clearinghouseprint", "paper", "direct"], true);
                this.clickOnClaimState(true, false, false);
                this.setTransmitTypeOption("Direct", true);
            }
                break;

            case "undelete": {
            }
                break;

            case "checkeligibility": {
                this.disableOptions(["released", "stateonly", "deleted"], true);
                this.clickOnClaimState(true, false, false);  // unreleased - released - deleted
                this.setTransmitTypeOption("Direct", false);
            }
                break;

            case "canceleligibility": {
                const disabledList = ["deleted", "direct", "duplicateId", "invalidOrMissingInfo",
                    "patInsMatch", "serviceDate", "provider", "neverRequested", "requestPending", "requestFailed"]

                this.disableStatusOptions(disabledList, true);
                this.clickOnClaimState(true, false, false);
                this.setTransmitTypeOption("Direct", false);
                this.setActiveTab(3);

                this.onSelectEligibilityStatusCategory('RequestPending')

                /*
                todo implement 
                document.AdvancedFilter.RequestPending.checked = true;
                document.AdvancedFilter.RequestPending.disabled = 
                document.AdvancedFilter.DuplicateId.disabled = 
                document.AdvancedFilter.InvalidOrMissingInfo.disabled = 
                document.AdvancedFilter.PatInsMatch.disabled = 
                document.AdvancedFilter.ServiceDate.disabled = 
                document.AdvancedFilter.Provider.disabled = 
                document.AdvancedFilter.NeverRequested.disabled = 
                document.AdvancedFilter.RequestFailed.disabled = true;
                */
            }
                break;

            case "submit": {
                this.disableOptions(["released", "stateonly", "deleted", "electronic", "clearinghouseprint", "paper", "direct", "rejected"], true);
                this.clickOnClaimState(true, false, false);
                this.setTransmitTypeOption("Direct", true);

            }
                break;

            case "status": {
                this.disableOptions(["unreleased", "stateonly", "deleted"], true);
                this.clickOnClaimState(false, true, false);
                if (this.tabType !== "Advanced") {
                    this.setTransmitTypeOption("Direct", true);
                }
            }
                break;

            case "deslstatus": {
                this.setActiveTab(1);
                this.disableOptions(["unreleased", "stateonly", "deleted", "formtype", "claimlob"], true);
                this.disableDirectEntryOptions(["notQueried", "exception", "paid"], true);
                this.clickOnClaimState(false, true, false);
                this.updateUserFieldId({ label: "Reason Code", value: "15" }, 1)
                // Direct Entry: Pended or Denied only. Default: Pended.
                if (
                    !(
                        this.props.dataStore.ui.filter.Filter.AiiStatus &&
                        (this.props.dataStore.ui.filter.Filter.AiiStatus["@AiiPended"] === "3" ||
                            this.props.dataStore.ui.filter.Filter.AiiStatus["@All"] === "true")
                    ) &&
                    !(
                        this.props.dataStore.ui.filter.Filter.AiiStatus &&
                        (this.props.dataStore.ui.filter.Filter.AiiStatus["@AiiDenied"] === "2" ||
                            this.props.dataStore.ui.filter.Filter.AiiStatus["@All"] === "true")
                    )
                ) {
                    this.onSelectAiiStatus("AiiPended");
                }

                // FORM TYPE - UB only
                this.selectFormType("1011");

                // LOB - MEDICARE ONLY
                this.onSelectLob("1");

            }
                break;

            case "eoboutsource":
            case "depoutsource": {
                this.disableOptions(["stateonly"], true);

                if (!this.isClientUser()) {
                    this.disableOptions(["outsourced"], true);
                    this.setTransmitTypeOption("Outsourced", true);
                }
            }
                break;

            case "reprinthcdclaimeob": {
                this.disableOptions(["unreleased", "stateonly", "deleted"], true);
                if (!this.isConfigLoaded()) {
                    this.setState({ tryForConfigDataAgain: true });
                }
                // note the report does not show up in the list if this value is not present, so you couldn't get here and the below if fail
                var hcdomsId = this.getConfigValue("HCDOMSId");
                if (hcdomsId !== "") {
                    this.clickOnClaimState(false, true, false);
                    this.disableOptions(["hcd", "direct", "clearinghouseprint", "electronic", "paper"], true);
                    this.setTransmitTypeOption("HCD", true);
                    this.setTransmitTypeOption("Direct", false);
                    this.setTransmitTypeOption("Clearing", false);
                    this.setTransmitTypeOption("Electronic", false);
                    this.setTransmitTypeOption("Paper", false);
                }
                break;
            }


            case "changeclaimstateor": {
                this.disableOptions(["unreleased", "stateonly", "deleted"], true);
                this.clickOnClaimState(false, true, false);
                if (!this.isClientUser()) {
                    this.disableOptions(["outsourced"], true);
                    this.setTransmitTypeOption("Outsourced", true);
                }

            }
                break;

            case "changeclaimstateuo": {
                this.disableOptions(["released", "stateonly", "deleted"], true);
                this.clickOnClaimState(true, false, false);

                if (!this.isClientUser()) {
                    this.disableOptions(["outsourced"], true);
                    this.setTransmitTypeOption("Outsourced", false);
                }
            }
                break;

            case "changeclaimstatero": {
                this.disableOptions(["unreleased", "stateonly", "deleted"], true);
                this.clickOnClaimState(false, true, false);


                if (!this.isClientUser()) {
                    this.disableOptions(["outsourced"], true);
                    this.setTransmitTypeOption("Outsourced", false);
                }
            }
                break;

            case "statereport": {
                this.disableOptions(["unreleased", "deleted", "rejected"], true);
                this.clickOnClaimState(false, true, false);
                alert('todo implement me');
                /*


        document.AdvancedFilter.UserField2.selectedIndex = GetFieldIndex("State Reporting State", document.AdvancedFilter.UserField2);
        SwitchInput(document.AdvancedFilter.UserField2);
        if (document.AdvancedFilter.UserField.length > 1)
            document.AdvancedFilter.UserField.selectedIndex = document.AdvancedFilter.UserField2.selectedIndex;

        document.AdvancedFilter.UserField.disabled = document.AdvancedFilter.UserField2.disabled = true;
        document.AdvancedFilter.NotSentToState.checked = true;
        document.AdvancedFilter.NotSentToState.disabled = document.AdvancedFilter.SentToState.disabled = true;
                 */
            }
                break;

            case "printcaltc": {

                this.onDisplayOrderChange(undefined, '17');

            }
                break;

        }

        this.setFilterDateHelper();   // document.AdvancedFilter.DateField.fireEvent("onchange");
        this.setFilterUserFieldHelper();
        this.setFilterUserField2Helper();
        this.setFilterUserField3Helper();
        this.setFilterUserField4Helper();

        this.checkClaimState();
        this.setFilterFor837();

    }

    public setFilterFor837() {
        if (this.process_lowercase === "viewx12claim") {
            this.setActiveTab(1);
            /*   
                EnableStateDataReporting(false);
                EnableBillingOption(false);
            
                // Disable Release Batch
                document.AdvancedFilter.ReleaseBatch.disabled = true;
            
                SetButtonState("Save", "disabled");
                SetButtonState("Load", "disabled");
            */
        }
    }

    public isClientUser() {
        let userId = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "isClientUser");
        return (userId && userId.value === 'True');
    }

    public getConfigValue(flag: string): string {
        let configObj = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === flag);
        return (configObj && configObj.value ? configObj.value : "");
    }

    public isConfigLoaded(): boolean {
        return this.props.dataStore.configData.container.config.length > 0 || this.props.dataStore.configData.container.cookies.length > 0;
    }

    public dateAdd(date: any, type: string, amount: number) {
        try {
            var y = date.getFullYear(),
                m = date.getMonth(),
                d = date.getDate();
            if (type === 'y') {
                y += amount;
            };
            if (type === 'm') {
                m += amount;
            };
            if (type === 'd') {
                d += amount;
            };
            var xx = new Date(y, m, d);
            return this.getDateInMMDDYYYYFormat(xx);
        }
        catch (e) {
            console.error('Unable to properly format date, dateAdd');
        }
        return "00/00/0000";
    }

    public getDateInMMDDYYYYFormat(input: any): string {
        try {
            var mm = input.getMonth() + 1;
            var dd = input.getDate();
            return (mm < 10 ? '0' + mm : mm) + '/' + (dd < 10 ? '0' + dd : dd) + '/' + input.getFullYear();
        }
        catch (e) {
            console.error('Unable to properly format date');
        }
        return "00/00/0000";
    }

    public errorCallback(dataStatus: string): boolean {
        var str = 'An error occurred processing call for ' + this.props.title + ".  Error: " + dataStatus
        console.error(str);
        this.onDisplayClaimFilterError(str);

        return true;
    }

    public validationCallback(data: APICF_ListPopulationContainer): boolean {
        let dataCopy: APICF_ListPopulationContainer = data as APICF_ListPopulationContainer;
        if (!dataCopy?.ListPopulation?.FilterList?.Filter.filter)  // does the filter function exist
            return true;

        if (this.process_lowercase === 'runbridgeroutines')
            dataCopy.ListPopulation.FilterList.Filter = dataCopy.ListPopulation.FilterList.Filter.filter(item => item.ClaimState === '1')

        dataCopy.ListPopulation.FilterList.Filter = dataCopy.ListPopulation.FilterList.Filter.filter(
            (filter) => {
                if (filter.ClaimState && filter.ClaimState.length > 0 &&
                    this.props.claimStateRestrictions && this.props.claimStateRestrictions.length > 0) {
                    var found = false;
                    for (var i = 0; !found && i < this.props.claimStateRestrictions.length; i++) {
                        if (this.props.claimStateRestrictions[i] == filter.ClaimState)
                            found = true;
                    }

                    if (!found) {
                        // debug helper console.log('filtering out ' + filter.Name + ' ' + filter.ClaimState);
                        return false;
                    }

                }

                if (filter.ClaimStatus &&
                    filter.ClaimStatus.length > 0 &&
                    this.props.claimStatusExclusions &&
                    this.props.claimStatusExclusions.length > 0) {
                    for (var j = 0; j < this.props.claimStatusExclusions.length; j++) {
                        if (filter.ClaimStatus.includes(this.props.claimStatusExclusions[j])) {
                            // debug helper console.log('filtering out ' + filter.Name + ' ' + filter.ClaimStatus);
                            return false;
                        }
                    }

                }
                //ask Jason about  
                //"select", "*[(not(@ClaimState) or @ClaimState = '3') and @DESLStatus = 'Y']"

                if (this.process_lowercase == "deslstatus") {
                    if (filter.DESLStatus != 'Y')
                        return false;
                }

                return true;
            });


        return true;
    }

    public validationCallback_LoadFilter: ValidationCallback<APICF_FilterWrapper> = (data: any): data is APICF_FilterWrapper => {


        let testObj: APICF_FilterWrapper = data as APICF_FilterWrapper;

        let validShape: boolean = ValidateJSONSchema(testObj, "APICF_FilterWrapper");
        if (validShape) {
            if (testObj.Filter?.FormTypes as any === "") {
                testObj.Filter.FormTypes = { FormType: new Array<APICF_FilterSimpleID>() };
            }

            if (testObj.Filter.FormTypes &&
                !Array.isArray(testObj.Filter.FormTypes.FormType)) {
                let arr = new Array<APICF_FilterSimpleID>();
                arr.push(JSON.parse(JSON.stringify(testObj.Filter.FormTypes.FormType)));
                testObj.Filter.FormTypes.FormType = arr;
            }

            if (testObj.Filter?.ClaimLOBs?.ClaimLOB)
                testObj.Filter.ClaimLOBs.ClaimLOB = enforceArray<APICF_FilterSimpleID>(testObj.Filter.ClaimLOBs.ClaimLOB);

            if (testObj.Filter?.Facilities?.Facility)
                testObj.Filter.Facilities.Facility = enforceArray<APICF_FilterSimpleList>(testObj.Filter.Facilities.Facility);

            if (testObj.Filter?.Payers?.Payer)
                testObj.Filter.Payers.Payer = enforceArray<APICF_FilterSimpleID>(testObj.Filter.Payers.Payer);

            if (testObj.Filter?.EligibilityPayers?.EligibilityPayer)
                testObj.Filter.EligibilityPayers.EligibilityPayer = enforceArray<APICF_FilterSimpleList>(testObj.Filter.EligibilityPayers.EligibilityPayer);

            if (testObj.Filter?.ErrorCategories?.ErrorCategory)
                testObj.Filter.ErrorCategories.ErrorCategory = enforceArray<APICF_FilterSimpleList>(testObj.Filter.ErrorCategories.ErrorCategory);




        }

        return validShape;
    }

    public applyFocus(fld: string) {
        // FYI - can't get Input (ui-core) to handle ref properly (ref.current always null), going at the dom
        var x = document.getElementById(fld);
        if (x) x.focus();
    }
    // NOTE this did not work as expected because subsequent re-renders were losing the focus.  If this is going to be
    // required functionality will need to keep resetting on a lifecycle event (componentdidupdate?)
    public setFocus(fld: string) {
        this.props.action.ui.setFocusField(fld);
        this.applyFocus(fld);
    }

    public setPostAction(data: string) {
        this.props.action.ui.setPostAction(data);
    }

    public isFieldValid(data: string, dataType: string, focusField: string) {
        var eqMsg = isMissingEndQuote(data);
        if (eqMsg) {
            this.onAlert(eqMsg);
            if (focusField) this.setFocus(focusField);
            return false;
        }

        var typeMsg = IsValidType(data, dataType);
        if (typeMsg) {
            this.onAlert(typeMsg);
            if (focusField) this.setFocus(focusField);
            return false;
        }
        return true;
    }

    public validateUserField(data: any, focusFldPrefix: string): boolean {
        var id = this.getData(data, "@ID");
        var val = this.getData(data, "@Value");

        if (id == "" || id == "0") {
            if (val != "") {
                this.onAlert("Please select a field.");
                this.setFocus(focusFldPrefix);

                return false;
            }
        } else {
            if (val == "") {
                this.onAlert("Please enter a value.");

                if (id === STATE_REPORTING_STATE_ID)
                    this.setFocus(focusFldPrefix + 'state_input');
                else
                    this.setFocus(focusFldPrefix + '_input');


                return false;
            }
        }
        return true;
    }

    public validateDateField(data: any, focusFldPrefix: string): boolean {
        var id = this.getData(data, "@ID");
        var fromVal = this.getData(data, "@From");
        var toVal = this.getData(data, "@To");
        var from3Val = this.getData(this.props.dataStore.ui.filter.Filter?.ClaimNote, "@From");  // DateField3
        var to3Val = this.getData(this.props.dataStore.ui.filter.Filter?.ClaimNote, "@To");

        if (id == "" || id == "0") {
            if (fromVal != "" || toVal != ""
                && from3Val == "" && to3Val == "") {
                this.onAlert("Please select a date field.");

                this.setFocus(focusFldPrefix);

                return false;
            }
        } else {
            if (fromVal == "") {
                if (toVal == "") {
                    var acctNmb = this.getData(this.props.dataStore.ui.filter.Filter?.AccountNumber, "@Value");
                    var from4Val = this.getData(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@From");   // DateField4

                    if (acctNmb.length < 5 && from3Val == "" && from4Val == "") {
                        this.onAlert("Please enter a date.");
                        this.setFocus(focusFldPrefix + 'from');
                        return false;
                    }
                } else {
                    this.onDateChange(toVal, true);
                }
            } else {
                if (toVal == "") {
                    this.onDateChange(fromVal, false);

                }
            }
        }
        return true;
    }

    ///////////////////////////////////////////////////////////////////////////////////
    //   date field reference
    //   DateField and DateField2 are DateField @From and @To with domId 'claimfilter_<quickstart|adv|pay|elig>_datefieldfrom', with an @ID field
    //   DateField3 is ClaimNote @From and @to with domId claimfilter_pay_claimnotefrom
    //   DateField4 is StatusCategory @From and @To with domId "claimfilter_pay_statuscategoryfrom"
    //   DateField5 is AppealStatus @From and @To with domId    "claimfilter_pay_appealsfrom"
    //   DateField6 is LastAttchment @From and @To with domId "claimfilter_adv_lastattachmentfrom"
    ///////////////////////////////////////////////////////////////////////////////////

    public validateDateField_Check2(data: any, focusFldPrefix: string): boolean {
        var fromVal = this.getData(data, "@From");
        var toVal = this.getData(data, "@To");

        if (fromVal == "00/00/0000" || fromVal == "0/0/0000" || fromVal == "00/0/0000" || fromVal == "0/00/0000" || (fromVal.length > 0 && fromVal.length < 8)) {
            if (toVal !== "00/00/0000" && toVal !== "0/0/0000" && toVal !== "00/0/0000" && toVal !== "0/00/0000" || (toVal.length > 0 && toVal.length < 8)) {
                this.onAlert("Please check date validity.");
                this.setFocus(focusFldPrefix + 'to');
                return false;
            }
        }

        try {
            var fromDate = new Date(fromVal);
            var toDate = new Date(toVal);

            if (fromDate.valueOf() > 0 && toDate.valueOf() > 0 && fromDate > toDate) {
                this.onAlert("Please check date validity.");

                this.setFocus(focusFldPrefix + 'to');

                return false;
            }
        }
        catch (e) {

        }

        return true;
    }

    public getDateFromControl(dateVal: string, focusFld: string) {
        var formattedDate = new Date(dateVal);
        if (dateVal != "") {
            if (formattedDate.valueOf() != 0) {
                var today = new Date();
                var oneDay = 1000 * 60 * 60 * 24;
                var yearOffset = dateVal.lastIndexOf("/");
                var monthOffset = dateVal.indexOf("/");
                var dayOffset = dateVal.indexOf("/", monthOffset + 1);

                if (yearOffset == -1 || monthOffset == -1 || dayOffset == -1) {
                    this.onAlert("Please enter a valid date.");
                    this.setFocus(focusFld);
                    return undefined;
                }

                // need to build an actual date
                if (dateVal.substr(yearOffset + 1) == "0000") {
                    var month = dateVal.substr(0, monthOffset);
                    var day = dateVal.substr(monthOffset + 1, dayOffset - monthOffset - 1);

                    var timeDiff = Math.abs(today.getTime() - Math.abs((+month) * 30 * oneDay + (+day) * oneDay));

                    // reset the value of FromDate to the actual date
                    formattedDate = new Date(timeDiff);
                }
            }
        }
        return formattedDate;
    }

    public validateDateField_Check3(data: any, focusFldPrefix: string, checkForFutureDates: boolean, maxDateRange: number, maxDateRangeErrMsg: string, deletedCheck: boolean | undefined): boolean {
        var fromVal = this.getData(data, "@From");
        var toVal = this.getData(data, "@To");

        if (deletedCheck == undefined || deletedCheck == false) {
            if (fromVal == "" || toVal == "" || (!checkForFutureDates && maxDateRange < 0))
                return true;
        }

        try {
            var fromDate = this.getDateFromControl(fromVal, focusFldPrefix + 'from');
            var toDate = this.getDateFromControl(toVal, focusFldPrefix + 'to');

            if (fromDate && fromDate.valueOf() > 0 && toDate && toDate.valueOf() > 0) {

                if (maxDateRange >= 0) {
                    var oneDay = 1000 * 60 * 60 * 24;

                    var ActualFromDate = this.getDateFromControl(fromVal, focusFldPrefix + 'from');
                    if (ActualFromDate == undefined) return false;
                    var ActualToDate = this.getDateFromControl(toVal, focusFldPrefix + 'to');
                    if (ActualToDate == undefined) return false;

                    if (isNaN(ActualFromDate.valueOf()) || isNaN(ActualToDate.valueOf()) ||
                        (ActualFromDate.valueOf() > 0 && ActualToDate.valueOf() > 0 && ActualFromDate > ActualToDate)) {
                        this.onAlert("Please check date validity.");

                        this.setFocus(focusFldPrefix + 'to');
                        return false;
                    }

                    var dateDiff = Math.abs(ActualToDate.getTime() - ActualFromDate.getTime());
                    var dayDiff = Math.round(dateDiff / oneDay);

                    if (dayDiff > maxDateRange) {
                        if (deletedCheck != undefined || deletedCheck == true)
                            this.onAlertModeOn(maxDateRangeErrMsg && maxDateRangeErrMsg.length > 0 ? maxDateRangeErrMsg : "Please select a date range of less than a year");
                        else
                            this.onAlert(maxDateRangeErrMsg && maxDateRangeErrMsg.length > 0 ? maxDateRangeErrMsg : "Please select a date range of less than a year");
                        this.setFocus(focusFldPrefix + 'from');
                        return false;
                    }
                }

                if (checkForFutureDates && toDate > new Date()) {
                    this.onAlert("Future dates are not valid.");
                    this.setFocus(focusFldPrefix + 'to');
                    return false;
                }
            } else {
                if (deletedCheck != undefined || deletedCheck == true) {
                    this.onAlertModeOn(maxDateRangeErrMsg);
                    return false;
                } else { // we got here  because from to dates were invalid...
                    this.onAlert("Please check date validity.");
                }
            }
        }
        catch (e) {
            //  does this mean the date is invalid and i need to error it?
        }

        return true;
    }

    public validateFilter(): boolean  // NOTE checkRua is now in this.checkRua - and only checked during building the filter data (getFilterData())
    {
        // implement the bail conditions first
        if (this.process_lowercase === "deslstatus") {

            if (this.getData(this.props.dataStore.ui.filter.Filter.AiiStatus, '@AiiPended') == ''
                && this.getData(this.props.dataStore.ui.filter.Filter.AiiStatus, '@AiiDenied') == '') {
                this.onAlert("Please select the Denied checkbox, the Pended checkbox, or both.");
                return false;
            }
        }

        if (this.props.dataStore.ui.activeTab == 2 || this.props.dataStore.ui.activeTab == 3) {

            if (this.process_lowercase != "deslstatus") {
                this.clearAiiStatus();
            }

            if (this.props.dataStore.ui.activeTab !== 2) {
                if (this.process_lowercase == "canceleligibility") {

                    if (!(this.props.dataStore.ui.filter.Filter.EligibilityCategory &&
                        (this.props.dataStore.ui.filter.Filter.EligibilityCategory["@RequestPending"] === 'G' ||
                            this.props.dataStore.ui.filter.Filter.EligibilityCategory["@All"] === 'true'))) {
                        this.onSelectEligibilityStatusCategory('RequestPending')
                    }

                }
            }
        }

        if (this.process_lowercase == "manual" || this.process_lowercase == "submit" || this.process_lowercase == "statereport") {
            if (!this.isAcceptedChecked() && !this.isWarningChecked()) {
                this.onSelectClaimStatus('Warning')
                this.onSelectClaimStatus('Accepted')
            }
        }

        if (this.process_lowercase == "manual" || this.process_lowercase == "changetransmitd") {
            var el = this.getData(this.props.dataStore.ui.filter.Filter.TransmitType, "@Electronic");
            var pa = this.getData(this.props.dataStore.ui.filter.Filter.TransmitType, "@Paper");
            var ch = this.getData(this.props.dataStore.ui.filter.Filter.TransmitType, "@ClearinghousePrint");

            if (el == "" && pa == "" && ch == "") {
                this.onSelectTransmitType('Electronic');
                this.onSelectTransmitType('Paper');
                this.onSelectTransmitType('Clearing');
            }
        }

        if (this.process_lowercase == "eoboutsource" || this.process_lowercase == "depoutsource") {
            this.setTransmitTypeOption("Outsourced", true);
        }

        switch (this.props.dataStore.ui.activeTab) {
            // QuickStart
            case 0: {
                switch (this.props.dataStore.ui.quickStart) {  // == "3" 
                    case "0": // LastClaimIndex
                        {
                            // this logic moved to getFilterData()
                            break;
                        }

                    case "1": // LastFilterIndex
                        {
                            // this logic moved to getFilterData() 
                            break;
                        }

                    case "2":
                        {
                            if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.PatientName, "@Value"), "alpha", 'claimfilter_quickstart_patientname_input'))
                                return false;
                            if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.AccountNumber, "@Value"), "alphanumeric", 'claimfilter_quickstart_accountnumber_input'))
                                return false;
                            if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.UserField, "@Value"), "alphanumeric", 'claimfilter_quickstart_userfield_input'))
                                return false;
                            if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.FinancialClass, "@Value"), "alphanumeric", 'claimfilter_quickstart_fclass_input'))
                                return false;
                            if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.Amount, "@Value"), "numeric", 'claimfilter_quickstart_totalcharges_input'))
                                return false;
                            if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.TypeOfBill, "@Value"), "alphanumeric", 'claimfilter_quickstart_tob_input'))
                                return false;

                            var isNotUnreleased = !this.isUnreleasedChecked();

                            var fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                            fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.PatientName, "@Value"), "alpha", isNotUnreleased, /[^\w '\-@,":]/g);
                            if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                                this.updatePatientName(fieldRegex);
                            }

                            fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                            fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.AccountNumber, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                            if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                                this.updateAccountNumber(fieldRegex);
                            }

                            fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                            fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.UserField, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                            if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                                this.updateUserField(fieldRegex, 1);
                            }

                            fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                            fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.FinancialClass, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                            if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                                this.updateFinancialClass(fieldRegex);
                            }

                            fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                            fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.Amount, "@Value"), "numeric", isNotUnreleased, /[^\d\.\-,:]/g);
                            if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                                this.updateAmount(fieldRegex);
                            }

                            fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                            fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.TypeOfBill, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                            if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                                this.updateTypeOfBill(fieldRegex);
                            }

                            if (this.isReleasedChecked()) {
                                if ((this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "" || this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "0") &&
                                    (this.getData(this.props.dataStore.ui.filter.Filter?.ReleaseBatch, "@ID") == "" || this.getData(this.props.dataStore.ui.filter.Filter?.ReleaseBatch, "@ID") == "0") &&
                                    this.getData(this.props.dataStore.ui.filter.Filter?.AccountNumber, "@Value").length < 5 &&
                                    this.getData(this.props.dataStore.ui.filter.Filter?.ClaimNote, "@From") === "" &&
                                    this.getData(this.props.dataStore.ui.filter.Filter?.ClaimNote, "@To") === "" &&
                                    this.getData(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@From") === "" &&
                                    this.getData(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@To") === "") {
                                    if (this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@From") == "")
                                        this.onDateChange("00/00/0000", true);
                                    if (this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@To") == "")
                                        this.onDateChange("00/00/0000", false);

                                    this.onAlert("Please select a date field.");

                                    this.setFocus('claimfilter_pay_datefield');
                                    return false;
                                }

                                // For AddNote, requirement is: for released claims only, date range is limited to 7 days.
                                if (this.process_lowercase === "addnote") {
                                    if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.DateField,
                                        'claimfilter_quickstart_datefield', true, 7, "Please limit date range to 7 days", false)) {
                                        return false;
                                    }
                                }

                                if (this.process_lowercase == "deslstatus") {

                                    if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.DateField,
                                        'claimfilter_quickstart_datefield', true, 7, "Please limit date range to 7 days", false)) {
                                        return false;
                                    }

                                }
                            } else {
                                if (this.isDeletedFilter && this.process_lowercase === "deleted") {
                                    if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.DateField,
                                        'claimfilter_quickstart_datefield', true, 7, "Please limit the date range to 7 days for deleted claims.", true)) {
                                        return false;
                                    }
                                }
                            }

                            if (!this.validateUserField(this.props.dataStore.ui.filter.Filter?.UserField, 'claimfilter_quickstart_userfield')) {
                                return false;
                            }

                            if (!this.validateDateField(this.props.dataStore.ui.filter.Filter?.DateField, 'claimfilter_quickstart_datefield')) {
                                return false;
                            }

                            if (!this.validateDateField_Check2(this.props.dataStore.ui.filter.Filter?.DateField, 'claimfilter_quickstart_datefield')) {
                                return false;
                            }


                            if (this.process_lowercase === "reprocesspayerstatusrules") {

                                if (this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "" || this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "0") {
                                    this.onAlert("Please select a date field.");

                                    this.setFocus('claimfilter_quickstart_datefield');
                                    return false;
                                }

                                if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.DateField,
                                    'claimfilter_quickstart_datefield',
                                    true, 120, "Please select a date within the last 120 days", false)) {
                                    return false;
                                }


                            }

                            if (this.process_lowercase === "reprocessuserprofiles") {

                                if (this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "" || this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "0") {
                                    this.onAlert("Please select a date field.");

                                    this.setFocus('claimfilter_quickstart_datefield');
                                    return false;
                                }

                                if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.DateField,
                                    'claimfilter_quickstart_datefield',
                                    true, 120, "Please adjust your date range to not exceed 120 days.", false)) {
                                    return false;
                                }

                            }

                            break;
                        }
                }
            }
                break; // exiting quickstart

            // payer status
            case 2: {

                var dateFrom4 = this.getData(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@From");
                var dateTo4 = this.getData(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@To");
                if (dateFrom4 == "" && dateTo4 !== "")
                    this.onStatusCategoryDateChange(dateTo4, true);
                if (dateFrom4 !== "" && dateTo4 == "")
                    this.onStatusCategoryDateChange(dateFrom4, false);

                if (!this.validateDateField_Check2(this.props.dataStore.ui.filter.Filter?.StatusCategory, 'claimfilter_pay_statuscategory')) {
                    return false;
                }

                if (this.getData(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@From") === "" &&
                    this.getData(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@To") === "") {
                    this.onStatusCategoryTypeChange('');
                }

                if (this.getData(this.props.dataStore.ui.filter.Filter?.AppealStatus, "@Created") !== "" ||
                    this.getData(this.props.dataStore.ui.filter.Filter?.AppealStatus, "@Filed") !== "" ||
                    this.getData(this.props.dataStore.ui.filter.Filter?.AppealStatus, "@Succeeded") !== "" ||
                    this.getData(this.props.dataStore.ui.filter.Filter?.AppealStatus, "@Denied") !== "") {
                    var frmDate5 = this.getData(this.props.dataStore.ui.filter.Filter?.AppealStatus, "@From");
                    var toDate5 = this.getData(this.props.dataStore.ui.filter.Filter?.AppealStatus, "@To");
                    if (toDate5 !== "" && frmDate5 === "") {
                        this.setFocus('claimfilter_pay_appealsfrom');
                        return false;
                    } else if (toDate5 === "" && frmDate5 !== "") {
                        this.setFocus('claimfilter_pay_appealsto');
                        return false;
                    }
                }

                if (!this.validateDateField_Check2(this.props.dataStore.ui.filter.Filter?.AppealStatus, 'claimfilter_pay_appeals')) {
                    return false;
                }

                if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.AppealStatus, 'claimfilter_pay_appeals', true, 365, "Please select a date range of less than a year", false)) {
                    return false;
                }

                if (this.process_lowercase === "addnote") {
                    var dateFrom5 = this.getData(this.props.dataStore.ui.filter.Filter?.AppealStatus, "@From");
                    var dateTo5 = this.getData(this.props.dataStore.ui.filter.Filter?.AppealStatus, "@To");
                    if (dateFrom5 == "" && dateTo5 !== "")
                        this.onStatusCategoryDateChange(dateTo5, true);
                    if (dateFrom5 !== "" && dateTo5 == "")
                        this.onStatusCategoryDateChange(dateFrom5, false);
                }

                if (this.process_lowercase === "reprocesspayerstatusrules") {

                    if ((this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "" || this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "0") &&
                        this.getData(this.props.dataStore.ui.filter.Filter?.ClaimNote, "@From") === "" && this.getData(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@From") === "") {
                        this.onAlert("Please select a date field.");

                        this.setFocus('claimfilter_pay_datefield');
                        return false;
                    }

                    if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.ClaimNote,
                        'claimfilter_pay_claimnote',
                        true, 120, "Please select a date within the last 120 days", false)) {
                        return false;
                    }

                    if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.StatusCategory,
                        'claimfilter_pay_statuscategory',
                        true, 120, "Please select a date within the last 120 days", false)) {
                        return false;
                    }
                }

                if (this.process_lowercase === "reprocessuserprofiles") {

                    if ((this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "" || this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "0") &&
                        this.getData(this.props.dataStore.ui.filter.Filter?.ClaimNote, "@From") === "" && this.getData(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@From") === "") {
                        this.onAlert("Please select a date field.");

                        this.setFocus('claimfilter_pay_datefield');
                        return false;
                    }

                    if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.ClaimNote,
                        'claimfilter_pay_claimnote',
                        true, 120, "Please adjust your date range to not exceed 120 days.", false)) {
                        return false;
                    }

                    if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.StatusCategory,
                        'claimfilter_pay_statuscategory',
                        true, 120, "Please adjust your date range to not exceed 120 days.", false)) {
                        return false;
                    }
                }

                if (!this.isReleasedChecked()) {
                    if (this.isDeletedFilter && this.process_lowercase === "deleted") {
                        if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.DateField,
                            'claimfilter_pay_datefield', true, 7, "Please limit the date range to 7 days for deleted claims.", false)) {
                            return false;
                        }
                    }
                } // debuke says: needed here for my report?
            }
            // no break statement

            // advanced
            case 1: {
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.PatientName, "@Value"), "alpha",
                    this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_patientname_input' : 'claimfilter_adv_patientname_input'))
                    return false;
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.AccountNumber, "@Value"), "alphanumeric",
                    this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_accountnumber_input' : 'claimfilter_adv_accountnumber_input'))
                    return false;
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.UserField, "@Value"), "alphanumeric",
                    this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_userfield_input' : 'claimfilter_adv_userfield_input'))
                    return false;
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.UserField2, "@Value"), "alphanumeric",
                    this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_userfieldsec_input' : 'claimfilter_adv_userfieldsec_input'))
                    return false;
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.UserField3, "@Value"), "alphanumeric",
                    this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_userfieldsec2_input' : 'claimfilter_adv_userfieldsec2_input'))
                    return false;
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.UserField4, "@Value"), "alphanumeric",
                    this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_userfieldsec3_input' : 'claimfilter_adv_userfieldsec3_input'))
                    return false;
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.FinancialClass, "@Value"), "alphanumeric",
                    this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_fclass_input' : 'claimfilter_adv_fclass_input'))
                    return false;
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.Amount, "@Value"), "numeric",
                    this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_totalcharges_input' : 'claimfilter_adv_totalcharges_input'))
                    return false;
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.TypeOfBill, "@Value"), "alphanumeric",
                    this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_tob_input' : 'claimfilter_adv_tob_input'))
                    return false;
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.PlanCode, "@Value"), "alphanumeric",
                    this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_plancode_input' : 'claimfilter_adv_plancode_input'))
                    return false;

                var isNotUnreleased = !this.isUnreleasedChecked();

                var fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.PatientName, "@Value"), "alpha", isNotUnreleased, /[^\w '\-@,":]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updatePatientName(fieldRegex);
                }

                fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.AccountNumber, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updateAccountNumber(fieldRegex);
                }

                fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.UserField, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updateUserField(fieldRegex, 1);
                }
                fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.UserField2, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updateUserField(fieldRegex, 2);
                }
                fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.UserField3, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updateUserField(fieldRegex, 3);
                }
                fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.UserField4, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updateUserField(fieldRegex, 4);
                }

                fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.FinancialClass, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updateFinancialClass(fieldRegex);
                }

                fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.Amount, "@Value"), "numeric", isNotUnreleased, /[^\d\.\-,:]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updateAmount(fieldRegex);
                }

                fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.TypeOfBill, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updateTypeOfBill(fieldRegex);
                }

                fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.PlanCode, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updatePlanCode(fieldRegex);
                }

                if (!this.validateUserField(this.props.dataStore.ui.filter.Filter?.UserField,
                    this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_userfield' : 'claimfilter_adv_userfield')) {
                    return false;
                }

                if (!this.validateUserField(this.props.dataStore.ui.filter.Filter?.UserField2,
                    this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_userfieldsec' : 'claimfilter_adv_userfieldsec')) {
                    return false;
                }

                if (!this.validateUserField(this.props.dataStore.ui.filter.Filter?.UserField3,
                    this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_userfieldsec2' : 'claimfilter_adv_userfieldsec2')) {
                    return false;
                }

                if (!this.validateUserField(this.props.dataStore.ui.filter.Filter?.UserField4,
                    this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_userfieldsec3' : 'claimfilter_adv_userfieldsec3')) {
                    return false;
                }

                if (!this.validateDateField(this.props.dataStore.ui.filter.Filter?.DateField,
                    this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_datefield' : 'claimfilter_adv_datefield')) {
                    return false;
                }

                if (!this.validateDateField_Check2(this.props.dataStore.ui.filter.Filter?.DateField,
                    this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_datefield' : 'claimfilter_adv_datefield')) {
                    return false;
                }

                if (!this.validateDateField_Check2(this.props.dataStore.ui.filter.Filter?.StatusCategory,
                    this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_statuscategory' : 'claimfilter_adv_statuscategory')) {
                    return false;
                }

                if (!this.validateDateField_Check2(this.props.dataStore.ui.filter.Filter?.ClaimNote,
                    this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_claimnote' : 'claimfilter_adv_claimnote')) {
                    return false;
                }

                var dateFrom3 = this.getData(this.props.dataStore.ui.filter.Filter?.ClaimNote, "@From");
                var dateTo3 = this.getData(this.props.dataStore.ui.filter.Filter?.ClaimNote, "@To");
                if (dateFrom3 == "" && dateTo3 !== "")
                    this.onClaimNoteDateChange(dateTo3, true);
                if (dateFrom3 !== "" && dateTo3 == "")
                    this.onClaimNoteDateChange(dateFrom3, false);

                if (this.process_lowercase == "addnote") {
                    var dateFrom6 = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@From");
                    var dateTo6 = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@To");
                    if (dateFrom6 == "" && dateTo6 !== "")
                        this.onLastAttachmentDateChange(dateTo6, true);
                    if (dateFrom6 !== "" && dateTo6 == "")
                        this.onLastAttachmentDateChange(dateFrom6, false);
                }

                if (this.isReleasedChecked()) {
                    if ((this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "" || this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "0") &&
                        (this.getData(this.props.dataStore.ui.filter.Filter?.ReleaseBatch, "@ID") == "" || this.getData(this.props.dataStore.ui.filter.Filter?.ReleaseBatch, "@ID") == "0") &&
                        this.getData(this.props.dataStore.ui.filter.Filter?.AccountNumber, "@Value").length < 5 &&
                        this.getData(this.props.dataStore.ui.filter.Filter?.ClaimNote, "@From") === "" &&
                        this.getData(this.props.dataStore.ui.filter.Filter?.ClaimNote, "@To") === "") {
                        var bContinue = false;
                        if (this.process_lowercase === "addnote") {
                            if ((this.props.dataStore.ui.activeTab === 1 &&
                                this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@From") === "" &&
                                this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@To") === ""
                            ) || (this.props.dataStore.ui.activeTab === 2 &&
                                this.getData(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@From") === "" &&
                                this.getData(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@To") === "" &&
                                this.getData(this.props.dataStore.ui.filter.Filter?.AppealStatus, "@From") === "" &&
                                this.getData(this.props.dataStore.ui.filter.Filter?.AppealStatus, "@To") === "")
                            )
                                bContinue = true;
                        } else if (this.getData(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@From") === "" &&
                            this.getData(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@To") === "")
                            bContinue = true;

                        if (bContinue) {
                            if (this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@From") == "")
                                this.onDateChange("00/00/0000", true);
                            if (this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@To") == "")
                                this.onDateChange("00/00/0000", false);

                            this.onAlert("Please select a date field.");

                            this.setFocus('claimfilter_pay_datefield');
                            return false;
                        }
                    }

                    if (this.process_lowercase == "addnote") {
                        if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.DateField,
                            'claimfilter_adv_datefield', true, 7, "Please limit date range to 7 days", false)) {
                            return false;
                        }
                        if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.ClaimNote,
                            'claimfilter_pay_claimnote', true, 7, "Please limit date range to 7 days", false)) {  // should this be claimfilter_adv_claimnote?
                            return false;
                        }
                        if (this.props.dataStore.ui.activeTab == 2 &&
                            !this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.StatusCategory,
                                'claimfilter_pay_statuscategoryfrom', true, 7, "Please limit date range to 7 days", false)) {
                            return false;
                        }
                        if (this.props.dataStore.ui.activeTab == 2 &&
                            !this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.AppealStatus,
                                'claimfilter_pay_appealsfrom', true, 7, "Please limit date range to 7 days", false)) {
                            return false;
                        }
                        if (this.props.dataStore.ui.activeTab == 1 &&
                            !this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.LastAttachment,
                                'claimfilter_adv_lastattachmentfrom', true, 7, "Please limit date range to 7 days", false)) {
                            return false;
                        }
                    }

                    if (this.process_lowercase == "deslstatus") {

                        if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.DateField,
                            this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_datefield' : 'claimfilter_adv_datefield',
                            true, 7, "Please limit date range to 7 days", false)) {
                            return false;
                        }
                    }
                } else {
                    if (this.isDeletedFilter && this.process_lowercase === "deleted") {
                        if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.DateField,
                            'claimfilter_adv_datefield', true, 7, "Please limit the date range to 7 days for deleted claims.", true)) {
                            return false;
                        }
                    }
                }

                if (this.process_lowercase == "reprocesspayerstatusrules") {

                    if ((this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "" || this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "0") &&
                        this.getData(this.props.dataStore.ui.filter.Filter?.ClaimNote, "@From") === "" && this.getData(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@From") === "") {
                        this.onAlert("Please select a date field.");

                        this.setFocus(this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_datefield' : 'claimfilter_adv_datefield');
                        return false;
                    }

                    if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.DateField,
                        this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_datefield' : 'claimfilter_adv_datefield',
                        true, 120, "Please select a date within the last 120 days", false)) {
                        return false;
                    }

                    if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.ClaimNote,
                        this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_claimnote' : 'claimfilter_adv_claimnote',
                        true, 120, "Please select a date within the last 120 days", false)) {
                        return false;
                    }

                    if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.StatusCategory,
                        this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_statuscategory' : 'claimfilter_adv_statuscategory',
                        true, 120, "Please select a date within the last 120 days", false)) {
                        return false;
                    }

                }

                if (this.process_lowercase == "reprocessuserprofiles") {

                    if ((this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "" || this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "0") &&
                        this.getData(this.props.dataStore.ui.filter.Filter?.ClaimNote, "@From") === "" && this.getData(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@From") === "") {
                        this.onAlert("Please select a date field.");

                        this.setFocus(this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_datefield' : 'claimfilter_adv_datefield');
                        return false;
                    }

                    if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.DateField,
                        this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_datefield' : 'claimfilter_adv_datefield',
                        true, 120, "Please adjust your date range to not exceed 120 days.", false)) {
                        return false;
                    }

                    if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.ClaimNote,
                        this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_claimnote' : 'claimfilter_adv_claimnote',
                        true, 120, "Please adjust your date range to not exceed 120 days.", false)) {
                        return false;
                    }

                    if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.StatusCategory,
                        this.props.dataStore.ui.activeTab == 2 ? 'claimfilter_pay_statuscategory' : 'claimfilter_adv_statuscategory',
                        true, 120, "Please adjust your date range to not exceed 120 days.", false)) {
                        return false;
                    }

                }
            }
                break; // exiting advanced

            // eligibility
            case 3: {
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.PatientName, "@Value"), "alpha", 'claimfilter_elig_patientname_input'))
                    return false;
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.AccountNumber, "@Value"), "alphanumeric", 'claimfilter_elig_accountnumber_input'))
                    return false;
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.UserField, "@Value"), "alphanumeric", 'claimfilter_elig_userfield_input'))
                    return false;
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.UserField2, "@Value"), "alphanumeric", 'claimfilter_elig_userfieldsec_input'))
                    return false;
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.UserField3, "@Value"), "alphanumeric", 'claimfilter_elig_userfieldsec2_input'))
                    return false;
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.UserField4, "@Value"), "alphanumeric", 'claimfilter_elig_userfieldsec3_input'))
                    return false;
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.FinancialClass, "@Value"), "alphanumeric", 'claimfilter_elig_fclass_input'))
                    return false;
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.Amount, "@Value"), "numeric", 'claimfilter_elig_totalcharges_input'))
                    return false;
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.TypeOfBill, "@Value"), "alphanumeric", 'claimfilter_elig_tob_input'))
                    return false;
                if (!this.isFieldValid(this.getData(this.props.dataStore.ui.filter.Filter?.PlanCode, "@Value"), "alphanumeric", 'claimfilter_elig_plancode_input'))
                    return false;

                var isNotUnreleased = !this.isUnreleasedChecked();

                var fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.PatientName, "@Value"), "alpha", isNotUnreleased, /[^\w '\-@,":]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updatePatientName(fieldRegex);
                }

                fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.AccountNumber, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updateAccountNumber(fieldRegex);
                }

                fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.UserField, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updateUserField(fieldRegex, 1);
                }
                fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.UserField2, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updateUserField(fieldRegex, 2);
                }
                fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.UserField3, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updateUserField(fieldRegex, 3);
                }
                fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.UserField4, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updateUserField(fieldRegex, 4);
                }

                fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.FinancialClass, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updateFinancialClass(fieldRegex);
                }

                fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.Amount, "@Value"), "numeric", isNotUnreleased, /[^\d\.\-,:]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updateAmount(fieldRegex);
                }

                fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.TypeOfBill, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updateTypeOfBill(fieldRegex);
                }

                fieldRegex = "DO_NOT_UPDATE_3";  // old code IsValidType also replaced the value after a regex, doing that here
                fieldRegex = this.applyRegex(this.getData(this.props.dataStore.ui.filter.Filter?.PlanCode, "@Value"), "alphanumeric", isNotUnreleased, /[^\w '\.\-/@,":]/g);
                if (fieldRegex !== "DO_NOT_UPDATE_3") { // data changed
                    this.updatePlanCode(fieldRegex);
                }

                if (!this.validateUserField(this.props.dataStore.ui.filter.Filter?.UserField, 'claimfilter_elig_userfield')) {
                    return false;
                }
                if (!this.validateUserField(this.props.dataStore.ui.filter.Filter?.UserField2, 'claimfilter_elig_userfieldsec')) {
                    return false;
                }
                if (!this.validateUserField(this.props.dataStore.ui.filter.Filter?.UserField3, 'claimfilter_elig_userfieldsec2')) {
                    return false;
                }
                if (!this.validateUserField(this.props.dataStore.ui.filter.Filter?.UserField4, 'claimfilter_elig_userfieldsec3')) {
                    return false;
                }

                if (!this.validateDateField(this.props.dataStore.ui.filter.Filter?.DateField, 'claimfilter_elig_datefield')) {
                    return false;
                }

                if (!this.validateDateField_Check2(this.props.dataStore.ui.filter.Filter?.DateField, 'claimfilter_elig_datefield')) {
                    return false;
                }

                var dateFrom3 = this.getData(this.props.dataStore.ui.filter.Filter?.ClaimNote, "@From");
                var dateTo3 = this.getData(this.props.dataStore.ui.filter.Filter?.ClaimNote, "@To");
                if (dateFrom3 == "" && dateTo3 !== "")
                    this.onClaimNoteDateChange(dateTo3, true);
                if (dateFrom3 !== "" && dateTo3 == "")
                    this.onClaimNoteDateChange(dateFrom3, false);

                if (this.isReleasedChecked()) {

                    if ((this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "" || this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "0") &&
                        (this.getData(this.props.dataStore.ui.filter.Filter?.ReleaseBatch, "@ID") == "" || this.getData(this.props.dataStore.ui.filter.Filter?.ReleaseBatch, "@ID") == "0") &&
                        this.getData(this.props.dataStore.ui.filter.Filter?.AccountNumber, "@Value").length < 5 &&
                        (this.process_lowercase !== "addnote" ||
                            (this.process_lowercase === "addnote" && this.getData(this.props.dataStore.ui.filter.Filter?.ClaimNote, "@From") === ""))) {
                        if (this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@From") == "")
                            this.onDateChange("01/00/0000", true);
                        if (this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@To") == "")
                            this.onDateChange("00/00/0000", false);

                        this.onAlert("Please select a date field.");

                        this.setFocus('claimfilter_elig_datefield');
                        return false;
                    }
                }

                if (this.process_lowercase == "reprocessuserprofiles" || this.process_lowercase == "reprocesspayerstatusrules") {

                    if ((this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "" || this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@ID") == "0") &&
                        this.getData(this.props.dataStore.ui.filter.Filter?.ClaimNote, "@From") === "") {
                        this.onAlert("Please select a date field.");

                        this.setFocus('claimfilter_elig_datefield');
                        return false;
                    }
                }

                if (this.process_lowercase == "deslstatus") {

                    if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.DateField,
                        'claimfilter_elig_datefield', true, 7, "Please limit date range to 7 days", false)) {
                        return false;
                    }

                }

                if (this.isReleasedChecked()) {
                    if (this.process_lowercase == "addnote") {
                        if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.DateField,
                            'claimfilter_adv_datefield', true, 7, "Please limit date range to 7 days", false)) {
                            return false;
                        }

                        if (this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@From") === "") {
                            if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.ClaimNote,
                                'claimfilter_pay_claimnote', true, 7, "Please limit date range to 7 days", false)) {  // should this be claimfilter_adv_claimnote? or both?
                                return false;
                            }
                        }
                    } else {
                        var reproc = this.process_lowercase === "reprocessuserprofiles";
                        if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.DateField,
                            'claimfilter_elig_datefield',
                            false,
                            reproc ? 120 : 30,
                            reproc ? "Please adjust your date range to not exceed 120 days."
                                : "Please select a date range less than 31 days", false)) {
                            return false;
                        }

                    }
                } // needed date check here for my report?

                if (!this.validateDateField_Check2(this.props.dataStore.ui.filter.Filter?.ClaimNote, 'claimfilter_elig_claimnote')) {
                    return false;
                }

                if (this.isReleasedChecked()) {
                    if (this.getData(this.props.dataStore.ui.filter.Filter?.ClaimNote, "@From") !== "" &&
                        this.getData(this.props.dataStore.ui.filter.Filter?.ClaimNote, "@To") !== "") {
                        if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.ClaimNote,
                            'claimfilter_elig_claimnote',
                            false, 29, "Please select a date range less than 30 days", false)) {
                            return false;
                        }
                    } else if (this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@From") == "" &&
                        this.getData(this.props.dataStore.ui.filter.Filter?.DateField, "@To") == "") {

                        var today = new Date();
                        var thirtyDaysAgo = new Date();
                        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 29);
                        this.onDateChange(today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear(), false);
                        this.onDateChange(thirtyDaysAgo.getMonth() + 1 + "/" + thirtyDaysAgo.getDate() + "/" + thirtyDaysAgo.getFullYear(), true);
                        this.onDateFieldChange({ value: DATE_RELEASED_ID });
                    }
                } else {
                    if (this.isDeletedFilter && this.process_lowercase === "deleted") {
                        if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.DateField,
                            'claimfilter_elig_datefield', true, 7, "Please limit date range to 7 days", true)) {
                            return false;
                        }
                    }
                }

                if (this.process_lowercase == "reprocesspayerstatusrules") {

                    if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.DateField,
                        'claimfilter_elig_datefield',
                        true, 120, "Please select a date within the last 120 days", false)) {
                        return false;
                    }

                    if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.ClaimNote,
                        'claimfilter_elig_claimnote',
                        true, 120, "Please select a date within the last 120 days", false)) {
                        return false;
                    }

                }

                if (this.process_lowercase == "reprocessuserprofiles") {

                    if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.DateField,
                        'claimfilter_elig_datefield',
                        true, 120, "Please adjust your date range to not exceed 120 days.", false)) {
                        return false;
                    }

                    if (!this.validateDateField_Check3(this.props.dataStore.ui.filter.Filter?.ClaimNote,
                        'claimfilter_elig_claimnote',
                        true, 120, "Please adjust your date range to not exceed 120 days.", false)) {
                        return false;
                    }
                }

                break;
            }
        }

        return this.checkRua_validateFilter();
    }

    applyRegex(data: string, type: string, applyAddlRegex: boolean, rExp: RegExp): string {
        var newData = data;
        if (type == "alpha") {
            newData = newData.replace(/[^\w '\-@,"!:]/g, "");
        } else if (type == "numeric") {
            newData = newData.replace(/[^\d\.\-,!:]/g, "");
        } else {
            newData = newData.replace(/[^\w '\.\-/@,"!:#*]/g, "");
        }

        if (applyAddlRegex)
            newData = newData.replace(rExp, "");
        if (newData != data)
            return newData;

        return "DO_NOT_UPDATE_3";
    }

    public checkRua_validateFilter(): boolean {
        if (this.checkRua == true) {

            var xmlData: APIXMLCB_XmlCallBackModel = {};
            xmlData.Type = "Claim";
            xmlData.ViewOrigin = '/Claims/filter.asp';  // see note in type def
            xmlData.FilterJsonData = this.getFilterData();
            xmlData.TestMode = this.props.testMode;

            fetch(URLs.api + '/api/data/ValidateFilter',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `${getRawToken()}`
                    },
                    body: JSON.stringify(xmlData),
                })
                .then(async (response) => {

                    // get json response here
                    let data: any = await response.json();

                    if (response.status === 306) {
                        //let cleanData = data.message.replace(/:"/g, "-");
                        this.onAlert(data.message);  // error is in message
                        return false;
                    } else if (response.status === 200) {
                        //   this.onRefineSuccess(data, url);  // data is just in data
                        return true;
                    } else {
                        throw new Error(`ClaimFilter: unable to run filter ${response.status}. ${response.statusText}:  ${(data && data.message ? data.message : data)}`);
                    }
                })
                .catch(error => {

                    console.error('ClaimFilter: ' + error);
                    this.errorCallback("Unable to process filter.");
                })
                .finally(() => {

                });
        }
        return true;
    }

    public getFilterType(): string {
        var filterType;

        if (this.props.dataStore.ui.activeTab === 0 || this.props.dataStore.ui.activeTab === 1)
            filterType = "Claim";
        else if (this.props.dataStore.ui.activeTab === 2)
            filterType = "Status";
        else
            filterType = "Eligibility";

        return filterType;
    }

    // based on how we implemented the store, i'll need to clean up unused elements 
    public getFilterData(): string {

        if (this.props.dataStore.ui.activeTab === 0 && this.props.dataStore.ui.quickStart == "0") {
            return "";  // special case from validate filter
        }

        var data: any = this.props.dataStore.ui.filter;

        data.Filter['@ID'] = this.props.dataStore.ui.filterId ?? "0";

        if ((data.Filter['@ID'] == "0" || data.Filter['@ID'] == "0"))
            delete data.Filter['@Name'];

        if (!('@ID' in data.Filter) || data.Filter['@ID'] == "")
            data.Filter['@ID'] = "0";

        delete data.Filter['@Type'];
        data.Filter['@Type'] = this.getFilterType();

        // AccountNumber: { '@Value': '' },
        if (data.Filter.AccountNumber) {
            if (this.getData(data.Filter.AccountNumber, '@Value').length == 0)
                delete data.Filter['AccountNumber'];
        }

        //PatientName: { '@Value': '' },
        if (data.Filter.PatientName) {
            if (this.getData(data.Filter.PatientName, '@Value').length == 0)
                delete data.Filter['PatientName'];
        }

        //Financial Class: { '@Value': '' },
        if (data.Filter.FinancialClass) {
            if (this.getData(data.Filter.FinancialClass, '@Value').length == 0)
                delete data.Filter['FinancialClass'];
        }

        //Amount: { '@Value': '' },
        if (data.Filter.Amount) {
            if (this.getData(data.Filter.Amount, '@Value').length == 0)
                delete data.Filter['Amount'];
        }

        //TypeOfBill: { '@Value': '' },
        if (data.Filter.TypeOfBill) {
            if (this.getData(data.Filter.TypeOfBill, '@Value').length == 0)
                delete data.Filter['TypeOfBill'];
        }

        //PlanCode: { '@Value': '' },
        if (data.Filter.PlanCode) {
            if (this.props.dataStore.ui.activeTab === 0 || this.getData(data.Filter.PlanCode, '@Value').length == 0)
                delete data.Filter['PlanCode'];
        }

        //DisplayOrder: { '@ID': '1' },
        if (data.Filter.DisplayOrder) {
            if (this.getData(data.Filter.DisplayOrder, '@ID').length == 0 || this.getData(data.Filter.DisplayOrder, '@ID') == "0")
                delete data.Filter['DisplayOrder'];
        }
        //ReleaseBatch: { '@ID': '' },
        if (data.Filter.ReleaseBatch) {
            if (this.getData(data.Filter.ReleaseBatch, '@ID').length == 0 || this.getData(data.Filter.ReleaseBatch, '@ID') == "0")
                delete data.Filter['ReleaseBatch'];
        }
        //SubmittedFile: { '@ID': '' },
        if (data.Filter.SubmittedFile) {
            if ((this.props.dataStore.ui.activeTab === 2 || this.props.dataStore.ui.activeTab === 3) ||
                this.getData(data.Filter.SubmittedFile, '@ID').length == 0 || this.getData(data.Filter.SubmittedFile, '@ID') == "0")
                delete data.Filter['SubmittedFile'];
        }
        //ClaimState: { '@ID': '' },  // NOTE I think this should always have a value
        if (data.Filter.ClaimState) {
            if (this.getData(data.Filter.ClaimState, '@ID').length == 0)
                delete data.Filter['ClaimState'];
            // should i data.Filter.ClaimState['@ID'] = "1" instead?  check ClaimState_Submit for details
        }

        //SDR: {'@SentToState': '', '@NotSentToState': '', '@All': '' },
        if (data.Filter.SDR) {
            if (this.getData(data.Filter.SDR, '@SentToState').length == 0
                && this.getData(data.Filter.SDR, '@NotSentToState').length == 0
                && this.getData(data.Filter.SDR, '@All').length == 0)
                delete data.Filter['SDR'];
            else {
                if (this.getData(data.Filter.SDR, '@SentToState').length == 0)
                    delete data.Filter.SDR['@SentToState'];
                if (this.getData(data.Filter.SDR, '@NotSentToState').length == 0)
                    delete data.Filter.SDR['@NotSentToState'];
                if (this.getData(data.Filter.SDR, '@All').length == 0)
                    delete data.Filter.SDR['@All'];
            }
        }

        //UserField: { '@ID': '', '@Value': ''},
        if (data.Filter.UserField) {
            if (this.getData(data.Filter.UserField, '@ID').length == 0 || this.getData(data.Filter.UserField, '@ID') == "0"
                || this.getData(data.Filter.UserField, '@Value').length == 0)
                delete data.Filter['UserField'];
        }
        //UserField2: { '@ID': '', '@Value': ''},
        if (data.Filter.UserField2) {
            if (this.getData(data.Filter.UserField2, '@ID').length == 0 || this.getData(data.Filter.UserField2, '@ID') == "0"
                || this.getData(data.Filter.UserField2, '@Value').length == 0)
                delete data.Filter['UserField2'];
        }
        //UserField3: { '@ID': '', '@Value': ''},
        if (data.Filter.UserField3) {
            if (this.getData(data.Filter.UserField3, '@ID').length == 0 || this.getData(data.Filter.UserField3, '@ID') == "0"
                || this.getData(data.Filter.UserField3, '@Value').length == 0)
                delete data.Filter['UserField3'];
        }
        //UserField4: { '@ID': '', '@Value': ''},
        if (data.Filter.UserField4) {
            if (this.getData(data.Filter.UserField4, '@ID').length == 0 || this.getData(data.Filter.UserField4, '@ID') == "0"
                || this.getData(data.Filter.UserField4, '@Value').length == 0)
                delete data.Filter['UserField4'];
        }

        //DateField: { '@ID': '', '@From': '', '@To': '' },
        if (data.Filter.DateField) {
            if (this.getData(data.Filter.DateField, '@ID').length == 0 || this.getData(data.Filter.DateField, '@ID') == "0")
                delete data.Filter['DateField'];
        }

        // ClaimStatus: {'@Accepted': '', '@Warning': '', '@Rejected': '', '@All': '', '@Unreported': '' },
        if (data.Filter.ClaimStatus) {
            if ((this.getData(data.Filter.ClaimStatus, '@Accepted').length == 0
                && this.getData(data.Filter.ClaimStatus, '@Warning').length == 0
                && this.getData(data.Filter.ClaimStatus, '@All').length == 0
                && this.getData(data.Filter.ClaimStatus, '@Unreported').length == 0
                && this.getData(data.Filter.ClaimStatus, '@Rejected').length == 0))
                delete data.Filter['ClaimStatus'];
            else {
                if (this.getData(data.Filter.ClaimStatus, '@Accepted').length == 0)
                    delete data.Filter.ClaimStatus['@Accepted'];
                if (this.getData(data.Filter.ClaimStatus, '@Warning').length == 0)
                    delete data.Filter.ClaimStatus['@Warning'];
                if (this.getData(data.Filter.ClaimStatus, '@All').length == 0)
                    delete data.Filter.ClaimStatus['@All'];
                if (this.getData(data.Filter.ClaimStatus, '@Unreported').length == 0)
                    delete data.Filter.ClaimStatus['@Unreported'];
                if (this.getData(data.Filter.ClaimStatus, '@Rejected').length == 0)
                    delete data.Filter.ClaimStatus['@Rejected'];
            }
        }

        //FormTypes: { FormType: [] },  note: this appears to build an empty xml formtypes
        if (data.Filter.FormTypes) {
            if (data.Filter.FormTypes.FormType) {
                if (data.Filter.FormTypes.FormType.length == 0)
                    delete data.Filter.FormTypes['FormType'];
            }
        }
        // Facilities: { Facility: [] },
        if (data.Filter.Facilities) {
            if (data.Filter.Facilities.Facility) {
                if (data.Filter.Facilities.Facility.length == 0 ||
                    (data.Filter.Facilities.Facility.length == 1 && this.getData(data.Filter.Facilities.Facility[0], '@ID').length == 0)) {
                    delete data.Filter.Facilities['Facility'];
                    delete data.Filter['Facilities'];
                }

            }
        }

        // ErrorCategories: { ErrorCategory: [] },
        if (data.Filter.ErrorCategories) {
            if (data.Filter.ErrorCategories.ErrorCategory) {
                if (this.props.dataStore.ui.activeTab !== 1 || data.Filter.ErrorCategories.ErrorCategory.length == 0) {
                    delete data.Filter.ErrorCategories['ErrorCategory'];
                    delete data.Filter['ErrorCategories'];
                }

            }
        }


        //ClaimLOBs: { ClaimLOB: [] },
        if (data.Filter.ClaimLOBs) {
            if (data.Filter.ClaimLOBs.ClaimLOB) {
                if (data.Filter.ClaimLOBs.ClaimLOB.length == 0)
                    delete data.Filter.ClaimLOBs['ClaimLOB'];
            }
        }

        //SubmittedFile: { '@ID': '' },
        if (data.Filter.SubmittedFile) {
            if (this.getData(data.Filter.SubmittedFile, '@ID').length == 0 || this.getData(data.Filter.SubmittedFile, '@ID') == "0")
                delete data.Filter['SubmittedFile'];
        }

        //ServiceType: { '@Inpatient': '', '@Outpatient': '', '@All': '' },
        if (data.Filter.ServiceType) {
            if (this.getData(data.Filter.ServiceType, '@Inpatient').length == 0
                && this.getData(data.Filter.ServiceType, '@Outpatient').length == 0
                && this.getData(data.Filter.ServiceType, '@All').length == 0)
                delete data.Filter['ServiceType'];
            else {
                if (this.getData(data.Filter.ServiceType, '@Inpatient').length == 0)
                    delete data.Filter.ServiceType['@Inpatient'];
                if (this.getData(data.Filter.ServiceType, '@Outpatient').length == 0)
                    delete data.Filter.ServiceType['@Outpatient'];
                if (this.getData(data.Filter.ServiceType, '@All').length == 0)
                    delete data.Filter.ServiceType['@All'];
            }
        }

        //TransmitType: {'@Electronic': '','@Paper': '','@ClearinghousePrint': '','@Direct': '','@Outsourced': '','@HCD': ''},
        if (data.Filter.TransmitType) {
            var hcdomsId = this.getConfigValue("HCDOMSId");


            if (this.getData(data.Filter.TransmitType, '@Electronic').length == 0
                && this.getData(data.Filter.TransmitType, '@Paper').length == 0
                && this.getData(data.Filter.TransmitType, '@ClearinghousePrint').length == 0
                && this.getData(data.Filter.TransmitType, '@Direct').length == 0
                && this.getData(data.Filter.TransmitType, '@Outsourced').length == 0
                && this.getData(data.Filter.TransmitType, '@HCD').length == 0)
                delete data.Filter['TransmitType'];
            else {
                if (this.getData(data.Filter.TransmitType, '@Electronic').length == 0)
                    delete data.Filter.TransmitType['@Electronic'];
                if (this.getData(data.Filter.TransmitType, '@Paper').length == 0)
                    delete data.Filter.TransmitType['@Paper'];
                if (this.getData(data.Filter.TransmitType, '@ClearinghousePrint').length == 0)
                    delete data.Filter.TransmitType['@ClearinghousePrint'];
                if (this.getData(data.Filter.TransmitType, '@Direct').length == 0)
                    delete data.Filter.TransmitType['@Direct'];
                if (this.isClientUser() || this.getData(data.Filter.TransmitType, '@Outsourced').length == 0)
                    delete data.Filter.TransmitType['@Outsourced'];
                if (this.getData(data.Filter.TransmitType, '@Outsourced').length == 0)
                    delete data.Filter.TransmitType['@Outsourced'];
                if (hcdomsId == "" || this.getData(data.Filter.TransmitType, '@HCD').length == 0)
                    delete data.Filter.TransmitType['@HCD'];
            }


        }

        // ClaimNote: { '@Type': '', '@Author': '', '@From': '', '@To': ''}
        if (data.Filter.ClaimNote) {
            if (this.props.dataStore.ui.activeTab === 0)
                delete data.Filter['ClaimNote'];
            else {
                if (this.getData(data.Filter.ClaimNote, '@Type').length == 0
                    && (this.getData(data.Filter.ClaimNote, '@Author') == "0" || this.getData(data.Filter.ClaimNote, '@Author').length == 0)
                    && this.getData(data.Filter.ClaimNote, '@From').length == 0
                    && this.getData(data.Filter.ClaimNote, '@To').length == 0) {
                    delete data.Filter['ClaimNote'];
                }
                // note: old filter did not remove empty attributes for some reason
                else {
                    var testA = this.getData(data.Filter.ClaimNote, '@Author');
                    if (testA.indexOf("\\\\") > -1) {
                        data.Filter.ClaimNote['@Author'] = testA.replace("\\\\", "\\");
                    }
                    if (testA == "0")
                        data.Filter.ClaimNote['@Author'] = "";
                }


            }
        }


        // User: { '@ID': '0' },
        if (data.Filter.User) {
            if (this.props.dataStore.ui.activeTab === 0
                || this.getData(data.Filter.User, '@ID').length == 0 || this.getData(data.Filter.User, '@ID') == "0")
                delete data.Filter['User'];
        }

        //         ClaimsWithHoldCodeYes: { '@ClaimsWithHoldCodeYes': '' },
        if (data.Filter.ClaimsWithHoldCodeYes) {
            if (this.props.dataStore.ui.activeTab === 0
                || this.getData(data.Filter.ClaimsWithHoldCodeYes, '@ClaimsWithHoldCodeYes').length == 0)
                delete data.Filter['ClaimsWithHoldCodeYes'];
        }
        //         ClaimsWithHoldCodeNo: { '@ClaimsWithHoldCodeNo': '' },
        if (data.Filter.ClaimsWithHoldCodeNo) {
            if (this.props.dataStore.ui.activeTab === 0
                || this.getData(data.Filter.ClaimsWithHoldCodeNo, '@ClaimsWithHoldCodeNo').length == 0)
                delete data.Filter['ClaimsWithHoldCodeNo'];
        }


        // LastAttachment: {'@None': '','@Created': '','@Pending': '','@Error': '','@Sent': '','@Acknowledged': '','@Rejected': '','@From': '','@To': '' },
        if (data.Filter.LastAttachment) {
            if (this.getData(data.Filter.LastAttachment, '@None').length == 0)
                delete data.Filter.LastAttachment['@None'];
            if (this.getData(data.Filter.LastAttachment, '@Created').length == 0)
                delete data.Filter.LastAttachment['@Created'];
            if (this.getData(data.Filter.LastAttachment, '@Pending').length == 0)
                delete data.Filter.LastAttachment['@Pending'];
            if (this.getData(data.Filter.LastAttachment, '@Error').length == 0)
                delete data.Filter.LastAttachment['@Error'];
            if (this.getData(data.Filter.LastAttachment, '@Sent').length == 0)
                delete data.Filter.LastAttachment['@Sent'];
            if (this.getData(data.Filter.LastAttachment, '@Acknowledged').length == 0)
                delete data.Filter.LastAttachment['@Acknowledged'];
            if (this.getData(data.Filter.LastAttachment, '@Rejected').length == 0)
                delete data.Filter.LastAttachment['@Rejected'];
            if (this.getData(data.Filter.LastAttachment, '@From').length == 0)
                delete data.Filter.LastAttachment['@From'];
            if (this.getData(data.Filter.LastAttachment, '@To').length == 0)
                delete data.Filter.LastAttachment['@To'];

            if (this.props.dataStore.ui.activeTab === 0
                || Object.keys(data.Filter.LastAttachment).length == 0)
                delete data.Filter['LastAttachment'];
        }

        // AppealStatus: {'@Created': '','@Succeeded': '','@Filed': '','@Denied': '','@From': '','@To': ''},
        if (data.Filter.AppealStatus) {
            if (this.getData(data.Filter.AppealStatus, '@Created').length == 0)
                delete data.Filter.AppealStatus['@Created'];
            if (this.getData(data.Filter.AppealStatus, '@Succeeded').length == 0)
                delete data.Filter.AppealStatus['@Succeeded'];
            if (this.getData(data.Filter.AppealStatus, '@Filed').length == 0)
                delete data.Filter.AppealStatus['@Filed'];
            if (this.getData(data.Filter.AppealStatus, '@Denied').length == 0)
                delete data.Filter.AppealStatus['@Denied'];
            if (this.getData(data.Filter.AppealStatus, '@From').length == 0)
                delete data.Filter.AppealStatus['@From'];
            if (this.getData(data.Filter.AppealStatus, '@To').length == 0)
                delete data.Filter.AppealStatus['@To'];

            if (this.props.dataStore.ui.activeTab === 0 || this.props.dataStore.ui.activeTab === 1
                || Object.keys(data.Filter.AppealStatus).length == 0)
                delete data.Filter['AppealStatus'];
        }

        // BillingOption: {'@Primary': '','@Rebill': '','@Secondary': '','@SecondaryFromRemit': '','@Tertiary': '','@TertiaryFromRemit': '','@All': '',},
        if (data.Filter.BillingOption) {
            if (this.getData(data.Filter.BillingOption, '@Primary').length == 0)
                delete data.Filter.BillingOption['@Primary'];
            if (this.getData(data.Filter.BillingOption, '@Rebill').length == 0)
                delete data.Filter.BillingOption['@Rebill'];
            if (this.getData(data.Filter.BillingOption, '@Secondary').length == 0)
                delete data.Filter.BillingOption['@Secondary'];
            if (this.getData(data.Filter.BillingOption, '@SecondaryFromRemit').length == 0)
                delete data.Filter.BillingOption['@SecondaryFromRemit'];
            if (this.getData(data.Filter.BillingOption, '@Tertiary').length == 0)
                delete data.Filter.BillingOption['@Tertiary'];
            if (this.getData(data.Filter.BillingOption, '@TertiaryFromRemit').length == 0)
                delete data.Filter.BillingOption['@TertiaryFromRemit'];
            if (this.getData(data.Filter.BillingOption, '@All').length == 0)
                delete data.Filter.BillingOption['@All'];

            if (this.props.dataStore.ui.activeTab === 0
                || Object.keys(data.Filter.BillingOption).length == 0)
                delete data.Filter['BillingOption'];
        }

        // PayerStatusCategory: { '@NotFound': '', '@Error': '', '@Pending': '', '@FinalPaid': '', '@FinalDenied': '', '@FinalOther': '', '@Acknowledged': '', '@ReqInfo': '', '@Processing': '', '@Returned': '', '@NoStatus': '', '@All': '',        },
        if (this.props.dataStore.ui.activeTab !== 2 && data.Filter.StatusCategory)
            delete data.Filter['StatusCategory'];

        if (data.Filter.StatusCategory) {
            var noNF = this.getData(data.Filter.StatusCategory, '@NotFound').length == 0;
            var noErr = this.getData(data.Filter.StatusCategory, '@Error').length == 0;
            var noPen = this.getData(data.Filter.StatusCategory, '@Pending').length == 0;
            var noFP = this.getData(data.Filter.StatusCategory, '@FinalPaid').length == 0;
            var noFD = this.getData(data.Filter.StatusCategory, '@FinalDenied').length == 0;
            var noFO = this.getData(data.Filter.StatusCategory, '@FinalOther').length == 0;
            var noAck = this.getData(data.Filter.StatusCategory, '@Acknowledged').length == 0;
            var noRI = this.getData(data.Filter.StatusCategory, '@ReqInfo').length == 0;
            var noPro = this.getData(data.Filter.StatusCategory, '@Processing').length == 0;
            var noRet = this.getData(data.Filter.StatusCategory, '@Returned').length == 0;
            var noSta = this.getData(data.Filter.StatusCategory, '@NoStatus').length == 0;
            var noAll = this.getData(data.Filter.StatusCategory, '@All').length == 0;

            if (noNF)
                delete data.Filter.StatusCategory['@NotFound'];
            if (noErr)
                delete data.Filter.StatusCategory['@Error'];
            if (noPen)
                delete data.Filter.StatusCategory['@Pending'];
            if (noFP)
                delete data.Filter.StatusCategory['@FinalPaid'];
            if (noFD)
                delete data.Filter.StatusCategory['@FinalDenied'];
            if (noFO)
                delete data.Filter.StatusCategory['@FinalOther'];
            if (noAck)
                delete data.Filter.StatusCategory['@Acknowledged'];
            if (noRI)
                delete data.Filter.StatusCategory['@ReqInfo'];
            if (noPro)
                delete data.Filter.StatusCategory['@Processing'];
            if (noRet)
                delete data.Filter.StatusCategory['@Returned'];
            if (noSta)
                delete data.Filter.StatusCategory['@NoStatus'];
            if (noAll)
                delete data.Filter.StatusCategory['@All'];

            var fromDate = this.getData(data.Filter.StatusCategory, '@From');
            var toDate = this.getData(data.Filter.StatusCategory, '@To');
            if (fromDate === "" || toDate === "") {
                delete data.Filter.StatusCategory['@From'];
                delete data.Filter.StatusCategory['@To'];
                delete data.Filter.StatusCategory['@Type'];
            } else {
                var type = this.getData(data.Filter.StatusCategory, '@Type');
                data.Filter.StatusCategory['@Type'] = type.length > 0 ? type : "Posted";
            }

            //if (noNF && noErr && noPen && noFP && noFD && noFO && noAck &&
            //    noRI && noPro && noRet && noSta && noAll) {
            //        data.Filter.StatusCategory['@All'] = 'true';
            //}

            var statusType = this.getData(data.Filter.StatusCategory, '@StatusType');
            delete data.Filter.StatusCategory['@StatusType'];

            if (this.props.dataStore.ui.activeTab === 0  // should this be !== 2?
                || Object.keys(data.Filter.StatusCategory).length == 0)
                delete data.Filter['StatusCategory'];
            else {
                data.Filter.StatusCategory['@StatusType'] = statusType.length > 0 ? statusType : "Status277";
            }
        }

        // EligibilityCategory:
        if (this.props.dataStore.ui.activeTab !== 3 && data.Filter.EligibilityCategory)
            delete data.Filter['EligibilityCategory'];

        if (data.Filter.EligibilityCategory) {
            if (this.getData(data.Filter.EligibilityCategory, '@DuplicateId').length == 0)
                delete data.Filter.EligibilityCategory['@DuplicateId'];
            if (this.getData(data.Filter.EligibilityCategory, '@InvalidOrMissingInfo').length == 0)
                delete data.Filter.EligibilityCategory['@InvalidOrMissingInfo'];
            if (this.getData(data.Filter.EligibilityCategory, '@PatInsMatch').length == 0)
                delete data.Filter.EligibilityCategory['@PatInsMatch'];
            if (this.getData(data.Filter.EligibilityCategory, '@ServiceDate').length == 0)
                delete data.Filter.EligibilityCategory['@ServiceDate'];
            if (this.getData(data.Filter.EligibilityCategory, '@Provider').length == 0)
                delete data.Filter.EligibilityCategory['@Provider'];
            if (this.getData(data.Filter.EligibilityCategory, '@NeverRequested').length == 0)
                delete data.Filter.EligibilityCategory['@NeverRequested'];
            if (this.getData(data.Filter.EligibilityCategory, '@RequestPending').length == 0)
                delete data.Filter.EligibilityCategory['@RequestPending'];
            if (this.getData(data.Filter.EligibilityCategory, '@RequestFailed').length == 0)
                delete data.Filter.EligibilityCategory['@RequestFailed'];
            if (this.getData(data.Filter.EligibilityCategory, '@All').length == 0)
                delete data.Filter.EligibilityCategory['@All'];

            if (this.props.dataStore.ui.activeTab === 0
                || Object.keys(data.Filter.EligibilityCategory).length == 0)
                delete data.Filter['EligibilityCategory'];
        }


        // AiiStatus: {'@NotQueried': '','@AiiPended': '','@AiiDenied': '','@AiiPaid': '','@AiiException': '','@All': ''},
        if (data.Filter.AiiStatus) {
            if (this.getData(data.Filter.AiiStatus, '@NotQueried').length == 0)
                delete data.Filter.AiiStatus['@NotQueried'];
            if ((this.process_lowercase !== "deslstatus" && this.props.dataStore.ui.activeTab === 0)
                || this.getData(data.Filter.AiiStatus, '@AiiPended').length == 0)
                delete data.Filter.AiiStatus['@AiiPended'];
            if ((this.process_lowercase !== "deslstatus" && this.props.dataStore.ui.activeTab === 0)
                || this.getData(data.Filter.AiiStatus, '@AiiDenied').length == 0)
                delete data.Filter.AiiStatus['@AiiDenied'];
            if (this.getData(data.Filter.AiiStatus, '@AiiPaid').length == 0)
                delete data.Filter.AiiStatus['@AiiPaid'];
            if (this.getData(data.Filter.AiiStatus, '@AiiException').length == 0)
                delete data.Filter.AiiStatus['@AiiException'];
            if (this.getData(data.Filter.AiiStatus, '@All').length == 0)
                delete data.Filter.AiiStatus['@All'];

            if (this.props.dataStore.ui.activeTab === 0
                || Object.keys(data.Filter.AiiStatus).length == 0)
                delete data.Filter['AiiStatus'];
        }


        if (this.props.dataStore.ui.activeTab == 3) {
            if (!data.Filter.EligibilityPayers) {
                data.Filter.EligibilityPayers = {};
                data.Filter.EligibilityPayers.EligibilityPayer = [];
            }
        }

        //EligibilityPayers: { EligibilityPayer: [],
        if (data.Filter.EligibilityPayers) {
            if (this.props.dataStore.ui.activeTab !== 3) {
                delete data.Filter['EligibilityPayers'];
            } else {
                if (!data.Filter.EligibilityPayers.EligibilityPayer)
                    data.Filter.EligibilityPayers.ElgibilityPayer = [];

                if (data.Filter.EligibilityPayers.EligibilityPayer) {
                    if (data.Filter.EligibilityPayers.EligibilityPayer.length == 0) {
                        data.Filter.EligibilityPayers.EligibilityPayer.push({ '@ID': '- All Eligibility Payers -' });
                    }
                }
            }
        }




        //Payers: { Payer: [],
        if (data.Filter.Payers) {
            if ((this.props.dataStore.ui.activeTab !== 1 && this.props.dataStore.ui.activeTab !== 2)) {
                delete data.Filter['Payers'];
            } else {
                if (data.Filter.Payers.Payer) {
                    if (data.Filter.Payers.Payer.length == 0)
                        delete data.Filter.Payers['Payer'];
                }
            }
        }


        //PayerType: { '@Type': '' } or <PayerType Type='' />
        let reportRequest: any = this.props.parameter?.ReportRequests?.ReportRequest?.find((req: any) => req['@ID'] != null)
        if (reportRequest && reportRequest['@ID'] != '') {
            console.log('running report request, not touching PayerType...');
        } else {
            if (data.Filter.PayerType) {
                if ((this.props.dataStore.ui.activeTab !== 1 && this.props.dataStore.ui.activeTab !== 2)
                    || !data.Filter.Payers?.Payer
                    || this.getData(data.Filter.PayerType, '@Type').length == 0)
                    delete data.Filter['PayerType'];
            } else {
                if (data.Filter.Payers && data.Filter.Payers.Payer && data.Filter.Payers.Payer.length > 0) {
                    data.Filter.PayerType = { '@Type': 'Active' };
                }
            }
        }

        return JSON.stringify(data);
    }



    ////////////////////////////
    ////  ACTION CALLS
    ////////////////////////////

    public onLoadFilter(data: APICF_FilterWrapper) {
        var tabIndex: number = 1;
        if (data && data.Filter) {
            if (this.process_lowercase !== "viewx12claim") {
                var type = data.Filter['@Type'];
                if (type == 'Status')
                    tabIndex = 2;
                else if (type == 'Eligibility')
                    tabIndex = 3;
            }
        }

        if (tabIndex == 2 || tabIndex == 3)
            this.onSelectPayerType('Primary');

        this.setActiveTab(tabIndex);

        if (this.validationCallback_LoadFilter(data)) {
            this.props.action.ui.loadFilter(
                {
                    api: this.props.dataStore.api.data,
                    uiData: { data: data, index: tabIndex }
                });
        } else {
            console.error('onLoadFilter cannot verify shape of filter to load.');
            this.onAlert('Unable to load filter.');
        }

    }

    public setDataRefreshed() {
        this.props.action.ui.setDataRefreshed();
    }
    public clearDataRefreshed() {
        this.props.action.ui.clearDataRefreshed();
    }

    public disableOption(type: string, val: boolean) {
        this.props.action.ui.disableOption(type, val);
    }

    public disableReleaseBatch(type: string, val: boolean) {
        this.props.action.ui.disableReleaseBatch(type, val);
    }

    public disableOptions(type: DisableableOptions[], val: boolean) {
        this.props.action.ui.disableOptions(type, val);
    }

    public disableStateDataReporting(type: string[], val: boolean) {
        this.props.action.ui.disableStateDataReporting(type, val);
    }

    public disableBillingOption(type: string[], val: boolean) {
        this.props.action.ui.disableBillingOption(type, val);
    }

    public disableStatusOptions(type: string[], val: boolean) {
        this.props.action.ui.disableStatusOptions(type, val);
    }

    public disableDirectEntryOptions(type: string[], val: boolean) {
        this.props.action.ui.disableDirectEntryOptions(type, val);
    }

    public setFilterSelection(id: number, name: string) {

        this.props.action.ui.selectFilter(
            {
                api: this.props.dataStore.api.data,
                uiData: { id: id, value: name }
            });
    }

    public onLoadFilterList(data: APICF_ListPopulationContainer) {
        if (this.validationCallback(data)) {
            this.props.action.ui.reloadFilterList(
                {
                    api: this.props.dataStore.api.data,
                    uiData: { data }
                });
        } else {
            console.error('onLoadFilter invalid data returned from .');
            this.onAlert('A problem occurred during filter save.');
        }

    }

    public setFilterUserFieldHelper() {
        var currentData: any = this.props.dataStore.ui.filter.Filter?.UserField;
        if (!currentData)
            return;

        if ((this.getData(currentData, '@ID') == "0" || this.getData(currentData, '@ID') == "") &&
            this.getData(currentData, '@Value') !== '') {
            this.props.action.ui.updateUserField(
                {
                    api: this.props.dataStore.api.data,
                    uiData: { data: { '@ID': '', '@Value': '' } }
                });
        }
    }
    public setFilterUserField2Helper() {
        var currentData: any = this.props.dataStore.ui.filter.Filter?.UserField2;
        if (!currentData)
            return;

        if ((this.getData(currentData, '@ID') == "0" || this.getData(currentData, '@ID') == "") &&
            this.getData(currentData, '@Value') !== '') {
            this.props.action.ui.updateUserField2(
                {
                    api: this.props.dataStore.api.data,
                    uiData: { data: { '@ID': '', '@Value': '' } }
                });
        }
    }
    public setFilterUserField3Helper() {
        var currentData: any = this.props.dataStore.ui.filter.Filter?.UserField3;
        if (!currentData)
            return;

        if ((this.getData(currentData, '@ID') == "0" || this.getData(currentData, '@ID') == "") &&
            this.getData(currentData, '@Value') !== '') {
            this.props.action.ui.updateUserField3(
                {
                    api: this.props.dataStore.api.data,
                    uiData: { data: { '@ID': '', '@Value': '' } }
                });
        }
    }
    public setFilterUserField4Helper() {
        var currentData: any = this.props.dataStore.ui.filter.Filter?.UserField4;
        if (!currentData)
            return;

        if ((this.getData(currentData, '@ID') == "0" || this.getData(currentData, '@ID') == "") &&
            this.getData(currentData, '@Value') !== '') {
            this.props.action.ui.updateUserField4(
                {
                    api: this.props.dataStore.api.data,
                    uiData: { data: { '@ID': '', '@Value': '' } }
                });
        }
    }

    public setFilterDateHelper() {
        var currentData: any = this.props.dataStore.ui.filter.Filter?.DateField;
        if (!currentData)
            return;

        if ((this.getData(currentData, '@ID') == "0" || this.getData(currentData, '@ID') == "") &&
            (this.getData(currentData, '@From') !== '' || this.getData(currentData, '@To') !== '')) {
            this.props.action.ui.updateDateField(
                {
                    api: this.props.dataStore.api.data,
                    uiData: { data: { '@ID': '', '@From': '', '@To': '' } }
                });
        }
    }



    public onDateChange(e: any, fromField: boolean) {
        var data: string = e ?? '';


        var currentData: any = this.props.dataStore.ui.filter.Filter?.DateField;
        if (!currentData)
            currentData = { '@ID': '', '@From': '', '@To': '' };

        if (fromField && currentData['@From'] === data) return;
        if (!fromField && currentData['@To'] === data) return;


        if (fromField)
            currentData['@From'] = data;
        else
            currentData['@To'] = data;


        this.props.action.ui.updateDateField(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: currentData
                }
            });
    }


    // onclick of the date type dropdown - not the date itself
    public onDateFieldChange(e: any) {
        var data: string = (e && e.value ? e.value : '');


        var currentData: any = this.props.dataStore.ui.filter.Filter?.DateField;
        if (!currentData)
            currentData = { '@ID': '', '@From': '', '@To': '' };

        if (currentData['@ID'] === data) return;

        if (data === '') {
            currentData = { '@ID': '', '@From': '', '@To': '' };
        }

        var allowTime = false;
        {
            currentData['@ID'] = data;

            switch (data) {
                case "10":
                case "5":
                case "6":
                case "9":
                case "7":
                case "12":
                case "13":
                case "8":
                case "14":
                    allowTime = true;

            }
        }


        //console.log(currentData);

        this.props.action.ui.updateDateField(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: currentData,
                    value: allowTime,
                }
            });
    }

    public updateAccountNumber(val: string) {
        this.props.action.ui.updateAccountNumber({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });


        if (this.process_lowercase === "manual" && this.props.dataStore.ui.disableDeleted) {
            this.disableOption("deleted", true);
        }

    }

    public updatePatientName(val: string) {
        this.props.action.ui.updatePatientName({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public updateUserField(val: string, index: number) {
        if (index == 1)
            this.props.action.ui.updateUserField({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
        else if (index == 2)
            this.props.action.ui.updateUserField2({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
        else if (index == 3)
            this.props.action.ui.updateUserField3({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
        else if (index == 4)
            this.props.action.ui.updateUserField4({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public updateUserFieldId(e: any, index: number) {
        var isSpc = this.isSpecialUserFieldRadio(e.value);
        this.props.action.ui.updateUserFieldId({ api: this.props.dataStore.api.data, uiData: { id: e.value, value: isSpc ? "true" : "", index: index } });
    }


    public updateFinancialClass(val: string) {
        this.props.action.ui.updateFinancialClass({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }
    public updateAmount(val: string) {
        this.props.action.ui.updateAmount({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }
    public updateTypeOfBill(val: string) {
        this.props.action.ui.updateTypeOfBill({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }
    public updatePlanCode(val: string) {
        this.props.action.ui.updatePlanCode({ api: this.props.dataStore.api.data, uiData: { value: val.trim() } });
    }

    public onDisplayOrderChange(e: any | undefined, id: string = '') {
        var result = { '@ID': id.length > 0 ? id : (e && e.value ? e.value : '0') };

        this.props.action.ui.selectDisplayOrder(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    selected: result,
                }
            });
    }

    public onReleaseBatchChange(e: any) {
        var result = { '@ID': (e && e.value ? e.value : '0') };

        this.props.action.ui.selectReleaseBatch(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    selected: result,
                }
            });
    }

    public onSubmittedFileChange(e: any | undefined) {
        var result = { '@ID': (e && e.value ? e.value : '0') };

        this.props.action.ui.selectSubmittedFile(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    selected: result,
                }
            });
    }
    public onUserChange(e: any | undefined) {
        var result = { '@ID': (e && e.value ? e.value : '0') };

        this.props.action.ui.selectUser(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    selected: result,
                }
            });
    }




    public onClaimNoteDateChange(e: string, fromField: boolean) {
        var data: string = e ?? '';

        var currentData: any = this.props.dataStore.ui.filter.Filter?.ClaimNote;
        if (!currentData)
            currentData = { '@Type': '', '@Author': '', '@From': '', '@To': '' };

        if (fromField && currentData['@From'] === data) return;
        if (!fromField && currentData['@To'] === data) return;

        if (fromField)
            currentData['@From'] = data;
        else
            currentData['@To'] = data;

        this.props.action.ui.updateClaimNote(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: currentData
                }
            });
    }

    public onClaimNoteTypeChange(e: any) {
        var data: string = e.label ?? '';
        if (data == "- Select Type -")
            data = '';

        var currentData: any = this.props.dataStore.ui.filter.Filter?.ClaimNote;
        if (!currentData)
            currentData = { '@Type': '', '@Author': '', '@From': '', '@To': '' };

        if (data === currentData['@Type']) return;

        currentData['@Type'] = data;

        this.props.action.ui.updateClaimNote(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: currentData
                }
            });
    }

    public onAuthorChange(e: any) {
        var data: string = e.value ?? '';

        var currentData: any = this.props.dataStore.ui.filter.Filter?.ClaimNote;
        if (!currentData)
            currentData = { '@Type': '', '@Author': '', '@From': '', '@To': '' };

        if (data === currentData['@Author']) return;

        currentData['@Author'] = data;


        this.props.action.ui.updateClaimNote(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: currentData
                }
            });
    }

    public onSelectFilter(e: React.ChangeEvent<HTMLSelectElement>) {
        if (e.target.options && e.target.options.length > 0 &&
            e.target.options[e.target.options.selectedIndex] !== null &&
            e.target.options[e.target.options.selectedIndex].text &&
            e.target.options.selectedIndex !== -1) {

            this.props.action.ui.selectFilterQs(
                {
                    api: this.props.dataStore.api.data,
                    uiData: {
                        index: e.target.options.selectedIndex,
                        value: e.target.value,
                        text: e.target.options[e.target.options.selectedIndex].textContent
                    }
                });
        }
    }

    // WARNING: this will unselect others
    public selectFormType(id: string) {
        this.props.action.ui.selectFormTypes(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    selected: [{ '@ID': id }]
                }
            });
    }

    public onSelectAllFormTypes() {
        var sel = this.getSelectedFormTypes();
        if (!sel || (sel && sel.length == 0)) {

            this.props.action.ui.selectFormTypes(
                {
                    api: this.props.dataStore.api.data,
                    uiData: {
                        selected: [{ '@ID': "" }]
                    }
                });
        }

    }

    public onSelectFormTypes(e: React.ChangeEvent<HTMLSelectElement>) {
        var result = this.getSelectedItemsArray(e);

        this.props.action.ui.selectFormTypes(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    selected: result,
                }
            });
    }

    public onSelectLob(id: string) {
        this.props.action.ui.selectLobs(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    selected: [{ '@ID': id }]
                }
            });
    }

    public onSelectAllLobs() {
        var lobSelected = this.getSelectedLobs();
        if (!lobSelected || (lobSelected && lobSelected.length == 0)) {

            this.props.action.ui.selectLobs(
                {
                    api: this.props.dataStore.api.data,
                    uiData: {
                        selected: [{ '@ID': "0" }]
                    }
                });
        }

    }
    public onSelectLobs(e: React.ChangeEvent<HTMLSelectElement>) {
        var result = this.getSelectedItemsArray(e);

        this.props.action.ui.selectLobs(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    selected: result,
                }
            });
    }

    public onSelectFacility(e: React.ChangeEvent<HTMLSelectElement> | undefined) {
        var result = [{ '@ID': '' }];
        if (e)
            result = this.getSelectedItemsArray(e);

        this.props.action.ui.selectFacility(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    selected: result,
                }
            });
    }



    public onSelectPayers(e: React.ChangeEvent<HTMLSelectElement>) {
        var result = this.getSelectedItemsArray(e);
        if (result.length > 0 && result[0]["@ID"] === "") {
            result.splice(0, 1)
        }

        this.props.action.ui.selectPayers(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    selected: result,
                }
            });
    }

    public onSelectEligibilityPayers(e: React.ChangeEvent<HTMLSelectElement>) {
        var result = this.getSelectedItemsArray(e);

        this.props.action.ui.selectEligibilityPayers(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    selected: result,
                }
            });
    }

    public onSelectErrorCategories(e: React.ChangeEvent<HTMLSelectElement> | undefined) {
        var result;
        if (e) {
            result = this.getSelectedItemsArray(e);

            this.props.action.ui.selectErrorCatagories(
                {
                    api: this.props.dataStore.api.data,
                    uiData: {
                        data: result,
                    }
                });
        }
    }

    public onSelectSDR(e: any) {

        var sentToState = this.props.dataStore.ui.filter.Filter.SDR && this.props.dataStore.ui.filter.Filter.SDR["@SentToState"] ? this.props.dataStore.ui.filter.Filter.SDR["@SentToState"] : '';
        var notSentToState = this.props.dataStore.ui.filter.Filter.SDR && this.props.dataStore.ui.filter.Filter.SDR["@NotSentToState"] ? this.props.dataStore.ui.filter.Filter.SDR["@NotSentToState"] : '';
        var all = this.props.dataStore.ui.filter.Filter.SDR && this.props.dataStore.ui.filter.Filter.SDR["@All"] ? this.props.dataStore.ui.filter.Filter.SDR["@All"] : '';;

        if (e === 'SentToState') {
            if (sentToState === 'Y') {
                sentToState = '';
            } else {
                if (notSentToState === 'N') {
                    all = 'true';
                    notSentToState = '';
                } else {
                    if (all !== 'true') {
                        sentToState = 'Y';
                    } else {
                        notSentToState = 'N';
                        all = '';
                    }
                }
            }
        } else if (e === 'NotSentToState') {
            if (notSentToState === 'N') {
                notSentToState = '';
            } else {
                if (sentToState === 'Y') {
                    all = 'true';
                    sentToState = '';
                } else {
                    if (all !== 'true') {
                        notSentToState = 'N';
                    } else {
                        sentToState = 'Y';
                        all = '';
                    }
                }
            }
        }

        var newSDR = { '@SentToState': sentToState, '@NotSentToState': notSentToState, '@All': all }

        this.props.action.ui.selectSDRsentToState(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newSDR,
                }
            });
    }



    public getLastAttachmentData(): any {
        var None, Created, Pending, Error, Sent, Acknowledged, Rejected, From, To;

        if (!this.props.dataStore.ui.filter.Filter.LastAttachment) {
            None = Created = Pending = Error = Sent = Acknowledged = Rejected = From = To = '';
        } else {
            None = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@None");
            Created = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@Created");
            Pending = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@Pending");
            Error = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@Error");
            Sent = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@Sent");
            Acknowledged = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@Acknowledged");
            Rejected = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@Rejected");
            From = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@From");
            To = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@To");
        }
        return {
            '@None': None,
            '@Created': Created,
            '@Pending': Pending,
            '@Error': Error,
            '@Sent': Sent,
            '@Acknowledged': Acknowledged,
            '@Rejected': Rejected,
            '@From': From,
            '@To': To,
        };
    }

    public onLastAttachmentDateChange(e: any, fromField: boolean) {
        var data: string = e ?? '';
        var newData = this.getLastAttachmentData();

        if (fromField && newData['@From'] === data) return;
        if (!fromField && newData['@To'] === data) return;

        if (fromField)
            newData['@From'] = data;
        else
            newData['@To'] = data;


        this.props.action.ui.selectLastAttachment(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newData,
                }
            });
    }


    public onSelectLastAttachment(e: any) {

        var None, Created, Pending, Error, Sent, Acknowledged, Rejected, From, To;

        if (!this.props.dataStore.ui.filter.Filter.LastAttachment) {
            None = Created = Pending = Error = Sent = Acknowledged = Rejected = From = To = '';
        } else {
            None = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@None");
            Created = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@Created");
            Pending = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@Pending");
            Error = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@Error");
            Sent = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@Sent");
            Acknowledged = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@Acknowledged");
            Rejected = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@Rejected");
            From = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@From");
            To = this.getData(this.props.dataStore.ui.filter.Filter?.LastAttachment, "@To");
        }

        switch (e) {
            case 'None':
                if (None === "") {
                    None = 'N';
                } else None = ""
                break;
            case 'Created':
                if (Created === "") {
                    Created = 'C';
                } else Created = ""
                break;
            case 'Pending':
                if (Pending === "") {
                    Pending = 'P';
                } else Pending = ""
                break;
            case 'Error':
                if (Error === "") {
                    Error = 'E';
                } else Error = ""
                break;
            case 'Sent':
                if (Sent === "") {
                    Sent = 'S';
                } else Sent = ""
                break;
            case 'Acknowledged':
                if (Acknowledged === "") {
                    Acknowledged = 'A';
                } else Acknowledged = ""
                break;
            case 'Rejected':
                if (Rejected === "") {
                    Rejected = 'R';
                } else Rejected = ""
                break;
        }

        var something = None || Created || Pending || Error || Sent || Acknowledged || Rejected;

        var newLastAttachment = {
            '@None': None,
            '@Created': Created,
            '@Pending': Pending,
            '@Error': Error,
            '@Sent': Sent,
            '@Acknowledged': Acknowledged,
            '@Rejected': Rejected,
            '@From': something && From == "" ? "00/30/0000" : From,  //SetAttchmentDefault logic
            '@To': something && To == "" ? "00/00/0000" : To,
        }

        this.props.action.ui.selectLastAttachment(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newLastAttachment,
                }
            });
    }

    public getAppealsData(): any {
        var Created, Succeeded, Filed, Denied, From, To;
        if (!this.props.dataStore.ui.filter.Filter.AppealStatus) {
            Created = Succeeded = Filed = Denied = From = To = '';
        } else {
            Created = this.props.dataStore.ui.filter.Filter.AppealStatus["@Created"] ? this.props.dataStore.ui.filter.Filter.AppealStatus["@Created"] : '';
            Succeeded = this.props.dataStore.ui.filter.Filter.AppealStatus["@Succeeded"] ? this.props.dataStore.ui.filter.Filter.AppealStatus["@Succeeded"] : '';
            Filed = this.props.dataStore.ui.filter.Filter.AppealStatus["@Filed"] ? this.props.dataStore.ui.filter.Filter.AppealStatus["@Filed"] : '';
            Denied = this.props.dataStore.ui.filter.Filter.AppealStatus["@Denied"] ? this.props.dataStore.ui.filter.Filter.AppealStatus["@Denied"] : '';
            From = this.getData(this.props.dataStore.ui.filter.Filter?.AppealStatus, "@From");
            To = this.getData(this.props.dataStore.ui.filter.Filter?.AppealStatus, "@To");
        }

        return {
            '@Created': Created,
            '@Succeeded': Succeeded,
            '@Filed': Filed,
            '@Denied': Denied,
            '@From': From,
            '@To': To,
        };
    }

    public onAppealsDateChange(e: any, fromField: boolean) {
        var data: string = e ?? '';
        var newData = this.getAppealsData();

        if (fromField && newData['@From'] === data) return;
        if (!fromField && newData['@To'] === data) return;


        if (fromField)
            newData['@From'] = data;
        else
            newData['@To'] = data;


        this.props.action.ui.selectAppeals(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newData,
                }
            });
    }


    public onSelectAppeals(e: any) {

        var Created, Succeeded, Filed, Denied, From, To;
        if (!this.props.dataStore.ui.filter.Filter.AppealStatus) {
            Created = Succeeded = Filed = Denied = From = To = '';
        } else {
            Created = this.props.dataStore.ui.filter.Filter.AppealStatus["@Created"] ? this.props.dataStore.ui.filter.Filter.AppealStatus["@Created"] : '';
            Succeeded = this.props.dataStore.ui.filter.Filter.AppealStatus["@Succeeded"] ? this.props.dataStore.ui.filter.Filter.AppealStatus["@Succeeded"] : '';
            Filed = this.props.dataStore.ui.filter.Filter.AppealStatus["@Filed"] ? this.props.dataStore.ui.filter.Filter.AppealStatus["@Filed"] : '';
            Denied = this.props.dataStore.ui.filter.Filter.AppealStatus["@Denied"] ? this.props.dataStore.ui.filter.Filter.AppealStatus["@Denied"] : '';
            From = this.getData(this.props.dataStore.ui.filter.Filter?.AppealStatus, "@From");
            To = this.getData(this.props.dataStore.ui.filter.Filter?.AppealStatus, "@To");
        }

        switch (e) {
            case 'Created':
                if (Created === "") {
                    Created = 'C';
                } else Created = ""
                break;
            case 'Succeeded':
                if (Succeeded === "") {
                    Succeeded = 'S';
                } else Succeeded = ""
                break;
            case 'Filed':
                if (Filed === "") {
                    Filed = 'P';
                } else Filed = ""
                break;
            case 'Denied':
                if (Denied === "") {
                    Denied = 'D';
                } else Denied = ""
                break;
        }


        if (Denied != "" || Filed != "" || Succeeded != "" || Created != "") {
            if (From == "")
                From = "00/30/0000";
            if (To == "")
                To = "00/00/0000";
        }

        var newAppeals = {
            '@Created': Created,
            '@Succeeded': Succeeded,
            '@Filed': Filed,
            '@Denied': Denied,
            '@From': From,
            '@To': To,
        }

        this.props.action.ui.selectAppeals(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newAppeals,
                }
            });
    }

    /////////////////////////////////////
    //  CLAIM STATE METHODS
    /////////////////////////////////////

    // to replace the need for :  if (!document.AdvancedFilter.ClaimState[3].checked)
    public isClaimStateChecked(val: number) {
        var currentClaimState = this.getData(this.props.dataStore.ui.filter?.Filter?.ClaimState, "@ID");


        if (!currentClaimState) return false;

        var strArr = currentClaimState.split(',');

        if (val == 0)
            return (strArr.indexOf('1') > -1 || strArr.indexOf('-1') > -1);  // unrel
        if (val == 1)
            return (strArr.indexOf('3') > -1 || strArr.indexOf('-3') > -1);  // rel
        if (val == 2) {
            if (this.process_lowercase == 'deleted' && this.isDeletedFilter)
                return true;
            else
                return (strArr.indexOf('-1') > -1 || strArr.indexOf('-3') > -1 || strArr.indexOf('-11') > -1);  // rel
        }
        if (val == 3)
            return (strArr.indexOf('11') > -1 || strArr.indexOf('-11') > -1);  // rel
        return false;
    }



    /// setClaimStateSwitch - turns the Claim State radio buttons to checkboxes
    public setClaimStateSwitch(val: boolean) {
        this.props.action.ui.setClaimStateSwitch(val);
    }

    public isUnreleasedChecked(): boolean {
        var cs = this.getData(this.props.dataStore.ui.filter.Filter.ClaimState, "@ID");
        var strArr = cs.split(',');
        return (strArr.indexOf('1') > -1 || strArr.indexOf('-1') > -1);
    }
    public isReleasedChecked(): boolean {
        var cs = this.getData(this.props.dataStore.ui.filter.Filter.ClaimState, "@ID");
        var strArr = cs.split(',');
        return (strArr.indexOf('3') > -1 || strArr.indexOf('-3') > -1);
    }
    public isStateOnlyChecked(): boolean {
        var cs = this.getData(this.props.dataStore.ui.filter.Filter.ClaimState, "@ID");
        var strArr = cs.split(',');
        return (strArr.indexOf('11') > -1 || strArr.indexOf('-11') > -1);
    }
    public isDeletedChecked(): boolean {
        var cs = this.getData(this.props.dataStore.ui.filter.Filter.ClaimState, "@ID");
        return cs.indexOf('-') > -1;
    }


    public setClaimState(type: string, val: boolean) {
        this.props.action.ui.selectSpecificClaimState(type, val, this.process_lowercase);
    }

    // note this does not handle not specifying all three currently
    public clickOnClaimState(unrel: boolean, rel: boolean, del: boolean) {

        this.props.action.ui.clickOnClaimState(unrel, rel, del, this.process_lowercase);
    }



    public onSelectClaimState(e: any) {

        this.props.action.ui.selectClaimState({ api: this.props.dataStore.api.data, uiData: { value: e, text: this.process_lowercase, index: -1 } });
    }

    /////////////////  END CLAIM STATE METHODS


    public isAcceptedChecked() {
        return (this.getData(this.props.dataStore.ui.filter.Filter.ClaimStatus, "@Accepted") === "A" ||
            this.getData(this.props.dataStore.ui.filter.Filter.ClaimStatus, "@All").length > 0);
    }
    public isWarningChecked() {
        return (this.getData(this.props.dataStore.ui.filter.Filter.ClaimStatus, "@Warning") === "W" ||
            this.getData(this.props.dataStore.ui.filter.Filter.ClaimStatus, "@All").length > 0);
    }
    public isRejectedChecked() {
        return (this.getData(this.props.dataStore.ui.filter.Filter.ClaimStatus, "@Rejected") === "R" ||
            this.getData(this.props.dataStore.ui.filter.Filter.ClaimStatus, "@All").length > 0);
    }

    public onSelectClaimStatus(e: any) {
        this.props.action.ui.selectClaimStatus(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: e,
                }
            });
    }

    public onSelectBillingOption(e: any) {

        var primary, rebill, secondary, secondaryFromRemit, tertiary, tertiaryFromRemit, All;

        if (!this.props.dataStore.ui.filter.Filter.BillingOption) {
            primary = rebill = secondary = secondaryFromRemit = tertiary = tertiaryFromRemit = All = '';
        } else {
            primary = this.props.dataStore.ui.filter.Filter.BillingOption["@Primary"] ? this.props.dataStore.ui.filter.Filter.BillingOption["@Primary"] : '';
            rebill = this.props.dataStore.ui.filter.Filter.BillingOption["@Rebill"] ? this.props.dataStore.ui.filter.Filter.BillingOption["@Rebill"] : '';
            secondary = this.props.dataStore.ui.filter.Filter.BillingOption["@Secondary"] ? this.props.dataStore.ui.filter.Filter.BillingOption["@Secondary"] : '';
            secondaryFromRemit = this.props.dataStore.ui.filter.Filter.BillingOption["@SecondaryFromRemit"] ? this.props.dataStore.ui.filter.Filter.BillingOption["@SecondaryFromRemit"] : '';
            tertiary = this.props.dataStore.ui.filter.Filter.BillingOption["@Tertiary"] ? this.props.dataStore.ui.filter.Filter.BillingOption["@Tertiary"] : '';
            tertiaryFromRemit = this.props.dataStore.ui.filter.Filter.BillingOption["@TertiaryFromRemit"] ? this.props.dataStore.ui.filter.Filter.BillingOption["@TertiaryFromRemit"] : '';
            All = this.props.dataStore.ui.filter.Filter.BillingOption["@All"] ? this.props.dataStore.ui.filter.Filter.BillingOption["@All"] : '';
        }
        switch (e) {
            case 'Primary':
                if (All === "true") {
                    primary = '';
                    rebill = 'R';
                    secondary = 'B';
                    secondaryFromRemit = 'C';
                    tertiary = 'D';
                    tertiaryFromRemit = 'E';
                    All = '';
                } else {
                    if (primary === '') {
                        primary = 'A'
                    } else primary = '';
                }
                break;
            case 'Rebill':
                if (All === "true") {
                    primary = 'A';
                    rebill = '';
                    secondary = 'B';
                    secondaryFromRemit = 'C';
                    tertiary = 'D';
                    tertiaryFromRemit = 'E';
                    All = '';
                } else {
                    if (rebill === '') {
                        rebill = 'R'
                    } else rebill = '';
                }
                break;
            case 'Secondary':
                if (All === "true") {
                    primary = 'A';
                    rebill = 'R';
                    secondary = '';
                    secondaryFromRemit = 'C';
                    tertiary = 'D';
                    tertiaryFromRemit = 'E';
                    All = '';
                } else {
                    if (secondary === '') {
                        secondary = 'B'
                    } else secondary = '';
                }
                break;
            case 'SecondaryFromRemit':
                if (All === "true") {
                    primary = 'A';
                    rebill = 'R';
                    secondary = 'B';
                    secondaryFromRemit = '';
                    tertiary = 'D';
                    tertiaryFromRemit = 'E';
                    All = '';
                } else {
                    if (secondaryFromRemit === '') {
                        secondaryFromRemit = 'C'
                    } else secondaryFromRemit = '';
                }
                break;
            case 'Tertiary':
                if (All === "true") {
                    primary = 'A';
                    rebill = 'R';
                    secondary = 'B';
                    secondaryFromRemit = 'C';
                    tertiary = '';
                    tertiaryFromRemit = 'E';
                    All = '';
                } else {
                    if (tertiary === '') {
                        tertiary = 'D'
                    } else tertiary = '';
                }
                break;
            case 'TertiaryFromRemit':
                if (All === "true") {
                    primary = 'A';
                    rebill = 'R';
                    secondary = 'B';
                    secondaryFromRemit = 'C';
                    tertiary = 'D';
                    tertiaryFromRemit = '';
                    All = '';
                } else {
                    if (tertiaryFromRemit === '') {
                        tertiaryFromRemit = 'E'
                    } else tertiaryFromRemit = '';
                }
                break;
        }

        if (primary === 'A' && rebill === 'R' && secondary === 'B' && secondaryFromRemit === 'C' && tertiary === 'D' && tertiaryFromRemit === 'D') {
            primary = rebill = secondary = secondaryFromRemit = tertiary = tertiaryFromRemit = '';
            All = "true"
        }

        var newBillingOption = {
            '@Primary': primary,
            '@Rebill': rebill,
            '@Secondary': secondary,
            '@SecondaryFromRemit': secondaryFromRemit,
            '@Tertiary': tertiary,
            '@TertiaryFromRemit': tertiaryFromRemit,
            '@All': All,
        }

        this.props.action.ui.selectBillingOption(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newBillingOption,
                }
            });
    }



    public getCurrentStatusCategoryData(): any {
        var notFound, error, pending, finalPaid, finalDenied, finalOther, ackReceipt, reqInfo, accepted1, ackReturned, noStatusCategoryCode, All, from, to, type, statusType;
        if (!this.props.dataStore.ui.filter.Filter.StatusCategory) {
            notFound = error = pending = finalPaid = finalDenied = finalOther = ackReceipt = reqInfo = accepted1 = ackReturned = noStatusCategoryCode = All = from = to = type = statusType = '';
        } else {
            notFound = this.props.dataStore.ui.filter.Filter.StatusCategory["@NotFound"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@NotFound"] : '';
            error = this.props.dataStore.ui.filter.Filter.StatusCategory["@Error"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@Error"] : '';
            pending = this.props.dataStore.ui.filter.Filter.StatusCategory["@Pending"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@Pending"] : '';
            finalPaid = this.props.dataStore.ui.filter.Filter.StatusCategory["@FinalPaid"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@FinalPaid"] : '';
            finalDenied = this.props.dataStore.ui.filter.Filter.StatusCategory["@FinalDenied"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@FinalDenied"] : '';
            finalOther = this.props.dataStore.ui.filter.Filter.StatusCategory["@FinalOther"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@FinalOther"] : '';
            ackReceipt = this.props.dataStore.ui.filter.Filter.StatusCategory["@Acknowledged"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@Acknowledged"] : '';
            reqInfo = this.props.dataStore.ui.filter.Filter.StatusCategory["@ReqInfo"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@ReqInfo"] : '';
            accepted1 = this.props.dataStore.ui.filter.Filter.StatusCategory["@Processing"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@Processing"] : '';
            ackReturned = this.props.dataStore.ui.filter.Filter.StatusCategory["@Returned"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@Returned"] : '';
            noStatusCategoryCode = this.props.dataStore.ui.filter.Filter.StatusCategory["@NoStatus"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@NoStatus"] : '';
            All = this.props.dataStore.ui.filter.Filter.StatusCategory["@All"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@All"] : '';

            from = this.getData(this.props.dataStore.ui.filter.Filter.StatusCategory, "@From");
            to = this.getData(this.props.dataStore.ui.filter.Filter.StatusCategory, "@To");

            type = this.getData(this.props.dataStore.ui.filter.Filter.StatusCategory, "@Type");
            statusType = this.getData(this.props.dataStore.ui.filter.Filter.StatusCategory, "@StatusType");
        }

        return {
            '@NotFound': notFound,
            '@Error': error,
            '@Pending': pending,
            "@FinalPaid": finalPaid,
            "@FinalDenied": finalDenied,
            "@FinalOther": finalOther,
            "@Acknowledged": ackReceipt, // Acknowledged
            "@ReqInfo": reqInfo,
            "@Processing": accepted1,  // Processing
            "@Returned": ackReturned,  // Returned
            "@NoStatus": noStatusCategoryCode,  // NoStatus
            '@All': All,
            '@Type': type,
            '@From': from,
            '@To': to,
            '@StatusType': statusType,
        };
    }

    public onStatusCategoryDateChange(e: any, fromField: boolean) {
        var data: string = e ?? '';
        var newPayerStatusCategory = this.getCurrentStatusCategoryData();

        if (fromField && newPayerStatusCategory['@From'] === data) return;
        if (!fromField && newPayerStatusCategory['@To'] === data) return;

        if (fromField)
            newPayerStatusCategory['@From'] = data;
        else
            newPayerStatusCategory['@To'] = data;


        this.props.action.ui.selectPayerStatusCategory(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newPayerStatusCategory,
                }
            });
    }

    public onStatusCategoryTypeChange(type: string) {
        var newPayerStatusCategory = this.getCurrentStatusCategoryData();

        if (newPayerStatusCategory['@Type'] === type) return;

        newPayerStatusCategory['@Type'] = type;

        this.props.action.ui.selectPayerStatusCategory(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newPayerStatusCategory,
                }
            });
    }

    public onStatusCategoryStatusTypeChange(type: string) {
        var newPayerStatusCategory = this.getCurrentStatusCategoryData();

        var arr = newPayerStatusCategory['@StatusType'] ?? "";
        var acodes = arr.split(",");
        var has277, hasCH, hasPortal = false;
        for (var n = 0; n < acodes.length; n++) {
            if (acodes[n] == "Status277") has277 = true;
            if (acodes[n] == "StatusCH") hasCH = true;
            if (acodes[n] == "StatusPortal") hasPortal = true;
        }
        if (!has277 && !hasCH && !hasPortal) has277 = true;

        if (type == "Status277") has277 = !has277;
        if (type == "StatusCH") hasCH = !hasCH;
        if (type == "StatusPortal") hasPortal = !hasPortal;
        if (!has277 && !hasCH && !hasPortal) has277 = true;

        var sCode;
        sCode = "";
        if (has277) {
            sCode = sCode + "," + "Status277";
        }
        if (hasCH) {
            sCode = sCode + "," + "StatusCH";
        }
        if (hasPortal) {
            sCode = sCode + "," + "StatusPortal";
        }
        if (sCode.substring(0, 1) == ",") {
            sCode = sCode.substring(1);
        }

        newPayerStatusCategory['@StatusType'] = sCode;

        this.props.action.ui.selectPayerStatusCategory(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newPayerStatusCategory,
                }
            });
    }


    public onSelectStatusCategory(e: any) {
        var notFound, error, pending, finalPaid, finalDenied, finalOther, ackReceipt, reqInfo, accepted1, ackReturned, noStatusCategoryCode, All, from, to, type, statusType;
        if (!this.props.dataStore.ui.filter.Filter.StatusCategory) {
            notFound = error = pending = finalPaid = finalDenied = finalOther = ackReceipt = reqInfo = accepted1 = ackReturned = noStatusCategoryCode = All = from = to = type = statusType = '';
        } else {
            notFound = this.props.dataStore.ui.filter.Filter.StatusCategory["@NotFound"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@NotFound"] : '';
            error = this.props.dataStore.ui.filter.Filter.StatusCategory["@Error"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@Error"] : '';
            pending = this.props.dataStore.ui.filter.Filter.StatusCategory["@Pending"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@Pending"] : '';
            finalPaid = this.props.dataStore.ui.filter.Filter.StatusCategory["@FinalPaid"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@FinalPaid"] : '';
            finalDenied = this.props.dataStore.ui.filter.Filter.StatusCategory["@FinalDenied"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@FinalDenied"] : '';
            finalOther = this.props.dataStore.ui.filter.Filter.StatusCategory["@FinalOther"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@FinalOther"] : '';
            ackReceipt = this.props.dataStore.ui.filter.Filter.StatusCategory["@Acknowledged"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@Acknowledged"] : '';
            reqInfo = this.props.dataStore.ui.filter.Filter.StatusCategory["@ReqInfo"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@ReqInfo"] : '';
            accepted1 = this.props.dataStore.ui.filter.Filter.StatusCategory["@Processing"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@Processing"] : '';
            ackReturned = this.props.dataStore.ui.filter.Filter.StatusCategory["@Returned"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@Returned"] : '';
            noStatusCategoryCode = this.props.dataStore.ui.filter.Filter.StatusCategory["@NoStatus"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@NoStatus"] : '';
            All = this.props.dataStore.ui.filter.Filter.StatusCategory["@All"] ? this.props.dataStore.ui.filter.Filter.StatusCategory["@All"] : '';

            from = this.getData(this.props.dataStore.ui.filter.Filter.StatusCategory, "@From");
            to = this.getData(this.props.dataStore.ui.filter.Filter.StatusCategory, "@To");

            type = this.getData(this.props.dataStore.ui.filter.Filter.StatusCategory, "@Type");
            statusType = this.getData(this.props.dataStore.ui.filter.Filter.StatusCategory, "@StatusType");
        }

        switch (e) {
            case 'NotFound':
                if (All === "true") {
                    notFound = '';
                    error = 'E';
                    pending = 'P';
                    finalPaid = 'F';
                    finalDenied = 'D';
                    finalOther = 'O';
                    ackReceipt = 'K';
                    reqInfo = 'I';
                    accepted1 = 'A';
                    ackReturned = 'R';
                    noStatusCategoryCode = 'S';
                    All = '';
                } else {
                    if (notFound === '') {
                        notFound = 'N'
                    } else notFound = '';
                }
                break;
            case 'Error':
                if (All === "true") {
                    notFound = 'N';
                    error = '';
                    pending = 'P';
                    finalPaid = 'F';
                    finalDenied = 'D';
                    finalOther = 'O';
                    ackReceipt = 'K';
                    reqInfo = 'I';
                    accepted1 = 'A';
                    ackReturned = 'R';
                    noStatusCategoryCode = 'S';
                    All = '';
                } else {
                    if (error === '') {
                        error = 'E'
                    } else error = '';
                }
                break;
            case 'Pending':
                if (All === "true") {
                    notFound = 'N';
                    error = 'E';
                    pending = '';
                    finalPaid = 'F';
                    finalDenied = 'D';
                    finalOther = 'O';
                    ackReceipt = 'K';
                    reqInfo = 'I';
                    accepted1 = 'A';
                    ackReturned = 'R';
                    noStatusCategoryCode = 'S';
                    All = '';
                } else {
                    if (pending === '') {
                        pending = 'P'
                    } else pending = '';
                }
                break;
            case 'FinalPaid':
                if (All === "true") {
                    notFound = 'N';
                    error = 'E';
                    pending = 'P';
                    finalPaid = '';
                    finalDenied = 'D';
                    finalOther = 'O';
                    ackReceipt = 'K';
                    reqInfo = 'I';
                    accepted1 = 'A';
                    ackReturned = 'R';
                    noStatusCategoryCode = 'S';
                    All = '';
                } else {
                    if (finalPaid === '') {
                        finalPaid = 'F'
                    } else finalPaid = '';
                }
                break;
            case 'FinalDenied':
                if (All === "true") {
                    notFound = 'N';
                    error = 'E';
                    pending = 'P';
                    finalPaid = 'F';
                    finalDenied = '';
                    finalOther = 'O';
                    ackReceipt = 'K';
                    reqInfo = 'I';
                    accepted1 = 'A';
                    ackReturned = 'R';
                    noStatusCategoryCode = 'S';
                    All = '';
                } else {
                    if (finalDenied === '') {
                        finalDenied = 'D'
                    } else finalDenied = '';
                }
                break;
            case 'FinalOther':
                if (All === "true") {
                    notFound = 'N';
                    error = 'E';
                    pending = 'P';
                    finalPaid = 'F';
                    finalDenied = 'D';
                    finalOther = '';
                    ackReceipt = 'K';
                    reqInfo = 'I';
                    accepted1 = 'A';
                    ackReturned = 'R';
                    noStatusCategoryCode = 'S';
                    All = '';
                } else {
                    if (finalOther === '') {
                        finalOther = 'O'
                    } else finalOther = '';
                }
                break;
            case 'Acknowledged':
                if (All === "true") {
                    notFound = 'N';
                    error = 'E';
                    pending = 'P';
                    finalPaid = 'F';
                    finalDenied = 'D';
                    finalOther = 'O';
                    ackReceipt = '';
                    reqInfo = 'I';
                    accepted1 = 'A';
                    ackReturned = 'R';
                    noStatusCategoryCode = 'S';
                    All = '';
                } else {
                    if (ackReceipt === '') {
                        ackReceipt = 'K'
                    } else ackReceipt = '';
                }
                break;
            case 'ReqInfo':
                if (All === "true") {
                    notFound = 'N';
                    error = 'E';
                    pending = 'P';
                    finalPaid = 'F';
                    finalDenied = 'D';
                    finalOther = 'O';
                    ackReceipt = 'K';
                    reqInfo = '';
                    accepted1 = 'A';
                    ackReturned = 'R';
                    noStatusCategoryCode = 'S';
                    All = '';
                } else {
                    if (reqInfo === '') {
                        reqInfo = 'I'
                    } else reqInfo = '';
                }
                break;
            case 'Processing':
                if (All === "true") {
                    notFound = 'N';
                    error = 'E';
                    pending = 'P';
                    finalPaid = 'F';
                    finalDenied = 'D';
                    finalOther = 'O';
                    ackReceipt = 'K';
                    reqInfo = 'I';
                    accepted1 = '';
                    ackReturned = 'R';
                    noStatusCategoryCode = 'S';
                    All = '';
                } else {
                    if (accepted1 === '') {
                        accepted1 = 'A'
                    } else accepted1 = '';
                }
                break;
            case 'Returned':
                if (All === "true") {
                    notFound = 'N';
                    error = 'E';
                    pending = 'P';
                    finalPaid = 'F';
                    finalDenied = 'D';
                    finalOther = 'O';
                    ackReceipt = 'K';
                    reqInfo = 'I';
                    accepted1 = 'A';
                    ackReturned = '';
                    noStatusCategoryCode = 'S';
                    All = '';
                } else {
                    if (ackReturned === '') {
                        ackReturned = 'R'
                    } else ackReturned = '';
                }
                break;
            case 'NoStatus':
                if (All === "true") {
                    notFound = 'N';
                    error = 'E';
                    pending = 'P';
                    finalPaid = 'F';
                    finalDenied = 'D';
                    finalOther = 'O';
                    ackReceipt = 'K';
                    reqInfo = 'I';
                    accepted1 = 'A';
                    ackReturned = 'R';
                    noStatusCategoryCode = '';
                    All = '';
                } else {
                    if (noStatusCategoryCode === '') {
                        noStatusCategoryCode = 'S'
                    } else noStatusCategoryCode = '';
                }
                break;
        }

        if (notFound === 'N' && error === 'E' && pending === 'P' && finalPaid === 'F' && finalDenied === 'D' && finalOther === 'O'
            && ackReceipt === 'K' && reqInfo === 'I' && accepted1 === 'A' && ackReturned === 'R' && noStatusCategoryCode === 'S') {
            notFound = error = pending = finalPaid = finalDenied = finalOther = ackReceipt = reqInfo = accepted1 = ackReturned = noStatusCategoryCode = '';
            All = "true"
        }

        var newPayerStatusCategory = {
            '@NotFound': notFound,
            '@Error': error,
            '@Pending': pending,
            "@FinalPaid": finalPaid,
            "@FinalDenied": finalDenied,
            "@FinalOther": finalOther,
            "@Acknowledged": ackReceipt, // Acknowledged
            "@ReqInfo": reqInfo,
            "@Processing": accepted1,  // Processing
            "@Returned": ackReturned,  // Returned
            "@NoStatus": noStatusCategoryCode,  // NoStatus
            '@All': All,
            '@Type': type,
            '@From': from,
            '@To': to,
            '@StatusType': statusType,
        };


        this.props.action.ui.selectPayerStatusCategory(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newPayerStatusCategory,
                }
            });
    }




    public onSelectEligibilityStatusCategory(e: any) {

        var duplicateId, missingInfo, insMatch, serviceDate, provider, noRequestInitiated, requestPending, requestFailed, All;
        if (!this.props.dataStore.ui.filter.Filter.EligibilityCategory) {
            duplicateId = missingInfo = insMatch = serviceDate = provider = noRequestInitiated = requestPending = requestFailed = All = '';
        } else {
            duplicateId = this.getData(this.props.dataStore.ui.filter.Filter?.EligibilityCategory, "@DuplicateId");
            missingInfo = this.getData(this.props.dataStore.ui.filter.Filter?.EligibilityCategory, "@InvalidOrMissingInfo");
            insMatch = this.getData(this.props.dataStore.ui.filter.Filter?.EligibilityCategory, "@PatInsMatch");
            serviceDate = this.getData(this.props.dataStore.ui.filter.Filter?.EligibilityCategory, "@ServiceDate");
            provider = this.getData(this.props.dataStore.ui.filter.Filter?.EligibilityCategory, "@Provider");
            noRequestInitiated = this.getData(this.props.dataStore.ui.filter.Filter?.EligibilityCategory, "@NeverRequested");
            requestPending = this.getData(this.props.dataStore.ui.filter.Filter?.EligibilityCategory, "@RequestPending");
            requestFailed = this.getData(this.props.dataStore.ui.filter.Filter?.EligibilityCategory, "@RequestFailed");
            All = this.getData(this.props.dataStore.ui.filter.Filter?.EligibilityCategory, "@All");

        }
        switch (e) {
            case 'DuplicateId':
                if (All === "true") {
                    duplicateId = '';
                    missingInfo = 'B';
                    insMatch = 'C';
                    serviceDate = 'D';
                    provider = 'E';
                    noRequestInitiated = 'F';
                    requestPending = 'G';
                    requestFailed = 'H';
                    All = '';
                } else {
                    if (duplicateId === '') {
                        duplicateId = 'A'
                    } else duplicateId = '';
                }
                break;
            case 'MissingInfo':
                if (All === "true") {
                    duplicateId = 'A';
                    missingInfo = '';
                    insMatch = 'C';
                    serviceDate = 'D';
                    provider = 'E';
                    noRequestInitiated = 'F';
                    requestPending = 'G';
                    requestFailed = 'H';
                    All = '';
                } else {
                    if (missingInfo === '') {
                        missingInfo = 'B'
                    } else missingInfo = '';
                }
                break;
            case 'InsMatch':
                if (All === "true") {
                    duplicateId = 'A';
                    missingInfo = 'B';
                    insMatch = '';
                    serviceDate = 'D';
                    provider = 'E';
                    noRequestInitiated = 'F';
                    requestPending = 'G';
                    requestFailed = 'H';
                    All = '';
                } else {
                    if (insMatch === '') {
                        insMatch = 'C'
                    } else insMatch = '';
                }
                break;
            case 'ServiceDate':
                if (All === "true") {
                    duplicateId = 'A';
                    missingInfo = 'B';
                    insMatch = 'C';
                    serviceDate = '';
                    provider = 'E';
                    noRequestInitiated = 'F';
                    requestPending = 'G';
                    requestFailed = 'H';
                    All = '';
                } else {
                    if (serviceDate === '') {
                        serviceDate = 'D'
                    } else serviceDate = '';
                }
                break;
            case 'Provider':
                if (All === "true") {
                    duplicateId = 'A';
                    missingInfo = 'B';
                    insMatch = 'C';
                    serviceDate = 'D';
                    provider = '';
                    noRequestInitiated = 'F';
                    requestPending = 'G';
                    requestFailed = 'H';
                    All = '';
                } else {
                    if (provider === '') {
                        provider = 'E'
                    } else provider = '';
                }
                break;
            case 'NoRequestInitiated':
                if (All === "true") {
                    duplicateId = 'A';
                    missingInfo = 'B';
                    insMatch = 'C';
                    serviceDate = 'D';
                    provider = 'E';
                    noRequestInitiated = '';
                    requestPending = 'G';
                    requestFailed = 'H';
                    All = '';
                } else {
                    if (noRequestInitiated === '') {
                        noRequestInitiated = 'F'
                    } else noRequestInitiated = '';
                }
                break;
            case 'RequestPending':
                if (All === "true") {
                    duplicateId = 'A';
                    missingInfo = 'B';
                    insMatch = 'C';
                    serviceDate = 'D';
                    provider = 'E';
                    noRequestInitiated = 'F';
                    requestPending = '';
                    requestFailed = 'H';
                    All = '';
                } else {
                    if (requestPending === '') {
                        requestPending = 'G'
                    } else requestPending = '';
                }
                break;
            case 'RequestFailed':
                if (All === "true") {
                    duplicateId = 'A';
                    missingInfo = 'B';
                    insMatch = 'C';
                    serviceDate = 'D';
                    provider = 'E';
                    noRequestInitiated = 'F';
                    requestPending = 'G';
                    requestFailed = '';
                    All = '';
                } else {
                    if (requestFailed === '') {
                        requestFailed = 'H'
                    } else requestFailed = '';
                }
                break;
        }

        if (duplicateId === 'A' && missingInfo === 'B' && insMatch === 'C' && serviceDate === 'D' && provider === 'E'
            && noRequestInitiated === 'F' && requestPending === 'G' && requestFailed === 'H') {
            duplicateId = missingInfo = insMatch = serviceDate = provider = noRequestInitiated = requestPending = requestFailed = '';
            All = "true"
        }

        var newEligibilityStatusCategory = {
            '@DuplicateId': duplicateId,
            '@InvalidOrMissingInfo': missingInfo,
            '@PatInsMatch': insMatch,
            '@ServiceDate': serviceDate,
            '@Provider': provider,
            '@NeverRequested': noRequestInitiated,
            '@RequestPending': requestPending,
            '@RequestFailed': requestFailed,
            '@All': All,
        }
        this.props.action.ui.selectEligibilityStatusCategory(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newEligibilityStatusCategory,
                }
            });
    }

    public clearAiiStatus() {
        var newAiiStatus = {
            '@NotQueried': '',
            '@AiiPended': '',
            '@AiiDenied': '',
            '@AiiPaid': '',
            '@AiiException': '',
            '@All': ''
        }

        this.props.action.ui.selectAiiStatus(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newAiiStatus,
                }
            });
    }
    public onSelectAiiStatus(e: any) {

        var NotQueried, AiiPended, AiiDenied, AiiPaid, AiiException, All;

        if (!this.props.dataStore.ui.filter.Filter.AiiStatus) {
            NotQueried = AiiPended = AiiDenied = AiiPaid = AiiException = All = '';
        } else {
            NotQueried = this.props.dataStore.ui.filter.Filter.AiiStatus["@NotQueried"] ? this.props.dataStore.ui.filter.Filter.AiiStatus["@NotQueried"] : '';
            AiiPended = this.props.dataStore.ui.filter.Filter.AiiStatus["@AiiPended"] ? this.props.dataStore.ui.filter.Filter.AiiStatus["@AiiPended"] : '';
            AiiDenied = this.props.dataStore.ui.filter.Filter.AiiStatus["@AiiDenied"] ? this.props.dataStore.ui.filter.Filter.AiiStatus["@AiiDenied"] : '';
            AiiPaid = this.props.dataStore.ui.filter.Filter.AiiStatus["@AiiPaid"] ? this.props.dataStore.ui.filter.Filter.AiiStatus["@AiiPaid"] : '';
            AiiException = this.props.dataStore.ui.filter.Filter.AiiStatus["@AiiException"] ? this.props.dataStore.ui.filter.Filter.AiiStatus["@AiiException"] : '';
            All = this.props.dataStore.ui.filter.Filter.AiiStatus["@All"] ? this.props.dataStore.ui.filter.Filter.AiiStatus["@All"] : '';
        }
        switch (e) {
            case 'NotQueried':
                if (All === "true") {
                    NotQueried = '';
                    AiiPended = '3';
                    AiiDenied = '2';
                    AiiPaid = '5';
                    AiiException = '4';
                    All = '';
                } else {
                    if (NotQueried === '') {
                        NotQueried = '1'
                    } else NotQueried = '';
                }
                break;
            case 'AiiPended':
                if (All === "true") {
                    NotQueried = '1';
                    AiiPended = '';
                    AiiDenied = '2';
                    AiiPaid = '5';
                    AiiException = '4';
                    All = '';
                } else {
                    if (AiiPended === '') {
                        AiiPended = '3'
                    } else AiiPended = '';
                }
                break;
            case 'AiiDenied':
                if (All === "true") {
                    NotQueried = '1';
                    AiiPended = '3';
                    AiiDenied = '';
                    AiiPaid = '5';
                    AiiException = '4';
                    All = '';
                } else {
                    if (AiiDenied === '') {
                        AiiDenied = '2'
                    } else AiiDenied = '';
                }
                break;
            case 'AiiPaid':
                if (All === "true") {
                    NotQueried = '1';
                    AiiPended = '3';
                    AiiDenied = '2';
                    AiiPaid = '';
                    AiiException = '4';
                    All = '';
                } else {
                    if (AiiPaid === '') {
                        AiiPaid = '5'
                    } else AiiPaid = '';
                }
                break;
            case 'AiiException':
                if (All === "true") {
                    NotQueried = '1';
                    AiiPended = '3';
                    AiiDenied = '2';
                    AiiPaid = '5';
                    AiiException = '';
                    All = '';
                } else {
                    if (AiiException === '') {
                        AiiException = '4'
                    } else AiiException = '';
                }
                break;
        }

        if (NotQueried === '1' && AiiPended === '3' && AiiDenied === '2' && AiiPaid === '5' && AiiException === '4') {
            NotQueried = AiiPended = AiiDenied = AiiPaid = AiiException = '';
            All = "true"
        }

        var newAiiStatus = {
            '@NotQueried': NotQueried,
            '@AiiPended': AiiPended,
            '@AiiDenied': AiiDenied,
            '@AiiPaid': AiiPaid,
            '@AiiException': AiiException,
            '@All': All
        }

        this.props.action.ui.selectAiiStatus(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newAiiStatus,
                }
            });
    }

    public onSelectServiceType(e: any) {

        var Inpatient, Outpatient, All;
        if (!this.props.dataStore.ui.filter.Filter.ServiceType) {
            Inpatient = Outpatient = All = '';
        } else {
            Inpatient = this.props.dataStore.ui.filter.Filter.ServiceType["@Inpatient"] ? this.props.dataStore.ui.filter.Filter!.ServiceType["@Inpatient"] : '';
            Outpatient = this.props.dataStore.ui.filter.Filter.ServiceType["@Outpatient"] ? this.props.dataStore.ui.filter.Filter!.ServiceType["@Outpatient"] : '';
            All = this.props.dataStore.ui.filter.Filter.ServiceType["@All"] ? this.props.dataStore.ui.filter.Filter!.ServiceType["@All"] : '';
        }

        switch (e) {
            case 'Inpatient':
                if (All === "true") {
                    Inpatient = '';
                    Outpatient = 'O';
                    All = '';
                } else {
                    if (Inpatient === '') {
                        Inpatient = 'I'
                    } else Inpatient = '';
                }
                break;
            case 'Outpatient':
                if (All === "true") {
                    Outpatient = '';
                    Inpatient = 'I';
                    All = '';
                } else {
                    if (Outpatient === '') {
                        Outpatient = 'O'
                    } else Outpatient = '';
                }
                break;

        }

        if (Inpatient === "I" && Outpatient === "O") {
            Inpatient = Outpatient = "";
            All = "true"
        }

        var newServiceType = {
            '@Inpatient': Inpatient, '@Outpatient': Outpatient, '@All': All
        }

        this.props.action.ui.selectServiceType(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newServiceType,
                }
            });
    }

    public setTransmitTypeOption(e: any, enable: boolean) {

        this.props.action.ui.selectTransmitType(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    id: e,
                    value: enable
                }
            });

    }

    public onSelectTransmitType(e: any) {
       
        this.props.action.ui.selectTransmitType(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    id: e,
                    value2: true
                }
            });
    }

    public onSelectClaimsWithHoldCodeYes(e: any) {

        var holdCodeYes = this.props.dataStore.ui.filter.Filter.ClaimsWithHoldCodeYes && this.props.dataStore.ui.filter.Filter.ClaimsWithHoldCodeYes["@ClaimsWithHoldCodeYes"] ? this.props.dataStore.ui.filter.Filter.ClaimsWithHoldCodeYes["@ClaimsWithHoldCodeYes"] : '';
        var holdCodeNo = this.props.dataStore.ui.filter.Filter.ClaimsWithHoldCodeNo && this.props.dataStore.ui.filter.Filter.ClaimsWithHoldCodeNo["@ClaimsWithHoldCodeNo"] ? this.props.dataStore.ui.filter.Filter.ClaimsWithHoldCodeNo["@ClaimsWithHoldCodeNo"] : '';
        var newClaimsWithHoldCode;
        var index = 0;

        switch (e) {
            case 'Yes':
                if (holdCodeYes === 'Y') {
                    holdCodeYes = '';
                } else {
                    holdCodeYes = 'Y';
                    if (holdCodeNo === 'Y') {
                        holdCodeNo = '';
                    }
                }
                index = 1;
                break;
            case 'No':
                if (holdCodeNo === 'Y') {
                    holdCodeNo = '';
                } else {
                    holdCodeNo = 'Y';
                    if (holdCodeYes === 'Y') {
                        holdCodeYes = '';
                    }
                }
                index = 2;
                break;
        }
        newClaimsWithHoldCode = { '@ClaimsWithHoldCodeYes': holdCodeYes, '@ClaimsWithHoldCodeNo': holdCodeNo }
        this.props.action.ui.selectClaimsWithHoldCode(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newClaimsWithHoldCode,
                    index: index
                }
            });

    }

    public onSelectPayerType(val: string) {

        var newPayerType = { '@Type': val }

        this.props.action.ui.selectPayerType(
            {
                api: this.props.dataStore.api.data,
                uiData: {
                    data: newPayerType,
                }
            });

    }

    public getSelectedPayerRecords() {

        // taken from function PopulatePayerList(strPayerType)

        let records: any[] = [];

        if (this.props.dataStore.ui.activeTab === 2) {
            records = this.getLookupListIntialItem(this.props.dataStore.api.data?.ListPopulation?.PayerStatusList, ["- All Payers -", ""])
        } else if (this.props.dataStore.ui.activeTab === 3) {
            var eligibilityRecords: any[] = [];
            records.push({ label: "- All Eligibility Payers -", value: "- All Eligibility Payers -" });
            records.push({ label: "- Only Active Eligibility Payers -", value: "- Only Active Eligibility Payers -" });
            records.push({ label: "- All Payers -", value: "- All Payers -" });
            eligibilityRecords = this.getLookupListIntialItem(this.props.dataStore.api.data?.ListPopulation?.EligibilityPayerList, null);
            if (eligibilityRecords.length > 0) {
                records.push.apply(records, eligibilityRecords);
            }
        } else {
            records = this.getLookupListIntialItem(this.props.dataStore.api.data?.ListPopulation?.PayerList, ["- All Payers -", ""])
        }

        return records;
    }



    public setDialogButtonState(val: string) {  // helper to hold this naming convention
        this.onChangeQuickStart(val);
    }
    public onChangeQuickStart(val: string) {
        this.props.action.ui.setDialogButtonState({ api: this.props.dataStore.api.data, uiData: { value: val } });
    }
    public disableDialogButtons(val: string[]) {
        this.props.action.ui.disableDialogButton({ api: this.props.dataStore.api.data, uiData: { value: val } });
    }
    public onClearAlert(e: any) {
        this.onAlert("");
    }
    public onClearAlertModeOn(e: any) {
        this.onAlertModeOn("");
    }
    public onAlert(msg: string) {
        this.props.action.ui.sendClaimFilterAlert(msg);
        this.setState({ disableBtn: false });

    }
    public onAlertModeOn(msg: string) {
        this.props.action.ui.sendClaimFilterAlertModeOn(msg);
        this.setState({ disableBtn: false });

    }
    public onDisplayClaimFilterError(msg: string) {
        this.props.action.ui.displayErrorMessage({ api: this.props.dataStore.api.data, uiData: { value: msg } });
    }

    public onClearClaimFilterErrorHandler() {
        this.goToHomePage();
    }

    public onClearClaimFilterErrorHandlerForNotFound() {
        this.props.action.ui.clearClaimFilterErrorHandlerForNotFound('', '');
    }

    public setBusy(val: boolean) {
        if (val == false && this.state.disableBtn === true)
            this.setState({ disableBtn: false });

        this.props.action.ui.setBusy({ api: this.props.dataStore.api.data, uiData: { value: val } });
    }
    ////////////////////////////
    ////  GENERAL METHODS
    ////////////////////////////
    public getSelectedItemsArray(e: React.ChangeEvent<HTMLSelectElement>) {
        var data: string[] = this.getSelectedOptions(e);

        let result: APICF_FilterSimpleID[] = [];

        data.forEach(ob => {
            if (ob.length > 0) {
                var item: APICF_FilterSimpleID = { '@ID': ob };
                result.push(item);
            }
        });

        return result;
    }

    public getSelectedItem(e: React.ChangeEvent<HTMLSelectElement>): APICF_FilterSimpleID {
        var data: string[] = this.getSelectedOptions(e);

        var item: APICF_FilterSimpleID = { '@ID': data[0] };

        return item;
    }

    public getSelectedOptions(e: React.ChangeEvent<HTMLSelectElement>) {
        if (!e || !e.target || !e.target.options || e.target.options.length === 0) return [];
        let result = [];

        for (let i = 0; i < e.target.options.length; i++) {
            if (e.target.options[i].selected)
                result.push(e.target.options[i].value);
        }
        return result;
    }

    public goToHomePage() {
        if (!!this.props.testMode) {
            // 		        todo test this
            this.props.history.push("/TestMode/UserHome.aspx");
        } else
            this.props.history.push("/LandingPage");
    }


    createPostDataFromProps(): any {
        return {
            filterId: this.props.dataStore.ui.filterId,
            filterName: this.props.dataStore.ui.filterName,
            title: this.props.title,
            process: this.props.process,
            execProcess: this.props.execProcess,
            execNode: this.props.execNode,
            execAction: this.props.execAction,
            execAttribs: this.props.execAttribs,
            parameter: this.props.parameter ? this.props.parameter : {},
            filterType: this.props.dataStore.ui.filter.Filter["@Type"],
            restrictionsExist: "",
            testMode: this.props.testMode,
        };
    }

    public onRefineSuccess(data: string, urlOverride: string) {
        let strResponse = data;
        let bContinue = true;
        //  console.log('onRefineSuccess: ' + data);  

        let postData = this.createPostDataFromProps();

        // user access restrictions should preface the responseText on a successful
        // filter, currently "RUA=[Y|N];"
        if (strResponse.substr(0, 4) === "RUA=") {
            postData.restrictionsExist = 'N';
            if (strResponse.substr(4, 1) === "Y") {
                postData.restrictionsExist = 'Y';
            }
            strResponse = strResponse.substr(6);
        }

        // in this event, we check the claim count and stop the processing (restting) if the count is 0 (zero)
        if (strResponse.substr(0, 7) === "CLMCNT=") {
            let nClaimCount = Number(strResponse.substr(7, strResponse.length - 8));
            if (nClaimCount <= 0) {
                bContinue = false;
            }

            strResponse = "";
        }
        if (this.process_lowercase == "viewx12claim") {
            var clientUrl = URLs.api + '/api/data/Claims/FileCountFromFilter';
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = () => {
                if (xhr.readyState == xhr.DONE) {
                    if (xhr.status == 200) {
                        var responseData = xhr.responseText;
                        if (responseData === "0") {

                            bContinue = false;
                            return;
                        }
                        bContinue = true;
                    } else {
                        console.warn(xhr.status + " response handling " + clientUrl);
                        bContinue = false;
                    }

                }
            };
            xhr.open('get', clientUrl, false);
            xhr.setRequestHeader('Authorization', getRawToken());
            xhr.send();
            /*
                       var count = assuranceServicesJQ().getClaimFileCountFromFilterAsync();
            
                        if (count == 0) {
                            bContinue = false;
                            this.onAlert( "The selected items did not return a claim file, please refine your filter");
                            SetApplicationState(true);
                            return;
             
             */

        }


        if (bContinue) {
            if (strResponse === "") {
                if (postData.filterId === "0") {
                    postData.filterName = '';
                }
                if (this.process_lowercase == "statereport") {
                    alert('todo implement logic here!');
                    return;
                    // document.UserFilter.Parameter.value = document.AdvancedFilter.UserFieldValue2.value;
                }

                // document.UserFilter.action = Url;
                var url = "/Claims/WorkingGroupDisplay";
                if (urlOverride == "CFI")  // don't inject url
                    url = "/CFI/Default";

                this.overrideUrl = '';
                this.props.history.push(url, postData);
            }
        }
        else if (this.process_lowercase === "viewx12claim") {
            //if (viewx12ClaimProcessed)
            this.onAlert("The selected items did not return a claim file, please refine your filter");
        }
        else {
            this.onAlert("No claims meet your filter criteria. Return to the filter and change your criteria.");
        }
    }

    public postAction_RefineFilter() {
        //  var filterType = this.props.dataStore.ui.filter.Filter['@Type'] ?? "Claim";  // be careful, which "type" this is ("Claim"|"Status"|"Eligibility"|"Remit")

        var xmlData: APIXMLCB_XmlCallBackModel = {};
        xmlData.Process = this.props.process;  // to test view edit use : "View/Edit"; 
        xmlData.Type = "Claim";
        xmlData.ViewOrigin = '/Claims/filter.asp';  // see note in type def
        xmlData.FilterJsonData = this.getFilterData();
        xmlData.TestMode = this.props.testMode;

        // debug only - REMOVE ME
        console.log(' ** refine clicked **');
        console.log(xmlData.FilterJsonData);

        this.setBusy(true);
        fetch(URLs.api + '/api/data/SetWorkingGroup',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            })
            .then(async (response) => {
                // get json response here
                let data: any = await response.json();
                console.log("DataEntered", response.status);
                if (response.status === 306) {
                    //let cleanData = data.message.replace(/:"/g, "-");
                    this.onAlert(data.message);  // error is in message
                    return;
                } else if (response.status === 200) {
                    this.onRefineSuccess(data, this.overrideUrl);  // data is just in data
                    return;
                } else {
                    throw new Error(`ClaimFilter: unable to run filter ${response.status}. ${response.statusText}:  ${(data && data.message ? data.message : data)}`);
                }
            })
            .catch(error => {
                console.error('ClaimFilter: ' + error);
                this.errorCallback("Unable to process filter.");
            })
            .finally(() => {
                this.setBusy(false);
            });

    }

    // maps to onOk - this is Refine or Submit for some process
    // handles "Submit|Refine~But_Refine~Permission~RunFilter~'WorkingGroupDisplay.asp'"
    // RunFilter/MakeRequest
    public onRefine(/*e: React.ChangeEvent<HTMLButtonElement>, */ url: string = '') {
        if (!this.validateFilter())
            return;
        else {
            this.overrideUrl = url;
            this.setPostAction("RefineFilter");
        }
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.goToHomePage();
    }

    //  Load~But_Load~~LoadFilter~'button'
    public loadFilter(source: string, successCallback: any = undefined) {
        try {

            var filterId = 0;
            if (source == 'button' || this.props.dataStore.ui.loaded == false) {
                if (this.props.dataStore.ui.quickStart === "3") {  // QuickFilter 3 is saved filter
                    if (this.props.dataStore.ui.filterIndexQs == -1) {  // filter index in list
                        this.onAlert("Please select a saved filter to load.");
                        return;
                    }

                    var filterAsNum: number = Number(this.props.dataStore.ui.filterIdQs);
                    if (isNaN(filterAsNum)) {
                        this.onAlert("Please select a saved filter to load.");
                        return;
                    }
                    this.loadFilterCriteria(filterAsNum, successCallback);

                } else if (this.props.dataStore.ui.quickStart === "1") {  // Type == "User"
                    this.loadFilterCriteria(0, successCallback);
                    // bClaimStateChecked = true;

                } else if (this.props.dataStore.ui.quickStart === "0") {
                    this.loadFilterCriteria(0, successCallback);

                }
            }

        } catch (e) {
            const typError = e as Error;
            console.error('unable to load filter ' + typError.message);
            this.onAlert("Unable to load filter. Please select a saved filter to load.");
            return;
        }
    }

    // "Save~But_Save~~SaveFilter~"
    public saveFilter() {
        this.saveFilterName = '';
        this.saveFilterId = undefined;
        if (this.validateFilter()) {
            this.props.action.ui.openFilterList();
        }
    }

    public onClearNCS(e: any) {
        // console.log('onClearNCS::ClaimFilter');
        this.props.action.ui.sendSaveFilterNCSAlert({ api: this.props.dataStore.api.data, uiData: { value: '' } });

        if (e.target.title != 'close') { // uicl's default close button title...
            this.saveFilterCall(false);
        }

        // as i understand, if they say No, its a no-op
    }

    public onConfirmNCS(e: any) {
        //  console.log('onConfirmNCS::ClaimFilter');
        this.props.action.ui.sendSaveFilterNCSAlert({ api: this.props.dataStore.api.data, uiData: { value: '' } });

        this.saveFilterCall(true);
    }

    public onConfirmFilterList(filterListSelection: IModalFilterListSelection) {
        this.props.action.ui.closeFilterList();
        this.setFilterSelection(filterListSelection.id ?? 0, filterListSelection.name);
        this.saveFilterName = filterListSelection.name;
        this.saveFilterId = filterListSelection.id ?? 0;

        if (!!this.props.ncsIsEnabled) {
            var ncsQuestion =
                "Do you want to add or update the filter for all enterprise sites? Unique fields like payer list or facility list are ignored.";
            this.props.action.ui.sendSaveFilterNCSAlert({
                api: this.props.dataStore.api.data,
                uiData: { value: ncsQuestion }
            });
        } else {
            this.saveFilterCall(false);
        }

    }

    public async saveFilterCall(syncNetworkClients: boolean) {

        this.setBusy(true);

        var xmlData: APIXMLCB_XmlCallBackModel = {};

        xmlData.FilterId = this.saveFilterId;
        //xmlData.GroupId = '';  // to be used by report filter...someday
        xmlData.Action = "FilterList"
        xmlData.TestMode = this.props.testMode;
        xmlData.Type = "Claim";  //  (Claim|Report|Remit)
        xmlData.Process = this.props.process;
        xmlData.FilterJsonData = this.getFilterData();
        xmlData.FilterName = this.saveFilterName;
        //        xmlData.FilterName = this.saveFilterId && this.saveFilterId > 0 ? '' : this.saveFilterName;
        xmlData.SyncNetworkedClients = syncNetworkClients;

        // do not save deleted state (similar logic in old ndc_Filters.dll)
        xmlData.FilterJsonData = xmlData.FilterJsonData.replace('"ClaimState":{"@ID":"-1"}', '"ClaimState":{"@ID":"1"}');

        if (syncNetworkClients) {
            xmlData.SyncFilterName = this.saveFilterName;
            xmlData.Name = this.saveFilterName;
        }

        await fetch(URLs.api + '/api/data/filterList',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error(response.statusText);
                }
            }).then((data) => {
                //     console.log(data);  //  {returnCode: 0, errorDescription: "", filterId: 0, filterData:"{ListPopulation:{FilterList:{@Current:"0", @FilterType: "S", @FilterAssigned:"0"}}}}

                if (data.returnCode !== 0) {
                    console.error(data.errorDescription + '(' + data.returnCode + ')  on filter ' + xmlData.FilterJsonData);
                    this.errorCallback('Unable to save filter. ' + data.errorDescription);
                } else {
                    this.onLoadFilterList(data.filterData);


                }

            })
            .catch(error => {
                console.error('ClaimFilter: ' + error);
                this.errorCallback('Unable to save filter.');
            })
            .finally(() => {
                this.setBusy(false);
            });


    }

    public viewx12claims() {
        var xmlData: APIXMLCB_XmlCallBackModel = {};
        xmlData.Process = this.props.process;  // to test view edit use : "View/Edit"; 
        xmlData.Type = "Claim";
        xmlData.ViewOrigin = '/Claims/filter.asp';  // see note in type def
        xmlData.FilterJsonData = this.getFilterData();
        xmlData.TestMode = this.props.testMode;

        // debug only - REMOVE ME
        console.log(' ** refine clicked **');
        console.log(xmlData.FilterJsonData);

        this.setBusy(true);
        fetch(URLs.api + '/api/data/SetWorkingGroup',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            })
            .then(async (response) => {
                // get json response here
                let data: any = await response.json();
                console.log("DataEntered", response.status);
                if (response.status === 306) {
                    //let cleanData = data.message.replace(/:"/g, "-");
                    this.onAlert(data.message);  // error is in message
                    this.setBusy(false);
                    return;
                } else if (response.status === 200) {
                    this.redirectToAdminPortal(); // data is just in data
                    return;
                } else {
                    this.setBusy(false);
                    throw new Error(`ClaimFilter: unable to run filter ${response.status}. ${response.statusText}:  ${(data && data.message ? data.message : data)}`);
                }
            })
            .catch(error => {
                console.error('ClaimFilter: ' + error);
                this.errorCallback("Unable to process filter.");
            })
    }

    protected GoToProcess = React.createRef<HTMLFormElement>();

    public renderLegacyGoTo() {
        return (
            <form ref={this.GoToProcess} action={"/Administration/AdminPortal.asp"} name="GoToProcess" method={"POST"}>
                <input type="hidden" name="Process" value={"viewx12claim"} />
            </form>
        );
    }

    public redirectToAdminPortal() {
        fetch(URLs.api + '/api/data/Claims/FileCountFromFilter',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                }
            })
            .then(async (response) => {
                // get json response here
                let data: any = await response.json();
                if (data === 1) {
                    fetch(URLs.api + '/api/data/VerifyX12File',
                        {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `${getRawToken()}`
                            }
                        })
                        .then(async (response) => {
                            // get json response here
                            let internalData: any = await response.json();
                            if (internalData.toString().indexOf("Not Found") == -1) {
                                if (this.GoToProcess) {
                                    this.GoToProcess.current?.submit();
                                }
                            }
                            else {
                                this.onAlert("File Not Found");
                                this.setBusy(false);
                            }
                        })
                        .catch(error => {
                            console.error('ClaimFilter: ' + error);
                            this.errorCallback("Unable to process filter.");
                            this.setBusy(false);
                        })
                        .finally(() => {
                            //this.setBusy(false);
                        });
                }
                else if (data === 0) {
                    this.setBusy(false);
                    this.onAlert("The selected items did not return a claim file, please refine your filter");
                    return false;
                }
                else {
                    this.setBusy(false);
                    this.onAlert("Too many results returned, please narrow your search criteria or use refine to select a single file.");
                    return false;
                }
            })
            .catch(error => {
                console.error('ClaimFilter: ' + error);
                this.errorCallback("Unable to process filter.");
            })
            .finally(() => {
                //this.setBusy(false);
            });
    }

    public onCloseFilterList() {
        var x = this.getClaimFilterList(false); // resetting the selected state


        this.props.action.ui.closeFilterList();
    }

    // only the Submit button on certain filters (see: get buttons for process list)
    // "Submit~But_Continue~Permission~ExecuteProcess~"
    public submitFilter() {
        if (this.process_lowercase === "viewx12claim") {
            this.viewx12claims();
            return;
            // ExecuteJS = "if (ValidateFilter(false)) { ShowBusyBrowser(true); setTimeout('ValidateRequest()', 500); }"
        }

        if (this.process_lowercase === "view/edit") {
            this.onRefine("CFI");  // ExecuteJS = "RunFilter(""/CFI/Default.asp"");"
            return;
        }

        if (this.process_lowercase === "manual") {
            this.setState({ showReleaseClaimModal: true })
            return;
        }

        // make the call
        if (this.props.execNode !== "" && this.props.execAction !== "") {
            this.checkRua = true;
            if (this.validateFilter()) {
                this.setPostAction("SubmitFilter");
            }
        }
    }
    // 
    public postAction_SubmitFilter() {
        this.setBusy(true);
        console.log(`postAction_SubmitFilter -> ${this.props.execNode}`)

        //  setTimeout('XMLCallBack(\'<" & ExecNode & _
        //  " Action=""" & ExecAction & """ Type=""Claim"" Process=""" & ExecProcess & """ " & ExecAttribs & " aft=""" & token & """>\' + document.UserFilter.Parameter.value + document.all[""xmlFilter""].xml + \'" & _
        //  "</" & ExecNode & ">\', true);', 500); }"

        // api has been coded for execAction = "Validation"
        // NOTE when implemented  filter-based pages don't assume this function works for you, you need to review the API code for your type (ExecAction)

        switch (this.props.execNode) {
            case "MultiClaim":
            case "PaperClaim":
                this.executeMultiClaimProcess();
                break;
            case "ClaimChange":
                this.executeClaimChangeProcess();
                break;
            case "Report":
                this.executeClaimReportProcess();
                break;
            case "ChangeClaimState":
            case "Export":
                alert('todo implement');
                break;
            default:
                console.error('ClaimFilter: unknown execNode found ' + this.props.execNode);
                break;
        }
    }

    public async executeClaimReportProcess() {

        var xmlData: APIXMLCB_XmlCallBackModel = {};

        // validations on inputs before making call - i do not know that any of these are required
        if (!this.props.execAction || (this.props.execAction && this.props.execAction.length == 0))
            return;

        this.setBusy(true);

        var jsonData: any = {};
        jsonData.Report = {}; // execNode
        jsonData.Report['@Action'] = this.props.execAction;
        jsonData.Report['@Type'] = 'Claim';  // hardcoded in filter.asp
        jsonData.Report['@Process'] = (this.props.execProcess && this.props.execProcess.length > 0) ? this.props.execProcess : this.props.process;

        if (this.props.parameter && this.props.parameter?.ReportRequests) {
            jsonData.Report.ReportRequests = this.props.parameter?.ReportRequests;
        }

        jsonData.Report = Object.assign(jsonData.Report, JSON.parse(this.getFilterData()));

        //  debug only
        //console.log(' ** executeClaimReportProcess clicked **');
        //console.log(jsonData.Report);

        xmlData.Action = this.props.execAction ? this.props.execAction : "";
        xmlData.ViewOrigin = '/Claims/filter.asp';  // see note in type def
        xmlData.FilterJsonData = JSON.stringify(jsonData);

        await fetch(URLs.api + '/api/data/PostMultiClaim',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
                if (response.status === 200) {
                    this.goToHomePage();
                    return;
                } else {
                    throw new Error('ClaimFilter: Failed to create the report job ' + response.status + ' ' + response.statusText);
                }
            })
            .catch(error => {
                console.error('ClaimFilter: ' + error);
                this.errorCallback("Failed to create the report job.");
            })
            .finally(() => {
                this.setBusy(false);
            });

    }

    // NOTE:  If your page uses ExecAttribs you need to code for them (remove your alert when you code it)
    public async executeMultiClaimProcess() {

        var xmlData: APIXMLCB_XmlCallBackModel = {};

        /*
                if (this.process_lowercase == "manual" ||
                    this.process_lowercase == "spinoff" ||
                    this.process_lowercase == "runbridgeroutines" ||
                    this.process_lowercase == "statereport")
                    // alert('TODO implement the execattribs');
         */

        // validations on inputs before making call - i do not know that any of these are required
        if (!this.props.execAction || (this.props.execAction && this.props.execAction.length == 0))
            return;

        var jsonData: any = {};
        jsonData.MultiClaim = {}; // execNode
        jsonData.MultiClaim['@Action'] = this.props.execAction;
        jsonData.MultiClaim['@Type'] = "Claim";  // hardcoded in filter.asp
        jsonData.MultiClaim['@Process'] = (this.props.execProcess && this.props.execProcess.length > 0) ? this.props.execProcess : this.props.process;
        if (this.props.execAttribs && this.props.execAttribs.length > 0 && this.props.execAction !== "SpinOffClaim")
            jsonData.MultiClaim['@ParamString'] = this.props.execAttribs;
        else if (this.props.execAction === "SpinOffClaim") {
            jsonData.MultiClaim['@Param'] = this.props.execAttribs;
        }

        if (['dep', 'depoutsource', 'reprinthcdclaimeob'].includes(this.process_lowercase)) {
            if (this.props.parameter && this.props.parameter?.ReportRequests) {
                jsonData.MultiClaim.ReportRequests = this.props.parameter?.ReportRequests;
            }
        }

        jsonData.MultiClaim = Object.assign(jsonData.MultiClaim, JSON.parse(this.getFilterData()));

        xmlData.Action = this.props.execAction ? this.props.execAction : "";
        xmlData.Process = this.props.execProcess ? this.props.execProcess : "";
        xmlData.ViewOrigin = '/Claims/filter.asp';  // see note in type def
        xmlData.FilterJsonData = JSON.stringify(jsonData);

        //console.log('** executeMultiClaimProcess clicked **');
        //console.log(JSON.stringify(jsonData.MultiClaim, null, 2));
        //console.log(jsonData.MultiClaim.Filter);
        //console.log(xmlData);

        this.setBusy(true);
        await fetch(URLs.api + '/api/data/PostMultiClaim',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
                if (response.status === 200) {
                    this.goToHomePage();
                    return;
                } else {
                    throw new Error('ClaimFilter: unable to run filter ' + response.status + ' ' + response.statusText);
                }
            })
            .catch(error => {
                console.error('ClaimFilter: ' + error);
                this.errorCallback("Unable to run filter");
            })
            .finally(() => {
                // this.setBusy(false);
            });

    }

    public async executeClaimChangeProcess() {

        var xmlData: APIXMLCB_XmlCallBackModel = {};

        /*
                if (this.process_lowercase == "manual" ||
                    this.process_lowercase == "spinoff" ||
                    this.process_lowercase == "runbridgeroutines" ||
                    this.process_lowercase == "statereport")
                    // alert('TODO implement the execattribs');
         */

        // validations on inputs before making call - i do not know that any of these are required
        if (!this.props.execAction || (this.props.execAction && this.props.execAction.length == 0))
            return;

        var jsonData: any = {};
        jsonData.ClaimChange = {}; // execNode
        jsonData.ClaimChange['@Action'] = this.props.execAction;
        jsonData.ClaimChange['@Type'] = "Claim";  // hardcoded in filter.asp
        jsonData.ClaimChange['@Process'] = (this.props.execProcess && this.props.execProcess.length > 0) ? this.props.execProcess : this.props.process;
        if (this.props.execAttribs && this.props.execAttribs.length > 0 && this.props.execAction !== "SpinOffClaim")
            jsonData.ClaimChange['@ParamString'] = this.props.execAttribs;
        else if (this.props.execAction === "SpinOffClaim") {
            jsonData.ClaimChange['@Param'] = this.props.execAttribs;
        }

        jsonData.ClaimChange = Object.assign(jsonData.ClaimChange, JSON.parse(this.getFilterData()));

        xmlData.Action = this.props.execAction ? this.props.execAction : "";
        xmlData.Process = this.props.execProcess ? this.props.execProcess : "";
        xmlData.ViewOrigin = '/Claims/filter.asp';  // see note in type def
        xmlData.FilterJsonData = JSON.stringify(jsonData);

        // console.log('** executeClaimChangeProcess clicked **');
        //console.log(JSON.stringify(jsonData.ClaimChange, null, 2));
        //console.log(jsonData.ClaimChange.Filter);
        //console.log(xmlData);

        this.setBusy(true);
        await fetch(URLs.api + '/api/data/PostMultiClaim',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
                if (response.status === 200) {
                    this.goToHomePage();
                    return;
                } else {
                    throw new Error('ClaimFilter: unable to run claim change process ' + response.status + ' ' + response.statusText);
                }
            })
            .catch(error => {
                console.error('ClaimFilter: ' + error);
                this.errorCallback("Unable to run claim change process");
            })
            .finally(() => {
                // this.setBusy(false);
            });

    }

    public async loadFilterCriteria(filterId: number, successCallback: any = undefined) {
        var manageBusy = !this.props.dataStore.ui.isBusy;

        if (manageBusy)
            this.setBusy(true);

        this.setLoaded(true);

        // call to set the filterName
        this.setFilterSelection(filterId, (filterId == 0 ? "" : this.props.dataStore.ui.filterNameQs));

        var xmlData: APIXMLCB_XmlCallBackModel = {};

        xmlData.FilterId = filterId;
        //xmlData.GroupId = '';  // to be used by report filter...someday
        xmlData.TestMode = this.props.testMode;
        xmlData.Type = "Claim";  // "Claim|Report|Remit"

        await fetch(URLs.api + '/api/data/getFilter',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error(response.statusText);
                }
            }).then((data: APICF_FilterWrapper) => {
                //console.log(data);
                if (data) {
                    if (data?.Filter['@TestMode'] == "true") //  is "true" correct, is this correct place for TestMode in response (Filter['@TestMode'])
                    {
                        this.onLoadFilter(deepCopyFunction({ ...emptyFilter }));
                    } else
                        this.onLoadFilter(data);


                }
            }).then(() => {
                if (this.props.dataStore.ui.claimFilterErrorHandlerForNotFound !== null && this.props.dataStore.ui.claimFilterErrorHandlerForNotFound !== "") {
                    this.setState({ disableBtn: false });
                    this.setActiveTab(1);
                    return false;
                }
                if (successCallback != undefined)
                    successCallback();
            })
            .catch(error => {
                console.error('ClaimFilter: ' + error);
                this.errorCallback('Unable to load filter.');
            })
            .finally(() => {

            });

    }



    public getEligibilityActive() {
        if (this.eligibilityActive != undefined)
            return this.eligibilityActive;

        let eligibilityActive = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "Eligibility");
        if (eligibilityActive && eligibilityActive.value)
            this.eligibilityActive = eligibilityActive.value === "1";

        return (this.eligibilityActive == undefined ? false : this.eligibilityActive);

    }

    public getDirectEntryActive() {
        if (this.directEntryActive != undefined)
            return this.directEntryActive;

        let directEntryActive = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "DirectEntry");
        if (directEntryActive && directEntryActive.value)
            this.directEntryActive = directEntryActive.value === "1";

        return (this.directEntryActive == undefined ? false : this.directEntryActive);

    }

    ////////////////////////////
    ////  TAB LOGIC
    ////////////////////////////
    public getTabs(): any {
        var tabs = [];
        var eligActive = this.getEligibilityActive();

        var tabQs = { label: 'Quick Start', domID: 'claimfilter-tab-qs' };
        var tabAdv = { label: 'Advanced', domID: 'claimfilter-tab-adv' };
        var tabPs = { label: 'Payer Status', domID: 'claimfilter-tab-ps' };
        var tabElig = { label: 'Eligibility', domID: 'claimfilter-tab-elig' };



        // i know this is coded odd, but i expect to have to add weird logic to handle disable later
        tabs.push(tabQs);
        tabs.push(tabAdv);
        if (this.process_lowercase != "viewx12claim") {
            tabs.push(tabPs);

            if (eligActive)
                tabs.push(tabElig);
        }
        if (this.process_lowercase == "viewx12claim") {
            $("#claimfilter-tab-qs").attr("disabled", 'disabled');
            // tabQs should be disabled, Tabs does not support disabling, need a hack or see if it can be done DOM-level
        }

        return tabs;
    }

    public getInitialTab(): number {
        //uicl tabs don't seem to support disabling tabs or custom id/index, 
        // creating this method in case there needs to be logic in determining the tab
        return this.props.dataStore.ui.activeTab;
    }

    public setLoaded(val: boolean) {
        this.props.action.ui.setLoaded(
            {
                api: this.props.dataStore.api.data,
                uiData: { index: '0', data: val }
            });
    }
    public setActiveTab(index: number) {
        this.props.action.ui.setActiveTab(
            {
                api: this.props.dataStore.api.data,
                uiData: { data: index }
            });
    }
    public onTabSelect(index: number) {

        if (index == 0 && this.process_lowercase != "viewx12claim") // this tab is disagbled for viewx12claim
        {
            this.disableOption("deleted", true);
            this.setClaimState("Deleted", false);
        } else {
            this.setActiveTab(index);
            this.loadFilter('tab', () => { this.setActiveTab(index) }); // note: i'm concerned that setActiveTab hasn't run yet (read: updated state yet), be careful of any tabindex checks
        }

        this.setActiveTab(index);

    }


    public getSavedFilterTabs(disabled : boolean): any {
        var tabs = [];
        var eligActive = this.getEligibilityActive();

        var tabAdv = { label: 'Advanced Claim', domID: 'claimfilter-sftab-adv', isDisabled: disabled};
        var tabPs = { label: 'Payer Status', domID: 'claimfilter-sftab-ps', isDisabled: disabled };
        var tabElig = { label: 'Eligibility', domID: 'claimfilter-sftab-elig', isDisabled: disabled };

        tabs.push(tabAdv);
        tabs.push(tabPs);
        if (eligActive)
            tabs.push(tabElig);

        return tabs;
    }
    public getInitialSFTab(): number {

        return this.props.dataStore.ui.activeSavedFilterTab;
    }

    public setActiveSFTab(index: number) {
        this.props.action.ui.setActiveSFTab(
            {
                api: this.props.dataStore.api.data,
                uiData: { data: index }
            });
    }


    ////////////////////////////
    ////  RENDER HELPER METHODS
    ////////////////////////////
    public dataLoaded(): boolean {
        if (this.props.dataStore.ui.filter &&
            this.props.dataStore.ui.filter.Filter)
            return true;

        return false;
    }



    public getLookupList(lookupList: APICF_SimpleList[] | null) {
        if (!this.apiDataAvailable() || !lookupList || lookupList.length === 0)
            return [];

        let result: any[] = [];

        result = this.getLookupListIntialItem(lookupList, null);

        return result;
    }

    public getLookupListIntialItem(lookupList: APICF_SimpleList[] | null, initialItem: string[] | null) {
        if (!this.apiDataAvailable() || !lookupList)
            return [];

        let result: any[] = [];

        if (initialItem) {
            result.push({ label: initialItem[0], value: initialItem[1] })
        }
        lookupList.forEach(ob => {
            if (ob['Name'])
                result.push({ label: ob['Name'], value: ob['ID'] });
        });

        return result;
    }




    public getLookupListIntialItem_Author(lookupList: APICF_SimpleListUser[] | null, initialItem: string[] | null) {
        if (!this.apiDataAvailable() || !lookupList)
            return [];

        let result: any[] = [];

        if (initialItem) {
            result.push({ label: initialItem[0], value: initialItem[1] })
        }

        lookupList.forEach(ob => {
            if (ob['Name']) {
                var id = this.clientId + "\\" + ob['LoginName'];
                var label = ob['Name'];
                if (ob['LoginName'])
                    label += " (" + ob['LoginName'] + ")";
                result.push({ label: label, value: id });
            }

        });

        return result;
    }

    public getLookupListIntialItem_User(lookupList: APICF_SimpleListUser[] | null, initialItem: string[] | null) {
        if (!this.apiDataAvailable() || !lookupList)
            return [];

        let result: any[] = [];

        if (initialItem) {
            result.push({ label: initialItem[0], value: initialItem[1] })
        }

        lookupList.forEach(ob => {
            if (ob['Name']) {
                var label = ob['Name'];
                if (ob['LoginName'])
                    label += " (" + ob['LoginName'] + ")";
                result.push({ label: label, value: ob['ID'] });
            }

        });

        return result;
    }

    public getUserLookupList(lookupList: any[] | null) {
        if (!this.apiDataAvailable() || !lookupList || lookupList.length === 0)
            return [];

        let result: any[] = [];

        result.push({ label: '- Select User Name -', value: '0' });

        lookupList.forEach(ob => {
            if (ob['Name'])
                result.push({ label: ob['Name'] + ' (' + ob['LoginName'] + ')', value: ob['ID'] });
        });
        return result;
    }

    public getLookupList_SelectedItem2_Author(lookupList: APICF_SimpleListUser[] | null, objItem: any, fieldToFind: string | undefined, emptyItem?: any): any {

        var result = emptyItem ?? { value: '', label: '' };
        var idToFind = '';

        if (!this.apiDataAvailable() || !lookupList || lookupList.length === 0 || objItem == undefined || fieldToFind == undefined)
            return result;

        if (objItem != null && objItem[fieldToFind] != null)
            idToFind = objItem[fieldToFind];

        if (idToFind == undefined)
            return result;

        var slashLocation = idToFind.indexOf('\\');

        if (slashLocation <= -1)
            return result;

        var newId = idToFind.substring(slashLocation + 1);

        let item = lookupList.find(
            ob => ob['LoginName'] === newId);

        if (item) {
            var id = this.clientId + "\\" + item['LoginName'];
            var label = item['Name'];
            if (item['LoginName'])
                label += " (" + item['LoginName'] + ")";

            result.label = label;
            result.value = id;
        } else if (emptyItem) {
            result = emptyItem;
        }
        return result;
    }

    public getLookupList_SelectedItem3(lookupList: any[] | null, lookupID: string, objItem: any, fieldToFind: string | undefined, emptyItem?: any): any {

        var result = emptyItem ?? { value: '', label: '' };
        var idToFind = '';

        if (!this.apiDataAvailable() || !lookupList || lookupList.length === 0 || objItem == undefined || fieldToFind === undefined)
            return result;

        if (objItem != null && objItem[fieldToFind] != null)
            idToFind = objItem[fieldToFind];

        if (idToFind == undefined)
            return result;

        let item = lookupList.find(
            ob => ob[lookupID] === idToFind);

        if (item) {
            result.label = item['Name'];
            result.value = item['ID'];
        } else if (emptyItem) {
            result = emptyItem;
        }
        return result;
    }

    public getLookupList_SelectedItem2(lookupList: any[] | null, objItem: any, fieldToFind: string | undefined, emptyItem?: any): any {

        return this.getLookupList_SelectedItem3(lookupList, 'ID', objItem, fieldToFind, emptyItem);
    }

    public getLookupList_SelectedItem(lookupList: APICF_SimpleList[] | null, idToFind: string | undefined, emptyItem?: any): any {
        if (!this.apiDataAvailable() || !lookupList || lookupList.length === 0 || idToFind === undefined)
            return { value: '', label: '' };

        let item = lookupList.find(
            ob => ob['ID'] === idToFind);
        let result = { value: '', label: '' };
        if (item) {
            result.label = item['Name'];
            result.value = item['ID'];
        } else if (emptyItem) {
            result = emptyItem;
        }
        /*        else {
                    result.label = lookupList[0].Name;
                    result.value = lookupList[0].ID;
                }
        */
        return result;
    }




    public getLookupList_SelectedEmptyItem(nameToInsert: string, idToInsert: string): any {


        let result = { value: '', label: '' };
        result.label = nameToInsert;
        result.value = idToInsert;

        return result;
    }

    public getLookupList_SelectedItem_DisplayOrder() {

        if (this.process_lowercase == "printcaltc") {  // printcaltc hardcoded to 17, but did not disable
            return this.getLookupList_SelectedItem(this.props.dataStore.api.data?.ListPopulation?.DisplayOrderList,
                "17");
        }
        if (this.process_lowercase == "direct") {  // direct hardcoded to , and disabled
            return this.getLookupList_SelectedItem(this.props.dataStore.api.data?.ListPopulation?.DisplayOrderList,
                "0");
        }


        return this.getLookupList_SelectedItem(this.props.dataStore.api.data?.ListPopulation?.DisplayOrderList,
            this.getData(this.props.dataStore.ui.filter.Filter.DisplayOrder, "@ID") == "" ? "1" : this.getData(this.props.dataStore.ui.filter.Filter.DisplayOrder, "@ID"));
    }


    public getCurrentTabSize() {

        switch (this.props.dataStore.ui.activeTab) {
            case 1:
                return 6
            case 2:
                return 7
            case 3:
                return 11
            default:
                return 11
        }
    }


    public getFormTypes() {
        let addItem: APICF_SimpleList[] = [
            {
                'ID': '',
                'Name': '-All Form Types-'
            }
        ];

        let data = this.props.dataStore.api.data?.ListPopulation?.FormTypeList
            ? addItem.concat(this.props.dataStore.api.data.ListPopulation.FormTypeList) : addItem;

        return data;
    }

    public getSelectedFormTypes(): string[] {
        let items: string[] = [];

        if (this.dataLoaded() &&
            this.props.dataStore.ui.filter.Filter.FormTypes &&
            this.props.dataStore.ui.filter.Filter.FormTypes.FormType) {
            this.props.dataStore.ui.filter.Filter.FormTypes.FormType.forEach(ob => {
                if (ob['@ID']?.length > 0) {
                    items.push(ob['@ID']);
                }
            });
        }

        if (items.length == 0) { items.push('') }
        return items;
    }
    public getLobs() {
        let addItem: APICF_SimpleList[] = [
            {
                'ID': '',
                'Name': '-All LOBs-'
            }
        ];

        let data = this.props.dataStore.api.data?.ListPopulation?.LOBList
            ? addItem.concat(this.props.dataStore.api.data.ListPopulation.LOBList) : addItem;

        return data;
    }

    public getSelectedLobs(): string[] {
        let items: string[] = [];

        if (this.dataLoaded() &&
            this.props.dataStore.ui.filter.Filter.ClaimLOBs &&
            this.props.dataStore.ui.filter.Filter.ClaimLOBs.ClaimLOB) {
            this.props.dataStore.ui.filter.Filter.ClaimLOBs.ClaimLOB.forEach(ob => {
                if (ob['@ID']?.length > 0) {
                    items.push(ob['@ID']);
                }
            });
        }

        if (items.length == 0) { items.push('') }
        return items;
    }

    public getSelectedFacilities(): string[] {
        let items: string[] = [];

        if (this.dataLoaded() &&
            this.props.dataStore.ui.filter.Filter.Facilities &&
            this.props.dataStore.ui.filter.Filter.Facilities.Facility) {
            this.props.dataStore.ui.filter.Filter.Facilities.Facility.forEach(ob => {
                if (ob['@ID']?.length > 0) {
                    items.push(ob['@ID']);
                }
            });
        }

        if (items.length == 0) { items.push('') }
        return items;
    }

    public getSelectedErrorCategories(): string[] {
        let items: string[] = [];

        if (this.dataLoaded() &&
            this.props.dataStore.ui.filter.Filter.ErrorCategories &&
            this.props.dataStore.ui.filter.Filter.ErrorCategories.ErrorCategory) {
            this.props.dataStore.ui.filter.Filter.ErrorCategories.ErrorCategory.forEach(ob => {
                if (ob['@ID']?.length > 0) {
                    items.push(ob['@ID']);
                }
            });
        }

        if (items.length == 0) { items.push('') }
        return items;
    }

    public getSelectedPayers(): string[] {
        let items: string[] = [];

        if (this.dataLoaded() &&
            this.props.dataStore.ui.filter.Filter.Payers &&
            this.props.dataStore.ui.filter.Filter.Payers.Payer) {
            this.props.dataStore.ui.filter.Filter.Payers.Payer.forEach(ob => {
                if (ob['@ID']?.length > 0) {
                    items.push(ob['@ID']);
                }
            });
        }

        if (items.length == 0) { items.push('') }
        return items;
    }

    public getEligibilityPayers(): string[] {
        let items: string[] = [];

        if (this.dataLoaded() &&
            this.props.dataStore.ui.filter.Filter.EligibilityPayers &&
            this.props.dataStore.ui.filter.Filter.EligibilityPayers.EligibilityPayer) {
            this.props.dataStore.ui.filter.Filter.EligibilityPayers.EligibilityPayer.forEach(ob => {
                if (ob['@ID']?.length > 0) {
                    items.push(ob['@ID']);
                }
            });
        }

        if (items.length == 0) { items.push('- All Eligibility Payers -') }
        return items;
    }

    public checkClaimState() {

        if (this.process_lowercase == "deslstatus") {
            this.disableOption("deleted", true);
            this.setClaimStateSwitch(false);
            this.setClaimState("Deleted", false);

        } else {
            var accNum: string = this.getData(this.props.dataStore.ui.filter.Filter?.AccountNumber, '@Value');
            if (!accNum || (accNum && accNum.length < 5)) {

                this.setClaimStateSwitch(false);

                //ASRN 1900 Deleted Claim Extract Posting filter defaults incorrect options for Claim State checkboxes
                if (this.bDEPDeletedFlag)
                    this.setClaimState("Deleted", true);
                else
                    this.setClaimState("Deleted", false);
                this.disableOption("deleted", true);

            } else {

                this.setClaimStateSwitch(true);
                if (this.bDEPDeletedFlag)
                    this.disableOption("deleted", true);
                else
                    this.disableOption("deleted", false);

                var dateData = this.getData(this.props.dataStore.ui.filter?.Filter?.DateField, '@ID');

                if (this.props.dataStore.ui.dataRefreshed == false && dateData == DATE_RELEASED_ID) {  //"Date Released" - 9
                    var dateTo = this.getData(this.props.dataStore.ui.filter?.Filter?.DateField, '@To');
                    var dateFrom = this.getData(this.props.dataStore.ui.filter?.Filter?.DateField, '@From');
                    if ((dateTo === "00/00/0000" || dateTo === "0/0/0000") &&
                        ((this.props.dataStore.ui.activeTab == 3 && dateFrom === "01/00/0000") || dateFrom === "00/00/0000" || dateFrom == "0/0/0000")) {

                        this.onDateFieldChange({ value: '' });

                    }

                }

            }
        }
    }

    public checkSaveButton(): boolean {
        if (!this.props.canCreate)
            return false;
        if (this.process_lowercase == "viewx12claim")
            return false;


        if ((this.isUnreleasedChecked() && this.isReleasedChecked()) ||
            (this.isUnreleasedChecked() && this.isStateOnlyChecked()) ||
            (this.isReleasedChecked() && this.isStateOnlyChecked()) ||
            (this.bDEPDeletedFlag == false && this.isDeletedChecked()))
            return false

        return true;
    }
    public apiDataAvailable(): boolean {
        if (this.props.dataStore.api.data &&
            this.props.dataStore.api.data.ListPopulation)
            return true;
        return false;
    }

    public getClaimFilterListChild(allowFav: boolean): any {
        if (this.props.dataStore.ui.activeTab === 0) {
            let data = this.getClaimFilterList(allowFav);
            if (data.filterList) {
                return data.filterList;
            }
        }
        return [];
    }
    public getSavedFilterList() {
        if (this.props.dataStore.ui.activeTab === 0) {
            if (this.props.dataStore.ui.activeSavedFilterTab == 0) {
                console.log(this.getClaimFilterListItem(true, 'Claim'))
                return this.getClaimFilterListItem(true, 'Claim');
            }
            else if (this.props.dataStore.ui.activeSavedFilterTab == 1) {
                console.log(this.getClaimFilterListItem(true, 'Status'))
                return this.getClaimFilterListItem(true, 'Status');
            }
            else if (this.props.dataStore.ui.activeSavedFilterTab == 2) {
                console.log(this.getClaimFilterListItem(true, 'Eligibility'))
                return this.getClaimFilterListItem(true, 'Eligibility');
            }

        }
        return [];
    }

    public getClaimFilterListItem(allowFav: boolean, filterType: string): IModalFilterListOption[] {
        let filterList: IModalFilterListOption[] = [];

        if (!this.apiDataAvailable())
            return filterList;

        let filters = this.props.dataStore.api.data.ListPopulation?.FilterList.Filter;
        if (filters && filters.length > 0) {

            if (allowFav == true) {
                filters.forEach((listItem: any) => {
                    let filterInfo: IModalFilterListOption = { 'value': listItem.ID, 'label': listItem.Name, 'fav': true };

                    if (listItem.FilterType === filterType && listItem.Favorite !== '9999') {
                        this.hasFavorites = true;
                        filterList.push(filterInfo);
                    }
                });
            }

            filters.forEach((listItem: any) => {
                let filterInfo: IModalFilterListOption = { 'value': listItem.ID, 'label': listItem.Name };

                if (listItem.FilterType === filterType && (!allowFav || (allowFav && listItem.Favorite === '9999'))) {
                    filterList.push(filterInfo);
                }
            });


        }

        return filterList;
    }


    public getClaimFilterList(allowFav: boolean): IModalFilterListFilters {
        let allFilters: IModalFilterListFilters = { filterList: [] };

        if (!this.apiDataAvailable())
            return allFilters;

        let filters = this.props.dataStore.api.data.ListPopulation?.FilterList.Filter;
        if (filters && filters.length > 0) {
            let advClaimFilters: IModalFilterListOption[] = this.getClaimFilterListItem(allowFav, 'Claim');
            let payerFilters: IModalFilterListOption[] = this.getClaimFilterListItem(allowFav, 'Status');
            let eligibilityFilters: IModalFilterListOption[] = this.getClaimFilterListItem(allowFav, 'Eligibility');

            if (advClaimFilters.length > 0) {
                allFilters.filterList.push({ label: "- Select Advanced Claim Filter -", items: advClaimFilters });
            }
            if (payerFilters.length > 0) {
                allFilters.filterList.push({ label: "- Select Payer Status Filter -", items: payerFilters });
            }
            if (eligibilityFilters.length > 0) {
                allFilters.filterList.push({ label: "- Select Eligibility Filter -", items: eligibilityFilters });
            }
        }

        return allFilters;

    }

    // replaces need for EnableAttachmentSection
    public isAttachmentsEnabled(): boolean {
        var attm_unsol = this.getConfigValue("Attm_Unsolicited_Enabled");
        var attm_unsol_enabled = attm_unsol === "1" || attm_unsol.toLowerCase() === "true";
        return this.process_lowercase !== "viewx12claim" && (this.isReleasedChecked() || (this.isUnreleasedChecked() && attm_unsol_enabled));
    }



    public isQuickStartDisabled(val: string): boolean {
        if (val && val == "0" && this.tabType == "Advanced")
            return true;

        if (val && val == "3") {
            if (!this.apiDataAvailable())
                return true;

            if (this.props.dataStore.api.data.ListPopulation?.FilterList && !this.props.dataStore.api.data.ListPopulation?.FilterList?.Filter)
                return true;
        }


        if (val && this.props.dataStore.ui.disabledQuickstart && this.props.dataStore.ui.disabledQuickstart.length > 0) {
            return this.props.dataStore.ui.disabledQuickstart.indexOf(val) > -1;
        }
        return false;
    }

    // this is called from the double click of "Last Claim List" and "Last Claim Filter" on QS
    // used to map to clicking the "submit" button - which is different for each process, but for several these buttons are disabled
    public onSubmitForm() {
        // NOTE EVERY ONE SHOULD TEST THIS FOR THEIR  PROCESS TYPE
        this.loadFilter('button');
    }

    public onRefineClick(refineCallback: any) {
        if (this.props.dataStore.ui.activeTab == 0 && (this.props.dataStore.ui.quickStart == "3" || this.props.dataStore.ui.quickStart == "1")) {
            // need to load filter before making call
            this.loadFilter('button', refineCallback);
        } else {
            refineCallback();
        }
    }

    public onSubmitClick(submitCallback: any) {
        this.setState({ disableBtn: true });
        if (this.props.dataStore.ui.activeTab == 0 && (this.props.dataStore.ui.quickStart == "3" || this.props.dataStore.ui.quickStart == "1")) {
            // need to load filter before making call
            this.loadFilter('button', submitCallback);
        } else {
            submitCallback();
        }
    }


    // this will be important to know if "Submit" did a refine or a submit
    public refineOnlyProcess() {
        switch (this.process_lowercase) {
            case "claimstatusrequest": case "delete": case "undelete": case "rebill":
            case "changetransmitd": case "changetransmits": case "print": case "secondary":
            case "deleted_dep": case "canceleligibility": case "changeclaimstater": case "changeclaimstateu":
            case "copyclaimstotest": case "holdcodeprocessing": case "addnote":
                return true;
                break;

        }
        return false;
    }


    public getButtons() {
        var isSaveEnabled = this.checkSaveButton();
        var isLoadEnabled = this.process_lowercase !== "viewx12claim" && this.props.dataStore.ui.activeTab === 0;

        if (this.refineOnlyProcess()) {
            return <FilterFourButtons
                disableOK={!this.props.canEdit}
                onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onRefineClick(this.onRefine.bind(this))}

                onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)}

                disableLoad={!isLoadEnabled}
                onClickLoad={() => this.loadFilter('button')}

                disableSave={!isSaveEnabled}
                onClickSave={() => this.saveFilter()}
            />;
        } else {
            return <FilterFiveButtons
                disableOK={!this.props.canEdit}
                nameOverride={this.process_lowercase === "view/edit" ? "view claims" : undefined}
                onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onRefineClick(this.onRefine.bind(this))}

                onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)}

                disableLoad={!isLoadEnabled}
                onClickLoad={() => this.loadFilter('button')}

                disableSave={!isSaveEnabled}
                onClickSave={() => this.saveFilter()}

                disableSubmit={!this.props.canEdit || this.state.disableBtn === true}
                // NOTE: needs further research, but doing this to avoid someone "spamming" the button, creating multiple jobs
                onClickSubmit={(e: any) => this.state.disableBtn === true ? null : this.onSubmitClick(this.submitFilter.bind(this))}
            />
        }

    }

    // trying to eliminate... this.props.dataStore.ui.filter?.Filter?.DateField['@To']
    public getData(ob: any, field: string): string {
        if (!this.dataLoaded() || !ob)
            return '';

        return ob[field] ?? '';

    }

    public stringContainsValue(ob: any, field: string, value: string): boolean {
        if (!this.dataLoaded() || !ob)
            return false;

        var testString = ob[field] ?? '';
        return testString.indexOf(value) > -1;
    }


    // claimfilter-claimstate_elig_unr_cb
    public renderClaimState(domIdExt: string) {
        var domId = "claimfilter-claimstate_" + domIdExt;


        return <>
            <ContentRowWrapper>
                {!this.props.dataStore.ui.claimStateSwitch && <> < RadioButtonComponent
                    id={domId + "_unr"}
                    label="Unreleased"
                    //   hasError={this.props.dataStore.ui.focusField == domId + "_unr"}
                    checked={this.isClaimStateChecked(0)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectClaimState('Unreleased')}
                    disabled={this.props.dataStore.ui.disableUnreleased}
                />
                    <RadioButtonComponent
                        id={domId + "_rel"}
                        label="Released"
                        //   hasError={this.props.dataStore.ui.focusField == domId + "_rel"}
                        checked={this.isClaimStateChecked(1)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectClaimState('Released')}
                        disabled={this.props.dataStore.ui.disableReleased}
                    /></>}
                {this.props.dataStore.ui.claimStateSwitch && <>
                    
                        <Checkbox
                            domID={domId + "_unr_cb"}
                            label='Unreleased'
                            checked={this.isClaimStateChecked(0)}
                            onChange={() => this.onSelectClaimState('Unreleased')}
                            disabled={this.props.dataStore.ui.disableUnreleased}
                        />
                        <div style={{marginLeft: '178px'}}>
                            <Checkbox
                                domID={domId + "_rel_cb"}
                                label='Released'
                                checked={this.isClaimStateChecked(1)}
                                onChange={() => this.onSelectClaimState('Released')}
                                disabled={this.props.dataStore.ui.disableReleased}
                            />
                        </div>
                        
                    </>}

            </ContentRowWrapper>
            <ContentRowWrapper>

                <Checkbox
                    domID={domId + "_del_cb"}
                    label='Deleted'
                    hasError={this.props.dataStore.ui.focusField == domId + "_del_cb"}
                    checked={this.isClaimStateChecked(2)}
                    onChange={() => this.onSelectClaimState('Deleted')}
                    disabled={this.props.dataStore.ui.disableDeleted}
                />
                {!this.props.dataStore.ui.claimStateSwitch && <>
                    <div style={{marginLeft: '172.5px'}}>
                        <RadioButtonComponent
                            id={domId + "_so"}
                            label="State Only"
                            checked={this.isClaimStateChecked(3)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectClaimState('StateOnly')}
                            disabled={this.props.dataStore.ui.disableStateOnly}
                        />
                    </div></>}
                {this.props.dataStore.ui.claimStateSwitch && <>
                    <div style={{marginLeft: '178.2px'}}>
                        <StyledCheckbox
                            domID={domId + "_so_cb"}
                            label='State Only'
                            checked={this.isClaimStateChecked(3)}
                            onChange={() => this.onSelectClaimState('StateOnly')}
                            disabled={this.props.dataStore.ui.disableStateOnly}
                        />
                    </div>
                    </>}
            </ContentRowWrapper>
        </>;
    }

    public renderClaimNoteSection(domId: string) {
        return <>
            <ContentRowWrapper style={{width: '100%'}}>
                <SelectDropdown
                    domID={domId + "_type"}
                    className="claim1_dropdown dropdown"
                    // label="Type:"
                    isClearable={false}
                    isSearchable={true}
                    hasError={this.props.dataStore.ui.focusField == domId + "_type"}
                    onChange={(option: SelectDropdownValue) => this.onClaimNoteTypeChange(option)}
                    options={this.getLookupListIntialItem(this.props.dataStore.api.data?.ListPopulation?.NoteList, ["- Select Type -", "0"])}
                    menuWidth={270}
                    initialValue={this.getLookupList_SelectedItem3(this.props.dataStore.api.data?.ListPopulation?.NoteList, 'Name',
                        this.props.dataStore.ui.filter.Filter?.ClaimNote, '@Type', { label: "- Select Type -", value: '0' })}
                />
                <SelectDropdown
                    domID={domId + "author"}
                    className="claim2_dropdown2 dropdown"
                    //  label="Author:"
                    isClearable={false}
                    isSearchable={true}
                    menuWidth={270}
                    hasError={this.props.dataStore.ui.focusField == domId + "author"}
                    onChange={(option: SelectDropdownValue) => this.onAuthorChange(option)}
                    options={this.getLookupListIntialItem_Author(this.props.dataStore.api.data?.ListPopulation?.UserList, ["- Select Author -", "0"])}
                    initialValue={this.getLookupList_SelectedItem2_Author(this.props.dataStore.api.data?.ListPopulation?.UserList,
                        this.props.dataStore.ui.filter.Filter?.ClaimNote, '@Author', { label: "- Select Author -", value: '0' })}


                />
            </ContentRowWrapper>
            <ContentRowWrapper style={{ paddingTop: '3px', gap: '5px' }}>


                <ARMDatePicker
                    className="col2_sep"
                    domID={domId + "from"}

                    label="Posted:"
                    onInputChange={(e: any) => this.onClaimNoteDateChange(e, true)}
                    allowTime={false}
                    hasError={this.props.dataStore.ui.focusField == domId + "from"}
                    minDate={this.minDate}
                    maxDate={this.maxDate}
                    assocTo={this.getData(this.props.dataStore.ui.filter?.Filter?.ClaimNote, '@To')}
                    initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.ClaimNote, '@From')}
                />
                <ARMDatePicker
                    domID={domId + "to"}
                    className="col3_sep"

                    onInputChange={(e: any) => this.onClaimNoteDateChange(e, false)}
                    allowTime={false}
                    hasError={this.props.dataStore.ui.focusField == domId + "to"}
                    minDate={this.minDate}
                    maxDate={this.maxDate}
                    assocFrom={this.getData(this.props.dataStore.ui.filter?.Filter?.ClaimNote, '@From')}
                    initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.ClaimNote, '@To')}
                />


            </ContentRowWrapper>
        </>;
    }

    public renderDateField(domId: string, index: number, qs: boolean) {
        var fld = this.props.dataStore.ui.filter.Filter?.DateField;

        return <>
            <SelectDropdown
                domID={domId}
                className={qs ? "quickstart_col1_dropdown dropdown" : "col1_dropdown dropdown"}
                //disabled={this.process_lowercase === "direct"}
                isClearable={false}
                isSearchable={true}
                hasError={this.props.dataStore.ui.focusField == domId}
                onChange={(option: SelectDropdownValue) => this.onDateFieldChange(option)}
                menuWidth={225}
                options={this.getLookupListIntialItem(this.props.dataStore.api.data?.ListPopulation?.DateFieldList, ["- Select Date Field -", ""])}
                initialValue={this.getLookupList_SelectedItem2(this.props.dataStore.api.data?.ListPopulation?.DateFieldList,
                    fld, '@ID', { label: "- Select Date Field -", value: '' })}
            />
            <ARMDatePicker
                className="col1_sep"
                domID={domId + "from"}
                onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onDateChange(e, true)}
                allowTime={this.props.dataStore.ui.allowTime}
                hasError={this.props.dataStore.ui.focusField == domId + "from"}
                minDate={this.minDate}
                maxDate={this.maxDate}
                assocTo={this.getData(fld, '@To')}
                initialDate={this.getData(fld, '@From')}
            />
            <ARMDatePicker
                domID={domId + "to"}
                className="col1_sep"
                onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onDateChange(e, false)}
                allowTime={this.props.dataStore.ui.allowTime}
                minDate={this.minDate}
                maxDate={this.maxDate}
                hasError={this.props.dataStore.ui.focusField == domId + "to"}
                assocFrom={this.getData(fld, '@From')}
                initialDate={this.getData(fld, '@To')}
            />
        </>;
    }

    renderStateDataReporting(domId: string) {
        return <>
            <ContentRowWrapper style={{marginBottom: '3px'}}>

                <Checkbox
                    domID={domId + "_sent"}
                    label='Sent To State'
                    hasError={this.props.dataStore.ui.focusField == domId + "_sent"}
                    checked={this.props.dataStore.ui.filter.Filter.SDR &&
                        (this.props.dataStore.ui.filter.Filter.SDR["@SentToState"] === 'Y' ||
                            this.props.dataStore.ui.filter.Filter.SDR["@All"] === 'true')}
                    onChange={(e: React.SyntheticEvent) => this.onSelectSDR('SentToState')}
                    disabled={this.props.dataStore.ui.disableStateDataReportingSentToState}
                />

                <Checkbox
                    domID={domId + "_notsent"}
                    label='Not Sent To State'
                    hasError={this.props.dataStore.ui.focusField == domId + "_notsent"}
                    checked={this.props.dataStore.ui.filter.Filter.SDR &&
                        (this.props.dataStore.ui.filter.Filter.SDR["@NotSentToState"] === 'N' ||
                            this.props.dataStore.ui.filter.Filter.SDR["@All"] === 'true')}
                    onChange={(e: React.SyntheticEvent) => this.onSelectSDR('NotSentToState')}
                    disabled={this.props.dataStore.ui.disableStateDataReportingNotSentToState}
                />
            </ContentRowWrapper>
        </>;
    }

    public disableDropDown(domId: any): boolean {

        switch (this.process_lowercase) {
            case "deslstatus":
                const disabledList = ["claimfilter_pay_userfield", "claimfilter_adv_userfield", "claimfilter_elig_userfield", "claimfilter_quickstart_userfield"];
                return disabledList.includes(domId) ? true : false
            default:
                return false
        }

    }


    public renderUserField(domId: string, index: number) {
        var fld = this.props.dataStore.ui.filter.Filter?.UserField;
        if (index == 2)
            fld = this.props.dataStore.ui.filter.Filter?.UserField2;
        else if (index == 3)
            fld = this.props.dataStore.ui.filter.Filter?.UserField3;
        else if (index == 4)
            fld = this.props.dataStore.ui.filter.Filter?.UserField4;

        return <>
            <SelectDropdown
                domID={domId}
                className="col1_userdropdown dropdown"
                disabled={domId === 'claimfilter_adv_userfield' && this.process_lowercase === "deslstatus"}
                isClearable={false}
                isSearchable={true}
                menuWidth={310}
                hasError={this.props.dataStore.ui.focusField == domId}
                onChange={(option: SelectDropdownValue) => this.updateUserFieldId(option, index)}
                options={this.getLookupListIntialItem(this.props.dataStore.api.data?.ListPopulation?.UserFieldList, ["- Select Field -", ""])}
                initialValue={this.getLookupList_SelectedItem2(this.props.dataStore.api.data?.ListPopulation?.UserFieldList,
                    fld, "@ID", { label: "- Select Field -", value: '' })}

            />
            {this.renderUserFieldInput(domId, fld, index)}
        </>;
    }

    isSpecialUserFieldRadio(id: string) {
        if (id == STATUS_AP_PENDING_ID)
            return true;

        var idNum: number = parseInt(id, 10);
        if (!isNaN(idNum) && idNum >= SINCE_RANGE_FROM_ID && idNum <= SINCE_RANGE_TO_ID)
            return true;

        return false;
    }

    renderUserFieldInput(domId: string, fld: any, index: number) {
        var UserFieldID = this.getData(fld, "@ID");

        if (UserFieldID === STATE_REPORTING_STATE_ID) {
            return <SelectDropdown
                domID={domId + "state_input"}
                className="col1_dropdown dropdown col1_sep"
                //disabled={this.process_lowercase === "direct"}
                isClearable={false}
                isSearchable={true}
                size='small'
                hasError={this.props.dataStore.ui.focusField == domId + "state_input"}
                onChange={(e: any) => this.updateUserField(e?.label !== "- Select State -" ? e?.label : '', index)}
                options={this.getLookupListIntialItem(this.props.dataStore.api.data?.ListPopulation?.StateReportStateList, ["- Select State -", ""])}
                initialValue={this.getLookupList_SelectedItem3(this.props.dataStore.api.data?.ListPopulation?.StateReportStateList, 'Name',
                    fld, '@Value', { label: "- Select Type -", value: '' })}

            />
        } else if (this.isSpecialUserFieldRadio(UserFieldID)) {
            return <RadioButtonComponent
                id={domId + "_radio"}
                className={'claim_filter_userfield_radio'}
                checked={true}
                disabled={true}
            //  hasError={this.props.dataStore.ui.focusField == domId + "_radio"}
            />
        } else {
            return <Input
                domID={domId + "_input"} //claimfilter_adv_userfield
                className="col1_userfieldinput col1_sep"
                maxLength={255}
                initialValue={this.getData(fld, "@Value")}
                hasError={this.props.dataStore.ui.focusField == domId + "_input"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateUserField(e.target.value, index)}
            />
        }

    }

    renderReleaseBatch(domId: string) {
        return <SelectDropdown
            domID={domId}
            className="dropdown"
            label="Release Batch:"
            hasError={this.props.dataStore.ui.focusField == domId}
            isClearable={false}
            isSearchable={true}
            onChange={(option: SelectDropdownValue) => this.onReleaseBatchChange(option)}
            menuWidth={390}
            options={this.getLookupListIntialItem(this.props.dataStore.api.data?.ListPopulation?.ReleaseBatchList, ["- Select Release Batch -", ""])}
            initialValue={this.getLookupList_SelectedItem2(this.props.dataStore.api.data?.ListPopulation?.ReleaseBatchList,
                this.props.dataStore.ui.filter.Filter?.ReleaseBatch, "@ID", { label: "- Select Release Batch -", value: '' })}
            disabled={this.props.dataStore.ui.disableReleaseBatch}
        />
    }

    renderSubmittedFile(domId: string) {
        return <SelectDropdown
            domID={domId}
            className="dropdown"
            label="Submitted File:"
            isClearable={false}
            isSearchable={true}
            disabled={this.props.dataStore.ui.disableSubmittedFile || this.props.dataStore.ui.activeTab == 2 || this.props.dataStore.ui.activeTab == 3}
            hasError={this.props.dataStore.ui.focusField == domId}
            onChange={(option: SelectDropdownValue) => this.onSubmittedFileChange(option)}
            menuWidth={390}
            options={this.getLookupListIntialItem(this.props.dataStore.api.data?.ListPopulation?.SubmittedFileList, ["- Select Submitted File -", ""])}
            initialValue={this.getLookupList_SelectedItem2(this.props.dataStore.api.data?.ListPopulation?.SubmittedFileList,
                this.props.dataStore.ui.filter.Filter?.SubmittedFile, "@ID", { label: "- Select Submitted File -", value: '' })}
        />
    }

    renderUser(domId: string) {
        return <SelectDropdown
            domID={domId}
            className="dropdown"
            label="User Name:"
            isClearable={false}
            isSearchable={true}
            menuWidth={390}
            hasError={this.props.dataStore.ui.focusField == domId}
            onChange={(option: SelectDropdownValue) => this.onUserChange(option)}
            options={this.getLookupListIntialItem_User(this.props.dataStore.api.data?.ListPopulation?.UserList, ["- Select User Name -", ""])}
            initialValue={this.getLookupList_SelectedItem2(this.props.dataStore.api.data?.ListPopulation?.UserList,
                this.props.dataStore.ui.filter.Filter?.User, "@ID", { label: "- Select User Name -", value: '' })}

        />
    }

    renderFacilities(domId: string) {
        var facs = this.getSelectedFacilities();
        return <SelectComponent
            className="select-component"
            title={domId}
            size={this.getCurrentTabSize()} // The size is dependant of the tab
            width='345px'
            style={{maxHeight: '130px'}}
            multiple='true'
            tooltip={true}
            //      hasError={this.props.dataStore.ui.focusField == domId}
            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectFacility(e)}
            optionFields={{ value: "value", text: "label" }}
            records={this.getLookupListIntialItem(this.props.dataStore.api.data?.ListPopulation?.FacilityList, ["- All Facilities -", ""])}
            selectedMultiValue={facs}
        >
        </SelectComponent>
    }

    renderNameAccountNum(domId1: string, domId2: string) {
        return <>
            <Input
                domID={domId1}
                className="col1_input"
                label="Control Number:"
                hasError={this.props.dataStore.ui.focusField == domId1}
                maxLength={255}
                initialValue={this.getData(this.props.dataStore.ui.filter.Filter.AccountNumber, "@Value")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateAccountNumber(e.target.value)}
            />

            <Input 
                domID={domId2}
                className="col1_input col1_sep"
                // style={{ width: "250px" }}
                hasError={this.props.dataStore.ui.focusField == domId2}
                label="Last Name:"
                maxLength={255}
                initialValue={this.getData(this.props.dataStore.ui.filter.Filter.PatientName, "@Value")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updatePatientName(e.target.value)}
            />
        </>;
    }
    renderFclassTob(domId1: string, domId2: string) {
        return <>

            <Input
                domID={domId1}
                className="col1_input"
                label="Financial Class:"
                maxLength={255}
                hasError={this.props.dataStore.ui.focusField == domId1}
                initialValue={this.getData(this.props.dataStore.ui.filter.Filter.FinancialClass, "@Value")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateFinancialClass(e.target.value)}
            />
            <Input
                domID={domId2}
                className="col1_input col1_sep"
                label="Type Of Bill:"
                maxLength={255}
                hasError={this.props.dataStore.ui.focusField == domId2}
                initialValue={this.getData(this.props.dataStore.ui.filter.Filter.TypeOfBill, "@Value")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateTypeOfBill(e.target.value)}
            />
        </>;

    }


    renderTotChargesPlanCode(domId1: string, domId2: string) {
        return <>
            <Input
                domID={domId1}
                className="col1_input"
                // style={{ width: "100px", height: "100px" }}
                label="Total Charges:"
                maxLength={255}
                hasError={this.props.dataStore.ui.focusField == domId1}
                initialValue={this.getData(this.props.dataStore.ui.filter.Filter.Amount, "@Value")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateAmount(e.target.value)}
            />

            <Input
                domID={domId2}
                className="col1_input col1_sep"
                label="Plan Code:"
                maxLength={255}
                hasError={this.props.dataStore.ui.focusField == domId2}
                initialValue={this.getData(this.props.dataStore.ui.filter.Filter.PlanCode, "@Value")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updatePlanCode(e.target.value)}
            />
        </>;
    }

    ////////////////////////////
    ////  RENDER
    ////////////////////////////



    public render() {
        var instruction = this.props.process === "viewx12claim" ? <React.Fragment>{this.props.extraInstruction}</React.Fragment> : <React.Fragment>{this.props.instruction + " " + this.props.extraInstruction}</React.Fragment>;
        var buttons = this.getButtons();
        //   console.log('test', this.props.dataStore.api.data)
        // data helpers
        var titleString = "Claim Filter >> " + this.props.title +
            (this.props.dataStore.ui.filterName.length == 0 ? '' : " \"" + this.props.dataStore.ui.filterName + "\"");
        var formTypesData = this.getFormTypes();
        var formTypesSelected = this.getSelectedFormTypes();
        var lobData = this.getLobs();
        var lobSelected = this.getSelectedLobs();
        var errsSelected = this.getSelectedErrorCategories();
        let isNDCUser = !this.isClientUser();
        var hcdomsId = this.getConfigValue("HCDOMSId");

        var loadTabs = this.getTabs();
        var directEntry = this.getDirectEntryActive() ? 'Direct Entry' : 'Aii Status';

        var attmEnabled = this.isAttachmentsEnabled();
        var selectedPayers = this.getSelectedPayers();
        var selectedEligibilityPayers = this.getEligibilityPayers();

        return (
            <DialogWrapper title={titleString} marginTop="30px" instruction={instruction} helpUrl={this.props.helpUrl} buttons={buttons} isBusy={this.props.dataStore.ui.isBusy || this.props.dataStore.api.dataStatus === 'REQUEST'}>
                <ContentWrapper id={"content_wrapper_filter"} style={{backgroundColor: '#fff'}}>
                    <ClaimFilterContent style={{padding: '10px', paddingBottom: '0px', paddingTop: '0px'}}>
                        <ContentRowWrapper id={"claimfilter-tab-container"} style={{ width: '100%' }}>
                            <ContentColumnWrapper>
                                <Tabs
                                    size="small"
                                    tabs={this.getTabs()}
                                    onTabSelect={(e: any, index: any) => { this.onTabSelect(index.selectedTabIndex) }}
                                    initialTab={this.getInitialTab()}
                                />
                            </ContentColumnWrapper>
                        </ContentRowWrapper>

                        { /****** QUICK START TAB ******/}
                        {this.props.dataStore.ui.activeTab == 0 &&

                            <ContentRowWrapper>
                                <ContentColumnWrapper id={"claimfilter-quickstart-container"} style={{ width: '1270px' }}>

                                    <ContentColumnWrapper>
                                        <RadioButtonComponent
                                            id="claimfilter-lastclaimlist"
                                            label="Last Claim List"
                                            checked={this.props.dataStore.ui.quickStart === "0" && this.tabType != "Advanced"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeQuickStart("0")}
                                            disabled={this.isQuickStartDisabled("0")}
                                            onDoubleClick={() => { if (!this.isQuickStartDisabled("0")) this.onSubmitForm() }}  // old asp mapped to a click of submit
                                        />
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper>
                                        <RadioButtonComponent
                                            id="claimfilter-lastfilter"
                                            label="Last Filter"
                                            checked={this.props.dataStore.ui.quickStart === "1"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeQuickStart("1")}
                                            disabled={this.isQuickStartDisabled("1")}
                                            onDoubleClick={() => { if (!this.isQuickStartDisabled("1")) this.onSubmitForm() }} // old asp mapped to a click of submit
                                        />
                                    </ContentColumnWrapper>

                                    <ContentColumnWrapper>
                                        <RadioButtonComponent
                                            id="claimfilter-quickfilter"
                                            label="Quick Claim Filter:"
                                            checked={this.props.dataStore.ui.quickStart === "2"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeQuickStart("2")}
                                            disabled={this.isQuickStartDisabled("2")}
                                            onDoubleClick={() => { this.loadFilter('button') }} // old asp mapped to a click of load...need to test
                                        />

                                        <ContentRowWrapper>

                                            <DialogFieldset id={"claimfilter-quickstart-fs"} style={{paddingLeft: '10px', paddingRight: '10px'}}>
                                                <ContentRowWrapper style={{ width: '100%' }} onMouseDown={() => { this.onChangeQuickStart("2") }} onClick={() => { this.onChangeQuickStart("2") }}>
                                                    <FilterColumn1 id={"claimfilter-quickstart-container_column1"} style={{width: '564px'}}>
                                                        <DialogFieldset id={'cf-quickstart-field-fs'} >
                                                            <DialogLegend3 helpUrl='/Support/Help/HELP_Ranges.htm'>Field</DialogLegend3>
                                                            <ContentRowWrapper>

                                                                <Input
                                                                    domID="claimfilter_quickstart_accountnumber_input"
                                                                    className="quickstart_col1_input"
                                                                    label="Control Number:"
                                                                    maxLength={255}
                                                                    hasError={this.props.dataStore.ui.focusField == "claimfilter_quickstart_accountnumber_input"}
                                                                    initialValue={this.getData(this.props.dataStore.ui.filter.Filter.AccountNumber, "@Value")}
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateAccountNumber(e.target.value)}
                                                                />


                                                                <Input
                                                                    domID="claimfilter_quickstart_patientname_input"
                                                                    className="quickstart_col1_input col1_sep"
                                                                    // style={{ width: "250px" }}
                                                                    label="Last Name:"
                                                                    hasError={this.props.dataStore.ui.focusField == "claimfilter_quickstart_patientname_input"}
                                                                    maxLength={255}
                                                                    initialValue={this.getData(this.props.dataStore.ui.filter.Filter.PatientName, "@Value")}
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updatePatientName(e.target.value)}
                                                                />
                                                            </ContentRowWrapper>

                                                            <ContentRowWrapper style={{ paddingTop: '3px' }}>
                                                                {this.renderDateField('claimfilter_quickstart_datefield', 1, true)}
                                                            </ContentRowWrapper>

                                                            <ContentRowWrapper style={{ paddingTop: '3px' }}>
                                                                {this.renderUserField('claimfilter_quickstart_userfield', 1)}
                                                            </ContentRowWrapper>

                                                            <ContentRowWrapper style={{ paddingTop: '3px' }}>


                                                                <Input
                                                                    domID="claimfilter_quickstart_fclass_input"
                                                                    className="quickstart_col1_input"
                                                                    label="Financial Class:"
                                                                    hasError={this.props.dataStore.ui.focusField == "claimfilter_quickstart_fclass_input"}
                                                                    maxLength={255}
                                                                    initialValue={this.getData(this.props.dataStore.ui.filter.Filter.FinancialClass, "@Value")}
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateFinancialClass(e.target.value)}
                                                                />


                                                                <Input
                                                                    domID="claimfilter_quickstart_tob_input"
                                                                    className="quickstart_col1_input col1_sep"
                                                                    label="Type Of Bill:"
                                                                    maxLength={255}
                                                                    hasError={this.props.dataStore.ui.focusField == "claimfilter_quickstart_tob_input"}
                                                                    initialValue={this.getData(this.props.dataStore.ui.filter.Filter.TypeOfBill, "@Value")}
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateTypeOfBill(e.target.value)}
                                                                />

                                                            </ContentRowWrapper>
                                                            <ContentRowWrapper style={{ paddingTop: '3px'}}>


                                                                <Input
                                                                    domID="claimfilter_quickstart_totalcharges_input"
                                                                    className="quickstart_col1_input "
                                                                    // style={{ width: "250px" }}
                                                                    label="Total Charges:"
                                                                    // hasError
                                                                    errorMessage={this.props.dataStore.ui.focusField}
                                                                    hasError={this.props.dataStore.ui.focusField == "claimfilter_quickstart_totalcharges_input"}
                                                                    maxLength={255}
                                                                    initialValue={this.getData(this.props.dataStore.ui.filter.Filter.Amount, "@Value")}
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateAmount(e.target.value)}
                                                                />
                                                                <div className="quickstart_col1_input col1_sep"></div>
                                                            </ContentRowWrapper>
                                                        </DialogFieldset>
                                                    </FilterColumn1>

                                                    <FilterColumn2 id={"claimfilter-quickstart-container_column2"} style={{width: '367px'}}>
                                                        <ContentRowWrapper>
                                                            <ContentColumnWrapper>
                                                                <ContentRowWrapper>
                                                                    <LobList
                                                                        title='claimfilter_loblist'
                                                                        forms={lobData}
                                                                        selectedValues={lobSelected}

                                                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectLobs(e)}
                                                                        disabled={this.props.dataStore.ui.disableLobs}

                                                                    />
                                                                    <FormTypeList
                                                                        title='claimfilter_ftl'
                                                                        forms={formTypesData}
                                                                        selectedValues={formTypesSelected}
                                                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectFormTypes(e)}
                                                                        disabled={this.props.dataStore.ui.disableFormType}

                                                                    />
                                                                </ContentRowWrapper>
                                                                <SelectDropdown
                                                                    domID="claimfilter_displayorder_qs"
                                                                    className="dropdown"
                                                                    label="Display Order:"
                                                                    disabled={this.process_lowercase === "direct"}
                                                                    isClearable={false}
                                                                    isSearchable={true}
                                                                    onChange={(option: SelectDropdownValue) => this.onDisplayOrderChange(option)}
                                                                    options={this.getLookupList(this.props.dataStore.api.data?.ListPopulation?.DisplayOrderList)}
                                                                    menuWidth={378}
                                                                    initialValue={this.getLookupList_SelectedItem_DisplayOrder()}

                                                                />
                                                                {this.renderReleaseBatch('claimfilter_releasebatch_qs')}
                                                                {this.renderSubmittedFile('claimfilter_submittedfile_qs')}


                                                            </ContentColumnWrapper>




                                                        </ContentRowWrapper>

                                                    </FilterColumn2>

                                                    <FilterColumn3>
                                                        <DialogFieldset id={'claimstatus_fieldset'} >
                                                            <DialogLegend>Claim State</DialogLegend>
                                                            <ContentRowWrapper style={{gap: '0'}}>
                                                                <RadioButtonComponent
                                                                    id="claimfilter-claimstate_qs_unr"
                                                                    label="Unreleased"
                                                                    checked={this.isClaimStateChecked(0)}
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectClaimState('Unreleased')}
                                                                    disabled={this.props.dataStore.ui.disableUnreleased}
                                                                />
                                                                <RadioButtonComponent
                                                                    id="claimfilter-claimstate_qs_rel"
                                                                    label="Released"
        
                                                                    checked={this.isClaimStateChecked(1)}
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectClaimState('Released')}
                                                                    disabled={this.props.dataStore.ui.disableReleased}
                                                                />

                                                            </ContentRowWrapper>

                                                        </DialogFieldset>
                                                        <DialogFieldset id={'claimstatus_fieldset'} >
                                                            <DialogLegend>Claim Status</DialogLegend>
                                                            <ContentRowWrapper>
                                                                
                                                                <Checkbox
                                                                    domID='claimfilter_accepted'
                                                                    label='Accepted'
                                                                    checked={this.props.dataStore.ui.filter.Filter.ClaimStatus && (this.props.dataStore.ui.filter.Filter.ClaimStatus["@Accepted"] === 'A' ||
                                                                        this.props.dataStore.ui.filter.Filter.ClaimStatus["@All"] === 'true')}
                                                                    onChange={(e: React.SyntheticEvent) => this.onSelectClaimStatus('Accepted')}
                                                                />
                                                                
                                                                <Checkbox
                                                                    domID='claimfilter_warning'
                                                                    label='Warning'
                                                                    checked={this.props.dataStore.ui.filter.Filter.ClaimStatus && (this.props.dataStore.ui.filter.Filter.ClaimStatus["@Warning"] === 'W' ||
                                                                        this.props.dataStore.ui.filter.Filter.ClaimStatus["@All"] === 'true')}
                                                                    onChange={(e: React.SyntheticEvent) => this.onSelectClaimStatus('Warning')}
                                                                />
                                                            </ContentRowWrapper>
                                                            <ContentRowWrapper>
                                                                
                                                                <Checkbox
                                                                    domID='claimfilter_rejected'
                                                                    label='Rejected'
                                                                    checked={this.props.dataStore.ui.filter.Filter.ClaimStatus && (this.props.dataStore.ui.filter.Filter.ClaimStatus["@Rejected"] === 'R' ||
                                                                        this.props.dataStore.ui.filter.Filter.ClaimStatus["@All"] === 'true')}
                                                                    onChange={(e: React.SyntheticEvent) => this.onSelectClaimStatus('Rejected')}
                                                                    disabled={this.props.dataStore.ui.disableRejected}
                                                                />
                                                                {false &&
                                                                    <Checkbox
                                                                        domID='claimfilter_unreported'
                                                                        label='Unreported'
                                                                        checked={this.props.dataStore.ui.filter.Filter.ClaimStatus && (this.props.dataStore.ui.filter.Filter.ClaimStatus["@Unreported"] === 'U')}
                                                                        onChange={(e: React.SyntheticEvent) => this.onSelectClaimStatus('Unreported')}
                                                                    />}
                                                            </ContentRowWrapper>
                                                        </DialogFieldset>
                                                        <DialogFieldset id={'claimstatus_transmitype'} >
                                                            <DialogLegend>Transmit Type</DialogLegend>
                                                            <ContentRowWrapper>
                                                                <Checkbox
                                                                    domID='claimfilter_electronic'
                                                                    label='Electronic'
                                                                    checked={this.props.dataStore.ui.filter.Filter.TransmitType && this.props.dataStore.ui.filter.Filter.TransmitType["@Electronic"] === 'Y'}
                                                                    onChange={(e: React.SyntheticEvent) => this.onSelectTransmitType('Electronic')}
                                                                    disabled={this.props.dataStore.ui.disableElectronic}
                                                                />
                                                                
                                                                <Checkbox
                                                                    domID='claimfilter_paper'
                                                                    label='Paper'
                                                                    checked={this.props.dataStore.ui.filter.Filter.TransmitType && this.props.dataStore.ui.filter.Filter.TransmitType["@Paper"] === 'N'}
                                                                    onChange={(e: React.SyntheticEvent) => this.onSelectTransmitType('Paper')}
                                                                    disabled={this.props.dataStore.ui.disablePaper}
                                                                />
                                                            </ContentRowWrapper>
                                                            <ContentRowWrapper>
                                                                
                                                                <Checkbox
                                                                    domID='claimfilter_chprint'
                                                                    label='CH Print'
                                                                    checked={this.props.dataStore.ui.filter.Filter.TransmitType && this.props.dataStore.ui.filter.Filter.TransmitType["@ClearinghousePrint"] === 'A'}
                                                                    onChange={(e: React.SyntheticEvent) => this.onSelectTransmitType('Clearing')}
                                                                    disabled={this.props.dataStore.ui.disableClearinghousePrint}
                                                                />
                                                                
                                                                <Checkbox
                                                                    domID='claimfilter_direct'
                                                                    label='Direct'
                                                                    checked={this.props.dataStore.ui.filter.Filter.TransmitType && this.props.dataStore.ui.filter.Filter.TransmitType["@Direct"] === 'M'}
                                                                    onChange={(e: React.SyntheticEvent) => this.onSelectTransmitType('Direct')}
                                                                    disabled={this.props.dataStore.ui.disableDirect}
                                                                />
                                                            </ContentRowWrapper>
                                                            <ContentRowWrapper>
                                                                
                                                                {isNDCUser && <Checkbox
                                                                    domID='claimfilter_outsourced'
                                                                    label='Outsourced'
                                                                    checked={this.props.dataStore.ui.filter.Filter.TransmitType && this.props.dataStore.ui.filter.Filter.TransmitType["@Outsourced"] === 'O'}
                                                                    onChange={(e: React.SyntheticEvent) => this.onSelectTransmitType('Outsourced')}
                                                                    disabled={this.props.dataStore.ui.disableOutsourced}
                                                                />}
                                                                {hcdomsId && hcdomsId !== "" &&
                                                                    <Checkbox
                                                                        domID='claimfilter_hcd'
                                                                        label='HCD'
                                                                        checked={this.props.dataStore.ui.filter.Filter.TransmitType && this.props.dataStore.ui.filter.Filter.TransmitType["@HCD"] === 'H'}
                                                                        onChange={(e: React.SyntheticEvent) => this.onSelectTransmitType('HCD')}
                                                                        disabled={this.props.dataStore.ui.disableHCD}
                                                                    />
                                                                }
                                                                
                                                            </ContentRowWrapper>
                                                        </DialogFieldset>
                                                        <DialogFieldset id={'service_type'} >
                                                            <DialogLegend>Service Type</DialogLegend>
                                                            <ContentRowWrapper>
                                                                {/* <CheckBoxComponent
                                                                    id='claimfilter_servicetype'
                                                                    label='Inpatient'
                                                                    checked={this.props.dataStore.ui.filter.Filter.ServiceType &&
                                                                        (this.props.dataStore.ui.filter.Filter.ServiceType["@Inpatient"] === 'I' ||
                                                                            this.props.dataStore.ui.filter.Filter.ServiceType["@All"] === 'true')}
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectServiceType('Inpatient')}
                                                                /> */}
                                                                <Checkbox
                                                                    domID='claimfilter_servicetype'
                                                                    label='Inpatient'
                                                                    checked={this.props.dataStore.ui.filter.Filter.ServiceType &&
                                                                        (this.props.dataStore.ui.filter.Filter.ServiceType["@Inpatient"] === 'I' ||
                                                                            this.props.dataStore.ui.filter.Filter.ServiceType["@All"] === 'true')}
                                                                    onChange={(e: React.SyntheticEvent) => this.onSelectServiceType('Inpatient')}
                                                                />
                                                                {/* <CheckBoxComponent
                                                                    id='claimfilter_servicetype'
                                                                    label='Outpatient'
                                                                    checked={this.props.dataStore.ui.filter.Filter.ServiceType &&
                                                                        (this.props.dataStore.ui.filter.Filter.ServiceType["@Outpatient"] === 'O' ||
                                                                            this.props.dataStore.ui.filter.Filter.ServiceType["@All"] === 'true')}
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectServiceType('Outpatient')}
                                                                /> */}
                                                                <Checkbox
                                                                    domID='claimfilter_servicetype'
                                                                    label='Outpatient'
                                                                    checked={this.props.dataStore.ui.filter.Filter.ServiceType &&
                                                                        (this.props.dataStore.ui.filter.Filter.ServiceType["@Outpatient"] === 'O' ||
                                                                            this.props.dataStore.ui.filter.Filter.ServiceType["@All"] === 'true')}
                                                                    onChange={(e: React.SyntheticEvent) => this.onSelectServiceType('Outpatient')}
                                                                />
                                                            </ContentRowWrapper>
                                                        </DialogFieldset>


                                                    </FilterColumn3>


                                                </ContentRowWrapper>
                                            </DialogFieldset>



                                        </ContentRowWrapper>





                                    </ContentColumnWrapper>



                                    <ContentColumnWrapper>
                                        <RadioButtonComponent
                                            id="claimfilter-savedfilter"
                                            label={this.hasFavorites ? "Saved Filter (favorites are shown in blue)" : "Saved Filter"}

                                            checked={this.props.dataStore.ui.quickStart === "3"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeQuickStart("3")}
                                            disabled={this.isQuickStartDisabled("3")}
                                        />
                                    </ContentColumnWrapper>
                                    <ContentRowWrapper id={"claimfilter-savedfilter-tab-container"} style={{ marginLeft: '20px', width: '40%' }} onMouseDown={() => { this.onChangeQuickStart("3") }}>
                                        <ContentColumnWrapper>
                                            <Tabs
                                                tabs={this.getSavedFilterTabs(this.isQuickStartDisabled("3"))}
                                                size="small"
                                                onTabSelect={(e: any, index: any) => { this.setActiveSFTab(index.selectedTabIndex) }}
                                                initialTab={this.getInitialSFTab()}
                                            />
                                        </ContentColumnWrapper>
                                    </ContentRowWrapper>
                                    <ContentRowWrapper style={{ marginLeft: '20px', width: '40%' }}>

                                        <SelectComponent
                                            className="select-component"
                                            title={'claimfilter_sflist'}
                                            size={12}
                                            width='100%'
                                            style={{maxHeight: '150px'}}
                                            disabled={this.isQuickStartDisabled("3")}
                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectFilter(e)}
                                            optionFields={{ value: "value", text: "label", fav: "fav" }}
                                            records={this.getSavedFilterList()}
                                            onDoubleClick={(e: React.ChangeEvent<HTMLSelectElement>) => { this.onSubmitClick(this.submitFilter.bind(this)) /* this.onSelectFilter(e); this.onSubmitClick()*/ }}  // old asp mapped to a click of submit
                                        //selectedValue={}
                                        >
                                        </SelectComponent>

                                    </ContentRowWrapper>




                                </ContentColumnWrapper>
                            </ContentRowWrapper>
                        }

                        { /****** ADVANCED TAB ******/}
                        {(this.props.dataStore.ui.activeTab == 1 || this.props.dataStore.ui.activeTab == undefined) &&

                            <ContentRowWrapper style={{marginBottom: '0px'}}>
                                <FilterColumn1 id={"claimfilter-advanced-container_column1"} style={{width: '564px'}}>
                                    <DialogFieldset id={'field_fieldset_adv'} >
                                        <DialogLegend3 helpUrl='/Support/Help/HELP_Ranges.htm'>Field</DialogLegend3>
                                        <ContentRowWrapper style={{marginBottom: '5px'}}>
                                            {this.renderNameAccountNum("claimfilter_adv_accountnumber_input", "claimfilter_adv_patientname_input")}
                                        </ContentRowWrapper>

                                        <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                            {this.renderDateField('claimfilter_adv_datefield', 1, false)}
                                        </ContentRowWrapper>
                                        <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                            {this.renderUserField('claimfilter_adv_userfield', 1)}
                                        </ContentRowWrapper>

                                        <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                            {this.renderUserField('claimfilter_adv_userfieldsec', 2)}
                                        </ContentRowWrapper>

                                        <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                            {this.renderUserField('claimfilter_adv_userfieldsec2', 3)}
                                        </ContentRowWrapper>

                                        <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                            {this.renderUserField('claimfilter_adv_userfieldsec3', 4)}
                                        </ContentRowWrapper>

                                        <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                            {this.renderFclassTob("claimfilter_adv_fclass_input", "claimfilter_adv_tob_input")}
                                        </ContentRowWrapper>
                                        <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                            {this.renderTotChargesPlanCode("claimfilter_adv_totalcharges_input", "claimfilter_adv_plancode_input")}

                                        </ContentRowWrapper>

                                    </DialogFieldset>
                                    <DialogFieldset id={'payer_fieldset'} >
                                        <DialogLegend>Payer</DialogLegend>
                                        <ContentRowWrapper style={{ width: '500px', marginBottom: '0px' }}>
                                            <RadioButtonComponent
                                                id="claimfilter-payertype"
                                                label="Active Payer"
                                                checked={this.getData(this.props.dataStore.ui.filter.Filter.PayerType, '@Type') === 'Active' ||
                                                    this.getData(this.props.dataStore.ui.filter.Filter.PayerType, '@Type') === ''}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectPayerType('Active')}
                                            />
                                            <RadioButtonComponent
                                                id="claimfilter-payertype"
                                                label="Primary Payer"
                                                checked={this.getData(this.props.dataStore.ui.filter.Filter.PayerType, '@Type') === 'Primary'}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectPayerType('Primary')}
                                            />
                                            <RadioButtonComponent
                                                id="claimfilter-payertype"
                                                label="Secondary Payer"
                                                checked={this.getData(this.props.dataStore.ui.filter.Filter.PayerType, '@Type') === 'Secondary'}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectPayerType('Secondary')}
                                            />
                                        </ContentRowWrapper>
                                        <ContentRowWrapper style={{marginBottom: '0px'}}>
                                            <SelectComponent
                                                className="select-component"
                                                title={'claimfilter_payer_select'}
                                                width='370px'
                                                multiple='true'
                                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectPayers(e)}
                                                optionFields={{ value: "value", text: "label" }}
                                                records={this.getSelectedPayerRecords()}
                                                selectedMultiValue={selectedPayers}
                                            >
                                            </SelectComponent>
                                        </ContentRowWrapper>
                                    </DialogFieldset>

                                    <DialogFieldset id={"claimnote_fieldset_pay"} style={{height: '136px'}}>
                                        <DialogLegend3 helpUrl='/Support/Help/HELP_FilterClaimNotes.htm'>Claim Note</DialogLegend3>
                                        {this.renderClaimNoteSection('claimfilter_pay_claimnote')}

                                    </DialogFieldset>

                                </FilterColumn1>


                                <ContentColumnWrapper id={"claimfilter-advanced-container"} >
                                    <ContentRowWrapper style={{marginBottom: '0px'}}>
                                        <FilterColumn2 style={{width: '367px'}}>
                                            <ContentRowWrapper style={{marginBottom: '5px'}}>
                                                <LobList
                                                    title='claimfilter_loblist'
                                                    forms={lobData}
                                                    selectedValues={lobSelected}
                                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectLobs(e)}
                                                    disabled={this.props.dataStore.ui.disableLobs}

                                                />
                                                <FormTypeList
                                                    title='claimfilter_ftl'
                                                    forms={formTypesData}
                                                    selectedValues={formTypesSelected}
                                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectFormTypes(e)}
                                                    disabled={this.props.dataStore.ui.disableFormType}

                                                />
                                            </ContentRowWrapper>
                                            <SelectDropdown
                                                domID="claimfilter_displayorder_adv"
                                                className="dropdown"
                                                label="Display Order:"
                                                disabled={this.process_lowercase === "direct"}
                                                isClearable={false}
                                                isSearchable={true}
                                                onChange={(option: SelectDropdownValue) => this.onDisplayOrderChange(option)}
                                                options={this.getLookupList(this.props.dataStore.api.data?.ListPopulation?.DisplayOrderList)}
                                                menuWidth={390}
                                                initialValue={this.getLookupList_SelectedItem_DisplayOrder()}

                                            />
                                            {this.renderReleaseBatch('claimfilter_releasebatch_adv')}

                                            {this.renderSubmittedFile('claimfilter_submittedfile_adv')}
                                            {this.renderUser('claimfilter_user_adv')}

                                            <DialogFieldset id={'facility_fieldset_adv'} >
                                                <DialogLegend>Facility</DialogLegend>
                                                {this.renderFacilities('claimfilter_facility_adv')}

                                            </DialogFieldset>
                                            <DialogFieldset id={'sdr_fieldset_adv'} >
                                                <DialogLegend>State Data Reporting</DialogLegend>
                                                {this.renderStateDataReporting('claimfilter_sdr_adv')}

                                            </DialogFieldset>
                                            <DialogFieldset id={'lastattachment_fieldset'} >
                                                <DialogLegend3 helpUrl='/Support/Help/HELP_FindAttachments.htm'>Last Attachment</DialogLegend3>
                                                <ContentRowWrapper style={{marginBottom: '0px'}}>

                                                    <Checkbox
                                                        domID='claimfilter_noattachment'
                                                        label='No Attachment'
                                                        disabled={!attmEnabled}
                                                        checked={this.props.dataStore.ui.filter.Filter.LastAttachment && this.props.dataStore.ui.filter.Filter.LastAttachment["@None"] === 'N'}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectLastAttachment('None')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{marginBottom: '0px'}}>
                                                    
                                                    <Checkbox
                                                        domID='claimfilter_created'
                                                        label='Created'
                                                        disabled={!attmEnabled}
                                                        checked={this.props.dataStore.ui.filter.Filter.LastAttachment && this.props.dataStore.ui.filter.Filter.LastAttachment["@Created"] === 'C'}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectLastAttachment('Created')}
                                                    />
                                                    
                                                    <Checkbox
                                                        domID='claimfilter_pending'
                                                        label='Pending'
                                                        disabled={!attmEnabled}
                                                        checked={this.props.dataStore.ui.filter.Filter.LastAttachment && this.props.dataStore.ui.filter.Filter.LastAttachment["@Pending"] === 'P'}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectLastAttachment('Pending')}
                                                    />
                                                    
                                                    <Checkbox
                                                        domID='claimfilter_error'
                                                        label='Error'
                                                        disabled={!attmEnabled}
                                                        checked={this.props.dataStore.ui.filter.Filter.LastAttachment && this.props.dataStore.ui.filter.Filter.LastAttachment["@Error"] === 'E'}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectLastAttachment('Error')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{marginBottom: '0px'}}>
                                                    
                                                    <Checkbox
                                                        domID='claimfilter_sent'
                                                        label='Sent'
                                                        disabled={!attmEnabled}
                                                        checked={this.props.dataStore.ui.filter.Filter.LastAttachment && this.props.dataStore.ui.filter.Filter.LastAttachment["@Sent"] === 'S'}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectLastAttachment('Sent')}
                                                    />
                                                    
                                                    <Checkbox
                                                        domID='claimfilter_ackd'
                                                        label='Ackd'
                                                        disabled={!attmEnabled}
                                                        checked={this.props.dataStore.ui.filter.Filter.LastAttachment && this.props.dataStore.ui.filter.Filter.LastAttachment["@Acknowledged"] === 'A'}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectLastAttachment('Acknowledged')}
                                                    />
                                                    
                                                    <Checkbox
                                                        domID='claimfilter_la_rejected'
                                                        label='Rejected'
                                                        disabled={!attmEnabled}
                                                        checked={this.props.dataStore.ui.filter.Filter.LastAttachment && this.props.dataStore.ui.filter.Filter.LastAttachment["@Rejected"] === 'R'}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectLastAttachment('Rejected')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>


                                                    <ARMDatePicker
                                                        className="col2_sep"
                                                        domID="claimfilter_adv_lastattachmentfrom"
                                                        label="Create Date:"
                                                        onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onLastAttachmentDateChange(e, true)}
                                                        allowTime={false}
                                                        disabled={!attmEnabled}
                                                        minDate={this.minDate}
                                                        maxDate={this.maxDate}
                                                        assocTo={this.getData(this.props.dataStore.ui.filter?.Filter?.LastAttachment, '@To')}
                                                        initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.LastAttachment, '@From')}
                                                    />
                                                    <ARMDatePicker
                                                        domID="claimfilter_adv_lastattachmentto"
                                                        className="col3_sep"
                                                        disabled={!attmEnabled}
                                                        onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onLastAttachmentDateChange(e, false)}
                                                        allowTime={false}
                                                        minDate={this.minDate}
                                                        maxDate={this.maxDate}
                                                        assocFrom={this.getData(this.props.dataStore.ui.filter?.Filter?.LastAttachment, '@From')}
                                                        initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.LastAttachment, '@To')}
                                                    />

                                                </ContentRowWrapper>
                                            </DialogFieldset>

                                        </FilterColumn2>

                                        <FilterColumn3>
                                            <DialogFieldset id={'claimstatus_fieldset'} >
                                                <DialogLegend>Claim State</DialogLegend>
                                                {this.renderClaimState('adv')}

                                            </DialogFieldset>
                                            <DialogFieldset id={'claimstatus_fieldset'} >
                                                <DialogLegend>Claim Status</DialogLegend>
                                                <ContentRowWrapper >
                                                    
                                                    <Checkbox
                                                        domID='claimfilter_accepted'
                                                        label='Accepted'
                                                        checked={this.props.dataStore.ui.filter.Filter.ClaimStatus && (this.props.dataStore.ui.filter.Filter.ClaimStatus["@Accepted"] === 'A' ||
                                                            this.props.dataStore.ui.filter.Filter.ClaimStatus["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectClaimStatus('Accepted')}
                                                    />
                                                    
                                                    <Checkbox
                                                        domID='claimfilter_warning'
                                                        label='Warning'
                                                        checked={this.props.dataStore.ui.filter.Filter.ClaimStatus && (this.props.dataStore.ui.filter.Filter.ClaimStatus["@Warning"] === 'W' ||
                                                            this.props.dataStore.ui.filter.Filter.ClaimStatus["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectClaimStatus('Warning')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper >
                                                    
                                                    <Checkbox
                                                        domID='claimfilter_rejected'
                                                        label='Rejected'
                                                        checked={this.props.dataStore.ui.filter.Filter.ClaimStatus && (this.props.dataStore.ui.filter.Filter.ClaimStatus["@Rejected"] === 'R' ||
                                                            this.props.dataStore.ui.filter.Filter.ClaimStatus["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectClaimStatus('Rejected')}
                                                        disabled={this.props.dataStore.ui.disableRejected}
                                                    />
                                                    {false &&
                                                        <Checkbox
                                                            domID='claimfilter_unreported'
                                                            label='Unreported'
                                                            checked={this.props.dataStore.ui.filter.Filter.ClaimStatus && (this.props.dataStore.ui.filter.Filter.ClaimStatus["@Unreported"] === 'U')}
                                                            onChange={(e: React.SyntheticEvent) => this.onSelectClaimStatus('Unreported')}
                                                        />}
                                                </ContentRowWrapper>
                                            </DialogFieldset>
                                            <DialogFieldset id={'claimstatus_transmitype'} >
                                                <DialogLegend>Transmit Type</DialogLegend>
                                                <ContentRowWrapper >
                                                    
                                                    <Checkbox
                                                        domID='claimfilter_electronic'
                                                        label='Electronic'
                                                        checked={this.props.dataStore.ui.filter.Filter.TransmitType && this.props.dataStore.ui.filter.Filter.TransmitType["@Electronic"] === 'Y'}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectTransmitType('Electronic')}
                                                        disabled={this.props.dataStore.ui.disableElectronic}
                                                    />
                                                    
                                                    <Checkbox
                                                        domID='claimfilter_paper'
                                                        label='Paper'
                                                        checked={this.props.dataStore.ui.filter.Filter.TransmitType && this.props.dataStore.ui.filter.Filter.TransmitType["@Paper"] === 'N'}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectTransmitType('Paper')}
                                                        disabled={this.props.dataStore.ui.disablePaper}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper >
                                                    
                                                    <Checkbox
                                                        domID='claimfilter_chprint'
                                                        label='CH Print'
                                                        checked={this.props.dataStore.ui.filter.Filter.TransmitType && this.props.dataStore.ui.filter.Filter.TransmitType["@ClearinghousePrint"] === 'A'}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectTransmitType('Clearing')}
                                                        disabled={this.props.dataStore.ui.disableClearinghousePrint}
                                                    />
                                                    
                                                    <Checkbox
                                                        domID='claimfilter_direct'
                                                        label='Direct'
                                                        checked={this.props.dataStore.ui.filter.Filter.TransmitType && this.props.dataStore.ui.filter.Filter.TransmitType["@Direct"] === 'M'}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectTransmitType('Direct')}
                                                        disabled={this.props.dataStore.ui.disableDirect}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    {isNDCUser && < Checkbox
                                                        domID='claimfilter_outsourced'
                                                        label='Outsourced'
                                                        checked={this.props.dataStore.ui.filter.Filter.TransmitType && this.props.dataStore.ui.filter.Filter.TransmitType["@Outsourced"] === 'O'}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectTransmitType('Outsourced')}
                                                        disabled={this.props.dataStore.ui.disableOutsourced}
                                                    />}
                                                    {hcdomsId && hcdomsId !== "" &&
                                                        <Checkbox
                                                            domID='claimfilter_hcd'
                                                            label='HCD'
                                                            checked={this.props.dataStore.ui.filter.Filter.TransmitType && this.props.dataStore.ui.filter.Filter.TransmitType["@HCD"] === 'H'}
                                                            onChange={(e: React.SyntheticEvent) => this.onSelectTransmitType('HCD')}
                                                            disabled={this.props.dataStore.ui.disableHCD}
                                                        />}
                                                </ContentRowWrapper>
                                            </DialogFieldset>
                                            <DialogFieldset id={'service_type'} >
                                                <DialogLegend>Service Type</DialogLegend>
                                                <ContentRowWrapper style={{marginBottom: '0px'}}>
                                                    <Checkbox
                                                        domID='claimfilter_servicetype'
                                                        label='Inpatient'
                                                        checked={this.props.dataStore.ui.filter.Filter.ServiceType &&
                                                            (this.props.dataStore.ui.filter.Filter.ServiceType["@Inpatient"] === 'I' ||
                                                                this.props.dataStore.ui.filter.Filter.ServiceType["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectServiceType('Inpatient')}
                                                    />
                                                    <Checkbox
                                                        domID='claimfilter_servicetype'
                                                        label='Outpatient'
                                                        checked={this.props.dataStore.ui.filter.Filter.ServiceType &&
                                                            (this.props.dataStore.ui.filter.Filter.ServiceType["@Outpatient"] === 'O' ||
                                                                this.props.dataStore.ui.filter.Filter.ServiceType["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectServiceType('Outpatient')}
                                                    />
                                                </ContentRowWrapper>
                                            </DialogFieldset>
                                            <DialogFieldset id={'claimswithholdcode'} >
                                                <DialogLegend>Claims With Hold Code </DialogLegend>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_claimswithholdcode'
                                                        label='Yes'
                                                        checked={this.props.dataStore.ui.filter.Filter.ClaimsWithHoldCodeYes &&
                                                            (this.props.dataStore.ui.filter.Filter.ClaimsWithHoldCodeYes["@ClaimsWithHoldCodeYes"] === 'Y')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectClaimsWithHoldCodeYes('Yes')}
                                                    />
                                                    <Checkbox
                                                        domID='claimfilter_claimswithholdcode'
                                                        label='No'
                                                        checked={this.props.dataStore.ui.filter.Filter.ClaimsWithHoldCodeNo &&
                                                            (this.props.dataStore.ui.filter.Filter.ClaimsWithHoldCodeNo["@ClaimsWithHoldCodeNo"] === 'Y')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectClaimsWithHoldCodeYes('No')}
                                                    />
                                                </ContentRowWrapper>
                                            </DialogFieldset>
                                            <DialogFieldset id={'billingoption'} >
                                                <DialogLegend>Billing Option </DialogLegend>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_billingoptionprimary'
                                                        label='Primary'
                                                        checked={this.props.dataStore.ui.filter.Filter.BillingOption &&
                                                            (this.props.dataStore.ui.filter.Filter.BillingOption["@Primary"] === 'A' ||
                                                                this.props.dataStore.ui.filter.Filter.BillingOption["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectBillingOption('Primary')}
                                                        disabled={this.props.dataStore.ui.disableBillingOptionPrimary}
                                                    />
                                                    <Checkbox
                                                        domID='claimfilter_billingoptionrebill'
                                                        label='Rebill'
                                                        checked={this.props.dataStore.ui.filter.Filter.BillingOption &&
                                                            (this.props.dataStore.ui.filter.Filter.BillingOption["@Rebill"] === 'R' ||
                                                                this.props.dataStore.ui.filter.Filter.BillingOption["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectBillingOption('Rebill')}
                                                        disabled={this.props.dataStore.ui.disableBillingOptionRebill}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_billingoptionsecondarym'
                                                        label='Secondary (Manual)'
                                                        checked={this.props.dataStore.ui.filter.Filter.BillingOption &&
                                                            (this.props.dataStore.ui.filter.Filter.BillingOption["@Secondary"] === 'B' ||
                                                                this.props.dataStore.ui.filter.Filter.BillingOption["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectBillingOption('Secondary')}
                                                        disabled={this.props.dataStore.ui.disableBillingOptionSecondaryManual}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_billingoptionsecondarya'
                                                        label='Secondary (Automated)'
                                                        checked={this.props.dataStore.ui.filter.Filter.BillingOption &&
                                                            (this.props.dataStore.ui.filter.Filter.BillingOption["@SecondaryFromRemit"] === 'C' ||
                                                                this.props.dataStore.ui.filter.Filter.BillingOption["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectBillingOption('SecondaryFromRemit')}
                                                        disabled={this.props.dataStore.ui.disableBillingOptionSecondaryAutomated}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_billingoptiontertiaryym'
                                                        label='Tertiary (Manual)'
                                                        checked={this.props.dataStore.ui.filter.Filter.BillingOption &&
                                                            (this.props.dataStore.ui.filter.Filter.BillingOption["@Tertiary"] === 'D' ||
                                                                this.props.dataStore.ui.filter.Filter.BillingOption["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectBillingOption('Tertiary')}
                                                        disabled={this.props.dataStore.ui.disableBillingOptionTertiaryManual}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_billingoptiontertiarya'
                                                        label='Tertiary (Automated)'
                                                        checked={this.props.dataStore.ui.filter.Filter.BillingOption &&
                                                            (this.props.dataStore.ui.filter.Filter.BillingOption["@TertiaryFromRemit"] === 'E' ||
                                                                this.props.dataStore.ui.filter.Filter.BillingOption["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectBillingOption('TertiaryFromRemit')}
                                                        disabled={this.props.dataStore.ui.disableBillingOptionTertiaryAutomated}
                                                    />
                                                </ContentRowWrapper>
                                            </DialogFieldset>

                                            <DialogFieldset id={'claimstatus_directentry'} >
                                                <DialogLegend>{directEntry}</DialogLegend>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_notqueried'
                                                        label='Not Queried'
                                                        disabled={this.props.dataStore.ui.disableNotQueried}
                                                        checked={this.props.dataStore.ui.filter.Filter.AiiStatus && (
                                                            this.props.dataStore.ui.filter.Filter.AiiStatus["@NotQueried"] === '1' || this.props.dataStore.ui.filter.Filter.AiiStatus["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectAiiStatus('NotQueried')}
                                                    />
                                                    <Checkbox
                                                        domID='claimfilter_pended'
                                                        label='Pended'
                                                        disabled={this.props.dataStore.ui.disablePended}
                                                        checked={this.props.dataStore.ui.filter.Filter.AiiStatus &&
                                                            (this.props.dataStore.ui.filter.Filter.AiiStatus["@AiiPended"] === '3' || this.props.dataStore.ui.filter.Filter.AiiStatus["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectAiiStatus('AiiPended')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_denied'
                                                        label='Denied'
                                                        disabled={this.props.dataStore.ui.disableDenied}
                                                        checked={this.props.dataStore.ui.filter.Filter.AiiStatus &&
                                                            (this.props.dataStore.ui.filter.Filter.AiiStatus["@AiiDenied"] === '2' || this.props.dataStore.ui.filter.Filter.AiiStatus["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectAiiStatus('AiiDenied')}
                                                    />
                                                    <Checkbox
                                                        domID='claimfilter_paid'
                                                        label='Paid'
                                                        disabled={this.props.dataStore.ui.disablePaid}
                                                        checked={this.props.dataStore.ui.filter.Filter.AiiStatus &&
                                                            (this.props.dataStore.ui.filter.Filter.AiiStatus["@AiiPaid"] === '5' || this.props.dataStore.ui.filter.Filter.AiiStatus["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectAiiStatus('AiiPaid')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_exception'
                                                        label='Exception'
                                                        disabled={this.props.dataStore.ui.disableException}
                                                        checked={this.props.dataStore.ui.filter.Filter.AiiStatus &&
                                                            (this.props.dataStore.ui.filter.Filter.AiiStatus["@AiiException"] === '4' || this.props.dataStore.ui.filter.Filter.AiiStatus["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectAiiStatus('AiiException')}
                                                    />
                                                </ContentRowWrapper>
                                            </DialogFieldset>
                                            <DialogFieldset id={'errorcatagory_fieldset'} style={{height: '142px'}}>
                                                <DialogLegend3 helpUrl='/Support/Help/ErrorCategories.pdf'>Error Category</DialogLegend3>
                                                <ContentRowWrapper style={{marginBottom: '0px'}}>
                                                    <SelectComponent
                                                        className="select-component"
                                                        title={'claimfilter_errorcatagory_select'}
                                                        size={5}
                                                        //className={'_select'}
                                                        width='100%'
                                                        height='112px'
                                                        multiple='true'
                                                        tooltip={true}
                                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectErrorCategories(e)}
                                                        optionFields={{ value: "value", text: "label" }}
                                                        records={this.getLookupListIntialItem(this.props.dataStore.api.data?.ListPopulation?.ErrorCategoryList, ["- All Categories -", ""])}
                                                        selectedMultiValue={errsSelected}
                                                    >
                                                    </SelectComponent>
                                                </ContentRowWrapper>
                                            </DialogFieldset>
                                        </FilterColumn3>
                                    </ContentRowWrapper>
                                </ContentColumnWrapper>
                            </ContentRowWrapper>
                        }

                        { /****** PAYER STATUS TAB ******/}
                        {(this.props.dataStore.ui.activeTab == 2) &&

                            <ContentRowWrapper>
                                <ContentColumnWrapper id={"claimfilter-payerstatus-container"} >
                                    <ContentRowWrapper>
                                        <FilterColumn1 id={"claimfilter-payerstatus-container_column1"} style={{width: '564px'}}>
                                            <DialogFieldset id={'field_fieldset_pay'} >
                                                <DialogLegend3 helpUrl='/Support/Help/HELP_Ranges.htm'>Field</DialogLegend3>
                                                <ContentRowWrapper style={{marginBottom: '5px'}}>
                                                    {this.renderNameAccountNum("claimfilter_pay_accountnumber_input", "claimfilter_pay_patientname_input")}

                                                </ContentRowWrapper>

                                                <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                                    {this.renderDateField('claimfilter_pay_datefield', 1, false)}
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                                    {this.renderUserField('claimfilter_pay_userfield', 1)}
                                                </ContentRowWrapper>

                                                <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                                    {this.renderUserField('claimfilter_pay_userfieldsec', 2)}
                                                </ContentRowWrapper>

                                                <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                                    {this.renderUserField('claimfilter_pay_userfieldsec2', 3)}
                                                </ContentRowWrapper>

                                                <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                                    {this.renderUserField('claimfilter_pay_userfieldsec3', 4)}
                                                </ContentRowWrapper>

                                                <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                                    {this.renderFclassTob("claimfilter_pay_fclass_input", "claimfilter_pay_tob_input")}

                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                                    {this.renderTotChargesPlanCode("claimfilter_pay_totalcharges_input", "claimfilter_pay_plancode_input")}

                                                </ContentRowWrapper>
                                            </DialogFieldset>
                                            <DialogFieldset>
                                                <DialogLegend>Payer</DialogLegend>
                                                <ContentRowWrapper >
                                                    <SelectComponent
                                                        className="select-component"
                                                        title={'claimfilter_payer_select_pay'}
                                                        multiple='true'
                                                        size={6}
                                                        width='550px'
                                                        height='100px'
                                                        disabled={false}
                                                        optionFields={{ value: "value", text: "label" }}
                                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectPayers(e)}
                                                        records={this.getSelectedPayerRecords()}
                                                        selectedMultiValue={selectedPayers}
                                                    />
                                                </ContentRowWrapper>
                                            </DialogFieldset>
                                            <DialogFieldset id={'claimnote_fieldset_pay'} style={{height: '193px'}}>
                                                <DialogLegend3 helpUrl='/Support/Help/HELP_FilterClaimNotes.htm'>Claim Note</DialogLegend3>
                                                {this.renderClaimNoteSection('claimfilter_pay_claimnote')}

                                            </DialogFieldset>
                                        </FilterColumn1>

                                        <FilterColumn2 style={{width: '367px'}}>
                                            <ContentRowWrapper style={{marginBottom: '5px'}}>
                                                <LobList
                                                    title='claimfilter_loblist'
                                                    forms={lobData}
                                                    selectedValues={lobSelected}
                                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectLobs(e)}
                                                    disabled={this.props.dataStore.ui.disableLobs}

                                                />
                                                <FormTypeList
                                                    title='claimfilter_ftl'
                                                    forms={formTypesData}
                                                    selectedValues={formTypesSelected}
                                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectFormTypes(e)}
                                                    disabled={this.props.dataStore.ui.disableFormType}

                                                />
                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ paddingTop: '0px', marginBottom: '0px' }}>
                                                <SelectDropdown
                                                    domID="claimfilter_displayorder_pay"
                                                    className="dropdown"
                                                    label="Display Order:"
                                                    disabled={this.props.dataStore.ui.disableDisplayOrder || this.process_lowercase === "direct"}
                                                    isClearable={false}
                                                    isSearchable={true}
                                                    onChange={(option: SelectDropdownValue) => this.onDisplayOrderChange(option)}
                                                    options={this.getLookupList(this.props.dataStore.api.data?.ListPopulation?.DisplayOrderList)}

                                                    initialValue={this.getLookupList_SelectedItem_DisplayOrder()}

                                                />
                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ paddingTop: '0px', marginBottom: '0px' }}>
                                                {this.renderReleaseBatch('claimfilter_releasebatch_pay')}

                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ paddingTop: '0px', marginBottom: '0px' }}>
                                                {this.renderSubmittedFile('claimfilter_submittedfile_pay')}

                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ paddingTop: '0px', marginBottom: '0px' }}>
                                                {this.renderUser('claimfilter_user_pay')}
                                            </ContentRowWrapper>
                                            <DialogFieldset id={'facility_fieldset_pay'} >
                                                <DialogLegend>Facility</DialogLegend>
                                                {this.renderFacilities('claimfilter_facility_pay')}

                                            </DialogFieldset>
                                            <DialogFieldset id={'start_data_fieldset_pay'} >
                                                <DialogLegend>State Data Reporting</DialogLegend>
                                                {this.renderStateDataReporting('claimfilter_sdr_pay')}
                                            </DialogFieldset>
                                            <DialogFieldset id={'Appeals_fieldset_pay'} >
                                                <DialogLegend3 helpUrl='/Support/Help/HELP_FindAppeals.htm'>Appeals</DialogLegend3>
                                                <ContentRowWrapper style={{marginBottom: '0px'}}>
                                                    <Checkbox
                                                        domID='claimfilter_created_pay'
                                                        label='Created'
                                                        checked={this.props.dataStore.ui.filter.Filter.AppealStatus && this.props.dataStore.ui.filter.Filter.AppealStatus["@Created"] === 'C'}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectAppeals('Created')}
                                                    />
                                                    <Checkbox
                                                        domID='claimfilter_Succeeded_pay'
                                                        label='Succeeded'

                                                        checked={this.props.dataStore.ui.filter.Filter.AppealStatus && this.props.dataStore.ui.filter.Filter.AppealStatus["@Succeeded"] === 'S'}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectAppeals('Succeeded')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{marginBottom: '0px'}}>
                                                    <Checkbox
                                                        domID='claimfilter_Filed_pay'
                                                        label='Filed'
                                                        checked={this.props.dataStore.ui.filter.Filter.AppealStatus && this.props.dataStore.ui.filter.Filter.AppealStatus["@Filed"] === 'P'}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectAppeals('Filed')}
                                                    />
                                                    <Checkbox
                                                        domID='claimfilter_Denied_pay'
                                                        label='Denied'
                                                        checked={this.props.dataStore.ui.filter.Filter.AppealStatus && this.props.dataStore.ui.filter.Filter.AppealStatus["@Denied"] === 'D'}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectAppeals('Denied')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>


                                                    <ARMDatePicker
                                                        className="col2_sep"
                                                        domID="claimfilter_pay_appealsfrom"
                                                        label="Create Date:"
                                                        onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onAppealsDateChange(e, true)}
                                                        allowTime={false}
                                                        minDate={this.minDate}
                                                        maxDate={this.maxDate}
                                                        assocTo={this.getData(this.props.dataStore.ui.filter?.Filter?.AppealStatus, '@To')}
                                                        initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.AppealStatus, '@From')}
                                                    />
                                                    <ARMDatePicker
                                                        domID="claimfilter_pay_appealsto"
                                                        className="col3_sep"

                                                        onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onAppealsDateChange(e, false)}
                                                        allowTime={false}
                                                        minDate={this.minDate}
                                                        maxDate={this.maxDate}
                                                        assocFrom={this.getData(this.props.dataStore.ui.filter?.Filter?.AppealStatus, '@From')}
                                                        initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.AppealStatus, '@To')}
                                                    />


                                                </ContentRowWrapper>
                                            </DialogFieldset>

                                        </FilterColumn2>

                                        <FilterColumn3>
                                            <DialogFieldset id={'claimstatus_fieldset_pay'} >
                                                <DialogLegend>Claim State</DialogLegend>
                                                {this.renderClaimState('pay')}
                                            </DialogFieldset>
                                            <DialogFieldset id={'service_type_pay'} >
                                                <DialogLegend>Service Type</DialogLegend>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_servicetype_pay'
                                                        label='Inpatient'
                                                        checked={this.props.dataStore.ui.filter.Filter.ServiceType &&
                                                            (this.props.dataStore.ui.filter.Filter.ServiceType["@Inpatient"] === 'I' ||
                                                                this.props.dataStore.ui.filter.Filter.ServiceType["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectServiceType('Inpatient')}
                                                    />
                                                    <Checkbox
                                                        domID='claimfilter_servicetype_pay'
                                                        label='Outpatient'
                                                        checked={this.props.dataStore.ui.filter.Filter.ServiceType &&
                                                            (this.props.dataStore.ui.filter.Filter.ServiceType["@Outpatient"] === 'O' ||
                                                                this.props.dataStore.ui.filter.Filter.ServiceType["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectServiceType('Outpatient')}
                                                    />
                                                </ContentRowWrapper>
                                            </DialogFieldset>
                                            <DialogFieldset id={'claimswithholdcode_pay'} >
                                                <DialogLegend>Claims With Hold Code </DialogLegend>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_claimswithholdcode_pay'
                                                        label='Yes'
                                                        checked={this.props.dataStore.ui.filter.Filter.ClaimsWithHoldCodeYes &&
                                                            (this.props.dataStore.ui.filter.Filter.ClaimsWithHoldCodeYes["@ClaimsWithHoldCodeYes"] === 'Y')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectClaimsWithHoldCodeYes('Yes')}
                                                    />
                                                    <Checkbox
                                                        domID='claimfilter_claimswithholdcode_pay'
                                                        label='No'
                                                        checked={this.props.dataStore.ui.filter.Filter.ClaimsWithHoldCodeNo &&
                                                            (this.props.dataStore.ui.filter.Filter.ClaimsWithHoldCodeNo["@ClaimsWithHoldCodeNo"] === 'Y')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectClaimsWithHoldCodeYes('No')}
                                                    />
                                                </ContentRowWrapper>
                                            </DialogFieldset>
                                            <DialogFieldset id={'billingoption_pay'} >
                                                <DialogLegend>Billing Option </DialogLegend>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_billingoptionprimary_pay'
                                                        label='Primary'
                                                        checked={this.props.dataStore.ui.filter.Filter.BillingOption &&
                                                            (this.props.dataStore.ui.filter.Filter.BillingOption["@Primary"] === 'A' ||
                                                                this.props.dataStore.ui.filter.Filter.BillingOption["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectBillingOption('Primary')}
                                                    />
                                                    <Checkbox
                                                        domID='claimfilter_billingoptionrebill_pay'
                                                        label='Rebill'
                                                        checked={this.props.dataStore.ui.filter.Filter.BillingOption &&
                                                            (this.props.dataStore.ui.filter.Filter.BillingOption["@Rebill"] === 'R' ||
                                                                this.props.dataStore.ui.filter.Filter.BillingOption["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectBillingOption('Rebill')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_billingoptionsecondarym_pay'
                                                        label='Secondary (Manual)'
                                                        checked={this.props.dataStore.ui.filter.Filter.BillingOption &&
                                                            (this.props.dataStore.ui.filter.Filter.BillingOption["@Secondary"] === 'B' ||
                                                                this.props.dataStore.ui.filter.Filter.BillingOption["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectBillingOption('Secondary')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_billingoptionsecondarya_pay'
                                                        label='Secondary (Automated)'
                                                        checked={this.props.dataStore.ui.filter.Filter.BillingOption &&
                                                            (this.props.dataStore.ui.filter.Filter.BillingOption["@SecondaryFromRemit"] === 'C' ||
                                                                this.props.dataStore.ui.filter.Filter.BillingOption["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectBillingOption('SecondaryFromRemit')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_billingoptiontertiaryym_pay'
                                                        label='Tertiary (Manual)'
                                                        checked={this.props.dataStore.ui.filter.Filter.BillingOption &&
                                                            (this.props.dataStore.ui.filter.Filter.BillingOption["@Tertiary"] === 'D' ||
                                                                this.props.dataStore.ui.filter.Filter.BillingOption["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectBillingOption('Tertiary')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_billingoptiontertiarya_pay'
                                                        label='Tertiary (Automated)'
                                                        checked={this.props.dataStore.ui.filter.Filter.BillingOption &&
                                                            (this.props.dataStore.ui.filter.Filter.BillingOption["@TertiaryFromRemit"] === 'E' ||
                                                                this.props.dataStore.ui.filter.Filter.BillingOption["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectBillingOption('TertiaryFromRemit')}
                                                    />
                                                </ContentRowWrapper>
                                            </DialogFieldset>

                                            <DialogFieldset id={'Payer_Status_Categories_pay'} style={{height: '390px'}}>
                                                <DialogLegend3 helpUrl='/Support/Help/HELP_Filter277CategoryCodes.htm'>Payer Status Categories</DialogLegend3>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_notfound_pay'
                                                        label='Not Found'
                                                        checked={this.props.dataStore.ui.filter.Filter.StatusCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.StatusCategory["@NotFound"] === 'N' ||
                                                                this.props.dataStore.ui.filter.Filter.StatusCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectStatusCategory('NotFound')}

                                                    />
                                                    <Checkbox
                                                        domID='claimfilter_error_pay'
                                                        label='Error'
                                                        checked={this.props.dataStore.ui.filter.Filter.StatusCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.StatusCategory["@Error"] === 'E' ||
                                                                this.props.dataStore.ui.filter.Filter.StatusCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectStatusCategory('Error')}

                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_pending_pay'
                                                        label='Pending'

                                                        checked={this.props.dataStore.ui.filter.Filter.StatusCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.StatusCategory["@Pending"] === 'P' ||
                                                                this.props.dataStore.ui.filter.Filter.StatusCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectStatusCategory('Pending')}
                                                    />
                                                    <Checkbox
                                                        domID='claimfilter_finalpaid_pay'
                                                        label='Final Paid'

                                                        checked={this.props.dataStore.ui.filter.Filter.StatusCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.StatusCategory["@FinalPaid"] === 'F' ||
                                                                this.props.dataStore.ui.filter.Filter.StatusCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectStatusCategory('FinalPaid')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_finaldenied_pay'
                                                        label='Final Denied'
                                                        checked={this.props.dataStore.ui.filter.Filter.StatusCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.StatusCategory["@FinalDenied"] === 'D' ||
                                                                this.props.dataStore.ui.filter.Filter.StatusCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectStatusCategory('FinalDenied')}

                                                    />
                                                    <Checkbox
                                                        domID='claimfilter_finalother_pay'
                                                        label='Final Other'
                                                        checked={this.props.dataStore.ui.filter.Filter.StatusCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.StatusCategory["@FinalOther"] === 'O' ||
                                                                this.props.dataStore.ui.filter.Filter.StatusCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectStatusCategory('FinalOther')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_ackreceipt_pay'
                                                        label='Ack. Receipt'
                                                        checked={this.props.dataStore.ui.filter.Filter.StatusCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.StatusCategory["@Acknowledged"] === 'K' ||
                                                                this.props.dataStore.ui.filter.Filter.StatusCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectStatusCategory('Acknowledged')}
                                                    />
                                                    <Checkbox
                                                        domID='claimfilter_reqinfo_pay'
                                                        label='Req. Info'
                                                        checked={this.props.dataStore.ui.filter.Filter.StatusCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.StatusCategory["@ReqInfo"] === 'I' ||
                                                                this.props.dataStore.ui.filter.Filter.StatusCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectStatusCategory('ReqInfo')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_accepted_pay'
                                                        label='Accepted'
                                                        checked={this.props.dataStore.ui.filter.Filter.StatusCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.StatusCategory["@Processing"] === 'A' ||
                                                                this.props.dataStore.ui.filter.Filter.StatusCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectStatusCategory('Processing')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_ackreturned_pay'
                                                        label='Ack. & Returned'
                                                        checked={this.props.dataStore.ui.filter.Filter.StatusCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.StatusCategory["@Returned"] === 'R' ||
                                                                this.props.dataStore.ui.filter.Filter.StatusCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectStatusCategory('Returned')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_nostatuscategorycode_pay'
                                                        label='No status Category Code'
                                                        checked={this.props.dataStore.ui.filter.Filter.StatusCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.StatusCategory["@NoStatus"] === 'S' ||
                                                                this.props.dataStore.ui.filter.Filter.StatusCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectStatusCategory('NoStatus')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <CheckBoxLabel compact={false} style={{ paddingLeft: '2px', paddingRight: '10px' }} >DATE: </CheckBoxLabel>
                                                    {!this.props.dataStore.ui.claimStateSwitch && <> < RadioButtonComponent
                                                        id="claimfilter-posted_pay"
                                                        label="Posted"
                                                        checked={this.getData(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@Type") !== "Effective"}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onStatusCategoryTypeChange('Posted')}
                                                    />
                                                        <RadioButtonComponent
                                                            id="claimfilter-effective_pay"
                                                            label="Effective"
                                                            checked={this.getData(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@Type") === "Effective"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onStatusCategoryTypeChange('Effective')}
                                                        /></>}
                                                </ContentRowWrapper>
                                                <ContentColumnWrapper style={{ paddingTop: '3px' }}>
                                                    <ContentRowWrapper style={{ gap: '5px' }}>
                                                        <ARMDatePicker
                                                            className="col2_sep"
                                                            domID="claimfilter_pay_statuscategoryfrom"

                                                            onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onStatusCategoryDateChange(e, true)}
                                                            allowTime={false}
                                                            minDate={this.minDate}
                                                            maxDate={this.maxDate}
                                                            assocTo={this.getData(this.props.dataStore.ui.filter?.Filter?.StatusCategory, '@To')}
                                                            initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.StatusCategory, '@From')}
                                                        />
                                                        <ARMDatePicker
                                                            domID="claimfilter_pay_statuscategoryto"
                                                            className="col2_sep"

                                                            onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onStatusCategoryDateChange(e, false)}
                                                            allowTime={false}
                                                            minDate={this.minDate}
                                                            maxDate={this.maxDate}
                                                            assocFrom={this.getData(this.props.dataStore.ui.filter?.Filter?.StatusCategory, '@From')}
                                                            initialDate={this.getData(this.props.dataStore.ui.filter?.Filter?.StatusCategory, '@To')}
                                                        />
                                                    </ContentRowWrapper>

                                                </ContentColumnWrapper>
                                                <ContentRowWrapper style={{ paddingTop: '3px' }}>
                                                    <Checkbox
                                                        domID='claimfilter_nostatuscategorycode_pay'
                                                        label='277'
                                                        checked={this.stringContainsValue(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@StatusType", "Status277") ||
                                                            this.getData(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@StatusType") === ""}
                                                        onChange={(e: React.SyntheticEvent) => this.onStatusCategoryStatusTypeChange('Status277')}
                                                    />
                                                    <Checkbox
                                                        domID='claimfilter_nostatuscategorycode_pay'
                                                        label='CH'
                                                        checked={this.stringContainsValue(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@StatusType", "StatusCH")}
                                                        onChange={(e: React.SyntheticEvent) => this.onStatusCategoryStatusTypeChange('StatusCH')}
                                                    />
                                                   
                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{ paddingTop: '3px' }}>
                                                    <Checkbox
                                                        domID='claimfilter_nostatuscategorycode_pay'
                                                        label='Portal'
                                                        checked={this.stringContainsValue(this.props.dataStore.ui.filter.Filter?.StatusCategory, "@StatusType", "StatusPortal")}
                                                        onChange={(e: React.SyntheticEvent) => this.onStatusCategoryStatusTypeChange('StatusPortal')}
                                                    />
                                                </ContentRowWrapper>

                                            </DialogFieldset>
                                        </FilterColumn3>

                                    </ContentRowWrapper>
                                </ContentColumnWrapper>
                            </ContentRowWrapper>

                        }
                        { /****** ELIGIBILITY TAB ******/}
                        {(this.props.dataStore.ui.activeTab == 3) &&

                            <ContentRowWrapper>
                                <ContentColumnWrapper id={"claimfilter-eligibility-container"} >
                                    <ContentRowWrapper>

                                        <FilterColumn1 id={"claimfilter-eligibility-container_column1"} style={{width: '564px'}}>
                                            <DialogFieldset id={'field_fieldset_elig'} >
                                                <DialogLegend3 helpUrl='/Support/Help/HELP_Ranges.htm'>Field</DialogLegend3>
                                                <ContentRowWrapper style={{marginBottom: '5px'}}>
                                                    {this.renderNameAccountNum("claimfilter_elig_accountnumber_input", "claimfilter_elig_patientname_input")}
                                                </ContentRowWrapper>

                                                <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                                    {this.renderDateField('claimfilter_elig_datefield', 1, false)}
                                                </ContentRowWrapper>

                                                <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                                    {this.renderUserField('claimfilter_elig_userfield', 1)}
                                                </ContentRowWrapper>

                                                <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                                    {this.renderUserField('claimfilter_elig_userfieldsec', 2)}
                                                </ContentRowWrapper>

                                                <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                                    {this.renderUserField('claimfilter_elig_userfieldsec2', 3)}
                                                </ContentRowWrapper>

                                                <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                                    {this.renderUserField('claimfilter_elig_userfieldsec3', 4)}
                                                </ContentRowWrapper>

                                                <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                                    {this.renderFclassTob("claimfilter_elig_fclass_input", "claimfilter_elig_tob_input")}


                                                </ContentRowWrapper>
                                                <ContentRowWrapper style={{ paddingTop: '3px', marginBottom: '0px' }}>
                                                    {this.renderTotChargesPlanCode("claimfilter_elig_totalcharges_input", "claimfilter_elig_plancode_input")}

                                                </ContentRowWrapper>
                                            </DialogFieldset>
                                            <DialogFieldset id={'Payer_fieldset_elig'} >
                                                <DialogLegend>Payer</DialogLegend>
                                                <ContentRowWrapper style={{marginBottom: '0px'}}>
                                                    <SelectComponent
                                                        className="select-component"
                                                        title={'claimfilter_payer_select_elig'}
                                                        size={6}
                                                        width='550px'
                                                        height='100px'
                                                        multiple='true'
                                                        optionFields={{ value: "value", text: "label" }}

                                                        records={this.getSelectedPayerRecords()}
                                                        selectedMultiValue={selectedEligibilityPayers}
                                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectEligibilityPayers(e)}
                                                    />
                                                </ContentRowWrapper>
                                            </DialogFieldset>
                                            <DialogFieldset id={'claimnote_fieldset_elig'}>
                                                <DialogLegend3 helpUrl='/Support/Help/HELP_FilterClaimNotes.htm'>Claim Note</DialogLegend3>
                                                {this.renderClaimNoteSection('claimfilter_elig_claimnote')}

                                            </DialogFieldset>
                                        </FilterColumn1>

                                        <FilterColumn2 id={"claimfilter-eligibility-container_column2"} style={{width: '367px'}}>
                                            <ContentRowWrapper style={{marginBottom: '5px'}}>
                                                <LobList
                                                    title='claimfilter_loblist'
                                                    forms={lobData}
                                                    selectedValues={lobSelected}
                                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectLobs(e)}
                                                    disabled={this.props.dataStore.ui.disableLobs}

                                                />
                                                <FormTypeList
                                                    title='claimfilter_ftl'
                                                    forms={formTypesData}
                                                    selectedValues={formTypesSelected}
                                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectFormTypes(e)}
                                                    disabled={this.props.dataStore.ui.disableFormType}

                                                />
                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ paddingTop: '0px', marginBottom: '0px' }}>
                                                <SelectDropdown
                                                    domID="claimfilter_displayorder_elig"
                                                    className="dropdown"
                                                    label="Display Order:"
                                                    disabled={this.props.dataStore.ui.disableDisplayOrder || this.process_lowercase === "direct"}
                                                    isClearable={false}
                                                    isSearchable={true}
                                                    onChange={(option: SelectDropdownValue) => this.onDisplayOrderChange(option)}
                                                    options={this.getLookupList(this.props.dataStore.api.data?.ListPopulation?.DisplayOrderList)}

                                                    initialValue={this.getLookupList_SelectedItem_DisplayOrder()}

                                                />
                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ paddingTop: '0px', marginBottom: '0px' }}>
                                                {this.renderReleaseBatch('claimfilter_releasebatch_elig')}

                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ paddingTop: '0px', marginBottom: '0px' }}>
                                                {this.renderSubmittedFile('claimfilter_submittedfile_elig')}

                                            </ContentRowWrapper>
                                            <ContentRowWrapper style={{ paddingTop: '0px', marginBottom: '0px' }}>
                                                {this.renderUser('claimfilter_user_elig')}
                                            </ContentRowWrapper>
                                            <DialogFieldset id={'facility_fieldset_elig'} >
                                                <DialogLegend>Facility</DialogLegend>
                                                {this.renderFacilities('claimfilter_facility_elig')}

                                            </DialogFieldset>
                                            <DialogFieldset id={'state_data_reporting_elig'} >
                                                <DialogLegend>State Data Reporting</DialogLegend>
                                                {this.renderStateDataReporting('claimfilter_sdr_elig')}
                                            </DialogFieldset>

                                        </FilterColumn2>

                                        <FilterColumn3 id={"claimfilter-eligibility-container_column3"}>
                                            <DialogFieldset id={'claimstatus_fieldset_elig'} >
                                                <DialogLegend>Claim State</DialogLegend>
                                                {this.renderClaimState('elig')}
                                            </DialogFieldset>
                                            <DialogFieldset id={'service_type_elig'} >
                                                <DialogLegend>Service Type</DialogLegend>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_servicetype_elig"'
                                                        label='Inpatient'
                                                        checked={this.props.dataStore.ui.filter.Filter.ServiceType &&
                                                            (this.props.dataStore.ui.filter.Filter.ServiceType["@Inpatient"] === 'I' ||
                                                                this.props.dataStore.ui.filter.Filter.ServiceType["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectServiceType('Inpatient')}
                                                    />
                                                    <Checkbox
                                                        domID='claimfilter_servicetype_elig'
                                                        label='Outpatient'
                                                        checked={this.props.dataStore.ui.filter.Filter.ServiceType &&
                                                            (this.props.dataStore.ui.filter.Filter.ServiceType["@Outpatient"] === 'O' ||
                                                                this.props.dataStore.ui.filter.Filter.ServiceType["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectServiceType('Outpatient')}
                                                    />
                                                </ContentRowWrapper>
                                            </DialogFieldset>
                                            <DialogFieldset id={'claimswithholdcode_elig'} >
                                                <DialogLegend>Claims With Hold Code </DialogLegend>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_claimswithholdcode_elig'
                                                        label='Yes'
                                                        checked={this.props.dataStore.ui.filter.Filter.ClaimsWithHoldCodeYes &&
                                                            (this.props.dataStore.ui.filter.Filter.ClaimsWithHoldCodeYes["@ClaimsWithHoldCodeYes"] === 'Y')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectClaimsWithHoldCodeYes('Yes')}
                                                    />
                                                    <Checkbox
                                                        domID='claimfilter_claimswithholdcode_elig'
                                                        label='No'
                                                        checked={this.props.dataStore.ui.filter.Filter.ClaimsWithHoldCodeNo &&
                                                            (this.props.dataStore.ui.filter.Filter.ClaimsWithHoldCodeNo["@ClaimsWithHoldCodeNo"] === 'Y')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectClaimsWithHoldCodeYes('No')}
                                                    />
                                                </ContentRowWrapper>
                                            </DialogFieldset>
                                            <DialogFieldset id={'billingoption_elig'} >
                                                <DialogLegend>Billing Option </DialogLegend>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_billingoptionprimary_elig'
                                                        label='Primary'
                                                        checked={this.props.dataStore.ui.filter.Filter.BillingOption &&
                                                            (this.props.dataStore.ui.filter.Filter.BillingOption["@Primary"] === 'A' ||
                                                                this.props.dataStore.ui.filter.Filter.BillingOption["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectBillingOption('Primary')}
                                                    />
                                                    <Checkbox
                                                        domID='claimfilter_billingoptionrebill_elig'
                                                        label='Rebill'
                                                        checked={this.props.dataStore.ui.filter.Filter.BillingOption &&
                                                            (this.props.dataStore.ui.filter.Filter.BillingOption["@Rebill"] === 'R' ||
                                                                this.props.dataStore.ui.filter.Filter.BillingOption["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectBillingOption('Rebill')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_billingoptionsecondarym_elig'
                                                        label='Secondary (Manual)'
                                                        checked={this.props.dataStore.ui.filter.Filter.BillingOption &&
                                                            (this.props.dataStore.ui.filter.Filter.BillingOption["@Secondary"] === 'B' ||
                                                                this.props.dataStore.ui.filter.Filter.BillingOption["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectBillingOption('Secondary')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_billingoptionsecondarya_elig'
                                                        label='Secondary (Automated)'
                                                        checked={this.props.dataStore.ui.filter.Filter.BillingOption &&
                                                            (this.props.dataStore.ui.filter.Filter.BillingOption["@SecondaryFromRemit"] === 'C' ||
                                                                this.props.dataStore.ui.filter.Filter.BillingOption["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectBillingOption('SecondaryFromRemit')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper >
                                                    <Checkbox
                                                        domID='claimfilter_billingoptiontertiaryym_elig'
                                                        label='Tertiary (Manual)'
                                                        checked={this.props.dataStore.ui.filter.Filter.BillingOption &&
                                                            (this.props.dataStore.ui.filter.Filter.BillingOption["@Tertiary"] === 'D' ||
                                                                this.props.dataStore.ui.filter.Filter.BillingOption["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectBillingOption('Tertiary')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper >
                                                    <Checkbox
                                                        domID='claimfilter_billingoptiontertiarya_elig'
                                                        label='Tertiary (Automated)'
                                                        checked={this.props.dataStore.ui.filter.Filter.BillingOption &&
                                                            (this.props.dataStore.ui.filter.Filter.BillingOption["@TertiaryFromRemit"] === 'E' ||
                                                                this.props.dataStore.ui.filter.Filter.BillingOption["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectBillingOption('TertiaryFromRemit')}
                                                    />
                                                </ContentRowWrapper>
                                            </DialogFieldset>

                                            <DialogFieldset id={'Eligibility_Status_Category_elig'} >
                                                <DialogLegend3 helpUrl='/Support/Help/HELP_DYN.asp?9'>Eligibility Status Category</DialogLegend3>
                                                <ContentRowWrapper >
                                                    <Checkbox
                                                        domID='claimfilter_duplicateid_elig'
                                                        label='Duplicate Id'
                                                        disabled={this.props.dataStore.ui.disableDuplicateId}
                                                        checked={this.props.dataStore.ui.filter.Filter.EligibilityCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.EligibilityCategory["@DuplicateId"] === 'A' ||
                                                                this.props.dataStore.ui.filter.Filter.EligibilityCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectEligibilityStatusCategory('DuplicateId')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper >
                                                    <Checkbox
                                                        domID='claimfilter_missinginfo_elig'
                                                        label='Invalid/Missing Info'
                                                        disabled={this.props.dataStore.ui.disableInvalidOrMissingInfo}
                                                        checked={this.props.dataStore.ui.filter.Filter.EligibilityCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.EligibilityCategory["@InvalidOrMissingInfo"] === 'B' ||
                                                                this.props.dataStore.ui.filter.Filter.EligibilityCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectEligibilityStatusCategory('MissingInfo')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper>
                                                    <Checkbox
                                                        domID='claimfilter_partmatch_elig'
                                                        label='Pat/Ins Match'
                                                        disabled={this.props.dataStore.ui.disablePatInsMatch}
                                                        checked={this.props.dataStore.ui.filter.Filter.EligibilityCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.EligibilityCategory["@PatInsMatch"] === 'C' ||
                                                                this.props.dataStore.ui.filter.Filter.EligibilityCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectEligibilityStatusCategory('InsMatch')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper >
                                                    <Checkbox
                                                        domID='claimfilter_service date_elig'
                                                        label='Service Date'
                                                        disabled={this.props.dataStore.ui.disableServiceDate}
                                                        checked={this.props.dataStore.ui.filter.Filter.EligibilityCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.EligibilityCategory["@ServiceDate"] === 'D' ||
                                                                this.props.dataStore.ui.filter.Filter.EligibilityCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectEligibilityStatusCategory('ServiceDate')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper >
                                                    <Checkbox
                                                        domID='claimfilter_provider_elig'
                                                        label='Provider'
                                                        disabled={this.props.dataStore.ui.disableProvider}
                                                        checked={this.props.dataStore.ui.filter.Filter.EligibilityCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.EligibilityCategory["@Provider"] === 'E' ||
                                                                this.props.dataStore.ui.filter.Filter.EligibilityCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectEligibilityStatusCategory('Provider')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper >
                                                    <Checkbox
                                                        domID='claimfilter_norequestinitiated_elig'
                                                        label='No Request Initiated'
                                                        disabled={this.props.dataStore.ui.disbleNeverRequested}
                                                        checked={this.props.dataStore.ui.filter.Filter.EligibilityCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.EligibilityCategory["@NeverRequested"] === 'F' ||
                                                                this.props.dataStore.ui.filter.Filter.EligibilityCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectEligibilityStatusCategory('NoRequestInitiated')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper >
                                                    <Checkbox
                                                        domID='claimfilter_requestpending_elig'
                                                        label='Request Pending'
                                                        disabled={this.props.dataStore.ui.disableRequestPending}
                                                        checked={this.props.dataStore.ui.filter.Filter.EligibilityCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.EligibilityCategory["@RequestPending"] === 'G' ||
                                                                this.props.dataStore.ui.filter.Filter.EligibilityCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectEligibilityStatusCategory('RequestPending')}
                                                    />
                                                </ContentRowWrapper>
                                                <ContentRowWrapper >
                                                    <Checkbox
                                                        domID='claimfilter_requestfailed_elig'
                                                        label='Request Failed'
                                                        disabled={this.props.dataStore.ui.disableRequestFailed}
                                                        checked={this.props.dataStore.ui.filter.Filter.EligibilityCategory &&
                                                            (this.props.dataStore.ui.filter.Filter.EligibilityCategory["@RequestFailed"] === 'H' ||
                                                                this.props.dataStore.ui.filter.Filter.EligibilityCategory["@All"] === 'true')}
                                                        onChange={(e: React.SyntheticEvent) => this.onSelectEligibilityStatusCategory('RequestFailed')}
                                                    />
                                                </ContentRowWrapper>
                                            </DialogFieldset>
                                        </FilterColumn3>

                                    </ContentRowWrapper>
                                </ContentColumnWrapper>
                            </ContentRowWrapper>
                        }
                    </ClaimFilterContent>
                </ContentWrapper>
                {this.renderLegacyGoTo()}
                <ModalConfirmation
                    title={"Release Claim(s)"}
                    isOpen={this.state.showReleaseClaimModal && !this.state.releaseClaimModalAnswer}
                    formattedMessage={(
                        <div>
                            <p><b>This action releases all claims that meet the criteria.</b></p>
                            <p>Choose an option:</p>
                            <p>Click <b>OK</b> to complete the release action.</p>
                            <p>Click <b>CANCEL</b> to return to the filter.</p>
                            <br></br>
                            <p><i>Tip: Click <b>REFINE</b> from the filter to review the claim list.</i></p>
                        </div>)}
                    okText={"OK"}
                    cancelText={"CANCEL"}
                    showDefaultClose={true}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => {
                        this.setState({ showReleaseClaimModal: false, releaseClaimModalAnswer: false, disableBtn: false })

                    }}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                        this.setState({ showReleaseClaimModal: false, releaseClaimModalAnswer: true, disableBtn: false })

                        if (this.props.execNode !== "" && this.props.execAction !== "") {
                            this.checkRua = true;
                            if (this.validateFilter()) {
                                this.setPostAction("SubmitFilter");
                            }
                        }

                    }}
                />
                <ModalFilterList
                    isOpen={!!this.props.dataStore.ui.isFilterListOpen}
                    onModalToggle={() => { this.onCloseFilterList() }}
                    title={"Save Claim Filter"}
                    filters={this.getClaimFilterList(false)}
                    onConfirm={(filterListSelection: IModalFilterListSelection) => this.onConfirmFilterList(filterListSelection)}
                    onDeny={() => this.onCloseFilterList()}
                    selectedValue={this.props.dataStore.ui.filterIdQs}
                />
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.saveFilterNcsAlert && this.props.dataStore.ui.saveFilterNcsAlert.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearNCS(e)}
                    message={this.props.dataStore.ui.saveFilterNcsAlert}
                    okText={"Yes"}
                    cancelText={"No"}
                    showDefaultClose={true}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onConfirmNCS(e)}
                />
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.filterAlert && this.props.dataStore.ui.filterAlert.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearAlert(e)}
                    message={this.props.dataStore.ui.filterAlert}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClearAlert(e)}
                />
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.filterAlertModeOn && this.props.dataStore.ui.filterAlertModeOn.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearAlertModeOn(e)}
                    message={this.props.dataStore.ui.filterAlertModeOn}
                    alertMode={true}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClearAlert(e)}
                />
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.claimFilterErrorHandler && this.props.dataStore.ui.claimFilterErrorHandler.length > 0}
                    onModalToggle={() => this.onClearClaimFilterErrorHandler()}
                    message={this.props.dataStore.ui.claimFilterErrorHandler}
                    onConfirm={() => this.onClearClaimFilterErrorHandler()}
                />
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.claimFilterErrorHandlerForNotFound && this.props.dataStore.ui.claimFilterErrorHandlerForNotFound.length > 0}
                    onModalToggle={() => this.onClearClaimFilterErrorHandlerForNotFound()}
                    message={this.props.dataStore.ui.claimFilterErrorHandlerForNotFound}
                    onConfirm={() => this.onClearClaimFilterErrorHandlerForNotFound()}
                />
            </DialogWrapper>
        );
    }

};

var connectedHoc = connect<IClaimFilterState, IClaimFilterActionProps, IClaimFilterOwnProps, IClaimFilterProps, ApplicationState>(
    createApiMapStateToProps('claimFilter'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(ClaimFilter);

export default withRouter(connectedHoc);