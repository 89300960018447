import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Modal } from '@optum-uicl/ui-core/dist';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper, ContentColumnWrapper,  } from '@common/DialogWrapper';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { isEqual } from 'lodash';
import { IPhysicianMaintenanceState, IPhysicianMaintenanceProps, actionCreators, validationCallback } from '@store/PhysicianMaintenance';
import { mapDeltaToXmlIsh, PmSearchRequest } from '@store/ui/PhysicianMaintenanceUI';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IModalConfirmationProps, ModalConfirmation } from '@common/ModalConfirmation';
import { CheckBoxComponent } from '@common/CheckBox';
import { IsFieldFilled, IsAlphaNumeric } from '@commonResources/validations';
import { IImageButtonImage, ImageButton } from '../../common/ImageButton';
import ARMHelpIconOff from '../../../content/images/Buttons/But_HelpOff.png';
import ARMHelpIconUp from '../../../content/images/Buttons/But_HelpUp.png';
import ARMHelpIconOver from '../../../content/images/Buttons/But_HelpOver.png';
import ARMHelpIconDown from '../../../content/images/Buttons/But_HelpDown.png';
import ARMAddPlusOff from '../../../content/images/Buttons/But_Plus_creamOff.png';
import ARMAddPlusUp from '../../../content/images/Buttons/But_Plus_creamUp.png';
import ARMAddPlusOver from '../../../content/images/Buttons/But_Plus_creamOver.png';
import ARMAddPlusDown from '../../../content/images/Buttons/But_Plus_creamDown.png';
import { CenteredContent } from '@common/DialogWrapper';
import * as wnd from '@commonResources/window';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';
import { URLs } from '../../../commonResources/constants';
import { getRawToken } from '../../../scripts/session/SecurityToken';
import {
    ContentWrapper, WarningWrapper, RedSpan, ButtonRow,
    PhysicianMaintenanceLeftContent, PhysicianDetailsLeftWrapper,
    PhysicianMaintenanceContent, ModalWrapper, ModalFilterListBody,
    ModalFilterListInnerContent, ModalFilterListSelectList
} from './PhysicianStyles'
import { PhysicianDataInputs } from './PhysicianDataInputs';
import { PhysicianForDataInputs } from './PhysicianForDataInputs';
import { PhysicianSearchTabBox } from './PhysicianSearchTabBox';
import { PhysicianInfo, DEFAULT_PHYSICIAN_INFO, Lob, DEFAULT_LOB, IlNyFields, DEFAULT_FLWA_FIELDS, FlWaFields, DEFAULT_ILNY_FIELDS} from '@store/ui/PhysicianMaintenanceUI';
import { PhysicianPayerInputs } from './PhysicianPayersInputs';


interface tabArr {
    label: string;
    domID: string;
}

enum eModifyButtonType {
    Add,
    Update
}

ButtonRow.displayName = "ModalFilterListButtonRow";


interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    title: string,
    canCreate: boolean,
    canDelete: boolean,
    accessList: Array<boolean>
};

interface IComponentState {
    isUpdate: boolean,
    selectedFacility: any,
    cancelLeave: boolean,
    physicianCountLimit: number,
    modalProps: Partial<IModalConfirmationProps>,
    currentId: string,
    stateFields: {
        flWa: FlWaFields,
        ilNy: IlNyFields,
    },
}

interface PmRights {
    physView: boolean,
    physAdd: boolean,
    physUpdate: boolean,
    physRemove: boolean,
    payerAdd: boolean,
    payerUpdate: boolean,
    payerRemove: boolean,
    facilityEdit: boolean,
}

export const DEFAULT_STATE: IComponentState = {
    isUpdate: false,
    selectedFacility: {},
    cancelLeave: false,
    physicianCountLimit: 0,
    modalProps: { isOpen: false },
    currentId: '',
    stateFields: {
        flWa: DEFAULT_FLWA_FIELDS,
        ilNy: DEFAULT_ILNY_FIELDS,
    },
};

export type FlWaProperty = 'groupNumber' | 'groupSuffix' | 'doctorNumber' | 'locationCode';
export type IlNyProperty = 'billingDept' | 'physicianAccountNumber' | 'idLocCode' | 'deptFlag';
type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IPhysicianProps = IMergeCrudComponentProps<IPhysicianMaintenanceState, IPhysicianMaintenanceProps, IOwnProps>;

type BorkedCrudSave = { SystemError?: { '@Message'?: string } };

export class PhysicianMaintenance extends React.Component<IPhysicianProps, IComponentState> {
    static addItemCount: number = 0;
    static helpIcon: IImageButtonImage = {
        Off: ARMHelpIconOff,
        Up: ARMHelpIconUp,
        Over: ARMHelpIconOver,
        Down: ARMHelpIconDown,
    }
    static addIcon: IImageButtonImage = {
        Off: ARMAddPlusOff,
        Up: ARMAddPlusUp,
        Over: ARMAddPlusOver,
        Down: ARMAddPlusDown,
    }

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        title: "Physician Maintenance",
        canDelete: false,
        canCreate: false,
        accessList: [false, false, false, false]
    };

    private rights: PmRights;

    constructor(props: IPhysicianProps) {
        super(props);
        this.state = DEFAULT_STATE;
        //this.onClickSearch = this.onClickSearch.bind(this, this.state.currentSearchParameters);
        this.addNewPhysician = this.addNewPhysician.bind(this);
        this.updatePhysician = this.updatePhysician.bind(this);
        this.removePhysician = this.removePhysician.bind(this);
        this.getModalProps = this.getModalProps.bind(this);
        this.removePhysician = this.removePhysician.bind(this);
        this.updateFlWaFields = this.updateFlWaFields.bind(this);
        this.updateIlNyFields = this.updateIlNyFields.bind(this);
        this.resetStateFields = this.resetStateFields.bind(this);
        this.processRightsList = this.processRightsList.bind(this);
        this.rights = this.processRightsList(this.props.accessList);
    }

    public componentDidMount() {
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
        }
        pageLeave();
        this.props.action.ui.loadPageOptions();
    }

    public componentWillUnmount() {

        pageLeave();

        resetCrudComponentState(this.props.action, this.props.dataStore);
    }


    static getDerivedStateFromProps(props: IPhysicianProps, state: IComponentState) {
        if (!isEqual(props.dataStore.ui.selectedPhysician.internalId, state.currentId)) {
            console.log("State Fields Reset!");
            return ({
                currentId: props.dataStore.ui.selectedPhysician.internalId,
                stateFields: {
                    flWa: { ...props.dataStore.ui.selectedPhysician.flwaInfo },
                    ilNy: { ...props.dataStore.ui.selectedPhysician.ilnyInfo },
                }
            });
        }
        return null;
    }


    private processRightsList(rights: boolean[]): PmRights {
        // Array is hardcoded as:
        // [ SecurityBits.FN_ADD_PAYERS,
        //   SecurityBits.FN_EDIT_PAYERS,
        //   SecurityBits.FN_DELETE_PAYERS,
        //   SecurityBits.FN_EDIT_FACILITY]

        return {
            physView: this.props.canView,
            physAdd: this.props.canCreate,
            physUpdate: this.props.canEdit,
            physRemove: this.props.canDelete,
            payerAdd: this.props.accessList[0],
            payerUpdate: this.props.accessList[1],
            payerRemove: this.props.accessList[2],
            facilityEdit: this.props.accessList[3]}
    }

    // This handles both the add and copy buttons.
    public addNewPhysician(newPhysician: PhysicianInfo) {
        newPhysician.flwaInfo = { ...this.state.stateFields.flWa };
        newPhysician.ilnyInfo = { ...this.state.stateFields.ilNy };
        this.props.action.ui.addPhysician(newPhysician);
    }

    public updatePhysician(updatedPhysician: PhysicianInfo) {
        updatedPhysician.flwaInfo = { ...this.state.stateFields.flWa };
        updatedPhysician.ilnyInfo = { ...this.state.stateFields.ilNy };

        // Need to copy the lob list over in case there were any payer updates between when the physician
        // was loaded and then updated.
        updatedPhysician.lobList = this.props.dataStore.ui.selectedPhysician.lobList;
        this.props.action.ui.updatePhysician(updatedPhysician);
    }

    public removePhysician(targetPhysician: PhysicianInfo) {
        this.setState({
            modalProps: {
                isOpen: true,
                alertMode: false,
                message: `Do you want to remove ${targetPhysician.displayName}?  This action cannot be undone.`,
                onConfirm: () => {
                    this.resetStateFields();
                    this.props.action.ui.deletePhysician(targetPhysician);
                },
                onModalToggle: () => this.setState({ modalProps: {isOpen: false}}),
    }
        });
    }

    public validateForm(): boolean {

        return true;
    }

    // TODO - This is a hack to get these fields reset - we need to move physician detection
    // and change from the lower level prop up to here, or do the better option and move
    // PhysicianForDataInputs down into PhysicianDataInputs and fix the styling.
    private resetStateFields() {
        this.setState({
            stateFields: {
                ilNy: { ...this.props.dataStore.ui.selectedPhysician.ilnyInfo },
                flWa: { ...this.props.dataStore.ui.selectedPhysician.flwaInfo },
            }
        })
    }

    /**
     * Updates the local state FlWa fields to include the newly updated value.
     */
    private updateFlWaFields(id: FlWaProperty, val: string) {
        const currentValue = this.state.stateFields.flWa[id];
        if (isEqual(val, currentValue))
            return;

        const newValue = val.trim().toUpperCase();
        if (!isEqual(newValue, currentValue))
            this.setState({
                stateFields: {
                    ilNy: this.state.stateFields.ilNy,
                    flWa: {
                        ...this.state.stateFields.flWa,
                        [id]: newValue,
                    }
                }
            });

        // This looks wonky, but we need to force a component re-render, so we're going to set the state to what
        // the unformatted value was, then set it to the formatted value. We know we don't have a value that is just
        // the same since our initial guard clause would have returned it already.
        else
            this.setState({
                stateFields: {
                    ilNy: this.state.stateFields.ilNy,
                    flWa: {
                        ...this.state.stateFields.flWa,
                        [id]: val,
                    }
                }
            }, () => this.setState({
                stateFields: {
                    ilNy: this.state.stateFields.ilNy,
                    flWa: {
                        ...this.state.stateFields.flWa,
                        [id]: newValue,
                    }
                }
            }));
    }

    /**
     * Updates the local state IlNy fields to include the newly updated value.
     */
    private updateIlNyFields(id: IlNyProperty, val: string) {
        const currentValue = this.state.stateFields.ilNy[id];
        if (isEqual(val, currentValue))
            return;

        const newValue = val.trim().toUpperCase();
        if (!isEqual(newValue, currentValue))
            this.setState({
                stateFields: {
                    flWa: this.state.stateFields.flWa,
                    ilNy: {
                        ...this.state.stateFields.ilNy,
                        [id]: newValue,
                    }
                }
            });

        // This looks wonky, but we need to force a component re-render, so we're going to set the state to what
        // the unformatted value was, then set it to the formatted value. We know we don't have a value that is just
        // the same since our initial guard clause would have returned it already.
        else
            this.setState({
                stateFields: {
                    flWa: this.state.stateFields.flWa,
                    ilNy: {
                        ...this.state.stateFields.ilNy,
                        [id]: val,
                    }
                }
            }, () => this.setState({
                stateFields: {
                    flWa: this.state.stateFields.flWa,
                    ilNy: {
                        ...this.state.stateFields.ilNy,
                        [id]: newValue,
                    }
                }
            }));
    }

    private getTooManyResultsMessage(): string {

        // Check our counts to see if we had too many physicians.
        const physicianCount: number = this.props.dataStore.ui.physicianCount;
        const physicianLimit: number = this.props.dataStore.ui.physicianLimit;

        // Our physician list is empty.  We need to check the physician and LOB Counts.
        if (physicianCount > physicianLimit)
            return `There are too many physicians in the selected group (${physicianCount}). Enter a smaller search range and search again.`;

        // Check our counts to see if we had too many physician IDs returned.
        const lobCount: number = this.props.dataStore.ui.lobCount;

        if (lobCount > physicianLimit) {
            return `There are too many physician IDs in the selected group (${lobCount}). Enter a smaller search range and search again.`;
        }

        return "An error occurred while processing your physician search. If this issue persists, please contact support.";
    }

    public PayerNPIHelp() {
        wnd.HelpWindow('/Support/Help/HELP_PayerNPI.htm');
    }
    public facilityHelp(e: any) {
        this.setState({ selectedFacility: {} });
        this.props.action.ui.showPopup();
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = this.props.dataStore.ui.deltaPhysicians.length > 0 ? 'true': 'false';
        if (userModified === 'true') {
            this.setState({ cancelLeave: true });
    }
        else {
            this.props.history.push('/LandingPage');
    }
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.isUpdate(false);

        if (!this.validateForm()) return;


        const data = {
            crudId: this.props.dataStore.crud.crudId,
            data: {
                PhysicianMaintenanceInfo: {
                    Physicians: {Physician: mapDeltaToXmlIsh(this.props.dataStore.ui.deltaPhysicians)},
                }
            },
        };
        //this.props.action.ui.clearFields(true);
        this.props.action.crud.update(data, (result: unknown) => {
            if (!!(result as BorkedCrudSave).SystemError) {
                this.setState({
                    modalProps: {
                        isOpen: true,
                        alertMode: true,
                        message: 'A fatal error occurred while performing updates for Physician Master.',
                        onModalToggle: () =>  this.setState({modalProps: {isOpen: false}}, () => this.props.history.push('/LandingPage')),
                    },
                });
                return false;
            } else {
                this.props.history.push('/LandingPage');
                return true;
            }
        });

    }

    private getModalProps(): Partial<IModalConfirmationProps> {
        
        const {showTooManyResultsModal, loading, facilityDropdownOptions} = this.props.dataStore.ui;
        const modalOpen = {
            isOpen: true,
            showDefaultClose: true,
            alertMode: true,
        }
        if (showTooManyResultsModal) {
            return {
                message: this.getTooManyResultsMessage(),
                onModalToggle: () => this.props.action.ui.setTooManyResultsFlag(!this.props.dataStore.ui.showTooManyResultsModal),
                onOK: () => this.props.action.ui.setTooManyResultsFlag(false),
                ...modalOpen,
            };
        }

        // if we loaded states but we didn't get any facilities, assume we've done the pass for form data and got no facilities
        if (!loading && facilityDropdownOptions?.length < 2) {
            if (this.rights.facilityEdit) {
                return {
                    message: 'You must Add a PH Facility BEFORE you can Add Physicians. You will now be redirected to Facility Maintenance.',
                    onModalToggle: () => this.props.history.push('/Administration/FacilityMaintenance'),
                    ...modalOpen,
                }
            } else {
                return {
                    message: 'Ask your administrator to Add a PH Facility BEFORE you can Add Physicians.',
                    onModalToggle: () => this.props.history.push('/LandingPage'),
                    ...modalOpen,
                }
            }
        }

        return this.state.modalProps;
    }

    /*
    public setBusy(val: boolean) {
        this.props.action.ui.setBusy({ api: this.props.dataStore.api.data, uiData: { value: val } });
    }
    */

    /* -----------------------------------  */
    /* -------------  RENDER -------------  */
    /* -----------------------------------  */

    public render() {
        var helpButtons = <OKCancelButtons disableOK={!this.rights.physUpdate} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        const instruction = <React.Fragment>Use this page to enter each physician/payer ID combination used by any physician. Select Add New Physician, enter all required physician information, click Add. Move to Payers, add number information for each physician number. Click Add between number sets. Last, click OK to send all data to the database. Use the Search tab to quickly find a specific physician or group of physicians. * indicates a required field.</React.Fragment>;
        let tabList: tabArr[] = [{ label: 'Search', domID: 'id-Search' }, { label: 'Results', domID: 'id-Results' }];
        const {addSinglePayer, deletePayer, updatePayer} = this.props.action.ui;

        // this.setBusy(true);



        // this.setBusy(false);

        var physicianAddItem: any[] = [
            {
                '@ID': '',
                '@Name': '- ADD A NEW PHYSICIAN -'
            }
        ];

        let payerData: Lob[] = [
            {
                ...DEFAULT_LOB,
                displayName: '- ADD A NEW PAYER -',
            }
        ];
        const payerLobList: Lob[] | undefined = this.props.dataStore.ui.selectedPhysician?.lobList;
        if (payerLobList) {
            payerData = payerData.concat(payerLobList);
        }

        let LobCount = 0;
        this.props.dataStore.crud.data?.PhysicianMaintenanceInfo?.Physicians.Physician.map(
            item => {
                if (item.LOB) {
                    if (item.LOB?.length > 0)
                        LobCount = item.LOB?.length + LobCount;
                    else
                        LobCount = 1 + LobCount;
                }
            }
        );

        const physicianInputsKey = `${this.props.dataStore.ui.selectedPhysician?.internalId ?? "NO-ID"}-physician`;
        const payerInputsKey = `${this.props.dataStore.ui.selectedPhysician?.internalId ?? "NO-ID"}-with-${this.props.dataStore.ui.selectedPhysician?.lobList?.length ?? 0}-payers`;
        return (
            <DialogWrapper title="Physician Maintenance" width='1400px' buttons={helpButtons} instruction={instruction} helpUrl='/Support/Help/HELP_Maint_Physician.htm' isBusy={this.props.dataStore.ui.loading}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <WarningWrapper>
                            <p><RedSpan>Warning!</RedSpan> If individual enrollment is required for the corresponding facility and payer, then you <RedSpan>must contact Customer Support</RedSpan> if you add new physicians and IDs or change existing IDs.</p>
                        </WarningWrapper>
                    </div>
                    <ContentRowWrapper>
                        <PhysicianMaintenanceLeftContent>
                            <PhysicianSearchTabBox
                                physicians={this.props.dataStore.ui.physicianList}
                                physicianCountLimit={this.props.dataStore.ui.physicianLimit}
                                searchPhysicians={(request: PmSearchRequest) => {
                                    this.props.action.ui.setLoading(true);
                                    this.props.action.ui.newSearchRequested(request);
                                }}
                                selectedPhysician={this.props.dataStore.ui.selectedPhysician}
                                selectPhysician={(internalId: string) => {
                                    this.props.action.ui.selectCurrentPhysician(internalId);
                                }}
                                selectedTab={this.props.dataStore.ui.selectedTab}
                                selectTab={(tabId: number) => this.props.action.ui.updateSelectedTab(tabId)}
                                physicianCount={this.props.dataStore.ui.physicianCount}
                                lobCount={this.props.dataStore.ui.lobCount}
                                />
                        </PhysicianMaintenanceLeftContent>
                        <PhysicianMaintenanceContent>
                            <ContentWrapper>
                                <ContentRowWrapper>
                                    <ContentColumnWrapper>
                                        <PhysicianDetailsLeftWrapper>
                                            {/********Physician Data Input Begins********/}
                                            <PhysicianDataInputs
                                                currentPhysician={this.props.dataStore.ui.selectedPhysician}
                                                key={physicianInputsKey}
                                                changeValue={(controlName: string, newValue: string) => { console.log(`Control Name: ${controlName}  New Value: ${newValue}`) }}
                                                helpIcon={PhysicianMaintenance.helpIcon}
                                                idTypeList={this.props.dataStore.ui.idDropdownOptions}
                                                stateList={this.props.dataStore.ui.stateDropdownOptions}
                                                onClickAddButton={this.addNewPhysician}
                                                onClickUpdateButton={this.updatePhysician}
                                                onClickRemoveButton={this.removePhysician}
                                                addButtonOnlyFlag={this.props.dataStore.ui.selectedPhysician.internalId === DEFAULT_PHYSICIAN_INFO.internalId}
                                                canAdd={this.rights.physAdd}
                                                canUpdate={this.rights.physUpdate}
                                                canCopy={this.rights.physAdd}
                                                canRemove={this.rights.physRemove}
                                            />                                         
                                            {/********Physician Data Input Ends********/}
                                            {/********Payer Data Input Begins**********/}
                                            <PhysicianPayerInputs
                                                currentPhysician={this.props.dataStore.ui.selectedPhysician}
                                                lobOptions={this.props.dataStore.ui.lobDropdownOptions}
                                                facilityOptions={this.props.dataStore.ui.facilityDropdownOptions}
                                                specialtyCodeOptions={this.props.dataStore.ui.specialtyCodeDropdownOptions}
                                                helpIcon={PhysicianMaintenance.helpIcon}
                                                addIcon={PhysicianMaintenance.addIcon}
                                                onPayerSelect={() => { return; }}
                                                onClickModifyButton={updatePayer}
                                                onClickPayerRemoveButton={deletePayer}
                                                onCopyPayerClick={addSinglePayer}
                                                key={payerInputsKey}
                                                addPayers={(newPayers: Lob[]) => this.props.action.ui.addMultiplePayers(newPayers)}
                                                canAdd={this.rights.payerAdd}
                                                canEdit={this.rights.payerUpdate}
                                                canCopy={this.rights.payerAdd}
                                                canRemove={this.rights.payerRemove}
                                            />
                                            {/********Payer Data Input Ends************/}
                                        </PhysicianDetailsLeftWrapper>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper style={{ height: 'fit-content', display: 'inline-flex', alignItems: 'flex-end', paddingRight: '5px' }}>
                                        {/**** PhysicianForDataInputs Begins ****/}
                                        <PhysicianForDataInputs
                                            key={physicianInputsKey}
                                            flWaFields={this.state.stateFields.flWa}
                                            ilNyFields={this.state.stateFields.ilNy}
                                            updateFlWa={this.updateFlWaFields}
                                            updateIlNy={this.updateIlNyFields}
                                            resetFields={this.resetStateFields}
                                        />
                                        {/**** PhysicianForDataInputs Ends ****/}
                                    </ContentColumnWrapper>
                                </ContentRowWrapper>
                            </ContentWrapper>
                        </PhysicianMaintenanceContent>
                    </ContentRowWrapper>
                </ContentWrapper>
                <ModalConfirmation
                    {...this.getModalProps()}
                />
            </DialogWrapper>
        )

    };
};

var connectedHoc = connect<IPhysicianMaintenanceState, IPhysicianMaintenanceProps, IOwnProps, IPhysicianProps, ApplicationState>(
    createCrudMapStateToProps('physicianMaintenance'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(PhysicianMaintenance);

export default withRouter(connectedHoc);;
