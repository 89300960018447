import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '@commonResources/colorVariables';
import { Typography } from '@commonResources/typography';
import { ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';

export interface ICheckBoxComponentProps {
    id: string;
    checked: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    label?: string;
    labelBefore?: string;
    labelAbove?: string;
    width?: string;
    disabled: boolean;
    darkerGreyOut?: boolean;
    hasError: boolean;
    sizeValue?: string;
    labelType?: string;
    lablePadding?: string;
    blackLabel?: boolean;
    greyText?: boolean;
    nowrap?: boolean;
    name?: string;
    className?: string;

};

export interface ICheckBoxComponentState {
    disabled: boolean;
};

export interface ICheckBoxWrapperProps {
    widthValue?: string;
    hasError?: boolean;
    large?: boolean;
    blackLabel?: boolean;
    compact?: boolean;
    greyText?: boolean;
    lablePadding?: string;
    nowrap?: boolean;
};

export interface ICheckBoxInputProps {
    hasError?: boolean;
    disabled: boolean;
    darkerGreyOut?: boolean;
    large?: boolean;
    compact?: boolean;
};
//--------------------------------------------------------------

export const CheckBoxWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin: 0 auto;
    cursor: pointer;
    
    ${(props: ICheckBoxWrapperProps) => props.greyText ? `color: #37474F` : `color: inherit`};
    ${(props: ICheckBoxWrapperProps) => props.large ? `min-width: 30px` : `min-width: 20px`};
    ${(props: ICheckBoxWrapperProps) => props.large ? `height: 30px` : `min-height: 20px`}; 
    ${(props: ICheckBoxWrapperProps) => props.widthValue && `width: ${props.widthValue}`}; 
`;

export const CheckBoxInput = styled.input`
    cursor: pointer;
    position: relative;
    opacity: 1 !important;
    padding-right: 10px;

    ${(props: ICheckBoxInputProps) => props.large ? `min-width: 30px` : `min-width: 14px`};
    ${(props: ICheckBoxInputProps) => props.large ? `max-width: 30px` : `max-width: 14px`};
    ${(props: ICheckBoxInputProps) => props.large ? `height: 15px` : `height: 15px`};
 	
    :before {
         content: "";
         display: block;
         position: absolute;
         top: -1px;
         left: -1px;
         border: 1px solid black;
         background-color: white;
         color: white;
         border-radius: 2px;
         ${(props: ICheckBoxInputProps) => props.disabled && `background-color: ${props.darkerGreyOut ? Colors.middleGrey : Colors.lightestGrey} !important`};
         ${(props: ICheckBoxInputProps) => props.disabled && `border: 1px solid ${Colors.lightGrey} !important`};
         ${(props: ICheckBoxInputProps) => props.hasError && `border: 1px solid ${Colors.darkRed}`};
         ${(props: ICheckBoxInputProps) => props.large ? `width: 30px` : `width: 15px`};
         ${(props: ICheckBoxInputProps) => props.large ? `height: 30px` : `height: 15px`};

	}
    :checked:after {
         content: "";
         display: block;
         border: solid white;         
         -webkit-transform: rotate(45deg);
         -ms-transform: rotate(45deg);
         transform: rotate(45deg);
         position: absolute;
         top: -1px;

         ${(props: ICheckBoxInputProps) => props.large ? `left: 8px` : `left: 4px`};
         ${(props: ICheckBoxInputProps) => props.large ? `width: 13px` : `width: 5px`};
         ${(props: ICheckBoxInputProps) => props.large ? `height: 22px` : `height: 10px`};
         ${(props: ICheckBoxInputProps) => props.large ? `border-width: 0 4px 4px 0` : `border-width: 0 2px 2px 0`};
	}
    :checked::before{
        background-color: rgb(12, 85, 184);
        border: 1px solid #4B4D4F;
    }

    :hover::before, :focus::before, :active::before{
        box-shadow: rgb(255, 255, 255) 0px 0px 0px 2px, rgb(12, 85, 184) 0px 0px 0px 5px;
    }

    
    
    
`;

export const CheckBoxLabel = styled.label`
    padding-top: 2px;
    padding-left: 5px;
    font-weight: 400;
    text-transform: capitalize;

    ${Typography.ARMFontFamily};
    ${(props: ICheckBoxWrapperProps) => props.large ? `${Typography.large}` : `font-size: 12px`};
    ${(props: ICheckBoxWrapperProps) => props.blackLabel ? `color: #fff` : `color: #4B4D4F`};
    ${(props: ICheckBoxWrapperProps) => props.compact && props.compact == true ? `padding-left: 3px, font-size: 11px` : `padding-left: 15px`};
 
    ${(props: ICheckBoxWrapperProps) => props.nowrap && `white-space: nowrap`};
`;

export const CheckBoxLabelBefore = styled.label`
    ${(props: any) => props.lablePadding ? `padding-left: ${props.lablePadding}` : `padding-left: 15px`};
    padding-top: 2px;
    text-transform: uppercase;
    
    color: ${Colors.grey100};
    
    ${Typography.ARMFontFamily};
    ${(props: ICheckBoxWrapperProps) => props.large ? `${Typography.large}` : `${Typography.small}`};
    ${(props: ICheckBoxWrapperProps) => props.compact ? `font-weight: 400, font-size: 11px` : `${Typography.bold}`};
 
`;

const CheckBoxLabelAbove = styled.label`
    padding-left: 2px;
    padding-top: 2px;
    text-transform: uppercase;

    color: ${Colors.grey100};
    
    ${Typography.ARMFontFamily};
    ${(props: ICheckBoxWrapperProps) => props.large ? `${Typography.large}` : `${Typography.small}`};
    ${(props: ICheckBoxWrapperProps) => props.compact ? `font-weight: 400, font-size: 11px` : `${Typography.bold}`};
`;

export class CheckBoxComponent extends React.Component<ICheckBoxComponentProps, ICheckBoxComponentState>
{
    static defaultProps: ICheckBoxComponentProps = {
        id: "",
        checked: false,
        disabled: false,
        hasError: false,
        sizeValue: undefined,
        labelType: undefined,
        greyText: false,
        nowrap: false,
        name: ""
    };

    constructor(props: ICheckBoxComponentProps) {
        super(props);
        this.state = {
            disabled: this.props.disabled || false,
        }
    }

    handleClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    };

    renderAbove() {
        return (
            <CheckBoxWrapper
                greyText={this.props.greyText}
                blackLabel={this.props.blackLabel}
                widthValue={this.props.width}
                hasError={this.props.hasError}
                id={this.props.id + "_wrapper"}
                large={this.props.sizeValue == 'large' ? true : undefined}
                compact={this.props.labelType == 'compact' ? true : undefined}
            >
                <ContentColumnWrapper>
                    <CheckBoxLabelAbove
                        id={this.props.id + "_label"}
                        large={this.props.sizeValue == 'large' ? true : undefined}
                        compact={this.props.labelType == 'compact' ? true : undefined}
                        className={'arm_checkbox_label_above'}
                        nowrap={this.props.nowrap}
                    >
                        {this.props.labelAbove}
                    </CheckBoxLabelAbove>
                    <CheckBoxInput
                        id={this.props.id}
                        type="checkbox"
                        checked={this.props.checked}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (this.props.onChange) this.props.onChange(e) }}
                        disabled={this.props.disabled}
                        hasError={this.props.hasError}
                        large={this.props.sizeValue == 'large' ? true : undefined}
                        compact={this.props.labelType == 'compact' ? true : undefined}
                        name={this.props.name}


                    />
                    <span />
                </ContentColumnWrapper>
            </CheckBoxWrapper>
        );

    }

    renderBefore() {
        return (
            <CheckBoxWrapper
                greyText={this.props.greyText}
                widthValue={this.props.width}
                hasError={this.props.hasError}
                id={this.props.id + "_wrapper"}
                large={this.props.sizeValue == 'large' ? true : undefined}
                compact={this.props.labelType == 'compact' ? true : undefined}
            >
                <CheckBoxLabelBefore
                    id={this.props.id + "_label"}
                    lablePadding={this.props.lablePadding}
                    large={this.props.sizeValue == 'large' ? true : undefined}
                    compact={this.props.labelType == 'compact' ? true : undefined}
                    className={'arm_checkbox_label_before'}
                    nowrap={this.props.nowrap}
                >
                    {this.props.labelBefore}
                </CheckBoxLabelBefore>
                <CheckBoxInput
                    id={this.props.id}
                    type="checkbox"
                    checked={this.props.checked}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (this.props.onChange) this.props.onChange(e) }}
                    disabled={this.props.disabled}
                    hasError={this.props.hasError}
                    large={this.props.sizeValue == 'large' ? true : undefined}
                    compact={this.props.labelType == 'compact' ? true : undefined}
                    name={this.props.name}

                />
                <span />

            </CheckBoxWrapper>
        );

    }

    renderOriginal() {
        return (
            <CheckBoxWrapper
                greyText={this.props.greyText}
                widthValue={this.props.width}
                hasError={this.props.hasError}
                id={this.props.id + "_wrapper"}
                large={this.props.sizeValue == 'large' ? true : undefined}
                compact={this.props.labelType == 'compact' ? true : undefined}
            >
                <CheckBoxInput
                    darkerGreyOut={this.props.darkerGreyOut}
                    id={this.props.id}
                    type="checkbox"
                    checked={this.props.checked}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleClick(e) }}
                    disabled={this.props.disabled}
                    hasError={this.props.hasError}
                    large={this.props.sizeValue == 'large' ? true : undefined}
                    compact={this.props.labelType == 'compact' ? true : undefined}
                    name={this.props.name}

                />
                <span />
                <CheckBoxLabel
                    id={this.props.id + "_label"}
                    large={this.props.sizeValue == 'large' ? true : undefined}
                    compact={this.props.labelType == 'compact' ? true : undefined}
                    className={'arm_checkbox_label'}
                    nowrap={this.props.nowrap}
                >
                    {this.props.label}
                </CheckBoxLabel>
            </CheckBoxWrapper>
        );

    }

    render() {
        if (this.props.labelAbove && this.props.labelAbove.length > 0) {
            return this.renderAbove();
        } else if (this.props.labelBefore && this.props.labelBefore.length > 0) {
            return this.renderBefore();
        } else {
            return this.renderOriginal();
        }
    }
};