import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { /*ContentColumnWrapper,*/ ContentRowWrapper, DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { /*Button, Modal, LoadingIndicator,*/ Grid } from '@optum-uicl/ui-core/dist';

import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';
import { getRawToken } from '@scripts/session/SecurityToken';

// store includes
import { ApplicationState } from '@store/index';
import { ICookies_Config } from '@store/ConfigData';
import { defaultState as defaultUIState } from '@store/api/ReportCreateStore';
import { IReportCreateState, /*IReportCreateActions,*/ IReportCreateActionProps, actionCreators/*, validationCallback */ } from '@store/ReportCreate';

// uicl
import { CheckBoxComponent } from '@common/CheckBox';

// commonResources
import { URLs } from '@commonDevResources/constants';
import { SelectGroupComponent } from '@common/SelectGroupComponent';
import { SelectComponent } from '@common/SelectComponent';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { CustomCheckBox } from '@common/CustomCheckBox';
import CustomInputCheckbox from '@common/CustomInputCheckbox';
import { ColumnType } from '@optum-uicl/ui-core/dist/Organisms/Grid/types';

/////////////////////////////////////////////////////////////////////////////////////////////
// styled divs
const ContentReportCreateWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    min-width: 950px;
    min-height: 610px;
        
    #header-row-wrapper {
        float: right;
        max-height: 60px;
    }

    #report-type-dialogfieldset {
        height: 60px;
        color: red;
        margin-bottom: 4px;
    }

    #report-grouping-dialogfieldset {
        float: right;
        height: 60px;
    }

    #report-submission-dialogfieldset {
        /*visibility: hidden;*/
        height: 60px;
        width: 600px;
    }

    .table-row-wrapper {
        background-color: #f5B700;
    }

`;

const CheckboxWrapper = styled.div`
    padding-bottom: 1px;
    padding-top: 1px;
    padding-left: 1px;   
`;

const CheckboxRow = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    min-width: 530px;
`;

const ContentReportListWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    min-width: 950px;
    height: 100%;

    .report-list {
        height: 100%;

    }
    .report-grid {
        min-width: 100%;



         .Name {
            vertical-align: inherit;
            width:580px;
            min-width: 580px !important;

            font-weight: 600;
        }

        .PDF, .CSV, .XML, .TXT {
            vertical-align: inherit;
            width:50px;
        }

        .Other {
            vertical-align: inherit;
            width:100px;
        }

        th[data-testid='header-Name'] {
            min-width: 580px;
        }
    }

       


`;

/////////////////////////////////////////////////////////////////////////////////////////////
// common components
enum eCreateReportSecurityBits {
    RPT_CREATE_CLAIM_REPORTS = 0,
    RPT_CREATE_CUSTOM_REPORTS,
    RPT_CREATE_MANAGEMENT_REPORTS,
    RPT_CREATE_MISCELLANEOUS_REPORTS,
    EBO_RPT_CREATE_OUTSOURCE_REPORTS,
    RPT_CREATE_REMIT_REPORTS,
    RPT_CREATE_SUBMISSION_REPORTS,
    RPT_CREATE_SYSTEM_REPORTS,
    FN_VIEW_PREBILL
}

enum eTypeStateVars {
    UNRELEASED = '100',
    RELEASED = '200',
    REMIT = '300',
    SYSTEM = '400',
    MANAGEMENT = '500',
    DIRECTENTRY = '600',
    MISCELLANEOUS = '700',
    OUTSOURCE = '800',
    CUSTOM = '900',
    SUBMISSION = '1000',
}

// report list...
interface IReportCreateOption {
    'value': string;
    'label': string;
}

interface IReportCreateOptionGroup {
    'label': string;
    'items': IReportCreateOption[];
}

interface ReportCreateList {
    reportList: IReportCreateOptionGroup[];
}

interface ReportGroupingList {
    reportList: IReportCreateOption[];
}

class ReportCreateCurrentList {
    Name: string = '';
    PDF: any;
    CSV: any;
    XML: any;
    TXT: any;
    Other: any;
    Other2: any;
}

class ReportCreateFilterSingleClaimPrint {
    ClaimTitle: string = '';
    ExecNode: string = '';
    ExecAction: string = '';
    ExecProcess: string = '';
    ExtraInstruction: string = '';
    claimStateRestrictions: string[] = [];
}

// report request...
export interface ReportCreateRequest {
    '@ID': string;
    '@Name': string;
    '@Format': string;
    '@Type': string;
    '@ClientID'?: string;
    '@BridgeName'?: string;
    '@TimeStamp'?: string;
    '@Grouping'?: string;
    '@AuditNo'?: string;
}

export interface ReportCreateRequests {
    '@Type': string;
    ReportRequest?: ReportCreateRequest[];
}

//state
export interface IReportCreateComponentProps {
    title: string,
    canView: boolean,  // SecurityClearance
    accessList: Array<boolean>, // per app array (see my enum in this class)
    testMode?: boolean;

    process: '',
    execNode: '',
    execAction: '',
    execProcess: '',
    parameter: '',
};

interface IComponentState {
    selectedReportType: string;
    groupingsDisabled: boolean;
    submissionVisible: boolean;
    submissionSummaryChecked: boolean;
    submissionDiscardedChecked: boolean;
    submissionBalancingChecked: boolean;
    submissionBalancingIIChecked: boolean;
    useSingleReport: boolean;
    remitEobReportType: boolean;
};

export const DEFAULT_STATE: IComponentState = {
    selectedReportType: 'Unreleased Claim',
    groupingsDisabled: true,
    submissionVisible: false,
    submissionSummaryChecked: true,
    submissionDiscardedChecked: false,
    submissionBalancingChecked: false,
    submissionBalancingIIChecked: false,
    useSingleReport: false,
    remitEobReportType: false,
};

type IReportCreateOwnProps = IReportCreateComponentProps & RouteComponentProps<{}>;
type IReportCreateProps = IMergeApiComponentProps<IReportCreateState, IReportCreateActionProps, IReportCreateOwnProps>;


/////////////////////////////////////////////////////////////////////////////////////////////
// HOC
export class ReportCreate extends React.Component<IReportCreateProps, IComponentState> {
    protected allReportTypes: ReportCreateList = { reportList: [] };
    protected finalReportRequest: ReportCreateRequests = { '@Type': '', ReportRequest: [] };
    protected holdReportRequest: ReportCreateRequests = { '@Type': '', ReportRequest: [] };
    protected groupingCount: number = 0;
    protected reportPathToPrint: string = '';
    protected reportGrouping: string = '';
    protected submissionPdfCount: number = 0; // goes along with this.state.submissionSummaryChecked...
    protected submissionCsvCount: number = 0;
    protected submissionTxtCount: number = 0;
    protected submissionReportId: string = '1101';
    protected discardedReportId: string = '1102';
    protected balancingReportId: string = '1100';
    protected balancingIIReportId: string = '1104';
    protected submissionReportType: string = '';
    protected discardedReportType: string = '';
    protected balancingReportType: string = '';
    protected balancingIIReportType: string = '';
    protected submissionReportName: string = 'Submission Summary';
    protected discardedReportName: string = 'Discarded Claims';
    protected balancingReportName: string = 'Balancing Report';
    protected balancingIIReportName: string = 'Balancing II';
    protected selectedGroupingType: string = '- Select Grouping -';
    protected currentType: eTypeStateVars = eTypeStateVars.UNRELEASED;
    protected currentCategory: string = 'Unreleased';
    protected reportTypeOutSource2227Txt: boolean = false;
    protected useSingleReportName: string = '';
    protected subSummaryCheck: boolean = false;
    protected subDiscardCheck: boolean = false;
    protected subBalanceCheck: boolean = false;
    protected subBalanceIICheck: boolean = false;
    protected singleReportSelected: any = null;
    protected singleReportId: string = '';
    protected selectedBalancingRemitReport: boolean = false;
    protected allowSubSumm: any = false;
    protected allowSubDisc: any = false;
    protected allowSubBal: any = false;
    protected allowSubBal2: any = false;

    //static defaultProps: IClaimFilterComponentProps = {
    //    canEdit: false,
    //    canView: false,
    //    canCreate: false,
    //    ncsIsEnabled: false,
    //    accessList: [false, false, false, false, false, false, false, false],

    //    title: 'Report Create',
    //    process: 'GetReport',
    //    parameter: {},
    //    apiType: 'ClaimFilter',
    //    executeJS: undefined,
    //    execNode: 'Report',
    //    execAction: 'GetReport',
    //    extraInstruction: 'Click Continue to create the report without viewing the claim list.',
    //    //saved filter list: claimStateRestrictions: ['', '1', '11'],

    //};

    static defaultProps: IReportCreateComponentProps = {
        title: 'Report Create',
        canView: false,
        accessList: [false, false, false, false, false, false, false, false],
        testMode: false,
        process: '',
        execNode: '',
        execAction: '',
        execProcess: '',
        parameter: '',
    };

    constructor(props: IReportCreateProps) {
        super(props);
        this.state = DEFAULT_STATE;

    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
    // lifecyle methods...
    public componentDidMount() {
        console.log('ReportCreate componentDidMount');
        if (!this.props.canView) {
            console.log('User did not have rights to [' + this.props.title + ']');
            this.goToHomePage();
        }

        this.initConfigLoad();
        this.props.action.api.loadData(URLs.api + '/api/data/GetReportFormData', this.validationCallback.bind(this), this.errorCallback.bind(this));
    }

    public componentWillUnmount() {
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
    // supporting functions...
    private initConfigLoad() {
        this.props.action.configData.getConfigData({
            cookies: [{ name: 'Eligibility' }, { name: 'DirectEntry' }, { name: 'MCAMethod' }, { name: 'MCDEEnabled' }, { name: 'AttachmentsEnabled' }, { name: 'PrebillEnabled' }],
            config: [{ name: 'HCDOMSId' }, { name: 'EnableTenetFeatures' }, { name: 'isClientUser' }]
        }, () => {
            //debugger;
            console.log('cookies enabled');
        });
    }

    public setReportExecParms(reportProcess: string) {
        let reportParms: ReportCreateFilterSingleClaimPrint = new ReportCreateFilterSingleClaimPrint();//{ ClaimTitle = '', ExtraInstruction = '', ExecNode = '', ExecAction = '', ExecProcess = '' };
        //debugger;
        if (this.reportPathToPrint === '7' && reportProcess.toLowerCase() == 'custom') {
            reportProcess = 'rsrtcustom';
        }

        // ASRN-156 : PS: 8004970411 - Create Epic External Edit Reports (CRD) for Deleted claims (Unreleased only!)
        let execAltProcess: string = reportProcess;
        let execAltName: string = '';
        if (this.state.useSingleReport && (this.singleReportId == '2350' || this.singleReportId == '2351' || this.singleReportId == '2352' || this.singleReportId == '2353' || this.singleReportId == '2354' || this.singleReportId == '2355')) {
            execAltProcess = 'Deleted';
            execAltName = this.useSingleReportName;
        }

        switch (reportProcess.toLowerCase()) {
            case 'unreleased':
            case 'released':
            case 'custom':
                if (execAltName.length > 0)
                    reportParms.ClaimTitle = execAltName + ' Report';
                else
                    reportParms.ClaimTitle = reportProcess + ' Claim Report';
                reportParms.ExecAction = 'GetReport';
                reportParms.ExecNode = 'Report';
                reportParms.ExecProcess = execAltProcess;
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                break;
            case 'submission':
                reportParms.ClaimTitle = reportProcess + ' Claim Report';
                reportParms.ExecAction = 'GetReport';
                reportParms.ExecNode = 'Report';
                reportParms.ExecProcess = reportProcess;
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                break;
            case 'deleted':
                reportParms.ClaimTitle = reportProcess + ' Claim Report';
                reportParms.ExecAction = 'GetReport';
                reportParms.ExecNode = 'Report';
                reportParms.ExecProcess = reportProcess;
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                break;
            case '277':
                reportParms.ClaimTitle = 'Claim + 277';
                reportParms.ExecAction = 'Print277';
                reportParms.ExecNode = 'PaperClaim';
                reportParms.ExecProcess = '277';
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                break;
            case 'portal':
                reportParms.ClaimTitle = 'Claim + Portal';
                reportParms.ExecAction = 'PrintPortal';
                reportParms.ExecNode = 'PaperClaim';
                reportParms.ExecProcess = 'portal';
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                break;
            case '271':
                reportParms.ClaimTitle = 'Claim + Eligibility';
                reportParms.ExecAction = 'Print271';
                reportParms.ExecNode = 'PaperClaim';
                reportParms.ExecProcess = '271';
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                break;
            case 'printcaltc':
                reportParms.ClaimTitle = 'California LTC Claims';
                reportParms.ExecAction = 'PrintCALTC';
                reportParms.ExecNode = 'PaperClaim';
                reportParms.ExecProcess = 'PrintCALTC';
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                break;
            case 'compliance':
            case 'direct':
                reportParms.ClaimTitle = this.useSingleReportName + ' Report';
                reportParms.ExecAction = 'GetReport';
                reportParms.ExecNode = 'Report';
                reportParms.ExecProcess = reportProcess;
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                break;
            case 'dep':
                reportParms.ClaimTitle = this.useSingleReportName;
                reportParms.ExecAction = 'ExtractData';
                reportParms.ExecNode = 'MultiClaim';
                reportParms.ExecProcess = 'DEP';
                reportParms.ExtraInstruction = 'Click Continue to create the extract without viewing the claim list.';
                break;
            case 'deleted_dep':
                reportParms.ClaimTitle = this.useSingleReportName;
                reportParms.ExecAction = 'ExtractData';
                reportParms.ExecNode = 'MultiClaim';
                reportParms.ExecProcess = 'deleted_dep';
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                break;
            case 'reprinthcdclaimeob':
                reportParms.ClaimTitle = 'Reprint HCD Claim + EOB';
                reportParms.ExecAction = 'ReprintHCDClaimEOB';
                reportParms.ExecNode = 'PaperClaim';
                reportParms.ExecProcess = 'ReprintHCDClaimEOB';
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                break;
            case 'eob':
                reportParms.ClaimTitle = 'Claim + EOB';
                reportParms.ExecAction = 'PrintEOB';
                reportParms.ExecNode = 'PaperClaim';
                reportParms.ExecProcess = 'EOB';
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                break;
            case 'eobdetail':
                reportParms.ClaimTitle = 'Claim + EOB Detail';
                reportParms.ExecAction = 'PrintEOBDetail';
                reportParms.ExecNode = 'PaperClaim';
                reportParms.ExecProcess = 'PrintEOBDetail';
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                break;
            case 'eobitemized':
                reportParms.ClaimTitle = 'Claim + EOB Itemized';
                reportParms.ExecAction = 'PrintEOBItemized';
                reportParms.ExecNode = 'PaperClaim';
                reportParms.ExecProcess = 'PrintEOBItemized';
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                break;
            case 'eobmnra':
                reportParms.ClaimTitle = 'Claim + EOB MNRA';
                reportParms.ExecAction = 'PrintEOBMNRA';
                reportParms.ExecNode = 'PaperClaim';
                reportParms.ExecProcess = 'PrintEOBMNRA';
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                break;
            case 'mran':
                reportParms.ClaimTitle = 'Claim + MRAN';
                reportParms.ExecAction = 'PrintMRAN';
                reportParms.ExecNode = 'PaperClaim';
                reportParms.ExecProcess = 'PrintMRAN';
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                break;
            case 'print':
                reportParms.ClaimTitle = 'Print Claim';
            case 'des':
                reportParms.ClaimTitle = this.useSingleReportName;
                reportParms.ExecAction = 'NotesFiles';
                reportParms.ExecNode = 'Report';
                reportParms.ExecProcess = 'DES';
                reportParms.ExtraInstruction = 'Click Continue to create the notes file without viewing the claim list.';
                break;
            case 'reprinteoboutsource':
                reportParms.ClaimTitle = 'Reprint Claim + EOB (Outsource)';
                reportParms.ExecAction = 'ReprintEOBOutsource';
                reportParms.ExecNode = 'PaperClaim';
                reportParms.ExecProcess = reportProcess;
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                reportParms.claimStateRestrictions = ['', '3'];
                break;
            case 'eoboutsource':
                reportParms.ClaimTitle = 'Claim + EOB (Outsource)';
                reportParms.ExecAction = 'PrintEOBOutsource';
                reportParms.ExecNode = 'PaperClaim';
                reportParms.ExecProcess = reportProcess;
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                reportParms.claimStateRestrictions = ['', '1', '3'];
                break;
            case 'depoutsource':
                reportParms.ClaimTitle = 'Outsource Extract';
                reportParms.ExecAction = 'ExtractDataOutsource';
                reportParms.ExecNode = 'MultiClaim';
                reportParms.ExecProcess = reportProcess;
                reportParms.ExtraInstruction = 'Click Continue to create the extract without viewing the claim list.';
                reportParms.claimStateRestrictions = ['', '1', '3'];
                break;
            case 'remit':
            case 'management':
            case 'denial':
                reportParms.ClaimTitle = 'Remit Report';
                reportParms.ExecAction = 'GetReport';
                reportParms.ExecNode = 'Report';
                reportParms.ExecProcess = reportProcess;
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                break;
            case 'rsrtcustom':
                reportParms.ClaimTitle = 'View/Modify Regulatory Reporting Claims';
                reportParms.ExecAction = 'GetReport';
                reportParms.ExecNode = 'Report';
                reportParms.ExecProcess = reportProcess;
                reportParms.ExtraInstruction = 'When you turn on an option, all claims that match will be in your working group. Turn on options and enter information until you have created the most precise set of requirements. Click Refine to check the group of claims before you continue.';
                break;
            //case 'analysis':
            //    actionOf = 'GetReport';
            default:
                reportParms.ClaimTitle = 'Claim Report';
                reportParms.ExecAction = 'GetReport';
                reportParms.ExecNode = 'Report';
                reportParms.ExecProcess = reportProcess;
                reportParms.ExtraInstruction = 'Click Continue to create the report without viewing the claim list.';
                break;
        }

        return reportParms;
    }

    public getCreateReportType(): string {

        let firstDigit: string = this.reportPathToPrint.toString().substr(0, 1);

        if (firstDigit == '1')
            return '';
        else if (firstDigit == '2')
            return 'Claim';
        else if (firstDigit == '3')
            return 'Remit';
        //else if (firstDigit == '4')
        //    return '?';
        //else if (firstDigit == '5')
        //    return '?';
        //else if (firstDigit == '6')
        //    return '?';
        //else if (firstDigit == '7')
        //    return '?';

        return ''; // debuke says: ??
    }

    public getCreateReportProcess(execProcess: string): string {
        let resultOf: string = '';

        return resultOf;
    }

    public async executeApiReportCreateProcess(reportProcess: string, rcpv: any) {
        var xmlData: APIXMLCB_XmlCallBackModel = {};
        let parms: ReportCreateFilterSingleClaimPrint = this.setReportExecParms(reportProcess);

        var jsonData: any = {};
        jsonData.Report = {}; // execNode
        jsonData.Report['@Action'] = parms.ExecAction;
        jsonData.Report['@Type'] = this.getCreateReportType(); // debuke says, i have a feeling this is going to be needing to be hashed out more...

        // bold move, but going foreward, going to BLOCK processes by default and add if needed, i'm not seeing any report needing that from here...
        //if (this.getProcessForReport === '???') { // then do this...
        jsonData.Report['@Process'] = this.getCreateReportProcess(parms.ExecProcess);
        //}

        // report list here...
        jsonData.Report.ReportRequests = rcpv;

        xmlData.Action = parms.ExecAction;
        xmlData.ViewOrigin = '/Reports/ReportCreate.asp';  // see note in type def
        xmlData.FilterJsonData = JSON.stringify(jsonData);
        console.log(xmlData);
        //console.log(JSON.stringify(xmlData));

        console.log('** executeApiReportCreateProcess(CR) clicked **');
        console.log('action[' + parms.ExecAction + '] process[' + parms.ExecProcess + '] title[' + parms.ClaimTitle + '] node[' + parms.ExecNode + '] instr[' + parms.ExtraInstruction + ']');
        console.log(jsonData.Report);

        await fetch(URLs.api + '/api/data/PostMultiClaim',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
                if (response.status === 200) {
                    this.goToHomePage();
                    return;
                } else {
                    throw new Error('ReportCreate: Failed to create the report job ' + response.status + ' ' + response.statusText);
                }
            })
            .catch(error => {
                console.error('ClaimFilter: ' + error);
                this.errorCallback('Failed to create the report job.');
            })
            .finally(() => {
                console.log('ReportCreate MultiClaim Request::finally');
            });
    }

    public onDisplayReportCreateError(msg: string) {
        this.props.action.ui.displayErrorMessage({ api: this.props.dataStore.api.data, uiData: { value: msg } });
    }

    public onClearReportCreateErrorHandler() {
        this.props.action.ui.displayErrorMessage({ api: this.props.dataStore.api.data, uiData: { value: '' } });
    }

    public onSelectReportType(e: React.ChangeEvent<HTMLSelectElement>) {
        if (this.finalReportRequest && this.finalReportRequest.ReportRequest)
            this.finalReportRequest.ReportRequest.splice(0); // first, let's clear our array...

        this.submissionCsvCount = this.submissionPdfCount = this.submissionTxtCount = 0;

        let newOption: string = e.target.options[e.target.options.selectedIndex].label.toString().trim();

        this.currentCategory = e.target.options[e.target.options.selectedIndex].value.toString();
        let selectSingleReport: boolean = false;
        let selectRemitEobReport: boolean = false;
        switch (this.currentCategory) {
            case 'Unreleased':
                this.currentType = eTypeStateVars.UNRELEASED;
                break;
            case 'Released':
                this.currentType = eTypeStateVars.RELEASED;
                break;
            case 'Remit':
                selectRemitEobReport = true;
                this.currentType = eTypeStateVars.REMIT;
                break;
            case 'Submission':
                this.subSummaryCheck = true; // we initialize the summary...
                this.currentType = eTypeStateVars.SUBMISSION;
                break;
            case 'System':
                this.currentType = eTypeStateVars.SYSTEM;
                break;
            case 'Management':
                selectSingleReport = true;
                this.currentType = eTypeStateVars.MANAGEMENT;
                break;
            case 'Direct':
                selectSingleReport = true;
                this.currentType = eTypeStateVars.DIRECTENTRY;
                break;
            case 'Miscellaneous':
                selectSingleReport = true;
                this.currentType = eTypeStateVars.MISCELLANEOUS;
                break;
            case 'Outsource':
                selectSingleReport = true;
                this.currentType = eTypeStateVars.OUTSOURCE;
                break;
            case 'Custom':
                selectSingleReport = true;
                this.currentType = eTypeStateVars.CUSTOM;
                break;
            default:
                break;
        }

        if (selectSingleReport == true) {
            this.singleReportSelected = null;
        }

        this.reportGrouping = '';
        this.setState({
            selectedReportType: newOption,
            submissionVisible: (newOption == 'Submission'),
            groupingsDisabled: this.currentType == eTypeStateVars.CUSTOM ? false : true,
            useSingleReport: selectSingleReport,
            remitEobReportType: selectRemitEobReport,
        });
    }

    public onSelectGrouping(e: React.ChangeEvent<HTMLSelectElement>) {
        let previousGrouping: string = this.reportGrouping; // current selection
        this.reportGrouping = e.target.options[e.target.options.selectedIndex].value.toString();
        this.selectedGroupingType = this.reportGrouping;

        let processContinue: boolean = true;
        let groupingSelected: boolean = this.reportGrouping.length > 0;
        if (!groupingSelected) { // remove the groupings...
            while (processContinue) {
                let groupingToRemove: any = this.finalReportRequest!.ReportRequest!.find(req => req['@Grouping'] != null && req['@Grouping']!.toString().length > 0 && req['@Grouping']?.toString() !== 'Grouping');
                if (groupingToRemove) {
                    groupingToRemove['@Grouping'] = '';
                    console.log('removing grouping: reportId[' + groupingToRemove['@ID'] + ']');
                } else {
                    processContinue = false;
                }
            }
        } else { // they selected a grouping...
            while (groupingSelected) {
                if (previousGrouping.length > 0) { // we are just switching the grouping name...
                    let groupingToAdd: any = this.finalReportRequest?.ReportRequest?.find(req => req['@Grouping'] != null && req['@Grouping']!.toString()?.length > 0 && req['@Grouping']?.toString() === previousGrouping);
                    if (groupingToAdd) {
                        //console.log('adding: reportId[' + reportId + ']');
                        groupingToAdd['@Grouping'] = this.reportGrouping;
                        //console.log('adding grouping: reportId[' + groupingToAdd['@ID'] + '] time[' + timeOf + ']');
                    } else {
                        groupingSelected = false;
                    }
                } else { // we are coming from no groupings...
                    let groupingToAdd: any = this.finalReportRequest?.ReportRequest?.find(req => req['@Grouping'] != null && req['@Grouping']!.toString()?.length > 0 && req['@Grouping']?.toString() === 'Grouping');
                    if (groupingToAdd) {
                        //console.log('adding: reportId[' + reportId + ']');
                        groupingToAdd['@Grouping'] = this.reportGrouping;
                        //console.log('adding grouping: reportId[' + groupingToAdd['@ID'] + '] time[' + timeOf + ']');
                    } else {
                        groupingSelected = false;
                    }
                }
            }
        }
    }

    public onSelectReportToCreate(eventSelectReport: any, isSelected: boolean, record: any) {
        // console.log('checkbox[' + eventSelectReport.target.name + '] isSelected[' + isSelected + '] report[' + record.Name + ']');
        if (eventSelectReport && isSelected !== undefined) {

            let isSubmissionType: boolean = false;
            if (this.state.selectedReportType == 'Submission')
                isSubmissionType = true;

            let groupingSelected: boolean = eventSelectReport.target.name.includes('Grouping');
            if (this.state.selectedReportType != 'Custom') {
                if (groupingSelected && isSelected) { // we be in the groupings...
                    this.setState({ groupingsDisabled: false });
                    this.groupingCount++;
                } else if (groupingSelected && !isSelected) {
                    let groupingCountChanged: boolean = false;
                    if (this.groupingCount > 0) { // only if we have selected a grouping previously...
                        groupingCountChanged = true;
                        this.groupingCount--;
                    }

                    if (groupingCountChanged && this.groupingCount == 0) {
                        this.setState({ groupingsDisabled: true/*, selectedGroupingType: ''*/ });
                        this.reportGrouping = '';
                    }
                }
            }


            //console.log('this.groupingCount[' + this.groupingCount + '] groupingsDisabled[' + this.state.groupingsDisabled + ']');

            if (this.state.useSingleReport) {
                //  let allCheckboxes: any = document.querySelectorAll('input.checkbox-input.table-row-checkbox');
                let allCheckboxes: any = document.querySelectorAll('.report-grid table tbody tr td div span div div:not([style*="visibility: hidden"]) input')

                let checkedElement: HTMLInputElement = eventSelectReport.target as HTMLInputElement;
                //debugger; //<<< REMOVE WHEN DONE DEBUGGING!!!

                for (var i = 0, length = allCheckboxes.length; i < length; i++) {
                    (allCheckboxes[i] as HTMLInputElement).checked = false;
                }

                checkedElement.checked = true;

                if (this.singleReportSelected == null) { // first time you come in...
                    this.singleReportSelected = checkedElement;
                } else if (this.singleReportSelected == checkedElement) { // come in and selected the same one...
                    checkedElement.checked = false;
                    this.singleReportSelected = null;
                } else { // come in and select a different item...
                    this.singleReportSelected.checked = false;
                    this.singleReportSelected = checkedElement;
                }

                this.finalReportRequest.ReportRequest?.splice(0); // let's clear our array to allow the user the ability to select a report now...
            } else {
                this.singleReportSelected = false; // in the event user moves from type to type...
            }

            let reportNameToPrint: string = record.Name.toString().trim(); // e.g. Claim Error Detail (1501)
            let bracketIndex: number = reportNameToPrint.indexOf('[');
            if (bracketIndex != -1)
                reportNameToPrint = reportNameToPrint.substr(0, bracketIndex - 1);
            let reportFormatFromID: string = eventSelectReport.target?.name?.toString(); // e.g. 'PDF-201-checkbox'
            let reportItemArray: string[] = reportFormatFromID.split('-');
            let reportFormatToPrint: string = reportItemArray[0];
            this.reportPathToPrint = reportItemArray[1];
            let reportIdFromId: string = reportItemArray[2];
            let auditNo: string = reportItemArray[3] == 'NA' ? '' : reportItemArray[2];
            let groupingFlag: string = reportItemArray[4] == 'checkbox' ? '' : (this.reportGrouping.toString().length > 0 ? this.reportGrouping : reportItemArray[4]); // either add the flag for when user selects grouping, or add the grouping that is already in selected...
            let reportOf: any = this.getReportObjectBasedOnState();

            if (this.state.useSingleReport) {
                this.singleReportId = reportIdFromId;
                this.useSingleReportName = reportNameToPrint;
            } else
                this.useSingleReportName = '';

            let reportSection: any = reportOf.find((rpt: { Name: any; }) => rpt.Name.trim() === reportNameToPrint.trim());

            let formatOf: any;
            let typeOf: any;
            let reportId: string = this.getReportId(reportSection.ID, reportFormatToPrint);
            if (isSubmissionType) {
                // if isSubmissionType, get the type from one of the the four report types, based on the reportID...
                let submitTargetSection: any = reportOf.find((rpt: { Name: string; }) => (rpt.Name.trim() === 'Submission')); // summary type, hack, fix it...
                if (submitTargetSection?.ReportInfo && submitTargetSection.ReportInfo?.Formats && submitTargetSection.ReportInfo.Formats.length > 0) {
                    if (submitTargetSection.ReportInfo.Type) {
                        typeOf = submitTargetSection.ReportInfo.Type;
                    }
                }

                console.log('submission: NEW type[' + typeOf + ']');
            } else if (this.state.remitEobReportType) { // top two remit reports (1934 & 1935) are selectable by themselves, then the rest of the reports... yes, odd, i know...
                // let allBalancingCheckboxes: any = document.querySelectorAll('input.checkbox-input.table-row-checkbox');
                let allBalancingCheckboxes: any = document.querySelectorAll('.report-grid table tbody tr td div span div div:not([style*="visibility: hidden"]) input')
                let checkedEobElement: HTMLInputElement = eventSelectReport.target as HTMLInputElement;

                if (reportId == '1934' || reportId == '1935') { // selecting top section...
                    //    //debugger; //<<< REMOVE WHEN DONE DEBUGGING!!!

                    for (var i = 0, length = allBalancingCheckboxes.length; i < length; i++) {
                        let labelArray: string[] = allBalancingCheckboxes[i]?.name.toString().split('-');
                        if (labelArray[2] != '1934' && labelArray[2] != '1935') {
                            (allBalancingCheckboxes[i] as HTMLInputElement).checked = false;
                            let reportEobIdxToRemove: any = this.finalReportRequest?.ReportRequest?.findIndex(req => req['@ID'] === labelArray[2]);
                            if (reportEobIdxToRemove !== -1) {
                                this.finalReportRequest?.ReportRequest?.splice(reportEobIdxToRemove, 1);
                            }
                        }
                    }

                    this.selectedBalancingRemitReport = true;

                } else { // selecting bottom section...
                    if (this.selectedBalancingRemitReport == true) { // we selected one of the two already, so just unselect it all...
                        for (var i = 0, length = allBalancingCheckboxes.length; i < length; i++) {
                            let labelArray: string[] = allBalancingCheckboxes[i]?.name.toString().split('-');
                            if (labelArray[2] == '1934' || labelArray[2] == '1935') {
                                (allBalancingCheckboxes[i] as HTMLInputElement).checked = false;
                                let reportIdxToRemove: any = this.finalReportRequest?.ReportRequest?.findIndex(req => req['@ID'] === labelArray[2]);
                                if (reportIdxToRemove !== -1) {
                                    this.finalReportRequest?.ReportRequest?.splice(reportIdxToRemove, 1);
                                }
                            }
                        }

                        checkedEobElement.checked = true;

                        //debuke says: don't remove them all, just remove the 1934 and 1935... there is another spot here in the code that is doing that (by ID)...
                        this.finalReportRequest.ReportRequest?.splice(0);
                    }

                    this.selectedBalancingRemitReport = false;
                }
            }

            console.log('reportId[' + reportId + ']');

            if (reportSection) {
                if (!isSubmissionType && reportSection?.ReportInfo && reportSection.ReportInfo?.Formats && reportSection.ReportInfo.Formats.length > 0) {
                    formatOf = reportSection.ReportInfo.Formats.find((fmt: { Type: string; }) => fmt.Type === reportFormatToPrint);
                    if (reportSection.ReportInfo.Type) {
                        typeOf = reportSection.ReportInfo.Type; // if submission, don't set type, we've already set it...
                    }
                }

                let clientId: string = '';
                let bridgeName: string = '';
                let timeStamp: string = '';
                if (isSubmissionType) {
                    let submitArray: string[] = reportSection.ID.split('-'); // splitting the dom id, not the report id...
                    if (submitArray && submitArray.length == 3) { // requirement
                        clientId = submitArray[0].trim(); // no data integrity check on the old page, either... 
                        bridgeName = submitArray[1].trim();
                        timeStamp = submitArray[2].trim();
                    }

                    console.log('submission: clientId[' + clientId + '] bridgeName[' + bridgeName + '] timeStamp[' + timeStamp + ']');
                }

                if (isSelected) {
                    this.finalReportRequest['@Type'] = typeOf;

                    let reportIdAgain: string = '';
                    let addBothSummaryTypes: boolean = false;
                    if (isSubmissionType && reportId.indexOf('-') != -1) { // adding multiple reports...
                        let idArray: string[] = reportId.split('-');
                        reportId = idArray[0];
                        reportIdAgain = idArray[1];
                        addBothSummaryTypes = true;
                        console.log('adding 2nd report!');
                    }

                    let secondSubmissionReportName: string = '';
                    let reportNameMain: string = reportNameToPrint;
                    let reportItToUse: string = reportId;
                    if (isSubmissionType) {
                        reportNameMain = this.getSubmissionReportName(reportId);
                        secondSubmissionReportName = this.getSubmissionReportName(reportIdAgain);
                    }
                    let newFormatAgain: any = null;
                    let newFormat: ReportCreateRequest = this.getReportRequest(reportItToUse, reportNameMain, reportFormatToPrint, typeOf, clientId, bridgeName, timeStamp, groupingFlag, auditNo);
                    if (addBothSummaryTypes) {
                        newFormatAgain = this.getReportRequest(reportIdAgain, secondSubmissionReportName, reportFormatToPrint, typeOf, clientId, bridgeName, timeStamp, '', auditNo); // never need grouping on second reports, this is for submission reports only...
                    }

                    if (reportFormatToPrint == 'TXT' && reportId == '2227')
                        this.reportTypeOutSource2227Txt = true; // see line 175 in ReportCreate.asp

                    if (isSubmissionType) { // not counting extra repoorts... this is just a count of the checkmarks for UI purposes...
                        switch (reportFormatToPrint) {
                            case 'PDF':
                                this.submissionPdfCount++;
                                break;
                            case 'TXT':
                                this.submissionTxtCount++;
                                break;
                            case 'CSV':
                                this.submissionCsvCount++;
                                break;
                        }

                        console.log('PdfCount[' + this.submissionPdfCount + '] TxtCount[' + this.submissionTxtCount + '] CsvCount[' + this.submissionCsvCount + ']');
                    }

                    this.finalReportRequest!.ReportRequest!.push(newFormat); // added to list...

                    if (addBothSummaryTypes) {
                        console.log('adding 2nd: id[' + newFormatAgain['@ID'] + '] name[' + newFormatAgain['@Name'] + '] format[' + newFormatAgain['@Format'] + '] type[' + newFormatAgain['@Type'] + '] clientId[' + newFormatAgain['@ClientID'] + '] bridgeName[' + newFormatAgain['@BridgeName'] + '] timeStamp[' + newFormat['@TimeStamp'] + '] grouping[' + newFormat['@Grouping'] + ']');

                        this.finalReportRequest!.ReportRequest!.push(newFormatAgain); // added 2nd report to list...
                    }

                    console.log('adding: id[' + newFormat['@ID'] + '] name[' + newFormat['@Name'] + '] format[' + newFormat['@Format'] + '] type[' + newFormat['@Type'] + '] clientId[' + newFormat['@ClientID'] + '] bridgeName[' + newFormat['@BridgeName'] + '] timeStamp[' + newFormat['@TimeStamp'] + '] grouping[' + newFormat['@Grouping'] + ']');
                } else {
                    if (this.finalReportRequest && this.finalReportRequest.ReportRequest) {
                        let itemIndexIfExists: any = this.finalReportRequest.ReportRequest.findIndex(req => req['@ID'] === reportId && req['@Format'] == reportFormatToPrint);
                        if (itemIndexIfExists !== -1) {
                            console.log('removing: reportId[' + reportId + ']');

                            if (isSubmissionType) {
                                switch (reportFormatToPrint) {
                                    case 'PDF':
                                        this.submissionPdfCount--;
                                        break;
                                    case 'TXT':
                                        this.submissionTxtCount--;
                                        break;
                                    case 'CSV':
                                        this.submissionCsvCount--;
                                        break;
                                }

                                //console.log('PdfCount[' + this.submissionPdfCount + '] TxtCount[' + this.submissionTxtCount + '] CsvCount[' + this.submissionCsvCount + ']');
                            }

                            console.log('removing reportId[' + reportId + ']');

                            //this.singleReportCount--; // should never get here...
                            this.finalReportRequest.ReportRequest.splice(itemIndexIfExists, 1);
                        }
                    }
                }
            }
        }
    }

    public getSubmissionReportName(reportId: string) {
        let nameOf: string = '';
        switch (reportId) {
            case '1101':
                nameOf = this.submissionReportName;
                break;
            case '1102':
                nameOf = this.discardedReportName;
                break;
            case '1100':
                nameOf = this.balancingReportName;
                break;
            case '1104':
                nameOf = this.balancingIIReportName;
                break;
        }

        return nameOf;
    }

    public removeTheseReportsFromTheQueue(selectedOption: string) {
        console.log('submission type[' + selectedOption + ']');

        let reportFormatToRemove: string = '';
        let reportIdToRemove: string = '';
        switch (selectedOption) {
            case 'Summary':
                reportIdToRemove = this.submissionReportId;
                reportFormatToRemove = 'PDF-TXT';
                break;
            case 'Discarded':
                reportIdToRemove = this.discardedReportId;
                reportFormatToRemove = 'PDF-TXT';
                break;
            case 'Balancing':
                reportIdToRemove = this.balancingReportId;
                reportFormatToRemove = 'CSV';
                break;
            case 'BalancingII':
                reportIdToRemove = this.balancingIIReportId;
                reportFormatToRemove = 'CSV';
                break;
        }

        // first, remove the soft, hidden 'real' data...
        let removingReports: boolean = true;
        while (removingReports) {
            let idxReport: any = this.finalReportRequest?.ReportRequest?.findIndex(req => req['@ID'] === reportIdToRemove);
            if (idxReport !== -1) {
                this.finalReportRequest?.ReportRequest?.splice(idxReport, 1);
            } else {
                removingReports = false;
            }
        }

        // second, remove the UI (seen) data...
        this.unCheckDisabledCheckboxes(reportIdToRemove, reportFormatToRemove);
    }

    public unCheckDisabledCheckboxes(reportIdToRemove: string = '', reportTypeToRemove: string = '') {
        let allCheckboxes: any = document.querySelectorAll('.report-grid table tbody tr td div span div div:not([style*="visibility: hidden"]) input')
        //debugger; //<<< REMOVE WHEN DONE DEBUGGING!!!

        let removePdf: boolean = false;
        let removeTxt: boolean = false;
        let removeCsv: boolean = false;

        if (!this.subSummaryCheck && !this.subDiscardCheck) {
            removePdf = true;
            removeTxt = true;
        }
        if (!this.subBalanceCheck && !this.subBalanceIICheck)
            removeCsv = true;

        if (!removePdf && !removeTxt && !removeCsv)
            return;

        for (var i = 0, length = allCheckboxes.length; i < length; i++) {
            let myItem: HTMLInputElement = (allCheckboxes[i] as HTMLInputElement);
            if (myItem.name.toString().substr(0, 3) == 'PDF' && myItem.checked == true && removePdf) {
                myItem.click();
            }
            if (myItem.name.toString().substr(0, 3) == 'TXT' && myItem.checked == true && removeTxt) {
                myItem.click();
            }
            if (myItem.name.toString().substr(0, 3) == 'CSV' && myItem.checked == true && removeCsv) {
                myItem.click();
            }
        }
    }

    public addTheseReportsToTheQueue(selectedOption: string) {
        console.log('submission type[' + selectedOption + ']');

        let siblingReportId: string = '';
        let reportIdToAdd: string = '';
        let reportNameToAdd: string = '';
        switch (selectedOption) {
            case 'Summary':
                reportIdToAdd = this.submissionReportId;
                reportNameToAdd = this.submissionReportName;
                siblingReportId = this.discardedReportId;
                break;
            case 'Discarded':
                reportIdToAdd = this.discardedReportId;
                reportNameToAdd = this.discardedReportName;
                siblingReportId = this.submissionReportId;
                break;
            case 'Balancing':
                reportIdToAdd = this.balancingReportId;
                reportNameToAdd = this.balancingIIReportName;
                siblingReportId = this.balancingIIReportId;
                break;
            case 'BalancingII':
                reportIdToAdd = this.balancingIIReportId;
                reportNameToAdd = this.balancingReportName;
                siblingReportId = this.balancingReportId;
                break;
        }

        let selectedCount: any = this.finalReportRequest!.ReportRequest!.length;
        for (let reportIdx = 0; reportIdx < selectedCount; reportIdx++) {
            let reportItem: any = this.finalReportRequest!.ReportRequest![reportIdx];
            if (reportItem && reportItem['@ID'] == siblingReportId) {
                let newFormat: ReportCreateRequest = this.getReportRequest(reportIdToAdd, reportNameToAdd, reportItem['@Format'], reportItem['@Type'], reportItem['@ClientID'], reportItem['@BridgeName'], reportItem['@TimeStamp'], '', reportItem['@AuditNo']);
                this.finalReportRequest!.ReportRequest!.push(newFormat); // added to list...
            }
        }
    }

    public onClickSubmissionType(eventSubmission: React.ChangeEvent<HTMLInputElement>, isSelected: boolean, selectedOption: string) {
        console.log('submission type[' + selectedOption + ']');

        switch (selectedOption) {
            case 'Summary':
                this.setState({ submissionSummaryChecked: isSelected });
                this.subSummaryCheck = isSelected;
                break;
            case 'Discarded':
                this.setState({ submissionDiscardedChecked: isSelected });
                this.subDiscardCheck = isSelected;
                break;
            case 'Balancing':
                this.setState({ submissionBalancingChecked: isSelected });
                this.subBalanceCheck = isSelected;
                break;
            case 'BalancingII':
                this.setState({ submissionBalancingIIChecked: isSelected });
                this.subBalanceIICheck = isSelected;
                break;
        }

        if (!isSelected) { // if we are unselecting a report
            this.removeTheseReportsFromTheQueue(selectedOption);
        } else {
            this.addTheseReportsToTheQueue(selectedOption);
        }
        this.setState({
            submissionVisible: true,
            groupingsDisabled: true
        });
    }

    public setBusy(val: boolean) {
        this.props.action.ui.setBusy({ api: this.props.dataStore.api.data, uiData: { value: val } });
    }

    public errorCallback(dataStatus: string): boolean {
        var str = 'An error during Report Create processing call for [' + this.props.title + ']  Error [' + dataStatus + ']';
        console.error(str);
        this.onDisplayReportCreateError(str);

        return true;
    }

    public validationCallback(data: APIRC_ReportCreate): boolean {
        let dataCopy: APIRC_ReportCreate = data as APIRC_ReportCreate;

        return true;
    }

    public getReportId(currentId: string, reportFormat: string): string {
        let reportIdToUse: string = currentId;
        if (this.state.selectedReportType == 'Submission') {

            let tempSubmit: string = '';
            if (reportFormat == 'CSV') {
                if (this.state.submissionBalancingChecked)
                    tempSubmit = this.balancingReportId;
                if (this.state.submissionBalancingIIChecked)
                    if (tempSubmit != '')
                        tempSubmit = tempSubmit + '-' + this.balancingIIReportId;
                    else
                        tempSubmit = this.balancingIIReportId;
            } else if (reportFormat == 'PDF') {
                if (this.state.submissionSummaryChecked)
                    tempSubmit = this.submissionReportId;
                if (this.state.submissionDiscardedChecked)
                    if (tempSubmit != '')
                        tempSubmit = tempSubmit + '-' + this.discardedReportId;
                    else
                        tempSubmit = this.discardedReportId;
            } else if (reportFormat == 'TXT') {
                if (this.state.submissionSummaryChecked)
                    tempSubmit = this.submissionReportId;
                if (this.state.submissionDiscardedChecked)
                    if (tempSubmit != '')
                        tempSubmit = tempSubmit + '-' + this.discardedReportId;
                    else
                        tempSubmit = this.discardedReportId;
            }

            reportIdToUse = tempSubmit;
        }

        return reportIdToUse;
    }

    public getReportRequest(id: string, name: string, format: string, type: string, clientId: string, bridgeName: string, timeStamp: string, groupingFlag: string = '', auditNo: string = ''): ReportCreateRequest {
        let newRequest: ReportCreateRequest = {
            '@ID': id,
            '@Name': name,
            '@Format': format,
            '@Type': type,
            '@ClientID': clientId,
        }

        if (bridgeName.length > 0) {
            newRequest['@BridgeName'] = bridgeName;
        } else { // it's not a submission report...
            delete newRequest['@ClientID'];
        }
        if (timeStamp.length > 0)
            newRequest['@TimeStamp'] = timeStamp;
        if (groupingFlag.length > 0)
            newRequest['@Grouping'] = groupingFlag;
        if (auditNo.length > 0)
            newRequest['@AuditNo'] = auditNo; // debuke says: store on list build with grouping flag?

        return newRequest;
    }

    public getCreateReportConfigValue(flag: string): string {
        //console.log('config: ' + flag);
        let configObj: any = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === flag);
        return (configObj && configObj.value ? configObj.value : '');
    }

    public getCreateReportCookieValue(flag: string): string {
        //console.log('cookie: ' + flag);
        let configObj: any = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === flag);
        return (configObj && configObj.value ? configObj.value : '');
    }

    /*
        !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        THIS is how we are hiding reports until the pages that are required are developed.
        just remove the path for the new page.
        as shown starting on line 277 in the legacy page.
        also hiding DEP reports... look at near the .push below
        !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    */
    public doWeHideReportForNow(reportPath: string, reportName: string, reportIdToHide: string) {
        let hideForNow: boolean = false;
        let firstCharOfReportPath: string = reportPath.substr(0, 1);

        // debug only...
        //if (reportName == 'Balancing Payment Detail' || firstCharOfReportPath == '4') {
        //    firstCharOfReportPath += '';
        //    this.submissionTxtCount = 0;
        //}

        // resulting/target pages not done...
        /*
            3 = remit filter
            4 = date range filter
            5 = filter regen
            6 = admin portal
            7 = rsrt custom

            turning on specific reports as we go (e.g. 4004)

            RASS-28256 : Create Reports Page : /Remits/Filter.asp (Remit Filter)
            1000 Explanation Of Benefits Summary
            1001 Explanation Of Benefits Detai
            1003 Secondary Billed Exception
            1005 Explanation Of Benefits Itemized
            1412 Matched Payments
            1413 Unmatched Payments
            1910 Remittance Voucher Detail
            1911 Remittance Voucher Summary
            1912 Remittance Voucher Summary Business Scenarios
            1913 Medicare Crossover Remits
            1914 Remittance Voucher Summary + Business Scenarios
            1915 Medicare Beneficiary Identifier
            1933 Payment Processing Status
            1938 Payment Matching
            1954 Remittance Catalogue
        */
        //if (reportIdToHide == '1738')
        //    debugger;


        //  console.log('firstCharOfReportPath[' + firstCharOfReportPath + '] reportIdToHide[' + reportIdToHide + ']');
        let lowerReports: boolean = this.currentCategory == 'Management' || this.currentCategory == 'Custom' || this.currentCategory == 'DirectEntry';

        if (firstCharOfReportPath == '3') {
            if (this.currentCategory == 'Management') {
                if (reportIdToHide == '1738' ||         // show Denial Management reports
                    reportIdToHide == '1736' ||
                    reportIdToHide == '1735' ||
                    reportIdToHide == '1734' ||
                    reportIdToHide == '1733') {
                    return false;
                }
            }
        }

        if (!lowerReports &&
            (firstCharOfReportPath == '1' &&
                this.currentCategory != 'System' &&
                reportIdToHide != '1862' &&
                reportIdToHide != '1863' &&
                reportIdToHide != '2012' &&
                reportIdToHide != '2013' &&
                reportIdToHide != '2504' &&
                reportIdToHide != '1854') ||
            (firstCharOfReportPath == '3' &&
                this.currentCategory != 'System' &&
                reportIdToHide != '1000' &&
                reportIdToHide != '1001' &&
                reportIdToHide != '1003' &&
                reportIdToHide != '1005' &&
                reportIdToHide != '1412' &&
                reportIdToHide != '1413' &&
                reportIdToHide != '1910' &&
                reportIdToHide != '1911' &&
                reportIdToHide != '1912' &&
                reportIdToHide != '1913' &&
                reportIdToHide != '1914' &&
                reportIdToHide != '1915' &&
                reportIdToHide != '1916' &&
                reportIdToHide != '1917' &&
                reportIdToHide != '1933' &&
                reportIdToHide != '1938' &&
                reportIdToHide != '1954') ||
            /*(firstCharOfReportPath == '4' && reportName != 'Balancing Payment Detail') ||
            firstCharOfReportPath == '4' || see story RASS-28711 */
            (firstCharOfReportPath == '4' &&
                this.currentCategory != 'System' &&
                reportIdToHide != '1934' &&
                reportIdToHide != '1935' &&
                reportIdToHide != '1260' &&
                reportIdToHide != '1721' &&
                reportIdToHide != '1810' &&
                reportIdToHide != '1830' &&
                reportIdToHide != '1831' &&
                reportIdToHide != '1834' &&
                reportIdToHide != '1835' &&
                reportIdToHide != '1551' &&
                reportIdToHide != '1832' &&
                reportIdToHide != '5004' &&
                reportIdToHide != '5101' &&
                reportIdToHide != '5102' &&
                reportIdToHide != '1103' &&
                reportIdToHide != '1324') ||
            (firstCharOfReportPath == '5' &&
                this.currentCategory != 'System' &&
                reportIdToHide != '2501' &&
                reportIdToHide != '1860R' &&
                reportIdToHide != '1864R' &&
                reportIdToHide != '2501' &&
                reportIdToHide != '2502' &&
                reportIdToHide != '1865R') ||
            (firstCharOfReportPath == '7' &&
                reportIdToHide != '2505') ||
            (firstCharOfReportPath == '6' &&
                reportIdToHide != '2503') ||
            reportIdToHide == '4004' || /* see  story RASS-28984 & RASS-28711 : we hiding these report id's based on that story */
            reportIdToHide == '4005' ||
            reportIdToHide == '5001' ||
            reportIdToHide == '5009' ||
            reportIdToHide == '5010') {
            hideForNow = true;
        } else {

            // for now: showing only the reports that have path first char = 4... will add the others when QA has bandwidth to test...
            switch (this.currentCategory) {
                case 'Management':
                case 'Custom':
                    /* stories unlucking reports with:
                        RASS-28414 : 1861, 1863
                        RASS-30559 : 1720, 1737, 1740, 1833, 1839
                        RASS-30561 : 1863, 2013, 2504
                        RASS-28258 : 1860, 1860R
                        RASS-30596 : 1856, 1859, 1852, 1452, 1453, 1410, 1450, 1454, 1455
                        RASS-30604 : 1321, 1325, 1315, 2012, 2015, 2016, 1306, 1305, 1107, 1310, 1311, 1251, 1312, 1250
                        RASS-28263 : 2505
                    */
                    if (reportIdToHide == '2012' && firstCharOfReportPath == '1') { break; }

                    if (reportIdToHide.substring(0, 4) != '1860' &&
                        reportIdToHide != '2503' &&
                        reportIdToHide != '2505' &&
                        reportIdToHide != '1861' &&
                        reportIdToHide != '1320' &&
                        reportIdToHide != '1322' &&
                        reportIdToHide != '1323' &&
                        reportIdToHide != '1862' &&
                        reportIdToHide != '1863' &&
                        reportIdToHide != '1720' &&
                        reportIdToHide != '1737' &&
                        reportIdToHide != '1740' &&
                        reportIdToHide != '1833' &&
                        reportIdToHide != '1839' &&
                        reportIdToHide != '1864' &&
                        reportIdToHide != '1864R' &&
                        reportIdToHide != '1860' &&
                        reportIdToHide != '1860R' &&
                        reportIdToHide != '1865' &&
                        reportIdToHide != '1865R' &&
                        reportIdToHide != '2013' &&
                        reportIdToHide != '2504' &&
                        reportIdToHide != '2501' &&
                        reportIdToHide != '2502' &&
                        reportIdToHide != '1856' &&
                        reportIdToHide != '1859' &&
                        reportIdToHide != '1852' &&
                        reportIdToHide != '1452' &&
                        reportIdToHide != '1453' &&
                        reportIdToHide != '1410' &&
                        reportIdToHide != '1450' &&
                        reportIdToHide != '1454' &&
                        reportIdToHide != '1455' &&
                        reportIdToHide != '1321' &&
                        reportIdToHide != '1325' &&
                        reportIdToHide != '1315' &&
                        //                        reportIdToHide != '2012' &&
                        reportIdToHide != '2015' &&
                        reportIdToHide != '2016' &&
                        reportIdToHide != '1306' &&
                        reportIdToHide != '1305' &&
                        reportIdToHide != '1107' &&
                        reportIdToHide != '1310' &&
                        reportIdToHide != '1311' &&
                        reportIdToHide != '1251' &&
                        reportIdToHide != '1312' &&
                        reportIdToHide != '1250') {
                        if (firstCharOfReportPath != '4')
                            hideForNow = true;
                    }
                    break;
                case 'DirectEntry':
                    if (reportIdToHide != '1850' &&
                        reportIdToHide != '1844' &&
                        reportIdToHide != '1843' &&
                        reportIdToHide != '1841' &&
                        reportIdToHide != '1842' &&
                        reportIdToHide != '1106' &&
                        reportIdToHide != '1848' &&
                        reportIdToHide != '1845' &&
                        reportIdToHide != '1846' &&
                        reportIdToHide != '1840' &&
                        reportIdToHide != '1847') {
                        if (firstCharOfReportPath != '4')
                            hideForNow = true;
                    }
                    break;
            }
        }

        //if (reportName.toLowerCase().indexOf('hcd') >= 0) {
        //    reportName = reportName + '!';
        //    debugger;
        //}

        // PaperClaims not implemented in filter yet...
        // RASS-30580 & RASS-30584
        if (reportName.toLowerCase().indexOf('claim +') >= 0) {
            if (reportIdToHide != '1018' &&
                reportIdToHide != '1853' &&
                reportIdToHide != '1946' &&
                reportIdToHide != '1947' &&
                reportIdToHide != '1011' &&
                reportIdToHide != '1015' &&
                reportIdToHide != '1016' &&
                reportIdToHide != '1004' &&
                reportIdToHide != '1019' &&
                reportIdToHide != '1949')
                hideForNow = true;
        }

        //if (reportName.toLowerCase().substr(0, 20) == 'california ltc claim') hideForNow = true;
        if (reportName.toLowerCase().substr(0, 15) == 'reprint claim +') hideForNow = true;

        return hideForNow;
    }

    /*
        NOTE: as the new target pages (RemitFilter, Date Range Filter, etc etc) are developed,
              the report types below need to be added back and developed...
    */
    public buildReportTypeComboBoxList = (reportType: string = 'UnreleasedReports'): any => {

        this.allReportTypes.reportList = [];
        let multipleReports: IReportCreateOption[] = [];
        let singleReports: IReportCreateOption[] = [];

        let unreleasedReport: IReportCreateOption = { 'value': 'Unreleased', 'label': 'Unreleased Claim' };
        if (this.props!.accessList[eCreateReportSecurityBits.RPT_CREATE_CLAIM_REPORTS])
            multipleReports.push(unreleasedReport);
        let releasedReport: IReportCreateOption = { 'value': 'Released', 'label': 'Released Claim' };
        if (this.props!.accessList[eCreateReportSecurityBits.RPT_CREATE_CLAIM_REPORTS])
            multipleReports.push(releasedReport);
        /*
            !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            THIS is how we are hiding reports until the pages that are required are developed.
            just remove the path for the new page.
            as shown starting on line 277 in the legacy page.
            !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        */
        let remitReport: IReportCreateOption = { 'value': 'Remit', 'label': 'Remit' };
        if (this.props!.accessList[eCreateReportSecurityBits.RPT_CREATE_REMIT_REPORTS])
            multipleReports.push(remitReport);
        let submissionReport: IReportCreateOption = { 'value': 'Submission', 'label': 'Submission' };
        if (this.props!.accessList[eCreateReportSecurityBits.RPT_CREATE_SUBMISSION_REPORTS])
            multipleReports.push(submissionReport);
        let systemReport: IReportCreateOption = { 'value': 'System', 'label': 'System' };
        if (this.props!.accessList[eCreateReportSecurityBits.RPT_CREATE_SYSTEM_REPORTS])
            multipleReports.push(systemReport);

        this.allReportTypes.reportList?.push({ 'items': multipleReports, 'label': 'Multiple Reports' });

        let managementReport: IReportCreateOption = { 'value': 'Management', 'label': 'Management' };
        if (this.props!.accessList[eCreateReportSecurityBits.RPT_CREATE_MANAGEMENT_REPORTS])
            singleReports.push(managementReport);

        let directEntryCookie = this.getCreateReportCookieValue('DirectEntry');
        let directEntryReport: IReportCreateOption = { 'value': 'Direct', 'label': 'Direct Entry' };
        if (directEntryCookie && directEntryCookie == '1') {
            singleReports.push(directEntryReport);
        }

        let miscellaneousReport: IReportCreateOption = { 'value': 'Miscellaneous', 'label': 'Miscellaneous' };
        if (this.props.accessList[eCreateReportSecurityBits.RPT_CREATE_MISCELLANEOUS_REPORTS])
            singleReports.push(miscellaneousReport);
        let outsourceReport: IReportCreateOption = { 'value': 'Outsource', 'label': 'Outsource' };
        if (this.props!.accessList[eCreateReportSecurityBits.EBO_RPT_CREATE_OUTSOURCE_REPORTS])
            singleReports.push(outsourceReport);
        let customReport: IReportCreateOption = { 'value': 'Custom', 'label': 'Custom' };
        if (this.props!.accessList[eCreateReportSecurityBits.RPT_CREATE_CUSTOM_REPORTS])
            singleReports.push(customReport);

        this.allReportTypes.reportList.push({ items: singleReports, label: 'Single Report' });

        return this.allReportTypes.reportList;
    }

    public buildGroupingTypeComboBoxList(): any[] {
        let groupings: any[] = [
            { value: '', label: '- Select Grouping -' },
            { value: 'FedTaxID', label: 'Federal Tax ID' },
            { value: 'FacSubID', label: 'Facility Sub ID' },
        ];

        return groupings;
    }

    public buildReportCreateList(): ReportCreateCurrentList[] {
        let reportListFinal: ReportCreateCurrentList[] = [];

        if (this.props.dataStore.api.data) {
            let reportOf: any = this.getReportObjectBasedOnState();
            let showReportsBasedOnRights: boolean = this.isReportListAllowedBasedOnState();

            if (reportOf && reportOf.length && reportOf.length > 0 && showReportsBasedOnRights) {

                reportListFinal.splice(0); // shouldn't be needed!!  why isn't it not clearing when switching types??

                let eligibilityCookie: string = this.getCreateReportCookieValue('Eligibility');
                let mcaMethodCookie: string = this.getCreateReportCookieValue('MCAMethod');
                let enableMCDEConfig: string = this.getCreateReportCookieValue('MCDEEnabled');
                let attachmentsEnabledConfig: string = this.getCreateReportCookieValue('AttachmentsEnabled');
                let hcdomsConfig: string = this.getCreateReportConfigValue('HCDOMSId');
                let enableTenetConfig: string = this.getCreateReportConfigValue('EnableTenetFeatures');
                let prebillEnabledConfig: string = this.getCreateReportCookieValue('PrebillEnabled');
                let isNDCUser: boolean = this.getCreateReportConfigValue('isClientUser') == 'True' ? false : true; // backwards, since we are checking for ndc user...

                //console.log('eligibilityCookie [' + eligibilityCookie + '] mcaMethodCookie [' + mcaMethodCookie + '] enableMCDEConfig [' + enableMCDEConfig + '] attachmentsEnabledConfig [' + attachmentsEnabledConfig + '] hcdomsConfig [' + hcdomsConfig + '] enableTenetConfig [' + enableTenetConfig + '] prebillEnabledConfig [' + prebillEnabledConfig + ']');

                for (let reportIndex = 0; reportIndex < reportOf.length; reportIndex++) {
                    let reportListPartial: ReportCreateCurrentList = new ReportCreateCurrentList();

                    let reportName: string = reportOf[reportIndex].Name;
                    let reportPath: string = reportOf[reportIndex].Path;
                    let reportId: string = reportOf[reportIndex].ID;
                    let reportAllow: any = isNDCUser ? '1' : reportOf[reportIndex].Allow; // Note: Allow flag comes from Restrict User Access (Report tab)

                    let enableMCDE: string = enableMCDEConfig && enableMCDEConfig == '1' ? enableMCDEConfig : enableMCDEConfig.length > 0 ? enableMCDEConfig : '0';

                    let hasRights: boolean = true;
                    if (prebillEnabledConfig && prebillEnabledConfig == '1' && this.props!.accessList[eCreateReportSecurityBits.RPT_CREATE_SUBMISSION_REPORTS])
                        if (this.props!.accessList[eCreateReportSecurityBits.RPT_CREATE_CLAIM_REPORTS])
                            if (reportName.toLowerCase().indexOf('pre-bill filter') >= 0)
                                hasRights = false;
                    if (eligibilityCookie && eligibilityCookie != '1')
                        if (reportName.toLowerCase().indexOf('eligibility') >= 0)
                            hasRights = false;
                    if (reportId == '1861' || reportId == '1862') {
                        if (mcaMethodCookie && mcaMethodCookie != '1')
                            hasRights = false;
                    }
                    if (!hcdomsConfig) // if we're not configured for hcdoms, disable report 1017
                        if (reportName.toLowerCase().indexOf('hcd') >= 0) hasRights = false;
                    if (enableMCDE == '0')
                        if (reportId == '1850')
                            hasRights = false;
                    if (attachmentsEnabledConfig && attachmentsEnabledConfig == '0')
                        if (reportId == '1551')
                            hasRights = false;
                    if (enableTenetConfig && enableTenetConfig != '1')
                        if (reportId == '1310' || reportId == '1311' || reportId == '1312')
                            hasRights = false;

                    /*
                        EPIC CRD and Claim Update File (1550) report should not be displayed in this screen. It is only accessible through AutoModules
                    */
                    if (reportId == '2250' || reportId == '2251' || reportId == '2252' || reportId == '2253' || reportId == '2254' || reportId == '2255' || reportId == '1550')
                        hasRights = false;

                    /*
                        !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                        THIS is how we are hiding reports until the pages that are required are developed.  
                        just remove the path for the new page.
                        as shown starting on line 277 in the legacy page.
                        also hiding DEP reports... look at near the .push below
                        !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                     */
                    //if (reportName.toLowerCase().indexOf('hcd') > 0) {
                    //debugger;
                    //    reportName += '';
                    //}
                    let isSubmissionType: boolean = false;
                    if (this.state.selectedReportType == 'Submission')
                        isSubmissionType = true;
                    let isSubmissionReport: boolean = false;
                    let hideReportForNow: boolean = this.doWeHideReportForNow(reportPath, reportName, reportId);
                    if ((hasRights && !hideReportForNow) || isSubmissionType) {
                        //console.log('SHOWING: reportName[' + reportName + '] reportPath[' + reportPath + '] reportId[' + reportId + '] reportAllow[' + reportAllow + ']');
                        if (isSubmissionType) {
                            isSubmissionReport = reportOf[reportIndex]?.ReportInfo && reportOf[reportIndex]?.ReportInfo?.Formats === null;
                            if (!isSubmissionReport) {
                                // remember which reports are allowed by Restrict User Access
                                if (reportId == this.submissionReportId)
                                    this.allowSubSumm = reportAllow;
                                else if (reportId == this.discardedReportId)
                                    this.allowSubDisc = reportAllow;
                                else if (reportId == this.balancingReportId)
                                    this.allowSubBal = reportAllow;
                                else if (reportId == this.balancingIIReportId)
                                    this.allowSubBal2 = reportAllow;
                                continue; // don't put the balancing 1/2, summary and discard reports on the page...
                            }
                        }

                        reportListPartial.Name = reportName.trim() + (!isSubmissionReport ? ' [' + reportId + ']' : '');

                        // how submission works: comes in & Summary is auto selected and pdf and txt is avail. they click any balancing, the csv pops up, if they unselect, the csv goes away...

                        let hidePdf: boolean = true;
                        let hideCsv: boolean = true;
                        let hideXml: boolean = true;
                        let hideTxt: boolean = true;
                        let hideOther: boolean = true;
                        let colourPdf: boolean = false;
                        let colourCsv: boolean = false;
                        let colourXml: boolean = false;
                        let colourTxt: boolean = false;
                        let colourOther: boolean = false;
                        let formatType: string = '';
                        let otherFormat: string = '';;
                        let formatArray: any = [];

                        // if submission, show the initial 2 rows and then show the balancing if it is clicked, 
                        // otherwise, just show the report list...
                        if (isSubmissionReport) {
                            hidePdf = !this.state.submissionSummaryChecked && !this.state.submissionDiscardedChecked;
                            hideTxt = !this.state.submissionSummaryChecked && !this.state.submissionDiscardedChecked;
                            hideCsv = !this.state.submissionBalancingChecked && !this.state.submissionBalancingIIChecked;

                            // apply logic from old asp page to disable row based on selections and RUA config
                            if (this.state.submissionSummaryChecked)
                                reportAllow = this.allowSubSumm;
                            else if (this.state.submissionDiscardedChecked)
                                reportAllow = this.allowSubDisc;
                            else {
                                reportAllow = this.allowSubBal;
                                if (this.state.submissionBalancingIIChecked)
                                    reportAllow = this.allowSubBal2;
                            }
                        } else {
                            if (reportOf[reportIndex].ReportInfo && reportOf[reportIndex].ReportInfo.Formats && reportOf[reportIndex].ReportInfo.Formats.length) {
                                for (let formatIndex = 0; formatIndex < reportOf[reportIndex].ReportInfo.Formats.length; formatIndex++) {
                                    formatType = reportOf[reportIndex].ReportInfo.Formats[formatIndex].Type;
                                    if (!!formatType && formatType.length > 0) {
                                        let groupingCount: boolean = false;
                                        if (reportOf[reportIndex].ReportInfo.Formats[formatIndex].Groupings)
                                            groupingCount = reportOf[reportIndex].ReportInfo.Formats[formatIndex].Groupings.length > 0; // don't discern between the groupings... yet...

                                        if (formatType == 'PDF') {
                                            hidePdf = false;
                                            if (groupingCount)
                                                colourPdf = true;
                                        }
                                        if (formatType == 'CSV') {
                                            hideCsv = false;
                                            if (groupingCount)
                                                colourCsv = true;
                                        }
                                        if (formatType == 'XML') {
                                            hideXml = false;
                                            if (groupingCount)
                                                colourXml = true;
                                        }
                                        if (formatType == 'TXT') {
                                            hideTxt = false;
                                            if (groupingCount)
                                                colourTxt = true;
                                        }
                                        if (formatType != 'PDF' && formatType != 'CSV' && formatType != 'XML' && formatType != 'TXT') {
                                            formatArray.push(formatType);
                                            hideOther = false;
                                            otherFormat = formatType;
                                            if (groupingCount)
                                                colourOther = true;
                                        }
                                    }
                                }
                            }
                        }

                        /*
                            !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                            THIS is how we are hiding reports until the pages that are required are developed.
                            just remove the path for the new page.
                            as shown starting on line 277 in the legacy page.
                            also hiding DEP reports... look at near the .push below
                            !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                        */
                        //if (formatType == 'DEP' || formatType == 'FUS' || formatType == 'CTV') {
                        //    continue; // hide for now...
                        //}

                        let hiddenItem: any = { visibility: 'hidden' };
                        let groupingItem: any = { backgroundColor: '#F5B700', margin: '2px' };

                        let stylePdf: any = (hidePdf == true ? hiddenItem : (colourPdf ? groupingItem : null));
                        let styleCsv: any = (hideCsv == true ? hiddenItem : (colourCsv ? groupingItem : null));
                        let styleXml: any = (hideXml == true ? hiddenItem : (colourXml ? groupingItem : null));
                        let styleTxt: any = (hideTxt == true ? hiddenItem : (colourTxt ? groupingItem : null));
                        let styleOther: any = (hideOther == true ? hiddenItem : (colourOther ? groupingItem : null)); // for the Other stuff: lines 358 617 & 558

                        let groupingPdf: string = colourPdf ? '-Grouping' : '';
                        let groupingCsv: string = colourCsv ? '-Grouping' : '';
                        let groupingXml: string = colourXml ? '-Grouping' : '';
                        let groupingTxt: string = colourTxt ? '-Grouping' : '';
                        let groupingOther: string = colourOther ? '-Grouping' : '';

                        let auditNo: string = '-NA';
                        let reportAuditNo: string = reportOf[reportIndex]!.ReportInfo!.AuditNo;
                        if (reportAuditNo?.length > 0)
                            auditNo = '-' + reportAuditNo;

                        let startIdx: number = 0;
                        let newIdx: number = parseInt(reportIndex!.toString() + startIdx!.toString());
                        reportListPartial.PDF =
                            <CustomInputCheckbox
                                sortBy='checkbox'
                                index={newIdx}
                                styleIt={stylePdf}
                                nameId={'PDF-' + reportPath + '-' + reportId + auditNo + groupingPdf}
                                keyOf={this.state.selectedReportType + '-' + newIdx}
                                postLabel={''}
                                disableIt={reportAllow}
                            />;

                        newIdx++;
                        reportListPartial.CSV =
                            <CustomInputCheckbox
                                sortBy='checkbox'
                                index={newIdx}
                                styleIt={styleCsv}
                                nameId={'CSV-' + reportPath + '-' + reportId + auditNo + groupingCsv}
                                keyOf={this.state.selectedReportType + '-' + newIdx}
                                postLabel={''}
                                disableIt={reportAllow}
                            />;

                        newIdx++;
                        reportListPartial.XML =
                            <CustomInputCheckbox
                                sortBy='checkbox'
                                index={newIdx}
                                styleIt={styleXml}
                                nameId={'XML-' + reportPath + '-' + reportId + auditNo + groupingXml}
                                keyOf={this.state.selectedReportType + '-' + newIdx}
                                postLabel={''}
                                disableIt={reportAllow}
                            />;

                        newIdx++;
                        reportListPartial.TXT =
                            <CustomInputCheckbox
                                sortBy='checkbox'
                                index={newIdx}
                                styleIt={styleTxt}
                                nameId={'TXT-' + reportPath + '-' + reportId + auditNo + groupingTxt}
                                keyOf={this.state.selectedReportType + '-' + newIdx}
                                postLabel={''}
                                disableIt={reportAllow}
                            />;

                        newIdx++;
                        if (formatArray && formatArray?.length > 1) {
                            console.log('adding 2 checkboxes one[' + formatArray[0] + '] two[' + formatArray[1] + ']');

                            reportListPartial.Other =
                                <CustomInputCheckbox
                                    sortBy='checkbox'
                                    index={newIdx}
                                    styleIt={styleOther}
                                    nameId={formatArray[0] + '-' + reportPath + '-' + reportId + auditNo + groupingOther}
                                    keyOf={this.state.selectedReportType + '-' + newIdx}
                                    postLabel={formatArray[0]}
                                    disableIt={reportAllow}
                                />;

                            reportListPartial.Other2 =
                                <CustomInputCheckbox
                                    sortBy='checkbox'
                                    index={newIdx}
                                    styleIt={styleOther}
                                    nameId={formatArray[1] + '-' + reportPath + '-' + reportId + auditNo + groupingOther}
                                    keyOf={this.state.selectedReportType + '-' + newIdx}
                                    postLabel={formatArray[1]}
                                    disableIt={reportAllow}
                                />;

                        } else {
                            reportListPartial.Other =
                                <CustomInputCheckbox
                                    sortBy='checkbox'
                                    index={newIdx}
                                    styleIt={styleOther}
                                    nameId={otherFormat + '-' + reportPath + '-' + reportId + auditNo + groupingOther}
                                    keyOf={this.state.selectedReportType + '-' + newIdx}
                                    postLabel={(otherFormat && otherFormat.length > 0 ? otherFormat : '')}
                                    disableIt={reportAllow}
                                />;

                            reportListPartial.Other2 =
                                <CustomInputCheckbox
                                    sortBy='checkbox'
                                    index={newIdx}
                                    styleIt={hiddenItem}
                                    nameId={'Other-' + reportPath + '-' + reportId + auditNo + groupingOther}
                                    keyOf={this.state.selectedReportType + '-' + newIdx}
                                    postLabel={formatArray[1]}
                                    disableIt={reportAllow}
                                />;

                        }

                        //console.log('reportName[' + reportName + '] reportPath[' + reportPath + '] reportId[' + reportId + '] reportAllow[' + reportAllow + ']');

                        /*
                            !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                            THIS is how we are hiding reports until the pages that are required are developed.
                            just hide if DEP which can be mulitple jobs.
                            !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                         */
                        //if (otherFormat == 'DEP') {
                        //if (formatArray && formatArray[0] == 'FUS') {
                        //    debugger;
                        //    newIdx++;;
                        //}
                        let allHidden: boolean = (!isSubmissionReport && hidePdf && hideCsv && hideXml && hideTxt && hideOther);
                        if (!allHidden) { // if we are hiding or they are all just hidden
                            if (otherFormat != 'DEP' || (formatArray && formatArray[0] != 'FUS'))// also: (it's all one line) formatArray[1] == 'CTV'))) // HIDING, REMOVE THIS WHEN JOBS ARE DONE!
                                reportListFinal.push(reportListPartial);
                        }
                    } else {
                        //console.log('HIDING reportName[' + reportName + '] reportPath[' + reportPath + '] reportId[' + reportId + '] reportAllow[' + reportAllow + ']');
                    }
                }
            }
        }

        return reportListFinal;
    }


    public getReportObjectBasedOnState(): any {
        let reportOf: any;
        if (this.props.dataStore.api.data) {
            reportOf = this.props.dataStore.api.data.UnreleasedReportList;
            switch (this.state.selectedReportType) {
                case 'Released Claim':
                    reportOf = this.props.dataStore.api.data.ReleasedReportList;
                    break;
                case 'Remit':
                    reportOf = this.props.dataStore.api.data.RemitReportList;
                    break;
                case 'Submission':
                    reportOf = this.props.dataStore.api.data.SubmissionReportList;
                    break;
                case 'System':
                    reportOf = this.props.dataStore.api.data.SystemReportList;
                    break;
                case 'Miscellaneous':
                    reportOf = this.props.dataStore.api.data.MiscellaneousReportList;
                    break;
                case 'Utility':
                    reportOf = this.props.dataStore.api.data.UtilityReportList;
                    break;
                case 'Lookup':
                    reportOf = this.props.dataStore.api.data.LookupReportList;
                    break;
                case 'Custom':
                    reportOf = this.props.dataStore.api.data.CustomReportList;
                    break;
                case 'ClaimVision':
                    reportOf = this.props.dataStore.api.data.ClaimVisionReportList;
                    break;
                case 'Management':
                    reportOf = this.props.dataStore.api.data.ManagementReportList;
                    break;
                case 'Direct Entry':
                    reportOf = this.props.dataStore.api.data.DirectReportList;
                    break;
                case 'Outsource':
                    reportOf = this.props.dataStore.api.data.OutsourceReportList;
                    break;
            }
        }
        return reportOf;
    }

    /*
        !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        THIS is how we are hiding reports until the pages that are required are developed.
        just remove the path for the new page.
        as shown starting on line 277 in the legacy page.
        also hiding DEP reports... look at near the .push below
        !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    */
    public isReportListAllowedBasedOnState(): boolean {
        let isReportAllowed: boolean = true;
        //debugger;
        if (this.props.dataStore.api.data) {
            switch (this.state.selectedReportType) {
                case 'Released Claim':
                case 'Unreleased Claim':
                    isReportAllowed = this.props!.accessList[eCreateReportSecurityBits.RPT_CREATE_CLAIM_REPORTS];
                    break;
                case 'Remit':
                    isReportAllowed = this.props!.accessList[eCreateReportSecurityBits.RPT_CREATE_REMIT_REPORTS];
                    break;
                case 'Submission':
                    isReportAllowed = this.props!.accessList[eCreateReportSecurityBits.RPT_CREATE_SUBMISSION_REPORTS];
                    break;
                case 'System':
                    isReportAllowed = this.props!.accessList[eCreateReportSecurityBits.RPT_CREATE_SYSTEM_REPORTS];
                    break;
                case 'Miscellaneous':
                    isReportAllowed = this.props!.accessList[eCreateReportSecurityBits.RPT_CREATE_MISCELLANEOUS_REPORTS];
                    break;
                case 'Custom':
                    isReportAllowed = this.props!.accessList[eCreateReportSecurityBits.RPT_CREATE_CUSTOM_REPORTS];
                    break;
                case 'ClaimVision':
                    isReportAllowed = false;
                    break;
                case 'Management':
                    isReportAllowed = this.props!.accessList[eCreateReportSecurityBits.RPT_CREATE_MANAGEMENT_REPORTS];
                    break;
                case 'Outsource':
                    isReportAllowed = this.props!.accessList[eCreateReportSecurityBits.EBO_RPT_CREATE_OUTSOURCE_REPORTS];
                    break;
                case 'Direct Entry':
                    break;
                case 'Utility':
                    isReportAllowed = false;
                    break;
                case 'Lookup':
                    isReportAllowed = false;
                    break;
            }
        }
        return isReportAllowed;
    }

    getReportCreateGridColumnHeaders() {
        return new Set([
            {
                dataName: 'Name',
                text: 'Name',
                sortable: false,
                cellType: 'text',
                isSorted: 1,
            },
            {
                dataName: 'PDF',
                text: 'PDF',
                sortable: false,
                cellType: 'custom',
                isSorted: 0,
            },
            {
                dataName: 'CSV',
                text: 'CSV',
                sortable: false,
                cellType: 'custom',
                isSorted: 0,
            },
            {
                dataName: 'XML',
                text: 'XML',
                sortable: false,
                cellType: 'custom',
                isSorted: 0,
            },
            {
                dataName: 'TXT',
                text: 'TXT',
                sortable: false,
                cellType: 'custom',
                isSorted: 0,
            },
            {
                dataName: 'Other',
                text: 'Other',
                sortable: false,
                cellType: 'custom',
                isSorted: 0,
            },
            {
                dataName: 'Other2',
                text: '',
                sortable: false,
                cellType: 'custom',
                isSorted: 0,
            }]);
    }

    public goToHomePage() {
        if (!!this.props.testMode) {
            // 		        todo test this
            this.props.history.push('/TestMode/UserHome.aspx');
        } else
            this.props.history.push('/LandingPage');
    }

    public getReportRequestProcess(): string {
        let processIs: string = '';
        //debugger;
        switch (this.reportPathToPrint) {
            case '201':
                processIs = 'Unreleased';
                break;
            case '202':
                processIs = 'Released';
                break;
            case '203':
                processIs = 'Deleted';
                break;
            case '209':
                processIs = 'deleted_dep';
                break;
            case '204':
                if (this.state.selectedReportType == 'Outsource')
                    processIs = 'EOBOutsource';
                else
                    processIs = 'EOB';
                break;
            case '205':
                if (this.state.selectedReportType == 'Outsource')
                    processIs = 'DEPOutsource';
                else if (this.reportTypeOutSource2227Txt == true) // won't hit in the current config, i ca't find TXT 
                    processIs = 'Custom';
                else
                    processIs = 'DEP';
                break;
            case '206':
                if (this.state.selectedReportType == 'Outsource')
                    processIs = 'ReprintEOBOutsource';
                else
                    processIs = 'DES';
                break;
            case '207':
                processIs = 'Compliance';
                break;
            case '208':
                processIs = '277';
                break;
            case '210':
                processIs = '271';
                break;
            case '211':
                processIs = 'portal';
                break;
            case '264':
                processIs = 'EOBDetail';
                break;
            case '265':
                processIs = 'EOBItemized';
                break;
            case '266':
                processIs = 'EOBMNRA';
                break;
            case '267':
                processIs = 'ReprintHCDClaimEOB';
                break;
            case '268':
                processIs = 'PrintCALTC'; // california CIF (not so new anymore)...
                break;
            case '301':
                processIs = 'Denial';
                break;
            case '601':
                processIs = 'RRTAdHoc';
                break;
            case '269':
                processIs = 'MRAN';
                break;
            default:
                processIs = this.currentCategory;
        }

        return processIs;
    }

    public onOKCreateReport(e: React.ChangeEvent<HTMLButtonElement>) {
        // unreleased e.g.
        //var parameterValue =
        //{
        //    'ReportRequests': {
        //        '@Type': '21',
        //        'ReportRequest': [
        //            { '@ID': '1201', '@Name': 'Claim Status', '@Format': 'PDF', '@Type': '21' },
        //            { '@ID': '1201', '@Name': 'Claim Status', '@Format': 'XML', '@Type': '21', '@Grouping': 'FedTaxID' }
        //
        //            // OR,for submission:
        //            { '@ID': '1100', '@Name': 'Submission', '@Format': 'PDF', '@ClientID': 'xx', '@BridgeName': 'xx', '@TimeStamp': 'xx' }
        //        ]
        //    }
        //};
        // submission e.g.
        //var parameterValue =
        //{
        //    'ReportRequests': {
        //        '@Type': '21',
        //        'ReportRequest': [
        //            { '@ID': '1201', '@Name': 'Claim Status', '@Format': 'PDF', '@Type': '21' },
        //            { '@ID': '1201', '@Name': 'Claim Status', '@Format': 'XML', '@Type': '21', '@Grouping': 'FedTaxID' }
        //
        //            // OR,for submission:
        //            { '@ID': '1100', '@Name': 'Submission', '@Format': 'PDF', '@ClientID': 'xx', '@BridgeName': 'xx', '@TimeStamp': 'xx' }
        //        ]
        //    }
        //};

        // remove non-init'd grouping flags...
        let processContinue: boolean = true;
        let groupingSelected: boolean = this.reportGrouping.length > 0;
        if (!groupingSelected) { // remove the groupings IF they exist...
            while (processContinue) {
                let groupingToRemove: any = this.finalReportRequest!.ReportRequest!.find(req => req['@Grouping'] != null && req['@Grouping']!.toString().length > 0); // just remove any grouping, don't look for particulars...  && req['@Grouping']?.toString() !== 'Grouping');
                if (groupingToRemove) {
                    groupingToRemove['@Grouping'] = '';
                    console.log('removing grouping: reportId[' + groupingToRemove['@ID'] + ']');
                } else {
                    processContinue = false;
                }
            }
        }

        let needAnyMsg: string = 'Please check a report to create.';
        let needCsvMsg: string = 'Please check CSV for Balancing reports.';
        let needPdfTxtMsg: string = 'Please check PDF or TXT for Submission or Discarded reports.';
        let continueToPrint: boolean = true;
        let isSubmissionType: boolean = this.state.selectedReportType == 'Submission' ? true : false;

        if (isSubmissionType) {
            if (this.submissionPdfCount == 0 && this.submissionTxtCount == 0 && this.submissionCsvCount == 0) {
                this.onDisplayReportCreateError(needAnyMsg);
                continueToPrint = false;
            }
            if (continueToPrint && this.state.submissionSummaryChecked || this.state.submissionDiscardedChecked) {
                if (this.submissionPdfCount == 0 && this.submissionTxtCount == 0) {
                    this.onDisplayReportCreateError(needPdfTxtMsg);
                    continueToPrint = false;
                }
            }
            if (continueToPrint && this.state.submissionBalancingChecked || this.state.submissionBalancingIIChecked) {
                if (this.submissionCsvCount == 0) {
                    this.onDisplayReportCreateError(needCsvMsg);
                    continueToPrint = false;
                }
            }
        } else {
            let selectedCount: any = this.finalReportRequest!.ReportRequest!.length;
            if (selectedCount == 0) {
                if (this.submissionCsvCount == 0) {
                    this.onDisplayReportCreateError(needAnyMsg);
                    continueToPrint = false;
                }
            }
        }

        if (continueToPrint) {
            this.setBusy(true);
            let reportProcess: string = this.getReportRequestProcess();
            let parms: ReportCreateFilterSingleClaimPrint = this.setReportExecParms(reportProcess);

            let ReportRequests: any = this.finalReportRequest;
            var reportCreateParameterValue =
            {
                ReportRequests
            };
            let getPathToPrint: string = this.reportPathToPrint.toString().substr(0, 1);
            //let findOne: any = this.finalReportRequest?.ReportRequest?.findIndex(req => (req['@ID'] === '1861' || req['@ID'] === '1863'));
            //if (findOne !== -1) // special cases, of course...
            //    getPathToPrint = '4';

            switch (getPathToPrint) {
                case '1':
                    this.executeApiReportCreateProcess(reportProcess, ReportRequests);
                    break;
                case '2':
                    //debugger; //<<< REMOVE WHEN DONE DEBUGGING!!!
                    this.props.history.push('/Claims/Filter/CreateReport', {
                        title: parms.ClaimTitle,
                        process: parms.ExecProcess,
                        execAction: parms.ExecAction,
                        execNode: parms.ExecNode,
                        extraInstruction: parms.ExtraInstruction,
                        parameter: reportCreateParameterValue,
                        claimStateRestrictions: parms.claimStateRestrictions
                    });
                    break;
                case '3':
                    this.props.history.push('/Remits/Filter/CreateReport', {
                        title: parms.ClaimTitle,
                        process: parms.ExecProcess,
                        execAction: parms.ExecAction,
                        execNode: parms.ExecNode,
                        parameter: reportCreateParameterValue
                    });
                    //this.onDisplayReportCreateError('Report Type 3 (Remit Filter) Not Ready!');
                    break;

                case '4':
                    this.props.history.push('/CreateReports/Filter', {
                        title: parms.ClaimTitle,
                        process: parms.ExecProcess,
                        execAction: parms.ExecAction,
                        execNode: parms.ExecNode,
                        extraInstruction: parms.ExtraInstruction,
                        parameter: reportCreateParameterValue
                    });
                    //this.onDisplayReportCreateError('Report Type 4 (Date Range Filter) Not Ready!');

                    break;
                case '5':
                    this.props.history.push('/Reports/FilterRegenerate', {
                        title: parms.ClaimTitle,
                        process: parms.ExecProcess,
                        execAction: parms.ExecAction,
                        execNode: parms.ExecNode,
                        extraInstruction: parms.ExtraInstruction,
                        parameter: reportCreateParameterValue,
                        reportId: this.reportPathToPrint.toString()
                    });
                    //this.onDisplayReportCreateError('Report Type 5 (Regen Filter) Not Ready!');

                    break;
                case '6':
                    this.props.history.push('/Administration/AdminPortal/rrtadhoc');
                    // this.onDisplayReportCreateError('Report Type 6 (Admin Portal Filter) Not Ready!');

                    break;
                case '7':
                    //this.onDisplayReportCreateError('Report Type 7 (RSRT Custom Filter) Not Ready!');
                    console.log(parms.ExecProcess)
                    //if (this.state.useSingleReport)
                    this.props.history.push('/Claims/RSRTFilter/Custom/ViewEdit/RRT', {
                        title: parms.ClaimTitle,
                        process: parms.ExecProcess,
                        execAction: parms.ExecAction,
                        execNode: parms.ExecNode,
                        extraInstruction: parms.ExtraInstruction,
                        parameter: reportCreateParameterValue
                    });
                    break;
            }
        }
    }

    public onCancelCreateReport(e: React.ChangeEvent<HTMLButtonElement>) {
        //    const userModified: any = sessionStorage.getItem('userModified')
        //    if (userModified === 'true') {
        //        this.setState({ cancelLeave: true })
        //    }
        //    else
        this.props.history.push('/LandingPage');
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // render functions...
    public render() {
        let instruction: any = <React.Fragment>Grouping is only available for reports with gold checkboxes.</React.Fragment>;
        let buttons: any = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOKCreateReport(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancelCreateReport(e)} />;
        let groupingTypes: any = this.buildGroupingTypeComboBoxList();
        let reportList: any = this.buildReportCreateList();
        let reportTypes: any = this.buildReportTypeComboBoxList();

        let disabledCheckboxes: boolean = !this.state.submissionVisible;

        return (
            <DialogWrapper title='Create Reports' instruction={instruction} helpUrl='/Support/Help/HELP_CreatingReports.htm' buttons={buttons} isBusy={this.props.dataStore.ui.isBusy} >
                <ContentReportCreateWrapper id='content_wrapper_ReportCreate'>
                    <ContentRowWrapper id='header-row-wrapper'>

                        <div id='report-type-dialogfieldset'>
                            <DialogFieldset>
                                <DialogLegend>Type</DialogLegend>
                                <SelectGroupComponent
                                    size={1}
                                    width='180px'
                                    records={reportTypes}
                                    optionGroups={{
                                        label: 'value',
                                        items: [{
                                            value: 'value',
                                            text: 'text',
                                        }]
                                    }}
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectReportType(e)}
                                //selectedValue={this.state.selectedReportType}
                                >
                                </SelectGroupComponent>
                            </DialogFieldset>
                        </div>

                        <div id='report-submission-dialogfieldset'>
                            {this.state.submissionVisible ?
                                <DialogFieldset isVisible='hidden' style={{ paddingBottom: "12px" }}>
                                    <DialogLegend>Sub-Type</DialogLegend>
                                    <CheckboxRow>
                                        <CheckboxWrapper>
                                            <CheckBoxComponent
                                                label='Summary'
                                                width='115px'
                                                disabled={disabledCheckboxes || (this.allowSubSumm == '0')}
                                                checked={this.state.submissionSummaryChecked}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onClickSubmissionType(e, e.target.checked, 'Summary')}
                                            />
                                        </CheckboxWrapper>
                                        <CheckboxWrapper>
                                            <CheckBoxComponent
                                                label='Discarded'
                                                width='115px'
                                                disabled={disabledCheckboxes || (this.allowSubDisc == '0')}
                                                checked={this.state.submissionDiscardedChecked}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onClickSubmissionType(e, e.target.checked, 'Discarded')}
                                            />
                                        </CheckboxWrapper>
                                        <CheckboxWrapper>
                                            <CheckBoxComponent
                                                label='Balancing'
                                                width='115px'
                                                disabled={disabledCheckboxes || (this.allowSubBal == '0')}
                                                checked={this.state.submissionBalancingChecked}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onClickSubmissionType(e, e.target.checked, 'Balancing')}
                                            />
                                        </CheckboxWrapper>
                                        <CheckboxWrapper>
                                            <CheckBoxComponent
                                                label='Balancing II'
                                                width='115px'
                                                disabled={disabledCheckboxes || (this.allowSubBal2 == '0')}
                                                checked={this.state.submissionBalancingIIChecked}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onClickSubmissionType(e, e.target.checked, 'BalancingII')}
                                            />
                                        </CheckboxWrapper>

                                    </CheckboxRow>
                                </DialogFieldset>
                                : null
                            }
                        </div>
                        <div id='report-grouping-dialogfieldset'>
                            <DialogFieldset style={{ paddingBottom: "7px" }}>
                                <DialogLegend>Grouping</DialogLegend>
                                <SelectComponent
                                    title='reportGrouping'
                                    size={1}
                                    width='180px'
                                    records={groupingTypes}
                                    optionFields={{
                                        value: 'value',
                                        text: 'label'
                                    }}
                                    disabled={this.state.groupingsDisabled}
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectGrouping(e)}
                                //selectedValue={this.selectedGroupingType}
                                >
                                </SelectComponent>
                            </DialogFieldset>
                        </div>
                    </ContentRowWrapper>

                    <ContentReportListWrapper>
                        <Grid
                            key={this.state.selectedReportType}
                            dom-ID={'report-list'}
                            onClickThrough={(e: React.SyntheticEvent, record: any) => this.onSelectReportToCreate(e, (e.target as HTMLInputElement).checked, record)}
                            isFixedHeader={true}
                            maxHeight='550px'
                            //width='300px'
                            className='report-grid'
                            columns={this.getReportCreateGridColumnHeaders() as Set<ColumnType>}
                            records={reportList}
                            selectionKey='empty'
                        />
                    </ContentReportListWrapper>

                </ContentReportCreateWrapper>
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.reportCreateErrorHandler && this.props.dataStore.ui.reportCreateErrorHandler.length > 0}
                    onModalToggle={() => this.onClearReportCreateErrorHandler()}
                    alertMode={true}
                    message={this.props.dataStore.ui.reportCreateErrorHandler}
                    onConfirm={() => this.onClearReportCreateErrorHandler()}
                />
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IReportCreateState, IReportCreateActionProps, IReportCreateOwnProps, IReportCreateProps, ApplicationState>(
    createApiMapStateToProps('reportCreate'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(ReportCreate);

export default withRouter(connectedHoc);