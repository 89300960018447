import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { switchClient, ISwitchClientModel } from '@scripts/session/Security';
import { ModalConfirmation, leaveMessage } from '@common/ModalConfirmation';
import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';

// uicl
import { Grid, Button } from '@optum-uicl/ui-core/dist';

import styled from 'styled-components';

// commonResources
import { URLs } from '@commonDevResources/constants';
import { pageLeave } from '@commonResources/userModified';

// common
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import AssuranceMenu from '../../common/AssuranceMenu';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';

// store includes
import { ApplicationState } from '@store/index';
import { ISelectClientState, ISelectClientActionProps, actionCreators } from '@store/SelectClient';
import { ColumnType } from '@optum-uicl/ui-core/dist/Organisms/Grid/types';


export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
`;

export const SelectList = styled.div`
    padding: 5px;
`;

const GridWrapper = styled.div`
    .empty-row{
        background-color:white;
    }

    thead tr th{
        background-color:#9BA1A9;
        height:20px;
    }

    table tbody tr > td{
        height:20px;
    }

    table tbody tr td div{
        min-height:1px;
    }
`;

const InputContainer = styled.div`
    padding:7px;
`;

const SwitchClientWrapper = styled.div`
    padding:7px;
`;

const PolicyWrapper = styled.div`    
    padding-top: 15px;
    display: block;
    text-align: center;
`;

/////////////////////////////////////////////////////////////////////////////////////////////

export interface ISelectClientComponentProps extends RouteComponentProps {
    apiType: string;
    privacyPolicyUrl: string;
    termsOfUseUrl: string;
}

interface IComponentState {
    cancelLeave: boolean,
    url: string,
    showAlert: boolean,
}

export const DEFAULT_STATE: IComponentState = {
    cancelLeave: false,
    url: '',
    showAlert: false,
};

type IOwnProps = ISelectClientComponentProps;
type ISelectClientProps = IMergeApiComponentProps<ISelectClientState, ISelectClientActionProps, IOwnProps>;

/////////////////////////////////////////////////////////////////////////////////////////////

export class SelectClient extends React.Component<ISelectClientProps, IComponentState> {
    static defaultProps: Partial<ISelectClientComponentProps> = {
        apiType: "SelectClient",
        privacyPolicyUrl: "/privacy-notice.html",
        termsOfUseUrl: "/terms-of-use.html",
    };

    constructor(props: ISelectClientProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.viewPrivacyPolicy = this.viewPrivacyPolicy.bind(this);
        this.getEndPointURL();
    }

    public componentDidMount() {
        pageLeave();
        this.setBusy(true);
        this.props.action.api.loadData(URLs.api + '/api/data/securityInfo/securityInfoUserClientList', this.validationCallback.bind(this), this.errorCallback.bind(this));
    }

    public componentWillUnmount() {
        pageLeave();
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        var clientId = (this.props.dataStore.ui.selectedClientId == undefined || this.props.dataStore.ui.selectedClientId.length == 0 ? "" : this.props.dataStore.ui.selectedClientId);
        var name = (this.props.dataStore.ui.selectedClientName == undefined || this.props.dataStore.ui.selectedClientName.length == 0 ? "" : this.props.dataStore.ui.selectedClientName);
        if (clientId == "" || name == "")
            this.setState({ showAlert: true });
        else {
            this.switchClientModel = {
                ClientAlias: clientId,
                ClientName: name
            };
            this.switchClient(this.switchClientModel);
        }
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push('/LandingPage');
    }

    highlightSelectedRow(target: any) {
        if (target != null) {
            // clear selected row highlights from ui
            var scGrid = document.getElementById("switch-client-grid");
            if (scGrid != null) {
                var selectedRows = scGrid.querySelectorAll('.selected');
                for (var i = 0; i < selectedRows.length; i++) {
                    selectedRows[i].classList.remove('selected');
                }
            }
            // set selected class to grid row
            target.className = 'selected';
        }
    }

    getEndPointURL = async () => {
        var url = URLs.api + '/api/data/securityInfo/getPortalEndpointURL';
        let endpointURL = ''
        await fetch(url)
            .then((response) => response.json())
            .then((jsonData) => {
                this.setState({ url: jsonData })
            });
    };

    viewPrivacyPolicy(e: React.ChangeEvent<HTMLButtonElement>) {
        e.preventDefault();
        let siteUrl = this.state.url + this.props.privacyPolicyUrl;
        let newTab = window.open();
        newTab && (newTab.location.href = siteUrl);
    }

    viewTermsOfUse(e: React.ChangeEvent<HTMLButtonElement>) {
        e.preventDefault();
        let siteUrl = this.state.url + this.props.termsOfUseUrl;
        window.location.href = siteUrl;
    }

    public selectClient(e: any, record: any) {
        e.persist();
        if (record) {
            this.highlightSelectedRow(e.currentTarget);                
            this.props.action.ui.selectClient(
                {
                    apiData: this.props.dataStore.api.data,
                    uiData: {
                        clientId: record.id,
                        clientName: record.name
                    }
                });
            if (e.detail >= 2) {
                this.switchClientModel = {
                    ClientAlias: record.id,
                    ClientName: record.name
                };
                this.switchClient(this.switchClientModel);
            }
        }
    }

    switchClientModel = {
        ClientAlias: '',
        ClientName: '',
    };

    async switchClient(switchModel: ISwitchClientModel) {
        document.body.style.cursor = "wait";
        const loginResult = await switchClient(switchModel);
        switch (loginResult.status) {

            case 200:
                this.props.history.push({ pathname: '/empty' });
                this.props.history.replace("/LandingPage");
                if (loginResult && loginResult.body) {
                    var responseData = loginResult.body.replace(/\"/g, '');
                    sessionStorage.setItem('Client', responseData);
                }
                break;
            case 306:
                // Password expired or reset
                this.props.history.push({
                    pathname: "/Home/ChangePassword",
                    state: { warningMessage: loginResult.body }
                });
                break;
            default:
                this.props.history.push("/Transactions/Logon");
        }
        document.body.style.cursor = "default";
    }

    public validationCallback(data: any): boolean {
        this.setBusy(false);
        return true;
    }

    public errorCallback(dataStatus: string): boolean {
        this.setBusy(false);
        var str = 'An error occurred retrieving SSO client list. Error: ' + dataStatus;
        console.error(str);
        return true;
    }

    public setBusy(val: boolean) {
        this.props.action.ui.setBusy({ api: this.props.dataStore.api.data, uiData: { value: val } });
    }

    getGridColumnHeaders() {
        return new Set([
            {
                dataName: "id",
                text: 'Client Id',
                sortable: true,
                cellType: "text",
                isSorted: 1,
            },
            {
                dataName: "name",
                text: "Client Name",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
        ]);
    }

    public render() {
        var buttons = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        var titleText = "Select Client";
        var apiData = [];
        if (this.props.dataStore && this.props.dataStore.api && this.props.dataStore.api.data)
            apiData = this.props.dataStore.api.data;
        var clientInfo: any = [];
        if (apiData && apiData.userClients) {
            clientInfo = apiData.userClients ? apiData.userClients : clientInfo;
        }
        return (
            <SwitchClientWrapper>
                <DialogWrapper title={titleText} buttons={buttons} isBusy={this.props.dataStore?.ui?.isBusy || this.props.dataStore?.api?.dataStatus === 'REQUEST'}>
                    {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                    <ContentWrapper>
                        <ContentWrapper>
                            <SelectList>
                                <DialogFieldset height='100%'>
                                    <GridWrapper>
                                        <Grid
                                            columns={this.getGridColumnHeaders() as Set<ColumnType>}
                                            domID='switch-client-grid'
                                            isFixedHeader={true}
                                            maxHeight='400px'
                                            records={clientInfo}
                                            onClickThrough={(e: any, record: any) => { this.selectClient(e, record); } }
                                            emptyGridMessage='No Clients Available.' 
                                            selectionKey={''}
                                        />
                                    </GridWrapper>
                                </DialogFieldset>
                            </SelectList>
                        </ContentWrapper>
                    </ContentWrapper>
                    <ModalConfirmation
                        isOpen={this.state.showAlert == true}
                        alertMode={true}
                        message="Please select a client."
                        onModalToggle={() => this.setState({ showAlert: false })}
                    />
                </DialogWrapper>
                <PolicyWrapper>
                    <Button
                        domID="privacy-policy-button"
                        type="button"
                        size="medium"
                        buttonType="diminished"
                        className="link-button align-top"
                        onClick={() => this.viewPrivacyPolicy}
                    >
                        <span>Privacy Policy</span>
                    </Button>
                    <Button
                        domID="terms-of-use-button"
                        type="button"
                        size="medium"
                        buttonType="diminished"
                        className="link-button align-top"
                        onClick={() => this.viewTermsOfUse}
                    >
                        <span>Terms of Use</span>
                    </Button>
                </PolicyWrapper>
                </SwitchClientWrapper>

            );
    }
};

var connectedHoc = connect<ISelectClientState, ISelectClientActionProps, IOwnProps, ISelectClientProps, ApplicationState>(
    createApiMapStateToProps('selectClient'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(SelectClient);


export default withRouter(connectedHoc);