import * as React from 'react';
import styled from 'styled-components';
import { PreBillEditFilterGridData } from '../../../store/ui/PreBillFilterUI';
import { Button } from 'react-bootstrap';
import { SelectDropdown } from '@optum-uicl/ui-core/dist';
import { Expand, Collapse } from '@optum-uicl/ui-core/dist';

export const TableRowWrapper = styled.tr`
    display: table-row;
    border-width: 1px;
    border-top-style:solid;
    width:600px;
    height:35px !important;
    #category{
        width:250px;
        line-height:initial;
    }
     #fieldName{
            width:180px;
           
        }
     #editName{
            width:180px;
        }
     #phase{
            width:220px;
            padding-top:4px;
        }
  
    .btn
    {
        font-size:12px;
        font-weight:normal;
        text-decoration: underline;
    }
    .btn:Hover
    {
        cursor:pointer;
    }
    #fieldName
    {
        line-height:initial !important;
    }
`;

export const TableRowCellWrapper = styled.td`
    font-size: 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    height: 25px;
    overflow: hidden;
    padding-right: 5px;
    color:#37474F;
    select,#fieldName
    {
        line-height:initial !important;
    }
  
`;



interface IComponentState {
    phaseLinkClicked: boolean;
    rowsel: string;
    expandClicked: boolean;
}
interface Rowstate {
    category: string;
    fieldName: string;
    editName: string;
    phase: string;
    isExpandable: boolean;
}
interface PhaseListItem {
    value: string;
    label: string;
}
interface IPrebillEditFilterGridRowProps {
    row: PreBillEditFilterGridData;
    loadPhaseList: any[];
    isExpandable: boolean;
    expandPrebillFilterGrid: (category: string, dvaraName: string, index: number) => void;
    collaspePrebillFilterGrid: (category: string, dvaraName: string, index: number) => void;
    enablePhaseList: (enable: boolean, index: number) => void;
    ManagePrebillPhaseMapping: (row: PreBillEditFilterGridData, id: string,index:number) => void
    index: number;
}

export const DEFAULT_STATE: IComponentState = {
    phaseLinkClicked: false,
    rowsel: '0',
    expandClicked: false,
    
};

export class PrebillEditFilterGridRow extends React.Component<IPrebillEditFilterGridRowProps, IComponentState> {

    constructor(props: IPrebillEditFilterGridRowProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.buildPhaseOptions.bind(this);
        this.updateFilterCategory.bind(this);
    }

    componentDidMount() {

    }
    render() {
        
        const { row, index } = this.props;
        return (
            <TableRowWrapper key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#F2F3F4' }} id={row.categoryId + '-' + (row!.dvarId || '') + '-' + (row!.editName || '')}>
                <TableRowCellWrapper id='category'>{row.category}</TableRowCellWrapper>
                <TableRowCellWrapper id='fieldName' >{row.dvarName}</TableRowCellWrapper>
                <TableRowCellWrapper id='editName' >{row.editName}</TableRowCellWrapper>
                <TableRowCellWrapper id='phase' >
                    {!row.phaseLinkClicked ? <Button className='btn' style={{ backgroundColor: index % 2 === 0 ? 'white' : '#F2F3F4' }} onClick={(_e: any) => this.selectPhase(row.phase)}>{this.createPhaseLink(row.phase, row.inheritedFrom)}</Button> :
                        <SelectDropdown
                            domID="select-phase-id"
                            className="dropdown"
                            isClearable={false}
                            menuWidth={220}
                            onChange={(e: any) => {  this.updateFilterCategory(e.value) }}
                            options={this.buildPhaseOptions(row.phase, row.inheritedFrom)}
                            // initialValue={{ label: this.state.rowsel, value: this.state.rowsel }}
                        />}
                </TableRowCellWrapper>
                <TableRowCellWrapper id='expand' >
                    {row.editName === '' ?
                     
                            <Button className='btn' style={{ backgroundColor: index % 2 === 0 ? 'white' : '#F2F3F4' }} onClick={(_e: any) =>
                            this.expandOrCollsape(row.categoryId, row.dvarName, row.category, !(this.props.isExpandable || row.expandClicked), index)}>
                            {this.props.isExpandable || row.expandClicked ?
                                <Collapse
                                className="test"
                                fillColor="#37474F"
                                title="title"
                            /> :
                                <Expand
                                    className="test"
                                    fillColor="#37474F"
                                    title="title"
                                />} </Button>:''
                    }
                </TableRowCellWrapper>
            </TableRowWrapper>
        );

    }
    updateFilterCategory(value: any) {
        this.props.ManagePrebillPhaseMapping(this.props.row, value, this.props.index);
    }


    expandOrCollsape(categoryId: string, dvarName: string, category: string, expand: boolean, index: number): void {
        
        if (expand)
            this.props.expandPrebillFilterGrid(categoryId, dvarName, index);
        else
            this.props.collaspePrebillFilterGrid(category, dvarName, index);

    }

    addRemovePhaseOption = (phase: string): boolean => {
        
        if (phase == "" || (phase.indexOf("Inherit") != -1) || (phase.indexOf("Not Mapped") != -1) || (phase.indexOf("Not Assigned") != -1))
            return false;
        return true;
    }
    createPhaseLink = (phase: string, inheritedFrom: number): string => {
        //alert("phase[" + phase + "] inheritedFrom[" + inheritedFrom + "]");
        if (phase == null || phase.length == 0)
            phase = 'Not Assigned';

        if ((phase == null || phase == 'Not Assigned') && inheritedFrom != null && inheritedFrom != 0) // if inherited is null, there's an issue...
            phase = 'Inherited';

        return phase;
    }
    buildPhaseOptions(phase: string, inheritedFrom: number): any[] {
        let options: any[] = [];

        let newPhase = this.createPhaseLink(phase, inheritedFrom);
        options.push({ value: '0', label: "Currently '" + newPhase+"'" })

        if (this.addRemovePhaseOption(phase))
            options.push({ value: '-1', label:"Remove From '" + phase +"'"});

        for (var i = 0; i < this.props.loadPhaseList.length; i++) {
            var item = eval(this.props.loadPhaseList[i]);
            console.log(item);
            if (item.label !== phase && item.label !== '') {
                options.push({ value: item.value, label: item.label });
            }
        }
        
        return options;
    }
    selectPhase(phase: string) {
        
        this.props.enablePhaseList(true, this.props.index);
    }
}

