import * as React from 'react';
import { Modal, LoadingIndicator } from '@optum-uicl/ui-core/dist';
import styled from 'styled-components';
import { Portal } from 'react-portal';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { MODAL_ANIMATION } from '@commonResources/timings';

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${Colors.transparentGrey100};
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  transition: opacity ${MODAL_ANIMATION}ms, visibility ${MODAL_ANIMATION}ms;

    &.active {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
    }
`;

const ModalBody = styled.div`
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;

    background-color: ${Colors.transparentGrey100};
    z-index: 900;
    opacity: 0;
    visibility: hidden;
    overflow: auto;
    transition: opacity ${MODAL_ANIMATION}ms, visibility ${MODAL_ANIMATION}ms;

    &.active {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
    }
`;

const ModalLoadingWrapper = styled.div`
    position: relative;
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: stretch;
    text-align: left;
    width: 100%;
    height: 100%;
`;

export class ModalLoadingDiv extends React.PureComponent {
    render() {
        return (
                <ModalLoadingWrapper id='modal-loading-div'>
                    <ModalLoading isOpen={true} />
                </ModalLoadingWrapper>
        );
    }
}

export interface IModalLoadingProps {
    isOpen: boolean;
    forDomId?: string;
    fullscreen?: boolean;
};

interface IComponentState {
}

export class ModalLoading extends React.Component<IModalLoadingProps, IComponentState> {

    static defaultProps: IModalLoadingProps = {
        isOpen: false,
        fullscreen: false,
    };

    constructor(props: IModalLoadingProps) {
        super(props);

        this.state = {
        };
    }

    renderModalBody() {
        return (
            <ModalBody className={this.props.isOpen ? 'active' : ''}>
                <LoadingIndicator />
            </ModalBody>
        );
    }

    render() {
        let portalAttr:any = {};
        if (this.props.forDomId) {
            portalAttr = {
                node: document && document.getElementById(this.props.forDomId)
            }
        }
        if (portalAttr.node) {
            return (
                <aside>
                    <Portal {...portalAttr}>
                        {this.renderModalBody()}
                    </Portal>
                </aside>
            );
        }
        if (this.props.fullscreen) {
            return (
                <aside>
                    <Portal>
                        <ModalWrapper className={this.props.isOpen ? 'active' : ''}>
                            {this.renderModalBody()}
                        </ModalWrapper>
                    </Portal>
                </aside>
            );
        }
        return this.renderModalBody();
    }
}
