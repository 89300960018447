import * as React from 'react';
import { intersection } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import ReportFilter, {IOwnProps, validateReportFilterDatesAreWithinRange} from "@components/Reports/Filter/ReportFilter";
import { validateDateRangeNotEmpty, validateBothDatesPopulated, validateDateRangeSmallerThanLimit, validateUserReallyWantsEmptyDateRangeFactory, validateDateRangeIsEmptyOrFull, validateBothDatesPopulatedForRange, validateDatesInLogicalOrder } from '@scripts/util/ValidationHelpers';

export default class SpecificReportFilter extends React.PureComponent<RouteComponentProps<{}>> {
    constructor(props: any) {
        super(props);
    }
    public render() {
        const { title, process, execAction, execNode, parameter } = this.props.history.location.state as any;
        const affectedReports = parameter?.ReportRequests?.ReportRequest || [];
        const reportIds = affectedReports.map((report: any) => report['@ID']);

        console.table(reportIds);

        let dateValidators = [validateDateRangeNotEmpty, validateDatesInLogicalOrder, validateReportFilterDatesAreWithinRange, validateBothDatesPopulated];
        if (reportIds.indexOf('1551') > -1) {
            dateValidators.push(validateDateRangeSmallerThanLimit(90));
        }

        const pmtDateRangeValidators = [validateDateRangeIsEmptyOrFull, validateBothDatesPopulatedForRange('Payment')];

        const defaultStartDate = (reportIds.indexOf('1721') > -1) ? moment().format('MM/DD/YYYY') : undefined;

        // this shows just a single select list of users
        const showSingleUserSelect = reportIds.indexOf('5101') > -1;

        // Add reports that allow filter to be saved/loaded/deleted to this list
        const showFilterList = !showSingleUserSelect && intersection(['1103','1551','1324', '1810', '1830', '1831', '1834', '1835', '1934', '1935'], reportIds).length > 0;

        // We don't want people using ARM "special" dates unless they could potentially be creating a stored filter
        const mainDateRangeInputsDisabled = !showFilterList;

        // Add reports that show users (whether or not they show the actions list) to this list
        let showUserList = false;
        if (intersection(['1830', '1831', '1834', '1835'], reportIds).length > 0) {
            showUserList = true;
        }
        if (showUserList || showSingleUserSelect) {
            dateValidators.push(validateDateRangeSmallerThanLimit(31,
                'You can only report against up to 31 days worth of data at a time.'));
        }
        const hideUserActionList = !showUserList || reportIds.indexOf('1831') > -1;

        // Reports that show the facilites list
        const showFacilitiesList = intersection(['1810'], reportIds).length > 0;

        // reports that show the LOB list
        const showLobList = intersection(['1810', '1934', '1935'], reportIds).length > 0;

        // remit reports
        const showRemitFields = intersection(['1934', '1935'], reportIds).length > 0;
        if (showRemitFields) {
            const messageTemplate =
                ({ product, continueAction, returnDestination }: {product: string; continueAction: string; returnDestination: string} ) =>
                    `The ${product} will be limited to payments imported today because not enough filter criteria have been specified. Click Yes to ${continueAction}. Click No to return${returnDestination}.`;
            const modalOptions = {
                alertMode: false,
                okText: 'Yes',
                cancelText: 'No',
                title: 'Confirm Empty Import Dates',
            }
            /*  Remit reports allow empty date range, but will prompt user for confirmation.
                if confirmation is given (Yes selected), both dates are corrected to today
                before report is run or the filter is saved. If the choice is not confirmed,
                user is returned to the filter to update the entry.
                This takes the place of the default validateDateRangeNotEmpty.
             */
            dateValidators[0] = validateUserReallyWantsEmptyDateRangeFactory(messageTemplate, modalOptions);
        }
        // paper claims default mapping (note no other settings matter if this flag is on)
        const showFormsUsedOnly = reportIds.indexOf('5004') > -1;

        const reportFilterProps: IOwnProps = {
            ...this.props,
            title,
            process,
            execAction,
            execNode,
            parameter,
            defaultStartDate,
            mainDateRangeInputsDisabled,
            mainDateRangeValidators: dateValidators,
            pmtDateRangeValidators,
            showFilterList,
            showUserList,
            hideUserActionList,
            showSingleUserSelect,
            showFacilitiesList,
            showFormsUsedOnly,
            showRemitFields,
            showLobList,
        };
        return (
            <ReportFilter
                {...reportFilterProps}
            />
        );
        
    }
}