import React, { useState, useEffect } from "react";
import { DialogWrapper } from "@common/DialogWrapper";
import { DialogFieldset, DialogLegend } from "@common/DialogStyles";
import styled from "styled-components";
import _ from "lodash";
import { Checkbox } from "@optum-uicl/ui-core/dist";
import { Colors } from "@commonResources/colorVariables";

import FilterableList from "@commonResources/FilterableList/FilterableList";
import useSelect from "@commonResources/FilterableList/useSelect";
import { useSelector, useDispatch } from "react-redux";

import { ApplicationState } from "../../store";
import * as FilterableListDemoStore from "@store/FilterableListDemo";
import { RouteComponentProps } from "react-router";
import { TextStyles } from "@optum-uicl/ui-core/dist";

type FilterableListDemoProps = IComponentProps &
  FilterableListDemoStore.FilterableListDemoState &
  FilterableListDemoStore.ActionCreators &
  RouteComponentProps<{}>;

const DemoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

// NOTE: It is up to you to determine what fonts and colors best match your AC.
// FilterableList does not come with any pre-assumed font styles.
// You must use a wrapper to apply your own fonts and colors.
const ListContainerDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 530px;
  padding: 10px 5px 20px 5px;

  color: ${Colors.black};

  & select > option {
    color: ${Colors.grey100};
    ${TextStyles.xSmallBold};
    text-transform:uppercase;
  }

  & label {
    color: ${Colors.grey100};
    ${TextStyles.xSmallBold};
     text-transform:uppercase;
  }
`;

const FilterableListWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const UseSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px 20px 5px;
`;

const UseSelectTitle = styled.h5`
  ${TextStyles.medium};

  color: ${Colors.chBlue100};
  margin: 5px 0;
`;

const UseSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${TextStyles.medium};
   text-transform:uppercase;
  color: ${Colors.black};
`;

const UseSelectSelected = styled.div`
  display: flex;
  flex-directions: row;
  width: 300px;
  flex-wrap: wrap;
`;

const SelectedTags = styled.span`
  color: ${Colors.white};
  background: ${Colors.brightBlue100};
  border-radius: 5px;
  margin: 3px;
  padding: 5px 15px 5px 8px;
  ${TextStyles.xSmall};
  cursor: pointer;
  position: relative;

  &:hover {
    background: ${Colors.brightBlue115};
    &:after {
      color: ${Colors.brightBlue30};
    }
  }

  &:after {
    content: "x";
    position: absolute;
    right: 4px;
    top: 6px;
    font-size: 10px;
    color: ${Colors.brightBlue150};
  }
`;

const DisplayListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;

  & ul {
    list-style-type: none;
    ${TextStyles.xSmall};
    color: ${Colors.white};
  }
  & ul > li {
    padding: 5px 10px;
    margin: 2px;
    border-radius: 20px;
    border: 1px solid ${Colors.brightBlue115};
    background: ${Colors.babyBlue};
    text-align: center;
  }
`;

let sampleRecords: any[] = [
  {
    "@GUID": 0,
    "@PAYERCODE": "AETN",
    "@Text": "Aetna",
  },
  {
    "@GUID": 1,
    "@PAYERCODE": "ANTH",
    "@Text": "Anthem Healthcare",
  },
  {
    "@GUID": 2,
    "@PAYERCODE": "BLUC",
    "@Text": "Blue Cross Blue Shield",
  },
  {
    "@GUID": 3,
    "@PAYERCODE": "BRGHT",
    "@Text": "Bright Healthcare",
  },
  {
    "@GUID": 4,
    "@PAYERCODE": "CAPL",
    "@Text": "Capital Healthcare",
  },
  {
    "@GUID": 5,
    "@PAYERCODE": "CIGN",
    "@Text": "Cigna",
  },
  {
    "@GUID": 6,
    "@PAYERCODE": "CMMN",
    "@Text": "Common Ground",
  },
  {
    "@GUID": 7,
    "@PAYERCODE": "EMBL",
    "@Text": "Emblem Healthcare",
  },
  {
    "@GUID": 8,
    "@PAYERCODE": "HAVP",
    "@Text": "Harvard Pilgrim",
  },
  {
    "@GUID": 9,
    "@PAYERCODE": "HEAP",
    "@Text": "Health Partners",
  },
  {
    "@GUID": 10,
    "@PAYERCODE": "HUMA",
    "@Text": "Humana",
  },
  {
    "@GUID": 11,
    "@PAYERCODE": "KAIS",
    "@Text": "Kaiser Permanente",
  },
  {
    "@GUID": 12,
    "@PAYERCODE": "MEDA",
    "@Text": "Medica Health",
  },
  {
    "@GUID": 13,
    "@PAYERCODE": "MOSA",
    "@Text": "Mosaic Healthcare",
  },

  {
    "@GUID": 14,
    "@PAYERCODE": "NATL",
    "@Text": "National Healthcare",
  },
  {
    "@GUID": 15,
    "@PAYERCODE": "OPT",
    "@Text": "Optum Care",
  },
  {
    "@GUID": 16,
    "@PAYERCODE": "UNI",
    "@Text": "United Healthcare",
  },
  {
    "@GUID": 17,
    "@PAYERCODE": "HELLO",
    "@Text": "Hello Partners",
  },
];

const numberOfUUIDs = 2;
let UUIDs: any[] = [];
for (let i = 0; i < numberOfUUIDs; i++) {
  UUIDs = [...UUIDs, "" + _.uniqueId()];
}

interface IComponentProps {}

const FilterableListDemo: React.FC<FilterableListDemoProps> = (props) => {
  const [selectionsSecondList, setSelectionsSecondList] = useState([]);

  // We retrieve the selected records from the store
  const selectedRecords = useSelector((state: ApplicationState) => {
    return state.filterableListDemo.selectedRecords;
  });
  // We attach the dispatch function to run actions on the store (in order to set the records)
  const dispatch = useDispatch();

  const setSelectionsFirstList = (records: any) => {
    dispatch({ type: "SET_SELECTED_RECORDS", data: records });
  };

  const [Select1] = useSelect({
    domID: "useSelect-1",
    width: 300,
    height: 150,
    records: sampleRecords,
    optionFields: {
      id: "@GUID",
      value: "@PAYERCODE",
      text: "@Text",
    },
    multiSelect: true,
    setSelections: setSelectionsFirstList,
  });

  const [Select2] = useSelect({
    domID: "useSelect-2",
    width: 300,
    height: 150,
    records: sampleRecords,
    optionFields: {
      id: "@GUID",
      value: "@PAYERCODE",
      text: "@Text",
    },
  });

  const displaySelectedRecords = (selections: any[]) => {
    return (
      <DisplayListWrapper>
        <ul>
          {selections.map((record: any) => {
            return <li key={record.id}>{record.text}</li>;
          })}
        </ul>
      </DisplayListWrapper>
    );
  };

  /**
   * Note: the component passes the selectedRecords setter function to its child (the list)
   * delatgating to it the responsibiliy for setting the local state, based on its selection
   */
  return (
    <>
      <DialogWrapper title="Filterable List Demo">
        <DemoWrapper>
          <DialogFieldset>
            <DialogLegend>Use Select</DialogLegend>
            <UseSelectWrapper>
              <UseSelectContainer>
                <UseSelectTitle>Multiple Select</UseSelectTitle>
                {/* <UseSelectSelected>
                  {selectedRecords1.map((record: any, index: number) => {
                    return (
                      <SelectedTags
                        onClick={(e: any) => {
                          Ref1.current.removeRecord(e.target);
                        }}
                        key={index}
                        id={record.id}
                      >
                        {record.value}
                      </SelectedTags>
                    );
                  })}
                </UseSelectSelected> */}
                {Select1}
              </UseSelectContainer>
              <UseSelectContainer>
                <UseSelectTitle>Single Select</UseSelectTitle>
                {/* <UseSelectSelected>
                  {selectedRecords2.map((record: any, index: number) => {
                    return (
                      <SelectedTags onClick={(e: any) => {}} key={index}>
                        {record.value}
                      </SelectedTags>
                    );
                  })}
                </UseSelectSelected> */}
                {Select2}
              </UseSelectContainer>
            </UseSelectWrapper>
          </DialogFieldset>

          <DisplayListWrapper>
            <FilterableListWrapper>
              <ListContainerDiv>
                <FilterableList
                  key={JSON.stringify(setSelectionsFirstList)} //required to force displayed data to update if records change outside of the FilterableList
                  domID={UUIDs[0]}
                  width={300}
                  height={300}
                  records={sampleRecords}
                  optionFields={{
                    id: "@GUID",
                    value: "@PAYERCODE",
                    text: "@Text",
                  }}
                  multiSelect
                  setSelections={setSelectionsFirstList}
                  value={selectedRecords}
                  placeholder="Your Text Here"
                  label="Your search label here"
                />
                {/*
                {!!selectionsFirstList.length &&
                displaySelectedRecords(selectionsFirstList)}*/}
              </ListContainerDiv>
              <ListContainerDiv>
                <FilterableList
                  key={JSON.stringify(sampleRecords)} //required to force displayed data to update if records change outside of the FilterableList
                  domID={UUIDs[1]}
                  width={300}
                  height={300}
                  records={sampleRecords}
                  setSelections={setSelectionsSecondList}
                  optionFields={{
                    id: "@GUID",
                    value: "@PAYERCODE",
                    text: "@Text",
                  }}
                  placeholder="Your Text Here"
                  label="Your search label here"
                />
                {!!selectionsSecondList.length &&
                  displaySelectedRecords(selectionsSecondList)}
              </ListContainerDiv>
            </FilterableListWrapper>
          </DisplayListWrapper>
        </DemoWrapper>
      </DialogWrapper>
    </>
  );
};

export default FilterableListDemo;
