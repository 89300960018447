import * as React from "react";
import { DialogFieldset, DialogLegend } from "../../../common/DialogStyles";
import styled from "styled-components";
import { RequiredFieldValidator } from "../Validators/RequiredFieldValidator";
import { RegExValidator } from "../Validators/RegExValidator";
import { Button } from '@optum-uicl/ui-core/dist';

//@store prevented component changes from getting reflected
import { Facility, List, LOBElement, MCFacilityMaintenanceInfo } from "../../../../store/api/FacilityMasterType";

import helpIcon from '@content/images/QMark_blue.png';
import * as wind from '@commonResources/window';
import { IsAlphaNumeric } from "../../../../commonResources/validations";
import { GenericValidator } from "../Validators/GenericValidator";


interface IComponentProps {
    key: string,
    maintenanceInfo: MCFacilityMaintenanceInfo | undefined,
    facility: Facility,
    isFacilityPayerInfoSubmit: boolean,
    fieldFocus: string,
    updateSelectedFacilityPayerInfo: Function,
    validationAggregation: Map<string, boolean>,
    validationAggregationRoutine: Function,
    cursorPos: number,
    selectedFacilityPayerInfoId: string,
    selectedFacilityPayerInfo: LOBElement,
};

interface IComponentState {
    //
}

export class PayerInfoCol1 extends React.Component<IComponentProps, IComponentState> {

    inputOnBlur(x: React.FocusEvent<HTMLInputElement>) {
        const {
            updateSelectedFacilityPayerInfo,
            selectedFacilityPayerInfoId,
        } = this.props;

        const inputId = x.currentTarget.id;
        const fieldId = '@' + inputId;

        // remove leading and trailing spaces from the input value per legacy
        let inputValue = x.currentTarget.value.trim();
        x.currentTarget.value = inputValue.toUpperCase();

        //debugger;

        // cursor pos of 0 selects all text in the input
        updateSelectedFacilityPayerInfo(selectedFacilityPayerInfoId, fieldId, x.currentTarget.value, inputId, 0)
    }

    getLobsDropdown() {
        const {
            maintenanceInfo,
            facility,
            fieldFocus,
            updateSelectedFacilityPayerInfo,
            cursorPos,
            selectedFacilityPayerInfoId,
            selectedFacilityPayerInfo,
        } = this.props;

        // prepend a default option to the start of countries
        let lobs: List[] = maintenanceInfo?.LOBList.LOB || [];

        const selectedValue = selectedFacilityPayerInfo && selectedFacilityPayerInfo['@LOBID'];

        return (
            <select
                id='LOBID'
                autoFocus={fieldFocus == 'LOBID'}
                style={{ height: 26 }}
                onChange={x => {
                    updateSelectedFacilityPayerInfo(
                        selectedFacilityPayerInfoId, '@LOBID', x.currentTarget.value, 'LOBID', cursorPos)
                }}
                value={selectedValue}
            >
                <option id='' key='ddl-lob-default' value=''>- Select LOB -</option>
                {lobs && lobs.map(x => {
                    return <option key={x["@ID"]} value={x["@ID"]}>{x["@Name"]}</option>
                })}
            </select>
        );
    }

    getPayersDropdown() {
        const {
            maintenanceInfo,
            selectedFacilityPayerInfo,
            selectedFacilityPayerInfoId,
            fieldFocus,
            updateSelectedFacilityPayerInfo,
            cursorPos,
        } = this.props;

        const selectedValue = selectedFacilityPayerInfo && selectedFacilityPayerInfo['@PayerID'];
        return (
            <select
                id='PayerID'
                autoFocus={fieldFocus == 'PayerID'}
                style={{ height: 26 }}
                onChange={x => {
                    updateSelectedFacilityPayerInfo(
                        selectedFacilityPayerInfoId, '@PayerID', x.currentTarget.value, 'PayerID', cursorPos)
                }}
                value={selectedValue}
            >
                <option id='' key='ddl-lob-default' value=''>- Select Payer -</option>
                {maintenanceInfo?.PayerList.Payer.map(x => {
                    return (
                        <option key={x["@ID"]} value={x["@ID"]}>{x["@Name"]}</option>
                    )
                })}
            </select>
        )
    }

    render() {

        const {
            maintenanceInfo,
            facility,
            isFacilityPayerInfoSubmit,
            fieldFocus,
            updateSelectedFacilityPayerInfo,
            validationAggregation,
            validationAggregationRoutine,
            cursorPos,
            selectedFacilityPayerInfoId,
            selectedFacilityPayerInfo,
        } = this.props;

        const ddlLob = this.getLobsDropdown();
        const ddlPayer = this.getPayersDropdown();

        const providerNoIsMissing =
            (!selectedFacilityPayerInfo['@ProviderNo'] ||
                selectedFacilityPayerInfo['@ProviderNo'] == '0');

        let lobMatches = []
        if (facility.LOB && Array.isArray(facility.LOB)) {
            lobMatches = facility.LOB!.filter(x =>
                x['@ID'] != selectedFacilityPayerInfo['@ID'] &&
                x['@LOBID'] == selectedFacilityPayerInfo['@LOBID'] &&
                x['@PayerID'] == selectedFacilityPayerInfo['@PayerID'] &&
                x["@ProviderNo"] == selectedFacilityPayerInfo['@ProviderNo']
            )
        }

        const lobValidationTrigger = isFacilityPayerInfoSubmit && lobMatches.length > 0;

        return (
            <table>
                <tbody>

                    <GenericValidator
                        fieldId={'PayerInfo.PayerInfoLOB'}
                        fieldName={'LOB'}
                        trigger={
                            lobValidationTrigger
                        }
                        message={'The LOB you wish to add already exists with this Provider Number, Payer, LOB'}
                        style={{ width: 300 }}
                        validationAggregation={validationAggregation}
                        validationAggregationRoutine={validationAggregationRoutine}
                    />

                    <tr>
                        <td className="fieldLabel">* LOB:</td>
                        <td style={{ width: 5 }}></td>
                        <td>{ddlLob}</td>
                    </tr>

                    <RequiredFieldValidator
                        fieldId={'PayerInfo.LOB'}
                        fieldName={'LOB'}
                        triggers={[
                            isFacilityPayerInfoSubmit &&
                            (selectedFacilityPayerInfo &&
                            selectedFacilityPayerInfo['@LOBID'] == '' || false)
                        ]}
                        style={{ position: 'relative', left: 3 }}
                        validationAggregation={validationAggregation}
                        validationAggregationRoutine={validationAggregationRoutine}
                    />

                    <tr>
                        <td className="fieldLabel">* Payer:</td>
                        <td style={{ width: 5 }}></td>
                        <td>{ddlPayer}</td>
                    </tr>

                    <RequiredFieldValidator
                        fieldId={'PayerInfo.Payer'}
                        fieldName={'Payer'}
                        triggers={[
                            isFacilityPayerInfoSubmit &&
                            (selectedFacilityPayerInfo &&
                            !selectedFacilityPayerInfo['@PayerID'])
                        ]}
                        style={{ position: 'relative', left: 3 }}
                        validationAggregation={validationAggregation}
                        validationAggregationRoutine={validationAggregationRoutine}
                    />

                    <tr>
                        <td className="fieldLabel">* Provider No:</td>
                        <td style={{ width: 5 }}></td>
                        <td>
                            <input
                                id='ProviderNo'
                                style={{ width: 350 }}
                                value={
                                    selectedFacilityPayerInfo &&
                                    selectedFacilityPayerInfo['@ProviderNo']}
                                onChange={x => {
                                    updateSelectedFacilityPayerInfo(
                                        selectedFacilityPayerInfoId,
                                        '@ProviderNo',
                                        x.currentTarget.value,
                                        'ProviderNo',
                                        cursorPos)
                                }}
                                onBlur={x => {
                                    this.inputOnBlur(x);
                                }}
                                maxLength={30}
                            />
                        </td>
                    </tr>

                    <RequiredFieldValidator
                        fieldId={'PayerInfo.ProviderNo'}
                        fieldName={'Provider No'}
                        triggers={[
                            isFacilityPayerInfoSubmit &&
                            providerNoIsMissing
                        ]}
                        style={{ position: 'relative', left: 3 }}
                        validationAggregation={validationAggregation}
                        validationAggregationRoutine={validationAggregationRoutine}
                    />

                    <RegExValidator
                        fieldId={'PayerInfo.ProviderNo.AlphaNumeric'}
                        fieldName={'Provider No'}
                        message={'Provider No must consist of valid characters'}
                        trigger={
                            isFacilityPayerInfoSubmit &&
                            !providerNoIsMissing &&
                            !IsAlphaNumeric(selectedFacilityPayerInfo["@ProviderNo"])
                        }
                        style={{ width: 300 }}
                        validationAggregation={validationAggregation}
                        validationAggregationRoutine={validationAggregationRoutine}
                    />

                    <tr>
                        <td className="fieldLabel">NPI:</td>
                        <td style={{ width: 5 }}></td>
                        <td>
                            <input
                                id='NPI'
                                style={{ width: 200 }}
                                value={
                                    selectedFacilityPayerInfo &&
                                    selectedFacilityPayerInfo['@NPI']}
                                onChange={x => {
                                    updateSelectedFacilityPayerInfo(
                                        selectedFacilityPayerInfoId,
                                        '@NPI',
                                        x.currentTarget.value,
                                        'NPI',
                                        cursorPos)
                                }}
                                onBlur={x => {
                                    this.inputOnBlur(x);
                                }}
                                maxLength={10}
                            />
                            
                            <img onClick={x => wind.BigHelp('/Support/Help/HELP_PayerNPI.htm')} 
                                 src='/Shared/Images/Icons/QMark_blue.png'
                                 style={{ position: 'relative', left: 5, top: 3, cursor: 'pointer', width:'17px', height: '17px'}}/>
                        </td>
                    </tr>
                    <tr>
                        <td className="fieldLabel">Taxonomy:</td>
                        <td style={{ width: 5 }}></td>
                        <td>
                            <input
                                id='Taxonomy'
                                style={{ width: 350 }}
                                value={
                                    selectedFacilityPayerInfo &&
                                    selectedFacilityPayerInfo['@Taxonomy']
                                }
                                onChange={x =>
                                    updateSelectedFacilityPayerInfo(
                                        selectedFacilityPayerInfoId,
                                        '@Taxonomy',
                                        x.currentTarget.value,
                                        'Taxonomy',
                                        cursorPos)
                                }
                                onBlur={x => {
                                    this.inputOnBlur(x);
                                }}
                                maxLength={10}
                            />

                            <img onClick={x => wind.BigHelp('/Support/Help/HELP_TaxonomyCodes.htm')}
                                 src='/Shared/Images/Icons/QMark_blue.png'
                                 style={{ position: 'relative', left: 5, top: 3, cursor: 'pointer', width:'17px', height: '17px'}}/>

                        </td>
                    </tr>
                    <tr>
                        <td className="fieldLabel">Description:</td>
                        <td style={{ width: 5 }}></td>
                        <td>
                            <input
                                id='Description'
                                style={{ width: 350 }}
                                value={
                                    selectedFacilityPayerInfo &&
                                    selectedFacilityPayerInfo['@Description']
                                }
                                onChange={x =>
                                    updateSelectedFacilityPayerInfo(
                                        selectedFacilityPayerInfoId,
                                        '@Description',
                                        x.currentTarget.value,
                                        'Description',
                                        cursorPos)
                                }
                                onBlur={x => {
                                    this.inputOnBlur(x);
                                }}
                                maxLength={25}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="fieldLabel">Set to Default:</td>
                        <td style={{ width: 5 }}></td>
                        <td>
                            <input
                                id='SetToDefault'
                                type="checkbox"
                                checked={
                                    selectedFacilityPayerInfo &&
                                    selectedFacilityPayerInfo['@SetToDefault'] == 'Y'
                                }
                                onChange={x => {
                                    updateSelectedFacilityPayerInfo(
                                        selectedFacilityPayerInfoId,
                                        '@SetToDefault',
                                        selectedFacilityPayerInfo['@SetToDefault'] == 'Y' ? 'N' : 'Y',
                                        'SetToDefault',
                                        cursorPos)
                                    }
                                }
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

}
