import React from "react";
import styled from 'styled-components';
import { DialogWrapper, ContentRowWrapper } from '@common/DialogWrapper';
import { ConstrainedInput } from './common/CommonStyles';
import { Button } from '@optum-uicl/ui-core/dist';
import { CreateCifDAL } from './CreateCifDAL';

/*
 * INTERFACES
 */



type ICreateCCNProps = {
    parent: any,
};

/*
 * STYLED COMPONETNS
 */

const CenteredRowWrapper = styled(ContentRowWrapper)`
    && {
        flex-direction: row;
        align-content: center;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
    }`;

const CenteredButton = styled(Button)`
    && {
        margin-left: 10px;
        margin-top: 20px;
    }`;

/**
 *
 * CLAIM CONTROL NUMBER
 * The last step in the process, allows user to record a CIF Claim Control Number
 * This is strictly a presentation component
 */

export const CreateCCNScreen = ({ parent }: ICreateCCNProps) => {

    const handleOnSubmit = () => {

        if (parent.state.isPrinted) {

            //make sure we have a Valid CCN number
            let controlNum = parent.state.CCN.replace(/\D/g, '');
            parent.setState({ CCN: controlNum });

            if (controlNum !== '' && controlNum.length > 0) {

                //We persist the Claim ID associated with the form
                let controlURL = `/api/data/cif/record/${parent.state.data?.formData.claimID}/${controlNum}`
                const ccnGet: Promise<string | undefined> = new CreateCifDAL().GetCnnNumber(controlURL);

                ccnGet.then((response) => {
                    parent.props.history.push(parent.referrerPage);
                })

            } else {

                let modalTitle = "Message from webpage";
                let modalMessage = "OK is not valid until you enter the CCN from the printed CIF";
                let modalVisible = true;

                //Put up modal 
                parent.modalHandler(modalTitle, modalMessage, modalVisible);

            }
        }
    }

    return (
        <React.Fragment>
            <CenteredRowWrapper>
                <DialogWrapper
                    title="Claim Control Number"
                    width={'300px'}
                >
                    <CenteredRowWrapper>
                        <ConstrainedInput
                            domID="cif-form-ccn"
                            label="*CIF CCN"
                            pixelwidth="150"
                            maxLength={12}
                            initialValue={parent.state.CCN}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    parent.setState({ CCN: e.target.value })
                                }
                            }
                        />
                        <CenteredButton
                            buttonType="standard"
                            domID="submit-cnn-but"
                            name="Submit"
                            onClick={(e: React.SyntheticEvent<Element, Event>) => {
                                handleOnSubmit()
                            }}
                            disabled={parent.state.CCN === '' }
                            size="small"
                            type="button"/>
                        
                    </CenteredRowWrapper>
                </DialogWrapper>
            </CenteredRowWrapper>
        </React.Fragment>
    );
}