import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DetailPaneHeader } from '@optum-uicl/ui-core/dist';

export default class PageNotFound extends React.Component<RouteComponentProps<{}>, {}> {
    public render() {
        return <div>
            <DetailPaneHeader
                domID="page-not-found"
                title="Page Not Found"
                description="The selected path was not found"
            />
        </div>;
    }
}
