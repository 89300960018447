import * as React from 'react';
import { ARMGrid } from '@common/UICLWrappers/ARMGrid';
import { GridConfig, IRecordProp, IRowProps } from './GridConfig';
import styled from 'styled-components';
import { IHeaderProps } from './GridConfig';
import { ARMRowCell } from '@common/UICLWrappers/ARMRowCell';
import { AvailableSelectedMap } from '../Shared';
import { handleChange } from '@commonResources/userModified';
import { ClientPayersPayer, MCEligibilityPayerMaintenanceInfo } from '@scripts/@types/MasterCrud/EligibilityProfile';
import Colors from "@commonResources/colorVariables";

export const GridWrapper = styled.div`
    width:835px;
    overflow-y: hidden !important;

    #grid-wrapper-enabled-payers-grid {

        height:385px;

        th {
            /* background-color:red; */
            padding: 0px !important;
            vertical-align: middle;
            /* prevent scrolled rows from overlaying header */
            z-index: 5;
        }
    }

    /* overflow-y:scroll; */
    overflow-y:hidden;
    background-color:white;
    border: black 1px solid;

    .empty-row{
        background-color:white !important;
    }

    table tbody tr > td{
        height:25px !important;
        font-size: 15px;
    }

    table tbody tr td div{
        min-height:1px;
    }

    .selected{
        background-color: ${Colors.digitalOrange15};
    }
    
    .added{
        background-color: #ccebff !important;
    }

`;

interface IEnabledPayersProps {
    maintenanceInfo: MCEligibilityPayerMaintenanceInfo;
    enabledPayers: ClientPayersPayer[];
    availableSelectedMap: AvailableSelectedMap;
    onRowClick: Function;
    onFieldClick: (maintenanceInfo: any, payerId: number, rtecpId: string) => void;
    onEditClick: (maintenanceInfo: any, payerId: number, rtecpId: string) => void;
    onCheckboxClick: (record: IRecordProp) => void;
    directEntry: any;
    canView: boolean;
    canEdit: boolean;
}

interface IComponentState {

}

export const DEFAULT_STATE: IComponentState = {
}

export class EnabledPayersGrid extends React.Component<IEnabledPayersProps, IComponentState> {
    constructor(props: IEnabledPayersProps) {
        super(props)
        this.state = DEFAULT_STATE;
    }

    onRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {    
        this.props.onRowClick(event.currentTarget.id);
    }

    onPayerCheckboxClicked(record: IRecordProp) {
        handleChange();
        this.props.onCheckboxClick(record);
    }

    handleScroll = (e: string) => {
        this.setState({ recordToScrollTo: e })
    }

    getCustomHeader() {
        const header = ({ columns }: IHeaderProps) => <thead><tr key={Math.random()}>
            {
                <React.Fragment>
                    <th style={{ width: '300px' }}>
                        <div style={{ fontWeight: '400', padding: '5px' }}>
                            Payer
                        </div>
                    </th>
                    <th style={{ width: '60px' }}>
                        <div style={{ fontWeight: '400', padding: '5px'}}>
                            Active
                        </div>
                    </th>
                    <th style={{ width: '60px' }}>
                        <div style={{ fontWeight: '400', padding: '5px'}}>
                            Batch
                        </div>
                    </th>
                    <th style={{ width: '60px' }}>
                        <div style={{ fontWeight: '400', padding: '5px' }}>
                            Bridge
                        </div>
                    </th>
                    <th style={{ width: '60px' }}>
                        <div style={{ fontWeight: '400', padding: '5px' }}>
                            CFI
                        </div>
                    </th>
                    <th style={{ width: '60px' }}>
                        <div style={{ fontWeight: '400', padding: '5px' }}>
                            Manual
                        </div>
                    </th>
                    {this.props.directEntry === "1" &&
                        <th style={{ width: '60px' }}>
                        <div style={{ fontWeight: '400', padding: '5px' }}>
                                Direct
                        </div>
                        </th>
                    }
                    <th style={{ width: '60px' }}>
                        <div style={{ fontWeight: '400', padding: '5px' }}>
                            Fields
                        </div>
                    </th>
                    {this.props.directEntry === "1" &&
                        <th style={{ width: '60px' }}>
                        <div style={{ fontWeight: '400', padding: '5px' }}>
                                Edits
                        </div>
                        </th>
                    }
                </React.Fragment>
            }
        </tr></thead>

        return header;
    }
    
    public colorRow(record : any, selectedId: any) : string{
        if(selectedId && record['@PayerID'] === selectedId['@PayerID'] && record['@RTECPID'] === selectedId['@RTECPID'])
            return 'selected';
        
        if(record.added)
            return 'added';
        
        return '';
    }

    getCustomRow() {
        const {
            availableSelectedMap,
        } = this.props;
        const CHECKBOX_STYLE = { cursor: 'pointer', accentColor: `${Colors.optumBlue90}`};
        //single select
        const selectedId = availableSelectedMap.selectedIds[0] as any; // can't index on ID
        const row = ({ record }: IRowProps) =>
            <tr id={record['@PayerID'].toString() + record['@RTECPID']}
                key={record['@PayerID'] + record['@RTECPID']}
                onClick={(x) => this.props.onRowClick(x, record)}
                className={this.colorRow(record, selectedId)}>
                <React.Fragment>

                    <ARMRowCell displayTooltip value={record['@DisplayName']}
                        style={{ width: 300, top: '-1px', whiteSpace: 'nowrap', padding: '0px', cursor: 'pointer' }}
                    />
                    
                    <td>
                        <div className="cell-component-wrapper">
                            <div className="no-margin" style={{ position: 'relative', top: '5px', textAlign: 'center' }}>
                                <input type="checkbox" defaultChecked={record['@Active'] && record['@Active'] == 'Y' ? true : false}
                                    onClick={(x) => {
                                        x.stopPropagation();
                                        record['@Active'] = record['@Active'] === 'Y' ? 'N' : 'Y';
                                        this.onPayerCheckboxClicked(record);
                                    }}
                                    style={CHECKBOX_STYLE}
                                />
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="cell-component-wrapper">
                            <div className="no-margin" style={{ position: 'relative', top: '5px', textAlign: 'center' }}>
                                <input type="checkbox" defaultChecked={record['@Batch'] && record['@Batch'] == 'Y' ? true : false}
                                    onClick={(x) => {
                                        x.stopPropagation();
                                        record['@Batch'] = record['@Batch'] === 'Y' ? 'N' : 'Y';
                                        this.onPayerCheckboxClicked(record);
                                    }}
                                    style={CHECKBOX_STYLE}
                                />
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="cell-component-wrapper">
                            <div className="no-margin" style={{ position: 'relative', top: '5px', textAlign: 'center' }}>
                                <input type="checkbox" defaultChecked={record['@Bridge'] && record['@Bridge'] == 'Y' ? true : false}
                                    onClick={(x) => {
                                        x.stopPropagation();
                                        record['@Bridge'] = record['@Bridge'] === 'Y' ? 'N' : 'Y';
                                        this.onPayerCheckboxClicked(record);
                                    }}
                                    style={CHECKBOX_STYLE}
                                />
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="cell-component-wrapper">
                            <div className="no-margin" style={{ position: 'relative', top: '5px', textAlign: 'center' }}>
                                <input type="checkbox" defaultChecked={record['@CFI'] && record['@CFI'] == 'Y' ? true : false}
                                    onClick={(x) => {
                                        x.stopPropagation();
                                        record['@CFI'] = record['@CFI'] === 'Y' ? 'N' : 'Y';
                                        this.onPayerCheckboxClicked(record);
                                    }}
                                    style={CHECKBOX_STYLE}
                                />
                            </div>
                        </div>
                    </td>
                    <td>
                        <div style={{ maxWidth: '100px' }}>
                            <div className="no-margin" style={{ position: 'relative', top: '5px', textAlign: 'center' }}>
                                <input type="checkbox" defaultChecked={record['@Manual'] && record['@Manual'] == 'Y' ? true : false}
                                    onClick={(x) => {
                                        x.stopPropagation();
                                        record['@Manual'] = record['@Manual'] === 'Y' ? 'N' : 'Y';
                                        this.onPayerCheckboxClicked(record);
                                    }}
                                    style={CHECKBOX_STYLE}
                                />
                            </div>
                        </div>
                    </td>
                    {this.props.directEntry === "1" &&
                        <td>
                            <div className="cell-component-wrapper">
                                <div className="no-margin" style={{ position: 'relative', top: '5px', textAlign: 'center' }}>
                                    <input disabled={record['@RTECPID'] === 'CMSHSP' ? false : true} type="checkbox" defaultChecked={record['@Direct'] && record['@Direct'] == 'Y' ? true : false}
                                        onClick={(x) => {
                                            x.stopPropagation();
                                            record['@Direct'] = record['@Direct'] === 'Y' ? 'N' : 'Y';
                                            this.onPayerCheckboxClicked(record);
                                        }}
                                        style={CHECKBOX_STYLE}
                                    />
                                </div>
                            </div>
                        </td>
                    }
                   
                        <td>
                            <div className="cell-component-wrapper">
                                <div className="no-margin" style={{ position: 'relative', top: '5px', textAlign: 'center' }}>
                                <a>{record['@Fields']}</a>
                                <a style={{ color: this.props.canEdit ? `${Colors.optumBlue90}` : `${Colors.optumNeutral100}`, textDecoration: 'underline', cursor: "pointer" }}
                                onClick={x => {
                                    if (this.props.canEdit) {
                                        this.updateFieldsOnClick(record['@PayerID'], record['@RTECPID']);
                                    }
                                }}>Update
                                </a> 
                                </div>
                            </div>
                        </td>
                    
                    { this.props.directEntry === "1" &&
                        <td>
                            <div className="cell-component-wrapper">
                                <div className="no-margin" style={{ position: 'relative', top: '5px', textAlign: 'center' }}>
                                    {record['@RTECPID'] === 'CMSHSP' ? <React.Fragment>
                                    <a>{record['@Edits']}</a>
                                    <a style={{ color: this.props.canEdit ? `${Colors.optumBlue90}` : `${Colors.optumNeutral100}`, textDecoration: 'underline', cursor: "pointer" }}
                                        onClick={x => {
                                            if (this.props.canEdit) {
                                                this.updateEditsOnClick(record['@PayerID'], record['@RTECPID']);
                                            }
                                        }}>Update</a>
                                    </React.Fragment> :
                                        <React.Fragment>
                                            &nbsp;
                                </React.Fragment>}
                                </div>
                            </div>
                        </td>
                    }
                </React.Fragment>
            </tr>
        return row;
    }

    updateFieldsOnClick(payerId: number, rtecpId: string) {
        const {
            maintenanceInfo
        } = this.props;

        this.props.onFieldClick(maintenanceInfo, payerId, rtecpId)
    }

    updateEditsOnClick(payerId: number, rtecpId: string) {
        const {
            maintenanceInfo
        } = this.props;

        this.props.onEditClick(maintenanceInfo, payerId, rtecpId)
    }

    render() {
        const {
            enabledPayers
        } = this.props;

        const columns = new GridConfig().getColumnHeadersConfig();
        const header = this.getCustomHeader();
        const row = this.getCustomRow();

        return (
            <GridWrapper>
                <ARMGrid
                    records={enabledPayers}
                    columns={columns}
                    domID='enabled-payers-grid'
                    isFixedHeader={true}
                    maxHeight='385px'
                    headerComponent={header}
                    rowComponent={row}
                    emptyGridMessage=' '
                    // grid works without selectionKey
                    // but cdt console would display warning from uicl grid
                    selectionKey='@PayerID'
                    scrollToTop={false}
                />
            </GridWrapper>
        )
    }
}
