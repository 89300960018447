import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@optum-uicl/ui-core/dist';
import { JobDetailsViewModelBuilder } from './JobDetailsViewModelBuilder';
import { DynamicTableLayout } from './DynamicTableLayout';
import refreshIcon from '@content/images/LandingPage/refresharrow.png';
import { DivWidthCalculator } from './DivWidthCalculator';

const ButtonRow = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 6px;
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

ButtonRow.displayName = "ModalFilterListButtonRow";

interface IComponentState {
    showModal: boolean,
    modalWidth: string,
    modalHeight: string,
    sizeIsSet: boolean,
    jobIsRunning: boolean,
    time: number,
    divWidth: number,
    jobDetailsViewModel: JobDetailsViewModel | undefined,
    hrWidth: string,
}

interface IJobDetailsModalProps {
    isOpen: boolean,
    showDefaultClose: boolean,
    onClose: Function,
    onSizeChange: Function,
    jobId: string,
}

export const DEFAULT_STATE: IComponentState = {
    showModal: true,
    modalWidth: '200px',
    modalHeight: '200px',
    sizeIsSet: false,
    jobIsRunning: false,
    time: Date.now(),
    divWidth: 0,
    jobDetailsViewModel: undefined,
    hrWidth: '100%',
};

export class JobDetailsDiv extends React.Component<IJobDetailsModalProps, IComponentState> {
    static propTypes: {}
    interval: number;

    constructor(props: IJobDetailsModalProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.interval = 0;
    }

    async componentDidMount() {
        this.loadJobDetails();
    }

    async loadJobDetails() {
        const {
            jobId
        } = this.props;

        // get viewmodel by jobid
        let jobDetailsViewModel = await new JobDetailsViewModelBuilder().BuildViewModel(jobId);

        // identify if job is currently running
        const jobIsRunning = jobDetailsViewModel?.JobIsRunning;

        // configure interval handle for timer refresh if not already configured
        if (jobIsRunning && this.interval === 0) {
            this.interval = window.setInterval(() => this.setState({ time: Date.now() }), 30000);
        }

        // set modal dimensions
        if (jobDetailsViewModel) this.setModalDimensions(jobDetailsViewModel);
    }

    setModalDimensions(jobDetailsViewModel: JobDetailsViewModel) {

        // create DivWidthCalculator input by excluding items with classname "innerhtml"
        const divWidthCalculatorInput = jobDetailsViewModel.Details.Items.filter(x => x.ClassNames?.toLowerCase() != 'innerhtml');

        // get div width by table metadata
        let divWidth = new DivWidthCalculator().getDivWidth({ Items: divWidthCalculatorInput});

        // add some div width to accomodate long lineage descriptions
        const maxLineageDescription = Math.max(...jobDetailsViewModel?.Lineage?.Events?.map(x => x.Description.length || 0) || [0]);
        if (maxLineageDescription > 100) {
            divWidth = divWidth * 1.50;
        }

        // get div height
        const divHeight = this.getDivHeight(jobDetailsViewModel);

        let hrWidth: string = '100%';
        this.setState({
            sizeIsSet: true,
            divWidth,
            jobDetailsViewModel,
            hrWidth,
        },
            () => {
                // added this line as a way to validate that setState gets called when user clicks Refresh
                console.log('setState called on ' + this.getCurrentDateTime());
                // modal width should be about 10% wider to accomodate child div
                const modalWidth = (divWidth * 1.10 + 'px');
                const modalHeight = divHeight + 'px';
                this.props.onSizeChange(modalWidth, modalHeight);
            }
        );
    }

    getCurrentDateTime() {
        var d = new Date,
            dformat = [d.getMonth() + 1,
            d.getDate(),
            d.getFullYear()].join('/') + ' ' +
                [d.getHours(),
                d.getMinutes(),
                    d.getSeconds()].join(':');
        return `[${d}]`;
    }

    getDivHeight(jobDetailsViewModel: JobDetailsViewModel) {
        const rowNumbers = jobDetailsViewModel.Details.Items.map(x => x.RowNumber);
        const rowCount = Math.max(...rowNumbers);
        const divHeight = 100 + (rowCount * 40);
        return divHeight;
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    refresh() {
        this.loadJobDetails();
    }

    render() {

        const {
            jobDetailsViewModel,
            divWidth,
        } = this.state;

        const {
            hrWidth
        } = this.state;

        return (
            <>
                {/* page title */}
                <div style={{ fontSize: 18, fontFamily: 'Century Gothic' }}>
                    Job Details
                </div>

                {/* spacer */}
                <div style={{ height: 10 }} />

                <div style={{ width: divWidth, textAlign: "center", fontFamily: 'Century Gothic' }}>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        {/* job details header title */}
                        <div>{jobDetailsViewModel && jobDetailsViewModel.Header}</div>

                        {/* refresh button */}
                        <div style={{ cursor: 'pointer' }}
                            onClick={() => {
                                this.refresh();
                            }}>
                            <img src={refreshIcon} style={{ left: -4, top: 4, position: 'relative' }} />
                            <a style={{ textDecoration: 'underline', color: 'blue' }}>Refresh</a>
                        </div>
                    </div>

                    {/* spacer */}
                    <div style={{ height: 5 }}></div>

                    {/* dynamic table layout */}
                    <div style={{ position: 'relative', left: -2 }}>
                        {jobDetailsViewModel &&
                            <DynamicTableLayout
                                tableMetadata={jobDetailsViewModel.Details}
                            />
                        }
                    </div>

                    {/* spacer */}
                    <div style={{ height: 7 }}></div>

                    {/* horizontal line */}
                    <hr style={{ width: hrWidth, height: 0.5, color: 'lightgrey' }} />

                    {/* lineage title */}
                    <div style={{ textAlign: 'left', fontWeight: 'bold' }}>Lineage:</div>
                    <div>
                        {jobDetailsViewModel?.Lineage?.Title}
                    </div>

                    {/* spacer */}
                    <div style={{ height: 20 }} />

                    {/* lineage events */}
                    <div style={{ display: 'table', textAlign: 'left' }}>
                        <div style={{ display: 'table-row-group' }}>
                            {jobDetailsViewModel?.Lineage?.Events?.map((x) => {
                                return (
                                    <div style={{ display: 'table-row' }}>
                                        <div style={{ display: 'table-cell', whiteSpace: 'nowrap' }}>{x.Date}</div>
                                        {/* spacer */}
                                        <div style={{ width: 80, display: 'table-cell' }}></div>
                                        <div style={{ display: 'table-cell' }}>{x.Description}</div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>

                {/* spacer */}
                <div style={{ height: 10 }} />

                <ButtonRow>
                    <Button
                        name="OK"
                        buttonType="emphasized"
                        onClick={() => this.props.onClose()}
                        size="small"
                        domID="filterlist-confirm-button" />
                </ButtonRow>
            </>
        )
    }
}

JobDetailsDiv.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    showDefaultClose: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    jobId: PropTypes.string,
    onSizeChange: PropTypes.func,
}
