import styled from 'styled-components';
import { Colors } from '../../../../commonResources/colorVariables';
import { BoxShadows } from '../../../../commonResources/boxShadows';
import { Borders } from '../../../../commonResources/borders';
import { Typography } from '../../../../commonResources/typography';

export const ARMLoginWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  padding-top: 40px;
  text-align: left;
`;

export const ARMLoginBody = styled.div`
  width: 440px;
  margin: auto;
  // ${BoxShadows.lightFade};
  // ${Borders.extraSmallBorderRadius};
  border: 2px solid #CBCCCD;
  border-radius: 10px;
`;

export const ARMLoginForm = styled.form`
  background-color: ${Colors.white};
  padding: 0px 24px;

  .title {
    ${Typography.heading};
    margin: 0 0 0.5em 0;
  }

  .text-input-sideby {
    margin-bottom: 1em;
    font-size: 11px;
    font-weight: bold;
    width: 30px;
    vertical-align: middle;
  }

  .text-input {
    margin-bottom: 1em;
    font-size: 11px;
    font-weight: bold;
  }

  .sign-in-cta {
    float: right;
  }

  @media(max-width: 480px) {
    .sign-in-cta {
      float: none;
      display: block;
      margin-left: 0;
      margin-top: 0.5em;
    }
  }

  label {
    margin-bottom: 8.8px;
    color: ${ Colors.default};
    ${ Typography.small};
    ${ Typography.defaultLineHeight};
    ${ Typography.bold};
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

   #orSeparator {
     margin-top: 4px;    
   }

`;

export const ARMLabel = styled.label`
    display: block;
    margin-bottom: 8.8px;
    color: ${ Colors.default};
    ${ Typography.small};
    ${ Typography.defaultLineHeight};
    ${ Typography.bold};
    letter-spacing: 0.5px;
    text-transform: uppercase;
`;

export const FormRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${ Typography.defaultLineHeight};

    .link-button {
    ${Typography.defaultLineHeight};
    font-size: 11px;
    padding: 0px;
    }

    .align-top {
        vertical-align: top;
    }

    #ciam {
        margin-top: 0px;
        font-size: 14px;
     } 
     #verify-button, #login-button {
         
         width: 110px;
         
     }
`;
