import * as React from 'react';
import { Modal, Button, Section, ModalHeader } from '@optum-uicl/ui-core/dist';
import styled from 'styled-components';


const ModalHeaderCntr = styled.div`
    > div {
        padding: 5px 10px;
        border-bottom: none;
    }
    #modal-confirmation-header { margin: auto; }

    > button{
        padding-top: 13px;
    }
`;

const ModalTitleContainerDiv = styled.div`
       .header{
           padding: 0 !important;
            margin-top: -23px;
            margin-bottom: 53px;
       }
`

const ModalBody = styled.div`
    justify-content: center;
    border-radius: 3px;
    padding: 0rem 1.5rem;
`;

const InnerComponentWrapper = styled.div`
`;

const ButtonRow = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 6px;
    margin-bottom: 15px;
    
    button {
        margin: 4px;
        width: 85px;
        height: 26px !important;
        text-align: center;
        text-transform:uppercase;
        padding: 4px 16px;
    }
`;

export interface ModalComponentWrapperProps {
    isOpen: boolean;
    showDefaultClose: boolean;
    onModalToggle: React.MouseEventHandler<HTMLElement>;
    title?: string;
    component: React.ReactNode;
    alertMode?: boolean;
    onConfirm?: React.MouseEventHandler<HTMLButtonElement>;
    onDeny?: React.MouseEventHandler<HTMLButtonElement>;
    onOK?: React.MouseEventHandler<HTMLButtonElement>;
    okText?: string;
    cancelText?: string;
    closeOnClickOut?: boolean;
};

interface IComponentState {
    visible: boolean;
}

export class ModalComponentWrapper extends React.Component<ModalComponentWrapperProps, IComponentState> {

    static defaultProps: ModalComponentWrapperProps = {
        isOpen: true,
        showDefaultClose: false,
        onModalToggle: (e: React.MouseEvent<HTMLElement>) => false,
        title: '',
        component: null,
        alertMode: false,
        okText: "OK",
        cancelText: "Cancel",
        closeOnClickOut: false,
    };

    constructor(props: ModalComponentWrapperProps) {
        super(props);

        this.state = {
            visible: true,
        };
    }

    closeModal() {
        this.setState({ visible: false });
    }

    //public onClickX(e: React.ChangeEvent<HTMLButtonElement>) {
    //    this.props.isOpen = false;
    //}

    // debuke says: button: onClick: { (e: React.ChangeEvent<HTMLButtonElement>) => this.onClickX(e) }
    render() {
        return (
            <Modal className="modal-confirmation" isOpen={this.props.isOpen}
                closeModalOnClickOut={this.props.closeOnClickOut}
                onModalToggle={(e: React.SyntheticEvent<Element, Event>) => this.props.onModalToggle(e as React.MouseEvent<HTMLButtonElement>)}>
                <ModalHeaderCntr>
                    <ModalHeader
                        showLine={false}
                        domID="modal-confirmation-header"
                        onClose={(e: React.SyntheticEvent<Element, Event>) => this.props.onModalToggle(e as React.MouseEvent<HTMLButtonElement>)}
                    />
                </ModalHeaderCntr>
                <ModalBody>
                    <ModalTitleContainerDiv>
                    <Section
                        domID="modal-confirmation-message"
                        title={this.props.title ? this.props.title : ModalComponentWrapper.defaultProps.title}
                        />
                    </ModalTitleContainerDiv>
                    <InnerComponentWrapper>
                        {this.props.component}
                    </InnerComponentWrapper>
                    {!this.props.alertMode &&
                        <ButtonRow>
                            <Button
                                name={this.props.okText}
                                buttonType="emphasized"
                                onClick={(e: React.SyntheticEvent) => { this.props.onModalToggle(e as React.MouseEvent<HTMLButtonElement>); if (this.props.onConfirm) { this.props.onConfirm(e as React.MouseEvent<HTMLButtonElement>); } }}
                                size="small"
                                domID="modal-confirmation-confirm-button"
                            />
                            <Button
                                name={this.props.cancelText}
                                buttonType="standard"
                                onClick={(e: React.SyntheticEvent) => { this.props.onModalToggle(e as React.MouseEvent<HTMLButtonElement>); if (this.props.onDeny) { this.props.onDeny(e as React.MouseEvent<HTMLButtonElement>); } }}
                                size="small"
                                domID="modal-confirmation-cancel-button"
                            />
                        </ButtonRow>
                    }
                    {this.props.alertMode &&
                        <ButtonRow>
                            <Button
                                name={this.props.okText}
                                buttonType="emphasized"
                                onClick={(e: React.SyntheticEvent) => { this.props.onModalToggle(e as React.MouseEvent<HTMLButtonElement>); if (this.props.onOK) { this.props.onOK(e as React.MouseEvent<HTMLButtonElement>); } }}
                                size="small"
                                domID="modal-confirmation-confirm-button"
                            />
                        </ButtonRow>
                    }
                </ModalBody>
            </Modal>
        );
    }
}
