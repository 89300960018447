import styled, { css } from 'styled-components';
import Colors from '../../commonResources/colorVariables';
import { Typography } from '@commonResources/typography';


export interface ITabID {
    domID: string;
    label: string;
}

interface IArmTabContainerProps {
    tabs: Array<ITabID>;
}

export const ArmTabContainer = styled.div<IArmTabContainerProps>`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px; 
    width: 650px;
    padding: 0px;
    button:hover {
        color: #FFF !important;
    }
    label {
        letter-spacing: 0.05em;
        max-width: 200px;
    }
    box-shadow:rgb(155, 161, 169) 0px -2px 0px 0px inset;

    ${(p) => {
    let labelCSS = "";
    let baseCSS = "";
    p.tabs?.map((tab: any, index: number) => {
        labelCSS += `#${tab.domID} > label { margin-bottom: 0px; ${Typography.small}; }`;
        baseCSS += `#${tab.domID} { padding-top: 1px; padding-bottom: 1px; }`
    })
    return `${labelCSS} ${baseCSS}`;  
    }}
`;

interface IContentWrapperStyleProps {
    flexDirection?: string;
    width?: string;
}

export const ContentWrapperStyle = styled.div<IContentWrapperStyleProps>`
    display: flex;
    flex: 1 1 auto;
    flex-direction: ${(p) => (p.flexDirection ? `${p.flexDirection}` : 'column')};
    width: ${(p) => (p.width ? p.width : '950px')};
     button label{
        text-transform:uppercase;
    }

`;

export const CenterFormItemsStyle = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    justify-content: center;
    padding: 1px;
    height: 100%;
    width: 100%;
`;

interface IButtonGroupStyleProps {
    justify: boolean;
    size?: string;
}

export const ButtonGroupStyle = styled.div<IButtonGroupStyleProps>`
    ${(p) => p.justify && css`
        justify-content: center;
    `}
    button {
        margin: 4px;
        text-align: center;

        ${(p) => p.size && p.size === 'small' && css`
            width: 85px;
        `}
    }
`;

const Fonts = {
    Default: `
        font-family: 'optum-sans-regular';
        font-size: 11px;
        font-weight: 600;
    `,
}

interface IDefaultProps {
    uppercase?: boolean | undefined;
    textAlign?: string | undefined;
}

interface IInputTextProps {
    inline: boolean;
    marginBottom?: string | undefined;
}

export const Labels = {
    Default : styled.div<IDefaultProps>`
        padding-top: -5px;
        margin-bottom: 0.3rem;
        ${Fonts.Default}
        line-height: 1.4em;
        letter-spacing: 0.5px;
        ${(p) => p.uppercase && css`
            text-transform: uppercase;
        `}
        ${(p) => p.textAlign && p.textAlign?.length > 0 && css`
            text-align: ${p.textAlign};
        `}
        
    `,
    InputText: styled.div<IInputTextProps>`
        display: ${(p) => (p.inline ? 'inline' : 'block')};
        color: #37474F;
        font-family: 'optum-sans-regular';
        font-size: 11px;
        font-weight: 600;
        line-height: 1.4em;
        margin-bottom ${( p ) => ( (p.marginBottom && p.marginBottom?.length > 0) ? `${p.marginBottom}` : '0.3rem' )};
        -webkit-letter-spacing: 0.5px;
        -moz-letter-spacing: 0.5px;
        -ms-letter-spacing: 0.5px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    `,
};

const Buttons: Record<string, any> = {};
Buttons.Props = {
    Default: {
        styleType: "emphasized",
        size: "small",
        type: "button",
    },
    PageDefault: {
        size: "small",
        type: "button",
    },
    Form: {
        small: {
            buttonType: "standard",
            size: "small", //text size
        },
    },
};
Object.freeze(Buttons.Props);
Buttons.Styles= {
    form: css`
        width: "85px",
        margin: "4px",
    `,
    alignCenter: css`
        text-align: "center",
    `,
};
Object.freeze(Buttons.Styles);
Object.seal(Buttons);
Object.freeze(Buttons);
export { Buttons };

export const BoldSelectWrapper = styled.div`
    .selectDropdown__container, .selectDropdown__option {
        font-weight: bold;
    }
`;

export const UnderlinedHeading = styled.div`
    width: 100%;
    border-bottom: 1px solid ${Colors.defaultDark};
`;

// can't use css prop bc babel macro won't compile without a .babelrc file
export const RightAligned = styled.div`text-align: right`;

/*
 * In IE, Input widths come from the length of the input. While we can provide a child input element
 * and give it a length, that's probably against the spirit of UICL and would probably open a
 * can of worms. So we force the container to the right width, knowing UICL will make the input 100%.
 * This can of course be used for things other than Inputs as well, but that's why it's here
                                hasError={!!errors?.incorrectCommentLength}
                                errorMessage={errors?.incorrectCommentLength}
 */
export interface SizedContainerProps {
    domID?: string;
    size?: string;
    maxLength?: number;
    initialValue?: any;
    onBlur?: any;
    label?: string;
    pixelWidth?: string;
    percentWidth?: string;
    heightValue?: string;
    minHeightValue?: string;
    hasError?: boolean;
    errorMessage?: string;
};

export const SizedContainer = styled.div<SizedContainerProps>`
width: ${(p) => p.percentWidth? p.percentWidth: p.pixelWidth + 'px'};
height: ${({heightValue}) => heightValue};
min-height: ${({minHeightValue}) => minHeightValue};
`;
