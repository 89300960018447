import * as React from 'react';
import * as jQuery from 'jquery';
import { LandingPageComponent, IComponentProps } from './LandingPageComponent';
import { ARMComponentLoading } from './ARMComponentLoading';
import { ARMComponentError } from './ARMComponentError';
import { ARMComponentMessage } from './ARMComponentMessage';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getRawToken } from '@scripts/session/SecurityToken';

import { URLs } from '@commonDevResources/constants';
import { ARMLandingPageHeaderWrapper, ARMLandingPageHeaderButton } from './ARMLandingPageHeader';
import { connect } from 'react-redux';
import { createMapDispatchToProps, IMergeComponentProps, mergeComponentProps, IActionCreatorMap, mapStatesToProps, IApplicationStateMap } from '@scripts/util/ReduxHelpers';
import { OperationFilter, defaultFilter, dateRanges } from "@store/OperationalDashboard/DrillDownView"

import * as wind from '@commonResources/window';

window.jQuery = jQuery; window.$ = jQuery;

const jqplot = require('../../scripts/jqPlot/jquery.jqplot.js');
//const jqplot = require('../../scripts/jqPlot/jquery.jqplot.min.js');
const jqplotPieRenderer = require('../../scripts/jqPlot/jqplot.pieRenderer.js');
const jqplotCategoryAxisRenderer = require('../../scripts/jqPlot/jqplot.categoryAxisRenderer.js');
const jqplotBarRenderer = require('../../scripts/jqPlot/jqplot.barRenderer.js');
const jqplotHighlighter = require('../../scripts/jqPlot/jqplot.highlighter.js');
const jqplotPointLabels = require('../../scripts/jqPlot/jqplot.pointLabels.js');

interface IUnReleasedClaimAgeState {
    data?: dataRef;
    userCanView: boolean;
    errorCode?: any | null;
    optionsData?: any[];
    dateRange?: string;
    dateRangeValue?: string;
    hasData: boolean;
}

type dataRef = {
    Data: [];
    Ticks: [];
    UserId: Number;
    FromDate: Date;
    ThruDate: Date;
    CountTicks: any;
    AmountTicks: any;
};

interface IProps extends IComponentProps {
    dateRange?: string;
    id?: string;
}

type IOwnProps = IProps & RouteComponentProps<{}>;

type IUnReleasedClaimAgeProps = IMergeComponentProps<IApplicationStateMap<"drillDownView">, IActionCreatorMap<"drillDownView">, IOwnProps>;


class UnReleasedClaimAge extends React.Component<IUnReleasedClaimAgeProps, IUnReleasedClaimAgeState>{
    constructor(props: IUnReleasedClaimAgeProps) {
        super(props);
        this.state =
        {
            userCanView: true,
            hasData: false
        };
    }

    componentDidMount() {
        this.checkUserRights();
    }

    componentDidUpdate() {
        if (this.state.data) {
            this.initChart();
        }
    }

    initChart() {
        let s1: any[] = [];
        let s2: any[] = [];
        let y1: any[] = [];
        let y2: any[] = [];

        var dataAvailable = false;
        if (this.state.data) {
            $.each(this.state.data.Data, function (entryindex, entry) {
                s1.push(new Array(entry['Description'], entry['Count']));
                s2.push(new Array(entry['Description'], entry['Amount']));
                if (entry['Count'] > 0) {
                    dataAvailable = true;
                }

                if (entry['Amount'] > 0) {
                    dataAvailable = true;
                }
            });
            $.each(this.state.data.CountTicks, function (entryindex, entry) {
                y1.push(new Array(entry['Value'], entry['Display']));
            });
            $.each(this.state.data.AmountTicks, function (entryindex, entry) {
                y2.push(new Array(entry['Value'], entry['Display']));
            });
        }

        if (dataAvailable == false) {
            y1 = [[0, 0], [4, 4], [8, 8], [12, 12], [16, 16], [20, 20]];
            y2 = [[0, '$0'], [4000000, '$4M'], [8000000, '$8M'], [12000000, '$12M'], [16000000, '$16M'], [20000000, '$20M']];
            //gridBgColor = '#efefef';
            $.each(s1, function (entryindex) {
                s1[entryindex][1] = 0.5;
            });
            $.each(s2, function (entryindex) {
                s2[entryindex][1] = 500000;
            });
        }

        var options = {
            seriesDefaults: {
                renderer: $.jqplot.BarRenderer,
                rendererOptions: {
                    barPadding: 0,
                    shadow: false,
                    shadowOffset: 0
                }
            },
            grid: {
                drawGridLines: false,        // wether to draw lines across the grid or not.
                gridLineColor: '#fff',   // CSS color spec of the grid lines.
                drawBorder: false,
                background: '#ffffff',      // CSS color spec for background color of grid.                
                borderColor: '#c3c5cd',     // CSS color spec for border around grid.
                shadow: false,
                shadowOffset: 0
            },
            animate: !$.jqplot.use_excanvas,
            seriesColors: ["#1E82CB", "#63E0C9"],
            axes: {
                xaxis: {
                    renderer: $.jqplot.CategoryAxisRenderer,
                    tickRenderer: $.jqplot.CanvasAxisTickRenderer,
                    tickOptions: {
                        showMark: true
                    }
                },
                yaxis: {
                    ticks: y1,
                    showTicks: true
                },
                y2axis: {
                    ticks: y2
                }
            },
            highlighter: {
                show: true,
                showMarker: false,
                tooltipContentEditor: function (str: any, seriesIndex: any, pointIndex: any, plot: any) {
                    var date = plot.data[seriesIndex][pointIndex][0];
                    var numClaims = plot.data[seriesIndex][pointIndex][1];
                    var claimAmt = plot.data[1][pointIndex][1];

                    if (numClaims === claimAmt) {
                        numClaims = s1[pointIndex][1];
                    }
                    var html = "<div class='claimAgePopup'><b><div class='header'>" + date + "</div></b>"
                    html += "  <br><b>Total Claims</b> : " + numClaims;
                    html += "  <br><b>Amount</b> : $" + claimAmt.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
                    html += "  </div>";

                    return html;
                }
            },
            series: [
                {
                    lineWidth: 2,
                    yaxis: 'yaxis',
                    highlighter: { show: true }
                },
                {
                    yaxis: 'y2axis',
                    highlighter: { show: true }
                },
                { yaxis: 'yaxis' },
                { yaxis: 'y2axis' }]
        };
        var plot1 = $.jqplot('unreleasedClaimAgeChart', [s1, s2], options);

        if (dataAvailable) {
            $('#unreleasedClaimAgeChart').bind('jqplotClick', (ev, seriesIndex, pointIndex, data) => this.redirectToDashboard());
        } else {
            $('#unreleasedClaimAgeChart').attr("class", "unreleasedClaimAgeChart disabled");
        }
        // This is handling a case where the grid randomly doesn't get rendered on the DOM.
        // TODO: prevent React from updating this component.       
        var element = document.getElementById('unreleasedClaimAgeChart');
        if (element && (element.childNodes.length == 0)) {
            $('#unreleasedClaimAgeChart').clone().prop('id', 'unreleasedClaimAgeChart2').insertAfter('#chartTitle');
            $('#unreleasedClaimAgeChart').remove();

            $.jqplot('unreleasedClaimAgeChart2', [s1, s2], options);

            if (dataAvailable) {
                $('#unreleasedClaimAgeChart2').bind('jqplotClick', (ev, seriesIndex, pointIndex, data) => this.redirectToDashboard());
            } else {
                $('#unreleasedClaimAgeChart2').attr("class", "unreleasedClaimAgeChart disabled");
            }
        }
        if (dataAvailable == false) {
            var cell1 = this.renderComponentMessage("No Data Available");
            var temp = '<div style="position:absolute;text-align:center;left:22px;height:230px;width:386px" class="noDataColor"></div>';
            $(temp).insertAfter('.unreleasedClaimAgeChart .jqplot-grid-canvas');
        }
    }
    renderComponentMessage(message: string) {
        return (
            <div className="componentLoading">
                <div className="componentLoadingTop" />
                <div className="componentLoadingContent disabled">
                    <img src="Content/images/messageIcon.png" />
                </div>
                <div className="componentMessageText">{message}</div>
                <div className="componentLoadingBottom" />
            </div>
        );
    }

    checkUserRights() {
        var xhr = new XMLHttpRequest();
        xhr.open('get', URLs.api + "/api/data/userrights/userPermission/133", false); // FN_VIEW_DASHBOARD = 133
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.onload = () => {

            var data: any = {};
            if (xhr.responseText && xhr.responseText.length > 0)
                data = JSON.parse(xhr.responseText);

            this.setState({ userCanView: data && data.userPermission ? data.userPermission : false },
                () => { setTimeout(() => this.loadChartData(), 20); });
        };
        xhr.send();
    }

    loadChartData() {
        if (this.state.userCanView) {
            //clientStatsService.startTracking("Unreleased Claim Age|Render");
            var gridUrl = URLs.api + '/api/data/GetUnreleasedClaimAgeData';
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = () => {
                if (xhr.readyState == xhr.DONE) {
                    if (xhr.status == 200) {
                        //clientStatsService.stopTracking("Unreleased Claim Age|Render");
                        var responseData = xhr.responseText;
                        var gridData = JSON.parse(responseData);
                        this.setState({ data: gridData });
                    } else {
                        console.warn(xhr.status + " response handling " + gridUrl);
                        this.setState({ errorCode: xhr.status });
                    }
                }
            };
            xhr.open('get', gridUrl, true);
            xhr.setRequestHeader('Authorization', getRawToken());
            xhr.send();
        }
    }

    redirectToDashboard = () => {
        var filter: OperationFilter = { ...defaultFilter };
        filter.drillPage = "UnreleasedClaimAge";
        this.props.action.drillDownView.drillDownTo(filter);
    }


    renderNoRights() {
        var noRightsStyle: React.CSSProperties = {
            position: 'inherit',
            textAlign: 'center',
            height: '100%',
            width: '100%'
        };
        return (
            <LandingPageComponent ComponentClass={this.props.Name} {...this.props}>
                <div className="componentHeader" />
                <div className="RAPUnreleasedClaimAgeLoader">
                    <div style={noRightsStyle} className="noDataColor"><ARMComponentMessage message="User Does Not Have Rights To View Dashboard" /></div>
                </div>
            </LandingPageComponent>
        );
    }


    renderLoading() {
        return (<LandingPageComponent ComponentClass={this.props.Name} {...this.props}>
            <div className="componentHeader" />
            <div className="RAPUnreleasedClaimAgeLoader">
                <div className="RAPAdSpaceItem">
                    <ARMComponentLoading />
                </div>
            </div>
        </LandingPageComponent>);
    }
    handleHelpClick() {
        wind.BigHelp('HELP_DASHBOARD_OPSCLAIMCHART.HTM');
        //helpLinkClicked('HELP_DASHBOARD_OPSCLAIMCHART.HTM');
    }
    renderGraph() {
        var titleStyle: React.CSSProperties = {
            textAlign: 'center',
            fontSize: '12px',
            fontWeight: 'bolder',
            paddingTop: '5'
        };
        var divStyle: React.CSSProperties = {};
        divStyle.width = "80%";
        divStyle.padding = "11px";

        var helpStyle: React.CSSProperties = {};
        helpStyle.float = "right";

        return (<LandingPageComponent ComponentClass={this.props.Name} {...this.props}>

            <ARMLandingPageHeaderWrapper
                helpUrl='/Support/Help/HELP_DASHBOARD_OPSCLAIMCHART.htm' title='Unreleased Claim Age' width='100%' isBillerView={false} isLandingPage={this.props.Name === "RAPUnreleasedClaimAge" ? true : false} >
                <div id="ucaContainer" className="unreleasedClaimAgeContainer" >

                    <div id="unreleasedClaimAgeChart" className="unreleasedClaimAgeChart"></div>
                    <div id="legend" className="claimAgeLegend">
                        <div className="lgd blue"></div>
                        <div className="lgdText"><p>Number of Claims</p></div>
                        <div className="lgd green"></div>
                        <div className="lgdText"><p>Dollar Amount</p></div>
                    </div>
                </div>
            </ARMLandingPageHeaderWrapper>
        </LandingPageComponent>);
    }
    renderError() {
        return (<LandingPageComponent ComponentClass={this.props.Name} {...this.props}>
            <div className="componentHeader" />
            <div className="RAPUnreleasedClaimAgeLoader">
                <ARMComponentError />
            </div>
        </LandingPageComponent>);
    }
    render() {
        if (this.state.data) {
            //console.log('Rendering Claim Age Graph');
            return this.renderGraph();
        } else if (this.state.errorCode) {
            return this.renderError();
        } else if (!this.state.userCanView) {
            return this.renderNoRights();
        } else {
            return this.renderLoading();
        }
    }

}

var connectedHoc = connect(
    mapStatesToProps([
        "drillDownView"
    ]), // Selects which state properties are merged into the component's props
    createMapDispatchToProps([
        "drillDownView"
    ]), // Selects which action creators are merged into the component's props
    mergeComponentProps
)(UnReleasedClaimAge as any); //TypeCasted this as any-- need to change this !Important

export default withRouter(connectedHoc);