import * as React from 'react';
import { RouteComponentProps, Prompt, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Location } from 'history';
import styled from 'styled-components';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { OKCancelButtons, DialogWrapper, ContentColumnWrapper, ContentRowWrapper } from '@common/DialogWrapper';
import { ModalConfirmation, leaveMessage } from '@common/ModalConfirmation';
import CrudTypes from '@commonResources/CrudTypes';
import {pageLeave } from '@commonResources/userModified';
import { ArrowButtons } from '@common/ArrowButtons';
import { ARMNarrowDropdown } from '../../common/UICLWrappers/ARMNarrowDropdown';
import { SelectComponent } from '@common/SelectComponent';
import {
    IMergeCrudComponentProps,
    createCrudMapStateToProps,
    createCrudMapDispatchToProps,
    mergeCrudComponentProps,
    resetCrudComponentState
} from '@scripts/util/CrudComponentHelpers';
import { IFormModalState, defaultFormModalState } from '@store/ui/BaseCrudUI';
import { ApplicationState } from '@store/index';
import {
    IBackfeedConfigUIState,
    IBackfeedConfigActionProps,
    actionCreators,
    validationCallback
} from '@store/BackfeedConfig';
import { defaultBackfeedConfigState, HISField, filterCrudChanges } from '../../../store/ui/BackfeedConfigUI';

const ShowCriteriaSelectionDiv = styled.div`
    display: flex;
    width: 585px;
    
    padding-bottom: 10px;
`

interface IComponentProps {
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IBackfeedConfigProps = IMergeCrudComponentProps<IBackfeedConfigUIState,
    IBackfeedConfigActionProps,
    IOwnProps>;

interface IBackfeedConfigState extends IFormModalState {
    isUpdate: boolean;
    showModal: boolean; 
}



export class BackfeedConfig extends React.PureComponent<IBackfeedConfigProps,
    IBackfeedConfigState> {
    // define only once, instead of on every render
    private instructions = <React.Fragment><div> Select the HIS type and claim form, then move the fields that should be included in your backfeed file to the<i> Included Fields</i> list. </div></React.Fragment>;

    constructor(props: IBackfeedConfigProps) {
        super(props);
        this.state = defaultBackfeedConfigState;
    }


    public componentDidMount() {
        this.props.action.crud.get({ crudId: CrudTypes.mctiHISFieldConfiguration },
            (result: MCHISFieldConfiguration) => {
                if (validationCallback(result)) {
                    this.props.action.ui.initalizeData({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: result,
                    });
                    return true;
                }
            }
        );
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }


  
    public onDialogOk = () => {
        const { dataStore } = this.props;
        this.setState({ navigationConfirmed: true, saveOnNavigate: true },
            () => {
                if (this.props.dataStore.ui) {
                    const updatedCrud = { ...this.props.dataStore.crud }
                    if (updatedCrud.data) {
                        const formsToChange = dataStore.ui.formTypeList.reduce((formList: string[], formType) => {
                            if (formType['@TypeId'] === dataStore.ui.selectedTypeId) {
                                formList.push(formType['@ID']);
                            }
                            return formList;
                        }, [])
                        const fieldUpdatesForAllForms = filterCrudChanges(dataStore.ui.memoizedFields, formsToChange, dataStore.ui.selectedTypeId);
                        console.log(fieldUpdatesForAllForms, 'ALL FORMS UPDATED')
                        updatedCrud.data.HISFieldConfiguration['Changes'] = fieldUpdatesForAllForms?.length > 0 ? {
                            Change: fieldUpdatesForAllForms
                        } : null
                        this.props.action.crud.update(updatedCrud);
                    }
                   this.props.history.push("/LandingPage");
                }
            });
    }




    public onDialogCancel = () => {
        // This will trigger the prompt to open the modal
        if (this.props.dataStore.ui.isDirty) {
            this.props.action.confirm.openConfirm(leaveMessage);
        }
        else {
            pageLeave();
            this.props.history.push('/LandingPage');
        }
    }

    public onModalOk = () => {
        this.setState({ navigationConfirmed: true, saveOnNavigate: false },
            () => {
                this.props.history.push(this.state.navDestination?.pathname || '/LandingPage');
            });
    }

    public onModalClose = () => {
        this.setState({ navigationConfirmed: false, saveOnNavigate: false },
            () => {
                this.props.action.confirm.closeConfirm();
            });
    }

    //methods for UI gestures here

    public validateOnSubmit = () => {
        const { dataStore } = this.props;
        const filteredFieldsByType = dataStore.ui.fieldList.filter(field => field['@TypeId'] === dataStore.ui.selectedTypeId && field['@FormId'] === dataStore.ui.selectedFormId);
        if (filteredFieldsByType.every(field => field['@Included'] === '0')) {
            this.setState({ showModal: true })
        }
        else {
            this.onDialogOk()
        }
    }

    private okCancelButtons = <OKCancelButtons onClickOK={this.validateOnSubmit} onClickCancel={this.onDialogCancel} />;

  

    public onSelectType(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectType(e.target.value);
    }

    public onSelectForm(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectForm(e.target.value);
    }

    // https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
    public showRouteChangeConfirm = (nextLocation: Location) => {
        this.setState({ saveOnNavigate: false, navDestination: nextLocation },
            () => {
                this.props.action.confirm.openConfirm({ message: leaveMessage });
            }
        );
        //suppresses native prompt modal
        return false;
    }

    public getSelectedOptions(e: React.ChangeEvent<HTMLSelectElement>) {
        if (!e.target.options || e.target.options.length === 0) return [];
        let result = [];

        for (let i = 0; i < e.target.options.length; i++) {
            if (e.target.options[i].selected)
                result.push(e.target.options[i].value);
        }
        const itemsToUpdate = result.map((id: string) => {
            return this.props.dataStore.ui.fieldList.find((item: HISField) => {
                return item['@ID'] === id && item['@FormId'] ===
                    this.props.dataStore.ui.selectedFormId && item['@TypeId'] === this.props.dataStore.ui.selectedTypeId
            })
            
        }) || []
        return itemsToUpdate;
    }

    public updateFieldIncludeState(includedState: '1' | '0') {
        this.props.action.ui.updateSelectedFields({ selectedFields: this.props.dataStore.ui.selectedFields, included: includedState });
    }

    public onClickFilterCriteriaToThisProfileArrow() {

    }


    public render() {
        const { isBusy, isDirty, typeList, formTypeList, selectedTypeId, includedFieldRecords, fieldRecords } = this.props.dataStore.ui;
        const typeListRecords = typeList.map(type => { return { text: type['@Name'], value: type['@ID'] } });
        const formListRecords = formTypeList.filter(t => t['@TypeId'] === selectedTypeId)?.map(type => { return { text: type['@Name'], value: type['@ID'] } });
        return (<DialogWrapper title='Backfeed Configuration'
            instruction={this.instructions}
            helpUrl='/Support/Help/HELP_Reports_HISBackfeedSetup.htm'
            buttons={this.okCancelButtons}
            isBusy={isBusy}>
            <Prompt when={isDirty && !this.state.navigationConfirmed} message={this.showRouteChangeConfirm} />
            
            <div>
                <ContentColumnWrapper>
                    <ContentColumnWrapper>
                        <DialogFieldset style={{width: 'auto'}}>
                            <DialogLegend>Type</DialogLegend>
                            <ARMNarrowDropdown
                                className="dropdown"
                                isLabel={true}
                                width="150px"
                                min-width="150px"
                                multiple="false"
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => { this.onSelectType(e) }}
                                records={typeListRecords}
                                optionFields={{
                                    value: "value",
                                    text: "text"
                                }}
                                selectedValue={this.props.dataStore.ui.selectedTypeId}
                            />
                        </DialogFieldset>
                    </ContentColumnWrapper>
                    <ContentColumnWrapper>
                        <div>
                            <DialogFieldset style={{width: 'auto'}}>
                                <DialogLegend>Form</DialogLegend>
                                <ARMNarrowDropdown
                                    className="dropdown"
                                    isLabel={true}
                                    width="150px"
                                    min-width="150px"
                                    multiple="false"
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => { this.onSelectForm(e) }}
                                    records={formListRecords}
                                    optionFields={{
                                        value: "value",
                                        text: "text"
                                    }}
                                    selectedValue={this.props.dataStore.ui.selectedFormId}
                                />
                            </DialogFieldset>
                        </div>
                    </ContentColumnWrapper>
                </ContentColumnWrapper>
            </div>
            <div>
                <ContentRowWrapper>
                    <ContentColumnWrapper>
                        <DialogFieldset style={{width: 'auto', height: '240px', maxHeight: '240px'}}>
                            <DialogLegend>Fields</DialogLegend>
                            <div style={{ width: "300px" }}>
                                <SelectComponent
                                    width='300px'
                                    height="210px"
                                    className="SelectBothFilterCriteria"
                                    multiple="true"
                                    records={fieldRecords.sort((a, b) => (a.text > b.text) ? 1 : -1)}
                                    selectedMultiValue={this.props.dataStore.ui.selectedFields.map(field => field['@ID'])}
                                    optionFields={{
                                        value: "value",
                                        text: "text"
                                    }}
                                //selectedValue={this.props.dataStore.ui.selectedthisProfile['@ID']}
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                       const items = this.getSelectedOptions(e);
                                       this.props.action.ui.selectFields(items);
                                    //}
                                }}
                                />
                            </div>
                        </DialogFieldset>
                    </ContentColumnWrapper>
                    <ContentColumnWrapper style={{ flex: "none" }}>
                        <div style={{ width: "27px" }}>
                            <ContentRowWrapper style={{justifyContent: 'center' }}>
                                <ArrowButtons
                                    paddingTop='105px'
                                    width='27px' //todo - this prop doesn't seem to do anything - must adjust margin instead
                                    margin='0 0 0 0'
                                    gap='10px'
                                    toolTipLeft='Remove User(s) from Selected Group'
                                    toolTipRight='Add User(s) to Selected Group'
                                    onLeftBtnClick={() => {
                                        if (this.props.dataStore.ui.selectedFields.length > 0) {
                                            this.updateFieldIncludeState('0')   }
                                    }}
                                    onRightBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                        if (this.props.dataStore.ui.selectedFields.length > 0) {
                                            this.updateFieldIncludeState('1')

                                        }
                                    }}
                                />
                            </ContentRowWrapper>
                        </div>
                    </ContentColumnWrapper>
                    <ContentColumnWrapper style={{ flex: "none"}}>
                        <DialogFieldset style={{width: 'auto', height: '240px', maxHeight: '240px'}}>
                            <DialogLegend>Included Fields</DialogLegend>
                            <div style={{ width: "300px" }}>
                                <SelectComponent
                                    width="300px"
                                    height='210px'
                                    multiple="true"
                                    selectedMultiValue={this.props.dataStore.ui.selectedFields.map(field => field['@ID'])}
                                    records={includedFieldRecords.sort((a, b) => (a.text > b.text) ? 1 : -1)}
                                    optionFields={{
                                        value: "value",
                                        text: "text"
                                    }}
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        const items = this.getSelectedOptions(e);
                                        this.props.action.ui.selectFields(items); 
                                 }}
                                />
                            </div>
                        </DialogFieldset>
                    </ContentColumnWrapper>
                </ContentRowWrapper>
            </div>
            <ModalConfirmation
                isOpen={this.props.dataStore.confirm.isOpen}
                onModalToggle={this.onModalClose}
                formattedMessage={leaveMessage}
                onConfirm={this.onModalOk} />
            <ModalConfirmation
                isOpen={this.state.showModal}
                alertMode={true}
                formattedMessage={<p> Must include at least one field </p>}
                onModalToggle= {() => { this.setState({showModal: false})}}
            />
        </DialogWrapper>);
    }
}

const connectedHoc = connect<IBackfeedConfigUIState,
    IBackfeedConfigActionProps,
    IOwnProps,
    IBackfeedConfigProps,
    ApplicationState>(
        createCrudMapStateToProps('backfeedConfig'),
        createCrudMapDispatchToProps(actionCreators),
        mergeCrudComponentProps
    )(BackfeedConfig);

export default withRouter(connectedHoc);