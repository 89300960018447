import * as React from 'react';
import { Modal } from '@optum-uicl/ui-core/dist';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components'

const StyledModal = styled(Modal)`
   
    #group-list-modal {
        width: 548px !important;
    }

    * div > .active-content {
         width: 548px !important;
    }

    && div > .active-content {
         width: 548px !important;
    }
`
const ModalBody = styled.div`
    width: 500px;
`;


const ModalWrapper = createGlobalStyle<{ width: string, height: string }>`
    #group-list-modal .active-content{
        width: ${props => props.width}

    }
`;


export interface IModalComponentProps {
    isOpen: boolean;
    component: React.ReactNode;
    onModalToggle: () => void;
    showDefaultClose: false;
    width?: string;

};

export interface IModalComponentState {

};


export class ModalComponent extends React.Component<IModalComponentProps, IModalComponentState> {

    static defaultProps: IModalComponentProps = {
        isOpen: false,
        // TODO: is this supposed to be some specific component 
        // OLD: component: React.Component,
        component: null,
        onModalToggle: () => { },
        showDefaultClose: false
    }

    constructor(props: IModalComponentProps) {
        super(props);
    }

    render() {
        return (
            <>
                <ModalWrapper width={this.props.width ? this.props.width  : '548px'} height={'600px'} />
                <StyledModal
                    domID="group-list-modal"
                    isOpen={this.props.isOpen}
                    showDefaultClose={this.props.showDefaultClose}
                    onModalToggle={this.props.onModalToggle}>
                    <ModalBody>
                        {this.props.component}
                    </ModalBody>          
                </StyledModal>
            </>
           
        )
    }
}