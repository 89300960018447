import { fetch, addTask } from 'domain-task';
import { URLs } from '@commonDevResources/constants';
import { extractTokenFromResponse, setJsonToken } from "@scripts/session/SecurityToken";
import { getRawToken } from '@scripts/session/SecurityToken';


interface UserPermission {
    userPermission: boolean;
}

export interface ILoginModel {
    Username: string;
    Password: string;
    ClientAlias: string;
    LoginType: string;
}

export interface ILoginResult {
    status: number,
    body?: string,
}

export interface ISwitchClientModel {
    ClientAlias: string;
    ClientName: string;
}

export interface ISwitchClientResult {
    status: number,
    body?: string,
}

// async version
export const authCheck = async (privilege: number): Promise<boolean> => {
    let response = await fetch(URLs.api + "/api/data/userrights/userPermission/" + privilege,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        }
    );

    let result = await response.status;
    if (result !== 200)
        return false;

    let rsltBody = await response.json();

    return rsltBody.userPermission;
};

// sync version
export const AuthCheck = (privilege: number): boolean => {

    var xhr = new XMLHttpRequest();
    xhr.open('get', URLs.api + "/api/data/userrights/userPermission/" + privilege, false);
    xhr.setRequestHeader('Authorization', getRawToken());
    xhr.send(null);

    if (xhr.status !== 200)
        return false;

    var data = JSON.parse(xhr.responseText);
    return data.userPermission;
};

export async function submitLogin(loginInfo: ILoginModel): Promise<ILoginResult> {
    const response = await fetch(URLs.api + "/api/data/securityInfo/login",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginInfo)
        });

    const jsonResponse = response.clone();
    const responseText = await response.text();
    const responseJson = responseText ? await jsonResponse.json() : { message: "" };
    const token = extractTokenFromResponse(response as Response);

    if (token != null) {
        // TODO: Handle Mobile Response.
        setJsonToken(token);
        sessionStorage.setItem('Username', loginInfo.Username);
        sessionStorage.setItem('ClientAlias', loginInfo.ClientAlias);
        const loginResult: ILoginResult = { status: response.status };

        //If the login status says to redirect to the Change Password page, pass the appropriate warning message to the page.
        if (response.status === 306) {
            loginResult.body = responseText === "\"Expired\""
                ? "Your password expired. You must change it now."
                : responseText === "\"Reset\""
                ? "Your password was reset. You must change it now."
                : "You must choose a new password now.";
        }

        //If the login status says to redirect to the Password Hints page, pass along the response text to append to the legacy URL.
        if (response.status === 316)
            loginResult.body = responseText;
        
        return loginResult;
    }

    //If the security token is not in the headers, we failed to login and need to display the returned message.
    return {
        status: -1,
        body: responseJson.message ? responseJson.message : responseText.replace(new RegExp("^\"+|\"+$", "g"), '')
    };
}

export async function switchClient(switchClientModel: ISwitchClientModel): Promise<ISwitchClientResult> {
    const response = await fetch(URLs.api + "/api/data/securityinfo/switchClient?newClientAlias=" + switchClientModel.ClientAlias,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `${getRawToken()}`
        },
    });

    const jsonResponse = response.clone();
    const responseText = await response.text();
    const responseJson = responseText ? await jsonResponse.json() : { message: "" };
    const token = extractTokenFromResponse(response as Response);

    if (token != null) {
        setJsonToken(token);
        var responseData = responseText.replace(/\"/g, '');
        // sessionStorage.setItem('Client', responseData); // responseData is Success
        const loginResult: ISwitchClientResult = { status: response.status, body: responseText };

        if (response.status === 200) {
            sessionStorage.setItem('Client', switchClientModel.ClientName);
            sessionStorage.setItem('ClientAlias', switchClientModel.ClientAlias);
        }

        if (response.status === 306) {
            loginResult.body = responseText === "\"Expired\""
                ? "Your password expired. You must change it now."
                : responseText === "\"Reset\""
                    ? "Your password was reset. You must change it now."
                    : "You must choose a new password now.";
        }

        if (response.status === 316)
            loginResult.body = responseText;

        return loginResult;
    }

    return {
        status: response.status,
        body: responseJson.message ? responseJson.message : responseText.replace(new RegExp("^\"+|\"+$", "g"), '')
    };

}

export const logoutUser = async (): Promise<boolean> => {
    let response = await fetch(URLs.api + "/api/data/logoutUser",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        }
    );

    let result = await response.status;
    if (result !== 200)
        return false;

    return true;
};

// sync version - worried about timing issues...would really likethe old wiped out before logging in
export const syncLogoutUser = (): boolean => {
    try {
        var xhr = new XMLHttpRequest();
        xhr.open('post', URLs.api + "/api/data/logoutUser", false);
        xhr.send(null);

        if (xhr.status !== 200)
            return false;
    } catch (e) {

    }


    return true;
};