import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Modal, LoadingIndicator, Grid } from '@optum-uicl/ui-core/dist';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled, { createGlobalStyle } from 'styled-components';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { SelectComponent } from '@common/SelectComponent';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { Input } from '@common/UICLWrappers/ARMInput';

import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { defaultState as defaultUIState } from '@store/ui/AcceptRemitsUI';
import { IAcceptRemitsState, IAcceptRemitsActions, IAcceptRemitsActionProps, actionCreators } from '@store/AcceptRemits';
import { Label } from 'react-bootstrap';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';
import { ColumnType } from '@optum-uicl/ui-core/dist/Organisms/Grid/types';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin-bottom: 5px;
    .Date {
        vertical-align: inherit;
    }
    .selectDropdown {
        vertical-align: inherit;
    }
    th div, th button, .sorted {
        text-transform: capitalize;
        font-size: 11px !important;
        font-family: ;
    }
    th button svg {
        min-width: 0px;
    }
    th button div {
        width: 32px !important;
    }
    tr.empty-row {
        background-color: rgb(235, 236, 237) !important;
        td{
            text-align: left !important;
        }
    }
    td div[class*="TextCell"] {
        font-size: 12px;
    }
`;

const GlobalStyles = createGlobalStyle` 
       [id^="select-dropdown-"] > div:first-of-type 
       {
        width: 130px !important;
} 
[id^="select-dropdown-"] #Rejected, [id^="select-dropdown-"] #Accepted 
{ 
    padding: 8px 24px 8px 10px !important;
    }
    `;

export const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;

    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

interface IComponentProps {
};

interface IComponentState {
    cancelLeave: boolean
};

export const DEFAULT_STATE: IComponentState = { cancelLeave: false };

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IAcceptRemitsProps = IMergeCrudComponentProps<IAcceptRemitsState, IAcceptRemitsActionProps, IOwnProps>;

class Options {

    static _option: any = [{ label: "Process", value: "Accepted" }, { label: "Reject", value: "Rejected" }];

    public static getOptions() {
        return this._option;
    }
}

export class AcceptRemits extends React.Component<IAcceptRemitsProps, IComponentState> {

    static defaultProps: IComponentProps = {
    };

    constructor(props: IAcceptRemitsProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        pageLeave();
        this.props.action.crud.get({ crudId: CrudTypes.mctiAcceptRemits });
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public onSelectRemit(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectRemit(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    value: e.target.value,
                    text: e.target.options[e.target.options.selectedIndex].textContent
                }
            });
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        console.log(JSON.stringify(this.props.dataStore.crud));
        let remitHasStatus: boolean = false;
        let remits: MCRemit[] = this.getRemitCrudRecords();
        for (let index = 0; index < remits.length; index++) {
            if (remits[index]["@State"] !== "") {
                remitHasStatus = true;
                break;
            }
        }

        if (remitHasStatus) {
            this.props.action.crud.updateWithDelta(this.props.dataStore.crud);
            this.props.history.push("/LandingPage");
        } else {
            this.props.action.confirm.openConfirm("Please select pending payments to process or reject.");
        }
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    getRemitGridColumnHeaders() {
        return new Set([
            {
                dataName: "Date",
                text: 'Date',
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "LOBPayer",
                text: "LOB & Payer",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "ProviderNumber",
                text: "Provider No",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Amount",
                text: "Amount",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "selectDropdown",
                text: "Status",
                sortable: false,
                cellType: 'selectDropdown',
                isSorted: 0,
            }
        ]);
    }

    getRemitCrudRecords() {
        let data: MCRemit[] = [{
            '@ID': '',
            "@Date": '',
            "@LobID": '',
            "@ProviderNumber": '',
            "@LobPayer": '',
            "@PayerID": '',
            "@Amount": '',
            "@State": '',
        }];

        if (this.props.dataStore.crud &&
            this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.RemitList &&
            this.props.dataStore.crud.data.RemitList.Remit) {
            data = this.props.dataStore.crud.data ? data.concat(this.props.dataStore.crud.data.RemitList.Remit) : data;
        }

        return data;
    }

    getRemitGridRecords(remit: MCRemit, index: number) {
        if (!remit)
            return;

        return {
            Date: remit["@Date"],
            LOBPayer: remit["@LobPayer"],
            ProviderNumber: remit["@ProviderNumber"],
            Amount: remit["@Amount"],
            selectDropdown: {
                domID: 'select-dropdown-' + index.toString(),
                options: Options.getOptions(),
                onChange: (e: any) => {
                    handleChange()
                    remit["@State"] = e?.value;
                },
                noInitialValue: false,
                onBlur: () => false,
                onFocus: () => false,
                initialValue: remit["@State"],
                className: 'select-dropdown-cell',
            },
        };
    }

    buildRemitGridData(remits: MCRemit[]) {
        let records = [];

        if (remits && remits.length > 1) {
            for (let index = 0; index < remits.length; index++) {
                if (remits[index]["@ID"] !== "") {
                    records.push(this.getRemitGridRecords(remits[index], index));
                }
            }
        }

        return records;
    }

    onToggleConfirmation(e: React.MouseEvent<HTMLElement>) {
        this.props.action.confirm.closeConfirm();
    }

    public onClickConfirm(e: React.MouseEvent<HTMLButtonElement>) {
        this.props.dataStore.confirm.confirmCallback();
    }

    public render() {
        var width: React.CSSProperties = {
            width: '300px'
        }

        var instruction = <React.Fragment>Use this page to create secondary claims from payments. To ignore a payment for now, leave the Status dropdown box empty. To create secondary claims from the payment, select Accept in the dropdown. To remove the payment from the list, select Reject.<br /><b>Rejected payments cannot be used later to create secondary claims.</b></React.Fragment>;
        var buttons = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;

        let remits: MCRemit[] = this.getRemitCrudRecords();

        return (
            <DialogWrapper title="Process Pending Payments" instruction={instruction} helpUrl='/Support/Help/HELP_RMTAccept.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper> 
                    <GlobalStyles/>
                    <DialogFieldset height='auto'>
                        <Grid
                            dom-ID="remit-id"
                            onClickThrough={function noRefCheck() { }}
                            //width='300px'
                            //height='100%'
                            className='remit-grid'
                            columns={this.getRemitGridColumnHeaders() as Set<ColumnType>}
                            records={this.buildRemitGridData(remits)}
                            selectionKey='empty'
                        />
                    </DialogFieldset>
                </ContentWrapper>
                <ModalConfirmation
                    isOpen={this.props.dataStore.confirm.isOpen}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleConfirmation(e)}
                    message={this.props.dataStore.confirm.message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClickConfirm(e)}
                    alertMode={true}
                />
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IAcceptRemitsState, IAcceptRemitsActionProps, IOwnProps, IAcceptRemitsProps, ApplicationState>(
    createCrudMapStateToProps('acceptRemits'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(AcceptRemits);

export default withRouter(connectedHoc);