import * as React from 'react';
import { Input, SelectDropdown } from '@optum-uicl/ui-core/dist';
import styled from 'styled-components';
import { SelectDropdownValue } from '@optum-uicl/ui-core/dist/Atoms/SelectDropdown/types';

//-----------------
// PAGE NOTES
// This component represents a user-selected security question and a user-defined answer
// in the User Password Hints page. Used this as an easy class to tie question, answer, and
// trivial UI/styling code together as well as to encapsulate a number of other defaults 
// values, logic, and styling. Basically, throwing everything up onesie-twosie in UPH was
// making it too bulky to work with.

//-----------------
//STYLES - Put custom style elements up here.
const SecurityQuestionAnswerWrapper = styled.div`
    padding-bottom: 1.155rem;
    padding-top: 0.25rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
`;

const Spacer = styled.div`
    height: 0.25rem;
`;

//-----------------
// OBJECT DEFINITIONS - Put any interface or small class definitions here.
export interface DropdownOption {
    label: string,
    value: string,
    index: number;
}

//-----------------
// STATE AND PROPS - Define the local component state and any component props here.
interface IComponentState { }

interface IComponentProps {
    questionNumber: number,
    questionErrorMessage?: string,
    answerErrorMessage?: string,
    initialQuestion: DropdownOption,
    initialAnswer: string,
    questions: DropdownOption[],
    onQuestionChange(selectedOption: DropdownOption): void,
    onAnswerChange(newAnswer: string): void,
    lastQuestion?: boolean,
}

//-----------------
// DEFAULTS - Define any default object values (including the state) here.
export const DEFAULT_STATE: IComponentState = {
};

//-----------------
// COMPONENT CLASS - Define the component class here.
export class SecurityQuestionAnswer extends React.Component<IComponentProps, IComponentState> {
    static defaultProps: IComponentProps = {
        questionNumber: 0,
        initialQuestion: { label: "- Select -", value: "Select a Question", index: 0 },
        initialAnswer: "",
        questions: [],
        onQuestionChange() {
        },
        onAnswerChange() {
        },
    };

    constructor(props: IComponentProps) {
        super(props);
        this.state = { ...DEFAULT_STATE };
    }

    // NOTE:  if i could render i'd fix this properly in the parent class
    private convertSelectDropdownValueToDropdownOption(selectedOption: SelectDropdownValue): DropdownOption {
        return { label: selectedOption?.label ?? "", value: selectedOption?.value ?? "", index: Number(selectedOption?.id ?? -1) };
    }

    private convertDropdownOptionToSelectDropdown(selectedOption: DropdownOption): SelectDropdownValue {
        return  { label: selectedOption?.label, value: selectedOption?.value, id: String(selectedOption?.index ?? "")};
    }

    private convertDropdownOptionsToSelectDropdownValues(questions: DropdownOption[]): SelectDropdownValue[] {
        let newData: SelectDropdownValue[] = [];

        for (var i = 0; i < questions.length; i++) {           
            newData.push(this.convertDropdownOptionToSelectDropdown(this.props.questions[i]));
        }

        return newData;
    }

    public render() {
        const reduceBottomPaddingOnLast = this.props.lastQuestion
            ? { paddingBottom: '0.4rem', paddingTop: '0.25rem', paddingRight: '0.4rem', paddingLeft: '0.4rem' }
            : {};
        let initValConverted: SelectDropdownValue = { label: this.props.initialQuestion?.label, value: this.props.initialQuestion?.value, id: String(this.props.initialQuestion?.index ?? "") };
        let questionsConverted: SelectDropdownValue[] = this.convertDropdownOptionsToSelectDropdownValues(this.props.questions);

        return (
            <SecurityQuestionAnswerWrapper style={reduceBottomPaddingOnLast}>
                <SelectDropdown
                    domID={"SecurityQuestion" + this.props.questionNumber}
                    label={"Security Question " + this.props.questionNumber}
                    errorMessage={this.props.questionErrorMessage}
                    hasError={!!this.props.questionErrorMessage && Boolean(this.props.questionErrorMessage.trim())}
                    initialValue={initValConverted}
                    options={questionsConverted}
                    isSearchable={false}
                    isClearable={false}
                    onChange={(selectedOption: SelectDropdownValue) => { this.props.onQuestionChange(this.convertSelectDropdownValueToDropdownOption(selectedOption)) }} />
                <Spacer />
                <Input
                    domID={"SecurityAnswer" + this.props.questionNumber}
                    autoComplete='off'
                    placeholder={"Answer to Question " + this.props.questionNumber}
                    type="text"
                    hasError={!!this.props.answerErrorMessage && Boolean(this.props.answerErrorMessage.trim())}
                    errorMessage={this.props.answerErrorMessage}
                    label={"Answer " + this.props.questionNumber}
                    initialValue={this.props.initialAnswer}
                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.onAnswerChange(e.target.value)} />
            </ SecurityQuestionAnswerWrapper>
        );
    }
};