import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as EligibilityProfileUI from './ui/EligibilityProfileUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray } from '@scripts/util/ValidationHelpers';
import { ClientPayersPayer, EligibilityPayersPayer, MCEligibilityPayerProfiles, MCEligibilityProfileType, PayerProfile } from '../scripts/@types/MasterCrud/EligibilityProfile';

export type IEligibilityProfileState = ICrudComponentState<MCEligibilityProfileType, EligibilityProfileUI.IEligibilityProfileUIState>;

export const actionCreators = createCrudComponentActions<EligibilityProfileUI.ActionCreators>(EligibilityProfileUI.actionCreators);
export type IEligibilityProfileActions = typeof actionCreators;
export type IEligibilityProfileActionProps = ICrudComponentActionProps<IEligibilityProfileActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiEligibilityPayerProfile, EligibilityProfileUI.reducer);
export const reducer = combineReducers<IEligibilityProfileState>(reducers);

export const validationCallback: ValidationCallback<MCEligibilityProfileType> = (crud: any): crud is MCEligibilityProfileType => {
    let testCrud: MCEligibilityProfileType = crud as MCEligibilityProfileType;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCEligibilityProfileType");
    if (validShape) {
        
        // Eligibility Payers Validation
        if (testCrud.EligibilityPayerMaintenanceInfo.EligibilityPayers as any === "") {
            testCrud.EligibilityPayerMaintenanceInfo.EligibilityPayers = { Payer: [] };
        }
        if (testCrud.EligibilityPayerMaintenanceInfo.EligibilityPayers !== undefined){
            VerifyArray(testCrud.EligibilityPayerMaintenanceInfo.EligibilityPayers, "Payer");
        }

        // Eligibility Payer Profile Validation
        if (testCrud.EligibilityPayerMaintenanceInfo.EligibilityPayerProfiles as any === "") {
            testCrud.EligibilityPayerMaintenanceInfo.EligibilityPayerProfiles = { PayerProfile: [] };
        }

        if(testCrud.EligibilityPayerMaintenanceInfo.EligibilityPayerProfiles !== undefined){
            VerifyArray(testCrud.EligibilityPayerMaintenanceInfo.EligibilityPayerProfiles, "PayerProfile");
        }

        // Client Payer Validation
        if (testCrud.EligibilityPayerMaintenanceInfo.ClientPayers as any === "") {
            testCrud.EligibilityPayerMaintenanceInfo.ClientPayers = { Payer: [] };
        }

        if(testCrud.EligibilityPayerMaintenanceInfo.ClientPayers){
            VerifyArray(testCrud.EligibilityPayerMaintenanceInfo.ClientPayers, "Payer");
        }
        
    }

    return validShape;
}
