import * as React from 'react';

import { CheckBoxComponent } from '@common/CheckBox';
import styled from 'styled-components';

interface IComponentProps {
    sortBy?: string;
    index?: number;
    styleIt?: any;
    nameId?: string | undefined;
    keyOf?: string | undefined;
    postLabel?: string | undefined;
    disableIt?: string | undefined;
    width?: string;
    className?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    checked?:boolean;
};

interface IWrapperProps {
    styleIt?: any;
    hideIt?: boolean;
};

interface IComponentState {
    checked: boolean;
};

const CustomInputCheckboxWrapper = styled.div`
    ${(props: IWrapperProps) => props?.styleIt?.visibility && `visibility: hidden;`};
    ${(props: IWrapperProps) => props.styleIt && `max-width: 26px; `  };

    ${(props: IWrapperProps) => props.styleIt && `background-color: #f5b700; `  };
    ${(props: IWrapperProps) => props.styleIt && `padding: 2px; width: 22px; height: 22px; `  };


    * input[type='checkbox'] {
        margin: 2px 1px 2px 2px;
        
    }

    input[type='checkbox']:hover::before, input[type='checkbox']:focus::before, input[type='checkbox']:active::before{
        ${(props: IWrapperProps) => props.styleIt && `box-shadow: rgb(255, 211, 82) 0px 0px 0px 2px, rgb(12, 85, 184) 0px 0px 0px 5px;`
    }

    

`;

/*



        let divstyle : any = { input[type='checkbox'] };
    else if (this.props.styleIt)
        divstyle={ backgroundColor: "#ffd352", padding: "2px", maxWidth: "26px" };
*/

export default class CustomInputCheckbox extends React.Component<IComponentProps, IComponentState> {
    checkID: string;
    disableCheckbox: boolean = false;
    hideIt: boolean = false;
    cbName: string = 'checkbox';
    newKey: string = '';
    newLabel: string = '';

    constructor(props: IComponentProps) {
        super(props);

        this.checkID = 'ops-input-' + this.props.index;

        if (this.props.disableIt && this.props.disableIt?.length > 0) {
            if (this.props.disableIt == '0')
                this.disableCheckbox = true;
        }

        if (this.props.styleIt && this.props.styleIt?.visibility)
            this.hideIt = this.props.styleIt.visibility == 'hidden' ? true : false; // need conditional css...

        if (this.props.nameId && this.props.nameId?.length > 0) {
            this.cbName = this.props.nameId + '-checkbox';
        }

        if (this.props.keyOf && this.props.keyOf?.length > 0) {
            this.newKey = this.props.keyOf;
        }

        if (this.props.postLabel && this.props.postLabel?.length > 0) {
            this.newLabel = this.props.postLabel;
        }


        this.state = {      
            checked: false,
        };

    }


    

    render() {



        return(
            <CustomInputCheckboxWrapper
                styleIt={this.props.styleIt}
                hideIt={this.hideIt}
            >
            <CheckBoxComponent
                id={this.checkID}
                    name={this.cbName}
                    sizeValue={'small' }
                label={this.newLabel ? this.newLabel : ''}
                className={this.props.className && this.props.className.length > 0 ? this.props.className : ''}
                checked={this.props.checked?this.props.checked:this.state.checked}
                disabled={this.disableCheckbox}
                width={this.props.width && this.props.width.length > 0 ? this.props.width : '44px'}
                //  key={this.newKey}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.props.onChange ? this.props.onChange(e) :
                        this.setState({
                            checked: e.target?.checked ?? false
                        })}
                {...(this.newKey && this.newKey.length > 0 ? { key: this.newKey } : {})}
                />
            </CustomInputCheckboxWrapper>
        );

    }
}



