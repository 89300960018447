import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { bindAll } from "lodash";
import styled from "styled-components";
import { IMergeCrudComponentProps } from "@scripts/util/CrudComponentHelpers";
import { IFormModalState } from "../../../store/ui/BaseCrudUI";
import {
  IClaimOptionsUIState,
  IClaimOptionsActionProps,
} from "@store/ClaimOptions";
import {
  IMNPayerSelectionData,
  IPayerSelectionUpdateRequest,
  IMNPayerSelectionLocalState,
  IMNPolicySelectionData,
  IMNPolicySelectionUpdateRequest,
  IMNPolicySelectionLocalState,
  IMNGeneralOptionsData,
  IModifyClaimOptions,
  MNSearchRequest,
  IGeneralUpdateRequest,
  IMNGeneralOptionsLocalState,
} from "../../../store/ui/ClaimOptionsUI";

import MedNecPayerSelectionOptions from "./MedNecPayerSelectionOptions";
import MedNecPolicySelectionOptions from "./MedNecPolicySelectionOptions";
import MedNecGeneralSelectionOptions from "./MedNecGeneralSelectionOptions";
import { RadioListComponent } from "@common/RadioListComponent";
import { CenteredContent, DialogInstruction } from "@common/DialogWrapper";
interface IContentWrapperProps {
  height: string;
}

const ContentWrapper = styled.div<IContentWrapperProps>`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: ${(p) => `${p.height}`};
`;

export const GridWrapper = styled.div`
  width: inherit;
  overflow-y: hidden !important;

  .selectedRow {
    background-color: yellow !important;
  }

  .unselectedRow {
    background-color: white;
  }

  #grid-wrapper-claim-list-grid {
    height: 200px;

    table tbody tr > td:first-child:not(.select-checkbox) {
      padding-left: 30px;
    }

    table > thead > tr > th {
      /*
            height:32px;
            background-color:#9BA1A9 !important;
            font-weight:bold;
            font-family: 'optum-sans-regular';
            position:sticky;
            top:0;
*/
    }

    #thPayer {
      padding-bottom: 3.5px;
    }

    table tbody tr > td {
      height: 32px !important;
      padding-top: 8px;
    }
  }

  table tbody tr:hover {
    background-color: #edf9ff !important;
    cursor: pointer !important;
  }

  max-height: 500px;
  overflow-y: scroll;
  background-color: white;
  border: black 1px solid;

  .empty-row {
    background-color: white !important;
  }

  .patientName {
    color: red;
    min-width: 250px;
    max-width: 250px;
    overflow: hidden;
  }

  .controlNo {
    min-width: 250px;
    max-width: 250px;
  }

  .totalCharges {
    min-width: 150px;
    max-width: 150px;
  }

  .typeOfBill {
    min-width: 80px;
    max-width: 80px;
  }

  .formName {
    min-width: 80px;
    max-width: 80px;
  }

  .payerName {
    width: 300px;
    overflow: hidden;
  }

  table tbody tr > td {
    height: 25px !important;
  }

  table tbody tr td div {
    min-height: 1px;
  }
`;

interface IComponentProps {
  canView: boolean;
  canEdit: boolean;
  canDelete: boolean;

  displayAlertMessage(msg: string): void;

  radioIndex: number;
  updateRadioIndex(index: number): void;

  generalOptions: IMNGeneralOptionsData;
  updateMNGeneralOptions(request: IGeneralUpdateRequest): void;
  generalOptionsLocalState?: IMNGeneralOptionsLocalState;
  onGeneralOptionsLocalStateChange(request: IMNGeneralOptionsLocalState): void;

  payerSelectionOptions: IMNPayerSelectionData;
  updateMNPayerSelectionOptions(request: IPayerSelectionUpdateRequest): void;
  payerSelectionLocalState?: IMNPayerSelectionLocalState;
  onPayerSelectionLocalStateChange(request: IMNPayerSelectionLocalState): void;
  getFiData(): void;
  policySelectionOptions: IMNPolicySelectionData;
  updateMNPolicySelectionOptions(
    request: IMNPolicySelectionUpdateRequest
  ): void;
  searchMNPolicyDescription(request: MNSearchRequest): void;
  onPolicySelectionLocalStateChange(
    request: IMNPolicySelectionLocalState
  ): void;
}

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IClaimOptionsProps = IMergeCrudComponentProps<
  IClaimOptionsUIState,
  IClaimOptionsActionProps,
  IOwnProps
>;

interface IComponentState extends IFormModalState {
  //   records: any;
}

export class MedicalNecessityOptions extends React.Component<
  IClaimOptionsProps,
  IComponentState
> {
  constructor(props: IClaimOptionsProps) {
    super(props);
    //   this.state = {
    //       records: this.props.dataStore.crud.data?.ClaimOptionsMaintenanceInfo.MNClientPolicySeverities.MNClientPolicySeverity
    //   }
    bindAll(this, [
      /*methods here*/
    ]);
  }
  // define only once, instead of on every render

  // for some reason manual bind and bindAll did not work with these functions, so using alternate syntax

  isBusy = () => {
    return this.props.dataStore.crud.dataStatus === "REQUEST";
  };

  public onSelectTab(e: React.MouseEvent<HTMLButtonElement>, selObj: any) {
    this.props.updateRadioIndex(selObj.radioIndex);
  }

  public renderContent(index: number) {
    switch (index) {
      case 0:
        return (
          <MedNecGeneralSelectionOptions
            canView={this.props.canView}
            canEdit={this.props.canEdit}
            canDelete={this.props.canDelete}
            generalOptions={this.props.generalOptions}
            updateMNGeneralOptions={this.props.updateMNGeneralOptions}
            displayAlertMessage={this.props.displayAlertMessage}
            localState={this.props.generalOptionsLocalState}
            onLocalStateChange={this.props.onGeneralOptionsLocalStateChange}
          />
        );

      case 1:
        return (
          <div>
            <MedNecPayerSelectionOptions
              canView={this.props.canView}
              canEdit={this.props.canEdit}
              canDelete={this.props.canDelete}
              payerSelectionOptions={this.props.payerSelectionOptions}
              updateMNPayerSelectionOptions={
                this.props.updateMNPayerSelectionOptions
              }
              getFiData={this.props.getFiData }
              displayAlertMessage={this.props.displayAlertMessage}
              localState={this.props.payerSelectionLocalState}
              onLocalStateChange={this.props.onPayerSelectionLocalStateChange}
            />
          </div>
        );
      case 2:
        return (
          <MedNecPolicySelectionOptions
            policySelectionOptions={this.props.policySelectionOptions}
            updateMNPolicySelectionOptions={
              this.props.updateMNPolicySelectionOptions
            }
            searchMNPolicyDescription={this.props.searchMNPolicyDescription}
            onLocalStateChange={this.props.onPolicySelectionLocalStateChange}
          />
        );

      default:
        return (
          <div>
            <p> radio 1 </p>
          </div>
        );
    }
  }

  public setRadioIndexState(selectedIndex: number) {
    this.props.updateRadioIndex(selectedIndex);
  }

  private instructions = (
    <React.Fragment>
      <span className="instructionRed"> Note! </span> The payer lists on the
      Medical Necessity and CCI tab may take a moment to load due to the
      extensive list of payers.
    </React.Fragment>
  );

  public render() {
    const { radioIndex } = this.props;
    const { /*props*/ isDirty } = this.props.dataStore.ui;
    return (
      <ContentWrapper height="600px">
        <RadioListComponent
          dialogTitle={"Configuration"}
          selectedIndex={radioIndex}
          hasDialog={true}
          setRadioSelectedState={this.setRadioIndexState.bind(this)}
          radioListItems={[
            {
              domID: "general-type",
              label: "General",
              disabled: false,
              index: 0,
            },
            {
              domID: "payer-type",
              label: "Payer Selection",
              disabled: false,
              index: 1,
            },
            {
              domID: "policy-type",
              label: "Policy Selection",
              disabled: false,
              index: 2,
            },
          ]}
          width={"45%"}
        >
          {this.renderContent(radioIndex)}
        </RadioListComponent>

        <CenteredContent>
          <DialogInstruction>{this.instructions}</DialogInstruction>
        </CenteredContent>
      </ContentWrapper>
    );
  }
}
