import * as React from 'react';
import { Colors } from '../../../commonResources/colorVariables';
import styled from 'styled-components';

const ARMCopyrightStyle = styled.div`
    padding-bottom: 5px;
    font-size: 12px;
    display: flex;
    justify-content:center;
    margin-top:2px;
`;

interface IComponentProps {
};

export class ARMCopyright extends React.PureComponent<IComponentProps> {
    constructor(props: IComponentProps) {
        super(props);
    }

    public render() {
        return (
            <ARMCopyrightStyle>
                &copy; {new Date().getFullYear()} Optum, Inc. All rights reserved.
            </ARMCopyrightStyle>
        );
    }
};
