import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Input, SelectDropdown, TextStyles } from '@optum-uicl/ui-core/dist';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';

import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { SelectComponent } from '@common/SelectComponent';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IFileTypeState, IFileTypeActionProps, actionCreators, validationCallback } from '@store/FileType';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { pageLeave } from '@commonResources/userModified';


export const ContentRowWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const ContentColumnWrapper = styled.div`
    display: flex;
    flex: 1 0.7 auto;
    flex-direction: column;
    margin:5px;
    gap:5px;

    .text-input {
        width: 230px;
    }

    .text-input > label {
        text-align:left;
    }
    label{
        text-transform:uppercase;
    }
`;

export const DialogLabel = styled.div`
    padding-left: 15px;
    padding-top: 2px;
    color: #37474F;
    font-family: 'optum-sans-regular';
    font-size: 11px;
    font-weight: 600;
`;

interface IComponentProps {
    secCheck1: boolean,
    canEdit: boolean,
    canView: boolean,
    title: string,
    isPrinterDisabled: boolean,
    updating: boolean,
};

interface IComponentState {
    updating: boolean, localTextInput?: string, blurred: boolean 
 }
export const DEFAULT_STATE: IComponentState = { updating: true, localTextInput: '', blurred: false };

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IFileTypeProps = IMergeCrudComponentProps<IFileTypeState, IFileTypeActionProps, IOwnProps>;

/*
 * class definition...
 *
 */
export class FileType extends React.Component<IFileTypeProps, IComponentState> {

    static defaultProps: IComponentProps = {
        secCheck1: false, //AuthCheck(SecurityBits.FN_VIEW_FILEDOWNLOAD),
        canEdit: false,//  AuthCheck(SecurityBits.FN_VIEW_FILEDOWNLOAD),
        canView: false, // AuthCheck(SecurityBits.FN_VIEW_FILEDOWNLOAD),
        title: "Auto Print File Type",
        isPrinterDisabled: true,
        updating: true,
    };

    constructor(props: IFileTypeProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    private setInitialLoad() {
        // debuke says: NO!  we can't leave this timeout in:
        //              the problem is that the data isn't coming until the "componentDidMount" and 
        //              it's not returning fast enough to select the first data element...
        setTimeout(() => {
            this.props.action.ui.selectFileType(
                {
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        index: 0,
                        id: '',
                        name: ''
                    }
                });
            this.setState({ updating: false, localTextInput: this.props.dataStore.ui.selectedDestination?.toLowerCase() });
        }, 4000);
    }

    public componentWillMount() {
        // console.log("FileType: WILL Mount");
        this.setInitialLoad();
    }

    public componentDidMount() {
        pageLeave();
        this.props.action.crud.get({ crudId: CrudTypes.mctiFileTypeConfig }, validationCallback);

        console.log("FileType : DID Mount");
    }

     public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public componentDidUpdate() {
        if (this.state.localTextInput?.endsWith(' ') && this.state.blurred){
            this.setState({localTextInput: this.state.localTextInput.slice().trim(), blurred: false})
        }
    }

    public getFileTypeActionArray() {
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.ReportActionList ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.ReportActionList.ReportAction)
            return [];

        let result: any[] = [];
        this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.ReportActionList.ReportAction.forEach(ob => {
                if (ob['@Name'])
                    result.push({ label: ob['@Name'], value: ob['@ID'] });
            });
        return result;
    }

    public getFileTypePrinterArray() {
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.PrinterList ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.PrinterList.Printer)
            return [];

            let result: any[] = [];
            this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.PrinterList.Printer.forEach(ob => {
                if (ob['@Name']) {
                    console.log('ob[@Name]: ' + ob['@Name'] + ' ob[@ID] ' + ob['@ID']);
                    result.push({ label: ob['@Name'], value: ob['@ID'] });
                }
            });
    return result;
    }

    public getInitialActionSelectedItem() {  //: IOptionListItem
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.ReportActionList ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.ReportActionList.ReportAction)
            return { value: '', label: '' };

        let result = { value: '', label: '' };
        let item = this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.ReportActionList.ReportAction.find(ob => ob['@ID'] === this.props.dataStore.ui.selectedActionItem["@ID"]);
        if (item) {
            result.label = item['@Name'];
            result.value = item['@ID'];
        } else {
            result.label = this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.ReportActionList.ReportAction[0]['@Name'];
            result.value = this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.ReportActionList.ReportAction[0]['@ID'];
        }
        return result;
    }

    public getInitialPrinterSelectedItem() {
        let result = { value: '- Select Printer -', label: '- Select Printer -' };

        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.PrinterList ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.PrinterList.Printer ||
            !this.props.dataStore.ui.selectedPrinterItem)
            return result;

            let item = this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.PrinterList.Printer.find(ob => ob['@ID'] === this.props.dataStore.ui.selectedPrinterItem["@ID"]);
            if (item) {
                result = { value: item['@ID'], label: item['@Name'] }
            }
        
        return result;
    }

    private getDestination(id: number): string {
        let dest: string = '';
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.FileSettings ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.FileSettings.FileSetting ||
            id === undefined || id < 0 ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.FileSettings.FileSetting[id] ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.FileSettings.FileSetting[id]['@Destination']) {
            return dest;
        }
        return this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.FileSettings.FileSetting[id]['@Destination'];
    }

    public onSelectFileType(e: React.ChangeEvent<HTMLSelectElement>) {
        if (this.props.dataStore.ui.selectedPrinterItem['@Name'] === '' && !this.getPrinterEnableStatus()) {
            this.props.action.ui.errorPrinterField({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    id: "printerItemError", value: 'Please select a printer.'
                }
            });
            this.props.action.ui.openPrinterRequiredModal({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: 'true'
                }
            });
        } else {
            this.props.action.ui.selectFileType(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    name: e.target.options[e.target.options.selectedIndex].textContent
                }
                });
            this.setState({ localTextInput: this.getDestination(e.target.options.selectedIndex).toLowerCase() });
        }       
    }

    public updateAction_TriggerEvent(val: string) {
        this.props.action.ui.selectFileTypeAction(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value:val
                }
            });
    }

    public updateFileType_PrinterEvent(val: string) {
        if (val) {
            this.props.action.ui.errorPrinterField({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    id: "printerItemError", value: ''
                }
            });
        }
        this.props.action.ui.selectFileTypePrinter(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: val
                }
            });
    }

    public getPrinterEnableStatus() {
        let actionId = this.props.dataStore.ui.selectedActionItem;
        return (actionId && actionId['@Name'].indexOf('Print') > -1) ? false : true;
    }

    public getDestinationEnableStatus() {
        let actionId = this.props.dataStore.ui.selectedActionItem;
        return (actionId && actionId['@Name'].indexOf('Download') > -1) ? false : true;
    }
    public updateFileType_Destination(val: string) {
        this.props.action.ui.updateFileTypeDestination({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: (val && val.length > 0 ? val.toUpperCase() : '').trim()
            }
        });
    }
    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.props.dataStore.ui.selectedPrinterItem['@Name'] === '' && !this.getPrinterEnableStatus()) {
            this.props.action.ui.errorPrinterField({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    id: "printerItemError", value: 'Please select a printer.'
                }
            });
        } else {
            this.props.action.ui.updateCrudData({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: ''
                }
            });
            this.props.action.crud.update({ crudId: CrudTypes.mctiFileTypeConfig, data: this.props.dataStore.crud.data });
            this.props.history.push('/LandingPage');
        }        
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push("/LandingPage");
    }

    public isPrinterRequiredCheck() {
        if (this.props.dataStore.ui.selectedPrinterItem['@Name'] === '' && !this.getPrinterEnableStatus()) {
            this.props.action.ui.errorPrinterField({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    id: "printerItemError", value: 'Please select a printer.'
                }
            });
            this.props.action.ui.openPrinterRequiredModal({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: 'true'
                }
            });
        }
    }

    public isPrinterRequiredModalClose(e: any) {
        this.props.action.ui.openPrinterRequiredModal({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: 'false'
            }
        });
    }

    public moveCursorToEnd(id: any) {
        var el: any = document.getElementById(id)
        let trimmedEl = el.value.trim();
        el.focus()
        if (el.value.endsWith(' ')) {
            var len = trimmedEl.length;
            if (el.setSelectionRange && el.selectionStart > len) {
                el.focus();
                el.setSelectionRange(len, len);
            } else if (el.createTextRange) {
                var t = el.createTextRange();
                t.collapse(true);
                t.moveEnd('character', len);
                t.moveStart('character', len);
                t.select();

            }
        }
    }

    public trimOnBlur(id: string) {
        let el: any = document.getElementById(id)
        if (el.value.endsWith(' ')) {
            el.value = el.value.slice().trim();
            let newVal = el.value.slice().trim();
            el.setAttribute('value', newVal)
        }
        el.blur()
        this.setState({blurred: true})
    }


    public render()
    {
        var helpButtons = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        var instruction = <React.Fragment>Use the Sweep page first, then this page. On this page, select a file type. Select an action. If printing, enter path to a printer. To override the default destination for the selected file, enter another path in Destination. Click OK to save.</React.Fragment>;
   
        var fileTypeListItem: MCFileSetting[] = [];
   
        let fileTypeList;
        if (typeof this.props.dataStore.crud.data == 'undefined' ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo ||
            !this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.FileSettings) {
            console.log('render: empty list...');
        } else {
            console.log('render: getting data...');
            fileTypeList = this.props.dataStore.crud.data ? fileTypeListItem.concat(this.props.dataStore.crud.data.FileDownloadMaintenanceInfo.FileSettings.FileSetting) : fileTypeListItem;
        }        

        return (
            <DialogWrapper title={this.props.title} width='650' instruction={instruction} helpUrl='/Support/Help/HELP_MaintAutoPrintFiles.asp' buttons={helpButtons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST' || this.state.updating}>
                <ContentRowWrapper style={{width:'632px'}}>
                    <DialogFieldset>
                        <DialogLegend>File Type</DialogLegend>
                        <SelectComponent
                            title='FileTypeList'
                            size={10}
                            width='370px'
                            multiple='false'
                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectFileType(e)}
                            optionFields={{
                                    value: "@ID",
                                    text: "@Name"
                                }}
                            records={fileTypeList}
                            selectedValue={this.props.dataStore.ui.FileTypeList.id}
                        >
                        </SelectComponent>
                    </DialogFieldset>
                    <ContentColumnWrapper>
                        <SelectDropdown
                            domID='triggerevent-dropdown'
                            className="dropdown dd200"
                            label='*Action:'
                            isClearable={false}
                            isSearchable={true}
                            menuWidth={230}
                            size='medium'
                            onChange={(e: any) => this.updateAction_TriggerEvent(e.value)}
                            options={this.getFileTypeActionArray()}
                            initialValue={this.getInitialActionSelectedItem()} 
                        />
                        <SelectDropdown
                                domID='triggerevent-dropdown'
                                className='dropdown dd200'
                                label='*Printer:'
                                disabled={this.getPrinterEnableStatus()}
                                isClearable={false}
                                isSearchable={true}
                                menuWidth={230}
                                size='medium'
                                onChange={(e: any) => this.updateFileType_PrinterEvent(e.value)}
                                options={this.getFileTypePrinterArray()}
                                initialValue={this.getInitialPrinterSelectedItem()}
                                hasError={(this.props.dataStore.ui.printerItemError && this.props.dataStore.ui.printerItemError !== '') ? true : false}
                                errorMessage={this.props.dataStore.ui.printerItemError}
                        />
                        <Input
                            domID='filetype-group-name'
                            className='text-input'
                            label='Destination:'
                            disabled={this.getDestinationEnableStatus()}
                            style={{ width: '230px' }}
                            type='text'
                            value={this.state.localTextInput || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({localTextInput: e.target.value})
                            }}
                            onClick={(e: any) => {
                                this.setState({ blurred: false })
                                this.moveCursorToEnd('filetype-group-name')
                            }}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.updateFileType_Destination(e.target.value.trim())
                                this.trimOnBlur('filetype-group-name');
                            }}
                            maxLength={256}
                        />
                    </ContentColumnWrapper>
                </ContentRowWrapper>
                <ModalConfirmation
                    isOpen={this.props.dataStore.ui.isPrinterRequiredModalShow}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.isPrinterRequiredModalClose(e)}
                    message={this.props.dataStore.ui.printerItemError}
                    onConfirm={(e: React.MouseEvent<HTMLElement>) => this.isPrinterRequiredModalClose(e)}
                />
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IFileTypeState, IFileTypeActionProps, IOwnProps, IFileTypeProps, ApplicationState>(
    createCrudMapStateToProps('fileType'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(FileType);

export default withRouter(connectedHoc);
