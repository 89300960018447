import * as React from 'react';
//import { useState, useEffect, useRef, useCallback } from 'react';
//import * as UI from 

//export interface IErrorMessage {
//    summary: string,
//    details: string
//};

export interface IErrorBoundary {
    messages?: Array<UIMessage>,
    children?:any
}

interface IComponentProps { 

};

interface IComponentState {
    messages?: Array<UIMessage>;

};

const DEFAULT_STATE: IComponentState = {
    messages: undefined,
};


//----------------------------------------------------------------------------------------------------
export default class ErrorBoundary extends React.Component<IErrorBoundary, IComponentState> {

    //for language support
    private text = {
        
    };

    private readonly Selectors = {
        
    };

    public messages: Array<UIMessage> | undefined;
    //private targetLanguage: ISOlanguage: ISOlanguage.English;


    constructor(props: any) {
        super(props);

        this.state = DEFAULT_STATE;

    }

    public componentDidMount() {

    }

    static getDerivedStateFromError(error: any) {
        return {
            summary: error.message,
            details: error.stack
        };
    }

    /**
     * 
     * @param error
     * @param errorInfo : stack trace
     */
    componentDidCatch(error: any, errorInfo: any) {

        let summary: string = error.message;
        let details: string = errorInfo.componentStack;
        console.error(summary, details);

        this.setState({
            messages: [
                {
                    summary: summary,
                    details: details
                }
            ]
        });
    }

    ////----------------------------------------------
    ////event handlers


    public render() {

        /*
         <ReactBootstrap.Panel defaultExpanded onClick={this.handleClick.bind(this)}>
            <ReactBootstrap.Panel.Heading>{headerForNow}</ReactBootstrap.Panel.Heading>
            <ReactBootstrap.Panel.Body>
            <ReactBootstrap.ListGroup onClick={this.handleClick.bind(this)} fill>
                {msgList}
                </ReactBootstrap.ListGroup>
            </ReactBootstrap.Panel.Body>
        </ReactBootstrap.Panel>

                <div>
                    <h2>Something went wrong.</h2>
                    <details style={{ whiteSpace: "pre-wrap" }}>
                        {this.state.errorMessages && this.state.errorMessages![0].summary}
                        <br />
                        {this.state.errorMessages![0].details}
                    </details>
                </div>
         
         */
        const messages: Array<UIMessage> | undefined = this.state.messages;

        if (messages && messages.length > 0) {
            return (
                <div style={{ display: "block" }}>
                    <h3>Error:: {messages[0].summary}</h3>
                    <details style={{ whiteSpace: "pre-wrap" }}>
                        {messages && messages[0].details}
                    </details>
                </div>
            );
        }

        return this.props.children;
    }
}

/*
 *
                        {messages && messages[0].summary}
                        <br />
 */