import * as React from 'react'
import * as $ from 'jquery'
import QuickLinksPanel from './QuickLinksPanel';
import { LandingPageComponent, IComponentProps } from './LandingPageComponent';
import { ARMComponentError } from './ARMComponentError';
import { PanelGroup } from 'react-bootstrap';
import { ARMComponentLoading } from './ARMComponentLoading';
import QuickLinksIconPanel, { TIconImageTypes } from './QuickLinksIconPanel';
import { ARMLegacyHomePanel, ARMLegacyHomePanelRow, ARMLegacyHomePanelTitle, ARMLegacyHomePanelSubTitle} from './styles/LegacyHomePanels';
import { RouteComponentProps } from 'react-router-dom';
import { URLs } from '@commonDevResources/constants';
import { BigHelp } from "@commonResources/window";
import { getSecurityToken } from '@scripts/session/SecurityToken';
import { saveComponentState } from '@scripts/util/LandingPageHelpers';
import { UrlFromApi } from '../common/UrlFromApi';
import { getRawToken } from '@scripts/session/SecurityToken';

type QuickLinkProps = IProps & RouteComponentProps<{}>;

interface IProps extends IComponentProps {
    id?: string
    isExpanded?: boolean
    type?: string
    qlId?: string
    qlName?: string
    link?: string
    count?: number
    content?: [{
        Type: string
        Link: string
        Name: string
    }]
    componentProps?: [{
        id: string
        state: string
        length: number
        [key: string]: string | number
    }]
    UserProperties?: [{
        id: string,
        state: string
    }]
    saveMethod?: () => void
    displayAsIcons: boolean
    quickLinksUrl?: string
    subTitle?: string
}

export interface IQuickLinkSubMenu {
    Id: string,
    Name: string,
    Type: "Item",
    Link: string,
    Url: UrlFromApi,
    Permission: string,
}

export interface IQuickLinkMenu {
    Id: string
    Name: string
    Type: "Menu" | "Item" | "Message" | "Dynamic"
    State?: "Expanded" | "Collapsed"
    LegacyOrder?: number
    Submenu?: IQuickLinkSubMenu[]
    Function?: string
    Data?: string
    Link?: string
    PasswordExpMessage?: string
}

interface IState {
    data?: IQuickLinkMenu[] | null
    errorCode?: string | number | undefined | null
    componentProps: {
        UserProperties: [{
            id: string,
            state: string
        }]
    }
}

interface IDivStyle {
    border: string | number | undefined | null
    fontSize: string
    margin: string
    minHeight: string
};

export default class RAPQuickLinks extends React.Component<QuickLinkProps, IState> {
    constructor(props: QuickLinkProps) {
        super(props);
        this.state =
        {
            data: null,
            componentProps: {
                UserProperties: [
                    {
                        id: '0',
                        state: ''
                    }
                ]
            },
            errorCode: null
        }
        this.onLinkClick = this.onLinkClick.bind(this);
    }

    componentDidMount() {
        this.loadQuickLinksData();
    }

    loadQuickLinksData() {
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState === xhr.DONE) {
                if (xhr.status === 200) {
                    var responseData = xhr.responseText;
                    var menuData = JSON.parse(responseData);
                    //var menuData = responseData;
                    this.setState({ data: menuData });
                } else {
                    console.warn(xhr.status + " response handling /api/data/GetQuickLinks");
                    this.setState({ errorCode: xhr.status });
                }
            }
        };

        var url = (this.props.quickLinksUrl == undefined ? '/api/data/GetQuickLinks' : this.props.quickLinksUrl);
        xhr.open('get', URLs.api + url);
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send();
    }

    setComponentProperties = (id: string, state: string) => {
        var propertiesArray = this.state.componentProps.UserProperties;
        propertiesArray.push({ "id": id, "state": state });
    }

    getQuickLinkPanelState = (qlId: any, defaultState: any) => {
        //if user had saved properties before, use them instead of defaults. else use default state
        if (this.props.UserProperties) {
            var userDefinedStates = this.props.UserProperties;
            if (userDefinedStates) {
                for (var i = 0; i < userDefinedStates.length; i++) {
                    if (userDefinedStates[i].id === qlId) {
                        if (userDefinedStates[i].state) {
                            return userDefinedStates[i].state;
                        } else {
                            return defaultState;
                        }
                    }
                }
            }
        } else {
            return defaultState;
        }
    }

    sendMessagesAlert = (msgs: string) => {
        $.event.trigger({
            type: "asyncMessageAlert",
            message: msgs,
            msgType: "warning",
            time: new Date()
        });
    }

    getQuickLinks = () => {
        var data: any = this.state.data;
        var messageList: any = [];
        var preparedMenuData = this.getPreparedMenuData();

        if (data) {
            var keyCount = 0;
            var qlData = preparedMenuData.map((qlItem: IQuickLinkMenu) => {
                    keyCount++;
                    if (qlItem.Type) {
                        switch (qlItem.Type) {
                            case "Menu":
                                var origState = false;
                                var panelExpanded = this.getQuickLinkPanelState(qlItem.Id, qlItem.State);
                                origState = (panelExpanded === "Expanded");
                                this.setComponentProperties(qlItem.Id, panelExpanded);
                                return <QuickLinksPanel type="PanelMenu" key={keyCount} qlId={qlItem.Id} qlName={qlItem
                                    .Name
} isExpanded={origState} content={qlItem.Submenu} componentProps={this.state.componentProps} saveMethod={
this.saveQuickLinksComponentCurrentState.bind(this)} location={this.props.location} history={this.props.history} match={
this.props.match} onLinkClick={this.onLinkClick}/>;

                            case "Item":
                                return <QuickLinksPanel type="PanelLink" key={keyCount} qlId={qlItem.Id} qlName={qlItem
                                    .Name
} isExpanded={false} count={qlItem.Data} link={qlItem.Link} location={this.props.location} history={this.props.history
} match={this.props.match} onLinkClick={this.onLinkClick}/>;
                            case "Message":
                                messageList.push(qlItem);
                                break;
                            default:
                                return ''
                        }
                    }
                    return ''
                },
                this);

            if (messageList.length > 0) {
                this.sendMessagesAlert(messageList)
            }

            return qlData;
        }
    }

    getDivStyle =() => {
        //var divStyle = evalDivStyle(this);
        var divStyle: IDivStyle = {
            border: "1px solid #c3c5cd",
            fontSize: "13px",
            margin: "3px",
            minHeight: "771px"
        };
        return divStyle;
    }

    getClassName() {
        return this.props.Name; //  + " flex-innercontainer";
    }

    renderQuickLinks() {
        return (
            <LandingPageComponent ComponentClass={this.getClassName()} {...this.props}>
                    <PanelGroup id="RAPQuickLinksPanel" className="quickLinksPanel">{this.getQuickLinks()}</PanelGroup>
            </LandingPageComponent >
        );
    }

    renderLoading() {
        return (
            <LandingPageComponent ComponentClass={this.getClassName()} {...this.props}>
                <div className="quickLinksHeader">Quick Links</div>
                <div className="quickLinksPanelLoader">
                    <div className="RAPAdSpaceItem">
                        <ARMComponentLoading/>
                    </div>
                </div>
            </LandingPageComponent >
        );
    }

    renderError() {
        return (
            <LandingPageComponent ComponentClass={this.getClassName()} {...this.props}>
                <div className="quickLinksHeader">Quick Links</div>
                <div className="quickLinksPanelLoader">
                    <ARMComponentError/>
                </div>
            </LandingPageComponent >
        );
    }

    render() {
        if (this.state.data) {
            if (this.props.displayAsIcons) {
                return this.renderQuickLinksAsIcons();
            } else {
                return this.renderQuickLinks();
            }
        } else if (this.state.errorCode) {
            return this.renderError();
        } else {
            return this.renderLoading();
        }
    }

    saveQuickLinksComponentCurrentState() {
  //      console.log('saving quick links to the db');
        var temp = JSON.parse(JSON.stringify(this.props));
        var saveThis = $.extend(temp, this.state.componentProps);
      //  console.log(JSON.stringify(saveThis));
        saveComponentState(this.props.id, JSON.stringify(saveThis));
    }


    renderQuickLinksAsIcons() {
        var today = new Date();
        var dateOptions: Intl.DateTimeFormatOptions = { 
            weekday: 'long', 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        };
        // var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return (
            <LandingPageComponent  {...this.props}>
                <ARMLegacyHomePanel id="ARMQuickLinksPanel" className="quickLinksPanel">
                    <ARMLegacyHomePanelRow wrap={true} justifyContent="space-between">
                        <ARMLegacyHomePanelTitle>{today.toLocaleDateString('en-US', dateOptions)
                        }</ARMLegacyHomePanelTitle>
                        {this.props.subTitle && <ARMLegacyHomePanelSubTitle>{this.props.subTitle}</ARMLegacyHomePanelSubTitle>}
                    </ARMLegacyHomePanelRow>
                    {/* <ARMLegacyHomePanelRow wrap={true} justifyContent="space-between">
                       
                    </ARMLegacyHomePanelRow> */}
                    <ARMLegacyHomePanelRow wrap={true} justifyContent="space-between">
                        <ARMLegacyHomePanelSubTitle 
                            style={{justifyContent:"left", 
                                    color: '#7D7F81', 
                                    position: 'relative',
                                    fontSize: '15px'}}>
                            Quick Links
                        </ARMLegacyHomePanelSubTitle>
                        {this.PrepareMenuIcons()}
                    </ARMLegacyHomePanelRow>
                </ARMLegacyHomePanel>
            </LandingPageComponent >
        );
    }

    getPreparedMenuData() {
        var data: any = this.state.data;
        var token = getSecurityToken();
        var preparedMenuData = JSON.parse(JSON.stringify(data));
        preparedMenuData.map((item: any) => {
            if (item.Name === "Support") {
                item.Submenu.map((menu: any) => {
                    if (menu.Name === "ConnectCenter") {
                        var connectCenterUrl = menu.Url.Path;
                        var urlMatchStringArray = connectCenterUrl.match(/(https?:\/\/[^\s]+)/g);
                        var urlMatchString = urlMatchStringArray[0].toString().slice(0, -2);
                        menu.Url.Path = urlMatchString
                        menu.Url.Type = "Window"
                    }
                })
            }
            if (item.Name === "Claims") {
                item.Submenu.map((menu: any) => {
                    if (menu.Name === "Test Mode") {
                        menu.Name = "*Test Mode*"
                    }
                    else if (menu.Name === "Exit Test Mode") {
                        menu.Name = "*Exit Test Mode*";
                    }
                })
            }
        });

        return preparedMenuData;
    }

    PrepareMenuIcons() {
        var data: any = this.state.data;
        var messageList: any = [];
        var preparedMenuData = this.getPreparedMenuData();

        if (data) {
            var keyCount = 0;
            var qlData = preparedMenuData.map((qlItem: IQuickLinkMenu) => {
                    keyCount++;
                    if (qlItem.Type) {
                        if (qlItem.Name != "Messages") {
                            switch (qlItem.Type) {
                                case "Menu":
                                    var origState = false;
                                    var panelExpanded = this.getQuickLinkPanelState(qlItem.Id, qlItem.State);
                                    origState = (panelExpanded === "Expanded");
                                    this.setComponentProperties(qlItem.Id, panelExpanded);
                                    if (qlItem.Submenu) {
                                        return <QuickLinksIconPanel IconImagePath={qlItem.Name as TIconImageTypes} IconAltText={qlItem.Name} Title={qlItem.Name}
                                                                    key={keyCount} Links={qlItem.Submenu} Order={qlItem.LegacyOrder} onLinkClick={this.onLinkClick}/>;
                                    }

                                case "Item":
                                    if (qlItem.Submenu) {
                                        return <QuickLinksIconPanel IconImagePath={qlItem.Name as TIconImageTypes} IconAltText={qlItem.Name} Title={qlItem.Name}
                                                                    key={keyCount} Links={qlItem.Submenu} Order={qlItem.LegacyOrder} onLinkClick={this.onLinkClick}/>;
                                    }
                                case "Message":
                                    messageList.push(qlItem);
                                    break;
                                default:
                                    return ''
                            }
                        }
                    }
                    return ''
                },
                this);

            if (messageList.length > 0) {
                this.sendMessagesAlert(messageList)
            }

            return qlData;
        }
    }

    onLinkClick(url: UrlFromApi) {
        //Open a new help window to the target path if necessary.
        if (url.Type === "BigHelpWindow") {
            BigHelp(url.Path);
        }
        else if (url.Type === "Window") {
            window.open(url.Path);
        }
        //Otherwise just navigate to the link or injected link.
        else {
            this.props.history.push(url.Path);
        }

    }
};