/**
 * Date:    10/Apr/2019
 * Author:  Luis-Carlos Rodriguez
 * Module:  AssuranceDateControl.tsx
 * Purpose: Provides a formatted date using the DateRangePicker from JQuery lib
 *          NOTE:
 *              Due to expedience, this jsx has not been translated into TypeScript
 *              this will be done when the file is cleanup and tested
 */
import * as React from 'react'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import * as wind from '@commonResources/window';

//const DateRangePicker = require('react-bootstrap-daterangepicker');
//import 'bootstrap/dist/css/bootstrap.css';                      // webpack uses bootstrap03
import 'bootstrap-daterangepicker/daterangepicker.css';         // the bootstrap-daterangepicker CSS is also needed

import { getItemStartDate, getItemEndDate } from '../../scripts/session/SessionHelpers';

import { Calendar, Button, SelectDropdown } from '@optum-uicl/ui-core/dist';
import { IImageButtonImage, ImageButton } from '../common/ImageButton';

import ARMHelpIconOff from '../../content/images/Buttons/But_HelpOff.png';
import ARMHelpIconUp from '../../content/images/Buttons/But_HelpUp.png';
import ARMHelpIconOver from '../../content/images/Buttons/But_HelpOver.png';
import ARMHelpIconDown from '../../content/images/Buttons/But_HelpDown.png';


export interface IAssuranceDateControlProps {
    Name: string;
    minDays?: number;
    helpLink: string;
    data: any | null;
    dateRange: any;
    menuWidth?: any;
    onDateChange: (event: any, selectedName: string) => void;
    dateCtrl?: any;
    ctrlLabel?: any;
    helpCtrl?: any;
    dateRangeValue: string;
}

export interface IAssuranceDateControlState {
    displayCalendarBtn: boolean;
}

export default class AssuranceDateControl extends React.Component<IAssuranceDateControlProps, IAssuranceDateControlState>
{
    constructor(props: IAssuranceDateControlProps) {
        super(props);
        this.state =
        {
            displayCalendarBtn: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.onApply = this.onApply.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onDatePickerEvent = this.onDatePickerEvent.bind(this);
        this.handleHelpClick = this.handleHelpClick.bind(this);
    }

    componentDidMount() {
        //this.setState({});
    }

    handleChange(event: any) {
        this.updateDropdown(event);
    }

    updateDropdown(event: any) {
        console.log('handleChange ' + event.value + ' ' + event.label);
        var selectedName = "";
        if (this.props.data) {
            this.props.data.map((option: any) => {
                if (option.value === event.value) {
                    selectedName = option.name;
                }
            });
        }

        if (event.value === '7') {
            this.setState({ displayCalendarBtn: true }, () => this.forceClick());
        } else {
            this.setState({ displayCalendarBtn: false });
            this.props.onDateChange(event, selectedName);
        }
    }

    forceClick() {
        var thisCustomDateRange = document.getElementById(this.props.Name + 'customDateRange');
        if (thisCustomDateRange) {
            thisCustomDateRange.style.display = "block";
            thisCustomDateRange.click();
        }
    }

    onClickCalendarButton() {
        // this is not correct - please fix - rh.web/scripts/shared/components/assurancedatecontrol
        var thisDateDiv = document.getElementById(this.props.Name + "DateDiv");
        if (!thisDateDiv)
            return;

        if (this.state.displayCalendarBtn) {
            //    this.setState({ displayCalendarBtn: false });
            console.log('nothing to say');
        } else {
            this.setState({ displayCalendarBtn: true });
        }
    }

    getDateOptions() {
        let options: any[] = [];

        if (this.props.data && this.props.data.length > 0) {
            for (var item of this.props.data) {
                if (this.props.dateRange !== item.value)
                    options.push({ label: item.name, value: item.value });
            }
        }
        return options;
    }

    getDateCtrl() {
        if (this.props.dateCtrl) {
            //         console.log('we are here in the getDateCtrl function, props: ', this.props);
            //var optionsData = [{name: "Last 90 days", value: "6"}, {name: "Today", value: "0"}];

            let optionsData = this.getDateOptions();
            let selectedOption = { value: this.props.dateRange, label: this.props.dateRangeValue };

            var styleSpan: React.CSSProperties = {};
            styleSpan.display = "inline-block";
            styleSpan.float = "left";

            var style: React.CSSProperties = {};
            style.display = "flex";
            style.flexDirection = "row";
            style.flexWrap = "nowrap";
            style.alignItems = "center";

            var label = "Date:";
            var customId = this.props.Name + "customDateRange";
            var customDivId = this.props.Name + "DateDiv";

            var calendarStyle: React.CSSProperties = {};
            calendarStyle.float = "right";
            calendarStyle.paddingLeft = "6px";
            calendarStyle.display = "block";

            if (this.props.ctrlLabel) {
                label = this.props.ctrlLabel;
            }

            var minDays = 365;
            var startDate = moment();
            var endDate = moment();

            if (sessionStorage.getItem(this.props.Name + "Start") !== null) {
                startDate = moment(getItemStartDate(this.props.Name));
            }

            if (sessionStorage.getItem(this.props.Name + "End") !== null) {
                endDate = moment(getItemEndDate(this.props.Name));
            }

            if (this.props.minDays) {
                minDays = this.props.minDays;
            }

            console.log("display calendar " + this.state.displayCalendarBtn, this.props.minDays);

            return (
                <div style={style}>
                    <span style={{ display: "inline-block", float: "left", marginRight: '3px' }} className="boldFont">{label}&nbsp;</span>
                    <SelectDropdown

                        domID={this.props.Name + "_armDateCtrl"}
                        className="dropdown"
                        isClearable={false}
                        isSearchable={true}
                        menuWidth={this.props.menuWidth ? this.props.menuWidth : 'auto'}
                        size='small'

                        onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateDropdown(e)}

                        options={optionsData}

                        initialValue={selectedOption}
                    />

                    {this.state.displayCalendarBtn &&
                        <DateRangePicker
                            minDate={moment().subtract(minDays, 'days')}
                            maxDate={moment()}
                            startDate={startDate}
                            endDate={endDate}
                            opens='center'
                            locale={{
                                format: 'MM/DD/YYYY',
                                cancelLabel: 'Reset'
                            }}
                            onApply={this.onApply}
                            onCancel={this.onCancel}
                        >

                            <div id={customDivId} style={calendarStyle} >
                                {/*
                            <button className="calendarButton" id={customId} />
                            */}
                                <Button
                                    domID={customId}
                                    type="button"
                                    size="medium"
                                    buttonType="unstyled"

                                    onClick={(e: React.SyntheticEvent) => { this.onClickCalendarButton() }}
                                >
                                    <Calendar
                                        fillColor="black"
                                        className="calendar"
                                        title="custom date range"
                                    />
                                </Button>
                            </div>
                        </DateRangePicker>
                    }
                </div>
            );

        }
        return null;
    }

    onApply(event: string, picker: any) {
        sessionStorage.setItem(this.props.Name + "Start", picker.startDate.format('M-D-YYYY'));
        sessionStorage.setItem(this.props.Name + "End", picker.endDate.format('M-D-YYYY'));
        this.props.onDateChange({ value: '7' }, picker.startDate.format('M/D/YYYY') + '-' + picker.endDate.format('M/D/YYYY'));
    }

    onCancel(event: string, picker: any) {
        picker.startDate = moment();
        picker.endDate = moment();

        var l = document.getElementById(this.props.Name + 'customDateRange');
        if (l) {
            l.click();
        }
    }

    onDatePickerEvent(ev: string, picker: any) {
        console.log('now handling the onEvent', ev, picker);
        //switch (ev) {
        //}
    }
    /*
        onEvent(ev: any, picker: any) {
            console.log('now handling the onEvent', ev, picker)
            if (ev.type == 'show' || ev.type == 'hide') return;
    
            const { Name }     = this.props
            const {Start, End} = this.state
            sessionStorage[Name + Start] = picker.startDate.format('M-D-YYYY')
            sessionStorage[Name + End]   = picker.endDate.format('M-D-YYYY')
            this.props.onDateChange(7, picker.startDate.format('M/D/YYYY') + - + picker.endDate.format('M/D/YYYY'))
        }
    */
    handleHelpClick() {
        console.warn('helpLinkClicked', this.props.helpLink);
        wind.BigHelp(this.props.helpLink);
    }


    static helpIcon: IImageButtonImage = {
        Off: ARMHelpIconOff,
        Up: ARMHelpIconUp,
        Over: ARMHelpIconOver,
        Down: ARMHelpIconDown,
    }

    getHelpCtrl() {
        if (this.props.helpCtrl) {

            var style: React.CSSProperties = {};
            return <div style={style}>
                <ImageButton
                    Title={this.props.Name + 'HelpIcon'}
                    topheader={true}
                    helpIcon={true}
                    image={AssuranceDateControl.helpIcon}
                    className="RAPHelp-button"
                    onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.handleHelpClick()} /></div>;
        }
        return null;
    }

    render() {
        var divStyle: React.CSSProperties = {};
        divStyle.width = "100%";
        divStyle.padding = "11px";
        divStyle.fontFamily = "optum-sans"
        return (
            <div style={divStyle} className="componentTop">
                {this.getDateCtrl()}
                {this.getHelpCtrl()}
            </div>
        );
    }
};
