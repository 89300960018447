import * as React from 'react';
import { ARMGrid } from '@common/UICLWrappers/ARMGrid';
import { GridConfig, IHeaderProps, IRowProps } from './GridConfig';
import { SortHelper } from '@scripts/util/SortHelper';
import { ISortIndicator, SortDataType } from '@scripts/util/SortHelper';
import { ISampleTreeviewData } from './ISampleTreeviewData';
import styled from 'styled-components';
import { ARMHeaderCell } from '../../common/UICLWrappers/ARMHeaderCell';
import plusSymbol from './icons/plus.gif';
import minusSymbol from './icons/minus.gif';
import i from './icons/i.gif';
import l_middle from './icons/l-middle.gif';
import l_end from './icons/l-end.gif';
import _ from 'lodash';


const numberOfSortableHeaders = 3
let sortableHeaderIds: any[] = []
for (let i = 0; i < numberOfSortableHeaders; i++) {
    sortableHeaderIds = [...sortableHeaderIds, ""+_.uniqueId()]
}

export const initTab = 0

export const InitLOBSelectionObj = {
    '@ID': "",
    '@Name': '- Select LOB -'
}

export const tabSelectionData = [
    {
        domID: 'id-0',
        label: 'Search'
    },
    {
        domID: 'id-1',
        label: 'Results'
    }
]


export const GridWrapper = styled.div`

    /* STANDARD OUTER GRID STYLE CONFIGURATION */

    div{
        display:inline;
    }
    background-color:white;
    width: 1032px;
    height: 400px;
    border: black 1px solid;
    overflow-y: scroll;

    #sample-treeview-grid{
           input[type="checkbox"]:checked{
        accent-color: rgb(12, 85, 184) !important;
    }
        /* STANDARD INNER GRID STYLE CONFIGURATION */

        thead{position:sticky; z-index:20; top:0px}

        thead{
            th {
                position: relative;
                height: 34px;
                text-align: left;
                transition: color 0.4s ease 0s;
                overflow: hidden;
                vertical-align: bottom;
                padding: 0px 10px;
                border-bottom: 1px solid rgb(211, 213, 220);
                border-image: initial;
                border-top: none;
                border-left: none;
                border-right: none;
                background: rgb(255, 255, 255);
                button {
                    display: block;
                    position: relative;
                    text-transform: capitalize;
                    letter-spacing: 0.05em;
                    font-size: 11px;
                    font-weight: 400;
                    text-align: left;
                    margin: 8px 0px;
                    padding: 1px 20px 1px 0px;
                    background-color: transparent;
                    color: rgb(55, 71, 79);
                    border: none;
                    .caret-span {
                        vertical-align: top;
                        position: absolute;
                        width: 14px;
                        fill: rgb(255, 255, 255);
                        fill-opacity: 0;
                        padding-left:18px;
                        fill:rgb(55, 71, 79);
                        transition: transform 0.2s ease 0s, fill 0.3s ease 0s, fill-opacity 0.3s ease 0s;
                        .up {
                            transform: rotate(180deg);
                        }
                        .up, .down {
                            fill-opacity: 1;
                        }
                        svg {
                            vertical-align: top;
                            position: absolute;
                            right: 0px;
                            fill: rgb(255, 255, 255);
                        }
                    }
                   
                }
            }
        }

        background-color:white;
        .empty-row{
            background-color:white !important;
        }
        overflow-y:scroll;
        overflow-x:scroll;

        table tbody tr > td{
            height:32px !important;
        }

        /* ROW HOVER CONFIGURATION */

        tr:hover{
            background-color:#edf9ff;
            cursor:pointer;
        }

        /* OTHER CONFIGURATION */

        .child-header {
            background-color: white;
            pointer-events: none;
            th > div {
                white-space: nowrap;
                float: left;
                img {
                    float: left;
                    margin-left: 15px;
        }
    }
    button {
         background: none;
        border: none;
        text-transform: uppercase;
        font-size: 11px;
        font-family: 'optum-sans-regular';
        font-weight: 600;
    }
}

        .child-row {
            cursor: default;
            img {
                float: left;
            }
        }

        .cell-style {
            height: 32px;
            display: flex;
            align-items: center;
            white-space: nowrap;
            font-size: 13px;
        }

        /* primary grid row headers */

        .fileName {
            width: 155px;
        }

        .importDate {
            width: 145px;
        }

        .paymentSource {
            width: 425px;
        }

        .primary-header-count {
            width: 100px;
        }

        .primary-header-amount {
            width: 100px;
        }

        .primary-header-remove {
            width: 50px;
        }

        /* primary grid rows */

        .overflow-filename-style {
            overflow: hidden;
            inline-size: 115px;
            margin-right: -10px;
        }

        .primary-row-file-name {
            width: 155px;
        }
        
        .overflow-import-date-style {
            overflow: hidden;
            inline-size: 130px;
            margin-right: -10px;
        }

        .primary-row-import-date {
            width: 145px;
        }
        
        .overflow-payment-source-style {
            overflow: hidden;
            inline-size: 410px;
            margin-right: -10px;
        }

        .primary-row-payment-source {
            width: 425px;
        }
        
        .overflow-count-style {
            overflow: hidden;
            inline-size: 90px;
            margin-right: -10px;
        }

        .primary-row-count {
            width: 100px;
        }
        
        .overflow-amount-style {
            overflow: hidden;
            inline-size: 90px;
            margin-right: -10px;
        }

        .primary-row-amount {
            width: 100px;
        }

        .primary-row-remove {
            width: 50px;
        }

        /* child grid row headers */

        .child-header-payment-date {
            white-space: nowrap;
            width: 170px;
        button {
        margin-top: 6px;
        padding-left: 0px;
    }
}

        .child-header-payment-no {
            width: 120px;
        }

        .child-header-provider-no {
            width: 114px !important;
        }

        .child-header-tax-id {
            width: 90px;
        }

        .child-header-secondary {
            width: 115px;
        }

        .child-header-matched {
            width: 100px;
        }

        .child-header-unmatched {
            width: 115px;
        }

        .child-header-amount {
            width: 100px;
        }

        .child-header-remove {
            width: 50px;
            white-space: nowrap;
        }

        /* child grid rows */
        .child-row-payment-date{
            white-space: nowrap;
            width: 170px;
        }
        
        .overflow-payment-no-style {
            overflow: hidden;
            inline-size: 110px;
            margin-right: -10px;
        }

        .child-row-payment-no {
            width: 120px;
        }
        
        .overflow-provider-no-style {
            overflow: hidden;
            inline-size: 100px;
            margin-right: -10px;
        }

        .child-row-provider-no {
            width: 115px;
        }
        
        .overflow-tax-id-style {
            overflow: hidden;
            inline-size: 70px;
            margin-right: -10px;
        }

        .child-row-tax-id {
            width: 90px;
        }
        
        .overflow-secondary-style {
            overflow: hidden;
            inline-size: 100px;
            margin-right: -10px;
        }

        .child-row-secondary {
            width: 115px;
        }
        
        .overflow-matched-style {
            overflow: hidden;
            inline-size: 90px;
            margin-right: -10px;
        }

        .child-row-matched {
            width: 100px;
        }
        
        .overflow-unmatched-style {
            overflow: hidden;
            inline-size: 100px;
            margin-right: -10px;
        }

        .child-row-unmatched {
            width: 115px;
        }
        
        .overflow-child-amount-style {
            overflow: hidden;
            inline-size: 90px;
            margin-right: -10px;
        }

        .child-row-amount {
            width: 100px;
        }

        .child-row-remove{
            width: 50px;
            white-space: nowrap;
             }
    }
    
   }
`;

interface ISampleHierarchyGridProps {
    treeviewData: ISampleTreeviewData;
    setResultsGridState: any;
    resultsGridState: IComponentState;
    onSort: any;
}


interface searchResultsObj {
    "count": string;
    "remits": any[];
};

interface IComponentProps {
};

interface ResultsGridState {
    treeviewData: ISampleTreeviewData | undefined;
    expandClicked: boolean;
    parentRemoveCheckedIds: string[];
    childRemoveCheckedIds: string[];
    parentRemoveDisabledIds: string[];
    childRemoveDisabledIds: string[];
    expandedParentIds: string[];
    inputValue: string;
    sortIndicator: ISortIndicator;
}

interface paginatedSearchResult {
    childData: any[];
    parentData: any[];
}

interface IComponentState {
    activeTab: number;
    cancelLeave: boolean;
    isConfirmationModalOpen: boolean;
    isValidationModalOpen: boolean;
    reasonInputError: boolean;
    reasonInputText: string;
    isHoveringOverBack: boolean;
    isHoveringOverFoward: boolean;
    searchResults: searchResultsObj;
    paginatedSearchResults: paginatedSearchResult[];
    pageIndex: number;
    LOBList: any[];
    LOBSelection: string;
    fromImportDate: string;
    thruImportDate: string;
    fromPaymentDate: string;
    thruPaymentDate: string;
    fromPaymentNumber: string;
    thruPaymentNumber: string;
    fromProviderID: string;
    thruProviderID: string;
    fromTaxID: string;
    thruTaxID: string;
    dateUUID: string;
    resultsUUID: string;
    treeviewData: ISampleTreeviewData | undefined;
    expandClicked: boolean;
    parentRemoveCheckedIds: string[];
    childRemoveCheckedIds: string[];
    parentRemoveDisabledIds: string[];
    childRemoveDisabledIds: string[];
    expandedParentIds: string[];
    inputValue: string;
    sortIndicator: ISortIndicator;
};


export const DEFAULT_RESULTS_GRID_STATE: ResultsGridState = {
    treeviewData: undefined,
    expandClicked: false,
    parentRemoveCheckedIds: [],
    childRemoveCheckedIds: [],
    parentRemoveDisabledIds: [],
    childRemoveDisabledIds: [],
    expandedParentIds: [],
    inputValue: '',
    sortIndicator: {
        sortColumn: 'importDate',
        sortDirection: 'up',
        sortDataType: SortDataType.Date
    },
}

export const DEFAULT_STATE: IComponentState = {
    activeTab: initTab,
    cancelLeave: false,
    isConfirmationModalOpen: false,
    isValidationModalOpen: false,
    reasonInputError: false,
    reasonInputText: "",
    isHoveringOverBack: false,
    isHoveringOverFoward: false,
    searchResults: {
        "count": "0",
        "remits": []
    },
    paginatedSearchResults: [{
        childData: [],
        parentData: [],
    }],
    pageIndex: 0,
    LOBList: [InitLOBSelectionObj],
    LOBSelection: "",
    fromImportDate: "",
    thruImportDate: "",
    fromPaymentDate: "",
    thruPaymentDate: "",
    fromPaymentNumber: "",
    thruPaymentNumber: "",
    fromProviderID: "",
    thruProviderID: "",
    fromTaxID: "",
    thruTaxID: "",
    dateUUID: _.uniqueId(),
    resultsUUID: "" + Date.now(),
    treeviewData: undefined,
    expandClicked: false,
    parentRemoveCheckedIds: [],
    childRemoveCheckedIds: [],
    parentRemoveDisabledIds: [],
    childRemoveDisabledIds: [],
    expandedParentIds: [],
    inputValue: '',
    sortIndicator: {
        sortColumn: 'importDate',
        sortDirection: 'up',
        sortDataType: SortDataType.Date
    },
};

export class RemoveRemitsResultsGrid extends React.Component<ISampleHierarchyGridProps, IComponentState> {
    cheackboxRefs: { [index: string]: any };
    sortableHeaderRefs: { [index: string]: any };

    constructor(props: ISampleHierarchyGridProps) {
        super(props);
        this.state = {
            ...DEFAULT_STATE,
            treeviewData: props.treeviewData
        };
        this.handleSorting = this.handleSorting.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.onParentRemoveClicked = this.onParentRemoveClicked.bind(this);
        this.cheackboxRefs = [];
        this.sortableHeaderRefs = [];
    }

    handleSorting(sortIndicator: ISortIndicator, refKey: string = "") {
        const {
            treeviewData
        } = this.props.resultsGridState;

        if (!treeviewData) return;

        let sortedTreeviewData = this.props.onSort(sortIndicator);

        this.props.setResultsGridState({
            treeviewData: sortedTreeviewData[this.props.resultsGridState.pageIndex],
            paginatedSearchResults: sortedTreeviewData,
            sortIndicator,
            expandedParentIds: [],
        }, () =>refKey !== "" && this.sortableHeaderRefs[refKey].focus());
    }

    onRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        const {
            expandedParentIds
        } = this.props.resultsGridState;

        let newExpandedParentIds = expandedParentIds;
        const clickedId = event.currentTarget.id;

        if (newExpandedParentIds.includes(clickedId)) {
            newExpandedParentIds = newExpandedParentIds.filter(x => x != clickedId)
        } else {
            newExpandedParentIds.push(clickedId);
        }

        this.props.setResultsGridState({ expandedParentIds: newExpandedParentIds });
    }

    getCustomHeader() {
        const {
            sortIndicator,
        } = this.props.resultsGridState;

        const header = () => <thead><tr style={{ display: 'block' }}>
            {
                <>
                    <ARMHeaderCell
                        id={sortableHeaderIds[0]}
                        key={sortableHeaderIds[0]}
                        setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[0]] = input}
                        refKey={sortableHeaderIds[0]}
                        dataName="fileName"
                        text="File Name"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        hasCorrectedTabIndex={true}
                    />
                    <ARMHeaderCell
                        id={sortableHeaderIds[1]}
                        key={sortableHeaderIds[1]}
                        setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[1]] = input}
                        refKey={sortableHeaderIds[1]}
                        dataName="importDate"
                        text="Import date"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.Date}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        hasCorrectedTabIndex={true}
                    />
                    <ARMHeaderCell
                        id={sortableHeaderIds[2]}
                        key={sortableHeaderIds[2]}
                        setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[2]] = input}
                        refKey={sortableHeaderIds[2]}
                        style={{ whiteSpace: "nowrap", width: "400px" }}
                        dataName="paymentSource"
                        text="Payment source"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        hasCorrectedTabIndex={true}
                    />
                    <ARMHeaderCell
                        dataName="primary-header-count"
                        text="Count"
                        hasCorrectedTabIndex={true}
                    />
                    <ARMHeaderCell
                        dataName="primary-header-amount"
                        text="Amount"
                        hasCorrectedTabIndex={true}
                    />
                    <ARMHeaderCell
                        dataName="primary-header-remove"
                        text="Remove"
                        hasCorrectedTabIndex={true}
                    />
                </>
            }
        </tr></thead>
        return header;
    }

    onParentRemoveClicked(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        e.preventDefault();
        const {
            treeviewData,
            parentRemoveCheckedIds,
            childRemoveCheckedIds,
            childRemoveDisabledIds,
        } = this.props.resultsGridState;

        // create a local copy of state vars
        let newParentRemoveCheckedIds = parentRemoveCheckedIds;
        let newChildRemoveCheckedIds = childRemoveCheckedIds;
        let newChildRemoveDisabledIds = childRemoveDisabledIds;

        // get parent id
        const clickedRemoveParentId = e.currentTarget.id;

        // get child id's
        const children = treeviewData?.childData.filter(x => ('cbxRemove-parent-' + x.parentId.toString()) === clickedRemoveParentId);
        let childIdStrings: string[] = [];
        children?.forEach(x => {
            childIdStrings.push('cbxRemove-child-' + x.id);
        });

        // handle click event for parent
        if (newParentRemoveCheckedIds.includes(clickedRemoveParentId)) {
            // uncheck parent
            newParentRemoveCheckedIds = newParentRemoveCheckedIds.filter(x => x != clickedRemoveParentId);

            // for each child
            childIdStrings.forEach(x => {

                // uncheck child
                newChildRemoveCheckedIds = newChildRemoveCheckedIds.filter(y => y != x);

                // enable child
                newChildRemoveDisabledIds = newChildRemoveDisabledIds.filter(y => y != x);
            });
        } else {
            // check parent
            newParentRemoveCheckedIds.push(clickedRemoveParentId);

            // for each child
            childIdStrings.forEach(x => {

                // check child
                newChildRemoveCheckedIds.push(x);

                // disable child
                newChildRemoveDisabledIds.push(x);
            });
        }

        this.props.setResultsGridState({
            parentRemoveCheckedIds: newParentRemoveCheckedIds,
            childRemoveCheckedIds: newChildRemoveCheckedIds,
            childRemoveDisabledIds: newChildRemoveDisabledIds,
        }, () => this.cheackboxRefs[clickedRemoveParentId].focus());

        return false;
    }

    onChildRemoveClicked(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        e.preventDefault();
        const {
            childRemoveCheckedIds,
            parentRemoveDisabledIds,
            treeviewData
        } = this.props.resultsGridState;

        let newChildRemoveCheckedIds = childRemoveCheckedIds;
        let newParentRemoveDisabledIds = parentRemoveDisabledIds;

        const clickedRemoveChildId = e.currentTarget.id;
        const parentIdNumber = treeviewData?.childData.find(x => ('cbxRemove-child-' + x.id.toString()) === clickedRemoveChildId)?.parentId || 0;
        const parentIdString = 'cbxRemove-parent-' + parentIdNumber;

        if (newChildRemoveCheckedIds.includes(clickedRemoveChildId)) {

            // remove the unchecked child id
            newChildRemoveCheckedIds = newChildRemoveCheckedIds.filter(x => x != clickedRemoveChildId);

            // det if sibling remove child checkboxes are checked
            const siblings = treeviewData?.childData.filter(x => x.parentId == parentIdNumber);

            // validate if any child siblings are checked
            let childSiblingChecked = false;
            siblings?.forEach(x => {
                if (newChildRemoveCheckedIds.includes('cbxRemove-child-' + x.id.toString())) {
                    childSiblingChecked = true;
                }
            });

            // if no child remove siblings are checked
            if (!childSiblingChecked) {
                // re-enable parent checkbox 
                newParentRemoveDisabledIds = newParentRemoveDisabledIds.filter(x => x != parentIdString);
            }
        } else {
            newChildRemoveCheckedIds.push(clickedRemoveChildId);

            // disable parent checkbox
            newParentRemoveDisabledIds.push(parentIdString);
        }

        this.props.setResultsGridState({
            childRemoveCheckedIds: newChildRemoveCheckedIds,
            parentRemoveDisabledIds: newParentRemoveDisabledIds
        }, () => this.cheackboxRefs[clickedRemoveChildId].focus());

        return false;
    }

    getCustomRow() {
        const row = ({ record }: IRowProps) => {
            const {
                expandedParentIds,
                treeviewData,
                parentRemoveCheckedIds,
                childRemoveCheckedIds,
                parentRemoveDisabledIds,
                childRemoveDisabledIds,
            } = this.props.resultsGridState;

            const {
                amount,
                count,
                fileName,
                id,
                importDate,
                paymentSource
            } = record
            const collapsedRecordDataStr = amount + "" + count + "" + fileName + "" + id + "" + importDate + "" + paymentSource

            let recordId = record.id.toString();

            const childRecords = treeviewData?.childData.filter(x => x.parentId.toString() == recordId);

            return (
                <>
                    <tr id={recordId as string} key={recordId} role="button" onClick={(x) => { this.onRowClick(x) }} style={{ display: 'block' }}>
                        {
                            <>
                                <td className="primary-row-file-name">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style overflow-filename-style">
                                            {expandedParentIds.includes(recordId) &&
                                                <img src={minusSymbol} />
                                            }
                                            {!expandedParentIds.includes(recordId) &&
                                                <img src={plusSymbol} />
                                            }
                                            {record.fileName}
                                        </div>
                                    </div>
                                </td>
                                <td className="primary-row-import-date">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style overflow-import-date-style">
                                            {record.importDate}
                                        </div>
                                    </div>
                                </td>
                                <td className="primary-row-payment-source">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style overflow-payment-source-style">
                                            {record.paymentSource}
                                        </div>
                                    </div>
                                </td>
                                <td className="primary-row-count">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style overflow-count-style">
                                            {record.count}
                                        </div>
                                    </div>
                                </td>
                                <td className="primary-row-amount">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style overflow-amount-style">
                                            {record.amount}
                                        </div>
                                    </div>
                                </td>
                                <td className="primary-row-remove">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style">
                                            {(collapsedRecordDataStr !== "record0") &&
                                                <input id={'cbxRemove-parent-' + record.id} type="checkbox"
                                                    ref={input => this.cheackboxRefs[`cbxRemove-parent-${record.id}`] = input}
                                                    onClick={(x) => {
                                                        // prevent row click event
                                                        x.stopPropagation();
                                                        this.onParentRemoveClicked(x);
                                                    }}
                                                    checked={
                                                        parentRemoveCheckedIds.includes('cbxRemove-parent-' + record.id)
                                                    }
                                                    disabled={parentRemoveDisabledIds.includes('cbxRemove-parent-' + record.id)}
                                                />
                                            }
                                        </div>
                                    </div>
                                </td>
                            </>
                        }
                    </tr>
                    { expandedParentIds.includes(recordId) &&
                        <>
                            <tr className="child-header" style={{ display: 'block' }}>
                                <th className="child-header-payment-date">
                                    <div>
                                        <img src={i} />
                                    <button tabIndex={-1}>
                                            <td>
                                                Payment date
                                            </td>
                                        </button>
                                    </div>
                            </th>
                            <th className="child-header-payment-no" >
                                <div>
                                    <button tabIndex={-1}>
                                        <td>
                                            Payment no
                                            </td>
                                    </button>
                                </div>
                            </th>
                            <th class-name="child-header-provider-no" >
                                <div>
                                    <button tabIndex={-1}>
                                        <td>
                                            Provider ID
                                            </td>
                                    </button>
                                </div>
                            </th>
                            <th className="child-header-tax-id" >
                                <div>
                                    <button tabIndex={-1}>
                                        <td>
                                            Tax id
                                            </td>
                                    </button>
                                </div>
                            </th>
                            <th className="child-header-secondary" >
                                <div>
                                    <button tabIndex={-1}>
                                        <td>
                                            Secondary
                                            </td>
                                    </button>
                                </div>
                            </th>
                            <th className="child-header-matched" >
                                <div>
                                    <button tabIndex={-1}>
                                        <td>
                                            Matched
                                            </td>
                                    </button>
                                </div>
                            </th>
                            <th className="child-header-unmatched" >
                                <div>
                                    <button tabIndex={-1}>
                                        <td>
                                            Unmatched
                                            </td>
                                    </button>
                                </div>
                            </th>
                            <th className="child-header-amount" >
                                <div>
                                    <button tabIndex={-1}>
                                        <td>
                                            Amount
                                            </td>
                                    </button>
                                </div>
                            </th>
                            <th className="child-header-remove" >
                                    <div>
                                    <button tabIndex={-1}>
                                            <td>
                                                Remove
                                            </td>
                                        </button>
                                    </div>
                                </th>
                            </tr>

                            { childRecords && childRecords.map((childRecord, i, childRecords) => {
                                return (
                                    <tr className="child-row" style={{ display: 'block' }}>
                                        <td className="child-row-payment-date">
                                            {childRecord.id == childRecords[childRecords.length - 1].id &&
                                                < img src={l_middle} />
                                            }
                                            {childRecord.id != childRecords[childRecords.length - 1].id &&
                                                < img src={l_end} />
                                            }

                                            <div className="cell-component-wrapper cell-style">
                                                <div className="no-margin cell-style">
                                                    {childRecord.paymentDate}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="child-row-payment-no">
                                            <div className="cell-component-wrapper cell-style">
                                                <div className="no-margin cell-style overflow-payment-no-style">
                                                    {childRecord.paymentNo}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="child-row-provider-no">
                                            <div className="cell-component-wrapper cell-style">
                                                <div className="no-margin cell-style overflow-provider-no-style">
                                                    {childRecord.providerNo}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="child-row-tax-id">
                                            <div className="cell-component-wrapper cell-style">
                                                <div className="no-margin cell-style overflow-tax-id-style">
                                                    {childRecord.taxId}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="child-row-secondary">
                                            <div className="cell-component-wrapper cell-style">
                                                <div className="no-margin cell-style overflow-secondary-style">
                                                    {childRecord.secondary}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="child-row-matched">
                                            <div className="cell-component-wrapper cell-style">
                                                <div className="no-margin cell-style overflow-matched-style">
                                                    {childRecord.matched}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="child-row-unmatched">
                                            <div className="cell-component-wrapper cell-style">
                                                <div className="no-margin cell-style overflow-unmatched-style">
                                                    {childRecord.unmatched}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="child-row-amount">
                                            <div className="cell-component-wrapper cell-style">
                                                <div className="no-margin cell-style overflow-child-amount-style">
                                                    {childRecord.amount}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="child-row-remove">
                                            <div className="cell-component-wrapper cell-style">
                                                <div className="no-margin cell-style">
                                                    <input id={"cbxRemove-child-" + childRecord.id} type="checkbox" 
                                                        ref={input => this.cheackboxRefs[`cbxRemove-child-${childRecord.id}`] = input}
                                                        onClick={(x) => { this.onChildRemoveClicked(x); }}
                                                        checked={childRemoveCheckedIds.includes('cbxRemove-child-' + childRecord.id)}
                                                        disabled={childRemoveDisabledIds.includes('cbxRemove-child-' + childRecord.id)}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                            }
                        </>
                    }
                </>
            );
        }
        return row;
    }

    render() {
        const {
            treeviewData
        } = this.props.resultsGridState;

        const data = treeviewData?.parentData



        const columns = GridConfig.getColumnsConfig();
        const row = this.getCustomRow();
        const header = this.getCustomHeader();

        return (
            <GridWrapper>
                {treeviewData?.parentData &&
                    <ARMGrid
                        domID='sample-treeview-grid'
                        maxHeight={'350px'}
                        isFixedHeader={true}
                        columns={columns}
                        headerComponent={header}
                        rowComponent={row}
                        records={data}
                        emptyGridMessage=' '
                        selectionKey={'id'} />
                }
            </GridWrapper>
        )
    }
}
