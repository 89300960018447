import * as React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { ActionCreators, actionCreators, selectors, Selection, IDashboardAssignedClaimsState } from '@store/OperationalDashboard/DashboardAssignedClaims';
import { CheckBoxComponent } from '@common/CheckBox';
import { dollarFormatter } from './utils/formatter';

const ClaimsContainer = styled.div`   
    margin-bottom: 8px;
    width:480px;
`
const CustomLabelContainer = styled.div`
    font-weight:600;
    margin: 8px 0;
    padding-top: 15px
`
const TableContent = styled.div`
    overflow: auto;
    background-color: white;
    tbody{
        tr:nth-child(odd){
            background-color: white;
        }
        tr:nth-child(even){
            background-color: #F2F3F4;
        }
    }
`;
const AssignedClaimsFilter = () => {
    const dispatch = useDispatch();
    const selectedClaimsData = useSelector(selectors.userWorkGroupSummarySelector)
    //const selectedClaimUser = useSelector(selectors.selectedClaimUserSelector);
    const selectedFilterType = useSelector(selectors.claimTypeFilterSelector);
    const selectedUserName = useSelector(selectors.selectedClaimUserNameSelector);
    const updateClaimTypeFilter = (e: React.ChangeEvent<HTMLInputElement>, claimsDetil: any) => {
        dispatch(actionCreators.filterClaimsByType(claimsDetil.claims))
    }
    return (
        <div>
            <CustomLabelContainer>
                <label>Claim Details for {selectedUserName}</label>
            </CustomLabelContainer>
            <ClaimsContainer>
                <TableContent>
                    <table style={{
                        width: "100%",
                        padding: "5px",
                        background: '#EBECED',
                        borderCollapse:'collapse'
                    }}
                    >
                        <thead style={{
                            backgroundColor: '#fff',
                            padding: "6px",
                            color: "white"
                        }}>
                            <tr>
                                <td> </td>
                                <td style={{ paddingLeft: '18px', paddingRight: '25px', textAlign: 'left', fontSize:'12px', fontWeight:600, color:'#37474F' , paddingTop: '10px', paddingBottom:'10px'}}>Cliams</td>
                                <td style={{ paddingLeft: '25px', paddingRight: '25px', textAlign: 'center', fontSize: '12px', fontWeight: 600, color: '#37474F', paddingTop: '10px', paddingBottom: '10px' }}>Count</td>
                                <td style={{ paddingLeft: '8px', paddingRight: '8px', textAlign: 'right', fontSize: '12px', fontWeight: 600, color: '#37474F', paddingTop: '10px', paddingBottom: '10px' }}>Amount</td>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedClaimsData.map((ele: any, index: number) => <tr key={'_' + Math.random().toString(36).substr(2, 9)}>
                                <td>
                                    <CheckBoxComponent
                                        id={ele.userName}
                                        width={"20px"}
                                        checked={selectedFilterType === ele.claims}
                                        onChange={(e) => updateClaimTypeFilter(e, ele)}
                                    />
                                </td>
                                <td ><span style={{ fontSize: "14px", padding: '6px', marginLeft: '10px'}} > {ele.claims}</span></td>
                                <td ><span style={{
                                    fontSize: "14px",
                                    padding: '6px',
                                    float: 'right',
                                    marginRight:'39px'
                                }}>{ele.count && ele.count.toString().replace(/(.)(?=(\d{3})+$)/g, '$1,')}</span></td>
                                <td ><span style={{
                                    fontSize: "14px",
                                    padding: '6px',
                                    float: 'right'
                                }}>{dollarFormatter(ele.amount)}</span></td>
                            </tr>)}
                        </tbody>
                    </table>
                </TableContent>
            </ClaimsContainer>
        </div>
    )
}
export default AssignedClaimsFilter;