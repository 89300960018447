import React, { useMemo,useState,useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import styled from 'styled-components';
import { selectors, actionCreators } from '@store/OperationalDashboard/DashboardAssignedClaims';
import { VirtualGrid, LoadingIndicator } from '@optum-uicl/ui-core/dist';
import { saveStateToSession } from '../../../SessionStorage';


export const VirtualGridWrapper = styled.div`
    position: relative;
    .virtaul-grid-overlay{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #efefef;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`



export const GridWrapper = styled.div`
    #AssignedClaims-Grid {
        overflow-x: hidden;
        z-index: 5;
        width: 1005px;
        .grid-header{
            background:#9ca0a6 !important;
        }
        .grid-body{
            background:#fff;
        }
        .grid-cell{
            overflow-x:hidden;
        }
        .claimButton{
            margin-left:3px;
            margin-top:10px;
            margin-bottom:3px;
        }            
    
    }
    div[id^='AssignedClaims-Grid_']{
        
        .grid-header .selection-column span, .grid-body .ReactVirtualized__Table__row .selection-column{            
                    visibility:visible !important;               
            
        }
        .grid-header .grid-header-columns .grid-header-column svg{
            min-width:10px !important;
        }
        .grid-body .ReactVirtualized__Table__rowColumn{
            align-self: center;
        }
    }
`;

const ARMVirtualGrid = (props: any) => {
    const { overlayComponent } = props;

    return (
        <VirtualGridWrapper>
            <VirtualGrid {...props} />
            {overlayComponent &&
                <div className="virtaul-grid-overlay">
                {overlayComponent}
                </div>
            }
        </VirtualGridWrapper>
    )
}


const ClaimDetails = (props : any) => {
    const claimsArr = useSelector(selectors.selectFilteredClaims);    
    const columns = useSelector(selectors.columnsWithSortingSelector);
    const sorts : string = useSelector(selectors.selectedSortOrderSelector);
    const requestStatus = useSelector(selectors.claimDataStatusSelector);
    const selectedClaims = useSelector(selectors.reassingClaimsSetSelector)
    const { setClaimListForCFI } = props;
    const dispatch = useDispatch();
    const [allChecked, setAllChecked] = useState(false);


    const splitSortedString = (sortDirection: any) => {

        switch (sortDirection) {
            case 'SORT_ASCENDING':
                return 'desc';
                break;
            case 'SORT_DESCENDING':
                return 'asc';
                break;
        }
    }

    const splitSortedColumn = (column: any) => {

        switch (column) {
            case 'patientName':
                return 'patientLast';
                break;
            case 'assigned':
                return 'daysAssigned';
                break;
            case 'formType':
                return 'typeOfForm';
                break;
        }
    }

  

    const handleClick = (claimId: string, assignedUserId: string) => {
        let direction = sorts.split("|");
        let finalString = (direction[0] == "assigned" ? "daysAssigned" : direction[0]) + "," + splitSortedString(direction[1]);
        let sortScrubbed = finalString.replace("patientName", "patientLast");
        let sortScrubbedFinal = sortScrubbed.replace("formType", "typeOfForm");
        const SELECTED_TAB_INDEX = 2;

       
        let payloadRequest = {
            id: assignedUserId,
            claimId: claimId,
            claimType: props.claimTypeSelection.charAt(0), //send type T as well
            sortOrder: sortScrubbedFinal
        };


        saveStateToSession({
            dashboardDefault:
            {
                selectedTabIndex: SELECTED_TAB_INDEX
            },
            dashboardAssignedClaims: {
                workGroupSummaryData: props.workGroupSummaryData,
                workGroupData: props.workGroupData,
                selectedGroup: props.selectedGroup,
                selectedView: props.selectedView,
                exportToCsvPath: props.exportToCsvPath,
                exportToCsvUserPath: props.exportToCsvUserPath,
                pageIndex: props.pageIndex,
                pageLength: props.pageLength,
                claimTypeSelection: props.claimTypeSelection,
                sortOrder: props.sortOrder,
                userSelection: props.userSelection,
                filterInput: props.filterInput,
                uiLock: props.uiLock,
                selectedClaimUser: props.selectedClaimUser,
                claimDataStatus: props.claimDataStatus,
                claimsData: props.claimsData,
                assignableUsers: props.assignableUsers,
                selectedAssignedUser: props.selectedAssignedUser
            }
        })

         setClaimListForCFI(payloadRequest, props.history);
     
    }



    const formattedData = claimsArr.map((obj: any) => ({
        ...obj,
        'ViewCFI': {
            ...obj.ViewCFI,
            onClick: (e: any) => handleClick(obj.claimId, obj.assignedUserId,)
        }

    }))

    

    const renderOverlay = useMemo(() => {
        if (requestStatus === 'PENDING') {
            return <LoadingIndicator />
        }
        return null;
    }, [requestStatus])


    const handleRowSelect = (e: any, state: any) => {
        //console.log("state from row select, claimsDetails:", state)
        //reassingClaimsList
        dispatch(actionCreators.reassingClaimsList(state.selectedItemIds))
    }

    const handleSelectAll = (e: any, state: any) => {
        //console.log("state from all select, claimsDetails:", state)
        //reassingClaimsList

        // We also check the selectedItemIds length to ensure it hasn't been cleared
        if (allChecked && state.selectedItemIds.length) {
            const dataArray = [];
            let data = Array.from(state.selectedItemIds)
            let i: any;
            let j: any = data?.length;
            const selectedList = 100
            // Why are we using a hard-coded number here to loop against? 
            for (i = 0, j = data.length; i < j; i += selectedList) {
                dataArray.push(data?.slice(i, i + selectedList))
            }
            dataArray?.map(item =>
                dispatch(actionCreators.reassingClaimsList(item))
            )
        } else
            dispatch(actionCreators.reassingClaimsList(state.selectedItemIds))
        
        setAllChecked(!allChecked)
    }

    // @todo we can move this 
    function numerically(a: any, b: any){
      //  return a - b;
        if(a < b){
           return -1;
        } else if(a > b){
           return 1;
        } else {
           return 0;
        }
    }


    const onSortGrid = (columnDataName: any, sortDirection: any, records: any) => {
        let sortedArr = [];

        if(columnDataName === 'totalCharges'){
            if(sortDirection === 'SORT_ASCENDING'){
                sortedArr = records.sort((a: any, b: any) => {
                    const valueA = parseInt(a[columnDataName].replace('$', '').replace(/,/g, ''))
                    const valueB = parseInt(b[columnDataName].replace('$', '').replace(/,/g, ''))
                    return numerically(valueB, valueA)
                })
            }else{
                sortedArr = records.sort((a: any, b: any) => {
                    const valueA = parseInt(a[columnDataName].replace('$', '').replace(/,/g, ''))
                    const valueB = parseInt(b[columnDataName].replace('$', '').replace(/,/g, ''))
                    return numerically(valueA, valueB)
                })
            }
            return sortedArr;
        }
        if(columnDataName === 'assigned'){
            if(sortDirection === 'SORT_ASCENDING'){
                sortedArr = records.sort((a: any, b: any) => {
                    const valueA = parseInt(a[columnDataName].replace(/,/g, ''))
                    const valueB = parseInt(b[columnDataName].replace(/,/g, ''))
                    return numerically(valueB, valueA)
                })
            }else{
                sortedArr = records.sort((a: any, b: any) => {
                    const valueA = parseInt(a[columnDataName].replace('$', '').replace(/,/g, ''))
                    const valueB = parseInt(b[columnDataName].replace('$', '').replace(/,/g, ''))
                    return numerically(valueA, valueB)
                })
            }
            return sortedArr;
        }

        if (columnDataName === 'ViewCFI') {
      
           
        }

        if(sortDirection === 'SORT_ASCENDING'){
            sortedArr = records.sort((a: any, b: any) => b[columnDataName].localeCompare(a[columnDataName]))
        }else{
            sortedArr = records.sort((a: any, b: any) =>  a[columnDataName].localeCompare(b[columnDataName]))
        }
        return sortedArr;

    }

    const onSortGridColumn = (e: any, state: any) => {
        dispatch(actionCreators.sortClaimsData(state.sortingKey))
        scrollToTop()
    }


    const scrollToTop = () => {
        const tableToScroll = document.querySelector('.ReactVirtualized__Grid');
        if (tableToScroll) {
            tableToScroll.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }



    return (
        <GridWrapper>
            <ARMVirtualGrid
                domID={"AssignedClaims-Grid_" + props.claimTypeSelection.charAt(0) + Date.now()}
                overlayComponent={renderOverlay}
                supportSelection={true}
                selectionKey={"selected"}
                columns={new Set(columns)}
                isFixedHeader
                records={formattedData}
                onRowSelect={handleRowSelect}
                sortingComparator={onSortGrid}
              //initialSortingKey='patientName|SORT_DESCENDING '
                initialSortingKey={sorts ? sorts : 'patientName | SORT_DESCENDING' }
                onSortGridColumn={onSortGridColumn}
                initialSelectedItemIds={selectedClaims}
                onSelectAll={handleSelectAll}
            />
        </GridWrapper>
    )
}

export default ClaimDetails