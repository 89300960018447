import { ActionTypes, createDataAction } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import { Reducer } from 'redux';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IAllowedIpMaintenanceUiState {
    selectedAddress: ISelectIpAddressData;
    remoteIpAddress: string;
    remoteIpAddressError?: string;
    showCommitWithoutClientIp: boolean;
    showCommitDirtyLeaveThePage: boolean;
}

interface ICommitWithoutClientIpCheck {
    value: boolean;
}


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface ISelectIpAddressData {
    index: number;
    value: string;
};

export const defaultAddress: ISelectIpAddressData = {
    index: 0,
    value: '',
};

export interface ISelectIpAddress extends ICrudActionData<MCRemoteIpAddress, ISelectIpAddressData> { }
export interface ICommitCheck extends ICrudActionData<MCRemoteIpAddress, ICommitWithoutClientIpCheck> { }

export interface IModifyRemoteIpAddress extends ICrudActionData<MCRemoteIpAddress, MCRemoteAddress> {
    delta: MCRemoteIpAddress
}

export const actionCreators = {
    selectRemoteAddress: (selectInfo: ISelectIpAddress) => createDataAction('SELECT_REMOTE_ADDRESS', selectInfo),
    updateRemoteAddressValue: (remoteAddress: string) => createDataAction('UPDATE_REMOTE_ADDRESS_VALUE', remoteAddress),
    addRemoteAddress: (updateInfo: IModifyRemoteIpAddress) => createDataAction('ADD_REMOTE_ADDRESS', updateInfo),
    updateRemoteAddress: (updateInfo: IModifyRemoteIpAddress) => createDataAction('UPDATE_REMOTE_ADDRESS', updateInfo),
    removeRemoteAddress: (updateInfo: IModifyRemoteIpAddress) => createDataAction('REMOVE_REMOTE_ADDRESS', updateInfo),
    toggleCommitWithoutClientIpConfirm: (fieldInfo: ICommitCheck) => createDataAction("TOGGLE_COMMIT_WITHOUT_CLIENT_IP_CONFIRM", fieldInfo),
    toggleCommitDirtyLeaveThePageConfirm: (fieldInfo: ICommitCheck) => createDataAction("TOGGLE_COMMIT_DIRTY_LEAVE_THE_PAGE", fieldInfo),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IAllowedIpMaintenanceUiState = {
    selectedAddress: defaultAddress,
    remoteIpAddress: '',
    showCommitWithoutClientIp: false,
    showCommitDirtyLeaveThePage: false,
};

function formatIp(rawIp: string): string {
    const ipPattern = /^(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.)(([0-9\\*]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){2}([0-9\\*]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/;
    if (!ipPattern.test(rawIp)) {
        return '';
    }

    let foundWildCard: boolean = false;
    const elements = rawIp.split('.');
    for (var index = 0; index < elements.length; ++index) {
        if (elements[index] === '*') {
            foundWildCard = true;
        } else {
        var octet: number = +elements[index]; // convert to number
            if ((octet < 0) || (octet > 255) || foundWildCard) {
                return '';
            }
            elements[index] = '' + octet;
        }
    }

    let formattedIp: string = elements[0];
    if (elements.length > 1) {
        formattedIp += `.${elements[1]}`;
        if (elements.length > 2) {
            formattedIp += `.${elements[2]}`;
            if (elements.length > 3) {
                formattedIp += `.${elements[3]}`;
            }
        }
    }
    return formattedIp;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IAllowedIpMaintenanceUiState, KnownActions> = (state: IAllowedIpMaintenanceUiState | undefined, action: KnownActions) => {
    var remoteAddress: MCRemoteAddress;
    if (state != undefined) {
        switch (action.type) {
            case 'SELECT_REMOTE_ADDRESS':
                if (action.data.masterCrud) {
                    var selIndex = action.data.uiData.index - 1;
                    var addresses = action.data.masterCrud.RemoteAddressesMaintenanceInfo.RemoteAddresses.RemoteAddress;
                    var remoteAddressData = addresses[selIndex];
                    return {
                        ...state,
                        selectedAddress: action.data.uiData,
                        remoteIpAddress: remoteAddressData ? remoteAddressData["@IP"] || '' : '',
                        noteTypeNameError: undefined,
                        noteTypeDescriptionError: undefined,
                    };
                }
                break;
            case 'UPDATE_REMOTE_ADDRESS_VALUE':
                return {
                    ...state,
                    remoteIpAddress: action.data,
                };
            case 'ADD_REMOTE_ADDRESS':
            case 'UPDATE_REMOTE_ADDRESS':
                if (action.data.masterCrud) {
                    var masterCrud = action.data.masterCrud;
                    remoteAddress = action.data.uiData;
                    var newStateData: IAllowedIpMaintenanceUiState = { ...state };
                    newStateData.remoteIpAddressError = '';
                    if (remoteAddress["@IP"] === '') {
                        newStateData.remoteIpAddressError = 'Please enter an IP address.';
                        return newStateData;
                    }
                    var formattedIp = formatIp(remoteAddress['@IP']);
                    if (!formattedIp) {
                        newStateData.remoteIpAddressError = 'Invalid IP address.';
                        return newStateData;
                    }
                    remoteAddress["@IP"] = formattedIp;
                    if (masterCrud.RemoteAddressesMaintenanceInfo &&
                        masterCrud.RemoteAddressesMaintenanceInfo.RemoteAddresses &&
                        masterCrud.RemoteAddressesMaintenanceInfo.RemoteAddresses.RemoteAddress &&
                        masterCrud.RemoteAddressesMaintenanceInfo.RemoteAddresses.RemoteAddress
                            .find(existingIpAddress => existingIpAddress["@IP"] === remoteAddress["@IP"])) {
                        newStateData.remoteIpAddressError = "That IP already exists. Please type in a different IP Address.";
                        return newStateData;
                    }

                    var updateIndex = state.selectedAddress.index;
                    var updateValue = state.selectedAddress.value;
                    if (masterCrud &&
                        masterCrud.RemoteAddressesMaintenanceInfo &&
                        masterCrud.RemoteAddressesMaintenanceInfo.RemoteAddresses &&
                        masterCrud.RemoteAddressesMaintenanceInfo.RemoteAddresses.RemoteAddress) {
                        if (action.type === 'ADD_REMOTE_ADDRESS') {
                            masterCrud.RemoteAddressesMaintenanceInfo.RemoteAddresses.
                                RemoteAddress.push(JSON.parse(JSON.stringify(remoteAddress)));
                            updateIndex = masterCrud.RemoteAddressesMaintenanceInfo.
                                RemoteAddresses.RemoteAddress.length;
                            updateValue = remoteAddress['@ID'];
                            action.data.delta.RemoteAddressesMaintenanceInfo.RemoteAddresses.
                                RemoteAddress.push(JSON.parse(JSON.stringify(remoteAddress)));
                        } else {
                            var addressToUpdate = masterCrud.RemoteAddressesMaintenanceInfo.
                                RemoteAddresses.RemoteAddress[state.selectedAddress.index - 1];
                            addressToUpdate['@IP'] = remoteAddress['@IP'];
                            updateIndex = state.selectedAddress.index;
                            var existingDelta = action.data.delta.RemoteAddressesMaintenanceInfo.
                                RemoteAddresses.RemoteAddress
                                .find(existingAddress => existingAddress['@ID'] === updateValue);
                            if (existingDelta) {
                                existingDelta['@IP'] = remoteAddress['@IP'];
                            } else {
                                action.data.delta.RemoteAddressesMaintenanceInfo.RemoteAddresses.
                                    RemoteAddress.push(JSON.parse(JSON.stringify(addressToUpdate)));
                            }
                        }
                    }
                    newStateData.selectedAddress = {
                        index: updateIndex, 
                        value: updateValue,
                    };
                    newStateData.remoteIpAddress = remoteAddress["@IP"] || '';

                    return newStateData;
                }
                break;
            case 'REMOVE_REMOTE_ADDRESS':
                if (action.data.masterCrud) {
                    remoteAddress = action.data.uiData;
                    var index = state.selectedAddress.index - 1;
                    if (index > -1) {
                        // remove from delta
                        if (('' + remoteAddress['@ID']).indexOf('New[') > -1) {
                            var existingDeltaIndex = action.data.delta.RemoteAddressesMaintenanceInfo.RemoteAddresses
                                .RemoteAddress
                                .findIndex(existingAddress => existingAddress['@ID'] === remoteAddress['@ID']);
                            if (existingDeltaIndex > -1) {
                                action.data.delta.RemoteAddressesMaintenanceInfo.RemoteAddresses.RemoteAddress
                                    .splice(existingDeltaIndex, 1);
                            }
                        } else {
                            var existingDeltaToDelete = action.data.delta.RemoteAddressesMaintenanceInfo.
                                RemoteAddresses.RemoteAddress
                                .find(existingAddress => existingAddress['@ID'] === remoteAddress['@ID']);
                            if (existingDeltaToDelete) {
                                existingDeltaToDelete['@ID'] = existingDeltaToDelete['@ID'];
                                existingDeltaToDelete['@Delete'] = true;
                            } else {
                                var newDeleteItem = JSON.parse(JSON.stringify(remoteAddress));
                                newDeleteItem['@ID'] = newDeleteItem['@ID'];
                                newDeleteItem['@Delete'] = true;
                                action.data.delta.RemoteAddressesMaintenanceInfo.RemoteAddresses.
                                    RemoteAddress.push(newDeleteItem);
                            }
                        }

                        // remove from mastercrud data
                        var newIndex = state.selectedAddress.index - 1;
                        if (state.selectedAddress.index < action.data.masterCrud.RemoteAddressesMaintenanceInfo.RemoteAddresses.RemoteAddress.length) {
                            newIndex = state.selectedAddress.index;
                        }
                        if (newIndex < 0) {
                            newIndex = 0;
                        }

                        action.data.masterCrud.RemoteAddressesMaintenanceInfo.RemoteAddresses.RemoteAddress.splice(index, 1);

                        var selectedNode = undefined;

                        var selectedValue = '';
                        if (newIndex > 0 && action.data.masterCrud.RemoteAddressesMaintenanceInfo.
                            RemoteAddresses.RemoteAddress.length >= newIndex) {
                            selectedNode = action.data.masterCrud.RemoteAddressesMaintenanceInfo.
                                RemoteAddresses.RemoteAddress[newIndex - 1];
                            selectedValue = selectedNode?.["@IP"] || '';
                        }

                        var newState =
                        {
                            ...state,
                            selectedAddress: {
                                index: newIndex,
                                value: selectedValue
                            },
                            remoteIpAddress: selectedValue,
                        };
                        return newState;
                    }
                    //delete action.data.masterCrud.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType[state.selectedNoteType.index - 1];
                    return {
                        ...state,
                    };
                }
                break;
            case "TOGGLE_COMMIT_WITHOUT_CLIENT_IP_CONFIRM":
                return {
                    ...state,
                    showCommitWithoutClientIp: action.data.uiData.value
                };
            case "TOGGLE_COMMIT_DIRTY_LEAVE_THE_PAGE":
                return {
                    ...state,
                    showCommitDirtyLeaveThePage: action.data.uiData.value
                };
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
};
