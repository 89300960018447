import { Colors } from '@commonResources/colorVariables';
import * as React from 'react';
import styled from 'styled-components';
import {BigHelp, ClearSecuritySession} from '../../commonResources/window';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { UrlFromApi } from './/UrlFromApi';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { pageLeave } from '@commonResources/userModified';
import {Redirect} from "react-router";
import {URLs} from "@commonResources/constants";
import {getRawToken} from "@scripts/session/SecurityToken";
import {ModalLoading} from "@common/ModalLoading";

// #region Styled Divs
// ${(props: IProps) => props.testMode && props.testMode == true && `background-color: #f42a4d !important` };

export const ArmMenuParentDiv = styled.div`
      background-color: ${Colors.defaultLight};
      border-radius: 0;
      width: 100%;
      border: 1px;
      height: 21px;
      z-index: 100;
      


    position: fixed;
    ${(props: IProps) => props.Top && props.Top.length > 0 ? `top: ${props.Top};` : `top: 50px`};

     margin: 0px auto;

    line-height: 20px;
    font-size: 14px;
    color: ${Colors.black};

    * > .testModeLabel{
        position: fixed;
        z-index: 101;
        ${(props: IProps) => props.Top && props.Top.length > 0 ? `top: ${props.Top};` : `top: 50px`};
        left: 50px;
        width: 100px;
        color: ${Colors.white};
        vertical-align: middle;
        font-size: 9pt;
        padding-top: 2px;
        font-weight: 600;
        cursor: default;
    }


    *, * :before, * :after {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    * .navbar{
      position: relative;
      z-index: 100;
        min-height: 21px
      margin-bottom: 20px;
      border: 1px solid transparent;
    }

    * .navbar:before,* .navbar:after, *.nav:before, * .nav:after {
      display: table;
      content: " ";
    }

    * .navbar:after, *.nav:after {
      clear: both;
    }

* .nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}


* .nav>li {
  position: relative;
  display: block;
}

* .nav>li>a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

* .nav>li>a:hover,.nav>li>a:focus {
  text-decoration: none;
  background-color: #eee;
}

* .nav>li.disabled>a {
  color: #999;
}

* .nav>li.disabled>a:hover,* .nav>li.disabled>a:focus {
  color: #999;
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
}

* .nav .open>a,* .nav .open>a:hover,* .nav .open>a:focus {
  background-color: #eee;
  color: ${Colors.black} !important;
  border-color: #428bca;
}
* .main-dropdown .open>a,* .main-dropdown .open>a:hover,* .main-dropdown .open>a:focus {
  background-color: #CDDDFE;
  color: #002677 !important;
  border-color: #428bca;
}

* .nav .nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}


* .navbar-nav {
  margin: 7.5px -15px;
}
* .dropdown {
  position: relative
}

* .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  background-clip: padding-box;
}


@media(min-height: 300px) and (max-height: 800px){
  .main-dropdown  {
    max-height: 75vh;
    overflow-y: auto;
  }
  
 .assuranceMenu .open > .dropdown-menu > .open > .dropdown-menu {
    position: fixed;
    overflow-y: auto;
    max-height: 30vh;
    padding-top: 0px;
    padding-bottom: 0px;
    display: block;
  }
}


* .open>.dropdown-menu {
  display: block;
}

* .open>a {
  outline: 0;
}


* .navbar-nav .open .dropdown-menu>li>a,
* .navbar-nav .open .dropdown-menu .dropdown-header {
  padding: 5px 15px 5px 25px;
}

* .navbar-nav .open .dropdown-menu>li>a {
  line-height: 18px;
}

* .navbar-nav .open .dropdown-menu>li>a:hover,
* .navbar-nav .open .dropdown-menu>li>a:focus {
  background-image: none;
}

* .navbar-nav{
        float: left;
        margin: 0;
        }

  *  .navbar-nav>li {
      float: left;
    }

}

`;

export const ArmMenuNavBar = styled.nav`
    margin: 0px auto;
    display: flex;
    min-height: 21px;
    height: 21px;
    margin-bottom: 20px;
    justify-content: center;
    position: absolute;
    z-index: 100;

${(props: any) => props.testMode && props.testMode == true && `background-color: #f42a4d !important;`};



@media(min-width:1200px) {
  .container{
        max-width: 1170px;
${(props: any) => props.testMode && props.testMode == true && `max-width: 870px;`};

}

     .container {
        height: 21px;
        display: flex;
        padding-left: 0px;
        align-items: center;
        justify-content: center;


    } 
.container:before,.container:after {
  display: table;
  content: " "
}

.container:after {
  clear: both
}

`;



export const ArmMenuLi = styled.li`
   cursor: default;
   white-space: nowrap;

    .divider {
      height: 1px;
      width: 95%;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      background-color: ${Colors.middleSecondaryGrey}!important;
        }


`;


export const ArmMenuUl = styled.ul`
  font-size: 12px;
  line-height: 17px;
  font: ${Colors.darkestSecondaryGrey} !important;
  background: ${Colors.white}!important;
  border: 1px solid ${Colors.defaultLight};
   
  display:none;


`;

export const ArmMenuAnchor = styled.a`
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #333;
  white-space: nowrap;
  outline: 0;
  color: ${Colors.black};
  text-decoration: none;
  

   :hover, :focus{
      color: ${Colors.white};
      text-decoration: none;
      background-color: #428bca;
    }

   span.caret{
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 5px;
      vertical-align: middle;
      border-top: 4px solid #000;
      border-right: 4px solid transparent;
      border-bottom: 0 dotted;
      border-left: 4px solid transparent;
      content: "";
      }

`;



export const ArmMenuNavBarAnchor = styled.a`
  display: block;

  padding: 10px;
  margin-top: -1px;
  line-height: 0px;
  
  font-weight: normal;

  color: #333;
  white-space: nowrap;
  color: ${Colors.white};
  text-decoration: none;
  

   :hover, :focus{
      color: ${Colors.white};
      text-decoration: none;
      background-color: #428bca;
    }



`;

// #endregion 




class ArmMenu {
    Id: string;
    Name: string;
    Type: "Menu" | "Submenu" | "Separator" | "Item"; /// | "Message" | "Dynamic";
    Submenu?: ArmMenu[];
    Link?: string;
    Url?: UrlFromApi;
    Caret?: string;

    constructor() {
        this.Id = "";
        this.Name = "";
        this.Type = "Item";
    }
}


/// helper functions

function isItemDefined(item: any) {
    if (typeof item === 'undefined' || item === null) {
        return false;
    }
    return true;
}

interface IProps {
    Name?: string,
    MenuUrl: string,
    PullRight?: any,
    menuClass?: string,
    subMenuClass?: string,
    itemClass?: string,
    Top?: string,
    Cancel?: boolean;
    stayEvent?: any;
    testMode?: boolean;
}

type IOwnProps = IProps & RouteComponentProps<{}>;

interface IAssuranceMenuState {
    data: ArmMenu[],
    openMenu: String[],
    leave: boolean,
    path: string,
    funcCallBusy: boolean;
}

// Author notes:  this is my attempt at recreating the logic provided by React.Bootstrap
// i worry that there is alot of logic that i am unaware of when recreating the logic
// therefore, i am implementing the logic i see us using, and i only know what i know (and see)
//
// if this is ever to be reused, i wish i could easily split the "display" from the menu, but this
// implementation is tied to the styled divs, and since timelines, i didn't put any thought in how
// to achieve that...perhaps you can take the time to split 
export class AssuranceMenu extends React.Component<IOwnProps, IAssuranceMenuState>
{
    isStillMounted: boolean = false;
    insideMenu: any;
    constructor(props: IOwnProps) {
        super(props);
        this.state =
        {
            data: [],
            openMenu: [],   // array of menu ids with nesting matching array index menu/submenu1/submenu2/etc.,
            path: '',
            leave: false,
            funcCallBusy: false
        }

        this.insideMenu = React.createRef();
    }

    mySetState(newState: any, callback: any | undefined = undefined) {
        if (this.isStillMounted) {
            if (callback) {
                this.setState(newState, callback);
            } else {
                this.setState(newState);
            }
        }
    }

    componentWillUnmount() {
        // this is a hack to know when the user clicked outside the menu, which should close it - if this doesn't work possibly move back to blur
        document?.querySelector('body')?.removeEventListener("click", this.handleClick, false);

        this.isStillMounted = false;
    }
    componentDidMount() {
        this.isStillMounted = true;
        this.loadData();
        if (this.props.Cancel) {
            this.setState({
                leave: true, path: '/LandingPage'
            })
        }
        // this is a hack to know when the user clicked outside the menu, which should close it - if this doesn't work possibly move back to blur
        if (document && document.querySelector('body'))
            document?.querySelector('body')?.addEventListener("click", this.handleClick.bind(this), false);


    }

    isMenuOpen() {
        if (this.state.openMenu && this.state.openMenu.length > 0) {
            return true;
        }
        return false;
    }
    closeMenu() {
        this.mySetState(
            {
                openMenu: [],
            });
    }


    handleClick(e: any) {
        if (this.isMenuOpen() &&
            (this.insideMenu && this.insideMenu.current && e && e.target &&
                !this.insideMenu.current.contains(e.target))) {
            e.preventDefault();
            e.stopPropagation();
            this.closeMenu();
        }
    };

    loadData() {
        if (isItemDefined(this.props.MenuUrl)) {
            //       console.log("AssuranceMenu: Loading from [" + this.props.MenuUrl + "]");

            fetch(this.props.MenuUrl,
            {
                headers: {
                    'Authorization': `${getRawToken()}`
                }
            })
                .then(response => {
                    if (!this.isStillMounted) return;

                    if (response.status === 200) {
                        return response.json();
                    } else {
                        if (response.status === 401 || response.status === 500) {
                            console.log('security credentials are corrupt');
                            this.props.history.push('/Transactions/Logon');
                        } else {
                            throw new Error(response.statusText);
                        }
                    }
                })
                .then(responseData => {
                    if (!this.isStillMounted) return;
                    this.mySetState(
                        {
                            data: responseData,
                        });
                })
                .catch(error => {
                    if (!this.isStillMounted) return;

                    this.mySetState(
                        {
                            data: [],
                        });
                });
        }
    }


    onMenuSelected(e: React.MouseEvent<HTMLAnchorElement>, item: ArmMenu) {
        //if (!item || item && !item.Url) return;

        if (!item) return;

        if (!item.Url && item.Link) {
            eval(item.Link);
        }

        if (!item.Url && !item.Link) return;

        this.mySetState(
            {
                openMenu: [],   // close the menu, then make call
            }, this._onMenuSelected(item));
    }

    _onMenuSelected(item: ArmMenu) {
        //console.log("menuSelected + " + item.Url);

        //Open a new help window to the target path if necessary.
        if (item.Url?.Type === "BigHelpWindow") {
            BigHelp(item.Url?.Path);
        } else if (item.Url?.Type === "Window") {
            window.open(item.Url?.Path);
        } else if (item.Url?.Type === "Func") {
             if (item.Url?.Path.includes("/Administration/CheckDataAlignment/")) {
                this.CheckDataAlignment(item.Url?.Path);
            }
        }
        //Otherwise just navigate to the link or injected link.
        else {
            if (item.Url?.Path == "Logout") {

                ClearSecuritySession();
                var logoffPath = sessionStorage.getItem('LogOffUrl');
                if (logoffPath && logoffPath.length > 0) {
                    if (logoffPath.endsWith('.asp')) logoffPath = logoffPath.substr(0, logoffPath.length - 4);
                    this.props.history.push(logoffPath);
                } else {
                    this.props.history.push('/Transactions/Logon');
                }
            } else if (item.Url?.Path) {
                const userModified: any = sessionStorage.getItem("userModified")
                if (userModified === 'true' && this.props.location.pathname !== item.Url.Path) {
                    this.setState({ leave: true, path: item.Url.Path })
                } else {
                    this.props.history.push(item.Url.Path);
                }
            }

        }

    }

    CheckDataAlignment(path: String) {
        console.log("AssuranceMenu - CheckDataAlignment");
        this.setState({ funcCallBusy: true });

        const pathRoot = "/Administration/CheckDataAlignment/";
        let params = path as string;
        const index = params.lastIndexOf(pathRoot);
        if (index >= 0) {
            params = params.substr(index + pathRoot.length);
        }
        let mypath: string = '';
        console.log("AssuranceMenu - CheckDataAlignment - url=" + URLs.api + '/api/data/checkDataAlignment/' + params);

        fetch(URLs.api + '/api/data/checkDataAlignment/' + params,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                }
            }).then(async (response) => {
                if (response.status === 200) {
                     return response.json();
                } else {
                    throw new Error(response.statusText);
                }
            }).then((data: any) => {
                mypath = data.PrintResponses[0].Path;
                console.log("AssuranceMenu - CheckDataAlignment - path=" + mypath);
                window.document.open("/shared/Open%3D" + mypath, 'FormAlignment', 'width=1273,height=1250');
            })
            .catch(error => {
                console.log("AssuranceMenu - CheckDataAlignment - Error");
                alert('Check Data Alignment request failed. Please try again. If the issue persists, please contact Support.');
                //sessionStorage.setItem('SystemErrorNotification', 'Check Data Alignment request failed. Please try again. If the issue persists, please contact Support.');
            })
            .finally(() => {
                this.setState({ funcCallBusy: false });
                this.props.history.push('/ui/LandingPage');
            });
    }

    isOpen(id: String, level: number) {
        if (this.state.openMenu && this.state.openMenu[0]) {
            if (this.state.openMenu[level] === id)
                return true;
        }
        return false;
    }

    onMenuClick(e: any, id: string, depth: number, menuItem: ArmMenu) {
        e.preventDefault();

        let newMenu = [];

        for (var i = 0; i < this.state.openMenu.length && i < depth; i++) {
            newMenu.push(this.state.openMenu[i]);
        }
        if (!this.state.openMenu[depth] || (this.state.openMenu[depth] && this.state.openMenu[depth] !== id))
            newMenu.push(id);

        this.mySetState(
            {
                openMenu: newMenu,
            });

        this.positionSubMenu(e, `submenu-${menuItem.Id}`)
    }

    //return<ReactBootstrap.NavDropdown id = {menuItem.Name} className = { this.props.menuClass } noCaret = { true} title = { menuItem.Name } href = "#" > { menuItems }</ReactBootstrap.NavDropdown >;
    getMenu(menuItem: ArmMenu, menuItems: any, depth: number) {
        return <ArmMenuLi id={menuItem.Id} key={menuItem.Id} className={this.isOpen(menuItem.Id, 0) ? "dropdown open" : "dropdown"}>
            <ArmMenuNavBarAnchor aria-haspopup={true} className="dropdown-toggle" role="button" onClick={(e: any) => this.onMenuClick(e, menuItem.Id, depth, menuItem)} >
                <span>{menuItem.Name}</span>
            </ArmMenuNavBarAnchor>
            <ArmMenuUl key={"menu" + menuItem.Name}  className="main-dropdown dropdown-menu" role="menu">{menuItems}</ArmMenuUl></ArmMenuLi>;
    }

    getCaret() {
        return <span className="caret"></span >;
    }
    //return<ReactBootstrap.NavDropdown id = {menuItem.Name} className = { this.props.subMenuClass } title = { menuItem.Name } href = "#" > { menuItems }</ReactBootstrap.NavDropdown >;
    getSubMenu(menuItem: ArmMenu, menuItems: any, depth: number) {
        return (
            <>
                <ArmMenuLi id={menuItem.Id} key={menuItem.Id} className={this.isOpen(menuItem.Id, depth) ? "dropdown open" : "dropdown"} >
                    <ArmMenuAnchor aria-haspopup={true} id={`anchor-${menuItem.Id}`} className="dropdown-toggle" role="button" onClick={(e: any) => this.onMenuClick(e, menuItem.Id, depth, menuItem)}>
                        <span id={`menuItem-${menuItem.Id}`}>{menuItem.Name}</span>
                        {!menuItem.Caret && <span className="caret"></span >}
                    </ArmMenuAnchor>
                    <ArmMenuUl className="dropdown-menu submenu" id={`submenu-${menuItem.Id}`} role="menu" key={"submenu" + menuItem.Name}>
                        {menuItems}
                    </ArmMenuUl>
                </ArmMenuLi>
            </>
        )
    }

    //return<ReactBootstrap.NavItem id = {menuItem.Id} key = { menuItem.Id } className = { this.props.itemClass } eventKey = { menuItem.Link } onSelect = { this.menuSelected } href = "#" > { menuItem.Name }</ReactBootstrap.NavItem >;
    getItem(menuItem: ArmMenu) {
        return <ArmMenuLi className={this.props.itemClass} key={menuItem.Id} role="presentation">
            <ArmMenuAnchor id={menuItem.Id} onClick={(event: React.MouseEvent<HTMLAnchorElement>) => this.onMenuSelected(event, menuItem)} role="button" >{menuItem.Name}</ArmMenuAnchor>
        </ArmMenuLi >;
    }

    getDivider(keyIndex: number) {
        return <ArmMenuLi className="divider" key={"divider" + keyIndex} role="presentation"><ArmMenuAnchor role="button" href="#" ></ArmMenuAnchor></ArmMenuLi>;
    }
    getMenuData(data: ArmMenu[] | undefined, depth: number): any {
        if (!data) return;

        var menuItems = null;
        if (isItemDefined(data)) {
            var keyIndex = 0;
            var menuData = data.map((menuItem) => {
                keyIndex++;
                //console.log("processing " + menuItem.Name);
                if (isItemDefined(menuItem.Type)) {
                    switch (menuItem.Type) {
                        case "Menu":
                            menuItems = this.getMenuData(menuItem.Submenu, depth + 1);
                            return this.getMenu(menuItem, menuItems, depth);
                        //return <ReactBootstrap.NavDropdown id={menuItem.Name} className={this.props.menuClass} noCaret={true} title={menuItem.Name} href="#">{menuItems}</ReactBootstrap.NavDropdown>;
                        case "Submenu":
                            menuItems = this.getMenuData(menuItem.Submenu, depth + 1);
                            return this.getSubMenu(menuItem, menuItems, depth);
                        //return <ReactBootstrap.NavDropdown id={menuItem.Name} className={this.props.subMenuClass} title={menuItem.Name} href="#">{menuItems}</ReactBootstrap.NavDropdown>;
                        case "Item":
                            return this.getItem(menuItem);
                        //return <ReactBootstrap.NavItem id={menuItem.Id} key={menuItem.Id} className={this.props.itemClass} eventKey={menuItem.Link} onSelect={this.menuSelected} href="#">{menuItem.Name}</ReactBootstrap.NavItem>;
                        case "Separator":
                            return this.getDivider(keyIndex);
                        //return <ReactBootstrap.NavItem key={keyIndex} className="divider" href="#" />;
                    }
                }
            }, this);
            return menuData;
        }
    }

    positionSubMenu(e: any, subMenuId: string) {
        const position = document.getElementById(e.currentTarget.parentNode.id);
        const subMenuTop: string = position ? String(position.getBoundingClientRect().top - 2) : "";
        const subMenuLeft: string = position ? String(position?.getBoundingClientRect().left + position?.getBoundingClientRect().width) : "";
        const subMenuPos = document.getElementById(subMenuId)
        const systemSubMenu = document.getElementById("submenu-Menu_5_i0");
        const alignSubMenu = document.getElementById("submenu-Menu_11_i13");
        const claimSubMenu = document.getElementById("submenu-Menu_11_i12");
        const paperComSubMenu = document.getElementById("submenu-Menu_5_i14");

        if (position && window.innerHeight < 800) {
            if( subMenuPos ) subMenuPos.style.top = subMenuTop + "px";
            if( subMenuPos ) subMenuPos.style.left = subMenuLeft + "px";
        } else if (position) {
            if (subMenuPos) {
                subMenuPos.style.top = subMenuTop + "px";
                subMenuPos.style.left = subMenuLeft + "px";
                subMenuPos.style.overflowY = "auto";
                //subMenuPos.style.maxHeight = "225px";
                subMenuPos.style.position = "fixed";
                if( paperComSubMenu ) paperComSubMenu.style.maxHeight = "225px";
            }
        } 

        if (subMenuPos?.id === "submenu-Menu_5_i0" && window.innerHeight > 800) {
            if (systemSubMenu) systemSubMenu.style.maxHeight = "initial";
            if (alignSubMenu) alignSubMenu.style.position = "fixed";
            if (alignSubMenu) alignSubMenu.style.left = "1180px";
            if (alignSubMenu) alignSubMenu.style.top = "660px";
            if (alignSubMenu) alignSubMenu.style.overflowY = "auto";
            if (alignSubMenu) alignSubMenu.style.maxHeight = "225px";
            if (claimSubMenu) claimSubMenu.style.position = "fixed";
            if (claimSubMenu) claimSubMenu.style.left = "1203px";
            if (claimSubMenu) claimSubMenu.style.top = "636px";
        } else if (subMenuPos?.id === "submenu-Menu_5_i0" && window.innerHeight < 800) {
            if (systemSubMenu) systemSubMenu.style.maxHeight = "225px";
            if (alignSubMenu) alignSubMenu.style.left = "86em";
            if (alignSubMenu) alignSubMenu.style.top = "19em";
            if (claimSubMenu) claimSubMenu.style.position = "fixed";
            if (claimSubMenu) claimSubMenu.style.left = "86em";
            if (claimSubMenu) claimSubMenu.style.top = "17em";
        } 
    }

    renderLoading() {
        return <div></div>;
    }

    render() {
        var testMode = (this.props.testMode && this.props.testMode == true);
        var menu = this.getMenuData(this.state.data, 0);
        var cssClassName = "assuranceMenu";
        if (testMode == true) cssClassName += " assuranceTestMenu";
        if(window.location.href.toLowerCase().indexOf("useremailconfirm") > 0)
            return <React.Fragment/>
        else
        return <>
            <ArmMenuParentDiv id="AssuranceMenu"  {...this.props}>
                <ArmMenuNavBar className={cssClassName} ref={this.insideMenu} {...this.props}>
                    {testMode == true && <div className="testModeLabel">TEST MODE</div>} 
                    <div className="container">
                        
                    <ul className="nav navbar-nav" key="assurancemenuul">{menu}
                        {/*             <ReactBootstrap.Nav pullRight={this.props.PullRight}>{menu}</ReactBootstrap.Nav>
                */}
                        </ul>
                </div>
            </ArmMenuNavBar>
            </ArmMenuParentDiv>
            
            <ModalLoading isOpen={this.state.funcCallBusy} />
            <ModalConfirmation
                isOpen={this.state.leave}
                onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setState({ leave: !this.state.leave })}
                formattedMessage={(
                    <div>
                        <b>Are you sure you want to leave this page?</b> <br /> <p> Your changes may NOT be saved.</p>
                    </div>)
                }
                message={''}
                okText='Leave'
                cancelText='Stay'
                onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                    this.setState({ leave: !this.state.leave })
                    pageLeave()
                    this.props.history.push(this.state.path)

                }}
                onDeny={(e: React.MouseEvent<HTMLButtonElement>) => {
                    this.setState({ leave: !this.state.leave })
                    if (this.props.Cancel) {
                        this.props.stayEvent()
                    }
                    sessionStorage.setItem('userModified', 'true')
                }}
            />
        </>;
    }
};

export default withRouter(AssuranceMenu);