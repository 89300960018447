import * as React from 'react';
import { RouteComponentProps, Prompt, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindAll } from 'lodash';
import { Button } from '@optum-uicl/ui-core/dist';
import { OKCancelButtons, DialogWrapper, ContentColumnWrapper, ContentRowFloatRight, ContentRowJustify, HighlightedColumnWrapper, ContentRowFloatLeft, SmallerTextColumn } from '@common/DialogWrapper';
import CrudTypes from '@commonResources/CrudTypes';
import {
    IMergeCrudComponentProps,
    createCrudMapStateToProps,
    createCrudMapDispatchToProps,
    mergeCrudComponentProps,
    resetCrudComponentState
} from '@scripts/util/CrudComponentHelpers';
import { IFormModalState, defaultFormModalState } from '@store/ui/BaseCrudUI';
import { ApplicationState } from '@store/index';
import { IADRCoverLetterUIState, IADRCoverLetterActionProps, actionCreators, validationCallback } from '@store/ADRCoverLetter';
import { IModifyADRCoverLetter } from '@store/ui/ADRCoverLetterUI';
import { RightAligned, SizedContainer } from '@common/component-styles';
import { Input } from '@common/UICLWrappers/ARMInput';
import { TextArea } from '@common/UICLWrappers/ARMTextArea';
import { pdfRouteBase, pdfWindowOptions } from '@commonResources/constants';
import { getHTMLContents } from '@common/ArmHtmlPopup';
import styled from 'styled-components';

export const ButtonWrapper=styled.div`
    button{
        width: 110px;
    }
`;
interface IComponentProps {
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IADRCoverLetterProps = IMergeCrudComponentProps<IADRCoverLetterUIState, IADRCoverLetterActionProps, IOwnProps>;

interface ISelectionOption {
    label: string;
    value: string;
}

const TwelveX = () => <React.Fragment>XXXXXXXXXXXX</React.Fragment>;
const DateX = () => <React.Fragment>XX/XX/XXXX</React.Fragment>;

export class ADRCoverLetter extends React.PureComponent<IADRCoverLetterProps, IFormModalState> {
    // define only once, instead of on every render
    private instructions = <React.Fragment>Comment text box is limited to 2048 characters. Mail text boxes are limited to 100 characters.</React.Fragment>;

    public componentDidMount() {
        this.props.action.crud.get({ crudId: CrudTypes.mctiADRCoverLetter },
            (result: MCADRCoverLetter) => {
                if (validationCallback(result)) {
                    this.props.action.ui.initalizeOptions({
                        masterCrud: result,
                        uiData: result.ADRCoverLetterMaintenanceInfo.ADRCoverLetter,
                    });
                    return true;
                }
            });
    }

    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public onDialogOk = () => {
        this.setState({ navigationConfirmed: true, saveOnNavigate: true },
            () => {
                if (this.props.dataStore.ui) {
                    this.props.action.crud.update(this.props.dataStore.crud);
                }
                this.props.history.push("/LandingPage");
            });
    }

    public onDialogCancel = (e: React.ChangeEvent<HTMLButtonElement>) => {
        this.props.history.push('/LandingPage');
    }

    private createModifyActionObject(data: string): IModifyADRCoverLetter {
        return {
            masterCrud: this.props.dataStore.crud.data,
            uiData: data?.trim() || '',
        }
    }

    public onContactNameChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editContactName(this.createModifyActionObject(e.target.value));
    }

    public onContactNumChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editContactNum(this.createModifyActionObject(e.target.value));
    }

    public onContactFaxChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editContactFax(this.createModifyActionObject(e.target.value));
    }

    public onCommentsLeave(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editComments(this.createModifyActionObject(e.target.value));
    }

    public onRegularMailChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editRegularMail(this.createModifyActionObject(e.target.value));
    }

    public onPriorityMailChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editPriorityMail(this.createModifyActionObject(e.target.value));
    }

    public onAddInfoChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editAddInfo(this.createModifyActionObject(e.target.value));
    }

    public onPreview() {
        if (this.props.dataStore.crud.data) {
            /*  If I wait to open the window until I have the path, the window no longer
                looks like it's the result of user interaction. So we open the window first.
                Once we get the path, we navigate there. If we don't get the path, we use
                the open window to tell the user about the error so we don't have a blank, 
                useless window.
            */
            const newWin = window.open('', 'Preview', pdfWindowOptions);
            newWin?.document.write(getHTMLContents('Preview', 'Loading...'));
            this.props.action.ui.previewPdf(this.props.dataStore.crud.data.ADRCoverLetterMaintenanceInfo.ADRCoverLetter,
                (result: IPreviewReportResponse) => {
                    console.log(pdfRouteBase + result.path);
                    if (!result.error) {
                        newWin?.location.assign(pdfRouteBase + result.path);
                    } else {
                        if (newWin) {
                            newWin.document.open();
                            newWin.document.write(getHTMLContents('Error', `<h3>${result.error}</h3>`));
                            newWin.document.close();
                        };
                    }
                    return true;
                }
            );
        }
    }

    private okCancelButtons = <OKCancelButtons onClickOK={this.onDialogOk} onClickCancel={this.onDialogCancel} />;


    constructor(props: IADRCoverLetterProps) {
        super(props);
        this.state = defaultFormModalState;
        bindAll(this, ['onContactNameChange', 'onContactNumChange', 'onContactFaxChange', 'onCommentsLeave', 'onRegularMailChange', 'onPriorityMailChange', 'onAddInfoChange', 'onPreview']);
    }

    public render() {
        const { contactName, contactNum, contactFax, comments, regularMail, priorityMail, addInfo, isBusy } = this.props.dataStore.ui;
        return (<DialogWrapper title='Additional Development Request Cover Letter'
            instruction={this.instructions}
            helpUrl='/Support/Help/HELP_Reports_ADRCoverLetter.htm'
            buttons={this.okCancelButtons}
            isBusy={isBusy}>
            <ContentColumnWrapper >
                <SmallerTextColumn>
                    <ContentRowFloatRight>
                        <div>
                            <RightAligned>
                                <TwelveX />
                            </RightAligned>
                            <RightAligned>
                                <TwelveX />
                            </RightAligned>
                            <RightAligned>
                                <TwelveX />
                            </RightAligned>
                            <RightAligned>
                                Date Sent: <DateX />
                            </RightAligned>
                            <RightAligned>
                                Date Due: <DateX />
                            </RightAligned>
                        </div>
                    </ContentRowFloatRight>
                    <ContentColumnWrapper>
                        <SizedContainer pixelWidth='345'>
                            <Input style={{marginBottom:'5px'}}
                                label="FROM:"
                                domID='adr_contactName_input'
                                className='text-input'
                                size='small'
                                initialValue={contactName}
                                maxLength={40}
                                onBlur={this.onContactNameChange}
                            />
                        </SizedContainer>
                        <ContentRowFloatLeft >
                            <SizedContainer pixelWidth='165'>
                                <Input
                                    label='PHONE:'
                                    domID='adr_contactNum_input'
                                    className='text-input'
                                    size='small'
                                    initialValue={contactNum}
                                    maxLength={25}
                                    onBlur={this.onContactNumChange} />
                            </SizedContainer>
                            <SizedContainer pixelWidth='140'>
                                <Input
                                    label="FAX:"
                                    domID='adr_contactFax_input'
                                    className='text-input'
                                    size='small'
                                    initialValue={contactFax}
                                    maxLength={15}
                                    onBlur={this.onContactFaxChange} />
                            </SizedContainer>
                        </ContentRowFloatLeft>
                    </ContentColumnWrapper>
                    <SizedContainer as={TextArea}
                        domID='adr_comments_input'
                        size='small'
                        pixelWidth='605'
                        maxLength={2048}
                        initialValue={comments}
                        onBlur={this.onCommentsLeave}
                     />
                    <ContentRowJustify>
                        <SizedContainer as={TextArea}
                            domID='adr_regular_mail'
                            pixelWidth='285'
                            label='REGULAR MAIL'
                            maxLength={100}
                            initialValue={regularMail}
                            onBlur={this.onRegularMailChange}
                        />
                        <SizedContainer as={TextArea}
                            domID='adr_priority_mail'
                            pixelWidth='285'
                            label='PRIORITY MAIL'
                            maxLength={100}
                            initialValue={priorityMail}
                            onBlur={this.onPriorityMailChange}
                        />
                    </ContentRowJustify>
                    <ContentColumnWrapper>
                        <div>Provider Number: <TwelveX /></div>
                        <div>Beneficiary Name: <TwelveX /></div>
                        <div>HICN: <TwelveX /></div>
                        <div>DCN: <TwelveX /></div>
                        <div>Service Date: <DateX /> - <DateX /></div>
                    </ContentColumnWrapper>
                    <SizedContainer pixelWidth='605'>
                        <Input
                            domID='adr_addInfo_input'
                            className='text-input'
                            size='small'
                            initialValue={addInfo}
                            maxLength={100}
                            onBlur={this.onAddInfoChange} />
                    </SizedContainer>
                </SmallerTextColumn>
                <ContentRowFloatRight style={{ paddingTop: '10px' }}>
                    <ButtonWrapper>
                        <Button name='Preview' onClick={this.onPreview} size='control' />
                    </ButtonWrapper>
                </ContentRowFloatRight>
            </ContentColumnWrapper>
        </DialogWrapper>);
    }
}

const connectedHoc = connect<IADRCoverLetterUIState,
    IADRCoverLetterActionProps,
    IOwnProps,
    IADRCoverLetterProps,
    ApplicationState>(
        createCrudMapStateToProps('adrCoverLetter'),
        createCrudMapDispatchToProps(actionCreators),
        mergeCrudComponentProps
    )(ADRCoverLetter);

export default withRouter(connectedHoc);