import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Input } from '@common/UICLWrappers/ARMInput';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { Colors } from '@commonResources/colorVariables'
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { DialogWrapper, OKCancelButtons, SubmitCancelButtons } from '@common/DialogWrapper';
import { InlineInstructions } from '@common/InlineInstructions';
import { getSecurityToken } from '@scripts/session/SecurityToken';
import { submitLogin } from '@scripts/session/Security';

import {
    IMergeCrudComponentProps,
    createCrudMapStateToProps,
    createCrudMapDispatchToProps,
    mergeCrudComponentProps,
    resetCrudComponentState
} from '@scripts/util/CrudComponentHelpers';

import { IChangePasswordState, IChangePasswordActionProps, actionCreators } from
    '@store/ChangePassword';

//
//HARD-CODED STYLES
//
// Need to include width 100% when setting "display: flex" for IE 11
//https://stackoverflow.com/questions/21600345/flexbox-and-internet-explorer-11-displayflex-in-html
const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
    padding:5px;
    span[class*='ErrorMessage']{
        margin-bottom:18px !important;
    }
    label{
        text-transform: uppercase !important;
    }
`;

const PadBottomWrapper = styled.div`
    padding-bottom: 12px;
`;

const InputContainer = styled.div`
    height:auto;
    
`;

const InlineHeader = styled.div`   
    width:100%;
    margin: auto;
    background-color: #cbcccd;
    padding: 3px;
`;

const InlineError = styled.div`   
    width:100%;
    margin: auto;
    background-color: #cbcccd;
    padding: 3px;
    color: ${Colors.digitalRed130};
`;



//
// INTERFACE, TYPE, AND CLASS DEFINITIONS
//
interface IComponentProps {
    canView: boolean,
    canEdit: boolean,
    title: string,
}

//The only MasterCrud piece ChangePassword uses is the UserMaintenanceInfo
export const DEFAULT_STATE: IComponentState = {};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IChangePasswordProps = IMergeCrudComponentProps<IChangePasswordState, IChangePasswordActionProps, IOwnProps>;


interface IComponentState {

}

interface IWarningMessageProps {
    warningMessage: string | null | undefined,
}

// ReSharper disable once InconsistentNaming
function WarningMessage(props: IWarningMessageProps) {
    if (!props.warningMessage)
        return null;

    return(
        <InlineHeader>
            {props.warningMessage}
        </InlineHeader>
    );
}

function ErrorMessage(props: IWarningMessageProps) {
    if (!props.warningMessage)
        return null;

    return (
        <InlineError>
            {props.warningMessage}
        </InlineError>
    );
}

export class ChangePassword extends React.Component<IChangePasswordProps, IComponentState> {
    static defaultProps: IComponentProps = {
        canEdit: true,
        canView: true,
        title: "Change Password",
    };

    constructor(props: IChangePasswordProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.props.action.ui.getCookieInformation();
        if (this.props.history.location.state && (this.props.history.location.state as any).warningMessage) {
            this.props.dataStore.ui.warningMessage = (this.props.history.location.state as any).warningMessage;
        }
    }


    componentDidMount() {
        //Need to see if it is a good or bad idea to push this up to WillMount to ensure render fires with all components filled.
        this.props.action.crud.get({ crudId: CrudTypes.mctiUsers });
    }

    //Needed to use component did update to ensure actions fired the expected number of times and in the expected order.
    async componentDidUpdate() {
        //If the password requirements still need to be built and we have received the data for it, go ahead and build the instructions.
        if ((!this.props.dataStore.ui.instructions || this.props.dataStore.ui.instructions.length === 0) &&
            this.props.dataStore.crud.data) {
            this.props.action.ui.getPasswordRequirements(
                {
                    masterCrud: this.props.dataStore.crud.data,
                });
        } else {
            //If the pre-DB validation check was successful, send the old and new passwords off for submission.
            if (this.props.dataStore.ui.validated) {
                const oldPassword = this.props.dataStore.ui.currentPasswordField.text;
                const newPassword = this.props.dataStore.ui.newPasswordField.text;
                this.props.action.ui.submitNewPassword(oldPassword, newPassword);
            } else {
                //If submitSucceeded is ever true, it means the password has been successfully changed, so update login and see if
                //we need to redirect to the password hints page.
                if (this.props.dataStore.ui.submitSucceeded) {
                    const token = getSecurityToken();
                    const userName = sessionStorage.getItem("Username");

                    const loginResult = await submitLogin({
                        Username: !userName ? "" : userName,
                        Password: this.props.dataStore.ui.newPasswordField.text,
                        ClientAlias: token.CID,
                        LoginType: token.LoginType
                    });

                    //Leaving as a switch in case we want to only redirect to the home page explicitly on a 200.
                    switch (loginResult.status) {
                    case 316:
                        // Password questions needed
                        this.props.history.push("/Transactions/UserPasswordHints?" + loginResult.body, { redrawMenu: true });
                        break;

                    case 317:
                        // Confirm email needed
                        this.props.history.push("/UserEmailConfirm", { redrawMenu: true });
                        break;

                    default: 
                        // redirect to /LandingPage
                        this.props.history.push("/LandingPage", { redrawMenu: true });
                        break;
                    }
                }
            }
        }
    }

    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    //Kick off the validate -> submit -> redirect action chain.
    // ReSharper disable once InconsistentNaming
    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.validatePasswordFields(this.props.dataStore.ui);
    }

    public onSubmit(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.sendResetEmail();
    }

    //If the user wants to cancel, kick them back to the landing page.
    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push("/LandingPage");
    }

    public render() {
        //Generic password instruction.
        const bottomInstructions = <React.Fragment>You must choose a new password now.</React.Fragment>;

        //Buttons in the dialog wrapper footer.
        const buttons =
            <OKCancelButtons
                onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)}
                onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)}/>;

        const ciamButtons =
            <SubmitCancelButtons
                onClickSubmit={(e: React.ChangeEvent<HTMLButtonElement>) => this.onSubmit(e)}
                onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;

        if (this.props.dataStore.ui.isCiamUser == false) {
            return (
                <DialogWrapper title="Change Password" width="575px" instruction={bottomInstructions} buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                    <ContentWrapper>
                        <WarningMessage warningMessage={this.props.dataStore.ui.warningMessage} />
                        <br />
                        <InputContainer>
                            <Input
                                domID="currentPassword"
                                hasGutter={true}
                                type="password"
                                hasError={this.props.dataStore.ui.currentPasswordField.errorFlag}
                                className="text-input"
                                errorMessage={this.props.dataStore.ui.currentPasswordField.errorMessage}
                                label="Current Password"
                                initialValue={this.props.dataStore.ui.currentPasswordField.text}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.props.action.ui.updateCurrentField(e.target.value);
                                }} />
                            <Input
                                domID="newPassword"
                                hasGutter={true}
                                type="password"
                                hasError={this.props.dataStore.ui.newPasswordField.errorFlag}
                                errorMessage={this.props.dataStore.ui.newPasswordField.errorMessage}
                                className="text-input"
                                label="New Password"
                                initialValue={this.props.dataStore.ui.newPasswordField.text}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.props.action.ui.updateNewField(e.target.value);
                                }} />
                            <Input
                                domID="confirmNewPassword"
                                hasGutter={true}
                                type="password"
                                hasError={this.props.dataStore.ui.confirmNewPasswordField.errorFlag}
                                errorMessage={this.props.dataStore.ui.confirmNewPasswordField.errorMessage}
                                className="text-input"
                                label="Confirm New Password"
                                initialValue={this.props.dataStore.ui.confirmNewPasswordField.text}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.props.action.ui.updateConfirmField(e.target.value);
                                }} />
                        </InputContainer>
                        <PadBottomWrapper>
                            <InlineInstructions
                                instructions={this.props.dataStore.ui.instructions} />
                        </PadBottomWrapper>
                    </ContentWrapper>
                </DialogWrapper>
            );
        }
        else {
            return (

                <DialogWrapper title="Change Password" width="575px" buttons={ciamButtons} isBusy={this.props.dataStore.ui.isBusy}>
                    <ContentWrapper>
                        <br />
                        <WarningMessage warningMessage="Click the Submit button to receive an email with instructions to change your password, or Cancel to go back." />
                        <br />
                        <ErrorMessage warningMessage={this.props.dataStore.ui.warningMessage} />
                    </ContentWrapper>
                </DialogWrapper>

            );
        }
    }
};

var connectedHoc = connect<IChangePasswordState,
    IChangePasswordActionProps,
    IOwnProps,
    IChangePasswordProps,
    ApplicationState>(
    createCrudMapStateToProps('changePassword'),
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ChangePassword);

export default withRouter(connectedHoc);