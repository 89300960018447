import * as React from 'react';
import { RouteComponentProps, Redirect, withRouter } from 'react-router';

interface ICfiRouteProps {
    redirectNativeUrl?: string;
    allowChrome: boolean;
}

interface ICfiRouteParams {
    ParentFolder: string;
    ChildFolder: string;
    File: string;
    Process: string;
    Param1: string;
}

type IOwnProps = ICfiRouteProps & RouteComponentProps<ICfiRouteParams>;

interface ICfiRouteState {
}

const INITIAL_STATE: ICfiRouteState = {
};

export class CfiRoute extends React.Component<IOwnProps, ICfiRouteState> {

    static defaultProps = {
    }
    
    protected cfiUrl = "/ui/cfi";

    protected GoToProcess = React.createRef<HTMLFormElement>();

    constructor(props: IOwnProps) {
        super(props);
        this.state = {
            ...INITIAL_STATE,
        };
    }

    componentDidMount() {
        if (this.props.history.action === "PUSH") {
            const querystring = require("query-string");
            const parsed = querystring.parse(this.props.location.search);
            if (Object.keys(parsed).length > 0 && parsed.cfiReturnInfo != undefined) {
                this.props.history.replace(parsed.cfiReturnInfo);
            }
        }
        
        if (this.GoToProcess) {
            this.GoToProcess.current?.submit();
        }
    }
    
    

    getParams() {
        if (this.props.location.state) {
            let keys = Object.keys(this.props.location.state);
            let inputs = [];
            for (let key in keys) {
                let paramName = keys[key];
                let value = this.props.location.state[paramName as keyof typeof this.props.location.state];
                console.log(`mapping ${paramName} to hidden input field - value = ${value}`);
                inputs.push((<input
                    type="hidden"
                    name={paramName}
                    value={value}
                />) as any);
            }
            return inputs;
        }
        return null;
    }


    render() {

        
        if (this.props.history.action === "POP" && this.props.location.pathname.toLowerCase().includes("cfi/default")) {
            this.props.history.goBack();
            return null;
        }

        if (this.props.location.search &&
            this.props.location.search.startsWith("?")) {
            this.cfiUrl += this.props.location.search;
        }
        // Send process and cfiReturnInfo in the state 
        return  (
            <form ref={this.GoToProcess} action={this.cfiUrl} name="GoToProcess" method="POST">
                {this.getParams()}
            </form>
        );

    }
}

export default withRouter(CfiRoute);
