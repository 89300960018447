import { getRawToken } from '@scripts/session/SecurityToken'; 
import { URLs } from '@commonDevResources/constants';

export interface ILogMessage {
    logLevel:string
    message:string
}
export async function Log(type: string, url: string, status: string) {
    try {
        let actionType = type ? `actiontype: ${type}` : "";
        let actionUrl = url ? `url: ${url}` : "";
        let actionStatus = status ? `status: ${status}` : "";

        let message = [actionType, actionUrl, actionStatus].filter(Boolean).join(" && ");


        //let message: string = "actiontype :" + type || "" && "url :" + url || "" && "status" + status || "";
        let logType: string = "Info";

        await logInfo(logType, message);
    }
    catch (err) {
        const typError = err as Error;
        console.error('Error: ', typError.message);

    }
}
const logInfo = async(logType:string,message:string) => {
    var url = URLs.api + '/api/webclientlog';
    let logMsg = {} as ILogMessage;
    logMsg.logLevel = logType;
    
    logMsg.message = message;
    
    await fetch(url, {
        method: 'Post', 
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `${getRawToken()}`
        },
        body:JSON.stringify(logMsg)
    })
        .then(async response => {
            if (response.status === 200) {
                const data: any = await response.json();
               
            }
        })
        .catch(error => {
            console.error(`promise rejected: URL:${url} with Error: ${error}`);
        });
};

export async function LogMessage(logType: string,message:string) {
    await logInfo(logType, message);
}



