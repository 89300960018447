import { Reducer } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import {
    MCEligibilityProfileType, ClientPayersPayer, EligibilityPayersPayer, ClientFields,
    ClientEdits, ProfileEdits, MCField, MCEligibilityPayerMaintenanceInfo, ProfileEdit, ClientField, ClientEdit, ProfileField
} from '../../scripts/@types/MasterCrud/EligibilityProfile';
import _ from 'lodash';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface IEligibilityProfileUIData { };

export interface ISelectPayerData {
    name: string;
};

export const defaultPayerType: ISelectPayerData = {
    name: '',
};

export interface ISelectPayerInfo {
    '@RTECPID': string;
    '@PayerID': string;
    '@Name': string;
    '@DisplayName': string;
    '@ID': string;
};

export const defaultPayer: ISelectPayerInfo = {
    '@RTECPID': '',
    '@PayerID': '',
    '@Name': '',
    '@DisplayName': '',
    '@ID': '',
};

export interface ISelectedPayers {
    selectedPayers: ISelectPayerInfo[];
}

export interface IReleaseState{
    value: string;
}

export interface ISelectEnablePayerInfo {
    '@PayerID': string;
}

export const defaultEnablePayer: ISelectEnablePayerInfo = {
    '@PayerID': '',
};

export interface ISelectedEnablePayer {
    selectedEnablePayer: ISelectEnablePayerInfo;
}

export interface IEligibilityProfileUIState {
    availablePayers?: EligibilityPayersPayer[];
    selectedPayers: ISelectedPayers;
    addSelectedPayers: ISelectPayerData[];
    removeSelectedPayers: ISelectedEnablePayer;
    selectedEnabledPayerRecord: any;
    availableSelectedMap: {
        availablePayers: {
            selectedIds: ISelectPayerInfo[];
            addedIds: string[];
            removedIds: string[];
        },
        enabledPayers: {
            selectedIds: ISelectPayerInfo[];
            addedIds: string[];
            removedIds: string[];
        }
    };
    clientPayersPayerArray: ClientPayersPayer[];
    maintenanceInfoDetail: MCEligibilityPayerMaintenanceInfo;
    isBusy: boolean;
    requestFieldEdits?: any;
    fieldEdits: any;
    releaseHoursError: boolean;
    filterValue: string;
    lastUpdatedCheckboxParent: ISelectPayerInfo;
}

export const defaultState: IEligibilityProfileUIState = {
    selectedPayers: { selectedPayers: [] },
    addSelectedPayers: [],
    removeSelectedPayers: { selectedEnablePayer: defaultEnablePayer },
    selectedEnabledPayerRecord: undefined,
    availableSelectedMap: {
        availablePayers: {
            selectedIds: [],
            addedIds: [],
            removedIds: []
        },
        enabledPayers: {
            selectedIds: [],
            addedIds: [],
            removedIds: []
        }
    },
    clientPayersPayerArray: [],
    maintenanceInfoDetail: {
        '@ReleaseWithPending271': false,
        '@AutoReleasePending271': false,
        '@EligHospExclude90Day': false,
        '@AutoRelease271Hours': '',
        '@AutoListConversionFlag': '',
        EligibilityPayers: {
            Payer:[]
        },
        EligibilityPayerProfiles: {
            PayerProfile:[]
        },
        ClientPayers: {
            Payer:[]
        },
        updatedRecord: {},
    },
    isBusy: false,
    fieldEdits: {},
    releaseHoursError: false,
    filterValue: '',
    lastUpdatedCheckboxParent: _.cloneDeep(defaultPayer)
}

export interface ISelectPayerItem extends ICrudActionData<MCEligibilityProfileType, ISelectPayerInfo> { }
export interface ISelectPayerList extends ICrudActionData<MCEligibilityProfileType, ISelectedPayers> { }
export interface ICheckboxInSelectedRow extends ICrudActionData<MCEligibilityProfileType, ClientPayersPayer> { }
export interface ISelectClientPayersPayer extends ICrudActionData<MCEligibilityProfileType, ClientPayersPayer[]> { }
export interface IMaintenanceInfoDetail extends ICrudActionData<MCEligibilityProfileType, MCEligibilityPayerMaintenanceInfo> { }
export interface IProfileClaimsDetail extends ICrudActionData<MCEligibilityProfileType, IReleaseState> { }

export const actionCreators = {
    selectedPayers: (selectedPayers: ISelectPayerList) => createDataAction('SELECTED_PAYERS', selectedPayers),
    addPayers: (addPayers: ISelectPayerList) => createDataAction('ADD_SELECTED_PAYERS', addPayers),
    removePayers: (removePayers: ISelectPayerItem) => createDataAction('REMOVE_ENABLED_PAYERS', removePayers),
    selectedEnabledPayerRecords: (selectedEnabledPayer: any) => createDataAction('SELECTED_ENABLED_PAYER_RECORDS', selectedEnabledPayer),
    availableSelectedMaps: (availableSelectedMap: any) => createDataAction('AVAILABLE_SELECTED_MAP', availableSelectedMap),
    selectedClientPayersPayer: (selected: ISelectClientPayersPayer) => createDataAction('SELECTED_CLIENT_PAYERS_PAYER', selected),
    checkboxInSelectedRow: (checkboxSelected: ICheckboxInSelectedRow) => createDataAction('CHECKBOX_SELECTED_IN_GRID', checkboxSelected),
    getMaintenanceInfo: (info: IMaintenanceInfoDetail) => createDataAction('GET_MAINTENANCE_INFO', info),
    changeCheckOption: (changeCheckOption: ICheckboxInSelectedRow) => createDataAction('CHANGE_CHECK_OPTION', changeCheckOption),
    requestFieldEdits: (requestFieldEdit: ISelectPayerList) => createDataAction('REQUEST_FIELD_EDITS', requestFieldEdit),
    fieldEdits: (fieldEdit: ISelectPayerList) => createDataAction('FIELD_EDITS', fieldEdit),
    changeHoldClaimsCheckbox: (value: IProfileClaimsDetail) => createDataAction("EP_CHANGE_HOLD_CLAIMS", value),
    changeAvailableForReleaseCheckbox: (value: IProfileClaimsDetail) => createDataAction("EP_CHANGE_AVAILABLE_FOR_RELEASE", value),
    checkReleaseHours: (value: IProfileClaimsDetail) => createDataAction("EP_CHECK_RELEASE_HOURS", value),
    filterPayers: (value: IProfileClaimsDetail) => createDataAction("EP_FILTER_PAYERS", value),
    setCheckboxClickParent: (parent: any) => createDataAction("EP_SET_CHECKBOX_PARENT", parent),
    toggleMDEBatchFlag: (value: IProfileClaimsDetail) => createDataAction("EP_TOGGLE_MDE_BATCH_FLAG", value),
    changeExclude90DayCheckbox: (value: IProfileClaimsDetail) => createDataAction("EP_EXCLUDE_90_DAY", value),
}

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISelectEligibilityProfile extends ICrudActionData<MCEligibilityProfileType, IEligibilityProfileUIData> { }

function isMedicarePayer(recpid: string, crud: MCEligibilityProfileType | undefined): boolean {
    let isMedicare: boolean = false;
    if (crud &&
        crud.EligibilityPayerMaintenanceInfo) {

        let profile = crud.EligibilityPayerMaintenanceInfo.EligibilityPayerProfiles?.PayerProfile;

            let profileIndex: number | undefined= profile?.findIndex(profile => profile['@RTECPID'] === recpid);

            if (profileIndex !== -1 && profileIndex !== undefined && profile !== undefined) {
                isMedicare = profile[profileIndex]['@EnrollmentRequired'] === 'Y';
            }
    }
    return isMedicare;
}
// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IEligibilityProfileUIState, KnownActions> = (state: IEligibilityProfileUIState | undefined, action: KnownActions) => {
    if (state != undefined) {
        let updatedState = state;
        switch (action.type) {
            case 'SELECTED_PAYERS':
                {
                    if (action.data.masterCrud) {
                        return {
                            ...state,
                            selectedPayers: action.data.uiData,
                        }
                    }
                }
                break;
            case 'ADD_SELECTED_PAYERS':
                {
                    if (action.data.masterCrud) {

                        let selectedEligibilityPayers: EligibilityPayersPayer[] = (action.data.uiData as any).selectedPayers;

                        let clientPayers: ClientPayersPayer[] = [];

                        if (selectedEligibilityPayers && _.isArray(selectedEligibilityPayers)) {

                            let profiles = action.data.masterCrud.EligibilityPayerMaintenanceInfo.EligibilityPayerProfiles?.PayerProfile;

                            selectedEligibilityPayers.forEach(sep => {
                                let profileIndex: number = profiles?.findIndex(profile => profile['@RTECPID'] === sep['@RTECPID']) ?? -1;
                                if (profileIndex !== -1 && profiles !== undefined) {
                                    let payerProfile = profiles[profileIndex];
                                    if (payerProfile) {
                                        let clientFields: ClientFields = { Field: [] };
                                        // build Client Fields
                                        let profileFields = payerProfile.Fields;
                                        if (profileFields && profileFields.Field && _.isArray(profileFields.Field)) {
                                            let fields: ProfileField[] = profileFields.Field;
                                            if (fields && _.isArray(fields)) {
                                                clientFields.Field = fields.map((field: any) => {
                                                    field['@ID'] = field["@ID"];
                                                    field['@Type'] = field["@Type"];
                                                    field['@Required'] = field["@Required"];
                                                    field['@Send'] = field["@Send"];
                                                    return {
                                                        ...field
                                                    }
                                                });
                                            }
                                        }

                                        let clientEdits: ClientEdits = { Edit: [] };
                                        // build Client Edits
                                        let profileEdits: ProfileEdits = payerProfile.Edits;
                                        if (profileEdits && profileEdits.Edit && _.isArray(profileEdits.Edit)) {
                                            let edits: ProfileEdit[] = profileEdits.Edit;
                                            if (edits && _.isArray(edits)) {
                                                clientEdits.Edit = edits.map((edit: any) => {
                                                    edit['@ID'] = edit["@EditId"];
                                                    edit['@EditId'] = edit["@EditId"];
                                                    edit['@TypeBill'] = '';
                                                    edit['@Suprressed'] = edit["@Suprressed"];
                                                    return {
                                                        ...edit
                                                    }
                                                });
                                            }
                                        }

                                        let payer: ClientPayersPayer = {
                                            '@PayerID': sep['@ID'],
                                            '@RTECPID': sep['@RTECPID'] ?? '',
                                            '@Name': sep['@Name'],
                                            '@DisplayName': sep['@DisplayName'],
                                            '@Bridge': 'N',
                                            '@CFI': 'N',
                                            '@Manual': 'N',
                                            '@Batch': 'N',
                                            '@Direct': 'N',
                                            '@EligEnabled': 'Y',
                                            '@Active': payerProfile['@EnrollmentRequired'] === "Y" ? "N" : "Y",
                                            '@ID': '#',
                                            Fields: clientFields,
                                            Edits: clientEdits,
                                            added: true,
                                            updated: false
                                        };

                                        clientPayers.push(payer);
                                    }
                                }
                            });
                        }

                        if (clientPayers && _.isArray(clientPayers)) {
                            clientPayers.forEach(scp => {
                                const clientPayerIdx =
                                    action.data.masterCrud?.EligibilityPayerMaintenanceInfo.ClientPayers?.Payer.findIndex(payer => payer['@PayerID'] === scp['@PayerID'] && payer['@RTECPID'] ===  scp['@RTECPID']);
                                
                                if (clientPayerIdx !== undefined && clientPayerIdx !== -1) {
                                    let payerToActivate = action.data.masterCrud?.EligibilityPayerMaintenanceInfo.ClientPayers?.Payer[clientPayerIdx];
                                    if (payerToActivate !== undefined) {
                                        payerToActivate['@EligEnabled'] = 'Y';
                                        payerToActivate['@Active'] = isMedicarePayer(payerToActivate['@RTECPID'], action.data.masterCrud) ? 'N' : 'Y';
                                        payerToActivate.added = true;
                                        let idx: number = 1;
                                        if (scp.Fields && _.isArray(scp.Fields)) {
                                            scp.Fields.forEach(scpf => {
                                                let cField: ClientField = {
                                                    '@ID': idx.toString(),
                                                    '@Type': scpf['@Type'],
                                                    '@Required': 'N',
                                                    '@Send': 'N',
                                                };
                                                idx++;
                                                payerToActivate.Fields.Field.push(cField);
                                            });
                                        }
                                        if (scp.Edits && _.isArray(scp.Edits)) {
                                            scp.Edits.forEach(scpe => {
                                                let cEdit: ClientEdit = {
                                                    '@ID': idx.toString(),
                                                    '@EditId': scpe['@EditId'],
                                                    '@TypeBill': scpe['@Type'],
                                                    '@Suprressed': 'N'
                                                };
                                                idx++;
                                                payerToActivate.Fields.Field.push(cEdit);
                                            });
                                        }
                                    }
                                    
                                }
                                else {
                                    // if it's not there add it
									scp.Fields.Field = [];
									scp.Edits.Edit = [];
                                    action.data.masterCrud?.EligibilityPayerMaintenanceInfo.ClientPayers?.Payer.push(scp);
                                }
                            });
                        }
                        
                        return {
                            ...state,
                        }
                    }
                }
                break;
            case 'REMOVE_ENABLED_PAYERS':
                {
                    if (action.data.masterCrud) {
                        
                        let selectedPayer = action.data.uiData;
                        
                        let clientPayers = action.data.masterCrud.EligibilityPayerMaintenanceInfo.ClientPayers?.Payer;

                                let selectedEnabledPayerIndex: number = 
                                    clientPayers?.findIndex(payer => (payer['@PayerID'] ?? "") == selectedPayer['@PayerID'] && (payer['@RTECPID'] ?? "") == selectedPayer['@RTECPID']) ?? -1;
                                
                                if (selectedEnabledPayerIndex !== -1 && clientPayers !== undefined) {
                                    let selectedPayer = clientPayers[selectedEnabledPayerIndex];
                                    selectedPayer['@EligEnabled'] = 'N';
                                    // TODO remove Field and edits nodes per legacy, but they come back when you re-add it
                                    selectedPayer.Edits = { Edit: [] };
                                    selectedPayer.Fields = { Field: [] };
                                    selectedPayer.added = false;
                                }

                        return {
                            ...state,
                            removePayers: action.data.uiData,
                        }
                    }
                }
                break;
            case 'SELECTED_ENABLED_PAYER_RECORDS':
                if (action.data.masterCrud) {
                    return {
                        ...state,
                        selectedEnabledPayerRecord: action.data.uiData.updatedRecord
                    }
                }
                break;
            case 'AVAILABLE_SELECTED_MAP':
                if (action.data.masterCrud) {
                    return {
                        ...state,
                        availableSelectedMap: action.data.uiData.selectedMap
                    }
                }
                break;
            case 'CHECKBOX_SELECTED_IN_GRID':
                if (action.data.masterCrud) {
                    let newState = { ...state };
                    let newRow: ClientPayersPayer = action.data.uiData.updatedRecord;
                    let updateDetail: ClientPayersPayer | undefined = state.clientPayersPayerArray.find((item) => {
                        return item["@ID"] === newRow["@ID"];
                    });
                    if (updateDetail) {
                        updateDetail['@Bridge'] = newRow["@Bridge"];
                        updateDetail['@CFI'] = newRow["@CFI"];
                        updateDetail['@Manual'] = newRow["@Manual"];
                        updateDetail['@Batch'] = newRow["@Batch"];
                        updateDetail['@Direct'] = newRow["@Direct"];
                        updateDetail['@EligEnabled'] = newRow["@EligEnabled"];
                        updateDetail['@Active'] = newRow["@Active"];
                        updateDetail["@PayerID"] = newRow["@PayerID"];
                        updateDetail.updated = true;
                    }

                    return {
                        ...newState,
                    }
                }
            case 'SELECTED_CLIENT_PAYERS_PAYER':
                if (action.data.masterCrud) {
                    return {
                        ...state,
                        clientPayersPayerArray: action.data.uiData as ClientPayersPayer[]
                    }
                }
            case 'GET_MAINTENANCE_INFO':
                if (action.data.masterCrud) {
                    return {
                        ...state,
                        maintenanceInfoDetail: action.data.uiData as MCEligibilityPayerMaintenanceInfo
                    }
                }
            case 'CHANGE_CHECK_OPTION':
                if (action.data.masterCrud) {
                    let newStateData = { ...state };
                    let clientPayers: any = action.data.masterCrud.EligibilityPayerMaintenanceInfo.ClientPayers?.Payer[0];
                    let recordToUpdate = clientPayers.filter((item: any) => {
                        return item["@ID"] === (action.data.uiData as any).updatedRecord["@ID"]
                    })

                    if (recordToUpdate) {

                        recordToUpdate[0]['@Bridge'] = (action.data.uiData as any).updatedRecord["@Bridge"];
                        recordToUpdate[0]['@CFI'] = (action.data.uiData as any).updatedRecord["@CFI"];
                        recordToUpdate[0]['@Manual'] = (action.data.uiData as any).updatedRecord["@Manual"];
                        recordToUpdate[0]['@Batch'] = (action.data.uiData as any).updatedRecord["@Batch"];
                        recordToUpdate[0]['@Direct'] = (action.data.uiData as any).updatedRecord["@Direct"];
                        recordToUpdate[0]['@EligEnabled'] = (action.data.uiData as any).updatedRecord["@EligEnabled"];
                        recordToUpdate[0]['@Active'] = (action.data.uiData as any).updatedRecord["@Active"];
                        recordToUpdate[0]["@PayerID"] = (action.data.uiData as any).updatedRecord["@ID"];

                    }
                    return newStateData;
                }
            case 'REQUEST_FIELD_EDITS':
                if (action.data.masterCrud) {


                    let requestedData:any = action.data.uiData;
                    let newStateData = { ...state };
                    let ogPayerData: ClientPayersPayer | undefined = state.maintenanceInfoDetail.ClientPayers?.Payer.find((payer) => payer["@PayerID"] === requestedData.payerID && payer["@RTECPID"] === requestedData.rtecpId);
                    if (ogPayerData) {
                        ogPayerData.Fields = {
                            Field: requestedData.requestFieldEdit
                        };
                        ogPayerData.updated = true;
                    }
                    return {
                        ...newStateData,
                        requestFieldEdits: requestedData.requestFieldEdit
                    }
                }
            case 'FIELD_EDITS':
                if (action.data.masterCrud) {
                    return {
                        ...state,
                        fieldEdits: action.data.uiData
                    }
                }
            case 'EP_CHANGE_AVAILABLE_FOR_RELEASE':
                if(action.data.masterCrud){
                    action.data.masterCrud.EligibilityPayerMaintenanceInfo["@AutoReleasePending271"] =
                         action.data.masterCrud.EligibilityPayerMaintenanceInfo["@AutoReleasePending271"] === 'Y' ? 'N' : 'Y';
                }
                return{
                    ...state
                }
            case 'EP_EXCLUDE_90_DAY':
                if(action.data.masterCrud){
                    action.data.masterCrud.EligibilityPayerMaintenanceInfo["@EligHospExclude90Day"] =
                         action.data.masterCrud.EligibilityPayerMaintenanceInfo["@EligHospExclude90Day"] === 'Y' ? 'N' : 'Y';
                }
                return{
                    ...state
                }
            case 'EP_CHANGE_HOLD_CLAIMS':
                if(action.data.masterCrud){
                    action.data.masterCrud.EligibilityPayerMaintenanceInfo["@ReleaseWithPending271"] =
                        action.data.masterCrud.EligibilityPayerMaintenanceInfo["@ReleaseWithPending271"] === 'Y' ? 'N' : 'Y';
                }
                return{
                    ...state
                }
            case 'EP_CHECK_RELEASE_HOURS':
                let hasError : boolean = false;
                if(action.data.masterCrud){
                    if(/[a-zA-Z]/.test(action.data.uiData.value))
                        hasError = true;
                    else
                        action.data.masterCrud.EligibilityPayerMaintenanceInfo["@AutoRelease271Hours"] = action.data.uiData.value;
                }
                return{
                    ...state,
                    releaseHoursError : hasError
                }
                
            case 'EP_FILTER_PAYERS':
                
                return{
                    ...state,
                    filterValue: action.data.uiData.value
                }
            case 'EP_SET_CHECKBOX_PARENT':
                return{
                    ...state,
                    lastUpdatedCheckboxParent: action.data.uiData.parent
                }    
            case 'EP_TOGGLE_MDE_BATCH_FLAG':
                if(action.data.masterCrud){
                    let recordToUpdate = 
                        (action.data.masterCrud.EligibilityPayerMaintenanceInfo.ClientPayers?.Payer[0] as any).find((x: any) => 
                            x['@RTECPID'] === state.lastUpdatedCheckboxParent['@RTECPID'] &&
                            x['@PayerID'] === state.lastUpdatedCheckboxParent['@PayerID']);

                    action.data.masterCrud.EligibilityPayerMaintenanceInfo["@AutoListConversionFlag"] = 'Y';

                    if(recordToUpdate){
                        recordToUpdate['@Bridge'] = 'Y';
                    }
                }
                return{
                    ...state
                }
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //    const exhaustiveCheck: never = action;
                return _.cloneDeep(defaultState)                
        }
    }
    return defaultState || _.cloneDeep(defaultState);
}



