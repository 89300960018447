import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Caret, CaretUp } from '@optum-uicl/ui-core/dist';
import styled from 'styled-components';
import { SortDataType, ISortIndicator } from '@scripts/util/SortHelper';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
export interface IARMHeaderCellProps {
    dataName: string,
    text: string,
    cellType: string,
    sortable: boolean,
    select: object,
    sortIndicator: ISortIndicator,
    sortDataType: SortDataType,
    sortHandler: object
}


export const HeaderButtonWrapper = styled.div`

    button{
        display: block;
        position: relative;
        margin: 5px 10px ;
        padding: 1px 20px 1px 0px;
        color: green;
        background-color: transparent;
        border: none !important;
 
        color: ${Colors.grey100};
        ${Typography.small};
        ${Typography.defaultLineHeight};
        ${Typography.bold};
        ${Typography.uppercase};
        &.activeCol {
            font-weight: 600 !important;
            & .caret-span {fill-opacity: 1 !important;}
        }
    }
    .caret-span{
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        cursor: pointer;

        .arrow{
            margin-left: 5px;
        }
`;



interface IComponentState {
}

export const DEFAULT_STATE: IComponentState = {
};


export class ARMHeaderCell extends React.Component<IARMHeaderCellProps | any, IComponentState>
{
    constructor(props: IARMHeaderCellProps) {
        super(props);
    }

    static propTypes: {};

    SortGrid() {
        if (this.props.sortable) {
            const { setRef, refKey, sortHandler } = this.props
            let newSortIndicator = this.getNewSortIndicator();
            setRef && refKey ? sortHandler(newSortIndicator, refKey) : sortHandler(newSortIndicator);
        }
    }
    getNewSortIndicator(): ISortIndicator {
        const {
            dataName,
            sortIndicator,
            sortDataType
        } = this.props;

        let newSortIndicator: ISortIndicator = {
            sortColumn: '',
            sortDirection: '',
            sortDataType: sortDataType
        };

        newSortIndicator.sortColumn = dataName;
        if (sortIndicator.sortColumn === dataName) {
            newSortIndicator.sortDirection = sortIndicator.sortDirection === 'up' ? 'down' : 'up';
        } else {
            // default sort direction should be 'up' if last sort column was different
            newSortIndicator.sortDirection = 'up';
        }
        return newSortIndicator;
    }

    render() {
        const {
            dataName,
            text,
            sortable,
            select,
            sortIndicator,
            setRef,
            hasCorrectedTabIndex
        } = this.props;
        let sortDirection = '';
        if (sortIndicator && sortIndicator.sortColumn === dataName) {
            sortDirection = sortIndicator.sortDirection;
        } else {
            sortDirection = 'none';
        }

        return (
            <>
            <th className={dataName}><HeaderButtonWrapper>
                {select &&
                        <button className={sortIndicator?.sortColumn == dataName ? 'activeCol' : ''}
                        ref={button => { setRef && setRef(button) }}
                        tabIndex={!hasCorrectedTabIndex || sortIndicator ? 0 : -1}
                    >
                        {select}
                        {sortable &&
                            <span className="caret-span" onClick={() => { this.SortGrid() }}>
    
                                {sortDirection == 'up' ? (
                                    <CaretUp
                                        className="arrow"
                                        size="small"

                                    />
                                ) : (
                                    <Caret
                                        size="small"
                                        className={sortDirection}
                                    />
                                )}

                            </span>
                        }
                    </button>
                }

                {!select && text &&
                        <button className={sortIndicator?.sortColumn == dataName ? 'activeCol' : ''}
                        ref={button => { setRef && setRef(button) }}
                        tabIndex={!hasCorrectedTabIndex || sortIndicator ? 0 : -1}
                        onClick={() => { this.SortGrid()}}                    >
                        {text}
                        {sortable &&
                            <span className="caret-span">

                                {sortDirection == 'up' ? (
                                    <CaretUp
                                        className="arrow"
                                        size="small"

                                    />
                                ) : (
                                    <Caret
                                        size="small"
                                        className={sortDirection}
                                    />
                                )}
                            </span>
                        }
                    </button>
                }
                </HeaderButtonWrapper></th>
            </>
        );
    }
};

ARMHeaderCell.propTypes = {
    dataName: PropTypes.string,
    text: PropTypes.string,
    cellType: PropTypes.string,
    sortable: PropTypes.bool,
    sortIndicator: PropTypes.object,
    sortDataType: PropTypes.number,
    sortHandler: PropTypes.func
}
