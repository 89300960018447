import { FileDownloader } from "./FileDownloader";

export class JobDetailsTableBuilder {
    // ReSharper disable CommentTypo
    // ReSharper disable IdentifierTypo
    // ReSharper disable StringLiteralTy


    buildTable(jobInfo: JobDetailsData) {

        const isSupportUser = jobInfo.IsSupportUser;

        const detailsTable: JobDetailsViewModel = {
            Header: "",
            Details: { Items: [], },
            Lineage: {
                Title: "",
                Events: []
            },
            JobIsRunning: false,
        };

        let rowNum = 1;
        let dataTable: TableMetadataItem[] = [];
        let dataTableRow = 0;

        switch (jobInfo.JobTypeId) {

            // IAscJobDetails
            case EJobType.ejtASC: //27
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "ASC");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Payment ID", jobInfo.PaymentID);
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 2, jobInfo);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // IBridgeJobDetails
            case EJobType.ejtBridge: //2
            case EJobType.ejtPostFuncBridgeParent: //257
            case EJobType.ejtPostFuncBridgeChild: //258
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Bridge");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Client Alias", jobInfo.ClientAlias);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "File Name", this.getFileName(jobInfo.FileName, isSupportUser), 3, undefined, fontSizeSmall);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Submitted By Agent", (jobInfo.IsAgent ? "Yes" : "No"), 3, undefined, fontSizeSmall);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Original File Name", jobInfo.OriginalFileName, 3, undefined, fontSizeSmall);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Accepted", jobInfo.Accepted, 3);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Rejected", jobInfo.Rejected, 3);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Discarded", jobInfo.Discarded, 3);

                if (jobInfo.StateReported != undefined && jobInfo.StateReported > 0) {
                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "State Only", jobInfo.StateReported, 3);
                }

                if (jobInfo.TestMode === 1) {
                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Test Mode", "Yes");
                }

                if (jobInfo.JobTypeId === EJobType.ejtPostFuncBridgeParent) {
                    rowNum++;
                    this.addLogFileLink(detailsTable.Details.Items, rowNum, 1, jobInfo);
                }

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // ISubmit837JobDetails
            case EJobType.ejtSubmit837: //156
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "SubmitClaims");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Client Alias", jobInfo.ClientAlias);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "File Name", this.getFileName(jobInfo.FileName, isSupportUser), 3, undefined, fontSizeSmall);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Accepted", jobInfo.Accepted, 3);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Rejected", jobInfo.Rejected, 3);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Discarded", jobInfo.Discarded, 3);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "BridgeKey", jobInfo.BridgeKey, 3);

                rowNum++;
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 1, jobInfo);

                this.addJobLineage(jobInfo, detailsTable);

                break;

            // ICommandJobDetails
            case EJobType.ejtCommand: // 1
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Command");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Type ID", jobInfo.JobCommandTypeID);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Type", jobInfo.JobCommandType);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum,
                    1,
                    "Target App Server ID",
                    jobInfo.TargetAppServerID);
                if (jobInfo.JobCommandTypeID !== 53) {
                    this.addJobDetailsLink(detailsTable.Details.Items, rowNum, 2, "Target Job ID", jobInfo.TargetJobID);
                }

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum,
                    1,
                    "Parameters",
                    jobInfo.Parameters);
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 2, jobInfo);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // ISMSExportJobDetails
            case EJobType.ejtSMSExport: // 43
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Siemens Export");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Group ID", jobInfo.GroupID);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Export Type", jobInfo.ExportTypeName);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // IClaimStateJobDetails
            case EJobType.ejtClaimState: // 42
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Change Claim State");
                rowNum++;

                let toStateDesc = "Released";

                switch (jobInfo.ToState) {
                    case 1:
                        toStateDesc = "Unreleased";
                        break;
                    case 13:
                        toStateDesc = "Not Sent";
                        break;
                    case 15:
                        toStateDesc = "Sent";
                        break;
                }
                this.addItem(detailsTable.Details.Items, rowNum, 1, "To State", toStateDesc, 3);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // ISpinOffJobDetails
            case EJobType.ejtSpinOffClaims: // 35
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Spin Off");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "To Form", jobInfo.ToForm);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "To Form Name", jobInfo.ToFormName);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // IAutoListDetails
            case EJobType.ejtAutoList: // 29
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Auto List");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Process Ref", jobInfo.ProcessRef);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Job Name", jobInfo.JobName);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Trigger", jobInfo.TriggerContext, undefined, undefined, 'widthMedium', undefined, undefined, false);
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 2, jobInfo, 3);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // IImportClaimsJobDetails
            case EJobType.ejtImportClaims: // 28
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Import Claims");

                let priority = "Unknown";
                switch (jobInfo.FileType) {
                    case 1:
                        priority = "High";
                        break;
                    case 3:
                        priority = "Low";
                        break;
                }
                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Priority", priority, 3);

                rowNum++;
                this.addFileNameLink(detailsTable.Details.Items, rowNum, 1, "File Name", jobInfo.FileName, jobInfo.ConfigKeys, isSupportUser, 3);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            //  IImportTaxonomy, IImportSDR, IImportReasonCodes, IImportParallonFacilities, ImportCarcRarcGroupCodes, ImportSCPI, ClaimStatusRequest, ClaimStatusResponse, ImportPolicyMN, ImportRevenueCodeGroups, ImportMednecOmit
            case EJobType.ejtImportTaxonomy: // 157
            case EJobType.ejtImportSDR: // 159 
            case EJobType.ejtImportCSC: // 161 
            case EJobType.ejtFileImportReasonCodes: // 163 
            case EJobType.ejtFileImportParallonFacilities: // 164 
            case EJobType.ejtFileImportCarcRarcGroupCodes: // 167 
            case EJobType.ejtImportSCPI: // 168 
            case EJobType.ejtClaimStatusRequest: // 181 
            case EJobType.ejtClaimStatusResponse: // 183 
            case EJobType.ejtImportPolicyMN: // 192 
            case EJobType.ejtRevenueCodeGroup: // 203 
            case EJobType.ejtImportMednecOmit: // 204 
            case EJobType.ejtImportScpiFile: // 255
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Import");

                rowNum++;
                this.addFileNameLink(detailsTable.Details.Items, rowNum, 1, "File Name", jobInfo.FileName, jobInfo.ConfigKeys, isSupportUser, 3);
                ////  for import jobs that have log files
                if (jobInfo.JobTypeId === EJobType.ejtFileImportCarcRarcGroupCodes ||
                    jobInfo.JobTypeId === EJobType.ejtClaimStatusRequest ||
                    jobInfo.JobTypeId === EJobType.ejtImportPolicyMN ||
                    jobInfo.JobTypeId === EJobType.ejtRevenueCodeGroup ||
                    jobInfo.JobTypeId === EJobType.ejtImportMednecOmit ||
                    jobInfo.JobTypeId === EJobType.ejtImportScpiFile)
                {

                    rowNum++;
                    this.addLogFileLink(detailsTable.Details.Items, rowNum, 1, jobInfo, 3);
                }

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // IClaimUpdateFileJobDetails
            case EJobType.ejtProcessClaimUpdateFile: // 165
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Claim Update File");

                rowNum++;
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 1, jobInfo, 3);
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Claim Count", jobInfo.ClaimCount);

                rowNum++;
                this.addFileNameLink(detailsTable.Details.Items, rowNum, 1, "File Name", jobInfo.FileName, jobInfo.ConfigKeys, isSupportUser, 3);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Status", jobInfo.Status, 4, undefined, "innerHtml");

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // IImportJobDetails
            case EJobType.ejtYouGotMail: // 57
            case EJobType.ejtImportMail: // 44
            case EJobType.ejtImport277: // 46
            case EJobType.ejtImport835: // 47
            case EJobType.ejtImportAii: // 48
            case EJobType.ejtImportCCI: // 49
            case EJobType.ejtImportClaimStatus: // 50
            case EJobType.ejtImportEcarMast: // 51
            case EJobType.ejtImportEditSev: // 52
            case EJobType.ejtImportErrorText: // 53
            case EJobType.ejtImportNeicMast: //55
            case EJobType.ejtImportSLDesc: // 56
            case EJobType.ejtParse277: // 5
            case EJobType.ejtParse835: // 7
            case EJobType.ejtParseAii: // 9
            case EJobType.ejtParseCCI: // 11
            case EJobType.ejtParseClaimStatus: // 13
            case EJobType.ejtParseEcarMast: // 15
            case EJobType.ejtParseEditSev: // 17
            case EJobType.ejtParseErrorText: // 19
            case EJobType.ejtParseNeicMast: // 23
            case EJobType.ejtParseSLDesc: // 25
            case EJobType.ejtEtl277: // 6
            case EJobType.ejtEtl835: // 8
            case EJobType.ejtEtlAii: // 10
            case EJobType.ejtEtlCCI: // 12
            case EJobType.ejtEtlClaimStatus: // 14
            case EJobType.ejtEtlEcarMast: // 16
            case EJobType.ejtEtlEditSev: // 18
            case EJobType.ejtEtlErrorText: // 20
            case EJobType.ejtEtlNeicMast: // 24
            case EJobType.ejtEtlSLDesc: // 26
            case EJobType.ejtImportHipaaCodeList: // 77 
            case EJobType.ejtParseHipaaCodeList: // 78 
            case EJobType.ejtEtlHipaaCodeList: // 79 
            case EJobType.ejtImportProcedureCode: // 91 
            case EJobType.ejtParseProcedureCode: // 92 
            case EJobType.ejtImportMedicareReasonCodes: // 95 
            case EJobType.ejtParseMedicareReasonCodes: // 96 
            case EJobType.ejtEtlMedicareReasonCodes: // 97 
            case EJobType.ejtImportFPT: // 100 
            case EJobType.ejtFPTRelease: // 101 
            case EJobType.ejtImportRemitLOB: // 113 
            case EJobType.ejtParseRemitLOB: // 114 
            case EJobType.ejtImportIPLAN: // 121 
            case EJobType.ejtParseIPLAN: // 122 
            case EJobType.ejtImportRTEFieldMap: // 124 
            case EJobType.ejtParseRTEFieldMap: // 125 
            case EJobType.ejtImportPayerDirectory: // 142 
            case EJobType.ejtParsePayerDirectory: // 143 
            case EJobType.ejtParseRuleDRL: // 152 
            case EJobType.ejtImportRuleDRL: // 153 
            case EJobType.ejtECSResponseParser: // 189 
            case EJobType.ejtBatchClaimStatusResponse: // 190 
            case EJobType.ejtImportRemitTransferFile: // 209 
            case EJobType.ejtParseRemitTransferFile: // 210 
            case EJobType.ejtEtlRemitTransferFile: // 211                
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Import");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "File ID", jobInfo.FileID, 3);

                rowNum++;
                this.addFileNameLink(detailsTable.Details.Items, rowNum, 1, "File Name", jobInfo.FileName, jobInfo.ConfigKeys, isSupportUser, 3);

                // for ETL835 or ETLAii or ETLRemitTransferFile jobs only
                if (jobInfo.JobTypeId === EJobType.ejtEtl835 ||
                    jobInfo.JobTypeId === EJobType.ejtEtlAii ||
                    jobInfo.JobTypeId === EJobType.ejtEtlRemitTransferFile) {
                    rowNum++;
                    let fileName = `sites/${jobInfo.LogonAlias}/JobFiles/${jobInfo.JobID}/${jobInfo.JobID}_RemitCalcs.Script`;
                    this.addFileNameLink(detailsTable.Details.Items, rowNum, 1, "Calculations", fileName, jobInfo.ConfigKeys, isSupportUser, 3);
                    //sites/<%= jobInfo.LogonAlias %>/JobFiles/<%= jobInfo.JobID %>/<%= jobInfo.JobID %>_RemitCalcs.Script
                }

                // for import jobs that have log files
                if (jobInfo.JobTypeId === EJobType.ejtEtl835 ||
                    jobInfo.JobTypeId === EJobType.ejtEtlAii ||
                    jobInfo.JobTypeId === EJobType.ejtParseClaimStatus ||
                    jobInfo.JobTypeId === EJobType.ejtParseAii ||
                    jobInfo.JobTypeId === EJobType.ejtYouGotMail ||
                    jobInfo.JobTypeId === EJobType.ejtEtlRemitTransferFile) {
                    rowNum++;
                    this.addLogFileLink(detailsTable.Details.Items, rowNum, 2, jobInfo, 3);
                }

                if (jobInfo.JobTypeId === EJobType.ejtParse835 ||
                    jobInfo.JobTypeId === EJobType.ejtEtl835 ||
                    jobInfo.JobTypeId === EJobType.ejtImport835 ||
                    jobInfo.JobTypeId === EJobType.ejtImportRemitTransferFile ||
                    jobInfo.JobTypeId === EJobType.ejtParseRemitTransferFile ||
                    jobInfo.JobTypeId === EJobType.ejtEtlRemitTransferFile) {

                    if (jobInfo.Payments !== undefined) {

                        dataTable = this.createDataTable(4, undefined, fontSizeSmall);

                        for (let i = 0; i < jobInfo.Payments.length; i++) {
                            let payment = jobInfo.Payments[i];
                            let className = (payment.Status === "Fail" ? failBackgroundClass : "");
                            
                            let matched = `${payment.Matched}`;
                            if (!this.isBlank(payment.ActualMatchedCount) && payment.ActualMatchedCount !== payment.Matched)
                                matched += ` (${payment.ActualMatchedCount})`;

                            let unmatched = `${payment.Unmatched}`;
                            if (!this.isBlank(payment.ActualUnmatchedCount) && payment.ActualUnmatchedCount !== payment.Unmatched)
                                unmatched += ` (${payment.ActualUnmatchedCount})`;

                            let colNum = 1;
                            dataTableRow++;
                            this.addItem(dataTable, dataTableRow, colNum++, "Import date", payment.ImportDate, undefined, undefined, className);
                            this.addItem(dataTable, dataTableRow, colNum++, "Payment date", payment.PaymentDate, undefined, undefined, className);
                            this.addItem(dataTable, dataTableRow, colNum++, "Payment no", payment.PaymentNo, undefined, undefined, className);
                            this.addItem(dataTable, dataTableRow, colNum++, "LOB", payment.LOB, undefined, undefined, className);
                            this.addItem(dataTable, dataTableRow, colNum++, "Payer", payment.Payer, undefined, undefined, className);
                            this.addItem(dataTable, dataTableRow, colNum++, "Provider no", payment.ProviderNo, undefined, undefined, className);
                            this.addItem(dataTable, dataTableRow, colNum++, "Matched", matched, undefined, undefined, className);
                            this.addItem(dataTable, dataTableRow, colNum++, "Unmatched",unmatched, undefined, undefined, className);
                            this.addItem(dataTable, dataTableRow, colNum++, "Amount", payment.Amount, undefined, undefined, className);
                            this.addItem(dataTable, dataTableRow, colNum++, "ASC status", payment.AscStatus, undefined, undefined, className);
                            this.addItem(dataTable, dataTableRow, colNum++, "Status", payment.Status, undefined, undefined, className);
                        }

                        rowNum++;
                        this.addItem(detailsTable.Details.Items, rowNum, 1, "", undefined, 4, undefined, undefined, undefined, dataTable);
                    }
                }
                else
                    if (jobInfo.JobTypeId === EJobType.ejtECSResponseParser
                        || jobInfo.JobTypeId === EJobType.ejtBatchClaimStatusResponse
                        || jobInfo.JobTypeId === EJobType.ejtParse277
                        || jobInfo.JobTypeId === EJobType.ejtEtl277
                        || jobInfo.JobTypeId === EJobType.ejtImport277) {

                        rowNum++;
                        this.addItem(detailsTable.Details.Items, rowNum, 1, "Status", jobInfo.Status);

                        if (!this.isBlank(jobInfo.FileName)) {
                            var fn = this.getFileName(jobInfo.FileName);
                            if (fn.substr(2, 1).toLowerCase() === "t")
                                this.addItem(detailsTable.Details.Items, rowNum, 2, "Test Mode", "Yes");
                        }

                        if (jobInfo.JobTypeId === EJobType.ejtECSResponseParser) {
                            this.addLogFileLink(detailsTable.Details.Items, rowNum, 2, jobInfo);
                        }
                    }

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // IReleaseJobDetails
            case EJobType.ejtRelease:
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Release");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Group ID", jobInfo.GroupID);
                if (jobInfo.ReleaseType === "5") {
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "State Reporting State", jobInfo.StateReportingStateAbbr);
                }

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Instit. Count", jobInfo.InstitutionalCount);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Phys. Count", jobInfo.PhysicianCount);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Instit. Value", jobInfo.InstitutionalValue);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Phys. Value", jobInfo.PhysicianValue);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Exit Code", jobInfo.ExitCode);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Job State", jobInfo.JobState);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Release Type", jobInfo.ReleaseTypeDesc);
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 2, jobInfo);

                if (jobInfo.TestMode === 1) {
                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Test Mode", "Yes");
                }
                this.addJobLineage(jobInfo, detailsTable);
                break;

            case EJobType.ejtPhoneHome: // 160
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Phone Home");

                rowNum++;
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 1, jobInfo);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            case EJobType.ejtCopyClaimsToTest: // 166
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Copy Claims To Test");

                rowNum++;
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 1, jobInfo);
                this.addJobLineage(jobInfo, detailsTable);
                break;

            case EJobType.ejtReport: // 30
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Report");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Group ID", jobInfo.GroupID, (jobInfo.TestMode === 1) ? 3 : 1);
                if (jobInfo.TestMode === 1) {
                    this.addItem(detailsTable.Details.Items, rowNum, 4, "Test Mode", "Yes");
                }

                if (jobInfo.Reports !== undefined && jobInfo.Reports.ReportRequests !== undefined) {
                    dataTable = this.createDataTable(3, undefined, fontSizeSmall);

                    if (jobInfo.Reports.ReportRequests.ReportRequest != undefined) {
                        for (let i = 0; i < jobInfo.Reports.ReportRequests.ReportRequest.length; i++) {
                            let reportRequest = jobInfo.Reports.ReportRequests.ReportRequest[i];
                            let colNum = 1;
                            dataTableRow++;
                            this.addItem(dataTable, dataTableRow, colNum++, "Report", reportRequest.Name);
                            this.addItem(dataTable, dataTableRow, colNum++, "Format", reportRequest.Format);
                            this.addItem(dataTable, dataTableRow, colNum++, "Filter", jobInfo.Reports.FilterName);
                        }
                    }

                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Report Request", undefined, 4, undefined, undefined, undefined, dataTable);
                }

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // IBatchClaimJobDetails
            case EJobType.ejtPaperClaims: // 31 
            case EJobType.ejtPaperClaimsEOB: // 32 
            case EJobType.ejtAiiExtract: // 33 
            case EJobType.ejtDepExtract: // 34 
            case EJobType.ejtSecondaryBillClaims: // 37 
            case EJobType.ejtExportClaims: // 40 
            case EJobType.ejtReEdit: // 41 
            case EJobType.ejtPaperClaimAlternate: // 45 
            case EJobType.ejtIMaCSExtract: // 65 
            case EJobType.ejtPaperClaims277: // 105 
            case EJobType.ejtPaperClaimsOutsource: // 106 
            case EJobType.ejtOutsourceExtract: // 107 
            case EJobType.ejtCEPExtract: // 110 
            case EJobType.ejtChicagoExtract: // 111 
            case EJobType.ejtWEPExtract: // 115 
            case EJobType.ejtCheckEligibility: // 132 
            case EJobType.ejtPaperClaims271: // 135 
            case EJobType.ejtPaperClaimEOBDetail: // 146 
            case EJobType.ejtPaperClaimEOBItemized: // 147 
            case EJobType.ejtPaperClaimEOBMNRA: // 148 
            case EJobType.ejtReprintHCDClaimEOB: // 149 
            case EJobType.ejtReprintMSOClaimEOB: // 150 
            case EJobType.ejtPrintCALTC: // 151 
            case EJobType.ejtReprocessMCARules: // 155 
            case EJobType.ejtReprocessPayerStatusRules: // 169 
            case EJobType.ejtReprocessUserProfiles: // 170 
            case EJobType.ejtPaperClaimsPortal: // 195 
            case EJobType.ejtDepExtractReasonDesc: // 198 
            case EJobType.ejtRRTValidate: // 202 
            case EJobType.ejtPaperClaimsMRAN: // 219 
            case EJobType.ejtDECheckStatusServiceLines: // 244 
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Batch");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Claim Count", jobInfo.ClaimCount);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Filter Name", jobInfo.FilterName);

                // extra details for paper claim printing jobs
                switch (jobInfo.JobTypeId) {
                    case EJobType.ejtPaperClaims: // 31 
                    case EJobType.ejtPaperClaimsEOB: // 32 
                    case EJobType.ejtPaperClaimAlternate: // 45 
                    case EJobType.ejtPaperClaims277: // 105 
                    case EJobType.ejtPaperClaimsOutsource: // 106 
                    case EJobType.ejtPaperClaims271: // 135 
                    case EJobType.ejtPaperClaimEOBDetail: // 146 
                    case EJobType.ejtPaperClaimEOBItemized: // 147 
                    case EJobType.ejtPaperClaimEOBMNRA: // 148 
                    case EJobType.ejtReprintHCDClaimEOB: // 149 
                    case EJobType.ejtReprintMSOClaimEOB: // 150 
                    case EJobType.ejtPrintCALTC: // 151 
                    case EJobType.ejtPaperClaimsPortal: // 195 
                        rowNum++;
                        this.addItem(detailsTable.Details.Items, rowNum, 1, "Font Face", jobInfo.FontFace);
                        this.addItem(detailsTable.Details.Items, rowNum, 2, "Font Size", jobInfo.FontSize);

                        rowNum++;
                        this.addItem(detailsTable.Details.Items, rowNum, 1, "Font Adjust", jobInfo.FontAdjust);
                        this.addItem(detailsTable.Details.Items, rowNum, 2, "Print Type", jobInfo.FontSize);

                        rowNum++;
                        this.addItem(detailsTable.Details.Items, rowNum, 1, "Professional Form", jobInfo.ProfessionalFormName);
                        this.addItem(detailsTable.Details.Items, rowNum, 2, "Institutional Form", jobInfo.InstitutionalFormName);

                        rowNum++;
                        this.addItem(detailsTable.Details.Items, rowNum, 1, "Professional Command Set", jobInfo.ProfessionalPrintCommandSetName);
                        this.addItem(detailsTable.Details.Items, rowNum, 2, "Institutional Command Set", jobInfo.InstitutionalPrintCommandSetName);
                        break;
                }

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Trigger Event", `${(this.isBlank(jobInfo.EventDescription) ? "" : `${jobInfo.EventDescription} (${jobInfo.EventId})`)}`);
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 2, jobInfo);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Group ID", jobInfo.GroupID);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Report Auto Configuration ID", jobInfo.ReportAutoConfig);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // IBatchClaimJobDetails - Run Bridge Routines
            case EJobType.ejtRunBridgeRoutines: // 145
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Run Bridge Routines");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Claim Count", jobInfo.ClaimCount);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Filter Name", jobInfo.FilterName);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Options", jobInfo.JobData);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Trigger Event", jobInfo.EventId);
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 2, jobInfo);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Group ID", jobInfo.GroupID);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // IDESQueueDetails
            case EJobType.ejtDESQueue: // 59
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "DES Interface");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "File Name", this.getFileName(jobInfo.FileName), 3, undefined, fontSizeSmall);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Destination File", jobInfo.DestinationFileName, 3, undefined, fontSizeSmall);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Outgoing File", jobInfo.OutgoingFileName, 3, undefined, fontSizeSmall);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "HSP Code", jobInfo.HSPCode);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Flag", jobInfo.Flag);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Retry Count", jobInfo.RetryCount);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Status", jobInfo.Status);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Status Date Time", jobInfo.StatusDateTime);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "File Type ID", jobInfo.FileTypeId);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Queue Date Time", jobInfo.QueueDateTime);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Description", jobInfo.Description, 3);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // Notes: FUS/CTV Concatenation
            case EJobType.ejtFUSCTVConcate: // 61
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Notes: FUS & CTV Concatenation");

                rowNum++;
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 2, jobInfo, undefined, false);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // IDESSubmissionDetails
            case EJobType.ejtDESSubmissionFiles: // 62
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "DES Submission Files");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "File Name", this.getFileName(jobInfo.FileName, isSupportUser), 3, undefined, fontSizeSmall);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "File Type", jobInfo.FileType);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Bridge Key", jobInfo.BridgeKey);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Status", jobInfo.Status);
                this.addJobDetailsLink(detailsTable.Details.Items, rowNum, 2, "DES Job ID", jobInfo.DESJobId);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Page Count", jobInfo.PageCount, 3);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Total Count", jobInfo.TotalCount);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Total Amount", jobInfo.TotalAmount);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Content Field ID", jobInfo.ContentFieldId);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Control Field ID", jobInfo.ControlFieldId);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // IRemitPaymentListJobDetails
            case EJobType.ejtRemoveRemitPayments: // 74
            case EJobType.ejtRecalcRemits: // 80
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, (jobInfo.JobTypeId === 74 ? "Remove Remits" : "Recalculate Remits"));

                if (jobInfo.JobTypeId === 80) {

                    let fileName = `sites/${jobInfo.LogonAlias}/JobFiles/${jobInfo.JobID}/${jobInfo.JobID}_RemitCalcs.Script`;
                    rowNum++;
                    this.addFileNameLink(detailsTable.Details.Items, rowNum, 1, "Calculations", fileName, jobInfo.ConfigKeys, isSupportUser, 3);
                    this.addLogFileLink(detailsTable.Details.Items, rowNum, 4, jobInfo);
                }

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Reason", jobInfo.Reason, 3);

                if (jobInfo.Payments !== undefined) {
                    dataTable = this.createDataTable(4, undefined, fontSizeSmall);

                    for (let i = 0; i < jobInfo.Payments.length; i++) {
                        let payment = jobInfo.Payments[i];
                        let className = (payment.Status === "Fail" ? failBackgroundClass : "");

                        let colNum = 1;
                        dataTableRow++;
                        this.addItem(dataTable, dataTableRow, colNum++, "Filename", payment.Filename, undefined, undefined, className);
                        this.addItem(dataTable, dataTableRow, colNum++, "Import date", payment.ImportDate, undefined, undefined, className);
                        this.addItem(dataTable, dataTableRow, colNum++, "Payment date", payment.PaymentDate, undefined, undefined, className);
                        this.addItem(dataTable, dataTableRow, colNum++, "Payment no", payment.PaymentNo, undefined, undefined, className);
                        this.addItem(dataTable, dataTableRow, colNum++, "Provider no", payment.ProviderNo, undefined, undefined, className);
                        this.addItem(dataTable, dataTableRow, colNum++, "Secondary", payment.Secondary, undefined, undefined, className);
                        this.addItem(dataTable, dataTableRow, colNum++, "Matched", payment.Matched, undefined, undefined, className);
                        this.addItem(dataTable, dataTableRow, colNum++, "Unmatched", payment.Unmatched, undefined, undefined, className);
                        this.addItem(dataTable, dataTableRow, colNum++, "Amount", payment.Amount, undefined, undefined, className);
                    }
                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Payment List", undefined, 4, undefined, undefined, undefined, dataTable);
                }

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // IPhysFacJobDetails
            case EJobType.ejtImportFacilityPhysician: // 72
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Bulk Transfer Import");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Field Delimiter", jobInfo.Delimiter);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Header Row", jobInfo.HeaderRow === -1 ? "Yes" : "No");

                if (jobInfo.IncludeFacilities === -1) {
                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Facility File", this.getFileName(jobInfo.FacilityFileName), 3, undefined, fontSizeSmall);
                }

                if (jobInfo.IncludePhysicians === -1) {
                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Physician File", this.getFileName(jobInfo.PhysicianFileName), 3, undefined, fontSizeSmall);
                }

                if (jobInfo.IncludePhysicianAE === -1) {
                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "PhysicianAE File", this.getFileName(jobInfo.PhysicianAEFileName), 3, undefined, fontSizeSmall);
                }

                if (jobInfo.IncludePayerAlias === -1) {
                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Payer Alias File", this.getFileName(jobInfo.PayerAliasFileName), 3, undefined, fontSizeSmall);
                }

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // IPhysFacJobDetails
            case EJobType.ejtExportFacilityPhysician: // 73
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Bulk Transfer Export");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Field Delimiter", jobInfo.Delimiter);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Header Row", jobInfo.HeaderRow === -1 ? "Yes" : "No");

                if (jobInfo.IncludeFacilities === -1) {
                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Include Facility File", "Yes", 3);
                }

                if (jobInfo.IncludePhysicians === -1) {
                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Include Physician File", "Yes", 3);
                }

                if (jobInfo.IncludePhysicianAE === -1) {
                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Include PhysicianAE File", "Yes", 3);
                }

                if (jobInfo.IncludePayerAlias === -1) {
                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Include Payer Alias File", "Yes", 3);
                }

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // INDCDDESessionJobDetails
            case EJobType.ejtDDESessionEligibility: // 86 
            case EJobType.ejtDDESessionRelease: // 87 
            case EJobType.ejtDDESessionValidate: // 88 
            case EJobType.ejtDDESessionInquiry: // 89 
            case EJobType.ejtDDESessionPasswordChange: // 90 
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Direct Entry Session");

                //  less info is available for Change Password
                if (jobInfo.JobTypeId === EJobType.ejtDDESessionPasswordChange) {
                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "FI Name", jobInfo.FIName);
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "Run State", jobInfo.RunStateDesc);

                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Account Name", jobInfo.AccountName);
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "Parent Job ID", jobInfo.ParentJobId);
                } else {

                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Group ID", jobInfo.GroupID);
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "Run State", jobInfo.RunStateDesc);

                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Parent Group ID", jobInfo.ParentGroupId);
                    this.addJobDetailsLink(detailsTable.Details.Items, rowNum, 2, "Parent Job ID", jobInfo.ParentJobId);

                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "FI Name", jobInfo.FIName);
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "Payer Name", jobInfo.PayerName);

                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Account Name", jobInfo.AccountName);
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "Provider No", jobInfo.ProviderNo);

                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Total Claims", jobInfo.TotalClaimCount);
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "Processed Claims", jobInfo.ProcessedClaimCount);

                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Discarded Claims", jobInfo.DiscardedClaimCount);
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "Remaining Claims", jobInfo.RemainingClaimCount);

                }

                rowNum++;
                let file = `sites/${jobInfo.LogonAlias}/JobFiles/${jobInfo.JobID}/${jobInfo.JobID}_Script.txt`;
                this.addFileNameLink(detailsTable.Details.Items, rowNum, 1, "Script", file, jobInfo.ConfigKeys, isSupportUser);
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 2, jobInfo);

                rowNum++;
                file = `sites/${jobInfo.LogonAlias}/JobFiles/${jobInfo.JobID}/${jobInfo.JobID}_ScreenDef.txt`;
                this.addFileNameLink(detailsTable.Details.Items, rowNum, 1, "Screen Def", file, jobInfo.ConfigKeys, isSupportUser);

                file = `sites/${jobInfo.LogonAlias}/JobFiles/${jobInfo.JobID}/${jobInfo.JobID}_HostCfg.txt`;
                this.addFileNameLink(detailsTable.Details.Items, rowNum, 2, "Host Config", file, jobInfo.ConfigKeys, isSupportUser);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // INDCDDEControllerJobDetails
            case EJobType.ejtDDEEligibility: // 81 
            case EJobType.ejtDDERelease: // 82 
            case EJobType.ejtDDEValidate: // 83 
            case EJobType.ejtDDEInquiry: // 84 
            case EJobType.ejtDDEPasswordChange: // 85 

                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Direct Entry Controller");

                if (jobInfo.JobTypeId === EJobType.ejtDDEPasswordChange) {
                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Controller State", jobInfo.ControllerStateDesc);
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "Run State", jobInfo.RunStateDesc);

                } else {
                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Group ID", jobInfo.GroupID);
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "Run State", jobInfo.RunStateDesc);

                    rowNum++;
                    this.addJobDetailsLink(detailsTable.Details.Items, rowNum, 1, "Release Job ID", jobInfo.ReleaseJobId);
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "Filter Name", jobInfo.FilterName);

                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Total Claims", jobInfo.TotalClaimCount);
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "Processed Claims", jobInfo.ProcessedClaimCount);

                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Discarded Claims", jobInfo.DiscardedClaimCount);
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "Remaining Claims", jobInfo.RemainingClaimCount);

                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Controller State", jobInfo.ControllerStateDesc);
                }

                rowNum++;
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 1, jobInfo);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // INDCDirectSubmitStateJobDetails
            case EJobType.ejtDirectSubmitState: // 94
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Direct Submit State");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "To State", jobInfo.ToState, 3);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // IEPDJobDetails
            case EJobType.ejtEPDRequest: // 75
            case EJobType.ejtEPDInstall: // 76
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Product Update");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Package Type", jobInfo.PackageType);
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 2, jobInfo);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            // ISysImportExportJobDetails
            case EJobType.ejtePremisImport: // 102 
            case EJobType.ejtePremisExport: // 103 
            case EJobType.ejtSiteTemplate: // 108 
            case EJobType.ejtePremisPasImport: // 199 
            case EJobType.ejtePremisPasExport: // 200 
            case EJobType.ejtPasSiteTemplate: // 201 
            case EJobType.ejtSiteTemplateEnterprise: // 231 
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Product Import/Export");

                rowNum++;
                if (jobInfo.JobTypeId === EJobType.ejtSiteTemplateEnterprise) {
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Master Data", jobInfo.MasterData, undefined, undefined, "widthLarge", undefined, undefined, false);
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "Clients", jobInfo.ClientSetAliases, 0, 3, verticalAlignTopClass, undefined, undefined, false);

                } else {
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Master Data", jobInfo.MasterData, 3, undefined, "widthLarge", undefined, undefined, false );
                }

                rowNum++;
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 1, jobInfo);

                rowNum++;
                if (jobInfo.JobTypeId === EJobType.ejtePremisImport
                    || jobInfo.JobTypeId === EJobType.ejtePremisPasImport) {
                    this.addFileNameLink(detailsTable.Details.Items, rowNum, 1, "File", jobInfo.JobData, jobInfo.ConfigKeys, isSupportUser, 3);
                }
                else if (jobInfo.JobTypeId !== EJobType.ejtPasSiteTemplate
                    && jobInfo.JobTypeId !== EJobType.ejtSiteTemplateEnterprise) {
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Identifier", jobInfo.JobData, 3);
                }

                this.addJobLineage(jobInfo, detailsTable);
                break;

            case EJobType.ejtParseIPLAN: // 122
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Parse IPLAN");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "", "");
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 2, jobInfo);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            case EJobType.ejtUpdateRTEPayerProfile: // 123 
            case EJobType.ejtImportPayers: // 171 
            case EJobType.ejtEpic277ReportJob: // 222 
            case EJobType.ejtImportCustomerOptionsJob: // 225 
            case EJobType.ejtAddNoteProcessing: // 232 
            case EJobType.ejt837ViewerPrintPaperClaimsJob: // 242 
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Standard"); //  .NET queued jobs (log only)

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "", "");
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 2, jobInfo);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            case EJobType.ejtEligibilityRequest: // 126 
            case EJobType.ejtEligibilityGateway: // 127 
            case EJobType.ejtEligibility271ResponseParser: // 128 
            case EJobType.ejtEligibilityAudit: // 129 
            case EJobType.ejtEligibilityFormatter: // 130 
            case EJobType.ejtEligibilityError: // 131 
            case EJobType.ejtEligibilityDataStorage: // 133 
            case EJobType.ejtEligibilityStatusMonitor: // 136 
            case EJobType.ejtEligibilityHeldRequest: // 137 
            case EJobType.ejtBIExtractService: // 138 
            case EJobType.ejtHistoricalErrorsService: // 139 
            case EJobType.ejtSystemClaimAssigner: // 140 
            case EJobType.ejtClaimAssignmentNotification: // 141 
            case 144: // not used? 
            case EJobType.ejtRUSHMOREUNKNOWN: // 154 
            case EJobType.ejtBIAudit: // 162 
            case EJobType.ejtCTService: // 175 
            case EJobType.ejtMasterQueueService: // 176 
            case EJobType.ejtPayerStatusRequestService: // 185 
            case EJobType.ejtPayerStatusGatewayService: // 186 
            case EJobType.ejtPayerStatusDataStorageService: // 187 
            case EJobType.ejtPayerStatusResponseParserService: // 188 
            case EJobType.ejtRRTClaimValidation: // 193 
            case EJobType.ejtQueueViewService: // 194 
            case EJobType.ejtAppealsRequestService: // 196 
            case EJobType.ejtClientMetricsService: // 197 
            case EJobType.ejtAntPayCalcJob: // 206 
            case EJobType.ejtAntPayFinalizeJob: // 207 
            case EJobType.ejtAntPayFollowUpJob: // 208 
            case EJobType.eAttmUnsolicitedPWKRequestor: // 212 
            case EJobType.eAttmUnsolicitedPWKWriter: // 213 
            case EJobType.ejtValidationQueueService: // 214 
            case EJobType.ejtUnsolicitedAttachmentFollowupService: // 215 
            case EJobType.ejtParentMatchRequestService: // 216 
            case EJobType.ejtParentMatchStorageService: // 217 
            case EJobType.ejtParentMatchFinalizeService: // 218 
            case EJobType.ejtRemitMatchExtract: // 220 
            case EJobType.ejtPropensityToDeny: // 221 
            case EJobType.ejtPropensityToDenyAsnyc: // 223 
            case EJobType.ejtPropensityToDenyCleanupService: // 226 
            case EJobType.ejtRemitsFileProcessorService: // 227 
            case EJobType.ejtRemitsPaymentParserService: // 228 
            case EJobType.ejtRemitsETLService: // 229 
            case EJobType.ejtRemitsCleanupService: // 230 
            case EJobType.ejtMassAppealCreateService: // 233 
            case EJobType.ejtMassAppealUpdateService: // 234 
            case EJobType.ejtMassAppealMilestoneService: // 235 
            case EJobType.ejtMassAppealDeleteService: // 236 
            case EJobType.ejtMassAppealDocumentService: // 237 
            case EJobType.ejtDirectEntryService: // 243 
            case EJobType.ejtAutomatedAttachmentsCreateService: // 247 
            case EJobType.ejtAutomatedAttachmentsUpdateService: // 248 
            case EJobType.ejtAutomatedAttachmentsFinalizeService: // 249 
            case EJobType.ejtDigitalBillerStatusService: // 253
            case EJobType.ejtDigitalBillerSaveClaimService: // 254
            case EJobType.ejtClaimIngestionService: // 256
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Service");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "", "");
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 2, jobInfo, undefined, false);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            case EJobType.ejtRSRTParentJob: // 172
            case EJobType.ejtRSRTChildJob: // 173
            case EJobType.ejtRSRTRegenerationParent: // 174
            case EJobType.ejtRSRTRegenerationChild: // 178
            case EJobType.ejtRSRTAdhocParent: // 179
            case EJobType.ejtRSRTAdhocChild: // 180
                let title = "Regulatory Report";
                if (jobInfo.JobTypeId === EJobType.ejtRSRTChildJob || jobInfo.JobTypeId === EJobType.ejtRSRTRegenerationChild) {
                    title += " File";
                }
                if (jobInfo.JobTypeId === EJobType.ejtRSRTRegenerationParent || jobInfo.JobTypeId === EJobType.ejtRSRTRegenerationChild) {
                    title = `Regenerate ${title}`;
                }
                if (jobInfo.JobTypeId === EJobType.ejtRSRTAdhocParent || jobInfo.JobTypeId === EJobType.ejtRSRTAdhocChild) {
                    title = `Ad-hoc ${title}`;
                }
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, title);

                let parentJob = (jobInfo.JobTypeId === EJobType.ejtRSRTParentJob || jobInfo.JobTypeId === EJobType.ejtRSRTRegenerationParent || jobInfo.JobTypeId === EJobType.ejtRSRTAdhocParent);
                let childJob = (jobInfo.JobTypeId === EJobType.ejtRSRTChildJob || jobInfo.JobTypeId === EJobType.ejtRSRTRegenerationChild || jobInfo.JobTypeId === EJobType.ejtRSRTAdhocChild);

                rowNum++;
                if (parentJob) {
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "RRT Group Id", jobInfo.RSRTGroupID);
                }
                else if (childJob) {
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Claims", jobInfo.RSRTClaimCount);
                } else {
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "", "");
                }
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 2, jobInfo);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Profile Name", jobInfo.RSRTClientProfileName);
                if (!this.isBlank(jobInfo.RSRTClientProfileName)) {
                    if (parentJob) {
                        this.addFileNameLink(detailsTable.Details.Items, rowNum, 1, "File", jobInfo.RSRTFileName, jobInfo.ConfigKeys);
                    } else if (childJob) {
                        this.addItem(detailsTable.Details.Items, rowNum, 1, "File", jobInfo.RSRTFileName);
                    }
                }

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "TestMode", jobInfo.RSRTTestMode);
                if (!this.isBlank(jobInfo.RSRTOverrideName)) {
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "Include In File", jobInfo.RSRTIncludeInFile);

                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Ad Hoc Description", jobInfo.RSRTOverrideDescription);
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "Ad Hoc Filename", jobInfo.RSRTOverrideName);

                    rowNum++;
                    this.addItem(detailsTable.Details.Items, rowNum, 1, "Ad Hoc Date From", jobInfo.RSRTOverrideDateFrom);
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "Ad Hoc Date Thru", jobInfo.RSRTOverrideDateThru);
                }

                this.addJobLineage(jobInfo, detailsTable);
                break;

            case EJobType.ejtBatchClaimStatusRequest: // 184
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Batch");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Claim Count", jobInfo.ClaimCount);
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 2, jobInfo);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            case EJobType.ejtAntPayFeederJob: // 205
            case EJobType.ejtRetrieve835: // 224
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Remit List");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Group ID", jobInfo.GroupID);
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 2, jobInfo);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            case EJobType.ejtMassAppealFeederJob: // 238
            case EJobType.ejtMassAppealPaperClaimEOB: // 239
            case EJobType.ejtMassAppealFinalizerJob: // 240
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Mass Appeal");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Mass Appeal ID", jobInfo.MassAppealId);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Action User Name", jobInfo.ActionUserName);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Mass Appeal Batch Queue ID", jobInfo.MassAppealBatchQueueId);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Form ID", jobInfo.FormId);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Process Action", jobInfo.ProcessAction);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Form Name", jobInfo.FormName);

                rowNum++;
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 1, jobInfo);
                if (jobInfo.GroupID !== undefined && jobInfo.GroupID !== "") {
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "Group ID", jobInfo.GroupID);
                }

                this.addJobLineage(jobInfo, detailsTable);
                break;

            case EJobType.ejtRemitPostingFile: // 241
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Remit Posting File");

                let processingType = "";
                switch (jobInfo.ProcessingType) {
                    case 0: processingType = "Single"; break;
                    case 1: processingType = "By Payer"; break;
                    case 2: processingType = "By Payment"; break;
                    default: processingType = "N/A";
                }

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Processing Type", processingType);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Claim Count", jobInfo.ClaimCount);

                rowNum++;
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 1, jobInfo);
                if (jobInfo.GroupID !== undefined && jobInfo.GroupID !== "") {
                    this.addItem(detailsTable.Details.Items, rowNum, 2, "Group ID", jobInfo.GroupID);
                }

                this.addJobLineage(jobInfo, detailsTable);
                break;

            case EJobType.ejtAutomatedAttachmentsClaimMatching: // 246
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Automated Attachments Claim Matching");

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Total Attachments", jobInfo.TotalAttachmentCount);
                this.addItem(detailsTable.Details.Items, rowNum, 2, "Unmatched", jobInfo.UnmatchedCount);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Matched", jobInfo.MatchedCount);
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 2, jobInfo);

                this.addJobLineage(jobInfo, detailsTable);
                break;


            case EJobType.ejtLookUpDCN: // 251
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "Look Up DCN");

                rowNum++;
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 1, jobInfo);

                this.addJobLineage(jobInfo, detailsTable);
                break;

            case EJobType.ejtCiamBatchProcessingJob:
                rowNum = this.addBasicInfo(detailsTable, rowNum, jobInfo, "SSO");

                rowNum++;
                this.addLogFileLink(detailsTable.Details.Items, rowNum, 1, jobInfo, 3);
                
                rowNum++;
                var jobStatusMessage = "SSO Batch Job " + jobInfo.JobState + ". See results below.";
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Status", jobStatusMessage);

                rowNum++;
                this.addItem(detailsTable.Details.Items, rowNum, 1, "Status Detail", jobInfo.Status, 4, undefined, "innerHtml");

                this.addJobLineage(jobInfo, detailsTable);
                break;


            default:
                this.addBasicInfo(detailsTable, rowNum, jobInfo, "Standard");

                this.addJobLineage(jobInfo, detailsTable);
                break;

        }

        return detailsTable;
    }

    addBasicInfo(
        detailsTable: JobDetailsViewModel,
        rowNum: number,
        jobInfo: JobDetailsData,
        jobTypeName: string
    ): number {

        detailsTable.JobIsRunning = (jobInfo.JobStateId === 4);

        let header = `${jobTypeName} job`;
        if (jobInfo.JobType.toLowerCase() !== jobTypeName.toLowerCase() && jobInfo.JobType.toLowerCase() !== jobTypeName.replace(" ", "").toLowerCase()) {
            header += ` (${jobInfo.JobType})`;
        }

        if (jobInfo.LastStatusDateTime != undefined) {
            header += ` as of ${jobInfo.LastStatusDateTime}`;
        }

        detailsTable.Header = header;
        this.addItem(detailsTable.Details.Items, rowNum, 1, "Job ID", jobInfo.JobID);
        this.addItem(detailsTable.Details.Items, rowNum, 2, "User Name", jobInfo.UserName);

        rowNum++;
        this.addItem(detailsTable.Details.Items, rowNum, 1, "Logon Alias", jobInfo.LogonAlias);
        this.addItem(detailsTable.Details.Items, rowNum, 2, "Client ID", jobInfo.ClientId);

        rowNum++;
        this.addItem(detailsTable.Details.Items, rowNum, 1, "Enabled", jobInfo.Enabled ? "Yes" : "No");
        this.addItem(detailsTable.Details.Items, rowNum, 2, "State", this.getTableItemState(jobInfo));

        rowNum++;
        this.addItem(detailsTable.Details.Items, rowNum, 1, "Create Time", jobInfo.CreateDateTime);
        this.addItem(detailsTable.Details.Items, rowNum, 2, "Queued Time", jobInfo.QueuedDateTime);

        rowNum++;
        this.addItem(detailsTable.Details.Items, rowNum, 1, "Queued Count", jobInfo.QueuedCount);
        this.addItem(detailsTable.Details.Items, rowNum, 2, "Max Retries", jobInfo.MaxRetries);

        rowNum++;
        this.addItem(detailsTable.Details.Items, rowNum, 1, "Start Time", jobInfo.StartedDateTime);
        this.addItem(detailsTable.Details.Items, rowNum, 2, "End Time", jobInfo.EndedDateTime);

        return rowNum;
    }

    addJobLineage(
        jobInfo: JobDetailsData,
        detailsTable: JobDetailsViewModel
    ) {
        detailsTable.Lineage = jobInfo.JobLineage;
    }

    addItem(
        detailsTable: TableMetadataItem[],
        rowNum: number,
        colNum: number,
        fieldName: string,
        fieldValue: any | undefined = undefined,
        colSpan: number | undefined = undefined,
        rowSpan: number | undefined = undefined,
        classNames: string | undefined = undefined,
        downloadDocument: string | undefined = undefined,
        fieldTable: TableMetadataItem[] | undefined = undefined,
        nowrap: boolean = true
    ): TableMetadataItem {
        const item = this.createItem(rowNum, colNum, fieldName, fieldValue, colSpan, rowSpan, classNames, downloadDocument, fieldTable, nowrap);
        detailsTable.push(item);
        return item;
    }

    addLogFileLink(
        detailsTableItems: TableMetadataItem[],
        rowNum: number,
        colNum: number,
        jobInfo: JobDetailsData,
        colSpan: number | undefined = undefined,
        includeLogonAlias : boolean = true
    ): TableMetadataItem {

        const logFile = `${jobInfo.JobID}.log`;
        const link = jobInfo.IsSupportUser
            ? `sites/${(includeLogonAlias?`${jobInfo.LogonAlias}/`:"")}JobFiles/${jobInfo.JobID}/${logFile}`
            : undefined;

        return this.addItem(
            detailsTableItems,
            rowNum,
            colNum,
            "Log File",
            logFile,
            colSpan,
            undefined,
            `${fontSizeSmall} ${urlClass}`,
            link
        );
    }

    addFileNameLink(
        detailsTableItems: TableMetadataItem[],
        rowNum: number,
        colNum: number,
        fieldName: string,
        fileName: string | undefined,
        configKeys: JobDetailsConfigKeys,
        isSupportUser: boolean = true,
        colSpan: number | undefined = undefined): TableMetadataItem {

        let link: string | undefined = undefined;

        if (isSupportUser) {
            link = this.formatLink(fileName, configKeys);
        }
        fileName = this.getFileName(fileName, isSupportUser);

        return this.addItem(
            detailsTableItems,
            rowNum,
            colNum,
            fieldName,
            fileName,
            colSpan,
            undefined,
            `${fontSizeSmall} ${urlClass}`,
            link
        );
    }

    addJobDetailsLink(
        detailsTableItems: TableMetadataItem[],
        rowNum: number,
        colNum: number,
        fieldName: string,
        jobId: any,
        colSpan: number | undefined = undefined
    ): TableMetadataItem {
        return this.addItem(
            detailsTableItems,
            rowNum,
            colNum,
            fieldName,
            jobId,
            colSpan,
            undefined,
            urlClass,
            "JobDetails"
        );
    }

    createItem(
        rowNum: number,
        colNum: number,
        fieldName: string,
        fieldValue: any | undefined = undefined,
        colSpan: number | undefined = undefined,
        rowSpan: number | undefined = undefined,
        classNames: string | undefined = undefined,
        downloadDocument: string | undefined = undefined,
        fieldTable: TableMetadataItem[] | undefined = undefined,
        nowrap: boolean = true
    ): TableMetadataItem {

        let onClick: Function | undefined = undefined;
        if (!this.isBlank(downloadDocument)) {
            if (downloadDocument === "JobDetails") {
                if (fieldValue !== null)
                    onClick = () => { window.location.href = `Administration/JobQueue/${fieldValue}`; };
            } else {
                onClick = async () => {
                    if (downloadDocument) {
                        await new FileDownloader().downloadFile(`${downloadDocument}`);
                    }
                }
            }
        }

        return {
            RowNumber: rowNum,
            ColumnNumber: colNum,
            FieldName: fieldName,
            FieldValue: fieldValue != undefined && fieldValue != null ? `${fieldValue}` : undefined,
            ColSpan: colSpan,
            RowSpan: rowSpan,
            Nowrap: nowrap,
            ClassNames: classNames,
            OnClick: onClick,
            FieldTable: fieldTable,
        };
    }

    createDataTable(
        colSpan: number | undefined = undefined,
        rowSpan: number | undefined = undefined,
        className: string | undefined = undefined,
    ): TableMetadataItem[] {

        const tableList: TableMetadataItem[] = [];
        return tableList;
    }

    formatLink(url: string | undefined, configKeys: JobDetailsConfigKeys): string | undefined {
        if (url === undefined) return url;

        const wrkUrl = (url as string);
        const dir = (wrkUrl.indexOf("\\EFI\\") > 0)
            ? configKeys.SVR_File
            : (wrkUrl.indexOf("\\Upload\\") > 0)
                ? configKeys.SVR_File
                : configKeys.SVR_File_Support;

        return wrkUrl.replace(dir, "").replace("\\", "/");
    }

    getTableItemState(jobInfo: JobDetailsData): string {

        let resolvedState = jobInfo.JobState;
        if (jobInfo.JobStateId === 4 && jobInfo.ProgressTotal !== 0) {
            resolvedState += ` (${((jobInfo.ProgressCurrent * 100) / jobInfo.ProgressTotal).toFixed(0)}%)`;
        }
        return resolvedState;
    }

    getFileName(fileName: string | undefined, isSupportUser: boolean = false): string {
        if (fileName === undefined) {
            return "";
        }
        let fn = fileName as string;
        if (isSupportUser) {
            return fn;
        }

        const index = fn.lastIndexOf("\\");
        if (index > 0) {
            fn = fn.substr(index + 1);
        }
        return fn;
    }
    
    isBlank(data: string | undefined): boolean {
        return data === undefined || data === null || data?.trim() === "";
    }

    DownloadDocument(filePath: string) {

    }

}

// ReSharper disable InconsistentNaming
enum EJobType {
    ejtSendRecvMail = -1, // for jobs that aren't going to show up in the job queue, go in the negative direction...
    ejtUnknown = 0,
    ejtCommand = 1,
    ejtBridge = 2,
    ejtRelease = 3,
    ejtClaimValidation = 4,
    ejtParse277 = 5,
    ejtEtl277 = 6,
    ejtParse835 = 7,
    ejtEtl835 = 8,
    ejtParseAii = 9,
    ejtEtlAii = 10,
    ejtParseCCI = 11,
    ejtEtlCCI = 12,
    ejtParseClaimStatus = 13,
    ejtEtlClaimStatus = 14,
    ejtParseEcarMast = 15,
    ejtEtlEcarMast = 16,
    ejtParseEditSev = 17,
    ejtEtlEditSev = 18,
    ejtParseErrorText = 19,
    ejtEtlErrorText = 20,
    // ejtParseMedNec				= 21,   medical necessity files no longer imported as of 3.8
    // ejtEtlMedNec				= 22,   medical necessity files no longer imported as of 3.8
    ejtParseNeicMast = 23,
    ejtEtlNeicMast = 24,
    ejtParseSLDesc = 25,
    ejtEtlSLDesc = 26,
    ejtASC = 27,
    ejtImportClaims = 28,
    ejtAutoList = 29,
    ejtReport = 30,
    ejtPaperClaims = 31,
    ejtPaperClaimsEOB = 32,
    ejtAiiExtract = 33,
    ejtDepExtract = 34,
    ejtSpinOffClaims = 35,
    ejtRebillClaims = 36,
    ejtSecondaryBillClaims = 37,
    ejtDeleteClaims = 38,
    ejtUndeleteClaims = 39,
    ejtExportClaims = 40,
    ejtReEdit = 41,
    ejtClaimState = 42,
    ejtSMSExport = 43,
    ejtImportMail = 44,
    ejtPaperClaimAlternate = 45,
    ejtImport277 = 46,
    ejtImport835 = 47,
    ejtImportAii = 48,
    ejtImportCCI = 49,
    ejtImportClaimStatus = 50,
    ejtImportEcarMast = 51,
    ejtImportEditSev = 52,
    ejtImportErrorText = 53,
    // ejtImportMedNec				= 54,   medical necessity files no longer imported as of 3.8
    ejtImportNeicMast = 55,
    ejtImportSLDesc = 56,
    ejtYouGotMail = 57,
    ejtDeleteFiles = 58,
    ejtDESQueue = 59,
    ejtDESListener = 60,
    ejtFUSCTVConcate = 61,
    ejtDESSubmissionFiles = 62,
    ejtIMaCSPostEdit = 63,
    ejtIMaCSPreEdit = 64,
    ejtIMaCSExtract = 65,
    ejtNotesFUS = 66,
    ejtNotesFUSManual = 67,
    ejtNotesCTV = 68,
    ejtNotesCTVManual = 69,
    ejtMasterImport = 70,
    ejtMasterExport = 71,
    ejtImportFacilityPhysician = 72,
    ejtExportFacilityPhysician = 73,
    ejtRemoveRemitPayments = 74,
    ejtEPDRequest = 75,
    ejtEPDInstall = 76,
    ejtImportHipaaCodeList = 77,
    ejtParseHipaaCodeList = 78,
    ejtEtlHipaaCodeList = 79,
    ejtRecalcRemits = 80,
    ejtDDEEligibility = 81,
    ejtDDERelease = 82,
    ejtDDEValidate = 83,
    ejtDDEInquiry = 84,
    ejtDDEPasswordChange = 85,
    ejtDDESessionEligibility = 86,
    ejtDDESessionRelease = 87,
    ejtDDESessionValidate = 88,
    ejtDDESessionInquiry = 89,
    ejtDDESessionPasswordChange = 90,
    ejtImportProcedureCode = 91,
    ejtParseProcedureCode = 92,
    ejtPremisImport = 93,
    ejtDirectSubmitState = 94,
    ejtImportMedicareReasonCodes = 95,
    ejtParseMedicareReasonCodes = 96,
    ejtEtlMedicareReasonCodes = 97,
    ejtNotesCTVHoldCode = 98,
    ejtNotesFUSHoldCode = 99,
    ejtImportFPT = 100,
    ejtFPTRelease = 101,
    ejtePremisImport = 102,
    ejtePremisExport = 103,
    ejtEtl277PayerCodes = 104,
    ejtPaperClaims277 = 105,
    ejtPaperClaimsOutsource = 106,
    ejtOutsourceExtract = 107,
    ejtSiteTemplate = 108,
    ejtDashboard = 109,
    ejtCEPExtract = 110,
    ejtChicagoExtract = 111,
    ejtNotificationServer = 112,
    ejtImportRemitLOB = 113,
    ejtParseRemitLOB = 114,
    ejtWEPExtract = 115,
    ejtCHTransmission = 116,
    ejtCHRetrieval = 117,
    ejtCHMonitor = 118,
    ejtImportCSF = 119,
    ejtParseCSF = 120,
    ejtImportIPLAN = 121,
    ejtParseIPLAN = 122,
    ejtUpdateRTEPayerProfile = 123,
    ejtImportRTEFieldMap = 124,
    ejtParseRTEFieldMap = 125,
    ejtEligibilityRequest = 126,
    ejtEligibilityGateway = 127,
    ejtEligibility271ResponseParser = 128,
    ejtEligibilityAudit = 129,
    ejtEligibilityFormatter = 130,
    ejtEligibilityError = 131,
    ejtCheckEligibility = 132,
    ejtEligibilityDataStorage = 133,
    ejtCancelEligibility = 134,
    ejtPaperClaims271 = 135,
    ejtEligibilityStatusMonitor = 136,
    ejtEligibilityHeldRequest = 137,
    ejtBIExtractService = 138,
    ejtHistoricalErrorsService = 139,
    ejtSystemClaimAssigner = 140,
    ejtClaimAssignmentNotification = 141,
    ejtImportPayerDirectory = 142,
    ejtParsePayerDirectory = 143,
    ejtRunBridgeRoutines = 145,
    ejtPaperClaimEOBDetail = 146,
    ejtPaperClaimEOBItemized = 147,
    ejtPaperClaimEOBMNRA = 148,
    ejtReprintHCDClaimEOB = 149,
    ejtReprintMSOClaimEOB = 150,
    ejtPrintCALTC = 151, // new California LTC form
    ejtParseRuleDRL = 152,
    ejtImportRuleDRL = 153,
    ejtRUSHMOREUNKNOWN = 154,
    ejtReprocessMCARules = 155,
    ejtSubmit837 = 156,
    ejtImportTaxonomy = 157,
    ejtArhiveShouldNameThis = 158,
    ejtImportSDR = 159,
    ejtPhoneHome = 160,
    ejtImportCSC = 161,
    ejtBIAudit = 162,
    ejtFileImportReasonCodes = 163,
    ejtFileImportParallonFacilities = 164,
    ejtProcessClaimUpdateFile = 165,
    ejtCopyClaimsToTest = 166,
    ejtFileImportCarcRarcGroupCodes = 167,
    ejtImportSCPI = 168,
    ejtReprocessPayerStatusRules = 169,
    ejtReprocessUserProfiles = 170,
    ejtImportPayers = 171,
    ejtRSRTParentJob = 172,
    ejtRSRTChildJob = 173,
    ejtRSRTRegenerationParent = 174,
    ejtCTService = 175,
    ejtMasterQueueService = 176,
    ejtDeleteRRTClaims = 177,
    ejtRSRTRegenerationChild = 178,
    ejtRSRTAdhocParent = 179,
    ejtRSRTAdhocChild = 180,
    ejtClaimStatusRequest = 181,
    ejtTransferFileToExchange = 182,
    ejtClaimStatusResponse = 183,
    ejtBatchClaimStatusRequest = 184,
    ejtPayerStatusRequestService = 185,
    ejtPayerStatusGatewayService = 186,
    ejtPayerStatusDataStorageService = 187,
    ejtPayerStatusResponseParserService = 188,
    ejtECSResponseParser = 189,
    ejtBatchClaimStatusResponse = 190, //185-187 are already used.
    ejtHoldCodeProcessing = 191,
    ejtImportPolicyMN = 192,
    ejtRRTClaimValidation = 193,
    ejtQueueViewService = 194,
    ejtPaperClaimsPortal = 195,
    ejtAppealsRequestService = 196,
    ejtClientMetricsService = 197,
    ejtDepExtractReasonDesc = 198,
    ejtePremisPasImport = 199,
    ejtePremisPasExport = 200,
    ejtPasSiteTemplate = 201,
    ejtRRTValidate = 202,
    ejtRevenueCodeGroup = 203,
    ejtImportMednecOmit = 204,
    ejtAntPayFeederJob = 205,
    ejtAntPayCalcJob = 206,
    ejtAntPayFinalizeJob = 207,
    ejtAntPayFollowUpJob = 208,
    ejtImportRemitTransferFile = 209,
    ejtParseRemitTransferFile = 210,
    ejtEtlRemitTransferFile = 211,
    eAttmUnsolicitedPWKRequestor = 212,
    eAttmUnsolicitedPWKWriter = 213,
    ejtValidationQueueService = 214,
    ejtUnsolicitedAttachmentFollowupService = 215,
    ejtParentMatchRequestService = 216,
    ejtParentMatchStorageService = 217,
    ejtParentMatchFinalizeService = 218,
    ejtPaperClaimsMRAN = 219,
    ejtRemitMatchExtract = 220,
    ejtPropensityToDeny = 221,
    ejtEpic277ReportJob = 222,
    ejtPropensityToDenyAsnyc = 223,
    ejtRetrieve835 = 224,
    ejtImportCustomerOptionsJob = 225,
    ejtPropensityToDenyCleanupService = 226,
    ejtRemitsFileProcessorService = 227,
    ejtRemitsPaymentParserService = 228,
    ejtRemitsETLService = 229,
    ejtRemitsCleanupService = 230,
    ejtSiteTemplateEnterprise = 231,
    ejtAddNoteProcessing = 232,
    ejtMassAppealCreateService = 233,
    ejtMassAppealUpdateService = 234,
    ejtMassAppealMilestoneService = 235,
    ejtMassAppealDeleteService = 236,
    ejtMassAppealDocumentService = 237,
    ejtMassAppealFeederJob = 238,
    ejtMassAppealPaperClaimEOB = 239,
    ejtMassAppealFinalizerJob = 240,
    ejtRemitPostingFile = 241,
    ejt837ViewerPrintPaperClaimsJob = 242,
    ejtDirectEntryService = 243,
    ejtDECheckStatusServiceLines = 244,
    ejtAiiService = 245,
    ejtAutomatedAttachmentsClaimMatching = 246,
    ejtAutomatedAttachmentsCreateService = 247,
    ejtAutomatedAttachmentsUpdateService = 248,
    ejtAutomatedAttachmentsFinalizeService = 249,
    ejtLookUpDCN = 251,
    ejtCiamBatchProcessingJob = 252,
    ejtDigitalBillerStatusService = 253,
    ejtDigitalBillerSaveClaimService = 254,
    ejtImportScpiFile = 255,
    ejtClaimIngestionService = 256,
    ejtPostFuncBridgeParent = 257,
    ejtPostFuncBridgeChild = 258,
    // new values go above here, leave ejtMaxValue so it auto increments

    ejtMaxValue
}

const urlClass = "jobDetailsUrl";
const fontSizeSmall = "fontSizeSmall"; //"font-size:12"
const failBackgroundClass = "pinkBackground"; //"background-color:#FDDDDD"
const verticalAlignTopClass = "verticalAlignTop"; // vertical-align:top;width:60%
