// const enums unsupported in cra
//https://ncjamieson.com/dont-export-const-enums/
//https://create-react-app.dev/docs/adding-typescript/
export enum CrudTypes {
    mctiUsers = 0,
    mctiGroups = 1,
    mctiPayer = 2,
    mctiPayerAlias = 3,
    mctiBridge = 4,
    mctiPhysician = 5,
    mctiAEOtherPayer = 6,
    mctiAEUB92Physician = 7,
    mctiAEOtherPhysician = 7,
    mctiAEOutsideFacility = 8,
    mctiAEReferringPhysician = 9,
    mctiAutoSubmit = 10,
    mctiClaimOptionsSuppEdits = 11,
    mctiEditSeverities = 12,
    mctiPaperClaimsConfig = 13,
    mctiClaimChangesConfig = 14,
    mctiSecondaryBillingSetup = 15,
    mctiAcceptRemits = 16,
    mctiClaimOptions = 17,
    mctiEfiFileTypeConfig = 18,
    mctiAutoProcessMaster = 19,
    mctiAutoModuleMaster = 20,
    mctiManualRun = 21,
    mctiFileDownload = 22,
    mctiFacility = 23,
    mctiPhysician2 = 24,
    mctiRemitOptionsSetup = 25,
    mctiSpinOffFieldMap = 26,
    mctiSpinOffFormMap = 27,
    mctiFormsUsed = 28,
    mctiSplitClaimFieldMap = 29,
    mctiFileTypeConfig = 30,
    mctiAutoPrintPrinterConfig = 31,
    mctiAutoPrintMaster = 32,
    mctiLinkCertsToClients = 34,
    mctiReleaseStatus = 35,
    mctiConfigureSubmitter = 36,
    mctiASJobType = 37,
    mctiASServerConfig = 38,
    mctiCreateSubmitter = 39,
    mctiNotesFileMapping = 40,
    mctiDESFiles = 41,
    mctiRemoveRemits = 42,
    mctiEPDEditSchedule = 43,
    mctiCreateCIF = 44,
    mctiRecalcRemits = 45,
    mctiUserAccountPolicy = 46,
    mctiNdcUserMaster = 47,
    mctiSecurityAccess = 48,
    mctiDDEFISetup = 49,
    mctiDDEAccountMaint = 50,
    mctiReasonCodes = 51,
    mctiFIMaint = 52,
    mctiHoldCode = 53,
    mctiCSVOption = 54,
    mctiADRCoverLetter = 55,
    mctiPhysMNCoverLetter = 58,
    mctiClaimNotes = 59,
    mcti277Responses = 60,
    mcti277ResponsesResults = 61,
    mctiDESFileTypes = 62,
    mctiOutsourceUserMaster = 63,
    mctiOutsourceFunctionMaster = 64,
    mctiFieldLevelSecurity = 65,
    mctiUserModuleMaster = 66,
    mctiUserPasswordQuestions = 67,
    mctiProductProfileMaster = 68,
    mctiLinkClients = 69,
    mctiBannedAddressesMaster = 70,
    mctiDashboard = 71,
    mctiUsersGroup = 72,
    mctiFacilityNPIStatus = 73,
    mctiEligibilityPayerProfile = 74,
    mctiUserPreferences = 75,
    mctiClaimEventNotification = 76,
    mctiClaimAssignment = 77,
    mctiAssignFavoriteFilterMaster = 78,
    mctiReconPayerProfile = 79,
    mctiClaimAssignmentDepartment = 80,
    mctiClaimAssignmentError = 81,
    mctiClaimAssignmentErrorSimple = 82,
    mctiBIDashboard = 84,
    mctiHISFieldConfiguration = 85,
    mctiRemoteAddressesMaster = 86,
    mctiClientSetsConfiguration = 87,
    mctiPhoneHomeConfigration = 88,
    mctiSiloInformation = 89,
    mctiErrorLvlAdditonalInfo = 90,
    mctiParallonManageCrossovers = 91,
    mctiAudit72ClientSet = 92,
    mctiSupplementalEdits = 93,
    mctiPreBillIE = 94,
    mctiInternalGroups = 95,
    mctiEditSeveritiesExport = 96,
    mctiEditSeveritiesImport = 97,
    mctiFeaturePreviewConfiguration = 98,
    mctipcgPayerMaintenance = 99
};

export default CrudTypes;
