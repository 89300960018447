
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TabContainer, Input, LoadingIndicator, TextStyles } from '@optum-uicl/ui-core/dist';
import styled from 'styled-components';
import { DialogWrapper, DialogButtonOK } from '@common/DialogWrapper';
import { createApiMapDispatchToProps, createApiMapStateToProps, IMergeApiComponentProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { actionCreators /*, validationCallback */, IUseFileActionProps, IUseFileState } from '@store/UseFile';
import { UploadBridgeRoutine } from '../UploadBridgeRoutine/UploadBridgeRoutine';
import { UseTabGrid } from './UseTabGrid';
import { SortHelper, ISortIndicator, SortDataType } from '@scripts/util/SortHelper';
import { ISampleTreeviewData } from './ISampleTreeviewData';
import { getRawToken } from "@scripts/session/SecurityToken";
import { URLs } from '@commonDevResources/constants';
import { Colors } from '@commonResources/colorVariables';
import refreshArrow from './icons/RefreshArrow.png';
import _ from 'lodash';
import { isArray } from 'util';
import { IRowProps } from '../ManageHelpNotesForEdits/ManageHelpNotesForEdits';
import register from './icons/cvs_register.gif';
import { ModalConfirmation } from '@common/ModalConfirmation';





export const ContentWrapper = styled.div`
             display: flex;
             flex:1 auto;
             flex-direction: row;
             width:950px;

            /*Override Tab Styles*/
             #use-file-use-tab,
             #use-file-upload-tab,
             #use-file-tools-tab, > :first-child button {
             padding-top:1px;
             padding-bottom:1px;
             font-size:11px;
            }
   .tabsUseFile {
        width:'100%';
        height:'600px';
       }

   .link {
        ${TextStyles.small};
        color: ${Colors.brightBlue130};
        padding: 0px;
        text-decoration: underline;
        &: hover {
            text-decoration-color: ${Colors.babyBlue};
            {cursor: pointer;}
        }
    }
`;

const ResultsTabChinContentDiv = styled.div`
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: ${Colors.grey100};
    ${TextStyles.xSmallBold};
    text-transform:uppercase;
`;

const ReasonContainerDiv = styled.div`
    display: flex;
    align-items: center;
`;

const ReasonInputDiv = styled.div`
    width: 400px;
    height: 32px;
    padding-left: 5px;
    position: relative;
`;

export const pageSize = 50;



interface searchResultsObj {
    "tOwner": string;
    "parentData": any[];
};


interface IComponentProps {
    title: string,
};


interface paginatedSearchResult {
    childData: any[];
    parentData: any[];
};

interface ICvsRecord {
    FileName: string;
    Modified: string;
    Size: string;
    Owner: string;
    CheckedOut: string;
    Revisions: [],
    tOwner: string,
    Status: string,
    FileSequence: number,
    DeletedBy: string
}



interface IComponentState {
    selectedIndex: number,
    activeTab: number,
    cancelLeave: boolean;
    isBusy: boolean;
    isBusyWithRegisterAll: boolean; //hack... because I did not want to modify doApiCall to take out the setState for isBusy
    readOnly: boolean;
    haveUnregisteredFile: boolean;
    topOwner: string;
    validationModalMessage: string;
    isValidationModalOpen: boolean;
    reasonInputError: boolean;
    reasonInputText: string;
    isHoveringOverBack: boolean;
    isHoveringOverFoward: boolean;
    searchResults: searchResultsObj;
    paginatedSearchResults: paginatedSearchResult[];
    pageIndex: number;
    dateUUID: string;
    resultsUUID: string;
    treeviewData: ISampleTreeviewData | undefined;
    expandClicked: boolean;
    expandedParentIds: string[];
    inputValue: string;
    sortIndicator: ISortIndicator;
    filesData: any[];
    fileToCheckin: string
    recordToScrollTo: string
    unRegisteredFiles: any[];
    statusMessage: string;
    showRegisterAllFilesAlert: boolean;
};



export const DEFAULT_STATE: IComponentState = {
    selectedIndex: 0,
    activeTab: 0,
    cancelLeave: false,
    isBusy: true,
    isBusyWithRegisterAll: false,
    readOnly: false,
    haveUnregisteredFile: false,
    topOwner: "",
    validationModalMessage: "",
    isValidationModalOpen: false,
    reasonInputError: false,
    reasonInputText: "",
    isHoveringOverBack: false,
    isHoveringOverFoward: false,
    searchResults: {
        "tOwner": "",
        "parentData": []
    },
    paginatedSearchResults: [{
        childData: [],
        parentData: [],
    }],
    pageIndex: 0,
    dateUUID: _.uniqueId(),
    resultsUUID: "" + Date.now(),
    treeviewData: undefined,
    expandClicked: false,
    expandedParentIds: [],
    inputValue: '',
    sortIndicator: {
        sortColumn: 'FileName',
        sortDirection: 'up',
        sortDataType: SortDataType.Date
    },
    filesData: [],
    fileToCheckin: "",
    recordToScrollTo: "",
    unRegisteredFiles: [],
    statusMessage: '',
    showRegisterAllFilesAlert: false,
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IUseFileProps = IMergeApiComponentProps<IUseFileState, IUseFileActionProps, IOwnProps>;

export class UseFile extends React.Component<IUseFileProps, IComponentState> {

    static defaultProps: IComponentProps = {
        title: 'Bridge Routine Maintenance'
    };

    constructor(props: IUseFileProps) {
        super(props);

        this.state = DEFAULT_STATE;
        this.getBridgeFiles();
    }

    apiCallFailed: boolean = false;

    handleScroll = (e: string) => {
        this.setState({ recordToScrollTo: e })
    }


    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.state.recordToScrollTo !== "") {
            document.getElementById(this.state.recordToScrollTo)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    public async componentWillUnmount() {
        this.setState({
            selectedIndex: 0,
            activeTab: 0,
            cancelLeave: false,
            reasonInputError: false,
            reasonInputText: this.state.topOwner,
            isHoveringOverBack: false,
            isHoveringOverFoward: false,
            recordToScrollTo: "",
            searchResults: {
                "tOwner": "",
                "parentData": []
            },
            paginatedSearchResults: [{
                childData: [],
                parentData: [],
            }],
            dateUUID: _.uniqueId(),
            resultsUUID: "" + Date.now(),
            treeviewData: undefined,
            expandClicked: false,
            expandedParentIds: [],
            inputValue: '',
            sortIndicator: {
                sortColumn: 'FileName',
                sortDirection: 'up',
                sortDataType: SortDataType.Date
            },
        });
        resetApiComponentState(this.props.action, this.props.dataStore);
    };

    public getPageSelectionButtons = () => {
        const {
            isHoveringOverBack,
            isHoveringOverFoward,
            paginatedSearchResults,
            pageIndex
        } = this.state;

        const nPages = paginatedSearchResults.length

        const isBackArrowDisabled = (nPages < 2) || (pageIndex < 1)
        const isFowardArrowDisabled = (nPages < 2) || (pageIndex === nPages - 1)

        const isBackArrowGray = isBackArrowDisabled || isHoveringOverBack
        const isFowardArrowGray = isFowardArrowDisabled || isHoveringOverFoward

        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div
                    onMouseEnter={() => { this.setState({ isHoveringOverBack: true }); this.clearScrollTo(); }}

                    onMouseLeave={() => { this.setState({ isHoveringOverBack: false }); this.clearScrollTo(); }}

                    onClick={() => {
                        isBackArrowDisabled || this.setState({
                            pageIndex: pageIndex - 1,
                            resultsUUID: "" + Date.now(),
                            expandedParentIds: [],
                        })
                        this.clearScrollTo();
                    }} >
                    {
                        isBackArrowGray ?
                            <img src={'/Shared/Images/Buttons/But_ArrowLeft_creamOff.png'} /> :
                            <img src={'/Shared/Images/Buttons/But_ArrowLeft_creamUp.png'} />
                    }
                </div>
                <div style={{ paddingBottom: "5px" }}>{pageIndex + 1 + "/" + nPages}</div>
                <div
                    onMouseEnter={() => { this.setState({ isHoveringOverFoward: true }); this.clearScrollTo(); }}

                    onMouseLeave={() => { this.setState({ isHoveringOverFoward: false }); this.clearScrollTo(); }}

                    onClick={() => {
                        isFowardArrowDisabled || this.setState({
                            pageIndex: pageIndex + 1,
                            resultsUUID: "" + Date.now(),
                            expandedParentIds: [],
                        })
                        this.clearScrollTo();
                    }} >
                    {
                        isFowardArrowGray ?
                            <img src={'/Shared/Images/Buttons/But_ArrowRight_creamOff.png'} /> :
                            <img src={'/Shared/Images/Buttons/But_ArrowRight_creamUp.png'} />
                    }
                </div>
            </div>
        );
    };

    public setResultsGridState = (obj: object, funct: () => any = () => { }) => {
        this.setState({ ...this.state, ...obj }, funct);
    };

    public getBridgeFiles = async () => {
        //console.log("getBridgeFiles - enter");
        this.setState({ isBusy: true });

        // this.setState({ pageIndex: this.state.pageIndex });

        await fetch(URLs.api + '/api/data/GetAllBridgeFiles',
            {
                method: 'GET',
                /*       headers: {
                           'Accept': 'application/json',
                           'Content-Type': 'application/json',
                           'Authorization': `${getRawToken()}`
                       },*/

            }).then(response => {
                if (response.status == 500) {
                    this.disableUploadTab(false);
                    this.setState({
                        isValidationModalOpen: true,
                        validationModalMessage: "API has server error.",
                        statusMessage: '',
                        isBusyWithRegisterAll: false,
                        isBusy: false,
                    })

                } else
                    return response.json();
            }).then((data) => {

                //console.log("getBridgeFiles - got the data");
                if (data) {
                    this.setState({ filesData: data });

                    /*Iterate data and check for unregistered files
                     * Which are records that do not have any revisions
                    */

                    let countOfUnRegisteredFiles = 0;
                    let arrUnRegisteredFiles: any[] = [];

                    Object.entries(data).forEach((entry) => {
                        const [key, value] = entry;
                        if (isArray(value)) {
                            value.map((e: any, i, a) => {
                                let record: ICvsRecord = e;
                                if (record.Revisions.length == 0) {
                                    arrUnRegisteredFiles.push(record.FileName);
                                    countOfUnRegisteredFiles++;
                                }
                            })
                        }
                    });

                    if (arrUnRegisteredFiles.length > 0) {
                        this.setState({ unRegisteredFiles: arrUnRegisteredFiles });
                    }

                    this.disableUploadTab(false);

                    this.setState({
                        // selectedIndex: 0,
                        pageIndex: this.state.pageIndex > 0 ? this.state.pageIndex : 0,
                        haveUnregisteredFile: countOfUnRegisteredFiles > 0 ? true : false,
                        isBusy: false,
                        isBusyWithRegisterAll: false,
                        statusMessage: '',
                        searchResults: data.Files,
                        topOwner: data.Owner,
                        paginatedSearchResults: this.paginateResults(data.Files, {
                            sortColumn: 'FileName',
                            sortDirection: 'up',
                            sortDataType: SortDataType.Date
                        }),
                        resultsUUID: "" + Date.now(),
                        expandedParentIds: [],
                    });
                } else {
                    this.setState({
                        //selectedIndex: 0,
                        pageIndex: 0,
                        searchResults: {
                            "tOwner": "",
                            "parentData": []
                        },
                        paginatedSearchResults: [{
                            childData: [],
                            parentData: [],
                        }],
                        resultsUUID: "" + Date.now(),
                    });
                };

            }).catch(error => {
                console.log('error:', error);
            });
    }

    public formatResults = (data: any) => {
        const formattedResults = {
            parentData: data.map((files: any) => {
                const {
                    CheckedOut,
                    Size,
                    FileName,
                    Modified,
                    Owner,
                    Revisions,
                    tOwner,
                    Status,
                    FileSequence,
                    DeletedBy
                } = files
                return ({
                    CheckedOut,
                    Size,
                    FileName,
                    Modified,
                    Owner,
                    Revisions,
                    tOwner,
                    Status,
                    FileSequence,
                    DeletedBy
                })
            })

        };
        return formattedResults;
    };

    public paginateResults = (data: any, sortIndicator: any) => {
        const sortedData = SortHelper.Sort(
            data,
            sortIndicator
        );
        const nPages = sortedData.length === 0 ? 1 : Math.ceil(sortedData.length / pageSize);
        let pages: any[] = [];
        let dataSlice: any[] = [];
        let formattedDataSlice;
        for (let i = 0; i < nPages; i++) {
            dataSlice = sortedData.slice((i * pageSize), ((i + 1) * pageSize));
            formattedDataSlice = this.formatResults(dataSlice);
            pages = [
                ...pages,
                formattedDataSlice
            ];
        };
        return pages;
    };

    public createLockorUnlockButton = () => {

        let userName: any = sessionStorage?.getItem("Username");
        if (userName?.toUpperCase().startsWith('NDC\\')) {
            userName = userName.substring(4, userName.length);
        }

        if (!this.state.isBusy) {

            if (this.state.topOwner != "") {
                if (this.state.topOwner.toUpperCase() === userName?.toUpperCase()) {
                    return <><img role="button"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.doApiCall("UNLOCK", "", true)}
                        src={refreshArrow} />&nbsp;
                        <a style={{ cursor: "pointer" }} href={void (0)} onClick={() => { this.clearScrollTo(); this.doApiCall("UNLOCK", "", true); }} > UNLOCK </a>
                    </>;
                }
                else {
                    return "Locked by " + this.state.topOwner;
                }
            }
            else {
                return <><img role="button"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.doApiCall("LOCK", "", true)}
                    src={refreshArrow} />&nbsp;
                    <a style={{ cursor: "pointer" }} href={void (0)} onClick={() => { this.clearScrollTo(); this.doApiCall("LOCK", "", true) }} > LOCK </a></>;
            }
        }
    }

    public doApiCall = async (action: string, filename: string, resetFiles: boolean, statusMsg: string = '') => {

        let API_CALL = "";
        const LOCK_FILES_API_CALL = "api/data/LockSiteFiles";
        const UNLOCK_FILES_API_CALL = "api/data/UnlockSiteFiles";
        const REGISTER_API_CALL = 'api/data/BridgeFileAction';
        const REGISTER_COMMAND = 'Register';
        let body: any;

        this.apiCallFailed = false;

        switch (action) {
            case 'LOCK':
                API_CALL = LOCK_FILES_API_CALL;
                break;
            case 'UNLOCK':
                API_CALL = UNLOCK_FILES_API_CALL;
                break;
            case 'REGISTER':
                API_CALL = REGISTER_API_CALL;
                body = JSON.stringify({ BridgeFileName: filename, Command: REGISTER_COMMAND });
                break;
            default:
                console.log(`Sorry, no action found for ${action}.`);
        }

        this.setState({
            isBusy: true,
            statusMessage: statusMsg,
            activeTab: 0,
            pageIndex: 0,
            searchResults: {
                "tOwner": "",
                "parentData": []
            },
            paginatedSearchResults: [{
                childData: [],
                parentData: [],
            }],
            resultsUUID: "" + Date.now(),
        });

        await fetch(URLs.api + '/' + API_CALL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: body

            }).then(response => {
                if (response.status == 500) {
                    console.log("doApiCall - error - response.status=500");
                    this.apiCallFailed = true;
                    this.setState({
                        isValidationModalOpen: true,
                        validationModalMessage: "Failed to " + action.charAt(0).toUpperCase() + action.substr(1).toLowerCase() + " " + filename,
                        isBusy: false
                    })

                } else
                    return response.json();
            }).then((data) => {

                if (data == "") {
                    this.setState({ isBusy: false });

                    if (resetFiles == true) {
                        console.log('reset');
                        this.getBridgeFiles();
                    }
                } else {
                    console.log("doApiCall - error - data:" + data);
                    this.apiCallFailed = true;
                    this.setState({
                        isValidationModalOpen: true,
                        validationModalMessage: "Failed to " + action.charAt(0).toUpperCase() + action.substr(1).toLowerCase() + " " + filename,
                        isBusy: false
                    })

                }
            }).catch(error => {
                console.log('Exception - Action:' + action + ' file:' + filename, error);
                this.apiCallFailed = true;
                this.setState({
                    isValidationModalOpen: true,
                    validationModalMessage: "Failed to " + action.charAt(0).toUpperCase() + action.substr(1).toLowerCase() + " " + filename,
                    isBusy: false,
                })
            });
    }

    public doRegisterFiles = (filename: string) => {

        this.setState({
            fileToCheckin: filename
        })

        document.getElementById('use-file-upload-tab')?.click();

    };

    public doCheckIn = (filename: string) => {
        this.setState({
            fileToCheckin: filename
        })

        document.getElementById('use-file-upload-tab')?.click();
    };

    public doRegisterAllFiles = async () => {

        if (this.state.unRegisteredFiles.length > 0) {

            this.setState({ isBusyWithRegisterAll: true, haveUnregisteredFile: false, statusMessage: `Registering All Files...` });

            let IsAllFilesRegistered = false;

            for (var i = 0; i < this.state.unRegisteredFiles.length; i++) {
                var fileName = this.state.unRegisteredFiles[i];

                //update message
                console.log("Registering " + fileName);
                var statusMsg = "Registering '" + fileName + "'...   " + (i + 1) + " of " + this.state.unRegisteredFiles.length;
                await this.doApiCall('REGISTER', fileName, false, statusMsg);

                console.log(`Done Registering ${fileName}`);
                if (this.apiCallFailed || i === this.state.unRegisteredFiles.length - 1) {
                    console.log(`Done Registering all files`);
                    IsAllFilesRegistered = true;
                    this.setState({ statusMessage: `Refreshing...` });
                }

                if (this.apiCallFailed || IsAllFilesRegistered) {
                    var today3 = new Date();
                    console.log("all registered - getBridgeFiles - ", today3);
                    this.getBridgeFiles();
                }

                if (this.apiCallFailed) {
                    console.log("api call failed - stopping registering files");
                    break;
                }
            }
        }
    };

    public clearFileValue = () => {

        this.setState({
            fileToCheckin: ""
        })
    };

    public clearScrollTo = () => {

        this.setState({
            recordToScrollTo: ""
        })
    };

    public onOKViewResponse(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push("/LandingPage");
    }

    onToggleConfirmation(e: React.MouseEvent<HTMLElement>) {
        this.props.action.confirm.closeConfirm();
    }

    public onClickConfirm(e: React.MouseEvent<HTMLButtonElement>) {
        this.props.dataStore.confirm.confirmCallback();
    }

    public onFileUploadSave = () => {
        this.getBridgeFiles();
        //this.props.action.ui.setActiveTab({selectedIndex:0});
    }

    getUseTab() {
        const {
            searchResults,
            paginatedSearchResults,
            pageIndex,
            resultsUUID,
            isBusy,
            isBusyWithRegisterAll,
        } = this.state


        return (
            <div id="1">
                <UseTabGrid
                    key={resultsUUID}
                    treeviewData={paginatedSearchResults[pageIndex]}
                    setResultsGridState={this.setResultsGridState}
                    onSort={(sortIndicator: any) => { return this.paginateResults(searchResults, sortIndicator) }}
                    resultsGridState={{ ...this.state, treeviewData: paginatedSearchResults[pageIndex] }}
                    onGetBridgeFiles={this.getBridgeFiles}
                    setBusyLoader={this.setBusyLoader}
                    onGetFilename={this.doCheckIn}
                    onClearScrollTo={this.clearScrollTo}
                    onHandleScroll={this.handleScroll}
                />
                <ResultsTabChinContentDiv>
                    <ReasonContainerDiv>
                        <ReasonInputDiv>
                            <div style={{ paddingTop: "2px", display: "flex" }} > {this.createLockorUnlockButton()}</div>
                        </ReasonInputDiv>
                    </ReasonContainerDiv>
                    <>
                        {
                            (isBusy || isBusyWithRegisterAll) && (<React.Fragment>
                                <div style={{ paddingTop: "2px", width: "315px", paddingLeft: "5px", display: "flex", position: "absolute", bottom: "300px", left: "460px" }} ><LoadingIndicator length="32px" />&nbsp;&nbsp;</div>
                                <div style={{ paddingTop: "2px", width: "250px", paddingLeft: "5px", display: "flex" }} >&nbsp;</div>
                            </React.Fragment>
                            )
                        }
                    </>
                    {(this.state.haveUnregisteredFile && !isBusy && !isBusyWithRegisterAll) && (
                        <React.Fragment>
                            <div style={{ paddingTop: "2px", width: "260px", paddingLeft: "5px", display: "flex" }} >
                                <span id="registerAllFiles" className="link" onClick={() => this.handleRegisterAllFilesAlert(true)}>
                                    <img src={register} style={{ verticalAlign: "bottom", marginRight: "5px" }} />
                                    Register All Files</span>
                            </div>
                        </React.Fragment>
                    )
                    }
                    {(this.state.statusMessage != '' && (isBusy || isBusyWithRegisterAll)) && (
                        <React.Fragment>
                            <div style={{ paddingTop: "2px", width: "650px", paddingLeft: "5px", display: "flex", textAlign: 'right' }} >
                                {this.state.statusMessage}
                            </div>
                        </React.Fragment>
                    )
                    }
                    {this.getPageSelectionButtons()}
                </ResultsTabChinContentDiv>
            </div>
        );
    }


    getUploadTab() {
        return (
            <div id="2">
                <UploadBridgeRoutine {...this.props}
                    filesData={this.state.filesData}
                    onFileUploadSave={this.onFileUploadSave}
                    setBusyLoader={this.setBusyLoader}
                    fileToCheckin={this.state.fileToCheckin}
                    clearScrollTo={this.clearScrollTo}
                    clearFileCheckin={this.clearFileValue} />
            </div>
        );
    }

    /*  getToolsTab() {
          return (
              <div id ="3">
                  TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB   TOOLS TAB 
              </div>
          );
      }*/


    getTabs() {
        var tabJson = [
            {
                domID: 'use-file-use-tab',
                label: 'Use',
                tabContent: this.getUseTab()
            },
            {
                domID: 'use-file-upload-tab',
                label: 'Upload',
                tabContent: this.getUploadTab()
            },
            /*     {
                     domID: 'use-file-tools-tab',
                     label: 'Tools',
                     tabContent: this.getToolsTab()
                 },*/
        ];
        return tabJson;
    }

    onTabSelect(e: React.MouseEvent<HTMLElement>, selectInfo: ITabSelectInfo) {
        e.preventDefault();
    }

    onTabChange(e: React.MouseEvent<HTMLElement>, selectInfo: ITabChangeInfo) {
        e.preventDefault();
    }

    public setBusyLoader = (isbusy: boolean) => {
        this.setState({ isBusy: isbusy });
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {

        this.props.history.push("/LandingPage");
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push('/LandingPage');
    }

    handleRegisterAllFilesAlert(show: boolean) {
        this.setState({ showRegisterAllFilesAlert: show });
    }

    disableUploadTab(disable: boolean) {
        console.log("disableUploadTab with:" + disable);
        const tabButton = document.getElementById('use-file-upload-tab') as HTMLButtonElement;
        if (tabButton) tabButton.disabled = disable;
    }

    handleRegisterAllFilesConfirmation(confirm: boolean) {
        console.log("handleRegisterAllFilesConfirmation - confirm:", confirm);
        this.setState({ showRegisterAllFilesAlert: false /*confirm*/ });
        if (confirm) {
            console.log("handleRegisterAllFilesConfirmation - doRegisterAllFiles");
            this.disableUploadTab(true);
            this.doRegisterAllFiles();
        }
    }

    public render() {

        const {
            isBusy,
            isBusyWithRegisterAll,
            isValidationModalOpen,
            validationModalMessage,
            activeTab,
            showRegisterAllFilesAlert,
        } = this.state;

        var buttons = <DialogButtonOK onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} disable={isBusyWithRegisterAll} />

        return (
            <DialogWrapper title={this.props.title} helpUrl='/Support/Help/HELP_Maint_BridgeRoutine.htm' buttons={buttons}>
                <ContentWrapper>
                    <div style={{ width: 980 }}>

                        <TabContainer
                            key={"Tabs" + activeTab}
                            domID="TabContainerResponseUseFile"
                            initialTab={activeTab}
                            className='tabs tabsUseFile'
                            onTabSelect={(e: React.ChangeEvent<HTMLButtonElement>, index: any) => {
                                this.setState({
                                    activeTab: index.selectedTabIndex
                                })
                            }}
                            tabs={this.getTabs()}

                        />
                    </div>
                    <ModalConfirmation
                        title={""}
                        isOpen={isValidationModalOpen}
                        alertMode={true}
                        onModalToggle={() => this.setState({ isValidationModalOpen: false, validationModalMessage: "" })}
                        formattedMessage={validationModalMessage}
                    />
                    <ModalConfirmation
                        isOpen={showRegisterAllFilesAlert}
                        formattedMessage={(
                            <div>
                                <p>Register All Unregistered Files?</p>
                            </div>)
                        }
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.handleRegisterAllFilesAlert(!showRegisterAllFilesAlert)}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.handleRegisterAllFilesConfirmation(true)}
                        onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.handleRegisterAllFilesConfirmation(false)}
                    />
                </ContentWrapper>
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IUseFileState, IUseFileActionProps, IOwnProps, IUseFileProps, ApplicationState>(
    createApiMapStateToProps('useFile'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(UseFile);

export default withRouter(connectedHoc);
