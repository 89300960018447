import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as AutoSubmitMaintenanceUI from './ui/AutoSubmitMaintenanceUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray, VerifyParentAndArray } from '@scripts/util/ValidationHelpers';

export type IAutoSubmitMaintenanceState = ICrudComponentState<AutoSubmitMaintenanceType, AutoSubmitMaintenanceUI.IAutoSubmitMaintenanceUIState>;

export const actionCreators = createCrudComponentActions<AutoSubmitMaintenanceUI.ActionCreators>(AutoSubmitMaintenanceUI.actionCreators);
export type IAutoSubmitMaintenanceActions = typeof actionCreators;
export type IAutoSubmitMaintenanceActionProps = ICrudComponentActionProps<IAutoSubmitMaintenanceActions>


export const reducers = createCrudComponentReducers(CrudTypes.mctiAutoSubmit, AutoSubmitMaintenanceUI.reducer);
export const reducer = combineReducers<IAutoSubmitMaintenanceState>(reducers);

export const validationCallback: ValidationCallback<AutoSubmitMaintenanceType> = (crud: any): crud is AutoSubmitMaintenanceType => {
    let typeCrud: AutoSubmitMaintenanceType = crud as AutoSubmitMaintenanceType;
    let validShape: boolean = ValidateJSONSchema(typeCrud, "AutoSubmitMaintenanceType");
    if (validShape) {
        if (typeCrud?.AutoSubmitMaintenanceInfo?.BridgeKeyList) {
            VerifyArray(typeCrud?.AutoSubmitMaintenanceInfo?.BridgeKeyList, "BridgeKey");
        }
        VerifyArray(typeCrud?.AutoSubmitMaintenanceInfo?.AutoSubmits, "AutoSubmit");
    }
    return true;
}