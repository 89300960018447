import { ActionTypes, createAction, createDataAction, createApiAction, createApiBodyAction } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import { Reducer } from 'redux';
import { URLs } from '@commonDevResources/constants';
import { uniqueId } from 'lodash';
// -----------------
// STATE - This defines the type of data maintained in the Redux store. PaperCommandsUI
export interface IPaperCommandsUIState {
    searchResults: SearchResultsType[];
    paperCommandSets: PrintCommandSet[];
    selectedFormType: string;
    selectedPaperCommandSetsId: string;
    selectedPaperCommandSetsSetName: string;
    //PaperCommand
    getClientID: [];
    paperCommands: PaperCommands | undefined;
    paperCommandsFiltered: PaperCommands | undefined;
    listPopulation: ListPopulation | undefined;
    action: string;
    fieldID: string;
    occ: string;
    value: string;
    row: string;
    col: string;
    len: string;
    pdfRowRed: string;
    pdfColRed: string;
    pdfLenRed: string;
    pdfSpcRed: string;
    pdfRowGrey: string;
    pdfColGrey: string;
    pdfLenGrey: string;
    pdfSpcGrey: string;
    dataForm: string;
    inx: string;
    function: string;
    isDefaultSelected: string;
    paperCommandSetCount: string;
    command: PaperFormCmd | undefined;
    typeOfBills?: TypeOfBills;
    selectedConditionRadio: String;
    selectedTypeOfBill: string;
    selectedPayer: string;
    selectedWhenCondition: string;
    modalText?: string;
    commandFieldError?: string;
    commandValueError?: string;
    selectedCommandRow: string;
    isSaveSuccess: string;
    isPrintCommandSetModifies: string;
    isSpecificFormType: string;
    isDeleteSuccess: string;
}

export const defaultState: IPaperCommandsUIState = {
    searchResults: [],
    paperCommandSets: [],
    selectedFormType: "",
    selectedPaperCommandSetsId: "",
    selectedPaperCommandSetsSetName: "",
    //PaperCommand
    getClientID: [],
    paperCommands: undefined,
    listPopulation: undefined,
    paperCommandsFiltered: undefined,
    action: "",
    fieldID: "",
    occ: "",
    value: "",
    row: "",
    col: "",
    len: "",
    pdfRowRed: "",
    pdfColRed: "",
    pdfLenRed: "",
    pdfSpcRed: "",
    pdfRowGrey: "",
    pdfColGrey: "",
    pdfLenGrey: "",
    pdfSpcGrey: "",
    dataForm: "",
    inx: "",
    function: "",
    isDefaultSelected: "",
    paperCommandSetCount: "",
    command: undefined,
    typeOfBills: undefined,
    selectedConditionRadio: "",
    selectedTypeOfBill: "",
    selectedPayer: "",
    selectedWhenCondition: "",
    modalText: "",
    commandFieldError: undefined,
    commandValueError: undefined,
    selectedCommandRow: "",
    isSaveSuccess: "",
    isPrintCommandSetModifies: "",
    isSpecificFormType: "false",
    isDeleteSuccess: ""
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface ISearchResultsUIData {
    searchResults: SearchResultsType[]
}

interface IFieldUpdate {
    value: string;
}

export interface IPaperCommandSetsUIData {
    paperCommandSets: PrintCommandSet[]
}

//PaperCommand
export interface IPaperCommandsUIData {
    paperCommands: PaperCommands;
}

export interface IListPopulationUIData {
    listPopulation: ListPopulation;
}

export interface ICommandUIData {
    commandData: PaperFormCmd;
}

export interface IFormValueUIData {
    action: string;
    fieldID: string;
    occ: string;
    value: string;
    row: string;
    col: string;
    len: string;
    pdfRowRed: string;
    pdfColRed: string;
    pdfLenRed: string;
    pdfSpcRed: string;
    pdfRowGrey: string;
    pdfColGrey: string;
    pdfLenGrey: string;
    pdfSpcGrey: string;
    dataForm: string;
    inx: string;
    function: string;
    selectedCommandRow: string;
}

export interface IFormValueWithIdUIData {
    value: string;
    id: string;
    action: any;
}
export interface IClaimTypeIdWithPrintSetDetailsUIData {
    ID: string;
    Name: string;
    ClaimTypeId: string;
    IsDefault: string;
    ActionType: string;
    action: any;
    value: string;
}
interface ICommandUIError {
    value: string;
    id?: string;
}

export interface ISearchResults extends ICrudActionData<SearchResultsType, ISearchResultsUIData> { }
export interface IPaperCommandSets extends ICrudActionData<PrintCommandSet, IPaperCommandSetsUIData> { }
export interface IUpdateField extends ICrudActionData<PaperCommandsType, IFieldUpdate> { }
export interface IListData extends ICrudActionData<PaperCommandsInfoType, IPaperCommandSetsUIData> { }

//PC
export interface IPaperCommandResults extends ICrudActionData<PaperCommandType, IPaperCommandsUIData> { }
export interface IListPopulationResults extends ICrudActionData<ListPopulationType, IListPopulationUIData> { }
export interface IFormValue extends ICrudActionData<FormValueType, IFormValueUIData> { }
export interface IFormValueWithId extends ICrudActionData<FormValueTypeWithId, IFormValueWithIdUIData> { }
export interface IClaimTypeIdWithPrintSetDetails extends ICrudActionData<ClaimTypeIdWithPrintSetDetails, IClaimTypeIdWithPrintSetDetailsUIData> { }
export interface IError extends ICrudActionData<PaperFormCommandField, ICommandUIError> { }

export const actionCreators = {
    searchResults: (results: ISearchResults) => createDataAction('SEARCH_ADDITIONAL_RESULTS', results),
    setPaperCommandSets: (paperCommands: IPaperCommandSets) => createDataAction('PAPER_COMMAND_SETS', paperCommands),
    selectedPaperCommandSetsItem: (data: IFormValueWithId) => createDataAction('SELECTED_PAPER_COMMAND_SETS_ITEM', data),
    selectedFormType: (data: IUpdateField) => createDataAction('SELECTED_FORM_TYPE', data),

    //PaperCommand
    getPaperCommand: () => createApiAction('GET_PAPER_COMMAND', `${URLs.api}/api/data/LoadPaperCommandList/`),
    loadPaperFunctionList: (data: string) => createApiAction('LOAD_PAPER_FUNCTION_LIST', `${URLs.api}/api/data/LoadPaperFunctionsList?clientAlias=${data}`),
    loadFormTypeDetails: (data: IListData) => createApiAction('LOAD_FORM_TYPE_DETAILS', `${URLs.api}/api/data/allpapercommandsets`, data),
    getPaperCommandsFiltered: (data: IFormValueWithId) => createDataAction('GET_PAPER_COMMAND_FILTERED', data),
    getPaperCommandListPopulation: (results: IListPopulationResults) => createDataAction('GET_PAPER_COMMAND_LISTPOPULATION', results),
    validatePaperCommand: (dataForm: string, inx: string, command: string) => createApiBodyAction('VALIDATE_PAPER_COMMAND', `${URLs.api}/api/data/ValidatePaperCommand/${dataForm}/${inx}`, undefined, "POST", JSON.stringify(command)),
    savePaperCommands: (data: string) => createApiBodyAction('SAVE_PAPER_COMMAND_SETS', `${URLs.api}/api/data/SavePaperCommands`, undefined, "POST", JSON.stringify(data)),
    DeletePaperCommand: (data: string) => createApiBodyAction('DELETE_PAPER_COMMAND', `${URLs.api}/api/data/DeletePaperCommandSet`, undefined, "POST", JSON.stringify(data)),
    removePaperCommand: () => createAction('REMOVE_PAPER_COMMAND'),
    setAction: (data: IFormValue) => createDataAction('SET_ACTION', data),
    setFieldID: (data: IFormValue) => createDataAction('SET_FIELDID', data),
    setOcc: (data: IFormValue) => createDataAction('SET_OCC', data),
    setValue: (data: IFormValue) => createDataAction('SET_VALUE', data),
    setRow: (data: IFormValue) => createDataAction('SET_ROW', data),
    setCol: (data: IFormValue) => createDataAction('SET_COL', data),
    setLan: (data: IFormValue) => createDataAction('SET_LAN', data),
    setPDFRowRed: (data: IFormValue) => createDataAction('SET_PDFROWRED', data),
    setPDFColRed: (data: IFormValue) => createDataAction('SET_PDFCOLRED', data),
    setPDFLenRed: (data: IFormValue) => createDataAction('SET_PDFLENRED', data),
    setPDFSpcRed: (data: IFormValue) => createDataAction('SET_PDFSPCRED', data),
    setPDFRowGrey: (data: IFormValue) => createDataAction('SET_PDFROWGREY', data),
    setPDFColGrey: (data: IFormValue) => createDataAction('SET_PDFCOLGREY', data),
    setPDFLenGrey: (data: IFormValue) => createDataAction('SET_PDFLENGREY', data),
    setPDFSpcGrey: (data: IFormValue) => createDataAction('SET_PDFSPCGREY', data),
    setDataForm: (data: IFormValue) => createDataAction('SET_DATA_FORM', data),
    setINX: (data: IFormValue) => createDataAction('SET_INX', data),
    setFunction: (data: IFormValue) => createDataAction('SET_FUNCTION', data),
    setSelectedCommandRow: (data: IFormValue) => createDataAction('SET_SELECTED_COMMAND_ROW', data),
    SetIsDefault: (data: IFormValue) => createDataAction('SET_IS_DEFAULT', data),
    SetPaperCommandCount: (data: IFormValue) => createDataAction('SET_PAPER_COMMAND_COUNT', data),
    AddPaperCommandSetToNewList: (data: IClaimTypeIdWithPrintSetDetails) => createDataAction('ADD_NEW_PAPER_COMMAND_SET', data),
    DeletePaperCommandSet: (data: IClaimTypeIdWithPrintSetDetails) => createDataAction('DELETE_PAPER_COMMAND_SET', data),
    DeSelectPrintCommandSet: (data: IClaimTypeIdWithPrintSetDetails) => createDataAction('DE_SELECT_PRINT_COMMAND_SET', data),
    addTypeOfBill: (data: IFormValue) => createDataAction('ADD_TYPE_OF_BILL', data),
    addPayer: (data: IFormValue) => createDataAction('ADD_PAYER', data),
    getTypeOfBills: () => createDataAction('GET_TYPE_OF_BILLS', ''),
    selectedTypeOfBill: (data: IFormValue) => createDataAction('SELECTED_TYPE_OF_BILL', data),
    selectedPayer: (data: IFormValue) => createDataAction('SELECTED_PAYER', data),
    selectedConditionRadio: (data: IFormValue) => createDataAction('SELECTED_CONDITION_RADIO', data),
    selectedWhenCondition: (data: IFormValue) => createDataAction('SELECTED_WHEN_CONDITION', data),
    setModalText: (modalText: string) => createDataAction('SET_MODAL_TEXT', modalText),
    errorCommand: (fieldInfo: IError) => createDataAction('ERROR_COMMAND_FIELD', fieldInfo),
    ResetDeleteSuccessMessage: (data: IFormValue) => createDataAction('RESET_DELETE_SUCCESS_MESSAGE', data),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
function filterPaperCommands(id: string, name: string, paperCommands: PaperCommands, paperCommandSets: PrintCommandSet[]) {
    let paperCommandOriginal = paperCommands;
    let claimTypeId = paperCommandSets.find((item: PrintCommandSet) => item["@ID"] == id)?.["@ClaimTypeId"];
    let paperCommandsFiltered = paperCommandOriginal?.PaperCommands.PaperCommand.filter((item: PaperCommandInner) => {
        return item["@SetName"] === name && item.Form["@ID"] === claimTypeId
        //if (name.startsWith("Standard") && item.Form["@ID"] === claimTypeId)
        //    return item;
        //else
        //    return item["@SetName"] === name && item.Form["@ID"] === claimTypeId
    });
    if (paperCommandsFiltered !== undefined && paperCommandsFiltered.length > 0) {
        return {
            "PaperCommands": {
                "PaperCommand": [paperCommandsFiltered[0]]
            }
        };
    }
    return undefined;
}
function sortPrintCommandset(sortPrintCommandSets: PrintCommandSet[], claimTypeId: string) {
    let sortedPrintCommandSet: any[] = [];
    if (sortPrintCommandSets !== undefined) {
        let defaultCommandSet = sortPrintCommandSets.find(x => x?.["@IsDefault"] == "-1" && x["@ClaimTypeId"] == claimTypeId);
        if (defaultCommandSet) {
            sortedPrintCommandSet.push(defaultCommandSet)
        }
        sortPrintCommandSets.filter((item: PrintCommandSet) => {
            return item["@IsDefault"] !== "-1" && item["@ClaimTypeId"] === claimTypeId
        }).sort(
            (p1: PrintCommandSet, p2: PrintCommandSet) => (p1["@Name"].toUpperCase() > p2["@Name"].toUpperCase()) ? 1 : (p1["@Name"].toUpperCase() < p2["@Name"].toUpperCase()) ? -1 : 0).map(a => sortedPrintCommandSet.push(a));
    }
    return sortedPrintCommandSet;
}

export const reducer: Reducer<IPaperCommandsUIState, KnownActions> = (state: IPaperCommandsUIState = defaultState, action: KnownActions) => {
    if (state !== undefined) {
        switch (action.type) {
            case 'SET_MODAL_TEXT':
                {
                    return {
                        ...state,
                        modalText: action.data,
                    }
                }
            case 'SEARCH_ADDITIONAL_RESULTS':
                {
                    return {
                        ...state,
                        searchResults: action.data.uiData.searchResults,
                    }
                }
            case 'PAPER_COMMAND_SETS':
                {
                    let paperCommandSetsFormTypeChange = [];
                    if (action.data.uiData.paperCommandSets && action.data.uiData.paperCommandSets.length > 0)
                        paperCommandSetsFormTypeChange = sortPrintCommandset(action.data.uiData.paperCommandSets, state.selectedFormType);
                    return {
                        ...state,
                        paperCommandSets: paperCommandSetsFormTypeChange,
                    }
                }
            case 'SELECTED_PAPER_COMMAND_SETS_ITEM':
                {
                    return {
                        ...state,
                        selectedPaperCommandSetsId: action.data.uiData.id,
                        selectedPaperCommandSetsSetName: action.data.uiData.value
                    }
                }
            case 'SELECTED_FORM_TYPE':
                {
                    let formTypesFromSession = state.searchResults;
                    let formTypeName = "";
                    let isSpecificFormType = "false";
                    if (formTypesFromSession && formTypesFromSession?.length > 0) {
                        formTypeName = formTypesFromSession?.filter((item: SearchResultsType) => {
                            return item.claimTypeId == action.data.uiData.value;
                        })?.[0].claimTypeName;
                        if (formTypeName) {
                            if (formTypeName.toUpperCase().endsWith("ALT PG1") || formTypeName.toUpperCase().endsWith("ALT PG2")
                                || formTypeName.toUpperCase().endsWith("HCD") || formTypeName.toUpperCase().includes("PA-319")
                                || formTypeName.toUpperCase().includes("NY-XIX") || formTypeName.toUpperCase().includes("IL-2360")) {
                                isSpecificFormType = "true";
                            }
                        }
                    }
                    return {
                        ...state,
                        selectedFormType: action.data.uiData.value,
                        isSpecificFormType: isSpecificFormType,
                    }
                }

            //Paper Command
            case 'GET_PAPER_COMMAND':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                            break;

                        case "SUCCESS":
                            {
                                const responseData = action?.responseData;

                                if (responseData) {
                                    // check to see if form type details came back first and run the filter
                                    let responseCommands = JSON.parse(responseData as string);
                                    let filteredCommands: any;
                                    if (state.paperCommandSets && state.paperCommandSets.length > 0) {
                                        let selectedId = state.paperCommandSets ? state.paperCommandSets[0]["@ID"] : "";
                                        let selectedName = state.paperCommandSets ? state.paperCommandSets[0]["@Name"] : "";
                                        filteredCommands = filterPaperCommands(selectedId, selectedName, responseCommands, state.paperCommandSets ? state.paperCommandSets : []);
                                    }
                                    return {
                                        ...state,
                                        paperCommands: responseCommands,
                                        paperCommandsFiltered: filteredCommands
                                    }
                                } else {
                                    console.log('where\'s ma data???');
                                }
                                break;
                            }
                        case "FAIL":
                            {
                                console.log(action, 'Failed to retrieve paper command data');
                                break;
                            }
                    }
                    break;
                }
            //Load Paper Funcs
            case 'LOAD_PAPER_FUNCTION_LIST':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                            break;

                        case "SUCCESS":
                            {
                                const responseData = action?.responseData;
                                if (responseData) {
                                    return {
                                        ...state,
                                        listPopulation: JSON.parse(responseData as string),
                                    }
                                } else {
                                    console.log('PaperCommand function list - No data in request..');
                                }
                                break;
                            }
                        case "FAIL":
                            {
                                console.log(action, 'Failed to retrieve Paper command function data.');
                                break;
                            }
                    }
                    break;
                }
            case 'LOAD_FORM_TYPE_DETAILS':
                {
                    switch (action.status.status) {

                        case "REQUEST":
                            break;

                        case "SUCCESS":
                            {
                                const formResponseData = action?.responseData;

                                if (formResponseData) {
                                    let sortedData = (formResponseData as any).sort(
                                        (p1: any, p2: any) => (p1.claimTypeName > p2.claimTypeName) ? 1 : (p1.claimTypeName < p2.claimTypeName) ? -1 : 0);
                                    let paperCommandSets: PrintCommandSet[] = [];
                                    let crudDataForPaperCommandSets = action.data.masterCrud ? JSON.parse(JSON.stringify(action.data.masterCrud?.PaperClaimsPrintingMaintenanceInfo?.PrintCommandSetList.PrintCommandSet)) : [];
                                    crudDataForPaperCommandSets = sortPrintCommandset(crudDataForPaperCommandSets, sortedData[0].claimTypeId.toString());

                                    if (action.data.masterCrud && sortedData.length > 0) {
                                        paperCommandSets = crudDataForPaperCommandSets.filter((item: PrintCommandSet) => {
                                            return item["@ClaimTypeId"] === sortedData[0].claimTypeId.toString();
                                        });
                                    }
                                    let selectedId = paperCommandSets !== undefined ? paperCommandSets[0]["@ID"] : "";
                                    let selectedName = paperCommandSets !== undefined ? paperCommandSets[0]["@Name"] : "";
                                    let isDefaultCheckSelected = paperCommandSets !== undefined ? paperCommandSets[0]["@IsDefault"] == "-1" ? "true" : "false" : "false";
                                    let filteredCommands: any;
                                    if (state.paperCommands)
                                        filteredCommands = filterPaperCommands(selectedId, selectedName, state.paperCommands, paperCommandSets ? paperCommandSets : []);
                                    return {
                                        ...state,
                                        searchResults: sortedData,
                                        paperCommandSets: paperCommandSets,
                                        selectedPaperCommandSetsId: selectedId,
                                        selectedPaperCommandSetsSetName: selectedName,
                                        paperCommandsFiltered: filteredCommands,
                                        isDefaultSelected: isDefaultCheckSelected,
                                        paperCommandSetCount: String(paperCommandSets?.length)
                                    }
                                } else {
                                    console.log('PaperCommandAll list - No data in request..');
                                }
                                break;
                            }
                        case "FAIL":
                            {
                                console.log(action, 'Failed to retrieve all PaperCommandSets.');
                                break;
                            }
                    }
                    break;
                }

            case 'GET_PAPER_COMMAND_LISTPOPULATION':
                {
                    return {
                        ...state,
                        listPopulation: JSON.parse(action.data.uiData.listPopulation.toString()),
                    }
                }
            case 'GET_PAPER_COMMAND_FILTERED':
                {
                    if (state.paperCommands) {
                        let filteredCommandsObj: any;
                        let filteredCommandSetList = state.paperCommandSets;
                        filteredCommandsObj = filterPaperCommands(action.data.uiData.id, action.data.uiData.value, state.paperCommands, filteredCommandSetList ? filteredCommandSetList : []);
                        return {
                            ...state,
                            paperCommandsFiltered: filteredCommandsObj,
                            isDefaultSelected: filteredCommandsObj?.[0]?.["@IsDefault"],
                            paperCommandSetCount: String(state.paperCommandSets?.length)
                        }
                    }
                    break;
                }

            case 'VALIDATE_PAPER_COMMAND':
                {
                    switch (action.status.status) {

                        case "REQUEST":
                            break;

                        case "SUCCESS":
                            {
                                if (action?.responseData.includes("Command")) {
                                    const paperCommandsFiltered = state?.paperCommandsFiltered
                                    let commandArray: any = state?.paperCommandsFiltered?.PaperCommands?.PaperCommand[0]?.Form?.Cmd?.Command || []
                                    let commandObject: any = []
                                    if (Array.isArray(commandArray)) {
                                        if (state.inx != "") {
                                            const inxIndex = commandArray.findIndex(item => item['@INX'] === state.inx)
                                            const commandJsonUpdate = JSON.parse(action.responseData)

                                            if (inxIndex > -1) {
                                                commandArray.splice(inxIndex, 1);
                                                commandArray.push(commandJsonUpdate?.Command);
                                            }
                                        }
                                        else {
                                            let index = commandArray.length;
                                            const commandJsonInsert = JSON.parse(action.responseData)
                                            if (index == 0) {
                                                commandArray = commandJsonInsert;
                                                if (paperCommandsFiltered)
                                                    paperCommandsFiltered.PaperCommands.PaperCommand[0].Form.Cmd = commandArray;
                                            }
                                            else
                                                commandArray.splice(index, 0, commandJsonInsert?.Command);
                                        }
                                    }
                                    else if (state.inx === "") {
                                        commandObject.push(commandArray);
                                        let odjIndex = commandObject.length;
                                        const commandJsonInsert1 = JSON.parse(action.responseData)
                                        if (odjIndex == 0) {
                                            commandArray = commandJsonInsert1;
                                            if (paperCommandsFiltered)
                                                paperCommandsFiltered.PaperCommands.PaperCommand[0].Form.Cmd = commandArray;
                                        }
                                        else {
                                            commandObject.splice(odjIndex, 0, commandJsonInsert1?.Command);
                                            if (paperCommandsFiltered)
                                                if (paperCommandsFiltered.PaperCommands.PaperCommand[0].Form.Cmd !== null) {
                                                    paperCommandsFiltered.PaperCommands.PaperCommand[0].Form.Cmd.Command = commandObject;
                                                }
                                        }
                                    }
                                    else {
                                        const commandObject = JSON.parse(action.responseData)
                                        commandArray = commandObject;
                                        if (paperCommandsFiltered)
                                            paperCommandsFiltered.PaperCommands.PaperCommand[0].Form.Cmd = commandArray;
                                    }
                                    return {
                                        ...state,
                                        command: commandObject?.length > 0 ? commandObject : commandArray,
                                        paperCommandsFiltered: paperCommandsFiltered,
                                        commandFieldError: '',
                                        commandValueError: '',
                                        action: "AddMap",
                                        fieldID: "",
                                        occ: '',
                                        function: '',
                                        value: '',
                                        row: '',
                                        col: '',
                                        len: '',
                                        pdfRowRed: '',
                                        pdfColRed: '',
                                        pdfLenRed: '',
                                        pdfSpcRed: '',
                                        pdfRowGrey: '',
                                        pdfColGrey: '',
                                        pdfLenGrey: '',
                                        pdfSpcGrey: '',
                                        inx: '',
                                        selectedCommandRow: '',
                                        selectedConditionRadio: '',
                                        selectedPayer: '',
                                        selectedTypeOfBill: '',
                                        selectedWhenCondition: '',
                                    }
                                }
                                else {
                                    return {
                                        ...state,
                                        modalText: action.responseData,
                                        commandFieldError: '',
                                        commandValueError: ''
                                    }
                                }
                            }
                        case "FAIL":
                            {
                                console.log(action, 'Failed to validate command data.');
                                break;
                            }
                    }
                    break;
                }

            case 'REMOVE_PAPER_COMMAND':
                {
                    const paperCommandsFiltered = state?.paperCommandsFiltered
                    let commandArray: any = state?.paperCommandsFiltered?.PaperCommands?.PaperCommand[0]?.Form?.Cmd?.Command || []
                    if (Array.isArray(commandArray)) {
                        const inxIndex = commandArray.findIndex(item => item['@INX'] === state.inx)
                        if (inxIndex > -1)
                            commandArray.splice(inxIndex, 1);
                    }
                    else {
                        if (paperCommandsFiltered !== undefined)
                            paperCommandsFiltered.PaperCommands.PaperCommand[0].Form.Cmd = null;
                    }
                    return {
                        ...state,
                        command: commandArray,
                        paperCommandsFiltered: paperCommandsFiltered,
                        commandFieldError: '',
                        commandValueError: '',
                        action: "AddMap",
                        fieldID: "",
                        occ: '',
                        function: '',
                        value: '',
                        row: '',
                        col: '',
                        len: '',
                        pdfRowRed: '',
                        pdfColRed: '',
                        pdfLenRed: '',
                        pdfSpcRed: '',
                        pdfRowGrey: '',
                        pdfColGrey: '',
                        pdfLenGrey: '',
                        pdfSpcGrey: '',
                        inx: '',
                        selectedCommandRow: '',
                        selectedConditionRadio: '',
                        selectedPayer: '',
                        selectedTypeOfBill: '',
                        selectedWhenCondition: '',
                    }
                }

            case 'SAVE_PAPER_COMMAND_SETS':
                switch (action.status.status) {
                    case "VALIDATE":
                    case "REQUEST":
                        return {
                            ...state,
                        }
                    case "SUCCESS":
                        let isSaveSuccess = JSON.stringify(action.responseData).toString();
                        return {
                            ...state,
                            isSaveSuccess: isSaveSuccess
                        }
                    case "FAIL":
                        console.log("Error: failed to Save Paper Commands");
                        return {
                            ...state,
                            isSaveSuccess: JSON.stringify(action.responseData).toString(),
                        }
                    default:
                        return state;
                }

            case 'DELETE_PAPER_COMMAND':
                switch (action.status.status) {
                    case "VALIDATE":
                    case "REQUEST":
                        return {
                            ...state,
                        }
                    case "SUCCESS":
                        let isDeleteSuccess = action.responseData;
                        return {
                            ...state,
                            isDeleteSuccess: isDeleteSuccess
                        }
                    case "FAIL":
                        console.log("Error: failed to Delete Paper Commands");
                        return {
                            ...state,
                            isDeleteSuccess: action.responseData,
                        }
                    default:
                        return state;
                }
            case 'SET_ACTION':
                {
                    return {
                        ...state,
                        action: action.data.uiData.action,
                        function: '',
                        value: ''
                    }
                }
            case 'SET_FIELDID':
                {
                    return {
                        ...state,
                        fieldID: action.data.uiData.fieldID,
                        commandFieldError: undefined
                    }
                }
            case 'SET_OCC':
                {
                    return {
                        ...state,
                        occ: action.data.uiData.occ
                    }
                }
            case 'SET_VALUE':
                {
                    return {
                        ...state,
                        value: action.data.uiData.value,
                        commandValueError: undefined
                    }
                }
            case 'SET_ROW':
                {
                    return {
                        ...state,
                        row: action.data.uiData.row
                    }
                }
            case 'SET_COL':
                {
                    return {
                        ...state,
                        col: action.data.uiData.col
                    }
                }
            case 'SET_LAN':
                {
                    return {
                        ...state,
                        len: action.data.uiData.len
                    }
                }
            case 'SET_PDFROWRED':
                {
                    return {
                        ...state,
                        pdfRowRed: action.data.uiData.pdfRowRed
                    }
                }
            case 'SET_PDFCOLRED':
                {
                    return {
                        ...state,
                        pdfColRed: action.data.uiData.pdfColRed
                    }
                }
            case 'SET_PDFLENRED':
                {
                    return {
                        ...state,
                        pdfLenRed: action.data.uiData.pdfLenRed
                    }
                }
            case 'SET_PDFSPCRED':
                {
                    return {
                        ...state,
                        pdfSpcRed: action.data.uiData.pdfSpcRed
                    }
                }
            case 'SET_PDFROWGREY':
                {
                    return {
                        ...state,
                        pdfRowGrey: action.data.uiData.pdfRowGrey
                    }
                }
            case 'SET_PDFCOLGREY':
                {
                    return {
                        ...state,
                        pdfColGrey: action.data.uiData.pdfColGrey
                    }
                }
            case 'SET_PDFLENGREY':
                {
                    return {
                        ...state,
                        pdfLenGrey: action.data.uiData.pdfLenGrey
                    }
                }
            case 'SET_PDFSPCGREY':
                {
                    return {
                        ...state,
                        pdfSpcGrey: action.data.uiData.pdfSpcGrey
                    }
                }
            case 'SET_DATA_FORM':
                {
                    return {
                        ...state,
                        dataForm: action.data.uiData.dataForm
                    }
                }
            case 'SET_INX':
                {
                    return {
                        ...state,
                        inx: action.data.uiData.inx
                    }
                }

            case 'SET_FUNCTION':
                {
                    return {
                        ...state,
                        function: action.data.uiData.function
                    }
                }
            case 'SET_SELECTED_COMMAND_ROW':
                {
                    return {
                        ...state,
                        selectedCommandRow: action.data.uiData.selectedCommandRow
                    }
                }
            case 'SET_PAPER_COMMAND_COUNT':
                {
                    let count = action.data.uiData.value;
                    return {
                        ...state,
                        paperCommandSetCount: count,
                    }
                }

            case 'SET_IS_DEFAULT':
                {
                    let isDefaultCommand = action.data.uiData.value;
                    return {
                        ...state,
                        isDefaultSelected: isDefaultCommand
                    }
                }

            case 'ADD_NEW_PAPER_COMMAND_SET':
                {
                    let paperCommandInitial = state.paperCommands?.PaperCommands.PaperCommand;
                    let paperCommandSetsState = state.paperCommandSets;
                    let selectedPaperCommandSetName = state.paperCommandsFiltered?.PaperCommands?.PaperCommand !== undefined ? state.paperCommandsFiltered?.PaperCommands?.PaperCommand[0]["@SetName"] : "";
                    let PaperCommandListAdded: PaperCommands = {
                        PaperCommands: {
                            PaperCommand: []
                        }
                    }
                    let paperCommandsFilteredData: PaperCommands = {
                        PaperCommands: {
                            PaperCommand: []
                        }
                    }
                    if (action.data.uiData.Name !== undefined && action.data.uiData.ClaimTypeId !== undefined) {
                        let paperCommandsSelected = paperCommandInitial?.filter((item: PaperCommandInner) => {
                            return item["@SetName"] === selectedPaperCommandSetName && item.Form["@ID"] === action?.data?.uiData?.ClaimTypeId
                        });
                        let paperCommandNew: PaperCommandInner =
                        {
                            ["@SetName"]: action?.data?.uiData?.Name,
                            ["@IsDefault"]: action?.data?.uiData?.IsDefault === "-1" ? "true" : "false",
                            Form: {
                                ["@ID"]: action?.data?.uiData?.ClaimTypeId,
                                Cmd: null,
                                ["@Name"]: (paperCommandsSelected !== undefined ? (paperCommandsSelected[0]?.Form["@Name"] !== undefined ? paperCommandsSelected[0]?.Form["@Name"] : "") : ""),
                            }
                        }
                        let tempId: string = uniqueId();
                        let printCommandSetNew: PrintCommandSet =
                        {
                            ["@ID"]: tempId,
                            ["@ClaimTypeId"]: action?.data?.uiData?.ClaimTypeId,
                            //["@Name"]: paperCommandSets?.length + 1 + '. ' + action?.data?.uiData?.Name,
                            ["@Name"]: action?.data?.uiData?.Name,
                            ["@IsDefault"]: action?.data?.uiData?.IsDefault
                        }

                        if (paperCommandsSelected !== undefined && paperCommandsSelected.length > 0) {
                            if (action.data.uiData.Name !== undefined && action.data.uiData.ClaimTypeId !== undefined) {
                                if (action.data.uiData.ActionType == "SaveAs") {
                                    paperCommandNew.Form.Cmd = paperCommandsSelected !== undefined ? paperCommandsSelected[0]?.Form.Cmd : null;

                                }
                                paperCommandsFilteredData.PaperCommands.PaperCommand = [paperCommandNew];
                            }

                            if (paperCommandInitial !== undefined) {
                                paperCommandInitial?.push(paperCommandNew);
                                PaperCommandListAdded.PaperCommands.PaperCommand = paperCommandInitial !== undefined ? paperCommandInitial : []
                            }
                            if (paperCommandSetsState !== undefined) {
                                paperCommandSetsState.push(printCommandSetNew);
                            }
                            if (action?.data?.uiData?.IsDefault !== "-1")
                                paperCommandSetsState = sortPrintCommandset(paperCommandSetsState, action?.data?.uiData?.ClaimTypeId)
                        }
                        return {
                            ...state,
                            paperCommands: PaperCommandListAdded,
                            paperCommandSets: paperCommandSetsState,
                            paperCommandsFiltered: paperCommandsFilteredData,
                            selectedPaperCommandSetsId: tempId,
                            selectedPaperCommandSetsSetName: action?.data?.uiData?.Name,
                            isDefaultSelected: paperCommandsFilteredData?.PaperCommands.PaperCommand[0]?.["@IsDefault"],
                            paperCommandSetCount: String(paperCommandSetsState?.length),
                            isPrintCommandSetModifies: "true",
                        }
                    }
                    else {
                        console.log("Failed to Add paper command set data");
                    }
                    break;
                }

            case 'DELETE_PAPER_COMMAND_SET':
                {
                    let paperCommandInitialState = state.paperCommands?.PaperCommands.PaperCommand;
                    let printCommandSetsOriginalState = state.paperCommandSets;
                    let paperCommandNew: PaperCommandInner =
                    {
                        ["@SetName"]: "",
                        ["@IsDefault"]: "",
                        Form: {
                            ["@ID"]: "",
                            Cmd: null,
                            ["@Name"]: "",
                        }
                    }
                    if (action.data.uiData.Name !== undefined && action.data.uiData.ClaimTypeId !== undefined) {

                        let paperCommandInnerIndex = paperCommandInitialState?.findIndex(x => x["@SetName"] == action.data.uiData.Name && x.Form["@ID"] == action?.data?.uiData?.ClaimTypeId)
                        if (paperCommandInnerIndex !== undefined && paperCommandInnerIndex > -1) {
                            paperCommandInitialState?.splice(paperCommandInnerIndex, 1);
                        }

                        //let filteredPaperCommandSet = paperCommandInitialState?.filter((item: PaperCommandInner) => {
                        //    return item["@SetName"] !== action.data.uiData.Name && item.Form["@ID"] !== action?.data?.uiData?.ClaimTypeId
                        //});
                        let printCommandSetIndex = printCommandSetsOriginalState.findIndex(x => x["@Name"] == action.data.uiData.Name && x["@ClaimTypeId"] == action?.data?.uiData?.ClaimTypeId)
                        printCommandSetsOriginalState.splice(printCommandSetIndex, 1);
                        let filteredPrintCommandSet = printCommandSetsOriginalState.filter((item: PrintCommandSet) => {
                            return item["@IsDefault"] == "-1" && item["@ClaimTypeId"] == action?.data?.uiData?.ClaimTypeId
                        });
                        let paperCommandTobeDiplayedinUi = paperCommandInitialState?.filter((item: PaperCommandInner) => {
                            return item["@SetName"] == filteredPrintCommandSet[0]["@Name"] && item.Form["@ID"] == filteredPrintCommandSet[0]["@ClaimTypeId"]
                        });
                        let filteredPaperCommandSetData = {
                            "PaperCommands": {
                                "PaperCommand": paperCommandInitialState !== undefined ? paperCommandInitialState : [paperCommandNew]
                            }
                        }
                        let paperCommandTobeDiplayedinUiData = {
                            "PaperCommands": {
                                "PaperCommand": paperCommandTobeDiplayedinUi !== undefined ? [paperCommandTobeDiplayedinUi?.[0]] : [paperCommandNew]
                            }
                        }
                        let tempObj = printCommandSetsOriginalState.find((item: PrintCommandSet) => {
                            return item["@IsDefault"] == "-1" && item["@ClaimTypeId"] == action.data.uiData.ClaimTypeId
                        });
                        printCommandSetsOriginalState = sortPrintCommandset(printCommandSetsOriginalState, action?.data?.uiData?.ClaimTypeId)
                        return {
                            ...state,
                            paperCommands: filteredPaperCommandSetData,
                            paperCommandSets: printCommandSetsOriginalState,
                            paperCommandsFiltered: paperCommandTobeDiplayedinUiData,
                            selectedPaperCommandSetsId: tempObj !== undefined ? tempObj["@ID"] : "",
                            selectedPaperCommandSetsSetName: tempObj !== undefined ? tempObj["@Name"] : "",
                            isDefaultSelected: tempObj !== undefined ? tempObj["@IsDefault"] : "",
                            paperCommandSetCount: String(printCommandSetsOriginalState?.length),
                            isPrintCommandSetModifies: "true",
                        }
                    }
                    else {
                        console.log("Failed to Delete paper command set data");
                    }
                    break;
                }
            case 'DE_SELECT_PRINT_COMMAND_SET':
                {

                    let paperCommandInitialDeSelect = state.paperCommands?.PaperCommands.PaperCommand;
                    let printCommandSetsInitialDeselect = state.paperCommandSets;
                    let paperCommandEmptyDeSelect: PaperCommandInner =
                    {
                        ["@SetName"]: "",
                        ["@IsDefault"]: "",
                        Form: {
                            ["@ID"]: "",
                            Cmd: null,
                            ["@Name"]: "",
                        }
                    }
                    if (action.data.uiData.Name !== undefined && action.data.uiData.ClaimTypeId !== undefined) {
                        //Deselecting isDefault for All items in the ClaimType
                        paperCommandInitialDeSelect?.filter((item: PaperCommandInner) => {
                            return item.Form["@ID"] == action.data.uiData.ClaimTypeId
                        }).map(x => x["@IsDefault"] = "false");
                        //setting is default true to selected element
                        paperCommandInitialDeSelect?.filter((item: PaperCommandInner) => {
                            return item["@SetName"] == action.data.uiData.Name && item.Form["@ID"] == action.data.uiData.ClaimTypeId
                        }).map(x => x["@IsDefault"] = action.data.uiData.IsDefault == "-1" ? "true" : "false");
                        printCommandSetsInitialDeselect.map(x => x["@IsDefault"] = "0");
                        printCommandSetsInitialDeselect.filter((item: PrintCommandSet) => {
                            return item["@Name"] == action.data.uiData.Name && item["@ClaimTypeId"] == action?.data?.uiData?.ClaimTypeId
                        }).map(x => x["@IsDefault"] = "-1");
                        let filteredCommandSetDeSelect = paperCommandInitialDeSelect?.filter((item: PaperCommandInner) => {
                            return item["@SetName"] == action.data.uiData.Name && item.Form["@ID"] == action.data.uiData.ClaimTypeId
                        });

                        let filteredPrintCommandSetDeSelect = printCommandSetsInitialDeselect?.filter((item: PrintCommandSet) => {
                            return item["@Name"] == action.data.uiData.Name && item["@ClaimTypeId"] == action?.data?.uiData?.ClaimTypeId
                        })?.[0];
                        let paperCommandSetsInitialDeselectData = {
                            "PaperCommands": {
                                "PaperCommand": paperCommandInitialDeSelect !== undefined ? paperCommandInitialDeSelect : [paperCommandEmptyDeSelect]
                            }
                        }
                        let filteredPaperCommandSetDataDeselect = {
                            "PaperCommands": {
                                "PaperCommand": filteredCommandSetDeSelect !== undefined ? filteredCommandSetDeSelect : [paperCommandEmptyDeSelect]
                            }
                        }
                        printCommandSetsInitialDeselect = sortPrintCommandset(printCommandSetsInitialDeselect, action?.data?.uiData?.ClaimTypeId)
                        return {
                            ...state,
                            paperCommands: paperCommandSetsInitialDeselectData,
                            paperCommandSets: printCommandSetsInitialDeselect,
                            paperCommandsFiltered: filteredPaperCommandSetDataDeselect,
                            selectedPaperCommandSetsId: filteredPrintCommandSetDeSelect !== undefined ? filteredPrintCommandSetDeSelect["@ID"] : "",
                            selectedPaperCommandSetsSetName: filteredPrintCommandSetDeSelect !== undefined ? filteredPrintCommandSetDeSelect["@Name"] : "",
                            isDefaultSelected: filteredPrintCommandSetDeSelect !== undefined ? filteredPrintCommandSetDeSelect["@IsDefault"] : "",
                            paperCommandSetCount: String(printCommandSetsInitialDeselect?.length),
                            isPrintCommandSetModifies: "true",
                        }
                    }
                    else {
                        console.log("Failed to De-Select paper command set data");
                    }
                }
            case 'ADD_TYPE_OF_BILL':
                {
                    let addTypeOfBills = state.typeOfBills?.TypeOfBills || [];
                    let typeOfBillValueToAdd = action.data.uiData.value;
                    if (addTypeOfBills) {
                        if (addTypeOfBills?.findIndex(x => x["@Name"].toUpperCase() == typeOfBillValueToAdd?.toUpperCase()) == -1) {
                            addTypeOfBills.push(
                                {
                                    "@ID": typeOfBillValueToAdd ?? '',
                                    "@Name": typeOfBillValueToAdd ?? ''
                                })

                        }
                    }
                    let modifiedTypeOfBills = {

                        "TypeOfBills": addTypeOfBills
                    }
                    return {
                        ...state,
                        typeOfBills: modifiedTypeOfBills,
                        selectedTypeOfBill: action.data.uiData.value
                    }

                }
            case 'ADD_PAYER':
                {
                    let functionList = state.listPopulation?.ListPopulation.FunctionList;
                    let addPayerList = state.listPopulation?.ListPopulation?.PayerList.Payer
                    let payerValueToAdd = action.data.uiData.value;
                    if (addPayerList) {
                        if (addPayerList?.findIndex(x => x["@Name"].toUpperCase() == payerValueToAdd.toUpperCase()) == -1) {
                            addPayerList.push(
                                {
                                    "@ID": payerValueToAdd,
                                    "@Name": payerValueToAdd
                                })

                        }
                    }
                    let modifiedListPopulation = {

                        "ListPopulation": {
                            "PayerList": {
                                "Payer": addPayerList
                            },
                            "FunctionList": functionList
                        }
                    }
                    return {
                        ...state,
                        listPopulation: modifiedListPopulation,
                        selectedPayer: action.data.uiData.value
                    }

                }
            case 'GET_TYPE_OF_BILLS':
                {
                    var typeOfBills = [];
                    typeOfBills.push({
                        "@ID": "I",
                        "@Name": "inpatient"
                    })
                    typeOfBills.push({
                        "@ID": "O",
                        "@Name": "outpatient"
                    })
                    let typeOfBillList = {

                        "TypeOfBills": typeOfBills
                    }
                    return {
                        ...state,
                        typeOfBills: typeOfBillList
                    }
                }
            case 'SELECTED_TYPE_OF_BILL':
                {
                    return {
                        ...state,
                        selectedTypeOfBill: action.data.uiData.value
                    }
                }
            case 'SELECTED_PAYER':
                {
                    return {
                        ...state,
                        selectedPayer: action.data.uiData.value
                    }
                }
            case 'SELECTED_CONDITION_RADIO':
                {
                    return {
                        ...state,
                        selectedConditionRadio: action.data.uiData.value
                    }
                }
            case 'SELECTED_WHEN_CONDITION':
                {
                    return {
                        ...state,
                        selectedWhenCondition: action.data.uiData.value
                    }
                }

            case 'ERROR_COMMAND_FIELD':
                if (action.data.uiData.id && action.data.uiData.id.length > 0) {
                    switch (action.data.uiData.id) {
                        case 'CommandField':
                            return {
                                ...state,
                                commandFieldError: action.data.uiData.value,
                            }
                        case 'CommandValue':
                            return {
                                ...state,
                                commandValueError: action.data.uiData.value,
                            }
                    }
                }
                break;
            case 'RESET_DELETE_SUCCESS_MESSAGE':
                {
                    return {
                        ...state,
                        isDeleteSuccess: ""
                    }
                }
            default:
                return state;
        }
    }
    return state || defaultState;
}