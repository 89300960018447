import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '../../commonResources/typography';
import { Colors } from '../../commonResources/colorVariables';
//import { BoxShadows } from '../../commonResources/boxShadows';
import { Borders } from '../../commonResources/borders';
import { Button, LoadingIndicator } from '@optum-uicl/ui-core/dist';
import { BasePalette } from '@optum-uicl/ui-core/dist'
import { IImageButtonImage, ImageButton } from '../common/ImageButton';
import { ModalLoading } from '@common/ModalLoading'
import * as wind from '../../commonResources/window';

import ARMHelpIconOff from '../../content/images/Buttons/But_HelpOff.png';
import ARMHelpIconUp from '../../content/images/Buttons/But_HelpUp.png';
import ARMHelpIconOver from '../../content/images/Buttons/But_HelpOver.png';
import ARMHelpIconDown from '../../content/images/Buttons/But_HelpDown.png';
import { ButtonVariantType } from '@optum-uicl/ui-core/dist/Atoms/Button/types';


interface IDialogContainerProps {
    domId?: string;
    widthValue?: string;
    marginTop?: string;
}

export const CenteredContent = styled.div`
    display: block;
    text-align: center;
`;

//${BoxShadows.lightFade};

export const DialogContainer = styled.div.attrs((props: IDialogContainerProps) => {
    var idObj = (props.domId) ?
        {
            id: props.domId
        } : {};
    return { ...idObj };
})`
    position: relative;
    display: inline-flex;
    flex-flow: column wrap;
    align-items: stretch;
    text-align: left;
    margin-top: ${props => !!props.marginTop ? props.marginTop : "40px"};
    ${(props: IDialogContainerProps) => props.widthValue && `width: ${props.widthValue}`};
`;

export const DialogCaption = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #FFF;
`;

export const DialogCaptionText = styled.div`
    ${Typography.ARMFontFamily};
    ${Typography.bold};
    ${Typography.headingSmall};
    ${Typography.defaultLineHeight};
    color: ${Colors.optumBlue110};
   
    padding: 10px;
    padding-bottom: 5px;
    padding-top: 2px;
    text-align: left;
    letter-spacing: 1px;
    display: flex;
`;

export const DialogCaptionHelp = styled.div`
    ${Typography.ARMFontFamily};
    ${Typography.bold};
    ${Typography.large};
    ${Typography.defaultLineHeight};
    color: ${Colors.white};
    padding: 0px;
    text-align: right;

    .help-button {
        padding: 0px;
        padding-top: 6px;
        padding-right: 6px;
    }
`;

interface IDialogBodyProps {
    paddingTop?: string | undefined
    justifyContent?: string | undefined
    sharpBorder?: boolean | undefined // Have to opt-in to sharp borders as Optum default is rounded.
    borderColor?: string | undefined // Have to opt-in to override color default.
}

export const DialogBody = styled.div<IDialogBodyProps>`
    ${Typography.ARMFontFamily};
    padding: 5px;
    background-color: ${Colors.white};
    display: flex;
    justify-content:  ${(p) => p.justifyContent ? `${p.justifyContent}` : 'center'};;
    ${(p) => p.paddingTop ? `padding-top: ${p.paddingTop}` : 'padding-top: 5px'};
    border: 2px solid ${(p) => p.borderColor ? `${p.borderColor}`: `${Colors.optumNeutral30}`};
    border-radius: ${(p) => p.sharpBorder ? `0px` : `10px`};
`;


export const DialogFooter = styled.div`
    ${Typography.ARMFontFamily};
    background-color: #FFF;
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    justify-content: center;
    padding: 5px;
    height: 100%;
    width: auto;

    button {
        margin: 4px;
        width: 110px;
        text-align: center;
        text-transform: uppercase;

    }

    
`;

export const ContentRowWrapper = styled.div`
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
    flex-direction: row;
    label[id*='claimfilter-']{
        font-size: 14px;
        color: rgb(75, 77, 79);
    }
    span[id*='claimfilter_']{
        width:100px;
        flex: 1 1 auto;
    }
    div[class*='ControlWrapper-']{
        width: 47%;
    }
    select option:checked, select option:active{
       background-color: #E9F1FF !important;
       color: #002677 !important; 
       border-top: 1px solid #0C55B8;
       border-bottom: 1px solid #0C55B8;
       border-left:none !important;
       border-right:none !important;
       font-weight:initial !important;
    }
    select option:hover{
        background-color: #CDDDFE !important;
        color: #002677 !important;
        cursor: pointer;
    }
    option:checked, option:active{
       background-color: #E9F1FF !important;
       color: #002677 !important; 
       border-top: 1px solid #0C55B8;
       border-bottom: 1px solid #0C55B8;
       border-left:none !important;
       border-right:none !important;
       font-weight:initial !important;
    }
`;

export const ContentColumnWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
`;

interface FlexBoxModifierProps {
    flexGap: string;
    alignItems: string;
    justifyContent: string;
    flex: string;
};

//intended to be used with a flex-box styled component in the "as" attribute
export const FlexBoxModifier = styled.div<FlexBoxModifierProps>`
    gap: ${(p) => p.flexGap};
    align-items: ${(p) => p.alignItems};
    justify-content: ${(p) => p.justifyContent};
    flex?: ${p => p.flex}
`;

interface IDialogInstructionProps {
    instructionWidth?: string;
};

export const DialogInstruction = styled.div<IDialogInstructionProps>`
    display: inline-block;
    ${Typography.ARMFontFamily};
    ${Typography.mediumLarge};
    color: ${Colors.black};
    background-color: ${Colors.optumNeutral10};
    ${Borders.grey50}
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 2px;
    text-align: center;
    width:  ${(p) => p.instructionWidth ? `${p.instructionWidth}` : '60%'};

    .instructionRed{
        color: ${Colors.darkRed};
        ${Typography.ARMFontFamily};
        ${Typography.bold};
    }
`;

export const HighlightedColumnWrapper = styled(ContentColumnWrapper)`
    background-color:none;
    border: 1px solid ${Colors.defaultDark};
    row-gap: 10px;
    padding: 10px;
`;

//TODO: why doesn't ${Typography.mediumLarge} work?
export const SmallerTextColumn = styled(HighlightedColumnWrapper)`${Typography.mediumLarge};`;

export const ContentRowFloatLeft = styled(ContentRowWrapper)`
    justify-content: flex-start;
    column-gap: 20px;
`;

export const ContentRowFloatRight = styled(ContentRowWrapper)`
    justify-content: flex-end;
    column-gap: 20px;
`;

export const ContentRowJustify = styled(ContentRowWrapper)`
    justify-content:space-between;
    column-gap: 20px;
`;

export const ActionButtonsDiv = styled.div`
    #loadButton:disabled {
        background-color: transparent;
        border-color: rgb(63, 96, 127);
        color: rgb(63, 96, 127);
    }
`;


interface IDialogWrapperPropsOnly {
    title: string;
    instruction?: string | JSX.Element;
    buttons?: JSX.Element;
    helpUrl?: string;
    helpCallback?(): void;
    width?: string;
    isBusy?: boolean;
    domId?: string;
    dropdown: JSX.Element | undefined;
    instructionWidth: string | undefined;
    paddingTop: string | undefined;
    marginTop?: string | undefined;
    justifyContent?: string | undefined;
    bodySharpBorder?: boolean | undefined;
    bodyBorderColor?: string | undefined;
};

type IDialogWrapperProps = React.PropsWithChildren<IDialogWrapperPropsOnly>;

interface IDialogWrapperState {
};

export const DEFAULT_STATE: IDialogWrapperState = {
};

export interface IDialogButtonProps {
    domID: string;
    name: string;
    onClick: React.ChangeEventHandler<HTMLButtonElement>;
    disable: boolean;
    btnType?: ButtonVariantType | undefined;
};

export const DialogButton: React.FunctionComponent<IDialogButtonProps> = ({ domID, name, disable, onClick, btnType/* will this work?, ...restProps*/ }) => {
    return (
        <ActionButtonsDiv>
            <Button
                domID={domID}
                name={name}
                disabled={disable}
                buttonType={(btnType && btnType.length > 0 ? btnType : "emphasized")}
                size="control"
                type="button"
                onClick={(e: React.SyntheticEvent) => onClick(e as React.ChangeEvent<HTMLButtonElement>)} /></ActionButtonsDiv>
    );
};

export const DialogButtonOK: React.FunctionComponent<Partial<IDialogButtonProps>> = ({ domID = "okButton", name = "OK", disable = false, onClick }) => {
    return (
        <DialogButton
            domID={domID}
            name={name}
            disable={disable}
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (onClick) onClick(e) }} />
    );
};

export const DialogButtonCancel: React.FunctionComponent<Partial<IDialogButtonProps>> = ({ domID = "cancelButton", name = "Cancel", disable = false, onClick }) => {
    return (
        <DialogButton
            domID={domID}
            name={name}
            disable={disable}
            btnType="standard"
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (onClick) onClick(e) }} />
    );
};

export const DialogButtonDELETE: React.FunctionComponent<Partial<IDialogButtonProps>> = ({ domID = "deleteButton", name = "DELETE", disable = false, onClick }) => {
    return (
        <DialogButton
            domID={domID}
            name={name}
            disable={disable}
            btnType="emphasized"
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (onClick) onClick(e) }} />
    );
};

export const DialogButtonLoad: React.FunctionComponent<Partial<IDialogButtonProps>> = ({ domID = "loadButton", name = "Load", disable = false, onClick }) => {
    return (
        <DialogButton
            domID={domID}
            name={name}
            disable={disable}
            btnType="standard"
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (onClick) onClick(e) }} />
    );
};

export const DialogButtonSave: React.FunctionComponent<Partial<IDialogButtonProps>> = ({ domID = "saveButton", name = "Save", disable = false, onClick }) => {
    return (
        <DialogButton
            domID={domID}
            name={name}
            disable={disable}
            btnType="standard"
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (onClick) onClick(e) }} />
    );
};

export const DialogButtonRefine: React.FunctionComponent<Partial<IDialogButtonProps>> = ({ domID = "refineButton", name = "Refine", disable = false, onClick }) => {
    return (
        <DialogButton
            domID={domID}
            name={name}
            disable={disable}
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (onClick) onClick(e) }} />
    );
};

export const DialogButtonSubmit: React.FunctionComponent<Partial<IDialogButtonProps>> = ({ domID = "submitButton", name = "Continue", disable = false, onClick }) => {
    return (
        <DialogButton
            domID={domID}
            name={name}
            disable={disable}
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (onClick) onClick(e) }} />
    );
};

export const DialogButtonOKSubmit: React.FunctionComponent<Partial<IDialogButtonProps>> = ({ domID = "okSubmitButton", name = "Submit", disable = false, onClick }) => {
    return (
        <DialogButton
            domID={domID}
            name={name}
            disable={disable}
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (onClick) onClick(e) }} />
    );
};


export interface IDefaultButtonProps {
    onClickOK?: React.ChangeEventHandler<HTMLButtonElement>;
    onClickCancel: React.ChangeEventHandler<HTMLButtonElement>;
    onClickLoad?: React.ChangeEventHandler<HTMLButtonElement>;
    onClickSave?: React.ChangeEventHandler<HTMLButtonElement>;
    onClickSubmit?: React.ChangeEventHandler<HTMLButtonElement>;
    disableOK?: boolean;
    disableCancel?: boolean;
    disableLoad?: boolean;
    disableSave?: boolean;
    disableSubmit?: boolean;
    nameOverride?: string;
};

export const OKCancelButtons: React.FunctionComponent<IDefaultButtonProps> = (props) => {
    return (
        <React.Fragment>
            <DialogButtonOK onClick={props.onClickOK} name={props.nameOverride || "Ok"} disable={props.disableOK || false} />
            <DialogButtonCancel onClick={props.onClickCancel} disable={props.disableCancel || false} />
        </React.Fragment>
    );
}

export const SubmitCancelButtons: React.FunctionComponent<IDefaultButtonProps> = (props) => {
    return (
        <React.Fragment>
            <DialogButtonOKSubmit onClick={props.onClickSubmit} disable={props.disableSubmit || false} />
            <DialogButtonCancel onClick={props.onClickCancel} disable={props.disableCancel || false} />
        </React.Fragment>
    );
}

export const DeleteCancelButtons: React.FunctionComponent<IDefaultButtonProps> = (props) => {
    return (
        <React.Fragment>
            <DialogButtonDELETE onClick={props.onClickOK} disable={props.disableOK || false} />
            <DialogButtonCancel onClick={props.onClickCancel} disable={props.disableCancel || false} />
        </React.Fragment>
    );
}

export const FilterFourButtons: React.FunctionComponent<IDefaultButtonProps> = (props) => {
    return (
        <React.Fragment>
            <DialogButtonLoad onClick={props.onClickLoad ? props.onClickLoad : () => { }} disable={props.disableLoad || false} />
            <DialogButtonSave onClick={props.onClickSave ? props.onClickSave : () => { }} disable={props.disableSave || false} />
            <DialogButtonRefine onClick={props.onClickOK} disable={props.disableOK || false} />
            <DialogButtonCancel onClick={props.onClickCancel} disable={props.disableCancel || false} />
        </React.Fragment>
    );
}

export const FilterFiveButtons: React.FunctionComponent<IDefaultButtonProps> = (props) => {
    return (
        <React.Fragment>
            <DialogButtonLoad onClick={props.onClickLoad ? props.onClickLoad : () => { }} disable={props.disableLoad || false} />
            <DialogButtonSave onClick={props.onClickSave ? props.onClickSave : () => { }} disable={props.disableSave || false} />
            <DialogButtonRefine onClick={props.onClickOK ? props.onClickOK : () => { }} disable={props.disableOK || false} />
            <DialogButtonSubmit onClick={props.onClickSubmit} name={props.nameOverride ? props.nameOverride : undefined} disable={props.disableSubmit || false} />
            <DialogButtonCancel onClick={props.onClickCancel} disable={props.disableCancel || false} />
        </React.Fragment>
    );
}

export class DialogWrapper extends React.Component<IDialogWrapperProps, IDialogWrapperState> {
    static defaultProps: IDialogWrapperProps = {
        title: '',
        dropdown: undefined,
        instructionWidth: undefined,
        paddingTop: undefined,
        marginTop: undefined,
    };

    static helpIcon: IImageButtonImage = {
        Off: ARMHelpIconOff,
        Up: ARMHelpIconUp,
        Over: ARMHelpIconOver,
        Down: ARMHelpIconDown,
    }

    constructor(props: IDialogWrapperProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public getInstruction() {
        return this.props.instruction ? <DialogInstruction instructionWidth={this.props.instructionWidth}>{this.props.instruction}</DialogInstruction> : null;
    }

    public getHelpIcon() {
        if (this.props.helpUrl || this.props.helpCallback) {
            return (
                <DialogCaptionHelp>
                    <ImageButton
                        Title={this.props.title + 'HelpIcon'}
                        legend={false}
                        image={DialogWrapper.helpIcon}
                        className="help-button"
                        helpIcon={true}
                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickHelpIcon(e)} />
                </DialogCaptionHelp>
            );
        }
        return null;
    }

    public onClickHelpIcon(e: React.ChangeEvent<HTMLButtonElement>) {
        e.preventDefault();
        if (this.props.helpUrl) wind.BigHelp(this.props.helpUrl);
        if (this.props.helpCallback) this.props.helpCallback();
    }

    public render() {
        const {
            dropdown
        } = this.props;

        var instruction = this.getInstruction();
        var helpIcon = this.getHelpIcon();
        let domIdName = this.props.domId ? this.props.domId : 'dialog-container';
        var domId =
        {
            domId: domIdName
        }
        return (
            <CenteredContent>
                <DialogContainer {...domId} widthValue={this.props.width} marginTop={this.props.marginTop} >
                    <DialogCaption>
                        <DialogCaptionText>
                            <div style={{ paddingRight: '5px' }}>{this.props.title}</div>
                            {dropdown}
                        </DialogCaptionText>
                        {helpIcon}
                    </DialogCaption>
                    <DialogBody paddingTop={this.props.paddingTop} justifyContent={this.props.justifyContent}
                                sharpBorder={this.props.bodySharpBorder} borderColor={this.props.bodyBorderColor}>
                        {this.props.children}
                    </DialogBody>
                    <DialogFooter>
                        {this.props.buttons}
                    </DialogFooter>
                    <ModalLoading isOpen={this.props.isBusy} />
                </DialogContainer>
                <ContentColumnWrapper />
                {instruction}

            </CenteredContent>
        );
    }
};
