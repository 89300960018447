import styled, { css } from 'styled-components';
import { Colors } from '../../../commonResources/colorVariables';

const flexInnerContainer = css`
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-flow: row wrap;
`;

/* Override the default bootstrap behavior where horizontal description lists 
will truncate terms that are too long to fit in the left column 
*/
const bootstrapDl = css`
    .dl-horizontal dt {
        white-space: normal;
`;

/* Set width on the form input elements since they're 100% wide by default */
const formElements = css`
    input,
    select,
    textarea {
        max-width: 280px;
    }
`;

export const LandingPageContainer = styled.div.attrs(() => {
    return { id: "LandingPageContainer" };
})`
    ${flexInnerContainer}
    align-content: flex-start;
    width: 1050px;
    font-family:"Core Sans C", "Century Gothic", "AppleGothic", sans-serif;

    input { 
        text-transform: uppercase;
    }

    ${bootstrapDl}
    ${formElements}

    @media only screen and (max-width: 767px) {
        .nofloat {
            float: none;
            padding: 10px 15px;
        }
    }
}
`;

export const LandingPageContainerLegacy = styled.div.attrs(() => {
    return { id: "LandingPageContainer" };
})`
    ${flexInnerContainer}
    xxxalign-content: flex-start;
    align-content: center;
    width: 1140px;
    height: 770px;
    padding: 5px;
    margin-top: 30px;
    font-family:"Core Sans C", "Century Gothic", "AppleGothic", sans-serif;

    input { 
        text-transform: uppercase;
    }

    ${bootstrapDl}
    ${formElements}

    @media only screen and (max-width: 767px) {
        .nofloat {30px
            float: none;
            padding: 10px 15px;
        }
    }
}
`;

export const TestModeLandingPageContainer = styled.div.attrs(() => {
    return { id: "LandingPageContainer" };
})`
    ${flexInnerContainer}
    xxxalign-content: flex-start;
    align-content: center;
    width: 1140px;
    height: 505px;
    padding: 5px;
    margin-top: 30px;
    font-family:"optum sans";

    input { 
        text-transform: uppercase;
    }

    ${bootstrapDl}
    ${formElements}

    @media only screen and (max-width: 767px) {
        .nofloat {
            float: none;
            padding: 10px 15px;
        }
    }
}
`;

export const LandingPageTop = styled.div.attrs(() => {
    return { id: "LandingPageTop" };
})`
    ${flexInnerContainer}
    margin-top: 30px;
    padding: 0px !important;
`;

export const LandingPageBody = styled.div.attrs(() => {
    return { id: "LandingPageBody" };
})`
    ${flexInnerContainer}
    border: none;
    align-items: flex-start;
    flex-flow: nowrap;
    gap: 5px;    
`;

export const LandingPageComponentsLegacy = styled.div.attrs(() => {
    return { id: "LandingPageComponents" };
})`
    ${flexInnerContainer}
    width:1050px;
    height: 100%;
    align-items: center;

    #ARMQuickLinksPanel{    
        max-width: 390px !important;
        width: 390px !important;
        background-color: #fff;
        color: #002677;
        border-radius: 8px;
        box-shadow: 0 1px 2px rgba(17,80,244,.1),0 2px 12px rgba(131,144,175,.3);
        align-items: unset;
    }

    #tabsNotifications{
        margin: 5px;
        max-width: 620px !important;
        width: 620px !important;
    }
    .MessageAlerts,
    .ARMMyClaims {
        width: 650px;
        height: 280px;
    }

    .ARMMyClaims{
        height: 430px !important;
    }
`;

export const TestModeLandingPageComponentsLegacy = styled.div.attrs(() => {
    return { id: "TestModeLandingPageComponents" };
})`
    ${flexInnerContainer}
    width:1050px;
    
    align-items: stretch;

    #ARMQuickLinksPanel{    
        max-width: 429px !important;
        width: 429px !important;
    }
`;

export const LandingPageComponents = styled.div.attrs(() => {
    return { id: "LandingPageComponents" };
})`
    ${flexInnerContainer}
    width: 860px;
    justify-content: space-between;
    align-items: center;
    align-content: flex-start;

    gap: 10px;    

    .RAPMyClaimsBiller {
        border: 1px solid #c3c5cd;
        width: auto;
        height: auto;
    }
    .RAPMyClaimsSupervisor {
        border-style: solid;
        border-width: 1px 0px 1px 1px;
        border-color: ${Colors.grey30};

        width: auto;
        height: auto;
    }
`;

export const LandingPageBottom = styled.div.attrs(() => {
    return { id: "LandingPageBottom" };
})`
    ${flexInnerContainer}
    display: none;
    height: 51px;
    width: 100%;
    border: 1px solid #0f0f59;
    border-top: 1px solid #c3c5cd;
    align-items: flex-start;
    background-color: #efefef;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
`;
/*
return (<div id="LandingPage" className="flex-innercontainer">
    <div key="LandingPageTop" id="LandingPageTop" className="flex-innercontainer">{landingPageTop}</div>
    <div key="LandingPageBody" id="LandingPageBody" className="flex-innercontainer landingPageBody">{landingPageBody}
        <div id="LandingPageComponents" className="flex-innercontainer landingPage-sub" style={divStyle}>{landingPageComponents}</div>
    </div>
    <div key="LandingPageBodyBottomGrayArea" id="LandingPageBodyBottomGrayArea" className="flex-innercontainer landingPageBodyBottomGrayArea" style={divStyle}></div>
</div>);
*/