import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';
import { IClaimFilterState, IClaimFilterActionProps, actionCreators} from '@store/ClaimFilter';
import { ClaimFilter, IClaimFilterComponentProps } from './ClaimFilter';

export interface IRunBridgeRoutinesFilterComponentState {};

export const DEFAULT_STATE: IRunBridgeRoutinesFilterComponentState = {};

type IOwnProps = IClaimFilterComponentProps & RouteComponentProps<{}>;

type IClaimFilterProps = IMergeApiComponentProps<IClaimFilterState, IClaimFilterActionProps, IOwnProps>; 

export class RunBridgeRoutinesFilter extends React.Component<IClaimFilterProps, IRunBridgeRoutinesFilterComponentState>
{

    static defaultProps: IClaimFilterComponentProps = {
        canEdit: false,
        canView: false,
        canCreate: false,
        ncsIsEnabled: false,
        process: "RunBridgeRoutines",
        execNode: "MultiClaim",
        execAction: "RunBridgeRoutines",
        execProcess:"RunBridgeRoutines",
        parameter: "some dummy",
        apiType: "ClaimFilter",
        title: "Run Bridge Routines",
        executeJS: undefined,
        extraInstruction: "Click Continue to run bridge routines without viewing the claim list.",
    };

    constructor(props: IClaimFilterProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.props.history.push('/LandingPage');
        }
    }

    public componentWillUnmount() {
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public onOk = (baseThis: any, param1: any) : boolean => {
        return true;
    }

    public render() {
        var parameterValue = (this.props.history.location.state as any).parameter;

        return (
            <div>
            <ClaimFilter
                    {...this.props} parameter={parameterValue} execAttribs={parameterValue}
                    executeJS ={this.onOk}
                />
            </div>

        );
    }
};

var connectedHoc = connect<IClaimFilterState, IClaimFilterActionProps, IOwnProps, IClaimFilterProps, ApplicationState>(
    createApiMapStateToProps('claimFilter'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(RunBridgeRoutinesFilter);

export default withRouter(connectedHoc);
