import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, createAction, ActionTypes } from '@scripts/util/ActionHelpers'; 
import { ValidateObject, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers'; 

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IViewResponseUIData {
    id: string;
    name: string;
};
export interface IViewResponsesUIState {
    
    selectedTabIndex: number;
    categoryFrom: string;
    categoryTo: string;
    statusFrom: string;
    statusTo: string;
    datePostedOrEffective: string;
    categoryList: any,
    selectedCategoryDescription?: string;
    selectedStatusDescription?: string;
    selectedCategoryCode?: string;
    selectedStatusCode?: string;
    payerList: any[];
    isViewResponseLoaded: boolean;
    selectedPayerList: string[];
    selectedPayerNameList: string[];
    filterRecords: any[];
    sorted: boolean; 
    clientId: string;
}


export const defaultViewResponseItem: CategoryItem = {
    "@ID": '',
    "@Name": ''
};
export interface CategoryItem {
    "@ID": string
    "@Name": string
}

export interface ResponseGridData {
    "payerName": string
    "oldest": string
    "newest": string
    "categoryCode": string
    "statusCode": string
    "categocountryCode": string
}


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data). 
export const actionCreators = {
    selectPayerList: (selectInfo: any) => createDataAction('SELECT_PAYER_LIST', selectInfo), 
    loadGridPayerList: (selectInfo: any) => createDataAction('LOAD_GRID_PAYER_LIST', selectInfo),
    loadGridSorted: (selectInfo: boolean) => createDataAction('LOAD_SORTED_GRID', selectInfo),
    clearViewResponse: () => createDataAction('CLEAR_SELECTION', ''),
    clearResponse: () => createDataAction('CLEAR_RESPONSE', ''),
    selectTab: (tabIndex: Number) => createDataAction('SELECT_RESPONSE_TAB', tabIndex),
    updateCategoryFrom: (categoryFrom: string) => createDataAction('UPDATE_CATEGORY_FROM', categoryFrom),
    updateCategoryTo: (categoryTo: string) => createDataAction('UPDATE_CATEGORY_TO', categoryTo),
    updateStatusFrom: (statusFrom: string) => createDataAction('UPDATE_STATUS_FROM', statusFrom),
    updateStatusTo: (statusTo: string) => createDataAction('UPDATE_STATUS_TO', statusTo),
    updateDatePostedOrEffective: (datePostedOrEffective: string) => createDataAction('UPDATE_DATE_POSTED_OR_EFFECTIVE', datePostedOrEffective),
    loadCategoryStatusList: (categoryStatusList: any) => createDataAction('LOAD_CATEGORY_LIST', categoryStatusList),  
    loadCategoryDescription: (categoryCode: string) => createDataAction('LOAD_CATEGORY_DESCRIPTION', categoryCode),
    loadStatusDescription: (statusCode: string) => createDataAction('LOAD_STATUS_DESCRIPTION', statusCode),
    search: () => createAction('SEARCH'),
    clear: () => createAction('CLEAR'),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IViewResponsesUIState = {
    selectedTabIndex: 0,
    categoryFrom: '',
    categoryTo: '',
    statusFrom: '',
    statusTo: '',
    datePostedOrEffective: '0',
    categoryList: undefined,
    payerList: [],
    isViewResponseLoaded: false,
    selectedPayerList: [],
    selectedPayerNameList: [],
    filterRecords: [],
    sorted: false, 
    clientId:'',
};


// ----------------
// REDUCER - For a given state and actio returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IViewResponsesUIState, KnownActions> = (state: IViewResponsesUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'CLEAR_SELECTION':
                return {
                    ...state,
                    categoryFrom: '',
                    categoryTo: '',
                    statusFrom: '',
                    statusTo: '',
                    datePostedOrEffective: '0',
                    selectedPayerList: [],
                    selectedPayerNameList: [],
                    selectedCategoryDescription: '',
                    selectedStatusDescription: '',
                    selectedCategoryCode: '',
                    selectedStatusCode:''
                }
                break;
            case 'CLEAR_RESPONSE':
                return {
                    ...state,
                    
                    selectedCategoryDescription: '',
                    selectedStatusDescription: '',
                    selectedCategoryCode: '',
                    selectedStatusCode: ''
                }
                break;
            case 'SELECT_RESPONSE_TAB':
                return {
                    ...state,
                    selectedTabIndex: action.data as number,
                }
                break;
           
            case 'LOAD_GRID_PAYER_LIST':
               return {
                    ...state,
                    filterRecords:action.data
                }
                break;
            case 'LOAD_SORTED_GRID':
               
                return {
                    ...state,
                    sorted: action.data
                }
                break;
            case 'LOAD_CATEGORY_LIST':
                 
                let listPayer = [];
                let payer = [];
                let newClientId = '';
                
                if (action.data.PayerStatusResponseInfo.PayerList) {
                     if (!Array.isArray(action.data.PayerStatusResponseInfo.PayerList.Payer)) {
                        let obj = { ...action.data.PayerStatusResponseInfo.PayerList.Payer };
                        listPayer.push(obj);
                    }
                    else {
                        listPayer = action.data.PayerStatusResponseInfo.PayerList.Payer;

                    }
                    payer = listPayer.map((payer: CategoryItem) => { return { ID: payer["@ID"], Name: payer["@Name"] } });
                }
                          
                if (action.data.PayerStatusResponseInfo.Client && action.data.PayerStatusResponseInfo.Client.CurrentClient) {
                    newClientId = action.data.PayerStatusResponseInfo.Client.CurrentClient["@ID"];
                }
                return {
                    ...state,
                    payerList: payer,
                    categoryList: action.data.PayerStatusResponseInfo,
                    clientId:newClientId
                }
                break;
                
            
            case 'SELECT_PAYER_LIST':



                return {
                    ...state,
                    selectedPayerList: action.data.uiData.selected,
                    selectedPayerNameList: action.data.uiData.selectedPayerName,
                }
                break; 
      
            case 'LOAD_CATEGORY_DESCRIPTION': {
                let { categoryList } = state;
                
                let description = categoryList.CategoryList.Category.filter((cate: CategoryItem) => cate["@ID"]=== action.data);
                
                return {
                    ...state,
                    selectedCategoryCode: action.data,
                    selectedCategoryDescription: description[0]["@Name"],
                }
            }
                break;
            case 'LOAD_STATUS_DESCRIPTION': {
                let { categoryList } = state;
                let description = categoryList.StatusList.Status.filter((cate: CategoryItem) => cate["@ID"] === action.data);

                return {
                    ...state,
                    selectedStatusCode: action.data,
                    selectedStatusDescription: description[0]["@Name"],
                }
            }
                break;
             

            case 'UPDATE_DATE_POSTED_OR_EFFECTIVE':
                return {
                    ...state,
                    datePostedOrEffective:action.data,
                }
                break;
            case 'UPDATE_CATEGORY_FROM':
                return {
                    ...state,
                    categoryFrom: action.data,
                }
                break;
            case 'UPDATE_CATEGORY_TO':
                return {
                    ...state,
                    categoryTo: action.data,
                }
                break;
            case 'UPDATE_STATUS_FROM':
                return {
                    ...state,
                    statusFrom: action.data,
                }
                break;
            case 'UPDATE_DATE_POSTED_OR_EFFECTIVE':
                return {
                    ...state,
                    datePostedOrEffective:action.data,
                }
            case 'UPDATE_STATUS_TO':
                return {
                    ...state,
                    statusTo: action.data,
                }
                break;
            case 'SEARCH':
                return state;
            case 'CLEAR':
                return state;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
