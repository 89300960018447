import * as React from 'react';
import { RouteComponentProps, withRouter, Redirect } from 'react-router-dom';
import { Input, Button, DataItem, SelectDropdown } from '@optum-uicl/ui-core/dist';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';

import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { SelectComponent } from '@common/SelectComponent';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { CheckBoxComponent } from '@common/CheckBox';
import { RadioButtonComponent } from '@common/RadioButton';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IUserMaintenanceState, IUserMaintenanceActionProps, actionCreators, validationCallback } from '@store/UserMaintenance';
import { ICookies_Config } from '@store/ConfigData';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';
import { getRawToken } from '@scripts/session/SecurityToken';
import CiamAccount from './CiamAccount'
import FilterableList from '@commonResources/FilterableList/FilterableList';
import { CheckBoxContainer } from '../PhysicianMaintenance/PhysicianStyles';
import { event } from 'jquery';
import { SelectDropdownValue } from '@optum-uicl/ui-core/dist/Atoms/SelectDropdown/types';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-grow:initial;
    flex-direction: column;
`;
export const ContentWrapper1 = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-grow:initial;
    flex-direction: row;
`;
export const ContentWrapper2 = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-grow:initial;
    flex-direction: row;
`;

export const ContentWrapper3 = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-grow:initial;
    flex-direction: column;
`;

export const SelectList = styled.div`
    select{
        margin-left:0px !important;
    }
`;

export const CloneContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin-left: -15px;
`;

export const Inputs = styled.div`
    span {
        width: 300px;
    }
`;
export const SelectActions = styled.div`
    margin: 5px 0px 5px 8px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

    label[for=ciamError] {
        color:red;
    }
    #loginName-input {
        width: 300px;
    }
    #firstName-input {
        width: 300px;
    }
    #lastName-input {
        width: 300px;
    }
    #password-input {
        width: 300px;
    }
    #confPassword-input {
        width: 300px;
    }
    #emailAddress-input {
        width: 300px;
    }
    #emailValidated-CheckBox { 
        margin-top: 7px;
        margin-bottom: 10px;
    }
    #emailValidated-CheckBox_label { 
        padding-left: 0px;
        padding-top: 7px;
        padding-bottom: 10px;
    }
    #interactiveUser-input { 
        margin-bottom: 10px;
    }
    #interactiveUser-input_label { 
        padding-left: 0px;
        padding-bottom: 10px;
    }
    #claimViewUser-input { 
        margin-bottom: 10px;
    }
    #claimViewUser-input_label { 
        padding-left: 0px;
        padding-bottom: 10px;
    }
    div[class^='Wrapper-sc-']{
        margin-bottom:5px;
    }
`;

export const SelectActions2 = styled.div`
     margin: 5px 5px 5px 0px;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px 5px 5px 0px;
    
    label[for=failedAttempts-id] {
        margin-top:2px;
    }
    label[for=ciamAccountStatus-id] {
        margin-top:2px;
    }
    label[for=lastLogonDate-id] {
        margin-top:2px;
    }
    label[for=ciamLastPwdResetDate-id] {
        margin-top:2px;
    }
    label[for=ciamLastWelcomeEmailDate-id] {
        margin-top:2px;
    }
    label[for=lastLoggedIn-id] {
        margin-top:2px;
    }
    label[for=passwordExpiringIn-id] {
        margin-top:2px;
    }
    label[for=filterProfile-id] {
        margin-top:2px;
    }
    label[for=reportProfile-id] {
        margin-top:2px;
    }
    label[for=userProfile-id] {
        margin-top:2px;
    }
    label[for=btnFilterProfileLabel] {
        padding-top:gpx;
    }
    label[for=btnReportProfileLabel] {
        padding-top:8px;
    }
    #select-userRole-id {
        width:321px;
    }
    #select-clientSet-id {
        width:321px;
    }
    #select-featurePreview-id {
        width:321px;
    }
    #enabled-account-id {
        margin-left:3px; 
        height:15px;
        width:15px;
    }
    #lockedout-account-id {
        margin-left:1px; 
        height:15px;
        width:15px;
    }
    #disabled-account-id {
        margin-left:20px; 
        height:15px;
        width:15px;
    }
    #btnFilterProfile {
	    padding:0px; 
    }
    #btnReportProfile {
	    padding:0px; 
    }
    #userProfile-id {
	    padding:0px; 
    }
    #resetPassword-id{
        width:150px;
    }
    #resendWelcomeEmail-id{
        width:170px;
        visibility: hidden;
    }
`;

export const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin-left: auto;
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

export const CiamButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    max-height: 35px;
    margin-left: auto;
    button {
        margin: 4px;
        width: 108px;
        text-align: center;
    }
`;

export const CiamErrorMessage = styled.div`
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
    flex-direction: row;
    max-height: 35px;
    margin-left: auto;
    label[for=ciamAcknowledge] {
        margin-bottom:2px;
        color:red;
    }
    .errorMsg{

    }
    #ciamAcknowledgeButton {
        width:40px;
    }
`;

export const CiamErrorMessage1 = styled.div`
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
    flex-direction: row;
    max-height: 35px;
    margin-left: auto;  
    #ciamAcknowledge{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .errorMsg{
         color:red;
         width:54%;
    }   
`;

export const CheckBoxRow = styled.div`
    width: 280px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    div{
        align-items:center;
        label{
            font-size:12px !important;
            padding-left:5px !important;
        }
    }
    `

export const DEFAULT_STATE: IComponentState = {
    isPageBusy: true,
    cancelLeave: false,
    selectedUsers: [],
    ciamLinkModalMessage: '',
    ciamUnlinkModalMessage: '',
    isCiamUnlinkModalOpen: false,
    linkableUsersList: [],
    unlinkableUsersList: [],
    isCiamLinkModalOpen: false,
    ctrlKeyPressed: false,
    searchText: '',
    activeUsersSelected: false,
    inActiveUsersSelected: false,
    ciamUsersSelected: false,
    nonCiamUsersSelected: false,
    interactiveUsersSelected: false,
    nonInteractiveUsersSelected: false
};

enum eModifyButtonType {
    Add,
    Update
}

interface IDropDownItem {
    label: string,
    value: string,
}

interface IComponentProps {
    canCreate: boolean; // SecurityBits.FN_CREATE_USERS
    canView: boolean;   // SecurityBits.FN_VIEW_USERS
    canEdit: boolean;   // SecurityBits.FN_EDIT_USERS
    canDelete: boolean; // SecurityBits.FN_DELETE_USERS
    canRestrictAccess: boolean; // SecurityBits.FN_VIEW_RESTRICT_USER_ACCESS
    canViewClientSet: boolean;  // SecurityBits.NDC_VIEW_CLIENTSET
    canEditClientSet: boolean;  // SecurityBits.NDC_EDIT_CLIENTSET
    canBetaPreview: boolean; // SecurityBits.NDC_USER_BETA_PREVIEW
};

interface IComponentState {
    isPageBusy: boolean,
    cancelLeave: boolean,
    selectedUsers:any,
    ciamLinkModalMessage: any,
    ciamUnlinkModalMessage: any,
    linkableUsersList: any,
    unlinkableUsersList: any,
    isCiamLinkModalOpen: boolean,
    isCiamUnlinkModalOpen: boolean,
    ctrlKeyPressed: boolean,
    searchText: string,
    activeUsersSelected: boolean,
    inActiveUsersSelected: boolean,
    ciamUsersSelected: boolean,
    nonCiamUsersSelected: boolean,
    interactiveUsersSelected: boolean,
    nonInteractiveUsersSelected: boolean
}

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IUserMaintenanceProps = IMergeCrudComponentProps<IUserMaintenanceState, IUserMaintenanceActionProps, IOwnProps>;

class UserMaintenance extends React.Component<IUserMaintenanceProps, IComponentState> {
    
    static defaultProps: IComponentProps = {
        canCreate: false, // SecurityBits.FN_CREATE_USERS
        canView: false,   // SecurityBits.FN_VIEW_USERS
        canEdit: false,   // SecurityBits.FN_EDIT_USERS
        canDelete: false, // SecurityBits.FN_DELETE_USERS
        canRestrictAccess: false, // SecurityBits.FN_VIEW_RESTRICT_USER_ACCESS
        canViewClientSet: false,  // SecurityBits.NDC_VIEW_CLIENTSET
        canEditClientSet: false,  // SecurityBits.NDC_EDIT_CLIENTSET
        canBetaPreview: false,  // SecurityBits.NDC_USER_BETA_PREVIEW
    };
    
    constructor(props: IUserMaintenanceProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.props.dataStore.crud.redirectURL = undefined;
    }

    public componentDidMount() {
        pageLeave();
        this.props.action.crud.get({ crudId: CrudTypes.mctiUsers },
            async (crud: any) => {
                await validationCallback(crud);
                this.setState(
                    {
                        isPageBusy: false
                    });
            });
        this.props.action.configData.getConfigData({ cookies: [], config: [{ name: "EnableUserBetaPreview" }, { name: "isClientUser" }] });
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public isClientUser() {
        let userType = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "isClientUser");
        return (userType && userType.value === 'True');
    }

    public onEmailConfirmed(isChecked: boolean) {
        this.props.action.ui.updateEmailConfirmed(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {index:0, value: isChecked, text: this.props.dataStore.ui.eMail}
            }
        );
    }

    public onInteractiveUser(isChecked: boolean) {
        this.props.action.ui.updateInteractiveUser({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: isChecked,
            }
        });
        if (!isChecked) {
            this.onClaimViewer(isChecked);
            this.isClaimViewerEnabled();
        }
    }

    public onClaimViewer(isChecked: boolean) {
        this.props.action.ui.updateClaimViewerUser({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: isChecked,
            }
        });
    }

    public onClearCloneUser(isChecked: boolean) {
        this.props.action.ui.updateCloneUser({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: isChecked,
            }
        });
    }
    public onChangeAccountStatus(accountStatus: string) {
        this.props.action.ui.updateAccountStatus({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: accountStatus }
        });
    }

    public onSelectUser(e: React.ChangeEvent<HTMLSelectElement>, users: any[]) {
        if (e.target.selectedIndex > 0 && e.target.selectedOptions.length == 1) {
            let userData = this.props.dataStore.crud.data?.UserMaintenanceInfo.Users.User.find(xuser => xuser["@LoginName"] === e.target.options[e.target.options.selectedIndex].textContent);
            let userId = userData?.['@ID'];
            let username = userData?.['@LoginName'];
            if (userData) {
                this.props.action.ui.getUserCiamJobStatus({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        index: e.target.options.selectedIndex,
                        value: e.target.value,
                        text: e.target.options[e.target.options.selectedIndex].textContent
                    }
                });

                this.props.action.ui.getAssuranceUserDetails({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        index: e.target.options.selectedIndex,
                        value: e.target.value,
                        text: e.target.options[e.target.options.selectedIndex].textContent
                    }
                });
                this.setState({
                    selectedUsers: e.target.selectedOptions
                });
            }
        }
        else if (e.target.selectedOptions.length > 1) {
            this.props.action.ui.updateLinkEnabled(true);
            this.props.action.ui.updateUnlinkEnabled(true);
            this.setState({
                selectedUsers: e.target.selectedOptions
            })
            this.props.action.ui.getMultiAssuranceUserDetails({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: 0,
                    value: '',
                    text: ''
                }
            });
        }
        else {
            this.props.action.ui.updateLinkEnabled(false);
            this.props.action.ui.updateUnlinkEnabled(false);
            this.setState({
                selectedUsers: e.target.selectedOptions
            })
            this.props.action.ui.getAssuranceUserDetails({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: 0,
                    value: '',
                    text: ''
                }
            });
        }
    }

    public onHandleSearchInputChange(e: React.ChangeEvent<HTMLSelectElement>) {
        let searchText = e.target.value.toUpperCase();
        console.log(searchText)
        this.setState({ searchText: searchText });
    }

    removeUnmodifiedUsersFromCrud() {
        let crud = this.props.dataStore.crud;
        let modifiedUserList = this.props.dataStore.ui.modifiedUserList;
        let modifiedCrudUserList: MCUser[] = [];
        if (crud && crud.data) {
            if (crud.data.UserMaintenanceInfo.Users.User && crud.data.UserMaintenanceInfo.Users.User.length > 0) {
                if (modifiedUserList && modifiedUserList.length > 0) {
                    for (var j = modifiedUserList.length - 1; j >= 0; j--) {
                        let modifiedCrudUser: any = crud.data.UserMaintenanceInfo.Users.User.find(ele => ele['@ID'] === modifiedUserList[j]);
                        if (modifiedCrudUser)
                            modifiedCrudUserList.push(modifiedCrudUser);
                    }
                }
            }

            crud.data.UserMaintenanceInfo.Users.User = modifiedCrudUserList;
        }
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.dataStore.crud.redirectURL = "/LandingPage";
        let updateCrud = this.props.action.crud.update;
        this.removeUnmodifiedUsersFromCrud();
        let crud = this.props.dataStore.crud;
        let xhr = new XMLHttpRequest();
        let removableList = this.props.dataStore.ui.newlyLinkedToCiamList;
        xhr.open("POST", "/api/data/ciam/userMaintenanceJson", false);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Authorization", getRawToken());

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status !== 200) {
                    alert("Unable to update user. Please try again. If the problem persists, please contact Support.");
                    return false;
                } else {
                    console.log(xhr.responseText);
                    if (removableList && removableList.length > 0) {
                        for (var i = 0; i < removableList.length; i++) {
                            let currentId = removableList[i];
                            if (crud && crud.data) {
                                let selectedUser: any = crud.data.UserMaintenanceInfo.Users.User.find(ele => ele['@ID'] === currentId);
                                if (selectedUser) {
                                    let selectedIndex = crud.data.UserMaintenanceInfo.Users.User.indexOf(selectedUser);
                                    crud.data.UserMaintenanceInfo.Users.User.splice(selectedIndex);
                                }
                                
                            }
                        }
                    }
                    //now remove the new users with validated emails since API has created them.
                    if (crud && crud.data) {
                        for (var j = crud.data.UserMaintenanceInfo.Users.User.length - 1 ; j  >= 0; j--) {
                            if ((crud.data.UserMaintenanceInfo.Users.User[j]["@ID"].startsWith("#") &&
                                crud.data.UserMaintenanceInfo.Users.User[j]["@EmailConfirmed"] == "1"))
                            {
                                    crud.data.UserMaintenanceInfo.Users.User.splice(j, 1);
                            }
                        }
                    }
                    //now remove the password from items not resetting pw
                    if (crud && crud.data) {
                        for (var j = crud.data.UserMaintenanceInfo.Users.User.length - 1; j >= 0; j--) {
                            if ((!crud.data.UserMaintenanceInfo.Users.User[j]["@ID"].startsWith("#") &&
                                crud.data.UserMaintenanceInfo.Users.User[j]["@Password"] == "")) {
                                delete (crud.data.UserMaintenanceInfo.Users.User[j]["@Password"]);
                            }
                        }
                    }


                    updateCrud(crud);
                }
            }
        }
        let payload = JSON.stringify(
            {
                crud: {
                    data: crud.data
                }
            });        
        xhr.send(payload);
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    public getModifyButtonType(): eModifyButtonType {
        return (this.props.dataStore.ui.selectedUser.index == 0) ? eModifyButtonType.Add : eModifyButtonType.Update;
    }

    public getModifyButtonText(): string {
        return this.getModifyButtonType() == eModifyButtonType.Add ? 'Add' : 'Update';
    }

    public isClonedUser(): boolean {
        return this.props.dataStore.ui.cloneUserId !== undefined && this.props.dataStore.ui.cloneUserId !== "";
    }

    public getClonedUserName(): string {
        return this.props.dataStore.ui.cloneUserName ?? "";
    }

    public isCloneButtonHidden(): boolean {
        if (this.props.dataStore.ui.selectedUser.index == 0 || this.state.selectedUsers.length > 1 || this.props.dataStore.ui.selectedUser.value.startsWith('#')) return true;
        return this.isClonedUser();
    }

    public isRemoveButtonDisabled(): boolean {
        return (this.props.dataStore.ui.selectedUser.index == 0 || !this.props.canDelete || this.state.selectedUsers.length > 1);
    }

    public onClickConfirmUndeleteUser(user: MCUser) {
        this.props.action.ui.restoreUser({
            masterCrud: this.props.dataStore.crud.data,
            uiData: user
        });
    }

    public onClickModifyButton(e: React.SyntheticEvent) {
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.UserMaintenanceInfo ||
            !this.props.dataStore.crud.data.UserMaintenanceInfo.Users ||
            !this.props.dataStore.crud.data.UserMaintenanceInfo.Users.User) return;

        if (this.props.dataStore.crud.data) {
            let duplicate = this.props.dataStore.crud.data.UserMaintenanceInfo.Users.User.find(xusr => xusr["@LoginName"] === this.props.dataStore.ui.loginName);

            if (duplicate && duplicate["@Delete"] === 'true') {
                let user: MCUser = duplicate;
                this.props.action.confirm.openConfirm("A deleted user by that login name already exists. Click confirm to undelete it.", () => this.onClickConfirmUndeleteUser(user));
            }
            else {
                switch (this.getModifyButtonType()) {
                    case eModifyButtonType.Add:
                        {
                            let userData: any = {
                                "@ID": "#" + (this.props.dataStore.crud.data.UserMaintenanceInfo.Users.User.length + 1),
                                "@LoginName": this.props.dataStore.ui.loginName,
                                "@FirstName": this.props.dataStore.ui.firstName,
                                "@LastName": this.props.dataStore.ui.lastName,
                                "@Password": this.props.dataStore.ui.password,
                                "@ConfirmPassword": this.props.dataStore.ui.confirmPassword,
                                "@EMail": this.props.dataStore.ui.eMail,
                                "@UserRoleId": this.props.dataStore.ui.userRoleId,
                                "@ClaimViewer": this.props.dataStore.ui.claimViewer,
                                "@Interactive": this.props.dataStore.ui.interactive,
                                "@ClientSetId": this.props.dataStore.ui.clientSet,
                                "@ItemName": this.props.dataStore.ui.itemName,
                                "@ItemValue": this.props.dataStore.ui.itemValue,
                                "@FeaturePreview": this.props.dataStore.ui.featurePreview,
                                "@Status": this.props.dataStore.ui.status,
                                "@FailedAttempts": this.props.dataStore.ui.failedAttempts,
                                "@PWExpiringIn": this.props.dataStore.ui.passwordExpiresIn,
                                "@LastLogonDate": this.props.dataStore.ui.lastLogonDate,
                                "@FilterProfile": this.props.dataStore.ui.filterProfile,
                                "@ReportProfile": this.props.dataStore.ui.reportProfile,
                                "@UserProfile": this.props.dataStore.ui.userProfile,
                                "@Delete": "false",
                                "@LinkedToCiam": "0",
                                "@EmailConfirmed": this.props.dataStore.ui.emailConfirmed,
                                "@CloneUserId": this.props.dataStore.ui.cloneUserId,
                                "@CloneUserName":  this.props.dataStore.ui.cloneUserName
                            };
                            this.props.action.ui.addUser({
                                masterCrud: this.props.dataStore.crud.data,
                                uiData: userData
                            });
                            this.setState({
                                selectedUsers: [{
                                    index: this.props.dataStore.crud.data.UserMaintenanceInfo.Users.User.length,
                                    value: userData["@ID"],
                                    text: userData["@LoginName"]}]})
                        }
                        break;
                    default:
                        {
                            this.props.action.ui.updateUser({ masterCrud: this.props.dataStore.crud.data, uiData: true });
                            this.props.action.ui.updateUser({
                                masterCrud: this.props.dataStore.crud.data,
                                uiData: {
                                    "@ID": this.props.dataStore.ui.selectedUser.value,
                                    "@LoginName": this.props.dataStore.ui.loginName,
                                    "@FirstName": this.props.dataStore.ui.firstName,
                                    "@LastName": this.props.dataStore.ui.lastName,
                                    "@Password": this.props.dataStore.ui.password,
                                    "@ConfirmPassword": this.props.dataStore.ui.confirmPassword,
                                    "@EMail": this.props.dataStore.ui.eMail,
                                    "@UserRoleId": this.props.dataStore.ui.userRoleId,
                                    "@ClaimViewer": this.props.dataStore.ui.claimViewer,
                                    "@Interactive": this.props.dataStore.ui.interactive,
                                    "@ClientSetId": this.props.dataStore.ui.clientSet,
                                    "@FeaturePreview": this.props.dataStore.ui.featurePreview,
                                    "@ItemName": this.props.dataStore.ui.itemName,
                                    "@ItemValue": this.props.dataStore.ui.itemValue,
                                    "@Status": this.props.dataStore.ui.status,
                                    "@FailedAttempts": this.props.dataStore.ui.failedAttempts,
                                    "@PWExpiringIn": this.props.dataStore.ui.passwordExpiresIn,
                                    "@LastLogonDate": this.props.dataStore.ui.lastLogonDate,
                                    "@FilterProfile": this.props.dataStore.ui.filterProfile,
                                    "@ReportProfile": this.props.dataStore.ui.reportProfile,
                                    "@UserProfile": this.props.dataStore.ui.userProfile,
                                    "@Delete": "false",
                                    "@EmailConfirmed": this.props.dataStore.ui.emailConfirmed,
                                    "@CloneUserId": this.props.dataStore.ui.cloneUserId,
                                    "@CloneUserName":  this.props.dataStore.ui.cloneUserName
                                }
                            });
                        }
                        break;
                }
            }
        }
    }

    public getAllUserList(){    
        let goodList = new Array();
        let notConfirmedList = new Array();
        let notInteractiveList = new Array(); 
        let claimViewerList = new Array();
        let alreadyLinked = new Array();
        let missingEmailList = new Array();
        let disabledList = new Array();
        let objUsers:any = this.state.selectedUsers;

        for (let i = 0; i < objUsers.length; i++) {
            let currentUser = objUsers[i];
            if (currentUser) {
                let userId = currentUser.value;
                let selectedUser: any = this.props.dataStore.crud.data && this.props.dataStore.crud.data.UserMaintenanceInfo.Users.User.find(ele => ele['@ID'] === userId);
                if (!selectedUser){
                    continue;
                }                    
                let interactive = selectedUser["@Interactive"];
                let cvNode = selectedUser["@UserType"];
		        let cvUser = false;
                if (cvNode && cvNode === "C")
			        cvUser = true;
                let confirmed = selectedUser["@EmailConfirmed"];
                let linkedToCiam = selectedUser["@LinkedToCIAM"];
                let email = selectedUser["@EMail"];
                let status = selectedUser["@Status"];
                let missingEmail = email.length < 7;

                if(linkedToCiam == "1")
                    alreadyLinked.push(userId);
                else {
                    if(missingEmail)
                        missingEmailList.push(userId);
                    else if(status == "2")
                        disabledList.push(userId);
                    else {
                        if(confirmed == "1" && interactive == "1" && cvUser == false) {
                            goodList.push(userId);
                        }
                        else {
                            if(interactive != "1")
                                notInteractiveList.push(userId);
                            else if(cvUser)
                                claimViewerList.push(userId);
                            else
                                notConfirmedList.push(userId);
                        }
                    }
                }
            }
        }
        return {
            goodList,
            alreadyLinked,
            notConfirmedList,
            notInteractiveList,
            claimViewerList,
            missingEmailList,
            disabledList
        }

    }

    public handleActiveUsersSelection = () => {
        this.setState({activeUsersSelected: !this.state.activeUsersSelected})
    }

    public handleInActiveUsersSelection = () => {
        this.setState({inActiveUsersSelected: !this.state.inActiveUsersSelected})
    }

    public handleCiamUsersSelection = () => {
        this.setState({ciamUsersSelected: !this.state.ciamUsersSelected})
    }

    public handleNonCiamUsersSelection = () => {
        this.setState({nonCiamUsersSelected: !this.state.nonCiamUsersSelected})
    }

    public handleInteractiveUsersSelection = () => {
        this.setState({interactiveUsersSelected: !this.state.interactiveUsersSelected})
    }

    public handleNonInteractiveUsersSelection = () => {
        this.setState({nonInteractiveUsersSelected: !this.state.nonInteractiveUsersSelected})
    }

    public handleAcknowledgeButtonClick(e: React.SyntheticEvent<Element, Event>) {
        this.setBusy(true);
        this.props.action.ui.updateCiamJobStatus({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                index: this.props.dataStore.ui.selectedUser.index,
                value: this.props.dataStore.ui.selectedUser.value,
                text: this.props.dataStore.ui.selectedUser.text
            }
        });
    }

    public handleCiamButtonClick(e: React.SyntheticEvent) {
        if (this.props.dataStore.ui.linkedToCiam == "1") {
            this.setBusy(true);
            this.props.action.ui.getCiamUserDetails({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: this.props.dataStore.ui.selectedUser.index,
                    value: this.props.dataStore.ui.selectedUser.value,
                    text: this.props.dataStore.ui.selectedUser.text
                }
            });
        }
        else
            this.onClickCiamButton(e as React.KeyboardEvent);

    }

    public onClickCiamButton(e: React.KeyboardEvent) {
        let ctrlKey = e.ctrlKey;            
        let {
                goodList,
                alreadyLinked,
                notConfirmedList,
                notInteractiveList,
                claimViewerList,
                missingEmailList,
                disabledList
            } = this.getAllUserList();

        let alertMsg = '';
        let confirmMsg = '';
        let count = this.state.selectedUsers.length;
        let selectedUsers = this.state.selectedUsers;
       
        if(goodList.length == 0) {
            alertMsg = "The following errors occurred while linking the \n" + (count > 1 ? count : "") +  (count > 1 ? " users" : "user") +  " to SSO: \n\n";
            if(alreadyLinked.length > 0)
                alertMsg += (alreadyLinked.length > 1 || count > 1 ? alreadyLinked.length : "") + (alreadyLinked.length > 1 ? " Users" : " User") + " already linked to SSO.\n\n";
            if(missingEmailList.length > 0)
                alertMsg += (missingEmailList.length > 1 || count > 1 ? missingEmailList.length : "") + (missingEmailList.length > 1 ? " Users do not have an email address." : " User does not have an email address.") + ".\n\n";
            if(disabledList.length > 0)
                alertMsg += (disabledList.length > 1 || count > 1 ? disabledList.length : "") + (disabledList.length > 1 ? " Users are in disabled status." : " User is in disabled status.") + ".\n\n";
            if(notInteractiveList.length > 0)
                alertMsg += (notInteractiveList.length > 1 || count > 1 ? notInteractiveList.length : "") + (notInteractiveList.length > 1 ? " Users are non-interactive." : " User is not interactive.") + ".\n\n";
            if(claimViewerList.length > 0)
                alertMsg += (claimViewerList.length > 1 || count > 1 ? claimViewerList.length : "") + (claimViewerList.length > 1 ? " Users are claim viewer users." : " User is a claim viewer user.") + ".\n\n";
            if(notConfirmedList.length > 0)
                alertMsg += (notConfirmedList.length > 1 || count > 1 ? notConfirmedList.length : "") + (notConfirmedList.length > 1 ? " Users do not have validated emails." : " User email is not validated.") + ".\n\n";

            this.props.action.ui.setModalText(alertMsg);
        }
        else {

            if(goodList.length > 0 && goodList.length == selectedUsers.length) {
                confirmMsg = "Please confirm your request to link " + (count > 1 ? count : "") +  (count > 1 ? " users" : "the selected user") +  " to SSO, or click cancel to go back.";
            }
            if(goodList.length > 0 && goodList.length != selectedUsers.length) {
                confirmMsg = goodList.length + (goodList.length > 1 ? " users" : " User") + " can be linked to SSO. The following users cannot be linked: .\n\n";
                if(alreadyLinked.length > 0)
                    confirmMsg += "Number of users already linked to SSO: " + alreadyLinked.length +".\n\n";
                if(missingEmailList.length > 0)
                    confirmMsg += "Number of users with missing email address: " + missingEmailList.length +".\n\n";
                if(disabledList.length > 0)
                    confirmMsg += "Number of users in disabled status: " + disabledList.length +".\n\n";
                if(notInteractiveList.length > 0)
                    confirmMsg += "Number of non interactive users: " + notInteractiveList.length +".\n\n";
                if(claimViewerList.length > 0)
                    confirmMsg += "Number of claim viewer users: " + claimViewerList.length +".\n\n";
                if(notConfirmedList.length > 0)
                    confirmMsg += "Number of users with email not validated: " + notConfirmedList.length + ".\n\n";
                confirmMsg += "Please confirm your request or click cancel to go back.";
            }
            this.setState({ isCiamLinkModalOpen: true, ciamLinkModalMessage: confirmMsg, linkableUsersList: goodList, ctrlKeyPressed: ctrlKey })
        }

    }   
        
    public onCiamLinkModalConfirmation(e: React.MouseEvent<HTMLElement>) {
        if(this.state.linkableUsersList.length > 0) {
            this.onLinkCiamUser(this.state.linkableUsersList);
        }
        this.setState({ isCiamLinkModalOpen:false, ciamLinkModalMessage:'',linkableUsersList:[]});
    }

    public onCiamUserNameModalConfirmation(e: React.MouseEvent<HTMLElement>) {
        this.setBusy(true);
        let selectedUserId = this.props.dataStore.ui.selectedUser.value;
        this.props.action.ui.updateCiamUserName({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { index: 0, value: selectedUserId, text: JSON.stringify({ email: this.props.dataStore.ui.ciamUserName }) }
        });
        this.props.action.ui.updateCiamUserNameConfirmationModal(false);
        this.props.action.ui.updateCiamUserNameConfirmationText('');
    }

    public onLinkCiamUser(goodList:any){
        let payLoad:any =[];
        goodList.map((userId: any) => {
            this.props.dataStore.crud.data && this.props.dataStore.crud.data.UserMaintenanceInfo.Users.User.forEach(ele => {
                if (ele['@ID'] === userId) {
                    payLoad.push(ele);
                }
            });

        });

        let payloadRequest = {UserMaintenanceInfo:{Users:{User:payLoad
        }}}
        this.setBusy(true);
        
        if (this.state.ctrlKeyPressed) {
            this.props.action.ui.linkToCiam({
                masterCrud: this.props.dataStore.crud.data,
                uiData: { index: 0, value: "", text: JSON.stringify(payloadRequest) }
            });
        }
        else {
            this.props.action.ui.linkToCiamUsingJobQueue({
                masterCrud: this.props.dataStore.crud.data,
                uiData: { index: 0, value: "", text: JSON.stringify(payloadRequest) }
            });
        }
    }

    public onClickUnlinkCiamButton(e: React.KeyboardEvent<HTMLElement>) {
        let ctrlKey = e.ctrlKey;
        let goodList = new Array();
        let confirmMsg = '';
        let count = this.state.selectedUsers.length;
        let selectedUsers = this.state.selectedUsers;

        for (let i = 0; i < selectedUsers.length; i++) {
            let currentUser = selectedUsers[i];
            if (currentUser) {
                let userId = currentUser.value;
                let selectedUser: any = this.props.dataStore.crud.data && this.props.dataStore.crud.data.UserMaintenanceInfo.Users.User.find(ele => ele['@ID'] === userId);
                if (!selectedUser) {
                    continue;
                }
                let linkedToCiam = selectedUser["@LinkedToCIAM"];
                if (linkedToCiam != "0")
                    goodList.push(userId);
            }
        }
        if (goodList.length == 0) {
            this.props.action.ui.setModalText("None of the selected users are currently linked to SSO.");
        }
        else {

            if (goodList.length > 0 && goodList.length == selectedUsers.length) {
                confirmMsg = "Please confirm your request to unlink " + (count > 1 ? count : "") + (count > 1 ? " users" : "the selected user") + " from SSO, or click cancel to go back.";
            }
            if (goodList.length > 0 && goodList.length != selectedUsers.length) {
                confirmMsg = goodList.length + (goodList.length > 1 ? " users" : " User") + " can be unlinked from SSO. Remaining users are not currently linked. .\n\n";
                confirmMsg += "Please confirm your request or click cancel to go back.";
            }
            this.setState({ isCiamUnlinkModalOpen: true, ciamUnlinkModalMessage: confirmMsg, unlinkableUsersList: goodList, ctrlKeyPressed: ctrlKey })
        }
    }

    public onCiamUnlinkModalConfirmation(e: React.MouseEvent<HTMLElement>) {
        if (this.state.unlinkableUsersList.length > 0) {
            this.onUnlinkCiam(this.state.unlinkableUsersList);
        }
        this.setState({ isCiamUnlinkModalOpen: false, ciamUnlinkModalMessage: '', unlinkableUsersList: [] });
    }

    public onUnlinkCiam(goodList: any) {
        this.setBusy(true);
        if (this.state.ctrlKeyPressed) {
            this.props.action.ui.unlinkCiam({
                masterCrud: this.props.dataStore.crud.data,
                uiData: { index: 0, value: "", text: JSON.stringify(goodList) }
            });
        }
        else {
            this.props.action.ui.unlinkCiamUsingJobQueue({
                masterCrud: this.props.dataStore.crud.data,
                uiData: { index: 0, value: "", text: JSON.stringify(goodList) }
            });
        }
    }

    public onClickResetPasswordButton(e: React.SyntheticEvent) {
        this.setBusy(true);
        let selectedUserId = this.props.dataStore.ui.selectedUser.value;
        this.props.action.ui.ciamResetPassword(selectedUserId);
    }

    public onClickResendWelcomeEmailButton(e: React.SyntheticEvent) {
        this.setBusy(true);
        let selectedUserId = this.props.dataStore.ui.selectedUser.value;
        this.props.action.ui.ciamResendWelcomeEmail(selectedUserId);
    }
    
    public onClickRemoveUser(e: React.SyntheticEvent) {
        this.props.action.confirm.openConfirm(() => this.onClickConfirmRemoveUser());
    }

    public onClickCloneUser(e: React.SyntheticEvent) {
        this.props.action.ui.cloneUser({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { index: this.props.dataStore.ui.selectedUser.index, value: this.props.dataStore.ui.selectedUser.value, text: this.props.dataStore.ui.selectedUser.text }
        });
    }

    onToggleConfirmation(e: React.MouseEvent<HTMLElement>) {
        this.props.action.confirm.closeConfirm();
    }

    public onRemoveUser(e: React.MouseEvent<HTMLButtonElement>) {
        this.onClickConfirmRemoveUser();
    }

    public setAlert(alertMsg:string) {
        this.props.action.ui.setModalText(alertMsg);
    }

    public onClickConfirmRemoveUser() {
        handleChange()
        this.props.action.ui.removeUser({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { index: this.props.dataStore.ui.selectedUser.index, value: this.props.dataStore.ui.selectedUser.value, text: this.props.dataStore.ui.selectedUser.text }
        });
    }

    public onClientSetChange(cset: string) {
        this.props.action.ui.updateClientSet({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: cset }
        });
    }

    public onUserRoleChange(role: string) {
        this.props.action.ui.updateUserRole({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: role }
        });
    }

    public onFeaturePreviewChange(feature: string) {
        this.props.action.ui.updateFeaturePreview({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: feature }
        });
    }

    public getUserType(): string {

        let userType: string = "";

        if (this.props.dataStore.crud.data && 
            this.props.dataStore.crud.data.UserMaintenanceInfo.Users.User.length > 0) {

            let selectedUserIndex = this.props.dataStore.crud.data.UserMaintenanceInfo.Users.User.findIndex(usr =>
                usr["@ID"] === this.props.dataStore.ui.selectedUser.value);

            if (selectedUserIndex !== -1) {
                let selectedUser = this.props.dataStore.crud.data.UserMaintenanceInfo.Users.User[selectedUserIndex];

                let interactiveUser: boolean = selectedUser["@Interactive"] === "1" ? true : false;
                let claimViewerUser: boolean = selectedUser["@ClaimViewer"] === "1" ? true : false;

                userType = interactiveUser && claimViewerUser ? "C" : "U";
            }
        }

        return userType;
    }

    public getUserPolicy(userType: string): MCAccountPolicy {

        let result: MCAccountPolicy = {
            '@ExpirationInterval': "",
            '@ExpirationWarning': "",
            '@MinLength': "",
            '@AlphaNumeric': "",
            '@UpperLowerCase': "",
            '@SpecialChar': "",
            '@PWHistoryCount': "",
            '@LockoutThreshold': "",
            '@LockoutDuration': "",
            '@InactivityThreshold': "",
            '@IdleTimeout': "",
            '@UserType': "",
            '@EmailRequired': ""
        };

        let clientPolicy: MCAccountPolicy[] = [{
            '@ExpirationInterval': "",
            '@ExpirationWarning': "",
            '@MinLength': "",
            '@AlphaNumeric': "",
            '@UpperLowerCase': "",
            '@SpecialChar': "",
            '@PWHistoryCount': "",
            '@LockoutThreshold': "",
            '@LockoutDuration': "",
            '@InactivityThreshold': "",
            '@IdleTimeout': "",
            '@UserType': "",
            '@EmailRequired': ""
        }];

        let clientPolicies: MCAccountPolicy[] = this.props.dataStore.crud.data ? clientPolicy.concat(this.props.dataStore.crud.data.UserMaintenanceInfo.ClientAccountPolicies.ClientAccountPolicy) : clientPolicy;

        if (userType !== "") {
            let clientPolicyIndex: number = clientPolicies.findIndex(cp => cp["@UserType"] === userType);
            if (clientPolicyIndex !== -1) {
                result = clientPolicies[clientPolicyIndex];
            } 
        }

        return result;
    }

    public GetPasswordRulesText() {
        let userType: string = this.getUserType();
        if (userType !== "") {
            let userPolicy: MCAccountPolicy = this.getUserPolicy(userType);
            if (userPolicy) {
                let minLength: string = userPolicy["@MinLength"];
                let alphaNumberic: boolean = userPolicy["@AlphaNumeric"] === "1";
                let upperLowerCase: boolean = userPolicy["@UpperLowerCase"] === "1";
                let speicalChar: boolean = userPolicy["@SpecialChar"] === "1";

                let passwordRules = this.getPasswordRules(minLength, alphaNumberic, upperLowerCase, speicalChar);

                return passwordRules;
            }
        }
    }   

    private getPasswordRules(intLength: string, bForceAlphaNum: boolean, bForceUpperLower: boolean, bForceSpecialChar: boolean) {
        // check to make sure new password meets length requirements
        let message = "Passwords must contain at least " + intLength + " characters.";

        if (bForceAlphaNum)
            message += "\n Passwords must contain at least one letter and one number.";

        // check to make sure new password meets upper/lower case requirements
        if (bForceUpperLower)
            message += "\n Passwords must contain at least one uppercase and one lowercase letter.";

        // check to make sure new password meets special char requirements
        if (bForceSpecialChar)
            message += "\n Passwords must contain at least one special character.";

        return message;
    }

    private viewFilterProfile() {

        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.UserMaintenanceInfo &&
            this.props.dataStore.crud.data.UserMaintenanceInfo.Users &&
            this.props.dataStore.crud.data.UserMaintenanceInfo.Users.User) {

            let selectedUser = this.props.dataStore.crud.data!.UserMaintenanceInfo.Users.User.find(usr =>
                usr["@ID"] === this.props.dataStore.ui.selectedUser.value);

            if (selectedUser && selectedUser["@FilterProfileId"] && selectedUser["@FilterProfile"]) {
                this.props.history.push("/Administration/RestrictAccess?ProfileId=" +
                    selectedUser["@FilterProfileId"] + '/' + selectedUser["@FilterProfile"]);
            }
        }
    }

    private viewReportProfile() {
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.UserMaintenanceInfo &&
            this.props.dataStore.crud.data.UserMaintenanceInfo.Users &&
            this.props.dataStore.crud.data.UserMaintenanceInfo.Users.User) {

            let selectedUser = this.props.dataStore.crud.data!.UserMaintenanceInfo.Users.User.find(usr =>
                usr["@ID"] === this.props.dataStore.ui.selectedUser.value);

            if (selectedUser && selectedUser["@ReportProfileId"] && selectedUser["@ReportProfile"]) {
                this.props.history.push("/Administration/RestrictAccess?ProfileId=" +
                    selectedUser["@ReportProfileId"] + '/' + selectedUser["@ReportProfile"]);
            }
        }
    }

    private isClaimViewerEnabled(): boolean {
        let isClaimViewerEnabled: boolean = false;

        if (this.props.dataStore.ui.interactive === "0") {
            isClaimViewerEnabled = true;
        }

        return isClaimViewerEnabled;
    }

    // User Roles
    public getUserRoles(lookupList: MCUserRole[] | null) {
        let isUserRoleCrudLoaded = (this.props.dataStore.crud.data && this.props.dataStore.crud.data.UserMaintenanceInfo &&
            this.props.dataStore.crud.data.UserMaintenanceInfo.UserRoles && this.props.dataStore.crud.data.UserMaintenanceInfo.UserRoles.UserRole) ? true : false;

        if (!isUserRoleCrudLoaded || !lookupList || lookupList.length === 0)
            return [];

        let result: any[] = [];

        lookupList.forEach(ob => {
            if (ob['@Name'])
                result.push({ label: ob['@Name'], value: ob['@ID'] });
        });
        return result;
    }

    public getSelectedUserRole(lookupList: MCUserRole[] | null, idToFind: string | undefined): IDropDownItem {

        let isUserRoleCrudLoaded = (this.props.dataStore.crud.data && this.props.dataStore.crud.data.UserMaintenanceInfo &&
            this.props.dataStore.crud.data.UserMaintenanceInfo.UserRoles && this.props.dataStore.crud.data.UserMaintenanceInfo.UserRoles.UserRole) ? true : false;

        if (!isUserRoleCrudLoaded ||
            idToFind === undefined || !lookupList || lookupList.length === 0
        ) return { value: '', label: '' };

        let item = lookupList.find(
            ob => ob['@ID'] === idToFind);
        let result = { value: '', label: '' };
        if (item) {
            result.label = item['@Name'];
            result.value = item['@ID'];
        } else {
            result.label = 'No User Role Assigned';
            result.value = '0';
        }

        return result;
    }

    // Client Set
    public getClientSet(lookupList: MCClientSet | null) {

        let result: any[] = [];

        let isClientSetCrudLoaded = (this.props.dataStore.crud.data && this.props.dataStore.crud.data.UserMaintenanceInfo &&
            this.props.dataStore.crud.data.UserMaintenanceInfo.ClientSets && this.props.dataStore.crud.data.UserMaintenanceInfo.ClientSets.ClientSet) ? true : false;

        if (!isClientSetCrudLoaded) {
            return result.push({ value: "-1", label: "No Client Sets Defined" });
        }

        if (lookupList) {
            if (this.props.canViewClientSet || this.props.canEditClientSet) {
                result.push({ value: "-1", label: "- Select Client Set -" });
                result.push({ label: lookupList["@Name"], value: lookupList["@ID"] });
            }
        } else {
            if (this.props.canViewClientSet || this.props.canEditClientSet) {
                result.push({ value: "-1", label: "No Client Sets Defined" });
            }
        }

        return result;
    }

    public getSelectedClientSet(lookupList: MCClientSet | null, idToFind: string | undefined): IDropDownItem {

        let isClientSetCrudLoaded = (this.props.dataStore.crud.data && this.props.dataStore.crud.data.UserMaintenanceInfo &&
            this.props.dataStore.crud.data.UserMaintenanceInfo.ClientSets && this.props.dataStore.crud.data.UserMaintenanceInfo.ClientSets.ClientSet) ? true : false;

        if (!isClientSetCrudLoaded || !this.props.dataStore.ui.clientSet ||
            idToFind === undefined || !lookupList)
            return { value: '-1', label: 'No Client Sets Defined' };

        let result = { value: '', label: '' };

        if (lookupList) {
            let itemExists: boolean = lookupList["@ID"] === idToFind;
            if (itemExists) {
                result.label = lookupList['@Name'];
                result.value = lookupList['@ID'];
            } else {
                result.label = '- Select Client Set -';
                result.value = '-1';
            }
        }
        return result;
    }

    public setBusy(val: boolean) {
        this.setState({ isPageBusy: val });
        this.props.action.ui.setBusy(val);
    }

    private isClientSetEnabled(): boolean {

        let isClientSetCrudLoaded = (this.props.dataStore.crud.data && this.props.dataStore.crud.data.UserMaintenanceInfo &&
            this.props.dataStore.crud.data.UserMaintenanceInfo.ClientSets && this.props.dataStore.crud.data.UserMaintenanceInfo.ClientSets.ClientSet) ? true : false;
        //console.log("this.props.canViewClientSet = " + this.props.canViewClientSet);
        //console.log("this.props.canEditClientSet = " + this.props.canEditClientSet);
        //console.log("isClientSetCrudLoaded = " + isClientSetCrudLoaded);
        let result: boolean = (!this.props.canViewClientSet || !this.props.canEditClientSet || !isClientSetCrudLoaded);
        return result;
    }

    // Feature Preview
    public getFeaturePreviews() {
        let featurePreviews: IDropDownItem[] = [
            {
                label: '- Select Feature -',
                value: ''
            },
            {
                label: 'CFI Legacy Plus',
                value: 'Legacy+',
            },
            {
                label: 'CFI Legacy Only',
                value: 'Legacy',
            }
        ];

        return featurePreviews;
    }
    
    public getSelectedFeaturePreview(lookupList: MCUser[] | null, idToFind: string | undefined): IDropDownItem {
        let result: IDropDownItem = { value: '', label: '- Select Feature -' };

        let features: IDropDownItem[] = this.getFeaturePreviews();

        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.UserMaintenanceInfo ||
            !this.props.dataStore.crud.data.UserMaintenanceInfo.Users.User) {
            return result;
        }
        
        // Assign Default User Role
        result.label = features[0].label;
        result.value = features[0].value;

        let selectedUser = this.props.dataStore.crud.data.UserMaintenanceInfo.Users.User.find(usr =>
            usr["@ID"] === this.props.dataStore.ui.selectedUser.value);

        if (selectedUser && this.props.dataStore.ui.itemName === selectedUser["@ItemName"] &&
            this.props.dataStore.ui.itemName === "CFI_Mode") {

            if (this.props.dataStore.ui.itemValue === "Legacy") {
                result.label = features[2].label;
                result.value = features[2].value;
            } else if (this.props.dataStore.ui.itemValue === "Legacy+") {
                result.label = features[1].label;
                result.value = features[1].value;
            } else {
                result.label = features[0].label;
                result.value = features[0].value;
            }
        } else {
            if (idToFind) {
                let id = features.find(fet => fet.label === idToFind);
                if (id) {
                    result.label = id.label;
                    result.value = id.value;
                }
            }
        }

        return result;
    }

    public onCiamUserNameModalToggle() {
        this.props.action.ui.updateCiamUserNameConfirmationModal(false);
        this.props.action.ui.updateCiamUserNameConfirmationText('');
    }

    public showAcknowledgementButtons() {
        return (this.props.dataStore.ui.userInCiamJob == false &&
            this.props.dataStore.ui.ciamActionSucceeded != undefined && this.props.dataStore.ui.ciamActionSucceeded == false &&
            this.props.dataStore.ui.userAcknowledged != undefined && this.props.dataStore.ui.userAcknowledged == false)     
    }

    public getCiamAckMessage() {
        return "<p>SSO Batch Job failed for this user,</p> <p>please check the job queue for details of the job.</p>";
    }

    public render() {
        let instruction = <React.Fragment>The right to use a feature is controlled by a combination of groups and users.Use this page to add or remove a user and to update information for existing users. * indicates a required field. ** indicates a required field only for new users.</React.Fragment>;
        let buttons = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        let usersItem: MCUser[] = [{
            "@ID": "",
            "@LoginName": "- ADD NEW USER -",
            "@FirstName": "",
            "@LastName": "",
            "@Password": "",
            "@ConfirmPassword": "",
            "@EMail": "",
            "@UserRoleId": "0",
            "@Interactive": "1",
            "@ClaimViewer": "0",
            '@UserType': 'U',
            "@ClientSetId": "-1",
            "@FeaturePreview": "0",
            "@Status": "0",
            "@ItemName": "",
            "@ItemValue": "",
            "@EmailConfirmed": "",
            "@LinkedToCIAM": "",
            "@CloneUserId": "",
            "@CloneUserName": ""
        }];

        let users = usersItem;
        
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.UserMaintenanceInfo &&
            this.props.dataStore.crud.data.UserMaintenanceInfo.Users &&
            this.props.dataStore.crud.data.UserMaintenanceInfo.Users.User) {
                
                let usersArray = this.props.dataStore.crud.data.UserMaintenanceInfo.Users.User.filter(xuser => xuser["@Delete"] !== "true");


                if (this.state.searchText.length > 0 || this.state.activeUsersSelected || this.state.inActiveUsersSelected ||
                    this.state.ciamUsersSelected || this.state.nonCiamUsersSelected || this.state.interactiveUsersSelected ||
                    this.state.nonInteractiveUsersSelected) {
                        const filterConditions = []

                        let userData = this.props.dataStore.crud.data?.UserMaintenanceInfo.Users.User.filter(xuser => {
                            const filterConditions = []
                            filterConditions.push(this.state.searchText.length === 0 || (this.state.searchText.length > 0 &&
                                        (xuser["@LastName"].toUpperCase().includes(this.state.searchText) ||
                                        xuser["@LoginName"].toUpperCase().includes(this.state.searchText) ||
                                        xuser["@EMail"].toUpperCase().includes(this.state.searchText))
                                    ));

                            if(this.state.activeUsersSelected) {
                                filterConditions.push( xuser["@Status"] == "0" );
                            }
                             if(this.state.inActiveUsersSelected) {
                                filterConditions.push( xuser["@Status"] == "1" || xuser["@Status"] == "2" );
                            }
                            
                            if(this.state.ciamUsersSelected) {
                                filterConditions.push( xuser["@LinkedToCIAM"] !== "0");
                            }
                            if(this.state.nonCiamUsersSelected) {
                                filterConditions.push( xuser["@LinkedToCIAM"] === "0");
                            }
                            
                            if(this.state.interactiveUsersSelected) {
                                filterConditions.push( xuser["@Interactive"] === "1");
                            }
                            if(this.state.nonInteractiveUsersSelected) {
                                filterConditions.push( xuser["@Interactive"] === "0");
                            }
                            return xuser["@ID"] == "" || filterConditions.every(condition => condition)
                        });
                        usersArray = userData ?? [];
                }

            users = usersItem.concat(usersArray);
        }
        console.log(users)
        if (this.props.dataStore.crud.redirectURL !== undefined) {
            return (<Redirect to={{ pathname: this.props.dataStore.crud.redirectURL, state: { from: this.props.location } }} />);
        }

        let isAddUserEnabled: boolean = this.getModifyButtonType() === eModifyButtonType.Add && !this.props.canCreate ? true : false;
        let isUpdateUserEnabled: boolean = this.getModifyButtonType() === eModifyButtonType.Update && !this.props.canEdit ? true : false;
        let enableUserBetaPreviewObj = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "EnableUserBetaPreview");
        let enableUserBetaPreview = enableUserBetaPreviewObj && enableUserBetaPreviewObj.value ? enableUserBetaPreviewObj.value : "";
        let canBetaPreview: boolean = this.props.canBetaPreview && enableUserBetaPreview === "1" ? true : false;
        let passwordExpirationDays = this.props.dataStore.ui.passwordExpiresIn === "" ? "" : this.props.dataStore.ui.passwordExpiresIn + " days";
        let isClientUser = this.isClientUser();
        let selectedItems = [];
        if (this.state.selectedUsers.length > 1) {
            for (var i = 0; i < this.state.selectedUsers.length; i++) {
                selectedItems.push(this.state.selectedUsers[i].value);
            }
        }
        let selection = this.state.selectedUsers.length > 1 ? selectedItems : [this.props.dataStore.ui.selectedUser.value];
        return (
            <DialogWrapper title='User Maintenance' instruction={instruction} helpUrl='/Support/Help/HELP_Maint_Users.htm' buttons={buttons} isBusy={this.props.dataStore.ui.isBusy}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper>
                    <ContentWrapper1>
                        {this.showAcknowledgementButtons() &&
                            <CiamErrorMessage1>
                                <div id="ciamAcknowledge">
                                    <p className="errorMsg">SSO Batch Job failed for this user, check the job queue for details </p>
                                    <Button
                                        domID="ciamAcknowledgeButton"
                                        name={"ok"}
                                        size="control"
                                        buttonType="emphasized"
                                        type="button"
                                        onClick={(e: React.SyntheticEvent<Element, Event>) => {
                                            this.handleAcknowledgeButtonClick(e)
                                        }}
                                    />
                                </div>
                                
                            </CiamErrorMessage1>}
                </ContentWrapper1>
                <ContentWrapper2>
                     <ContentWrapper3> 
                        <SelectList>
                            <DialogFieldset>
                                <DialogLegend>Users</DialogLegend>
                                <Input
                                    domID={"Users-Filterable-Search-Input"}
                                    name={"users-Filterable-Search-Input"}
                                    type="text"
                                    placeholder={"Filter"}
                                    value={this.state.searchText}
                                    onChange={(e: any) => this.onHandleSearchInputChange(e)}
                                />
                                <SelectComponent
                                    title="Users"
                                    size={21}
                                    width="100%"
                                    // height="250px"
                                    multiple="true"
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectUser(e, users)}
                                    optionFields={{
                                        value: "@ID",
                                        text: "@LoginName"
                                    }}
                                    records={users}
                                    selectedMultiValue={selection}
                                    autoScrollToSelection={true}
                                >
                                </SelectComponent>
                            </DialogFieldset>
                        </SelectList>
                        <CheckBoxContainer>
                            <CheckBoxRow>
                                <CheckBoxComponent
                                    id="activeCheckbox"
                                    checked={this.state.activeUsersSelected}
                                    onChange={() => {this.handleActiveUsersSelection(); }}
                                    label='Active'
                                    disabled={false}
                                    hasError={false}
                                />

                                <CheckBoxComponent
                                    id="inactiveCheckbox"
                                    checked={this.state.inActiveUsersSelected}
                                    onChange={() => {this.handleInActiveUsersSelection(); }}
                                    label="Inactive"
                                    disabled={false}
                                    hasError={false}
                                />
                            </CheckBoxRow>
                            <CheckBoxRow>
                                <CheckBoxComponent
                                    id="ciamCheckbox"
                                    checked={this.state.ciamUsersSelected}
                                    onChange={() => {this.handleCiamUsersSelection(); }}
                                    label="Linked"
                                    disabled={false}
                                    hasError={false}
                                />

                                <CheckBoxComponent
                                    id="nonCiamCheckbox"
                                    checked={this.state.nonCiamUsersSelected}
                                    onChange={() => {this.handleNonCiamUsersSelection(); }}
                                    label="Not Linked"
                                    disabled={false}
                                    hasError={false}
                                />
                            </CheckBoxRow>
                            <CheckBoxRow>
                                <CheckBoxComponent
                                    id="interactiveCheckbox"
                                    checked={this.state.interactiveUsersSelected}
                                    onChange={() => {this.handleInteractiveUsersSelection(); }}
                                    label="Interactive"
                                    disabled={false}
                                    hasError={false}
                                />

                                <CheckBoxComponent
                                    id="nonInteractiveCheckbox"
                                    checked={this.state.nonInteractiveUsersSelected}
                                    onChange={() => {this.handleNonInteractiveUsersSelection(); }}
                                    label="Non-Interactive"
                                    disabled={false}
                                    hasError={false}
                                />
                            </CheckBoxRow>
                        </CheckBoxContainer>
                    </ContentWrapper3>
                    <SelectActions>
                            <CiamButtons>
                                <Button
                                    domID="ciamButton"
                                    name={this.props.dataStore.ui.linkedToCiam == "1" ? "Get SSO Data" : "Link"}
                                    size="control"
                                    buttonType="standard"
                                    disabled={!this.props.dataStore.ui.isLinkEnabled || this.props.dataStore.ui.userInCiamJob || this.showAcknowledgementButtons()}
                                    type="button"
                                    onClick={(e: React.SyntheticEvent) => {
                                        this.handleCiamButtonClick(e)
                                    }}
                                />
                                <Button
                                    domID="UnlinkCiamButton"
                                    name="Unlink"
                                    size="control"
                                    buttonType="standard"
                                    type="button"
                                    disabled={!this.props.dataStore.ui.isUnlinkEnabled ||
                                        this.props.dataStore.ui.userInCiamJob ||
                                        this.showAcknowledgementButtons() ||
                                        isClientUser || !this.props.dataStore.ui.retrievedCiamUserRecord}
                                    onClick={(e: React.SyntheticEvent) => this.onClickUnlinkCiamButton(e as React.KeyboardEvent<HTMLElement>)}

                                />
                            </CiamButtons>
                        {
                            this.props.dataStore.ui.userInCiamJob == true &&
                            <CiamErrorMessage>
                                <DataItem
                                    domID="ciamError"
                                    label="SSO Batch Job In Progress, editing disabled."
                                />
                            </CiamErrorMessage>
                        }
                        {
                            this.props.dataStore.ui.linkedToCiam != '1' &&
                            <div>
                                <Inputs>
                                    <Input
                                        domID="loginName-input"
                                        label="*User Name:"
                                        autoComplete="new-password"
                                        className="text-input"
                                        maxLength={12}
                                        size="small"
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => this.props.action.ui.updateLoginName(e.target.value)}
                                        initialValue={this.props.dataStore.ui.loginName}
                                        hasError={!!this.props.dataStore.ui.loginName_userError}
                                        errorMessage={this.props.dataStore.ui.loginName_userError}
                                        disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                    />
                                    <Input
                                        domID="firstName-input"
                                        label="*First Name:"
                                        autoComplete="new-password"
                                        className="text-input"
                                        maxLength={25}
                                        size="small"
                                        initialValue={this.props.dataStore.ui.firstName}
                                        hasError={!!this.props.dataStore.ui.firstName_userError}
                                        errorMessage={this.props.dataStore.ui.firstName_userError}
                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => { this.props.action.ui.updateFirstName(e.target.value.toUpperCase()) }}
                                        disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                    />
                                    <Input
                                        domID="lastName-input"
                                        label="*Last Name:"
                                        autoComplete="new-password"
                                        className="text-input"
                                        maxLength={35}
                                        size="small"
                                        initialValue={this.props.dataStore.ui.lastName}
                                        hasError={!!this.props.dataStore.ui.lastName_userError}
                                        errorMessage={this.props.dataStore.ui.lastName_userError}
                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => { this.props.action.ui.updateLastName(e.target.value.toUpperCase())}}
                                        disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                    />
                                    <Input
                                        domID="password-input"
                                        label="**Password:"
                                        autoComplete="new-password"
                                        className="text-input"
                                        type="password"
                                        maxLength={64}
                                        size="small"
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => this.props.action.ui.updatePassword(e.target.value)}
                                        initialValue={this.props.dataStore.ui.password}
                                        hasError={!!this.props.dataStore.ui.password_userError}
                                        errorMessage={this.props.dataStore.ui.password_userError}
                                        disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                    />
                                    <Input
                                        domID="confPassword-input"
                                        label="**Confirm Password:"
                                        autoComplete="new-password"
                                        className="text-input"
                                        type="password"
                                        maxLength={64}
                                        size="small"
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => this.props.action.ui.updateConfirmPassword(e.target.value)}
                                        initialValue={this.props.dataStore.ui.confirmPassword}
                                        hasError={!!this.props.dataStore.ui.confirmPassword_userError}
                                        errorMessage={this.props.dataStore.ui.confirmPassword_userError}
                                        disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                    />
                                    <Input
                                        domID="emailAddress-input"
                                        label="E-Mail Address:"
                                        autoComplete="new-password"
                                        className="text-input"
                                        size="small"
                                        initialValue={this.props.dataStore.ui.eMail}
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => this.props.action.ui.updateEmail(e.target.value)}
                                        hasError={!!this.props.dataStore.ui.email_userError}
                                        errorMessage={this.props.dataStore.ui.email_userError}
                                        disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                    />
                                </Inputs>
                                <ContentRowWrapper style={{ maxHeight: '35px' }}>
                                    <CheckBoxComponent
                                        id="emailValidated-CheckBox"
                                        labelBefore="E-MAIL VALIDATED:&nbsp;&nbsp;"
                                        checked={this.props.dataStore.ui.emailConfirmed === "1"}
                                        disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onEmailConfirmed(e.target.checked)}
                                    />
                                </ContentRowWrapper>
                                <ContentColumnWrapper>
                                    <ContentRowWrapper style={{ maxHeight: '35px' }}>
                                        <CheckBoxComponent
                                            id="interactiveUser-input"
                                            labelBefore="INTERACTIVE USER:&nbsp;&nbsp;&nbsp;"
                                            checked={this.props.dataStore.ui.interactive === "1"}
                                            disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onInteractiveUser(e.target.checked)}
                                        />
                                        {/*<CheckBoxComponent*/}
                                        {/*    id="claimViewUser-input"*/}
                                        {/*    labelBefore="CLAIMVIEWER USER:&nbsp;&nbsp;"*/}
                                        {/*    checked={this.props.dataStore.ui.claimViewer === "1"}*/}
                                        {/*    disabled={this.isClaimViewerEnabled() || selectedItems.length > 1}*/}
                                        {/*    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onClaimViewer(e.target.checked)}*/}
                                        {/*/>*/}
                                    </ContentRowWrapper>
                                </ContentColumnWrapper>
                                {
                                    this.isClonedUser() ?
                                        (
                                            <CloneContentWrapper style={{ maxHeight: '35px' }}>
                                                <CheckBoxComponent
                                                    id="cloneUser-input"
                                                    labelBefore={"CLONING USER: " + this.getClonedUserName()}
                                                    checked={true}
                                                    disabled={false}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onClearCloneUser(e.target.checked)}
                                                />
                                            </CloneContentWrapper>
                                        ) :
                                        (
                                            <ContentRowWrapper style={{ maxHeight: '35px' }} />
                                        )

                                }
                            </div>
                        }
                        {
                            this.props.dataStore.ui.linkedToCiam === '1' &&
                            <div>
                                <CiamAccount {...this.props} canBetaPreview={canBetaPreview} apiProps=""></CiamAccount>
                            </div>
                        }
                        <SelectButtons>
                            {
                                !this.isCloneButtonHidden()
                                    ?
                                    (<Button
                                        domID="automation-id"
                                        name="Clone"
                                        size="control"
                                        buttonType="standard"
                                        type="button"
                                        disabled={this.props.dataStore.ui.userInCiamJob}
                                        onClick={(e: React.SyntheticEvent) => this.onClickCloneUser(e)}
                                    />) : ""
                            }
                            <Button
                                domID="automation-id"
                                name={this.getModifyButtonText()}
                                size="control"
                                buttonType="emphasized"
                                disabled={(isAddUserEnabled || isUpdateUserEnabled) || (selectedItems.length > 1) || this.props.dataStore.ui.userInCiamJob}
                                type="button"
                                onClick={(e: React.SyntheticEvent) => {
                                    handleChange()
                                    this.onClickModifyButton(e)
                                }}
                            />
                            <Button
                                domID="automation-id"
                                name="Remove"
                                size="control"
                                buttonType="standard"
                                type="button"
                                disabled={this.isRemoveButtonDisabled() || this.props.dataStore.ui.userInCiamJob}
                                onClick={(e: React.SyntheticEvent) => this.onClickRemoveUser(e)}

                            />
                        </SelectButtons>
                    </SelectActions>

                    <SelectActions2>
                        {this.props.dataStore.ui.linkedToCiam != '1' &&
                            <DialogFieldset>
                                <DialogLegend>Account</DialogLegend>
                                <ContentColumnWrapper>
                                    <ContentRowWrapper style={{ paddingBottom: '5px' }}>
                                        <RadioButtonComponent
                                            id="enabled-account-id"
                                            label="Enabled"
                                            checked={this.props.dataStore.ui.status === "0"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAccountStatus("0")}
                                            disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                        />
                                        <RadioButtonComponent
                                            id="lockedout-account-id"
                                            label="Locked Out"
                                            checked={this.props.dataStore.ui.status === "1"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAccountStatus("1")}
                                            disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                        />
                                        <RadioButtonComponent
                                            id="disabled-account-id"
                                            label="Disabled"
                                            checked={this.props.dataStore.ui.status === "2"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAccountStatus("2")}
                                            disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                        />
                                    </ContentRowWrapper>
                                </ContentColumnWrapper>
                                <ContentColumnWrapper>
                                    <ContentRowWrapper>
                                        <DataItem
                                            domID="failedAttempts-id"
                                            label="Failed attempts:&nbsp;&nbsp;"
                                        />
                                        <DataItem content={this.props.dataStore.ui.failedAttempts} />
                                    </ContentRowWrapper>
                                    <ContentRowWrapper>
                                        <DataItem
                                            domID="passwordExpiringIn-id"
                                            label="Password Expiring In:&nbsp;&nbsp;"
                                        />
                                        <DataItem content={passwordExpirationDays} />
                                    </ContentRowWrapper>
                                    <ContentRowWrapper>
                                        <DataItem
                                            domID="lastLoggedIn-id"
                                            label="Last logged In:&nbsp;&nbsp;"
                                        />
                                        <DataItem content={this.props.dataStore.ui.lastLogonDate} />
                                    </ContentRowWrapper>
                                </ContentColumnWrapper>
                            </DialogFieldset>
                        }
                        {this.props.dataStore.ui.linkedToCiam === '1' && (<DialogFieldset>
                            <DialogLegend>Account</DialogLegend>
                                <ContentColumnWrapper>
                                    <ContentRowWrapper style={{ paddingBottom: '5px' }}>
                                        <RadioButtonComponent
                                            id="enabled-account-id"
                                            label="Enabled"
                                            checked={this.props.dataStore.ui.status === "0"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAccountStatus("0")}
                                            disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                        />
                                        <RadioButtonComponent
                                            id="lockedout-account-id"
                                            label="Locked Out"
                                            checked={this.props.dataStore.ui.status === "1"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAccountStatus("1")}
                                            disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                        />
                                        <RadioButtonComponent
                                            id="disabled-account-id"
                                            label="Disabled"
                                            checked={this.props.dataStore.ui.status === "2"}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAccountStatus("2")}
                                            disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                        />
                                    </ContentRowWrapper>
                                </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <ContentRowWrapper>
                                    <DataItem domID="ciamAccountStatus-id"
                                        label="Account Status:&nbsp;&nbsp;"
                                    />
                                    <DataItem
                                        content={this.props.dataStore.ui.ciamAccountStatus}
                                    />
                                </ContentRowWrapper>
                                <ContentRowWrapper>
                                    <DataItem domID="lastLogonDate-id"
                                        label="Last logged in:&nbsp;&nbsp;"
                                    />
                                    <DataItem
                                        content={this.props.dataStore.ui.lastLogonDate}
                                    />
                                </ContentRowWrapper>                               
                                <SelectButtons>
                                    <Button
                                            domID="resetPassword-id"
                                            name="Reset Password"
                                            size="control"
                                            buttonType="standard"
                                            type="button"
                                            onClick={(e: React.SyntheticEvent) => this.onClickResetPasswordButton(e)}
                                            disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob || !this.props.dataStore.ui.retrievedCiamUserRecord}
                                            style={{ display: this.props.dataStore.ui.linkedToCiam != "1" ? 'inline-flex' : 'none' }}
                                    />
                                    <Button
                                        domID="resendWelcomeEmail-id"
                                        name="Resend Welcome Email"
                                        size="control"
                                        buttonType="standard"
                                        type="button"
                                        onClick={(e: React.SyntheticEvent) => this.onClickResendWelcomeEmailButton(e)}
                                            disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob || !this.props.dataStore.ui.retrievedCiamUserRecord}
                                            style={{ display: this.props.dataStore.ui.linkedToCiam != "1" ? 'inline-flex' : 'none' }}
                                    />
                                </SelectButtons>
                            </ContentColumnWrapper>
                        </DialogFieldset>)}
                        <DialogFieldset>
                            <DialogLegend>Profiles</DialogLegend>
                            {
                                this.props.canRestrictAccess ?
                                    (<ContentColumnWrapper>
                                        <ContentRowWrapper>
                                            <DataItem
                                                domID="btnFilterProfileLabel"
                                                label="Filter Profile:&nbsp;&nbsp;"
                                            />
                                            <Button
                                                name={this.props.dataStore.ui.filterProfile}
                                                domID="btnFilterProfile"
                                                type="button"
                                                buttonType="diminished"
                                                onClick={() => this.viewFilterProfile()}
                                                disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                            />
                                        </ContentRowWrapper>
                                        <ContentRowWrapper>
                                            <DataItem
                                                domID="btnReportProfileLabel"
                                                label="Report Profile:&nbsp;&nbsp;"
                                            />
                                            <Button
                                                name={this.props.dataStore.ui.reportProfile}
                                                domID="btnReportProfile"
                                                type="button"
                                                buttonType="diminished"
                                                onClick={() => this.viewReportProfile()}
                                                disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                            />
                                        </ContentRowWrapper>
                                        <ContentRowWrapper>
                                            <DataItem
                                                domID="userProfile-id"
                                                label="User Profile:&nbsp;&nbsp;"
                                            />
                                            <DataItem content={this.props.dataStore.ui.userProfile} />
                                        </ContentRowWrapper>
                                    </ContentColumnWrapper>
                                    ) :
                                    (
                                        <ContentColumnWrapper>
                                            <ContentRowWrapper>
                                                <DataItem
                                                    domID="filterProfile-id"
                                                    label="Filter Profile:&nbsp;&nbsp;"
                                                />
                                                <DataItem content={this.props.dataStore.ui.filterProfile} />
                                            </ContentRowWrapper>
                                            <ContentRowWrapper>
                                                <DataItem
                                                    domID="reportProfile-id"
                                                    label="Report Profile:&nbsp;&nbsp;"
                                                />
                                                <DataItem content={this.props.dataStore.ui.reportProfile} />
                                            </ContentRowWrapper>
                                            <ContentRowWrapper>
                                                <DataItem
                                                    domID="userProfile-id"
                                                    label="User Profile:&nbsp;&nbsp;"
                                                />
                                                <DataItem content={this.props.dataStore.ui.userProfile} />
                                            </ContentRowWrapper>
                                        </ContentColumnWrapper>
                                    )
                            }
                        </DialogFieldset>
                        <ContentRowWrapper style={{ paddingLeft: '5px' }}>
                            <SelectDropdown
                                    domID="select-userRole-id"
                                    className="dropdown"
                                    label="User Role:"
                                    size='small'
                                    isClearable={false}
                                    isSearchable={false}
                                    onChange={(e: any) => this.onUserRoleChange(e.value)}
                                    options={this.getUserRoles((!this.props.dataStore.crud.data ||
                                        !this.props.dataStore.crud.data.UserMaintenanceInfo
                                        ? null : this.props.dataStore.crud.data.UserMaintenanceInfo.UserRoles.UserRole))}
                                    initialValue={this.getSelectedUserRole(
                                        (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.UserMaintenanceInfo
                                            ? null : this.props.dataStore.crud.data.UserMaintenanceInfo.UserRoles.UserRole),
                                        this.props.dataStore.ui.userRoleId)}
                                    disabled={selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                    menuWidth={321}
                            />
                        </ContentRowWrapper>

                        {
                            (this.props.canViewClientSet || this.props.canEditClientSet) ? (
                                <ContentRowWrapper style={{ paddingLeft: '5px' }}>
                                    <SelectDropdown
                                        domID="select-clientSet-id"
                                        className="dropdown"
                                        label="Client Set:"
                                        size='small'
                                        options={this.getClientSet((!this.props.dataStore.crud.data ||
                                            !this.props.dataStore.crud.data.UserMaintenanceInfo
                                            ? null : this.props.dataStore.crud.data.UserMaintenanceInfo.ClientSets.ClientSet)) as SelectDropdownValue[]}
                                        isClearable={false}
                                        isSearchable={false}
                                        onChange={(e: any) => this.onClientSetChange(e.value)}
                                        initialValue={this.getSelectedClientSet(
                                            (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.UserMaintenanceInfo
                                                ? null : this.props.dataStore.crud.data.UserMaintenanceInfo.ClientSets.ClientSet),
                                            this.props.dataStore.ui.clientSet)}
                                            disabled={this.isClientSetEnabled() || selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                            menuWidth={321}
                                    />
                                </ContentRowWrapper>
                            ) : ""
                        }
                        {
                            (canBetaPreview) ? (
                                <ContentRowWrapper style={{ paddingLeft: '5px' }}>
                                    <SelectDropdown
                                        domID="select-featurePreview-id"
                                        className="dropdown"
                                        label="Feature Preview:"
                                        size='small'
                                        isClearable={false}
                                        isSearchable={false}
                                        options={this.getFeaturePreviews()}
                                        onChange={(e: any) => this.onFeaturePreviewChange(e.value)}
                                        initialValue={this.getSelectedFeaturePreview((!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.UserMaintenanceInfo
                                            ? null : this.props.dataStore.crud.data.UserMaintenanceInfo.Users.User),
                                            this.props.dataStore.ui.featurePreview)}
                                            disabled={!this.props.canBetaPreview || selectedItems.length > 1 || this.props.dataStore.ui.userInCiamJob}
                                            menuWidth={321}
                                    />
                                </ContentRowWrapper>
                            ) : ""
                        }
                    </SelectActions2>
                </ContentWrapper2>
                    
                </ContentWrapper>
                <ModalConfirmation
                        isOpen = {this.props.dataStore.confirm.isOpen}
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleConfirmation(e)}
                        formattedMessage={(
                            <div>
                                <p>Do you want to remove the user - <b>{this.props.dataStore.ui.loginName}</b>?</p>
                                <p>Deleting the user will remove the user name from the following lists:</p>
                                <p>* Favorite filters (User and Manager assigned)</p>
                                <p>* All Claim Assignments</p>
                                <p>* All Restrict User Access Profiles</p>
                                <p>* All User Preferences</p>
                                <p>This action cannot be undone</p>
                            </div>)
                        }
                        message = {this.props.dataStore.confirm.message}
                        onConfirm = {(e: React.MouseEvent<HTMLButtonElement>) => this.onRemoveUser(e)}
                />
                <ModalConfirmation
                    title={""}
                    isOpen={this.state.isCiamUnlinkModalOpen}
                    alertMode={false}
                    onModalToggle={() => { this.setState({ isCiamUnlinkModalOpen: false, ciamUnlinkModalMessage: '' }) }}
                    onConfirm={(e) => this.onCiamUnlinkModalConfirmation(e)}
                    formattedMessage={(
                        <div>
                            {this.state.ciamUnlinkModalMessage.split('.').map((ele:any) => 
                                <p>{ele}</p>)}
                        </div>)}
                    />
                <ModalConfirmation
                    title={""}
                    isOpen={this.state.isCiamLinkModalOpen}
                    alertMode={false}
                    onModalToggle={() => { this.setState({ isCiamLinkModalOpen: false, ciamLinkModalMessage: '' }) }}
                    onConfirm={(e) => this.onCiamLinkModalConfirmation(e)}
                    formattedMessage={(
                        <div>
                            {this.state.ciamLinkModalMessage.split('.').map((ele:any) => 
                                <p>{ele}</p>)}
                        </div>)}
                />
                <ModalConfirmation
                    title={""}
                    isOpen={this.props.dataStore.ui.ciamUserNameConfirmationModalOpen}
                    alertMode={false}
                    onModalToggle={(e) => this.onCiamUserNameModalToggle() }
                    onConfirm={(e) => this.onCiamUserNameModalConfirmation(e)}
                    formattedMessage={(
                        <div>
                            {this.props.dataStore.ui.ciamUserNameConfirmationModalText?.split('~').map((ele: any) => 
                                <p>{ele}</p>)}
                        </div>)}
                />
                <ModalConfirmation
                    isOpen={this.props.dataStore.ui.modalText != ""}
                    alertMode={true}
                    message={this.props.dataStore.ui.modalText}
                    onModalToggle={() => this.setAlert('')}
                />
            </DialogWrapper>
        );
    }
};

let connectedHoc = connect<IUserMaintenanceState, IUserMaintenanceActionProps, IOwnProps, IUserMaintenanceProps, ApplicationState>(
    createCrudMapStateToProps("userMaintenance"),   // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),   // Selects which action creators are merged into the component's props
    mergeCrudComponentProps
)(UserMaintenance);

export default withRouter(connectedHoc);
