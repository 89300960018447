import * as React from 'react';
import { SelectDropdown, Button } from '@optum-uicl/ui-core/dist';
import styled from 'styled-components';

export const DataSelectionWrapper = styled.div`
                display: flex;
                justify-content: space-between;
                align-items: end;
                padding-right: 2em;
                margin-bottom: 2em;
`;
export const SelectDropDownWrapper = styled.div`
    width: 30%
`

interface IComponentState {

};

export const DEFAULT_STATE: IComponentState = {

}

interface IComponentProps {
    groupList: any[];
    selectedGroup: object | undefined;
    selectedView: object | undefined;
    onExportToCsv: Function | undefined;
    onSelectGroup: Function | undefined;
    onSelectView: Function | undefined;
    enabled?: boolean;
};

export class AssignedClaimsDataSelection extends React.Component<IComponentProps, IComponentState> {


    public render() {
        const { enabled } = this.props
        const isEnabled = enabled === undefined ? true : enabled;

        const selectViewOptions = [
            { label: 'Charts', value: 'charts' },
            { label: 'Lists', value: 'lists' }
        ]

        return (
            <DataSelectionWrapper>
                <SelectDropDownWrapper>
                    <SelectDropdown
                        domID="triggerevent-dropdown-selectGrp"
                        className="dropdown"
                        label="Select Group:"
                        isClearable={false}
                        size='large'
                        onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => { if (this.props.onSelectGroup) this.props.onSelectGroup(e) }}
                        options={this.props.groupList}
                        disabled={!isEnabled}
                        initialValue={this.props.selectedGroup}
                        menuWidth={289}
                    />
                </SelectDropDownWrapper>
                <SelectDropDownWrapper>
                    <SelectDropdown
                        domID="triggerevent-dropdown-selectView"
                        className="dropdown"
                        label="Select View:"
                        isClearable={false}
                        size='large'
                        onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => { if (this.props.onSelectView) this.props.onSelectView(e) }}
                        options={selectViewOptions}
                        disabled={!isEnabled}
                        initialValue={this.props.selectedView}
                        menuWidth={289}
                    />
                </SelectDropDownWrapper>
                <Button
                    domID="AssignedClaims_ExportToCsv"
                    name="Export to CSV"
                    buttonType="standard"
                    size="small"
                    type="button"
                    className="previewButton"
                    disabled={!isEnabled}
                    onClick={() => { if (this.props.onExportToCsv) this.props.onExportToCsv() }}
                ></Button>
            </DataSelectionWrapper>
        )
    }

}
