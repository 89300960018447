import * as React from 'react';
import styled, { css } from "styled-components";
//import { } from 'styled-components/cssprop'; // for typescript
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindAll, delay } from 'lodash';
import { Button } from '@optum-uicl/ui-core/dist';

import { OKCancelButtons, DialogWrapper, SmallerTextColumn, ContentRowFloatLeft, ContentRowFloatRight, ContentRowJustify, ContentColumnWrapper } from '@common/DialogWrapper';
import CrudTypes from '@commonResources/CrudTypes';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { IFormModalState, defaultFormModalState } from '../../../store/ui/BaseCrudUI';
import { ApplicationState } from '@store/index';
import { IPhysicianMnLetterUIState, IPhysicianMnLetterActionProps, actionCreators, validationCallback } from '@store/PhysicianMnLetter';
import { IModifyPhysicianMnLetter } from '../../../store/ui/PhysicianMnLetterUI';
import { Input } from '@common/UICLWrappers/ARMInput';
import { RightAligned, SizedContainer, UnderlinedHeading } from '../../common/component-styles';
import { TextArea } from '@common/UICLWrappers/ARMTextArea';
import { pdfRouteBase, pdfWindowOptions } from '@commonResources/constants';
import { getHTMLContents } from '@common/ArmHtmlPopup';
export const ButtonWrapper=styled.div`
    button{
        width: 110px;
    }
`;

interface IComponentProps {
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IPhysicianMnLetterProps = IMergeCrudComponentProps<IPhysicianMnLetterUIState, IPhysicianMnLetterActionProps, IOwnProps>;

const Div60 = styled.div`
    width: 60%;
`;
const DivInline = styled.div`
    display: inline-block;
`;


const Input100Percent = styled(Input)`width: 100%;`;

export class PhysicianMnLetter extends React.PureComponent<IPhysicianMnLetterProps, IFormModalState> {
    // define only once, instead of on every render
    private instructions = <React.Fragment>Enter contact information, a greeting, and a custom body. These items will be part of each letter.<br />(Items containing Xs will be read from claim data.)</React.Fragment>;

    public componentDidMount() {
        this.props.action.crud.get({ crudId: CrudTypes.mctiPhysMNCoverLetter }, (result: MCPhysicianMnLetter) => {
            if (validationCallback(result)) {
                this.props.action.ui.initalizeOptions({
                    masterCrud: result,
                    uiData: result.PhysMNLetterMaintenanceInfo.PhysMNLetter,
                });
                return true;
            }
        });
    }

    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    // for some reason manual bind and bindAll did not work with these functions, so using alternate syntax
    public onDialogOk = () => {
        this.setState({ navigationConfirmed: true, saveOnNavigate: true }, () => {
            //allow any pending saves on blur to happen
            delay(() => {
                if (this.props.dataStore.ui) {
                    this.props.action.crud.update(this.props.dataStore.crud);
                }
                this.props.history.push("/LandingPage");
            },
                5);
        });
    }

    public onDialogCancel = (e: React.ChangeEvent<HTMLButtonElement>) => {
        this.props.history.push('/LandingPage');
    }

    private createModifyActionObject(data: string): IModifyPhysicianMnLetter {
        return {
            masterCrud: this.props.dataStore.crud.data,
            uiData: data?.trim() || '',
        }
    }
    public onContactNameChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editContactName(this.createModifyActionObject(e.target.value));
    }

    public onContactNumChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editContactNum(this.createModifyActionObject(e.target.value));
    }

    public onContactFaxChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editContactFax(this.createModifyActionObject(e.target.value));
    }

    public onSubjectChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editSubject(this.createModifyActionObject(e.target.value));
    }

    public onCommentsLeave(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editComments(this.createModifyActionObject(e.target.value));
    }

    public onPreview() {
        if (this.props.dataStore.crud.data) {
            /*  If I wait to open the window until I have the path, the window no longer
                looks like it's the result of user interaction. So we open the window first.
                Once we get the path, we navigate there. If we don't get the path, we use
                the open window to tell the user about the error so we don't have a blank, 
                useless window.
            */
            const newWin = window.open('', 'Preview', pdfWindowOptions);
            newWin?.document.write(getHTMLContents('Preview', 'Loading...'));
            this.props.action.ui.previewPdf(this.props.dataStore.crud.data.PhysMNLetterMaintenanceInfo.PhysMNLetter,
                (result: IPreviewReportResponse) => {
                    if (!result.error) {
                        newWin?.location.assign(pdfRouteBase + result.path);
                    } else {
                        if (newWin) {
                            newWin.document.open();
                            newWin.document.write(getHTMLContents('Error', `<h3>${result.error}</h3>`));
                            newWin.document.close();
                        };
                    }
                    return true;
                }
            );
        }
    }

    // end UI gesture handlersexport

    private okCancelButtons = <OKCancelButtons onClickOK={this.onDialogOk} onClickCancel={this.onDialogCancel} />;


    constructor(props: IPhysicianMnLetterProps) {
        super(props);
        this.state = defaultFormModalState;
        bindAll(this, ['createModifyActionObject', 'onContactNameChange', 'onContactNumChange', 'onContactFaxChange', 'onSubjectChange', 'onCommentsLeave', 'onPreview']);
    }

    public render() {
        const { errors, contactName, contactNum, contactFax, subject, comments, isBusy } = this.props.dataStore.ui;
        return (
            <DialogWrapper title='Physician Medical Necessity Letter'
                instruction={this.instructions}
                helpUrl='/Support/Help/HELP_Reports_MNLetter.htm'
                buttons={this.okCancelButtons}
                isBusy={isBusy}
            >
                <ContentColumnWrapper>
                    <SmallerTextColumn>
                        <ContentRowFloatRight>
                            <DivInline>Date: XX/XX/XXXX</DivInline>
                        </ContentRowFloatRight>
                        <ContentRowFloatLeft>
                            <Div60>To: XXXXXXXXXXXX</Div60>
                            <div style={{ whiteSpace: 'nowrap' }}>Physician Fax: XXX-XXX-XXXX</div>
                        </ContentRowFloatLeft>
                        <ContentRowJustify>
                            <SizedContainer pixelWidth='300'>
                                <Input
                                    label='FROM:'
                                    domID='pmn_contactName_input'
                                    className='text-input'
                                    size='small'
                                    initialValue={contactName}
                                    maxLength={40}
                                    onBlur={this.onContactNameChange} />
                            </SizedContainer>
                            <SizedContainer pixelWidth='195'>
                                <Input
                                    label='PHONE:'
                                    domID='pmn_contactNum_input'
                                    className='text-input'
                                    size='small'
                                    initialValue={contactNum}
                                    maxLength={25}
                                    onBlur={this.onContactNumChange} />
                            </SizedContainer>
                            <SizedContainer pixelWidth='120'>
                                <Input
                                    label='FAX:'
                                    domID='pmn-contactFax-input'
                                    className='text-input'
                                    size='small'
                                    initialValue={contactFax}
                                    maxLength={15}
                                    onBlur={this.onContactFaxChange} />
                            </SizedContainer>
                        </ContentRowJustify>
                        <ContentRowJustify>
                            <Input100Percent
                                domID='pmn-subject-input'
                                className='text-input'
                                size='small'
                                initialValue={subject}
                                maxLength={128}
                                onBlur={this.onSubjectChange} />
                        </ContentRowJustify>
                        <ContentColumnWrapper>
                            <UnderlinedHeading>Encounter Information</UnderlinedHeading>
                            <ContentRowJustify>
                                <div>
                                    Physician
                                <br />
                                XXXXXXXXXXXX
                            </div>
                                <div>
                                    Patient Name
                                <br />
                                XXXXXX, XXXXXX
                            </div>
                                <div>
                                    Service Date
                                <br />
                                XX/XX/XXXX - XX/XX/XXXX
                            </div>
                                <div>
                                    Patient Acct No
                                <br />
                                XXXXXXXXXXXX
                            </div>
                                <div>
                                    Med Rec No
                                <br />
                                XXXXXXXXXX
                            </div>
                            </ContentRowJustify>
                        </ContentColumnWrapper>
                        <ContentColumnWrapper>
                            <UnderlinedHeading>
                                Conflict Information
                        </UnderlinedHeading>
                            <ContentRowFloatLeft>
                                <div>
                                    <SizedContainer pixelWidth='350'>
                                        Procedure: XXXXXX
                                </SizedContainer>
                                    <div>Diagnosis: XXXXXX</div>
                                    <div>Occurrence: XXXXXXXXXXXXXXXXX</div>
                                </div>
                                <div>
                                    <div>
                                        Description: XXXXXXXXXXXX
                                </div>
                                    <div>
                                        Description: XXXXXXXXXXXX
                                </div>
                                </div>
                            </ContentRowFloatLeft>
                        </ContentColumnWrapper>
                        <ContentRowFloatRight>
                            <div>
                                <RightAligned>
                                    ABN Amount: XXX.XX
                            </RightAligned>
                                <RightAligned>
                                    Claim Charges: XXX.XX
                            </RightAligned>
                            </div>
                        </ContentRowFloatRight>
                        <ContentRowFloatLeft>
                            <SizedContainer as={TextArea}
                                domID="pmn_comments_text"
                                pixelWidth='605'
                                maxLength={2048}
                                onBlur={this.onCommentsLeave}
                                initialValue={comments}
                                hasError={!!errors?.incorrectCommentLength}
                                errorMessage={errors?.incorrectCommentLength}
                            />
                        </ContentRowFloatLeft>
                    </SmallerTextColumn>
                    <ContentRowFloatRight style={{ paddingTop: '10px' }}>
                        <ButtonWrapper>
                            <Button name='Preview' onClick={this.onPreview} size='control' />
                        </ButtonWrapper>
                    </ContentRowFloatRight>
                </ContentColumnWrapper>
            </DialogWrapper>
        );
    }
}

const connectedHoc = connect<IPhysicianMnLetterUIState,
    IPhysicianMnLetterActionProps,
    IOwnProps,
    IPhysicianMnLetterProps,
    ApplicationState>(
        createCrudMapStateToProps('physicianMnLetter'),
        createCrudMapDispatchToProps(actionCreators),
        mergeCrudComponentProps
    )(PhysicianMnLetter);

export default withRouter(connectedHoc);
