import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '@commonResources/colorVariables';
import { Typography } from '@commonResources/typography';
import { ContentRowWrapper } from '@common/DialogWrapper';
import { RadioButtonComponent } from '@common/RadioButton';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { ContentColumnWrapper } from '@common/DialogWrapper';

// For an example of how to implement this component see MedicalNecessityOptions.tsx

export interface IRadioButtonComponentProps {
    id: string;
    name?: string;
    checked: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    label?: string;
    labelBefore?: string;
    labelAbove?: string;
    width?: string;
    disabled: boolean;
    onDoubleClick?: any;
    className?: string;
    maxContent: string | undefined;
};

export interface IRadioButtonComponentState {
    currentIndex: number;
};

export interface IRadioButtonWrapperProps {
    widthValue?: string;
    className?: string;
    onDoubleClick?: any;
};

interface IRadioButtonLabelProps {
    id?: string;
    disabled?: boolean;
};
//---------------------------------------------------------------

interface IRadioListComponentProps {
    width: string | undefined;
    selectedIndex: number;
    radioListItems: RadioButtonList;
    dialogTitle?: string;
    hasDialog?: boolean | undefined;
    setRadioSelectedState: (index: number) => void; 
    children?: React.ReactNode | React.ReactElement,
}


interface RadioButtonData {
    domID: string;
    disabled: boolean;
    index: number;
    label: string;
}

interface RadioButtonList extends Array<RadioButtonData> { }




export const RadioButtonWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;

    flex-direction: row;
    height: 20px;
    xxmargin: 0 auto;
    min-width: 20px; 
 

    ${(props: IRadioButtonWrapperProps) => props.widthValue && `width: ${props.widthValue}`};
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    .has-error span{
    width:240px !important    
}`

export const RadioButtonLabel = styled.label`
    padding-left:3px;
    padding-top: 2px;
    font-size: 10pt;
    ${Typography.ARMFontFamily};
    ${Typography.bold};
    ${(props: IRadioButtonComponentProps) => props.maxContent && `width: ${props.maxContent}`};
    ${(props: IRadioButtonLabelProps) => props.disabled ? `color: ${Colors.grey50}` : `color: ${Colors.grey100}`};
`;





export const RadioButtonLabelBefore = styled.label`
    padding-left:3px;
    padding-top: 2px;
    
    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.ARMFontFamily};
    ${Typography.bold};
    
`;


const RadioButtonLabelAbove = styled.label`
    padding-left: 2px;
    padding-top: 2px;

    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.ARMFontFamily};
    ${Typography.bold};
`;

export const RadioButtonInput = styled.input`
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 15px;
    width: 15px;
    padding-right: 5px;
	border: 1;
    border-radius: 50px;
    outline: none;
    
    :before {
         content: "";
         position: absolute;
         border-radius: 14px;
         top: 0;
         left: 0;
         background-color: white;
		}
    :checked:after {
         content: "";
         border-radius: 5px;
         border-width: 0 2px 2px 0;
         -webkit-transform: rotate(45deg);
         -ms-transform: rotate(45deg);
         transform: rotate(45deg);
         position: absolute;
         top: 2px;
         left: 5px;
	}
`;


export class RadioListComponent extends React.Component<IRadioListComponentProps, IRadioButtonComponentState>
{

    constructor(props: IRadioListComponentProps) {
        super(props);
        this.state = {
            currentIndex: 0
        }
    }


    public renderItems() {
        const { radioListItems, hasDialog } = this.props;
        const currentItem = radioListItems[this.state.currentIndex];
        return (
            <ContentColumnWrapper>
                <DialogFieldset style={{ visibility: hasDialog ? 'visible' : 'hidden' }} width={this.props.width} >
                    {hasDialog ? < DialogLegend > {this.props.dialogTitle}</DialogLegend> : null}
                    <ContentRowWrapper style={{ visibility: 'visible', marginBottom: '5px'}} >
                    {radioListItems.map((item: RadioButtonData, index: number) => {
                        return (
                            <RadioButtonComponent
                                width={'initial'}
                                id={item.domID}
                                key={item.label}
                                label={item.label}
                                checked={this.props.selectedIndex === item.index}
                                onChange={() => this.props.setRadioSelectedState(item.index)}
                                disabled={item.disabled}
                            />
                        )
                    })}
                    </ContentRowWrapper>
                </DialogFieldset>
                {this.props.children}
            </ContentColumnWrapper>
        )


    }



    render() {
        return (
            <div>
                    {this.renderItems()}
            </div>
        )
    }
}
