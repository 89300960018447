import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '@commonResources/typography';
import { ModalComponentWrapper } from './ModalComponentWrapper';


const MessageWrapper = styled.div`
    ${Typography.ARMFontFamily};
    ${Typography.normal};
    ${Typography.default};
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-height: 900px) {
        max-height: 590px; /* Adjusted max height for zoomed-in view */
    }

    @media screen and (max-height: 800px) {
        max-height: 490px; /* Adjusted max height for zoomed-in view */
    }
    
    @media screen and (max-height: 700px) {
        max-height: 350px; /* Adjusted max height for zoomed-in view */
    }

    @media screen and (max-height: 600px) {
        max-height: 280px; /* Adjusted max height for zoomed-in view */
    }

    @media screen and (max-height: 500px) {
        max-height: 200px; /* Further adjusted max height for more zoomed-in view */
    }
`;

export interface IModalConfirmationProps {
    isOpen: boolean;
    showDefaultClose: boolean;
    onModalToggle: React.MouseEventHandler<HTMLElement>;
    title?: string;
    message: string;
    formattedMessage?: any;
    alertMode?: boolean;
    onConfirm?: React.MouseEventHandler<HTMLButtonElement>;
    onDeny?: React.MouseEventHandler<HTMLButtonElement>;
    onOK?: React.MouseEventHandler<HTMLButtonElement>;
    okText?: string;
    cancelText?: string;
    component?: JSX.Element;
};

interface IComponentState {
    visible: boolean;
}

export const leaveMessage = (
    <div style={{ textAlign: 'center' }}>
        <b>Are you sure you want to leave this page?</b> <br />
        <p> Your changes will NOT be saved.</p>
    </div>
);

// Extracting out the guts of this to the more generic class ModalComponentWrapperProps. That allows us
// to wrap non-text components in the same wrapper as confirmation windows.
export class ModalConfirmation extends React.Component<IModalConfirmationProps, IComponentState> {

    static defaultProps: IModalConfirmationProps = {
        isOpen: true,
        showDefaultClose: false,
        onModalToggle: (e: React.MouseEvent<HTMLElement>) => false,
        title: '',
        message: '',
        alertMode: false,
        okText: "OK",
        cancelText: "Cancel",
    };

    constructor(props: IModalConfirmationProps) {
        super(props);

        this.state = {
            visible: true,
        };
    }

    render() {
        return (
            <ModalComponentWrapper
                isOpen={this.props.isOpen}
                showDefaultClose={this.props.showDefaultClose}
                onModalToggle={this.props.onModalToggle}
                title={this.props.title}
                alertMode={this.props.alertMode}
                onConfirm={this.props.onConfirm}
                onDeny={this.props.onDeny}
                onOK={this.props.onOK}
                okText={this.props.okText}
                cancelText={this.props.cancelText}
                component={
                    //set style for message wrapper if message is empty
                    <MessageWrapper style={{
                        marginTop: this.props.message === '' ? "-53px" : "",
                    }}>
                        {this.props.message}
                        <div style={{ marginBottom: "20px" }}>{this.props.formattedMessage ? this.props.formattedMessage : ""}</div>
                        {this.props.component && this.props.component}
                    </MessageWrapper>
                }
            />
        );
    }
}
