import * as React from 'react';
import { Input, Button, Help, SelectDropdown, } from '@optum-uicl/ui-core/dist';
import styled from 'styled-components';
import { uniqueId } from 'lodash';
import { ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { DialogLegend, DialogFieldset } from '@common/DialogStyles';
import { AddFacilityModal } from './AddFacilityModal';
import { CheckBoxComponent } from '@common/CheckBox';
import { IImageButtonImage, ImageButton } from '../../common/ImageButton';
import { DialogLabel } from '../FileType/FileType';
import * as wnd from '@commonResources/window';
import { Facilitywrapper, Helpwrapper, RowWrapper, CheckboxWrapper, CheckBoxContainer } from './PhysicianStyles';
import { PmDropdownOption, Lob, DEFAULT_LOB, PmPayerDropdownOption, PmFacilityDropdownOption, getNewPayerDisplayName } from '@store/ui/PhysicianMaintenanceUI';
import { IsAlphaNumeric, IsFieldFilled } from '@commonResources/validations';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { FieldError } from './PhysicianPayersInputs'; 
import { Select } from '../../common/FunctionComponents';
import { SelectDropdownValue } from '@optum-uicl/ui-core/dist/Atoms/SelectDropdown/types';

//-----------------
// PAGE NOTES
// This component collects all the physician 
//-----------------
//STYLES - Put custom style elements up here.
// e.g.
//const Spacer = styled.div`
//    height: 0.25rem;
//
const StyleName = styled.div`
    
`;

// ReSharper disable IdentifierTypo

// ReSharper restore IdentifierTypo

//-----------------
// OBJECT DEFINITIONS - Put any interface or small class definitions here.

interface CurrentControlErrors {
    keyNoError?: string;
    lobError?: string;
    physicianNoError?: string;
    facilityError?: string;
    descriptionError?: string;
    npiError?: string;
    taxonomyError?: string;
}

const DEFAULT_CONTROL_ERRORS = {}

interface Result<T> {
    success: boolean,
    value: T,
}

//-----------------
// STATE AND PROPS - Define the local component state and any component props here.
interface IComponentState {
    selectedPayer: Lob;
    errors: CurrentControlErrors; //TODO: now both state and props have errors props of different types
    addFacilityIsOpen: boolean;
    errorAddFacilityIsOpen: boolean;
    addFacilityErrorMessage?: string;
}

interface IComponentProps {
    currentPayer: Lob;
    lobOptions: PmDropdownOption[];
    facilityOptions: PmFacilityDropdownOption[];
    specialtyCodeOptions: PmDropdownOption[];
    helpIcon: IImageButtonImage;
    addIcon: IImageButtonImage;
    addPayers(newPayers: Lob[]): void;
    removePotentialDuplicates(newPayer: Lob): PmFacilityDropdownOption[];
    onChange?: (payer:Lob) => void;
    errors: FieldError[];
    showErrors: boolean;
    getLatestFieldErrors: () => FieldError[];
    canAddMultiPayer: boolean;
}

//-----------------
// DEFAULTS - Define any default object values (including the state) here.
export const DEFAULT_STATE: IComponentState = {
    selectedPayer: DEFAULT_LOB,
    errors: DEFAULT_CONTROL_ERRORS,
    addFacilityIsOpen: false,
    errorAddFacilityIsOpen: false,
};

type ChangeEvent = React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>;
type ControlId = "keyNo" | "physicianNo" | "facilityId" | "payerId" | "lobId" | "specialtyCodeId" | "description"
    | "setToDefault" | "npi" | "taxonomy" | "suppressCCIAI" | "suppressCCIBI" | "suppressCCIBP"
    | "suppressMNAI" | "suppressMNBI" | "suppressMNBP";

type Validation = {
    check: Function,
    failureMessage: string,
}

type ControlValidation = {
    id: ControlId,
    validations: Validation[],
}

const isFieldFilled: Validation = {
    check: IsFieldFilled,
    failureMessage: " must not be blank"
}

const isAlphaNumeric: Validation = {
    check: IsAlphaNumeric,
    failureMessage: " must consist of alphanumeric characters."
}

const checkPayerFields: Function = (payer: Lob, errors: FieldError[]): Result<string> => {
    const fieldsNeededForFacility = ["keyNo", "physicianNo", "description", "npi", "taxonomy", "lobId"];
    const invalidField = errors.find((error:FieldError) => fieldsNeededForFacility.indexOf(error.id) > -1);
    if (invalidField) return {success: false, value: invalidField.failureMessage};
    return { success: true, value: '' };
};

export class PhysicianPayerDetails extends React.Component<IComponentProps, IComponentState>
{
    state = {
        ...DEFAULT_STATE,
        selectedPayer: { ...this.props.currentPayer }
    };

    constructor(props: IComponentProps) {
        super(props);
        this.state = {
            ...DEFAULT_STATE, selectedPayer: { ...this.props.currentPayer }
        };
        this.updateField = this.updateField.bind(this);
        this.updatePhysicianNo = this.updatePhysicianNo.bind(this);
        this.updateKeyNo = this.updateKeyNo.bind(this);
        this.updateDescription = this.updateDescription.bind(this);
        this.updateLobId = this.updateLobId.bind(this);
        this.updateFacilityId = this.updateFacilityId.bind(this);
        this.updatePayerId = this.updatePayerId.bind(this);
        this.updateSpecialtyCodeId = this.updateSpecialtyCodeId.bind(this);
        this.updateSetToDefault = this.updateSetToDefault.bind(this);
        this.updateNpi = this.updateNpi.bind(this);
        this.updateTaxonomy = this.updateTaxonomy.bind(this);
        this.updateSuppressCCIAI = this.updateSuppressCCIAI.bind(this);
        this.updateSuppressCCIBP = this.updateSuppressCCIBP.bind(this);
        this.updateSuppressMNAI = this.updateSuppressMNAI.bind(this);
        this.updateSuppressMNBP = this.updateSuppressMNBP.bind(this);
        this.openTaxonomyHelp = this.openTaxonomyHelp.bind(this);
        this.facilityHelp = this.facilityHelp.bind(this);
        this.openPayerNpiHelp = this.openPayerNpiHelp.bind(this);
        this.openAddFacility = this.openAddFacility.bind(this);
        this.getErrorForControl = this.getErrorForControl.bind(this);
    }

    //Help Window Methods
    private openTaxonomyHelp() {
        wnd.BigHelp('/Support/Help/HELP_TaxonomyCodes.htm');
    }

    private facilityHelp(e: any) {
        //this.setState({ selectedPayer.facilityId: {} });
        //this.props.action.ui.showPopup();
    }

    private openPayerNpiHelp() {
        wnd.BigHelp('/Support/Help/HELP_PayerNPI.htm');
    }

    private openAddFacility() {
        const checkCurrentPayerResult = checkPayerFields(this.state.selectedPayer, this.props.getLatestFieldErrors());
        if (checkCurrentPayerResult.success) {
            this.setState({
                addFacilityIsOpen: true,
            });
        }
        // If validation failed, display the error message to the user in the error modal.
        else {
            this.setState({
                errorAddFacilityIsOpen: true,
                addFacilityErrorMessage: checkCurrentPayerResult.value,
            });
        }
    }

    // Necessary functions in order to bind methods in constructor for better performance.
    private updateKeyNo(e: ChangeEvent) { this.updateField(e.target.value, "keyNo"); }

    private updatePhysicianNo(e: ChangeEvent) { this.updateField(e.target.value, "physicianNo"); }

    private updateDescription(e: ChangeEvent) { this.updateField(e.target.value, "description"); }

    private updatePayerId(e: SelectDropdownValue) { this.updateField(e.value ?? "", "payerId") }

    private updateLobId(e: SelectDropdownValue) { this.updateField(e.value ?? "", "lobId"); }

    private updateFacilityId(e: SelectDropdownValue) { this.updateField(e.value ?? "", "facilityId"); }

    private updateSpecialtyCodeId(e: SelectDropdownValue) { this.updateField(e.value ?? "", "specialtyCodeId"); }

    private updateSetToDefault(e: React.ChangeEvent<HTMLInputElement>) {
        const checkbox = e.currentTarget;
        this.updateField(checkbox.checked ? 'Y': 'N', "setToDefault");
    }

    private updateNpi(e: ChangeEvent) { this.updateField(e.target.value, "npi"); }

    private updateTaxonomy(e: ChangeEvent) { this.updateField(e.target.value, "taxonomy"); }

    private getCheckBoxValue(checkbox: HTMLInputElement): 'Y' | 'N' {
        return checkbox.checked ? 'Y' : 'N';
    }

    private updateSuppressCCIAI(e: React.ChangeEvent<HTMLInputElement>) {
        this.updateField(this.getCheckBoxValue(e.currentTarget), "suppressCCIAI"); 
    }

    private updateSuppressCCIBI(e: ChangeEvent) { this.updateField(e.target.value, "suppressCCIBI"); }

    private updateSuppressCCIBP(e: React.ChangeEvent<HTMLInputElement>) { this.updateField(this.getCheckBoxValue(e.currentTarget), "suppressCCIBP"); }

    private updateSuppressMNAI(e: React.ChangeEvent<HTMLInputElement>) { 
        this.updateField(this.getCheckBoxValue(e.currentTarget), "suppressMNAI"); 
    }

    private updateSuppressMNBI(e: ChangeEvent) { this.updateField(e.target.value, "suppressMNBI"); }

    private updateSuppressMNBP(e: React.ChangeEvent<HTMLInputElement>) {
         this.updateField(this.getCheckBoxValue(e.currentTarget), "suppressMNBP");
    }

    /**
     * Updates the appropriate field in the selectedPayer object in the current state.
     * @param e The change event containing the new value.
     * @param id The control id that matches the name of the property being updated.
     */
    private updateField(newValue: string, id: ControlId) {
        const checkForUpperCase = ["keyNo", "physicianNo", "description", "npi", "taxonomy"];
        if (checkForUpperCase.indexOf(id) > -1) newValue = newValue.toUpperCase();
        //const newValue = e?.target?.value ?? e?.value;
        const newPayer = {
                        ...this.state.selectedPayer,
                        [id as string]: newValue,
                    };
        if (!newPayer.id) {newPayer.isNew = true}
        newPayer.displayName = this.getPayerDisplayName(newPayer);
        switch (id) {
            // derived values
            case 'payerId':
            case 'facilityId':
                const facilityOption = this.props.facilityOptions.find(option => option.value === newPayer.facilityId);
                if (facilityOption) {
                    const payerOption = facilityOption.payerDropdowns.find(option => option.value === newPayer.payerId);
                    if (payerOption) {
                        newPayer.accountStatus = payerOption.facilityPayerStatus;
                        newPayer.enrollmentTypeId = payerOption.enrollmentTypeId;
                        break;
                    }
                }
                newPayer.accountStatus = 'T';
                newPayer.enrollmentTypeId = '';
        }
        this.setState
            (
                {
                    selectedPayer: {
                        ...newPayer,
                    }
                },
                () => {this.props.onChange && this.props.onChange(this.state.selectedPayer)}
            );
    }

    /**
     * Given a list of facility IDs, generates a list of payers based on the currently selected payer.
     * @param facilityIds
     */
    private lookupMultiAddDetails(facilityIds: string[]): Lob[] {
        // moving this outside the loop lowers the cyclomatic complexity of this fn
        const lobName = this.props.lobOptions.find(l => l.value === this.state.selectedPayer.lobId)?.label ?? '';
        const newPayers = facilityIds.map((id) => {
            // no point in using getPayerDisplayName because we still need description
            const description: string = this.props.facilityOptions.find(fac => fac.value === id)?.label ?? '';
            const name =
                `${lobName} - ${description} - ${this.state.selectedPayer.physicianNo}`;
            const newInternalId: string = `New[${uniqueId()}]LOB`;
            return {
                ...this.state.selectedPayer,
                description: description,
                facilityId: id,
                displayName: name,
                id: newInternalId,
                isNew: true,
            }
        });

        return newPayers;
    }

    private getPayerDisplayName(payer: Lob):string {
        return getNewPayerDisplayName(payer, this.props.lobOptions);
    }

    /**
     * Get the list of options for the Payer Dropdown Box.
     */
    private getPayerDropdownOptions(): Partial<PmPayerDropdownOption>[] {
        let payerOptions: Partial<PmPayerDropdownOption>[] = [{ value: "0", label: "- Select Payer -", lobId: 0 }];

        const selectedFacility: PmFacilityDropdownOption | undefined =
            this.props.facilityOptions.find(x => x.value === this.state.selectedPayer.facilityId);


        if (selectedFacility) {
            payerOptions = payerOptions.concat(selectedFacility.payerDropdowns.filter(option => option.lobId === parseInt(this.state.selectedPayer.lobId)));
        }
        return payerOptions;
    }

    private getErrorForControl(controlName: string): FieldError | undefined {
        return this.props.errors.find(error => error.id === controlName);
    }

    static getDerivedStateFromProps(props:IComponentProps, state: IComponentState) {
        const {errors, showErrors} = props;
        if (showErrors && errors.length) {
            const errs = {
                keyNoError: errors.find(error => error.id === 'keyNo')?.failureMessage,
                lobError: errors.find(error => error.id === 'lobId')?.failureMessage,
                physicianNoError: errors.find(error => error.id === 'physicianNo')?.failureMessage,
                facilityError: errors.find(error => error.id === 'facilityId')?.failureMessage,
                descriptionError: errors.find(error => error.id === 'description')?.failureMessage,
                npiError: errors.find(error => error.id === 'npi')?.failureMessage,
                taxonomyError: errors.find(error => error.id === 'taxonomy')?.failureMessage,
            };
            return { errors: errs };
        }
        return { errors: {} };
    }

    public render() {
        const payerOptions: Partial<PmPayerDropdownOption>[] = this.getPayerDropdownOptions();
        const specialityCodeOptions: PmDropdownOption[] = this.props.specialtyCodeOptions;
        
        /*  Note that we're using SelectDropdown, which requires a match in
            initialValue for the entire option object, unlike ARMSelect, which 
            only needs a match on the value.
         */
        const selectedFacility: PmFacilityDropdownOption = this.props.facilityOptions.find(x => this.state.selectedPayer.facilityId === x.value)
            ?? { value: "-1", label: "- Select Facility -", payerDropdowns: [], enrollmentTypeId: '' };
        const selectedPayerOption = payerOptions.find(option => option.value === this.state.selectedPayer.payerId) || payerOptions[0];
        const selectedSpecialtyCode = specialityCodeOptions.find(option => option.value === this.state.selectedPayer.specialtyCodeId) || specialityCodeOptions[0];
        const { errors, selectedPayer: {accountStatus}} = this.state;
        let enrollmentTypeId = '';
        if (selectedPayerOption.value !== '0') {
            enrollmentTypeId = selectedPayerOption.enrollmentTypeId || '';
        } else {
            // fall back to facility
            if (selectedFacility.value !== '-1') {
                enrollmentTypeId = selectedFacility.enrollmentTypeId ?? '1'; // hide it if we're not sure
            } else {
                enrollmentTypeId = '1'; // hide it if we don't have a facility selected
            }
        }
        const status = enrollmentTypeId === '1' ? '' : accountStatus === 'L'? 'Live' : 'Test';
        return (
            <ContentColumnWrapper>
                <ContentRowWrapper style={{ paddingTop: '5px' }}>
                    <RowWrapper >
                        <Input
                            autoComplete="off"
                            domID="keyno"
                            className="text-input"
                            label="*Key No:"
                            size="small"
                            maxLength={20}
                            hasError={Boolean(this.state.errors.keyNoError)}
                            errorMessage={this.state.errors?.keyNoError ?? ""}
                            onBlur={this.updateKeyNo}
                            initialValue={this.state.selectedPayer?.keyNo ?? ""} />
                    </RowWrapper>
                    <RowWrapper>
                        <Input
                            autoComplete="off"
                            domID="physican-no"
                            className="text-input"
                            label="*Physician No:"
                            size="small"
                            maxLength={15}
                            hasError={Boolean(this.state.errors.physicianNoError)}
                            errorMessage={this.state.errors.physicianNoError ?? ""}
                            onBlur={this.updatePhysicianNo}
                            initialValue={this.state.selectedPayer.physicianNo} />
                    </RowWrapper>
                    <RowWrapper>
                        <Input
                            autoComplete="off"
                            domID="payer-description"
                            className="text-input"
                            label="*Description:"
                            size="small"
                            maxLength={16}
                            hasError={Boolean(this.state.errors.descriptionError)}
                            errorMessage={this.state.errors.descriptionError ?? ""}
                            onBlur={this.updateDescription}
                            initialValue={this.state.selectedPayer.description}
                        />
                    </RowWrapper>
                    <RowWrapper>
                        <SelectDropdown
                            domID="pm-payer-lob-dropdown"
                            label="*LOB:"
                            size="small"
                            menuWidth={170}
                            hasError={!!errors.lobError}
                            errorMessage={errors.lobError ?? ''}
                            isClearable={false}
                            isSearchable={true}

                            onChange={this.updateLobId}
                            options={this.props.lobOptions}
                            initialValue={this.state.selectedPayer?.lobId
                                ? this.props.lobOptions[Number(this.state.selectedPayer.lobId)]
                                : this.props.lobOptions[0]} />
                    </RowWrapper>
                </ContentRowWrapper>
                <ContentRowWrapper>
             
                    <RowWrapper>
                        <SelectDropdown
                            domID="facility_Payer"
                            className="dropdown"
                            label="*Facility:"
                            size="small"
                            menuWidth={600}
                            isClearable={false}
                            isSearchable={true}
                            hasError={!!errors.facilityError}
                            errorMessage={errors.facilityError ?? ''}
                            options={this.props.facilityOptions}
                            onChange={this.updateFacilityId}
                            initialValue={selectedFacility}
                            disabled={false} />
                    </RowWrapper>

                    <RowWrapper>
                        <Facilitywrapper>
                            <ImageButton
                                Title={'AddIcon'}
                                image={this.props.addIcon}
                                onClick={this.openAddFacility}
                                disabled={!this.props.canAddMultiPayer}
                                tooltip={'Add Multiple Facilities'} />
                        </Facilitywrapper>
                    </RowWrapper>

                    <RowWrapper>
                        <SelectDropdown
                            domID="Phy_payer"
                            className="dropdown"
                            label="Payer:"
                            size="small"
                            menuWidth={300}
                            isClearable={false}
                            isSearchable={true}
                            options={payerOptions}
                            onChange={this.updatePayerId}
                            initialValue={selectedPayerOption}
                            key={selectedPayerOption?.value}
                            disabled={payerOptions.length < 2}
                        />
                    </RowWrapper>
                </ContentRowWrapper>
                <ContentRowWrapper>
             
                    <RowWrapper>
                        <Input
                            autoComplete="off"
                            domID="taxonomy"
                            className="text-input"
                            label="Taxonomy:"
                            size="small"
                            maxLength={10}
                            onBlur={this.updateTaxonomy}
                            initialValue={this.state.selectedPayer.taxonomy}
                            hasError={Boolean(this.state.errors.taxonomyError)}
                            errorMessage={this.state.errors.taxonomyError ?? ""} />
                    </RowWrapper>
                    <RowWrapper>
                        <Helpwrapper>
                            <ImageButton
                                Title={'HelpIcon'}
                                image={this.props.helpIcon}
                                helpIcon={true}
                                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.openTaxonomyHelp()} />

                        </Helpwrapper>
                    </RowWrapper>
                    <RowWrapper>
                        <SelectDropdown
                            domID="specialty-code"
                            className="dropdown"
                            label="Specialty Code:"
                            size="small"
                            menuWidth={205}
                            isClearable={false}
                            isSearchable={true}
                            options={this.props.specialtyCodeOptions}
                            onChange={this.updateSpecialtyCodeId}
                            initialValue={selectedSpecialtyCode}
                            disabled={false} />
                    </RowWrapper>
                    <RowWrapper>
                        <Input
                            autoComplete="off"
                            domID="npi1"
                            className="text-input"
                            label="NPI:"
                            size="small"
                            maxLength={10}
                            onBlur={this.updateNpi}
                            initialValue={this.state.selectedPayer.npi}
                            hasError={!!errors.npiError}
                            errorMessage={errors.npiError} />
                    </RowWrapper>
                    <RowWrapper>
                        <Helpwrapper>
                            <ImageButton
                                Title={'HelpIcon'}
                                helpIcon={true}
                                image={this.props.helpIcon}
                                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.openPayerNpiHelp()} />
                        </Helpwrapper>
                    </RowWrapper>
                        <div style={{ display: 'inline-flex', height: '55px', padding: '0px 0px'}}>
                            <div style={{ width: '155px' }}>
                            <RowWrapper>
                                    {!!status
                                        ? <DialogLabel id='account_status'>
                                            {`ACCOUNT STATUS : ${status}`}
                                        </DialogLabel>
                                    : <DialogLabel id='account_status_na'>{`ACCOUNT STATUS : `}</DialogLabel>}
                                <CheckboxWrapper>
                                    <CheckBoxComponent
                                        labelBefore='SET AS DEFAULT'
                                        width="155px"
                                        checked={this.state.selectedPayer.setToDefault === "Y"}
                                        onChange={this.updateSetToDefault} />
                                </CheckboxWrapper>
                             
                            </RowWrapper>
                        </div>

                    </div>
                    {this.state.selectedPayer.lobId === "1"
                        ? <ContentRowWrapper style={{ display: 'inline-flex', justifyContent: 'flex-end', marginRight: '10px' }}>
                            <CheckBoxContainer >
                                <DialogFieldset id={'pm_compliance_edit_supression'}  >
                                    <DialogLegend>Compliance Edit Suppression</DialogLegend>
                                    <div className="pm_checkbox_label_container">
                                        <label className="pm_checkbox_label label_left">CCI</label>
                                        <label className="pm_checkbox_label label_right">MN</label>
                                    </div>
                                    <ContentRowWrapper>
                                        <CheckBoxComponent
                                            id='pm_checkbox_cci_left'
                                            labelBefore='Suppress Professional'
                                            checked={this.state.selectedPayer.suppressCCIBP === 'Y'}
                                            onChange={this.updateSuppressCCIBP}
                                        />
                                        <CheckBoxComponent
                                            id='pm_checkbox_cci_right'
                                            checked={this.state.selectedPayer.suppressMNBP === 'Y'}
                                            onChange={this.updateSuppressMNBP}
                                        />
                                    </ContentRowWrapper>
                                    <ContentRowWrapper>
                                        <CheckBoxComponent
                                            id='pm_checkbox_mn_left'
                                            labelBefore='Suppress Institutional'
                                            disabled
                                        />
                                        <CheckBoxComponent
                                            id='pm_checkbox_mn_right'
                                            disabled
                                        />
                                    </ContentRowWrapper>
                                </DialogFieldset>
                            </CheckBoxContainer>
                        </ContentRowWrapper>
                        : null}
                </ContentRowWrapper>
              
                {this.state.addFacilityIsOpen
                    ? <AddFacilityModal
                        isOpen={this.state.addFacilityIsOpen}
                        onModalToggle={() => this.setState({ addFacilityIsOpen: !this.state.addFacilityIsOpen })}
                        facilitiesList={this.props.removePotentialDuplicates(this.state.selectedPayer)}
                        onConfirm={(newPayers: string[]) => {
                            if (newPayers.length < 1) {
                                this.setState({ addFacilityIsOpen: false });
                                return;
                            }
                            const payersToAdd = this.lookupMultiAddDetails(newPayers);
                            this.setState(
                                { addFacilityIsOpen: false },
                                () => this.props.addPayers(payersToAdd)
                            );
                        }}
                        onDeny={() => this.setState({addFacilityIsOpen: false})}
                    />
                    : null}
                {this.state.errorAddFacilityIsOpen
                    ? <ModalConfirmation
                        isOpen={this.state.errorAddFacilityIsOpen}
                        message={this.state.addFacilityErrorMessage}
                        onModalToggle={() => this.setState({ errorAddFacilityIsOpen: false, addFacilityErrorMessage: undefined})}
                        onOK={() => this.setState({ errorAddFacilityIsOpen: false, addFacilityErrorMessage: undefined })}
                        showDefaultClose
                        alertMode
                    />
                    : null}
        </ContentColumnWrapper>
        );
    }
}