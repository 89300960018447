import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { CrudTypes } from '@commonResources/CrudTypes';

import { IsFieldFilled, IsAlphaNumeric } from '@commonResources/validations';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IAEPhysicianMaintenanceState, IAEPhysicianMaintenanceActions, IAEPhysicianMaintenanceActionProps, actionCreators, validationCallback } from '@store/AEPhysicianMaintenance';
import { ICookies_Config } from '@store/ConfigData';
import { trim } from 'lodash';
import { Input, Button, SelectDropdown, Tabs } from '@optum-uicl/ui-core/dist';
import { DialogWrapper, OKCancelButtons, DialogInstruction, CenteredContent, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { DialogLegend, DialogFieldset, DialogLabel } from '@common/DialogStyles';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { SelectComponent } from '../../common/SelectComponent';
import { RadioButtonComponent } from '@common/RadioButton';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';
import { URLs } from '../../../commonResources/constants';
import { getRawToken } from '../../../scripts/session/SecurityToken';

const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
`;

const SearchMessageTop = styled.div`
    margin-top: 13px;
    font-size: 10pt;
    padding-left: 34px;
    max-width: 90%;
`;

const SearchMessageBottom = styled.div`
    margin-top: 10px;
    margin-bottom: 4px;
    font-size: 10pt;
    padding-left: 20px;
    max-width: 90%;
`;

const AEPhysicianMaintenanceLeftContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    width: 436px;
   
    #left-dialogfieldset {
        padding-bottom: 4px !important;
     }

    #physician-count {
        margin-top: 13px;
        fontSize: 10pt;
        paddingLeft: 34px;
     } 
   
    #search-center {
        margin: 15px auto;
     } 
    
    #search-field {
        width: 207px;
        padding-left: 0px;
        margin-bottom: 8px;
    } 

    #search-type-label {
        margin-left: 0px;
        margin-top:6px
        margin-bottom: 6px;
    }

    #search-type {
        height: 15px;
        width: 15px;
    }

    #search-type-begins-with {
        margin-bottom: 4px;
        margin-top: 4px;
        margin-left: 38px;
    }  

    #search-type-equals {
        margin-left: 38px;
        margin-bottom: 4px;
    }  
   
    #equals {
        margin-left:-65px; 
        height: 15px;
        width: 15px;
    }

    #search-value {
        width: 207px;
        text-transform: uppercase;
        padding-right: 10px;
        margin-right: 20px;
        margin-bottom: 8px;
        margin-top: 6px;
    }

    #thru {
        width: 207px;
        text-transform: uppercase;
        padding-right: 10px;
        margin-bottom: 8px;
    }

    #limit-search-to-label {
        margin-left: 2px;
        margin-bottom: 4px;
    }

    #limit-search-to {
        height: 15px;
        width: 15px;
    }

    #limit-search-to-ubphysicians {
        margin-bottom: 4px;
        margin-top: 4px;
        margin-left: 38px;
    }  

    #limit-search-to-1500physicians {
        margin-left: 38px;
        margin-bottom: 4px;
    }  

    #limit-search-to-allphysicians {
        margin-left: 38px;
        margin-bottom: 4px;
    }  

    #searchresult-textsearch {
        width: 402px;
        text-transform: uppercase;
        padding-right: 10px;
        margin-left: 6px;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    div[class^="TabsWrapper-sc"]{
        box-shadow: #9ba1a9 0px -2px 0px 0px inset;
        span[class^="TabsWrapper"] {
            margin-left: 0px;
        button {
            width: 70px;
            border-bottom: 2px solid #9ba1a9 !important;
        }
        button.selected {
            border-bottom-color: #37474f !important;
        }
    }
}
`;

const AEPhysicianMaintenanceRightContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

    min-height: 180px;
    width: 590px; 
    padding: 10px;
    height: 100%;

    div.text-input input[type='text']{
        height: 30px;
    }
     
    #last-name {
        width: 176px;
        text-transform: uppercase;
        padding-right: 10px;
        margin-right: 11px;  
    }

    #first-name {
        width: 176px;
        text-transform: uppercase;
        padding-right: 10px;
        margin-right: 17px;
    }

    #middle-initial {         
        width: 40px;
        text-transform: uppercase;
        margin-right: 11px;
    }

    #suffix {
         width: 120px;
         text-transform: uppercase;
         padding-right: 10px;
         margin-right: 11px;
    }

    #form-type {
         width: 177px;
         margin-right: 18px;
    }
            
    #id-type {
         width: 176px;
         margin-right: 20px;
    }

    #tax-id {
         width: 176px;
         text-transform: uppercase;
         padding-right: 10px;             
    }

    #npi {
         width: 176px;
         text-transform: uppercase;
         padding-right: 10px;
         margin-right: 14px;
    }

    #taxonomy {
         width: 176px;
         text-transform: uppercase;
         padding-right: 9px;
         margin-right: 18px;
    }

    #fax-no {
         width: 176px;
         text-transform: uppercase;
        padding-right: 10px;
    }

    #payer-specific-dialogfieldset {
        height: 100%;
    }

    #payer-specific-list {
        margin-bottom: 8px;
    }
        
    #id {
        width: 178px;
        text-transform: uppercase;
        padding-right: 10px;
        margin-right: 20px;
        margin-bottom: 6px;          
    }
       
    #qualifier-code {         
        width: 53px;
        text-transform: uppercase;
        margin-bottom: 6px;
    }

    #lob {
        width: 178px;  
        margin-bottom: 6px;
        margin-right: 20px;
    }
    
    #payer {
        width: 350px;  
        margin-bottom: 6px;
    }

    #lob-npi {
        width: 178px;
        text-transform: uppercase;
        padding-right: 10px;
        margin-right: 20px;
    }

    #lob-taxonomy {
         width: 178px;
         text-transform: uppercase;
         padding-right: 9px;
     }
   
`;

const FieldSetMargin = styled.div `
fieldset {
    width: 99.5%;
}
`
const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;



interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    canSecCheck1: boolean,
    title: string
};

interface IComponentState {
    lobModel: boolean,
    isUpdate: boolean,
    physicianIdExist: boolean,
    searchEmpty: boolean,
    lobRemoveMessage: string,
    PhysicianModel: boolean,
    cancelLeave: boolean,
    updating: boolean,
    physicianCountLimit: number,
}

interface IDropdownItem {
    label: string,
    value: string,
}

interface ITabItem {
    label: string;
    domID: string;
}

interface MCViewRespParam {
    "Name": string
    "Value": string
}
interface MCViewRespParamL1 {
    Param: MCViewRespParam[]
}

export const DEFAULT_STATE: IComponentState = {
    PhysicianModel: false,
    lobModel: false, isUpdate: false,
    physicianIdExist: false,
    searchEmpty: false,
    lobRemoveMessage: '',
    cancelLeave: false,
    updating: false,
    physicianCountLimit: 0
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IAEPhysicianMaintenanceProps = IMergeCrudComponentProps<IAEPhysicianMaintenanceState, IAEPhysicianMaintenanceActionProps, IOwnProps>;


export class AEPhysicianMaintenance extends React.Component<IAEPhysicianMaintenanceProps, IComponentState> {
    static addItemCount: number = 0;

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        canSecCheck1: false,
        title: "Auto Entry Physician Maintenance"
    };

    constructor(props: IAEPhysicianMaintenanceProps) {
        super(props);
        this.state = DEFAULT_STATE;
        //testing
        this.getPhysicianCountLimit();
    }

    public buildDefaultSearch() : any {
        let Params = [] as MCViewRespParam[];
        let Level1Ip = {} as MCViewRespParamL1;
        // cjd this needs to come from config if we go with the legacy default search
        Params.push({ Name: '@chvMaxRows', Value: '500' }); 

        Level1Ip.Param = Params;
        return Level1Ip;
    }
    
    public componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }

        this.props.action.ui.clearDelta();
        pageLeave();
        this.props.action.configData.getConfigData({ cookies: [], config: [{ name: "EnableTenetFeatures" }] });
        this.props.action.crud.get({ crudId: CrudTypes.mctiAEOtherPhysician }, validationCallback);
        //this.props.action.crud.search({crudId: CrudTypes.mctiAEOtherPhysician, data: JSON.stringify(this.buildDefaultSearch())});
    }

    public componentDidUpdate(prevProps:any) {
        if (this.props.dataStore.crud.data !== prevProps.dataStore.crud.data) {
             this.lessThanLimitPhysians() 
        }
    }


    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    /* -----------------------------------  */
    /* --------  ACTION METHODS ----------  */
    /* -----------------------------------  */

    public onSelectTab(e: React.SyntheticEvent<Element,Event>, selObj: any) {
        this.props.action.ui.selectTabIndex(selObj.selectedTabIndex);
    }

    public onSearch(e: React.SyntheticEvent) {
        if (this.props.dataStore.ui.searchFrom == "") {
            this.setState({ searchEmpty: true })
            return;
        }
        let obj = {
            'SearchFields': this.props.dataStore.ui.cboSearchFields,
            'SearchType': this.props.dataStore.ui.searchType,
            'SearchFrom': this.props.dataStore.ui.searchFrom,
            'SearchForm': this.props.dataStore.ui.searchForm,
            'SearchTo': this.props.dataStore.ui.searchTo,
            'SearchData': this.props.dataStore.crud.data?.AEPhysicianMaintenanceInfo.Physicians.Physician
        }
        this.props.action.ui.search(obj);
        this.props.action.ui.selectTabIndex(1);
    }

    
    //Action for the limited physicians
    public lessThanLimitPhysians() {
        let searchData = this.props.dataStore.crud.data?.AEPhysicianMaintenanceInfo.Physicians.Physician
        let limit = this.state.physicianCountLimit
      
        let obj = {
            'SearchData': searchData
        }

        if (searchData && searchData.length < limit) {
            this.props.action.ui.limitPhysians(obj)
            this.props.action.ui.selectTabIndex(1);
        }

        return
    }
    //
  

    public onChangeSearchFields(searchFields: string) {
        this.props.action.ui.updateAEPhysicianSearchFields(searchFields.trim());
    }

    public onChangeSearchType(searchType: string) {
        this.props.action.ui.updateAEPhysicianSearchType(searchType.trim());
    }

    public onChangeSearchFrom(searchFrom: string) {
        if (searchFrom && searchFrom.length > 0)
            searchFrom = searchFrom.toUpperCase();
        this.props.action.ui.updateAEPhysicianSearchFrom(searchFrom.trim());
    }

    public onChangeSearchTo(searchTo: string) {
        if (searchTo && searchTo.length > 0)
            searchTo = searchTo.toUpperCase();
        this.props.action.ui.updateAEPhysicianSearchTo(searchTo.trim());
    }

    public onChangeSearchForm(searchForm: string) {
        this.props.action.ui.updateAEPhysicianSearchForm(searchForm.trim());
    }

    public updateAEPhysician_TxtSearch(val: string) {
        if (val && val.length > 0)
            val = val.toUpperCase();
        let obj = {
            'searchData': val.trim(),
            'resultData': this.props.dataStore.crud.data?.AEPhysicianMaintenanceInfo.Physicians.Physician
        }
        this.props.action.ui.txtSearch(obj);
    }

    public updateAEPhysician_LastName(val: string) {
        if (val && val.length > 0)
            val = val.toUpperCase();
        this.props.action.ui.updateAEPhysicianLastName({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val .trim()
            }
        });
    }

    public updateAEPhysician_FirstName(val: string) {
        if (val && val.length > 0)
            val = val.toUpperCase();
        this.props.action.ui.updateAEPhysicianFirstName({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val.trim()
            }
        });
    }

    public updateAEPhysician_MiddleInitial(val: string) {
        if (val && val.length > 0)
            val = val.toUpperCase();
        this.props.action.ui.updateAEPhysicianMI({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val.trim()
            }
        });
    }

    public updateAEPhysician_Suffix(val: string) {
        if (val && val.length > 0)
            val = val.toUpperCase();
        this.props.action.ui.updateAEPhysicianSuffix({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val.trim()
            }
        });
    }

    public updateAEPhysician_FormType(val: string) {
        this.props.action.ui.updateAEPhysicianFormType({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val.trim()
            }
        });
    }

    public updateAEPhysician_IDType(val: string) {
        this.props.action.ui.updateAEPhysicianIDType({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val.trim()
            }
        });
    }
    public updateAEPhysician_TaxID(val: string) {
        if (val && val.length > 0)
            val = val.toUpperCase();
        this.props.action.ui.updateAEPhysicianTaxID({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val.trim()
            }
        });
    }

    public updateAEPhysician_NPI(val: string) {
        if (val && val.length > 0)
            val = val.toUpperCase();
        this.props.action.ui.updateAEPhysicianNPI({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val.trim()
            }
        });
    }

    public updateAEPhysician_Taxonomy(val: string) {
        if (val && val.length > 0)
            val = val.toUpperCase();
        this.props.action.ui.updateAEPhysicianTaxonomy({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val.trim()
            }
        });
    }

    public updateAEPhysician_FaxNo(val: string) {
        if (val && val.length > 0)
            val = val.toUpperCase();
        this.props.action.ui.updateAEPhysicianFaxNo({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val.trim()
            }
        });
    }

    public updateAEPhysician_LOB_PhysicianNo(val: string) {
        if (val && val.length > 0)
            val = val.toUpperCase();

        this.props.action.ui.updateAEPhysicianNo({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val.trim()
            }
        });
    }

    public updateAEPhysician_LOB_QC(val: string) {
        if (val && val.length > 0)
            val = val.toUpperCase();
        this.props.action.ui.updateAEPhysicianLOBQC({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val.trim()
            }
        });
    }

    public updateAEPhysician_LOB_LOB(val: string) {
        this.props.action.ui.updateAEPhysicianLOBLOB({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val.trim()
            }
        });
    }

    public updateAEPhysician_LOB_Payer(val: string) {
        this.props.action.ui.updateAEPhysicianLOBPayer({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val.trim()
            }
        });
    }

    public updateAEPhysician_LOB_NPI(val: string) {
        if (val && val.length > 0)
            val = val.toUpperCase();
        this.props.action.ui.updateAEPhysicianLOBNPI({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val.trim()
            }
        });
    }

    public updateAEPhysician_LOB_Taxonomy(val: string) {
        if (val && val.length > 0)
            val = val.toUpperCase();
        this.props.action.ui.updateAEPhysicianLOBTaxonomy({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val.trim()
            }
        });
    }

    public sendError(id: string, msg: string) {
        if (!id || (id && id.length === 0) || !msg || (msg && msg.length === 0))
            return;

        this.props.action.ui.errorAEPhysicianField({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { id: id, value: msg }
        });
    }

    public sendLOBError(id: string, msg: string) {
        if (!id || (id && id.length === 0) || !msg || (msg && msg.length === 0))
            return;

        this.props.action.ui.errorLOBAEPhysicianField({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { id: id, value: msg }
        });
    }



    /* -----------------------------------  */
    /* ------  BUTTON RELATED METHODS ----  */
    /* -----------------------------------  */

    public crudLoaded(): boolean {
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.AEPhysicianMaintenanceInfo &&
            this.props.dataStore.crud.data.AEPhysicianMaintenanceInfo.Physicians &&
            this.props.dataStore.crud.data.AEPhysicianMaintenanceInfo.Physicians.Physician
        )
            return true;

        return false;
    }

    public validateEntry(): boolean {

        if (!this.crudLoaded())
            return false;
        let requiredFieldErrorFound = false;

        if (!IsFieldFilled(this.props.dataStore.ui.selectedItem['@Last'])) {
            this.sendError('LastName', "Last Name must not be blank.");
            requiredFieldErrorFound = true;
        }

        if (!IsFieldFilled(this.props.dataStore.ui.selectedItem['@First'])) {
            this.sendError('FirstName', "First Name must not be blank.");
            requiredFieldErrorFound = true;
        }

        if (this.props.dataStore.ui.selectedItem['@FaxNo'] != "" && this.props.dataStore.ui.selectedItem['@FaxNo'].length < 10) {
            this.sendError('FaxNo', "Fax number must consist of ten numbers.");
            requiredFieldErrorFound = true;
        }

        if (!IsAlphaNumeric(this.props.dataStore.ui.selectedItem['@NPI'])) {
            this.sendError('NPI', "The value you typed in must consist of valid characters.");
            requiredFieldErrorFound = true;
        }
        if (!IsAlphaNumeric(this.props.dataStore.ui.selectedItem['@Taxonomy'])) {
            this.sendError('Taxonomy', "The value you typed in must consist of valid characters.");
            requiredFieldErrorFound = true;
        }

        if (requiredFieldErrorFound)
            return false;

        return true;
    }

    public validateLOBEntry(): boolean {
        let requiredFieldErrorFound = false;

        let physicianNo = this.props.dataStore.ui.selectedLOBID != undefined ? this.props.dataStore.ui.selectedLOBID['@PhysicianNo'] : ""
        let npi = this.props.dataStore.ui.selectedLOBID != undefined ? this.props.dataStore.ui.selectedLOBID['@NPI'] : ""
        let taxonomy = this.props.dataStore.ui.selectedLOBID != undefined ? this.props.dataStore.ui.selectedLOBID['@Taxonomy'] : ""

        if (!IsFieldFilled(physicianNo)) {
            this.sendLOBError('PhysicianNoLOB', "ID must not be blank.");
            requiredFieldErrorFound = true;
        }

        if (!IsAlphaNumeric(npi)) {
            this.sendLOBError('NPILOB', "The value you typed in must consist of valid characters.");
            requiredFieldErrorFound = true;
        }
        if (!IsAlphaNumeric(taxonomy)) {
            this.sendLOBError('TaxonomyLOB', "The value you typed in must consist of valid characters.");
            requiredFieldErrorFound = true;
        }

        if (requiredFieldErrorFound)
            return false;

        return true;
    }

    public onClickModifyButton(e: React.SyntheticEvent) {
        if (!this.crudLoaded())
            return;

        if (!this.validateEntry())
            return;

        const selectedItemID = this.props.dataStore.ui.selectedItem['@ID'];

        if (selectedItemID !== "") {
            let temp = this.props.dataStore.crud.data!.AEPhysicianMaintenanceInfo.Physicians.Physician.find(
                obj => obj['@ID'] === selectedItemID);

            if (!temp) return;
            this.props.action.ui.updateAEPhysician({ masterCrud: this.props.dataStore.crud.data, uiData: { id: selectedItemID } });
        } else {
            this.props.action.ui.addAEPhysician({ masterCrud: this.props.dataStore.crud.data, uiData: { id: "#" + AEPhysicianMaintenance.addItemCount++ } });
        }
        this.props.action.ui.selectTabIndex(1);

    }

    public onLOBClickModifyButton(e: React.SyntheticEvent) {
        if (!this.crudLoaded())
            return;

        if (!this.validateLOBEntry())
            return;

        const selectedItemPayerSpecificID = this.props.dataStore.ui.selectedLOBID["@ID"];

        if (selectedItemPayerSpecificID !== "") {
            let temp = this.props.dataStore.ui.selectedItem.LOB.find(
                obj => obj['@ID'] === selectedItemPayerSpecificID);

            if (temp) {
                this.props.action.ui.setPayerLOBID(selectedItemPayerSpecificID);
                this.props.action.ui.updateLOBAEPhysician({ masterCrud: this.props.dataStore.crud.data, uiData: { id: this.props.dataStore.ui.selectedItem["@ID"] } });
            } else {
                this.props.action.ui.setPayerLOBID("#" + AEPhysicianMaintenance.addItemCount++);
                this.props.action.ui.addLOBAEPhysician({ masterCrud: this.props.dataStore.crud.data, uiData: { id: this.props.dataStore.ui.selectedItem["@ID"] } });
            }
        }
        else {
            this.props.action.ui.setPayerLOBID("#" + AEPhysicianMaintenance.addItemCount++);
            this.props.action.ui.addLOBAEPhysician({ masterCrud: this.props.dataStore.crud.data, uiData: { id: this.props.dataStore.ui.selectedItem["@ID"] } });
        }
    }

    public onClickRemovePhysician(e: React.SyntheticEvent) {
        this.setState({ PhysicianModel: !this.state.PhysicianModel })
        this.props.action.confirm.openConfirm(() => this.onClickConfirmRemovePhysician());
    }

    onToggleConfirmation(e: React.MouseEvent<HTMLElement>) {
        this.setState({ PhysicianModel: !this.state.PhysicianModel })
        this.props.action.confirm.closeConfirm();
    }

    public onRemovePhysician(e: React.MouseEvent<HTMLButtonElement>) {
        this.setState({ PhysicianModel: !this.state.PhysicianModel })
        setTimeout(() => {
            this.onClickConfirmRemovePhysician();
        }, 150)
    }

    public onClickConfirmRemovePhysician() {
        handleChange()
        this.props.action.ui.removeAEPhysician({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                updateInfo: {
                    "@ID": this.props.dataStore.ui.selectedItem["@ID"],
                    "@Last": this.props.dataStore.ui.selectedItem["@Last"],
                    "@First": this.props.dataStore.ui.selectedItem["@First"],
                    "@MI": this.props.dataStore.ui.selectedItem["@MI"],
                    "@Suffix": this.props.dataStore.ui.selectedItem["@Suffix"],
                    "@TaxID": this.props.dataStore.ui.selectedItem["@TaxID"],
                    "@FaxNo": this.props.dataStore.ui.selectedItem["@FaxNo"],
                    "@IDType": this.props.dataStore.ui.selectedItem["@IDType"],
                    "@NPI": this.props.dataStore.ui.selectedItem["@NPI"],
                    "@Taxonomy": this.props.dataStore.ui.selectedItem["@Taxonomy"],
                    "@FormType": this.props.dataStore.ui.selectedItem["@FormType"],
                    "LOB": this.props.dataStore.ui.selectedItem.LOB,
                }
            }
        });
    }

    public onClickRemoveLOB(e: React.SyntheticEvent) {
        this.props.action.ui.isLOBModelOpen(true);
        this.setState({ lobModel: true })
        this.props.action.confirm.openConfirm(() => this.onClickConfirmRemoveLOB());
    }
    onToggleConfirmationLOB(e: React.MouseEvent<HTMLElement>) {
        this.props.action.confirm.closeConfirm();
        this.setState({ lobModel: false })
    }

    public onRemoveLOB(e: React.MouseEvent<HTMLButtonElement>) {
        this.onClickConfirmRemoveLOB();
    }

    public onClickConfirmRemoveLOB() {
        handleChange()
        this.props.action.ui.removeLOBAEPhysician({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                updateInfo: {
                    "@ID": this.props.dataStore.ui.selectedLOBID["@ID"],
                    "PhysicianNo": this.props.dataStore.ui.selectedLOBID["@PhysicianNo"],
                    "@QC": this.props.dataStore.ui.selectedLOBID["@QC"],
                    "@LOB": this.props.dataStore.ui.selectedLOBID["@LOB"],
                    "@Payer": this.props.dataStore.ui.selectedLOBID["@Payer"],
                    "@NPI": this.props.dataStore.ui.selectedLOBID["@NPI"],
                    "@Taxonomy": this.props.dataStore.ui.selectedLOBID["@Taxonomy"],
                }
            }
        });
    }

    public onLOBClickRemoveButton(e: any) {
        if (this.props.dataStore.ui.selectedLOBID['@ID'] === "")
            return;
        const msg = "Do you want to remove '" + this.props.dataStore.ui.selectedLOBID['@PhysicianNo'] + "'? This action cannot be undone.";
        this.onLOBConfirmRemove(msg);
    }

    public onClearRemoveConfirm(e: any) {
        this.onConfirmRemove('');
    }
    public onLOBClearRemoveConfirm(e: any) {
        this.onLOBConfirmRemove('');
    }

    public onConfirmRemove(msg: string) {
        this.props.action.ui.errorAEPhysicianRemoveConfirm({ masterCrud: this.props.dataStore.crud.data, uiData: { value: msg } });
    }
    public onLOBConfirmRemove(msg: string) {
        this.props.action.ui.errorLOBAEPhysicianRemoveConfirm({ masterCrud: this.props.dataStore.crud.data, uiData: { value: msg } });
    }

    public onRemoveButton(e: any) {
        if (!this.crudLoaded()) return;

        const selectedItemID = this.props.dataStore.ui.selectedItem['@ID'];
        if (selectedItemID !== "") {
            let temp = this.props.dataStore.crud.data!.AEPhysicianMaintenanceInfo.Physicians.Physician.find(
                obj => obj['@ID'] === selectedItemID);
            if (!temp) return;

            this.props.action.ui.removeAEPhysician({ masterCrud: this.props.dataStore.crud.data, uiData: { id: selectedItemID } });
        }

    }

    public onLOBRemoveButton(e: any) {
        const getLOBID = this.props.dataStore.ui.selectedLOBID['@ID'];

        if (getLOBID !== "") {
            let temp = this.props.dataStore.crud.data!.AEPhysicianMaintenanceInfo.Physicians.Physician[0].LOB.find(
                obj => obj['@ID'] === getLOBID);
            if (!temp) return;

            this.props.action.ui.removeLOBAEPhysician({ masterCrud: this.props.dataStore.crud.data, uiData: { id: getLOBID } });
        }
    }


    public validateForm(): boolean {
        return true;
    }
    
    public onUpdateCallback() {
        console.log('onUpdateCallback');
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.isUpdate(false)

        if (!this.validateForm())
            return;
        this.setState({ updating: true });
        this.props.action.crud.update({ crudId: CrudTypes.mctiAEOtherPhysician, data: this.props.dataStore.ui.deltaCrud });

        setTimeout(() => {
            this.props.history.push('/LandingPage')
        }, 2200)
    }


    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }
    
    public onClearAlert(e: any) {
        this.onAlert("");
    }
    public onAlert(msg: string) {
        this.props.action.ui.sendAEPhysicianMaintenanceAlert({ masterCrud: this.props.dataStore.crud.data, uiData: { value: msg } });
    }

    /* -----------------------------------  */
    /* -----  DATA HELP METHODS ----------  */
    /* -----------------------------------  */

    public getSearchFieldItem() {

        let setvalue = [{ label: 'Name (Last + First)', value: '1' },
        //{ label: 'Payer-Specific ID', value: '2' },
        //{ label: 'NPI', value: '4' },
        //{ label: 'Taxonomy', value: '8' },
            //{ label: 'Tax ID', value: '16' }
        ]

        let getValue = setvalue.filter(res => res.value == this.props.dataStore.ui.cboSearchFields)

        return getValue[0];
    }

    public getFormTypeFieldItem() {

        let setvalue = [{ label: 'Both', value: '0' },
        { label: 'UB', value: '1011' },
        { label: '1500', value: '1001' }]

        let getValue = setvalue.filter(res => {
            if (this.props.dataStore.ui.selectedItem != undefined) {
                return res.value == this.props.dataStore.ui.selectedItem["@FormType"]
            }
            else {
                return { label: 'Both', value: '0' };
            }
        });

        return getValue[0];
    }

    public getIDType_SelectedItem(lookupList: AEP_GenericLookupList[] | null, idToFind: string | undefined): IDropdownItem {

        if (!this.crudLoaded() ||
            idToFind === undefined || !lookupList || lookupList.length === 0
        ) return { value: '', label: '' };

        let item = lookupList.find(
            ob => ob['@ID'] === idToFind);
        let result = { value: '', label: '' };
        if (item) {
            result.label = item['@Name'];
            result.value = item['@ID'];
        } else {
            result.label = '- Select ID Type -';
            result.value = '0';
        }

        return result;
    }

    public getLOB_SelectedItem(lookupList: AEP_GenericLookupList[] | null, idToFind: string | undefined): IDropdownItem {

        if (!this.crudLoaded() ||
            idToFind === undefined || !lookupList || lookupList.length === 0
        ) return { value: '', label: '' };

        let item = lookupList.find(
            ob => ob['@ID'] === idToFind);
        let result = { value: '', label: '' };
        if (item) {
            result.label = item['@Name'];
            result.value = item['@ID'];
        } else {
            result.label = '- Select LOB -';
            result.value = '0';
        }

        return result;
    }

    public getPayer_SelectedItem(lookupList: AEP_GenericLookupList[] | null, idToFind: string | undefined): IDropdownItem {

        if (!this.crudLoaded() ||
            idToFind === undefined || !lookupList || lookupList.length === 0
        ) return { value: '', label: '' };

        let item = lookupList.find(
            ob => ob['@ID'] === idToFind);
        let result = { value: '', label: '' };
        if (item) {
            result.label = item['@Name'];
            result.value = item['@ID'];
        } else {
            result.label = '- Select Payer -';
            result.value = '0';
        }

        return result;
    }

    public getGenericLookupList_Array(lookupList: AEP_GenericLookupList[] | null) {

        if (!this.crudLoaded() ||
            !lookupList || lookupList.length === 0)
            return [];

        let result: any[] = [];

        lookupList.forEach(ob => {
            if (ob['@Name'])
                result.push({ label: ob['@Name'], value: ob['@ID'] });
        });
        return result;
    }

    public allowTenetFlag(): string {
        let allowTenetObj = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "EnableTenetFeatures");
        return (allowTenetObj && allowTenetObj.value ? allowTenetObj.value : "");
    }

    public getPhysicianSearchResultsDisplay() {
        try {
            let lobList: AE_LOB[] = [];
            let addItem: any[] = [
                {
                    "@ID": '',
                    "@First": ' ',
                    "@MI": '',
                    "@Last": '',
                    "@Suffix": '',
                    "@TaxID": '',
                    "@FaxNo": '',
                    "@IDType": '',
                    "@NPI": '',
                    "@Taxonomy": '',
                    "@FormType": '',
                    "@CustomerKey": '',
                    "@lastFirstMI": ' - ADD A NEW PHYSICIAN -',
                    "LOB": lobList
                }
            ];
            {
                this.props.dataStore.ui.searchResults.map((item) => {
                    let lastFirstMI = item["@Last"].toString() + (item["@First"].toString() != undefined && item["@First"].toString().length > 0 ? ", " + item["@First"].toString() : "") + " " + item["@MI"].toString();
                    var obj: any;

                    obj = {
                        "@ID": item["@ID"].toString(),
                        "@First": item["@First"].toString(),
                        "@MI": item["@MI"].toString(),
                        "@Last": item["@Last"].toString(),
                        "@Suffix": item["@Suffix"].toString(),
                        "@TaxID": item["@TaxID"].toString(),
                        "@FaxNo": item["@FaxNo"].toString(),
                        "@IDType": item["@IDType"].toString(),
                        "@NPI": item["@NPI"].toString(),
                        "@Taxonomy": item["@Taxonomy"].toString(),
                        "@FormType": item["@FormType"].toString(),
                        "@CustomerKey": item["@CustomerKey"].toString(),
                        "@lastFirstMI": lastFirstMI.trim(),
                        "LOB": lobList
                    }
                    if (lastFirstMI.trim() != "") {
                        addItem.push(obj)
                    }
                })
            }
            return addItem;
        }
        catch (e) {
            console.error(e);
        }
    }

    public getPayerSpecificIDDisplay() {
        let addItem: object[] = [
            {
                "@ID": "",
                "@PhysicianNo": '',
                "@QC": '',
                "@LOB": '',
                "@Payer": '',
                "@NPI": '',
                "@Taxonomy": '',
                "@PayerID": ' - ADD A NEW PAYER ID -'
            }
        ];
        let payer: AEP_GenericLookupList[];
        if (this.props.dataStore.ui.selectedItem != undefined && this.props.dataStore.ui.selectedItem["LOB"] != undefined &&
            this.props.dataStore.ui.selectedItem["LOB"].length > 0) {
            this.props.dataStore.ui.selectedItem["LOB"].map((item) => {
                payer = this.props.dataStore.crud.data?.AEPhysicianMaintenanceInfo?.Payers.Payer.filter(allIDs => {
                    return allIDs["@ID"].trim() == item["@Payer"].trim();
                })!;

                let physicianNoPayer = item["@PhysicianNo"].toString() + (payer != undefined && payer.length > 0 ? " - " + payer[0]["@Name"] : "");
                var obj: any;
                obj = {
                    "@ID": item["@ID"].toString(),
                    "@PhysicianNo": item["@PhysicianNo"].toString(),
                    "@QC": item["@QC"].toString(),
                    "@LOB": item["@LOB"].toString(),
                    "@Payer": item["@Payer"].toString(),
                    "@NPI": item["@NPI"].toString(),
                    "@Taxonomy": item["@Taxonomy"].toString(),
                    "@PayerID": physicianNoPayer
                }
                if (item["@Delete"] !== 'true')
                    addItem.push(obj)
            })
        }

        addItem.concat(this.props.dataStore.ui.searchLOBResults)
        return addItem;
    }

    public onSearchSelectedItem(e: React.ChangeEvent<HTMLSelectElement>) {
        let searchResultsSelectedValue = this.props.dataStore.ui.searchResults.filter(selectedValue => selectedValue["@ID"] == e.target.value);
        this.props.action.ui.searchSelectedItem(searchResultsSelectedValue[0]);
        if (e.target.value == "")
            this.props.action.ui.searchLOBSelectedItem(undefined);
    }

    public onSearchLOBSelectedItem(e: React.ChangeEvent<HTMLSelectElement>) {
        let payerSpecificIDSelectedValue = this.props.dataStore.ui.selectedItem.LOB.filter(selectedValue => selectedValue["@ID"] == e.target.value);
        this.props.action.ui.searchLOBSelectedItem(payerSpecificIDSelectedValue[0]);
    }

    //Call to the get limit for number of physians than can be displayed in the resul tab
    getPhysicianCountLimit = async () => {
        var url = URLs.api + '/api/data/securityInfo/getPhysicianCountLimit';
        let physicianLimit = ''
        await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        }).then((response) => response.json())
            .then((jsonData) => {
                this.setState({ physicianCountLimit: jsonData })
                physicianLimit = jsonData;
            });
        return physicianLimit;
    };

    public moveCursorToEnd(id: any) {
        var el: any = document.getElementById(id)
        let trimmedEl = el.value.trim();
        el.focus()
        if (el.value.endsWith(' ')) {
            var len = trimmedEl.length;
            if (el.setSelectionRange && el.selectionStart > len) {
                el.focus();
                el.setSelectionRange(len, len);
            } else if (el.createTextRange) {
                var t = el.createTextRange();
                t.collapse(true);
                t.moveEnd('character', len);
                t.moveStart('character', len);
                t.select();

            }
        }
    }

    public trimOnBlur(id: string) {
        let el: any = document.getElementById(id)
        if (el.value.endsWith(' ')) {
            el.value = el.value.slice().trim();
            let newVal = el.value.slice().trim();
            el.setAttribute('value', newVal)
        }
        el.blur()
    }

    /* -----------------------------------  */
    /* -------------  RENDER -------------  */
    /* -----------------------------------  */

    public render() {

        var instruction = <React.Fragment>Use this screen to add Physicians for Auto Entry. * indicates a required field. .</React.Fragment>;

        var buttons = <OKCancelButtons disableOK={!this.props.dataStore.ui.changed || !this.props.canEdit} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        let allowTenet = this.allowTenetFlag();

        let tabList: ITabItem[] = [{ label: 'Search', domID: 'id-Search' }, { label: 'Results', domID: 'id-Results' }];
        let physician = this.getPhysicianSearchResultsDisplay();
        let PayerIDs = this.getPayerSpecificIDDisplay();

        let count = this.props.dataStore.crud.data?.AEPhysicianMaintenanceInfo.Physicians["@Count"];
        let lobCount = this.props.dataStore.crud.data?.AEPhysicianMaintenanceInfo.Physicians["@LOBCount"];

        let thruClassName: string = "text-input";
        let thru: string = "";
        thru = this.props.dataStore.ui.searchType == "0" ? "" : "thru-visible"
        thruClassName = thruClassName + " " + thru;

        return (
            <DialogWrapper title={this.props.title} instruction={instruction} helpUrl='/Support/Help/HELP_AEPhysicians.htm' buttons={buttons} isBusy={!this.props.dataStore.crud.data || this.state.updating}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper id={"content_wrapper_AEPhysicianMaintenance"}>
                    {// <CenteredContent><DialogInstruction>{instructionTop }</DialogInstruction></CenteredContent> 
                    }
                    <ContentRowWrapper>
                        <AEPhysicianMaintenanceLeftContent>
                            <DialogFieldset id='left-dialogfieldset'>
                                <DialogLegend>Physicians</DialogLegend>
                                        <Tabs
                                    tabs={tabList}
                                    onTabSelect={(e: React.SyntheticEvent<Element,Event>, selObj: any) => this.onSelectTab(e, selObj)}
                                initialTab={this.props.dataStore.ui.selectedTabIndex}
                                   />
                                {this.props.dataStore.ui.selectedTabIndex !== 1 ?
                                    (
                                        <ContentWrapper>
                                            <ContentRowWrapper>
                                                <ContentColumnWrapper >
                                                    <SearchMessageTop>Add a new physician or search any of your {count} existing physicians ({lobCount} IDs) </SearchMessageTop>
                                                </ContentColumnWrapper>
                                            </ContentRowWrapper>

                                            <div id='search-center'>
                                                <ContentRowWrapper >
                                                    <SelectDropdown
                                                        domID="search-field"
                                                        className="dropdown"
                                                        label="Search Field:"
                                                        menuWidth='400px'
                                                        isClearable={false}
                                                        isSearchable={true}
                                                        size='small'

                                                        onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.onChangeSearchFields(e.value)}
                                                        options={[{ label: 'Name (Last + First)', value: '1' },
                                                        //{ label: 'Payer-Specific ID', value: '2' },
                                                        //{ label: 'NPI', value: '4' },
                                                        //{ label: 'Taxonomy', value: '8' },
                                                        //    { label: 'Tax ID', value: '16' }
                                                        ]}

                                                        initialValue={this.getSearchFieldItem()}
                                                    />
                                                </ContentRowWrapper>

                                                <DialogLabel id='search-type-label'>Search Type:</DialogLabel>
                                                <ContentColumnWrapper>
                                                    <ContentRowWrapper id='search-type-begins-with'>
                                                        <RadioButtonComponent
                                                            id="search-type"
                                                            label="Begins With"
                                                            checked={this.props.dataStore.ui.searchType === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeSearchType("0")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentColumnWrapper>
                                                <ContentColumnWrapper>
                                                    <ContentRowWrapper id='search-type-equals'>
                                                        <RadioButtonComponent
                                                            id="search-type"
                                                            label="Equals"
                                                            checked={this.props.dataStore.ui.searchType === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeSearchType("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentColumnWrapper>
                                                <div style={{ height: "4px" }}></div>
                                                <ContentColumnWrapper>
                                                    <ContentRowWrapper >
                                                        <Input
                                                            onClick={() => {
                                                                this.moveCursorToEnd('search-value')
                                                            }}
                                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                this.onChangeSearchFrom(e.target.value.trim())
                                                                this.trimOnBlur('search-value')
                                                            }}                                                          
                                                            domID="search-value"
                                                            className="text-input"
                                                            label="Search Value:"
                                                            maxLength={60}
                                                            initialValue={this.props.dataStore.ui.searchFrom}
                                                        />

                                                    </ContentRowWrapper>
                                                </ContentColumnWrapper>
                                                <ContentColumnWrapper>
                                                    <ContentRowWrapper >
                                                        <Input
                                                            domID="thru"                                                           
                                                            onClick={() => {
                                                                this.moveCursorToEnd('thru')
                                                            }}
                                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                this.onChangeSearchTo(e.target.value.trim())
                                                                this.trimOnBlur('thru')
                                                            }}
                                                            className={thruClassName}
                                                            label="Thru:"
                                                            maxLength={60}
                                                            initialValue={this.props.dataStore.ui.searchTo}
                                                        />

                                                    </ContentRowWrapper>
                                                </ContentColumnWrapper>

                                                <DialogLabel id='limit-search-to-label'>Limit Search to:</DialogLabel>
                                                <ContentColumnWrapper>
                                                    <ContentRowWrapper id='limit-search-to-ubphysicians'>
                                                        <RadioButtonComponent
                                                            //SearchForm
                                                            id="limit-search-to"
                                                            label="UB Physicians"
                                                            checked={this.props.dataStore.ui.searchForm === "1011"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeSearchForm("1011")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentColumnWrapper>
                                                <ContentColumnWrapper>
                                                    <ContentRowWrapper id='limit-search-to-1500physicians'>
                                                        <RadioButtonComponent
                                                            id="limit-search-to"
                                                            label="1500 Physicians"
                                                            checked={this.props.dataStore.ui.searchForm === "1001"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeSearchForm("1001")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentColumnWrapper>
                                                <ContentColumnWrapper>
                                                    <ContentRowWrapper id='limit-search-to-allphysicians'>
                                                        <RadioButtonComponent
                                                            id="limit-search-to"
                                                            label="All Physicians"
                                                            checked={this.props.dataStore.ui.searchForm === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeSearchForm("0")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentColumnWrapper>
                                            </div>

                                            <SelectButtons>
                                                <Button
                                                    domID="Search"
                                                    name="Search"
                                                    buttonType="emphasized"
                                                    size="control"
                                                    type="button"
                                                    disabled={!this.props.canEdit || !this.props.dataStore.crud.data} 
                                                    onClick={(e: React.SyntheticEvent) => this.onSearch(e)}
                                                />
                                            </SelectButtons>

                                            <ContentRowWrapper>
                                                <ContentColumnWrapper >
                                                    <SearchMessageBottom>Before beginning a second search, click OK to save any changes.</SearchMessageBottom>
                                                </ContentColumnWrapper>
                                            </ContentRowWrapper>
                                        </ContentWrapper>

                                    ) :
                                    (
                                        <ContentWrapper>
                                            <ContentRowWrapper>
                                                <ContentColumnWrapper >
                                                    <Input
                                                        onClick={() => {
                                                            this.moveCursorToEnd('searchresult-textsearch')
                                                        }}
                                                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            this.updateAEPhysician_TxtSearch(e.target.value.trim())
                                                            this.trimOnBlur('searchresult-textsearch')
                                                        }}
                                                       
                                                        domID="searchresult-textsearch"
                                                        className="text-input"
                                                        maxLength={6}
                                                        initialValue={this.props.dataStore.ui.localSearch}

                                                    />

                                                </ContentColumnWrapper>
                                            </ContentRowWrapper>
                                            <ContentRowWrapper>
                                                <ContentColumnWrapper style={{ marginLeft: "6px" }}>
                                                    <SelectComponent
                                                        title='physicians'
                                                        size={(allowTenet === '1' ? 35 : 30)}
                                                        width='402px'
                                                        height='460px'
                                                        selectedValue={this.props?.dataStore?.ui?.selectedItem !== undefined ? this.props?.dataStore?.ui?.selectedItem["@ID"] : ''}
                                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSearchSelectedItem(e)}
                                                        optionFields={{
                                                            value: "@ID",
                                                            text: "@lastFirstMI"
                                                        }}
                                                        records={physician}
                                                    ></SelectComponent>
                                                </ContentColumnWrapper>
                                            </ContentRowWrapper>
                                        </ContentWrapper>
                                    )
                                        }
                               

                            </DialogFieldset>
                        </AEPhysicianMaintenanceLeftContent>

                        <AEPhysicianMaintenanceRightContent>
                            <ContentRowWrapper>
                                <Input
                                    domID="last-name"
                                    className="text-input"
                                    label="*Last Name:"
                                    maxLength={21}
                                    initialValue={this.props.dataStore.ui.selectedItem != undefined ? this.props.dataStore.ui.selectedItem['@Last'].trim() : ""}
                                    hasError={!!this.props.dataStore.ui.lastNameError }
                                    errorMessage={this.props.dataStore.ui.lastNameError}
                                    onClick={() => {
                                        this.moveCursorToEnd('last-name')
                                    }}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        this.updateAEPhysician_LastName(e.target.value.trim())
                                        this.trimOnBlur('last-name')
                                    }}
                                />
                                <Input
                                    domID="first-name"
                                    className="text-input"
                                    label="*First Name:"
                                    maxLength={12}
                                    initialValue={this.props.dataStore.ui.selectedItem != undefined ? this.props.dataStore.ui.selectedItem['@First'] : ""}

                                    hasError={!!this.props.dataStore.ui.firstNameError}
                                    errorMessage={this.props.dataStore.ui.firstNameError}
                                    onClick={() => {    
                                        this.moveCursorToEnd('first-name')
                                    }}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        this.updateAEPhysician_FirstName(e.target.value.trim())
                                        this.trimOnBlur('first-name')
                                    }}
                                />
                                <Input
                                    domID="middle-initial"
                                    
                                    className="text-input"
                                    label="MI:"
                                    maxLength={1}
                                    initialValue={this.props.dataStore.ui.selectedItem != undefined ? this.props.dataStore.ui.selectedItem['@MI'] : ""}
                                    onClick={() => {
                                        this.moveCursorToEnd('middle-initial')
                                    }}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        this.updateAEPhysician_MiddleInitial(e.target.value.trim())
                                        this.trimOnBlur('middle-initial')
                                    }}
                                />
                                <Input
                                    domID="suffix"
                                   
                                    className="text-input"
                                    label="Suffix:"
                                    maxLength={5}
                                    initialValue={this.props.dataStore.ui.selectedItem != undefined ? this.props.dataStore.ui.selectedItem['@Suffix'] : ""}
                                    onClick={() => {
                                        this.moveCursorToEnd('suffix')
                                    }}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        this.updateAEPhysician_Suffix(e.target.value.trim())
                                        this.trimOnBlur('suffix')
                                    }}
                                />
                            </ContentRowWrapper>

                            <ContentRowWrapper >
                                <SelectDropdown
                                    domID="form-type"
                                    className="dropdown"
                                    label="Form Type:"
                                    menuWidth={177}
                                    isClearable={false}
                                    isSearchable={true}
                                    size='small'
                                    onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateAEPhysician_FormType(e.value)}
                                    options={[{ label: 'Both', value: '0' }, { label: 'UB', value: '1011' }, { label: '1500', value: '1001' }]}
                                    initialValue={this.getFormTypeFieldItem()}
                                />

                                <SelectDropdown
                                    domID="id-type"
                                    className="dropdown"
                                    label="ID Type:"
                                    menuWidth={176}
                                    isClearable={false}
                                    isSearchable={true}
                                    size='small'
                                    onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateAEPhysician_IDType(e.value)}

                                    options={this.getGenericLookupList_Array((!this.props.dataStore.crud.data ||
                                        !this.props.dataStore.crud.data.AEPhysicianMaintenanceInfo
                                        ? null : this.props.dataStore.crud.data.AEPhysicianMaintenanceInfo.IDTypeList.IDType))}

                                    initialValue={this.getIDType_SelectedItem(
                                        (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.AEPhysicianMaintenanceInfo
                                            ? null : this.props.dataStore.crud.data.AEPhysicianMaintenanceInfo.IDTypeList.IDType),
                                        this.props.dataStore.ui.selectedItem != undefined ? this.props.dataStore.ui.selectedItem["@IDType"] : "")}
                                />
                                <Input
                                    domID="tax-id"
                                    className="text-input"
                                    label="Tax Id:"
                                    //max length for tax-id is 9
                                    maxLength={9}
                                    initialValue={this.props.dataStore.ui.selectedItem != undefined ? this.props.dataStore.ui.selectedItem['@TaxID'] : ""}
                                    hasError={!!this.props.dataStore.ui.taxIDError}
                                    errorMessage={this.props.dataStore.ui.taxIDError}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateAEPhysician_TaxID(e.target.value.trim())}
                                />
                            </ContentRowWrapper>

                            <ContentRowWrapper >
                                <Input
                                    domID="npi"
                                    className="text-input"
                                    label="NPI:"
                                    maxLength={10}
                                    initialValue={this.props.dataStore.ui.selectedItem != undefined ? this.props.dataStore.ui.selectedItem['@NPI'] : ""}
                                    hasError={!!this.props.dataStore.ui.nbiError}
                                    errorMessage={this.props.dataStore.ui.nbiError}
                                    onClick={(e: React.SyntheticEvent<Element, Event>) => {
                                        this.moveCursorToEnd('npi')
                                    }}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        this.updateAEPhysician_NPI(e.target.value.trim())
                                        this.trimOnBlur('npi')
                                    }}
                                />
                                <Input
                                    domID="taxonomy"
                                    className="text-input"
                                    label="Taxonomy:"
                                    maxLength={10}
                                    initialValue={this.props.dataStore.ui.selectedItem != undefined ? this.props.dataStore.ui.selectedItem['@Taxonomy'] : ""}
                                    hasError={!!this.props.dataStore.ui.taxonomyError}
                                    errorMessage={this.props.dataStore.ui.taxonomyError}
                                    onClick={(e: React.SyntheticEvent<Element, Event>) => {
                                        this.moveCursorToEnd('taxonomy')
                                    }}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        this.updateAEPhysician_Taxonomy(e.target.value.trim())
                                        this.trimOnBlur('taxonomy')
                                    }}
                                />
                                <Input
                                    domID="fax-no"
                                    className="text-input"
                                    label="Fax No:"
                                    maxLength={10}
                                    initialValue={this.props.dataStore.ui.selectedItem != undefined ? this.props.dataStore.ui.selectedItem['@FaxNo'] : ""}
                                    hasError={!!this.props.dataStore.ui.faxNoError}
                                    errorMessage={this.props.dataStore.ui.faxNoError}
                                    onClick={(e:React.SyntheticEvent<Element, Event>) => {
                                        this.moveCursorToEnd('fax-no')
                                    }}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        this.updateAEPhysician_FaxNo(e.target.value.trim())
                                        this.trimOnBlur('fax-no')
                                    }}
                                />
                            </ContentRowWrapper>

                            <SelectButtons >
                                <Button
                                    domID="addButton"
                                    name={(this.props.dataStore.ui.selectedItem != undefined && this.props.dataStore.ui.selectedItem['@ID'] !== "") ? "Update" : "Add"}
                                    buttonType="emphasized"
                                    size="control"
                                    type="button"
                                    disabled={!this.props.canEdit || !this.props.dataStore.crud.data}

                                    onClick={(e: React.SyntheticEvent) => {
                                        this.setState({ isUpdate: true })
                                        handleChange()
                                        this.onClickModifyButton(e)
                                    }}
                                />
                                <Button
                                    domID="removeButton"
                                    name="Remove"
                                    buttonType="standard"
                                    size="control"
                                    type="button"
                                    disabled={!this.props.canSecCheck1 || (this.props.dataStore.ui.selectedItem != undefined && this.props.dataStore.ui.selectedItem['@ID'] === "")}

                                    onClick={(e: React.SyntheticEvent) => {
                                        this.setState({ isUpdate: true })
                                        this.onClickRemovePhysician(e)
                                    }}
                                />
                            </SelectButtons>

<FieldSetMargin>
                            <DialogFieldset id='payer-specific-dialogfieldset'>
                                <DialogLegend>Payer-Specific ID's</DialogLegend>
                                <ContentRowWrapper id='payer-specific-list'>

                                    <SelectComponent
                                        title='payerIDs'
                                        size={5}
                                        width='457px'
                                        selectedValue={this.props?.dataStore?.ui?.selectedLOBID !== undefined ? this.props?.dataStore?.ui?.selectedLOBID["@ID"] : ''}
                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSearchLOBSelectedItem(e)}
                                        optionFields={{
                                            value: "@ID",
                                            text: "@PayerID"
                                        }}
                                        records={PayerIDs}
                                    ></SelectComponent>
                                </ContentRowWrapper>
                                <ContentRowWrapper>
                                    <Input
                                        domID="id"
                                        className="text-input"
                                        label="*Id:"
                                        maxLength={15}
                                        initialValue={this.props.dataStore.ui.selectedLOBID != undefined ? this.props.dataStore.ui.selectedLOBID['@PhysicianNo'] : ""}
                                        hasError={!!this.props.dataStore.ui.physicianNoError}
                                        errorMessage={this.props.dataStore.ui.physicianNoError}

                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateAEPhysician_LOB_PhysicianNo(e.target.value.trim())}
                                    />
                                    <Input
                                        domID="qualifier-code"
                                        className="text-input"
                                        label="Qualifier:"
                                        maxLength={2}
                                        initialValue={this.props.dataStore.ui.selectedLOBID != undefined ? this.props.dataStore.ui.selectedLOBID['@QC'] : ""}
                                        onClick={(e: React.SyntheticEvent<Element, Event>) => {
                                            this.moveCursorToEnd('qualifier-code')
                                        }}
                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            this.updateAEPhysician_LOB_QC(e.target.value.trim())
                                            this.trimOnBlur('qualifier-code')
                                        }}
                                    />
                                </ContentRowWrapper>
                                <ContentRowWrapper>

                                    <SelectDropdown
                                        domID="lob"
                                        className="dropdown"
                                        label="LOB:"
                                        isClearable={false}
                                        menuWidth={178}
                                        isSearchable={true}
                                        size='small'
                                        onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateAEPhysician_LOB_LOB(e.value)}
                                        options={this.getGenericLookupList_Array((!this.props.dataStore.crud.data ||
                                            !this.props.dataStore.crud.data.AEPhysicianMaintenanceInfo
                                            ? null : this.props.dataStore.crud.data.AEPhysicianMaintenanceInfo.LOBList.LOB))}

                                        initialValue={(this.props?.dataStore?.ui?.selectedItem !== undefined) ? this.getLOB_SelectedItem(
                                            (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.AEPhysicianMaintenanceInfo
                                                ? null : this.props.dataStore.crud.data.AEPhysicianMaintenanceInfo.LOBList.LOB),
                                            this.props.dataStore.ui.selectedLOBID != undefined ? this.props.dataStore.ui.selectedLOBID['@LOB'] : "") : undefined}
                                    />
                                    <SelectDropdown
                                        domID="payer"
                                        className="dropdown"
                                        label="Payer:"
                                        isClearable={false}
                                        isSearchable={true}
                                        menuWidth={350}
                                        size='small'
                                        onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateAEPhysician_LOB_Payer(e.value)}
                                        options={this.getGenericLookupList_Array((!this.props.dataStore.crud.data ||
                                            !this.props.dataStore.crud.data.AEPhysicianMaintenanceInfo
                                            ? null : this.props.dataStore.crud.data.AEPhysicianMaintenanceInfo.Payers.Payer))}

                                        initialValue={(this.props?.dataStore?.ui?.selectedItem !== undefined ) ? this.getPayer_SelectedItem(
                                            (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.AEPhysicianMaintenanceInfo
                                                ? null : this.props.dataStore.crud.data.AEPhysicianMaintenanceInfo.Payers.Payer),
                                            this.props.dataStore.ui.selectedLOBID != undefined ? this.props.dataStore.ui.selectedLOBID['@Payer'] : "") : undefined}
                                    />

                                </ContentRowWrapper>

                                <ContentRowWrapper >
                                    <Input
                                        domID="lob-npi"
                                        className="text-input"
                                        label="NPI:"
                                        maxLength={10}
                                        initialValue={this.props.dataStore.ui.selectedLOBID != undefined ? this.props.dataStore.ui.selectedLOBID['@NPI'] : ""}
                                        hasError={!!this.props.dataStore.ui.nbiLOBError}
                                        errorMessage={this.props.dataStore.ui.nbiLOBError}
                                        onClick={(e: React.SyntheticEvent<Element, Event>) => {
                                            this.moveCursorToEnd('lob-npi')
                                        }}
                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            this.updateAEPhysician_LOB_NPI(e.target.value.trim())
                                            this.trimOnBlur('lob-npi')
                                        }}
                                    />
                                    <Input
                                        domID="lob-taxonomy"
                                        className="text-input"
                                        label="Taxonomy:"
                                        maxLength={10}
                                        initialValue={this.props.dataStore.ui.selectedLOBID != undefined ? this.props.dataStore.ui.selectedLOBID['@Taxonomy'] : ""}
                                        hasError={!!this.props.dataStore.ui.taxonomyLOBError}
                                        errorMessage={this.props.dataStore.ui.taxonomyLOBError}
                                        onClick={(e: React.SyntheticEvent<Element, Event>) => {
                                            this.moveCursorToEnd('lob-taxonomy')
                                        }}
                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            this.updateAEPhysician_LOB_Taxonomy(e.target.value.trim())
                                            this.trimOnBlur('lob-taxonomy')
                                        }}
                                    />
                                </ContentRowWrapper>

                                <ContentRowWrapper >
                                    <SelectButtons >
                                        <Button
                                            domID="addButton"
                                            name={this.props.dataStore.ui.selectedLOBID != undefined && this.props.dataStore.ui.selectedLOBID['@ID'] !== "" && this.props.dataStore.ui.selectedLOBID['@ID'] != undefined ? "Update" : "Add"}
                                            buttonType="emphasized"
                                            size="control"
                                            type="button"
                                            disabled={!this.props.canEdit || (this.props.dataStore.ui.selectedItem == undefined || this.props.dataStore.ui.selectedItem['@ID'] == "")}

                                            onClick={(e: React.SyntheticEvent) => {
                                                this.setState({ isUpdate: true })
                                                handleChange()
                                                if (this.props.dataStore.ui.selectedLOBID['@ID'] !== '') {
                                                    this.onLOBClickModifyButton(e)
                                                }
                                                else {
                                                    let PhysicianNoExisit = this.props.dataStore.ui.selectedItem.LOB.find(
                                                        obj => obj['@PhysicianNo'] === this.props.dataStore.ui.selectedLOBID['@PhysicianNo'] &&
                                                            obj['@Delete'] !== 'true');
                                                    if (PhysicianNoExisit === undefined) {
                                                        this.onLOBClickModifyButton(e)
                                                    }
                                                    else {
                                                        this.setState({ physicianIdExist: true })
                                                    }
                                                }
                                            }}
                                        />
                                        <Button
                                            domID="removeButton"
                                            name="Remove"
                                            buttonType="standard"
                                            size="control"
                                            type="button"
                                            disabled={!this.props.canSecCheck1 || (this.props.dataStore.ui.selectedLOBID != undefined && this.props.dataStore.ui.selectedLOBID['@ID'] === "")}

                                            onClick={(e: React.SyntheticEvent) => {
                                                this.setState({ isUpdate: true })
                                                let payer = this.props.dataStore.crud.data?.AEPhysicianMaintenanceInfo?.Payers.Payer.filter(allIDs => {
                                                    return allIDs["@ID"].trim() == this.props.dataStore.ui.selectedLOBID["@Payer"].trim();
                                                })!;

                                                let physicianNoPayer = this.props.dataStore.ui.selectedLOBID["@PhysicianNo"].toString() + (payer != undefined && payer.length > 0 ? " - " + payer[0]["@Name"] : "");
                                                this.setState({ lobRemoveMessage: physicianNoPayer })
                                                this.onClickRemoveLOB(e)
                                            }
                                            }
                                        />
                                    </SelectButtons>
                                </ContentRowWrapper>
                            </DialogFieldset>
                            </FieldSetMargin>
                        </AEPhysicianMaintenanceRightContent>
                    </ContentRowWrapper>
                </ContentWrapper>

                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.aePhysicianMaintenanceAlert && this.props.dataStore.ui.aePhysicianMaintenanceAlert.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearAlert(e)}
                    message={this.props.dataStore.ui.aePhysicianMaintenanceAlert}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClearAlert(e)}
                />
                <ModalConfirmation
                    isOpen={this.state.PhysicianModel}

                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleConfirmation(e)}
                    formattedMessage={(
                        <div>
                            {this.props.dataStore.ui.selectedItem !== undefined && <p>Do you want to remove <b>{this.props.dataStore.ui.selectedItem["@Last"]}</b>, <b>{this.props.dataStore.ui.selectedItem["@First"]} </b> <b>{this.props.dataStore.ui.selectedItem["@MI"]}</b>? This action cannot be undone.</p>}
                        </div>)
                    }
                    message={this.props.dataStore.confirm.message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onRemovePhysician(e)}
                />
                <ModalConfirmation
                    isOpen={this.state.lobModel}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleConfirmationLOB(e)}
                    formattedMessage={(
                        <div>
                            {this.props.dataStore.ui.selectedLOBID !== undefined && <p>Do you want to remove <b>{this.state.lobRemoveMessage}</b>? This action cannot be undone.</p>}
                        </div>)
                    }
                    message={this.props.dataStore.confirm.message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onRemoveLOB(e)}
                />

                <ModalConfirmation
                    isOpen={this.state.physicianIdExist}
                    alertMode
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setState({ physicianIdExist: !this.state.physicianIdExist })}
                    formattedMessage={(
                        <div>
                            <p>The Payer ID you wish to add already exists with this ID and Payer/LOB</p>
                        </div>)
                    }
                    message={this.props.dataStore.confirm.message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.setState({ physicianIdExist: !this.state.physicianIdExist })}
                />

                {/*<ModalConfirmation*/}
                {/*    isOpen={this.state.searchEmpty}*/}
                {/*    alertMode*/}
                {/*    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setState({ searchEmpty: !this.state.searchEmpty })}*/}
                {/*    formattedMessage={(*/}
                {/*        <div>*/}
                {/*            <p>There are too many physicians in the selected group ({this.props.dataStore.crud.data?.AEPhysicianMaintenanceInfo.Physicians["@Count"]}). Enter a smaller search range and search again.</p>*/}
                {/*        </div>)*/}
                {/*    }*/}
                {/*    message={this.props.dataStore.confirm.message}*/}
                {/*    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.setState({ searchEmpty: !this.state.searchEmpty })}*/}
                {/*/>*/}
                <ModalConfirmation
                    isOpen={this.state.searchEmpty}
                    alertMode
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setState({ searchEmpty: !this.state.searchEmpty })}
                    formattedMessage={(
                        <div>
                            <p>Please add an initial value to start the search.</p>
                        </div>)
                    }
                    message={this.props.dataStore.confirm.message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.setState({ searchEmpty: !this.state.searchEmpty })}
                />

            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IAEPhysicianMaintenanceState, IAEPhysicianMaintenanceActionProps, IOwnProps, IAEPhysicianMaintenanceProps, ApplicationState>(
    createCrudMapStateToProps('aePhysicianMaintenance'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(AEPhysicianMaintenance);

export default withRouter(connectedHoc);
