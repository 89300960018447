import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Button, Input} from '@optum-uicl/ui-core/dist';
import {Typography} from '@commonResources/typography';
import {Colors} from '@commonResources/colorVariables';
import {CrudTypes} from '@commonResources/CrudTypes';
import styled from 'styled-components';
import {URLs} from '@commonDevResources/constants';
import {ContentColumnWrapper, ContentRowWrapper, DialogWrapper, OKCancelButtons} from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend, DialogFieldset4} from '@common/DialogStyles';
import {SelectComponent} from '@common/SelectComponent';
import {ModalConfirmation} from '@common/ModalConfirmation';

import {
    createCrudMapDispatchToProps,
    createCrudMapStateToProps,
    IMergeCrudComponentProps,
    mergeCrudComponentProps,
    resetCrudComponentState
} from '@scripts/util/CrudComponentHelpers';
import {connect} from 'react-redux';
import {ApplicationState} from '@store/index';
import { actionCreators, IAutoModuleActionProps, IAutoModuleState, validationCallback, AUTOMODULE_PROPS} from '@store/AutoModule';
import {getRawToken} from "@scripts/session/SecurityToken";
import {SelectGroupComponent} from "@common/SelectGroupComponent";
import {handleChange, pageLeave} from "@commonResources/userModified";
import {ICookies_Config} from "@store/ConfigData";
import AssuranceMenu from "@common/AssuranceMenu";

const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    width: 950px;
    min-height: 610px;

    #autoModules {
        height: 675px;
    }

    #mytabcontainer >  div
    { 
	    -webkit-box-shadow: none !important;
	    -moz-box-shadow: none !important;
	    box-shadow: none !important;
    }

    #autolist-days_wrapper
    {
        padding: 10px 20px 0 0;
        
    }

    #autolist-daysofweek_label
    {
        
        padding: 40px 20px 10px 20px;
    }
    #autolist-monthlytoggle_wrapper{
        padding-top: 30px;
        padding-left: 5px;
        width: 180px;
    }
    #autolist-lagdays_wrapper{
        width: 120px;
        padding: 10px 20px 5px 20px;
    }
    #autolist-lagdays{
        width: 60px;
    }
    #triggerevent-dropdown{
        padding-top: 5px;
    }

    #SelectedAutoModules_wrapper{
        width: 360px;
    }
    label{
        text-transform:uppercase;
    }
    input{
        padding: 8px 8px 9px 4px !important;
    }
`;

const PrintOptionsWrapper = styled.div`
    //width: 375px;
    .option-class{
        font-size:12px;
    }


`;

const AutoModuleLeftContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    width: 350px;
`;

const AutoModuleContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    
    #automodule-filter{
        text-align: center;
    }
    
    #automodule-commandset-label {
        display: block;
        margin-bottom: 5px;
        color: #37474F;
        font-family: optum-sans-regular;
        font-size: 11px;
        line-height: 16px;
        font-weight: 600;
        letter-spacing: 0.5px;
        padding-left: 100px;

    }
    .option-class{
        font-size:12px !important;
    }
    

`;

const TabContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    
    margin-left: 5px;
    border: 1px solid ${Colors.grey50};
    padding: 5px;

    #autolist-tab-monthly,
    #autolist-tab-daily {
        box-shadow: 0 0 0 0 black !important;
        
    }


    #autolist-tab-monthly,
    #autolist-tab-daily {
        padding-top: 1px;
        padding-bottom: 1px;
        
    }

    #autolist-tab-monthly > label,
    #autolist-tab-daily > label {     
        margin-bottom: 0px;
        ${Typography.small};
    }

`;

const MonthlyToggleWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    
`;

const LagDaysWrapper = styled.div`

`;

const EnabledButtonWrapper = styled.div`
    padding-left: 20px;
`;

const LimitClaimsByJobButtonWrapper = styled.div`
    padding-left: 20px;
    padding-top: 8px;
    
`;

const SelectActions = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

    // #triggerevent-name {
    //     width: 350px;
    //    
    // }
    // #autolist-name {
    //     width: 420px;
    // }
    //
    // #autolist-enabled{    
    //     padding-left: 10px;
    // }
    // #autolist-enabled_label {
    //     padding-bottom: 5px;
    // }
`;

const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 6px;
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;
const SortButtonWrapper = styled.div`
    

`;
const CustomWrapper=styled.div`
    margin-top:5px;
`;
const CustomWrapper2=styled.div`
    margin-top:8px;
`;
export interface IMCAMOption {
    'value': string;
    'label': string;
}

export interface IMCAMOptionGroup {
    'label': string;
    'items': IMCAMOption[];
}

export interface IMCAMModules {
    moduleList: IMCAMOptionGroup[];
}

export interface IReportMap {
    [key: number] : string
}

// ---------------------------------

interface ITriggerEventItem {
    label: string,
    value: string,
}

export interface IReportItem {
    listName: string,
    reportName: string
}

export interface IReportHash {
    [reportKey: number] : IReportItem
}

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    canSecCheck1: boolean, // edit attachments
    title: string,
    ncsIsEnabled: boolean,
    ncsSkipPrompt: boolean,
    ncsPromptText: string,
    
    ncsListType: string,
    testMode?: boolean,
    apiType?: string,
    reportHash: IReportHash
}

interface IComponentState {
    cancelLeave: boolean;
    reportFormApiData: any;
    workflowApiData: any;
}

export const DEFAULT_STATE: IComponentState = {
    cancelLeave: false,
    reportFormApiData: '',
    workflowApiData: '',
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IAutoModuleProps = IMergeCrudComponentProps<IAutoModuleState, IAutoModuleActionProps, IOwnProps>;

export class AutoModule extends React.Component<IAutoModuleProps, IComponentState> {
    static addItemCount: number = 0;
    static addScheduleItemCount: number = 0;
    protected moduleList: IMCAMModules;
    protected reportHash: IReportHash;
    
    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        canSecCheck1: false, // attachments edit
        title: "Auto Module",
        ncsIsEnabled: false,
        ncsSkipPrompt: false,
        ncsPromptText: "",
        ncsListType: "",
        testMode: false,
        apiType: "Claim", // api for SDRFilterXMLCB
        reportHash: {}
    };

    constructor(props: IAutoModuleProps) {
        super(props);
        this.moduleList = { moduleList: [] };
        this.state = DEFAULT_STATE;
        this.reportHash = {};
        // initialize report details for process xRef
        this.reportHash[1] = {listName: 'UnreleasedReportList', reportName: 'Reports - Unreleased'};
        this.reportHash[2] = {listName: 'ReleasedReportList', reportName: 'Reports - Released'};
        this.reportHash[3] = {listName: 'RemitReportList', reportName: 'Reports - Remit'};
        this.reportHash[4] = {listName: 'SubmissionReportList', reportName: 'Reports - Submission'};
        this.reportHash[5] = {listName: 'SystemReportList', reportName: 'Reports - System'};
        this.reportHash[6] = {listName: 'ManagementReportList', reportName: 'Reports - Management'};
        this.reportHash[7] = {listName: 'DirectReportList', reportName: 'Reports - Direct'};
        this.reportHash[8] = {listName: 'MiscellaneousReportList', reportName: 'Reports - Miscellaneous'};
        this.reportHash[9] = {listName: 'OutsourceReportList', reportName: 'Reports - Outsource'};
        this.reportHash[10] = {listName: 'LookupReportList', reportName: 'Reports - Lookup'};
        this.reportHash[11] = {listName: 'CustomReportList', reportName: 'Reports - Custom'};
        this.reportHash[12] = {listName: 'ClaimVisionReportList', reportName: 'Reports - RF Assurance Pro'};
    }

    public componentDidMount() {
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.props.history.push('/LandingPage');
        }
        // issue with process not clearing out on componentDidUnmount
        this.props.action.ui.selectProcess(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: -1,
                    id: '',
                    text: ""
                }
            });
        this.props.action.configData.getConfigData({ cookies: [{ name: "EnhancedClaimStatusEnabled" }, { name: "DirectEntry" }, { name: "MCAMethod" }, { name: "Eligibility" }, {name: "AttachmentsEnabled"}, {name: "MCDEEnabled"}], 
                                                            config: [{ name: "EnableTenetFeatures" },  {name: "isClientUser"}, {name: "isEBOUser"}, {name: "CSFN_ISClaimVision"} ] });

        this.props.action.crud.get({ crudId: CrudTypes.mctiAutoModuleMaster }, validationCallback);
        this.loadViewReports();
        this.loadWorkflowDefinitions();
    }

    loadViewReports = async () => {
        var url = URLs.api + '/api/data/GetReportFormData';

        await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        })
            .then(async response => {
                if (response.status === 200) {
                    const data: any = await response.json();
                    let responseData: string = JSON.stringify(data);
                    this.setState({reportFormApiData: JSON.parse(responseData)});
                    this.props.action.ui.setReportFormData(
                        {
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                reportData: data
                            }
                        });
                }
            })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
            });
    };

    loadWorkflowDefinitions = async () => {
        var url = URLs.api + '/api/data/workflow/client';

        await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        })
            .then(async response => {
                if (response.status === 200) {
                    const data: any = await response.json();
                    let responseData: string = JSON.stringify(data);
                    this.setState({ workflowApiData: JSON.parse(responseData) });
                }
            })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
            });
    };

    public async verifySdrReport(filterId: number) : Promise<string>{
        var xmlData: APIXMLCB_XmlCallBackModel = { };

        xmlData.FilterId = filterId;
        xmlData.TestMode = this.props.testMode;
        xmlData.Type = this.props.apiType;  // "Claim|Report|Remit"

        let result : string = '';
        await fetch(URLs.api + '/api/data/getFilter',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
            if (response.status === 200) {
                //console.log('response');
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        }).then((data: APICF_FilterWrapper) => {
            if(data.Filter && data.Filter.UserField && data.Filter.UserField["@ID"] == '43') {
                result = data.Filter.UserField["@Value"]; // SDR State
            }
            else result = '';
                
        })
            .catch(error => {
                result = ''; 
            })
            .finally(() => {
                return result;
            });
        return result;
     }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public onSelectAutoModule(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectAutoModule(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }

    public updateAutoModule_Name(val: string) {
        this.props.action.ui.updateAutoModuleName({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: (val && val.length > 0 ? val.toUpperCase()  : '')
            }
        });
    }

    public updateAutoModule_Destination(val: string) {
        this.props.action.ui.updateAutoModuleDest({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: (val && val.length > 0 ? val.toUpperCase()  : '')
            }
        });
    }

    public updateAutoModule_Desc(val: string) {
        this.props.action.ui.updateAutoModuleDesc({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: (val && val.length > 0 ? val : '')
            }
        });
    }
    
    public isEmptyValue(val: string | undefined){
        if(!val)
            return true;
        
        return val === '' || val === '- Select -';
    }

    public async onClickModifyButton(e: React.SyntheticEvent) {

        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.AutoModuleMaintenanceInfo ||
            !this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.AutoModules ||
            !this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.AutoModules.AutoModule) return;

        let selectedItemId = this.props.dataStore.ui.selectedItem['@ID'];
        let selectedItemName = this.props.dataStore.ui.selectedItem['@Name'];
        let selectedModuleId = this.props.dataStore.ui.selectedItem["@ModuleID"];
        let selectedFilterId = this.props.dataStore.ui.selectedItem["@FilterID"];
        let selectedDescription = this.props.dataStore.ui.selectedItem["@Text"];
        let selectedReportActionId = this.props.dataStore.ui.selectedReportAction;
        let selectedPrinterId = this.props.dataStore.ui.selectedPrinter;
        let selectedDestination = this.props.dataStore.ui.selectedItem["@Destination"];
        let selectedParameters = this.props.dataStore.ui.selectedItem["@Parameters"];

        selectedItemName = selectedItemName ? selectedItemName.replace(/[^\w '\.\-/]/g, "").trim() : '';
        selectedModuleId = selectedModuleId ? selectedModuleId.trim() : '';
        selectedFilterId = selectedFilterId ? selectedFilterId.trim() : '';
        selectedDescription = selectedDescription ? selectedDescription.trim() : '';
        selectedReportActionId = selectedReportActionId ? selectedReportActionId.trim() : '';
        selectedPrinterId = selectedPrinterId ? selectedPrinterId.trim() : '';
        selectedDestination = selectedDestination ? selectedDestination.trim() : '';
        selectedParameters = selectedParameters ? selectedParameters.trim() : '';

        let currentModuleData = this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.ModuleList.Module.find(ob => {
            return ob["@ID"] == selectedModuleId
        });

        if (selectedItemName != "" && currentModuleData) {

            let filterRequired = currentModuleData["@FilterRequired"] ? currentModuleData["@FilterRequired"] : 'N';
            let filterId : string = this.props.dataStore.ui.selectedItem['@FilterID'] ? this.props.dataStore.ui.selectedItem['@FilterID'] : '';
            if (!this.props.dataStore.ui.filterDisabled && (filterId == '' || isNaN(+filterId))) { // one of the header filters
                if (filterRequired == 'Y') {
                    if (currentModuleData["@FilterType"] == 'G') {
                        this.onAlert('Please select a profile.');
                        return;
                    }
                    else {
                        this.onAlert('Please select a filter.');
                        return;
                    }
                }
            }

            if (!this.props.dataStore.ui.workflowCodesHidden && this.isEmptyValue(this.props.dataStore.ui.selectedWorkflowCode)) {
                this.onAlert('Please select a workflow code set.');
                return;
            }

            if (!this.props.dataStore.ui.reportFormatDisabled && this.isEmptyValue(this.props.dataStore.ui.selectedReportFormat)) {
                this.onAlert('Please select a report format.');
                return;
            }

            if (!this.props.dataStore.ui.spinOffFormTypeDisabled && this.isEmptyValue(this.props.dataStore.ui.selectedSpinOffFormType)) {
                this.onAlert('Please select a form type to spin off to.');
                return;
            }

            if (!this.props.dataStore.ui.exportChangesFormatDisabled && this.isEmptyValue(this.props.dataStore.ui.selectedExportChangesFormat)) {
                this.onAlert('Please select an export format.');
                return;
            }
            // if selected report action == Print
            let isPrint: boolean = (this.props.dataStore.ui.selectedReportAction == '3' || this.props.dataStore.ui.selectedReportAction == '4' ||
                this.props.dataStore.ui.selectedReportAction == '503' || this.props.dataStore.ui.selectedReportAction == '504');

            if (isPrint && (this.props.dataStore.ui.selectedPrinter == '')) {
                this.onAlert('Please select a printer.')
                return;
            }
            let dupNameFound : boolean = false;
            this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.AutoModules.AutoModule.forEach(ob => {
                if (selectedItemName.toUpperCase() == ob["@Name"].toUpperCase()) {
                    if(selectedItemId !=  ob["@ID"]) {
                        dupNameFound = true;
                    }
                }
            });
            if(dupNameFound) {
                this.onAlert("Please enter a different name as it already exists.");
                return;
            }
            let sdrState : string = '';
            //if it's the SDR module
            if (currentModuleData["@ID"] == "{83F93C46-54A8-437D-8533-F509F014BC6D}") {
                if (this.props.dataStore.ui.selectedItem['@FilterID']) {
                    await this.verifySdrReport(+this.props.dataStore.ui.selectedItem['@FilterID']).then(res => {sdrState = res});
                    if (sdrState == '') {
                        let moduleName = currentModuleData["@Name"];
                        this.onAlert(`Please select a saved filter that contains a [State Reporting State] 
                        for this auto module (${moduleName})`);
                        return;
                    }
                }
            }
            // build parameter list
            let parameterList : string = '';
            let selectedProcessType : string = currentModuleData["@ModuleType"] ? currentModuleData["@ModuleType"] : '';
            switch (selectedProcessType) {
                case AUTOMODULE_PROPS.MODULETYPE_RELEASESDR:
                    parameterList = `-s ${sdrState}`;
                    break;
                case AUTOMODULE_PROPS.MODULETYPE_REPORT:
                    parameterList = `${AUTOMODULE_PROPS.PARAM_REPORTFORMAT} ${this.props.dataStore.ui.selectedReportFormat}`;
                    if (!this.props.dataStore.ui.reportGroupingDisabled)
                        parameterList += ` ${AUTOMODULE_PROPS.PARAM_REPORTGROUPING} ${this.props.dataStore.ui.selectedReportGrouping}`;

                    if (!this.props.dataStore.ui.csvOptionsDelimiterDisabled)
                        parameterList += ` ${AUTOMODULE_PROPS.PARAM_DELIMITER} ${this.props.dataStore.ui.selectedDelimiter}`;

                    if (!this.props.dataStore.ui.csvOptionsQualifierDisabled)
                        parameterList += ` ${AUTOMODULE_PROPS.PARAM_QUALIFIER} ${this.props.dataStore.ui.selectedQualifier}`;

                    if (!this.props.dataStore.ui.workflowCodesHidden)
                        parameterList += ` ${AUTOMODULE_PROPS.PARAM_WORKFLOWFILTER} ${this.props.dataStore.ui.selectedWorkflowCode}`;
                    break;
                case AUTOMODULE_PROPS.MODULETYPE_SPINOFF:
                    parameterList = `${AUTOMODULE_PROPS.PARAM_FORMTYPE} ${this.props.dataStore.ui.selectedSpinOffFormType}`;
                    break;
                case AUTOMODULE_PROPS.MODULETYPE_EXPORT:
                    parameterList = `${AUTOMODULE_PROPS.PARAM_EXPORTFORMAT} ${this.props.dataStore.ui.selectedExportChangesFormat}`;
                    break;
                case AUTOMODULE_PROPS.MODULETYPE_DIRECTENTRY:
                    if (!this.props.dataStore.ui.reportFormatDisabled) {
                        
                        parameterList = `${AUTOMODULE_PROPS.PARAM_REPORTFORMAT} ${this.props.dataStore.ui.selectedReportFormat}`;

                        if (!this.props.dataStore.ui.csvOptionsDelimiterDisabled)
                            parameterList += ` ${AUTOMODULE_PROPS.PARAM_DELIMITER} ${this.props.dataStore.ui.selectedDelimiter}`;

                        if (!this.props.dataStore.ui.csvOptionsQualifierDisabled)
                            parameterList += ` ${AUTOMODULE_PROPS.PARAM_QUALIFIER} ${this.props.dataStore.ui.selectedQualifier}`;
                    }
                    break;
                case AUTOMODULE_PROPS.MODULETYPE_PAPERCLAIMS:
                    let paperParams: string = '';
                    if (this.props.dataStore.ui.selectedPrintFormType) {
                        paperParams = ` ${AUTOMODULE_PROPS.PARAM_PRINTFORMTYPE} ${this.props.dataStore.ui.selectedPrintFormType}`;

                        if (this.props.dataStore.ui.instPaperCommandSetConfigId) {
                            paperParams += ` ${AUTOMODULE_PROPS.PARAM_IPAPERCOMMANDSET} ${this.props.dataStore.ui.instPaperCommandSetConfigId}`;

                            let commandSet = this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PaperCommandSetList.PaperCommandSet.find(ob =>
                                ob["@ConfigurationId"] === this.props.dataStore.ui.instPaperCommandSetConfigId);
                            if (commandSet) {
                                paperParams += ` ${AUTOMODULE_PROPS.PARAM_IPAPERFORMTYPE} ${commandSet["@ClaimTypeId"]}`;
                            }
                        }

                        if (this.props.dataStore.ui.profPaperCommandSetConfigId) {
                            paperParams += ` ${AUTOMODULE_PROPS.PARAM_PPAPERCOMMANDSET} ${this.props.dataStore.ui.profPaperCommandSetConfigId}`;

                            let commandSet = this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PaperCommandSetList.PaperCommandSet.find(ob =>
                                ob["@ConfigurationId"] === this.props.dataStore.ui.profPaperCommandSetConfigId
                            );
                            if (commandSet) {
                                paperParams += ` ${AUTOMODULE_PROPS.PARAM_PPAPERFORMTYPE} ${commandSet["@ClaimTypeId"]}`;
                            }
                        }
                    }

                    if (this.props.dataStore.ui.selectedPrintType && this.props.dataStore.ui.selectedPrintType != 'Default') {
                        paperParams += ` ${AUTOMODULE_PROPS.PARAM_PRINTTYPE} ${this.props.dataStore.ui.selectedPrintType}`;
                    }

                    if (this.props.dataStore.ui.selectedFontFace && this.props.dataStore.ui.selectedFontFace != 'Default') {
                        paperParams += ` ${AUTOMODULE_PROPS.PARAM_FONTFACE} ${this.props.dataStore.ui.selectedFontFace}`;
                    }

                    if (this.props.dataStore.ui.selectedFontSize && this.props.dataStore.ui.selectedFontSize != 'Default') {
                        paperParams += ` ${AUTOMODULE_PROPS.PARAM_FONTSIZE} ${this.props.dataStore.ui.selectedFontSize}`;
                    }

                    parameterList = paperParams.substr(1);
                    break;
                case AUTOMODULE_PROPS.MODULETYPE_PAPERCLAIMSALT:
                case AUTOMODULE_PROPS.MODULETYPE_IMACS:
                    let altParams: string = '';
                    if (this.props.dataStore.ui.selectedPrintFormType) {
                        altParams += ` ${AUTOMODULE_PROPS.PARAM_PRINTFORMTYPE} ${this.props.dataStore.ui.selectedPrintFormType}`;
                    }
                    parameterList = altParams.substr(1);
                    break;
                case AUTOMODULE_PROPS.MODULETYPE_OUTSOURCE:
                case AUTOMODULE_PROPS.MODULETYPE_CLAIMVISION:    
                    parameterList = `${AUTOMODULE_PROPS.PARAM_REPORTFORMAT} ${this.props.dataStore.ui.selectedReportFormat}`;
                    break;
                default:
                    parameterList = '';
                    break;
            }             
            // a change has been made to the form, set the flag (this is handled in the update call)
            // build the item or update it
            selectedParameters = parameterList;

            if(selectedItemId !== ''){
                let temp = this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.AutoModules.AutoModule.find(obj => obj["@ID"] === selectedItemId);
                if(!temp) 
                    return;
                let item = JSON.parse(JSON.stringify(temp));
                
                item['@Name'] = selectedItemName;
                item['@ModuleID'] = selectedModuleId;
                item['@FilterID'] = selectedFilterId;
                item['@Text'] = selectedDescription;
                item['@ReportActionID'] = selectedReportActionId;
                item['@PrinterID'] = selectedPrinterId;
                item['@Destination'] = selectedDestination;
                item['@Parameters'] = selectedParameters;
                //console.log(item);
                this.props.action.ui.updateAutoModule({ masterCrud: this.props.dataStore.crud.data, uiData:item });
            }
            else { // new item
                let item : MCAM_AutoModule = {
                    "@ID": `#${++AutoModule.addItemCount}`,
                    "@Name": selectedItemName,
                    "@ModuleID": selectedModuleId,
                    "@FilterID": selectedFilterId,
                    "@Text": selectedDescription,
                    "@ReportActionID": selectedReportActionId,
                    "@PrinterID": selectedPrinterId,
                    "@Destination": selectedDestination,
                    "@Parameters": selectedParameters
                };
                //console.log(item);
                this.props.action.ui.addAutoModule({ masterCrud: this.props.dataStore.crud.data, uiData:item });
            }

        } // if no item name selected
        else {
            this.onAlert('Please enter a name and select a process.');
            return;
        }
        return;
    }

    public onRemoveButton(e:any) {
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.AutoModuleMaintenanceInfo ||
            !this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.AutoModules ||
            !this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.AutoModules.AutoModule) return;

        if (this.props.dataStore.ui.selectedItem['@ID'] !== "") {
            let temp = this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.AutoModules.AutoModule.find(
                obj => obj['@ID'] === this.props.dataStore.ui.selectedItem['@ID']);
            if (!temp) return;
            let item = JSON.parse(JSON.stringify(temp));

            item['@Name'] = this.props.dataStore.ui.selectedItem['@Name'];
            item['@Delete'] = "true";

            this.props.action.ui.removeAutoModule({ masterCrud: this.props.dataStore.crud.data, uiData: item });
        }
    }

    public onClearRemoveConfirm(e: any) {
        this.onConfirmRemove('');
    }

    public onClickRemoveButton(e: React.SyntheticEvent) {
        if (this.props.dataStore.ui.selectedItem['@ID'] === "")
            return;

        const msg = "Do you want to remove '" + this.props.dataStore.ui.selectedItem['@Name'] + "' ? This action cannot be undone.";
        this.onConfirmRemove(msg);
    }

    public onConfirmRemove(msg: string) {
        this.props.action.ui.errorAutoModuleRemoveConfirm({ masterCrud: this.props.dataStore.crud.data, uiData: { value: msg } });
    }

    public onSelectProcess(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectProcess(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }

    public onSelectChangeFilter(e: React.ChangeEvent<HTMLSelectElement>){
    
        this.props.action.ui.selectFilter(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }

    public onSelectWorkflowCode(e: React.ChangeEvent<HTMLSelectElement>){
        this.props.action.ui.selectWorkflowCode(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }

    public onSelectExportChanges(e: React.ChangeEvent<HTMLSelectElement>){
        this.props.action.ui.selectExportChanges(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }
    
    public onSelectSpinOffFormType(e: React.ChangeEvent<HTMLSelectElement>){
        this.props.action.ui.selectSpinOffFormType(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }

    public onSelectReportFormat(e: React.ChangeEvent<HTMLSelectElement>){
        this.props.action.ui.selectReportFormat(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }

    public onSelectReportGrouping(e: React.ChangeEvent<HTMLSelectElement>){
        this.props.action.ui.selectReportGrouping(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }

    public onSelectDelimiter(e: React.ChangeEvent<HTMLSelectElement>){
        this.props.action.ui.selectDelimiter(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }

    public onSelectQualifier(e: React.ChangeEvent<HTMLSelectElement>){
        this.props.action.ui.selectQualifier(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }
        
    public onSelectChangePrintFormType(e: React.ChangeEvent<HTMLSelectElement>){
        this.props.action.ui.selectPrintFormType(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }

    public onSelectPrintType(e: React.ChangeEvent<HTMLSelectElement>){
        this.props.action.ui.selectPrintType(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }

    public onSelectFontFace(e: React.ChangeEvent<HTMLSelectElement>){
        this.props.action.ui.selectFontFace(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }

    public onSelectFontSize(e: React.ChangeEvent<HTMLSelectElement>){
        this.props.action.ui.selectFontSize(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }
    
    public onSelectINameFieldSet(e: React.ChangeEvent<HTMLSelectElement>){
        this.props.action.ui.selectINameFieldSet(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }

    public onSelectPNameFieldSet(e: React.ChangeEvent<HTMLSelectElement>){
        this.props.action.ui.selectPNameFieldSet(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }
    public onSelectPrintAction(e: React.ChangeEvent<HTMLSelectElement>){
        this.props.action.ui.selectPrintAction(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }

    public onSelectPrinter(e: React.ChangeEvent<HTMLSelectElement>){
        this.props.action.ui.selectPrinter(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }
    

    public onClearAlert(e: any) {
        this.onAlert("");
    }

    public onAlert(msg: string) {
        this.props.action.ui.errorAutoModuleAlert({ masterCrud: this.props.dataStore.crud.data, uiData: { value: msg } });
    }

    public onSubmitNCS(e: any) {
        //console.log('onSubmitNCS::AutoModule');
        this.onAlert("");
        this.props.action.crud.updateWithDeltaNcs(this.props.dataStore.crud);
        this.props.history.push('/LandingPage');
    }

    public onOKSubmitAutoModule(e: React.ChangeEvent<HTMLButtonElement>) {

        // ncs disabled in legacy version
        // if (this.props.ncsIsEnabled) {
        //     this.props.action.ui.sendSubmitNCSAlert({ masterCrud: this.props.dataStore.crud, uiData: { value: this.props.ncsPromptText } });
        // } else {
        //     console.log('crud:');
        //     console.log(this.props.dataStore.crud);
        //     this.props.action.crud.updateWithDelta(this.props.dataStore.crud);
        //
        //     this.props.history.push('/LandingPage');
        // }
        
        // theres an issue with xmlDeltaCrud with a missing original item property updating, inserts are fine
        if(this.props.dataStore.crud.originalData) {
            if (this.props.dataStore.crud.originalData?.AutoModuleMaintenanceInfo.AutoModules.AutoModule) {
                this.props.dataStore.crud.originalData?.AutoModuleMaintenanceInfo.AutoModules.AutoModule.forEach(orig => {
                    if (!orig['@PrinterID']) {
                        orig['@PrinterID'] = '';
                    }
                });
            }
        }

        // console.log('crud:');
        // console.log(this.props.dataStore.crud);
        this.props.action.crud.updateWithDelta(this.props.dataStore.crud);

        this.props.history.push('/LandingPage');
    }

    public onExitWithNCS(e: any) {
        //console.log('onExitWithNCS: inserting NCS job...');
    }

    public onExit(e: any) {
        //console.log('onExit: bye...')
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    public onClearNCS(e: any) {
        //console.log('onClearNCS::AutoModule');
        this.props.action.ui.sendSubmitNCSAlert({ masterCrud: this.props.dataStore.crud, uiData: { value: '' } });
    }

    public onDenyNCS(e: any) {
        console.log('onDenyNCS::AutoModule');
        this.props.action.ui.sendSubmitNCSAlert({ masterCrud: this.props.dataStore.crud, uiData: { value: '' } });
        // now insert the crud to save at the current site...
        this.props.action.crud.updateWithDelta(this.props.dataStore.crud);

        this.props.history.push('/LandingPage');
    }


    public getAutoModulesForLeftSideDisplay() {
        
        let addItem: MCAM_AutoModule[] = [ 
            {
                "@ID" : '',
                "@Name": " - ADD A NEW AUTO MODULE - ",
                "@ModuleID": '',
                "@FilterID": '',
                "@Text": '',
                "@ReportActionID": '',
                "@Destination": '',
                "@Parameters": '',
            }
        ];
        
        return this.props.dataStore.crud.data ? addItem.concat(this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.AutoModules.AutoModule) : addItem;
    }
    
    public getProcessList(): any { 
        let allModules: IMCAMModules = { moduleList: []};
        
        if (this.props.dataStore.crud.data)
        {
            let isClientUser = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "isClientUser")?.value !== "False";
            let isEboUser = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "isEBOUser")?.value !== "False";
            let allowTenet = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "EnableTenetFeatures")?.value == "1";
            let directEntry = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "DirectEntry")?.value == "1";
            let ecs = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "EnhancedClaimStatusEnabled")?.value == "1";
            let mcaMethod = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "MCAMethod")?.value == "1";
            let eligibility = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "Eligibility")?.value == "1";
            let attachments = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "AttachmentsEnabled")?.value == "1";
            let mcde = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "MCDEEnabled")?.value == "1";
            let claimVision = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "CSFN_ISClaimVision")?.value == "1";
            
            if(this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.ModuleList.Module.length > 0 && this.state.reportFormApiData){
                let baseModules: IMCAMOption[] = [];
                let directEntryModules: IMCAMOption[] = [];
                let tenetModules: IMCAMOption[] = [];
                let outsourceModules: IMCAMOption[] = [];
                                
                let modules = this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.ModuleList.Module;
                let defaultOption: IMCAMOption = {'value' : '', 'label' : '- Select Process -' };
                baseModules.push(defaultOption);
                
                modules.forEach((moduleItem: any) => {
                    let moduleInfo: IMCAMOption = {'value' : moduleItem["@ID"], 'label' : moduleItem["@Name"] };
                    if(moduleItem["@ModuleType"] == '26' && (this.props.canSecCheck1 && attachments)) // authorized for attachments edit
                        baseModules.push(moduleInfo);
                    
                    // GetCookie("EnhancedClaimStatusEnabled") = "1"
                    if(moduleItem["@ModuleType"] == '23' && ecs)
                        baseModules.push(moduleInfo);
                    
                    if (moduleItem["@ModuleType"] != '19' && moduleItem["@ModuleType"] != '1' && moduleItem["@ModuleType"] != '23' && moduleItem["@ModuleType"] != '26' && (moduleItem["@ModuleType"] < 11 || moduleItem["@ModuleType"] > 13) && moduleItem["@ModuleType"] != '15')
                        //  obSecurity.IsNDCUser() Or InStr(1,objNode.getAttribute("Name"),"Run Bridge Routines",VBTextCompare)
                        if(!isClientUser || moduleItem["@Name"].indexOf("Run Bridge Routines") === -1)
                            //  AND GetCookie("Eligibility") = "1" Or InStr(1,objNode.getAttribute("Name"),"Eligibility",VBTextCompare) = 0
                            if(eligibility ||  moduleItem["@Name"].indexOf("Eligibility") === -1)
                                baseModules.push(moduleInfo);
                    
                    // GetCookie("DirectEntry") = "1"
                    if(moduleItem["@ModuleType"] == '13' && directEntry)
                        directEntryModules.push(moduleInfo);

                    //  obSecurity.Config(-1, "EnableTenetFeatures") = 1 
                    if((moduleItem["@ModuleType"] == '11' || moduleItem["@ModuleType"] == '12') && allowTenet) 
                        tenetModules.push(moduleInfo);

                    //  obSecurity.IsNDCUser() or obSecurity.IsEBOUser() (is not a client user??)
                    
                    if(moduleItem["@ModuleType"] == '15' && (!isClientUser || isEboUser)) 
                        outsourceModules.push(moduleInfo);
                    
                });
                
                allModules.moduleList.push({items : baseModules, label: ''});
                if(directEntry && directEntryModules.length > 0)
                    allModules.moduleList.push({items : directEntryModules, label: 'Direct Entry'});
                if(allowTenet && tenetModules.length > 0)
                    allModules.moduleList.push({items : tenetModules, label: 'Tenet'});
                if(!isClientUser || isEboUser && outsourceModules.length > 0)
                    allModules.moduleList.push({items : outsourceModules, label: 'Outsource'});
                
                let reportList = 11; // # of report types
                if(claimVision)
                    reportList = 12; // Include RF Pro
                
                for (let i = 1; i <= reportList; i++) { 
                    let apiReports = this.state.reportFormApiData;
                    let reportType = this.reportHash[i];
                    let reportModules: IMCAMOption[] = [];
                    let moduleList = modules.filter(ob => { return (ob["@ModuleType"] == '1' || ob["@ModuleType"] == '19') });
                    let addReport : boolean = false;
                    moduleList.forEach((reportItem: any) =>
                    {
                        let startIdx = reportItem["@ModuleDataFormat"].indexOf("-i") + 3;
                        let endIdx = startIdx + 4;
                        let reportId = reportItem["@ModuleDataFormat"].substring(startIdx, endIdx).trim();

                        let apiReport = apiReports[reportType.listName].find((obj: any) => obj['ID'] === reportId);
                        
                        if(apiReport) {
                            if ((apiReport.ID == '1861' || apiReport.ID == '1862') && mcaMethod) {
                                addReport = true;
                            } else if (apiReport.ID == '1850' && mcde) {
                                addReport = true;

                            } else if (apiReport.ID == '1551') {
                                if (this.props.canSecCheck1 && attachments) // edit attachments and attachmentsEnabled
                                    addReport = true;
                            } else {
                                // if you have eligibility cookie add it, otherwise check that the name of the module doesnt have it
                                if (eligibility)
                                    addReport = true;
                                else {
                                    if (apiReport.Name.indexOf("Eligibility") === -1)
                                        addReport = true;
                                }
                            }
                            if (addReport)
                                reportModules.push({'value': reportItem["@ID"], 'label': reportItem["@Name"]});
                                
                        }
                        addReport = false;
                    });
             
                    if(reportModules.length > 0) 
                        allModules.moduleList.push({items : reportModules, label: this.reportHash[i].reportName});
                }
                
            }
        }
        return allModules.moduleList;
    }
    
    public getWorkflowCodes() {
        let workflowItem: any[] = [
            { workFlowId: '', workFlowName: "- Select -" },
            { workFlowId: 'n', workFlowName: "None" },
        ];
        return this.state.workflowApiData ? workflowItem.concat(this.state.workflowApiData) : workflowItem;
    }

    public getToFormTypeData() {
        let formItem: MCAM_FormType[] = [
            {
                "@ID": '',
                "@Name": '- Select -',
            }
        ]
        return this.props.dataStore.crud.data ? formItem.concat(this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.FormList.Form) : formItem;
    }
    
    public getExportChangesOptions() : any[] {
        let result: any[] = [
            { value: '', label: "- Select -" },
            { value: '28', label: "Siemens 28 File"},
            { value: '7M', label: "Siemens 7M File"},
            { value: 'MS4', label: "Siemens MS4 File"},
        ];
        return result;
    }

    public getReportFormatData() : any[] {
        let result: any[] = [
            { value: '', label: "- Select -" }
        ];
        return result;
    }

    public getReportGroupingData() : any[] {
        let result: any[] = [
            { value: '', label: "- Select -" }
        ];
        return result;
    }

    public getFieldDelimiterData() : any[] {
        return [
            {value: '', label: "Default"},
            {value: '124', label: "|"},
            {value: '9', label: "Tab"},
            {value: '126', label: "~"},
            {value: '42', label: "*"},
            {value: '44', label: ","}
        ];
    }
    
    public getTextQualifierData() : any[] {
        return [
            {value: '', label: "Default"},
            {value: '34', label: "\""},
            {value: '96', label: "`"},
            {value: '0', label: "None"}
        ];
    }

    public getPrintFormTypeData() : any[] {
        let printFormTypes: MCAM_PrintOptionFormType[] = [
            { "@Value": '', "@PName": "Default" }
        ];

        if(this.props.dataStore.crud.data) {
            
        
            if (this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList.PrintOptionsFormType) {
                    // check to see if we've already added ADA forms
                if(this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList.PrintOptionsFormType.length > 5){
                    if(this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList.PrintOptionsFormType.find(ob => ob["@Value"] == '3010'))
                        this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList.PrintOptionsFormType.push({
                            "@Value" : '3010',
                            "@IName" : '', 
                            "@PName" : 'ADA-1999',
                            "@PClaimType" : '3010'
                        });
                    if(this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList.PrintOptionsFormType.find(ob => ob["@Value"] == '3011'))
                        this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList.PrintOptionsFormType.push({
                            "@Value" : '3011',
                            "@IName" : '',
                            "@PName" : 'ADA-2002',
                            "@PClaimType" : '3011'                            
                        });
                    if(this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList.PrintOptionsFormType.find(ob => ob["@Value"] == '3012'))
                        this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList.PrintOptionsFormType.push({
                            "@Value" : '3012',
                            "@IName" : '',
                            "@PName" : 'ADA-2006',
                            "@PClaimType" : '3012'
                        });
                }
                
                let formTypeList = this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList.PrintOptionsFormType;
                formTypeList.forEach(listItem => {
                    
                   if ( listItem["@IName"])
                        printFormTypes.push( { "@Value": listItem["@Value"], "@PName": listItem["@IName"] + "/" + listItem["@PName"]}); 
                   else
                       printFormTypes.push( { "@Value": listItem["@Value"], "@PName": (listItem["@PName"] ? listItem["@PName"] : '')});
                });
            }
        }
        return printFormTypes;
    }
    
    public getActionData() : any[] {
        let defaultItem: any[] = [
            { "@ID": '', "@Name": "- Select Action -" }
        ];
        return this.props.dataStore.crud.data ? defaultItem.concat(this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.ReportActionList.ReportAction) : defaultItem;      
    }

    public getPrinterData() : any[] {
        let defaultItem: any[] = [
            { "@ID": '', "@Name": "- Select Printer -" }
        ];
        return this.props.dataStore.crud.data ? defaultItem.concat(this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PrinterList.Printer) : defaultItem;
    }
    
    public getCommandSetNameLabel(isInst : boolean) : string {
        if(this.props.dataStore.ui.selectedPrintFormType){
            
            if(this.props.dataStore.crud.data){
                if (this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList.PrintOptionsFormType)
                {
                    let list = this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList.PrintOptionsFormType
                    let label = list.find(elem => elem["@Value"] == this.props.dataStore.ui.selectedPrintFormType)
                  
                    if(isInst) {
                        if (label && label["@IName"])
                            return label["@IName"];
                    }
                    else {
                        if (label && label["@PName"])
                            return label["@PName"];
                    }
                }
            }
        }
        
        return '';
    }

    public getPrintTypeData() : any[] {
        let defaultItem: any[] = [
            { "@Value": '', "@Name": "Default" }
        ];
        
        if(this.props.dataStore.ui.selectedPrintFormType){
            if (this.props.dataStore.ui.selectedPrintFormType == '3010' || this.props.dataStore.ui.selectedPrintFormType == '3011' || this.props.dataStore.ui.selectedPrintFormType == '3012' ){

                return this.props.dataStore.crud.data ? defaultItem.concat(this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.FormTypeList.FormType.find(ob => ob["@Name"] == "Gray Form")) : defaultItem;
            }
        }
        
        return this.props.dataStore.crud.data ? defaultItem.concat(this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.FormTypeList.FormType) : defaultItem;
    }

    public getFontFaceData() : any[] {
        let defaultItem: any[] = [
            { "@ID": '', "@Name": "Default" }
        ];
        return this.props.dataStore.crud.data ? defaultItem.concat(this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.FontFaceList.FontFace) : defaultItem;
    }

    public getFontSizeData() : any[] {
        let defaultItem: any[] = [
            { "@ID": '', "@Name": "Default" }
        ];
        return this.props.dataStore.crud.data ? defaultItem.concat(this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.FontSizeList.FontSize) : defaultItem;
    }

    public getCommandSetData(isInst : boolean) : any[] {
        let defaultItem: any[] = [
            { "@ConfigurationId": '', "@SetName": "Default" }
        ];

        if(this.props.dataStore.ui.selectedPrintFormType){

            if(this.props.dataStore.crud.data){
                if (this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList.PrintOptionsFormType)
                {
                    let list = this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList.PrintOptionsFormType
                    let printTypeItem = list.find(elem => elem["@Value"] == this.props.dataStore.ui.selectedPrintFormType)
                    
                    if(printTypeItem){
                        if(this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PaperCommandSetList.PaperCommandSet){
                            let commandSets = this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PaperCommandSetList.PaperCommandSet;
                            let setLists = commandSets.filter(ob => {
                                if(isInst) {
                                    let type = printTypeItem ? printTypeItem["@IClaimType"] : '';
                                    return (ob["@ClaimTypeId"] == type);
                                }
                                else{
                                    let type = printTypeItem ? printTypeItem["@PClaimType"] : '';
                                    return (ob["@ClaimTypeId"] == type);
                                }
                            });
                            return setLists.length > 0 ? defaultItem.concat(setLists) : defaultItem;
                        }
                    }
                        
                                        
                }
            }
        }
        return defaultItem;
        //return this.props.dataStore.crud.data ? this.props.dataStore.crud.data.AutoModuleMaintenanceInfo.PaperCommandSetList.PaperCommandSet : defaultItem;
    }

    public render() {

        const instruction = <React.Fragment>To add a new auto-module: Give it a name, choose the process, fill in the fields that activate, click Add, and then click OK to save. If Select Printer does not show any printers, you are not set up to auto-print. Call Customer Support and ask for the Agent.</React.Fragment>;
        var buttons = <OKCancelButtons disableOK={!this.props.dataStore.ui.changed || !this.props.canEdit} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOKSubmitAutoModule(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
  
        let data = this.getAutoModulesForLeftSideDisplay();
        let processList = this.getProcessList();
        //let filterData = this.getFilterData();
        let workflowData = this.getWorkflowCodes();
        let toFormTypeData = this.getToFormTypeData();
        let reportFormatData = this.getReportFormatData();
        let reportGroupingData = this.getReportGroupingData();
        let fieldDelimiterData = this.getFieldDelimiterData();
        let textQualifierData = this.getTextQualifierData();;
        let printFormTypeData = this.getPrintFormTypeData();
        let printTypeData = this.getPrintTypeData();
        let fontFaceData = this.getFontFaceData();
        let fontSizeData = this.getFontSizeData();
        let commandSetIData = this.getCommandSetData(true);
        let commandSetPData = this.getCommandSetData(false);
        let actionData = this.getActionData();
        let printerData = this.getPrinterData();
        let iNameLabel = this.getCommandSetNameLabel(true);
        let pNameLabel = this.getCommandSetNameLabel(false);

        let okText = "Yes";
        let cancelText = "No";

        if (this.props.dataStore.ui.changed)
            handleChange()
        return (
            <DialogWrapper title={this.props.title} instruction={instruction} helpUrl='/Support/Help/HELP_Maint_AutoModule.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper id={"content_wrapper_AutoModule"}>
                    <AutoModuleLeftContent>
                        <DialogFieldset>
                            <DialogLegend>Auto Modules</DialogLegend>
                            <SelectComponent
                                title='autoModules'
                                size={34}
                                width='328px'
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectAutoModule(e)}
                                optionFields={{
                                    value: "@ID",
                                    text: "@Name"
                                }}
                                records={data}
                                selectedValue={this.props.dataStore.ui.selectedAutoModule.id}>
                            </SelectComponent>
                        </DialogFieldset>
                    </AutoModuleLeftContent>
                    <AutoModuleContent>
                        <SelectActions>
                                <Input
                                    domID="automodule-name"
                                    className="text-input"
                                    label="*Auto Module Name:"
                                    maxLength={50}
                                    initialValue={this.props.dataStore.ui.selectedItem['@Name']}
                                    hasError={!!this.props.dataStore.ui.autoModuleNameError}
                                    errorMessage={this.props.dataStore.ui.autoModuleNameError}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateAutoModule_Name(e.target.value)}/>
                            <SelectGroupComponent
                                size={1}
                                label='*Process:'
                                width='580px'
                                height='35px'
                                fontSize='12px'
                                isUpper={false}
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectProcess(e)}
                                optionGroups={{
                                    label: "value",
                                    items: [{
                                        value: "value",
                                        text: "text",
                                    }]
                                }}
                                selectedValue={this.props.dataStore.ui.selectedItem['@ModuleID']}
                                records={processList}
                            >
                            </SelectGroupComponent>  
                            <CustomWrapper2>                        
                            <Input
                                domID="automodule-desc"
                                className="text-input"
                                label="Description:"
                                maxLength={256}
                                initialValue={this.props.dataStore.ui.selectedItem['@Text']}
                                hasError={!!this.props.dataStore.ui.autoModuleNameError}
                                errorMessage={this.props.dataStore.ui.autoModuleNameError}
                                onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateAutoModule_Desc(e.target.value)}/>
                            </CustomWrapper2>  
                        </SelectActions>
                        <DialogFieldset>
                            <DialogLegend>Parameters</DialogLegend>
                            <ContentColumnWrapper style={{marginLeft:'5px'}}>
                                <SelectComponent 
                                    label={this.props.dataStore.ui.filterLabel}
                                    title='automodule_filter'
                                    size={1}
                                    isUpper={false}
                                    width='465px'
                                    multiple='false'
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectChangeFilter(e)}
                                    optionFields={{
                                        value: "@ID",
                                        text: "@Name"
                                    }}
                                    disabled={this.props.dataStore.ui.filterDisabled}
                                    selectedValue={this.props.dataStore.ui.selectedItem['@FilterID']}
                                    records={this.props.dataStore.ui.filterList}
                                />

                                {!this.props.dataStore.ui.workflowCodesHidden && <CustomWrapper>
                                <SelectComponent
                                    label='Workflow Code Set:'
                                    title='automodule_workflow'
                                    size={1}
                                    width='465px'
                                    multiple='false'
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectWorkflowCode(e)}
                                    //disabled={this.props.dataStore.ui.workflowCodesHidden}
                                    selectedValue={this.props.dataStore.ui.selectedWorkflowCode}
                                    optionFields={{
                                        value: "workFlowId",
                                        text: "workFlowName"
                                    }}
                                    records={workflowData}
                                />
                                </CustomWrapper>
                                }
                            </ContentColumnWrapper>
                            <ContentRowWrapper>
                                <DialogFieldset style={{marginRight:'0px',width:'245px'}} >
                                    <DialogLegend>Export Changes</DialogLegend>
                                   
                                        <SelectComponent
                                            label='Format:'
                                            title='automodule_format'
                                            size={1}
                                            width='220px'
                                            multiple='false'
                                            disabled={this.props.dataStore.ui.exportChangesFormatDisabled}
                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectExportChanges(e)}
                                            selectedValue={this.props.dataStore.ui.selectedExportChangesFormat}
                                            optionFields={{
                                                value: "value",
                                                text: "label"
                                            }}
                                            records={this.getExportChangesOptions()}
                                        />
                                    
                                </DialogFieldset>
                                <DialogFieldset style={{marginLeft:'0px',width:'55%'}}>
                                    <DialogLegend>Spin Off</DialogLegend>
                                  
                                        <SelectComponent
                                            label='To Form Type:'
                                            title='automodule_toFormtype'
                                            size={1}
                                            width='295px'
                                            multiple='false'
                                            disabled={this.props.dataStore.ui.spinOffFormTypeDisabled}
                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectSpinOffFormType(e)}
                                            optionFields={{
                                                value: "@ID",
                                                text: "@Name"
                                            }}
                                            selectedValue={this.props.dataStore.ui.selectedSpinOffFormType}
                                            records={toFormTypeData}
                                        />
                                    
                                </DialogFieldset>
                            </ContentRowWrapper>
                            <ContentRowWrapper>
                                <ContentColumnWrapper>
                                <DialogFieldset>
                                    <DialogLegend>Report</DialogLegend>
                                    <ContentRowWrapper>
                                        <SelectComponent
                                            label='Format:'
                                            title='automodule_format'
                                            size={1}
                                            width='100px'
                                            multiple='false'
                                            disabled={this.props.dataStore.ui.reportFormatDisabled}
                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectReportFormat(e)}
                                            optionFields={{
                                                value: "Type",
                                                text: "Type"
                                            }}
                                            selectedValue={this.props.dataStore.ui.selectedReportFormat}
                                            records={this.props.dataStore.ui.formatList}
                                        />
                                        <SelectComponent
                                            label='Grouping:'
                                            title='automodule_grouping'
                                            size={1}
                                            width='100px'
                                            multiple='false'
                                            disabled={this.props.dataStore.ui.reportGroupingDisabled}
                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectReportGrouping(e)}
                                            optionFields={{
                                                value: "value",
                                                text: "label"
                                            }}
                                            selectedValue={this.props.dataStore.ui.selectedReportGrouping}
                                            records={this.props.dataStore.ui.groupingList}
                                        />                                        
                                    </ContentRowWrapper>                                 
                                </DialogFieldset>
                                    <DialogFieldset>
                                        <DialogLegend>CSV Options Override</DialogLegend>
                                        <ContentRowWrapper>
                                            <SelectComponent
                                                label='Field Delimiter:'
                                                title='automodule_fieldDelimiter'
                                                size={1}
                                                width='85px'
                                                multiple='false'
                                                disabled={this.props.dataStore.ui.csvOptionsDelimiterDisabled}
                                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectDelimiter(e)}
                                                optionFields={{
                                                    value: "value",
                                                    text: "label"
                                                }}
                                                selectedValue={this.props.dataStore.ui.selectedDelimiter}
                                                records={fieldDelimiterData}
                                            />
                                            <SelectComponent
                                                label='Text Qualifier:'
                                                title='automodule_textqualifier'
                                                size={1}
                                                width='85px'
                                                multiple='false'
                                                disabled={this.props.dataStore.ui.csvOptionsQualifierDisabled}
                                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectQualifier(e)}
                                                optionFields={{
                                                    value: "value",
                                                    text: "label"
                                                }}
                                                selectedValue={this.props.dataStore.ui.selectedQualifier}
                                                records={textQualifierData}
                                            />
                                        </ContentRowWrapper>
                                    </DialogFieldset>
                                </ContentColumnWrapper>      
                                <PrintOptionsWrapper>
                                    <DialogFieldset4>
                                        <DialogLegend>Print Options Override</DialogLegend>
                                        <ContentColumnWrapper>
                                            <ContentRowWrapper>
                                            <SelectComponent
                                                label='Form Type:'
                                                title='automodule_formType'
                                                size={1}
                                                width='150px'
                                                multiple='false'
                                                disabled={this.props.dataStore.ui.printOptionsFormTypeDisabled}
                                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectChangePrintFormType(e)}
                                                optionFields={{
                                                    value: "@Value",
                                                    text: "@PName"
                                                }}
                                                selectedValue={this.props.dataStore.ui.selectedPrintFormType}
                                                records={printFormTypeData}
                                            />
                                            <SelectComponent
                                                label='Print Type:'
                                                title='automodule_printType'
                                                size={1}
                                                width='150px'
                                                multiple='false'
                                                disabled={this.props.dataStore.ui.printOptionsPrintTypeDisabled}
                                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectPrintType(e)}
                                                optionFields={{
                                                    value: "@ID",
                                                    text: "@Name"
                                                }}
                                                selectedValue={this.props.dataStore.ui.selectedPrintType}
                                                records={printTypeData}
                                            />
                                            </ContentRowWrapper>
                                            <ContentRowWrapper>
                                            <SelectComponent
                                                label='Font Face:'
                                                title='automodule_fontface'
                                                size={1}
                                                width='150px'
                                                multiple='false'
                                                disabled={this.props.dataStore.ui.printOptionsFontFaceDisabled}
                                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectFontFace(e)}
                                                optionFields={{
                                                    value: "@ID",
                                                    text: "@Name"
                                                }}
                                                selectedValue={this.props.dataStore.ui.selectedFontFace}
                                                records={fontFaceData}
                                            />
                                            <SelectComponent
                                                label='Font Size:'
                                                title='automodule_fontsize'
                                                size={1}
                                                width='150px'
                                                multiple='false'
                                                disabled={this.props.dataStore.ui.printOptionsFontSizeDisabled}
                                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectFontSize(e)}
                                                optionFields={{
                                                    value: "@ID",
                                                    text: "@Name"
                                                }}
                                                selectedValue={this.props.dataStore.ui.selectedFontSize}
                                                records={fontSizeData}
                                            /> 
                                            </ContentRowWrapper> 
                                            {(iNameLabel || pNameLabel) &&
                                            <label id="automodule-commandset-label">COMMAND SET:</label>
                                            }
                                                <ContentRowWrapper>
                                                    {iNameLabel &&
                                                    <SelectComponent
                                                        label={iNameLabel + ':'}
                                                        title='automodule_inameset'
                                                        size={1}
                                                        width='150px'
                                                        multiple='false'
                                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectINameFieldSet(e)}
                                                        optionFields={{
                                                            value: "@ConfigurationId",
                                                            text: "@SetName"
                                                        }}
                                                        records={commandSetIData}
                                                        selectedValue={this.props.dataStore.ui.instPaperCommandSetConfigId}
                                                    />
                                                    }
                                                    {pNameLabel &&
                                                    <SelectComponent
                                                        label={pNameLabel + ':'}
                                                        title='automodule_pnameset'
                                                        size={1}
                                                        width='150px'
                                                        multiple='false'
                                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectPNameFieldSet(e)}
                                                        optionFields={{
                                                            value: "@ConfigurationId",
                                                            text: "@SetName"
                                                        }}
                                                        records={commandSetPData}
                                                        selectedValue={this.props.dataStore.ui.profPaperCommandSetConfigId}
                                                    />
                                                    }
                                                </ContentRowWrapper>
                                        </ContentColumnWrapper>
                                    </DialogFieldset4>
                                </PrintOptionsWrapper>                                   
                                </ContentRowWrapper>
                            </DialogFieldset>
                        <DialogFieldset>
                            <DialogLegend>Auto Download and Print</DialogLegend>
                            <ContentRowWrapper>
                                <SelectComponent
                                    label='Action:'
                                    title='automodule_printAction'
                                    size={1}
                                    width='200px'
                                    multiple='false'
                                    disabled={this.props.dataStore.ui.actionDisabled}
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectPrintAction(e)}
                                    optionFields={{
                                        value: "@ID",
                                        text: "@Name",
                                        disabled: "disabled"
                                    }}
                                    records={this.props.dataStore.ui.reportActionList}
                                    selectedValue={this.props.dataStore.ui.selectedReportAction}
                                />
                                <SelectComponent
                                    label='Printer:'
                                    title='automodule_printer'
                                    size={1}
                                    width='300px'
                                    multiple='false'
                                    disabled={this.props.dataStore.ui.printerDisabled}
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectPrinter(e)}
                                    optionFields={{
                                        value: "@ID",
                                        text: "@Name"
                                    }}
                                    selectedValue={this.props.dataStore.ui.selectedPrinter}
                                    records={printerData}
                                />
                            </ContentRowWrapper>
                            <Input
                                domID="automodule-dest"
                                className="text-input"
                                label="Destination:"
                                maxLength={256}
                                
                                disabled={this.props.dataStore.ui.destinationDisabled}
                                initialValue={this.props.dataStore.ui.selectedItem['@Destination']}
                                hasError={!!this.props.dataStore.ui.autoModuleNameError}
                                errorMessage={this.props.dataStore.ui.autoModuleNameError}
                                onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateAutoModule_Destination(e.target.value)}/>
                            
                        </DialogFieldset>
                        <ContentRowWrapper>
                            <SelectButtons>
                                <Button
                                    domID="addButton"
                                    name={this.props.dataStore.ui.selectedItem['@ID'] !== "" ? "Update": "Add"}
                                    buttonType="emphasized"
                                    size="control"
                                    type="button"
                                    disabled={!this.props.canEdit }

                                    onClick={(e: React.SyntheticEvent) => this.onClickModifyButton(e)}/>
                                <Button
                                    domID="removeButton"
                                    name="Remove"
                                    buttonType="standard"
                                    size="control"
                                    type="button"
                                    disabled={!this.props.canEdit || this.props.dataStore.ui.selectedItem['@ID'] === ""}

                                    onClick={(e: React.SyntheticEvent) => this.onClickRemoveButton(e)}/>
                            </SelectButtons>
                        </ContentRowWrapper>                        
                        {/*<ModalConfirmation*/}
                        {/*    isOpen={!!this.props.dataStore.ui.submitNCSAlert && this.props.dataStore.ui.submitNCSAlert.length > 0}*/}
                        {/*    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearNCS(e)}*/}
                        {/*    message={this.props.dataStore.ui.submitNCSAlert}*/}
                        {/*    okText={okText}*/}
                        {/*    cancelText={cancelText}*/}
                        {/*    showDefaultClose={true}*/}
                        {/*    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onSubmitNCS(e)}*/}
                        {/*    onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.onDenyNCS(e)}*/}
                        {/*/>*/}
                        <ModalConfirmation
                            isOpen={!!this.props.dataStore.ui.autoModuleAlert && this.props.dataStore.ui.autoModuleAlert.length > 0}
                            onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearAlert(e)}
                            alertMode={true}
                            message={this.props.dataStore.ui.autoModuleAlert}
                            onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClearAlert(e)}
                        />
                        <ModalConfirmation
                            isOpen={!!this.props.dataStore.ui.autoModuleRemoveConfirm && this.props.dataStore.ui.autoModuleRemoveConfirm.length > 0}
                            onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearRemoveConfirm(e)}
                            message={this.props.dataStore.ui.autoModuleRemoveConfirm}
                            onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onRemoveButton(e)}
                        />
                        {/*<ModalConfirmation*/}
                        {/*    isOpen={!!this.props.dataStore.ui.autoModuleRemoveScheduleConfirm && this.props.dataStore.ui.autoModuleRemoveScheduleConfirm.length > 0}*/}
                        {/*    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearRemoveScheduleConfirm(e)}*/}
                        {/*    message={this.props.dataStore.ui.autoModuleRemoveScheduleConfirm}*/}
                        {/*    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onRemoveScheduleButton()}*/}
                        {/*/>*/}
                    </AutoModuleContent>
                </ContentWrapper>
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IAutoModuleState, IAutoModuleActionProps, IOwnProps, IAutoModuleProps, ApplicationState>(
    createCrudMapStateToProps('autoModule'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(AutoModule);

export default withRouter(connectedHoc);
