import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled, { createGlobalStyle } from 'styled-components';
import { Input, Button, Grid, Section, SelectDropdown, Toggle } from '@optum-uicl/ui-core/dist';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { SelectComponent } from '../../common/SelectComponent';
import { EditRow, SeSearchParam, SeSearchRequest } from '@store/ui/SupplementalEditsMaintenanceUI';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { DialogLegend, DialogFieldset, DialogLabel } from '@common/DialogStyles';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';
import { ARMHeaderCell } from '@common/UICLWrappers/ARMHeaderCell';
import { SortHelper, ISortIndicator, SortDataType, SortOrder } from "@scripts/util/SortHelper";
import './supplementcss.css';

import {
    IMergeCrudComponentProps,
    createCrudMapStateToProps,
    createCrudMapDispatchToProps,
    mergeCrudComponentProps,
    resetCrudComponentState
} from '@scripts/util/CrudComponentHelpers';

import { ISupplementalEditMaintenanceState, SupplementalEditsMaintenanceActionsProps, actionCreators } from
    '@store/SupplementalEditMaintenance';
import { ARMRowCellToolTip, IGridRowCellProps } from './GridCellWithTooltip';
import { ColumnType, RowType } from '@optum-uicl/ui-core/dist/Organisms/Grid/types';
//
//HARD-CODED STYLES
//
// Need to include width 100% when setting "display: flex" for IE 11
//https://stackoverflow.com/questions/21600345/flexbox-and-internet-explorer-11-displayflex-in-html
const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
& th {
    z-index: 1;}
& .iwdhyE {
    z-index: 0;}
    & .dd200 {
        width: 200px;
    }
`;

const PadBottomWrapper = styled.div`
    padding-bottom: 12px;
`;

const Breaker = styled.div`
   flex-basis: 100%;
   height: 0;
}
`;

export const LabelWrapper = styled.div`
    display: inline;
    margin-bottom: 0.3rem;
    color: #37474F;
    font-family: 'optum-sans-regular';
    font-size: 11px;
    line-height: 1.4em;
    font-weight: 400 !important;
    letter-spacing: 0.5px;
    text-transform: uppercase;
`;

const InputContainer = styled.div`
    display: flex;
    height:auto;
    flex-wrap: wrap;
    width:100%;
    Section
       { 
        width:100%;
        font-size:8pt;
        padding:5px !important;
            }
            #test-header h3 {
                display: none;
            }
    #test-header.header{
        margin-top: 0px !important;
        padding: 0px !important;
    
}

#testSample{
    width:200px !important;
}

#grid-wrapper-test-id table thead th: nth-child(1){
    width:200px;
}


#basic-test-id{
    width:190px !important;
}
#expression, #dvarName{
    width: 190px !important;
}
.option-class{
    font-size:12px !important;
    display: table !important;
}
#test-body{
    padding: 0px !important;
}

#automation-id{
   
}
#addremovebutton{
  
}
#supplementEditCriteriaSelect{
    width:300px !important;
}

table#test-id thead tr th:nth-child(1) {max-width: 140px !important;width: 130px !important;min-width: 130px !important;}
table#test-id thead tr th:nth-child(2) button {margin: 5px 10px 5px 5px }
table#test-id thead tr th:nth-child(2) {min-width: 400px;padding-left:5px !important;}
table#test-id thead tr th:nth-child(3) {min-width: 100px;}
table#test-id thead tr th:nth-child(4) {min-width: 100px;}
table#test-id tbody tr td:nth-child(1) {padding-left:16px !important;}
table#test-id thead tr th {text-transform:capitalize}

#test-id>thead>tr {
    background: #fff !important;
}

#test-id tbody tr:nth-child(-n+3) .tooltiptext {
    border: 1px solid black;
    background: white;
    overflow:visible;
    min-width: 100px;
    max-width: 500px;
    background-color: white;
    text-align: left;
    padding: 5px;
    left: -5%;
    font-size: 10pt;
    /* Position the tooltip */
    position: absolute;
    z-index: 9999999;
    top:100%;
    height: fit-content;
}

td:empty{
display:none;
}


`;

const InlineHeader = styled.div`   
    width:98%;
    margin: auto;
    background-color: #e1e2e6;
    border: 1px solid #9ba1a9;
    padding: 3px;
`;

const GlobalStyles = createGlobalStyle` 
        #dvarName-select-menu .selectDropdown__menu, #expression-select-menu .selectDropdown__menu
       
{ 
         width: 190px !important;
    }
    #dvarName-select-menu .selectDropdown__menu button, #expression-select-menu .selectDropdown__menu button
    {
        padding: 8px;
    }
    
    `;

const SortIcon = styled.div`
    button {
    font-weight: 400;
    text-transform: capitalize;
    }
    button > .caret-span {
        fill-opacity: 0;
    }
    button:hover > .caret-span {
        fill-opacity: 1;
    }
    `
const AddRemoveButton = styled.div`
button {
    width: 110px;
    text-align: center;
    margin-top: 10px;
}
`;

//
// INTERFACE, TYPE, AND CLASS DEFINITIONS
//
interface IComponentProps {
    canView: boolean,
    canEdit: boolean,
    title: string,
}

export const DEFAULT_STATE: IComponentState = {
    nameSearchText: "",
    shortDescSearchText: "",
    longDescSearchText: "",
    disableAddUpdateButton: false,
    disabledDeleteButton: false,
    isAlertOpen: false,
    alertMessage: "",
    objectValueForEdit: {},
    currentState: {},
    isAlertOpenForValidation: false,
    alertMessageForValidation: "",
    cancelLeave: false,
    sortIndicator: {
        sortColumn: '',
        sortDirection: '',
    },
    isSearchChange: false,
    isAlertOpenForSearch: false,
    alertMessageForSearch: "",
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type SupplementalEditMaintenanceProps = IMergeCrudComponentProps<ISupplementalEditMaintenanceState, SupplementalEditsMaintenanceActionsProps, IOwnProps>;

interface HeaderProps { columns: {} }

interface IComponentState {
    nameSearchText: string,
    shortDescSearchText: string,
    longDescSearchText: string,
    disableAddUpdateButton: boolean,
    disabledDeleteButton: boolean,
    isAlertOpen: boolean,
    alertMessage: string,
    objectValueForEdit: {},
    currentState: {},
    isAlertOpenForValidation: boolean,
    alertMessageForValidation: string,
    cancelLeave: boolean,
    sortIndicator: {
        sortColumn: string,
        sortDirection: string
    },
    isSearchChange: boolean,
    isAlertOpenForSearch: boolean,
    alertMessageForSearch: string,
}

enum eModifyButtonType {
    Add,
    Update
}

export class SupplementalEditsMaintenance extends React.Component<SupplementalEditMaintenanceProps, IComponentState> {
    static defaultProps: IComponentProps = {
        canEdit: true,
        canView: true,
        title: "Supplemental Edits Maintenance",
    };

    constructor(props: SupplementalEditMaintenanceProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.handleSort = this.handleSort.bind(this);
    }


    componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }
        pageLeave();
        this.props.action.ui.clearSearchFields();
        this.props.action.ui.clearAllData();
    }

    //Needed to use component did update to ensure actions fired the expected number of times and in the expected order.
    async componentDidUpdate() {

    }

    public onClear() {
        this.props.action.ui.clearSearchFields();
        this.setState({
            sortIndicator: {
                sortColumn: '',
                sortDirection: '',
            },
        })
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    //Kick off the validate -> submit -> redirect action chain.
    // ReSharper disable once InconsistentNaming
    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        let criteriaDetails: any = [];
        let supplementalEditDetails: any = [];
        let supplementEditList = this.props.dataStore.ui.supplementalEditList;
        if (supplementEditList !== undefined && supplementEditList.length > 0) {
            supplementEditList.forEach((item, index) => {
                let newObjectSupplementEdit = {
                    "@ID": item["@ID"],
                    "@RuleEnabled": item["@RuleEnabled"],
                    "@DRLClientRulesInx": item["@DRLClientRulesInx"] === 0 ? "New[" + index + "]" : item["@DRLClientRulesInx"],
                    "@RuleRequired": item["@RuleRequired"]

                }
                supplementalEditDetails.push(newObjectSupplementEdit);
            })
        }


        let ruleIndex: number = 0;
        let drlRulesReference: number = 0;

        let criteriaList: MCCriteria[] = [];
        if (this.props.dataStore.ui.criteriaList !== undefined && this.props.dataStore.ui.criteriaList !== null && this.props.dataStore.ui.criteriaList.length > 0) {
            criteriaList = this.props.dataStore.ui.criteriaList.sort((a, b) => {
                return parseInt(a["@DRLRulesReferenceInx"]) - parseInt(b["@DRLRulesReferenceInx"]);
            });
            criteriaList.forEach((obj) => {
                if (obj["@Delete"] !== true && (obj["@Add"] === true || obj["@Update"] === true)) {
                    if (parseInt(obj["@DRLRulesReferenceInx"]) === drlRulesReference) {
                        ruleIndex++;
                    }
                    else {
                        ruleIndex = 0;
                    }
                    let newObject = {
                        "@DRLRulesReferenceInx": obj["@DRLRulesReferenceInx"],
                        "@FieldName": obj["@FieldName"],
                        "@FieldDVARName": obj["@FieldDVARName"],
                        "@Expression": obj["@Expression"],
                        "@DRLClientRulesInx": obj["@Add"] === true ? "New[" + ruleIndex + "]" : obj["@DRLClientRulesInx"],
                        "@CriteriaData": obj["@CriteriaData"]
                    }

                    drlRulesReference = parseInt(obj["@DRLRulesReferenceInx"]);
                    criteriaDetails.push(newObject);
                }
                else if (obj["@Delete"] === true) {
                    let newObject = {
                        "@DRLRulesReferenceInx": obj["@DRLRulesReferenceInx"],
                        "@FieldName": obj["@FieldName"],
                        "@FieldDVARName": obj["@FieldDVARName"],
                        "@Expression": obj["@Expression"],
                        "@DRLClientRulesInx": obj["@DRLClientRulesInx"],
                        "@CriteriaData": obj["@CriteriaData"],
                        "@Delete": obj["@Delete"] ? "true" : "false"
                    }
                    criteriaDetails.push(newObject);
                }

            })
        }


        const data = {
            crudId: this.props.dataStore.crud.crudId,
            data: {
                SupplementalEditMaintenanceInfo: {
                    SupplementalEditList: {
                        SupplementalEdit: supplementalEditDetails
                    },
                    CriteriaList: {
                        Criteria: criteriaDetails
                    }
                }

            }
        }
        console.log("Final Data", data);
        this.props.action.crud.update(data);

        this.props.action.ui.updateLoadingFlag(true);
        setTimeout(() => {
            this.props.action.ui.clearAllData();
            this.props.action.ui.updateLoadingFlag(false);
            this.props.history.push('/LandingPage')
        }, 3000)
    }

    //If the user wants to cancel, kick them back to the landing page.
    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    getLongDescription() {
        return this.props.dataStore.ui.selectedEdit ? this.props.dataStore.ui.selectedEdit['@LongDescription'] : "";
    }

    public handleSort(sortIndicator: ISortIndicator) {
        let sortedRecords: any = [];
        switch (sortIndicator.sortColumn) {
            case 'Name': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    this.props.dataStore.ui.edits.sort((a: any, b: any) => (a["@EditName"]).toString().localeCompare((b["@EditName"]).toString())) :
                    this.props.dataStore.ui.edits.sort((a: any, b: any) => (b["@EditName"]).toString().localeCompare((a["@EditName"]).toString()));
            }
                break;
            case 'Description': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    this.props.dataStore.ui.edits.sort((a: any, b: any) => (a["@Description"]).toString().localeCompare((b["@Description"]).toString())) :
                    this.props.dataStore.ui.edits.sort((a: any, b: any) => (b["@Description"]).toString().localeCompare((a["@Description"]).toString()));
            }
                break;
            case 'Enabled': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    this.props.dataStore.ui.edits.sort((a: any, b: any) => (a["@RuleEnabled"]).toString().localeCompare((b["@RuleEnabled"]).toString())) :
                    this.props.dataStore.ui.edits.sort((a: any, b: any) => (b["@RuleEnabled"]).toString().localeCompare((a["@RuleEnabled"]).toString()));
            }
                break;
            case 'Required': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    this.props.dataStore.ui.edits.sort((a: any, b: any) => (a["@RuleRequired"]).toString().localeCompare((b["@RuleRequired"]).toString())) :
                    this.props.dataStore.ui.edits.sort((a: any, b: any) => (b["@RuleRequired"]).toString().localeCompare((a["@RuleRequired"]).toString()));
            }
                break;
        }
        this.props.action.ui.updateEdits(sortedRecords);
        this.setState({ sortIndicator });
    }

    private createSearchParam(name: string, value: string, type?: string): SeSearchParam {
        return type
            ? { Name: name, Value: value, Type: type }
            : { Name: name, Value: value };
    }

    /**
     * Take in the current name, short description, and long description values in the search inputs and
     * pass them along in a SelectiveGetXml request to the master crud controller.
     */
    crudSearch(name: string, shortDesc: string, longDesc: string): SeSearchRequest {
        // Create default search request with standard values.
        this.props.action.ui.updateLoadingFlag(true);
        const searchRequest: SeSearchRequest = {
            Param: [
                this.createSearchParam("@intPageSize", "0", "int"),
                this.createSearchParam("@intStartPage", "0", "int"),
                this.createSearchParam("@intPageCount", "0", "int"),
                this.createSearchParam("@intTotalPages", "0", "int"),
            ]
        }

        searchRequest.Param.push(this.createSearchParam("@chvFilterEditName", name));
        searchRequest.Param.push(this.createSearchParam("@chvFilterShortDesc", shortDesc));
        searchRequest.Param.push(this.createSearchParam("@chvFilterLongDesc", longDesc));

        searchRequest.Param.push(this.createSearchParam("@chvSortBy", ""));
        setTimeout(() => {
            this.setState({
                sortIndicator: {
                    sortColumn: 'Name',
                    sortDirection: 'up',
                }
            })
        }, 100)
        return searchRequest;
    }

    getGridColumnHeaders(): Set<ColumnType> {
        return new Set<ColumnType>([
            {
                dataName: "updatedEdit",
                text: 'Name',
                sortable: true,
                cellType: 'custom',
                customComponent: ARMRowCellToolTip as unknown as React.FunctionComponent<IGridRowCellProps>,
                isSorted: 0,
            },
            {
                dataName: "updatedDesc",
                text: "Description",
                sortable: true,
                cellType: 'custom',
                customComponent: ARMRowCellToolTip as unknown as React.FunctionComponent<IGridRowCellProps>,
                isSorted: 0,
            },
            {
                dataName: "EnabledToggle",
                text: "Enabled",
                sortable: true,
                cellType: "toggle",
                isSorted: 0,
            },
            {
                dataName: "RequiredToggle",
                text: "Required",
                sortable: true,
                cellType: "toggle",
                isSorted: 0,
            },
        ]);
    }

    public onSelectGroup(e: React.ChangeEvent<HTMLSelectElement>) {

    }

    public OnClick_Add() {

        switch (this.getModifyButtonType()) {
            case eModifyButtonType.Add:
                if (this.props.dataStore.ui.DVARName !== null && this.props.dataStore.ui.DVARName !== "") {
                    if (this.props.dataStore.ui.Expression !== null && this.props.dataStore.ui.Expression !== "") {
                        if (this.props.dataStore.ui.input !== null && this.props.dataStore.ui.input !== "") {
                            handleChange();
                            this.setState({ isSearchChange: true });
                            let dvarName = this.props.dataStore.ui.criteriaFieldList.find((obj) => {
                                return obj["@DVARName"] === this.props.dataStore.ui.DVARName;
                            })
                            let selectedEditValue = this.props.dataStore.ui.selectedEdit !== null && this.props.dataStore.ui.selectedEdit !== undefined ? this.props.dataStore.ui.selectedEdit : {};
                            let dataRulesInx = "@DRLClientRulesInx" in selectedEditValue ? (selectedEditValue?.["@DRLClientRulesInx"]) as Number : 0;
                            let rowIdInx = this.props.dataStore.ui.selectedEdit?.["@ID"];
                            this.props.action.ui.addSupplementsEditCriteria({ id: dataRulesInx.toString() + "-" + rowIdInx + "-" + dvarName?.["@FieldName"] + "-" + this.props.dataStore.ui.Expression + "-" + this.props.dataStore.ui.input, value: dvarName?.["@FieldName"] + "-" + this.props.dataStore.ui.Expression + "-" + this.props.dataStore.ui.input });

                        }
                        else {
                            this.setState({ isAlertOpenForValidation: true, alertMessageForValidation: "There is no Criteria Value Field" });
                        }
                    }
                }
                break;
            case eModifyButtonType.Update:
                if (this.props.dataStore.ui.DVARName !== null && this.props.dataStore.ui.DVARName !== "") {
                    if (this.props.dataStore.ui.Expression !== null && this.props.dataStore.ui.Expression !== "") {
                        if (this.props.dataStore.ui.input !== null && this.props.dataStore.ui.input !== "") {
                            handleChange();
                            this.setState({ isSearchChange: true });
                            let dvarName = this.props.dataStore.ui.criteriaFieldList.find((obj) => {
                                return obj["@DVARName"] === this.props.dataStore.ui.DVARName;
                            })
                            this.props.action.ui.updateSupplementsEditCriteria({ id: this.props.dataStore.ui.supplementEditCriteriaId, value: dvarName?.["@FieldName"] + "-" + this.props.dataStore.ui.Expression + "-" + this.props.dataStore.ui.input });

                        }
                        else {
                            this.setState({ isAlertOpenForValidation: true, alertMessageForValidation: "There is no Criteria Value Field" });
                        }
                    }
                }
                break;
        }
    }

    public OnClick_Remove() {
        if (this.props.dataStore.ui.supplementEditCriteriaId !== null && this.props.dataStore.ui.supplementEditCriteriaId !== "0") {
            handleChange();
            this.setState({ isSearchChange: true });
            let values = this.props.dataStore.ui.supplementEditCriteriaId.split('-');
            this.props.action.ui.removeSupplementsEditCriteria({ id: this.props.dataStore.ui.supplementEditCriteriaId, value: values[2] + "-" + values[3] + "-" + values[4] });
        }
    }

    public getSupplementEditCriteria() {

        if (!this.props.dataStore.ui.supplementEditCriteria) return [];
        let object = { value: "-- Add New Criteria --", label: "0" };
        let supplementEditCriteria: any = [];
        supplementEditCriteria.push(object);
        this.props.dataStore.ui.supplementEditCriteria.forEach((item) => {
            supplementEditCriteria.push(item);
        })
        supplementEditCriteria.push(this.props.dataStore.ui.supplementEditCriteria)


        return supplementEditCriteria !== null && supplementEditCriteria !== undefined ? supplementEditCriteria : [];
    }

    public onSelectSupplementEditCriteriaDetails(e: any) {
        if (e.target.value !== undefined && e.target.value !== null) {
            if (e.target.value !== "-- Add New Criteria --") {
                this.props.action.ui.updateSupplementEditCriteriaId(e.target.value);
                let supplementEditCriteria = e.target.value.split('-');
                if (supplementEditCriteria.length === 3) {
                    let dvarName = this.props.dataStore.ui.criteriaFieldList.find((obj) => {
                        return obj["@FieldName"] === supplementEditCriteria[0];
                    })
                    this.props.action.ui.updateDVARName(dvarName !== undefined && dvarName !== null ? dvarName["@DVARName"] : "0");
                    this.props.action.ui.updateExpression(supplementEditCriteria[1]);
                    this.props.action.ui.updateInput(supplementEditCriteria[2]);
                }
                else if (supplementEditCriteria.length === 5) {
                    let dvarName = this.props.dataStore.ui.criteriaFieldList.find((obj) => {
                        return obj["@FieldName"] === supplementEditCriteria[2];
                    })
                    this.props.action.ui.updateDVARName(dvarName !== undefined && dvarName !== null ? dvarName["@DVARName"] : "0");
                    this.props.action.ui.updateExpression(supplementEditCriteria[3]);
                    this.props.action.ui.updateInput(supplementEditCriteria[4]);
                }
            }
            else {
                this.props.action.ui.updateExpression("0");
                this.props.action.ui.updateInput("");
                this.props.action.ui.updateDVARName("0");
                this.props.action.ui.updateSupplementEditCriteriaId("0");
                this.setState({ disabledDeleteButton: true });
            }

        }
    }

    public getInitialValue(options: any, selectedValue: any) {
        let getValue = options.filter((res: any) => {
            if (selectedValue != undefined) {
                return res.value === selectedValue
            } else {
                return { label: '', value: '0' };
            }
        });
        return getValue;
    }

    public getSelectSupplementEditCriteriaItem() {

        let result = { value: '', label: '' };
        result.label =
            this.props.dataStore.ui.DVARName;
        result.value =
            this.props.dataStore.ui.DVARName;
        return result;
    }

    public OnSelectGrid(e: any, record: EditRow | RowType) {
        const rowId: any = record["@EditName"];
        if (rowId) {
            this.props.action.ui.updateSelectedEdit(rowId);
            $("#test-id").children().children().removeClass('selected')
            e.currentTarget.className = 'selected';
            setTimeout(() => {
                this.selectEditCriteriaDetails(0);
            }, 200);
        }
    }

    public selectEditCriteriaDetails(index: number) {
        if (index !== -1) {
            let supplementalEditCriteria: any = this.props.dataStore.ui.supplementEditCriteria;
            if (supplementalEditCriteria !== undefined && supplementalEditCriteria !== null && supplementalEditCriteria.length > 0) {
                let supplementEditCriteriaSplit = supplementalEditCriteria[index].value.split('-');
                let dvarName = this.props.dataStore.ui.criteriaFieldList.find((obj) => {
                    return obj["@FieldName"] === supplementEditCriteriaSplit[0];
                })
                this.props.action.ui.updateDVARName(dvarName?.["@DVARName"]);
                this.props.action.ui.updateExpression(supplementEditCriteriaSplit[1]);
                this.props.action.ui.updateInput(supplementEditCriteriaSplit[2]);
                this.props.action.ui.updateSupplementEditCriteriaId(supplementalEditCriteria[index].id);
                this.setState({ disabledDeleteButton: false });
            }
            else {
                this.props.action.ui.updateDVARName("0");
                this.props.action.ui.updateExpression("0");
                this.props.action.ui.updateInput("");
                this.props.action.ui.updateSupplementEditCriteriaId("0");
                this.setState({ disabledDeleteButton: true });
            }
        }
        else {
            this.props.action.ui.updateExpression("0");
            this.props.action.ui.updateInput("");
            this.props.action.ui.updateSupplementEditCriteriaId("0");
            this.setState({ disabledDeleteButton: true });
        }
    }

    public getModifyButtonType(): eModifyButtonType {
        if (this.props.dataStore.ui.supplementEditCriteriaId && this.props.dataStore.ui.supplementEditCriteriaId !== "0") {
            return eModifyButtonType.Update;
        }
        else {
            return eModifyButtonType.Add;
        }
    }

    public getModifyButtonText(): string {
        //if (eModifyButtonType.Add) {
        //    this.setState({ disabledDeleteButton: true });
        //}
        //else {
        //    this.setState({ disabledDeleteButton: false });
        //}

        return this.getModifyButtonType() == eModifyButtonType.Add ? 'Add' : 'Update';
    }

    public OnToggleEnabled(obj: any, e: any) {
        let editsData = JSON.parse(JSON.stringify(this.props.dataStore.ui.edits));
        let ruleEnabled: boolean = false;
        let editName: string = "";
        editsData.forEach((item: any) => {
            if (item["@ID"] === obj["@ID"]) {
                editName = item["@EditName"];
            }
        })
        this.props.action.ui.updateSelectedEdit(editName);
        let dataProps: any;
        setTimeout(() => {
            dataProps = this.props;
        }, 950)
        setTimeout(() => {
            if ((dataProps.dataStore.ui.supplementEditCriteria !== undefined && dataProps.dataStore.ui.supplementEditCriteria.length > 0)) {
                this.setState({
                    isAlertOpen: true,
                    alertMessage: "Do you want to disable Edits " + obj["@EditName"] + "?  This will remove any additional acceptance criteria",
                    objectValueForEdit: obj,
                    currentState: e
                });
                return;
            }
            else if (obj["@RuleRequired"] === "-1") {
                this.setState({
                    isAlertOpen: true,
                    alertMessage: "Do you want to disable Edits " + obj["@EditName"] + "?  This will remove any additional acceptance criteria",
                    objectValueForEdit: obj,
                    currentState: e
                });
                return;
            }
            else {
                handleChange();
                this.setState({ isSearchChange: true });
                editsData.forEach((item: any) => {
                    if (item["@ID"] === obj["@ID"]) {
                        if (item["@RuleEnabled"] === "0") {
                            item["@RuleEnabled"] = "1";
                            ruleEnabled = true;
                        }
                        else {
                            item["@RuleEnabled"] = "0";
                        }
                    }
                })
                let clientRulesInx = "@DRLClientRulesInx" in obj ? obj["@DRLClientRulesInx"] : "0";
                let object: MCSupplementalEditListDetail = {
                    "@ID": obj["@ID"],
                    "@Description": obj["@Description"],
                    "@DRLClientRulesInx": clientRulesInx,
                    "@EditName": obj["@EditName"],
                    "@LongDescription": obj["@LongDescription"],
                    "@RuleEnabled": ruleEnabled ? "1" : "0",
                    "@RuleRequired": "0",
                    "@Add": clientRulesInx === "0" ? true : false
                }
                setTimeout(() => {

                    dataProps.action.ui.updateEdits(editsData);

                    dataProps.action.ui.enableSupplementEdit(object);
                    dataProps.action.ui.updateSelectedEdit(editName);

                    let classValue = object["@RuleEnabled"] == "1" ? e.target.className.replace("left", "right") : e.target.className.replace("right", "left");
                    e.target.className = classValue;
                }, 100)
            }
        }, 1000)

    }

    public OnConfirmRemoveSupplementEdit() {

        handleChange();
        this.setState({ isSearchChange: true });
        this.setState({
            isAlertOpen: false, alertMessage: ""
        })

        let editsData = JSON.parse(JSON.stringify(this.props.dataStore.ui.edits));
        let currentStateValue: any = this.state.currentState;
        let ruleEnabled: boolean = false;
        let editName: string = "";
        let obj: any = this.state.objectValueForEdit;
        editsData.forEach((item: any) => {
            if (item["@ID"] === obj["@ID"]) {
                item["@RuleEnabled"] = "0";
                item["@RuleRequired"] = "0";
                editName = item["@EditName"];
            }
        });

        let clientRulesInx = "@DRLClientRulesInx" in obj ? obj["@DRLClientRulesInx"] : "0";
        let object: MCSupplementalEdit = {
            "@ID": obj["@ID"],
            "@Description": obj["@Description"],
            "@DRLClientRulesInx": clientRulesInx,
            "@EditName": obj["@EditName"],
            "@LongDescription": obj["@LongDescription"],
            "@RuleEnabled": "0",
            "@RuleRequired": "0"
        }
        //this.props.action.ui.updateEdits([]);
        this.props.action.ui.enableSupplementEdit(object);

        setTimeout(() => {
            this.props.action.ui.updateEdits(editsData);
            this.props.action.ui.clearSupplementsEditCriteria(object["@ID"]);
            this.props.action.ui.updateSelectedEdit(editName);

            let classValue = currentStateValue.target.className.replace("right", "left");
            currentStateValue.target.className = classValue;
            this.setState({
                objectValueForEdit: {}, currentState: {}
            });
            let id = "#" + obj["@EditName"] + "-require-toggle button:nth-child(2)";
            if (id !== undefined && document.querySelector !== null) {
                let requireClassValue = $(id)[0].children[1].className.replace("right", "left");
                $(id)[0].children[1].className = requireClassValue;

            }
        }, 100)
    }

    public OnCancelRemoveSupplementEdit() {

        let editsData = JSON.parse(JSON.stringify(this.props.dataStore.ui.edits));
        let editName: string = "";
        let obj: any = this.state.objectValueForEdit;
        editsData.forEach((item: any) => {
            if (item["@ID"] === obj["@ID"]) {
                item["@RuleEnabled"] = "1";
                editName = item["@EditName"];
            }
        });
        setTimeout(() => {
            this.props.action.ui.updateEdits(editsData);
            this.props.action.ui.updateSelectedEdit(editName);
            let currentStateValue: any = this.state.currentState;
            let classValue = currentStateValue.target.className.replace("left", "right");
            currentStateValue.target.className = classValue;
            this.setState({
                isAlertOpen: false, alertMessage: "", currentState: {}
            })
        }, 100)

    }

    public OnToggleRequiredEnabled(obj: any, e: any) {
        let editsData = JSON.parse(JSON.stringify(this.props.dataStore.ui.edits));
        let ruleEnabled: boolean = false;
        let editName: string = "";
        let ruleRequired: string = "";
        handleChange();
        this.setState({ isSearchChange: true });
        editsData.forEach((item: any) => {
            if (item["@ID"] === obj["@ID"]) {
                if (item["@RuleRequired"] === "0") {
                    item["@RuleRequired"] = "-1";
                    item["@RuleEnabled"] = "1";
                    ruleEnabled = true;
                }
                else {
                    item["@RuleRequired"] = "0";
                }
                editName = item["@EditName"];
            }
        })

        ruleRequired = ruleEnabled ? "-1" : "0";
        let clientRulesInx = "@DRLClientRulesInx" in obj ? obj["@DRLClientRulesInx"] : "0";
        let object: MCSupplementalEditListDetail = {
            "@ID": obj["@ID"],
            "@Description": obj["@Description"],
            "@DRLClientRulesInx": clientRulesInx,
            "@EditName": obj["@EditName"],
            "@LongDescription": obj["@LongDescription"],
            "@RuleEnabled": ruleRequired === "-1" ? "1" : obj["@RuleEnabled"],
            "@RuleRequired": ruleRequired === "-1" ? "-1" : "0",
            "@Add": clientRulesInx === "0" ? true : false
        }
        setTimeout(() => {
            this.props.action.ui.updateEdits(editsData);
            this.props.action.ui.updateSelectedEdit(editName);
            this.props.action.ui.enableRequiredData(object);
            let id = "#" + obj["@EditName"] + "-enable-toggle button:nth-child(2)";
            if (id !== undefined && document.querySelector !== null) {
                let requireClassValue = $(id)[0].children[1].className.replace("left", "right");
                $(id)[0].children[1].className = requireClassValue;
            }
        }, 100)
    }

    public OnConfirmForSearch() {
        this.props.action.ui.clearAllData();
        this.props.action.ui.searchAndUpdateRows(
            this.crudSearch(this.props.dataStore.ui.nameText,
                this.props.dataStore.ui.shortDescText,
                this.props.dataStore.ui.longDescText));
        this.setState({ isAlertOpenForSearch: false, isSearchChange: false, alertMessageForSearch: "" });
    }

    public OnCancelForSearch() {
        this.setState({ isAlertOpenForSearch: false, alertMessageForSearch: "" });
    }

    public render() {
        const bottomInstructions = <React.Fragment>Use this page to customize the way your claims are affected by supplemental edits processing. (See help for more information.)</React.Fragment>;

        //Buttons in the dialog wrapper footer.
        const buttons =
            <OKCancelButtons
                onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)}
                onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        const editCrud = this.props.dataStore?.crud
            ?.dataSearch?.SupplementalEditMaintenanceInfo?.SupplementalEditList?.SupplementalEdit;

        let edits: EditRow[] = this.props.dataStore.ui.edits.length > 0
            ? this.props.dataStore.ui.edits
            : []
        if (edits != undefined) {
            edits.forEach((obj) => {

                obj.EnabledToggle.onToggle = (e: React.ChangeEvent<HTMLInputElement>) => this.OnToggleEnabled(obj, e);
                obj.RequiredToggle.onToggle = (e: React.ChangeEvent<HTMLInputElement>) => this.OnToggleRequiredEnabled(obj, e);
            })
        }
        let supplementEditCriteria = this.getSupplementEditCriteria();
        let dvarNames = this.props.dataStore.ui.criteriaFieldList;
        let DVARNames: any = [{ label: "", value: "0" }];
        dvarNames.forEach((item) => {
            let objectValue = {
                label: item["@FieldName"],
                value: item["@DVARName"]
            }
            DVARNames.push(objectValue);
        });
        let expressionNames = this.props.dataStore.ui.expressionList;
        let EXPRESSIONNames: any = [{ label: "", value: "0" }];
        expressionNames.forEach((item) => {
            let objectValue = {
                label: item["@Expression"],
                value: item["@Expression"]
            }
            EXPRESSIONNames.push(objectValue);
        });


        const header = () => <thead><tr>
            {
                <React.Fragment>
                    <ARMHeaderCell
                        dataName="Name"
                        text="Name"
                        cellType="text"
                        sortable={true}
                        isSorted={0}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}

                    />
                    <ARMHeaderCell
                        dataName="Description"
                        text="Description"
                        cellType="text"
                        sortable={true}
                        isSorted={0}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="Enabled"
                        text="Enabled"
                        cellType="text"
                        sortable={true}
                        isSorted={0}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="Required"
                        text="Required"
                        cellType="text"
                        sortable={true}
                        isSorted={0}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                </React.Fragment>
            }
        </tr></thead>
        return (
            <DialogWrapper title="Supplemental Edits Maintenance" width="1000px" instruction={bottomInstructions} buttons={buttons} helpUrl='/Support/Help/HELP_Maint_SupplementalEdits.htm'
                isBusy={this.props.dataStore.ui.loading}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper>
                    <InputContainer>
                        <DialogFieldset style={{ "width": "99%" }}>
                            <DialogLegend>Search for Supplemental Edits</DialogLegend>
                            <ContentRowWrapper>
                                <ContentColumnWrapper style={{ marginRight: "15px" }}>
                                    <Input
                                        autoComplete="off"
                                        dataTestId="test-inputBasic"
                                        domID="basic-test-id"
                                        placeholder="Name"
                                        label="Name:"
                                        initialValue={this.props.dataStore.ui.nameText}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.props.action.ui.updateNameText(e.target.value) }}
                                    />
                                </ContentColumnWrapper>
                                <ContentColumnWrapper style={{ marginRight: "15px" }}>
                                    <Input
                                        autoComplete="off"
                                        dataTestId="test-inputBasic"
                                        domID="basic-test-id"
                                        placeholder="Short Description"
                                        label="Short Description:"
                                        initialValue={this.props.dataStore.ui.shortDescText}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.props.action.ui.updateShortDescText(e.target.value) }}
                                    />
                                </ContentColumnWrapper>
                                <ContentColumnWrapper style={{ marginRight: "15px" }}>
                                    <Input
                                        autoComplete="off"
                                        dataTestId="test-inputBasic"
                                        domID="basic-test-id"
                                        placeholder="Long Description"
                                        label="Long Description:"
                                        initialValue={this.props.dataStore.ui.longDescText}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.props.action.ui.updateLongDescText(e.target.value) }}
                                    />
                                </ContentColumnWrapper>
                                <ContentColumnWrapper style={{ marginRight: "15px", marginTop: "23px" }}>
                                    <Button
                                        buttonType="emphasized"
                                        dataTestId="test-defaultButton"
                                        domID="automation-id"
                                        style={{ height: '30px' }}
                                        name="Search"
                                        onClick={() => {
                                            if (!this.state.isSearchChange) {
                                                this.props.action.ui.searchAndUpdateRows(
                                                    this.crudSearch(this.props.dataStore.ui.nameText,
                                                        this.props.dataStore.ui.shortDescText,
                                                        this.props.dataStore.ui.longDescText));
                                            }
                                            else {
                                                this.setState({ isAlertOpenForSearch: true, alertMessageForSearch: "Do you want to search? The content of the page has changed. If you search your changes will be gone." });
                                            }
                                        }
                                        }
                                        size="small"
                                        type="button"
                                    />
                                </ContentColumnWrapper>
                                <ContentColumnWrapper style={{ marginTop: "23px" }}>
                                    <Button
                                        buttonType="standard"
                                        style={{ height: '30px' }}
                                        dataTestId="test-defaultButton"
                                        domID="automation-id"
                                        name="Clear"
                                        onClick={() => {
                                            this.onClear()
                                        }}
                                        size="small"

                                        type="button"
                                    />
                                </ContentColumnWrapper>
                            </ContentRowWrapper>
                        </DialogFieldset>
                    </InputContainer>
                    <SortIcon>
                        <InputContainer>

                            <DialogFieldset style={{ "width": "100%" }}>
                                <DialogLegend>Supplemental Edits</DialogLegend>
                                <Grid
                                    columns={this.getGridColumnHeaders() as Set<ColumnType>}
                                    dataTestId="test-gridFixed"
                                    domID="test-id"
                                    maxHeight="300px"
                                    isFixedHeader
                                    // TODO: EditRow and RowType do not match eachother.
                                    onClickThrough={(e: React.SyntheticEvent<Element, Event>, record: EditRow | RowType) => this.OnSelectGrid(e, record)}
                                    records={this.props.dataStore.ui.edits.length > 0 ? this.props.dataStore.ui.edits : []}
                                    selectAllCheckboxDomID="select-all-test-id"
                                    selectionKey="empty"
                                    headerComponent={header}
                                />
                            </DialogFieldset>

                        </InputContainer>
                    </SortIcon>
                    <ContentRowWrapper>
                        <InputContainer>

                            <DialogFieldset style={{ width: "100%" }}>
                                <DialogLegend>Description</DialogLegend>
                                <Section
                                    description={this.getLongDescription()}
                                    title=""
                                    className=""
                                    dataTestId="test-sectionBasic"
                                    domID="test"
                                />
                            </DialogFieldset>

                        </InputContainer>
                    </ContentRowWrapper>
                    <GlobalStyles />
                    <ContentRowWrapper>
                        <InputContainer>
                            <DialogFieldset style={{ width: "100%" }}>
                                <DialogLegend>Supplemental Edit Criteria</DialogLegend>
                                <ContentRowWrapper>
                                    <ContentColumnWrapper style={{ marginRight: "15px" }}>
                                        <SelectComponent
                                            title={'supplementEditCriteriaSelect'}
                                            size={6} // The size is dependant of the tab
                                            //className={'_select'}
                                            width='200px'
                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectSupplementEditCriteriaDetails(e)}
                                            optionFields={{ value: "id", text: "value" }}
                                            records={supplementEditCriteria}
                                            selectedValue={this.props.dataStore.ui.supplementEditCriteriaId}
                                            disabled={!(this.props.dataStore.ui.selectedEdit?.["@RuleEnabled"] === "1")}
                                        >
                                        </SelectComponent>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper style={{ marginRight: "20px", marginTop: "5px", width: "180px" }}>
                                        <ContentRowWrapper>
                                            <SelectDropdown
                                                domID="dvarName"
                                                className="dd200"

                                                label=""
                                                //onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectGroup(e)}
                                                onChange={(e: any) => {
                                                    if (e.value !== undefined) {
                                                        this.props.action.ui.updateDVARName(e.value);
                                                        let isAlreadyPresentData: boolean = false;
                                                        this.props.dataStore.ui.supplementEditCriteria.find((obj: any) => {
                                                            let value = obj.id.split('-');
                                                            let dvarName = this.props.dataStore.ui.criteriaFieldList.find((item) => {
                                                                return item["@FieldName"] === value[2];
                                                            })
                                                            if (dvarName !== undefined && dvarName["@DVARName"] === e.value) {
                                                                this.props.action.ui.updateSupplementEditCriteriaId(obj.id);
                                                                let index = this.props.dataStore.ui.supplementEditCriteria.findIndex((data: any) => {
                                                                    return data.value === obj.value;
                                                                });
                                                                this.selectEditCriteriaDetails(index);
                                                                isAlreadyPresentData = true;
                                                            }
                                                        });
                                                        if (!isAlreadyPresentData) {
                                                            this.selectEditCriteriaDetails(-1);
                                                        }
                                                    }
                                                    else
                                                        this.props.action.ui.updateDVARName("0")
                                                }}
                                                options={DVARNames}
                                                initialValue={this.getInitialValue(DVARNames, this.props.dataStore.ui.DVARName)}
                                                isClearable={false}
                                                isSearchable={false}
                                                disabled={!(this.props.dataStore.ui.selectedEdit?.["@RuleEnabled"] === "1")}

                                            // placeholderText="Select..."
                                            />
                                        </ContentRowWrapper>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper style={{ marginRight: "20px", marginTop: "5px", width: "180px" }}>
                                        <ContentRowWrapper>
                                            <SelectDropdown
                                                domID="expression"
                                                label=""
                                                onChange={(e: any) => {
                                                    if (e.value !== undefined)
                                                        this.props.action.ui.updateExpression(e.value)
                                                }}
                                                options={EXPRESSIONNames}
                                                initialValue={this.getInitialValue(EXPRESSIONNames, this.props.dataStore.ui.Expression)}
                                                isClearable={false}
                                                isSearchable={false}
                                                disabled={!(this.props.dataStore.ui.selectedEdit?.["@RuleEnabled"] === "1")}
                                            />
                                        </ContentRowWrapper>
                                        <ContentRowWrapper style={{ alignSelf: "flex-end" }}>
                                            <AddRemoveButton>
                                                <Button
                                                    buttonType="standard"
                                                    dataTestId="test-defaultButton"
                                                    domID="addremovebutton"
                                                    name={this.getModifyButtonText()}
                                                    onClick={(e: React.SyntheticEvent) => this.OnClick_Add()}
                                                    size="control"
                                                    type="button"
                                                    disabled={this.props.canEdit ? (this.props.dataStore.ui.supplementEditCriteria !== undefined ?
                                                        this.props.dataStore.ui.supplementEditCriteria.length >= 1 ?
                                                            false : this.props.dataStore.ui.supplementEditCriteriaId === "0" && this.props.dataStore.ui.DVARName !== "0" ?
                                                                !(this.props.dataStore.ui.selectedEdit?.["@RuleEnabled"] === "1") : true : true) : true}
                                                />
                                            </AddRemoveButton>
                                        </ContentRowWrapper>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper style={{ marginRight: "15px", marginTop: "5px", width: "180px" }}>
                                        <ContentRowWrapper>
                                            <Input
                                                autoComplete="off"
                                                style={{ width: "100px" }}
                                                dataTestId="test-inputBasic"
                                                domID="basic-test-id"
                                                placeholder=""
                                                label=""
                                                initialValue={this.props.dataStore.ui.input}
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
                                                    if (e.target.value !== '')
                                                        this.props.action.ui.updateInput(e.target.value.toUpperCase())
                                                }
                                                }

                                                disabled={!(this.props.dataStore.ui.selectedEdit?.["@RuleEnabled"] === "1")}
                                            />
                                        </ContentRowWrapper>
                                        <ContentRowWrapper>
                                            <AddRemoveButton>
                                                <Button
                                                    buttonType="standard"
                                                    dataTestId="test-defaultButton"
                                                    domID="addremovebutton"
                                                    name="Remove"
                                                    onClick={(e: React.SyntheticEvent<Element, Event>) => this.OnClick_Remove()}
                                                    size="control"
                                                    type="button"
                                                    disabled={this.props.canEdit ? this.props.dataStore.ui.supplementEditCriteriaId === "0" : true}

                                                />
                                            </AddRemoveButton>
                                        </ContentRowWrapper>
                                    </ContentColumnWrapper>
                                </ContentRowWrapper>
                            </DialogFieldset>
                        </InputContainer></ContentRowWrapper>


                </ContentWrapper>
                <ModalConfirmation
                    isOpen={this.state.isAlertOpen}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.setState({ alertMessage: '' }) }}
                    message={this.state.alertMessage}
                    okText={"Yes"}
                    cancelText={"No"}
                    showDefaultClose={false}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.OnConfirmRemoveSupplementEdit()}
                    onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.OnCancelRemoveSupplementEdit()}
                />
                <ModalConfirmation
                    isOpen={this.state.isAlertOpenForSearch}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.setState({ alertMessageForSearch: '' }) }}
                    message={this.state.alertMessageForSearch}
                    okText={"Yes"}
                    cancelText={"No"}
                    showDefaultClose={false}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.OnConfirmForSearch()}
                    onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.OnCancelForSearch()}
                />
                <ModalConfirmation
                    isOpen={this.state.isAlertOpenForValidation}
                    formattedMessage={(
                        <React.Fragment>
                            <p>{this.state.alertMessageForValidation}.</p>
                        </React.Fragment>)
                    }
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.setState({ isAlertOpenForValidation: false, alertMessageForValidation: "" }) }}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                        this.setState({ isAlertOpenForValidation: false, alertMessageForValidation: "" })

                    }
                    }
                    onDeny={(e: React.MouseEvent<HTMLButtonElement>) => { this.setState({ isAlertOpenForValidation: false }) }}
                    alertMode={true}
                />
            </DialogWrapper >
        );
    }
};

var connectedHoc = connect<ISupplementalEditMaintenanceState,
    SupplementalEditsMaintenanceActionsProps,
    IOwnProps,
    SupplementalEditMaintenanceProps,
    ApplicationState>(
        createCrudMapStateToProps('supplementalEditMaintenance'),
        createCrudMapDispatchToProps(actionCreators),
        mergeCrudComponentProps
    )(SupplementalEditsMaintenance);

export default withRouter(connectedHoc);