import * as React from 'react';
import { Button, Help } from '@optum-uicl/ui-core/dist';
import styled from 'styled-components';

export interface IImageButtonImage {
    Off: any;
    Up: any;
    Over?: any;
    Down?: any;
}

export interface IImageButtonProps {
    Title: string;
    legend?: boolean;
    image: IImageButtonImage;
    disabled?: boolean;
    altText?: string;
    tooltip?: string;
    onClick: React.ChangeEventHandler<HTMLButtonElement>;
    className?: string;
    topheader?: boolean;
    helpIcon?: boolean;
}

export interface IImageButtonState {
    disabled: boolean;
}

export const HelpIconWrapper = styled.div`
    display: flex;
`
export class ImageButton extends React.Component<IImageButtonProps, IImageButtonState>
{
    static defaultProps: IImageButtonProps = {
        Title: "imageButton",
        disabled: false,
        image: {
            Off: undefined,
            Up: undefined,
        },
        onClick: function (event: React.SyntheticEvent): void {
            throw new Error('Function not implemented.');
        },
        helpIcon: false,
        legend: false,
        topheader: false
    };

    constructor(props: IImageButtonProps) {
        super(props);
        this.state = {
            disabled: this.props.disabled || false,
        }
    }

    handleImageInfo(event: React.MouseEvent<HTMLImageElement, MouseEvent>, image: any) {
        if (!this.state.disabled) {
            if (image) {
                event.currentTarget.src = image;
            }
        }
    }

    render() {
        const { disabled } = this.props;
        return (
            <Button
                domID={this.props.Title}
                type="button"
                size="medium"
                buttonType="unstyled"
                className={this.props.className}
                onClick={(e: React.SyntheticEvent) => { if (this.props.onClick) this.props.onClick(e as React.ChangeEvent<HTMLButtonElement>) }}
                disabled={disabled}
            >

                {this.props.helpIcon ?
                    <HelpIconWrapper>
                        <Help
                            fillColor={this.props.legend ? "#111" : (this.props.topheader ? "#323334" : "#002677")}
                            size={this.props.legend ? "small" : (this.props.topheader ? "medium" : "large")}
                            title={this.props.tooltip}
                            className="Dialog-help"
                        />
                    </HelpIconWrapper> :
                    <img src={disabled ? this.props.image.Off : this.props.image.Up}
                        title={this.props.tooltip}
                        alt={this.props.altText}
                        onMouseOver={(event: React.MouseEvent<HTMLImageElement, MouseEvent>) => { this.handleImageInfo(event, this.props.image.Over) }}
                        onMouseOut={(event: React.MouseEvent<HTMLImageElement, MouseEvent>) => { this.handleImageInfo(event, this.props.image.Up) }}
                        onMouseDown={(event: React.MouseEvent<HTMLImageElement, MouseEvent>) => { this.handleImageInfo(event, this.props.image.Down) }}
                        onMouseUp={(event: React.MouseEvent<HTMLImageElement, MouseEvent>) => { this.handleImageInfo(event, this.props.image.Up) }} />
                }
            </Button>
        );
    }
};