//this is an confirgurable number formatter function
export const numberFormatterFactory = ({
    locale = 'en-us',
    localeMatcher = 'best fit',
    style = 'decimal',
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
}: any) => {

    const setup = new Intl.NumberFormat(locale, {
        currency,
        style,
        localeMatcher,
        minimumFractionDigits,
        maximumFractionDigits,
    })

    return (value: any) => {
        if (value === null || value === '' || value === undefined) return value;
        let formattedValue = setup.format(value)
        return formattedValue
    }
}


export const dollarFormatter = numberFormatterFactory({
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,

})