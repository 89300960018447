import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindAll } from 'lodash';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper } from '@common/DialogWrapper';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { createApiMapDispatchToProps, createApiMapStateToProps, IMergeApiComponentProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { ISubmitReasonCodesState, ISubmitReasonCodesActionProps, actionCreators } from '@store/SubmitReasonCodes';
import { URLs } from '@commonDevResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';
import { ARMNarrowBrowse } from '@common/ARMNarrowBrowse';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { pageLeave } from '@commonResources/userModified';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const SelectList = styled.div`
    padding: 5px;
`;

export const UpdatingDialogContainer = styled.div`
`;

export const UpdatingStatusMsg = styled.div`
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${Colors.black};
    padding: 10px;
    ${Typography.headingSmall};
    ${Typography.ARMFontFamily};
`;

interface IComponentProps {
    canView: boolean;
};

interface IComponentState {
    files: any;
    isBusy: boolean;
    isAlertOpen: boolean;
    alertMessage: string;
};

export const DEFAULT_STATE: IComponentState = {
    files: new FormData(),
    isBusy: false,
    isAlertOpen: false,
    alertMessage: "",
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type ISubmitReasonCodesProps = IMergeApiComponentProps<ISubmitReasonCodesState, ISubmitReasonCodesActionProps, IOwnProps>;

export class SubmitReasonCodes extends React.Component<ISubmitReasonCodesProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canView: false,
    };

    constructor(props: ISubmitReasonCodesProps) {
        super(props);
        this.state = DEFAULT_STATE;
        bindAll(this, ['onOKSubmit', 'onCancel', 'onChangeUploadFile', 'onClearAlert']);
    }

    public componentDidMount() {
        pageLeave();
    }

    public componentWillUnmount() {
        pageLeave();
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public validate() {
        if (!this.props.dataStore.ui.reasonCodesFileName) {
            this.setState({ isAlertOpen: true, alertMessage: "Please select a file to upload!" });
            return false;
        }

        if (!this.props.dataStore.ui.reasonCodesFileName.toUpperCase().endsWith(".CSV")) {
            this.setState({ isAlertOpen: true, alertMessage: "File will not be uploaded because its file type is not CSV." });
            return false;
        }

        return true;
    }

    public onOKSubmit() {
        if (this.validate()) {
            this.setState({ isBusy: true });
            console.log('File obj: ', this.state.files);

            fetch(URLs.api + '/api/data/SubmitReasonCodes',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `${getRawToken()}`
                    },
                    body: this.state.files, 
                })
                .then(async (response) => {
                    // get json response here
                    let data: any = await response.json();
                    if (data === "success") {
                        this.setState({ isBusy: false });
                        this.props.history.push('/LandingPage');
                    }
                    else if (data === "fileerror") {
                        this.setState({ isBusy: false });
                        this.setState({ isAlertOpen: true, alertMessage: "Please select a valid file type" });
                    }
                    else {
                        this.setState({ isBusy: false });
                        this.setState({ isAlertOpen: true, alertMessage: "The file that you selected to import reason code could not be saved." });
                    }
                })
                .catch(error => {
                    console.error('Exception loading reason codes file: ' + error);
                    this.setState({ isBusy: false });
                    this.setState({ isAlertOpen: true, alertMessage: "The file that you selected to import reason code could not be saved." });
                });
        }
    }

    public onCancel() {
        this.props.history.push('/LandingPage');
    }

    public onClearAlert() {
        this.setState({ isAlertOpen: false, alertMessage: "" })
    }

    public onChangeUploadFile = (e: any) => {
        this.uploadSubmitReasonCodes(e.target.files)
    }
  
    public uploadSubmitReasonCodes(uploadedfiles: any) {
        const uploadData = new FormData();
        uploadData.append('file', uploadedfiles);
        const files = this.state.files;
        files?.delete(`file[1]`);
        files.append(`file[1]`, uploadedfiles[0]);
        this.props.action.ui.selectSubmitReasonCodesFile(
            {
                uiData: { value: uploadedfiles[0].name }
            });
        this.setState({ files: files });
    }

    public render() {
        var alertMsgStyle: React.CSSProperties = { alignContent: 'center', justifyContent: 'center', textAlign: 'center', color: 'black' };
        var instruction = <React.Fragment>Browse to the CSV reason code file you want to submit.</React.Fragment>;
        var buttons = <OKCancelButtons disableOK={!this.props.canView || this.state.isBusy} disableCancel={this.state.isBusy}
            onClickOK={this.onOKSubmit}
            onClickCancel={this.onCancel} />;

        return (
            <DialogWrapper title="Submit Reason Codes" instruction={instruction} helpUrl='/Support/Help/HELP_Maint_SubmitReasonCodes.htm' buttons={buttons} width='540px;' >
                {!this.state.isBusy ? (
                <ContentWrapper>
                    <ContentRowWrapper style={{ marginTop: "5px", marginLeft: "5px" }}>
                            <ARMNarrowBrowse labelValue="File:"
                                inputId="file-upload"
                                classNameValue="text-input"
                                maxLength={500}
                                initialValue={this.props.dataStore.ui.reasonCodesFileName}
                                disabledInput={true}
                                browseId="fileupload"
                                browseInputId="file"
                                onChange={this.onChangeUploadFile}
                                styleValue={{ marginTop: "20px", marginLeft: "5px" }}
                                inputColumnStyleValue={{ marginTop: "10px", marginLeft: "5px" }}
                                browseColumnStyleValue={{ marginTop: "5px", marginLeft: "10px" }}
                                divBrowseStyle={{ marginTop: '13px' }}
                                acceptType=".csv"
                                canEdit={true}
                            >
                        </ARMNarrowBrowse>
                    </ContentRowWrapper>
                </ContentWrapper> ) : (
                <UpdatingDialogContainer>
                    <UpdatingStatusMsg>Uploading Reason Codes File, please wait...</UpdatingStatusMsg>
                </UpdatingDialogContainer> )}
                <ModalConfirmation
                    isOpen={this.state.isAlertOpen}
                    formattedMessage={(
                        <React.Fragment>
                            <p style={alertMsgStyle}>{this.state.alertMessage}</p>
                        </React.Fragment>)
                    }
                    onModalToggle={this.onClearAlert}
                    alertMode={true}
                />
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<ISubmitReasonCodesState, ISubmitReasonCodesActionProps, IOwnProps, ISubmitReasonCodesProps, ApplicationState>(
    createApiMapStateToProps('submitReasonCodes'),   
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(SubmitReasonCodes);

export default withRouter(connectedHoc);
