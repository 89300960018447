import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Input, Button } from '@optum-uicl/ui-core/dist';
import styled from 'styled-components';

import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import AssuranceMenu from '../../common/AssuranceMenu';
import { getRawToken } from '@scripts/session/SecurityToken';
import { URLs } from '../../../commonResources/constants';
import { Loader, Toast } from './PcgPayerMaintenance';
import { IPcgPayerMaintenanceActionProps, IPcgPayerMaintenanceState, actionCreators } from '../../../store/PcgPayerMaintenance';
import { PayerDetailsGrid } from './PayerDetailsGrid';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-grow:initial;
    flex-direction: column;
`;



export const sortImage = styled.div`
  vertical-align:buttom;
  margin-left: 5px;

`;
export const ContentWrapper2 = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-grow:initial;
    flex-direction: row;
`;

export const SelectList = styled.div`
`;

export const CloneContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin-left: -15px;
`;

export const Inputs = styled.div`
    span {
        width: 300px;
    }
`;



export const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin-left: auto;
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

export const BottomButton = styled('button')`
    background: #0F0F59;
    color: white;
    border: 1px solid #FFF;
    border-radius: 3px;
    :disabled {
        background-color: transparent;
    border-color: #3F607F;
    color: #3F607F;
  cursor: pointer;
    pointer-events: none;


    }
`;
export const AddRemoveCancel = (props: any) => {
    const { onAdd = (e: any) => { }, onRemove = (e: any) => { }, onCancel = (e: any) => { } } = props;

    return (<>
        <BottomButton id="okButton" onClick={onAdd} className="button small deEmphasizedReversed" type="button">Add</BottomButton>
        <BottomButton id="okButton" onClick={onRemove} className="button small deEmphasizedReversed" type="button">Remove</BottomButton>
        <BottomButton id="okButton" onClick={onCancel} className="button small deEmphasizedReversed" type="button">Cancel</BottomButton>
    </>)
}

export const Table = styled.table`
     width: 100%;
    border-collapse: collapse;
    td, th {
        padding: 0.75rem;
        border-top: 1px solid #dee2e6;
        border-left: 1px solid #dee2e6;
    }
    tr td:first-child, 
    tr th:first-child {
        border-left: none !important;
    } 

`;


export const DEFAULT_STATE: IComponentState = {
    deleteConfirmation: false,
    addConfirmation: false,
    cancelLeave: false,
    selectedUsers: [],
    ciamLinkModalMessage: '',
    ciamUnlinkModalMessage: '',
    isCiamUnlinkModalOpen: false,
    linkableUsersList: [],
    unlinkableUsersList: [],
    isCiamLinkModalOpen: false,
    ctrlKeyPressed: false,
};

interface IComponentProps {
    canCreate: boolean; // SecurityBits.FN_CREATE_USERS
    canView: boolean;   // SecurityBits.FN_VIEW_USERS
    canEdit: boolean;   // SecurityBits.FN_EDIT_USERS
    canDelete: boolean; // SecurityBits.FN_DELETE_USERS
    canRestrictAccess: boolean; // SecurityBits.FN_VIEW_RESTRICT_USER_ACCESS
    canViewClientSet: boolean;  // SecurityBits.NDC_VIEW_CLIENTSET
    canEditClientSet: boolean;  // SecurityBits.NDC_EDIT_CLIENTSET
    canBetaPreview: boolean; // SecurityBits.NDC_USER_BETA_PREVIEW
};

interface IComponentState {
    deleteConfirmation: boolean,
    addConfirmation: boolean,
    cancelLeave: boolean,
    selectedUsers: any,
    ciamLinkModalMessage: any,
    ciamUnlinkModalMessage: any,
    linkableUsersList: any,
    unlinkableUsersList: any,
    isCiamLinkModalOpen: boolean,
    isCiamUnlinkModalOpen: boolean,
    ctrlKeyPressed: boolean,
    toastType?: string,
    toastMessage?: string,
    toastHeading?: string,
    showToast?: boolean,
    toastTimeout?: number,
}

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IPcgPayerMaintenanceProps = IMergeCrudComponentProps<IPcgPayerMaintenanceState, IPcgPayerMaintenanceActionProps, IOwnProps>;

class PcgPayerMaintenanceAdd extends React.Component<IPcgPayerMaintenanceProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canCreate: false, // SecurityBits.FN_CREATE_USERS
        canView: false,   // SecurityBits.FN_VIEW_USERS
        canEdit: false,   // SecurityBits.FN_EDIT_USERS
        canDelete: false, // SecurityBits.FN_DELETE_USERS
        canRestrictAccess: false, // SecurityBits.FN_VIEW_RESTRICT_USER_ACCESS
        canViewClientSet: false,  // SecurityBits.NDC_VIEW_CLIENTSET
        canEditClientSet: false,  // SecurityBits.NDC_EDIT_CLIENTSET
        canBetaPreview: false,  // SecurityBits.NDC_USER_BETA_PREVIEW
    };

    constructor(props: IPcgPayerMaintenanceProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        this.props.action.ui.getPayerNames();
    }

    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    onToggleConfirmation(e: React.MouseEvent<HTMLElement>, key: string) {
        this.setState(s => {
            const st: any = { ...s };
            st[key] = !st[key];
            return st;
        });
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push('/SupportTools/PCGPayerMaintenance');
    }

    async getDetails(id: string) {
        this.props.action.ui.getPayerDetails(id);
    }

    async addPayerDetails() {
        this.props.action.ui.addPayerDetails(this.props.dataStore.ui.selectedPayer, this.props.dataStore.ui.payerData[0]?.payerName);
    }

    onChange(e: any) {
        this.props.action.ui.setSelectedPayer(e.target.value);
    }

    public render() {
        let instruction = <React.Fragment>To add a new supported PCG Payer ID, click Add and Check the number.  To remove a supported PCG Payer ID, click the value and Remove.</React.Fragment>;
        let buttons = <OKCancelButtons nameOverride="Add" disableOK={this.props.dataStore.ui.isExist || !this.props.dataStore.ui.selectedPayer || this.props.dataStore.ui.payerData.length === 0 || !this.props.dataStore.ui.payerData[0].cpId} onClickOK={(e: any) => this.onToggleConfirmation(e, 'addConfirmation')} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;

        if (this.props.dataStore.ui.addedSuccessful) {
            this.props.history.push('/SupportTools/PCGPayerMaintenance');
            return null;
        }

        return (<>
            <DialogWrapper title='Add PCG Supported PayerID' instruction={instruction} helpUrl='/Support/Help/HELP_Maint_Users.htm' buttons={buttons}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <SelectList style={{ background: 'white', maxWidth: '700px', minHeight: '350px' }}>
                    <SelectList>
                        <SelectList>
                            <Inputs style={{ maxWidth: '120px', display: 'inline-block', marginRight: '10px', marginTop: '20px', marginLeft: '4px' }}>
                                <Input
                                    domID="payerId-input"
                                    label=""
                                    autoComplete="new-password"
                                    className="text-input"
                                    maxLength={12}
                                    size="small"
                                    onKeyUp={(e: any) => { this.onChange(e) }}

                                />
                            </Inputs>
                            <Button
                                domID="automation-id"
                                name='Search'
                                size="small"
                                buttonType="standard"
                                disabled={!this.props.dataStore.ui.selectedPayer}
                                type="button"
                                onClick={(e: React.SyntheticEvent) => {
                                    this.getDetails(this.props.dataStore.ui.selectedPayer);
                                }
                                }
                            />

                            {this.props.dataStore.ui.isExist && <span style={{ color: 'red', marginLeft: '10px' }}>Payer Id already exists</span>}
                        </SelectList>
                        <SelectList style={{ maxWidth: '600px', width: '600px' }}>
                            <DialogFieldset style={{ minHeight: '435px', maxHeight: '435px', overflow: 'auto', position: 'relative' }}>
                                <DialogLegend>Payer Information</DialogLegend>
                                <div style={{ minHeight: '400px' }}>
                                    {this.props.dataStore.ui.isLoadingPayer && < Loader />}
                                    <PayerDetailsGrid rows={this.props.dataStore.ui.payerData} />
                                </div>
                            </DialogFieldset>
                        </SelectList>
                    </SelectList>
                </SelectList>
            </DialogWrapper>
            <ModalConfirmation
                isOpen={this.state.addConfirmation}
                onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleConfirmation(e, 'addConfirmation')}
                formattedMessage={(
                    <div>
                        <p>Are you sure you wish to add this Payer ID?</p>
                    </div>)
                }
                onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => { this.addPayerDetails() }}
            />

        </>
        );
    }
};

let connectedHoc = connect<IPcgPayerMaintenanceState, IPcgPayerMaintenanceActionProps, IOwnProps, IPcgPayerMaintenanceProps, ApplicationState>(
    createCrudMapStateToProps("pcgPayerMaintenance"),   // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),   // Selects which action creators are merged into the component's props
    mergeCrudComponentProps
)(PcgPayerMaintenanceAdd);

export default withRouter(connectedHoc);
