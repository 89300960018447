import { IARMMessageProps } from './ARMMessage';

export class ARMLoginMessages {
    static ciamLoginDescription: IARMMessageProps = {
        message: 'Log in with',
        isBold: false,
        isError: false,
    };

    static instructions: IARMMessageProps = {
        message: 'Please log in to continue.',
        isBold: false,
        isError: false,
    };

    static ciamInfo: IARMMessageProps = {
        message: 'A validated email linked to SSO is required to access Assurance.',
        isBold: true,
        isError: true,
    };

    static ciamInfoLogon: IARMMessageProps = {
        message: 'You are now required to log in through SSO. Please click the SSO Login button above.',
        isBold: true,
        isError: true,
    };

    static verifyingCredentials: IARMMessageProps = {
        message: 'Verifying credentials...',
        isBold: true,
        isError: false,
    };

    static enterUserName: IARMMessageProps = {
        message: 'Please enter your user name.',
        isBold: true,
        isError: true,
    };

    static enterPassword: IARMMessageProps = {
        message: 'Please enter your password.',
        isBold: true,
        isError: true,
    };

    static enterClient: IARMMessageProps = {
        message: 'Please enter client CID.',
        isBold: true,
        isError: true,
    };
};