import React, { useEffect } from 'react';
import { SelectDropdown, Button } from "@optum-uicl/ui-core/dist";
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { actionCreators, selectors } from '@store/OperationalDashboard/DashboardAssignedClaims';
import { getRawToken } from '../../../../scripts/session/SecurityToken';
import { URLs } from '@commonDevResources/constants';
import { SelectDropdownValue } from '@optum-uicl/ui-core/dist/Atoms/SelectDropdown/types';


const ComboSelectContainer = styled.div`
  display: flex;
  align-items: flex-end;
  .assign-user-select{
    width: 250px;
    margin-right: 8px;
  }
`

const StatusUl = styled.ul`
    list-style: none;
`


const Checkmark = styled.span`
    color: green
`

const FailX = styled.span`
    color: red
`

interface OptionType {
    label: string;
    value: string;
}


const UserComboSelection = () => {
    const dispatch = useDispatch();
    const [isReassignModalOpen, setIsReassignModalOpen] = React.useState(false);
    const [isReassignProgessModalOpen, setIsReassignProgessModalOpen] = React.useState(false);
    const [reassignResponseData, setReassignResponseData] = React.useState([])
    const [isReassignModalInAlertMode, setIsReassignModalInAlertMode] = React.useState(false);
    const [reassignModalMessage, setReassignModalMessage] = React.useState('');
    const [originalClaimsList, setOriginalClaimsList] = React.useState([]);


    const userlist = useSelector(selectors.assignUsersOptionSelector);
    const assignedUser = useSelector(selectors.assignedUserSelector);
    const isEnabled = useSelector(selectors.isAssignedUserSelectedSelector);
    const selectedClaimUser = useSelector(selectors.selectedClaimUserSelector);
    const userSelectionId = assignedUser?.value;
    const reassingedClaimsListPrev = useSelector(selectors.reassingClaimsSelector) || [];
    const claimsDataSelector = useSelector(selectors.claimsDataSelector);
    const selectGroupSelector = useSelector(selectors.selectGroupSelector);

    //@ts-ignore
    const reassingedClaimsList = reassingedClaimsListPrev.map(itemId => itemId.substring(2) ) ?? []

    const filteredClaimList = () => {
        //@ts-ignore
        const newList = claimsDataSelector.filter(claim => reassingedClaimsList.includes(claim.id));

        //@ts-ignore
        const formatedlist = newList.map(item => {
            let container = {
                "claimId": "",
                "assignmentType": ""
            }

            container.claimId = item.claimId;
            container.assignmentType = item.assignmentType;

            return container;
        })

        let payload = {
            "claims": [...formatedlist],
            "userId": userSelectionId
        }
        return payload
    }

    const selectedClaimList = () => {
        //@ts-ignore
        const tempList = claimsDataSelector.filter(claim => reassingedClaimsList.includes(claim.id));
        return tempList;
    }


    const makeCallToReassingClaims = async (payload: any) => {
        // store off the original list to show for post-processing
        setOriginalClaimsList(claimsDataSelector);
        var url = URLs.api + '/api/data/claims/assignToUser/batch';


        await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: JSON.stringify(payload)
        })
            .then(async response => {
                if (response.status === 200) { }
                const data: any = await response.json();
                let responseData: string = JSON.stringify(data);
                const responseDataParse = JSON.parse(responseData) || [];

                setReassignResponseData(responseDataParse);
                dispatch(actionCreators.getWorkgroupSummary(selectGroupSelector.value));
                dispatch(actionCreators.getClaimsDetails(selectedClaimUser));
                //reset selected Claims
                dispatch(actionCreators.reassingClaimsList([]));

            })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
            });
    }

    const getOptions = async (value: string) => {
        const searchValue = value.toLowerCase().trim();
        if (searchValue) {
            const check = userlist.filter((userObj: OptionType) => {
                return userObj.label.toLowerCase().includes(searchValue)
            })
            return check;
        }
        return userlist
    }

    const onChange = (option: SelectDropdownValue) => {
        let op: OptionType = { label: option.label ?? '', value: option.value ?? ''};
        dispatch(actionCreators.setAssignedUser(op))
    }


    const handleApplyChangesConfirmation = () => {

        setIsReassignModalOpen(true);
        if (reassingedClaimsList.length > 0) {
            if (userSelectionId == selectedClaimUser) {
                setIsReassignModalInAlertMode(true);
                setReassignModalMessage('Selected claims are already assigned to this user.');
            } else {
                setIsReassignModalInAlertMode(false);
                setReassignModalMessage('');
            }
        } else {
            setIsReassignModalInAlertMode(true);
            setReassignModalMessage('You must select claims to reassign.');
        }

    }

    const handleApplyChanges = () => {

        setIsReassignModalOpen(false);
        setIsReassignProgessModalOpen(true);

        const payload = filteredClaimList();
        makeCallToReassingClaims(payload);
    }

    const getFormattedSelectedClaimDetails = () => {
        const claimsList = selectedClaimList()
        const count = claimsList.length;
        const textClaim = count > 1 ? `${count} selected items` : 'selected item';
        return (
            <div>
                <p>Do you want to reassign the {textClaim} to {assignedUser?.label!= null && assignedUser?.label!= undefined ? assignedUser.label:""}?</p>
                <StatusUl>
                    {//@ts-ignore
                        claimsList.map(claim => {
                            return (
                                <li>
                                    {claim.patientLastName}, {claim.patientFirstName} ({claim.controlNo})
                                </li>
                            )
                        })}
                </StatusUl>
                <p>This action cannot be undone.</p>
            </div>
        )
    }

    const getFormattedProgressDetails = () => {
        //@ts-ignore
        const reassignStatusResponseList = reassignResponseData || [{}];
        const preUpdateClaimsList = originalClaimsList;
        const updatedClaimsListWithStatus = reassignStatusResponseList?.map((responseItem: any) =>
        {
            const preUpdateClaimsObject: any = preUpdateClaimsList.find((origClaim: any) => origClaim?.claimId === responseItem?.claimId)
            return (
                {//@ts-ignore
                    ...responseItem,
                    ...preUpdateClaimsObject,
                })
        })
    
        const statusIcon = (success: boolean) => {
            return success ?
                (<Checkmark>{'\u2713'}</Checkmark>)
                :
                (<FailX>{'\u2715'}</FailX>)
        }

        return (
            <div>
                <p>Processing Complete</p>
                <StatusUl>
                    {//@ts-ignore
                        updatedClaimsListWithStatus?.map(claim => {
                            return (
                                <li>
                                    {statusIcon(claim.successful)}  {claim.patientLastName}, {claim.patientFirstName} ({claim.controlNo})
                                </li>
                            )
                        })}
                </StatusUl>
            </div>
        )
    }

    useEffect(() => {
        dispatch(actionCreators.getUtilityData())
    }, [])

    return (
        <ComboSelectContainer>
            <SelectDropdown
                domID="assigned-user-dropdown"
                label="Select User for Reassignment"
                isClearable={true}
                isAsync={true}
                loadOptions={getOptions}
                defaultOptions={userlist}
                className="assign-user-select"
                placeholderText="Select User"
                onChange={onChange}
                initialValue={assignedUser}
                menuWidth={250}
            //  additionalProps={{isLoading: true}}
            />
            <Button
                domID="apply-assigned-user-btn"
                name="Apply Changes"
                buttonType="standard"
                size="small"
                type="button"
                disabled={!isEnabled}
                onClick={handleApplyChangesConfirmation}
            />
            <ModalConfirmation
                title={"Reassign Claims?"}
                isOpen={isReassignModalOpen}
                alertMode={isReassignModalInAlertMode}
                message={reassignModalMessage}
                formattedMessage={!isReassignModalInAlertMode ? getFormattedSelectedClaimDetails() : null}
                okText={"Yes"}
                cancelText={"No"}
                showDefaultClose={false}
                onModalToggle={(e: React.MouseEvent<HTMLElement>) => { setIsReassignModalOpen(false) }}
                onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => { handleApplyChanges() }}
            />
            <ModalConfirmation
                title={"Reassigning Claim(s)"}
                isOpen={isReassignProgessModalOpen}
                alertMode={true}
                message={''}
                formattedMessage={getFormattedProgressDetails()}
                okText={"Yes"}
                cancelText={"No"}
                showDefaultClose={false}
                onModalToggle={(e: React.MouseEvent<HTMLElement>) => { setIsReassignProgessModalOpen(false) }}
            //    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => { handleApplyChanges() }}
            />
        </ComboSelectContainer>
    )
}

export default UserComboSelection;