import * as React from 'react';
import styled from 'styled-components';
import {
    Colors
} from './Colors';

export type StyledProps = {
    left?: number;
    top?: number;
    width?: number;
    visible?: boolean;
    data?: any;

}

type TooltipProps = {
    data: any;
    tooltipWidth: number;
    tooltipOffsetX: number;
    tooltipOffsetY: number;
    left?: number;
    top?: number;
    visible?: boolean;
    getContent: any;
};

const TooltipContainer = styled("div")`
    background: black;
    border-radius: 5px;
    position: absolute;
    z-index: 999;
    left: ${({left}: StyledProps) => left + "px"};
    top: ${({top}: StyledProps) => top + "px"};
    width: ${({width}: StyledProps) => width + "px"};
    color: white;
    padding: 10px 20px;
    opacity: .8;
    filter: drop-shadow(1px 1px 2px rgb(0,0,0,.4));
    visibility: ${({visible}: StyledProps) => visible ? 'visible' : 'hidden'};
    
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-right-color: black;
        border-left: 0;
        margin-top: -10px;
        margin-left: -10px;

    }
`;
const Name = styled("div")`
    padding-bottom: 0.2em; 
    text-transform: uppercase; 
    font-size: 11px;
    content: "My contents";
`;

const Data = styled("div")`
    font-size: 9px;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const Label = styled("div")`
    font-weight: bold;
    color: ${Colors.blue30}
`;

const Value = styled("div")`
    color: white;
`;
// TODO: Check why tooltip is not appearing under mouse cursor
// Allow the body to be passed in as props
export const HtmlTooltip = (props:TooltipProps) => {
    const { left, top, data, visible, tooltipWidth, tooltipOffsetX, tooltipOffsetY, getContent} = props;

    return(
        <TooltipContainer
            left={(left ?? 0) + tooltipOffsetX} 
            top={(top ?? 0) - tooltipOffsetY}
            width={tooltipWidth}
            data={data}
            visible={visible}
    >
            {getContent(data)}
        </TooltipContainer>
    );
}