import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DialogWrapper, ContentRowWrapper, OKCancelButtons } from '@common/DialogWrapper';
import styled from 'styled-components';
import { MessagesAPI } from './MessagesAPI';
import { MessageBodyContent } from './MessageBodyContent';
import { MessagesGrid } from './MessagesGrid';
import refreshIcon from '@content/images/LandingPage/refresharrow.png';
import { Button, SelectDropdown } from '@optum-uicl/ui-core/dist';
import { IMergeApiComponentProps } from '@scripts/util/ApiDataHelpers';
import { IWorkingGroupDisplayActionProps, IWorkingGroupDisplayState } from '@store/WorkingGroupDisplay';
import { ModalConfirmation } from '@common/ModalConfirmation';
import _ from 'lodash';

interface plusContentObj {
    plusText: string,
    plusContent: string,
    id: number
}

interface IComponentProps {
    testMode?: boolean
}

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

interface IComponentState {
    messages: MessagesType | undefined,
    requestedMessageId: number,
    selectedFilterType: string,
    selectedMessage: Message | undefined,
    isBusy: boolean,
    checkedForDeleteMessageIds: string[],
    showModal: boolean,
    okClicked: boolean,
    deleteAllClicked: boolean,
}

type IMessagesProps = IMergeApiComponentProps<IWorkingGroupDisplayState, IWorkingGroupDisplayActionProps, IOwnProps>;

const emptySelectedMessage: Message = {
    id: 0,
    clientId: 0,
    userId: 0,
    groupId: 0,
    isRead: true,
    messageSubject: '',
    messageDateTime: new Date('01/01/0001'),
    messageDateTimeFormatted: '',
    message: '',
    messageType: 0,
    messageSeverity: 0,
}

export const DEFAULT_STATE: IComponentState = {
    messages: undefined,
    requestedMessageId: 0,
    selectedFilterType: 'ALL',
    selectedMessage: emptySelectedMessage,
    isBusy: true,
    checkedForDeleteMessageIds: [],
    showModal: false,
    okClicked: false,
    deleteAllClicked: false,
}

export const PageWrapper = styled.div`
    #messages-filter-dropdown
    {
        width: 160px;
        padding-left:12px;
    }
`;

export const ContentWrapper = styled.div`
`;

const FileUrlTextDiv = styled.div`
    cursor: pointer;
    color: blue; 
    text-decoration: underline;
    display: inline;
`;

const PlusButtontDiv = styled.div`
    cursor: pointer;
`;

export class Messages extends React.Component<IMessagesProps, IComponentState> {

    static defaultProps: IComponentProps = {
        testMode: false
    };

    constructor(props: IMessagesProps) {
        super(props)
        this.state = DEFAULT_STATE;
        this.onRowClick.bind(this);
        this.onClickCancel.bind(this);
        this.onClickOK.bind(this);
    }

    async componentDidMount() {
        await this.getMessages();
    }

    async getMessages() {
        const {
            selectedFilterType
        } = this.state;

        this.setState({ isBusy: true })

        // get requested message id if it was requested
        const pathname = window.location.pathname;
        const requestedMessageId = pathname.split('/').slice(-1)[0];
        const messageIdRequested: boolean = !Number.isNaN(Number(requestedMessageId));
        const api = new MessagesAPI();

        // ensure that message read flag is set by default on passthrough message requests 
        if (messageIdRequested) await api.markAsRead([requestedMessageId]);

        // get messages via api
        const messages = await api.getMessages(selectedFilterType);

        // if message id was requested then get its message
        let selectedMessage: Message | undefined;
        if (messageIdRequested) {
            selectedMessage = messages?.messageList.find((x: { id: { toString: () => string; }; }) =>
                x.id.toString() === requestedMessageId);
        }

        this.setState(prevState => ({
            messages,
            isBusy: false,
            requestedMessageId:
                messageIdRequested ?
                    Number(requestedMessageId) :
                    prevState.requestedMessageId,
            selectedMessage
        }));
    }

    getMessagesFilterDropdown() {
        const userName = sessionStorage.getItem("Username")!;
        const clientAlias = sessionStorage.getItem("ClientAlias")!;
        const options = [{ id: "All", value: "All", label: "All" },
        { id: "User", value: "User", label: userName },
        { id: "Client", value: "Client", label: `CID ${clientAlias}` }];
        return (
            <SelectDropdown
                domID="messages-filter-dropdown"
                isClearable={false}
                isSearchable={false}
                size='small'
                menuWidth={180}
                options={options}
                initialValue={options.find(ob => ob['id'] === this.state.selectedFilterType) ?? options[0]}
                onChange={(e: any /*React.ChangeEvent<HTMLSelectElement>*/) => this.setState({
                    selectedFilterType: e.value,
                    selectedMessage: emptySelectedMessage
                }, this.getMessages)}
            />
        );
    }

    refresh() {
        this.getMessages();
    }

    getRefreshButton() {
        return (
            <div style={{ flex: '1 1 0px' }}>
                <div style={{
                    cursor: 'pointer', position: 'relative',
                    left: 10, width: 80
                }}
                    onClick={() => {
                        this.refresh();
                    }}>
                    <img src={refreshIcon} style={{ position: 'relative', left: -4, top: 3 }} />
                    <a style={{ textDecoration: 'underline', color: 'blue' }}>Refresh</a>
                </div>
            </div>
        )
    }

    getGridFooter(currentServerTime: string) {
        const refreshButton = this.getRefreshButton();
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 5 }}>
                {/* refresh button */}
                <div style={{ fontSize: 14 }}>
                    {refreshButton}
                </div>
                {/* current server time */}
                <div style={{ fontSize: 14 }}>
                    Current Server Time: {currentServerTime}
                </div>
                {/* "delete all" button */}
                <div>
                    <Button
                        domID="delete-all-button"
                        name={"DELETE ALL"}
                        size="control"
                        buttonType="emphasized"
                        type="button"
                        onClick={(e: React.SyntheticEvent) => {
                            this.onClickDeleteAll();
                        }}
                    />
                </div>
            </div>
        );
    }

    onClickDeleteAll() {
        this.setState({
            okClicked: false,
            deleteAllClicked: true,
            showModal: true,
        });
    }

    getMessageBodyDiv() {
        const {
            selectedMessage
        } = this.state;

        let message: string = !!selectedMessage ? selectedMessage.messageSubject : ""; return (
            <div style={{ width: '1000px', height: '200px', backgroundColor: 'white', border: 'solid 1px black' }}>
                <div style={{
                    verticalAlign: 'middle', height: 32, fontSize: 14, paddingLeft: 5,
                    fontWeight: 600, display: 'flex', alignItems: 'center'
                }}>
                    {selectedMessage?.message}
                </div>
                <div style={{
                    verticalAlign: 'middle', height: 32, fontSize: 14, paddingLeft: 5,
                    backgroundColor: '#F4F4F4',
                    display: 'flex', alignItems: 'center'
                }}>
                    {selectedMessage?.messageDateTimeFormatted}
                </div>
                <div style={{ paddingLeft: 5, paddingTop: 5, fontSize: 13, overflowY: 'scroll', minHeight: 134, maxHeight: 134 }}>
                    <MessageBodyContent key={_.uniqueId()} message={message} />
                </div>
            </div>
        );
    }

    onRowClick(selectedMessageId: string) {
        this.displayRowDetails(selectedMessageId);
    }

    displayRowDetails(selectedMessageId: string) {
        const {
            messages
        } = this.state;

        const selectedMessage = messages?.messageList.find(x =>
            x.id.toString() === selectedMessageId);

        this.setState({
            selectedMessage
        });
    }

    updateCheckedForDeleteMessageIds(checkedMessageIds: string[]) {
        this.setState({
            checkedForDeleteMessageIds: checkedMessageIds
        });
    }

    onClickOK() {
        const {
            checkedForDeleteMessageIds,
        } = this.state;

        if (checkedForDeleteMessageIds.length) {
            this.setState({
                showModal: true,
                okClicked: true,
                deleteAllClicked: false
            });
        } else {
            this.props.history.push('/LandingPage');
        }
    }

    onClickCancel() {
        this.props.history.push('/LandingPage');
    }

    async onModalToggle(param: any) {

        const {
            selectedFilterType,
            checkedForDeleteMessageIds,
            okClicked,
            deleteAllClicked,
        } = this.state;
        const id = param?.currentTarget?.id ?? param?.target?.id ?? 'modal-confirmation-confirm-button'
        // user confirmed delete
        if(id == 'modal-confirmation-confirm-button') {
            if (okClicked) {
                const deleteMessages = async () => {
                    // delete selected messages
                    await new MessagesAPI().deleteMessages('Selected', checkedForDeleteMessageIds);
                    this.props.history.push('/LandingPage');
                }
                await deleteMessages();
            } else if (deleteAllClicked) {
                const deleteAll = async () => {
                    // delete all messages based on currently selected type
                    new MessagesAPI().deleteMessages(selectedFilterType, []);
                }
                await deleteAll();
                // reload the messages to reflect persistence view
                this.getMessages();
            }
        }
        this.setState({ showModal: false });
    }

    render() {
        const {
            messages,
            selectedMessage,
            requestedMessageId,
            isBusy,
            showModal,
            okClicked,
            deleteAllClicked,
        } = this.state;

        const instruction = 'Click the column headings to sort the message list.';
        var buttons = React.createElement(OKCancelButtons, {
            disableOK: false,
            onClickOK: this.onClickOK.bind(this),
            onClickCancel: this.onClickCancel.bind(this)
        });
        const messageFilterDropdown = this.getMessagesFilterDropdown();

        // get the selected or requested row id if it exists
        let selectedRowId = '0';
        if (selectedMessage && selectedMessage?.id > 0) {
            selectedRowId = selectedMessage.id.toString();
        } else if (requestedMessageId) {
            selectedRowId = requestedMessageId.toString();
        }

        const currentServerTime = messages && messages?.serverTime;
        const gridFooter = currentServerTime && this.getGridFooter(currentServerTime);
        const messageBodyDiv = this.getMessageBodyDiv();

        // configure the appropriate delete confirmation message
        let deleteConfirmationMessage = '';
        if (deleteAllClicked)
            deleteConfirmationMessage = 'Are you sure you want to delete all of these messages?\r\nThis action cannot be undone.';
        else if (okClicked)
            deleteConfirmationMessage = 'Are you sure you want to delete the selected messages?\r\nThis action cannot be undone.';

        return (
            <PageWrapper>
                <DialogWrapper
                    title={'Messages for '} instruction={instruction}
                    isBusy={isBusy}
                    dropdown={messageFilterDropdown}
                    buttons={buttons}
                >
                    <ContentWrapper id={"content-wrapper-messages"}>
                        <ContentRowWrapper>
                            {messages &&
                                <MessagesGrid
                                    messages={messages.messageList}
                                    onRowClick={(x: string) => this.onRowClick(x)}
                                    selectedRowId={selectedRowId}
                                    updateCheckedForDeleteMessageIds={this.updateCheckedForDeleteMessageIds.bind(this)}
                                />
                            }
                        </ContentRowWrapper>
                        <div style={{ height: 5 }}></div>
                        {gridFooter}
                        {messageBodyDiv}
                        <ModalConfirmation
                            isOpen={showModal}
                            message={deleteConfirmationMessage}
                            onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onModalToggle(e)}
                        />
                    </ContentWrapper>
                </DialogWrapper>
            </PageWrapper>
        )
    }
}

