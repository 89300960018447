import * as React from 'react';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import _ from 'lodash';
import Big from 'big.js';
import { Tabs, SelectDropdown, Input, DataItem, Button, TextArea, Checkbox, widths } from '@optum-uicl/ui-core/dist';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { SelectComponent } from '@common/SelectComponent';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { pageLeave } from '@commonResources/userModified';
import { CrudTypes } from '@commonResources/CrudTypes';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IDashboardMaintenanceState, IDashboardMaintenanceActionProps, actionCreators, validationCallback } from '@store/DashboardMaintenance';
import AssuranceMenu from '../../common/AssuranceMenu';
import { SelectDropdownValue } from '@optum-uicl/ui-core/dist/Atoms/SelectDropdown/types';


export const initTab = 0

export const tabSelectionData = [
    {
        domID: 'id-0',
        label: 'Gauge Settings'
    },
    {
        domID: 'id-1',
        label: 'Notification Settings'
    }
]

export const defaultNotifyWhenSelection = {
    "@ID": "0",
    "@Name": "- SELECT AN EVENT -",
    "@type": "2"
}

export const addNewNotificationEventObj = {
    '@ID': "",
    '@Name': '- ADD NEW NOTIFICATION EVENT -'
}

export const defaultEventSelection: MCDashboardEvent = {
    '@ID': "",
    '@Name': "",
    '@EventId': "- SELECT AN EVENT -",
    '@OperatorId': "Greater Than",
    '@Threshold': "",
    '@Body': "",
    '@Time': "00:00:00",
    '@Mon': "N",
    '@Tue': "N",
    '@Wed': "N",
    '@Thu': "N",
    '@Fri': "N",
    '@Sat': "N",
    '@Sun': "N",
    Contacts: { Contact:[]}
}

export const needsThresholdArry = ["1", "2", "3", "4", "33", "34", "40"]

export const needsDateTimeArry = ["1", "3", "4", "6", "7", "33", "34", "39"] // exclude: ["2", "5", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "35", "36", "37", "38", "40", "41", "42"]


const DashboardMaintenanceContainerDiv = styled.div`
    width: 910px;
    height: 560px;

    label{
        max-width: 200px;
        margin-left: 8px;
    }
`

const NotificationSettingsDiv = styled.div`
    display: flex;
`

const NotificationEventsDiv = styled.div`
    height: 457px;
`

const EventNameDiv = styled.div`
    width: 585px;
    margin-left: 10px;
    margin-top: 11px;
`

const NotifyWhenDiv = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 585px;
    height: 100px;
    margin-top: 10px;
`

const NotifyWhenLeftDiv = styled.div`
    width: 300px;
`

const NotifyWhenDropdownDiv = styled.div`
    padding-bottom: 20px;
`

const NotifyWhenDropdownWithThresholdDiv = styled.div`
    padding-bottom: 1px;
`

const ThresholdContainerDiv = styled.div`
    display: flex; 
    align-items: center; 
    justify-content: space-between;
`

const ThresholdElementDiv = styled.div`
    min-width: 120px;
`

const NotifyWhenRightDiv = styled.div`
    width: 237px;
`

const DaysContainerDiv = styled.div`
    display: flex; 
    align-items: center;
    margin-bottom: -15px;
`

const DayDiv = styled.div`
   margin-left: 10px;
   margin-right: 3px;
`

const DayTitleStdDiv = styled.div`
   width: 0px;
`

const DayTitleKernRightDiv = styled.div`
   width: 0px;
   margin-left: 2px;
`

const DayTitleKernLeftDiv = styled.div`
   width: 0px;
   margin-left: -3px;
`

const DayCheckboxDiv = styled.div`
   position: relative;
   bottom: 15px;
`

const TimeContainerDiv = styled.div`
   display: flex;
   align-items: center;
`

const TimeElementDiv = styled.div`
   margin-left: 10px;
   div[id*="dm_time_hr_"]{
    margin-right: 7px;
   }
`

const NotificationMessageBodyDiv = styled.div`
    width: 585px;
`

const ButtonGroupDiv = styled.div`
    display: flex; 
    justify-content: center;
    width: 585px;
`

const ButtonDiv = styled.div`
    margin: 2.5px;
`

const NotificationContactsDiv = styled.div`
    display: flex;
    justify-content: space-between;
    width: 585px;
`

const ContactSelectionButtonsDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 1.5rem;
`

const GaugeSettingsDiv = styled.div`
    width: 375px;
    margin: 20px;

    div[id*="dm_gauge_sel_"]{
        margin-bottom: 10px
    }

    div[class*="InputLabel__LabelContainer-"]{
        margin-bottom: 5px;
        margin-left 5px;
    }

    div[class*="Wrapper-sc-"]{
        margin-left: 5px;
        margin-bottom: 4px;

    }
`

const GaugeDetailOuterWrapperDiv = styled.div`
    display: flex;
    align-items: center;
`

const GaugeDetailInnerWrapperDiv = styled.div`
    width: 375px;

    #moderate{
        margin-left: 5px
    }
`

const FlagImg = styled.img`
    width: 40px;
    margin-top: 1.5rem;
    margin-left: 10px;
`



interface MCDashboardChart {
    '@ID': string;
    '@High': string;
    '@Low': string;
    '@Name': string;
}

interface MCDashboardCharts {
    Chart: MCDashboardChart[];
}

interface MCDashboardEvent {
    '@ID': string;
    '@Name': string;
    '@EventId': string;
    '@OperatorId': string;
    '@Threshold': string;
    '@Body': string;
    '@Time': string;
    '@Mon': string;
    '@Tue': string;
    '@Wed': string;
    '@Thu': string;
    '@Fri': string;
    '@Sat': string;
    '@Sun': string;
    Contacts: MCDashboardEventContacts;
    '@Delete'?: string;
}

interface MCDashboardEventContact {
    '@ID': string;
    '@Delete'?: string;
}

interface MCDashboardEventContacts {
    Contact: MCDashboardEventContact[];
}

interface MCDashboardEvents {
    Event: MCDashboardEvent[];
}

interface MCDashboardContact {
    '@ID': string;
    '@Name': string;
    '@Email': string;
    '@Delete'?: string;
}

interface MCDashboardContacts {
    ContactEmail: MCDashboardContact[];
}

interface MCDashboardNotification {
    '@ID': string;
    '@Name': string;
    '@type': string;
}

interface MCDashboardNotifications {
    Notify: MCDashboardNotification[];
}

interface MCDashboardMaintenanceInfo {
    DashboardChartList: MCDashboardCharts;
    Events: MCDashboardEvents;
    ContactList: MCDashboardContacts;
    NotifyList: MCDashboardNotifications;
}

interface updatedEvent {
    "@ID": string;
}

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
};

interface IComponentState {
    activeTab: number;
    cancelLeave: boolean;
    isHoveringOverAddContacts: boolean;
    isHoveringOverRemoveContacts: boolean;
    isValidationModalOpen: boolean;
    isRemoveNotificationEventModalOpen: boolean;
    validationModalMessage: string;
    moderateOutput: string;
    dashboardMaintenanceInfo: MCDashboardMaintenanceInfo;
    updatedEvents: updatedEvent[];
    gaugeSelection: number;
    eventSelection: MCDashboardEvent;
    contactSelection: MCDashboardContact;
    eventContactSelection: MCDashboardEventContact;
    allContactsKey: any;
    thisEventKey: any;
};



export const DEFAULT_STATE: IComponentState = {
    activeTab: initTab,
    cancelLeave: false,
    isHoveringOverAddContacts: false,
    isHoveringOverRemoveContacts: false,
    isValidationModalOpen: false,
    isRemoveNotificationEventModalOpen: false,
    validationModalMessage: "",
    moderateOutput: "",
    dashboardMaintenanceInfo: {
        DashboardChartList: {
            Chart: []
        },
        Events: {
            Event: []
        },
        ContactList: {
            ContactEmail: []
        },
        NotifyList: {
            Notify: []
        }
    },
    updatedEvents: [],
    gaugeSelection: 0,
    eventSelection: defaultEventSelection,
    contactSelection: {
        '@ID': "",
        '@Name': "",
        '@Email': "",
        '@Delete': "",
    },
    eventContactSelection: {
        '@ID': "",
        '@Delete': "",
    },
    allContactsKey: Date.now() + "" + _.uniqueId(),
    thisEventKey: Date.now() + "" + _.uniqueId(),
};



type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IDashboardMaintenanceProps = IMergeCrudComponentProps<IDashboardMaintenanceState, IDashboardMaintenanceActionProps, IOwnProps>;



export class DashboardMaintenance extends React.Component<IDashboardMaintenanceProps, IComponentState> {
    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
    };

    constructor(props: IDashboardMaintenanceProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        pageLeave();
        const getCrudData = async () => {
            await this.props.action.crud.get({ crudId: CrudTypes.mctiDashboard }, validationCallback);
            const DashboardMaintenanceInfo = this.props.dataStore.crud.data?.DashboardMaintenanceInfo
            DashboardMaintenanceInfo && this.setState({
                dashboardMaintenanceInfo: {
                    ...DashboardMaintenanceInfo,
                    Events: {
                        ...DashboardMaintenanceInfo.Events,
                        Event: DashboardMaintenanceInfo.Events.Event.map(e => {
                            return {
                                ...e,
                                Contacts: {
                                    ...e.Contacts,
                                    Contact: Array.isArray(e.Contacts.Contact) ? e.Contacts.Contact : e.Contacts.Contact === undefined ? [] : [e.Contacts.Contact]
                                }
                            };
                        })
                    },
                }
            }, this.calcModerateOutput)
        }
        getCrudData()
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public calcModerateOutput = () => {
        const {
            gaugeSelection,
            dashboardMaintenanceInfo
        } = this.state
        const {
            Chart
        } = dashboardMaintenanceInfo.DashboardChartList
        const lowInput = Chart[gaugeSelection]["@Low"]
        const highInput = Chart[gaugeSelection]["@High"]

        if (!isNaN(parseInt(lowInput, 10)) && !isNaN(parseInt(highInput, 10)) && (+lowInput < +highInput)) {
            //Offset each High and Low for the Moderate Setting
            const ModHigh = Big(+highInput).minus(0.01).toFixed(2);
            const ModLow = Big(+lowInput).plus(0.01).toFixed(2);
            const updatedChart = {
                ...Chart[gaugeSelection],
                '@High': (+highInput).toFixed(2),
                '@Low': (+lowInput).toFixed(2),
            }
            this.setState({
                dashboardMaintenanceInfo: {
                    ...dashboardMaintenanceInfo,
                    DashboardChartList: {
                        Chart: [
                            ...Chart.slice(0, gaugeSelection),
                            updatedChart,
                            ...Chart.slice(gaugeSelection + 1)
                        ]
                    }
                },
                // If they match do not offset the High/Low
                moderateOutput: ModHigh <= ModLow ? lowInput + " - " + highInput : ModLow + "-" + ModHigh
            })
        } else if (isNaN(parseInt(lowInput, 10)) || isNaN(parseInt(highInput, 10))) {
            const updatedChart = {
                ...Chart[gaugeSelection],
                '@High': isNaN(parseInt(highInput, 10)) ? highInput : (+highInput).toFixed(2),
                '@Low': isNaN(parseInt(lowInput, 10)) ? lowInput : (+lowInput).toFixed(2),
            }
            this.setState({
                dashboardMaintenanceInfo: {
                    ...dashboardMaintenanceInfo,
                    DashboardChartList: {
                        Chart: [
                            ...Chart.slice(0, gaugeSelection),
                            updatedChart,
                            ...Chart.slice(gaugeSelection + 1)
                        ]
                    }
                },
                moderateOutput: ''
            })
        } else {
            const updatedChart = {
                ...Chart[gaugeSelection],
                '@High': "",
            }
            this.setState({
                dashboardMaintenanceInfo: {
                    ...dashboardMaintenanceInfo,
                    DashboardChartList: {
                        Chart: [
                            ...Chart.slice(0, gaugeSelection),
                            updatedChart,
                            ...Chart.slice(gaugeSelection + 1)
                        ]
                    }
                },
                isValidationModalOpen: true,
                validationModalMessage: "The 'High' value may not be less then or equal to the 'Low' value."
            })
        }
    }

    public lowInputOnChange = (e: { target: { value: any; }; }) => {
        const {
            gaugeSelection,
            dashboardMaintenanceInfo
        } = this.state
        const {
            Chart
        } = dashboardMaintenanceInfo.DashboardChartList
        if (!isNaN(e.target.value)) {
            this.setState({
                dashboardMaintenanceInfo: {
                    ...dashboardMaintenanceInfo,
                    DashboardChartList: {
                        Chart: [
                            ...Chart.slice(0, gaugeSelection),
                            {
                                ...Chart[gaugeSelection],
                                '@Low': e.target.value,
                            },
                            ...Chart.slice(gaugeSelection + 1)
                        ]
                    }
                },
            })
        } else {
            this.setState({
                isValidationModalOpen: true,
                validationModalMessage: "The value you typed in must consist of only numbers."
            })
        }
    }

    public highInputOnChange = (e: { target: { value: any; }; }) => {
        const {
            gaugeSelection,
            dashboardMaintenanceInfo
        } = this.state
        const {
            Chart
        } = dashboardMaintenanceInfo.DashboardChartList
        if (!isNaN(e.target.value)) {
            this.setState({
                dashboardMaintenanceInfo: {
                    ...dashboardMaintenanceInfo,
                    DashboardChartList: {
                        Chart: [
                            ...Chart.slice(0, gaugeSelection),
                            {
                                ...Chart[gaugeSelection],
                                "@High": e.target.value
                            },
                            ...Chart.slice(gaugeSelection + 1)
                        ]
                    }
                },
            })
        } else {
            this.setState({
                isValidationModalOpen: true,
                validationModalMessage: "The value you typed in must consist of only numbers."
            })
        }
    }

    public gaugeInputOnSelect = (e: SelectDropdownValue) => {
        const {
            Chart
        } = this.state.dashboardMaintenanceInfo.DashboardChartList
        if (e){
            const gaugeInputIndex = Chart.findIndex(chart => chart["@ID"] === e.value)
            let moderateOutput: string = ""
            if (gaugeInputIndex > -1) {
                const chartSelection = Chart[gaugeInputIndex]
                if (!isNaN(parseInt(chartSelection["@Low"], 10)) && !isNaN(parseInt(chartSelection["@High"], 10)) && (+chartSelection["@Low"] < +chartSelection["@High"])) {
                    const ModHigh = Big(+chartSelection["@High"]).minus(0.01).toFixed(2);
                    const ModLow = Big(+chartSelection["@Low"]).plus(0.01).toFixed(2);
                    moderateOutput = ModHigh <= ModLow ? chartSelection["@Low"] + " - " + chartSelection["@High"] : ModLow + "-" + ModHigh
                }
            }
            this.setState({
                gaugeSelection: gaugeInputIndex,
                moderateOutput
            }, this.calcModerateOutput)
        }
    }

    public notificationEventsOnSelect = (e: { target: { value: any; }; }) => {
        const {
            Event
        } = this.state.dashboardMaintenanceInfo.Events
        const eventSelectionIndex = Event.findIndex((element: MCDashboardEvent) => element["@ID"] === e.target.value)
        console.log(this.state.dashboardMaintenanceInfo)
        console.log(Event[eventSelectionIndex])
        if(eventSelectionIndex >= 0){
            Event[eventSelectionIndex]['@EventId'] = (this.state.dashboardMaintenanceInfo.NotifyList.Notify.find((data)=> (data['@ID'] == Event[eventSelectionIndex]['@EventId'] || data['@Name'] == Event[eventSelectionIndex]['@EventId']))?.['@Name']) || 'robin';
            if (Event[eventSelectionIndex]['@OperatorId'] == '0'){
                Event[eventSelectionIndex]['@OperatorId'] = 'Greater Than';
            }else if (Event[eventSelectionIndex]['@OperatorId'] ==  '1'){
                Event[eventSelectionIndex]['@OperatorId'] = 'Less Than';
            }else if (Event[eventSelectionIndex]['@OperatorId'] == '2'){
                Event[eventSelectionIndex]['@OperatorId'] = 'Equal To';
            }
        }
        if (e.target.value === addNewNotificationEventObj["@ID"] || eventSelectionIndex < 0) {  
            
            this.setState({
                allContactsKey: Date.now() + "" + _.uniqueId(),
                thisEventKey: Date.now() + "" + _.uniqueId(),
                eventContactSelection: DEFAULT_STATE.eventContactSelection,
                contactSelection: DEFAULT_STATE.contactSelection,
                eventSelection: DEFAULT_STATE.eventSelection
            })
        } else {
            console.log(Event[eventSelectionIndex]['@EventId'])
            this.setState({
                allContactsKey: Date.now() + "" + _.uniqueId(),
                thisEventKey: Date.now() + "" + _.uniqueId(),
                eventContactSelection: DEFAULT_STATE.eventContactSelection,
                contactSelection: DEFAULT_STATE.contactSelection,
                eventSelection: Event[eventSelectionIndex]
 
            })
        }
    }

    public notifyWhenInputOnSelect = (e: SelectDropdownValue) => {
        if(e){
        const {
            eventSelection
        } = this.state
        const {
            Notify
        } = this.state.dashboardMaintenanceInfo.NotifyList;
        Notify.push({
            "@ID": "0",
            "@Name": "- SELECT AN EVENT -",
            "@type": "2"
        });
        const selectedEventId = Notify.find(element => element["@ID"] === e.value)

        
            let eventId = e.label
            if (selectedEventId === undefined) {
                eventId= DEFAULT_STATE.eventSelection['@EventId']
            } 
            this.setState({
                eventSelection: {
                    ...eventSelection,
                    '@EventId': (e.label) as string,
                }
            })
        }
    }

    public onSelGreaterLessEqualTo = (e: SelectDropdownValue) => {
        if(e){
            this.setState({
                eventSelection: {
                    ...this.state.eventSelection,
                    "@OperatorId": (e.label) as string
                }
            })
        }
    }

    public addOnClick = () => {
        const {
            eventSelection,
            dashboardMaintenanceInfo
        } = this.state
        const {
            Event
        } = this.state.dashboardMaintenanceInfo.Events
        const newEvent = { ...eventSelection, '@ID': "new[" + _.uniqueId() + "]", "@Name": eventSelection["@Name"].trim().toUpperCase(), Contacts: { Contact: [] }, "@Threshold": eventSelection["@Threshold"].trim() }
        const eventDouble: MCDashboardEvent | undefined = Event.filter(e => e["@Delete"] !== "true").find((element: MCDashboardEvent) => element["@Name"] === eventSelection["@Name"].trim())
        if (eventSelection["@Name"].trim() === "") {
            
            this.setState({
                isValidationModalOpen: true,
                validationModalMessage: "Event Name must not be blank.",
                eventSelection: { ...eventSelection, "@Name": "" }
            })

        } else if (eventSelection["@EventId"] === "- SELECT AN EVENT -") {
            
            this.setState({
                isValidationModalOpen: true,
                validationModalMessage: "You must select a valid Notify When type.",
                eventSelection: { ...eventSelection, "@Name": eventSelection["@Name"].trim().toUpperCase() }
            })
        } else if (eventDouble !== undefined) {
            this.setState({
                isValidationModalOpen: true,
                validationModalMessage: "An event notification by that name already exists. Please type in a different name.",
                eventSelection: {
                    ...eventSelection, "@Name": eventSelection["@Name"].trim().toUpperCase() }
            })
        } else if (eventSelection["@Threshold"].trim() === "" && needsThresholdArry.includes((this.state.dashboardMaintenanceInfo.NotifyList.Notify.find((data)=> data['@Name'] == eventSelection["@EventId"])?.['@ID']) || '')) {
            this.setState({
                isValidationModalOpen: true,
                validationModalMessage: "Threshold Value must not be blank.",
                eventSelection: {
                    ...eventSelection, "@Name": eventSelection["@Name"].trim().toUpperCase(), "@Threshold": eventSelection["@Threshold"].trim()
                }
            })
        } else if ((eventSelection["@Threshold"].search(/\D/g) > -1) && needsThresholdArry.includes((this.state.dashboardMaintenanceInfo.NotifyList.Notify.find((data)=> data['@Name'] == eventSelection["@EventId"])?.['@ID']) || '')) {
            this.setState({
                isValidationModalOpen: true,
                validationModalMessage: "The value you typed in must consist of only numbers.",
                eventSelection: {
                    ...eventSelection, "@Name": eventSelection["@Name"].trim().toUpperCase(), "@Threshold": eventSelection["@Threshold"].trim()
                }
            })
        } else {
            this.setState({
                allContactsKey: Date.now() + "" + _.uniqueId(),
                thisEventKey: Date.now() + "" + _.uniqueId(),
                dashboardMaintenanceInfo: {
                    ...dashboardMaintenanceInfo,
                    Events: {
                        Event: [...Event, newEvent]
                    },
                },
                eventSelection: newEvent
            })
        }

    }

    public updateOnClick = () => {
        const {
            eventSelection,
            dashboardMaintenanceInfo,
            updatedEvents
        } = this.state
        const {
            Event
        } = this.state.dashboardMaintenanceInfo.Events
        const updateIndex = Event.findIndex((element: MCDashboardEvent) => element["@ID"] === eventSelection["@ID"])
        
        if (updateIndex > -1) {
            if (eventSelection["@Name"].trim() === "") {
                this.setState({
                    isValidationModalOpen: true,
                    validationModalMessage: "Event Name must not be blank.",
                    eventSelection: { ...eventSelection, "@Name": "", "@Threshold": eventSelection["@Threshold"].trim() }
                })
            } else if (eventSelection["@EventId"] === "- SELECT AN EVENT -") {
                this.setState({
                    isValidationModalOpen: true,
                    validationModalMessage: "You must select a valid Notify When type.",
                    eventSelection: { ...eventSelection, "@Name": eventSelection["@Name"].trim().toUpperCase(), "@Threshold": eventSelection["@Threshold"].trim() }
                })

            }else if (eventSelection["@Threshold"].trim() === "" && needsThresholdArry.includes((this.state.dashboardMaintenanceInfo.NotifyList.Notify.find((data)=> data['@Name'] == eventSelection["@EventId"])?.['@ID']) || '')) {
                this.setState({
                    isValidationModalOpen: true,
                    validationModalMessage: "Threshold Value must not be blank.",
                    eventSelection: {
                        ...eventSelection, "@Name": eventSelection["@Name"].trim().toUpperCase(), "@Threshold": eventSelection["@Threshold"].trim()
                    }
                })
            } else if ((eventSelection["@Threshold"].search(/\D/g) > -1) && needsThresholdArry.includes((this.state.dashboardMaintenanceInfo.NotifyList.Notify.find((data)=> data['@Name'] == eventSelection["@EventId"])?.['@ID']) || '')) {
                this.setState({
                    isValidationModalOpen: true,
                    validationModalMessage: "The value you typed in must consist of only numbers.",
                    eventSelection: {
                        ...eventSelection, "@Name": eventSelection["@Name"].trim().toUpperCase(), "@Threshold": eventSelection["@Threshold"].trim()
                    }
                })
            } else {
                this.setState({
                    dashboardMaintenanceInfo: {
                        ...dashboardMaintenanceInfo,
                        Events: {
                            Event: [
                                ...Event.slice(0, updateIndex),
                                { ...eventSelection, "@Name": eventSelection["@Name"].trim().toUpperCase(), Contacts: Event[updateIndex].Contacts, "@Threshold": eventSelection["@Threshold"].trim() },
                                ...Event.slice(updateIndex + 1)
                            ]
                        },
                    },
                    updatedEvents: updatedEvents.find((element: updatedEvent) => element["@ID"] === eventSelection["@ID"]) === undefined ? [...updatedEvents, { "@ID": eventSelection["@ID"] }] : updatedEvents,
                    eventSelection: { ...eventSelection, "@Name": eventSelection["@Name"].trim().toUpperCase(), Contacts: Event[updateIndex].Contacts, "@Threshold": eventSelection["@Threshold"].trim() },
                })
            }
        }
    }

    public removeOnClick = () => {
        const {
            eventSelection,
            dashboardMaintenanceInfo
        } = this.state
        const {
            Event
        } = this.state.dashboardMaintenanceInfo.Events
        const updateIndex = Event.findIndex((element: MCDashboardEvent) => element["@ID"] === eventSelection["@ID"])

        if (updateIndex > -1) {
            this.setState({
                allContactsKey: Date.now() + "" + _.uniqueId(),
                thisEventKey: Date.now() + "" + _.uniqueId(),
                eventContactSelection: DEFAULT_STATE.eventContactSelection,
                contactSelection: DEFAULT_STATE.contactSelection,
                dashboardMaintenanceInfo: {
                    ...dashboardMaintenanceInfo,
                    Events: {
                        Event: [
                            ...Event.slice(0, updateIndex),
                            { ...Event[updateIndex], "@Delete": "true"},
                            ...Event.slice(updateIndex + 1)
                        ]
                    },
                },
                eventSelection: DEFAULT_STATE.eventSelection,
                isRemoveNotificationEventModalOpen: false,
                validationModalMessage: ""
            })
        }
    }

    public allContactsOnSelect = (e: { target: { value: any; }; }) => {
        const {
            ContactEmail
        } = this.state.dashboardMaintenanceInfo.ContactList
        const selectedContact = ContactEmail.find((element: MCDashboardContact) => element["@ID"] === e.target.value)
        if (selectedContact !== undefined) {
            this.setState({
                contactSelection: selectedContact
            })
        }
    }

    public addContactToThisEvent = () => {
        const {
            contactSelection,
            eventSelection,
            dashboardMaintenanceInfo,
            updatedEvents
        } = this.state
        const {
            Event
        } = this.state.dashboardMaintenanceInfo.Events
        
        if (eventSelection["@ID"] !== "") {
            const updateIndex = Event.findIndex((element: MCDashboardEvent) => element["@ID"] === eventSelection["@ID"])
            const eventContacts = Event[updateIndex].Contacts.Contact
            const contactIndex = eventContacts.findIndex((element: MCDashboardEventContact) => element["@ID"] === contactSelection["@ID"])

            const thisEventContact = eventSelection.Contacts.Contact
            const thisEventContactIndex = thisEventContact.findIndex((element: MCDashboardEventContact) => element["@ID"] === contactSelection["@ID"])

            if (contactSelection["@ID"] !== "") {
                this.setState({
                    updatedEvents: updatedEvents.find((element: updatedEvent) => element["@ID"] === eventSelection["@ID"]) === undefined ? [...updatedEvents, { "@ID": eventSelection["@ID"] }] : updatedEvents,
                    eventSelection: {
                        ...eventSelection,
                        Contacts: {
                            Contact: [
                                ...thisEventContact.slice(0, thisEventContactIndex),
                                { ...thisEventContact[thisEventContactIndex], "@Delete": "" },
                                ...thisEventContact.slice(thisEventContactIndex + 1)
                            ]
                        }
                    },
                    contactSelection: DEFAULT_STATE.contactSelection,
                    dashboardMaintenanceInfo: {
                        ...dashboardMaintenanceInfo,
                        Events: {
                            Event: [
                                ...Event.slice(0, updateIndex),
                                {
                                    ...Event[updateIndex],
                                    Contacts: {
                                        Contact: contactIndex < 0 ? [...Event[updateIndex].Contacts.Contact, { "@ID": contactSelection["@ID"], "@Delete": "" }] : [
                                            ...eventContacts.slice(0, contactIndex),
                                            { ...eventContacts[contactIndex], "@Delete": "" },
                                            ...eventContacts.slice(contactIndex + 1)
                                        ]
                                    }
                                },
                                ...Event.slice(updateIndex + 1)
                            ]
                        },
                    }
                })
            } else {
                this.setState({
                    isValidationModalOpen: true,
                    validationModalMessage: "Please make a selection from the list."
                })
            }
        }
    }

    public thisEventOnSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {

        const {
            eventSelection
        } = this.state
        const {
            Event
        } = this.state.dashboardMaintenanceInfo.Events
        const updateIndex = Event.findIndex((element: MCDashboardEvent) => element["@ID"] === eventSelection["@ID"])
        const {
            Contact
        } = Event[updateIndex].Contacts
        const selectedContact = Contact.find((element: MCDashboardEventContact) => {
            return element["@ID"] === e.target.value
        })
        
        if (selectedContact !== undefined) {
            this.setState({
                eventContactSelection: selectedContact
            })
        }
    }

    public removeContactFromThisEvent = () => {
        const {
            eventSelection,
            eventContactSelection,
            dashboardMaintenanceInfo,
            updatedEvents
        } = this.state
        const {
            Event
        } = this.state.dashboardMaintenanceInfo.Events
        const updateIndex = Event.findIndex((element: MCDashboardEvent) => element["@ID"] === eventSelection["@ID"])
        const {
            Contact
        } = Event[updateIndex].Contacts
        const thisEventContact = eventSelection.Contacts.Contact
        const contactIndex = Contact.findIndex((element: MCDashboardEventContact) => element["@ID"] === eventContactSelection["@ID"])
        const thisEventContactIndex = thisEventContact.findIndex((element: MCDashboardEventContact) => element["@ID"] === eventContactSelection["@ID"])
        const updatedEventSelection = {
            ...eventSelection,
            Contacts: {
                Contact: [
                    ...thisEventContact.slice(0, thisEventContactIndex),
                    { ...thisEventContact[thisEventContactIndex], "@Delete": "true" },
                    ...thisEventContact.slice(thisEventContactIndex + 1)
                ]
            }
        }

        const updatedEvent = {
            ...Event[updateIndex],
            Contacts: {
                Contact: [
                    ...Contact.slice(0, contactIndex),
                    { ...Contact[contactIndex], "@Delete": "true" },
                    ...Contact.slice(contactIndex + 1)
                ]
            }
        }

        let newUpdatedEvents = updatedEvents
        const newUpdatedEventIndex = updatedEvents.findIndex((element: updatedEvent) => element["@ID"] === eventSelection["@ID"])
        if (newUpdatedEventIndex > -1) {
            newUpdatedEvents = [...updatedEvents.slice(0, newUpdatedEventIndex), updatedEvent, ...updatedEvents.slice(newUpdatedEventIndex + 1)]
        } else {
            newUpdatedEvents = [...updatedEvents, updatedEventSelection]
        }

        if (contactIndex > -1) {
            if (eventContactSelection["@ID"] !== "") {
                this.setState({
                    updatedEvents: newUpdatedEvents,
                    eventSelection: updatedEventSelection,
                    eventContactSelection: DEFAULT_STATE.eventContactSelection,
                    dashboardMaintenanceInfo: {
                        ...dashboardMaintenanceInfo,
                        Events: {
                            Event: [
                                ...Event.slice(0, updateIndex),
                                updatedEvent,
                                ...Event.slice(updateIndex + 1)
                            ]
                        },
                    }
                })
            } else {
                this.setState({
                    isValidationModalOpen: true,
                    validationModalMessage: "Please make a selection from the list."
                })
            }
        }
    }

    public getContactSelectionButtons = () => {
        const {
            isHoveringOverAddContacts,
            isHoveringOverRemoveContacts,
            eventSelection
        } = this.state
        if (eventSelection["@ID"] === "" || !this.props.canEdit) {
            return (
                <ContactSelectionButtonsDiv>
                    <div>
                        <img key={Date.now() + "" + _.uniqueId()} src={'/Shared/Images/Buttons/But_ArrowRight_creamOff.png'} />
                    </div>
                    <div>
                        <img src={'/Shared/Images/Buttons/But_ArrowLeft_creamOff.png'} />
                    </div>
                </ContactSelectionButtonsDiv>
            )
        } else {
            return (
                <ContactSelectionButtonsDiv>
                    <div
                        onMouseEnter={() => this.setState({
                            isHoveringOverAddContacts: true
                        })}
                        onMouseLeave={() => this.setState({
                            isHoveringOverAddContacts: false
                        })}
                        onClick={this.addContactToThisEvent} >
                        {
                            isHoveringOverAddContacts ?
                                <img src={'/Shared/Images/Buttons/But_ArrowRight_creamOff.png'} /> :
                                <img src={'/Shared/Images/Buttons/But_ArrowRight_creamUp.png'} />
                        }
                    </div>
                    <div
                        onMouseEnter={() => this.setState({
                            isHoveringOverRemoveContacts: true
                        })}
                        onMouseLeave={() => this.setState({
                            isHoveringOverRemoveContacts: false
                        })}
                        onClick={this.removeContactFromThisEvent} >
                        {
                            isHoveringOverRemoveContacts ?
                                <img src={'/Shared/Images/Buttons/But_ArrowLeft_creamOff.png'} /> :
                                <img src={'/Shared/Images/Buttons/But_ArrowLeft_creamUp.png'} />
                        }
                    </div>
                </ContactSelectionButtonsDiv>
            )
        }
    }

    public removeDeletedElements = (obj: any) => {
        return obj.filter((e:any) => !!e ? (!("@Delete" in e) || (e["@Delete"] !== "true")) : true)
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        const {
            dashboardMaintenanceInfo
        } = this.state
        const {
            Event
        } = this.state.dashboardMaintenanceInfo.Events
        console.log("ok events", Event)
        const notDeletedEvents = this.removeDeletedElements(Event)
        const isEventMissingContact = !!notDeletedEvents.find((e:any) => {
            let Contact = e?.Contacts?.Contact || [];
            if (!Array.isArray(Contact)) {
                Contact = [
                    Contact
                ]
            }
            const notDeletedContacts = this.removeDeletedElements(Contact)
            return !notDeletedContacts.length
        })

        if (isEventMissingContact) {
            this.setState({
                isValidationModalOpen: true,
                validationModalMessage: "There is an event with no contacts selected, please select at least one contact for all events"
            })
        } else {
            const {
                deltaCrud
            } = this.props.dataStore.ui
            const originalChart = this.props.dataStore.crud.data?.DashboardMaintenanceInfo.DashboardChartList.Chart

            let eventDeltaUnfixed: MCDashboardEvent[] = []
            Event.forEach((currentValue) => {
                const isNew = currentValue["@ID"].slice(0, 4) === "new["
                const isDeleted = currentValue["@Delete"] ? currentValue["@Delete"] === "true" : false
                const isValidEvent = this.state.updatedEvents.find(event => event["@ID"] == currentValue["@ID"]) !== undefined
                const isUpdated = (isValidEvent && !isNew && !isDeleted)
                const isNewXorIsDeleted = (!(isNew && isDeleted) && (isNew || isDeleted))

                if (isUpdated || isNewXorIsDeleted) {
                    eventDeltaUnfixed = [...eventDeltaUnfixed, isDeleted ? { ...currentValue, Contacts: {Contact:[]} } : currentValue]
                }
            })

            const eventDelta = eventDeltaUnfixed.map(elm => elm["@ID"].slice(0, 4) === "new[" ? { ...elm, "@ID": "#" } : elm)

            const chartDelta = dashboardMaintenanceInfo.DashboardChartList.Chart.filter(elm => {
                const orgChart = originalChart ? originalChart.find(oChart => oChart["@ID"] === elm["@ID"]) : undefined
                const isHighChange = orgChart && elm['@High'] !== orgChart['@High']
                const isLowChange = orgChart && elm['@Low'] !== orgChart['@Low']
                return isHighChange || isLowChange
            })

            const deltaData = {
                ...deltaCrud, DashboardMaintenanceInfo: {
                    ...deltaCrud.DashboardMaintenanceInfo,
                    DashboardChartList: {
                        ...deltaCrud.DashboardMaintenanceInfo.DashboardChartList,
                        Chart: chartDelta
                    },
                    Events: {
                        ...deltaCrud.DashboardMaintenanceInfo.Events,
                        Event: eventDelta
                    }
                }
            }
            console.log(deltaData)
            if (deltaData.DashboardMaintenanceInfo.Events.Event.length > 0) {
                deltaData.DashboardMaintenanceInfo.Events.Event[0]['@EventId'] = (this.state.dashboardMaintenanceInfo.NotifyList.Notify.find((data) => data['@Name'] == this.state.eventSelection["@EventId"])?.['@ID']) || '';
                deltaData.DashboardMaintenanceInfo.Events.Event[0]['@OperatorId'] = this.state.eventSelection["@OperatorId"] == 'Greater Than' ? '0' : this.state.eventSelection["@OperatorId"] == 'Less Than' ? '1' : '2';
            }
            this.props.action.crud.update({
                crudId: CrudTypes.mctiDashboard, data: deltaData
            });
            this.props.history.push("/LandingPage");
        }
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({
                cancelLeave: true
            })
        }
        else
            this.props.history.push('/LandingPage');
    }

    public getValueObjArray = (formattedStartStr:string, incrementNum:number, endNum:number) => {
        let valueArray = [{ value: formattedStartStr, label: formattedStartStr }]
        let numberStr = formattedStartStr
        while (parseInt(numberStr, 10) < endNum) {
            const desiredLength = numberStr.length // how many leading zeros
            const number = parseInt(numberStr, 10) + incrementNum; // parse and increment
            numberStr = number + ""; // convert to string

            while (numberStr.length < desiredLength) { // prepend leading zeros
                numberStr = "0" + numberStr
            }

            valueArray = [...valueArray, { value: numberStr, label: numberStr }] 
            // console.log(valueArray);// append new value obj to valueArray
        }
        return valueArray
    }

    public render() {
        const {
            isValidationModalOpen,
            isRemoveNotificationEventModalOpen,
            validationModalMessage,
            moderateOutput,
            allContactsKey,
            thisEventKey,
            eventSelection,
            contactSelection,
            eventContactSelection,
            gaugeSelection,
        } = this.state
        const {
            Chart
        } = this.state.dashboardMaintenanceInfo.DashboardChartList
        const {
            Event
        } = this.state.dashboardMaintenanceInfo.Events
        const {
            ContactEmail
        } = this.state.dashboardMaintenanceInfo.ContactList
        const {
            Notify
        } = this.state.dashboardMaintenanceInfo.NotifyList;
        const updateIndex = Event.findIndex((element: MCDashboardEvent) => element["@ID"] === eventSelection["@ID"]);
        let Contact = Event[updateIndex]?.Contacts?.Contact || [];
        if (!Array.isArray(Contact)) {
            Contact = [
                Contact
            ]
        }

        const notDeletedContacts = this.removeDeletedElements(Contact)

        const allContacts = _.differenceBy(
            ContactEmail,
            notDeletedContacts,
            "@ID"
        )
        const thisEventContacts = _.intersectionBy(
            ContactEmail,
            notDeletedContacts,
            "@ID"
        )

        var buttons = <OKCancelButtons
            disableOK={!this.props.canEdit}
            onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)}
            onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)}
        />;

        const isEventEditingDisabled = !this.props.canEdit || eventSelection["@ID"] === ""
        return (
            <DialogWrapper
                title="Dashboard Maintenance"
                helpUrl={"/Support/Help/HELP_Maint_DashboardMaintenance.htm"}
                buttons={
                    buttons
                }
                instruction={"Use Dashboard Maintenance to configure trending gauges and define event alerts."}
            >
                {
                    this.state.cancelLeave &&
                    <AssuranceMenu {...this.props}
                        Cancel={true}
                        MenuUrl=''
                        stayEvent={() => this.setState({
                            cancelLeave: false
                        })} />
                }
                <DashboardMaintenanceContainerDiv>
                    <Tabs
                        initialTab={initTab}
                        onTabSelect={(e: any, index: any) => {
                            this.setState({
                                activeTab: index.selectedTabIndex
                            })
                        }}
                        tabs={tabSelectionData}
                    />
                    {this.state.activeTab ?
                        <NotificationSettingsDiv>
                            <NotificationEventsDiv>
                                <DialogFieldset style={{width: 'auto'}}>
                                    <DialogLegend>Notification Events</DialogLegend>
                                    <SelectComponent
                                        width={'265px'}
                                        height={'457px'}
                                        onSelect={this.notificationEventsOnSelect}
                                        records={[addNewNotificationEventObj, ...Event.filter(e => e["@Delete"] !== "true")]}
                                        optionFields={{
                                            value: "@ID",
                                            text: "@Name"
                                        }}
                                        selectedValue={eventSelection["@ID"] === "" ? addNewNotificationEventObj["@ID"] : eventSelection["@ID"] }
                                    />
                                </DialogFieldset>
                            </NotificationEventsDiv>
                            <div>
                                <EventNameDiv>
                                    <Input
                                        label="Event Name:"
                                        value={eventSelection["@Name"]}
                                        onChange={(e: { target: { value: any; }; }) => this.setState({
                                            eventSelection: {
                                                ...eventSelection,
                                                "@Name": e.target.value
                                            }
                                        })}
                                        onBlur={() => this.setState({
                                            eventSelection: {
                                                ...eventSelection, "@Name":
                                                    eventSelection["@Name"].trim().toUpperCase()
                                            }
                                        })}
                                        domID={'dm_eventname_' + _.uniqueId()}
                                        maxLength={30 }
                                    />
                                </EventNameDiv>
                                <div>
                                    <DialogFieldset style={{width: 'auto'}} >
                                        <DialogLegend>Event Thresholds</DialogLegend>
                                        <NotifyWhenDiv>
                                            <NotifyWhenLeftDiv>
                                                {needsThresholdArry.includes((Notify.find((data)=> data['@Name'] == eventSelection["@EventId"])?.['@ID']) || '') ?
                                                    <div>
                                                        <NotifyWhenDropdownWithThresholdDiv>
                                                            <SelectDropdown
                                                                domID={'dm_notify_with_thresh_' + _.uniqueId()}
                                                                label="Notify When:"
                                                                menuWidth={300}
                                                                isClearable={false}
                                                                initialValue={{ value: eventSelection["@EventId"], label: eventSelection['@EventId'] }}
                                                                onChange={this.notifyWhenInputOnSelect}
                                                                options={[...[defaultNotifyWhenSelection, ...Notify].map(notification => { return { value: notification["@ID"], label: notification["@Name"] } })]}
                                                            />
                                                        </NotifyWhenDropdownWithThresholdDiv>
                                                        <ThresholdContainerDiv> 
                                                            <ThresholdElementDiv>
                                                                <SelectDropdown
                                                                    domID={'dm_thresh_sel_' + _.uniqueId()}
                                                                    initialValue={{ label: eventSelection["@OperatorId"], value:eventSelection['@OperatorId']}}
                                                                    isClearable={false}
                                                                    menuWidth={150}
                                                                    onChange={this.onSelGreaterLessEqualTo}
                                                                    options={[
                                                                        {
                                                                            label: 'Greater Than',
                                                                            value: '0'
                                                                        },
                                                                        {
                                                                            label: 'Less Than',
                                                                            value: '1'
                                                                        },
                                                                        {
                                                                            label: 'Equal To',
                                                                            value: '2'
                                                                        },
                                                                    ]}
                                                                />
                                                            </ThresholdElementDiv>
                                                            <ThresholdElementDiv>
                                                                <Input
                                                                    value={eventSelection["@Threshold"]}
                                                                    style={{width: '100px', marginLeft: '10px'}}
                                                                    onChange={(e: { target: { value: any; }; }) => this.setState({
                                                                        eventSelection: {
                                                                            ...eventSelection,
                                                                            "@Threshold": e.target.value
                                                                        }
                                                                    })}
                                                                    domID={'dm_thresh_' + _.uniqueId()}
                                                                    maxLength={20}
                                                                />
                                                            </ThresholdElementDiv>
                                                            <p>{["1"].includes((Notify.find((data)=> data['@Name'] == eventSelection["@EventId"])?.['@ID']) || '') ? "Day(s)" : ["4", "34"].includes((Notify.find((data)=> data['@Name'] == eventSelection["@EventId"])?.['@ID']) || '') ? "$" : ["3", "33"].includes((Notify.find((data)=> data['@Name'] == eventSelection["@EventId"])?.['@ID']) || '') ? "#" : "%"}</p>
                                                        </ThresholdContainerDiv>
                                                    </div>
                                                    :
                                                    <NotifyWhenDropdownDiv>
                                                        <SelectDropdown
                                                            domID={'dm_notify_when_' + _.uniqueId()}
                                                            label="Notify When:"
                                                            menuWidth={300}
                                                            isClearable={false}
                                                            initialValue={{ label: eventSelection["@EventId"], value: eventSelection['@EventId'] }}
                                                            onChange={this.notifyWhenInputOnSelect}
                                                            options={[...[defaultNotifyWhenSelection, ...Notify].map(notification => { return { value: notification["@ID"], label: notification["@Name"] } })]}
                                                        />
                                                    </NotifyWhenDropdownDiv>
                                                }
                                            </NotifyWhenLeftDiv>
                                            <NotifyWhenRightDiv> 
                                                {needsDateTimeArry.includes((Notify.find((data)=> data['@Name'] == eventSelection["@EventId"])?.['@ID']) || '') ?
                                                    <div>
                                                        <DaysContainerDiv>
                                                            <p>Days:</p>
                                                            <NotificationSettingsDiv>
                                                                <DayDiv key="Sun">
                                                                    <DayTitleStdDiv>
                                                                        <p>Su</p>
                                                                    </DayTitleStdDiv>
                                                                    <DayCheckboxDiv>
                                                                        <Checkbox
                                                                            name="Sun"
                                                                            checked={eventSelection["@Sun"] === "Y"}
                                                                            onChange={() => {
                                                                                this.setState({
                                                                                    eventSelection: {
                                                                                        ...eventSelection,
                                                                                        "@Sun": eventSelection["@Sun"] === "N" ? "Y" : "N"
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </DayCheckboxDiv>
                                                                </DayDiv>
                                                                <DayDiv key="Mon">
                                                                    <DayTitleKernLeftDiv>
                                                                        <p>Mo</p>
                                                                    </DayTitleKernLeftDiv>
                                                                    <DayCheckboxDiv>
                                                                        <Checkbox
                                                                            name="Mon"
                                                                            checked={eventSelection["@Mon"] === "Y"}
                                                                            onChange={() => {
                                                                                this.setState({
                                                                                    eventSelection: {
                                                                                        ...eventSelection,
                                                                                        "@Mon": eventSelection["@Mon"] === "N" ? "Y" : "N"
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </DayCheckboxDiv>
                                                                </DayDiv>
                                                                <DayDiv key="Tue">
                                                                    <DayTitleStdDiv>
                                                                        <p>Tu</p>
                                                                    </DayTitleStdDiv>
                                                                    <DayCheckboxDiv>
                                                                        <Checkbox
                                                                            name="Tue"
                                                                            checked={eventSelection["@Tue"] === "Y"}
                                                                            onChange={() => {
                                                                                this.setState({
                                                                                    eventSelection: {
                                                                                        ...eventSelection,
                                                                                        "@Tue": eventSelection["@Tue"] === "N" ? "Y" : "N"
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </DayCheckboxDiv>
                                                                </DayDiv>
                                                                <DayDiv key="Wed">
                                                                    <DayTitleKernLeftDiv>
                                                                        <p>We</p>
                                                                    </DayTitleKernLeftDiv>
                                                                    <DayCheckboxDiv>
                                                                        <Checkbox
                                                                            name="Wed"
                                                                            checked={eventSelection["@Wed"] === "Y"}
                                                                            onChange={() => {
                                                                                this.setState({
                                                                                    eventSelection: {
                                                                                        ...eventSelection,
                                                                                        "@Wed": eventSelection["@Wed"] === "N" ? "Y" : "N"
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </DayCheckboxDiv>
                                                                </DayDiv>
                                                                <DayDiv key="Thu">
                                                                    <DayTitleStdDiv>
                                                                        <p>Th</p>
                                                                    </DayTitleStdDiv>
                                                                    <DayCheckboxDiv>
                                                                        <Checkbox
                                                                            name="Thu"
                                                                            checked={eventSelection["@Thu"] === "Y"}
                                                                            onChange={() => {
                                                                                this.setState({
                                                                                    eventSelection: {
                                                                                        ...eventSelection,
                                                                                        "@Thu": eventSelection["@Thu"] === "N" ? "Y" : "N"
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </DayCheckboxDiv>
                                                                </DayDiv>
                                                                <DayDiv key="Fri">
                                                                    <DayTitleKernRightDiv>
                                                                        <p>Fr</p>
                                                                    </DayTitleKernRightDiv>
                                                                    <DayCheckboxDiv>
                                                                        <Checkbox
                                                                            name="Fri"
                                                                            checked={eventSelection["@Fri"] === "Y"}
                                                                            onChange={() => {
                                                                                this.setState({
                                                                                    eventSelection: {
                                                                                        ...eventSelection,
                                                                                        "@Fri": eventSelection["@Fri"] === "N" ? "Y" : "N"
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </DayCheckboxDiv>
                                                                </DayDiv>
                                                                <DayDiv key="Sat">
                                                                    <DayTitleStdDiv>
                                                                        <p>Sa</p>
                                                                    </DayTitleStdDiv>
                                                                    <DayCheckboxDiv>
                                                                        <Checkbox
                                                                            name="Sat"
                                                                            checked={eventSelection["@Sat"] === "Y"}
                                                                            onChange={() => {
                                                                                this.setState({
                                                                                    eventSelection: {
                                                                                        ...eventSelection,
                                                                                        "@Sat": eventSelection["@Sat"] === "N" ? "Y" : "N"
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </DayCheckboxDiv>
                                                                </DayDiv>
                                                            </NotificationSettingsDiv>
                                                        </DaysContainerDiv>
                                                        <TimeContainerDiv>
                                                            <p>Hour:</p>
                                                            <TimeElementDiv>
                                                                <SelectDropdown
                                                                    domID={'dm_time_hr_' + _.uniqueId()}
                                                                    initialValue={{ label: eventSelection["@Time"].split(":")[0], value: eventSelection["@Time"].split(":")[0] }}
                                                                    isClearable={false}
                                                                    menuWidth={90}
                                                                    onChange={(e: SelectDropdownValue) => {
                                                                        if(e){
                                                                            
                                                                            this.setState({
                                                                                eventSelection: {
                                                                                    ...eventSelection,
                                                                                    "@Time": e.value + ":" + eventSelection["@Time"].split(":")[1] + ":" + eventSelection["@Time"].split(":")[2]
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                    options={this.getValueObjArray("00", 1, 23)}
                                                                    
                                                                />
                                                            </TimeElementDiv>
                                                            <TimeElementDiv>
                                                                <p>Min:</p>
                                                            </TimeElementDiv>
                                                            <TimeElementDiv>
                                                                <SelectDropdown
                                                                    domID={'dm_time_min_' + _.uniqueId()}
                                                                    initialValue={{ label: eventSelection["@Time"].split(":")[1], value: eventSelection["@Time"].split(":")[1] }}
                                                                    isClearable={false}
                                                                    menuWidth={90}
                                                                    onChange={(e: SelectDropdownValue) => {
                                                                        if(e){
                                                                            
                                                                            this.setState({
                                                                                eventSelection: {
                                                                                    ...eventSelection,
                                                                                    "@Time": eventSelection["@Time"].split(":")[0] + ":" + e.value + ":" + eventSelection["@Time"].split(":")[2]
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                    options={this.getValueObjArray("00", 5, 55)}
                                                                />
                                                            </TimeElementDiv>
                                                        </TimeContainerDiv>
                                                    </div>
                                                    :
                                                    <p style={{fontSize: '14px'}}>Notify: Immediately after event occurs.</p>
                                                }
                                            </NotifyWhenRightDiv>
                                        </NotifyWhenDiv>
                                    </DialogFieldset>
                                </div>
                                <div >
                                    <DialogFieldset style={{width: 'auto'}} >
                                        <DialogLegend>Notification Message Body</DialogLegend>
                                        <NotificationMessageBodyDiv>
                                            <TextArea
                                                initialValue={eventSelection["@Body"]}
                                                onChange={(e: { target: { value: any; }; }) => this.setState({
                                                    eventSelection: {
                                                        ...eventSelection,
                                                        "@Body": e.target.value
                                                    }
                                                })}
                                                size="small"
                                                maxLength={2048}
                                            />
                                        </NotificationMessageBodyDiv>
                                    </DialogFieldset>
                                    <ButtonGroupDiv>
                                        <ButtonDiv>
                                            {
                                                eventSelection["@ID"] == "" ? <Button
                                                    buttonType="standard"
                                                    name='ADD'
                                                    onClick={this.addOnClick}
                                                    disabled={!this.props.canEdit}
                                                    size="small"
                                                    style={{height: '28px', marginTop: '4px'}}
                                                    type="submit"
                                                /> : <Button
                                                        buttonType="standard"
                                                        name='UPDATE'
                                                        onClick={this.updateOnClick}
                                                        disabled={!this.props.canEdit}
                                                        size="small"
                                                        type="submit"
                                                        style={{height: '28px', marginTop: '4px'}}
                                                    />
                                            }

                                        </ButtonDiv>
                                        <ButtonDiv>
                                            <Button
                                                buttonType="standard"
                                                disabled={isEventEditingDisabled}
                                                name="REMOVE"
                                                onClick={() => this.setState({
                                                    isRemoveNotificationEventModalOpen: true,
                                                    validationModalMessage: `Do you want to remove ${eventSelection["@Name"]}? This action cannot be undone.`
                                                })}
                                                size="small"
                                                type="submit"
                                                style={{height: '28px', marginTop: '4px'}}
                                            />
                                        </ButtonDiv>
                                    </ButtonGroupDiv>
                                </div>
                                <div >
                                    <DialogFieldset style={{marginTop: '3px', width: 'auto'}}>
                                        <DialogLegend>Notification Contacts</DialogLegend>
                                        <NotificationContactsDiv>
                                            <SelectComponent
                                                label="All Contacts:"
                                                key={allContactsKey}
                                                width='275px'
                                                height="86px"
                                                onSelect={isEventEditingDisabled ? () => { } : this.allContactsOnSelect}
                                                records={allContacts}
                                                optionFields={{
                                                    value: "@ID",
                                                    text: "@Name"
                                                }}
                                                style={{marginLeft: '0px'}}
                                                selectedValue={contactSelection["@ID"] === "" ? undefined : contactSelection["@ID"]}
                                                onDoubleClick={isEventEditingDisabled ? () => { } : this.addContactToThisEvent}
                                            />
                                            {this.getContactSelectionButtons()}
                                            <SelectComponent
                                                label="This Event:"
                                                key={thisEventKey}
                                                width='275px'
                                                height="86px"
                                                onSelect={isEventEditingDisabled ? () => { } : this.thisEventOnSelect}
                                                records={thisEventContacts}
                                                optionFields={{
                                                    value: "@ID",
                                                    text: "@Name"
                                                }}
                                                style={{marginLeft: '0px'}}
                                                selectedValue={eventContactSelection["@ID"] === "" ? undefined : eventContactSelection["@ID"]}
                                                onDoubleClick={isEventEditingDisabled ? () => { } : this.removeContactFromThisEvent}
                                            />
                                        </NotificationContactsDiv>
                                    </DialogFieldset>
                                </div>
                            </div>
                        </NotificationSettingsDiv>
                        :
                        <GaugeSettingsDiv>
                            <SelectDropdown
                                domID={'dm_gauge_sel_' + _.uniqueId()}
                                label="Gauge"
                                menuWidth={370}
                                isClearable={false}
                                onChange={this.gaugeInputOnSelect}
                                options={!!Chart.length ? Chart.map(chart => { return { value: chart["@ID"], label: chart["@Name"] } }) : [{ value: "", label: "" }] }
                                initialValue={{ label: !!Chart.length ? Chart[gaugeSelection]["@Name"] : "", value: !!Chart.length ? Chart[gaugeSelection]["@Name"] : "" }}
                            />
                            <GaugeDetailOuterWrapperDiv>
                                <GaugeDetailInnerWrapperDiv>
                                    <Input
                                        label="High >="
                                        value={!!Chart[gaugeSelection] ? Chart[gaugeSelection]["@High"] === "Default" ? "" : Chart[gaugeSelection]["@High"] : ""}
                                        onBlur={this.calcModerateOutput}
                                        onChange={this.highInputOnChange}
                                        domID={'dm_gauge_high_' + _.uniqueId()}
                                        maxLength={25}
                                    />
                                </GaugeDetailInnerWrapperDiv>
                                <FlagImg src={'/Shared/Images/Icons/Icon_Flg_Red.gif'} />
                            </GaugeDetailOuterWrapperDiv>
                            <GaugeDetailOuterWrapperDiv>
                                <GaugeDetailInnerWrapperDiv style={{marginLeft: '5px'}}>
                                    <DataItem
                                        content={moderateOutput}
                                        label="Moderate"
                                        domID='moderate'
                                    />
                                </GaugeDetailInnerWrapperDiv>
                                <FlagImg src={'/Shared/Images/Icons/Icon_Flg_Yellow.gif'} />
                            </GaugeDetailOuterWrapperDiv>
                            <GaugeDetailOuterWrapperDiv>
                                <GaugeDetailInnerWrapperDiv>
                                    <Input
                                        label="Low <="
                                        value={!!Chart[gaugeSelection] ? Chart[gaugeSelection]["@Low"] === "Default" ? "" : Chart[gaugeSelection]["@Low"] : ""}
                                        onBlur={this.calcModerateOutput}
                                        onChange={this.lowInputOnChange}
                                        domID={'dm_gauge_low_' + _.uniqueId()}
                                        maxLength={25}
                                    />
                                </GaugeDetailInnerWrapperDiv>
                                <FlagImg src={'/Shared/Images/Icons/Icon_Flg_Green.gif'} />
                            </GaugeDetailOuterWrapperDiv>
                        </GaugeSettingsDiv>
                    }
                </DashboardMaintenanceContainerDiv>
                <ModalConfirmation
                    title={"Message from webpage"}
                    isOpen={isValidationModalOpen}
                    alertMode={true}
                    onModalToggle={() => this.setState({
                        isValidationModalOpen: false,
                        validationModalMessage: ""
                    })}
                    formattedMessage={validationModalMessage}
                />
                <ModalConfirmation
                    title={"Message from webpage"}
                    isOpen={isRemoveNotificationEventModalOpen}
                    alertMode={false}
                    onDeny={() => this.setState({
                        isRemoveNotificationEventModalOpen: false,
                        validationModalMessage: ""
                    })}
                    onConfirm={this.removeOnClick}
                    formattedMessage={validationModalMessage}
                />
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IDashboardMaintenanceState, IDashboardMaintenanceActionProps, IOwnProps, IDashboardMaintenanceProps, ApplicationState>(
    createCrudMapStateToProps('dashboardMaintenance'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(DashboardMaintenance);

export default withRouter(connectedHoc);