import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '../../commonResources/typography';
import { Colors } from '../../commonResources/colorVariables';
//import { BoxShadows } from '../../commonResources/boxShadows';
import { Borders } from '../../commonResources/borders';
import { DialogWrapper } from './DialogWrapper';
import { ImageButton } from './ImageButton';
import ARMLegendHelp from '../../content/images/QMark_blue.png';
import * as wind from '../../commonResources/window';

export const DialogFieldsetContent2 = styled.fieldset`
    display: flex;
    flex-flow: column nowrap;
    height: 100%;

    width: initial;
    visibility: initial;
    border: 1px solid ${Colors.darkGrey};
    padding: 5px !important;
    margin: 5px !important;
    min-width:0px;

    ${(props: IDialogFieldsetContentProps) => props.widthValue && `width: ${props.widthValue}`};
    ${(props: IDialogFieldsetContentProps) => props.heightValue && `height: ${props.heightValue}`};
    ${(props: IDialogFieldsetContentProps) => props.visibilityValue && `visiblity: ${props.visibilityValue}`};
`;

export const DialogLegend2 = styled.legend`
    width: initial;
    visibility: initial;

    color: ${Colors.grey100};
    ${Typography.mediumLarge};
    ${Typography.defaultLineHeight};
    ${Typography.bold};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};

    display: block;
    min-width: 0;
    margin: -1em 5px 0;
    background-color: ${Colors.grey10};
 `;

export const DialogFieldsetContent = styled.fieldset`

    width: 100%;
    visibility: initial;
    border: 1px solid ${Colors.darkGrey};

    ${(props: IDialogFieldsetContentProps) => props.widthValue && `width: ${props.widthValue}`};
    ${(props: IDialogFieldsetContentProps) => props.heightValue && `height: ${props.heightValue}`};
    ${(props: IDialogFieldsetContentProps) => props.visibilityValue && `visibility: ${props.visibilityValue}`};

    display: block;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    padding-inline-start: 0.75em;
    min-inline-size: min-content;

    padding: 5px ;
    margin: 5px 5px 1px 5px;

`;

export const DialogFieldsetContent4 = styled.fieldset`

    width: 98%;
    visibility: initial;
    border: 1px solid ${Colors.darkGrey};

    ${(props: IDialogFieldsetContentProps) => props.widthValue && `width: ${props.widthValue}`};
    ${(props: IDialogFieldsetContentProps) => props.heightValue && `height: ${props.heightValue}`};
    ${(props: IDialogFieldsetContentProps) => props.visibilityValue && `visibility: ${props.visibilityValue}`};

    display: block;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    padding-inline-start: 0.75em;
    min-inline-size: min-content;

    padding: 5px ;
    margin: 5px 5px 1px 5px;
`;


export const DialogLegend = styled.legend`
    padding-inline-start: 2px; 
    padding-inline-end: 2px;

    color: ${Colors.grey100};
    ${Typography.mediumLarge};
    ${Typography.defaultLineHeight};
    ${Typography.bold};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};
    display: flex;
    aligh-items: center;

    .legend-help-button {
        padding: 0px 1em;
    }
`;


export interface IDialogFieldsetContentProps {
    widthValue?: string;
    heightValue?: string;
    visibilityValue?: string;
};


export const HelpIcon: React.FunctionComponent<any> = (props) => {
    if (props.helpUrl) {
        return (

            <ImageButton
                Title={props.title + 'HelpIcon'}
                legend={true}
                image={{ Off: ARMLegendHelp, Up: ARMLegendHelp, Over: ARMLegendHelp, Down: ARMLegendHelp }}
                className="legend-help-button"
                helpIcon={true}
                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                    wind.BigHelp(props.helpUrl);
                    e.preventDefault();
                }} />
        );
    }
    return null;
}

export const DialogLegend3: React.FunctionComponent<any> = (props) => {

    return (
        <React.Fragment>
            <DialogLegend>{props.children}<HelpIcon {...props} /></DialogLegend>
        </React.Fragment>
    );
}

export const DialogFieldset4: React.FunctionComponent<any> = (props) => {
    return (
        <React.Fragment>
            <DialogFieldsetContent4 visibilityValue={props.visiblity} widthValue={props.width} heightValue={props.height} {...props}>{props.children}</DialogFieldsetContent4>
        </React.Fragment>
    );
}

export const DialogFieldset: React.FunctionComponent<any> = (props) => {
    return (
        <React.Fragment>
            <DialogFieldsetContent visibilityValue={props.visiblity} widthValue={props.width} heightValue={props.height} {...props}>{props.children}</DialogFieldsetContent>
        </React.Fragment>
    );
}

// FYI - this should have been exported from UICL...now we risk them changing the style in the future
export const ErrorMessage = styled.span`
  display: block;
  color: ${Colors.digitalRed130};
  ${Typography.small};
  ${Typography.defaultLineHeight};
  margin-bottom: 1.5em;
`;

export const DialogLabel = styled.label`
    cursor: pointer;

    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.defaultLineHeight};
    ${Typography.bold};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};
`;