import { combineReducers, Reducer } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers'; 
import * as SubmitClaimsStore from './api/SubmitClaimsStore';

export type ISubmitClaimsState = IApiComponentState<any, SubmitClaimsStore.ISubmitClaimsUIState>;

export const actionCreators = createApiComponentActions<SubmitClaimsStore.ActionCreators>(SubmitClaimsStore.actionCreators); 
export type ISubmitClaimsActions = typeof actionCreators;
export type ISubmitClaimsActionProps = IApiComponentActionProps<ISubmitClaimsActions>

// TODO: TS2345 error is coming from ClientApp\src\store\api\SubmitClaimsStore.ts because there are no actionCreators
export const reducers = createApiComponentReducers("submitClaims", SubmitClaimsStore.reducer); 
export const reducer = combineReducers<ISubmitClaimsState>(reducers);
