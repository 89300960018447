import * as React from 'react';
import styled from 'styled-components';
import { DialogLegend, DialogFieldset } from '@common/DialogStyles';
import { CheckBoxComponent } from '../../common/CheckBox';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { SelectComponent } from '../../common/SelectComponent';
import { IMainClaimsData, IMainClaimOptionsUpdateRequest } from '@store/ui/ClaimOptionsUI';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 345px;
    position: relative;
`;

export const DialogContainer = styled.div`
    padding-bottom: 10px;
`

export const SubContentWrapper = styled.div`
    padding-bottom: 20px;
`

export const ManualRunContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    padding: 5px;
    min-height: 310px;
    min-width: 745px !important;
    padding: 10px;
`;

export const RelPaddingDiv = styled.div`
    padding-left: 15%;
`;

export const SuppressInstContainer = styled.div`
    display: inline-flex;
`
export const LabelFont = styled.div`
    fieldset label { 
    font-size: 11px;
    padding-left: 15px;
    letter-spacing: 0.5px;
    line-height: 1.4em;
    text-transform: uppercase;
    font-weight: 600;
}
`

export const InlineContainer = styled.div`
    display: inline-flex;
    margin-top: 10px;
    margin-left: 35px;
`
export const HisInlineContainer = styled.div`
    display: inline-flex;
    margin-top: 10px;
    margin-left: 107px;
`

export const AddInlineLabel = styled.span`
    font-size: 11px;
    font-weight: 600;
    padding-right: 7px;
    padding-left: 11px;
    text-transform: uppercase;
    line-height: 1.4em;
    letter-spacing: 0.5px;
`
export const HisInlineLabel = styled.span`
    font-size: 11px;
    font-weight: 600;
    padding-right: 7px;
    display: inline;
    padding-left: 11px;
    text-transform: uppercase;
    line-height: 1.4em;
    letter-spacing: 0.5px;
`
export const NumberOfDaysLabel = styled.span`
    font-size: 11px;
    font-weight: 600;
    padding-right: 7px;
    display: inline-block;
    padding-left: 10px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    line-height: 1.4em;
    color: rgb(55, 71, 79);
`
export const NumDaysDiv = styled.div`
    display: inline-block;
`
export const DropdownContainer = styled.div`
    position: relative;
    right: 25px;
`

interface IComponentProps {
    mainClaimsData: IMainClaimsData,
    updateMainClaimsOptions(request: IMainClaimOptionsUpdateRequest): void,
};

interface IComponentState {
    isModalOpen: boolean;
    modalMessage: string;
    hisSystemLabels: [];
    suppressMessageArr: any;
}

export const DEFAULT_STATE: IComponentState = {
    isModalOpen: false,
    modalMessage: "",
    hisSystemLabels: [],
    suppressMessageArr: [],
};

export class MainClaimsOptions extends React.Component<IComponentProps, IComponentState> {

    constructor(props: IComponentProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    private addServiceLineOptions = [
        { label: "Disable", value: '0' },
        { label: "Overwrite All", value: '1' },
        { label: "Blank only", value: '2' },
    ];

    private hisSourceSystemOptions = [
        { label: "", value: "0" }
    ]

    private numberOfDaysOptions = [
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" },
        { label: "13", value: "13" },
        { label: "14", value: "14" },
        { label: "15", value: "15" },
    ]

    private styles = {
        globalSuppField: {
            display: 'block'
        },
        globalSuppLabels: {
            display: "block",
            color: "rgb(55, 71, 79)",
            fontFamily: "optum-sans-regular",
            fontSize: "11px",
            fontWeight: 600,
            lineHeight: "1.4em",
            marginBottom: "0.3rem",
            letterSpacing: "0.5px",
        },
        globalSuppCCI: {
            marginLeft: '4em'
        },
        selectDropdown: {
            display: 'inline'
        }
    }

    public componentDidMount() {
        this.handleStyleOnMount()
        this.numberOfDaysOptions.forEach((item) => {
           item.value = this.handleConvertHours(item.value)
        })
    }

    public componentDidUpdate(prevProps: any, prevState: IComponentState) { 
        if (this.props.mainClaimsData.ComplianceGlobalSuppression !== prevProps.mainClaimsData.ComplianceGlobalSuppression) {
            this.checkGlobalSuppression(this.props.mainClaimsData.ComplianceGlobalSuppression)
        }
        if (this.state.modalMessage !== prevState.modalMessage) {
            this.displayModal()
        }
    }

    public validateSuppressionModal(formType: string, suppressionType: string) {
        const { mainClaimsData: { CCIEdits, MNEdits } } = this.props;
        if (formType === 'CCIEdits') {
            return CCIEdits.CCIEdit.some((edit: any) => {
                return edit[suppressionType] === 'Y'
            })
        }
        else {
            return MNEdits.MNEdit.some((edit: any) => {
                return edit[suppressionType] === 'Y'
            })
        }
    }


    public checkGlobalSuppression = (suppressProp?: any) => {
        let pushSuppressMessageArr = []

        if (suppressProp['@SuppressCCIBP'] === 'Y' && this.validateSuppressionModal('CCIEdits', '@Professional')) {
            pushSuppressMessageArr.push('At least one Professional CCI Edit will not apply because Suppress Professional is checked.')
        }

        if (suppressProp['@SuppressMNBP'] === 'Y' && this.validateSuppressionModal('MNEdits', '@Professional') ) {
            pushSuppressMessageArr.push('At least one Professional Medical Necessity Edit will not apply because Suppress Professional is checked.')
        }

        if (suppressProp['@SuppressCCIAI'] === 'Y' && this.validateSuppressionModal('CCIEdits', '@Institutional')) { 
            pushSuppressMessageArr.push('At least one Institutional CCI Edit will not apply because Suppress Institutional is checked.')
        }

        if (suppressProp['@SuppressMNAI'] === 'Y' && this.validateSuppressionModal('MNEdits', '@Instiutional')) {
           pushSuppressMessageArr.push('At least one Institutional Medical Necessity Edit will not apply because Suppress Institutional is checked.')
        }

        if (pushSuppressMessageArr.length > 0) {
            this.handleModal(pushSuppressMessageArr[0])
            pushSuppressMessageArr.shift()

            this.setState({
                suppressMessageArr: pushSuppressMessageArr
            })
        }
    }

    public handleStyleOnMount = () => {
        const cciLabel = document.getElementById('chkDupMedRecNoUB_wrapper')
        const mnLabel = document.getElementById('SuppressMNBP_wrapper')
        const suppProf = document.getElementById('suppress-prof')
        const suppInst = document.getElementById('suppress-inst')
        const cclInstLabel = document.getElementById('SuppressCCIAI')
        const mnInstLabel = document.getElementById('SuppressMNAI')
        const claimUseConfig = document.getElementById('claim-status-use-config')
        const claimStatusLabel = document.getElementById('claim-delete_label')
        const claimStatusWrapper = document.getElementById('claim-delete_wrapper')
        const enablePriorLabel = document.getElementById('_label')
        if (cciLabel) {
            cciLabel.style.marginLeft = "3.5em";
            cciLabel.style.marginRight = "3em";
            cciLabel.style.display = "inline-grid";
        }
        if (enablePriorLabel) {
            enablePriorLabel.style.position = "relative";
            enablePriorLabel.style.bottom = "2px";
            enablePriorLabel.style.right = "10px";
        }
        if (mnLabel) {
            mnLabel.style.display = "inline-grid";
        }
        if (suppProf) {
            suppProf.style.position = "relative";
            suppProf.style.top = "16px";
            suppProf.style.left = "10px";
            suppProf.style.textTransform = 'uppercase';
        }
        if (suppInst) {
            suppInst.style.position = "relative";
            suppInst.style.left = '10px';
            suppInst.style.textTransform = 'uppercase';
        }
        if (cclInstLabel) {
            cclInstLabel.style.marginLeft = "41px";
        }
        if (mnInstLabel) {
            mnInstLabel.style.marginLeft = "23px";
        }
        if (claimUseConfig) {
            claimUseConfig.style.display = 'inline-flex';
        }
        if (claimStatusLabel) {
            claimStatusLabel.style.fontSize = '11px';
        }
        if (claimStatusWrapper) {
            claimStatusWrapper.style.position = 'relative';
            claimStatusWrapper.style.right = '36px';
        }
    }

    public handleConvertHours = (stringDays: string) => {
        const hoursInDay = 24
        const hours = Number(stringDays) * hoursInDay
        return String(hours)
    }

    public handleAddServiceChanges = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const request: IMainClaimOptionsUpdateRequest = {
            Name: "@ActionService", Value: (e.target.value)
        };
        this.props.updateMainClaimsOptions(request);

    }

    public handleHisSourceSystemChanges = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const request: IMainClaimOptionsUpdateRequest = {
            Name: "@ActionHisSystem", Value: (e.target.value)
        };
        this.props.updateMainClaimsOptions(request);
    }

    public handleClaimStatusDaysChanges = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const request: IMainClaimOptionsUpdateRequest = {
            Name: "@RequestHours", Value: (e.target.value)
        };
        this.props.updateMainClaimsOptions(request);
    }

    public handleModal = (message: string) => {
        this.setState({
            isModalOpen: true,
            modalMessage: `${message}`
        })
    }

    public displayModal = () => {
        this.setState({
            isModalOpen: true,
            modalMessage: `${this.state.modalMessage}`
        })

        if (this.state.modalMessage === 'undefined') {
            this.setState({
                isModalOpen: false
            })
        }
    }

    public render() {
        return (
            <ContentWrapper id={"content_wrapper_MainClaimsOptions"}>
                <DialogFieldset id='mainclaims-global-supp-fieldset' style={this.styles.globalSuppField}>
                    <DialogLegend>Compliance Edit Global Suppression</DialogLegend>
                    <ModalConfirmation
                        //title={"Message from webpage"}
                        isOpen={this.state.isModalOpen}
                        alertMode={true}
                        onModalToggle={() => {
                            let newMessageArr = [...this.state.suppressMessageArr]
                            newMessageArr.shift()
                            this.setState({
                                isModalOpen: false,
                                suppressMessageArr: newMessageArr
                            })
                        }}
                        formattedMessage={`${this.state.modalMessage}`}
                        onOK={() => {
                            this.setState({
                                modalMessage: this.state.suppressMessageArr[0]
                            })
                        }}
                        
                    />
                    <SuppressInstContainer>
                    <span id="suppress-prof" style={this.styles.globalSuppLabels}>Suppress Professional</span>
                    <CheckBoxComponent
                        id='chkDupMedRecNoUB'
                        labelAbove='CCI'
                        width='inherit'
                        checked={this.props.mainClaimsData.ComplianceGlobalSuppression['@SuppressCCIBP'] == 'Y'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const request: IMainClaimOptionsUpdateRequest = {
                                Name: "@SuppressCCIBP", Value: (e.target.checked ? 'Y' : 'N')
                            };
                            this.props.updateMainClaimsOptions(request);
                            if (e.target.checked) {
                                if (this.validateSuppressionModal('CCIEdits', '@Professional')) {
                                    this.handleModal('At least one Professional CCI Edit will not apply because Suppress Professional is checked.');
                                }
                            }
                          }
                        }
                    />
                    <CheckBoxComponent
                        id='SuppressMNBP'
                        labelAbove='MN'
                        width='inherit'
                        checked={this.props.mainClaimsData.ComplianceGlobalSuppression['@SuppressMNBP'] == 'Y'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const request: IMainClaimOptionsUpdateRequest = {
                                Name: "@SuppressMNBP", Value: (e.target.checked ? 'Y' : 'N')
                            };
                            this.props.updateMainClaimsOptions(request);
                            if (e.target.checked) {
                                if (e.target.checked) {
                                    if (this.validateSuppressionModal('MNEdits', '@Professional')) {
                                        this.handleModal('At least one Professional Medical Necessity Edit will not apply because Suppress Professional is checked.');
                                    }
                                }
                            }
                          }
                        }
                    />
                    </SuppressInstContainer>
                    <SuppressInstContainer>
                        <span id="suppress-inst" style={this.styles.globalSuppLabels}>Suppress Institutional</span>
                        <CheckBoxComponent
                            id='SuppressCCIAI'
                            width='inherit'
                            checked={this.props.mainClaimsData.ComplianceGlobalSuppression['@SuppressCCIAI'] == 'Y'}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IMainClaimOptionsUpdateRequest = {
                                    Name: "@SuppressCCIAI", Value: (e.target.checked ? 'Y' : 'N')
                                };
                                this.props.updateMainClaimsOptions(request);
                                if (e.target.checked) {
                                    if (this.validateSuppressionModal('CCIEdits', '@Institutional')) {
                                        this.handleModal('At least one Institutional CCI Edit will not apply because Suppress Institutional is checked.');
                                    }
                                }
                              }
                            }
                        />
                        <CheckBoxComponent
                            id='SuppressMNAI'
                            width='inherit'
                            checked={this.props.mainClaimsData.ComplianceGlobalSuppression['@SuppressMNAI'] == 'Y'}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IMainClaimOptionsUpdateRequest = {
                                    Name: "@SuppressMNAI", Value: (e.target.checked ? 'Y' : 'N')
                                };
                                this.props.updateMainClaimsOptions(request);
                                if (e.target.checked) {
                                    if (this.validateSuppressionModal('MNEdits', '@Instiutional')) {
                                        this.handleModal('At least one Institutional Medical Necessity Edit will not apply because Suppress Institutional is checked.');
                                    }
                                }
                              }
                            }
                        />
                    </SuppressInstContainer>
                </DialogFieldset>
                <SubContentWrapper>
                    <DropdownContainer>
                        <InlineContainer>
                            <AddInlineLabel>Add Service Line Description: </AddInlineLabel>
                            <SelectComponent
                                title='claim-status-use-configuration'
                                size={1}
                                width='100px'
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.handleAddServiceChanges(e)}
                                selectedValue={this.props.mainClaimsData.ServiceLineDescription['@Action']}
                                optionFields={{
                                    text: "label",
                                    value: "value"
                                }}
                                records={this.addServiceLineOptions}
                            />
                        </InlineContainer>
                        <HisInlineContainer>
                            <HisInlineLabel> HIS Source System: </HisInlineLabel>
                            <SelectComponent
                                title='claim-status-use-configuration'
                                width='100px'
                                size={1}
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.handleHisSourceSystemChanges(e)}
                                selectedValue={this.props.mainClaimsData.HISSourceSystemOption['@Action']}
                                optionFields={{
                                    text: "@Name",
                                    value: "@ID"
                                }}
                                label=""
                                records={[...this.hisSourceSystemOptions, ...this.props.mainClaimsData.HISSourceSystemList]}
                            />
                        </HisInlineContainer>
                    </DropdownContainer>
                </SubContentWrapper>
                {/*State Reporting Enabled if @SDREnabled is not "1"*/}
                { this.props.mainClaimsData.StateReportingEdits['@SDREnabled'] !== "1" && (
                    <DialogContainer>
                        <DialogFieldset id='state-reporting'>
                            <DialogLegend>State Reporting Edits</DialogLegend>
                            <CheckBoxComponent
                                //id='fsLinkBridgedSecondaryTertiary'
                                label='Enable State Reporting Edits'
                                width='260px'
                                checked={this.props.mainClaimsData.StateReportingEdits['@Enabled'] == 'Y'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const request: IMainClaimOptionsUpdateRequest = {
                                        Name: "@StateReportingEditsEnabled", Value: (e.target.checked ? 'Y' : 'N')
                                    };
                                    this.props.updateMainClaimsOptions(request);
                                    }
                                }
                            />
                        </DialogFieldset>
                    </DialogContainer>
                )}
                { this.props.mainClaimsData.IsClaimStatusUserConfigurationVisible && (
                    <DialogContainer>
                        <DialogFieldset id="claim-status-use-config">
                            <DialogLegend>Claim Status Use Configuration</DialogLegend>
                            <NumberOfDaysLabel> Number of Days: </NumberOfDaysLabel>
                            <NumDaysDiv>
                                <SelectComponent
                                    size={1}
                                    width='40px'

                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.handleClaimStatusDaysChanges(e)}
                                    selectedValue={this.props.mainClaimsData.RequestRetryDelayHours['@Hours']}
                                    optionFields={{
                                        value: "value",
                                        text: "label"
                                    }}
                                    records={this.numberOfDaysOptions}
                                />
                            </NumDaysDiv>               
                        </DialogFieldset>
                    </DialogContainer>
                )}
                    <LabelFont>
                <DialogContainer>
                    <DialogFieldset>
                        <DialogLegend>Claim Deletion</DialogLegend>
                        <CheckBoxComponent
                            id='claim-delete'
                            labelBefore='Prompt on Delete?'
                            width='260px'
                            checked={this.props.mainClaimsData.OtherOptions['@DeleteWarningFlag'] == '1'}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IMainClaimOptionsUpdateRequest = {
                                    Name: "@DeleteWarningFlag", Value: (e.target.checked ? '1' : '0')
                                };
                                this.props.updateMainClaimsOptions(request);
                                }
                            }
                        />
                    </DialogFieldset>
                </DialogContainer>
                <DialogContainer>
                    <DialogFieldset>
                        <DialogLegend>Prior Payer Remit Look Up</DialogLegend>
                        <CheckBoxComponent
                            label='Enable Prior Payer Remit Look Up'
                            width='260px'
                            checked={this.props.mainClaimsData.LinkBridgedSecondaryTertiary['@Enabled'] == '1'}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IMainClaimOptionsUpdateRequest = {
                                    Name: "@RemitEnabled", Value: (e.target.checked ? '1' : '0')
                                };
                                this.props.updateMainClaimsOptions(request);
                                }
                            }
                        />
                    </DialogFieldset>
                </DialogContainer>
                { this.props.mainClaimsData.IsHoldClaimsPendingPropensityToDenyVisible && (
                    <DialogContainer>
                        <DialogFieldset id='propensity'>
                            <DialogLegend>Propensity to Deny</DialogLegend>
                            <CheckBoxComponent
                                label='Hold claims pending a Propensity to Deny response'
                                width='260px'
                                checked={this.props.mainClaimsData.ComplianceDetailSuppression['@HoldClaimsWithPropensityToDeny'] !== '0'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const request: IMainClaimOptionsUpdateRequest = {
                                        Name: "@HoldClaimsWithPropensityToDeny", Value: (e.target.checked ? '1' : '0')
                                    };
                                    this.props.updateMainClaimsOptions(request);
                                    }
                                }
                            />
                            <CheckBoxComponent
                                //id='fsLinkBridgedSecondaryTertiary'
                                label='Hold claims with a high Propensity to Deny score'
                                width='260px'
                                checked={this.props.mainClaimsData.ComplianceDetailSuppression['@HoldClaimsWithHighP2DScore'] !== '0'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const request: IMainClaimOptionsUpdateRequest = {
                                        Name: "@HoldClaimsWithHighP2DScore", Value: (e.target.checked ? '1' : '0')
                                    };
                                    this.props.updateMainClaimsOptions(request);
                                    }
                                }
                            />
                        </DialogFieldset>
                    </DialogContainer>
                )}
                            </LabelFont>
            </ContentWrapper>
        );
    }
};


