import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';

// uicl
import { Grid, TextArea } from '@optum-uicl/ui-core/dist';

import styled from 'styled-components';

// commonResources
import { URLs } from '@commonDevResources/constants';
import { pageLeave } from '@commonResources/userModified';

// common
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import AssuranceMenu from '@common/AssuranceMenu';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';

// store includes
import { ApplicationState } from '@store/index';
import { IAddNoteState, IAddNoteActionProps, actionCreators } from '@store/AddNote';
import { ColumnType } from '@optum-uicl/ui-core/dist/Organisms/Grid/types';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    #AddNoteText {
        width: 900px;
        height: 100px;
        textarea {
                border:1px solid rgb(155, 161, 169);
                box-shadow: rgba(155, 161, 169, 0.25) 0px 2px 2px 0px inset;
            }
    }

    .Description {
        min-width:500px;
        max-width:500px;
    }
`;

export const SelectList = styled.div`
    padding: 5px;
`;

const GridWrapper = styled.div`
    .empty-row{
        background-color:white;
    }

    thead tr th{
        background-color:#fff;
        height:20px;
        width:100px;
        border-bottom: 1px solid rgb(155, 161, 169);
    }

    th div{
        text-transform: uppercase;
        font-weight:600;
         letter-spacing: 0.05em;
    }

    
    table {
        border-collapse: separate;
    }

    table tbody tr > td{
        height:20px;
    }

    table tbody tr td div{
        min-height:1px;
        font-size: 13px !important;
    }
`;

const InputContainer = styled.div`
    padding:7px;

    .bBTdyP {
        color: rgb(55, 71, 79);
        font-size: 11px !important;
        line-height: 1.4em;
        font-weight: 600;
        letter-spacing: 0.05em;
    }
`;

/////////////////////////////////////////////////////////////////////////////////////////////

interface IAddNoteComponentProps {
    canEdit: boolean,
    canView: boolean,
    apiType: string;
}

interface IComponentState {
    cancelLeave: boolean;
    selectedNoteName: string,
    descriptionText: string
}

export const DEFAULT_STATE: IComponentState = {
    cancelLeave: false,
    selectedNoteName: '',
    descriptionText: ''
};

type IOwnProps = IAddNoteComponentProps & RouteComponentProps<{}>;
type IAddNoteProps = IMergeApiComponentProps<IAddNoteState, IAddNoteActionProps, IOwnProps>;

/////////////////////////////////////////////////////////////////////////////////////////////

export class AddNote extends React.Component<IAddNoteProps, IComponentState> {

    static defaultProps: IAddNoteComponentProps = {
        canEdit: false,
        canView: false,
        apiType: "AddNote",
    };

    constructor(props: IAddNoteProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }
        pageLeave();

        this.setBusy(true);
        this.props.action.api.loadData(URLs.api + '/api/data/claimnotes', this.validationCallback.bind(this), this.errorCallback.bind(this));
    }

    public componentWillUnmount() {
        pageLeave();
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.props.dataStore.ui.noteDescription == undefined || this.props.dataStore.ui.noteDescription.length == 0)
            return;

        var delim = '\t';
        var noteDesc = this.props.dataStore.ui.noteDescription.replace(/[\\]/g, "\\\\\\\\");
        var noteName = (this.props.dataStore.ui.selectedNoteName == undefined || this.props.dataStore.ui.selectedNoteName.length == 0 ? "" : this.props.dataStore.ui.selectedNoteName);

        var parameterValue = delim + noteDesc + delim + noteName + delim;
        this.props.history.push('/Claims/Filter/AddNote', { parameter: parameterValue });
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push('/LandingPage');
    }

    highlightSelectedRow(target: any) {
        if (target != null) {
            // clear selected row highlights from ui
            var predefinedNotesGrid = document.getElementById("predefined-notes-grid");
            if (predefinedNotesGrid != null) {
                var selectedRows = predefinedNotesGrid.querySelectorAll('.selected');
                for (var i = 0; i < selectedRows.length; i++) {
                    selectedRows[i].classList.remove('selected');
                }
            }
            // set selected class to grid row
            target.className = 'selected';
        }
    }

    public onSelectPredefinedNote(e: any, record: any) {
        if (record) {

            this.highlightSelectedRow(e.currentTarget);

            this.props.action.ui.selectNote(
                {
                    apiData: this.props.dataStore.api.data,
                    uiData: {
                        index: record.id,
                        noteName: record.ShortName,
                        noteDescription: record.Description
                    }
                });
        }
    }

    public onUpdateNoteText(val: string) {
        this.props.action.ui.updateNoteDescription({
            apiData: this.props.dataStore.api.data,
            uiData: {
                noteDescription: val.split('\r\n').join(' ').split('\n').join(' ')
            }
        });
    }

    public validationCallback(data: any): boolean {
       this.setBusy(false);
       return true;
    }

    public errorCallback(dataStatus: string): boolean {
        this.setBusy(false);
        var str = 'An error occurred retrieving the  call for predefined notes. Error: ' + dataStatus;
        console.error(str);
        return true;
    }

    public setBusy(val: boolean) {
        this.props.action.ui.setBusy({ api: this.props.dataStore.api.data, uiData: { value: val } });
    }

    getGridColumnHeaders() {
        return new Set([
                {
                    dataName: "ShortName",
                    text: 'NAME',
                    sortable: false,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "Description",
                    text: "DESCRIPTION",
                    sortable: false,
                    cellType: "text",
                    isSorted: 0,
                },
            ]);
    }

    public render()
    {
        var instruction = <React.Fragment>Select a predefined note or add a Custom Note. Then, click OK to use the filter to target group of claims for the action.<br /> <i>(For more information, click the help button.)</i></React.Fragment>;
        var buttons = <OKCancelButtons disableOK={!this.props.canEdit || !this.props.dataStore.ui.changed } onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;

        var predefinedNotes: any;
        predefinedNotes = this.props.dataStore.api.data ? this.props.dataStore.api.data : [];

        return (
            <DialogWrapper title="Add Note" instruction={instruction} helpUrl='/Support/Help/AddNote.htm' buttons={buttons} isBusy={this.props.dataStore.ui.isBusy || this.props.dataStore.api.dataStatus === 'REQUEST'}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper>
                    <ContentWrapper>
                      <SelectList>
                        <DialogFieldset height='100%'>
                            <DialogLegend>Select Note</DialogLegend>
                            <GridWrapper>
                                <Grid
                                        columns={this.getGridColumnHeaders() as Set<ColumnType>}
                                        domID='predefined-notes-grid'
                                        isFixedHeader={true}
                                        maxHeight='200px'
                                        //width="150px"
                                        className='grid-width'
                                        records={predefinedNotes}
                                        onClickThrough={(e: any, record: any) => { this.onSelectPredefinedNote(e, record); } }
                                        emptyGridMessage='No Claim Note Types Defined.' 
                                        selectionKey={''}                                />
                            </GridWrapper>
                         </DialogFieldset>
                        </SelectList>
                    </ContentWrapper>
                    <ContentWrapper>
                        <InputContainer>
                            <TextArea
                                className='margin-padding'
                                domID="AddNoteText"
                                label="NOTE:"
                                maxLength={1024}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => this.onUpdateNoteText(e.target.value)}
                                initialValue={this.props.dataStore.ui.noteDescription}
                            />
                        </InputContainer>
                    </ContentWrapper>
                </ContentWrapper>
             </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IAddNoteState, IAddNoteActionProps, IOwnProps, IAddNoteProps, ApplicationState>(
    createApiMapStateToProps('addNote'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(AddNote);


export default withRouter(connectedHoc);