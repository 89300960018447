import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../../commonResources/colorVariables';
import { ARMLoginWrapper, ARMLoginBody, ARMLoginForm, FormRow } from '../Logon/styles/ARMLoginForm';
import { Button, Input} from '@optum-uicl/ui-core/dist';
import { URLs } from '../../../commonResources/constants';

interface CiamProps{ };

interface CiamState {
    portalEndpointURL: string;
};

const LoginPrompt = styled.div`
    padding: 10px 0 5px 0;
    white-space: pre-line;
    font-size: 12px;
    color: ${Colors.black};
    width:100%;
`

const CustomButtonWrapper=styled.div`
    display:flex;
    justify-content:space-around;
    padding-bottom:30px;
    button{
        width:110px;
    }
`;
export default class CiamPortal extends React.Component<CiamProps, CiamState>{

    constructor(props: CiamProps) {
        super(props);
    }

    public componentDidMount() {
        this.getEndPointURL();
    }

    public getEndPointURL = async () => {
        var url = URLs.api + '/api/data/securityInfo/getPortalEndpointURL';
        let endpointURL = ''
        await fetch(url)
            .then((response) => response.json())
            .then((jsonData) => {
                endpointURL = jsonData;
            });
        this.setState({ portalEndpointURL: endpointURL });
        return endpointURL;
    };

    public getPortalEndpoint(e: React.ChangeEvent<HTMLButtonElement>) {
        e.preventDefault();
        let ciamLinkURL = this.state.portalEndpointURL + "/AuthLogin";
        window.location.href = ciamLinkURL;
    }

    public render() {
        return (
            <>
                {/* <LoginPrompt>Log in with</LoginPrompt> */}
                {/* <FormRow>
                    <div> */}
                    <CustomButtonWrapper>
                        <Button
                            domID="ciam-button"
                            type="button"
                            size="control"
                            name='SSO Login'
                            buttonType="emphasized"
                            className="link-button align-top"
                            onClick={(e: any) => this.getPortalEndpoint(e)}
                            disabled={false}
                        />
                    </CustomButtonWrapper>
                            {/* <span id="ciam">Customer Identity Access Management (CIAM)</span> */}
                        {/* </Button> */}
                    {/* </div>
                </FormRow> */}
            </>
        )
    }

}