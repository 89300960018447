import React from "react";
import { CheckBoxComponent } from "@common/CheckBox";
import { Button } from "@optum-uicl/ui-core/dist";
import { DialogWrapper } from "@common/DialogWrapper";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { ARMAuthentication } from "./ARMAuthentication";
import { URLs } from "../../../commonResources/constants"; 
import { ARMCopyright } from "../Logon/ARMCopyright";
import { ClearSecuritySession } from "@commonResources/window";
import { getRawToken } from "@scripts/session/SecurityToken";

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    gap:20px;
    button{
        margin-bottom: 20px;
        width:160px;
    }
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content:center;
    margin:20px;
    width:660px;
`;
const ContentRowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    #_wrapper{
        margin-top:12px;
    }
    a {
        color:#0C55B8;
    }
    a:hover {
        color: #133D97;
    }
    a:visited {
        color: #00184D;
    }
`;
const CustomWrapper= styled.div`
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    margin-bottom: 20px;
    h1{
        color: #002677;
        }
`;
const CheckboxWrapper = styled.div`
    display:flex;
    flex-direction:row;
    margin:0 100px;
    label{
        margin-left:10px;
        font-size:16px;
    }
`;

interface IState {
    isChecked: boolean;
    privacyUrl: string;
}

interface AckDetails {
    HostName: string,
    Silo: string,
    OtherDetails?: string
}

interface AcknowledgementProps extends RouteComponentProps {
    onContinue: () => void;
}

export class Acknowledgement extends React.Component<AcknowledgementProps, IState> {
    constructor(props: AcknowledgementProps) {
        super(props);
        this.state = {
            isChecked: false,
            privacyUrl: "",
        };
    }

    renderLogo() {
        let logo = (<img src='/shared/images/Branding/Default/LoginLogo.png' alt='logo'/> );

        if (sessionStorage.getItem("brandingPath")=='/Achieve') {
            logo = (<img src='/shared/images/Branding/Achieve/LoginLogo.png' alt='logo'/>);
        }
        return logo;
    }

    handleCheckboxChange = () => {
        this.setState(prevState => ({
            isChecked: !prevState.isChecked
        }));
    };

    handleContinueClick = async () => {
        // It puts the check in the basket or it gets the hose again.
        if (!this.state.isChecked) {
            return;
        }
        // If the box is checked, we're going to the home page regardless. If there is
        // an error, logging should capture the user's ack for this go around, and they will
        // be forced to reacknowledge on the next login. If they somehow don't have their global
        // user id in session storage, try and grab a username that will get logged on the backend.
        // If they don't have a user id or a username, something is wrong, so put in a placeholder name 
        // that will trigger further research.
        let userId: string = sessionStorage.getItem("GlobalUserId")
            || sessionStorage.getItem("Username")
            || "error-user-with-no-id-or-username";
        let client: string = sessionStorage.getItem("ClientAlias")
            || "error-no-client-alias-selected";
        let hostname: string = window.location.hostname;

        let ack: AckDetails = {
            HostName: hostname,
            Silo: client
        };

        // Only add in the prop if something was missing in the session item retrieval process.
        if (userId.includes("error") || client.includes("error")) {
            ack.OtherDetails = "ERROR-IN-ACK-PROCESS";
        }
        // Send a POST with the ack data to the endpoint. We're redirecting regardless, but
        // we can log information in the console in case we need future error logging.
        try {
            const response = await fetch(
                `${URLs.api}/api/data/privacy/${userId}/status`,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `${getRawToken()}`
                    },
                    body: JSON.stringify(ack)
            });

            // We only log troubleshooting information on failures but otherwise don't care about the results of this call.
            if (!response.ok) {
                console.error(`Did not receive an OK status from the privacy status endpoint. Received a ${response.status}`);
            }
            else {
                // Handle the response if needed
                const result = await response.json();
                if (!result)
                    console.error("Privacy acknowledgement update did not complete successfully!"); // Only care on false return.
            }

        } catch (error) {
            console.error("An error occurred while trying to submit the user's privacy acknowledgement.", error);
        }

        // Set our in-session accepted flag so we can avoid modifying security tokens or firing off 
        // multiple login processes.
        sessionStorage.setItem("PrivacyAccepted", "1");

        // Can we push on history props to preserve history, or do we need to do a full-on window redirect to user home?
        this.props.history.push("/Home/UserHome");
    };

    handleCancelClick = () => {
        ClearSecuritySession();
        this.props.history.push("/Transactions/Logon");
    };

    viewPrivacyPolicy = (e: React.SyntheticEvent) => {
        e.preventDefault();

        let siteUrl = ARMAuthentication.defaultProps.privacyPolicyUrl;
        let newTab = window.open();
        newTab && (newTab.location.href = siteUrl);
    };

    viewTermsOfUse = (e: React.SyntheticEvent) => {
        e.preventDefault();

        let siteUrl = ARMAuthentication.defaultProps.termsOfUseUrl;
        let newTab = window.open();
        newTab && (newTab.location = siteUrl);
    };

    render() {
        return (
            <>
                <DialogWrapper>
                    <ContentWrapper>
                        {this.renderLogo()}
                        <CustomWrapper>
                            <h1>We've updated our terms of service.</h1>
                            <p>Please review and accept the following terms of service to sign into your account.</p>
                        </CustomWrapper>
                        <ContentRowWrapper>
                            <CheckboxWrapper>
                                <CheckBoxComponent
                                    checked={this.state.isChecked}
                                    onChange={this.handleCheckboxChange}
                                />
                                <label>By selecting this checkbox, I have reviewed and agree to the <a href="#" onClick={this.viewTermsOfUse}>Terms of Use</a> and <a href="#" onClick={this.viewPrivacyPolicy}>Privacy Policy</a>.</label>
                            </CheckboxWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <ButtonWrapper>
                                <Button
                                    name="Continue"
                                    buttonType="emphasized"
                                    disabled={!this.state.isChecked}
                                    onClick={this.handleContinueClick}
                                />
                                <Button
                                    name="Cancel"
                                    buttonType="standard"
                                    onClick={this.handleCancelClick}
                                />
                            </ButtonWrapper>
                        </ContentRowWrapper>
                    </ContentWrapper>
                </DialogWrapper>
                <ARMCopyright/>
            </>
        );
    }
}

export default withRouter(Acknowledgement);