import { ActionTypes, createDataAction, createApiAction, createApiBodyAction } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import { Reducer } from 'redux';
import { defaultFormState } from "@store/ui/BaseCrudUI";
import { URLs } from '../../commonResources/constants';

// -----------------
// STATE - This defines the type of data maintained in the Redux store. ConfigureSubmitterUI
export interface IConfigureSubmitterUIState {
    selectedTabIndex: number;

    checkFilter: string;
    facilityLookup: string;
    cfiProviderNumberUpdatable: string;
    lobByName: string;
    physAutoEntry: string;
    ncpdp: string;
    des: string;
    thirdPartyPrint: string;
    dashboardEnabled: string;
    reconEnabled: string;
    cpiDLookup: string;
    ecarLookup: string;
    mcaMethod: string;
    mcaPayerConfigMethod: string;
    sdrEnabled: string;
    sdrType: string;
    sdrTypeList: any;
    mobileEnabled: string;
    accupostLinkEnabled: string;
    submitRemitFileEnabled: string;
    enforceCIAMLogin: string;
    hospitalId: string;
    fieldName: string;
    tableName: string;
    productProfileId: string;
    productProfileList: any;
    serverName: string;
    parallonSCName: string;
    audit72FacilityConfiguration: string;
    fieldNameError?: string;
    tableNameError?: string;

    siteAccess: string;
    siteSuspend: string;
    release: string;
    medicareDDE: string;
    mcdeEnabled: string;
    filePassThrough: string;
    preBillEnabled: string;
    eligibilityType: string;
    enhancedClaimStatusEnabled: string;
    secondaryDest: string;
    omsId: string;
    billId: string;
    biEnabled: string;
    hcdEnabled: string;
    hcdOMSId: string;
    clearinghousePrintEnabled: string;
    appealsEnabled: string;
    attachmentsEnabled: string;
    editSuitesEnabled: string;
    anticipatedPaymentEnabled: string;
    propensityToDenyEnabled: string;
    enterpriseManagementEnabled: string;
    assuranceDentalEnabled: string;
    enableDigitalBillerAutomation: string;
    ddePlusLinkEnabled: string;
    pcgEditsEnabled: string;
    pcgTenantId: string;
    viewX12Enabled: string;
    hcdOMSIdError?: string;
    omsIdError?: string;
    billError?: string;
    pcgTenantIdError?: string;
}

export const defaultState: IConfigureSubmitterUIState = {
    ...defaultFormState,
    selectedTabIndex: 0,

    checkFilter: "0",
    facilityLookup: "0",
    cfiProviderNumberUpdatable: "0",
    lobByName: "0",
    physAutoEntry: "0",
    ncpdp: "0",
    des: "0",
    thirdPartyPrint: "0",
    dashboardEnabled: "0",
    reconEnabled: "0",
    cpiDLookup: "0",
    ecarLookup: "0",
    mcaMethod: "0",
    mcaPayerConfigMethod: "0",
    sdrEnabled: "0",
    sdrType: "0",
    sdrTypeList: [],
    mobileEnabled: "0",
    accupostLinkEnabled: "0",
    submitRemitFileEnabled: "0",
    enforceCIAMLogin: "0",
    hospitalId: "",
    fieldName: "",
    tableName: "",
    productProfileId: "0",
    productProfileList: [],
    serverName: "",
    parallonSCName: "",
    audit72FacilityConfiguration: "0",
    fieldNameError: undefined,
    tableNameError: undefined,

    siteAccess: "0",
    siteSuspend: "0",
    release: "0",
    medicareDDE: "0",
    mcdeEnabled: "0",
    filePassThrough: "0",
    preBillEnabled: "0",
    eligibilityType: "0",
    enhancedClaimStatusEnabled: "0",
    secondaryDest: "0",
    omsId: "0",
    billId: "0",
    biEnabled: "0",
    hcdEnabled: "0",
    hcdOMSId: "0",
    clearinghousePrintEnabled: "0",
    appealsEnabled: "0",
    attachmentsEnabled: "0",
    editSuitesEnabled: "0",
    anticipatedPaymentEnabled: "0",
    propensityToDenyEnabled: "0",
    enterpriseManagementEnabled: "0",
    assuranceDentalEnabled: "0",
    enableDigitalBillerAutomation: "0",
    ddePlusLinkEnabled: "0",
    pcgEditsEnabled: "0",
    pcgTenantId: "0",
    viewX12Enabled: "1",
    hcdOMSIdError: undefined,
    omsIdError: undefined,
    billError: undefined,
    pcgTenantIdError: undefined,
}

export function mapCrudToUiState(crud: CSConfigureSubmitter): IConfigureSubmitterUIState {
    return {
        selectedTabIndex: 0,
        checkFilter: crud['@CheckFilter'],
        facilityLookup: crud['@FacilityLookup'],
        cfiProviderNumberUpdatable: crud['@CFIProviderNumberUpdatable'],
        lobByName: crud['@LOBByName'],
        physAutoEntry: crud['@PhysAutoEntry'],
        ncpdp: crud['@NCPDP'],
        des: crud['@DES'],
        thirdPartyPrint: crud['@ThirdPartyPrint'],
        dashboardEnabled: crud['@DashboardEnabled'],
        reconEnabled: crud['@ReconEnabled'],
        cpiDLookup: crud['@CPIDLookup'],
        ecarLookup: crud['@ECARLookup'],
        mcaMethod: crud['@MCAMethod'],
        mcaPayerConfigMethod: crud['@MCAPayerConfigMethod'],
        sdrEnabled: crud['@SDREnabled'],
        sdrType: crud['@SDRType'],
        sdrTypeList: crud['SDRTypeList'].SDRType,
        mobileEnabled: crud['@MobileEnabled'],
        accupostLinkEnabled: crud['@AccupostLinkEnabled'],
        submitRemitFileEnabled: crud['@SubmitRemitFileEnabled'],
        enforceCIAMLogin: crud['@EnforceCIAMLogin'],
        hospitalId: crud['@HospitalId'],
        fieldName: crud['@FieldName'],
        tableName: crud['@TableName'],
        productProfileId: crud['@ProductProfileId'],
        productProfileList: crud['ProductProfileList'].ProductProfile,
        serverName: crud['@ServerName'],
        parallonSCName: crud['@ParallonSCName'],
        audit72FacilityConfiguration: crud['@Audit72FacilityConfiguration'],

        siteAccess: setValue(crud['@SiteAccess'], crud['@SiteSuspend']),
        siteSuspend: crud['@SiteSuspend'],
        release: crud['@Release'],
        medicareDDE: crud['@MedicareDDE'],
        mcdeEnabled: crud['@MCDEEnabled'],
        filePassThrough: crud['@FilePassThrough'],
        preBillEnabled: crud['@PreBillEnabled'],
        eligibilityType: crud['@EligibilityType'],
        enhancedClaimStatusEnabled: crud['@EnhancedClaimStatusEnabled'],
        secondaryDest: crud['@SecondaryDest'],
        omsId: crud['@OMSId'],
        billId: crud['@BillId'],
        biEnabled: crud['@BIEnabled'],
        hcdEnabled: crud['@HCDEnabled'],
        hcdOMSId: crud['@HCDOMSId'],
        clearinghousePrintEnabled: crud['@ClearinghousePrintEnabled'],
        appealsEnabled: crud['@AppealsEnabled'],
        attachmentsEnabled: crud['@AttachmentsEnabled'],
        editSuitesEnabled: crud['@EditSuitesEnabled'],
        anticipatedPaymentEnabled: crud['@AnticipatedPaymentEnabled'],
        propensityToDenyEnabled: crud['@PropensityToDenyEnabled'],
        enterpriseManagementEnabled: crud['@EnterpriseManagementEnabled'],
        assuranceDentalEnabled: crud['@AssuranceDentalEnabled'],
        enableDigitalBillerAutomation: crud['@EnableDigitalBillerAutomation'],
        ddePlusLinkEnabled: crud['@DDEPlusLinkEnabled'],
        pcgEditsEnabled: crud['@PCGEditsEnabled'],
        pcgTenantId: crud['@PCGTenantId'],
        viewX12Enabled: crud['@ViewX12Enabled'],
    }
}

export function setValue(siteAccess: any, siteSuspend: any) {
    if (siteSuspend == "1") {
        return "2"
    } else if (siteAccess == "1") {
        return "1"
    } else {
        return "0"
    }
}

interface IConfigureUIFieldUpdate {
    value: string;
}

export interface IListSDRTypeListUIData {
    cssDRTypeList: CSSDRTypeList;
}
interface IConfigureSubmitterUIError {
    value: string;
    id?: string;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface IUpdateConfigure extends ICrudActionData<ConfigureSubmitterInfoType, IConfigureUIFieldUpdate> { }
export interface IInitializeConfigure extends ICrudActionData<ConfigureSubmitterInfoType, CSConfigureSubmitter> { }
export interface IError extends ICrudActionData<ConfigureSubmitterInfoType, IConfigureSubmitterUIError> { }


export const actionCreators = {
    initalizeConfigureSubmitter: (rawApiReturn: IInitializeConfigure) => createDataAction('INITIALIZE_CONFIGURESUBMITTER', rawApiReturn),
    updateCrudData: (fieldInfo: IUpdateConfigure) => createDataAction('UPDATE_CONFIGURESUBMITTER_CRUD', fieldInfo),
    setSiteAccess: (siteAccess: any) => createApiBodyAction('SET_SITEACCESS', `${URLs.api}/api/data/ciam/updateSiloCidSiteAccess/${siteAccess}`, undefined, "POST", undefined),
    setCidType: (data: any) => createApiAction('SET_CIDTYPE', `${URLs.api}/api/data/ciam/setCidType/${data}/`),
    selectTabIndex: (tabIndex: number) => createDataAction('SELECT_CONFIGURESUBMITTER_TAB_INDEX', tabIndex),
    updateCheckFilter: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_CHECKFILTER', updateInfo),
    updateFacilityLookup: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_FACILITYLOOKUP', updateInfo),
    updateCFIProviderNumberUpdatable: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_CFIPROVIDERNUMBERUPDATABLE', updateInfo),
    updateLOBByName: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_LOBBYNAME', updateInfo),
    updatePhysAutoEntry: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_PHYSAUTOENTRY', updateInfo),
    updateNCPDP: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_NCPDP', updateInfo),
    updateDES: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_DES', updateInfo),
    updateThirdPartyPrint: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_THIRDPARTYPRINT', updateInfo),
    updateDashboardEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_DASHBOARDENABLED', updateInfo),
    updateReconEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_RECONENABLED', updateInfo),
    updateCPIDLookup: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_CPIDLOOKUP', updateInfo),
    updateECARLookup: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_ECARLOOKUP', updateInfo),
    updateMCAMethod: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_MCAMETHOD', updateInfo),
    updateMCAPayerConfigMethod: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_MCAPAYERCONFIGMETHOD', updateInfo),
    updateSDREnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_SDRENABLED', updateInfo),
    selectedSDRTypeList: (selectedInfo: IUpdateConfigure) => createDataAction('SELECTED_SDRTYPELIST', selectedInfo),
    updateMobileEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_MOBILEENABLED', updateInfo),
    updateAccupostLinkEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_ACCUPOSTLINKENABLED', updateInfo),
    updateSubmitRemitFileEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_SUBMITREMITFILEENABLED', updateInfo),
    updateEnforceCIAMLogin: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_ENFORCECIAMLOGIN', updateInfo),
    updateHospitalId: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_HOSPITALID', updateInfo),
    updateFieldName: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_FIELDNAME', updateInfo),
    updateTableName: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_TABLENAME', updateInfo),
    selectedProductProfileList: (selectedInfo: IUpdateConfigure) => createDataAction('SELECTED_PRODUCTPROFILELIST', selectedInfo),
    updateParallonSCName: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_PARALLONSCNAME', updateInfo),
    updateAudit72FacilityConfiguration: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_AUDIT72FACILITYCONFIGURATION', updateInfo),
    errorCommand: (fieldInfo: IError) => createDataAction('ERROR_CONFIGURESUBMITTER_FIELD', fieldInfo),

    updateSiteAccess: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_SITEACCESS', updateInfo),
    updateSiteSuspend: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_SITESUSPEND', updateInfo),
    updateRelease: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_RELEASE', updateInfo),
    updateMedicareDDE: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_MEDICAREDDE', updateInfo),
    updateMCDEEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_MCDEENABLED', updateInfo),
    updateFilePassThrough: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_FILEPASSTHROUGH', updateInfo),
    updatePreBillEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_PREBILLENABLED', updateInfo),
    updateEligibilityType: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_ELIGIBILITYTYPE', updateInfo),
    updateEnhancedClaimStatusEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_ENHANCEDCLAIMSTATUSENABLED', updateInfo),
    updateSecondaryDest: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_SECONDARYDEST', updateInfo),
    updateOMSId: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_OMSID', updateInfo),
    updateBillId: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_BILLID', updateInfo),
    updateBIEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_BIENABLED', updateInfo),
    updateHCDEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_HCDENABLED', updateInfo),
    updateHCDOMSId: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_HCDOMSID', updateInfo),
    updateClearinghousePrintEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_CLEARINGHOUSEPRINTENABLED', updateInfo),
    updateAppealsEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_APPEALSENABLED', updateInfo),
    updateAttachmentsEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_ATTACHMENTSENABLED', updateInfo),
    updateEditSuitesEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_EDITSUITESENABLED', updateInfo),
    updateAnticipatedPaymentEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_ANTICIPATEDPAYMENTENABLED', updateInfo),
    updatePropensityToDenyEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_PROPENSITYTODENYENABLED', updateInfo),
    updateEnterpriseManagementEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_ENTERPRISEMANAGEMENTENABLED', updateInfo),
    updateAssuranceDentalEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_ASSURANCEDENTALENABLED', updateInfo),
    updateEnableDigitalBillerAutomation: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_ENABLEDIGITALBILLERAUTOMATION', updateInfo),
    updateDDEPlusLinkEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_DDEPLUSLINKENABLED', updateInfo),
    updatePCGEditsEnabled: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_PCGEDITSENABLED', updateInfo),
    updatePCGTenantId: (updateInfo: IUpdateConfigure) => createDataAction('UPDATE_PCGTENANTID', updateInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.


export const reducer: Reducer<IConfigureSubmitterUIState, KnownActions> = (state: IConfigureSubmitterUIState | undefined, action: KnownActions) => {
    if (state !== undefined) {
        switch (action.type) {

            case 'UPDATE_CONFIGURESUBMITTER_CRUD':
                if (action.data.masterCrud &&
                    action.data.masterCrud.ConfigureSubmitter) {
                    let updateConfigureSubmitter = action.data.masterCrud.ConfigureSubmitter;
                    if (updateConfigureSubmitter != null) {
                        updateConfigureSubmitter['@CheckFilter'] = state.checkFilter;
                        updateConfigureSubmitter['@FacilityLookup'] = state.facilityLookup;
                        updateConfigureSubmitter['@CFIProviderNumberUpdatable'] = state.cfiProviderNumberUpdatable;
                        updateConfigureSubmitter['@LOBByName'] = state.lobByName;
                        updateConfigureSubmitter['@PhysAutoEntry'] = state.physAutoEntry;
                        updateConfigureSubmitter['@NCPDP'] = state.ncpdp;
                        updateConfigureSubmitter['@DES'] = state.des;
                        updateConfigureSubmitter['@ThirdPartyPrint'] = state.thirdPartyPrint;
                        updateConfigureSubmitter['@DashboardEnabled'] = state.dashboardEnabled;
                        updateConfigureSubmitter['@ReconEnabled'] = state.reconEnabled;
                        updateConfigureSubmitter['@CPIDLookup'] = state.cpiDLookup;
                        updateConfigureSubmitter['@ECARLookup'] = state.ecarLookup;
                        updateConfigureSubmitter['@MCAMethod'] = state.mcaMethod;
                        updateConfigureSubmitter['@MCAPayerConfigMethod'] = state.mcaPayerConfigMethod;
                        updateConfigureSubmitter['@SDREnabled'] = state.sdrEnabled;
                        updateConfigureSubmitter['@SDRType'] = state.sdrType;
                        updateConfigureSubmitter['@MobileEnabled'] = state.mobileEnabled;
                        updateConfigureSubmitter['@AccupostLinkEnabled'] = state.accupostLinkEnabled;
                        updateConfigureSubmitter['@SubmitRemitFileEnabled'] = state.submitRemitFileEnabled;
                        updateConfigureSubmitter['@EnforceCIAMLogin'] = state.enforceCIAMLogin;
                        updateConfigureSubmitter['@HospitalId'] = state.hospitalId;
                        updateConfigureSubmitter['@FieldName'] = state.fieldName;
                        updateConfigureSubmitter['@TableName'] = state.tableName;
                        updateConfigureSubmitter['@ProductProfileId'] = state.productProfileId;
                        updateConfigureSubmitter['@ParallonSCName'] = state.parallonSCName;
                        updateConfigureSubmitter['@Audit72FacilityConfiguration'] = state.audit72FacilityConfiguration;

                        updateConfigureSubmitter['@SiteAccess'] = state.siteAccess;
                        updateConfigureSubmitter['@SiteSuspend'] = state.siteSuspend;
                        updateConfigureSubmitter['@Release'] = state.release;
                        updateConfigureSubmitter['@MedicareDDE'] = state.medicareDDE;
                        updateConfigureSubmitter['@MCDEEnabled'] = state.mcdeEnabled;
                        updateConfigureSubmitter['@FilePassThrough'] = state.filePassThrough;
                        updateConfigureSubmitter['@PreBillEnabled'] = state.preBillEnabled;
                        updateConfigureSubmitter['@EligibilityType'] = state.eligibilityType;
                        updateConfigureSubmitter['@EnhancedClaimStatusEnabled'] = state.enhancedClaimStatusEnabled;
                        updateConfigureSubmitter['@SecondaryDest'] = state.secondaryDest;
                        updateConfigureSubmitter['@OMSId'] = state.omsId;
                        updateConfigureSubmitter['@BillId'] = state.billId;
                        updateConfigureSubmitter['@BIEnabled'] = state.biEnabled;
                        updateConfigureSubmitter['@HCDEnabled'] = state.hcdEnabled;
                        updateConfigureSubmitter['@HCDOMSId'] = state.hcdOMSId;
                        updateConfigureSubmitter['@ClearinghousePrintEnabled'] = state.clearinghousePrintEnabled;
                        updateConfigureSubmitter['@AppealsEnabled'] = state.appealsEnabled;
                        updateConfigureSubmitter['@AttachmentsEnabled'] = state.attachmentsEnabled;
                        updateConfigureSubmitter['@EditSuitesEnabled'] = state.editSuitesEnabled;
                        updateConfigureSubmitter['@AnticipatedPaymentEnabled'] = state.anticipatedPaymentEnabled;
                        updateConfigureSubmitter['@PropensityToDenyEnabled'] = state.propensityToDenyEnabled;
                        updateConfigureSubmitter['@EnterpriseManagementEnabled'] = state.enterpriseManagementEnabled;
                        updateConfigureSubmitter['@AssuranceDentalEnabled'] = state.assuranceDentalEnabled;
                        updateConfigureSubmitter['@EnableDigitalBillerAutomation'] = state.enableDigitalBillerAutomation;
                        updateConfigureSubmitter['@DDEPlusLinkEnabled'] = state.ddePlusLinkEnabled;
                        updateConfigureSubmitter['@PCGEditsEnabled'] = state.pcgEditsEnabled;
                        updateConfigureSubmitter['@PCGTenantId'] = state.pcgTenantId;
                        updateConfigureSubmitter['@ViewX12Enabled'] = state.viewX12Enabled;
                    }
                }
                break;

            case 'INITIALIZE_CONFIGURESUBMITTER':
                {
                    const crud = action.data.uiData;
                    if (crud) {
                        return mapCrudToUiState(crud);
                    } else {
                        return { ...state, isBusy: false }
                    }
                    break;
                }

            case 'SET_SITEACCESS':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                            break;

                        case "SUCCESS":
                            {
                                const responseData = action?.responseData;
                                if (responseData) {
                                    return {
                                        ...state,
                                    }
                                } else {
                                    console.log('set siteaccess - no data in request..');
                                }
                                break;
                            }
                        case "FAIL":
                            {
                                console.log(action, 'failed to retrieve set siteaccess data.');
                                break;
                            }
                    }
                    break;
                }

            case 'SET_CIDTYPE':
                switch (action.status.status) {
                    case "REQUEST":
                        break;

                    case "SUCCESS":
                        {
                            let responseData = action?.responseData;
                            if (responseData) {
                                return {
                                    ...state,
                                }
                            } else {
                                console.log('set cidtype - no data received.');
                            }
                            break;
                        }
                    case "FAIL":
                        {
                            console.log(action, 'failed to retrieve set cidtype data.');
                            break;
                        }
                }
                break;
            case 'SELECT_CONFIGURESUBMITTER_TAB_INDEX':
                return {
                    ...state,
                    selectedTabIndex: action.data,
                }
                break;

            case 'UPDATE_CHECKFILTER':
                {
                    if (state.checkFilter !== action.data.uiData.value) {
                        return {
                            ...state,
                            checkFilter: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_FACILITYLOOKUP':
                {
                    if (state.facilityLookup !== action.data.uiData.value) {
                        return {
                            ...state,
                            facilityLookup: action.data.uiData.value,
                            cfiProviderNumberUpdatable: (action.data.uiData.value == "1" || action.data.uiData.value == "2") ? "0" : state.cfiProviderNumberUpdatable
                        }
                    }
                }
                break;

            case 'UPDATE_CFIPROVIDERNUMBERUPDATABLE':
                {
                    if (state.cfiProviderNumberUpdatable !== action.data.uiData.value) {
                        return {
                            ...state,
                            cfiProviderNumberUpdatable: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_LOBBYNAME':
                {
                    if (state.lobByName !== action.data.uiData.value) {
                        return {
                            ...state,
                            lobByName: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_PHYSAUTOENTRY':
                {
                    if (state.physAutoEntry !== action.data.uiData.value) {
                        return {
                            ...state,
                            physAutoEntry: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_NCPDP':
                {
                    if (state.ncpdp !== action.data.uiData.value) {
                        return {
                            ...state,
                            ncpdp: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_DES':
                {
                    if (state.des !== action.data.uiData.value) {
                        return {
                            ...state,
                            des: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_THIRDPARTYPRINT':
                {
                    if (state.thirdPartyPrint !== action.data.uiData.value) {
                        return {
                            ...state,
                            thirdPartyPrint: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_DASHBOARDENABLED':
                {
                    if (state.dashboardEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            dashboardEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_RECONENABLED':
                {
                    if (state.reconEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            reconEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_CPIDLOOKUP':
                {
                    if (state.cpiDLookup !== action.data.uiData.value) {
                        return {
                            ...state,
                            cpiDLookup: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_ECARLOOKUP':
                {
                    if (state.ecarLookup !== action.data.uiData.value) {
                        return {
                            ...state,
                            ecarLookup: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_MCAMETHOD':
                {
                    if (state.mcaMethod !== action.data.uiData.value) {
                        return {
                            ...state,
                            mcaMethod: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_MCAPAYERCONFIGMETHOD':
                {
                    if (state.mcaPayerConfigMethod !== action.data.uiData.value) {
                        return {
                            ...state,
                            mcaPayerConfigMethod: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_SDRENABLED':
                {
                    if (state.sdrEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            sdrEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_MOBILEENABLED':
                {
                    if (state.mobileEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            mobileEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_ACCUPOSTLINKENABLED':
                {
                    if (state.accupostLinkEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            accupostLinkEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_SUBMITREMITFILEENABLED':
                {
                    if (state.submitRemitFileEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            submitRemitFileEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_ENFORCECIAMLOGIN':
                {
                    if (state.enforceCIAMLogin !== action.data.uiData.value) {
                        return {
                            ...state,
                            enforceCIAMLogin: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'SELECTED_SDRTYPELIST':
                {
                    return {
                        ...state,
                        sdrType: action.data.uiData.value,
                    }
                }
                break;

            case 'UPDATE_HOSPITALID':
                {
                    if (state.hospitalId !== action.data.uiData.value) {
                        return {
                            ...state,
                            hospitalId: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_FIELDNAME':
                {
                    if (state.fieldName !== action.data.uiData.value) {
                        return {
                            ...state,
                            fieldName: action.data.uiData.value,
                            fieldNameError: undefined
                        }
                    }
                }
                break;

            case 'UPDATE_TABLENAME':
                {
                    if (state.tableName !== action.data.uiData.value) {
                        return {
                            ...state,
                            tableName: action.data.uiData.value,
                            tableNameError: undefined
                        }
                    }
                }
                break;

            case 'SELECTED_PRODUCTPROFILELIST':
                {
                    return {
                        ...state,
                        productProfileId: action.data.uiData.value,
                    }
                }
                break;

            case 'UPDATE_PARALLONSCNAME':
                {
                    if (state.parallonSCName !== action.data.uiData.value) {
                        return {
                            ...state,
                            parallonSCName: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_AUDIT72FACILITYCONFIGURATION':
                {
                    if (state.audit72FacilityConfiguration !== action.data.uiData.value) {
                        return {
                            ...state,
                            audit72FacilityConfiguration: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'ERROR_CONFIGURESUBMITTER_FIELD':
                if (action.data.uiData.id && action.data.uiData.id.length > 0) {
                    switch (action.data.uiData.id) {
                        case 'ValidateFieldName':
                            return {
                                ...state,
                                fieldNameError: action.data.uiData.value,
                            }
                        case 'ValidateTableName':
                            return {
                                ...state,
                                tableNameError: action.data.uiData.value,
                            }
                        case 'ValidateHCDOMSId':
                            return {
                                ...state,
                                hcdOMSIdError: action.data.uiData.value,
                            }
                        case 'ValidateOMSId':
                            return {
                                ...state,
                                omsIdError: action.data.uiData.value,
                            }
                        case 'ValidateBillId':
                            return {
                                ...state,
                                billError: action.data.uiData.value,
                            }
                        case 'ValidatePCGTenantId':
                            return {
                                ...state,
                                pcgTenantIdError: action.data.uiData.value,
                            }
                    }
                }
                break;

            //ADDON
            case 'UPDATE_SITEACCESS':
                {
                    if (state.siteAccess !== action.data.uiData.value) {
                        return {
                            ...state,
                            siteAccess: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_SITESUSPEND':
                {
                    if (state.siteSuspend !== action.data.uiData.value) {
                        return {
                            ...state,
                            siteSuspend: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_RELEASE':
                {
                    if (state.release !== action.data.uiData.value) {
                        return {
                            ...state,
                            release: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_MEDICAREDDE':
                {
                    if (state.medicareDDE !== action.data.uiData.value) {
                        return {
                            ...state,
                            medicareDDE: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_MCDEENABLED':
                {
                    if (state.mcdeEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            mcdeEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_FILEPASSTHROUGH':
                {
                    if (state.filePassThrough !== action.data.uiData.value) {
                        return {
                            ...state,
                            filePassThrough: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_PREBILLENABLED':
                {
                    if (state.preBillEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            preBillEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_ELIGIBILITYTYPE':
                {
                    if (state.eligibilityType !== action.data.uiData.value) {
                        return {
                            ...state,
                            eligibilityType: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_ENHANCEDCLAIMSTATUSENABLED':
                {
                    if (state.enhancedClaimStatusEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            enhancedClaimStatusEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_SECONDARYDEST':
                {
                    if (state.secondaryDest !== action.data.uiData.value) {
                        return {
                            ...state,
                            secondaryDest: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_OMSID':
                {
                    if (state.omsId !== action.data.uiData.value) {
                        return {
                            ...state,
                            omsId: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_BILLID':
                {
                    if (state.billId !== action.data.uiData.value) {
                        return {
                            ...state,
                            billId: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_BIENABLED':
                {
                    if (state.biEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            biEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_HCDOMSID':
                {
                    if (state.hcdOMSId !== action.data.uiData.value) {
                        return {
                            ...state,
                            hcdOMSId: action.data.uiData.value,
                            hcdOMSIdError: undefined
                        }
                    }
                }
                break;

            case 'UPDATE_HCDENABLED':
                {
                    if (state.hcdEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            hcdEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_CLEARINGHOUSEPRINTENABLED':
                {
                    if (state.clearinghousePrintEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            clearinghousePrintEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_APPEALSENABLED':
                {
                    if (state.appealsEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            appealsEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_ATTACHMENTSENABLED':
                {
                    if (state.attachmentsEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            attachmentsEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_EDITSUITESENABLED':
                {
                    if (state.editSuitesEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            editSuitesEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_ANTICIPATEDPAYMENTENABLED':
                {
                    if (state.anticipatedPaymentEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            anticipatedPaymentEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_PROPENSITYTODENYENABLED':
                {
                    if (state.propensityToDenyEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            propensityToDenyEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_ENTERPRISEMANAGEMENTENABLED':
                {
                    if (state.enterpriseManagementEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            enterpriseManagementEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_ASSURANCEDENTALENABLED':
                {
                    if (state.assuranceDentalEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            assuranceDentalEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_ENABLEDIGITALBILLERAUTOMATION':
                {
                    if (state.enableDigitalBillerAutomation !== action.data.uiData.value) {
                        return {
                            ...state,
                            enableDigitalBillerAutomation: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_DDEPLUSLINKENABLED':
                {
                    if (state.ddePlusLinkEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            ddePlusLinkEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_PCGEDITSENABLED':
                {
                    if (state.pcgEditsEnabled !== action.data.uiData.value) {
                        return {
                            ...state,
                            pcgEditsEnabled: action.data.uiData.value,
                        }
                    }
                }
                break;

            case 'UPDATE_PCGTENANTID':
                {
                    if (state.pcgTenantId !== action.data.uiData.value) {
                        return {
                            ...state,
                            pcgTenantId: action.data.uiData.value,
                        }
                    }
                }
                break;


            default:
                return state;
        }
    }
    return state || defaultState;
}