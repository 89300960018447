import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Help, Checkbox } from '@optum-uicl/ui-core/dist';
import styled from 'styled-components';
import { DialogWrapper, DialogButtonOK, DialogButtonCancel, OKCancelButtons, ContentColumnWrapper } from '@common/DialogWrapper';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IRunBridgeRoutinesState, IRunBridgeRoutinesActionProps, actionCreators } from '@store/RunBridgeRoutines';

export const LabelWrapper = styled.label`
                    text-transform: uppercase;
                    display: block;
                    margin: 0.1rem;
                    margin-bottom: 0.3rem;
                    margin-left: 0.4rem;
                    color: #37474F;
                    font-size: 12px;
                    line-height: 1.4em;
                    font-weight: 600;
                    letter-spacing: 0.5px;
}
`;
export const LabelWrapperExtended = styled(LabelWrapper)`
                    text-transform: uppercase;
                    font-size: 12px;
}
`;

export const ContentWrapper = styled.div`
                display: flex;
                flex:1 1 auto;
                flex-direction: column;
                width:300px;
                align-items:center;
`;
export const ContentRowWrapper = styled.div`
                display: flex;
                flex:1 1 auto;
                flex-direction: row;
                margin:5px;
`;


interface IComponentProps {
    canView: boolean,
    canEdit: boolean,
    process: string,
    parameter: string,
    apiType: string
    title: string,

};

interface IComponentState {

};

export const DEFAULT_STATE: IComponentState = {

};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IRunBridgeRoutinesProps = IMergeCrudComponentProps<IRunBridgeRoutinesState, IRunBridgeRoutinesActionProps, IOwnProps>;

export class RunBridgeRoutines extends React.Component<IRunBridgeRoutinesProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canView: false,
        canEdit: false,
        process: "RunBridgeRoutines",
        parameter: '',
        apiType: "ClaimFilter",
        title: "RUN BRIDGE ROUTINES",
    };

    constructor(props: IRunBridgeRoutinesProps) {
        super(props);
    }

    public componentDidMount() {
        if (!this.props.canView)
            this.props.history.push("/LandingPage");
    }

    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    onToggleConfirmation(e: React.MouseEvent<HTMLElement>) {
        this.props.action.confirm.closeConfirm();
    }

    public onClickConfirm(e: React.MouseEvent<HTMLButtonElement>) {
        this.props.dataStore.confirm.confirmCallback();
    }

    public render() {
        var buttons = <OKCancelButtons disableOK={!this.props.canEdit} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOkRunBridgeRoutines(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        const bottomInstructions = <React.Fragment>Use this page to apply 307, 327, 328, 329 and/or 330 bridge routines to claims that have already been bridged into the system. <span className='instructionRed'>Warning!</span> It may be very difficult to undo the changes made to claims by bridge routines. Do not use this feature on a production environment unless the routines have been thoroughly tested.</React.Fragment>;
        return (
            <React.Fragment>
                <DialogWrapper title={this.props.title} instruction={bottomInstructions} helpUrl='/SupportTools/Help/SUP_RunBridgeRoutines.htm' buttons={buttons} >
                    <ContentWrapper>
                        <ContentRowWrapper style={{ marginLeft: '0%' }}>
                            <LabelWrapperExtended>Select Routine File(s): </LabelWrapperExtended>
                            <Checkbox
                                domID="307"
                                name="307"
                                checked={this.props.dataStore.ui.Rtn307Checked}
                                onChange={(e: React.ChangeEvent<HTMLInputElement> | React.SyntheticEvent<Element, Event>, checked?: boolean) => {
                                    this.props.action.ui.updateRtn307(checked);
                                }}
                            />
                            <LabelWrapper>307</LabelWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper style={{ marginLeft: '55%' }}>
                            <Checkbox
                                domID="327"
                                name="327"
                                checked={this.props.dataStore.ui.Rtn327Checked}
                                onChange={(e: React.ChangeEvent<HTMLInputElement> | React.SyntheticEvent<Element, Event>, checked?: boolean) => {
                                    this.props.action.ui.updateRtn327(checked);
                                }}
                            />
                            <LabelWrapper>327</LabelWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper style={{ marginLeft: '55%' }}>
                            <Checkbox
                                domID="328"
                                name="328"
                                checked={this.props.dataStore.ui.Rtn328Checked}
                                onChange={(e: React.ChangeEvent<HTMLInputElement> | React.SyntheticEvent<Element, Event>, checked?: boolean) => {
                                    this.props.action.ui.updateRtn328(checked);
                                }}
                            />
                            <LabelWrapper>328</LabelWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper style={{ marginLeft: '55%' }}>
                            <Checkbox
                                domID="329"
                                name="329"
                                checked={this.props.dataStore.ui.Rtn329Checked}
                                onChange={(e: React.ChangeEvent<HTMLInputElement> | React.SyntheticEvent<Element, Event>, checked?: boolean) => {
                                    this.props.action.ui.updateRtn329(checked);
                                }}
                            />
                            <LabelWrapper>329</LabelWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper style={{ marginLeft: '55%' }}>
                            <Checkbox
                                domID="330"
                                name="330"
                                checked={this.props.dataStore.ui.Rtn330Checked}
                                onChange={(e: React.ChangeEvent<HTMLInputElement> | React.SyntheticEvent<Element, Event>, checked?: boolean) => {
                                    this.props.action.ui.updateRtn330(checked);
                                }}
                            />
                            <LabelWrapper>330</LabelWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper >
                            <Checkbox
                                domID="Validate"
                                name="Validate"
                                checked={this.props.dataStore.ui.ValidateChecked}
                                onChange={(e: React.ChangeEvent<HTMLInputElement> | React.SyntheticEvent<Element, Event>, checked?: boolean) => {
                                    this.props.action.ui.updateValidate(checked);
                                }}
                            />
                            <LabelWrapper>Revalidate changed claims</LabelWrapper>
                        </ContentRowWrapper>
                    </ContentWrapper>
                    <ModalConfirmation
                        isOpen={this.props.dataStore.ui.errorPopup}
                        alertMode
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => {
                            this.props.action.ui.errorPopup(false)
                        }}
                        formattedMessage={(<div><p>Please select at least one routine file to run.</p></div>)}
                        message={this.props.dataStore.confirm.message}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                            this.props.action.ui.errorPopup(false)
                        }}
                    />
                </DialogWrapper>
            </React.Fragment>
        );
    }

    onOkRunBridgeRoutines(e: React.ChangeEvent<HTMLButtonElement>): void {
        if (!(this.props.dataStore.ui.Rtn307Checked || this.props.dataStore.ui.Rtn327Checked || this.props.dataStore.ui.Rtn328Checked || this.props.dataStore.ui.Rtn329Checked || this.props.dataStore.ui.Rtn330Checked)) {
            this.props.action.ui.errorPopup(true)
        }
        else {
            var Options = "";
            if (this.props.dataStore.ui.Rtn307Checked)
                Options = "307";
            if (this.props.dataStore.ui.Rtn327Checked)
                if (Options == "")
                    Options = "327";
                else
                    Options = String(Options) + ";327";
            if (this.props.dataStore.ui.Rtn328Checked)
                if (Options == "")
                    Options = "328";
                else
                    Options = String(Options) + ";328";
            if (this.props.dataStore.ui.Rtn329Checked)
                if (Options == "")
                    Options = "329";
                else
                    Options = String(Options) + ";329";
            if (this.props.dataStore.ui.Rtn330Checked)
                if (Options == "")
                    Options = "330";
                else
                    Options = String(Options) + ";330";
            if (this.props.dataStore.ui.ValidateChecked)
                Options = String(Options) + ";Validate";

            this.props.history.push('/Claims/Filter/RunBridgeRoutines', {
                process: this.props.process,
                parameter: Options,
                title: this.props.title,
            });
        }
    }
    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push('/LandingPage');
    }
};

var connectedHoc = connect<IRunBridgeRoutinesState, IRunBridgeRoutinesActionProps, IOwnProps, IRunBridgeRoutinesProps, ApplicationState>(
    createCrudMapStateToProps('runBridgeRoutines'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(RunBridgeRoutines);

export default withRouter(connectedHoc);
