import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { DialogWrapper, DialogButtonOK } from '@common/DialogWrapper';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps } from '@scripts/util/CrudComponentHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IReportListState, IReportListActionProps, actionCreators } from '@store/ReportList';
import { URLs } from '@commonDevResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';
import { DialogFieldset } from '@common/DialogStyles';
import { ARMGrid } from '@common/UICLWrappers/ARMGrid';
import { ARMHeaderCell } from '@common/UICLWrappers/ARMHeaderCell';
import { FooterInstructions } from '@common/FooterInstructions';
import { LogMessage } from '../../../scripts/util/LogHelper';
import { ICookies_Config } from '@store/ConfigData';
import { SortHelper, ISortIndicator, SortDataType, SortOrder } from "@scripts/util/SortHelper"
import { columnType } from '@optum-uicl/ui-core/dist/Organisms/VirtualGrid/types';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin-bottom:8px;
        
    #report-id {
        overflow: 'auto',
        padding: '1px',
    }
    #dialog-container{
        align-items: inherit !important;
    }
    .EventLabel, .Event {
        vertical-align: inherit;
        width:180px;
    }
    .CIDLabel, .CID {
        vertical-align: inherit;
        width:146px;
    }
    .NameLabel, .Name {
        vertical-align: inherit;
        width:400px;
        word-break: break-word;
    }
    .StartLabel, .EndLabel, .Start, .End {
        vertical-align: inherit;
        width:106px;
        word-break: break-word;
    }
     .IntervalLabel, .Interval {
        vertical-align: inherit;
        width:146px;
        word-break: break-word;
    }
    .LagDaysLabel, .LagDays {
        vertical-align: inherit;
        width:148px;
        word-break: break-word;
    }
    .ScheduledLabel, .Scheduled {
        vertical-align: inherit;
        width:206px;
        word-break: break-word;
    }
    .selectDropdown {
        vertical-align: inherit;
    }
    select {
        font-size: 12px;
        font-weight: 600;
    }   
    table thead th button{
        margin:5px 8px;
    }

`;

interface IComponentProps {
    canView: boolean;      // SecurityBits.FN_VIEW_REPORTS
};

interface IComponentState {
    selectedOperation: string;
    selectedFilterType: string;
    reportFormAPIData: any[];
    sortIndicator: {
        sortColumn: string,
        sortDirection: string
    };
    pageTotal: number;
    pageCount: number;
    data: any;
    loader: boolean;
}

interface HeaderProps { columns: {} }

export const DEFAULT_STATE: IComponentState = {
    selectedOperation: 'cid',
    selectedFilterType: '- Event Type -',
    reportFormAPIData: [],
    sortIndicator: {
        sortColumn: '',
        sortDirection: '',
    },
    pageTotal: 1,
    pageCount: 1,
    data: [],
    loader: false
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IReportListProps = IMergeCrudComponentProps<IReportListState, IReportListActionProps, IOwnProps>;

export class ScheduledEvents extends React.Component<IReportListProps, IComponentState> {

    protected dataForSwitchClients: any = [];
    protected data: any;
    protected dataEPDRequestDirectEntryFI: any = [];
    protected selectedOperation: string = '';
    readonly PAGE_SIZE = 100;
    protected clientUser: any = false;

    static defaultProps: IComponentProps = {
        canView: false    // SecurityBits.FN_VIEW_REPORTS
    };

    constructor(props: IReportListProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.handleSort = this.handleSort.bind(this);
    }

    public componentDidMount() {
        if (this.props.canView) {
            this.loadClientsFromServer();
            this.props.action.configData.getConfigData({
                cookies: [
                    { name: "Eligibility" },
                    { name: "Reconciliation" },
                    { name: "ClientMobileEnabled" },
                    { name: "SubmitRemitFileEnabled" },
                    { name: "ViewX12Enabled" },
                    { name: "BI" },
                    { name: "UDEEnabled" }],
                config: [
                    { name: "isClientUser" }]
            });
        }
    }

    delay = async (ms: number) => new Promise(res => setTimeout(res, ms));

    loadClientsFromServer() {
        var clientUrl = URLs.api + '/api/data/securityinfo/securityInfoUserClientList';
        var xhr = new XMLHttpRequest();
        this.setState({ loader: true })
        xhr.onreadystatechange = () => {
            if (xhr.readyState == xhr.DONE) {
                if (xhr.status == 200) {
                    var responseData = xhr.responseText;
                    this.dataForSwitchClients = JSON.parse(responseData);
                    this.loadEPDScheduleData()
                    this.setState({ loader: false })
                } else {
                    console.warn(xhr.status + " response handling " + clientUrl);
                    this.setState({ loader: false })
                }
            }
        };
        xhr.open('get', clientUrl, false);
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send();
    }

    loadEPDScheduleData = async () => {
        var url = URLs.api + '/api/crud/43';
        this.setState({ loader: false })
        await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        })
            .then(async response => {
                if (response.status === 200) {
                    const data: any = await response.json();
                    let responseData: any = []
                    this.setState({ loader: false })
                    if (data?.EPDScheduleMaintenanceInfo?.EPDSchedule?.EPDRequests !== '') {
                        if (Array.isArray(data?.EPDScheduleMaintenanceInfo?.EPDSchedule?.EPDRequests?.EPDRequest)) {
                            data?.EPDScheduleMaintenanceInfo?.EPDSchedule?.EPDRequests?.EPDRequest.map((itemEvent: any) => {
                                const json = {
                                    Event: 'EPDSchedule',
                                    Name: 'EPDRequest',
                                    CID: '',
                                    Start: itemEvent['@Time'],
                                    End: '',
                                    Interval: '',
                                    Scheduled: (itemEvent['@Sun'] == '1' ? 'Su' : '') + (itemEvent['@Mon'] == '1' ? 'Mo' : '') + (itemEvent['@Tue'] == '1' ? 'Tu' : '') + (itemEvent['@Wed'] == '1' ? 'We' : '') + (itemEvent['@Thu'] == '1' ? 'Th' : '') + (itemEvent['@Fri'] == '1' ? 'Fr' : '') + (itemEvent['@Sat'] == '1' ? 'Sa' : ''),
                                    LagDays: '',
                                    Enabled: itemEvent['@Enabled']
                                }
                                responseData.push(json)
                            })
                        }
                        else if (data?.EPDScheduleMaintenanceInfo?.EPDSchedule?.EPDRequests?.EPDRequest !== '') {
                            const itemEvent = data?.EPDScheduleMaintenanceInfo?.EPDSchedule?.EPDRequests?.EPDRequest
                            const json = {
                                Event: 'EPDSchedule',
                                Name: 'EPDRequest',
                                CID: '',
                                Start: itemEvent['@Time'],
                                End: '',
                                Interval: '',
                                Scheduled: (itemEvent['@Sun'] == '1' ? 'Su' : '') + (itemEvent['@Mon'] == '1' ? 'Mo' : '') + (itemEvent['@Tue'] == '1' ? 'Tu' : '') + (itemEvent['@Wed'] == '1' ? 'We' : '') + (itemEvent['@Thu'] == '1' ? 'Th' : '') + (itemEvent['@Fri'] == '1' ? 'Fr' : '') + (itemEvent['@Sat'] == '1' ? 'Sa' : ''),
                                LagDays: '',
                                Enabled: itemEvent['@Enabled']
                            }
                            responseData.push(json)
                        }
                    }

                    if (data?.EPDScheduleMaintenanceInfo?.EPDSchedule?.EPDInstalls !== '') {
                        if (Array.isArray(data?.EPDScheduleMaintenanceInfo?.EPDSchedule?.EPDInstalls?.EPDInstall)) {
                            data?.EPDScheduleMaintenanceInfo?.EPDSchedule?.EPDInstalls?.EPDInstall.map((itemEvent: any) => {
                                const json = {
                                    Event: 'EPDSchedule',
                                    Name: 'EPDInstall',
                                    CID: '',
                                    Start: itemEvent['@Time'],
                                    End: '',
                                    Interval: '',
                                    Scheduled: (itemEvent['@Sun'] == '1' ? 'Su' : '') + (itemEvent['@Mon'] == '1' ? 'Mo' : '') + (itemEvent['@Tue'] == '1' ? 'Tu' : '') + (itemEvent['@Wed'] == '1' ? 'We' : '') + (itemEvent['@Thu'] == '1' ? 'Th' : '') + (itemEvent['@Fri'] == '1' ? 'Fr' : '') + (itemEvent['@Sat'] == '1' ? 'Sa' : ''),
                                    LagDays: '',
                                    Enabled: itemEvent['@Enabled']
                                }
                                responseData.push(json)
                            })
                        }
                        else if (data?.EPDScheduleMaintenanceInfo?.EPDSchedule?.EPDInstalls?.EPDInstall !== '') {
                            const itemEvent = data?.EPDScheduleMaintenanceInfo?.EPDSchedule?.EPDInstalls?.EPDInstall
                            const json = {
                                Event: 'EPDSchedule',
                                Name: 'EPDInstall',
                                CID: '',
                                Start: itemEvent['@Time'],
                                End: '',
                                Interval: '',
                                Scheduled: (itemEvent['@Sun'] == '1' ? 'Su' : '') + (itemEvent['@Mon'] == '1' ? 'Mo' : '') + (itemEvent['@Tue'] == '1' ? 'Tu' : '') + (itemEvent['@Wed'] == '1' ? 'We' : '') + (itemEvent['@Thu'] == '1' ? 'Th' : '') + (itemEvent['@Fri'] == '1' ? 'Fr' : '') + (itemEvent['@Sat'] == '1' ? 'Sa' : ''),
                                LagDays: '',
                                Enabled: itemEvent['@Enabled']
                            }
                            responseData.push(json)
                        }
                    }
                    if (Array.isArray(responseData)) {
                        this.dataEPDRequestDirectEntryFI = responseData
                    }
                    this.loadDirectEntrydata()
                }
            })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
                LogMessage("error", `promise rejected: URL:${url} with Error: ${error}`);
            });
    };

    loadDirectEntrydata = async () => {
        var url = URLs.api + '/api/crud/49';
        this.setState({ loader: true })
        await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        })
            .then(async response => {
                if (response.status === 200) {
                    const data: any = await response.json();
                    this.setState({ loader: false })
                    if (data?.DDEFIMaintenanceInfo?.FIs?.FI !== '') {
                        if (Array.isArray(data?.DDEFIMaintenanceInfo?.FIs?.FI)) {
                            data?.DDEFIMaintenanceInfo?.FIs?.FI.map((item: any) => {
                                if (Array.isArray(item?.Events.Event)) {
                                    item?.Events.Event.map((itemEvent: any) => {
                                        const json = {
                                            Event: 'DirectEntryFI',
                                            Name: item['@Name'],
                                            CID: '',
                                            Start: itemEvent['@FromTime'],
                                            End: itemEvent['@ThruTime'],
                                            Interval: '',
                                            Scheduled: (itemEvent['@Sun'] == 'Y' ? 'Su' : '') + (itemEvent['@Mon'] == 'Y' ? 'Mo' : '') + (itemEvent['@Tue'] == 'Y' ? 'Tu' : '') + (itemEvent['@Wed'] == 'Y' ? 'We' : '') + (itemEvent['@Thu'] == 'Y' ? 'Th' : '') + (itemEvent['@Fri'] == 'Y' ? 'Fr' : '') + (itemEvent['@Sat'] == 'Y' ? 'Sa' : ''),
                                            LagDays: '',
                                            Enabled: item['@Enabled']
                                        }
                                        this.dataEPDRequestDirectEntryFI.push(json)
                                    })
                                }
                                else if (item?.Events !== '') {
                                    const itemEvent = item?.Events.Event
                                    const json = {
                                        Event: 'DirectEntryFI',
                                        Name: item['@Name'],
                                        CID: '',
                                        Start: itemEvent['@FromTime'],
                                        End: itemEvent['@ThruTime'],
                                        Interval: '',
                                        Scheduled: (itemEvent['@Sun'] == 'Y' ? 'Su' : '') + (itemEvent['@Mon'] == 'Y' ? 'Mo' : '') + (itemEvent['@Tue'] == 'Y' ? 'Tu' : '') + (itemEvent['@Wed'] == 'Y' ? 'We' : '') + (itemEvent['@Thu'] == 'Y' ? 'Th' : '') + (itemEvent['@Fri'] == 'Y' ? 'Fr' : '') + (itemEvent['@Sat'] == 'Y' ? 'Sa' : ''),
                                        LagDays: '',
                                        Enabled: item['@Enabled']
                                    }
                                    this.dataEPDRequestDirectEntryFI.push(json)
                                }
                            })
                        }
                        else {
                            let item = data?.DDEFIMaintenanceInfo?.FIs?.FI
                            if (Array.isArray(item?.Events.Event)) {
                                item?.Events.Event.map((itemEvent: any) => {
                                    const json = {
                                        Event: 'DirectEntryFI',
                                        Name: item['@Name'],
                                        CID: '',
                                        Start: itemEvent['@FromTime'],
                                        End: itemEvent['@ThruTime'],
                                        Interval: '',
                                        Scheduled: (itemEvent['@Sun'] == 'Y' ? 'Su' : '') + (itemEvent['@Mon'] == 'Y' ? 'Mo' : '') + (itemEvent['@Tue'] == 'Y' ? 'Tu' : '') + (itemEvent['@Wed'] == 'Y' ? 'We' : '') + (itemEvent['@Thu'] == 'Y' ? 'Th' : '') + (itemEvent['@Fri'] == 'Y' ? 'Fr' : '') + (itemEvent['@Sat'] == 'Y' ? 'Sa' : ''),
                                        LagDays: '',
                                        Enabled: item['@Enabled']
                                    }
                                    this.dataEPDRequestDirectEntryFI.push(json)
                                })
                            }
                            else if (item?.Events !== '') {
                                const itemEvent = item?.Events.Event
                                const json = {
                                    Event: 'DirectEntryFI',
                                    Name: item['@Name'],
                                    CID: '',
                                    Start: itemEvent['@FromTime'],
                                    End: itemEvent['@ThruTime'],
                                    Interval: '',
                                    Scheduled: (itemEvent['@Sun'] == 'Y' ? 'Su' : '') + (itemEvent['@Mon'] == 'Y' ? 'Mo' : '') + (itemEvent['@Tue'] == 'Y' ? 'Tu' : '') + (itemEvent['@Wed'] == 'Y' ? 'We' : '') + (itemEvent['@Thu'] == 'Y' ? 'Th' : '') + (itemEvent['@Fri'] == 'Y' ? 'Fr' : '') + (itemEvent['@Sat'] == 'Y' ? 'Sa' : ''),
                                    LagDays: '',
                                    Enabled: item['@Enabled']
                                }
                                this.dataEPDRequestDirectEntryFI.push(json)
                            }
                        }
                    }

                    this.clientUser = this.getCreateReportCookieValue('isClientUser').toLowerCase() === "false";
                    let CID = this.dataForSwitchClients.userClients.filter((itemData: any) => itemData.name === sessionStorage.getItem("Client"))
                    if (CID.length > 0) {
                        this.loadData(CID[0].clientId);
                        this.setState({ selectedOperation: CID[0].id })
                    }
                    else {
                        this.loadData(0);
                        this.setState({ selectedOperation: '0' })
                    }
                }
            })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
                LogMessage("error", `promise rejected: URL:${url} with Error: ${error}`);
            });
    };

    loadData = async (id: any) => {
        var url = URLs.api + '/api/data/LoadScheduledEventsList?clientId=' + id;
        this.setState({ loader: true })
        await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        })
            .then(async response => {
                if (response.status === 200) {
                    const data: any = await response.json();
                    this.setState({ loader: false })
                    this.data = JSON.parse(data);
                    this.loadAutoPrintSetting()
                }
            })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
                LogMessage("error", `promise rejected: URL:${url} with Error: ${error}`);
            });
    };

    loadAutoSubmit = (responseData: any) => {
        const data: any = this.data.ScheduledEvents

        if (data?.AutoSubmitMaintenanceInfo?.AutoSubmits !== "") {
            if (Array.isArray(data?.AutoSubmitMaintenanceInfo?.AutoSubmits?.AutoSubmit)) {
                data?.AutoSubmitMaintenanceInfo?.AutoSubmits?.AutoSubmit.map((item: any) => {
                    let CID = this.dataForSwitchClients.userClients.filter((itemData: any) => itemData.clientId === parseInt(item['@ClientId']))

                    if (Array.isArray(item?.Events.Event)) {
                        item?.Events.Event.map((itemEvent: any) => {
                            const json = {
                                Event: 'AutoSubmit',
                                Name: item['@Name'],
                                CID: CID.length > 0 ? CID[0].id : '',
                                Start: itemEvent['@FromTime'],
                                End: itemEvent['@ThruTime'],
                                Interval: itemEvent['@CheckInterval'],
                                Scheduled: (itemEvent['@Sun'] == 'Y' ? 'Su' : '') + (itemEvent['@Mon'] == 'Y' ? 'Mo' : '') + (itemEvent['@Tue'] == 'Y' ? 'Tu' : '') + (itemEvent['@Wed'] == 'Y' ? 'We' : '') + (itemEvent['@Thu'] == 'Y' ? 'Th' : '') + (itemEvent['@Fri'] == 'Y' ? 'Fr' : '') + (itemEvent['@Sat'] == 'Y' ? 'Sa' : ''),
                                LagDays: '',
                                Enabled: item['@Enabled']
                            }
                            responseData.push(json)
                        })
                    }
                    else if (item?.Events !== '') {
                        const itemEvent = item?.Events.Event
                        const json = {

                            Event: 'AutoSubmit',
                            Name: item['@Name'],
                            CID: CID.length > 0 ? CID[0].id : '',
                            Start: itemEvent['@FromTime'],
                            End: itemEvent['@ThruTime'],
                            Interval: itemEvent['@CheckInterval'],
                            Scheduled: (itemEvent['@Sun'] == 'Y' ? 'Su' : '') + (itemEvent['@Mon'] == 'Y' ? 'Mo' : '') + (itemEvent['@Tue'] == 'Y' ? 'Tu' : '') + (itemEvent['@Wed'] == 'Y' ? 'We' : '') + (itemEvent['@Thu'] == 'Y' ? 'Th' : '') + (itemEvent['@Fri'] == 'Y' ? 'Fr' : '') + (itemEvent['@Sat'] == 'Y' ? 'Sa' : ''),
                            LagDays: '',
                            Enabled: item['@Enabled']
                        }
                        responseData.push(json)
                    }
                })
            }
            else {
                let item = data?.AutoSubmitMaintenanceInfo?.AutoSubmits?.AutoSubmit
                let CID = this.dataForSwitchClients.userClients.filter((itemData: any) => itemData.clientId === parseInt(item['@ClientId']))

                if (Array.isArray(item?.Events.Event)) {
                    item?.Events.Event.map((itemEvent: any) => {
                        const json = {

                            Event: 'AutoSubmit',
                            Name: item['@Name'],
                            CID: CID.length > 0 ? CID[0].id : '',
                            Start: itemEvent['@FromTime'],
                            End: itemEvent['@ThruTime'],
                            Interval: itemEvent['@CheckInterval'],
                            Scheduled: (itemEvent['@Sun'] == 'Y' ? 'Su' : '') + (itemEvent['@Mon'] == 'Y' ? 'Mo' : '') + (itemEvent['@Tue'] == 'Y' ? 'Tu' : '') + (itemEvent['@Wed'] == 'Y' ? 'We' : '') + (itemEvent['@Thu'] == 'Y' ? 'Th' : '') + (itemEvent['@Fri'] == 'Y' ? 'Fr' : '') + (itemEvent['@Sat'] == 'Y' ? 'Sa' : ''),
                            LagDays: '',
                            Enabled: item['@Enabled']
                        }
                        responseData.push(json)
                    })
                }
                else if (item?.Events !== '') {
                    const itemEvent = item?.Events.Event
                    const json = {

                        Event: 'AutoSubmit',
                        Name: <b>{item['@Name']}</b>,
                        CID: CID.length > 0 ? CID[0].id : '',
                        Start: itemEvent['@FromTime'],
                        End: itemEvent['@ThruTime'],
                        Interval: itemEvent['@CheckInterval'],
                        Scheduled: (itemEvent['@Sun'] == 'Y' ? 'Su' : '') + (itemEvent['@Mon'] == 'Y' ? 'Mo' : '') + (itemEvent['@Tue'] == 'Y' ? 'Tu' : '') + (itemEvent['@Wed'] == 'Y' ? 'We' : '') + (itemEvent['@Thu'] == 'Y' ? 'Th' : '') + (itemEvent['@Fri'] == 'Y' ? 'Fr' : '') + (itemEvent['@Sat'] == 'Y' ? 'Sa' : ''),
                        LagDays: '',
                        Enabled: item['@Enabled']
                    }
                    responseData.push(json)
                }
            }
        }

        const dataEPDRequestDirectEntryFIFilter = this.dataEPDRequestDirectEntryFI.filter((item: any) => item['Event'].includes('EPD') || item['Event'].includes('FI'))

        let reportFormAPIDataCurd = dataEPDRequestDirectEntryFIFilter?.length > 0 ? [...responseData, ...dataEPDRequestDirectEntryFIFilter] : responseData
        reportFormAPIDataCurd.map((item: any) => {
            item.EventLabel = <span style={item['Enabled'] === "Y" || item['Enabled'] === "1" ? {} : { color: '#9ba1a9' }}>
                {item['Event']}
            </span>
            item.NameLabel = <span style={item['Enabled'] === "Y" || item['Enabled'] === "1" ? {} : { color: '#9ba1a9' }}>
                {item['Name']}
            </span>

            if (this.clientUser) {
                item.CIDLabel = <span style={item['Enabled'] === "Y" || item['Enabled'] === "1" ? {} : { color: '#9ba1a9' }}>
                    {item['CID']}
                </span>
            }
            else {
                item.CIDLabel = "";
            }
            item.StartLabel = <span style={item['Enabled'] === "Y" || item['Enabled'] === "1" ? {} : { color: '#9ba1a9' }}>
                {item['Start'] !== undefined ? item['Start'].substr(0, 5) : ''}
            </span>
            item.EndLabel = <span style={item['Enabled'] === "Y" || item['Enabled'] === "1" ? {} : { color: '#9ba1a9' }}>
                {item['End'] !== undefined ? item['End'].substr(0, 5) : ''}
            </span>
            item.IntervalLabel = <span style={item['Enabled'] === "Y" || item['Enabled'] === "1" ? {} : { color: '#9ba1a9' }}>
                {item['Interval']}
            </span>
            item.ScheduledLabel = <span style={item['Enabled'] === "Y" || item['Enabled'] === "1" ? {} : { color: '#9ba1a9' }}>
                {item['Scheduled']}
            </span>
            item.LagDaysLabel = <span style={item['Enabled'] === "Y" || item['Enabled'] === "1" ? {} : { color: '#9ba1a9' }}>
                {item['LagDays']}
            </span>
        })

        let reportFormAPIData = this.state.selectedFilterType === '- Event Type -' ? reportFormAPIDataCurd : reportFormAPIDataCurd.filter((item: any) =>
            item['Event'] === this.state.selectedFilterType
        )

        if (reportFormAPIData.length === 0 && this.state.selectedFilterType === '- Event Type -')
            reportFormAPIData = dataEPDRequestDirectEntryFIFilter?.length > 0 ? [...responseData, ...dataEPDRequestDirectEntryFIFilter] : responseData
        this.setState({
            data: reportFormAPIDataCurd,
            reportFormAPIData: reportFormAPIData.slice(0, this.PAGE_SIZE),
            pageCount: 1,
            pageTotal: Math.ceil(reportFormAPIData.length / this.PAGE_SIZE) == 0 ? 1 : Math.ceil(reportFormAPIData.length / this.PAGE_SIZE)
        });
    };

    loadAutoProcess = (responseData: any) => {
        const data: any = this.data.ScheduledEvents

        if (data?.AutoProcessMaintenanceInfo?.AutoProcesses !== "") {
            if (Array.isArray(data?.AutoProcessMaintenanceInfo?.AutoProcesses?.AutoProcess)) {
                data?.AutoProcessMaintenanceInfo?.AutoProcesses?.AutoProcess.map((item: any) => {
                    let CID = this.dataForSwitchClients.userClients.filter((itemData: any) => itemData.clientId === parseInt(item['@ClientId']))

                    if (Array.isArray(item?.Events.Event)) {
                        item?.Events.Event.map((itemEvent: any) => {
                            const json = {
                                Event: 'AutoProcess',
                                Name: item['@Name'],
                                CID: CID.length > 0 ? CID[0].id : '',
                                Start: itemEvent['@Time'],
                                End: '',
                                Interval: '',
                                Scheduled: itemEvent['@Quarterly'] == 'Y' ? 'Quarterly' : itemEvent['@Monthly'] == 'Y' ? 'Monthly' : ((itemEvent['@Sun'] == 'Y' ? 'Su' : '') + (itemEvent['@Mon'] == 'Y' ? 'Mo' : '') + (itemEvent['@Tue'] == 'Y' ? 'Tu' : '') + (itemEvent['@Wed'] == 'Y' ? 'We' : '') + (itemEvent['@Thu'] == 'Y' ? 'Th' : '') + (itemEvent['@Fri'] == 'Y' ? 'Fr' : '') + (itemEvent['@Sat'] == 'Y' ? 'Sa' : '')),
                                LagDays: itemEvent['@LagDays'] === '0' ? '' : itemEvent['@LagDays'],
                                Enabled: item['@Enabled']
                            }
                            responseData.push(json)
                        })
                    }
                    else if (item?.Events !== '') {
                        const itemEvent = item?.Events.Event
                        const json = {
                            Event: 'AutoProcess',
                            Name: item['@Name'],
                            CID: CID.length > 0 ? CID[0].id : '',
                            Start: itemEvent['@Time'],
                            End: '',
                            Interval: '',
                            Scheduled: itemEvent['@Quarterly'] == 'Y' ? 'Quarterly' : itemEvent['@Monthly'] == 'Y' ? 'Monthly' : ((itemEvent['@Sun'] == 'Y' ? 'Su' : '') + (itemEvent['@Mon'] == 'Y' ? 'Mo' : '') + (itemEvent['@Tue'] == 'Y' ? 'Tu' : '') + (itemEvent['@Wed'] == 'Y' ? 'We' : '') + (itemEvent['@Thu'] == 'Y' ? 'Th' : '') + (itemEvent['@Fri'] == 'Y' ? 'Fr' : '') + (itemEvent['@Sat'] == 'Y' ? 'Sa' : '')),
                            LagDays: itemEvent['@LagDays'] === '0' ? '' : itemEvent['@LagDays'],
                            Enabled: item['@Enabled']
                        }
                        responseData.push(json)
                    }
                })
            }
            else {
                let item = data?.AutoProcessMaintenanceInfo?.AutoProcesses?.AutoProcess
                let CID = this.dataForSwitchClients.userClients.filter((itemData: any) => itemData.clientId === parseInt(item['@ClientId']))

                if (Array.isArray(item?.Events.Event)) {
                    item?.Events.Event.map((itemEvent: any) => {
                        const json = {
                            Event: 'AutoProcess',
                            Name: item['@Name'],
                            CID: CID.length > 0 ? CID[0].id : '',
                            Start: itemEvent['@Time'],
                            End: '',
                            Interval: '',
                            Scheduled: itemEvent['@Quarterly'] == 'Y' ? 'Quarterly' : itemEvent['@Monthly'] == 'Y' ? 'Monthly' : ((itemEvent['@Sun'] == 'Y' ? 'Su' : '') + (itemEvent['@Mon'] == 'Y' ? 'Mo' : '') + (itemEvent['@Tue'] == 'Y' ? 'Tu' : '') + (itemEvent['@Wed'] == 'Y' ? 'We' : '') + (itemEvent['@Thu'] == 'Y' ? 'Th' : '') + (itemEvent['@Fri'] == 'Y' ? 'Fr' : '') + (itemEvent['@Sat'] == 'Y' ? 'Sa' : '')),
                            LagDays: itemEvent['@LagDays'] === '0' ? '' : itemEvent['@LagDays'],
                            Enabled: item['@Enabled']
                        }
                        responseData.push(json)
                    })
                }
                else if (item?.Events !== '') {
                    const itemEvent = item?.Events.Event
                    const json = {
                        Event: 'AutoProcess',
                        Name: item['@Name'],
                        CID: CID.length > 0 ? CID[0].id : '',
                        Start: itemEvent['@Time'],
                        End: '',
                        Interval: '',
                        Scheduled: itemEvent['@Quarterly'] == 'Y' ? 'Quarterly' : itemEvent['@Monthly'] == 'Y' ? 'Monthly' : ((itemEvent['@Sun'] == 'Y' ? 'Su' : '') + (itemEvent['@Mon'] == 'Y' ? 'Mo' : '') + (itemEvent['@Tue'] == 'Y' ? 'Tu' : '') + (itemEvent['@Wed'] == 'Y' ? 'We' : '') + (itemEvent['@Thu'] == 'Y' ? 'Th' : '') + (itemEvent['@Fri'] == 'Y' ? 'Fr' : '') + (itemEvent['@Sat'] == 'Y' ? 'Sa' : '')),
                        LagDays: itemEvent['@LagDays'] === '0' ? '' : itemEvent['@LagDays'],
                        Enabled: item['@Enabled']
                    }
                    responseData.push(json)
                }
            }
        }

        this.loadAutoSubmit(responseData)
    };

    loadAutoPrintSetting = () => {
        const data: any = this.data.ScheduledEvents
        let responseData: any = [];
        if (Array.isArray(data?.AutoPrintMaintenanceInfo?.AutoPrintSettings?.AutoPrintSetting)) {
            data?.AutoPrintMaintenanceInfo?.AutoPrintSettings?.AutoPrintSetting.map((item: any) => {
                let CID = this.dataForSwitchClients.userClients.filter((itemData: any) => itemData.clientId === parseInt(item['@ClientId']))

                if (Array.isArray(item?.Events.Event)) {
                    item?.Events.Event.map((itemEvent: any) => {
                        const json = {
                            Event: 'AutoPrintSetting',
                            Name: item['@Name'] === undefined ? 'Event' : item['@Name'],
                            CID: CID.length > 0 ? CID[0].id : '',
                            Start: itemEvent['@FromTime'],
                            End: itemEvent['@ThruTime'],
                            Interval: itemEvent['@CheckInterval'],
                            Scheduled: (itemEvent['@Sun'] == 'Y' ? 'Su' : '') + (itemEvent['@Mon'] == 'Y' ? 'Mo' : '') + (itemEvent['@Tue'] == 'Y' ? 'Tu' : '') + (itemEvent['@Wed'] == 'Y' ? 'We' : '') + (itemEvent['@Thu'] == 'Y' ? 'Th' : '') + (itemEvent['@Fri'] == 'Y' ? 'Fr' : '') + (itemEvent['@Sat'] == 'Y' ? 'Sa' : ''),
                            LagDays: itemEvent['@LagDays'] === undefined ? '' : itemEvent['@LagDays'],
                            Enabled: item['@Enabled']
                        }
                        responseData.push(json)
                    })
                }
                else if (item?.Events !== '') {
                    const itemEvent = item?.Events.Event
                    const json = {
                        Event: 'AutoPrintSetting',
                        Name: item['@Name'] === undefined ? 'Event' : item['@Name'],
                        CID: CID.length > 0 ? CID[0].id : '',
                        Start: itemEvent['@FromTime'],
                        End: itemEvent['@ThruTime'],
                        Interval: itemEvent['@CheckInterval'],
                        Scheduled: (itemEvent['@Sun'] == 'Y' ? 'Su' : '') + (itemEvent['@Mon'] == 'Y' ? 'Mo' : '') + (itemEvent['@Tue'] == 'Y' ? 'Tu' : '') + (itemEvent['@Wed'] == 'Y' ? 'We' : '') + (itemEvent['@Thu'] == 'Y' ? 'Th' : '') + (itemEvent['@Fri'] == 'Y' ? 'Fr' : '') + (itemEvent['@Sat'] == 'Y' ? 'Sa' : ''),
                        LagDays: itemEvent['@LagDays'] === undefined ? '' : itemEvent['@LagDays'],
                        Enabled: item['@Enabled']
                    }
                    responseData.push(json)
                }
            })
        }
        else if (data?.AutoPrintMaintenanceInfo?.AutoPrintSettings?.AutoPrintSetting !== '') {
            let item = data?.AutoPrintMaintenanceInfo?.AutoPrintSettings?.AutoPrintSetting
            let CID = this.dataForSwitchClients.userClients.filter((itemData: any) => itemData.clientId === parseInt(item['@ClientId']))

            if (Array.isArray(item?.Events.Event)) {
                item?.Events.Event.map((itemEvent: any) => {
                    const json = {
                        Event: 'AutoPrintSetting',
                        Name: item['@Name'] === undefined ? 'Event' : item['@Name'],
                        CID: CID.length > 0 ? CID[0].id : '',
                        Start: itemEvent['@FromTime'],
                        End: itemEvent['@ThruTime'],
                        Interval: itemEvent['@CheckInterval'],
                        Scheduled: (itemEvent['@Sun'] == 'Y' ? 'Su' : '') + (itemEvent['@Mon'] == 'Y' ? 'Mo' : '') + (itemEvent['@Tue'] == 'Y' ? 'Tu' : '') + (itemEvent['@Wed'] == 'Y' ? 'We' : '') + (itemEvent['@Thu'] == 'Y' ? 'Th' : '') + (itemEvent['@Fri'] == 'Y' ? 'Fr' : '') + (itemEvent['@Sat'] == 'Y' ? 'Sa' : ''),
                        LagDays: itemEvent['@LagDays'] === undefined ? '' : itemEvent['@LagDays'],
                        Enabled: item['@Enabled']
                    }
                    responseData.push(json)
                })
            }
            else if (item?.Events !== '') {
                const itemEvent = item?.Events.Event
                const json = {
                    Event: 'AutoPrintSetting',
                    Name: item['@Name'] === undefined ? 'Event' : item['@Name'],
                    CID: CID.length > 0 ? CID[0].id : '',
                    Start: itemEvent['@FromTime'],
                    End: itemEvent['@ThruTime'],
                    Interval: itemEvent['@CheckInterval'],
                    Scheduled: (itemEvent['@Sun'] == 'Y' ? 'Su' : '') + (itemEvent['@Mon'] == 'Y' ? 'Mo' : '') + (itemEvent['@Tue'] == 'Y' ? 'Tu' : '') + (itemEvent['@Wed'] == 'Y' ? 'We' : '') + (itemEvent['@Thu'] == 'Y' ? 'Th' : '') + (itemEvent['@Fri'] == 'Y' ? 'Fr' : '') + (itemEvent['@Sat'] == 'Y' ? 'Sa' : ''),
                    LagDays: itemEvent['@LagDays'] === undefined ? '' : itemEvent['@LagDays'],
                    Enabled: item['@Enabled']
                }
                responseData.push(json)
            }
        }
        this.loadAutoProcess(responseData)
    };

    public onOKScheduledEvents(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push("/LandingPage");
    }

    getReportListGridColumnHeaders() {
        if (this.clientUser)
            return new Set([
                {
                    dataName: "Event",
                    text: 'Event',
                    sortable: true,
                    cellType: "text",
                    isSorted: 1,
                },
                {
                    dataName: "Name",
                    text: "Name",
                    sortable: true,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "CID",
                    text: "CID",
                    sortable: true,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "Start",
                    text: "Start",
                    sortable: true,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "End",
                    text: "End",
                    sortable: false,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "Interval",
                    text: "Interval",
                    sortable: false,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "Scheduled",
                    text: "Scheduled",
                    sortable: false,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "LagDays",
                    text: "LagDays",
                    sortable: false,
                    cellType: "text",
                    isSorted: 0,
                }
            ]);
        else
            return new Set([
                {
                    dataName: "EventLabel",
                    text: 'Event',
                    sortable: true,
                    cellType: "text",
                    isSorted: 1,
                },
                {
                    dataName: "NameLabel",
                    text: "Name",
                    sortable: true,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "StartLabel",
                    text: "Start",
                    sortable: true,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "EndLabel",
                    text: "End",
                    sortable: false,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "IntervalLabel",
                    text: "Interval",
                    sortable: false,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "ScheduledLabel",
                    text: "Scheduled",
                    sortable: false,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "LagDaysLabel",
                    text: "LagDays",
                    sortable: false,
                    cellType: "text",
                    isSorted: 0,
                }
            ]);
    }

    public previousPage(e: React.ChangeEvent<HTMLButtonElement>) {
        const {
            pageTotal,
            pageCount
        } = this.state;
        const reportFormAPIData = this.state.selectedFilterType === '- Event Type -' ? this.state.data : this.state.data.filter((item: any) =>
            item['Event'] === this.state.selectedFilterType
        )
        if (pageTotal >= pageCount) {
            let page = pageCount - 1
            this.setState({
                pageCount: pageCount - 1,

                reportFormAPIData: reportFormAPIData.slice(page * this.PAGE_SIZE - this.PAGE_SIZE, page * this.PAGE_SIZE)

            });
            e.target.value = pageCount.toString();
        }
    }

    public nextPage(e: React.ChangeEvent<HTMLButtonElement>) {
        const {
            pageCount,
            pageTotal
        } = this.state;
        const reportFormAPIData = this.state.selectedFilterType === '- Event Type -' ? this.state.data : this.state.data.filter((item: any) =>
            item['Event'] === this.state.selectedFilterType
        )

        if (pageCount < pageTotal) {
            this.setState({
                pageCount: pageCount + 1,
                reportFormAPIData: reportFormAPIData.slice((pageCount) * this.PAGE_SIZE, (pageCount) * this.PAGE_SIZE + this.PAGE_SIZE)
            });
            e.target.value = pageCount.toString();
        }
    }

    public handleSort(sortIndicator: ISortIndicator) {
        const reportFormAPIData = this.state.selectedFilterType === '- Event Type -' ? this.state.data : this.state.data.filter((item: any) =>
            item['Event'] === this.state.selectedFilterType
        )
        let sortedRecords: any = [];
        switch (sortIndicator.sortColumn) {
            case 'Created': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    reportFormAPIData.sort((a: any, b: any) => b.UTC - a.UTC) :
                    reportFormAPIData.sort((a: any, b: any) => a.UTC - b.UTC);
            }
                break;
            case 'Event': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    reportFormAPIData.sort((a: any, b: any) => (a.Event).toString().localeCompare((b.Event).toString())) :
                    reportFormAPIData.sort((a: any, b: any) => (b.Event).toString().localeCompare((a.Event).toString()));
            }
                break;
            case 'Name': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    reportFormAPIData.sort((a: any, b: any) => (a.Name).toString().localeCompare((b.Name).toString())) :
                    reportFormAPIData.sort((a: any, b: any) => (b.Name).toString().localeCompare((a.Name).toString()));
            }
                break;
            case 'Start': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    reportFormAPIData.sort((a: any, b: any) => (a.Start).toString().localeCompare((b.Start).toString())) :
                    reportFormAPIData.sort((a: any, b: any) => (b.Start).toString().localeCompare((a.Start).toString()));
            }
                break;
            case 'End': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    reportFormAPIData.sort((a: any, b: any) => (a.End).toString().localeCompare((b.End).toString())) :
                    reportFormAPIData.sort((a: any, b: any) => (b.End).toString().localeCompare((a.End).toString()));
            }
                break;
            case 'LagDays': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    reportFormAPIData.sort((a: any, b: any) => (a.LagDays).toString() - ((b.LagDays).toString())) :
                    reportFormAPIData.sort((a: any, b: any) => (b.LagDays).toString() - ((a.LagDays).toString()));
            }
                break;
        }
        let page = this.state.pageCount
        if (page === 0) {
            this.setState({ sortIndicator, reportFormAPIData: sortedRecords.slice(0, this.PAGE_SIZE) });
        }
        else
            this.setState({ sortIndicator, reportFormAPIData: sortedRecords.slice(page * this.PAGE_SIZE - this.PAGE_SIZE, page * this.PAGE_SIZE) });
    }

    public getCreateReportCookieValue(flag: string): string {
        const configObj = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === flag) ||
            this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === flag);
        return (configObj && configObj.value ? configObj.value : '');
    }


    public render() {
        var buttons = <DialogButtonOK onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOKScheduledEvents(e)} />;

        interface nesas {
            Event: string;
            Name: string;
            CID: string;
            Start: string;
            End: string;
            Interval: string;
            Scheduled: string;
            LagDays: string;
        }
        const newRecords: nesas[] = [];
        var filteredRecords = this.state.reportFormAPIData.map((data: nesas) => {
            const json = {
                Event: data.Event,
                Name: data.Name,
                CID: data.CID,
                Start: data.Start,
                End: data.End,
                Interval: data.Interval,
                Scheduled: data.Scheduled,
                LagDays: data.LagDays

            }
            newRecords.push(json)
        })
        // Report Type Filters
        const reportFilterTypeData: { reportFilterType: string }[] = [
            { reportFilterType: '- Event Type -' },
            { reportFilterType: 'AutoPrintSetting' },
            { reportFilterType: 'AutoProcess' },
            { reportFilterType: 'AutoSubmit' },
            { reportFilterType: 'EPDSchedule' },
            { reportFilterType: 'DirectEntryFI' }
        ]

        const options = reportFilterTypeData.map(flt => <option
            value={flt.reportFilterType}
            selected={this.state.selectedFilterType === flt.reportFilterType}>
            {flt.reportFilterType}
        </option>);

        const reportFilters =
            <select onChange={filter => {

                const value = filter.target.value

                const reportFormAPIData = filter.target.value === '- Event Type -' ? this.state.data : this.state.data.filter((item: any) =>
                    item['Event'] === value
                )
                this.setState(
                    {
                        selectedFilterType: filter.target.value,

                        reportFormAPIData: reportFormAPIData.slice(0, this.PAGE_SIZE),
                        pageCount: 1,
                        pageTotal: Math.ceil(reportFormAPIData.length / this.PAGE_SIZE) == 0 ? 1 : Math.ceil(reportFormAPIData.length / this.PAGE_SIZE),
                    })
            }}>
                {options}
            </select>;



        const reportOperations =
            <select value={this.state.selectedOperation} onChange={
                operation => {
                    this.setState({
                        data: [],
                        reportFormAPIData: [],
                        pageCount: 1,
                        pageTotal: Math.ceil(0 / this.PAGE_SIZE) == 0 ? 1 : Math.ceil(0 / this.PAGE_SIZE)
                    });

                    let CID = this.dataForSwitchClients.userClients.filter((itemData: any) => itemData.id === operation.target.value)
                    if (CID.length > 0) {
                        this.loadData(CID[0].clientId);
                        this.setState({ selectedOperation: CID[0].id })
                    }
                    else {
                        this.loadData(0);
                        this.setState({ selectedOperation: '- CID -' })
                    }
                }
            }>

                <option value='CID'>- CID -</option>
                {this.dataForSwitchClients?.userClients?.map((item: any) =>
                    <option value={item?.id}>{item?.id}</option>)}

            </select>;

        const header = ({ columns }: HeaderProps) => <thead><tr>
            {
                <React.Fragment>
                    {this.clientUser ?
                        <ARMHeaderCell
                            dataName="Event"
                            text="-Event Type-"
                            cellType="text"
                            sortable={false}
                            isSorted={0}
                            select={reportFilters}
                        /> :
                        <ARMHeaderCell
                            dataName="Event"
                            text="Type"
                            cellType="text"
                            sortable={true}
                            isSorted={1}
                            sortHandler={this.handleSort}
                            sortIndicator={this.state.sortIndicator}

                        />}
                    <ARMHeaderCell
                        dataName="Name"
                        text="Name"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}

                    />
                    {this.clientUser &&
                        <ARMHeaderCell
                            dataName="CID"
                            text="All Reports"
                            cellType="text"
                            select={reportOperations}
                        />}
                    <ARMHeaderCell
                        dataName="Start"
                        text="Start"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="End"
                        text="End"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="Interval"
                        text="Interval"
                        cellType="text"
                    />
                    <ARMHeaderCell
                        dataName="Scheduled"
                        text="Scheduled"
                        cellType="text"
                    />
                    <ARMHeaderCell
                        dataName="LagDays"
                        text="Lag Days"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                </React.Fragment>
            }
        </tr></thead>

        let footerMsg: string = '';

        return (
            <DialogWrapper title="Scheduled Events" width="1133px" instruction={''} helpUrl='/Support/Help/SUP_ViewScheduledEvents.htm' buttons={buttons} isBusy={this.state.loader} >
                <ContentWrapper>
                    <DialogFieldset height='99%'>
                        <ARMGrid
                            dom-ID="report-id"
                            isFixedHeader={true}
                            maxHeight='492px'
                            columns={this.getReportListGridColumnHeaders() as Set<columnType>}
                            headerComponent={header}
                            records={this.state.reportFormAPIData}
                            selectionKey='empty'
                        />
                        <br></br>
                        
                        <FooterInstructions
                            footerMsgs={[footerMsg]}
                            pageCount={this.state.pageCount}
                            pageTotal={this.state.pageTotal}
                            onLeftBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.previousPage(e)}
                            onRightBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.nextPage(e)}
                        />
                    </DialogFieldset>
                </ContentWrapper>

            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IReportListState, IReportListActionProps, IOwnProps, IReportListProps, ApplicationState>(
    createCrudMapStateToProps('reportList'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ScheduledEvents);

export default withRouter(connectedHoc);
