import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';

export interface IOptionComponentProps {
    value: string;
    text: string;
    deleted?: string;
    selected?: string;
    fav?: boolean;
};

export interface IOptionGroupComponentProps {
    label: string;
    items: IOptionComponentProps[];
};

export interface ISelectGroupComponentProps {
    title: string;
    records: Array<{ label: string, items: Array<any> }>;
    optionGroups: IOptionGroupComponentProps;
    size?: number;
    width?: string;
    height?: string;
    disabled?: boolean;
    onSelect?: React.ChangeEventHandler<HTMLSelectElement>;
    className?: string;
    selectedValue?: string;
    selectedMultiValue?: string[];
    label?: string;
    multiple?: string;
    fontSize?: any;
    isUpper?: boolean;
};

export interface ISelectGroupComponentState {
    disabled: boolean;
};

export interface ISelectWrapperProps {
    widthValue?: string;
    heightValue?: string;
    fontSize?: any;
};

export interface ISelectWrapperDivProps {
    className?: string;
};

const SelectWrapperDiv = styled.div`
    flex: 1 1 auto;
    ${(props: ISelectWrapperDivProps) => props.className && `className: ${props.className}`};
`;

export const SelectWrapper = styled.select`
    ${(props: ISelectWrapperProps) => props.widthValue && `width: ${props.widthValue}`};
    ${(props: ISelectWrapperProps) => props.heightValue && `height: ${props.heightValue}`};
    ${Typography.ARMFontFamily} !important;
 

    overflow-y: auto;
    overflow-x: auto;

    .optgroup-class {
        color: ${Colors.grey100};
          ${Typography.ARMFontFamily};
          ${Typography.mediumLarge};
          ${(props: ISelectWrapperProps) => props.fontSize && `${props.fontSize}`};
          ${Typography.defaultLineHeight};
          margin: 0.1rem;
          display:block;
    }

    .option-class{
          color: ${Colors.grey100};
          ${Typography.ARMFontFamily};
          ${(props: ISelectWrapperProps) => props.fontSize && `${props.fontSize}`};
          ${Typography.defaultLineHeight};
          
          width: 100%;
          margin: 0;
          padding-left: 7px;
    }

    .option-fav {
        color: blue !important;
    }

    option:checked,
    option:active,
    option:focus {   
    position: relative; 
     background-color:  #E9F1FF !important;
     color: #002677;  
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none; 
    }
    option:hover{
     position: relative; 
     background-color:  #CDDDFE !important;
     color: #002677;  
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none; 
    cursor:pointer;
    }

    select:focus > option:checked:before {
    color: #FFF;
    position: absolute;
    content: attr(data-content);
    }

        .option-class:checked{
       background-color: #E9F1FF !important;
       color: #002677; 
        border-top: 1px solid #0C55B8;
       border-bottom: 1px solid #0C55B8;
       border-left:none !important;
       border-right:none !important;
       font-weight:initial !important;
    }
    .option-class:active{
        background-color: #E9F1FF !important;
        color: #002677;
        border-top: 1px solid #0C55B8;
       border-bottom: 1px solid #0C55B8;
       border-left:none !important;
       border-right:none !important;
        font-weight:initial !important;
    }

`;

export const InputLabel = styled.label`
  display: block;
  margin-bottom: 0.3rem;
  color: ${Colors.grey100};
  ${Typography.ARMFontFamily};
  ${Typography.small};
  ${Typography.defaultLineHeight};
  ${Typography.bold};
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const InputLabel2 = styled.label`
  font-size: 12px;
  font-family: 'Optum Sans';
  margin-top: 0.5rem;
  display: block;
  margin-bottom: 0.3rem;
  color: ${Colors.grey100};
  ${Typography.ARMFontFamily};
  ${Typography.defaultLineHeight};
  ${Typography.bold};
  letter-spacing: 0.5px;
`;

export class SelectGroupComponent extends React.Component<ISelectGroupComponentProps, ISelectGroupComponentState>
{
    static defaultProps: ISelectGroupComponentProps = {
        title: "SelectGroupComponent",
        records: [{label: '', items:[]}],
        optionGroups: {
            label: "value",
            items: [{
                value: "value",
                text: "text",
                selected: "selected",
                fav: false,
            }]
        },
        size: 10,
        disabled: false,
        label: '',
        fontSize: Typography.large,
        isUpper: true,
    };

    constructor(props: ISelectGroupComponentProps) {
        super(props);
        this.state = {
            disabled: this.props.disabled || false,
        }
    }

    isMultiple(): boolean {
        if (this.props.multiple && this.props.multiple.length > 0 && this.props.multiple !== "false")
            return true;
        return false;
    }

    renderOptionGroup() {       
        if (this.props.records && this.props.records.length > 0) {
            let optionGroups = this.props.records.map((record) => {
                let hasLabel = record.label != '';
                let options = record.items.map((itm) => {
                    if(hasLabel)
                        if (!(itm.deleted && (itm.deleted == "1"))) {
                            return <option className={'option-class ' + (itm.fav == true ? 'option-fav' : '') } key={itm.value} value={itm.value}>{itm.label}</option>
                        }
                    return <option className='option-class' key={itm.value} value={itm.value}>{itm.label}</option>
                });
                if(hasLabel)
                    return (<optgroup key={record.label + "_optkey"} className='optgroup-class' label={record.label}>{options}</optgroup>);
                return options
            });

            return (
                <React.Fragment>
                    {optionGroups}
                </React.Fragment>
            );
        }
    }

    render() {
            return (
                <SelectWrapperDiv className={this.props.className}>
                    {this.props.label && this.props.title && this.props.isUpper ? <InputLabel htmlFor={this.props.title}>{this.props.label}</InputLabel> : <InputLabel2 htmlFor={this.props.title}>{this.props.label}</InputLabel2> }
                    <SelectWrapper
                            id={this.props.title}
                            name={this.props.title}
                            widthValue={this.props.width}
                            heightValue={this.props.height}
                            {...(this.isMultiple() ? { multiple: true } : null)}
                            size={this.props.size}
                            fontSize={ this.props.isUpper ? this.props.fontSize : '12px'}
                            {...(this.isMultiple() ? { value: this.props.selectedMultiValue } : { value: this.props.selectedValue })}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { if (this.props.onSelect) this.props.onSelect(e) }}>
                            {this.renderOptionGroup()}
                    </SelectWrapper>
                </SelectWrapperDiv>
            );
    }
};