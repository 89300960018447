import * as React from 'react';
import { Masthead } from '@optum-uicl/ui-core/dist';
//import auth from 'auth-management';
import { IDToken } from '../../store/User';
//import 'core-js/es6/string';
//import 'core-js/es7/array';
//import { Typography } from '../commonResources/typography';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as ReactBootstrap from 'react-bootstrap';
import Colors from "@commonResources/colorVariables";
import { URLs } from '@commonDevResources/constants';

//${ Typography.fontFamily };
export const LayoutStyles = styled.div`
    .masthead-logo {
      cursor: default;
      pointer-events: none;        
      text-decoration: none;        
    }
`;

export const MainContent = styled.div`
    transition: padding-left 300ms ease-in-out;
      margin-top: 50px;
      height: calc(100vh - 60px);
    //   overflow: auto;

    aside {
        display: none;
        }
`;

export const MastheadWrapper = styled.div`
  .masthead-logo{
     img {
        content:url(/Shared/Images/Branding/Achieve/HeaderCapLeft.png);
        width: 420px;
        height: 50px;
        margin-left: -21px;
        margin-top: -13px;
        transform: translateZ(0);  /* Chrome image aliasing */
      }
      background-color: ${Colors.white};
      width: 100%;
    }  
`;

interface MastheadTokenData {
    currentProductName?: string;
    productMenuItems?: any;
    supportMenuSearch: () => void;
    userName?: string;
    userEmail?: string;
    avatarImage?: string;
};

const getTokenDataForMasthead = (isDefault: boolean = true) => {
    
    var results: MastheadTokenData = {
        currentProductName: isDefault ? "Assurance Reimbursement Management \u2122" : "",
        supportMenuSearch: () => { }
    };
    /*
    if (auth.isAuthenticated()) {
        const idTokenData: IDToken = auth.getUser();
        // TODO: we may need a better test than this
        const currentProduct = idTokenData.apps.find(app => app.uri === idTokenData.aud);
        if (currentProduct != undefined) {
            results.currentProductName = currentProduct.name;
        }
        // set product menu items
        results.productMenuItems = [idTokenData.apps
            .filter(app => app !== currentProduct)
            .map(app => ({
                id: app.id,
                label: app.name,
                path: app.uri
            }))];
        // set user info
        results.userName = `${idTokenData.given_name} ${idTokenData.family_name}`;
        results.userEmail = idTokenData.email;
        results.avatarImage = idTokenData.photo;
    }
    // TODO: if using auth-management, remove / don't use dummy data when logged out
    console.log(results);
    */
    return results;
};

const getNavMenuItems = () => {
    return [];
};

/*
//https://www.productiverage.com/typescript-es6-classes-for-react-components-without-the-hacks
//https://medium.freecodecamp.org/effective-use-of-typescript-with-react-3a1389b6072a
//https://levelup.gitconnected.com/ultimate-react-component-patterns-with-typescript-2-8-82990c516935
*/

interface ILayoutProps {
    mastheadConfig?: MastheadTokenData;
    children?: React.ReactNode; 
}

interface ILayoutState {
    navMenuItems: any;
    brandingPath: string;
    brandingLoaded: boolean;
}

type LayoutProps = ILayoutProps & RouteComponentProps<{}>;

export class Layout extends React.Component<LayoutProps, ILayoutState> {
    static defaultProps: ILayoutProps = {
        mastheadConfig: {
            currentProductName: "Assurance Reimbursement Management \u2122",
            supportMenuSearch: () => { }
        },
    };

    constructor(props: LayoutProps) {
        super(props);
        this.state = {
            navMenuItems: getNavMenuItems(),
            brandingPath: "Default",
            brandingLoaded: false,
        };
    }
    
    getBrandPath() {
        if (sessionStorage.getItem("brandingPath") === null || sessionStorage.getItem("brandingPath") === "undefined") {
            let url = URLs.api + '/api/data/securityInfo/brandPath';
            fetch(url)
                .then((response) => response.json())
                .then((jsonData) => {
                    this.setState({brandingLoaded: true, brandingPath: jsonData.BrandPath})
                    sessionStorage.setItem("brandingPath", jsonData.BrandPath);
                })
                .catch(error => {
                    console.error("Unable to retrieve brand path. Defaulting..");
                    this.setState({brandingLoaded: true});
                });
        }
        else{
            let brandPath = sessionStorage.getItem("brandingPath");
            if(brandPath) {
                this.setState({
                    brandingLoaded: true,
                    brandingPath: brandPath
                });
            }
        }
    }
    
    componentDidMount() {
        this.getBrandPath();
    }
    
    public renderMasthead(defaultBrand : boolean) {
        return <Masthead
            {...Object.assign({}, this.props.mastheadConfig, getTokenDataForMasthead(defaultBrand))}
            navMenuItems={this.state.navMenuItems}
            logoRedirect='LandingPage'
            onNavMenuSelect={(event?: React.SyntheticEvent<{}>, state?: any) => {
                //const item = props.mastheadConfig.navMenuItems[state.activeSectionIndex][state.activeMenuItemIndex];
                //props.mastheadActions.onNavMenuSelect(event, item);
                const theProps = this.props;
                const item = this.state.navMenuItems[state.activeMenuItemIndex];
                if (item) {
                    //    console.log(item.label);
                    //    console.log(item.Submenu);
                    //    if (item.Submenu) {
                    //        this.setState({
                    //            navMenuItems: item.Submenu
                    //        });
                    //    }
                    //if (item.useNav) {
                    //    window.location.href = item.path;
                    //}
                    //else {
                    this.props.history.push(item.path);
                    //}
                    //this.props.push(item.path);
                }
            }}
            onProductMenuSelect={(event: any, state: any) => {
                //const item = props.mastheadConfig.productMenuItems[state.activeSectionIndex][state.activeMenuItemIndex];
                //props.mastheadActions.onProductMenuSelect(event, item, state);
            }}
            onAvatarMenuSelect={(event: any, state: any) => {
                //const item = props.mastheadConfig.avatarMenuItems[state.activeSectionIndex][state.activeMenuItemIndex];
                //props.mastheadActions.onAvatarMenuSelect(event, item, state);
            }}
            productNameOneLine={true}
        />
    }
    
    public render() {
        let isPrivateLabel = this.state.brandingLoaded && this.state.brandingPath.indexOf("Achieve") > -1;
        if(this.state.brandingLoaded) {
            document.title = isPrivateLabel ? "Claims Management" : "Assurance";
        }
        let masthead = this.renderMasthead(true);

        if(isPrivateLabel) {
            masthead = <MastheadWrapper> {this.renderMasthead(false)} </MastheadWrapper>
        }
        if(this.state.brandingLoaded) {
            return <LayoutStyles>
                <div>
                    {masthead}
                </div>

                <MainContent>
                    {this.props.children}
                </MainContent>

            </LayoutStyles>
        }
        return "Loading...";
    }
};

export default withRouter(Layout);
