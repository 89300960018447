import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Radio } from '@optum-uicl/ui-core/dist';
import styled from 'styled-components';
import { bindAll,  find, map, noop, omit, partialRight, without } from 'lodash';
import moment from 'moment';
import { OKCancelButtons, DialogWrapper, ContentColumnWrapper, ContentRowJustify, DialogButtonOK } from '@common/DialogWrapper';
import { IModalConfirmationProps, ModalConfirmation } from '@common/ModalConfirmation';
import CrudTypes from '@commonResources/CrudTypes';
import {
    IMergeCrudComponentProps,
    createCrudMapStateToProps,
    createCrudMapDispatchToProps,
    mergeCrudComponentProps,
    resetCrudComponentState
} from '@scripts/util/CrudComponentHelpers';
import { IFormModalState } from '@store/ui/BaseCrudUI';
import { ApplicationState } from '@store/index';
import {
    actionCreators,
    IReportFilterUIState,
    IReportFilterActionProps,
    filterResponseValidationCallback,
    filterFormDataValidationCallback,
    massageFormsUsed
} from '@store/ReportFilter';
import { IModifyReportFilter } from '@store/ui/ReportFilterUI';
import { Input } from '@common/UICLWrappers/ARMInput';
import { handleChange, pageLeave } from '@commonResources/userModified';
import { DateRangeValidator, validateDateRange, ARMSpecialDates, validateDateRangeNotEmpty, validateDatesBetweenMinAndMax } from '@scripts/util/ValidationHelpers';
import FilterList from '@components/Reports/Filter/FilterList';
import UserList from '@components/Reports/Filter/UserList';
import MultiSelectForSimpleValues from '@common/FilterRelated/MultiSelectForSimpleValues';
import ARMDateRange from '@common/DateOrTimeRelated/ARMDateRange';
import { Typography } from '@commonResources/typography';
import { SelectComponent } from '@common/SelectComponent';

const CustomWrapper = styled.div`
    margin-bottom:4px;
`;
const SelectWrapper = styled.div`
    select{
        height:130px !important;
    }
`;
const InputWrapper = styled.div`
    label{
        text-transform:uppercase;
    }
`;

export interface IComponentState extends IFormModalState {
    isPageBusy: boolean,
    cancelLeave: boolean,
    activeTab: number | undefined,
    todayMoment: moment.Moment;
    today: string;
    minDate: string;
    maxDate: string;
    modalProps: Partial<IModalConfirmationProps>;
};

const armDateFormat = 'MM/DD/YYYY';
const DEFAULT_STATE: IComponentState = {
    isPageBusy: true,
    cancelLeave: false,
    activeTab: undefined,
    todayMoment: moment(),
    today: moment().format(armDateFormat),
    minDate: moment().add(-4, 'year').format(armDateFormat),
    maxDate: moment().format(armDateFormat),
    navigationConfirmed: false,
    saveOnNavigate: false,
    modalProps: {
        okText: "OK",
        cancelText: "Cancel",
        alertMode: false,
    },
};

// Defining this here rather than in the wrapper to ensure that the min and max dates stay in synch
export const validateReportFilterDatesAreWithinRange = validateDatesBetweenMinAndMax(moment().add(-4, 'year').toDate(), moment().toDate());
//export const validateReportFilterDatesAreWithinRange = validateDatesBetweenMinAndMax(moment().add(-1, 'year').toDate(), moment().add(2, 'years').toDate());
export interface IReportRequest {
    '@AuditNo'?: string;
    '@ClientID'?: string;
    '@Format': string;
    '@ID': string;
    '@Name': string;
    '@Type': string;
    '@DateFrom'?: string;
    '@DateTo'?: string;
    '@UserId'?: string;
    '@ActionId'?: string;
    '@LOBId'?: string;
    '@FacSubId'?: string;
    '@DatePmtTo'?: string;
    '@DatePmtFrom'?: string;
    '@CheckNumber'?: string;
    '@ProviderNum'?: string;
    '@ProviderNPI'?: string;
    '@ICNum'?: string;
    '@FormType'?: string;
    '@FormName'?: string;
}
export interface IFilterParameter {
    ReportRequests: {
        '@Type': string;
        ReportRequest: IReportRequest[];
    }
};


export interface IComponentProps {
    mainDateRangeInputsDisabled?: boolean;
    mainDateRangeValidators: DateRangeValidator[];
    pmtDateRangeValidators: DateRangeValidator[];
    defaultStartDate?: string | undefined;
    showFilterList?: boolean;
    showUserList?: boolean;
    showSingleUserSelect?: boolean;
    hideUserActionList?: boolean;
    showFacilitiesList: boolean;
    showLobList: boolean;
    showRemitFields: boolean;
    showFormsUsedOnly?: boolean;

    // config page props
    onActiveTabChange?: (i:number) => void;

    //wrapper props
    title?: string;
    process?: string;
    parameter?: IFilterParameter;
    execNode?: string;
    execAction?: string;
    execProcess?: string;
    //execAttribs?: string;
};

export interface ReportFilterMessageTemplateOptions {product: string; continueAction: string; returnDestination: string}

export type IOwnProps = IComponentProps & RouteComponentProps<{}>;
export type IReportFilterProps = IMergeCrudComponentProps<IReportFilterUIState, IReportFilterActionProps, IOwnProps>;

// for some reason dialogfieldset is inset from the edges of the other controls, this pulls it back out
// inside our filterlist
const BetterStyledFieldList = styled(FilterList)`
    &.report-filter-filter-list {
        margin-inline-start: 0;
        margin-inline-end: 0;
        margin: 0 5px 1px 0;
    }
`;

// UICL RadioButtonGroup not exported properly, so chewing gum and binder twine here
interface RadioGroupItem<T> {value:T; labelAfter:string};
interface RadioGroupProps<T> extends React.HTMLAttributes<HTMLDivElement> {
    items: RadioGroupItem<T>[];
    initialValue: T;
    onGroupChange: (value: T) => void;
}

const ARMContentRowJustify = styled(ContentRowJustify)`
    font-family: ${Typography.ARMFontFamily}
    margin: auto;
    min-width: 450px;
`;

function RadioButtonGroup <T>(props: RadioGroupProps<T>) {
    const [selectedItem, setSelectedItem] = React.useState<RadioGroupItem<T>>();
    React.useEffect(() => {
        if (selectedItem) props.onGroupChange(selectedItem.value);
    }, [selectedItem]);

    const checkedItem = selectedItem || props.items.find((item) => item.value === props.initialValue);
    /* removing value as Radio no longer supports - need to test this functionality */
    return (<ARMContentRowJustify className={props.className} id={props.id}>
                {props.items.map((item, idx) => (
                    <Radio checked={checkedItem?.value===item.value}  label={item.labelAfter} key={idx} onChange={() => setSelectedItem(item as any)}  />
                ))}
            </ARMContentRowJustify>);
}

export class ReportFilter extends React.PureComponent<IReportFilterProps, IComponentState> {
    // define only once, instead of on every render
    static defaultProps = {
        defaultStartDate: '',
        mainDateRangeInputsDisabled: false,
        mainDateRangeValidators: [],
        pmtDateRangeValidators: [],
        showFilterList: true, // TODO: minimal report filter is just a date range. Should this default to false?
        showUserList: false,
        hideUserActionList: false,
        showFacilitiesList:false,
        showFormsUsedOnly: false,
        showLobList: false,
        showRemitFields: false,
        title: '',
    }

    // life cycle //////////
    constructor(props: IReportFilterProps) {
        super(props);
        this.state = DEFAULT_STATE;
        //this.state = defaultFormModalState;
        bindAll(this, [
            'deleteSaveFilterCallback', 
            'formatRequest',
            'generateReport',
            'getSaveReportFilterData',
            'loadSavedFilterCallback',
            'newNameChangeCallback',
            'onCheckNumberChange',
            'onDateFromChange', 
            'onDateToChange', 
            'onFacilitySelectionChange',
            'onLobSelectionChange',
            'onDatePmtFromChange',
            'onDatePmtToChange',
            'onSelectedFormChange',
            'onUserSelectChange',
            'onUserActionChange',
            'onDialogOk',
            'onDialogCancel',
            'onProviderNumberChange',
            'onProviderNPIChange',
            'onICNumChange',
            'renderConfigurationButtons',
            'renderFacilities',
            'renderLobList',
            'renderUserList',
            'savedFilterSelectCallback', 
            'saveFilterCallback',
            'validateFields',
            'validateAndExecuteSaveFilter',
        ]);                     
    }

    public componentDidMount() {
        if (this.props.showFormsUsedOnly) {
            this.props.action.crud.get({ crudId: CrudTypes.mctiFormsUsed },
                (result: MCReportFilter) => {
                    if (massageFormsUsed(result)) {
                        this.props.action.ui.initFormsUsed({
                            masterCrud: result,
                            uiData: result.FormsUsedMaintenanceInfo?.Forms?.Form || [],
                        });
                        return true;
                    }
                }
            );
        } else {
            this.props.action.ui.getFormData(filterFormDataValidationCallback);

            if (!!this.props.defaultStartDate) {
                this.props.action.ui.editDateFrom(this.createModifyActionObject(this.props.defaultStartDate || ''));
            }
        }

        this.setState({ isPageBusy: false });

        pageLeave();
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    // validation (called from main dialog ok button gesture) //////////
    public validateFields(): boolean {
        if (this.props.showFormsUsedOnly) {
            if (!!this.props.dataStore.ui.selectedFormId) return true;
            // crud confirm reducer does not support alert mode
            this.setState({ modalProps: {...DEFAULT_STATE.modalProps, alertMode: true } }, () => {
                this.props.action.confirm.openConfirm('Please select a form type.', () => {
                        this.props.action.confirm.closeConfirm();
                        this.setState({ modalProps: { ...DEFAULT_STATE.modalProps } });
                    }
                );
            });
            return false;
        }
        const { dateFrom, dateTo} = this.props.dataStore.ui;
        // Template options get passed to a backtick template function to generate a different message
        // from creating the report (here) vs saving a filter
        const dateRangeTemplateOptions = {
            product: 'report',
            continueAction: 'create the report',
            returnDestination: ' to the filter'
        };
        //on remit report filters, if you have any information in any field, you have enough to run a report
        if (this.props.showRemitFields) {
            const remitFieldsValid = this.validateRemitFields(dateRangeTemplateOptions);
            // if we do have dates in the main field, we still need to validate them
            if (remitFieldsValid && (dateFrom + dateTo === '')) return true;
            // is ok if this didn't validate, but date range below needs to validate in that case
        }
        /*  Note that passing generateReport to fixMe here ONLY works because under current requirements never have a
            user field on a report that can run with an empty date range. If that changes, make sure that we validate the
            user field before generating the report!!!
        */
        return this.validateDateRange(dateFrom, dateTo, this.props.mainDateRangeValidators, dateRangeTemplateOptions, this.generateReport) && this.validateUserField(this.generateReport);
    }

    public validateDateRange(
        from: string, to: string,
        validators: DateRangeValidator[],
        templateOptions: ReportFilterMessageTemplateOptions, 
        fixMe: Function = noop):boolean {
        const result = validateDateRange(from, to, validators);
        switch (result.kind) {
            case 'noError':
                return true;
            case 'correctedDateRangeError':
                switch (result.fieldName) {
                    case 'Payment': {
                        this.props.action.ui.editPaymentDateFrom(result.newFromValue);
                        this.props.action.ui.editPaymentDateTo(result.newToValue);
                        break;
                    }
                    default: {
                        this.props.action.ui.editDateFrom(this.createModifyActionObject(result.newFromValue));
                        this.props.action.ui.editDateTo(this.createModifyActionObject(result.newToValue));
                        break;
                    }
                }
                return true; //because we fixed it already
            case 'dateRangeError':
                // crud confirm reducer does not support alert mode
                this.setState({ modalProps: {...DEFAULT_STATE.modalProps, alertMode: true } }, () => {
                    this.props.action.confirm.openConfirm(result.message, () => {
                            this.props.action.confirm.closeConfirm();
                            this.setState({ modalProps: { ...DEFAULT_STATE.modalProps } });
                        }
                    );
                });
                return false;
            case 'correctableDateRangeResult':
                let message: string;
                if (typeof result.message === 'function') {
                    const rm = result.message as Function; // really, TS?
                    message = rm(templateOptions);
                } else {
                    message = result.message as string;
                }
                this.setState({ modalProps: result.modalOptions }, () => {
                    this.props.action.confirm.openConfirm(message, () => {
                        this.props.action.ui.editDateFrom(this.createModifyActionObject(ARMSpecialDates.TODAY));
                        this.props.action.ui.editDateTo(this.createModifyActionObject(ARMSpecialDates.TODAY));

                        this.props.action.confirm.closeConfirm();
                        // having fixMe run as a setState callback is a hack to guarantee redux state has updated by the time it runs
                        this.setState({ modalProps: { ...DEFAULT_STATE.modalProps } }, () => fixMe());
                    });
                });
        }
        return false;
    }

    public validateRemitFields(dateRangeTemplateOptions:ReportFilterMessageTemplateOptions): boolean {
        const { dateFrom, dateTo, datePmtFrom, datePmtTo, checkNumber, providerNum, providerNPI, ICNum } = this.props.dataStore.ui;

        //empty date range needs to be fixed if we don't have other date
        const validators = [...this.props.pmtDateRangeValidators];
        if (!dateFrom && !dateTo) {
            validators[0] = validateDateRangeNotEmpty;
        }
        if (this.validateDateRange(datePmtFrom,
            datePmtTo,
            validators,
            dateRangeTemplateOptions)) {
            return true;
        }
        if (!!(checkNumber + providerNum + providerNPI + ICNum)) return true; //any of these strings has at least 1 char
        return false;
    }

    // TODO: report 5101 does not allow multi select and requires a user be selected
    // it has a different error message
    public validateUserField(runOnConfirm: Function | undefined): boolean {
        const { selectedUserIds } = this.props.dataStore.ui;
        const { showUserList, showSingleUserSelect } = this.props;
        if (showUserList && !selectedUserIds.length) {
            this.setState({ modalProps: { ...DEFAULT_STATE.modalProps } },
                () => {
                    this.props.action.confirm.openConfirm(
                        'Do you want to run the report against all users?',
                        runOnConfirm || this.generateReport, //yes
                        this.props.action.confirm.closeConfirm //no
                    );
                }
            );

            return false;
        } else if (showSingleUserSelect && !selectedUserIds.length) {
            this.setState({ modalProps: {...DEFAULT_STATE.modalProps, alertMode: true } }, () => {
                this.props.action.confirm.openConfirm('Please select a user.', () => {
                        this.props.action.confirm.closeConfirm();
                        this.setState({ modalProps: { ...DEFAULT_STATE.modalProps } });
                    }
                );
            });
            return false;
        }
        return true;
    }

    // happens whenever user clicks yes, no, ok, cancel x in corner
    // after this method is called, specific handler can also run
    // TODO: should rename
    public onModalOk = () => {
        this.props.action.confirm.closeConfirm();
        if (this.props.dataStore.ui.errors) {
            this.props.action.ui.clearFilterError(this.createModifyActionObject('ALL'));
        }
    }

    // callbacks from the filter buttons (load, save, delete) //////////

    public deleteSaveFilterCallback(filter: APICF_SimpleList) {
        const modalProps = { ...DEFAULT_STATE.modalProps };
        modalProps.okText = 'Yes';
        modalProps.cancelText = 'No';

        this.setState({modalProps},
            () => {
                this.props.action.confirm.openConfirm(`Are you sure you want to delete '${filter['Name']}'?`,
                    () => this.executeDeleteFilter({ 'Name': '', 'ID': filter.ID })
                );
            }
        );
        return;
    }

    public saveFilterCallback() {
        var selectedId = +this.props.dataStore.ui.selectedFilterData['ID'];
        var typedName = this.props.dataStore.ui.newFilterName.trim();

        // no filter picked or named?
        if (selectedId === -1 && typedName.length < 1) {
            this.props.action.confirm.openConfirm('Please type a new name to create a filter or select an existing saved filter to update it.');
            return;
        }

        // existing filter not selected, but typedName appears on list?
        var foundId = this.findFilterNameInList(typedName, this.props.dataStore.ui.ListPopulation?.FilterList);
        if (foundId != -1) {
            const modalProps = { ...DEFAULT_STATE.modalProps };

            modalProps.okText = 'Yes';
            modalProps.cancelText = 'No';

            this.setState({modalProps},
                () => {
                    this.props.action.confirm.openConfirm(
                        `Do you want to update the existing saved filter named '${typedName}'?`,
                        () => this.validateAndExecuteSaveFilter({ 'Name': '', 'ID': foundId.toString() })
                    );
                }
            );
            return;
        }

        if (typedName.length > 0) { // new filter name?
            this.validateAndExecuteSaveFilter({ 'Name': typedName, 'ID': '-1' });
        } else {
            this.validateAndExecuteSaveFilter({ 'Name': '', 'ID': selectedId.toString() });
        }
    }

    public validateAndExecuteSaveFilter(filter: APICF_SimpleList) {
        const { dateFrom, dateTo} = this.props.dataStore.ui;
        const dateRangeTemplateOptions =
            { product: 'filter', continueAction: 'save this filter', returnDestination: '' };
        //on remit report filters, if you have any information in any field, you have enough to run a report
        let remitFieldsValid = false;
        if (this.props.showRemitFields) {
            remitFieldsValid = this.validateRemitFields(dateRangeTemplateOptions);
            // is ok if this didn't validate, but date range below needs to validate in that case
            // even if it did validate and we have stuff in our main date range, that needs to validate
        }
        if ((remitFieldsValid && (dateFrom + dateTo === '')) || (this.validateDateRange(dateFrom, dateTo,
            this.props.mainDateRangeValidators,
            dateRangeTemplateOptions,
            () => {
                /*  This function gets run if a user confirms they want to correct the date range.
                    We're using a closure here to store identifying info to save this filter, because
                    modal confirmations are asynchronous. In other words, we're passing the function containing
                    this comment to be run at a later time.
                */
                this.executeSaveFilter(filter);
            }) && this.validateUserField(() => {
                this.executeSaveFilter(filter);
            }))
        ) {
            // This gets run synchronously if validation passes in the normal way
            this.executeSaveFilter(filter);
        }
    }
    
    // utilities //////////
    public formatRequest(): APIXMLCB_XmlCallBackModel | undefined {
        let xmlData: APIXMLCB_XmlCallBackModel = {}

        if (!this.props.execAction) return;


        var jsonData: any = {};
        jsonData.Report = {}; // execNode
        jsonData.Report['@Action'] = this.props.execAction;

        const requests = this.props.parameter?.ReportRequests;
        const { dateFrom, dateTo, 
            selectedUserIds, selectedUserActions, 
            selectedLobs, selectedFacilities, 
            datePmtTo, datePmtFrom, checkNumber, providerNum, providerNPI, ICNum, 
            selectedFormId, formsUsedList } = this.props.dataStore.ui;

        // don't send -1 filter values (without removes specified values, partial wraps it to remove the -1 in map)
        const [realSelectedIds, realSelectedActions, realSelectedLobs, realSelectedFacilities ] = map([selectedUserIds, selectedUserActions, selectedLobs, selectedFacilities], partialRight(without, '-1'));
        const { showUserList, hideUserActionList, showLobList, showFilterList, showRemitFields, showFormsUsedOnly, showSingleUserSelect } = this.props;

        if (!!requests) {
            jsonData.Report.ReportRequests = { ...requests }; //shallow copy
            const requestsNodes = requests.ReportRequest.map((node:Partial<IReportRequest>):Partial<IReportRequest> => {
                // these extra variables (e.g., rangeProps, userProps) 
                // are there so that if these values are undefined, they disappear from the spread
                const rangeProps = {
                    '@DateFrom': dateFrom,
                    '@DateTo': dateTo,
                };

                let userProps: { '@UserId'?: string, '@ActionId'?: string } = {};
                let otherProps: Partial<IReportRequest> = {};

                if (showFormsUsedOnly) {
                    const selectedForm = formsUsedList.find(form => form['@ID'] === selectedFormId);
                    if (selectedForm) otherProps = { '@FormType': selectedFormId, '@FormName': selectedForm['@Name'] };
                } else {
                    let UserId: string | undefined, ActionId: string | undefined;
                    if ((showUserList || showSingleUserSelect) && realSelectedIds.length) {
                        UserId = realSelectedIds.join();
                    }
                    if (showUserList && !hideUserActionList && realSelectedActions.length) {
                        ActionId = realSelectedActions.join();
                    }
                    userProps = { '@UserId': UserId, '@ActionId': ActionId };

                    if (showLobList && realSelectedLobs.length) {
                        otherProps['@LOBId'] = realSelectedLobs.join();
                    }
                    if (showFilterList && realSelectedFacilities.length) {
                        otherProps['@FacSubId'] = realSelectedFacilities.join();
                    }

                    if (showRemitFields) {
                        otherProps['@DatePmtTo'] = datePmtTo;
                        otherProps['@DatePmtFrom'] = datePmtFrom;
                        otherProps['@CheckNumber'] = checkNumber;
                        otherProps['@ProviderNum'] = providerNum;
                        otherProps['@ProviderNPI'] = providerNPI;
                        otherProps['@ICNum'] = ICNum;
                    }
                }

                return { ...node, ...rangeProps, ...userProps, ...otherProps, '@AuditNo': '1' };
            });
            jsonData.Report.ReportRequests.ReportRequest = requestsNodes;
        }
        xmlData.Action = this.props.execAction ? this.props.execAction : "";
        xmlData.ViewOrigin = '/Reports/filter.asp';  // see note in type def
        xmlData.FilterJsonData = JSON.stringify(jsonData);
        //console.log('xmlData ' + xmlData);
        return xmlData;
    }

    public findFilterNameInList(name: string, filterList?: APICF_SimpleList[]): number {
        // TODO: Javascript Array fn has a find method
        if (filterList != null) {
            for (var index = 0; index < filterList.length; ++index) {
                if (name == filterList[index]['Name']) {
                    return +filterList[index]['ID'];
                }
            }
        }
        return -1;
    }
    
    private createModifyActionObject(data: string): IModifyReportFilter {
        return {
            masterCrud: this.props.dataStore.crud.data,
            uiData: data.trim() || '',
        }
    }

    // backend calls //////////
    public executeSaveFilter(filter: APICF_SimpleList) {
        let xmlData: APIXMLCB_XmlCallBackModel = {};
        xmlData.Action = "FilterList";
        xmlData.Type = 'Report';
        xmlData.Process = this.props.process;
        xmlData.FilterId = +filter['ID'];
        xmlData.FilterName = filter['Name'];
        xmlData.FilterJsonData = this.getSaveReportFilterData();

        this.props.action.ui.saveFilter(xmlData, filterResponseValidationCallback);
    }

    public getSaveReportFilterData(): string {
        const defaultArr: string[] = []; //stupid typescript
        const { selectedUserIds = defaultArr, selectedUserActions = defaultArr, selectedLobs = defaultArr, selectedFacilities = defaultArr } = this.props.dataStore.ui;
        const [realSelectedUserIds, realSelectedActions, realSelectedLobs, realSelectedFacilities] = map<string[], string[]>([selectedUserIds, selectedUserActions, selectedLobs, selectedFacilities], partialRight(without, '-1'));

        let dataFilter: APIRep_StoredFilterSettings = {
            '@DateFrom': this.props.dataStore.ui.dateFrom,
            '@DateTo': this.props.dataStore.ui.dateTo,
            '@DatePmtFrom': this.props.dataStore.ui.datePmtFrom,
            '@DatePmtTo': this.props.dataStore.ui.datePmtTo,
            '@CheckNumber': this.props.dataStore.ui.checkNumber,
            '@ProviderNum': this.props.dataStore.ui.providerNum,
            '@ProviderNPI': this.props.dataStore.ui.providerNPI,
            '@ICNum': this.props.dataStore.ui.ICNum,
            'LOBs': undefined,
            'Users': undefined,
            'UserActions': undefined,
            'Facilities': undefined,
        };

        if (realSelectedLobs.length > 0) {
            dataFilter.LOBs = {
                LOB: realSelectedLobs.map((id: string) => ({ '@ID': id })),
            }
        }

        if (realSelectedUserIds.length) {
            dataFilter.Users = {
                User: realSelectedUserIds.map((id: string) => ({ '@ID': id })),
            }
        }

        if (realSelectedActions.length) {
            dataFilter.UserActions = {
                UserAction: realSelectedActions.map(id => ({'@ID': id})),
            }
        }

        if (realSelectedFacilities.length) {
            dataFilter.Facilities = {
                Facility: realSelectedFacilities.map(id => ({'@ID': id})),
            }
        }

        let data: APIRep_StoredFilterSettingsContainer = {
            Filter: { ...dataFilter }, //destructuring to drop out undefined attributes
        };

        return JSON.stringify(data);
    }

    public executeDeleteFilter(filter: APICF_SimpleList) {
        let xmlData: APIXMLCB_XmlCallBackModel = {};
        xmlData.Action = "DeleteFilter";
        xmlData.Type = 'Report';
        xmlData.Process = this.props.process;
        xmlData.FilterName = filter.Name;
        xmlData.FilterId = +filter.ID;

        this.props.action.ui.deleteReportFilter(xmlData, filterResponseValidationCallback);
    }

    public loadSavedFilterCallback(filter: APICF_SimpleList) {
        let xmlData: APIXMLCB_XmlCallBackModel = {};
        xmlData.Type = 'Report';
        xmlData.FilterId = +filter['ID'];

        this.props.action.ui.loadSelectedFilter(xmlData);
    }

    private generateReport() {
        const requestBody = this.formatRequest();
        if (!!requestBody) {
            this.props.action.ui.executeReportProcess(requestBody,
                (response: any) => {
                    if (response.error) return false;
                    this.props.history.push("/LandingPage");
                    return true;
                }
            );
        }
    }

    // change handlers for form fields //////////

    public onDateFromChange(dateFrom: string) {
        this.props.action.ui.editDateFrom(this.createModifyActionObject(dateFrom));
    }

    public onDateToChange(dateTo: string) {
        this.props.action.ui.editDateTo(this.createModifyActionObject(dateTo));
    }

    public onDatePmtFromChange(dateFrom: string) {
        this.props.action.ui.editPaymentDateFrom(dateFrom);
    }

    public onDatePmtToChange(dateTo: string) {
        this.props.action.ui.editPaymentDateTo(dateTo);
    }

    public onUserSelectChange(selectedUsers: APICF_SimpleListUser[]) {
        this.props.action.ui.editSelectedUsers({
            masterCrud: this.props.dataStore.crud.data,
            uiData: selectedUsers.map(user => user.ID),
        });
    }

    public onUserActionChange(selectedActions: string[]) {
        this.props.action.ui.editSelectedUserActions({
            masterCrud: this.props.dataStore.crud.data,
            uiData: selectedActions,
        });
    }

    public onLobSelectionChange(values: string[]) {
        this.props.action.ui.editSelectedLOBs({
            masterCrud: this.props.dataStore.crud.data,
            uiData: values,
        });
    }

    public onFacilitySelectionChange(values: string[]) {
        this.props.action.ui.editSelectedFacilities({
            masterCrud: this.props.dataStore.crud.data,
            uiData: values,
        });
    }

    public onCheckNumberChange({target: {value}} : React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editCheckNumber(value);
    }

    public onProviderNumberChange({target: {value}} : React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editProviderNumber(value);
    }

    public onProviderNPIChange({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editProviderNPINumber(value);
    }

    public onICNumChange({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editICNumber(value);
    }

    public onSelectedFormChange({target: {value}}: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.editSelectedFormId(value);
    }

    public newNameChangeCallback(value: string) {
        this.props.action.ui.updateNewFilterName(value);
    }

    public savedFilterSelectCallback(filter: APICF_SimpleList) {
        this.props.action.ui.updateSelectedFilter(filter);
    }


    // outer dialog ok cancel callbacks
    // for some reason manual bind and bindAll did not work with these functions, so using alternate syntax
    public onDialogOk = () => {
        if (this.validateFields()) {
            this.setState({ navigationConfirmed: true, saveOnNavigate: true },
                this.generateReport
            );
        }
    }
    public onDialogCancel = (e: React.ChangeEvent<HTMLButtonElement>) => {
        this.props.history.push('/LandingPage');
    }

    // rendering //////////
    public renderConfigurationButtons() {
        const { onActiveTabChange } = this.props;
        if (!onActiveTabChange) return '';
        return (
            <CustomWrapper>
            <RadioButtonGroup
                id='report-filter-select-active-tab'
                initialValue={0}
                items={[
                        {value: 0, labelAfter: '72 Hour'},
                        {value: 1, labelAfter: 'Remit Balancing'},
                        {value: 2, labelAfter: 'User Activity'},
                    ]}
                onGroupChange={onActiveTabChange}
            />
            </CustomWrapper>
        );
    }
    public renderMainDateRange() {
        let { dateFrom, dateTo } = this.props.dataStore.ui;
        if (!dateFrom) dateFrom = this.props.defaultStartDate ?? '';
        const { mainDateRangeInputsDisabled } = this.props;
        const labelFrom = this.props.showRemitFields ? 'Import Date:' : 'Date:';
        return (
            <ARMDateRange 
                allowTime={false}
                className={this.props.mainDateRangeInputsDisabled ? 'prevent-manual-input' : ''}
                domIdPrefix="report-filter-main-date-" 
                initialFrom={dateFrom}
                initialTo={dateTo}
                labelFrom={labelFrom}
                labelTo="Thru:"
                lockKeyboardInput={mainDateRangeInputsDisabled}
                minDate={this.state.minDate}
                maxDate={this.state.maxDate}
                onFromChange={this.onDateFromChange}
                onToChange={this.onDateToChange}
                showDefaultButtonBar={!mainDateRangeInputsDisabled}
            />
        );
    }
    
    public renderRemitFields() {
        let { checkNumber, datePmtFrom, datePmtTo, errors, ICNum, providerNPI, providerNum } = this.props.dataStore.ui;
        return (
            <React.Fragment>
                <ARMDateRange 
                    allowTime={false}
                    domIdPrefix="report-filter-pmt-date-" 
                    initialFrom={datePmtFrom}
                    initialTo={datePmtTo}
                    labelFrom="Payment Date:"
                    labelTo="Thru:"
                    minDate={this.state.minDate}
                    maxDate={this.state.maxDate}
                    onFromChange={this.onDatePmtFromChange}
                    onToChange={this.onDatePmtToChange}
                    showDefaultButtonBar={true}
                />
                <InputWrapper>
                <Input
                    className='text-input'
                    domID='report-filter-check-number-input'
                    errorMessage={errors?.invalidCheckNum}
                    hasError={!!errors?.invalidCheckNum}
                    label='Check Number:'
                    size='small'
                    initialValue={checkNumber}
                    maxLength={50}
                    onBlur={this.onCheckNumberChange}
                />
                <Input
                    className='text-input'
                    domID='report-filter-provider-num-input'
                    errorMessage={errors?.invalidProviderNum}
                    hasError={!!errors?.invalidProviderNum}
                    label='Provider No:'
                    size='small'
                    initialValue={providerNum}
                    maxLength={50}
                    onBlur={this.onProviderNumberChange}
                />
                <Input
                    className='text-input'
                    domID='report-filter-provider-npi-input'
                    errorMessage={errors?.invalidProviderNpi}
                    hasError={!!errors?.invalidProviderNpi}
                    label='Provider NPI:'
                    size='small'
                    initialValue={providerNPI}
                    maxLength={50}
                    onBlur={this.onProviderNPIChange}
                />
                <Input
                    className='text-input'
                    domID='report-filter-ic-num-input'
                    errorMessage={errors?.invalidIcNum}
                    hasError={!!errors?.invalidIcNum}
                    label='Internal Control No:'
                    size='small'
                    initialValue={ICNum}
                    maxLength={50}
                    onBlur={this.onICNumChange}
                />
                </InputWrapper>
            </React.Fragment>
        );
    }

    public renderFilters(shouldDisableSave:boolean) {
        return (
            <BetterStyledFieldList className='report-filter-filter-list'
                disableSaveBtn={shouldDisableSave}
                onDeleteSavedFilter = { this.deleteSaveFilterCallback }
                onLoadSavedFilter={ this.loadSavedFilterCallback }
                onNewNameChange = { this.newNameChangeCallback }
                onSavedFilterSelect = { this.savedFilterSelectCallback }
                onSaveFilter = { this.saveFilterCallback }
                newFilterName={this.props.dataStore.ui.newFilterName}
                savedFilters={this.props.dataStore.ui.ListPopulation?.FilterList}
                selectedFilter={this.props.dataStore.ui.selectedFilterData}
            />
        );
    }

    public renderUserList(singleSelect = false) {
        const userList = this.props.dataStore.ui.ListPopulation?.UserList;
        const { selectedUserIds, selectedUserActions } = this.props.dataStore.ui;
        return <UserList 
            users={userList}
            selectedUserIds={selectedUserIds}
            selectedUserActions={selectedUserActions}
            hideActions={this.props.hideUserActionList}
            onUserSelectionChange={this.onUserSelectChange}
            onUserActionChange={this.onUserActionChange}
            singleSelect={singleSelect}
            />;
    }

    public renderLobList() {
        const optionList = this.props.dataStore.ui.ListPopulation?.LOBList || [];
        const ids = this.props.dataStore.ui.selectedLobs;
        return (
            <SelectWrapper>
            <MultiSelectForSimpleValues
                defaultOption="- All LOBs -"
                label="LOB:"
                optionList={optionList}
                onChange={this.onLobSelectionChange}
                selectedIds={ids}
                size={6}
            />
            </SelectWrapper>
        );
    }

    public renderFacilities() {
        const optionList = this.props.dataStore.ui.ListPopulation?.FacilityList || [];
        const ids = this.props.dataStore.ui.selectedFacilities;
        return (
            <MultiSelectForSimpleValues
                defaultOption="- All Facilities -"
                label="Facility:"
                optionList={optionList}
                onChange={this.onFacilitySelectionChange}
                selectedIds={ids}
            />
        );
    }

    public renderFormsUsedList() {
        const {formsUsedList, selectedFormId} = this.props.dataStore.ui;
        const options = { value: '@ID', text: '@Name' };
        const defaultOptionAndValue = {'@ID': '', '@Name': '- Select Form Type -' } 
        const records = [defaultOptionAndValue, ...formsUsedList];
        return (
            <SelectComponent 
                label="Form Type:"
                multiple="false"
                records={records}
                selectedValue={selectedFormId}
                size={1}
                onSelect={this.onSelectedFormChange}
                optionFields={options}
            />
        );
    }
    
    private okButton = <DialogButtonOK onClick={this.onDialogCancel} />;

    public render() {
        const { isBusy, errors } = this.props.dataStore.ui;
        const { showUserList, showFacilitiesList, showFilterList, showLobList, showRemitFields, showFormsUsedOnly, showSingleUserSelect } = this.props;
        const errMessage = errors?.failedReportBatch;
        const { modalProps } = this.state;
        // these errors show on the screen, so we don't need a modal.
        // but we still can't let them save with an error state
        let shouldDisableSave: boolean = false;
        if (this.props.showRemitFields && !!this.props.dataStore.ui.errors) {
            const remitFilterErrors = omit(this.props.dataStore.ui.errors, 'failedReportBatch');
            const anyError = find(remitFilterErrors);
            shouldDisableSave = !!anyError;
        }
        const okCancelButtons = <OKCancelButtons onClickOK={this.onDialogOk} onClickCancel={this.onDialogCancel} disableOK={shouldDisableSave} />;

        /*  We could also create a function to run in the ternary below. I picked this
            because that way we don't have to pass shouldDisableSave through another function.
            The downside is this logic runs even when we don't need it. (same decision was made for okCancelButtons)
        */
        const normalRender = (
            <React.Fragment>
                {this.renderConfigurationButtons()}
                {this.renderMainDateRange()}
                {showRemitFields && this.renderRemitFields()}
                {(showUserList || showSingleUserSelect)&& this.renderUserList(showSingleUserSelect)}
                {showLobList && this.renderLobList()}
                {showFacilitiesList && this.renderFacilities()}
                {showFilterList && this.renderFilters(shouldDisableSave)}
            </React.Fragment>);
        return (<DialogWrapper title='Report Filter'
            helpUrl='/Support/Help/HELP_Reports_DateRangeFilter.htm'
            buttons={this.props.onActiveTabChange? this.okButton : okCancelButtons}
            isBusy={isBusy || this.state.isPageBusy}
        >
            <ContentColumnWrapper style={{padding: '10px', minWidth: 'auto'}}>
                {
                    showFormsUsedOnly? this.renderFormsUsedList(): normalRender
                }
            </ContentColumnWrapper>
            <ModalConfirmation alertMode={this.state.modalProps.alertMode || !!errMessage}
                cancelText={modalProps.cancelText}
                isOpen={this.props.dataStore.confirm.isOpen || !!errMessage}
                message={this.props.dataStore.confirm.message || errMessage}
                okText={modalProps.okText}
                onModalToggle={this.onModalOk}
                onConfirm={this.props.dataStore.confirm.confirmCallback}
                title={modalProps.title || ''}
            />
        </DialogWrapper>);
    }
}

const connectedHoc = connect<IReportFilterUIState,
    IReportFilterActionProps,
    IOwnProps,
    IReportFilterProps,
    ApplicationState>(
        createCrudMapStateToProps('reportFilter'),
        createCrudMapDispatchToProps(actionCreators),
        mergeCrudComponentProps
    )(ReportFilter);

export default withRouter(connectedHoc);