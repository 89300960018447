import * as React from 'react';
import { useState, useEffect, useRef, useCallback } from "react";
import styled, { css } from 'styled-components';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// this is how uicl imports singleDatePicker
//import SingleDatePicker from 'react-dates/lib/components/SingleDatePicker';
import { SingleDatePicker } from 'react-dates';

import { Colors, getCalendarIconColor, getClearIconColor} from '@commonResources/colorVariables';
import { Typography } from '@commonResources/typography';
import { VisuallyHiddenText } from '@commonResources/visuallyHiddenText';
import next from '../../../content/images/next.svg';
import previous from '../../../content/images/previous.svg';

import { SelectComponent } from '@common/SelectComponent';

import { Clear, SelectDropdown, Calendar, Input } from '@optum-uicl/ui-core/dist';

import moment from 'moment';



export const ARMDatePickerWrapper = (styled.div as any)`
  display: flex;
  flex-direction: column;
  /*
   INPUT
   *****************************
   */
  .SingleDatePicker {
    display: initial;
    min-width: ${(props: IARMDatePickerWrapperProps) => (props.showCalendarIcon ? 140 : 120)}px;
    z-index: auto;
    
  }

  .SingleDatePickerInput {
    position: relative;
    display: flex;
    align-items: center;

    width: ${(props: IARMDatePickerWrapperProps) =>  (props.showCalendarIcon ? 170 : 145)}px;

    height: 32px;
    padding: 0 8px;
    border-radius: 6px;
    border: 1px solid rgb(75, 77, 79);
    box-shadow: inset 0 2px 2px 0 rgba(155, 161, 169, 0.25);
    background-color: ${Colors.white};
    outline: none;
    min-width: ${(props: IARMDatePickerWrapperProps) => 
         props.showCalendarIcon ? 140 : 120
    }px;

    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    &:hover {
      ${Colors.grey70};
    }

    &:focus-within {
      ${Colors.grey100};
    }

    .SingleDatePickerInput_calendarIcon {
      margin: 0 3px 0 3px;
      padding: 0;
      svg {
        vertical-align: middle;
        padding-left: 0px;
      }
      &:focus {
        outline: none;
        box-shadow: 0px 0px 0px 2px #0C55B8;
        border-radius: 6px;
        border: none;
        margin: 0 1px 0 1px;
        padding: 2px;
        padding-right: 4px;
      }
    }
    .DateInput {
      background: none;
      flex: 1;
      width: unset;
      /* for the date range picker we need to shorten the first input */
      &:first-of-type {
        flex: 1;
        width: 'unset';
      }

      /* the actual input element */
      .DateInput_input {
        height: 32px;
        ${Typography.mediumLarge};
        color: ${Colors.grey100};
        padding: 0;
        background: none;
        border-bottom: none;

        &::placeholder {
          color: ${Colors.grey50};
          opacity: 1;
        }
        &::-ms-clear {
          display: none;
        }
      }
      .DateInput_input__focused {
        border-bottom: none;
      }
      .DateInput_input__disabled {
        font-style: normal;
      }
    }


    /* clear button styles */
    .SingleDatePickerInput_clearDate {
      position: relative;
      display: flex;
      top: 0;
      margin: 0;
      margin-left: auto;
      padding: 0;
      transform: none;
      margin-right: ${(props: IARMDatePickerWrapperProps) => (props.hasDropdown ? '22px' : 0)};
      &:focus {
        outline: none;
        box-shadow: 0px 0px 0px 2px #0C55B8;
        border-radius: 2px;
        border: none;
      }
    }
    .SingleDatePickerInput_clearDate__hide {
      display: none;
    }
  }
    .SingleDatePickerInput .DateInput .DateInput_input{
        outline-color: rgb(12, 85, 184) !important;
        }

  #datepicker-select-button-dropdown-wrapper {
    margin-top: -31px;
    align-self: flex-end;
    #datepicker-select-button {
      height: 18px;
      padding: 0 0.5rem;
      margin-top: 6px;
      margin-right: 1px;
      border-left: 1px solid ${Colors.grey20};
      z-index: auto;
      svg {
        margin-left: 0px;
      }
      &:focus {
        outline: none;
        box-shadow: none;
        border-left: 1px solid ${Colors.grey20};
        border-radius: 0;
      }
    }
  }

  /* if hasError prop is true */
  &.has-error {
    label,
    .SingleDatePickerInput .DateInput .DateInput_input {
      color: ${Colors.digitalRed130};
    }
    .SingleDatePickerInput {
      border: 1px solid ${Colors.digitalRed130};
      box-shadow: 0px 0px 0px 1px ${Colors.digitalRed130};
      margin-bottom: 0.5em;
    }
    /* resolve clear button being out of position */
    button.unstyled {
      margin-bottom: 0.7em;
    }
    &.focused {
      color: ${Colors.grey100};
    }
    #datepicker-select-button-dropdown-wrapper {
      margin-top: -39px;
      margin-bottom: 1rem;
    }
  }

  /* if disabled prop is true */
  &.disabled {
    .SingleDatePickerInput {
      color: ${Colors.grey100};
      border: 1px solid ${Colors.grey30};
      box-shadow: none;
    }
    label {
      color: ${Colors.grey50};
    }
    input {
      color: ${Colors.grey100};

      &::placeholder {
        color: ${Colors.grey50};
      }
    }
  }

  /*
   CALENDAR
   *****************************
   */

  /* supporting openDirection */
  .SingleDatePicker_picker {
    /* we need to use !important in some places here to override the library's styles */
    top: 33px !important; /* stylelint-disable-line declaration-no-important */
  }

  .SingleDatePicker_picker__directionLeft {
    /* we need to use !important in some places here to override the library's styles */
    left: 0px !important; /* stylelint-disable-line declaration-no-important */
    right: unset !important; /* stylelint-disable-line declaration-no-important */
  }

  /* border and shadow for open datepicker */
  .DayPicker {
    border: 1px rgba(55, 71, 79, 0.2); /* Grey100 */
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12); /* do we have this color already? */
  }

  /* force a bunch of things to be the correct width */
  .SingleDatePicker_picker,
  .DayPicker{
    /* we need to use !important in some places here to override the library's styles */
    /* stylelint-disable-next-line declaration-no-important */
    width: ${(props: IARMDatePickerWrapperProps) => (props.numberOfMonths ? props.numberOfMonths * 320 : 320) + (props.showDefaultButtonBar ? 160 : 0)}px !important;
  }

  .DayPicker > div > div:first-child,
  .DayPicker_focusRegion,
  .DayPicker_transitionContainer,
  .DayPicker_weekHeaders__horizontal {
    /* we need to use !important in some places here to override the library's styles */
    /* stylelint-disable-next-line declaration-no-important */
    width: ${(props: IARMDatePickerWrapperProps) => (props.numberOfMonths ? props.numberOfMonths * 320 : 320)}px !important;
  }

  /* supporting openDirection */
  &.openUp {
    .SingleDatePicker_picker {
      /* we need to use !important in some places here to override the library's styles */
      bottom: 32px !important; /* stylelint-disable-line declaration-no-important */
      top: unset !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  /* to center in smaller total width, was 9px */
  .DayPicker_weekHeaders__horizontal {
    margin-left: 7px;
  }

  /* to center in smaller total width, was 9px */
  .CalendarMonthGrid__horizontal {
    left: 7px;
  }

  .CalendarMonth_table {
    width: 280px;
    margin-top: 6px;
  }

  /* month title */
  .CalendarMonth_caption {
    color: ${Colors.grey100};
    font-size: 16px;
    padding-top: 1rem;
    line-height: 15px;
    strong {
      font-weight: normal;
    }
  }

  /* next/previous month arrows */
  .DayPickerNavigation_button {
    position: absolute;
    top: 5px;
    &:first-child {
      left: 0px;
    }
    &:last-child {
      right: 0px;
    }
    img:focus {
      outline: none;
      box-shadow: 0px 0px 0px 2px #0C55B8;
      border-radius: 6px;
      border: none;
    }
  }

  .DayPicker_weekHeader {
    top: 49px;
  }

  /* individual days in calendar */
  .CalendarDay {
    font-size: 13px;
    &:hover {
      background: rgb(205, 221, 254);
    }
    &:focus:not(.CalendarDay__blocked_calendar) {
      outline: none;
      box-shadow: 0px 0px 0px 2px #0C55B8;
      border-radius: 6px;
      position: absolute;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 1px 1px 0;
    }
  }

  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar:hover,
  .CalendarDay__blocked_calendar:focus,
  .CalendarDay__blocked_calendar:active {
    background: ${Colors.grey5};
    border: 1px double ${Colors.grey15};
    color: ${Colors.grey75};
  }

  /* selected calendar day */
  .CalendarDay__selected,
  .CalendarDay__selected:hover,
  .CalendarDay__selected:active {
    background: transparent;
    color: #111;
    border: 2px solid #0C55B8;
  }

  /* hovering a range in Date Range Picker */
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${Colors.brightBlue10};
    border: 1px double ${Colors.blue30};
  }

  /* selected range in Date Range Picker */
  .CalendarDay__selected_span,
  .CalendarDay__selected_span:hover,
  .CalendarDay__selected_span:active {
    background: ${Colors.blue70};
    border: 1px double ${Colors.blue70};
  }

  .DateInput_fang {
    display: none;
  }

  .date-picker-button-bar {
    padding: 0 1.25rem 1rem 1.25rem;
  }
`;

export const ARMDatePickerInputLabel =  styled.label`
  display: block;
  margin-bottom: 0.3rem;
  color: ${Colors.grey100};
  ${Typography.small};
  ${Typography.defaultLineHeight};
  ${Typography.bold};
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const ARMDatePickerErrorMessage =  styled.span`
  display: block;
  color: ${Colors.digitalRed130};
  ${Typography.small};
  ${Typography.defaultLineHeight};
  margin-bottom: 1.5em;
`;


export interface IARMDatePickerWrapperProps {
    showCalendarIcon?: boolean,
    numberOfMonths?: number,
    hasDropdown?: boolean,
    allowTime?: boolean,
    showDefaultButtonBar?: boolean,
    
    minDate?: string,
    maxDate?: string,
    assocTo?: string,
    assocFrom?: string,

};

export interface IARMDatePickerProps  {
    domID: string,

    /** show the time element in the calendar info */
    allowTime?: boolean,
    /** for overriding the default */
    showDefaultButtonBar?: boolean,
    /** use this for the value in input box, to avoid initialSelection which is a moment object */
    initialDate?: string,  // to pass in our date string

    /** to block out dates on the calendar */
    minDate?: string,
    height?: boolean,
    maxDate?: string,
    assocTo?: string,
    assocFrom?: string,
    hideClearDate?: boolean,
    lockKeyboardInput?: boolean,

    
    /** label for input, appears above it */
    label?: string,
    className?: string,
    /** you can pass false to hide the calendar icon in the input, default true */
    showCalendarIcon?: boolean,
    /** controls whether the popup opens below or above the input, default down */
    openDirection?: 'down' | 'up',
    /** how many months to display in the popup, default 1 */
    numberOfMonths?: number,
    /** fires when the content in the input changes */
    onInputChange?: any,


    /* FROM HERE DOWN, RARELY USED, POSSIBLY UNTESTED */

    /** disables the DatePicker */
    disabled?: boolean,
    /** pass or change to open or close the popup */
    initialIsOpen?: boolean,
    /** if true, and errorMessage exists, message is shown and styling changes */
    hasError?: boolean,
    /** displayed below input if hasError is also true */
    errorMessage?: string,
    /** pass a moment object to have an initially selected date */
    initialSelection?: any,
    /** displayed below the calendar in the popup. See with buttons story for more */
    buttonBarChildren?: any,

    disableHowLong?: boolean,

    /* NOT EXPOSING AT THIS TIME */
    /** receives a date object, or null, on every input keypress, on clicking a date in the popup, and on clicking the clear button. */
 //   onDateChange?: any,
    /** fires when the popup opens or closes, receives a boolean. */
 //   onFocusChange?: any,
};

type ARMDatePickerComponent = React.FC<IARMDatePickerProps>;  


export const createMomentDate = (val: string) => {
    if (val && val.length > 0) {
        if (val.length > 10 ) { // must be time
            return moment(val, "MM/DD/YYYY HH:mm");
        } else
            return moment(val, "MM/DD/YYYY");
    }
    return null;
};



export const ARMDatePicker: ARMDatePickerComponent = ({
    allowTime = true,
    initialSelection = null,
    initialDate = null,
    initialIsOpen = false,
    disabled = false,
    hideClearDate= false,
    lockKeyboardInput= false,
    domID = '',  
    label = null,
    buttonBarChildren = null,
    className = null,
    hasError = false,
    errorMessage = null,
    openDirection = 'down',
    numberOfMonths = 1,
    showCalendarIcon = true,
 //   onDateChange = (v: any) => false,
 //   onFocusChange = (v: any) => false,
    onInputChange = (v: any) => false,
    showDefaultButtonBar = true,
    minDate = null, /* the following values are for determining calendar availible days only*/
    maxDate = null,
    assocTo = null,  /* in case this is part of a to/from combo...send this if you are the from and the to is not null */
    assocFrom = null, /* send this if you are the to and the from is not null */
    disableHowLong = false,
    ...otherProps
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(initialIsOpen);
    useEffect(() => setIsOpen(initialIsOpen), [initialIsOpen]);



    const [currentSelection, setCurrentSelection] = useState(initialSelection);  // holds the moment object - not useful to us
    useEffect(() => setCurrentSelection(initialSelection), [initialSelection]);

    const [displayDate, setDisplayDate] = useState(initialDate);  // holds our "internal" date
    useEffect(() => initializeDates(initialDate) /*setDisplayDate(getDate(initialDate))*/, [initialDate]);

    const [displayTime, setDisplayTime] = useState(initialDate);  // holds our "internal" date
    useEffect(() => { setDisplayTime(getTime(initialDate)); }, [initialDate]);

    const initializeDates = (input: string | null) => {
        var dateObj = getDate(input);
       
        var momObj = dateObj == null ? moment() : createMomentDate(dateObj);

        setCurrentSelection(momObj && momObj.isValid() ? momObj : moment());
        setDisplayDate(dateObj);    
        
    }

    const isOutsideRange = (day: any, minDate: string | null, maxDate: string | null) => {
        let dayIsBlocked = false;
        
        if (minDate != null) {
            var minDateMoment = moment(minDate, "MM/DD/YYYY");
            if (minDateMoment.isValid() && day < minDateMoment) 
                dayIsBlocked = true;
        }
        if (maxDate != null) {
            var maxDateMoment = moment(maxDate, "MM/DD/YYYY");
            if (maxDateMoment.isValid() && day > maxDateMoment)
                dayIsBlocked = true;
        }

        if (assocFrom != null) {
            var dateMoment = moment(assocFrom, "MM/DD/YYYY");
            if (dateMoment.isValid() && day < dateMoment)
                dayIsBlocked = true;
        }

        if (assocTo != null ){
            var dateMoment = moment(assocTo, "MM/DD/YYYY");
            if (dateMoment.isValid() && day > dateMoment)
                dayIsBlocked = true;
        }

        return dayIsBlocked;
    };

    const getValidTimeFromString = (input: string) => {
        input = input.replace(/[^0-9:]/g, "");
        if (input.length >= 3 && input.length <= 5) {
            var del = input.indexOf(':');
            var hr = '';
            var mn = '';
            if (del > -1) {
                hr = input.slice(0, del);
                mn = input.slice(del + 1);
            } else {
                hr = input.slice(0, input.length == 3 ? 1 : 2);
                mn = input.slice(input.length == 3 ? 1 : 2);             
            }
            if (hr.length == 1)
                hr = '0' + hr;
            if (mn.length > 2)
                mn = mn.slice(0, 2);

            var numHr = parseInt(hr);
            if (isNaN(numHr) || numHr < 0 || numHr > 23)
                hr = "00";
            var numMn = parseInt(mn);
            if (isNaN(numMn) || numMn < 0 || numMn > 59)
                mn = "00";

            // todo comment out debug
      //      console.log('getValidTimeFromString ' + hr + ' ' + mn);
            return hr + ":" + mn;
        }

        return "";
    }

    const buildDateFromDateParts = (delArr: string[]) => {
        var mm = '';
        var dd = '';
        var yy = '';
        if (delArr.length == 3) {
            mm = delArr[0];
            dd = delArr[1];
            yy = delArr[2];

            if (mm.length == 1)
                mm = '0' + mm;
            if (mm.length != 2)
                return "";
            if (dd.length == 1)
                dd = '0' + dd;
            if (dd.length != 2)
                return "";
            if (yy.length == 2) {
                var thisYear = new Date().getFullYear();
                var testCent20 = parseInt("20" + yy);
                if ((thisYear + 1) >= testCent20)
                    yy = '20' + yy;
                else {
                    yy = '19' + yy;
                }
            }
            if (yy.length != 4)
                return "";
            if (yy != '0000') {
                var invalidDate: boolean = false;
                var numMm = parseInt(mm);
                if (isNaN(numMm) || numMm <= 0) {
                    invalidDate = true;
                } else if (numMm > 12)
                    mm = "12";

                var numDD = parseInt(dd);
                if (isNaN(numDD) || numDD <= 0) {
                    invalidDate = true;
                } else if (numDD > 31)
                    dd = "31";

                var numYY = parseInt(yy);
                if (isNaN(numYY) || numYY <= 1601 || numYY >= 4500) {
                    invalidDate = true;
                }

                if (invalidDate && yy.length == 4) {
                    return mm + '/' + dd + '/' + yy;
                }
            }

            return mm + '/' + dd + '/' + yy;
        }
        return "";
    }
    const getValidDateFromString = (input: string) => {
        var outStr = "";
        input = input.replace(/[^0-9\/]/g, "");
        if (input.length >= 8 && input.length <= 10) {
            var delArr = input.split('/');
            if (delArr.length == 3)
                outStr = buildDateFromDateParts(delArr)
            else if (input.length == 8) {
                delArr = [];
                delArr.push(input.substr(0, 2));
                delArr.push(input.substr(2, 2));
                delArr.push(input.substr(4));
                outStr = buildDateFromDateParts(delArr)
            }
        }
        
        return outStr;
    }


    const getDate = (input: string | null) => {
        if (input == null)
            return null;
        if (input.indexOf(' ') === -1)
            return getValidDateFromString(input);
        else {
            var i = input.substring(0, input.indexOf(' '));
            i = getValidDateFromString(i);
     //       console.log('day is ' + i);
            return i;
        }
    }

    const getTime = (input: string | null) => {
        if (!allowTime) return '';  // todo had null - test this
        if (input == null || input.indexOf(' ') === -1)
            return '';  // todo had null test this
        else {
            var i = input.substring(input.indexOf(' ') + 1);
            i = getValidTimeFromString(i);
      //      console.log('time is ' + i);
            return i;
        }
    }

    const wrapperClasses = `
    ${className}
    ${hasError ? 'has-error' : ''}
    ${disabled ? 'disabled' : ''}
    ${openDirection === 'up' ? 'openUp' : ''}
  `;

    const wrapperRef = useRef(null);
    const onInputChangeCallback = useCallback((inp: any) => { onInputChange(inp) }, [onInputChange]);


    

    useEffect(() => {
        let input: any = null;
        const tabListener = (e: any) => {
            if (e.which == 9 || e.keyCode == 9) {
                if (input.value.length > 0 && input.value.length < 3) {
                    if (input.value.length == 1) {
                        input.value = "00/0" + input.value + "/0000";
                    } else if (input.value.length == 2) {
                        input.value = "00/" + input.value + "/0000";
                    } else if (input.value.length == 3 && input.value[2] == '/') {
                        input.value = "00/" + input.value + "0000";
                    }
                    return;
                }
            }
        };

        const listener = (e: any) => {
            const month = /^(((0)[0-9])|((1)[0-2]))$/;
            const monthAndDay = /^(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])$/;
            const dateAndHr = /^(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])(\/)([1-2][0-9][0-9][0-9])(\s)(([0-1][0-9])|((2)[0-3]))$/;
            // TODO: account for month lengths? (30, 31, 28) but then we get into leap years :(



            if (e.which === 89 || e.which === 121 && !disableHowLong) { // y
                input.value = "00/01/0000";
                e.preventDefault();
                return;
            }
            if (e.which === 84 || e.which === 116 && !disableHowLong) { // t
                input.value = "00/00/0000";
                e.preventDefault();
                return;
            }


            if (e.srcElement.selectionStart === 0 && e.srcElement.selectionEnd === input.value.length) {
                if (e.which >= 48 && e.which <= 57)
                    input.value = "";

            }


            // only allow a space and colon for time
            if (input.value.length == 10) {
                if (!allowTime) {
                    e.preventDefault();
                    return;
                }

                if (allowTime && e.which !== 32)
                    e.preventDefault();
            }
            else if (input.value.length == 13) {  // mm/dd/yyyy hh
                if (allowTime && e.which !== 58)
                    e.preventDefault();
            }
            // only allow numeral keypresses in input and set max length to 10
            else if (e.which < 48 || e.which > 57 || (allowTime && input.value.length === 16) || (!allowTime && input.value.length === 10))
                e.preventDefault();

            const valueWithCurrentKeyPress = `${input.value}${e.key}`;

            // if we have the month digits, add a slash
            if (valueWithCurrentKeyPress.match(month)) {
                e.preventDefault();
                input.value = `${valueWithCurrentKeyPress}/`;
            }

            // if we have the month and day digits, add a slash
            if (valueWithCurrentKeyPress.match(monthAndDay)) {
                e.preventDefault();
                input.value = `${valueWithCurrentKeyPress}/`;
            }

            // if we have the date and hr, add a colon
            if (valueWithCurrentKeyPress.match(dateAndHr)) {
                e.preventDefault();
                input.value = `${valueWithCurrentKeyPress}:`;
            }

            // don't allow invalid months
            // only allow 0 and 1 as the first character
            if (input.value === '' && e.which !== 48 && e.which !== 49) {
                e.preventDefault();
            }
            // if typing the second digit wouldn't match the month regex, don't allow it
            if (input.value.length === 1 && !valueWithCurrentKeyPress.match(month)) {
                e.preventDefault();
            }

            // if 'MM' add slash before current character, validate for month
            if (input.value.length === 2) {
                e.preventDefault();
                // if we have 'MM/', only allow 0-3
                if (e.which >= 48 && e.which <= 51) {
                    input.value = `${input.value}/${e.key}`;
                }
            }

            // if 'MM/DD' add slash before current character
            if (input.value.length === 5) {
                e.preventDefault();
                input.value = `${input.value}/${e.key}`;
            }

            // don't allow invalid days
            // if we have 'MM/', only allow 0-3
            if (input.value.length === 3 && (e.which < 48 || e.which > 51)) {
                e.preventDefault();
            }
            // if we have 'MM/D', only allow characters that match the regex
            if (
                input.value.length === 4 &&
                !valueWithCurrentKeyPress.match(monthAndDay)
            ) {
                e.preventDefault();
            }


            // time only allow a hour 0-2 (00-23)
            if (input.value.length === 11 && e.which !== 48 && e.which !== 49 && e.which !== 50) {
                e.preventDefault();
            }
            // time only allow a hour 20-23 
            if (input.value.length === 12 && input.value[11] == '2' && (e.which < 48 || e.which > 51)) {
                e.preventDefault();
            }
            // time only allow a minute 0-5 (00-59)
            if (input.value.length === 14 && (e.which < 48 || e.which > 53)) {
                e.preventDefault();
            }

        };

        const onChangeListener = () => {
            onInputChangeCallback(input.value);
        };

        const onBlurListener = (e: any) => {
            var targetDate = input.value;
            var d1 = getDate(input.value);
            var t1 = getTime(input.value);
            if (d1 == null)
                targetDate = '';
            else {
                targetDate = d1 + (t1.length > 0 ? ' ' + t1 : '');
            }

            if (input.value != null && input.value.length > 0 && (d1 == null || d1.length == 0)) {
                setTimeout(function () {
                    input.focus(); return false;
                }, 10);
                alert('Please enter a valid date or clear this date field.');
                e.preventDefault();
                e.stopPropogation();
                return false;
            }


            if (targetDate != input.value) {
                setDisplayDate(targetDate);
                onInputChange(targetDate);
                input.value = targetDate;
            }
            onInputChangeCallback(targetDate);
        };

        if (wrapperRef !== null && wrapperRef.current !== null) {
            input = (wrapperRef.current as any).querySelector('input');
            input.addEventListener('keypress', listener);
            input.addEventListener('keydown', tabListener);
            input.addEventListener('change', onChangeListener);
            input.addEventListener('blur', onBlurListener);
        }

        return () => {
            input.removeEventListener('keypress', listener);
            input.removeEventListener('keydown', tabListener);
            input.removeEventListener('change', onChangeListener);
            input.removeEventListener('blur', onBlurListener);
        };
    }, [wrapperRef, onInputChangeCallback]);

    const handleDateChange = (date: any) => {

        setCurrentSelection(date);
  //      onDateChange(date);

        if (date !== null) {
            setDisplayDate(date.format('MM/DD/YYYY'));
            onInputChange(date.format('MM/DD/YYYY'));
        }

        if (currentSelection !== null && date == null) {
            // when user clicks on clear button
            setDisplayDate('');
            onInputChange('');
        }

        // if we didn't have a valid date but now we do, we want to blur the input
        if (
            currentSelection === null &&
            date !== null &&
            wrapperRef.current !== null
        ) {
            (wrapperRef.current as any).querySelector('input').blur();
        }
    };


    const blurTime = (val: any) => {
        setDisplayTime(val);
        onInputChange(displayDate + (val && val.length > 0 ? ' ' + val : ''));

   /*     // if we didn't have a valid date but now we do, we want to blur the input
        if ( wrapperRef.current !== null ) {
            (wrapperRef.current as any).querySelector('input').blur();
        }
*/
    }


    const selectHowLong = (e: any) => {
        setDisplayDate(e.target.value);
        onInputChange(e.target.value);

        setCurrentSelection(moment());
   //     onDateChange(moment());

        // if we didn't have a valid date but now we do, we want to blur the input
        if ( wrapperRef.current !== null ) {
            (wrapperRef.current as any).querySelector('input').blur();
        }

        //close the calendar
        setIsOpen(false);
   //     onFocusChange(false);

    };


    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    const handleFocusChange = (focused: any) => {
        if(!focused.focused)
           setIsOpen(focused.focused);
  //      onFocusChange(focused.focused);
    };

    const getDisplayFormat = () => {
    //    console.log('getDisplayFormat ' + displayDate + ' ' + displayTime);

        if (displayDate == null)
            return "";
        else if (displayTime && displayTime.length > 0 && allowTime)
            return "[" + displayDate + "] [" + displayTime + "]";
        else
            return "[" + displayDate + "]";
        return "";
  
    }



    return (

        <ARMDatePickerWrapper
            className={wrapperClasses}
            showCalendarIcon={showCalendarIcon}
            numberOfMonths={numberOfMonths}
            ref={wrapperRef}
            showDefaultButtonBar={showDefaultButtonBar}
           
            allowTime={allowTime}
            initialDate={initialDate}
            minDate={minDate}
            maxDate={maxDate}
            assocTo={assocTo}
            assocFrom={assocFrom}
        >
            {label ? <ARMDatePickerInputLabel htmlFor={domID}>{label}</ARMDatePickerInputLabel> : null}
            <SingleDatePicker
                displayFormat={getDisplayFormat}
                date={currentSelection}
                daySize={40}
                readOnly={lockKeyboardInput}
                disabled={disabled}
                focused={isOpen}
                hideKeyboardShortcutsPanel
                id={domID ?? "datepicker"}
                navPrev={<img src={previous} tabIndex={0} alt="" />}
                navNext={<img src={next} tabIndex={0} alt="" />}
                numberOfMonths={numberOfMonths}
                onDateChange={handleDateChange}
                onFocusChange={handleFocusChange}
                placeholder="MM/DD/YYYY"
                weekDayFormat="ddd"
                showClearDate={!hideClearDate}
                isOutsideRange={(day : any) => isOutsideRange(day, minDate, maxDate)}
                calendarInfoPosition={showDefaultButtonBar ? "after" : "bottom"}
                customInputIcon={
                    showCalendarIcon ? (
                        <>
                            <VisuallyHiddenText>Pick a date</VisuallyHiddenText>
                            <div onClick={() => setIsOpen(!isOpen)}>
                                <Calendar
                                    domID={domID ? `${domID}-calander-icon` : 'calander-icon'}
                                    
                                    title="Pick a date"
                                    fillColor={getCalendarIconColor(disabled, hasError, isOpen)}
                                />
                            </div>
                        </>
                    ) : null
                }
                renderCalendarInfo={
                    buttonBarChildren
                        ? () => (
                            <div className="date-picker-button-bar">
                                {buttonBarChildren}
                            </div>
                        )
                        :
                        showDefaultButtonBar ? () => (
                            <div className="date-picker-button-bar" >


                                <div style={{paddingTop: '60px'}} />

                                <SelectComponent
                                    title='How Long'
                                    label='How Long Ago'
                                    size={6}
                                    width='120px'
                                    height='100%'
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => selectHowLong(e)}
                                    optionFields={{
                                        value: "value",
                                        text: "text",
                                    }}
                                    records={[
                                        { text: 'Today', value: '00/00/0000' },
                                        { text: 'Yesterday', value: '00/01/0000' },
                                        { text: '3 days ago', value: '00/03/0000' },
                                        { text: '7 days ago', value: '00/07/0000' },
                                        { text: '30 days ago', value: '01/00/0000' },
                                    ]}
                                    disabled={disableHowLong}
                                >
                                </SelectComponent>

                                                        
                                {allowTime && <hr />}
                                {allowTime &&
                                    <Input
                                        domID={domID ? `${domID}-datepicker-time` : 'datepicker-time'}
                                        style={{ width: '100px' }}
                                        className="arm_datepicker_time"
                                        label="Time:"
                                        errorMessage={''}
                                        hasError={false} // overridden
                                        maxLength={5}
                                        disabled={!!!allowTime}
                                        initialValue={displayTime ?? ""}

                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => { blurTime(e.target.value) }}
                                    />
                                }                      
                                
                            </div>
                        )
                        : null
                }
                openDirection={"down"}  
                
                customCloseIcon={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Clear
                        domID={`${domID}-clear-button`}
                        size='medium'
                        title="clear dates"
                        fillColor={getClearIconColor(disabled, hasError)}
                    />
                }
                inputIconPosition={'after'}
                {...otherProps}
            /> 

            {hasError && errorMessage ? (
                <ARMDatePickerErrorMessage >
                    {errorMessage}
                </ARMDatePickerErrorMessage>
            ) : null}
        </ARMDatePickerWrapper >
    );
};
    



