import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '@commonResources/colorVariables';
import { ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { CheckBoxComponent } from '@common/CheckBox';

export interface IDaysOfWeekCheckBoxComponentProps {
    id: string;
    label?: string;
    checkedSun: boolean;
    checkedMon: boolean;
    checkedTue: boolean;
    checkedWed: boolean;
    checkedThu: boolean;
    checkedFri: boolean;
    checkedSat: boolean;


    onChangeSun?: React.ChangeEventHandler<HTMLInputElement>;
    onChangeMon?: React.ChangeEventHandler<HTMLInputElement>;
    onChangeTue?: React.ChangeEventHandler<HTMLInputElement>;
    onChangeWed?: React.ChangeEventHandler<HTMLInputElement>;
    onChangeThu?: React.ChangeEventHandler<HTMLInputElement>;
    onChangeFri?: React.ChangeEventHandler<HTMLInputElement>;
    onChangeSat?: React.ChangeEventHandler<HTMLInputElement>;
    
    width?: string;
    disabled: boolean;
};

export interface IDaysOfWeekCheckBoxComponentState {
    disabled: boolean;
};

export interface IDaysOfWeekCheckBoxWrapperProps {
    widthValue?: string;
};

//---------------------------------------------------------------

export const CheckBoxWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    height: 20px;
    margin: 0 auto;
    min-width: 180px; 
    ${(props: IDaysOfWeekCheckBoxWrapperProps) => props.widthValue && `width: ${props.widthValue}`};
    text-align: center;

`;

export const CheckBoxLabel = styled.label`
    cursor: pointer;
    width: 24px;
    padding-bottom: 2px;
    padding-top: 2px;
    text-transform: capitalize;
    font-size: 12px;
    font-weight:700;
`;

export const CheckBoxInput = styled.input`
    cursor: pointer;
    position: relative;
    
    opacity: 1 !important;
    padding-right: 5px;
    width: 14px;
			
    :before {
         content: "";
         display: block;
         position: absolute;
         width: 14px;
         height: 14px;
         top: 0;
         left: 0;
         border: 1px solid ${Colors.darkGrey};
         background-color: white;
		}
    :checked:after {
         content: "";
         display: block;
         width: 5px;
         height: 10px;
         border: solid ${Colors.black};
         border-width: 0 2px 2px 0;
         -webkit-transform: rotate(45deg);
         -ms-transform: rotate(45deg);
         transform: rotate(45deg);
         position: absolute;
         top: 2px;
         left: 5px;
			}
`;
const CustomWrapper = styled.div`
    width: 24px !important;
`;


export class DaysOfWeekCheckBoxComponent extends React.Component<IDaysOfWeekCheckBoxComponentProps, IDaysOfWeekCheckBoxComponentState>
{
    static defaultProps: IDaysOfWeekCheckBoxComponentProps = {
        id: "",
        label: "",
        checkedSun: false,
        checkedMon: false,
        checkedTue: false,
        checkedWed: false,
        checkedThu: false,
        checkedFri: false,
        checkedSat: false,

        width: "200px",
        disabled: false,
    };

    constructor(props: IDaysOfWeekCheckBoxComponentProps) {
        super(props);
        this.state = {
            disabled: this.props.disabled || false,
        }

    }

    render() {
        const id_wrapper: string = this.props.id + "_wrapper";
        const id_label: string = this.props.id + "_label";
        const id_sun: string = this.props.id + "_sun";
        const id_mon: string = this.props.id + "_mon";
        const id_tue: string = this.props.id + "_tue";
        const id_wed: string = this.props.id + "_wed";
        const id_thu: string = this.props.id + "_thu";
        const id_fri: string = this.props.id + "_fri";
        const id_sat: string = this.props.id + "_sat";

        return (
            <CheckBoxWrapper
                id={id_wrapper}
                widthValue={this.props.width}
            >
                <ContentRowWrapper>
                    <ContentColumnWrapper>
                        <CheckBoxLabel id={id_sun + "_label"}>Su</CheckBoxLabel>
                        <CustomWrapper>
                        <CheckBoxComponent
                            id={id_sun}
                            checked={this.props.checkedSun}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (this.props.onChangeSun) this.props.onChangeSun(e) }}
                            disabled={this.props.disabled}
                            />
                        </CustomWrapper>
                    </ContentColumnWrapper>
                </ContentRowWrapper>
                <ContentRowWrapper>
                    <ContentColumnWrapper>
                        <CheckBoxLabel id={id_mon + "_label"}>Mo</CheckBoxLabel>
                        <CustomWrapper>
                        <CheckBoxComponent
                            id={id_mon}
                            checked={this.props.checkedMon}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (this.props.onChangeMon) this.props.onChangeMon(e) }}
                            disabled={this.props.disabled}
                        />
                        </CustomWrapper>
                    </ContentColumnWrapper>
                </ContentRowWrapper>
                <ContentRowWrapper>
                    <ContentColumnWrapper>
                        <CheckBoxLabel id={id_tue + "_label"}>Tu</CheckBoxLabel>
                        <CustomWrapper>
                        <CheckBoxComponent
                            id={id_tue}
                            checked={this.props.checkedTue}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (this.props.onChangeTue) this.props.onChangeTue(e) }}
                            disabled={this.props.disabled}
                        />
                        </CustomWrapper>
                    </ContentColumnWrapper>
                </ContentRowWrapper>
                <ContentRowWrapper>
                    <ContentColumnWrapper>
                        <CheckBoxLabel id={id_wed + "_label"}>We</CheckBoxLabel>
                        <CustomWrapper>
                        <CheckBoxComponent
                            id={id_wed}
                            checked={this.props.checkedWed}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (this.props.onChangeWed) this.props.onChangeWed(e) }}
                            disabled={this.props.disabled}
                        />
                        </CustomWrapper>
                    </ContentColumnWrapper>
                </ContentRowWrapper>
                <ContentRowWrapper>
                    <ContentColumnWrapper>
                        <CheckBoxLabel id={id_thu + "_label"}>Th</CheckBoxLabel>
                        <CustomWrapper>
                        <CheckBoxComponent
                            id={id_thu}
                            checked={this.props.checkedThu}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (this.props.onChangeThu) this.props.onChangeThu(e) }}
                            disabled={this.props.disabled}
                        />
                        </CustomWrapper>
                    </ContentColumnWrapper>
                </ContentRowWrapper>
                <ContentRowWrapper>
                    <ContentColumnWrapper>
                        <CheckBoxLabel id={id_fri + "_label"}>Fr</CheckBoxLabel>
                        <CustomWrapper>
                        <CheckBoxComponent
                            id={id_fri}
                            checked={this.props.checkedFri}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (this.props.onChangeFri) this.props.onChangeFri(e) }}
                            disabled={this.props.disabled}
                        />
                        </CustomWrapper>
                    </ContentColumnWrapper>
                </ContentRowWrapper>
                <ContentRowWrapper>
                    <ContentColumnWrapper>
                        <CheckBoxLabel id={id_sat + "_label"}>Sa</CheckBoxLabel>
                        <CustomWrapper>
                        <CheckBoxComponent
                            id={id_sat}
                            checked={this.props.checkedSat}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (this.props.onChangeSat) this.props.onChangeSat(e) }}
                            disabled={this.props.disabled}
                        />
                        </CustomWrapper>
                    </ContentColumnWrapper>
                </ContentRowWrapper>
            </CheckBoxWrapper>
        );
    }
};