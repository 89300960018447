interface Dictionary<T = any> {
    [key: string]: T;
}

export function isDictionary(value: any): value is Dictionary<any> {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
}

export function createTypeGuard<T>(keys: (keyof T)[], types: { [K in keyof T]: string }): (obj: any) => obj is T {
    return (obj: any): obj is T => {
        return keys.every(key => typeof obj[key] === types[key]);
    };
}